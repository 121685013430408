import types from './types';

// 線路一覧 取得
export const getSenroCodeList = (senroCodeList) => ({
  type: types.GET_SENRO_CODE_LIST,
  senroCodeList,
});


// 線路一覧 設定
export const setSenroCodeList = (senroCodeList) => ({
  type: types.SET_SENRO_CODE_LIST,
  senroCodeList,
});


import axios from 'axios';

import {
  getContractDntList,
  registContractDnt,
  retrieveContractDnt,
  setContractDntId,
  deleteContractDnt,
  getContractDntCount,
  checkAttachOrderDnt,
  saveContractDnts,
  getContractDntMasterList,
  getOrderAndContractDntList,
  getFormalContractDntList,
  saveFormalContractDnts,
  getIskkDntList,
  getContractDntSearchList,
  saveIskkContractDnts,
  setPhotoRegistrationInfo,
  getPhotoInfo,
  getContractDntORFilter,
  saveSyunkoContractDnts,
  getNwzgsyoSearchfilter,
  setSenroCodeSearchList,
} from './actions';

import conf from '../../config/config.js';
import common from '../common/operations';
import {setAttachedFileType} from '../../common/fileOperations';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';

/**
 * 契約電柱 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Object} addFields 追加設定するフィールド({フィールド名：値})
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetContractDntList = (conditions, fields, sortParams, addFields) => {
  console.log('契約電柱 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getContractDntList(ret.data.body.data ?
                                        ret.data.body.data : null);
    if (addFields && retData.contractDntList) {
      // 検索結果に項目追加
      const size = Object.keys(retData.contractDntList).length;
      for (let i = 0; i < size; i++) {
        Object.assign(retData.contractDntList[i], addFields);
      }
    }
    dispatch(retData);
    return ret;
  };
};

/**
 * 契約電柱件数 取得
 * @param {Object} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetContractDntCount = (conditions) => {
  console.log('契約電柱件数 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/count', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getContractDntCount(ret.data.body.data ?
                                        ret.data.body.data : 0);
    dispatch(retData);
    return ret;
  };
};

/**
 * 契約電柱 ID指定取得
 * @param {string|string[]} id ID
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doRetrieveContractDnt = (id) => {
  console.log('契約電柱 ID指定取得...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      Id: id,
    };

    const ret = await axios.post(conf.API_URL + 'ContractDnt/retrieve', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(retrieveContractDnt(
      ret.data.body.data ? ret.data.body.data : null));
  };
};

/**
 * 契約電柱 登録
 * @param {Object} data 登録する内容
 * @return {Promise<AxiosResponse<any>>} 登録結果
 */
const doRegistContractDnt = (data) => {
  console.log('契約電柱 登録...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'ContractDnt/regist', data, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(registContractDnt(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 契約電柱 削除
 * @param {string | array} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doDeleteContractDnt = (conditions) => {
  console.log('契約電柱 削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/delete', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(deleteContractDnt(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 契約電柱 ID設定
 *
 * @param {string} id 設定するID
 * @return {void} 設定結果
 */
const doSetContractDntId = (id) => {
  console.log('契約電柱ID 設定...');

  return async (dispatch) => {
    dispatch(setContractDntId(id));
  };
};


/**
 * 契約電柱情報のクリア
 * @return {void} 処理結果
 */
const doClearContractDntList = () => {
  console.log('契約電柱 クリア...');

  return async (dispatch) => {
    const retData = getContractDntList(null);
    dispatch(retData);
  };
};

/**
 * 契約電柱情報のクリア
 * @return {void} 処理結果
 */
const doClearOrderContractDnt = () => {
  console.log('契約電柱 クリア...');

  return async (dispatch) => {
    const retData = getOrderAndContractDntList(null);
    dispatch(retData);
  };
};

/**
 * 本申込の申込電柱情報のクリア
 * @return {void} 処理結果
 */
const doClearFormalContractDntList = () => {
  console.log('本申込の申込電柱情報 クリア...');

  return async (dispatch) => {
    const retData = getFormalContractDntList(null);
    dispatch(retData);
  };
};

/**
 * 契約電柱の業務チェック
 * @param {string} path 業務チェックAPI
 * @param {Object} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doCheckAttachOrderDnt = (path, conditions) => {
  console.log('契約電柱の業務チェック...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/' + path, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = checkAttachOrderDnt(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 契約電柱の業務チェッククリア
 * @return {void} 処理結果
 */
const doClearCheckAttachOrderDnt = () => {
  console.log('契約電柱の業務チェッククリア...');
  return async (dispatch) => {
    dispatch(checkAttachOrderDnt(null));
  };
};

/**
 * 申込電柱 登録/更新/削除
 * @param {Object} updateConditions 申込電柱更新条件
 * @param {Object} deleteConditions 申込電柱削除条件
 * @param {Object} contractDntList 申込電柱一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveContractDnts = (
    updateConditions, deleteConditions, contractDntList,
    orderConditions, order) => {
  console.log('申込電柱 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        contractDntList: contractDntList,
        orderConditions: orderConditions,
        order: order,
        orderStage: 'CHECK_JIKO_ORDER',
        insertDntStage: 'INSERT_CONTRACT_DNT',
        updateDntStage: 'UPDATE_CONTRACT_DNT',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/saveContractDnts', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveContractDnts(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 申込電柱（撤去） 登録/更新/削除
 * @param {Object} updateConditions 申込電柱更新条件
 * @param {Object} deleteConditions 申込電柱削除条件
 * @param {Object} contractDntList 申込電柱一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveContractDntsTekyo = (
    updateConditions, deleteConditions, contractDntList,
    orderConditions, order) => {
  console.log('申込電柱（撤去） 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        contractDntList: contractDntList,
        orderConditions: orderConditions,
        order: order,
        orderStage: 'TEKYOORDER_APPLY_UPDATE',
        insertDntStage: 'INSERT_CONTRACT_DNT_TEKYO',
        updateDntStage: 'UPDATE_CONTRACT_DNT_TEKYO',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/saveContractDnts', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveContractDnts(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 申込電柱（撤去）共架料区分反映 登録/更新/削除
 * @param {Object} updateConditions 申込電柱更新条件
 * @param {Object} deleteConditions 申込電柱削除条件
 * @param {Object} contractDntList 申込電柱一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveContractDntsTekyoPrice = (
    updateConditions, deleteConditions, contractDntList,
    orderConditions, order) => {
  console.log('申込電柱（撤去）共架料区分反映 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        contractDntList: contractDntList,
        orderConditions: orderConditions,
        order: order,
        orderStage: 'CHECK_JIKO_ORDER',
        insertDntStage: 'INSERT_CONTRACT_DNT_TEKYO',
        updateDntStage: 'UPDATE_CONTRACT_DNT_TEKYO',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/saveContractDnts', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveContractDnts(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 本申込の申込電柱 登録/削除
 * @param {array} updateConditions 申込電柱更新条件
 * @param {array} deleteConditions 申込電柱削除条件
 * @param {array} contractDntList 保存する申込電柱
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveFormalContractDnts = (
    updateConditions, deleteConditions, contractDntList,
    orderConditions, order) => {
  console.log('申込電柱 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        contractDntList: contractDntList,
        orderConditions: orderConditions,
        order: order,
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiFormal/saveFormalContractDnts', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveFormalContractDnts(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 契約電柱マスタ 取得（移設依頼）
 * @param {Object} conditions 検索条件
 *                            以下はデフォルト指定済み
 *                           'RecordType.DeveloperName': 'ContractDntMaster',
 *                           'RecordType.SobjectType': 'ContractDnt__c',
 *                           'KyogaHaisiDate__c': null
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetContractDntMasterList = (conditions, fields, sortParams) => {
  console.log('契約電柱マスタ 取得（移設依頼）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getContractDntMaster', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getContractDntMasterList(
      ret.data.body.data ? ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 申込情報と申込電柱情報 取得
 * @param {Object} orderConditions 申込検索条件
 * @param {array} orderFields 取得する申込のフィールド名
 * @param {Object} dntConditions 申込電柱検索条件
 * @param {array} dntFields 取得する申込電柱のフィールド名
 * @param {Object} sortParams 申込電柱の並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {bool} checkPhoto 写真取得フラグ true:取得する、false:取得しない
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetOrderAndContractDntList = (
    orderConditions, orderFields,
    dntConditions, dntFields, sortParams, checkPhoto=false) => {
  console.log('申込情報と申込電柱情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};

    if (orderConditions) {
      body['orderConditions'] = orderConditions;
    }

    if (orderFields) {
      body['orderFields'] = orderFields;
    }

    if (dntConditions) {
      body['conditions'] = dntConditions;
    }

    if (dntFields) {
      body['fields'] = dntFields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    body['checkPhoto'] = checkPhoto;

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getOrderAndContractDnt', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getOrderAndContractDntList(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 竣工時の申込情報と申込電柱情報 取得
 * @param {Object} orderConditions 申込検索条件
 * @param {array} orderFields 取得する申込のフィールド名
 * @param {Object} dntConditions 申込電柱検索条件
 * @param {array} dntFields 取得する申込電柱のフィールド名
 * @param {Object} sortParams 申込電柱の並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {bool} checkPhoto 写真取得フラグ true:取得する、false:取得しない
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetSyunkoOrderAndContractDnt = (orderConditions, orderFields,
    dntConditions, dntFields, sortParams, checkPhoto=false) => {
  console.log('竣工時の申込情報と申込電柱情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};

    if (orderConditions) {
      body['orderConditions'] = orderConditions;
    }

    if (orderFields) {
      body['orderFields'] = orderFields;
    }

    if (dntConditions) {
      body['conditions'] = dntConditions;
    }

    if (dntFields) {
      body['fields'] = dntFields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    body['checkPhoto'] = checkPhoto;

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getSyunkoOrderAndContractDnt', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getOrderAndContractDntList(ret.data.body.data ?
                                      ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 申込情報と申込電柱情報 取得
 * @param {Object} orderConditions 申込検索条件
 * @param {array} orderSubquery 申込サブクエリ設定
 * @param {array} orderFields 取得する申込のフィールド名
 * @param {Object} dntConditions 申込電柱検索条件
 * @param {array} dntFields 取得する申込電柱のフィールド名
 * @param {Object} sortParams 申込電柱の並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {bool} checkPhoto 写真取得フラグ true:取得する、false:取得しない
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetRepairOrderAndContractDntList = (
    orderConditions, orderSubquery, orderFields,
    dntConditions, dntFields, sortParams, checkPhoto=false) => {
  console.log('改修申込情報と申込電柱情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};

    if (orderConditions) {
      body['orderConditions'] = orderConditions;
    }

    if (orderSubquery) {
      body['orderSubquery'] = orderSubquery;
    }

    if (orderFields) {
      body['orderFields'] = orderFields;
    }

    if (dntConditions) {
      body['conditions'] = dntConditions;
    }

    if (dntFields) {
      body['fields'] = dntFields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    body['checkPhoto'] = checkPhoto;

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getOrderAndContractDnt', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getOrderAndContractDntList(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};


/**
 * 本申込の申込電柱情報 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {bool} isRef true:本申込データ参照
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetFormalContractDntList = (
    conditions, fields, sortParams, isRef=false) => {
  console.log('本申込の申込電柱情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    body['isRef'] = isRef;

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getFormalContractDntList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getFormalContractDntList(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

const doGetContractDntORFilter = (conditions, fields, sortParams) => {
  console.log('契約電柱情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getContractDntORFilter', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getContractDntORFilter(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 一束化設定申込電柱情報 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetIskkDntList = (conditions, fields, sortParams) => {
  console.log('一束化設申込電柱情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getIskkFormalDntList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getIskkDntList(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 一束化設定申込電柱情報 クリア
 * @return {function}
 */
const doClearIskkDntList = () => {
  console.log('一束化設定申込電柱情報 クリア...');
  return async (dispatch) => {
    dispatch(getIskkDntList(null));
  };
};

/**
 * 契約電柱一覧（検索画面用） 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetContractDntSearchList = (conditions, fields, sortParams) => {
  console.log('契約電柱 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'ContractDnt/getContractDntList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getContractDntSearchList(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 一束化相手先設定
 * @param {array} updateConditions 申込電柱更新条件
 * @param {array} contractDntList 保存する申込電柱
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @param {array} files 登録ファイル(サーバー登録済のファイル含む)
 * @param {array} deleteFiles 削除ファイル(サーバー未登録のファイル含む)
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveIskkContractDnts = (
    updateConditions, contractDntList,
    orderConditions, order, files, deleteFiles) => {
  console.log('一束化相手先設定...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const _fileInfo = {
      uuid: '',
      uploadFiles: [],
      deleteFileKeys: [],
    };

    // S3のバケットに未登録のファイルを一時アップロード
    const attachedFiles = files.filter((data) => !data.ContentDocumentId);
    let uploadResult = {};
    if (attachedFiles.length > 0) {
      uploadResult =
      await common.doUploadToS3Common(attachedFiles, getState, dispatch);
      _fileInfo['uuid'] = uploadResult.uuid;// API実行ごとにuuid発行
      _fileInfo['uploadFiles'] = setAttachedFileType(attachedFiles, uploadResult.uploadFiles);
    }
    // 削除ファイルのId一覧を生成
    _fileInfo['deleteFileKeys'] =
      deleteFiles.filter((file) => !!file.ContentDocumentId)
          .map((file)=> file.ContentDocumentId);

    const body = {
      record: {
        updateConditions: updateConditions,
        contractDntList: contractDntList,
        orderConditions: orderConditions,
        order: order,
        stage: 'ISKK_UPDATE',
        fileInfo: _fileInfo,
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/saveIskkContractDnts', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    // 一時アップロードが失敗でファイル登録成功時、エラー情報置き換え
    common.doCheckS3UploadResult(uploadResult, ret);

    dispatch(saveIskkContractDnts(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * @typedef PhotoRegistlationInfo 写真登録情報
 * @property {string} contractDntId 契約電柱ID
 * @property {string} transitionSrcId 遷移元画面の画面番号
 * @property {boolean} [readOnly] 読取専用で表示する場合trueを設定。省略時はfalse扱い
 * @property {boolean} [hasCommunicationLine] 通信引込線の有無
 * @property {boolean} [hasNewCopperPipes] 電柱への立上り銅管の新設有無
 * @property {'mae'} [suffix] 撤去 施工前の写真登録の場合は'mae'を指定
 * @property {string} [sekoMaeDntId] 施工前の契約電柱ID。竣工写真登録画面で施工前の電柱写真を表示する場合に指定
 * @property {'mae'} [sekoMaeSuffix] 施工前のサフィックス。竣工写真登録画面で施工前の電柱写真を表示する場合に指定
 */

/**
 * 写真登録情報 設定。
 * 写真登録画面へ渡す情報を設定します。
 *
 * @param {PhotoRegistlationInfo} photoRegistrationInfo 写真登録情報
 * @return {function}
 */
const doSetPhotoRegistrationInfo = (photoRegistrationInfo) => {
  console.log('写真登録情報 設定...');

  return (dispatch) => {
    dispatch(setPhotoRegistrationInfo(photoRegistrationInfo));
  };
};

/**
 * 写真アップロード。
 * @param {object} dntInfo 契約電柱情報
 * @param {string} suffix サフィックス。アップロード先ディレクトリ名の契約電柱IDの後に付加
 * @param {Object.<string, UploadFileInfo[]>} uploadImages 画像情報一覧
 * @param {Object.<string, UploadFileInfo[]>} deleteImages 削除画像情報一覧
 * @param {Object.<string, PhotoType>} photoTypes 登録可能写真情報
 * @return {function}
 */
const doUploadPhoto = (
    dntInfo, suffix, uploadImages, deleteImages, photoTypes) => {
  console.log('写真 アップロード...');

  return async (dispatch, getState) => {
    // ファイルがアップロード最大数を超えていないかチェックする
    const uploadable = await checkPhotoUploadable(
        dntInfo, uploadImages, deleteImages, photoTypes);
    if (!uploadable) {
      return {
        data: {body: {errorCode: 'FILE_MAX'}},
      };
    }

    // S3のバケットに一時アップロード
    const uploadResult =
      await common.doUploadToS3Common(uploadImages, getState, dispatch);

    // 削除したファイルのキー一覧を生成
    const deleteFileKeys = createDeleteFileKeys(deleteImages);

    // 写真登録APIを実行する
    const body = {
      orderId: dntInfo.Order__r.Id,
      contractDntId: dntInfo.Id,
      suffix: suffix,
      fileInfo: {
        uuid: uploadResult.uuid,
        uploadFiles: uploadResult.uploadFiles,
        deleteFileKeys: deleteFileKeys,
      },
    };

    // 一時アップロードしたファイルを正式な場所へ格納
    const ret = await axios.post(conf.API_URL + 'Photo/regist', body, {});

    // 一時アップロードが失敗でファイル登録成功時、エラー情報置き換え
    common.doCheckS3UploadResult(uploadResult, ret);

    return ret;
  };
};

/**
 * 写真をアップロード可能であるかチェックする
 * @param {Object} dntInfo 契約電柱情報
 * @param {Object.<string, UploadFileInfo[]>} uploadImages アップロード画像情報一覧
 * @param {Object.<string, UploadFileInfo[]>} deleteImages 削除画像情報一覧
 * @param {Object.<string, PhotoType>} photoTypes
 * @return {Promise<boolean>} 写真アップロード可否
 */
async function checkPhotoUploadable(
    dntInfo, uploadImages, deleteImages, photoTypes) {
  // アップロードするファイルがない場合はチェックOK
  const hasUpload = hasUploadFile(uploadImages);
  if (!hasUpload) {
    return true;
  }

  // 写真種別ごとのアップロード可能数を生成
  const uploadableNum =
    createUploadableNum(photoTypes);

  // 写真種別ごとのアップロード可能数に、アップロードファイルと削除ファイルの数を反映する
  calcUploadableNum(uploadableNum, uploadImages, deleteImages);

  // アップロード前に写真の有無をチェックする
  const result = await axios.post(conf.API_URL + 'Photo/exists', {
    contractDntId: dntInfo.Id,
    orderId: dntInfo.Order__r.Id,
    getNum: true,
  }, {});

  const numInfo = result.data.body.data.NumInfo;

  // アップロード可能数と現在アップロード済の画像数を比較
  if (!checkUploadableNum(uploadableNum, numInfo)) {
    return false;
  }

  return true;
}

/**
 * アップロードファイル有無判定
 * @param {Object.<string, UploadFileInfo[]>} uploadImages アップロード画像情報一覧
 * @return {boolean} アップロードするファイルがある場合true、ない場合false
 */
function hasUploadFile(uploadImages) {
  let hasUpload = false;

  // アップロードするファイルがあるか判定
  for (const key in uploadImages) {
    if (!uploadImages.hasOwnProperty(key)) {
      continue;
    }

    const images = uploadImages[key];
    if (Array.isArray(images) && images.length > 0) {
      hasUpload = true;
    }
  }

  return hasUpload;
}

/**
 * 残りアップロード可能ファイル数を生成。
 * @param {Object.<string, PhotoType>} photoTypes
 * @return {Object.<string, number>} 残りアップロード可能ファイル数
 */
function createUploadableNum(photoTypes) {
  const uploadableNum = {};

  for (const typeName in photoTypes) {
    if (!photoTypes.hasOwnProperty(typeName)) {
      continue;
    }

    const photoType = photoTypes[typeName];
    const componentId = photoType.componentId;
    uploadableNum[componentId] = photoType.maxFileCount;
  }

  return uploadableNum;
}

/**
 * 残りアップロード可能ファイル数を計算。
 *
 * アップロード可能ファイル数情報にアップロード画像情報一覧と削除画像情報一覧の値を反映する。
 *
 * @param {Object.<string, number>} uploadableNum アップロード可能ファイル数情報
 * @param {Object.<string, UploadFileInfo[]>} uploadImages アップロード画像情報一覧
 * @param {Object.<string, UploadFileInfo[]>} deleteImages 削除画像情報一覧
 */
function calcUploadableNum(uploadableNum, uploadImages, deleteImages) {
  for (const typeName in uploadableNum) {
    if (!uploadableNum.hasOwnProperty(typeName)) {
      continue;
    }

    let num = uploadableNum[typeName];
    if (uploadImages.hasOwnProperty(typeName)) {
      num -= uploadImages[typeName].length;
    }

    if (deleteImages.hasOwnProperty(typeName)) {
      num += deleteImages[typeName].length;
    }

    uploadableNum[typeName] = num;
  }
}

/**
 * アップロード可能数と現在アップロード済の画像数を比較
 * @param {Object.<string, number>} uploadableNum アップロード可能ファイル数情報
 * @param {Object.<string, number>} numInfo アップロード済ファイル数
 * @return {boolean} アップロード可能な場合true、不可の場合false
 */
function checkUploadableNum(uploadableNum, numInfo) {
  console.dir({uploadableNum, numInfo});

  for (const type in uploadableNum) {
    if (!uploadableNum.hasOwnProperty(type)) {
      continue;
    }

    // アップロード済ファイルに同一の種別が設定されていない場合はスキップ
    if (!numInfo.hasOwnProperty(type)) {
      continue;
    }

    // アップロード可能枚数をアップロード済枚数が超える場合はエラー
    const num = uploadableNum[type] - numInfo[type];
    if (num < 0) {
      return false;
    }
  }

  return true;
}

/**
 * 削除ファイルのキー一覧生成。
 * @param {Object.<string, UploadFileInfo[]>} deleteImages
 * @return {string[]} キー一覧
 */
function createDeleteFileKeys(deleteImages) {
  const deleteFiles = [];

  for (const photoType of Object.keys(deleteImages)) {
    for (const image of deleteImages[photoType]) {
      const key = image.ContentDocumentId;
      if (key == null) {
        // deleteImagesにContentDocumentId未設定の場合、未アップロードなので除外
        continue;
      }

      deleteFiles.push(key);
    }
  }

  return deleteFiles;
}

/**
 * 写真情報 取得。
 * @param {GetDntInfo[]} conditions 検索条件一覧
 * @param {boolean} getSignedUrl Base64形式ではなく、S3の署名付きURLとして画像を取得する場合はtrue
 * @return {function}
 */
const doGetPhotoInfo = (conditions, getSignedUrl) => {
  console.log('写真 取得...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      conditions,
      getSignedUrl,
    };

    const ret = await axios.post(conf.API_URL + 'Photo/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(getPhotoInfo(data));
    return ret;
  };
};

/**
 * 写真情報 破棄。
 * @return {function}
 */
const doClearPhotoInfo = () => {
  console.log('写真 破棄...');
  return async (dispatch, getState) => {
    dispatch(getPhotoInfo(null));
  };
};

/**
 * 写真削除。
 * @param {string} orderId 申込ID
 * @param {string} contractDntId 契約電柱 レコードID 省略時は申込に紐づく写真を全て削除
 * @return {function}
 */
const doDeletePhoto = (orderId, contractDntId = null) => {
  console.log('写真 削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      orderId: orderId,
      contractDntId: contractDntId,
    };
    console.log({body});
    const ret = await axios.post(conf.API_URL + 'Photo/delete', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    return ret;
  };
};

/**
 * 写真登録有無判定。
 * @param {string} contractDntId 契約電柱 レコードID
 * @param {string} suffix サフィックス
 * @return {function}
 */
const doExistsPhoto = (contractDntId, suffix) => {
  console.log('写真 写真登録有無判定...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      contractDntId: contractDntId,
      suffix: suffix,
    };

    console.log(body);
    const ret = await axios.post(conf.API_URL + 'Photo/exists', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    return ret;
  };
};

/**
 * 竣工申込の申込電柱 更新
 * @param {Object} updateConditions 申込電柱更新条件
 * @param {Object} deleteConditions 申込電柱削除条件
 * @param {Object} contractDntList 申込電柱一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
* @param {bool} isApply 竣工届登録日を更新する場合, true, 左記以外, false
 * @param {array} syunkoUploadFiles サーバーにアップロードするファイル一覧
 * @param {array} deleteFiles サーバーから削除するファイル一覧
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveSyunkoContractDnts = (
    updateConditions, deleteConditions, contractDntList,
    orderConditions, order, isApply=false,
    syunkoUploadFiles=null, deleteFiles=null) => {
  console.log('申込電柱 更新...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const _fileInfo = {
      uuid: '',
      uploadFiles: [],
      deleteFileKeys: [],
    };

    // S3のバケットに未登録のファイルを一時アップロード
    let uploadResult = {};
    if (syunkoUploadFiles) {
      const attachedFiles =
        syunkoUploadFiles.filter((data) => !data.ContentDocumentId);
      if (attachedFiles.length > 0) {
        uploadResult =
        await common.doUploadToS3Common(attachedFiles, getState, dispatch);
        _fileInfo['uuid'] = uploadResult.uuid;// API実行ごとにuuid発行
        _fileInfo['uploadFiles'] = setAttachedFileType(attachedFiles, uploadResult.uploadFiles);
      }
    }
    if (deleteFiles) {
      // 削除ファイルのId一覧を生成
      _fileInfo['deleteFileKeys'] =
        deleteFiles.filter((file) => !!file.ContentDocumentId)
            .map((file)=> file.ContentDocumentId);
    }

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        contractDntList: contractDntList,
        orderConditions: orderConditions,
        order: order,
        isApply: isApply,
        fileInfo: _fileInfo,
        orderStage: 'UPDATE_SYNKO',
        updateDntStage: 'UPDATE_SYNKO',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiFormal/saveSyunkoContractDnts', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    // 一時アップロードが失敗でファイル登録成功時、エラー情報置き換え
    common.doCheckS3UploadResult(uploadResult, ret);

    dispatch(saveSyunkoContractDnts(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 事業所 抽出
 * @param {Array} NWZgsyoList 事業所リスト
 * @param {String} Namefilter 抽出文字列
 * @return {Object} 登録結果
 */
const doGetNWZgsyoSearchfilter = (NWZgsyoList, Namefilter) =>{
  console.log('事業所 取得...');
  let nwzgsyoSearchfilter = [];
  if (isNullOrWhiteSpace(Namefilter)) {
    nwzgsyoSearchfilter = NWZgsyoList;
  } else {
    nwzgsyoSearchfilter = NWZgsyoList.filter((item)=>{
      return item.Name.includes(Namefilter);
    });
  }
  return async (dispatch) => {
    dispatch(getNwzgsyoSearchfilter(nwzgsyoSearchfilter));
  };
};

/**
 * 線路コード一覧 設定
 * @param {Array} senroCodeSearchList 線路一覧
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doSetSenroCodeSearchList = (senroCodeSearchList) =>{
  console.log('線路コード設定');
  return async (dispatch) =>{
    dispatch(setSenroCodeSearchList(senroCodeSearchList));
  };
};

export default {
  doGetContractDntList,
  doRetrieveContractDnt,
  doRegistContractDnt,
  doDeleteContractDnt,
  doSetContractDntId,
  doClearContractDntList,
  doClearOrderContractDnt,
  doClearFormalContractDntList,
  doGetContractDntCount,
  doCheckAttachOrderDnt,
  doClearCheckAttachOrderDnt,
  doSaveContractDnts,
  doSaveContractDntsTekyo,
  doSaveContractDntsTekyoPrice,
  doGetContractDntMasterList,
  doGetOrderAndContractDntList,
  doGetSyunkoOrderAndContractDnt,
  doGetRepairOrderAndContractDntList,
  doGetFormalContractDntList,
  doGetContractDntORFilter,
  doSaveFormalContractDnts,
  doGetIskkDntList,
  doClearIskkDntList,
  doGetContractDntSearchList,
  doSaveIskkContractDnts,
  doSetPhotoRegistrationInfo,
  doUploadPhoto,
  doGetPhotoInfo,
  doClearPhotoInfo,
  doDeletePhoto,
  doExistsPhoto,
  doSaveSyunkoContractDnts,
  doGetNWZgsyoSearchfilter,
  doSetSenroCodeSearchList,
};

/**
 * 本ファイルはメッセージのみしか使用しません。
 * その他、画面項目名、表示文言などは、compornentに直接定義する。
 * 読点は中電さんに合わせる。「，」
 */
export default {

  // ダイアログ タイトル
  /** 情報 : 情報表示（処理終了等）、ガイダンス等 */
  'dialog.title.I': '確認',
  /** 警告 : 入力チェックでの警告（処理継続可） */
  'dialog.title.W': '警告',
  /** エラー : 入力チェック、処理実行時のエラー（処理継続不可） */
  'dialog.title.E': '注意',
  /** 確認 : 処理継続の確認メッセージ */
  'dialog.title.C': '確認',
  /** システムエラー : サーバ、ネットワーク等の環境によるエラー 予期しないエラー */
  'dialog.title.S': 'システムエラー',

  // メッセージ(共通) CXXXXX
  'message.CS0001': '予期せぬエラーが発生しました。管理者に連絡してください。',
  'message.CC0002': '前画面に戻ります。よろしいですか。',
  'message.CC0003': '画面を閉じます。よろしいですか。',
  'message.CC0004': '登録します。よろしいですか。',
  'message.CC0005': '{0}します。よろしいですか。',
  'message.CC0006': '{0}を{1}します。よろしいですか。',
  'message.CC0007': '{0}を取込みます。よろしいですか。',
  'message.CC0008': '「{0}」を削除します。よろしいですか。',
  'message.CI0009': '{0}が完了しました。',
  'message.CI0010': '{0}が終了しました。',
  'message.CI0011': '選択した添付ファイル({0})を削除しました。',
  'message.CI0012': '{0}を添付（アップロード）しました。',
  'message.CI0013': '{0}を出力（ダウンロード）しました。',
  'message.CW0013': '入力内容が編集されています。保存せずに続行しますか。',
  'message.CW0014': '{0}が{1}に存在しませんが，続行しますか。',
  'message.CE0015': '検索結果の件数が上限を超えています。検索条件を絞り込んで検索し直してください。',
  'message.CE0016': '検索条件に合致するデータはありません。',
  'message.CE0017': '{0}を入力してください。',
  'message.CE0018': '{0}を添付（アップロード）してください。',
  'message.CE0019': '{0}は1件以上入力してください。',
  'message.CE0020': '{0}≦{1}の関係になるように入力してください。',
  'message.CE0021': '{0}から{1}の間になるように入力してください。',
  'message.CE0022': '1件のみ選択してください。',
  'message.CE0023': '{0}対象を選択してください。',
  'message.CE0024': '{0}が{1}の場合，{2}を入力してください。',
  'message.CE0025': '{0}は，いずれかを選択してください。',
  'message.CE0026': '{0}は，{1}の範囲で入力してください。',
  'message.CE0027': '{0}を選択してください。',
  'message.CE0028': '選択したファイルのサイズが大きすぎます。{1}MB以内のファイルを選択してください。{0}',
  'message.CE0029': '選択したファイルが読み込めません。ファイルのアクセス権限を確認してください。',
  'message.CE0030': '選択したファイルにマクロを検出しました。ファイルを確認してください。{0}',
  'message.CE0031': '選択したファイルは，{0}のレイアウトではありません。',
  'message.CE0032': '{0}取込先フォルダ名を選択してください。',
  'message.CE0033': '{0}が{1}に存在しません。',
  'message.CE0034': '{0}は既に登録済みです。',
  'message.CE0035': '{0}は数値を入力してください。',
  'message.CE0036': '{0}に正しい日付を入力してください。',
  'message.CE0037': '{0}は正しい時刻を入力してください。',
  'message.CE0038': '{0}は未来の日付を入力してください。',
  'message.CE0039': '既に申込しています。',
  'message.CE0040': '期限({0}日前)を超過しています。',
  'message.CE0041': '{0}の桁数が超過しています。',
  'message.CE0042': '{0}は半角数字のみ入力してください。',
  'message.CE0043': '{0}は全角ひらがなのみ入力してください。',
  'message.CE0044': '{0}は正しいフォーマットで入力してください。',
  'message.CE0045': '開始日は終了日以前に設定してください。',
  'message.CE0046': '{0}は半角文字のみで入力してください。',
  'message.CE0047': '入力されたパスワードは規定を満たしていません。',
  'message.CE0048': '他のユーザーによって更新されています。操作をやり直してください。',
  'message.CE0049': 'ファイル取込でエラーが発生しました。ファイル名:{0}, 行数:{1}, カラム:{2}, {3}',
  'message.CE0050': '入力に誤りがあります。入力内容を確認してください。',
  'message.CE0051': '{0}は整数{1}桁，小数{2}桁の精度以内の数値を入力してください。',
  'message.CE0052': '{0}の{1}に失敗しました。',
  'message.CC0053': '共架料算定が承認された時点の翌月が共架料発生月となります。よろしいですか？',
  'message.CE0054': '{0}は，{1}のため選択できません。',
  'message.CE0055': '登録可能な最大件数({0})を超過するため登録できません。',
  'message.CE0056': '{0}を操作する権限がありません。',
  'message.CE0057': '{0}ため{1}できません。',
  'message.CE0060': '{0}は許可されていないファイル形式です。',
  'message.CE0061': 'ファイルの件数が上限を超えています。',
  'message.CE0062': 'ファイル添付先がありません。{0}を保存してください。',
  'message.CE0063': '{0}は代表電柱と同じ事業所にしてください。({1}行目)',
  'message.CE0064': '離隔不足です。設備設置位置を見直してください。({0}:{1}行目)',
  'message.CE0065': '離隔不足です。緩和位置での設置を希望する場合は,確認事項に承諾してください。({0}:{1}行目)',
  'message.CE0066': '離隔不足です。離隔距離を緩和したとしても離隔不足となるため設備設置位置を見直してください。({0}:{1}行目)',
  'message.CW0067': '※注意※　0.30m未満に接近する場合，当該共架物の施設者に近接設置についての承諾が必要です。({0}:{1}行目)',
  'message.CE0068': '通信引込線は電柱から１ｍ以上離した位置から分岐させてください。({0}:{1}行目)',
  'message.CE0069': '新たに立上り鋼管を設置するスペースがありません。({0}:{1}行目)',
  'message.CE0070': '通信引込線の家屋側支持点付近における離隔確保にチェックがありません。({0}:{1}行目)',
  'message.CE0071': '基準への適合のチェックがありません。({0}:{1}行目)',
  'message.CE0072': '共架事業者に対応する請求先に買取保留があります。',
  'message.CE0073': '作成中の契約電柱があります。電柱番号: {0}',
  'message.CE0074': '外線工事ステータスが「工事完了」ではありません。',
  'message.CE0075': '再提出が必要な契約電柱があります。電柱番号:{0}',
  'message.CE0076': '契約電柱の登録がありません。',
  'message.CE0077': '作成中の線設備があります。電柱番号1: {0} 電柱番号2: {1}',
  'message.CE0078': '線設備の登録がありません。',
  'message.CE0079': '共架料起算月が1～12以外です。',
  'message.CE0080': '共架事業者の共架種別と申込の共架種別が一致しません。',
  'message.CE0084': '概算金額内訳書のファイル数が1つではありません。',
  'message.CI0085': 'ただいま承認申請中です。',
  'message.CI0086': '申込を差戻しました。',
  'message.CI0087': '申込を確定しました。',
  'message.CE0091': 'ファイル名が{0}文字を超えています。',
  'message.CE0092': 'ファイルが空です。',
  'message.CE0093': '{0}は既に登録されています。ファイル名を変更してください。',
  'message.CE0094': '写真の登録/削除が行われていません。',
  'message.CE0095': '写真のアップロードに失敗しました。再度やり直してください。',
  'message.CW0096': '本画面でエラーが残っている状態で保存しています。登録までにエラーを取り除いてください。\n',
  'message.CE0097': '契約終了日は契約開始日以降に設定してください。',
  'message.CE0098': 'この項目は変更できません。',
  'message.CE0099': 'ファイルのアップロードに失敗しました。再度やり直してください。',
  'message.CE0101': '{0}ため{1}できません。電柱番号:{2}',
  'message.CE0102': '{0}ステータスが「{1}」に至っていません。',
  'message.CE0104': '可否判定回答予定日が可否判定申込日の２か月後以前です。',
  'message.CE0105': '可否判定回答期限超過理由が未入力です。',
  'message.CE0106': '可否判定回答予定日が可否判定申込回答期限日以前です。',
  'message.CE0107': '{0}を入力した場合，{1}を入力してください。',
  'message.CE0108': '{0}が1件ではありません。',
  'message.CE0109': 'セッションの有効期限が切れました。\n再度ログインしてください。',
  'message.CE0110': '承認申請中のため，編集できません。',
  'message.CC0111': '{0}\nの代行入力を{1}します。よろしいですか？',
  'message.CI0112': '{0}\nの代行入力を{1}しました。',
  'message.CS0113': '予期せぬエラーが発生しました。\nログイン画面から再度やり直してください。',
  'message.CE0114': '本登録が完了していません。\n本登録が完了しましたら再度アクセスをお願いします。',
  'message.CE0115': '誤ったユーザーIDまたはパスワードが入力されました。\n再度入力してください。',
  'message.CE0116': 'セッションの有効期限が切れました。\n再度ログインしてください。',
  'message.CE0117': 'アクセスが集中しております。少し時間をおいて再度アクセスをお願いします。',
  'message.CE0118': 'ファイルのサイズが上限を超えています。',
  'message.CE0119': '許可されていないファイル形式です。',
  'message.CI0123': '仮パスワードでのログインのため，パスワードを変更してください。',
  'message.CI0124': 'パスワードを変更しました。\n新しいパスワードでログインしてください。',
  'message.CI0126': [
    'パスワードリセットのメールを送付しました。',
    'メールに記載のURLをクリックして新しいパスワードを入力してください。',
    '',
    '数分経過してもメールが届かない場合、以下をご確認ください。',
    '・迷惑メールに振り分けられている場合があります。',
    '　メールが迷惑メールフォルダに届いていないかご確認ください。',
    '・ドメイン受信設定が必要な場合があります。',
    '　pnet.energia.co.jpからのメールを受信できるよう設定してください。',
    '・ご入力のユーザIDが誤っている場合があります。',
    '　ユーザIDは大文字と小文字を区別します。',
  ].join('\n'),
  'message.CE0127': '連続して実行されたため，一時的に処理が制限されています。\n少し時間をおいて再度実施をお願いします。',
  'message.CE0128': '認証コードの有効期限が切れています。\n再度パスワードリセットを実施してください。',
  'message.CE0130': '{0}を添付（アップロード）してください。',
  'message.CE0133': '{0}が未設定です。{0}を設定し，保存してください。',
  'message.CE0134': '{0}を添付（アップロード）してください。({1}行目)',
  'message.CE0136': '{0}は本日以前の日付を入力してください。({1}行目)',
  'message.CC0140': 'システムセキュリティのため，新しいメールアドレスにて変更確認が必要です。\n\n「OK」をクリックすると，変更後のメールアドレスに確認手順を記載した確認メールを送信します。\n更新しない場合は「キャンセル」をクリックしてください。',
  'message.CE0141': 'メールの送信に失敗しました。\nメールアドレスをご確認の上，再度実行してください。',
  'message.CI0142': 'メールアドレス変更のメールを送付しました。\nメールに記載のURLをクリックして新しいメールアドレスを認証してください。',
  'message.CE0144': '{0}は削除されたユーザです。',
  'message.CI0149': '{0}が完了しました。\n{0}受付後，{1}メールを送信します。',
  'message.CE0150': '通信に失敗しました。再度実行してください。',
  'message.CW0152': '選択した電柱に，関係各所との調整が必要となる電柱が含まれております。\n\n　そのため，申込受付から，各種回答について時間を要する場合があります。時間を要しても申込を行う場合は，「OK」を選択してください。取止める場合は，「キャンセル」を選択してください。',
  'message.CW0157': 'エラーが残っています。{0}までに，本画面に戻って入力内容を修正してください。',
  'message.CE0158': 'エラーが残っているため{0}出来ません。{1}入力内容を修正してください。',
  'message.CE0159': '{0}を保存してください。',
  'message.CW0161': '入力内容が編集されています。保存して{0}しますか。',
  'message.CE0167': 'ファイルにウィルスを検出したため，アップロードできません。',
  'message.CE0169': '{0}を保存していないため，{1}を入力できません。',
  'message.CE0171': '作成中以外の申込（{0}）は削除できません。',
  'message.CE0174': 'エラーが残っているため，{0}できません。',
  'message.CE0178': '新規申込停止中のため，申込できません。',
  'message.CE0186': 'メールアドレスが登録されていないため，登録してください。',
  'message.CI0187': 'メールアドレス変更のメールを送付しました。\nメールに記載のURLをクリックしてメールアドレスを認証した後，\n再度ログインしてください。',
  'message.CE0198': '選択した{0}が削除可能な件数を超えています。\n件数を{1}件以下にしてください。',
  'message.CE0199': '本システムはただ今ご利用頂けません。\nご利用可能な時間帯は {0} ～ {1} となっております。',
  'message.CE0200': 'ネットワークエラーが発生しました。\n数分待ってから操作してください。\n再度ネットワークエラーが発生する場合は管理者に連絡してください。',
  'message.CW0208': '入力内容が編集されています。{0}しますか。',
  'message.CE0218': '{0}は上限（{1}件）まで登録しています。\n{2}で不要な電柱を削除して、代表電柱を登録してください。',

  // メッセージ(取付) PXXXXX
  'message.PC0058': '通知先メールアドレスが登録されていないため，可否判定回答延期通知書を出力します。\nよろしいですか？',
  'message.PC0059': '{0} 宛に可否判定回答延期通知メールを送付します。\nよろしいですか？',
  'message.PE0081': '申込電柱({0})はすでに契約しているため，新規で取付できません。増架または張替してください。({1}行目)',
  'message.PE0082': '申込電柱({0})は契約していないため，増架または張替できません。新規で取付してください。({1}行目)',
  'message.PE0083': '可否判定回答結果有効期限切れの申込があります。',
  'message.PE0088': '申込電柱({0})を線設備に設定してください。',
  'message.PE0103': '分割申込無の本申込があります。新しい本申込を作成できません。',
  'message.PE0160': '申込電柱の一束化（{0}）と一束化相手先共架事業者数が一致しません。申込電柱の一束化または一束化相手先共架事業者を修正してください。({1}行目)',
  'message.PE0170': '可否判定申込（{0}）は削除できません。',
  'message.PE0172': '前画面で分割申込{0}を選択しているため，申込電柱を{1}できません。',
  'message.PE0173': '同じ一束化相手先を選択しています。',
  'message.PE0176': '{0}は申込ステータスが強度計算中または可否判定中でないため，共架内諾書をダウンロードできません。',
  'message.PE0177': '{0}は可否判定済でないため，可否判定結果回答書をダウンロードできません。',
  'message.PE0221': '一束化対象外の共架種別を選択しているため、一束化できません。({0}行目)',

  // メッセージ(撤去) DXXXXX
  'message.DC0122': '共架種別が変更されました。\n設定済みの撤去電柱及び線設備を削除します。\nよろしいですか。',
  'message.DE0129': '一束化登録してください。({0}行目)',

  // メッセージ(移設) MXXXXX
  'message.MC0089': '以下の共架者へシステム通知メールを送信します。\nよろしいですか？\n{0}',
  'message.ME0090': 'システム通知対象外の事業者が選択されています。',
  'message.ME0180': '作成中以外の移設依頼（{0}）は削除できません。',
  'message.ME0181': '工事日情報（工事日または工事中止）が未入力のため工事日連絡共架者一覧を表示できません。',
  'message.ME0182': '移設依頼基本情報入力データが保存されていないため詳細入力画面へ移動できません。',
  'message.ME0183': '移設依頼基本情報入力の対象データが存在しないため保存できません。',
  'message.ME0184': '選択項目に誤りがあるため追加できません。電柱番号:{0}',
  'message.ME0185': '工事日を変更する場合，変更理由を入力してください。',
  'message.MW0201': '依頼内容を確定し、共架者が参照可能となります。よろしいですか。',
  'message.MI0210': '{0}を{1}しました。\n工事日連絡共架者一覧画面でシステム通知対象を選択し、確定登録してください。',
  'message.MC0211': '入力内容を確定し、共架者で移設工事予定日の入力が可能となります。よろしいですか。',
  'message.MI0212': '入力内容を確定しました。\nシステム通知（メール送信）の操作を行ってください。',
  'message.MC0212': '入力内容を確定します。よろしいですか。',

  // メッセージ(改修) RXXXXX

  // メッセージ(その他) OXXXXX
  'message.OE0194': '申込電柱({0})はすでに登録済のため，訂正申込できません。({1}行目)',
  'message.OW0197': '一部の検索結果を表示しています。結果に表示されない場合は詳細な線路名で検索してください。',

};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Checkbox from '@material-ui/core/Checkbox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {isEnecom, getCodeFromGeneralPurposeMap,
  changeDateFormat, getErrorCode, API_CODE, downloadAttachedFile,
  getResponseResult} from '../../../../../../common/common.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {orderBasicFields} from '../../../../../../common/SFFields';
import {ConfirmWarningMessageArea} from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// Order__cの申込情報の項目
const basicInfoItems = [
  {key: 'OrderNo__c', name: '申込番号', value: '', type: ''},
  {key: 'OrderDate__c', name: '申込年月日', value: '', type: 'date'},
  {key: 'TemporarilySavedDate__c', name: '一時保存日', value: '', type: 'date'},
  {key: 'KyogasyaSendBackReason__c', name: '差戻理由', value: '', type: ''},
];
// Order__cの共架事業者の項目;
const kyogaInfoItems = [
  {key: 'OrderKyogaZgsya__r', name: '申込共架事業者', value: '', type: ''},
  {key: 'KoziTitle__c', name: '工事件名', value: '', type: ''},
];
// Order__cの工事情報の項目
const koziInfoEnecomItems = [
  {key: 'KoziPlaceMainDntNo_SenroName__c', name: '線路名', value: '', type: ''},
  {key: 'Dsp_KoziPlaceMainDntNo__c', name: '代表電柱番号', value: '', type: ''},
  {key: 'KoziPlacePrefecturesName', name: '都道府県', value: '', type: ''},
  {key: 'KoziPlaceMunicipalities__c', name: '市区町村', value: '', type: ''},
  {key: 'KoziPlacePlace__c', name: '場所', value: '', type: ''},
  {key: 'StbTypeName', name: '設備種別', value: '', type: ''},
  {key: 'KyogaTypeName', name: '共架種別', value: '', type: ''},
  {key: 'ZizenNegotiationNo__c', name: '協議番号', value: '', type: ''},
];
const koziInfoItems = [
  {key: 'KoziPlaceMainDntNo_SenroName__c', name: '線路名', value: '', type: ''},
  {key: 'Dsp_KoziPlaceMainDntNo__c', name: '代表電柱番号', value: '', type: ''},
  {key: 'KoziPlacePrefecturesName', name: '都道府県', value: '', type: ''},
  {key: 'KoziPlaceMunicipalities__c', name: '市区町村', value: '', type: ''},
  {key: 'KoziPlacePlace__c', name: '場所', value: '', type: ''},
  {key: 'StbTypeName', name: '設備種別', value: '', type: ''},
  {key: 'KyogaTypeName', name: '共架種別', value: '', type: ''},
  {key: 'ZizenNegotiationNo__c', name: '協議番号', value: '', type: ''},
];
// Order__cのメモ内容の項目
const memoItems = {key: 'MemoContents__c', name: 'メモ内容', value: '', type: ''};

/** この画面で更新を行うフィールド名 */
const updateParams = {
  Id: null,
  OrderStatus__c: null, // 申込ステータス
};

/**
 * 訂正取付申込（申込内容のご確認）
 * 画面ID:1606
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      agree: false,
      rootFiles: [],
      horizonFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      isDisabled: true,
      hasError: true, // 各画面のチェック結果。trueのときはエラーあり。
      isSen: this.props.stbType == '1' ? true : false,
      chargeCompany: '', // 請求先
    };

    for (const row of basicInfoItems) {
      row['value'] = '';
    }
    for (const row of kyogaInfoItems) {
      row['value'] = '';
    }
    for (const row of koziInfoEnecomItems) {
      row['value'] = '';
    }
    for (const row of koziInfoItems) {
      row['value'] = '';
    }
    memoItems['value'] = '';
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {orderId, generalPurposeMap} = this.props;
      this.props.doSetReferenceMode(false);
      const attachedFileTypeRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA08', 3);
      const attachedFileTypeHorizon = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA02', 3);
      const attachedFileTypeSpec = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA01', 3);
      const attachedFileTypeOther = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA12', 3);
      const attachedFileTypePicture = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);
      const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

      // 申込を取得して画面表示
      const orderConditions = {
        'Id': orderId,
        'RecordType.DeveloperName': 'ContractDifferAttach',
        'attachedFileTypes': [
          attachedFileTypeRoot,
          attachedFileTypeHorizon,
          attachedFileTypeSpec,
          attachedFileTypeOther,
          attachedFileTypePicture,
        ],
      };
      const response =
        await this.props.doGetOrderInfo(
            orderConditions, orderBasicFields, true);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['訂正取付申込', '取得'],
          },
        });
        return;
      }

      if (!this.props.order) {
        this.sysErr();
        return;
      }

      // 取得した申込情報、添付ファイルを画面に表示する
      const data = this.props.order;
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          for (let i = 0; i < basicInfoItems.length; i++) {
            if (basicInfoItems[i].key == key) {
              basicInfoItems[i].value =
                basicInfoItems[i].type == 'date' ? changeDateFormat(data[key]) : data[key];
            }
          }
          for (let i = 0; i < kyogaInfoItems.length; i++) {
            if (kyogaInfoItems[i].key == key) {
              if (kyogaInfoItems[i].key == 'OrderKyogaZgsya__r') {
                kyogaInfoItems[i].value =
                  Object.hasOwnProperty.call(data[key], 'Name') ? data[key].Name : '' +
                  Object.hasOwnProperty.call(data[key], 'Account__r') &&
                  Object.hasOwnProperty.call(data[key].Account__r, 'Name') ?
                  data[key].Account__r.Name: '';
              } else {
                kyogaInfoItems[i].value = data[key];
              }
            }
          }
          for (let i = 0; i < koziInfoEnecomItems.length; i++) {
            if (koziInfoEnecomItems[i].key == key) {
              koziInfoEnecomItems[i].value = data[key];
            }
          }
          for (let i = 0; i < koziInfoItems.length; i++) {
            if (koziInfoItems[i].key == key) {
              koziInfoItems[i].value = data[key];
            }
          }
          if (memoItems.key == key) {
            memoItems.value = data[key];
          }
        }
      }

      // 添付ファイルダウンロード設定
      let _rootFiles = [];
      let _horizonFiles = [];
      let _specFiles = [];
      let _otherFiles = [];
      let _pictureFiles = [];
      if (this.props.contentDocuments &&
        this.props.contentDocuments.length > 0) {
        for (const row of this.props.contentDocuments) {
          const anchorAttrs = {};
          anchorAttrs['onClick'] = (event) => {
            this.doDownloadAttachedFile(
                row.VersionData,
                row.FileType,
                row.PathOnClient);
          };
          anchorAttrs['href']='javascript:void(0)';
          let data = {anchor: null, value: ''};
          data.value = row.PathOnClient;
          data.anchor = anchorAttrs;
          if (row.AttachedFileType__c == attachedFileTypeRoot) {
            _rootFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypeHorizon) {
            _horizonFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypeSpec) {
            _specFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypeOther) {
            _otherFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypePicture) {
            _pictureFiles.push(data);
          }
        }
      }

      // 申込確認ダイアログに表示する請求先を設定
      let _chargeCompany = '';
      if (this.props.accountList) {
        const account =
          this.props.accountList.filter(
              (account) => account.BillingDestinationId__c != null);
        _chargeCompany = account && account.length > 0 ? account[0].Name : '';
      }

      // 警告チェック
      let _hasError = false;
      if (this.props.setGmnWarning) {
        for (const key in this.props.setGmnWarning) {
          if (Object.hasOwnProperty.call(this.props.setGmnWarning, key)) {
            if (this.state.isSen) {
              _hasError =
                (this.props.setGmnWarning[1602] != undefined ?
                this.props.setGmnWarning[1602] : false) ||
                (this.props.setGmnWarning[1603] != undefined ?
                  this.props.setGmnWarning[1603] : false) ||
                (this.props.setGmnWarning[1604] != undefined ?
                  this.props.setGmnWarning[1604] : false);
            } else {
              _hasError =
                (this.props.setGmnWarning[1602] != undefined ?
                this.props.setGmnWarning[1602] : false) ||
                (this.props.setGmnWarning[1605] != undefined ?
                  this.props.setGmnWarning[1605] : false);
            }
          }
        }
      }

      this.setState({
        rootFiles: _rootFiles,
        horizonFiles: _horizonFiles,
        specFiles: _specFiles,
        otherFiles: _otherFiles,
        pictureFiles: _pictureFiles,
        isDisabled: this.props.order &&
                    this.props.order.OrderStatus__c ==
                    orderStatusCreate ? false : true, // 作成中のみ編集可
        hasError: _hasError,
        chargeCompany: _chargeCompany,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.props.doClearOrderInfo();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/CorrectAttachOrderList'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 申込確認ダイアログを表示
   */
  handleClickOpen = () => {
    this.setState({open: true});
  };

  /**
   * 申込確認ダイアログを閉じる
   */
  handleClose = () => {
    this.setState({
      open: false,
      agree: false,
    });
  };

  /**
   * 添付ファイルダウンロード
   * @param {*} url
   * @param {*} fileType
   * @param {*} fileName
   */
  doDownloadAttachedFile = async (url, fileType, fileName) => {
    const {userInfo} = this.props;
    try {
      downloadAttachedFile(
          userInfo, url,
          fileType, fileName);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 参照ボタン押下
   * @param {string} ref 遷移先
   */
  doRef = (ref) => {
    try {
      this.props.doSetReferenceMode(true);
      if (ref == '線申込電柱') {
        this.props.history.push({pathname: '/CorrectAttachSenOrderDnt'});
      } else if (ref == '径間') {
        this.props.history.push({pathname: '/CorrectAttachSenStb'});
      } else {
        // 点設備の申込電柱
        this.props.history.push({pathname: '/CorrectAttachTenOrderDnt'});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込確認ダイアログの申込ボタン押下
   */
  doApply = () => {
    try {
      if (!this.props.orderId) {
        return;
      }
      this.handleClose();
      this.updateOrder();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込処理実行
   */
  updateOrder = async () => {
    const {order, generalPurposeMap} = this.props;

    const orderStatusUpdate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus10', 2); // 受付中

    const conditions = {
      Id: order.Id,
      LastModifiedDate: this.props.orderLastModifiedDate,
    };
    updateParams['Id'] = order.Id;
    updateParams['OrderStatus__c'] = orderStatusUpdate;
    updateParams['KyogasyaSendBackReason__c'] = null;

    const body = {
      record: {
        conditions: conditions,
        order: updateParams,
        stage: 'CORRECTORDER_APPLY_UPDATE',
      },
    };
    const response = await this.props.doUpdateCorrectOrder(
        'applyCorrectOrder', body);
    const resResult = getResponseResult(response, ['訂正取付申込', '申込']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: resResult.messages,
        type: 'error',
        action: () => {
          if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
            this.gotoListGmn();
          }
        },
      });
      return;
    }

    this.setState({
      rootFiles: [],
      horizonFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      chargeCompany: '',
    });

    this.props.doShowMessage({
      message: {
        id: 'CI0009',
        values: ['訂正取付申込'],
      },
      action: () => {
        //  申込が成功したら一覧へ遷移
        this.props.history.push(
            {pathname: '/CorrectAttachOrderList'},
            null, this.props.history.option.CLEAR,
        );
      },
    });
  }

  render() {
    const {classes, userInfo, generalPurposeMap, handleSubmit} = this.props;
    // ログインユーザーがエネコムかどうか判定
    let isEnecomUser = false;
    if (generalPurposeMap) {
      isEnecomUser = isEnecom(userInfo.Account.KyogaZgsyaCode__c,
          generalPurposeMap.ENECOMKyogaZgsyaCode);
    }

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1606Apply" item>
          <PositiveButton
            id="btn1606Apply"
            onClick={handleSubmit(this.handleClickOpen)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled || this.state.hasError}
          >
            <span>申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1606Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer props={this.props}
          footerBtn={footerBtn}>
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='申込' />
          }
          <Typography variant="h5" gutterBottom className={classes.title}>
      訂正取付申込
          </Typography>
          <Grid container spacing={1}>
            <Grid item container direction="column" xs={12} sm={3}>
              <Typography variant="h6" gutterBottom className={classes.title}>
          申込情報
              </Typography>
              <Grid container>
                {basicInfoItems.map((basic) => (
                  <React.Fragment key={basic.name}>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{basic.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom className="textWrap">{basic.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={5}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                {'　'}
              </Typography>
              <Grid container>
                {kyogaInfoItems.map((kyogainfo) => (
                  <React.Fragment key={kyogainfo.name}>
                    <Grid item xs={3}>
                      <Typography gutterBottom>{kyogainfo.name}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography gutterBottom className="textWrap">{kyogainfo.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={4}>
              <Typography variant="h6" gutterBottom className={classes.title}>
          工事場所
              </Typography>
              <Grid container>
                {isEnecomUser && this.state.isSen &&
            koziInfoEnecomItems.map((koziInfo) => (
              <React.Fragment key={koziInfo.name}>
                <Grid item xs={3}>
                  <Typography gutterBottom>{koziInfo.name}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography gutterBottom className="textWrap">{koziInfo.value}</Typography>
                </Grid>
              </React.Fragment>
            ))}
                {(!isEnecomUser || !this.state.isSen) &&
                  koziInfoItems.map((koziInfo) => (
                    <React.Fragment key={koziInfo.name}>
                      <Grid item xs={3}>
                        <Typography gutterBottom>{koziInfo.name}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography gutterBottom className="textWrap">{koziInfo.value}</Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={3}>
              <Typography variant="h6" gutterBottom className={classes.title}>
              装柱図
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.pictureFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={3}>
              {this.state.isSen &&
            <React.Fragment>
              <Typography variant="h6" gutterBottom className={classes.title}>
              ルート図
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.rootFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </React.Fragment>
              }
              {!this.state.isSen &&
           <React.Fragment>
             <Typography variant="h6" gutterBottom className={classes.title}>
             平面図
             </Typography>
             <Grid container>
               <Grid item xs={6}>
                 {this.state.horizonFiles.map((data) => (
                   <>
                     <a key={data.value} {...data.anchor}>{data.value}</a>
                     <br></br>
                   </>
                 ))}
               </Grid>
             </Grid>
           </React.Fragment>
              }
            </Grid>
            <Grid item container direction="column" xs={12} sm={3}>
              <Typography variant="h6" gutterBottom className={classes.title}>
          共架設備仕様書
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.specFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={3}>
              <Typography variant="h6" gutterBottom className={classes.title}>
          その他資料
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.otherFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={8}>
              <Typography variant="h6" gutterBottom className={classes.title}>
          メモ内容
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className="textWrap" style={{whiteSpace: 'pre-wrap'}} component="div" gutterBottom>
                    {memoItems.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.state.isSen &&
        <Grid
          container
          spacing={1}>
          <Grid key="key1606ContractDntSen" item>
            <Typography variant="h5" gutterBottom className={classes.title}>
            線設備（申込電柱）
            </Typography>
            <PositiveButton
              id="id1606ContractDntSen"
              onClick={() => {
                this.doRef('線申込電柱');
              }}
              variant="contained"
              size="large"
              startIcon={<ListAltIcon/>}
            >
              <span>参照</span>
            </PositiveButton>
          </Grid>
          <Grid key="key1606Sen" item>
            <Typography variant="h5" gutterBottom className={classes.title}>
            線設備（径間）
            </Typography>
            <PositiveButton
              id="id1606Sen"
              onClick={() => {
                this.doRef('径間');
              }}
              variant="contained"
              size="large"
              startIcon={<ListAltIcon/>}
            >
              <span>参照</span>
            </PositiveButton>
          </Grid>
        </Grid>
          }
          {!this.state.isSen &&
      <Grid
        container
        spacing={1}>
        <Grid key="key1606ContractDntTen" item>
          <Typography variant="h5" gutterBottom className={classes.title}>
          点設備（申込電柱）
          </Typography>
          <PositiveButton
            id="id1606ContractDntTen"
            onClick={() => {
              this.doRef('点申込電柱');
            }}
            variant="contained"
            size="large"
            startIcon={<ListAltIcon/>}
          >
            <span>参照</span>
          </PositiveButton>
        </Grid>
      </Grid>
          }
        </MainContainer>

        <React.Fragment>
          <Dialog onClose={this.handleClose}
            aria-labelledby="dialogTitle1606"
            open={this.state.open}>
            <DialogContent dividers>
              <Typography variant="body1" gutterBottom>
              申込いただいた内容により，費用精算が発生する場合があります。
              </Typography>
              <Typography variant="body1" gutterBottom>
          費用等その他連絡事項については別途ご連絡し，ご確認いただきます。
              </Typography>
              <Typography variant="body1" gutterBottom>
          よろしければ以下をチェックして申込ボタンを押してください。
              </Typography>
              <Typography style={{whiteSpace: 'pre-wrap'}} component="div" gutterBottom>
                <Box fontWeight="fontWeightBold">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='chb1606Confirm'
                        checked={this.state.agree ? true : false}
                        onChange={(e) =>
                          this.setState({agree: e.target.checked})}
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                      />
                    }
                    label={<span style={{fontWeight: 'bold'}}>
                  上記内容を確認したので申込みします。
                    </span>}
                  />
                </Box>
              </Typography>
              <Typography component="div" gutterBottom>
                <Box fontWeight="fontWeightBold">
                  {'　請求先：'}{this.state.chargeCompany}
                </Box>
              </Typography>
            </DialogContent>
            <DialogActions>
              <PositiveButton
                id="id1606DialogApply"
                onClick={handleSubmit(this.doApply)}
                variant="contained"
                size="large"
                disabled={this.state.agree === true? false: true}
                startIcon={<AddIcon/>}
              >
                <span>申込</span>
              </PositiveButton>
              <NegativeButton
                id="id1606DialogCancel"
                onClick={this.handleClose}
                variant="contained"
                startIcon={<KeyboardReturnIcon />}
                size="large"
              >
                <span>閉じる</span>
              </NegativeButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  setGmnWarning: PropTypes.object,
  rootFiles: PropTypes.array,
  horizonFiles: PropTypes.array,
  specFiles: PropTypes.array,
  otherFiles: PropTypes.array,
  accountList: PropTypes.array,
  contentDocuments: PropTypes.array,
  stbType: PropTypes.object,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderInfo: PropTypes.func,
  doSetReferenceMode: PropTypes.func,
  doUpdateCorrectOrder: PropTypes.func,
  doClearOrderInfo: PropTypes.func,
  contractDntList: PropTypes.array,
  orderLastModifiedDate: PropTypes.string,
};

const mapStateToProps = (state) => {
  const initValues = {

  };
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'order') ?
      state.attachorder.orderInfo.order : null,
    contentDocuments: state.attachorder.orderInfo &&
        Object.hasOwnProperty.call(state.attachorder.orderInfo, 'contentDocuments') ?
          state.attachorder.orderInfo.contentDocuments : null,
    accountList: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'billingAccounts') ?
        state.attachorder.orderInfo.billingAccounts : null,
    setGmnWarning: state.common.setGmnWarning,
    stbType: state.common.stbType,
    initialValues: initValues,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderInfo: attachorderOperations.doGetOrderInfo,
  doSetReferenceMode: commonOperations.doSetReferenceMode,
  doUpdateCorrectOrder: attachorderOperations.doUpdateCorrectOrder,
  doClearOrderInfo: attachorderOperations.doClearOrderInfo,
};

const FORM_NAME = 'CorrectAttachConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';

import * as wjCore from '@grapecity/wijmo';
import {DataMap} from '@grapecity/wijmo.grid';

import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import {reduxForm, getFormValues, Field} from 'redux-form';

import * as validateRule from '../../../../../validateRule.js';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import PaperPart from '../../../../../atoms/PaperPart.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';

import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MenuItem from '@material-ui/core/MenuItem';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import {
  API_CODE, getErrorCode, createTyohyoCsv,
  isEnecom, changeDateFormat, getNendo,
  getDataFromGeneralPurposeMap, getEndOfMonth,
} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {billingDetailOperations} from '../../../../../../reducks/billingDetail/index.js';
import {billingDetailFields} from '../../../../../../common/SFFields';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

/** 請求タイミングを変更"できる"「請求区分」 */
const INCLUDE_BILLING_CATEGORY_FOR_BILLING_TIMING = [
  // 可否判定費用
  '01',

  // 個別請求費用
  '99',
];

/** 請求先を変更"できない"「請求区分」 */
const EXCLUDE_BILLING_CATEGORY_FOR_BILLING_DESTINATION = [
  // 期中共架料
  '11',

  // 定期共架料
  '12',
];

/**
 * 請求明細検索
 * 画面ID:1502
 */
class BillingDetailSearchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailBtnDisabled: true,
      csvBtnDisabled: true,
      isModified: false,
    };
    this.isEnecomUser = false;
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
    this.billingDestinationDataMap = null;
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // ログインユーザーがエネコムかどうか判定
      this.isEnecomUser = isEnecom(this.props.userInfo.KyogaZgsyaCode__c,
          this.props.generalPurposeMap.ENECOMKyogaZgsyaCode);

      // 請求タイミングのDataMapを生成してメンバ変数として保持する
      this.billingTimingDataMap = this.createBillingTimingDataMap();

      // FlexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1502', []);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 請求タイミングのDataMap生成
   * @return {DataMap} 請求タイミングのDataMap
   */
  createBillingTimingDataMap = () => {
    const {generalPurposeMap} = this.props;
    const items = generalPurposeMap['BillingTiming'];

    const billingTimings = [];
    for (const item of items) {
      billingTimings.push({
        id: item.Code__c,
        name: item.Name,
      });
    }

    return new DataMap(billingTimings, 'id', 'name');
  }

  /**
   * 請求先一覧の取得とDataMapの作成
   * @return {Promise<DataMap>}
   */
  createBillingDestinationDataMap = async () => {
    // 請求先一覧取得
    const {
      doClearAccountMasterList,
      doGetAccountMasterList,
    } = this.props;

    const response = await doGetAccountMasterList(
        {
          'RecordType.DeveloperName': 'BillingDestination',
        },
        ['Id', 'BillingDestinationId__c', 'Name'],
        {'BillingDestinationId__c': 1},
    );

    // API実行結果がエラーの場合は終了
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      throw new Error();
    }

    const dataList = response.data.body.data;

    // 取得したデータからDataMap生成用配列を生成
    const accounts = [];
    for (const data of dataList) {
      accounts.push({
        id: data.Id,
        name: `${data.BillingDestinationId__c} ${data.Name}`,
      });
    }

    // ストアからdoGetAccountMasterListの取得データを削除
    await doClearAccountMasterList();

    // DataMapを生成して返却
    return new DataMap(accounts, 'id', 'name');
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('billingDetailList') &&
      this.props.billingDetailList ? this.props.billingDetailList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * stateからオプション配列を生成
   * @param {string} stateitem 対象state
   * @return {option} オプション配列
   */
  createStateOption = (stateitem) => {
    try {
      let options = null;
      if (stateitem in this.state == false) {
        return options;
      }
      options = this.state[stateitem].map((item, index) => {
        return <option key={item.Id} value={item.Id}>
          {item.Name}
        </option>;
      });
      return options;
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 検索ボタン押下時処理
   */
  searchBillingDetail = async () => {
    try {
      // 初回検索時、請求先のDataMapを生成してメンバ変数として保持する
      if (this.billingDestinationDataMap == null) {
        this.billingDestinationDataMap =
          await this.createBillingDestinationDataMap();
      }

      // 画面入力されたデータのみ検索対象として取得
      const wkData = this.props.values ?
        JSON.parse(JSON.stringify(this.props.values), (key, value) => {
          if (key == 'Year__c') return Number(value);
          else return value;
        }) : {};
      if (wkData.Year__c == 0) {
        delete wkData.Year__c;
      }

      // 請求発生年月（自～至）を請求発生日に編集
      if (wkData.BillingHaseiDate__c_From) {
        wkData.BillingHaseiDate__c_From = wkData.BillingHaseiDate__c_From + '-01';
      }
      if (wkData.BillingHaseiDate__c_To) {
        const _date = getEndOfMonth(wkData.BillingHaseiDate__c_To);
        if (_date) {
          wkData.BillingHaseiDate__c_To = _date;
        } else {
          wkData.BillingHaseiDate__c_To = wkData.BillingHaseiDate__c_To + '-01';
        }
      }

      // 請求ステータスが未指定の場合
      if (!wkData.BillingStatus__c) {
        wkData.BillingStatus__c = ['1', '2'];
      }

      // 請求区分を設定
      this._setBillingCategory(wkData);

      // 請求明細一覧取得
      const response = await this.props.doGetBillingDetailList(
          wkData, billingDetailFields);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }
      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1502', 'Id', true);
        this.gridRef.current.setItems(this.props.billingDetailList);
      }

      // 検索結果0件の場合、CSVボタン非活性 & エラーダイアログ表示
      if (!this.props.hasOwnProperty('billingDetailList') ||
        !this.props.billingDetailList ||
        this.props.billingDetailList.length == 0) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
        this.setState({csvBtnDisabled: true});
        // 検索結果1件以上の場合、各ボタン活性
      } else {
        this.setState({csvBtnDisabled: false});
        // 最大件数チェック
        this.listMaxCountCheck();
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 検索条件：請求区分の設定。
   * 検索条件に請求区分が未設定の場合、プルダウンに表示された項目全てを検索対象とするよう設定する
   * @param {object} wkData 検索条件ワークデータ
   */
  _setBillingCategory = (wkData) => {
    // 請求区分が設定済の場合、何もしない
    if (wkData.BillingCategory__c) {
      return;
    }

    // 請求区分が未設定の場合、現在のプルダウンに存在する項目を指定
    const itemList = [];
    for (const item of this.props.billingCategory) {
      itemList.push(item.Code__c);
    }

    wkData.BillingCategory__c = itemList;
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      // 未選択／単一選択チェック
      let bRet = false;
      if (this.gridRef && this.gridRef.current) {
        if (this.gridRef.current.selectedItemCount() == 0) {
          bRet = true;
        } else if (this.gridRef.current.selectedItemCount() == 1) {
          bRet = false;
        } else {
          bRet = true;
        }
      }
      this.setState({detailBtnDisabled: bRet});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 更新ボタン押下時
   */
  doUpdate = async () => {
    const {
      doShowMessage,
    } = this.props;

    doShowMessage({
      message: {
        id: 'CC0006',
        values: ['請求明細', '変更'],
      },
      action: () => {
        this._execUpdate();
      },
    });
  }

  /**
   * 更新処理の実行
   */
  _execUpdate = async () => {
    const {
      doUpdateBillingDetail,
      doShowMessage,
    } = this.props;

    // 更新用データ生成
    const updateData = this.createUpdateData();

    // 請求明細 更新APIを実行
    const result = await doUpdateBillingDetail(updateData);
    const errorCode = result.data.body.errorCode;

    let messageParam = null;

    switch (errorCode) {
      // 成功
      case API_CODE.SUCCESS:
        messageParam = {
          message: {
            id: 'CI0009',
            values: ['請求先の変更'],
          },
          action: async () => {
            // 再描画
            await this.searchBillingDetail();

            // 変更状態をクリア
            this.setState({isModified: false});
          },
        };
        break;

      // 最終更新日時不一致
      case API_CODE.ERROR_LAST_MODIFIED_DATE:
        messageParam = {
          message: 'CE0048',
          action: async () => {
            // 再描画
            await this.searchBillingDetail();
          },
        };
        break;

      // 権限エラー
      case API_CODE.ERROR_PERMISSION_DENIED:
        messageParam = {
          message: {
            id: 'CE0056',
            values: ['請求先変更'],
          },
        };
        break;

      // その他のエラー時はシステムエラー
      default:
        break;
    }

    // 指定した結果以外はシステムエラー
    if (messageParam == null) {
      this.sysErr();
      return;
    }

    // 実行結果に応じたメッセージを表示
    doShowMessage(messageParam);
  }

  /**
   * 更新情報の生成
   * @return {object[]} 更新情報
   */
  createUpdateData = () => {
    const {billingDetailList} = this.props;

    // 未取得時は空を返却
    if (!billingDetailList) {
      return [];
    }

    // 値変更確認用のマップ生成
    const initMap = {};

    for (const initData of billingDetailList) {
      initMap[initData.Id] = initData;
    }

    // 更新情報リストの生成
    const updateDataList = [];
    const currentItems = this.gridRef.current.getSourceCollection();

    for (const item of currentItems) {
      const initData = initMap[item.Id];

      // 値の変更があるかどうかの判定
      let hasUpdate = false;

      // 更新情報のベースを生成
      const updateData = {
        Id: item.Id,
        LastModifiedDate: initData.LastModifiedDate,
      };

      // 「請求タイミング」が変更されていれば、更新用の値を設定
      hasUpdate |= this._setUpdateData(updateData, 'BillingTiming__c', initData, item);

      // 「請求先」が変更されていれば、更新用の値を設定
      hasUpdate |= this._setUpdateData(updateData, 'BillingDestination__c', initData, item);

      // 値が変更されていれば、更新情報リストにプッシュ
      if (hasUpdate) {
        updateDataList.push(updateData);
      }
    }

    return updateDataList;
  }

  /**
   * 更新情報の設定
   * 初期入力と異なる値が入力されている場合は更新情報に値を設定する
   * @param {object} updateData 更新情報
   * @param {string} key フィールド名
   * @param {object} init 初期入力情報
   * @param {object} current 現在の入力情報
   * @return {boolean} 変更があった場合true、なかった場合false
   */
  _setUpdateData(updateData, key, init, current) {
    // 値に更新がない場合はfalseを返却
    const currentData = current[key];
    if (init[key] == currentData) {
      return false;
    }

    // 値に更新がある場合はデータを設定
    const data = {};
    data[key] = currentData;

    Object.assign(updateData, data);
    return true;
  }

  /**
   * 詳細表示ボタン押下時
   */
  doDetailDisp = async () => {
    try {
      if (!this.gridRef ||
        !this.gridRef.current) {
        return;
      }

      // 「共架料金区分」が'定期共架料'以外の場合、「1511 定期共架料内訳」画面に遷移しない
      if (this.gridRef.current.selectedItemCount() != 0 &&
        this.gridRef.current.selectedItems[0].dataItem.hasOwnProperty('BillingCategory')) {
        if (this.gridRef.current.selectedItems[0].dataItem.BillingCategory != '定期共架料') {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['「請求区分」が"定期共架料"の行'],
            },
          });
          return;
        } else {
          // 画面引継情報設定
          const items = this.gridRef.current.selectedItem();
          this.props.doSetBillingDetailId(items[0].dataItem.Id);

          // 「1511 定期共架料内訳」画面に遷移
          this.props.history.push({pathname: '/Search/Billing/BillingDetailSearch/Confirm'});
        }
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * CSVファイル作成ボタン押下時
   */
  doTyohyoOutput = async () => {
    // 請求先を変更して未保存の場合は確認メッセージを表示
    if (this.state.isModified) {
      this.props.doShowMessage({
        message: 'CW0013',
        action: async () => {
          await this._doTyohyoOutput();
        },
      });
    } else {
      await this._doTyohyoOutput();
    }
  }

  /**
   * CSVファイル作成実行
   */
  _doTyohyoOutput = async () => {
    try {
      // 画面入力されたデータのみ検索対象として取得
      const wkData = this.props.values ?
        JSON.parse(JSON.stringify(this.props.values), (key, value) => {
          if (key == 'Year__c') return Number(value);
          else return value;
        }) : {};
      if (wkData.Year__c == 0) {
        delete wkData.Year__c;
      }

      // 請求発生年月（自～至）を請求発生日に編集
      if (wkData.BillingHaseiDate__c_From) {
        wkData.BillingHaseiDate__c_From = wkData.BillingHaseiDate__c_From + '-01';
      }
      if (wkData.BillingHaseiDate__c_To) {
        const _date = getEndOfMonth(wkData.BillingHaseiDate__c_To);
        if (_date) {
          wkData.BillingHaseiDate__c_To = _date;
        } else {
          wkData.BillingHaseiDate__c_To = wkData.BillingHaseiDate__c_To + '-01';
        }
      }

      // 請求ステータスが未指定の場合
      if (!wkData.BillingStatus__c) {
        wkData.BillingStatus__c = ['1', '2'];
      }

      // 請求区分を設定
      this._setBillingCategory(wkData);

      // ログインユーザーがエネコム以外の場合
      if (!this.isEnecomUser) {
        // 帳票(CSV)の作成（C0022_請求一覧表）
        const ret = await createTyohyoCsv('billingList', wkData, '請求一覧表.csv');
        if (!ret) {
          this.sysErr();
          return;
        }
        // ログインユーザーがエネコムの場合
      } else {
        // 帳票(CSV)の作成（C0008_エネコム共架料請求明細）
        const ret = await createTyohyoCsv('enecomKyogaPriceBillingDetail', wkData, 'エネコム共架料請求明細.csv');
        if (!ret) {
          this.sysErr();
          return;
        }
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearBillingDetailList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1502', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    let edt = s.activeEditor;
    const currentColumn = edt.filter.column.binding;
    if (currentColumn === 'BillingHaseiDate__c' ||
      currentColumn === 'TargetPeriodFrom__c' ||
      currentColumn === 'TargetPeriodTo__c') {
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      lb.itemFormatter = (index) => {
        const ret = changeDateFormat(lb.collectionView.items[index].value, 'YYYY/MM');
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * FlexGrid更新時の処理
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  refreshedFunction = (s, e) => {
    // 一覧のプルダウンを表示中に画面遷移を行うと本メソッドが呼ばれる事があり、
    // その場合gridRef.currentがnullでcreateUpdateData()でエラーとなるため回避
    if (!this.gridRef.current) {
      return;
    }

    const updateData = this.createUpdateData();
    const isModified = updateData.length > 0;

    if (this.state.isModified != isModified) {
      this.setState({isModified: isModified});
    }
  }

  /**
   * onFormtItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    const col = e.getColumn();
    const cell = e.cell;

    // ヘッダの場合は何もしない
    if (cell.classList.contains('wj-header')) {
      return;
    }

    // 現在行のデータを取得
    const dataItem = s.rows[e.row].dataItem;

    // 請求区分(BillingCategory__c)、請求ステータス(BillingStatus__c)を取得
    const billingCategory = dataItem.BillingCategory__c;
    const billingStatus = dataItem.BillingStatus__c;

    // ユーザが「管理者」の時の「請求タイミングコード」(BillingTiming__c)がバインドされていれば管理者
    if (col.binding === 'BillingTiming__c' && s.rows[e.row]) {
      // 請求区分が「可否判定費用」「個別請求費用」の以外の場合、
      // または、請求ステータスが「請求待ち(1)」以外の場合は編集不可にする
      if (!INCLUDE_BILLING_CATEGORY_FOR_BILLING_TIMING.includes(billingCategory) || billingStatus != '1') {
        wjCore.addClass(cell, 'wj-state-disabled');
      }
    }

    // ユーザが「管理者」の時の「請求先」(BillingDestination__cがバインドされていれば管理者)
    if (col.binding === 'BillingDestination__c' && s.rows[e.row]) {
      // 請求区分が「期中共架料(11)」「定期共架料(12)」の場合、
      // または、請求ステータスが「請求待ち(1)」以外の場合は編集不可にする
      if (EXCLUDE_BILLING_CATEGORY_FOR_BILLING_DESTINATION.includes(billingCategory) || billingStatus != '1') {
        wjCore.addClass(cell, 'wj-state-disabled');
      }
    }
  }

  /**
   * 一覧表出力columns
   * @return {CustomFlexGrid} 一覧表
   */
  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      isReadOnly: false,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkStateUpdate,
      filterChanging: this.filterChangingHandler,
      refreshedFunction: this.refreshedFunction,
      formatItemFunction: this.onFormatItemHandler,
    };

    const {userInfo} = this.props;

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="BillingCostConfirmDate__c" header="請求費用/確定年月日" dataType="Date" width={190} isReadOnly={true}>
          <FlexGridCellTemplate
            cellType='Cell'
            template={(context) => {
              return changeDateFormat(
                  context.item.
                      BillingCostConfirmDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="BillingDetailNo__c" header="請求明細番号" dataType="String" isReadOnly={true} />
        <FlexGridColumnGroup binding="OrderNo__c" header="申込番号" dataType="String" isReadOnly={true} />
        <FlexGridColumnGroup binding="BillingCategory" header="請求区分" dataType="String" isReadOnly={true} />
        <FlexGridColumnGroup binding="KyogaType" header="共架種別" dataType="String" isReadOnly={true} />

        {/* 詳細表示: ユーザレベルが「一般」の場合はラベルで表示 */}
        {userInfo.UserLevel__c == '1' &&
          <FlexGridColumnGroup
            binding="BillingTiming"
            header="請求タイミング"
            dataType="String"
            width={170}
            isReadOnly={true}
          />
        }

        {/* 詳細表示: ユーザレベルが「管理者」の場合はプルダウンで表示 */}
        {(userInfo.UserLevel__c != '1' && this.billingTimingDataMap) &&
          <FlexGridColumnGroup
            binding="BillingTiming__c"
            header="請求タイミング"
            dataMap={this.billingTimingDataMap}
            width={170}
            isReadOnly={false}
          />
        }

        <FlexGridColumnGroup binding="Year__c" header="年度" dataType="String" format="d" width={90} isReadOnly={true} />
        <FlexGridColumnGroup binding="BillingHaseiDate__c" header="請求発生年月" dataType="Date" isReadOnly={true}>
          <FlexGridCellTemplate
            cellType='Cell'
            template={(context) => {
              return changeDateFormat(
                  context.item.BillingHaseiDate__c, 'YYYY/MM');
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="BillingStatus" header="請求ステータス" dataType="String" width={170} isReadOnly={true} />
        <FlexGridColumnGroup binding="KyogaZgsya__r.Account__r.Name" header="共架事業者" dataType="String" isReadOnly={true} />

        {/* 詳細表示: ユーザレベルが「一般」の場合はラベルで表示 */}
        {userInfo.UserLevel__c == '1' &&
          <FlexGridColumnGroup
            binding="BillingDestination__r.Name"
            header="請求先"
            dataType="String"
            width={190}
            isReadOnly={true}
          />
        }

        {/* 詳細表示: ユーザレベルが「管理者」の場合はプルダウンで表示 */}
        {(userInfo.UserLevel__c != '1' && this.billingDestinationDataMap) &&
          <FlexGridColumnGroup
            binding="BillingDestination__c"
            header="請求先"
            dataMap={this.billingDestinationDataMap}
            width={230}
            isReadOnly={false}
          />
        }

        <FlexGridColumnGroup binding="IskkCategory" header="電線一束化事業者数" dataType="String" width={190} isReadOnly={true} />
        <FlexGridColumnGroup binding="UnitPrice__c" header="単価" dataType="Number" isReadOnly={true} />
        <FlexGridColumnGroup binding="TransferHonsu__c" header="異動本数" dataType="Number" isReadOnly={true} />
        <FlexGridColumnGroup binding="FreeOfChargeTransferHonsu__c" header="無償異動本数" dataType="Number" isReadOnly={true} />
        <FlexGridColumnGroup binding="BillingAmount__c" header="請求金額" dataType="Number" isReadOnly={true} />
        <FlexGridColumnGroup binding="ConsumptionTax__c" header="消費税" dataType="Number" isReadOnly={true} />
        <FlexGridColumnGroup binding="BilReason__c" header="請求理由" dataType="String" isReadOnly={true} />
        <FlexGridColumnGroup binding="TargetPeriodFrom__c" header="対象期間/FROM" dataType="Date" isReadOnly={true}>
          <FlexGridCellTemplate
            cellType='Cell'
            template={(context) => {
              return changeDateFormat(
                  context.item.TargetPeriodFrom__c, 'YYYY/MM');
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="TargetPeriodTo__c" header="対象期間/TO" dataType="Date" isReadOnly={true}>
          <FlexGridCellTemplate
            cellType='Cell'
            template={(context) => {
              return changeDateFormat(
                  context.item.TargetPeriodTo__c, 'YYYY/MM');
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes, userInfo, generalPurposeMap, reset,
      handleSubmit, billingDetailList, billingCategory} = this.props;

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        {/* 詳細表示: ユーザレベルが「一般」の場合非表示 */}
        {userInfo.UserLevel__c != '1' &&
          <>
            <Grid key="btn1" item>
              <PositiveButton
                startIcon={<EditIcon />}
                onClick={() => {
                  this.doUpdate();
                }}
                variant="contained"
                size="large"
                disabled={!this.state.isModified}
              >
                <span>更新</span>
              </PositiveButton>

            </Grid>

            <Grid key="btn2" item>
              <PositiveButton
                id="btnDetailView"
                onClick={() => {
                  this.doDetailDisp();
                }}
                variant="contained"
                size="large"
                disabled={this.state.detailBtnDisabled}
              >
                <span>詳細表示</span>
              </PositiveButton>

            </Grid>
          </>
        }

        {/* CSVファイル作成 */}
        <Grid key="btn3" item>
          <PositiveButton
            id="btnEquipCsv"
            onClick={() => {
              this.doTyohyoOutput();
            }}
            variant="contained"
            size="large"
            disabled={this.state.csvBtnDisabled}
          >
            <span>CSVファイル作成</span>
          </PositiveButton>
        </Grid>

        {/* 戻る */}
        <Grid key="btn4" item>
          <BackButton
            isModified={this.state.isModified}
            showWarning={true}
            useDirty={false}
            props={this.props}
          />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1-1" item xs={12} sm={3}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="Year__c"
                    name="Year__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="年度"
                    fullWidth
                    type="text"
                    required={false}
                    validate={[
                      validateRule.number,
                      validateRule.maxLength(4),
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1-2" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="BillingCostConfirmDate__c_From"
                    name="BillingCostConfirmDate__c_From"
                    accessibilitylabel="BillingCostConfirmDate__c_From"
                    label="請求費用／確定年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="BillingCostConfirmDate__c_To"
                    name="BillingCostConfirmDate__c_To"
                    accessibilitylabel="BillingCostConfirmDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key2-1" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="BillingHaseiDate__c_From"
                    name="BillingHaseiDate__c_From"
                    accessibilitylabel="BillingHaseiDate__c_From"
                    label="請求発生年月"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="BillingHaseiDate__c_To"
                    name="BillingHaseiDate__c_To"
                    accessibilitylabel="BillingHaseiDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key2-2" item xs={12} sm={3}>
                <Field
                  id="BillingCategory__c"
                  name="BillingCategory__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="請求区分"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {billingCategory &&
                    createSearchList(billingCategory, 'Code__c', 'Name')}
                </Field>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key3-1" item xs={12} sm={3}>
                <Field
                  id="BillingStatus__c"
                  name="BillingStatus__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="請求ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.BillingStatus, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key3-2" item xs={12} sm={3}>
                <Field
                  id="BillingTiming__c"
                  name="BillingTiming__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="請求タイミング"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.BillingTiming, 'Code__c', 'Name')}
                </Field>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key4-1" item xs={12} sm={3}>
                <Field
                  id="KyogaZgsyaCode__c"
                  name="KyogaZgsyaCode__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="共架事業者"
                  fullWidth
                  required={false}
                  disabled={true}
                  validate={[
                  ]}
                >
                  <MenuItem value={userInfo.KyogaZgsyaCode__c}>
                    {userInfo.Account.Name}</MenuItem>
                </Field>
              </Grid>
              <Grid key="key-btn" item xs={12} sm={6}>
                <NegativeButton
                  id="Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="id1502Search"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchBillingDetail)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>

          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="gridTable" item xs={12}>
              {this.renderTable(billingDetailList)}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

BillingDetailSearchList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doClearAccountMasterList: PropTypes.func,
  doGetAccountMasterList: PropTypes.func,
  doGetAllGeneralPurposeMap: PropTypes.func.isRequired,
  accountMasterList: PropTypes.array,
  doGetBillingDetailList: PropTypes.func,
  billingCategory: PropTypes.array,
  billingDetailList: PropTypes.array,
  doShowMessage: PropTypes.func,
  doClearBillingDetailList: PropTypes.func,
  doSetBillingDetailId: PropTypes.func.isRequired,
  doUpdateBillingDetail: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  accountMasterList: state.common.accountMasterList,
  userInfo: state.auth.userInfo,
  billingCategory: createBillingCategory(state),
  billingDetailList: state.billingDetail.billingDetailList,
  // 表示時のデフォルト設定
  initialValues: {
    Year__c: getNendo(),
    KyogaZgsyaCode__c: state.auth.userInfo.KyogaZgsyaCode__c,
  },
});

/**
 * 請求区分の項目設定
 * ユーザレベルが「一般」の場合、請求区分一覧から「定期共架料」を除外する。
 *
 * @param {object} state 保持情報
 * @return {array} 請求区分の一覧
 */
function createBillingCategory(state) {
  const userInfo = state.auth.userInfo;
  const billingCategory = state.common.generalPurposeMap.BillingCategory;

  // ユーザレベルが「一般」以外の場合、汎用マスタの請求区分をそのまま返却
  if (userInfo.UserLevel__c != '1') {
    return billingCategory;
  }

  // ユーザレベルが「一般」の場合、「定期共架料(12)」を除外した一覧を生成し返却
  return billingCategory.filter((item) => {
    return item.Code__c != '12';
  });
};

const mapDispatchToProps = {
  doClearAccountMasterList: commonOperations.doClearAccountMasterList,
  doGetAccountMasterList: commonOperations.doGetAccountMasterList,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doGetBillingDetailList: billingDetailOperations.doGetBillingDetailList,
  doShowMessage: commonOperations.doShowMessage,
  doClearBillingDetailList: billingDetailOperations.doClearBillingDetailList,
  doSetBillingDetailId: billingDetailOperations.doSetBillingDetailId,
  doUpdateBillingDetail: billingDetailOperations.doUpdateBillingDetail,
};

const FORM_NAME = 'BillingDetailSearchList';

BillingDetailSearchList = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(BillingDetailSearchList));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(BillingDetailSearchList),
);

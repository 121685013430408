import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import {renderTextField} from '../../../../../atoms/CustomPart.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

// 申込取得項目
const appFields = [
  'Id',
  'IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c',
  'IsetuIraiDesignDocument__r.IsetuIraiNo__c',
  'OrderKyogaZgsya__r.Account__r.Name',
  'OrderKyogaZgsya__r.Name',
  'MaintenanceKoziCompany__r.Name',
];
// 申込に紐づく契約電柱の取得項目
const subquery = [{
  'fields': [
    'Id', 'LastModifiedDate',
    'NWZgsyo__r.Name',
    'NWZgsyo__c',
    'SenroName__c',
    'SenroCode__c',
    'Dsp_DntNo__c',
    'DntNo__c',
    'toLabel(DntCategory__c)DntCategoryName',
    'toLabel(DrSideKoziEachDntContents__c)DrSideKoziEachDntContents',
    'EachDntKoziContentHosoku__c',
    'EachDntMessage__c',
  ],
  'table': 'ContractDnts__r',
}];


/**
 * 移設依頼対象電柱一覧 テンプレート。
 * 画面ID:1405
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    const {orderId} = this.props;
    const appCondition = {
      'Id': orderId,
    };
    await this.props.doGetOrderSubAllList(appCondition, appFields,
        null, subquery);
    if (!this.props.hasOwnProperty('orderSubAllList') ||
      !this.props.orderSubAllList) {
      this.sysErr();
      return;
    }

    // グリッド初期化
    if (!this.gridRef.current) {
      return;
    }
    const cv = createCollectionView(this.props.contractDnts,
        false, {}, null);
    this.gridRef.current.getGrid().itemsSource = cv;
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  renderTable() {
    const props = {
      rowHeaderType: 'none',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '600px', minHeight: '60px'},
      isReadOnly: true,
      useStore: false,
    };

    return (
      <>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="SenroName__c" header="線路名" dataType="String" width={185}/>
          <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" width={200}/>
          <FlexGridColumnGroup binding="DntCategoryName" header="電柱区分" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="DrSideKoziEachDntContents" header="電力側電柱別工事内容" dataType="String" width={200}/>
          <FlexGridColumnGroup binding="EachDntKoziContentHosoku__c" header="電力側電柱別工事内容／補足事項" dataType="String" width={200}/>
          <FlexGridColumnGroup binding="EachDntMessage__c" header="電柱別連絡事項" dataType="String" width={200}/>
        </CustomFlexGrid>
      </>
    );
  }

  render() {
    const {classes, values} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼発信年月日"
                  fullWidth
                  type={values.IsetuIraiDesignDocument__r && values.IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c ? (this.type='date'):(this.type='text')}
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} sm={6} md={3}>
                <Field
                  name="OrderKyogaZgsya__r.Account__r.Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="共架者"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="OrderKyogaZgsya__r.Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="共架事業所"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={6} md={3}>
                <Field
                  name="MaintenanceKoziCompany__r.Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼送付先"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

            </Grid>
          </PaperPart>

          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid key="key1405table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

          <div style={{marginBottom: '20px'}} />

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  doShowMessage: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  doGetOrderSubAllList: PropTypes.func,
  orderSubAllList: PropTypes.array,
  contractDnts: PropTypes.array,
};

const mapStateToProps = (state) => {
  const initValues = {
    IraiMotoCompanySyozoku__c: state.auth.userInfo.Account.Name,
  };
  const orderData = state.attachorder.orderSubAllList ?
    state.attachorder.orderSubAllList[0] : undefined;
  const contractDntsData = state.attachorder.orderSubAllList ?
    state.attachorder.orderSubAllList[0].ContractDnts__r ?
    state.attachorder.orderSubAllList[0].ContractDnts__r.records :
    undefined : undefined;
  if (orderData) {
    for (const fieldName of appFields) {
      const name = fieldName.split('.')[0];
      if (!initValues[name]) initValues[name] = orderData[name];
    }
  }
  console.dir(contractDntsData);
  return {
    initialValues: initValues,
    contractDnts: contractDntsData,
    orderId: state.attachorder.orderId,
    orderSubAllList: state.attachorder.orderSubAllList,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderSubAllList: attachorderOperations.doGetOrderSubAllList,
};

const FORM_NAME = 'IsetuIraiTargetDntList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

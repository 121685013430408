import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  downloadAttachedFile, changeDateFormat, execApexRestApi,
  parseStatusOrderToRepairirai, getToDate, getErrorCode, API_CODE,
  getResponseResult,
} from '../../../../../common/common.js';
import BackButton from '../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../atoms/Buttons/PositiveButton.js';
import SimpleTable from '../../../../molecules/SimpleTable.js';
import {attachorderOperations} from '../../../../../reducks/attachorder/index.js';

import {isNullOrWhiteSpace} from '@grapecity/wijmo';
import {commonOperations} from '../../../../../reducks/common/index.js';

const styles = (theme) => ({
});
// 申込テーブル検索時の検索対象のフィールド名
const appFields = [
  'Id',
  'OrderNo__c', // 改修依頼番号
  'NWZgsyo__r.name', // 事業所
  'toLabel(StbType__c)', // 設備種別
  'toLabel(KyogaType__c)', // 共架種別
  'RepairIraiDate__c', // 改修依頼日
  'OrderStatus__c', // 改修依頼ステータス
  'toLabel(RepairGaisanConsentStatus__c)', // 見積結果承諾状況
  'RepairOrderNo__c', // 改修元申込番号
  'KyogaKoziScheduleInputDeadline__c', // 改修工事予定日入力期限
  'RepairKoziDeadline__c', // 改修完了希望日
  'KyogaKoziScheduleDate__c', // 改修工事予定日
  'KoziTitle__c', // 工事件名
  'KoziPlaceMainDntNo_SenroName__c', // 工事場所_線路名
  'Dsp_KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'toLabel(KoziPlacePrefectures__c)', // 都道府県
  'KoziPlaceMunicipalities__c', // 市区町村
  'KoziPlacePlace__c', // 場所
  'RepairIraiMemo__c', // 改修依頼メモ
  'RepairIraiContents__c', // 改修依頼内容
  'RepairIraiCheckDate__c', // 改修依頼確認日
  'KyogasyaSendBackReason__c', // 共架者差戻し理由
  'LastModifiedDate', // 最終更新日時

  // 連絡先
  'RepairIraiTanto__r.CompanyName', // 会社名
  'RepairIraiTanto__r.Department', // 部署名
  'RepairIraiTanto__r.Name', // 担当者名
  'RepairIraiTanto__r.Phone', // 電話番号
  'RepairIraiTanto__r.Email', // メールアドレス
];

/** サブクエリ */
const subquery = [
  {
    'fields': [
      'Dsp_SenroName__c', // 線路名
      'Dsp_DntNo__c', // 電柱番号
      'InspectionResult__c', // 竣工検査結果
      'SyunkoDate__c', // 竣工日
      'DntCategory__c', 'KyogaCategory__c', 'StbKobetuId__c',
      'DntNo__c', 'SenroName__c', 'DntNoManualInput__c',
    ],
    'conditions': {},
    'table': 'ContractDnts__r',
  },
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['A12'],
    },
    'table': 'ContentDocumentLinks',
  },
];

/**
 * 改修依頼内容確認受理
 * 画面ID:1472
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      contactInfo: [],
      checkDone: false,
    };
    this.attachedFiles = [];
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // 申込テーブル検索時の検索条件
      const appConditions = {
        Id: this.props.orderId,
      };
      // 申込テーブルからデータ取得
      const response = await this.props.doGetOrderTypeList(
          appConditions, appFields, null, 'getRepairOrderList', subquery, null);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS || this.props.orderList.length != 1) {
        this.props.doShowMessage({
          message: 'CS0001',
          action: () => {
            this.props.history.push('/login');
          },
        });
        return;
      }

      // Mapに申込テーブル検索結果を保存
      const appData = this.props.orderList[0];
      const wkDataMap = new Map();
      for (const key of Object.keys(appData)) {
        wkDataMap.set(key, appData[key]);
        // 添付ファイルのハイパーリンク設定
        if (key === 'ContentDocumentLinks' && appData[key]) {
          for (const record of appData[key].records) {
            const items = record.ContentDocument.LatestPublishedVersion;
            this.attachedFiles.push({
              'AttachedFileType_Type': items.AttachedFileType__c,
              'AttachedFileType_Name': items.PathOnClient,
              'AttachedFileType_Url': items.VersionData,
            });
          };
        }
      }
      const data = [
        {key: 'OrderNo__c', name: '改修依頼番号', value: ''},
        {key: 'KoziTitle__c', name: '工事件名', value: ''},
        {key: 'NWZgsyo__r', name: '事業所', value: ''},
        {key: 'StbType__c', name: '設備種別', value: ''},
        {key: 'KyogaType__c', name: '共架種別', value: ''},
        {key: 'RepairIraiDate__c', name: '改修依頼日', value: ''},
        {key: 'OrderStatus__c', name: '改修依頼ステータス', value: ''},
        {key: 'RepairGaisanConsentStatus__c', name: '見積結果承諾状況', value: ''},
        {key: 'KoziPlaceMainDntNo_SenroName__c', name: '線路名', value: ''},
        {key: 'Dsp_KoziPlaceMainDntNo__c', name: '代表電柱番号', value: ''},
        {key: 'KoziPlacePrefectures__c', name: '都道府県', value: ''},
        {key: 'KoziPlaceMunicipalities__c', name: '市区町村', value: ''},
        {key: 'KoziPlacePlace__c', name: '場所', value: ''},
        {key: 'RepairOrderNo__c', name: '改修元申込番号', value: ''},
        {key: 'ContractDnts__r.SyunkoDate', name: '竣工日', value: ''},
        {key: 'KyogaKoziScheduleInputDeadline__c', name: '改修工事予定日入力期限', value: ''},
        {key: 'RepairKoziDeadline__c', name: '改修完了希望日', value: ''},
        {key: 'KyogaKoziScheduleDate__c', name: '改修工事予定日', value: ''},
        {key: 'RepairIraiMemo__c', name: '改修依頼メモ', value: ''},
        {key: 'RepairIraiContents__c', name: '改修依頼内容', value: ''},
        {key: 'OtherDocuments', name: 'その他資料', items: []},
        {key: 'KyogasyaSendBackReason__c', name: '差戻理由', value: ''},
      ];
      const contactInfo = [
        {key: 'Department', name: '会社部署名', value: ''},
        {key: 'Name', name: '担当者名', value: ''},
        {key: 'Phone', name: '電話番号', value: ''},
        {key: 'Email', name: 'メールアドレス', value: ''},
      ];
      // 取得データを画面表示
      for (const item of data) {
        if (wkDataMap.has(item.key)) {
          item.value = wkDataMap.get(item.key);
        }

        // 事業所
        if (item.key == 'NWZgsyo__r' && wkDataMap.get(item.key)) {
          item.value = wkDataMap.get(item.key).Name;
          continue;
        }

        // 改修依頼日 or 改修工事予定日 or 改修工事予定日入力期限 or 改修完了工事予定日
        if (['RepairIraiDate__c', 'KyogaKoziScheduleDate__c', 'RepairKoziDeadline__c', 'KyogaKoziScheduleInputDeadline__c'].includes(item.key) && wkDataMap.get(item.key)) {
          item.value = changeDateFormat(item.value);
        }

        // 改修依頼ステータス
        if (item.key == 'OrderStatus__c' && wkDataMap.get(item.key)) {
          const index =
            parseStatusOrderToRepairirai(wkDataMap.get(item.key));
          item.value = (index != null ?
            this.props.generalPurposeMap.RepairIraiStatus[index].Name : '');
          continue;
        }

        // 改修元申込番号
        if (item.key == 'RepairOrderNo__c') {
          item.value = wkDataMap.get(item.key);
          continue;
        }

        // その他資料
        if (item.key == 'OtherDocuments') {
          const fileLen = this.attachedFiles.length;
          if (fileLen > 0) {
            for (let i = 0; i < fileLen; i++) {
              const file = this.attachedFiles[i];
              item.items.push({
                value: file.AttachedFileType_Name,
                onClick: this.doDownloadAttachedFile(i),
                link: '#',
              });
            }
          }
          continue;
        }

        // 契約電柱
        if (item.key == 'ContractDnts__r.Dsp_SenroName') {
          item.value = wkDataMap.get('Dsp_SenroName__c');
        } else if (item.key == 'ContractDnts__r.Dsp_DntNo') {
          item.value = wkDataMap.get('Dsp_DntNo__c');
        } else if (item.key == 'ContractDnts__r.SyunkoDate') {
          item.value = changeDateFormat(wkDataMap.get('SyunkoDate__c'));
        }
      }

      // 共架担当データを画面表示
      if (wkDataMap.get('RepairIraiTanto__r')) {
        for (const contactitem of contactInfo) {
          if (contactitem.key == 'Department') {
            contactitem.value =
              (!wkDataMap.get('RepairIraiTanto__r').CompanyName ?
                '' : wkDataMap.get('RepairIraiTanto__r').CompanyName) +
              (!wkDataMap.get('RepairIraiTanto__r').Department ?
                '' : wkDataMap.get('RepairIraiTanto__r').Department);
          } else if (contactitem.key == 'Name') {
            contactitem.value = wkDataMap.get('RepairIraiTanto__r').Name;
          } else if (contactitem.key == 'Phone') {
            contactitem.value = wkDataMap.get('RepairIraiTanto__r').Phone;
          } else if (contactitem.key == 'Email') {
            contactitem.value = wkDataMap.get('RepairIraiTanto__r').Email;
          }
        }
      }

      this.setState({
        data: data,
        contactInfo: contactInfo,
        checkDone: !isNullOrWhiteSpace(wkDataMap.get('RepairIraiCheckDate__c')),

      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      // 画面表示内容クリア
      for (const item of this.state.data) {
        item.value = '';
      }
      for (const data of this.state.contactInfo) {
        data.value = '';
      }
      this.props.doClearOrderList();
    } catch (error) {
    }
  }

  /**
   * 添付ファイルダウンロード
   * @param {number} index 添付ファイル判定キー
   * @return {Promise<void>}
   */
  doDownloadAttachedFile = (index) => async () => {
    try {
      const {userInfo} = this.props;

      if (this.attachedFiles) {
        const attachedFile = this.attachedFiles[index];

        downloadAttachedFile(
            userInfo,
            attachedFile.AttachedFileType_Url,
            attachedFile.AttachedFileType_Type,
            attachedFile.AttachedFileType_Name);
      }
      return;
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['添付ファイル', 'ダウンロード'],
        },
      });
    }
  }

  /**
   * 改修予定入力ボタン押下時
   */
  doRepairIraiAccept = async () => {
    try {
      const {orderList, orderId} = this.props;
      if (isNullOrWhiteSpace(orderList[0].RepairIraiCheckDate__c)) {
        const conditions = {
          'Id': orderId,
          'LastModifiedDate': orderList[0].LastModifiedDate,
        };
        const param = {
          'Id': orderId,
          'RepairIraiCheckDate__c': getToDate(),
        };

        const body = {
          'record': [{
            'conditions': conditions,
            'order': param,
          }],
          'stage': 'REPAIR_IRAI_CHECK_DATE_UPDATE',
        };
        // 申込テーブルの更新
        const result = await execApexRestApi(this.props.userInfo,
            'ApiRepairRequest/repairCheckDateUpdate', body);
        const resResult = getResponseResult(result, ['申込', '更新']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        }
      }
      this.props.doSetOrderId(this.props.orderId);
      this.props.history.push({pathname: '/RepairIrai/ConfirmIraiContent/Input'});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  render() {
    const {handleSubmit} = this.props;

    const wkSimpleStyle = {
      tableData:
      {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
      },
    };

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn1" item>
          <PositiveButton
            onClick={handleSubmit(this.doRepairIraiAccept)}
            variant="contained"
            size="large"
          >
            <span>改修予定入力</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <BackButton props={this.props} />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h6">設備改修依頼内容</Typography>
              <SimpleTable styles={wkSimpleStyle} dataList={this.state.data} />

              <Typography variant="h6">連絡先</Typography>
              <SimpleTable dataList={this.state.contactInfo} />
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  userInfo: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  orderId: PropTypes.string,
  history: PropTypes.object,
  doSetOrderId: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderTypeList: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
  handleSubmit: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    generalPurposeMap: state.common.generalPurposeMap,
    orderList: state.attachorder.orderList,
  };
};

const mapDispatchToProps = {
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doShowMessage: commonOperations.doShowMessage,
};

const FORM_NAME = 'repairRequestConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);


import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import * as wjCore from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {MultiRowCellGroup, MultiRowCell, MultiRowCellTemplate} from '@grapecity/wijmo.react.grid.multirow';

import {getCodeFromGeneralPurposeMap, getComboName,
  getErrorCode, API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {senStbOperations} from '../../../../../../reducks/senStb';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomMultiRow, {createCollectionView} from '../../../../../molecules/CustomMultiRow';
import {orderSenStbFields} from '../../../../../../common/SFFields';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SerialNumber__c: null, // 通し番号
  SzbtItiId1__c: null, // 支持物位置ID1
  SzbtItiId2__c: null, // 支持物位置ID2
  StbKobetuId1__c: null, // 設備個別ID1
  StbKobetuId2__c: null, // 設備個別ID2
  SenroCode1__c: null, // 線路コード1
  SenroCode2__c: null, // 線路コード2
  SenroName1__c: null, // 線路名1
  SenroName2__c: null, // 線路名2
  SenroNameKana1__c: null, // 線路名カナ1
  SenroNameKana2__c: null, // 線路名カナ2
  DntNo1__c: null, // 電柱番号1
  DntNo2__c: null, // 電柱番号2
  DntCategory1__c: null, // 電柱区分1
  DntCategory2__c: null, // 電柱区分2
  KyogaCableSnsyu__c: null, // 共架ケーブル線種
  KyogaCableGaikei__c: null, // 共架ケーブル外径(mm)
  ClosestDrStbName__c: null, // 最接近電力設備_名称
  ClosestDrStbRikakOrder__c: null, // 最接電力設備_離隔_申込
  KyogaCategory1__c: null, // 共架区分1
  KyogaCategory2__c: null, // 共架区分2
  Order__c: null, // 参照(本申込)
  KyogaZgsya__c: null, // 参照(申込共架事業者)
  ContractDnt1__c: null, // 参照(契約電柱始点側)
  ContractDnt2__c: null, // 参照(契約電柱終点側)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  DntNo1ManualInput__c: null, // 電柱番号1_自由入力
  DntNo2ManualInput__c: null, // 電柱番号2_自由入力
  K6KansnNo1__c: null, // 6kV幹線No1
  K6KansnNo2__c: null, // 6kV幹線No2
  K6BunkOne1__c: null, // 6kV分岐1_1
  K6BunkOne2__c: null, // 6kV分岐1_2
  K6BunkTwo1__c: null, // 6kV分岐2_1
  K6BunkTwo2__c: null, // 6kV分岐2_2
  K6BunkThree1__c: null, // 6kV分岐3_1
  K6BunkThree2__c: null, // 6kV分岐3_2
  K22SzbtNo1__c: null, // 22kV支持物番号1
  K22SzbtNo2__c: null, // 22kV支持物番号2
  K22GatiCategory1__c: null, // 22kV架地区分
  K22GatiCategory2__c: null, // 22kV架地区分
  K6K22HeigaCategory1__c: null, // 6kV22kV併架区分
  K6K22HeigaCategory2__c: null, // 6kV22kV併架区分
  OtherTokkiZiko__c: null, // その他特記事項
};

/**
 * 本申込（径間：線設備）
 * 画面ID:1013
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      warningMessages: [],
    };
    this.gridRef = React.createRef();
    this.onFormatItemHandler = this.onFormatItemHandler.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // フィルターを表示する列
    this.filterCols = ['SerialNumber__c', 'Dsp_SenroName1__c', 'SenroCode1__c', 'Dsp_DntNo1__c',
      'DntCategory1__c', 'Dsp_SenroName2__c', 'SenroCode2__c', 'Dsp_DntNo2__c', 'DntCategory2__c',
      'OrderNo__c', 'KyogaCableSnsyu__c', 'KyogaCableGaikei__c', 'ClosestDrStbName__c',
      'ClosestDrStbRikakOrder__c'];
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearSenStbList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1013', 'Id');
    }
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;

    const orderStatusCreate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    // 径間を取得する
    const conditions = {
      'Id': orderId, // 本申込のId
      'RecordType.DeveloperName': 'MainOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response =
      await this.props.doGetFormalSenStbList(
          conditions, orderSenStbFields, appSortParams,
          this.props.referenceMode);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['線設備', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    this.gridRef.current.setScreenState('1013');
    const cv = createCollectionView(this.props.senStbList,
        false, {}, null);
    // 保存したら一覧の先頭にフォーカスを設定する
    this.gridRef.current.setCellRange(0, 0);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    // 入力チェックエラー
    let isWarn = false;
    if (_warningMessages.length > 0) {
      isWarn = true;
    }

    // 作成中かつ分割なしのとき、全選択する
    if (isInit &&
      this.props.order &&
      this.props.order.OrderStatus__c == orderStatusCreate &&
      this.props.order.SplitOrderUmu__c == '0') {
      const grid = this.gridRef.current.getGrid();
      if (grid != null && grid.rows.length > 0) {
        for (const row of grid.rows) {
          row.isSelected = true;
        }
        this.gridRef.current.onItemChecked();
      }
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                    orderStatusCreate ? false : true) ||
                    this.props.referenceMode, // 作成中のみ編集可
      isModified: false,
      warningMessages: _warningMessages,
    });

    await this.props.doSetGmnWarning('1013', isWarn);
    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'Id') ?
        data.order.Id : null;
      lastModifiedDate =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
        data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusCreate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    if (this.props.order &&
      this.props.order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    // 径間0件チェック
    const hasKeikan =
      Object.hasOwnProperty.call(this.props.order, 'OrderSenStbs__r') &&
      this.props.order.OrderSenStbs__r &&
      this.props.order.OrderSenStbs__r.totalSize > 0 ? true : false;
    if (!hasKeikan) {
      warningMessages.push({
        id: 'CE0159',
        values: ['線設備'],
      });
    }
  }

  /**
   * 線設備の登録/更新処理
   */
  insertOrUpdate = async () => {
    const {order, orderModifiedCondition} = this.props;

    // 登録済の線設備を全て削除し、現在選択している線設備を登録する
    const saveSenStbList = [];
    const selectedSenStbList = this.gridRef.current.selectedItem();
    // 登録
    let serialNumber = 1;
    for (const row of selectedSenStbList) {
      const item = row.dataItem;
      const insertData = {};
      for (const key in addParams) {
        if (Object.hasOwnProperty.call(item, key)) {
          insertData[key] = item[key];
        }
      }
      const formalDnts =
        order.ContractDnts__r && order.ContractDnts__r.totalSize > 0 ?
        order.ContractDnts__r.records : [];
      const startDnt = formalDnts.filter((data) =>
        item.ContractDnt1__c != null &&
        data.ProprietyJudgeContractDnt__c == item.ContractDnt1__c);
      const endDnt = formalDnts.filter((data) =>
        item.ContractDnt2__c != null &&
        data.ProprietyJudgeContractDnt__c == item.ContractDnt2__c);
      insertData['Order__c'] = order.Id; // 本申込と線設備紐づけ
      insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
      insertData['ContractDnt1__c'] = startDnt.length > 0 ? startDnt[0].Id : null; // 本申込の契約電柱と線設備紐づけ
      insertData['ContractDnt2__c'] = endDnt.length > 0 ? endDnt[0].Id : null; // 本申込の契約電柱と線設備紐づけ
      insertData['SerialNumber__c'] = serialNumber++;
      saveSenStbList.push(insertData);
    }
    // 削除 本申込の線設備
    const deleteConditions = [];
    const formalList =
          order.OrderSenStbs__r && order.OrderSenStbs__r.totalSize > 0 ?
          order.OrderSenStbs__r.records : [];
    for (const formalData of formalList) {
      if (formalData.Id) {
        deleteConditions.push({
          Id: formalData.Id,
          LastModifiedDate: formalData.LastModifiedDate,
        });
      }
    }
    // 本申込一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate:
        orderModifiedCondition != null &&
        order.Id == orderModifiedCondition.Id ?
        orderModifiedCondition.LastModifiedDate : null,
    };
    const updateOrder = {
      Id: order.Id,
    };

    const response = await this.props.doSaveFormalSenStbs(
        [], deleteConditions, saveSenStbList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(response, ['線設備', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      if (!this.gridRef.current) {
        return;
      }

      // 径間0件チェック
      const count = this.gridRef.current.selectedItemCount();
      if (count == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['線設備'],
          },
        });
        return;
      }

      // 選択状態保存
      this.gridRef.current.saveScreenState('1013', 'Id');

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/FormalOrderList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  };

  /**
   * 確認ボタン押下時
   */
  doMoveConfirm = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
        this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }
      await this.doCheckInput();
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1013')) {
        _hasError = this.props.setGmnWarning['1013'];
      }
    }

    // 入力チェックエラー
    if (_hasError) {
      const noKeikan =
          this.state.warningMessages.filter((data) => data.id == 'CE0159');
      if (noKeikan.length > 0) {
        // 線設備0件は次画面に遷移しない
        this.props.doShowMessage({message: noKeikan});
        return;
      }

      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/FormalOrderConfirm'});
  }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    if ((s.rowHeaders === e.panel &&
         e.panel.cellType == wjgGrid.CellType.RowHeader) ||
         (s.topLeftCells === e.panel &&
         e.panel.cellType == wjgGrid.CellType.TopLeft)) {
      if (this.state.isDisabled) {
        if (e.cell != null && e.cell.children != null &&
          e.cell.children.length > 0) {
          wjCore.addClass(e.cell.children[0], 'wj-state-disabled');
        }
      } else {
        if (e.cell != null && e.cell.children != null &&
          e.cell.children.length > 0) {
          wjCore.removeClass(e.cell.children[0], 'wj-state-disabled');
        }
      }
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    let edt = s.activeEditor;
    const currentColumn = edt.filter.column.binding;
    if (currentColumn === 'DntCategory1__c' ||
             currentColumn === 'DntCategory2__c' ||
             currentColumn === 'KyogaCableSnsyu__c'||
             currentColumn === 'ClosestDrStbName__c') {
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wjCore.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分（一）,電柱区分（二）
      if (currentColumn === 'KyogaCableSnsyu__c') {
        categoryName = 'CableSnsyu'; // ケーブル線種
      } else if (currentColumn === 'ClosestDrStbName__c') {
        categoryName = 'KiknClosestDrStbName'; // 最接近電力設備との離隔/設備名称
      }
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      filters: this.filterCols,
      headersVisibility: 'All',
      allowSorting: 'None',
      counterOn: false,
      AddDeleteOn: false,
      frozenColumns: 4,
      style: {height: '600px'},
      isReadOnly: true,
      useStore: false,
      validateEdits: false,
      doShowMessage: this.props.doShowMessage,
      formatItemFunction: this.onFormatItemHandler,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <React.Fragment>
        <CustomMultiRow ref={this.gridRef} {...props}>
          <MultiRowCellGroup header="" align="center">
            <MultiRowCell binding='SerialNumber__c' header='通し番号' width={50}/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="ケーブルルート" align="center" colspan={3}>
            <MultiRowCell binding='Dsp_SenroName1__c' header='線路名（一）' dataType="String" width={125}/>
            <MultiRowCell binding='Dsp_DntNo1__c' header='電柱番号（一）' dataType="String" width={140}/>
            <MultiRowCell binding='DntCategory1__c' header='電柱区分（一）' dataType="String" width={140}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory1__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='Dsp_SenroName2__c' header='線路名（二）' dataType="String"/>
            <MultiRowCell binding='Dsp_DntNo2__c' header='電柱番号（二）' dataType="String"/>
            <MultiRowCell binding='DntCategory2__c' header='電柱区分（二）' dataType="String">
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory2__c);
              }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="" align="center">
            <MultiRowCell binding='FormalOrderNo' header='本申込番号'/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="共架設備" align="center" colspan={2}>
            <MultiRowCell binding='KyogaCableSnsyu__c' header='ケーブル線種' dataType="String" width={250}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'CableSnsyu', ctx.item.KyogaCableSnsyu__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='KyogaCableGaikei__c' header='ケーブル外径\n（mm）' dataType="Number" format="n1" width={120}/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="最接近電力設備との離隔" align="center" colspan={2}>
            <MultiRowCell binding='ClosestDrStbName__c' header='設備名称' dataType="String" width={250}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'KiknClosestDrStbName', ctx.item.ClosestDrStbName__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='ClosestDrStbRikakOrder__c' header='離隔距離\n（m）' dataType="Number" format="n2" width={120}/>
          </MultiRowCellGroup>
        </CustomMultiRow>
      </React.Fragment>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1013Confirm" item>
          <PositiveButton
            id="btn1013Confirm"
            onClick={handleSubmit(this.doMoveConfirm)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode}
          >
            <span>確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1013Save" item>
          <PositiveButton
            id="btn1013Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1013Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer props={this.props}
          footerBtn={footerBtn}>
          <WarningMessageArea messages={this.state.warningMessages}/>
          <span>
          分割申込の有無の選択は「本申込画面」の工事場所欄で選択してください。
            <br/>
            〇分割申込「無」を選択した場合、この画面ですべての電柱に &#10004; してください。
            <br/>
            〇分割申込「有」を選択した場合、この画面で申込みを希望する電柱を &#10004; してください。
            （残りの電柱は「本申込画面」から同じ申込番号を選択し、別途申込むことができます。）
          </span>
          <br/>
          <Grid container
            direction="column"
            alignItems="flex-start">
            <Grid key="key1013Table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  senStbList: PropTypes.array,
  referenceMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetFormalSenStbList: PropTypes.func,
  doSaveFormalSenStbs: PropTypes.func,
  doClearSenStbList: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  const initValues = {
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId, // 前画面で設定した本申込のId
    order: state.senStb.senStbList &&
          Object.hasOwnProperty.call(state.senStb.senStbList, 'order') ?
              state.senStb.senStbList.order : null,
    senStbList: state.senStb.senStbList &&
                  Object.hasOwnProperty.call(state.senStb.senStbList, 'senStbList') ?
                  state.senStb.senStbList.senStbList : null,
    referenceMode: state.common.referenceMode == true ? true : false,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetFormalSenStbList: senStbOperations.doGetFormalSenStbList,
  doSaveFormalSenStbs: senStbOperations.doSaveFormalSenStbs,
  doClearSenStbList: senStbOperations.doClearSenStbList,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'FormalSenStb';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

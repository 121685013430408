import {
  setSetListAction,
  addItemAction,
  removeItemAction,
  changeItemAction,
  moveItemAction,
  setSetListMultiRowAction,
  addItemMultiRowAction,
  removeItemMultiRowAction,
  changeItemMultiRowAction,
  moveItemMultiRowAction,
  saveSelectedItemsAction,
  saveScrollPositionAction,
} from './actions';

/**
 * 一覧にデータを設定
 *
 * @param {array} items 初期値
 * @return {void} 設定結果
 */
const doSetListAction = (items) => {
  console.log('初期データ設定...');

  return async (dispatch) => {
    dispatch(setSetListAction(items));
  };
};

/**
 * 行追加
 *
 * @param {object} item 追加データ
 * @return {void} 設定結果
 */
const doAddItemAction = (item) => {
  console.log('行追加...');

  return async (dispatch) => {
    dispatch(addItemAction(item));
  };
};

/**
 * 行削除
 *
 * @param {number} index 削除する行番号
 * @return {void} 設定結果
 */
const doRemoveItemAction = (index) => {
  console.log('行削除...');

  return async (dispatch) => {
    dispatch(removeItemAction(index));
  };
};

/**
 * 行変更
 *
 * @param {object} item 変更データ
 * @param {number} index 行番号
 * @return {void} 設定結果
 */
const doChangeItemAction = (item, index) => {
  console.log('行変更...');

  return async (dispatch) => {
    dispatch(changeItemAction(item, index));
  };
};

/**
 * 行移動
 *
 * @param {object} item 移動データ
 * @param {number} dragIndex drag時の行番号
 * @param {number} dropIndex drop時の行番号
 * @return {void} 設定結果
 */
const doMoveItemAction = (item, dragIndex, dropIndex) => {
  console.log('行移動...');

  return async (dispatch) => {
    dispatch(moveItemAction(item, dragIndex, dropIndex));
  };
};

/**
 * 一覧にデータを設定（MultiRow）
 *
 * @param {array} items 初期値
 * @return {void} 設定結果
 */
const doSetListMultiRowAction = (items) => {
  console.log('初期データ設定...');

  return async (dispatch) => {
    dispatch(setSetListMultiRowAction(items));
  };
};

/**
 * 行追加（MultiRow）
 *
 * @param {object} item 追加データ
 * @return {void} 設定結果
 */
const doAddItemMultiRowAction = (item) => {
  console.log('行追加...');

  return async (dispatch) => {
    dispatch(addItemMultiRowAction(item));
  };
};

/**
 * 行削除（MultiRow）
 *
 * @param {number} index 削除する行番号
 * @return {void} 設定結果
 */
const doRemoveItemMultiRowAction = (index) => {
  console.log('行削除...');

  return async (dispatch) => {
    dispatch(removeItemMultiRowAction(index));
  };
};

/**
 * 行変更（MultiRow）
 *
 * @param {object} item 変更データ
 * @param {number} index 行番号
 * @return {void} 設定結果
 */
const doChangeItemMultiRowAction = (item, index) => {
  console.log('行変更...');

  return async (dispatch) => {
    dispatch(changeItemMultiRowAction(item, index));
  };
};

/**
 * 行移動（MultiRow）
 *
 * @param {object} item 移動データ
 * @param {number} index 移動データの現在の行番号
 * @param {number} dest 1:上に移動、-1:下に移動
 * @return {void} 設定結果
 */
const doMoveItemMultiRowAction = (item, index, dest) => {
  console.log('行移動...');

  return async (dispatch) => {
    dispatch(moveItemMultiRowAction(item, index, dest));
  };
};

/**
 * 選択データを保存
 *
 * @param {string} screenId 画面Id
 * @param {array} compareKey
 *                  一覧のデータと保存した選択データを比較して同じ行か判別するためのキー
 * @param {array} selectedItems 選択データ
 * @return {void}
 */
const doSaveSelectedItems = (
    screenId, compareKey, selectedItems) => {
  return async (dispatch) => {
    dispatch(saveSelectedItemsAction(
        screenId, compareKey, selectedItems));
  };
};

/**
 * グリッドのスクロール位置を保存
 *
 * @param {string} screenId 画面Id
 * @param {array} scrollPosition スクロール位置
 * @return {void}
 */
const doSaveScrollPosition = (screenId, scrollPosition) => {
  return async (dispatch) => {
    dispatch(saveScrollPositionAction(screenId, scrollPosition));
  };
};

export default {
  doSetListAction,
  doAddItemAction,
  doRemoveItemAction,
  doChangeItemAction,
  doMoveItemAction,
  doSetListMultiRowAction,
  doAddItemMultiRowAction,
  doRemoveItemMultiRowAction,
  doChangeItemMultiRowAction,
  doMoveItemMultiRowAction,
  doSaveSelectedItems,
  doSaveScrollPosition,
};

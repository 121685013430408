import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import {changeDateFormat, changeDateFormatter, getAddDate, getToDate,
  execApexRestApi, API_CODE, getResponseResult,
  getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import {renderCheckbox, renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createList, createSearchList, createSearchListEx} from '../../../../../../common/ComboParts.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#fff',
    height: '100%',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});
/** 移設依頼ステータス */
const isetuIraiStatusList = [
  {'Code__c': 1, 'Name': '予定連絡'},
  {'Code__c': 2, 'Name': '工事日確定・未竣工'},
  {'Code__c': 3, 'Name': '工事日変更・未竣工'},
  {'Code__c': 4, 'Name': '工事日未入力'},
  {'Code__c': 5, 'Name': '工事中止'},
  {'Code__c': 6, 'Name': '竣工済'},
];

const isetuIraiStatusKoziAcceptList = [
  {'Code__c': 2, 'Name': '工事日確定・未竣工'},
  {'Code__c': 3, 'Name': '工事日変更・未竣工'},
  {'Code__c': 4, 'Name': '工事日未入力'},
];

const appFields = [
  'IsetuIraiDesignDocument__r.IraimotoCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.DesignCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c',
  'IsetuIraiDesignDocument__r.IsetuIraiNo__c',
  'OrderNo__c', 'toLabel(KoziPlacePrefectures__c)',
  'KoziPlaceMunicipalities__c', 'KoziPlacePlace__c',
  'KyogaKoziScheduleDate__c',
  'IsetuIraiContentsAcceptedDate__c',
  'KoziPlaceMainDntNo__c',
  'IsetuIraiDesignDocument__r.DntNo__c',
  'IsetuIraiDesignDocument__r.KoziOverview__c',
  'IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfDesign__c',
  'IsetuIraiDesignDocument__r.KoziScheduleSeason__c',
  'IsetuIraiDesignDocument__r.KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocument__r.DesignTantoName__c',
  'IsetuIraiDesignDocument__r.IsetuIraiDesignDocumentNo__c',
  'IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfContact__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDate__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c',
  'IsetuIraiDesignDocument__r.KoziCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.KoziTantoName__c',
  'IsetuIraiDesignDocument__r.KoziStop__c',
  // 画面表示以外
  'Id', 'OrderStatus__c', 'LastModifiedDate',
  'IsetuIraiDesignDocument__c', 'IsetuIraiDesignDocument__r.IsetuIraiStatus__c',
  'IsetuIraiDesignDocument__r.LastModifiedDate',
];
// 申込、移設依頼設計書テーブル検索時のソート条件
const appSortParams = {
  'IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c': 1,
};

const subquery = [{
  'fields': ['Id'],
  'conditions': {
    'RecordType.DeveloperName': 'IsetuIrai',
  },
  'table': 'ContractDnts__r',
}];

const addFields = {
  IsetuIraiStatus: '工事日確定・未竣工',
};

const oneYearAgo = getAddDate(-12);

/**
 * 移設依頼予定連絡一覧 テンプレート。
 * 画面ID:1411
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
      isKoziAccept: false,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    const isIsetuIraiKoziAccept = location.href.includes('IsetuIraiKoziAccept');

    // FlexGridに初期値設定
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1411', []);
    }
    this.setState({isKoziAccept: isIsetuIraiKoziAccept});
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearOrderList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1411', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('searchResult') &&
      this.props.searchResult ? this.props.searchResult.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * 検索処理
   */
  doSearch = async () => {
    try {
      const {values} = this.props;
      this.setState({buttonDisabled: true});

      const conditions = this.createConditions(values);
      const result = await this.props.doGetOrderTypeList(conditions, appFields, appSortParams, 'getKoziCompSub', subquery, addFields);
      const errCode = result ? result.data ? result.data.body ?
            result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return;
      }
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
          this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }
      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1411', 'Id', true);
        this.gridRef.current.setInitItems('1411', this.props.searchResult, false);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索条件生成
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
  */
  createConditions = (values) => {
    const conditions = {
      'RecordType.DeveloperName': 'IsetuIrai',
      'IsetuIraiTarget__c': '1',
      'IsetuIraiDesignDocument__r.RecordType.DeveloperName': 'IsetuIraiDesignDocument',
      'IsetuIraiDesignDocument__r.Id_NE': null,
    };
    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }

      switch (key) {
        case 'IsetuIraiStatus':
          // 移設依頼ステータスの値をDB検索用に置き換える
          switch (values[key]) {
            case 1:// 予定連絡＝ステータスが工事日調整中
              conditions['OrderStatus__c'] = '50';
              break;
            case 2:// 工事日確定・未竣工＝ステータスが竣工待ち/工事日確定済
              conditions['OrderStatus__c'] = '51';
              conditions['KyogaKoziScheduleDate__c_NE'] = null;
              conditions['IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c_EQ'] = null;
              break;
            case 3:// 工事日変更・未竣工＝ステータスが竣工待ち/工事日確定済かつ電力移線工事日（変更）に値があるもの
              conditions['OrderStatus__c'] = '51';
              conditions['IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c_NE'] = null;
              conditions['KyogaKoziScheduleDate__c_NE'] = null;
              break;
            case 4:// 工事日未入力＝ステータスが竣工待ち/工事日確定済みかつ共架工事予定日が空白のもの
              conditions['OrderStatus__c'] = '51';
              conditions['KyogaKoziScheduleDate__c_EQ'] = null;
              break;
            case 5:// 工事中止
              conditions['IsetuIraiDesignDocument__r.KoziStop__c'] = true;
              break;
            case 6:// 竣工済＝ステータスが完結
              conditions['OrderStatus__c'] = '90';
              break;
            default:
          }
          break;
        case 'DesignCompanySyozoku__c':
          conditions['IsetuIraiDesignDocument__r.DesignCompanySyozoku__c'] = values[key];
          break;
        case 'NWZgsyo__c':
          conditions['NWZgsyo__r.Code__c'] = values[key];
          break;
        case 'IsetuIraiNo__c':
          conditions['IsetuIraiDesignDocument__r.IsetuIraiNo__c'] = values[key];
          break;
        case 'DesignTantoName__c':
          conditions['IsetuIraiDesignDocument__r.DesignTantoName__c'] = values[key];
          break;
        case 'KoziOverview__c':
          conditions['IsetuIraiDesignDocument__r.KoziOverview__c'] = values[key];
          break;
        case 'KoziStop__c':
          if (values[key]) {
            conditions['IsetuIraiDesignDocument__r.KoziStop__c_EQ'] = true;
          }
          break;
        case 'KoziScheduleSeason__c_From':
          if (values[key]) {}
          // 工事予定時期は、日は01で登録
          conditions['KoziScheduleSeason__c_From'] = values[key] + '-01';
          break;
        case 'KoziScheduleSeason__c_To':
          // 工事予定時期は、日は01で登録
          conditions['KoziScheduleSeason__c_To'] = values[key] + '-01';
          break;
        case 'KoziPlaceMainDntNo__c':
          conditions['IsetuIraiDesignDocument__r.DntNo__c'] = values[key];
          break;
        default:
          conditions[key] = values[key];
          break;
      }
    }
    if (!conditions['IsetuIraiCarryOutDate__c_From'] && !conditions['IsetuIraiCarryOutDate__c_To']) {
      conditions['IsetuIraiCarryOutDate__c_From'] = oneYearAgo;
    }
    if (!conditions['IsetuIraiDesignDocument__r.KoziStop__c_EQ']) {
      if (!conditions['IsetuIraiDesignDocument__r.KoziStop__c']) conditions['IsetuIraiDesignDocument__r.KoziStop__c'] = false;
      conditions['IsetuIraiDesignDocument__r.KoziHold__c'] = false;
    }
    if (!conditions['OrderStatus__c']) {
      // 申込ステータス未指定の場合、追加
      conditions['OrderStatus__c'] = (this.state.isKoziAccept ? ['51'] : ['50', '51', '90', '92']);
    }

    return conditions;
  }

  /**
   * 依頼内容確認
   */
  doConfirm = async () => {
    try {
      const {userInfo} = this.props;
      if (!this.gridRef.current.selectedItems ||
        this.gridRef.current.selectedItems.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['確認対象'],
          },
        });
        return;
      }
      const selectedItem = this.gridRef.current.selectedItems[0];
      // 受理日が設定されていない場合、設定する。
      const orderCondition = [];
      const orderList = [];
      if (!selectedItem.dataItem.IsetuIraiContentsAcceptedDate__c) {
        const conditions = {
          Id: selectedItem.dataItem.Id,
          LastModifiedDate: selectedItem.dataItem.LastModifiedDate,
        };
        orderCondition.push(conditions);
        const params = {
          Id: selectedItem.dataItem.Id,
          IsetuIraiContentsAcceptedDate__c: getToDate(), // 移設依頼内容受理日(システム日付)
        };
        orderList.push(params);
      }

      // 「依頼内容確認済」の場合「工事日調整中」に変更する。
      const designDocumentCondition = [];
      const designDocumentList = [];
      if (selectedItem.dataItem.IsetuIraiDesignDocument__r.IsetuIraiStatus__c == '01') {
        const conditions = {
          Id: selectedItem.dataItem.IsetuIraiDesignDocument__c,
          LastModifiedDate:
          selectedItem.dataItem.IsetuIraiDesignDocument__r.LastModifiedDate,
        };
        designDocumentCondition.push(conditions);
        const params = {
          Id: selectedItem.dataItem.IsetuIraiDesignDocument__c,
          IsetuIraiStatus__c: '02', // 移設依頼ステータス(「工事日調整中」)
        };
        designDocumentList.push(params);
      }
      if (orderCondition.length > 0 || designDocumentCondition.length > 0) {
        const body = {
          orderConditions: orderCondition,
          orderList: orderList,
          designDocumentConditions: designDocumentCondition,
          designDocumentList: designDocumentList,
        };
        console.dir(body);
        const result = await execApexRestApi(
            userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
        console.dir(result);
        const resResult = getResponseResult(result, ['移設依頼情報', '登録']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        }
      }
      this.props.doSetDesignDocumentId(
          selectedItem.dataItem.IsetuIraiDesignDocument__c);
      this.props.doSetOrderId(selectedItem.dataItem.Id);
      this.props.history.push({pathname: '/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/BasicCheck'});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * ボタンの活性、非活性化
   */
  checkStateUpdate = () => {
    try {
      let bRet = false;
      if (this.gridRef.current && this.gridRef.current.selectedItems &&
        this.gridRef.current.selectedItems.length == 1) {
        bRet = true;
      }

      if (bRet) {
        this.setState({buttonDisabled: false});
      } else {
        this.setState({buttonDisabled: true});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  renderTable(data) {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkStateUpdate,
    };

    return (
    // 一覧表示部の表示設定
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='DispIsetuIraiStatus' header='移設依頼ステータス' dataType='String' isReadOnly={true} width={200}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c' header='移設依頼発信年月日' dataType='Date' isReadOnly={true} width={190}
            cellTemplate={(ctx) => {
              return ctx.item.IsetuIraiDesignDocument__r ?
                changeDateFormat(ctx.item.
                    IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c) : '';
            }}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DesignCompanySyozoku__c' header='設計会社／所属' dataType='String' isReadOnly={true} width={160}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuIraiNo__c' header='一連番号' dataType='String' isReadOnly={true} width={100}/>
          <FlexGridColumnGroup binding='KoziPlacePrefectures__c' header='都道府県' dataType='String' isReadOnly={true} width={100}/>
          <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType='String' isReadOnly={true}/>
          <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType='String' isReadOnly={true}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DntNo__c' header='電柱番号' dataType='String' isReadOnly={true}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziOverview__c' header='工事概要' dataType='String' isReadOnly={true}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfDesign__c)' header='移設完了希望日 （設計時）' dataType='Date' isReadOnly={true} width={240}
            cellTemplate={(ctx) => {
              return ctx.item.IsetuIraiDesignDocument__r ?
                changeDateFormat(ctx.item.
                    IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfDesign__c) : '';
            }}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziScheduleSeason__c' header='工事予定時期' dataType='Date' isReadOnly={true} width={160}
            cellTemplate={(ctx) => {
              return ctx.item.IsetuIraiDesignDocument__r ?
                changeDateFormatter(ctx.item.
                    IsetuIraiDesignDocument__r.KoziScheduleSeason__c, 'YYYY年MM月') : '';
            }}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziScheduleSeasonHosoku__c' header='工事予定時期補足' dataType='String' isReadOnly={true} width={180}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuIraiDesignDocumentNo__c' header='設計書番号' dataType='String' isReadOnly={true}/>
          <FlexGridColumnGroup binding='KoziTargetHonsu' header='工事対象本数' dataType='Number' isReadOnly={true} width={160}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DesignTantoName__c' header='設計担当者名' dataType='String' isReadOnly={true}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfContact__c' header='移設完了希望日（工事日連絡時）' dataType='Date' isReadOnly={true} width={280}
            cellTemplate={(ctx) => {
              return ctx.item.IsetuIraiDesignDocument__r ?
                changeDateFormat(ctx.item.
                    IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfContact__c) : '';
            }}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DrIsnKoziDate__c' header='電力移線工事日' dataType='Date' isReadOnly={true} width={180}
            cellTemplate={(ctx) => {
              return ctx.item.IsetuIraiDesignDocument__r ?
                changeDateFormat(ctx.item.
                    IsetuIraiDesignDocument__r.DrIsnKoziDate__c) : '';
            }}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c' header='電力移線工事日（変更）' dataType='Date' isReadOnly={true} width={220}
            cellTemplate={(ctx) => {
              return ctx.item.IsetuIraiDesignDocument__r ?
                changeDateFormat(ctx.item.
                    IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c) : '';
            }}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziCompanySyozoku__c' header='工事会社／所属' dataType='String' isReadOnly={true} width={180}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziTantoName__c' header='工事担当者名' dataType='String' isReadOnly={true} width={180}/>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {
      classes,
      generalPurposeMap,
      searchResult,
      reset,
      handleSubmit,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            onClick={handleSubmit(this.doConfirm)}
            variant="contained"
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>依頼内容確認</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1411-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderSelect}
                  label="移設依頼ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {/* <MenuItem value={0}>&nbsp;</MenuItem> */}
                  {createSearchList((this.state.isKoziAccept ? isetuIraiStatusKoziAcceptList : isetuIraiStatusList), 'Code__c', 'Name', null)}
                </Field>
              </Grid>

              <Grid key="key1411-2" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1411-3" item xs={12} md={6} style={{position: 'relative'}}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuIraiCarryOutDate__c_From"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_From"
                    label="移設依頼発信年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    required={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuIraiCarryOutDate__c_To"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    required={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1411-4" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(8),
                  ]}
                />
              </Grid>

              <Grid key="key1411-5" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="key1411-6" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1411-7" item xs={12} sm={6} md={3}>
                <Field
                  name="NWZgsyo__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="事業所"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap != null &&
                    createSearchListEx(generalPurposeMap.NWZgsyo, 'Code__c', 'Name', null, {Code__c: ['1001', '1065']})
                  }
                </Field>
              </Grid>

              <Grid key="key1411-8" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap != null &&
                    createList(generalPurposeMap.PrefCode)
                  }
                </Field>
              </Grid>

              <Grid key="key1411-9" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="key1411-10" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1411-11" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMainDntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                />
              </Grid>

              <Grid key="key1411-12" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key2-1" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_From"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_From"
                    label="移設完了希望日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_To"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key2-2" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="KoziScheduleSeason__c_From"
                    accessibilitylabel="KoziScheduleSeason__c_From"
                    label="工事予定時期"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="KoziScheduleSeason__c_To"
                    accessibilitylabel="KoziScheduleSeason__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key2-3" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="DrIsnKoziDate__c_From"
                    accessibilitylabel="DrIsnKoziDate__c_From"
                    label="電力移線工事日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="DrIsnKoziDate__c_To"
                    accessibilitylabel="DrIsnKoziDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key2-4" item xs={12} sm={3} md={3}>
                <div style={{paddingLeft: '15px'}}>
                  <Field className={classes.checkbox}
                    name="KoziStop__c"
                    component={renderCheckbox}
                    label="工事保留／工事中止"
                  />
                </div>
              </Grid>

              <Grid key="key2-5" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.doSearch)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>

          </PaperPart>

          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable(searchResult)}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  history: PropTypes.object.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetAllGeneralPurposeMap: PropTypes.func.isRequired,
  generalPurposeMap: PropTypes.object,
  doGetOrderTypeList: PropTypes.func.isRequired,
  orderList: PropTypes.array,
  doClearOrderList: PropTypes.func,
  reset: PropTypes.func.isRequired,
  doSetDesignDocumentId: PropTypes.func,
  doSetOrderId: PropTypes.func,
  searchResult: PropTypes.array,
};

const mapStateToProps = (state) => {
  const searchResult = [];
  const orderList = state.attachorder.orderList;
  if (orderList) {
    for (const data of orderList) {
      const orderData = [];
      for (const fieldName of appFields) {
        if (fieldName == 'toLabel(KoziPlacePrefectures__c)') {
          orderData['KoziPlacePrefectures__c'] = data['KoziPlacePrefectures__c'];
        }
        const name = fieldName.split('.')[0];
        if (!orderData[name]) orderData[name] = data[name];
      }
      orderData['KoziTargetHonsu'] =
      data.ContractDnts__r ? data.ContractDnts__r.totalSize : 0;
      if (data.IsetuIraiDesignDocument__r &&
         data.IsetuIraiDesignDocument__r.KoziStop__c) {
        orderData['DispIsetuIraiStatus'] = '工事中止';
      } else if (data.OrderStatus__c == '50') {
        orderData['DispIsetuIraiStatus'] = '予定連絡';
      } else if (data.OrderStatus__c == '90') {
        orderData['DispIsetuIraiStatus'] = '竣工済';
      } else if (data.OrderStatus__c == '51') {
        if (data.IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c) {
          orderData['DispIsetuIraiStatus'] = '工事日変更・未竣工';
        } else if (!data.KyogaKoziScheduleDate__c) {
          orderData['DispIsetuIraiStatus'] = '工事日未入力';
        } else {
          orderData['DispIsetuIraiStatus'] = '工事日確定・未竣工';
        }
      }
      searchResult.push(orderData);
    }
  }
  return {
    searchResult: searchResult,
    generalPurposeMap: state.common.generalPurposeMap,
    orderList: state.attachorder.orderList,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = {
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doSetDesignDocumentId: isetuIraiOperations.doSetDesignDocumentId,
  doSetOrderId: attachorderOperations.doSetOrderId,
};

const FORM_NAME = 'IsetuIraiScheduleContactList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

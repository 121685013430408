import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {authOperations} from '../../reducks/auth';
import Container from '../../components/templates/Login/unavailableTime';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  signOut: authOperations.doSignOutOperation,
};

/**
 * ログイン画面:利用可能時間 ページ。
 * 画面ID:3000
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);

    // store初期化/サインアウト
    this.props.signOut();
  }

  /**
   * 画面描画。
   *
   * @return {JSX.Element} 描画要素
   */
  render() {
    return (<Container
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  signOut: PropTypes.func,
};

export default ContainerApp;

import {combineReducers} from 'redux';
import types from './types';

// 共架者連絡事項一覧
const contactZikoList = (state = null, action) =>{
  switch (action.type) {
    case types.GET_CONTACT_ZIKO_LIST:
      return action.contactZikoList;
    default:
      return state;
  }
};

// 共架者連絡事項
const contactZiko = (state = null, action) =>{
  switch (action.type) {
    case types.GET_CONTACT_ZIKO:
      return action.contactZiko;
    default:
      return state;
  }
};

// 共架者連絡事項の連絡種別
const contactZikoType = (state = null, action) =>{
  switch (action.type) {
    case types.SET_CONTACT_ZIKO_TYPE:
      return action.contactZikoType;
    default:
      return state;
  }
};

// 共架者連絡事項Id
const kyogaContactZikoId = (state = null, action) =>{
  switch (action.type) {
    case types.SET_KYOGA_CONTACT_ZIKO_ID:
      return action.kyogaContactZikoId;
    default:
      return state;
  }
};

const reducer = combineReducers({
  contactZikoList,
  contactZiko,
  contactZikoType,
  kyogaContactZikoId,
});

export default reducer;

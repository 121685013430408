import * as React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import NegativeButton from '../components/atoms/Buttons/NegativeButton';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
    background: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#fff',
    height: '100%',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

class ErrorApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.dir(errorInfo);
    this.setState({hasError: true});
  }

  backLogin=()=>{
    const {history} = this.props;
    this.setState({hasError: false});
    history.push('/login');
  }

  render() {
    if (this.state.hasError) {
      const commonStyle={
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
      return (
        <div >
          <div style={commonStyle }>
            <h1 style={ {
              color: 'white',
            } }>
              予期せぬエラーが発生しました。
            </h1>
          </div>
          <div style={commonStyle }>
            <h1 style={ {
              color: 'white',
            } }>
              ログイン画面から再度やり直してください。
            </h1>
          </div>
          <div style={commonStyle }>
            <NegativeButton
              onClick={
                this.props.handleSubmit(this.backLogin)
              }
            >OK</NegativeButton>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorApp.propTypes ={
  children: PropTypes.object,
  history: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
});

const mapDispatchToProps = {
};

const FORM_NAME = 'ErrorPage';

ErrorApp = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
  };
})(ErrorApp));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ErrorApp),
);

import types from './types';

export const changeTheme = (theme) => ({
  type: types.CHANGE_THEME,
  theme,
});

export const addTodo = (todo) => ({
  type: types.ADD_TODO,
  todo,
});

// ログイン情報設定
export const doLogin = (loginInfo) => ({
  type: types.DO_LOGIN,
  loginInfo,
});

// ログアウト
export const doLogout = () => ({
  type: types.DO_LOGOUT,
});

// パスワードリセット(認証コード送付)
export const sendAuthenticationCode = (sendAuthenticationCodeInfo) => ({
  type: types.SEND_AUTHENTICATION_CODE,
  sendAuthenticationCodeInfo,
});

// パスワードリセット(パスワード更新)
export const doPasswordReset = (passwordResetInfo) => ({
  type: types.DO_PASSWORD_RESET,
  passwordResetInfo,
});

// パスワード変更
export const doPasswordChange = (passwordChangeInfo) => ({
  type: types.DO_PASSWORD_CHANGE,
  passwordChangeInfo,
});

// 利用者登録
export const doUserRegist = (userRegistInfo) => ({
  type: types.DO_USER_REGIST,
  userRegistInfo,
});

// 利用者認証
export const verifyUser = (verifyUserInfo) => ({
  type: types.VERIFY_USER,
  verifyUserInfo,
});

// 住所取得
export const getAddress = (addressInfo) => ({
  type: types.GET_ADDRESS_INFO,
  addressInfo,
});

// ユーザー情報更新
export const doUserUpdate = (userUpdateInfo) => ({
  type: types.DO_USER_UPDATE,
  userUpdateInfo,
});

// ユーザー情報取得
export const getUserInfo = (userInfo) => ({
  type: types.GET_USER_INFO,
  userInfo,
});

// 代行ユーザの検索
export const getDaikoUserList = (daikoUserList) => ({
  type: types.GET_DAIKO_USER_LIST,
  daikoUserList,
});

export default {
  changeTheme,
  addTodo,
  doLogin,
  doLogout,
  doPasswordReset,
  doUserRegist,
  verifyUser,
  sendAuthenticationCode,
  getAddress,
  doUserUpdate,
  getUserInfo,
  doPasswordChange,
  getDaikoUserList,
};

import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import {createLogger} from 'redux-logger';
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import auth from './auth'; // import all reducers from ducks/index.js
import common from './common';
import style from './style';
import work from './work';
import isetuIrai from './isetuIrai';
import attachorder from './attachorder';
import contractDnt from './contractDnt';
import senStb from './senStb';
import senroCode from './senroCode';
import dnt from './dnt';
import billingDetail from './billingDetail';
import billinglDocumentDetail from './billinglDocumentDetail';
import grid from './grid';
import other from './other';
import {reducer as formReducer} from 'redux-form';
import Conf from '../config/config.js';

import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {setStockUrl} from './common/actions';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers({
  attachorder,
  auth,
  common,
  contractDnt,
  senStb,
  style,
  work,
  isetuIrai,
  senroCode,
  dnt,
  billingDetail,
  billinglDocumentDetail,
  grid,
  other,
  router: connectRouter(history),
  form: formReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'DO_LOGOUT') {
    // state = undefined;
    const ret = {};
    // router情報については初期化せず継承する
    ret.router = state.router;
    return appReducer(ret, action);
  }
  return appReducer(state, action);
};

// 永続化の設定
const persistConfig = {
  key: 'root', // Storageに保存されるキー名を指定する
  storage, // 保存先としてlocalStorageがここで設定される
  blacklist: ['router'], // `visibilityFilter`は保存しない
};

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({
  diff: true,
  collapsed: true,
});

const middlewares = [];
middlewares.push(thunk);
middlewares.push(routerMiddleware(history));

if (Conf.logger) {
  middlewares.push(logger);
}

/*
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, logger, routerMiddleware(history))),
  );
}
*/
/*
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
}
*/

// 履歴操作オプション
const CLEAR = 'CLEAR';
const NOT_SET = 'NOT_SET';
history.option = {CLEAR, NOT_SET};

// pushメソッドオーバーライド的実装のため、オリジナルのメソッドを保持
const push = history.push;

// pushメソッドのオーバーライド的実装
history.push = function(location, state, option) {
  const {dispatch, getState} = store;

  let stockUrl = getState().common.stockUrl;
  if (stockUrl == null) {
    stockUrl = [];
  }

  if (option == CLEAR) {
    // 戻り先URLをクリア
    dispatch(setStockUrl([]));
  } else if (option == NOT_SET) {
    // 何もしない
  } else {
    // 戻り先URLを設定
    stockUrl.push(history.location.pathname);
    dispatch(setStockUrl(stockUrl));
  }

  push(location, state);
};

// 戻り先URLの最新を取得し、最新を取り除いた戻り先URL一覧をストアに登録しなおす
history.popStockUrl = function() {
  const {dispatch, getState} = store;

  let stockUrl = getState().common.stockUrl;
  if (stockUrl == null) {
    stockUrl = [];
  }

  const url = stockUrl.pop();
  dispatch(setStockUrl(stockUrl));
  return url;
};

const store = createStore(
    persistedReducer,
    window.REDUX_INITIAL_DATA,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    composeEnhancers(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
export default function configureStore(initialState = {}) {
  return store;
}

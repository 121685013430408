import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import MainContainer from '../../organisms/MainContainer.js';
import BackButton from '../../atoms/Buttons/BackButton.js';
import PaperPart from '../../atoms/PaperPart.js';

import {reduxForm, getFormValues} from 'redux-form';
import conf from '../../../config/config.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

/**
 * 申込関連書類書式一覧
 * 画面ID:1522
*/
class TemplateList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {classes} = this.props;

    const sideMenuList = [];

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn5" item>
          <BackButton props={this.props} backUrl={'/login'}/>
        </Grid>

      </Grid>
    );

    // 様式リスト
    const fileList = [
      {title: '様式1 共架内諾申込書', link: [{link: conf.FORMAT01_PDF_URL, name: 'pdf'}, {link: conf.FORMAT01_DOC_URL, name: 'doc'}]},
      {title: '様式2 共架内諾・不内諾回答書', link: [{link: conf.FORMAT02_PDF_URL, name: 'pdf'}]},
      {title: '様式3 共架・変更申込書', link: [{link: conf.FORMAT03_PDF_URL, name: 'pdf'}, {link: conf.FORMAT03_DOC_URL, name: 'doc'}]},
      {title: '様式4 共架の対象および共架伝送路設備', link: [{link: conf.FORMAT04_PDF_URL, name: 'pdf'}, {link: conf.FORMAT04_DOC_URL, name: 'doc'}]},
      {title: '様式5 工事施工者および保守担当者の届', link: [{link: conf.FORMAT05_PDF_URL, name: 'pdf'}, {link: conf.FORMAT05_DOC_URL, name: 'doc'}]},
      {title: '様式6 共架承諾・不承諾回答書', link: [{link: conf.FORMAT06_PDF_URL, name: 'pdf'}]},
      {title: '様式7 共架・改修・撤去工事  着手・完了届', link: [{link: conf.FORMAT07_PDF_URL, name: 'pdf'}, {link: conf.FORMAT07_DOC_URL, name: 'doc'}]},
      {title: '様式8 共架申込・廃止　装柱図・写真【申請時／施工後】（標準様式）', link: [{link: conf.FORMAT08_XLS_URL, name: 'excel'}]},
    ];

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          sideMenuList={sideMenuList}
          footerBtn={footerBtn}
        >
          <Typography variant="h5" gutterBottom>申込み等に必要な書類の標準的な様式</Typography>
          <PaperPart>
            <Typography variant="h6" align="left" gutterBottom>線路名(線路コード)　電柱番号</Typography>
            <List>
              {fileList.map((file, index) =>(
                <ListItem key={index}>

                  <ListItemText primary={
                    <span>
                      {index + 1}{'. '}{file.title}
                      <React.Fragment>
                        {file.link.map((link, idx) =>(
                          <>
                            {'['}<a href={link.link} target="_blank" rel="noopener noreferrer">{link.name}</a>{']'}
                          </>
                        ))}
                      </React.Fragment>
                    </span>
                  }/>
                </ListItem>
              ))}
            </List>
          </PaperPart>
        </MainContainer>
      </form>
    );
  }
}

TemplateList.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {

};

const FORM_NAME = 'TemplateList';

TemplateList = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(TemplateList)(state),
  };
})(TemplateList));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TemplateList),
);

import {connect} from 'react-redux';

import {
  App,
  mapDispatchToProps,
  mapStateToProps,
} from './index';

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

/**
 * ユーザ管理（詳細） ページ。
 * 画面ID:1532
 *
 * 自身のユーザ情報編集画面。
 * ユーザ管理（詳細）で他のユーザ情報編集時、ユーザメニューから「ユーザー情報変更」を
 * クリックした際に画面遷移しないため、回避のため作成。
 * 処理は @see {@link App} と共通。
 */
export default ContainerApp;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListAltIcon from '@material-ui/icons/ListAlt';

import {
  getCodeFromGeneralPurposeMap, downloadAttachedFile,
  changeDateFormat, getErrorCode, API_CODE,
  getResponseResult,
} from '../../../../../../common/common.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {orderBasicFields} from '../../../../../../common/SFFields';
import {ConfirmWarningMessageArea} from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  attachedFileTitle: {
    marginTop: theme.spacing(2),
    marginBottom: '-1.6em',
    fontWeight: 700,
  },
});

// Order__cの申込情報の項目
const basicInfoItems = [
  {key: 'OrderNo__c', name: '本申込番号', value: '', type: ''},
  {key: 'OrderDate__c', name: '本申込年月日', value: '', type: 'date'},
  {key: 'ProprietyJudgeOrderNo__c', name: '可否判定申込番号', value: '', type: ''},
  {key: 'ProprietyJudgeOrderDate__c', name: '可否判定申込年月日', value: '', type: 'date'},
  {key: 'TemporarilySavedDate__c', name: '一時保存日', value: '', type: 'date'},
  {key: 'KyogasyaSendBackReason__c', name: '差戻理由', value: '', type: ''},
];
// Order__cの共架事業者の項目;
const kyogaInfoItems = [
  {key: 'OrderKyogaZgsya__r', name: '申込共架事業者', value: '', type: ''},
  {key: 'KoziTitle__c', name: '工事件名', value: '', type: ''},
];
// Order__cの工事情報の項目
const koziInfoItems = [
  {key: 'KoziPlaceMainDntNo_SenroName__c', name: '線路名', value: '', type: ''},
  {key: 'Dsp_KoziPlaceMainDntNo__c', name: '代表電柱番号', value: '', type: ''},
  {key: 'KoziPlacePrefecturesName', name: '都道府県', value: '', type: ''},
  {key: 'KoziPlaceMunicipalities__c', name: '市区町村', value: '', type: ''},
  {key: 'KoziPlacePlace__c', name: '場所', value: '', type: ''},
  {key: 'StbTypeName', name: '設備種別', value: '', type: ''},
  {key: 'KyogaTypeName', name: '共架種別', value: '', type: ''},
  {key: 'SplitOrderUmuName', name: '分割申込有無', value: '', type: ''},
  {key: 'ZizenNegotiationNo__c', name: '協議番号', value: '', type: ''},
];
// Order__cのメモ内容の項目
const memoItems = {key: 'MemoContents__c', name: 'メモ内容', value: '', type: ''};

/** この画面で更新を行うフィールド名 */
const updateFormalParams = {
  Id: null,
  OrderDate__c: null, // 本申込年月日 サーバーで設定する
  OrderStatus__c: null, // 申込ステータス サーバーで設定する
};
const updateProprietyParams = {
  Id: null,
  OrderStatus__c: null, // 申込ステータス サーバーで設定する
  ConclusionDate__c: null, // 完結日 サーバーで設定する
};

/**
 * 本申込（申込内容のご確認）
 * 画面ID:1062
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootFiles: [],
      horizonFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      iskkFiles: [],
      changedRootFiles: [],
      changedHorizonFiles: [],
      isDisabled: true,
      hasError: true,
    };
    this.isSen = this.props.stbType == '1' ? true : false;

    for (const row of basicInfoItems) {
      row['value'] = '';
    }
    for (const row of kyogaInfoItems) {
      row['value'] = '';
    }
    for (const row of koziInfoItems) {
      row['value'] = '';
    }
    memoItems['value'] = '';
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {orderId, generalPurposeMap} = this.props;

      this.props.doSetReferenceMode(false);

      const attachedFileTypeRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA08', 3);
      const attachedFileTypeHorizon = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA02', 3);
      const attachedFileTypeSpec = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA01', 3);
      const attachedFileTypeOther = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA12', 3);
      const attachedFileTypePicture = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);
      const attachedFileTypeIskk = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA10', 3);
      const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
      const orderCategoryFormal = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory02', 2);

      /** コード：申込:ルート図（変更後） */
      const attachedFileTypeChangedRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA27', 3);

      /**  コード：申込:平面図（変更後） */
      const attachedFileTypeChangedHorizon = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA28', 3);

      if (!orderId) {
        return;
      }

      // 申込を取得して画面表示
      const appConditions = {
        'Id': orderId, // 本申込のId
        'RecordType.DeveloperName': 'MainOrder',
        'OrderCategory__c': orderCategoryFormal,
        'attachedFileTypes': [
          attachedFileTypeRoot,
          attachedFileTypeHorizon,
          attachedFileTypeSpec,
          attachedFileTypeOther,
          attachedFileTypePicture,
          attachedFileTypeIskk,
        ],
        'formalAttachedFileTypes': [
          attachedFileTypeChangedRoot,
          attachedFileTypeChangedHorizon,
        ],
      };
      const response =
        await this.props.doGetFormalOrderInfo(appConditions, orderBasicFields);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['本申込', '取得'],
          },
        });
        return;
      }

      // 取得した申込情報、添付ファイルを画面に表示する
      const data = this.props.order ? this.props.order : {};
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          for (let i = 0; i < basicInfoItems.length; i++) {
            if (basicInfoItems[i].key == key) {
              basicInfoItems[i].value =
                basicInfoItems[i].type == 'date' ? changeDateFormat(data[key]) : data[key];
            }
          }
          for (let i = 0; i < kyogaInfoItems.length; i++) {
            if (kyogaInfoItems[i].key == key) {
              if (kyogaInfoItems[i].key == 'OrderKyogaZgsya__r') {
                kyogaInfoItems[i].value =
                  Object.hasOwnProperty.call(data[key], 'Name') ? data[key].Name : '' +
                    Object.hasOwnProperty.call(data[key], 'Account__r') &&
                    Object.hasOwnProperty.call(data[key].Account__r, 'Name') ?
                    data[key].Account__r.Name : '';
              } else {
                kyogaInfoItems[i].value = data[key];
              }
            }
          }
          for (let i = 0; i < koziInfoItems.length; i++) {
            if (koziInfoItems[i].key == key) {
              koziInfoItems[i].value = data[key];
            }
          }
          if (memoItems.key == key) {
            memoItems.value = data[key];
          }
        }
      }

      // 本申込の添付ファイルを表示する
      let _rootFiles = [];
      let _horizonFiles = [];
      let _specFiles = [];
      let _otherFiles = [];
      let _pictureFiles = [];

      /** 申込:ルート図（変更後） ファイル一覧 */
      let _changedRootFiles = [];

      /** 申込:平面図（変更後） ファイル一覧 */
      let _changedHorizonFiles = [];

      if (this.props.contentDocuments &&
        this.props.contentDocuments.length > 0) {
        for (const row of this.props.contentDocuments) {
          const anchorAttrs = {};
          anchorAttrs['onClick'] = (event) => {
            this.doDownloadAttachedFile(
                row.VersionData,
                row.FileType,
                row.PathOnClient);
          };
          anchorAttrs['href'] = 'javascript:void(0)';
          let data = {anchor: null, value: ''};
          data.value = row.PathOnClient;
          data.anchor = anchorAttrs;
          if (row.AttachedFileType__c == attachedFileTypeRoot) {
            _rootFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypeHorizon) {
            _horizonFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypeSpec) {
            _specFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypeOther) {
            _otherFiles.push(data);
          }
          if (row.AttachedFileType__c == attachedFileTypePicture) {
            _pictureFiles.push(data);
          }
        }
      }

      // 本申込で添付したデータの取得とアンカー要素設定
      if (this.props.formalContentDocuments &&
        this.props.formalContentDocuments.length > 0) {
        for (const row of this.props.formalContentDocuments) {
          const data = {
            value: row.PathOnClient,
            anchor: {
              onClick: (event) => {
                this.doDownloadAttachedFile(
                    row.VersionData,
                    row.FileType,
                    row.PathOnClient);
              },
              href: 'javascript:void(0)',
            },
          };

          if (row.AttachedFileType__c == attachedFileTypeChangedRoot) {
            _changedRootFiles.push(data);
          }

          if (row.AttachedFileType__c == attachedFileTypeChangedHorizon) {
            _changedHorizonFiles.push(data);
          }
        }
      }

      // 警告チェック
      let _hasError = false;
      if (this.props.setGmnWarning) {
        if (this.isSen) {
          for (const gmnkey of ['1012', '1013', '1014']) {
            if (Object.hasOwnProperty.call(this.props.setGmnWarning, gmnkey)) {
              _hasError = _hasError || this.props.setGmnWarning[gmnkey];
            }
          }
        } else {
          for (const gmnkey of ['1012', '1016']) {
            if (Object.hasOwnProperty.call(this.props.setGmnWarning, gmnkey)) {
              _hasError = _hasError || this.props.setGmnWarning[gmnkey];
            }
          }
        }
      }

      this.setState({
        rootFiles: _rootFiles,
        horizonFiles: _horizonFiles,
        specFiles: _specFiles,
        otherFiles: _otherFiles,
        pictureFiles: _pictureFiles,
        iskkFiles: [],
        changedRootFiles: _changedRootFiles,
        changedHorizonFiles: _changedHorizonFiles,
        isDisabled: this.props.order &&
          this.props.order.OrderStatus__c ==
          orderStatusCreate ? false : true, // 作成中のみ編集可
        hasError: _hasError,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearFormalOrderInfo();
  }

  /**
   * 添付ファイルダウンロード
   * @param {*} url
   * @param {*} fileType
   * @param {*} fileName
   */
  doDownloadAttachedFile = async (url, fileType, fileName) => {
    const {userInfo} = this.props;
    try {
      downloadAttachedFile(
          userInfo, url,
          fileType, fileName);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 参照ボタン押下
   * @param {string} ref 遷移先
   */
  doRef = (ref) => {
    this.props.doSetReferenceMode(true);
    if (ref == '線申込電柱') {
      this.props.history.push({pathname: '/FormalOrderSenOrderDnt'});
    } else if (ref == '径間') {
      this.props.history.push({pathname: '/FormalOrderSenStb'});
    } else {
      // 点設備の申込電柱
      this.props.history.push({pathname: '/FormalOrderTenOrderDnt'});
    }
  }

  /**
   * 申込ボタン押下
   */
  doApply = () => {
    try {
      const {generalPurposeMap, order} = this.props;
      const orderStatusExpired = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus91', 2);

      if (!this.props.orderId) {
        return;
      }

      // 可否判定申込の申込ステータスが期限切れだったら、本申込できない
      if (order && order.ProprietyJudgeOrder__r &&
        order.ProprietyJudgeOrder__r.OrderStatus__c == orderStatusExpired) {
        this.props.doShowMessage({
          message: {
            id: 'PE0083',
          },
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['申込'],
        },
        action: () => {
          this.updateOrder();
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込処理実行
   */
  updateOrder = async () => {
    const {order, orderModifiedCondition} = this.props;

    // 本申込設定
    updateFormalParams['Id'] = order.Id;
    // 可否判定申込設定
    updateProprietyParams['Id'] = order.ProprietyJudgeOrder__r.Id;

    const body = {
      record: {
        conditions: {
          Id: order.Id,
          LastModifiedDate:
            orderModifiedCondition != null &&
              order.Id == orderModifiedCondition.Id ?
              orderModifiedCondition.LastModifiedDate : null,
        },
        proprietyConditions: {
          Id: order.ProprietyJudgeOrder__r.Id,
          LastModifiedDate: order.ProprietyJudgeOrder__r.LastModifiedDate,
        },
        order: updateFormalParams,
        proprietyOrder: updateProprietyParams,
        stage: 'ORDER_STATUS_UPDATE',
      },
    };
    const response = await this.props.doUpdateFormalOrder(
        'applyFormalOrder', body);
    const resResult = getResponseResult(response, ['本申込', '申込']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
        this.props.doShowMessage({
          message: resResult.messages,
          type: 'error',
          action: async () => {
            // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
            this.props.history.push(
                {pathname: '/FormalOrderList'},
                null, this.props.history.option.CLEAR,
            );
          },
        });
      } else {
        this.props.doShowMessage({
          message: resResult.messages,
          type: 'error',
        });
      }
      return;
    }

    this.setState({
      rootFiles: [],
      horizonFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      iskkFiles: [],
    });

    this.props.doShowMessage({
      message: {
        id: 'CI0009',
        values: ['本申込'],
      },
      action: () => {
        // 申込が成功したら一覧へ遷移
        this.props.history.push(
            {pathname: '/FormalOrderList'},
            null, this.props.history.option.CLEAR,
        );
      },
    });
  }

  render() {
    const {classes, handleSubmit} = this.props;
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1062Apply" item>
          <PositiveButton
            id="btn1062Apply"
            onClick={handleSubmit(this.doApply)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled || this.state.hasError}
          >
            <span>申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1062Back" item>
          <BackButton props={this.props} />
        </Grid>
      </Grid>
    );

    const _gridSize = 3;

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer props={this.props}
          footerBtn={footerBtn}>
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='申込' />
          }
          <Typography variant="h5" gutterBottom className={classes.title}>
            本申込
          </Typography>
          <Grid container spacing={1}>
            <Grid item container direction="column" xs={12} sm={3}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                申込情報
              </Typography>
              <Grid container>
                {basicInfoItems.map((basic) => (
                  <React.Fragment key={basic.name}>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{basic.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography gutterBottom className="textWrap">{basic.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={5}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                {'　'}
              </Typography>
              <Grid container>
                {kyogaInfoItems.map((kyogainfo) => (
                  <React.Fragment key={kyogainfo.name}>
                    <Grid item xs={3}>
                      <Typography gutterBottom>{kyogainfo.name}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography gutterBottom className="textWrap">{kyogainfo.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={4}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                工事場所
              </Typography>
              <Grid container>
                {koziInfoItems.map((koziInfo) => (
                  <React.Fragment key={koziInfo.name}>
                    <Grid item xs={3}>
                      <Typography gutterBottom>{koziInfo.name}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography gutterBottom className="textWrap">{koziInfo.value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>

            <Grid item direction="column" xs={12}>
              <Typography variant="h6" className={classes.attachedFileTitle}>ファイル一覧（可否判定申込時添付）</Typography>
            </Grid>

            <Grid item container direction="column" xs={12} sm={_gridSize}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                装柱図
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.pictureFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={_gridSize}>
              {this.isSen &&
                <React.Fragment>
                  <Typography variant="h6" gutterBottom className={classes.title}>
                    ルート図
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      {this.state.rootFiles.map((data) => (
                        <>
                          <a key={data.value}
                            {...data.anchor}>{data.value}</a>
                          <br></br>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {!this.isSen &&
                <React.Fragment>
                  <Typography variant="h6" gutterBottom className={classes.title}>
                    平面図
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      {this.state.horizonFiles.map((data) => (
                        <>
                          <a key={data.value} {...data.anchor}>{data.value}</a>
                          <br></br>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
            <Grid item container direction="column" xs={12} sm={_gridSize}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                共架設備仕様書
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.specFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={12} sm={_gridSize}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                その他資料
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {this.state.otherFiles.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid item direction="column" xs={12}>
              <Typography variant="h6" className={classes.attachedFileTitle}>ファイル一覧（本申込時添付）</Typography>
            </Grid>

            {
              this.isSen &&
              <Grid item direction="column" xs={12} sm={_gridSize}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  ルート図（変更後）
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    {this.state.changedRootFiles.map((data) => (
                      <>
                        <a key={data.value} {...data.anchor}>{data.value}</a>
                        <br></br>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            }
            {
              !this.isSen &&
              <Grid item direction="column" xs={12} sm={_gridSize}>
                <Typography variant="h6" gutterBottom className={classes.title}>
                  平面図（変更後）
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    {this.state.changedHorizonFiles.map((data) => (
                      <>
                        <a key={data.value} {...data.anchor}>{data.value}</a>
                        <br></br>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            }

            <Grid item direction="column" xs={12 - _gridSize}></Grid>

            <Grid item container direction="column" xs={12} sm={8}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                メモ内容
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className="textWrap" style={{whiteSpace: 'pre-wrap'}} component="div" gutterBottom>
                    {memoItems.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.isSen &&
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}>
              <Grid key="key1062ContractDntSen" item>
                <Typography variant="h5" gutterBottom className={classes.title}>
                  線設備（申込電柱）
                </Typography>
                <PositiveButton
                  id="id1062ContractDntSen"
                  onClick={() => {
                    this.doRef('線申込電柱');
                  }}
                  variant="contained"
                  size="large"
                  startIcon={<ListAltIcon />}
                >
                  <span>参照</span>
                </PositiveButton>
              </Grid>
              <Grid key="key1062Sen" item>
                <Typography variant="h5" gutterBottom className={classes.title}
                  style={{width: '210px'}}>
                  線設備（径間）
                </Typography>
                <PositiveButton
                  id="id1062Sen"
                  onClick={() => {
                    this.doRef('径間');
                  }}
                  variant="contained"
                  size="large"
                  startIcon={<ListAltIcon />}
                >
                  <span>参照</span>
                </PositiveButton>
              </Grid>
            </Grid>
          }
          {!this.isSen &&
            <Grid
              container
              spacing={1}>
              <Grid key="key1062ContractDntTen" item>
                <Typography variant="h5" gutterBottom className={classes.title}>
                  点設備（申込電柱）
                </Typography>
                <PositiveButton
                  id="id1062ContractDntTen"
                  onClick={() => {
                    this.doRef('点申込電柱');
                  }}
                  variant="contained"
                  size="large"
                  startIcon={<ListAltIcon />}
                >
                  <span>参照</span>
                </PositiveButton>
              </Grid>
            </Grid>
          }
          <br></br>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  orderResult: PropTypes.object,
  setGmnWarning: PropTypes.object,
  rootFiles: PropTypes.array,
  horizonFiles: PropTypes.array,
  specFiles: PropTypes.array,
  otherFiles: PropTypes.array,
  pictureFiles: PropTypes.array,
  iskkFiles: PropTypes.array,
  contentDocuments: PropTypes.array,
  formalContentDocuments: PropTypes.array,
  attachedFile: PropTypes.array,
  stbType: PropTypes.string,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetFormalOrderInfo: PropTypes.func,
  doSetReferenceMode: PropTypes.func,
  doUpdateFormalOrder: PropTypes.func,
  doClearFormalOrderInfo: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  const initValues = {

  };
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'order') ?
      state.attachorder.orderInfo.order : null,
    contentDocuments: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'contentDocuments') ?
      state.attachorder.orderInfo.contentDocuments : null,
    formalContentDocuments: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'formalContentDocuments') ?
      state.attachorder.orderInfo.formalContentDocuments : null,
    attachedFile: state.common.attachedFileList,
    orderResult: state.attachorder.orderResult,
    setGmnWarning: state.common.setGmnWarning,
    stbType: state.common.stbType,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetFormalOrderInfo: attachorderOperations.doGetFormalOrderInfo,
  doSetReferenceMode: commonOperations.doSetReferenceMode,
  doUpdateFormalOrder: attachorderOperations.doUpdateFormalOrder,
  doClearFormalOrderInfo: attachorderOperations.doClearFormalOrderInfo,
};

const FORM_NAME = 'FormalConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

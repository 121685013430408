import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {Divider, Grid} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import {isNullOrWhiteSpace} from '@grapecity/wijmo';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';

import {renderTextField, renderSelect, renderCheckbox} from '../../../../../atoms/CustomPart.js';
import {createList, createSearchList, changePrefectures} from '../../../../../../common/ComboParts.js';

import {changeDateFormat, getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import * as validateRule from '../../../../../validateRule.js';

// スタイル
const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});


// 移設依頼ステータス
const isetuIraiStatusList = [
  {'Code__c': 1, 'Name': '依頼中'},
  {'Code__c': 2, 'Name': '竣工済'},
];

// 検索フィールド
const appFields = [
  'toLabel(IsetuIraiStatus__c)IsetuIraiStatus', // 移設依頼ステータス
  'IsetuIraiCarryOutDate__c', // 移設依頼発信年月日
  'DesignCompanySyozoku__c', // 設計会社／所属
  'IsetuIraiNo__c', // 一連番号
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefectures', // 都道府県
  'KoziPlaceMunicipalities__c', // 市区町村
  'KoziPlacePlace__c', // 場所
  'DntNo__c', // 電柱番号
  'KoziOverview__c', // 工事概要
  'IsetuCompHopeDateTimeOfDesign__c', // 移設完了希望日（設計時）
  'KoziScheduleSeason__c', // 工事予定時期
  'KoziScheduleSeasonHosoku__c', // 工事予定時期補足
  'IsetuIraiDesignDocumentNo__c', // 設計書番号
  'IsetuIraiHonsu__c', // 移設依頼本数
  'DesignTantoName__c', // 設計担当者名
  'IsetuCompHopeDateTimeOfContact__c', // 移設完了希望日（工事日連絡時）
  'DrIsnKoziDate__c', // 電力移線工事日
  'DrIsnKoziDateChange__c', // 電力移線工事日（変更）
  'KoziCompanySyozoku__c', // 工事会社/所属
  'KoziTantoName__c', // 工事担当者名
  // 画面表示以外
  'Id',
  'IsetuIraiStatus__c', // 移設依頼ステータス
  'KoziStop__c', // 工事中止
  'KoziHold__c', // 工事保留
  'KoziDateConfirmRegistDate__c', // 工事日確定登録日
];

// 移設依頼設計書テーブル検索時のソート条件
const appSortParams = {
  IsetuIraiCarryOutDate__c: 1, // 移設依頼発信年月日
};

/**
 * 移設依頼工事日連絡一覧
 * 画面ID:1421
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: true,
    };
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
    * 初期処理
    */
  async componentDidMount() {
    // FrexGridに初期値設定
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1421', []);
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.doClearList();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('designDocumentList') &&
      this.props.designDocumentList ? this.props.designDocumentList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
  * ボタンの活性、非活性化
  */
  checkHandler = () => {
    try {
      let isDisabled = true;
      if (this.gridRef && this.gridRef.current &&
          this.gridRef.current.selectedItemCount() == 1) {
        isDisabled = false;
      }
      this.setState({disableButton: isDisabled});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索処理
   */
  doSearch = async () => {
    try {
      const {values} = this.props;
      // 検索条件生成
      const conditions = this.createConditions(values);
      // 検索
      await this.props.doGetDesignDocumentTypeList(
          conditions, appFields, appSortParams, 'getKoziResDesignDocumentList');
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('designDocumentList') ||
          this.props.designDocumentList.length <= 0 ) {
        this.props.doShowMessage({message: 'CE0016',
        });
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }
      // FrexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1421', 'Id', true);
        this.gridRef.current.setInitItems('1421', this.props.designDocumentList);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索条件生成
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
  */
  createConditions = (values) => {
    const conditions = {};
    // レコードタイプが移設依頼設計書のデータのみ
    conditions['RecordType.DeveloperName'] = 'IsetuIraiDesignDocument';
    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }

      switch (key) {
        case 'IsetuIraiStatus':
          // 移設依頼ステータスの置き換えは、API側で行うため、そのまま
          conditions[key] = values[key];
          break;
        case 'KoziPlacePrefectures':
          conditions['KoziPlacePrefectures__c'] = values[key];
          break;
        case 'KoziStop__c':
          if (values[key]) {
            // 工事中止／保留チェック時は、工事中止または工事保留のデータのみ表示する
            conditions['KoziStop__c_EQ'] = values[key];
          } else {
            // 工事中止／保留未チェック時は、工事中止または工事保留のデータ以外を表示する
            conditions['KoziStop__c'] = values[key];
            conditions['KoziHold__c'] = values[key];
          }
          break;
        case 'KoziScheduleSeason__c_From':
          // 工事予定時期は、日は01で登録
          conditions['KoziScheduleSeason__c_From'] = values[key] + '-01';
          break;
        case 'KoziScheduleSeason__c_To':
          // 工事予定時期は、日は01で登録
          conditions['KoziScheduleSeason__c_To'] = values[key] + '-01';
          break;
        default:
          conditions[key] = values[key];
          break;
      }
    }

    // 工事中止／保留チェック時は、移設依頼ステータスを無視する
    if (values.hasOwnProperty('KoziStop__c') && values['KoziStop__c']) {
      delete conditions.IsetuIraiStatus;
    }

    return conditions;
  }

  /**
   * 工事日入力ボタン押下
   *
   * @return {void}
   */
  doInput = async () => {
    try {
      // ラジオボタンが未選択の場合はエラー
      const selectedItemCount = this.gridRef.current.selectedItemCount();
      if (selectedItemCount == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['編集'],
          },
        });
        return;
      }

      const selectedItems = this.gridRef.current.selectedItems;
      this.props.doSetDesignDocumentId(selectedItems[0].dataItem.Id);
      this.props.history.push({pathname: '/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/Input'});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 工事日連絡共架者一覧ボタン押下
   *
   * @return {void}
   */
  doKyogasyaList = async () => {
    try {
      // ラジオボタンが未選択の場合はエラー
      const selectedItemCount = this.gridRef.current.selectedItemCount();
      if (selectedItemCount == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['編集'],
          },
        });
        return;
      }

      // 工事日情報（工事日または工事中止）が未入力の場合はエラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (isNullOrWhiteSpace(
          selectedItems[0].dataItem.KoziDateConfirmRegistDate__c)) {
        this.props.doShowMessage({
          message: {
            id: 'ME0181',
          },
        });
        return;
      }

      this.props.doSetDesignDocumentId(selectedItems[0].dataItem.Id);
      this.props.history.push({pathname: '/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/KyogasyaList'});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    this.props.doClearDesignDocumentTypeList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1421', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    // 工事予定時期
    if (e.getColumn().binding === 'KoziScheduleSeason__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        const ret = changeDateFormat(lb.collectionView.items[index].value, 'YYYY年MM月');
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true, // 列フィルター有効
      headersVisibility: 'All', // 行ヘッダ、列ヘッダの表示設定
      allowDragging: 'None', // 行、列のD&D設定
      counterOn: false, // グリッドに設定したデータ件数表示有無を設定
      isReadOnly: true,
      style: {maxHeight: '400px'}, // グリッドのスタイル設定
      checkedFunction: this.checkHandler,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <div className='container-fluid'>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='IsetuIraiStatus' header='移設依頼ステータス' dataType='String' width={165}/>
          <FlexGridColumnGroup binding='IsetuIraiCarryOutDate__c' header='移設依頼発信年月日' dataType='Date' width={170}>
            <FlexGridCellTemplate
              cellType='Cell'
              template={ (context) => {
                return changeDateFormat(context.item.
                    IsetuIraiCarryOutDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DesignCompanySyozoku__c' header='設計会社／所属' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuIraiNo__c' header='一連番号' dataType='String' width={100}/>
          <FlexGridColumnGroup binding='KoziPlacePrefectures' header='都道府県' dataType='String' width={100}/>
          <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType='String'/>
          <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType='String'/>
          <FlexGridColumnGroup binding='DntNo__c' header='電柱番号' dataType='String'/>
          <FlexGridColumnGroup binding='KoziOverview__c' header='工事概要' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuCompHopeDateTimeOfDesign__c' header='移設完了希望日（設計時）' dataType='Date' width={210}>
            <FlexGridCellTemplate
              cellType='Cell'
              template={ (context) => {
                return changeDateFormat(
                    context.item.
                        IsetuCompHopeDateTimeOfDesign__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziScheduleSeason__c' header='工事予定時期' dataType='Date'>
            <FlexGridCellTemplate cellType='Cell'
              template= {(context) => {
                return changeDateFormat(
                    context.item.
                        KoziScheduleSeason__c, 'YYYY年MM月');
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziScheduleSeasonHosoku__c' header='工事予定時期補足' dataType='String' width={160}/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocumentNo__c' header='設計書番号' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuIraiHonsu__c' header='工事対象本数' dataType='Number'/>
          <FlexGridColumnGroup binding='DesignTantoName__c' header='設計担当者名' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuCompHopeDateTimeOfContact__c' header='移設完了希望日（工事日連絡時）' dataType='Date' width={260}>
            <FlexGridCellTemplate
              cellType='Cell'
              template={ (context) => {
                return changeDateFormat(
                    context.item.
                        IsetuCompHopeDateTimeOfContact__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrIsnKoziDate__c' header='電力移線工事日' dataType='Date'>
            <FlexGridCellTemplate
              cellType='Cell'
              template={ (context) => {
                return changeDateFormat(
                    context.item.DrIsnKoziDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrIsnKoziDateChange__c' header='電力移線工事日（変更）' dataType='Date' width={200}>
            <FlexGridCellTemplate cellType='Cell'
              template={ (context) => {
                return changeDateFormat(
                    context.item.
                        DrIsnKoziDateChange__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziCompanySyozoku__c' header='工事会社/所属' dataType='String'/>
          <FlexGridColumnGroup binding='KoziTantoName__c' header='工事担当者名' dataType='String'/>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {
      classes,
      generalPurposeMap,
      reset,
      handleSubmit,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<EditIcon />}
            variant="contained"
            size="large"
            disabled={this.state.disableButton}
            onClick={
              this.doInput
            }
          >
            <span>工事日入力</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            variant="contained"
            size="large"
            disabled={this.state.disableButton}
            onClick={
              this.doKyogasyaList
            }
          >
            <span>工事日連絡共架者一覧</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderSelect}
                  label="移設依頼ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {createSearchList(isetuIraiStatusList, 'Code__c', 'Name', null, 'false')}
                </Field>
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuIraiCarryOutDate__c_From"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_From"
                    label="移設依頼発信年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuIraiCarryOutDate__c_To"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(8),
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="key1-6" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap != null &&
                    createList(generalPurposeMap.PrefCode)
                  }
                </Field>
              </Grid>

              <Grid key="key1-8" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-10" item xs={12} sm={6} md={3}>
                <Field
                  name="DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                />
              </Grid>

              <Grid key="key1-11" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

              <Grid key="key1-12" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_From"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_From"
                    label="移設完了希望日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_To"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-13" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="KoziScheduleSeason__c_From"
                    accessibilitylabel="KoziScheduleSeason__c_From"
                    label="工事予定時期"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="KoziScheduleSeason__c_To"
                    accessibilitylabel="KoziScheduleSeason__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-14" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="DrIsnKoziDate__c_From"
                    accessibilitylabel="DrIsnKoziDate__c_From"
                    label="電力移線工事日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="DrIsnKoziDate__c_To"
                    accessibilitylabel="DrIsnKoziDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-15" item xs={12} sm={3} md={3}>
                <div style={{paddingLeft: '15px'}}>
                  <Field className={classes.checkbox}
                    name="KoziStop__c"
                    component={renderCheckbox}
                    label="工事保留／中止"
                  />
                </div>
              </Grid>

              <Grid key="key1-16" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.doSearch)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>

          </PaperPart>

          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  designDocumentList: PropTypes.array,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  doGetDesignDocumentTypeList: PropTypes.func,
  doClearDesignDocumentTypeList: PropTypes.func,
  doSetDesignDocumentId: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  designDocumentList: state.isetuIrai.designDocumentList,
  initialValues: {
    IsetuIraiStatus: 1, // 依頼中
    KoziStop__c: false, // 工事中止／保留未チェック
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetDesignDocumentTypeList: isetuIraiOperations.doGetDesignDocumentTypeList,
  doClearDesignDocumentTypeList:
    isetuIraiOperations.doClearDesignDocumentTypeList,
  doSetDesignDocumentId: isetuIraiOperations.doSetDesignDocumentId,
};

const FORM_NAME = 'IsetuIraiKoziDateContactList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Moment from 'moment';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {InputDate} from '@grapecity/wijmo.input';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {commonOperations} from '../../../../../../reducks/common/index.js';
import KyogaCompanyInformation from '../../../../../molecules/KyogaCompanyInformation.js';
import {changeDateFormat, createTyohyoPdf, API_CODE,
  execApexRestApi, getResponseResult, getDataFromGeneralPurposeMap, changeDateJst} from '../../../../../../common/common.js';

import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {changePrefectures, createSearchList, createSearchListEx} from '../../../../../../common/ComboParts.js';
import {attachorderOperations} from '../../../../../../reducks/attachorder/index.js';
import * as validateRule from '../../../../../validateRule.js';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';
import {orderBasicFields} from '../../../../../../common/SFFields.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

const appFields=[
  'Id',
  'OrderNo__c', // 申込番号
  'NWZgsyo__r.Name', // 事業所
  'toLabel(KoziPlacePrefectures__c)', // 都道府県
  'KoziPlaceMunicipalities__c', // 市区町村
  'KoziPlacePlace__c', // 場所
  'toLabel(KyogaType__c)', // 共架種別
  'toLabel(StbType__c)', // 設備種別
  'KoziTitle__c', // 工事件名
  'OrderDate__c', // 申込日
  'KyogaKoziScheduleDate__c', // 共架工事予定日
  'AttentionZikoCheckDate__c', // 留意事項確認日
  'KoziPlaceMainDntNo__c', // 代表電柱番号
  'KoziPlaceMainDntNo_SenroName__c', // 線路名
  'Dsp_KoziPlaceMainDntNo__c', // 代表電柱番号
  'ProprietyJudgeOrderNo__c', // 可否判定申込番号
  'StartNotificationDate__c', // 共架開始通知日
  'OrderStatus__c', // 申込ステータス
  'toLabel(OrderStatus__c)OrderStatusName', // 申込ステータス
  'LastModifiedDate',
];
const appSortParams={StartNotificationDate__c: -1};

const subQuery = [{
  'fields': [
    'Id',
    'BillingCategory__c',
    'BillingAmount__c',
    'ConsumptionTax__c',
    'TargetPeriodFrom__c', // 期中共架料の場合は、期首年月または開始年月
  ],
  'conditions': {
    'RecordType.DeveloperName': 'DuringKyogaCost', // 期中共架料
    'BillingCategory__c': '11', // 期中共架料
    'Order__r.StartNotificationDate__c_From': '2023-04-01', // マスタの切替日時を設定する
  },
  'table': 'BillingDetails__r',
}];

/**
 * 共架開始通知一覧
 * 画面ID:1141
 */

class KyogaStartNotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.selector = null;
    this.state = {
      retButtonDisabled: true,
      saveButtonDisabled: true,
    };
    this.gridRef = React.createRef();

    this.dateEditor = new InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // グリッド初期化
      this.gridRef.current.setScreenState('1141');
      const cv = createCollectionView([], false, {}, null);
      this.gridRef.current.getGrid().itemsSource = cv;
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doSetYbdsPath(null);
    this.props.doSetOrderNo(null);
    this.props.doClearOrderSubList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1141', 'Id');
    }
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      const selectedItems =
        this.gridRef && this.gridRef.current ?
          this.gridRef.current.selectedItem() : [];
      let temp = false;
      if (selectedItems.length>0) {
        for (const item in selectedItems ) {
          if (isNullOrWhiteSpace(
              selectedItems[item].dataItem.AttentionZikoCheckDate__c)) {
            temp = true;
            break;
          }
        }
      } else {
        temp=true;
      }

      this.setState({
        retButtonDisabled: selectedItems.length != 1,
        saveButtonDisabled: temp,
      });
    } catch (error) {
      this.setState({
        retButtonDisabled: true,
        saveButtonDisabled: true,
      });
    }
  }

  /**
  * 検索ボタン押下時
  * @param {boolean} isClear 選択行をクリアするどうか
  */
  searchOrder = async (isClear=false) =>{
    try {
      const {generalPurposeMap} = this.props;
      const listKirikaeDt1 = getDataFromGeneralPurposeMap(generalPurposeMap, 'KirikaeDt1', 1);

      const wkData = JSON.parse(JSON.stringify(this.props.values));

      wkData['OrderCategory__c'] = '02'; // 本申込
      wkData['OrderStatus__c'] = ['40', '41'];
      wkData['RecordType.DeveloperName'] = 'MainOrder';
      // 事業所
      if ('NWZgsyo__r_Code__c' in wkData) {
        wkData['NWZgsyo__r.Code__c']=wkData.NWZgsyo__r_Code__c;
        delete wkData.NWZgsyo__r_Code__c;
      }

      // 共架工事予定日
      if ('kyogaConstructionScheduledDate' in wkData) {
        if (wkData['kyogaConstructionScheduledDate'] == '0') {
          wkData['KyogaKoziScheduleDate__c_EQ']=null;
        } else if (wkData['kyogaConstructionScheduledDate'] == '1') {
          wkData['KyogaKoziScheduleDate__c_NE']=null;
        }
        delete wkData.kyogaConstructionScheduledDate;
      }
      // 開始通知日が切替日時より前の申込は料金に関わる情報を表示しない。
      if (listKirikaeDt1 && listKirikaeDt1.ValueDateTime__c) {
        const kirikaeDate = changeDateJst(listKirikaeDt1.ValueDateTime__c, 'YYYY-MM-DD');
        if (kirikaeDate) {
          subQuery[0].conditions['Order__r.StartNotificationDate__c_From'] = kirikaeDate;
        }
      }
      const result = await this.props.doGetOrderSubList(
          wkData, appFields, appSortParams, subQuery);
      // 件数超過エラー
      const maxKensu = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      const dataLen = result.data.body.data ?
        result.data.body.data.length : 0;
      if (dataLen >= maxKensu) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderSubList') ||
        this.props.orderSubList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }
      this.gridRef.current.saveScreenState('1141', 'Id', (isClear === true));
      const list = this.convertList(this.props.orderSubList);
      const cv = createCollectionView(list, false, {}, null);
      this.gridRef.current.getGrid().itemsSource = cv;
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込情報', '検索'],
        }});
    }
  }

  /**
   * 開始通知書ボタン押下時
   */
  downloadStartNotification = async () =>{
    try {
      const {userInfo, generalPurposeMap} = this.props;

      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItem();
      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }
      const item = selectedItems[0];
      const intOrderStatus = parseInt(item.dataItem.OrderStatus__c);
      if (intOrderStatus < 40) {
        const value = generalPurposeMap.OrderStatus.filter((item)=>item.Code__c=='40');
        const msg = value ? value.length>0 ? value[0].Name : '' : '';
        this.props.doShowMessage({
          message: {
            id: 'CE0102',
            values: ['申込', msg],
          },
        });
        return;
      }

      const conditions = {};
      conditions['OrderNo__c'] = item.dataItem.OrderNo__c;

      const ret = await createTyohyoPdf(userInfo, 'StartNotification', conditions, '開始通知書.pdf');
      if (!ret) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['開始通知書', 'ダウンロード'],
          },
        });
      }
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['開始通知書', 'ダウンロード'],
        },
      });
    }
  }

  /**
   * 柱上作業時の留意事項ボタン押下時
   */
  downloadAttentionZiko = async () =>{
    try {
      const {userInfo, generalPurposeMap} = this.props;
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItem();
      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }
      const item = selectedItems[0];
      const intOrderStatus = parseInt(item.dataItem.OrderStatus__c);
      if (intOrderStatus < 40) {
        const value = generalPurposeMap.OrderStatus.filter((item)=>item.Code__c=='40');
        const msg = value ? value.length>0 ? value[0].Name : '' : '';
        this.props.doShowMessage({
          message: {
            id: 'CE0102',
            values: ['申込', msg],
          },
        });
        return;
      }

      const targetId = item.dataItem.Id;
      const targetOrderNo = item.dataItem.OrderNo__c;

      const ret = await createTyohyoPdf(userInfo, 'OnDntWorkNotice', {'OrderNo__c': targetOrderNo}, '柱上作業時の留意事項.pdf');
      if (!ret) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['柱上作業時の留意事項', 'ダウンロード'],
          },
        });
        return;
      }

      if (isNullOrWhiteSpace(item.dataItem.AttentionZikoCheckDate__c)) {
        const conditions = {
          'Id': targetId,
          'LastModifiedDate': item.dataItem.LastModifiedDate,
        };
        const fields = {};
        const body={
          'conditions': conditions,
          'fields': fields,
        };
        await execApexRestApi( userInfo, 'ApiKyogaStartNotification/AttentionZikoCheckDateUpdate', body);

        // 更新後再検索
        await this.searchOrder();
      }
      // 検索前に選択していた列を再選択
      const grid= this.gridRef.current.getGrid();
      if (!grid) return;

      for (const row of grid.rows) {
        const dataItem = row.dataItem;
        if (item && Object.hasOwnProperty.call(dataItem, 'Id') &&
            dataItem['Id'] == targetId) {
          row.isSelected = true;
          this.gridRef.current.onItemChecked();
          return;
        }
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 受領/登録ボタン押下時
   */
  doSave = async () => {
    try {
      const {userInfo} = this.props;
      const selectedItems = this.gridRef.current.selectedItem();
      // チェックボックスのチェック数が0の場合エラー
      if (selectedItems.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['申込'],
          },
        });
        return;
      }

      for (let irow=0; irow<selectedItems.length; irow++) {
        // 共架工事予定日必須チェック
        let wkKyogaKoziScheduleDate = changeDateFormat(
            selectedItems[irow]._data.KyogaKoziScheduleDate__c);
        if (isNullOrWhiteSpace(wkKyogaKoziScheduleDate)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0017',
              values: ['共架工事予定日'],
            },
          });
          return;
        }
        // 共架工事予定日範囲チェック
        let wkStartNotification = changeDateFormat(
            selectedItems[irow]._data.StartNotificationDate__c);
        if (!isNullOrWhiteSpace(wkKyogaKoziScheduleDate) &&
            !isNullOrWhiteSpace(wkStartNotification) &&
            (wkKyogaKoziScheduleDate < wkStartNotification ||
              wkKyogaKoziScheduleDate >
              new Moment(wkStartNotification, 'YYYY/MM/DD').add(12, 'months').add(-1, 'days').format('YYYY/MM/DD'))) {
          this.props.doShowMessage({
            message: {
              id: 'CE0026',
              values: ['共架工事予定日', '共架開始通知日から１年以内'],
            },
          });
          return;
        }
      }

      const orders = selectedItems.map((item)=>item.dataItem);
      for (const order of orders) {
        order.KyogaKoziScheduleDate__c =
          changeDateFormat(order.KyogaKoziScheduleDate__c, 'YYYY-MM-DD');
      }
      const fields = {};
      const body = {orders, fields};

      const ret = await execApexRestApi( userInfo, 'ApiKyogaStartNotification/KyogaKoziScheduleDateRegister', body);
      const resResult = getResponseResult(ret, ['共架工事予定日', '保存']);
      if (resResult.errorCode == API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CI0009',
            values: ['共架工事予定日の保存'],
          },
          action: async () => {
            await this.searchOrder(true);
          },
        });
      } else {
        if (resResult.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: resResult.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため再検索
              await this.searchOrder();
            },
          });
        } else {
          this.props.doShowMessage({
            message: resResult.messages,
          });
        }
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['共架工事予定日', '保存'],
        },
      });
    }
  }

  /**
   * 申込内容確認
   */
  confirmOrderContent = async () => {
    try {
      const {doSetOrderId, doGetOrderInfo, history} = this.props;
      if (this.gridRef.current.selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }
      const targetOrder = this.gridRef.current.selectedItems[0].dataItem;
      await doSetOrderId(targetOrder.Id);
      await doGetOrderInfo({'Id': targetOrder.Id}, orderBasicFields);
      history.push({pathname: '/SenFormalOrderBasicInfo'});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込一覧を表示用データに変換する
   * @param {array} orderList 申込一覧
   * @return {array} 変換した申込一覧
   */
  convertList(orderList) {
    const list = [];
    for (const data of orderList) {
      let copyData = {};
      Object.assign(copyData, data);
      // 期中共架料を計算
      copyData['BillingAmount'] = null;
      copyData['TargetPeriodFrom'] = ''; // 期中共架料起算月
      if (Object.hasOwnProperty.call(data, 'BillingDetails__r') &&
        data.BillingDetails__r &&
        data.BillingDetails__r.totalSize > 0) {
        copyData['TargetPeriodFrom'] = changeDateFormat(
            data.BillingDetails__r.records[0].TargetPeriodFrom__c);
        let totalAmount = 0;
        for (const billingDetail of data.BillingDetails__r.records) {
          const amount = billingDetail.BillingAmount__c ?
            billingDetail.BillingAmount__c : 0;
          const tax = billingDetail.ConsumptionTax__c ?
            billingDetail.ConsumptionTax__c : 0;
          totalAmount += (amount + tax);
        }
        copyData['BillingAmount'] = totalAmount;
      }
      // 共架開始通知日,申込年月日を変換
      copyData.StartNotificationDate__c =
          changeDateFormat(data.StartNotificationDate__c);
      copyData.OrderDate__c =
          changeDateFormat(data.OrderDate__c);

      // 共架工事予定日を変換
      if (!isNullOrWhiteSpace(
          data.KyogaKoziScheduleDate__c)) {
        copyData.KyogaKoziScheduleDate__c =
            changeDateFormat(data.KyogaKoziScheduleDate__c);
      }
      list.push(copyData);
    }
    return list;
  }

  /**
   * 一覧表示
   * @return {object}
  */
  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      useStore: false,
      style: {height: '300px'},
      checkedFunction: this.checkStateUpdate,
    };
    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding= 'OrderNo__c' header= '申込番号' dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup binding= 'StbType__c' header= '設備種別' dataType="String" cssClass="uneditableCell" isReadOnly= {true} width={100}/>
          <FlexGridColumnGroup binding= 'KyogaType__c' header= '共架種別' dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup binding= 'OrderDate__c' header= '申込年月日' dataType="Date" cssClass="uneditableCell" isReadOnly= {true} width={110}/>
          <FlexGridColumnGroup binding= 'NWZgsyo__r.Name' header= '事業所名' dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup binding= 'KoziTitle__c' header= '工事件名' dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup header="工事場所" align="center">
            <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String" cssClass="uneditableCell" isReadOnly= {true}/>
            <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String" cssClass="uneditableCell" isReadOnly= {true}/>
            <FlexGridColumnGroup binding="KoziPlacePrefectures__c" header="都道府県" dataType="String" cssClass="uneditableCell" isReadOnly= {true} width={100}/>
            <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String" cssClass="uneditableCell" isReadOnly= {true}/>
            <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String" cssClass="uneditableCell" isReadOnly= {true}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='ProprietyJudgeOrderNo__c' header='可否判定\n申込番号' dataType="String" cssClass="uneditableCell" isReadOnly= {true}/>
          <FlexGridColumnGroup binding= 'KyogaKoziScheduleDate__c' header= '共架工事予定日' dataType="Date" isReadOnly= {false}
            editor= {this.dateEditor}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="StartNotificationDate__c" header='共架開始\n通知日' dataType="Date" cssClass="uneditableCell" isReadOnly= {true} width={110}/>
          <FlexGridColumnGroup binding="BillingAmount" header="期中共架料" dataType="Number" cssClass="uneditableCell" isReadOnly= {true}/>
          <FlexGridColumnGroup binding="TargetPeriodFrom" header="期中共架料\n起算月" dataType="Date" cssClass="uneditableCell" isReadOnly= {true}/>
          <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType="String" cssClass="uneditableCell" isReadOnly= {true}/>
        </CustomFlexGrid>
      </div>
    );
  }


  render() {
    const {classes, generalPurposeMap, userInfo,
      handleSubmit, reset} = this.props;
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<GetAppIcon />}
            onClick={handleSubmit(this.downloadStartNotification)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>開始通知書</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<GetAppIcon />}
            onClick={handleSubmit(this.downloadAttentionZiko)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>柱上作業時の留意事項</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn3" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.saveButtonDisabled}
          >
            <span>受領／登録</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn4" item>
          <PositiveButton
            startIcon={<ListAltIcon />}
            onClick={handleSubmit(this.confirmOrderContent)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>申込内容確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn5" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <KyogaCompanyInformation companyName={userInfo.Account.Name} />
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid key="key1-1" item xs={12} sm={3}>
                <Field
                  name="OrderNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="申込番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1-2" item xs={12} sm={3}>
                <Field
                  name="NWZgsyo__r_Code__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="事業所"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchListEx(generalPurposeMap.NWZgsyo, 'Code__c', 'Name', null, {Code__c: ['1001', '1065']})}
                </Field>
              </Grid>
              <Grid key="key1-3" item xs={12} sm={3}>
                <Field
                  name="StbType__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                     createSearchList(generalPurposeMap.StbType,
                         'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key1-4" item xs={12} sm={3}>
                <Field
                  name="KyogaType__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key1-6" item xs={12} sm={3}>
                <Field
                  name="KoziTitle__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事件名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>
              <Grid key="key1-7" item xs={12} sm={3}>
                <Field
                  name="kyogaConstructionScheduledDate"
                  className={classes.fields}
                  component={renderSelect}
                  label="共架工事予定日"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                  type="date"
                >
                  {generalPurposeMap &&
                  createSearchList( generalPurposeMap.UmuCategory, 'Code__c', 'ValueString2__c')}
                </Field>
              </Grid>
              <Grid key="key1-12" item xs={12} sm={3}>
                <Field
                  id="id1-12"
                  name="KoziPlaceMainDntNo_SenroName__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="線路名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>
              <Grid key="key1-8" item xs={12} sm={3}>
                <Field
                  name="Dsp_KoziPlaceMainDntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>
              <Grid key="key1-10" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                >
                </Field>
              </Grid>
              <Grid key="key1-11" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key2-1" item xs={12} md={6} style={{position: 'relative'}}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="OrderDate__c_From"
                    accessibilitylabel="applicationDateFrom"
                    label="申込年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    name="OrderDate__c_To"
                    accessibilitylabel="applicationDateTo"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key2-2" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>
          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

KyogaStartNotificationList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func.isRequired,
  orderList: PropTypes.array,
  values: PropTypes.object,
  orderSubList: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doClearOrderSubList: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doGetOrderInfo: PropTypes.func,
  doGetOrderSubList: PropTypes.func,
  ybdsPath: PropTypes.string,
  orderNo: PropTypes.string,
  doSetYbdsPath: PropTypes.func,
  doSetOrderNo: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {
    kyogaConstructionScheduledDate: '0',
  };
  if (state.attachorder.orderNo &&
    state.common.ybdsPath == '/processProgressSearch') {
    initValues['OrderNo__c'] = state.attachorder.orderNo;
    initValues['kyogaConstructionScheduledDate'] = null;
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    orderSubList: state.attachorder.orderSubList,
    ybdsPath: state.common.ybdsPath,
    orderNo: state.attachorder.orderNo,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doClearOrderSubList: attachorderOperations.doClearOrderSubList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doGetOrderInfo: attachorderOperations.doGetOrderInfo,
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderSubList: attachorderOperations.doGetOrderSubList,
  doSetYbdsPath: commonOperations.doSetYbdsPath,
  doSetOrderNo: attachorderOperations.doSetOrderNo,
};

const FORM_NAME = 'KyogaStartNotificationList';

KyogaStartNotificationList = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(KyogaStartNotificationList));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(KyogaStartNotificationList),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Container from '@material-ui/core/Container';
import {withStyles} from '@material-ui/core/styles';

import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu.js';
import CommonTheme from '../../Theme.js';
import {isChudenNwContract, isSmartPhone} from '../../common/common.js';
import {authOperations} from '../../reducks/auth';
import {commonOperations} from '../../reducks/common';

import Conf from '../../config/config.js';

/** ユーザ管理(詳細)画面パス */
const USER_DETAIL_PATH = '/Other/UserManagement/UserManagement/InputSelf';

/* 重要事項説明画面パス */
const IMPORTANT_SUBJECTS_PATH = '/importantSubjects';

/** 重要連絡事項一覧画面パス */
const JUYO_ZIKO_LIST_PATH = '/Other/ContactZiko/JuyoZiko/List';

/** 重要連絡事項確認画面パス */
const JUYO_ZIKO_CONFIRM_PATH = '/Other/ContactZiko/JuyoZiko/Confirm';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    height: 'calc(100vh - 100px - 78px)',
    marginTop: '78px',
    background: '#f5f5f5',

  },
  bgImg: {
    backgroundImage: 'url(../../assets/images/bg-menu.png)',
    backgroundSize: 'cover',
  },
  container: {
    paddingTop: '8px',
  },
  appBarSpacer: theme.mixins.toolbar,
  layout: {
    width: 'auto',
    [theme.breakpoints.up(
        CommonTheme.breakpoints.values.lg + CommonTheme.menu.width)]: {
      maxWidth: 'calc(' + CommonTheme.breakpoints.values.lg + 'px + ' + CommonTheme.menu.width + 'px)',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  contentArea: {
    width: '100%',
    minHeight: 'calc(100vh - 100px - 78px - 8px)',
    overflow: 'hidden',
    [theme.breakpoints.up(
        CommonTheme.breakpoints.values.lg + CommonTheme.menu.width)]: {
      paddingLeft: theme.spacing(3),
    },
  },
  sideMenuArea: {
    width: CommonTheme.menu.width,
    background: CommonTheme.palette.primary.dark,
    color: CommonTheme.palette.primary.contrastText,
    marginTop: -8,
    marginLeft: -24,
    display: 'none',

    [theme.breakpoints.up(
        CommonTheme.breakpoints.values.lg + CommonTheme.menu.width)]: {
      display: 'inline',
    },
  },
  sideMenu: {
    marginTop: theme.spacing(1),
    position: 'absolute',
  },
  footerDummy: {
    height: 100,
  },
});

class MainContainer extends React.Component {
  /** タイムアウトチェックのタイマー */
  timer = null;

  /**
   * 初期処理。
   */
  async componentDidMount() {
    const {
      doCheckAccess,
      isKirikaeMae,
      props,
      userInfo,
    } = this.props;

    // historyはthis.props.propsから取得
    const history = props.history;
    // タイムアウトチェックの設定
    doCheckAccess();
    this.timer = setInterval(this.doCheckTime, 5000);

    // メールアドレスが設定されていない場合、ユーザ管理(詳細)画面へ遷移
    if (!userInfo.Email) {
      props.history.push(USER_DETAIL_PATH, null, 'CLEAR');
      return;
    }

    // 強制遷移を行うかどうか判定
    if (this.checkForceTransition(userInfo)) {
      // システム切替後で約款未承諾の場合は重要事項確認画面へ遷移
      if (!isKirikaeMae && !this.props.isTermsAccepted) {
        props.history.push(IMPORTANT_SUBJECTS_PATH, null, 'CLEAR');
        return;
      }

      // 約款承諾済かつ重要事項未確認の場合、重要連絡事項一覧画面へ遷移
      if (this.props.hasJuyoZiko &&
        history.location.pathname != JUYO_ZIKO_LIST_PATH &&
        history.location.pathname != JUYO_ZIKO_CONFIRM_PATH) {
        props.history.push(JUYO_ZIKO_LIST_PATH, null, 'CLEAR');
        return;
      }
    }
  }

  /**
   * 画面の強制遷移を行うかどうかの判定。
   *
   * 重要事項説明が未承諾の場合は重要事項説明画面、
   * 未確認の重要事項が登録されている場合は重要事項確認画面へ
   * 遷移を行うが、以下いずれかに該当する場合は強制遷移を行わないと判定する
   * ・ログインユーザの契約区分が「中国電力ネットワーク契約」
   * ・代行者で代行入力中
   *
   * @param {object} userInfo ユーザ情報
   * @return {boolean} 強制遷移を行う場合true
   */
  checkForceTransition = (userInfo) => {
    // 中国電力ネットワーク契約のユーザは強制遷移を行わない
    if (isChudenNwContract(userInfo)) {
      return false;
    }

    // 代行者の場合は強制遷移を行わない
    if (userInfo.DaikoUserId != null) {
      return false;
    }

    return true;
  }

  doCheckTime = () => {
    const {
      checkAccess,
      doLogout,
      doShowMessage,
      isLoggedIn,
    } = this.props;

    if (isLoggedIn) {
      // UTC変換した現時刻と保持している時刻の差（ms）を計測
      const dateArg = Date.now() + new Date().getTimezoneOffset() * 60000;
      const diffTime = (new Date(dateArg)).getTime() - checkAccess.time;
      const timeout = (Conf.timeout * 60);
      if (diffTime / 1000 > timeout) {
        clearInterval(this.timer);
        doLogout();
        doShowMessage({
          message: 'CE0109',
        });
      }
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  render = () => {
    const {
      bgImg,
      children,
      classes,
      disableSideMenu,
      footerBtn,
      props,
    } = this.props;

    let mainClasses = null;
    if (bgImg) {
      mainClasses = clsx(classes.layout, classes.content, classes.bgImg);
    } else {
      mainClasses = clsx(classes.layout, classes.content);
    }

    return (
      <main className={mainClasses}>
        <Container
          maxWidth={false}
          className={classes.container}>

          <Header {...props} disableSideMenu={disableSideMenu} />

          <div style={{display: 'flex'}}>

            {/* サイドメニュー指定がある場合 */}
            <>
              {!disableSideMenu &&
                <div className={classes.sideMenuArea}>
                  <div className={classes.sideMenu}>
                    <SideMenu {...props} />
                  </div>
                </div>
              }
            </>

            <div className={classes.contentArea}>
              {children}
            </div>

          </div>


          {/* フッター高さ分のダミー */}
          {isSmartPhone() &&
            <div className={classes.footerDummy}></div>
          }

          <Footer>
            {footerBtn}
          </Footer>
        </Container>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  checkAccess: state.common.checkAccess,
  isTermsAccepted: state.common.isTermsAccepted,
  hasJuyoZiko: state.common.hasJuyoZiko,
  isLoggedIn: state.auth.isLoggedIn,
  userInfo: state.auth.userInfo,
  isKirikaeMae: state.common.isKirikaeMae,
});

const mapDispatchToProps = {
  doCheckAccess: commonOperations.doCheckAccess,
  doLogout: authOperations.doLogoutOperation,
  doShowMessage: commonOperations.doShowMessage,
};

MainContainer.propTypes = {
  bgImg: PropTypes.bool,
  checkAccess: PropTypes.object.isRequired,
  children: PropTypes.object,
  classes: PropTypes.object,
  disableSideMenu: PropTypes.bool,
  doCheckAccess: PropTypes.func.isRequired,
  doLogout: PropTypes.func,
  doShowMessage: PropTypes.func,
  footerBtn: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  props: PropTypes.object,
  userInfo: PropTypes.object,
  isTermsAccepted: PropTypes.bool,
  hasJuyoZiko: PropTypes.bool,
  history: PropTypes.object.isRequired,
  isKirikaeMae: PropTypes.bool,
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MainContainer),
);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import SimpleTable from '../../../../../molecules/SimpleTable.js';
import {execApexRestApi, downloadAttachedFile, getToDate, convertCommaSeparated,
  API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {reduxForm, getFormValues} from 'redux-form';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';

const styles = (theme) => ({
});

// 申込テーブル検索時の検索対象のフィールド名
const appFields = ['OrderNo__c', 'KoziTitle__c', 'GaisanAmount__c', 'Fee__c',
  'DrSideKoziRequiredPeriod__c', 'KoziOverview__c',
  'GaisanConsiderResultDeadline__c',
  'GaisanAmountStatus__c', 'toLabel(RepairGaisanConsentStatus__c)',
  'OrderStatus__c', 'AnswerUmu__c',
  'toLabel(DrSideKoziUmu__c)DrSideKoziUmu',
  'Id', 'LastModifiedDate',
];

/** 申込、添付ファイル名検索時の検索対象 */
const subquery = [
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['A17'],
    },
    'table': 'ContentDocumentLinks',
  },
];

/**
 * 概算費用算定結果確認
 * 画面ID:1482
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,  // ボタン非活性有無
      dataList: [],
    };

    // 添付ファイル
    this.attachedFile = {};
  }

  /**
  * 初期処理
  */
  async componentDidMount() {
    try {
      if (this.props.orderId) {
        this.searchOrder();
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderSubList();
    // 画面表示内容クリア
    for (const data of this.state.dataList) {
      data.value = '';
    }
  }

  /**
   * 検索処理
   */
  searchOrder = async () => {
    if (this.props.orderId) {
      // 申込テーブル検索時の検索条件
      const appConditions = {
        'Id': this.props.orderId,
        'RecordType.DeveloperName': 'RepairIrai',
      };
        // 申込テーブルからデータ取得
      const result = await this.props.doGetOrderSubList(
          appConditions, appFields, null, subquery);
      const errCode = result ? result.data ? result.data.body ?
        result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000' ||
          !this.props.hasOwnProperty('orderSubList') ||
           this.props.orderSubList.length <= 0 ) {
        this.sysErr();
        return;
      }

      if (this.props.orderSubList) {
        // Mapに申込テーブル検索結果を保存
        const appData = this.props.orderSubList[0];
        const wkDataMap = new Map();
        for (const key of Object.keys(appData)) {
          wkDataMap.set(key, appData[key]);
          // 添付ファイルのハイパーリンク設定
          if (key === 'ContentDocumentLinks' && appData[key]) {
            const items = appData[key].records[0].
                ContentDocument.LatestPublishedVersion;
            this.attachedFile = {
              'AttachedFileType_Type': items.AttachedFileType__c,
              'AttachedFileType_Name': items.PathOnClient,
              'AttachedFileType_Url': items.VersionData};
          }
        }

        const dataList = [
          {key: 'OrderNo__c', name: '改修依頼番号', value: ''},
          {key: 'KoziTitle__c', name: '工事件名', value: ''},
          {key: 'GaisanAmount__c', name: '改造工事費用（税抜）', value: ''},
          {key: 'GaisanAmountBreakdownDocument__c', name: '改造工事費用内訳書', value: '', onClick: this.doDownloadAttachedFile, link: '#'},
          {key: 'DrSideKoziRequiredPeriod__c', name: '改造工事期間（目安）〔か月〕', value: ''},
          {key: 'KoziOverview__c', name: '改造工事概要', value: ''},
          {key: 'GaisanConsiderResultDeadline__c', name: '回答期限', value: '', format: 'date'},
          {key: 'StatusName', name: 'ステータス', value: ''},
          {key: 'RepairGaisanConsentStatus__c', name: '見積結果確認状況', value: ''},
          {key: 'DrSideKoziUmu', name: '改造工事', value: ''},
        ];

        // 取得データを画面表示
        for (const data of dataList) {
          if (wkDataMap.has(data.key)) {
            data.value = wkDataMap.get(data.key);
            if (data.key == 'GaisanAmount__c') {
              if (wkDataMap.has('Fee__c') && wkDataMap.get('Fee__c')) {
                data.value += wkDataMap.get('Fee__c');
              }
            }
          }

          // 概算金額内訳書の場合、添付ファイル名を設定
          if (data.key == 'GaisanAmountBreakdownDocument__c') {
            if (this.attachedFile) {
              data.value = this.attachedFile.AttachedFileType_Name;
            }
          }

          // ステータス
          if (data.key == 'StatusName') {
            data.value = '';
            if (appData['OrderStatus__c'] == 15) { // 概算金額確定
              data.value = '未回答';
              // 概算金額検討結果期限日(GaisanConsiderResultDeadline)が超過していた場合、期限切れを設定
              if (getToDate() > appData.GaisanConsiderResultDeadline__c) {
                data.value = '期限切れ';
              }
            } else if (appData['OrderStatus__c'] == 92) { // 完結（取止め）
              data.value = '取下げ';
            } else {
              if (appData['OrderStatus__c'] >= 16) { // 概算金額受理以降
                data.value = '承諾';
              }
            }
          }

          // 概算金額の表示形式変更
          if (data.key == 'GaisanAmount__c') {
            // 算定前の場合（申込ステータスが14以下）、概算金額を空設定
            if (appData['OrderStatus__c'] <= 14) {
              data.value = '';
            } else {
              data.value = data.value ? convertCommaSeparated(data.value) + '円' : '';
            }
          }

          // 電力側工事所要期間の表示形式変換
          if (data.key == 'DrSideKoziRequiredPeriod__c') {
            data.value = data.value ?
              Number(data.value).toFixed(1) : data.value;
          }
        }
        // 再描画
        this.setState({
          dataList: dataList,
        });
      }
    }
    // ボタンの活性、非活性化
    this.checkStateUpdate();
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    // 未処理（概算費用ステータスが算定済以上でかつ回答有無が無）判定
    if (this.props.orderSubList &&
       (this.props.orderSubList[0].hasOwnProperty('OrderStatus__c')) &&
       (this.props.orderSubList[0].hasOwnProperty('AnswerUmu__c'))) {
      // 申込ステータスが15(概算金額確定)かつ、回答有無が無(未処理)
      if (Number(this.props.orderSubList[0].OrderStatus__c) == 15 &&
      (this.props.orderSubList[0].AnswerUmu__c == 0 ||
         !this.props.orderSubList[0].AnswerUmu__c )) {
        // ボタン活性
        this.setState({buttonDisabled: false});
        return;
      }
    }
    // ボタン非活性
    this.setState({buttonDisabled: true});
    return;
  }

  /**
   * 了承ボタン押下時
   */
  doOrderAccept = async () => {
    try {
      if (Number(this.props.orderSubList[0].OrderStatus__c) != 15 ||
      (this.props.orderSubList[0].AnswerUmu__c == 1)) {
        // ボタン非活性にしているのでここは通らないはず
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['改造工事費用が確定した申込'],
          },
        });
        return;
      }
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['了承'],
        },
        action: () => {
          this.doOrderAcceptExec();
        },
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
   * 了承ボタン押下時処理
   */
  doOrderAcceptExec = async () => {
    try {
      const data = this.props.orderSubList[0];
      const body = {
        'record': [{
          'conditions': {
            'Id': data.Id,
            'LastModifiedDate': data.LastModifiedDate,
          },
          'order': {
            'Id': data.Id,
            'OrderStatus__c': '16',  // 申込ステータス(概算金額受理)
            'AnswerUmu__c': '1',     // 回答有無(有)
            'GaisanAmountConsentDate__c': getToDate(), // 概算金額承諾日(システム日付)
            'RepairGaisanConsentStatus__c': '1', // 承諾
          },
        }],
        'stage': 'REPAIR_ORDER_ACCEPT_UPDATE',
      };
      // 申込テーブルの更新
      const result = await execApexRestApi(this.props.userInfo,
          'ApiAttachOrder/gaisanCostCalculationAccept', body);
      const resResult = getResponseResult(result, ['申込', '更新']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      // 再検索
      this.searchOrder();
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
   * 改造工事取下ボタン押下時
   */
  doGaisenCanceled= async () => {
    try {
      if (Number(this.props.orderSubList[0].OrderStatus__c) != 15 ||
      (this.props.orderSubList[0].AnswerUmu__c == 1)) {
        // ボタン非活性にしているのでここは通らないはず
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['改造工事費用が確定した申込'],
          },
        });
        return;
      }
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['改造工事取下'],
        },
        action: () => {
          this.doGaisenCanceledExec();
        },
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 改造工事取下ボタン押下時処理
   * @return {void}
   */
  doGaisenCanceledExec= async () => {
    try {
      const data = this.props.orderSubList[0];
      const body = {
        'record': [{
          'conditions': {
            'Id': data.Id,
            'LastModifiedDate': data.LastModifiedDate,
          },
          'order': {
            'Id': data.Id,
            'OrderStatus__c': '16',  // 申込ステータス(概算金額受理)
            'AnswerUmu__c': '1',     // 回答有無(有)
            'RepairGaisanConsentStatus__c': '2', // 取下げ
          },
        }],
        'stage': 'REPAIR_ORDER_GAISEN_CANCELED_UPDATE',
      };
      // 申込テーブルの更新
      const result = await execApexRestApi(this.props.userInfo,
          'ApiAttachOrder/gaisanCostCalculationAccept', body);
      const resResult = getResponseResult(result, ['申込', '更新']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      // 再検索
      this.searchOrder();
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
   * 添付ファイルダウンロード
   */
  doDownloadAttachedFile = async () => {
    const {userInfo} = this.props;

    if (this.attachedFile) {
      downloadAttachedFile(
          userInfo, this.attachedFile.AttachedFileType_Url,
          this.attachedFile.AttachedFileType_Type,
          this.attachedFile.AttachedFileType_Name);
    }
    return;
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  render() {
    const {handleSubmit} = this.props;
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            id="btnReceive"
            onClick={handleSubmit(this.doOrderAccept)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.buttonDisabled}
          >
            <span>了承</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <PositiveButton
            onClick={handleSubmit(this.doGaisenCanceled)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.buttonDisabled}
          >
            <span>改造工事取下</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn3" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {
              <Grid item xs={12} md={6}>
                <SimpleTable
                  styles={{tableHeader: {width: '40%'}}}
                  dataList={this.state.dataList}
                />
              </Grid>
            }
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  userInfo: PropTypes.object,
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  orderSubList: PropTypes.array,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderSubList: PropTypes.func,
  doClearOrderSubList: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    orderSubList: state.attachorder.orderSubList,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderSubList: attachorderOperations.doGetOrderSubList,
  doClearOrderSubList: attachorderOperations.doClearOrderSubList,
};

const FORM_NAME = 'RepairIraiGaisanCostCalculationResultConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);


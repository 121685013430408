import types from './types';

// 移設依頼設計書一覧 取得
export const getDesignDocumentList = (designDocumentList) => ({
  type: types.GET_DESIGN_DOCUMENT_LIST,
  designDocumentList,
});

// 移設依頼設計書一覧 取得（関数名指定）
export const getDesignDocumentTypeList = (designDocumentList) => ({
  type: types.GET_DESIGN_DOCUMENT_TYPE_LIST,
  designDocumentList,
});

// 移設依頼設計書 取得
export const retrieveDesignDocument = (designDocument) => ({
  type: types.RETRIEVE_DESIGN_DOCUMENT,
  designDocument,
});

// 移設依頼設計書 登録
export const registDesignDocument = (designDocumentResult) => ({
  type: types.REGIST_DESIGN_DOCUMENT,
  designDocumentResult,
});

// 移設依頼設計書 更新
export const updateDesignDocument = (designDocumentResult) => ({
  type: types.UPDATE_DESIGN_DOCUMENT,
  designDocumentResult,
});

// 移設依頼設計書 削除
export const deleteDesignDocument = (designDocumentResult) => ({
  type: types.DELETE_DESIGN_DOCUMENT,
  designDocumentResult,
});

// 移設依頼設計書ID 設定
export const setDesignDocumentId = (designDocumentId) => ({
  type: types.SET_DESIGN_DOCUMENT_ID,
  designDocumentId,
});

// 移設依頼設計書 取得
export const getDesignDocument = (saveDesignDocument) => ({
  type: types.GET_DESIGN_DOCUMENT,
  saveDesignDocument,
});

// 移設依頼設計書 削除
export const deleteSaveDesignDocument = (saveDesignDocument) => ({
  type: types.DEL_DESIGN_DOCUMENT,
  saveDesignDocument,
});

// 移設依頼設計書 詳細取得
export const getDetailDesignDocument = (saveDetailDesignDocument) => ({
  type: types.GET_DETAIL_DESIGN_DOCUMENT,
  saveDetailDesignDocument,
});

// 移設依頼設計書 詳細削除
export const deleteSaveDetailDesignDocument = (saveDetailDesignDocument) => ({
  type: types.DEL_DETAIL_DESIGN_DOCUMENT,
  saveDetailDesignDocument,
});


import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Field, getFormValues, reduxForm} from 'redux-form';
import * as validateRule from '../../validateRule';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';

import LoginCommon, {checkInputEmpty, commonStyles, renderTextField} from './loginCommon.js';
import BackButton from '../../atoms/Buttons/BackButton';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import {commonOperations} from '../../../reducks/common';
import {authOperations} from '../../../reducks/auth';

/**
 * ログイン画面:パスワード変更 テンプレート。
 * 画面ID:3000
 */
class Container extends React.Component {
  /**
   * 変更ボタンクリック時処理
   */
  doPasswordChange = async () => {
    const {doShowMessage} = this.props;

    // Valdationが動作していない事があるため、手動でチェックする
    if (!checkInputEmpty(this.createCheckTargets, doShowMessage)) {
      return;
    }

    const dialog = {
      message: {
        id: 'CC0005',
        values: ['パスワードを変更'],
      },
      action: this._execPasswordChange,
    };

    doShowMessage(dialog);
  };

  /**
   * 手動で未入力チェックを行う対象の入力項目情報を生成。
   *
   * @return {{name: string, value: string}[]} 入力項目情報
   */
  createCheckTargets = () => {
    const {
      values,
    } = this.props;

    // チェックする情報
    const checkTargets = [
      {name: '「現在のパスワード」', value: values.currentPassword},
      {name: '「新しいパスワード」', value: values.newPassword},
      {name: '「新しいパスワード(確認)」', value: values.newPasswordConfirm},
    ];

    return checkTargets;
  }

  /**
   * パスワード変更処理実施
   */
  _execPasswordChange = async () => {
    const {
      doChangePassword,
      doCompletePassword,
      doLogout,
      doShowMessage,
      loginInfo,
      values,
    } = this.props;

    const newPassword = values.newPassword;

    let result;
    const signInUser = loginInfo.signInUser;
    if (signInUser) {
      // 仮パスワードでログイン時は新パスワードを設定
      result = await doCompletePassword(signInUser, newPassword);
    } else {
      // パスワード変更を実行
      result = await doChangePassword(values.currentPassword, newPassword);
    }

    switch (result) {
      // パスワード変更成功
      case 'OK':
        doShowMessage({
          message: 'CI0124',
          action: () => {
            doLogout();
          },
        });
        break;

      // パスワード要件エラー、システムエラー、その他のエラー
      case 'InvalidPasswordException':
      case 'SYS_ERROR':
      default:
        doShowMessage({
          message: 'CS0001',
          action: () => {
            doLogout();
          },
        });
        break;
    }
  }

  /**
   * 描画処理。
   * @return {JSX.Element} メニュー画面の要素
   */
  render() {
    const {classes, handleSubmit} = this.props;

    return (
      <LoginCommon>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="currentPassword"
              label="現在のパスワード"
              type="password"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              autoComplete="username"
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="newPassword"
              label="新しいパスワード"
              type="password"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              autoComplete="current-password"
              validate={[
                validateRule.required,
                validateRule.minLength(8),
                validateRule.maxLength(32),
                validateRule.password,
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.typography}>
              <Typography>※パスワードは半角英数字(大文字、小文字)を含めて</Typography>
              <Typography>　8文字以上32文字以内で入力してください。</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="newPasswordConfirm"
              label="新しいパスワード(確認)"
              type="password"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              autoComplete="current-password"
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>

          <Grid container xs={12} className={classes.button}>
            <Grid item xs={1} sm={2}/>
            <Grid item xs={5} sm={4} style={{marginLeft: -4, marginRight: 4}}>
              <PositiveButton
                onClick={handleSubmit(this.doPasswordChange)}
                variant="contained"
                size="large"
                fullWidth
                style={{minWidth: 0}}
              >
                <span>変更</span>
              </PositiveButton>
            </Grid>
            <Grid item xs={5} sm={4} style={{marginLeft: 4, marginRight: -4}}>
              <BackButton
                props={this.props}
                fullWidth
                style={{minWidth: 0}}
              />
            </Grid>
            <Grid item xs={1} sm={2}/>
          </Grid>
        </Grid>
      </LoginCommon>
    );
  }
}

/**
 * 複数項目のバリデーション。
 *
 * @param {object} values フォームの値
 * @return {object} エラー情報
 */
const validate = (values) => {
  const errors = {};

  const newPassword = values.newPassword;
  const newPasswordConfirm = values.newPasswordConfirm;

  // 新しいパスワード(確認)が未入力の場合は終了
  if (newPasswordConfirm == null) {
    return errors;
  }

  // パスワードと確認が一致しない場合
  if (newPassword != newPasswordConfirm) {
    errors['newPasswordConfirm'] = 'パスワードが一致しません';
  }

  return errors;
};

const FORM_NAME = 'ChangePassword';

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  doChangePassword: PropTypes.func,
  doCompletePassword: PropTypes.func,
  doLogout: PropTypes.func,
  doShowMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  loginInfo: PropTypes.object,
  values: PropTypes.object,
};

const mapDispatchToProps = {
  doChangePassword: authOperations.doChangePassword,
  doCompletePassword: authOperations.doCompletePassword,
  doLogout: authOperations.doLogoutOperation,
  doShowMessage: commonOperations.doShowMessage,
};

const mapStateToProps = (state) => ({
  initialValues: {
    currentPassword: null,
    newPassword: null,
    newPasswordConfirm: null,
  },
  loginInfo: state.auth.loginInfo,
});

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));
export default withStyles(commonStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

// this rules is in this example below
// https://redux-form.com/7.3.0/examples/fieldlevelvalidation/
import m from 'moment';
const dt = m();
const today = dt.format('YYYY-MM-DD');
const monthDay = dt.format('YYYY-MM');

// 必須チェック
export const required = (value) => (value ? (value.trim() ? undefined : '必須入力です') : '必須入力です');
export const required2 = (value) => (value ? undefined : '携帯電話番号か電話番号のどちらかは必須です');
export const required3 = (value) => (value ? undefined : 'いずれかを選択してください');
export const required4 = (value) => (value ? undefined : '必須入力です');

// 最大文字数チェック
export const maxLength = (max) => (value) =>
  value && value.length > max ? max + '文字以内で入力してください' : undefined;

// 最少文字数チェック
export const minLength = (min) => (value) =>
  value && value.length < min ? min + '文字以上で入力してください' : undefined;

// 数字チェック
export const number = (value) =>
  value && !value.match(/^[0-9]+$/) ? '数字で入力してください' : undefined;

// Must be at least {min}
export const minValue = (min) => (value) =>
  value && value < min ? '少なくとも' + min + 'を入力してください': undefined;

// ユーザ名
export const userName = (value) =>
value && /[^a-zA-Z0-9\.\-_@]/i.test(value) ?
'半角英数字/@/ピリオド/ハイフン/アンダーバー のみ入力してください' :
undefined;

// メールアドレス形式チェック
export const email = (value) =>
  value &&
   !/^[A-Z0-9.!#$%&'*/=?^_+-`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
   'メールアドレスの形式が正しくありません' :
  undefined;

// メールアドレスドメインチェック()
export const emailDomain = (value) =>
  value &&
   (/^[A-Z0-9.!#$%&'*/=?^_+-`{|}~]+@(?=.*-\.).*$/i.test(value) ||
   /^[A-Z0-9._%+-]+@(?=.*\.-).*$/i.test(value)) ?
   'メールアドレスの形式が正しくありません' :
  undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ?
  '半角英数字のみ入力してください' :
  undefined;

export const alphaNumericSymbol = (value) =>
  value && /[^a-zA-Z0-9\- ]/i.test(value) ?
  '半角英数字ハイフンのみ入力してください' :
  undefined;

export const numericHyphen = (value) =>
  value && /[^0-9\-]/i.test(value) ?
  '半角数字ハイフンのみ入力してください' :
  undefined;

// 全角文字チェック
export const fullWidth = (value) =>
  !value ? undefined : (value && value.match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/) ?
    undefined :
    '全角文字で入力してください');

// 全角文字チェック(スペース不可)
export const fullWidthWithoutSpace = (value) =>
  !value ? undefined : (value && value.match(/^[^\x01-\x7E\uFF61-\uFF9F　]+$/) ?
    undefined :
    '全角文字で入力してください');

// 半角全角文字チェック
// 半角は0-9a-zA-Zのみ
export const halfAndFullWidth = (value) =>
!value ? undefined : (value &&
  value.match(/^[^\x01-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E\uFF61-\uFF9F]+$/) ?
    undefined :
    '半角英数字または全角文字で入力してください');

// 半角文字チェック
export const halfWidth = (value) =>
!value ? undefined : (value && value.match(/^[ -~｡-ﾟ]+$/) ?
  undefined :
  '半角文字で入力してください');

// パスワード形式チェック
export const password = (value) => {
  if (!value) {
    return undefined;
  }

  // 文字種チェック
  if (/[^a-zA-Z0-9]/i.test(value)) {
    return '半角英数字のみ入力してください';
  }

  // 半角英数混在チェック
  if ((!value.match(/[A-Z]/) || !value.match(/[a-z]/) || !value.match(/\d/))) {
    return 'パスワードは半角英字大文字，半角英字小文字，半角数字をそれぞれ含む必要があります';
  }

  return undefined;
};

// 確認用パスワードチェック
export const passwordConfirm = (password) => (value) =>
  value && value !== password ? 'パスワードが一致しません' : undefined;

// 全角カタカナチェック(全角スペースと全角ハイフンもOK)
export const fullKanaWidth = (value) =>
  !value ? undefined : (value && value.match(/^[ァ-ヶー　]+$/) ?
    undefined :
    '全角カタカナで入力してください');

// 全角カタカナチェック(全角スペースNGで全角ハイフンOK)
export const fullKanaWidthWithoutSpace = (value) =>
  !value ? undefined : (value && value.match(/^[ァ-ヶー]+$/) ?
    undefined :
    '全角カタカナで入力してください');


// 電話番号チェック(先頭は必ず０、ハイフンは少なくとも１つは入る)
export const telephone = (value) =>
  !value ? undefined : (value && value.match(/^0[0-9]+-[0-9-]*[0-9]+$/) ?
    undefined:
    '0(ゼロ)始まりで，半角の数字とハイフンを含む必要があります');

// 日付形式チェック
export const date = (value) => {
  if (!value) {
    return undefined;
  }

  const result =
    /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/i.test(value) ?
    undefined:
    '日付の形式が正しくありません';

  return result;
};

// 年月形式チェック
// (日付形式チェックを流用しているため、流用先に年月で修正が入ると本処理も修正が必要)
export const month = (value) => {
  if (!value) {
    return undefined;
  }

  const result =
    /^[0-9]{4}-(0[1-9]|1[0-2])$/i.test(value) ?
    undefined:
    '年月の形式が正しくありません';

  return result;
};


// 過去日付チェック(システム日付当日はOK)
export const pastDate = (value) =>
  !value ? undefined : (value && today > value) ? '過去の日付は指定できません' : undefined;

// 過去年月チェック(システム月当日はOK)
export const pastMonthDate = (value) =>
  !value ? undefined : (value && monthDay > value) ? '過去の年月は指定できません' : undefined;

// 未来日付チェック(システム日付当日はOK)
export const futureDate = (value) =>
!value ? undefined : (value && today < value) ? '本日以前の日付を入力してください' : undefined;

// 日付妥当性チェック
export const correctDate = (fromVal) => (value) =>
  !fromVal || fromVal === '' || !value || value === '' ? undefined : fromVal <= value ? undefined : '開始日より過去の日付は指定できません';

// 日付妥当性チェック
export const correctFromDate = (toVal) => (value) =>
!toVal || toVal === '' || !value || value === '' ? undefined : value <= toVal ? undefined : '終了日より未来の日付は指定できません';

// 日付妥当性チェック（システム日付当日はOK）
export const correctPastDate = (fromVal) => (value) =>
!fromVal || fromVal === '' || !value || value === '' ? undefined : fromVal <= value && value <= today ? undefined : '発信日から現在日までの間で指定してください';

// 年月妥当性チェック
export const correctMonth = (fromVal) => (value) =>
  !fromVal || fromVal === '' || !value || value === '' ? undefined : fromVal <= value ? undefined : '開始年月より過去の年月は指定できません';

// 年月妥当性チェック
export const correctFromMonth = (toVal) => (value) =>
!toVal || toVal === '' || !value || value === '' ? undefined : value <= toVal ? undefined : '終了年月より未来の年月は指定できません';


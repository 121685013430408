import {combineReducers} from 'redux';
import {copyObject} from '@grapecity/wijmo.grid.immutable';
import types from './types';

// FlexGrid
const editItems = (state = null, action) => {
  switch (action.type) {
    case types.SET_LIST:
      const data = [];
      const actionItems = action.items ? action.items : [];
      for (const actionItem of actionItems) {
        // make item immutable
        Object.freeze(actionItem);
        data.push(actionItem);
      }
      return copyObject({}, {items: null}, {items: data});
    case types.ADD_ITEM:
    {
      let newItem =
          Object.freeze(copyObject({}, action.item));
      return copyObject({}, state, {
        items: state.items.concat([newItem]),
      });
    }
    case types.REMOVE_ITEM:
    {
      let items = state.items;
      let index = action.index;
      return copyObject({}, state, {
        // items array clone with the item removed
        items: items.slice(0, index).concat(items.slice(index + 1)),
      });
    }
    case types.CHANGE_ITEM:
    {
      let items = state.items;
      let index = action.index;
      let oldItem = items && items.length > 0 ? items[index] : [];
      // create a cloned item with the property changes applied
      let clonedItem = Object.freeze(copyObject({}, oldItem, action.item));
      return copyObject({}, state, {
        // items array clone with the updated item
        items: items.slice(0, index).
            concat([clonedItem]).
            concat(items.slice(index + 1)),
      });
    }
    case types.MOVE_ITEM:
    {
      let items = state.items;
      let selectedItem = action.item;
      let dragIndex = action.dragIndex;
      let dropIndex = action.dropIndex;

      // 選択した行を削除
      let tmpItems =
            items.slice(0, dragIndex)
                .concat(items.slice(dragIndex + 1));
      // 行移動
      tmpItems.splice(dropIndex, 0, selectedItem);

      return copyObject({}, state, {
        items: tmpItems,
      });
    }
    default:
      return state;
  }
};

// MultiRow
// MultiRowのデータにObject.freezeをつかうとセルの編集ができなくなる
const multiRowEditItems = (state = null, action) => {
  switch (action.type) {
    case types.SET_LIST_MULTI_ROW:
      const data = [];
      const actionItems = action.multiRowItems ? action.multiRowItems : [];
      for (const actionItem of actionItems) {
        data.push(actionItem);
      }
      return copyObject({}, {multiRowItems: null}, {multiRowItems: data});
    case types.ADD_ITEM_MULTI_ROW:
    {
      let newItem =
          copyObject({}, action.multiRowItem);
      return copyObject({}, state, {
        multiRowItems: state.multiRowItems.concat([newItem]),
      });
    }
    case types.REMOVE_ITEM_MULTI_ROW:
    {
      let items = state.multiRowItems;
      let index = action.multiRowIndex;
      return copyObject({}, state, {
        multiRowItems: items.slice(0, index).concat(items.slice(index + 1)),
      });
    }
    case types.CHANGE_ITEM_MULTI_ROW:
    {
      let items = state.multiRowItems;
      let index = action.multiRowIndex;
      let oldItem = items && items.length > 0 ? items[index] : [];
      let clonedItem =
        copyObject({}, oldItem, action.multiRowItem);
      return copyObject({}, state, {
        multiRowItems: items.slice(0, index).
            concat([clonedItem]).
            concat(items.slice(index + 1)),
      });
    }
    case types.MOVE_ITEM_MULTI_ROW:
    {
      let items = state.multiRowItems;
      let selectedItem = action.multiRowItem;
      let selectedIndex = action.multiRowIndex;
      let dest = action.destination; // 1:上に移動、-1:下に移動
      let moveIndex = selectedIndex;

      if (dest > 0) {
        // 一つ上に選択した行を挿入
        moveIndex--;
      }
      if (dest < 0) {
        // 一つ下に選択した行を挿入
        moveIndex++;
      }
      if (moveIndex < 0 || moveIndex > items.length) {
        return state;
      }

      // 選択した行を削除
      let tmpItems =
            items.slice(0, selectedIndex)
                .concat(items.slice(selectedIndex + 1));
      // 行移動
      tmpItems.splice(moveIndex, 0, selectedItem);

      return copyObject({}, state, {
        multiRowItems: tmpItems,
      });
    }
    default:
      return state;
  }
};

// 選択データ
const selections = (state = null, action) => {
  switch (action.type) {
    case types.SAVE_SELECTED_ITEMS:
      let list = state ? state : [];
      let newList = list.filter((data) =>
        data.screenId != action.screenId);
      newList.push({
        screenId: action.screenId,
        compareKey: action.compareKey,
        selectedItems: action.selectedItems,
      });
      return newList;
    default:
      return state;
  }
};

// 選択データ
const scrollPosition = (state = null, action) => {
  switch (action.type) {
    case types.SAVE_SCROLL_POSITION:
      let list = state ? state : [];
      let newList = list.filter((data) =>
        data.screenId != action.screenId);
      newList.push({
        screenId: action.screenId,
        scrollPosition: action.scrollPosition,
      });
      return newList;
    default:
      return state;
  }
};

const reducer = combineReducers({
  editItems,
  multiRowEditItems,
  selections,
  scrollPosition,
});

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    position: 'relative',
  },
  container: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function DeleteIconButton({isDisabled}) {
  const classes = useStyles();

  return (
    <div id="btnMode" className={classes.root}>
      <IconButton id="btnDeleteRow"
        className={classes.container}
        size="small"
        disabled={isDisabled}>
        <DeleteIcon id="btnDeleteRow" />
      </IconButton>
    </div>
  );
};

DeleteIconButton.propTypes = {
  isDisabled: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TreeView from '@material-ui/lab/TreeView';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import getMenuItems from './MenuItems';
import {commonOperations} from '../../reducks/common';
import {authOperations} from '../../reducks/auth';

import CommonTheme from '../../Theme.js';

// eslint-disable-next-line no-unused-vars
import {MajorItem, MiddleItem, MinorItem} from './MenuItems.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tree: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
    marginLeft: 10,
  },
  minorItem: {
    'fontSize': 'small',
    '&:hover': {
      backgroundColor: CommonTheme.palette.primary.main,
    },
  },
  minorItemDisable: {
    'fontSize': 'small',
    'color': 'gray',
    'cursor': 'default',
    '&:hover': {
      backgroundColor: CommonTheme.palette.primary.dark,
    },
  },
  minorGroup: {
    marginLeft: '8px',
  },
});

class SideMenu extends React.Component {
  /**
   * 大項目単位の要素生成。
   *
   * @param {MajorItem[]} menuItems 大項目単位のメニュー項目
   * @return {JSX.Element} 大項目単位の要素
   */
  renderMajorItem = (menuItems) => {
    const {classes} = this.props;

    const element = [];

    for (let i = 0; i < menuItems.length; i++) {
      const majorItem = menuItems[i];

      // 遷移先が登録されている場合はクリック時の処理を登録
      let click = null;
      if (majorItem.hasOwnProperty('path') || majorItem.hasOwnProperty('action')) {
        click=()=>{
          this.clickMenuItem(majorItem);
        };
      }

      element.push(
          <TreeItem
            key={i.toString()}
            nodeId={i.toString()}
            label={majorItem.name}
            onClick={click}
            classes={{
              group: classes.minorGroup,
            }}
          >
            {this.renderMiddleItem(majorItem.items)}
          </TreeItem>,
      );
    }

    return element;
  };

  /**
   * 中項目単位の要素生成。
   * サイドメニューでは中項目は描画しない。
   *
   * @param {MiddleItem[]} middleItems 中項目単位のメニュー項目
   * @param {number} mjIdx 大項目のインデックス
   * @return {JSX.Element} 中項目単位の要素
   */
  renderMiddleItem = (middleItems, mjIdx) => {
    const element = [];

    for (let i = 0; i < middleItems.length; i++) {
      const middleItem = middleItems[i];
      const minorItem = this.renderMinorItem(middleItem.items, mjIdx, i);
      element.push(minorItem);
    }

    return element;
  };

  /**
   * 小項目単位の要素生成。
   * サイドメニューでは中項目は描画しない。
   *
   * @param {MinorItem[]} minorItems 小項目単位のメニュー項目
   * @param {number} mjIdx 大項目のインデックス
   * @param {number} mdIdx 中項目のインデックス
   * @return {JSX.Element} 小項目単位の要素
   */
  renderMinorItem = (minorItems, mjIdx, mdIdx) => {
    const {classes} = this.props;

    const element = [];

    for (let i = 0; i < minorItems.length; i++) {
      const minorItem = minorItems[i];
      const key = [mjIdx, mdIdx, i].join('_');

      const cls = minorItem.path ? classes.minorItem : classes.minorItemDisable;

      element.push(
          <React.Fragment>
            <TreeItem
              key={key}
              nodeId={key}
              label={minorItem.name}
              classes={{
                label: cls,
              }}
              onClick={() => {
                this.clickMenuItem(minorItem);
              }}
            />
          </React.Fragment>,
      );
    }

    return element;
  };

  /**
   * サイドメニュー項目クリック時の処理。
   *
   * @param {string} item 遷移先パス
   */
  clickMenuItem = (item) => {
    const {dirty, doShowMessage, history, isEditPage} = this.props;

    // クリック時アクションが指定されていれば実行
    if (item.hasOwnProperty('action')) {
      item.action(this.props);
      return;
    }

    // パスが指定されていれば実行
    if (item.hasOwnProperty('path')) {
      const path = item.path;

      // 編集されている場合は遷移確認メッセージ
      if (isEditPage && dirty) {
        doShowMessage({
          message: 'CW0013',
          action: () => {
            // OKを押すと画面遷移
            history.push(path, null, history.option.CLEAR);
          },
        });
      } else {
        // 編集されていない場合は確認なしで遷移
        history.push(path, null, history.option.CLEAR);
      }
    }
  };

  render = () => {
    const {classes, userInfo, generalPurposeMap} = this.props;
    const menuItems = getMenuItems('side', userInfo, generalPurposeMap);

    return (
      <React.Fragment>

        <TreeView
          className={classes.tree}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {this.renderMajorItem(menuItems)}
        </TreeView>

      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  doShowMessage: commonOperations.doShowMessage,
};

SideMenu.propTypes = {
  classes: PropTypes.object,
  doLogout: PropTypes.func,
  doShowMessage: PropTypes.func,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  isEditPage: PropTypes.bool,
  userInfo: PropTypes.object,
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SideMenu),
);

import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

export function createList(items, code='Code__c', name='Name') {
  const list = [];
  list.push(<MenuItem value="">&nbsp;</MenuItem>);
  for (const i in items) {
    if (items.hasOwnProperty(i)) {
      list.push(<MenuItem value={items[i][code]}>{items[i][name]}</MenuItem>);
    }
  }
  return (list);
}

/*
 * コンボボックス作成
 * @param {Object} items 対象データ
 * @param {Object} code タグのvalueに設定するフィールド名
 * @param {Object} name コンボボックスに設定するフィールド名
 * @param {Array} search 検索条件（and条件）（完全一致のみ）（{フィールド名：[値,…],…,…}）
 * @param {Object} blankline 空行有無（設定時なし）
 * @return {Object} 設定結果リスト
 */
export function createSearchList(items, code='Code__c', name='Name', search, blankline) {
  if (!items) {
    return [];
  }

  let wkItems = {};

  if (search) {
    // 検索条件が存在する場合
    for (const item of items) {
      let bFlag = false;
      for (const key in search) {
        if (!search.hasOwnProperty(key)) {
          continue;
        }
        // 引数の検索条件と比較
        if (search[key] && search[key].includes(item[key])) {
          bFlag = true;
          continue;
        }
        bFlag = false;
        break;
      }
      // 引数の検索条件と比較
      if (bFlag) {
        // 検索条件が一致した場合、表示対象として追加
        wkItems[Object.keys(wkItems).length] = item;
      }
    }
  } else {
    // 検索条件がない場合は、引数値を表示対象に設定
    wkItems = items;
  }

  // 表示対象をリストに追加
  const list = [];
  if (!blankline) {
    list.push(<MenuItem value="">&nbsp;</MenuItem>);
  }
  for (const i in wkItems) {
    if (wkItems.hasOwnProperty(i)) {
      list.push(
          <MenuItem value={wkItems[i][code]}>{wkItems[i][name]}</MenuItem>);
    }
  }
  return (list);
}

/*
 * コンボボックス作成
 * @param {Object} items 対象データ
 * @param {Object} code タグのvalueに設定するフィールド名
 * @param {Object} name コンボボックスに設定するフィールド名
 * @param {Array} search 検索条件（and条件）（完全一致のみ）（{フィールド名：[値,…],…,…}）
 * @param {Array} nqSearch 除外検索条件（or条件）（完全一致のみ）（{フィールド名：[値,…],…,…}）
 * @param {Object} blankline 空行有無（設定時なし）
 * @return {Object} 設定結果リスト
 */
export function createSearchListEx(items, code='Code__c', name='Name', search, nqSearch, blankline) {
  if (!items) {
    return [];
  }

  let wkItems = {};

  if (search || nqSearch) {
    // 検索条件が存在する場合
    for (const item of items) {
      let bFlag = false;
      if (search) {
        for (const key in search) {
          if (!search.hasOwnProperty(key)) {
            continue;
          }
          // 引数の検索条件と比較
          if (search[key] && search[key].includes(item[key])) {
            bFlag = true;
            continue;
          }
          bFlag = false;
          break;
        }
      } else {
        bFlag = true;
      }
      if (nqSearch && bFlag) {
        for (const key in nqSearch) {
          if (!nqSearch.hasOwnProperty(key)) {
            continue;
          }
          // 引数の除外検索条件と比較
          if (nqSearch[key] && nqSearch[key].includes(item[key])) {
            bFlag = false;
            break;
          }
        }
      }
      // 引数の検索条件と比較
      if (bFlag) {
        // 検索条件が一致した場合、表示対象として追加
        wkItems[Object.keys(wkItems).length] = item;
      }
    }
  } else {
    // 検索条件がない場合は、引数値を表示対象に設定
    wkItems = items;
  }

  // 表示対象をリストに追加
  const list = [];
  if (!blankline) {
    list.push(<MenuItem value="">&nbsp;</MenuItem>);
  }
  for (const i in wkItems) {
    if (wkItems.hasOwnProperty(i)) {
      list.push(
          <MenuItem value={wkItems[i][code]}>{wkItems[i][name]}</MenuItem>);
    }
  }
  return (list);
}

export function changePrefectures(event, component) {
  component.setState({prefectureId: event.target.value});
}

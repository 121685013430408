/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */

const MEDIA_TYPE_XLS = 'application/vnd.ms-excel';
const MEDIA_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const MEDIA_TYPE_DOC = 'application/msword';
const MEDIA_TYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const MEDIA_TYPE_PDF = 'application/pdf';
const MEDIA_TYPE_JPEG = 'image/jpeg';

/** ファイル名最大長 */
const MAX_FILENAME_LEN = 256;

/**
 * base64に変換したデータからdataスキームを取り除く
 * dataスキームの構文は以下の通り:
 *  data:[<mediatype>][;base64],<data>
 *  <data>を返す
 * @param {object} base64Data
 * @return {object} data
 */
export function removeDataSchemeFromBase64Data(base64Data) {
  return base64Data ? base64Data.split('base64,')[1] : base64Data;
}

/**
 * SFから取得した添付ファイルデータを設定する
 * @param {array} data SFから取得した添付ファイルデータ
 *  [{
 *       'ContentDocumentId': string, // ContentDocumentのId
 *       'PathOnClient': string, // 拡張子付きファイル名
 *       'AttachedFileType__c': string, // 添付ファイル種別
 *       'VersionData': base64, // SF上のファイルパス
 *       'FileType': string, //ファイルの種類
 *       'content': string, // VersionDataから取得したファイルの内容。dataスキーム付き。
 *  },...]
 * @return {array} データを追加する配列
 */
export function setAttachedFileData(data) {
  const list = [];
  if (!data) {
    return list;
  }
  for (const record of data) {
    const mediaType = getMediaType(record.FileType);
    const dataURL = 'data:' + mediaType + ';base64,';
    list.push({
      FileBody: dataURL + record.content,
      FileName: record.PathOnClient,
      ContentType: mediaType,
      ContentDocumentId: record.ContentDocumentId,
      AttachedFileType__c: record.AttachedFileType__c,
    });
  }
  return list;
}

/**
 *
 * @param {array} attachedFiles UploadCustomコンポーネントに添付したファイル一覧
 * @param {array} uploadFiles common.doUploadToS3Commonの戻り値の中のuploadFiles
 * @return {array} uploadFilesにファイル種別を設定したもの
 */
export function setAttachedFileType(attachedFiles, uploadFiles) {
  const _uploadFiles = [];
  if (!attachedFiles || !uploadFiles) {
    return _uploadFiles;
  }
  if (uploadFiles.length != attachedFiles.length) {
    return _uploadFiles;
  }

  for (let index = 0; index < uploadFiles.length; index++) {
    const uploadFile = uploadFiles[index];
    const attachedFile = attachedFiles[index];
    if (uploadFile.fileName != attachedFile.FileName) {
      continue;
    }
    // uploadFileの中身は以下のように作る
    // {
    //     id: "1-自主審査表.xlsx",
    //     contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     fileName: "自主審査表.xlsx",
    //     subdir: "1",
    //     AttachedFileType__c: "A12",
    //  }
    const _uploadFile = {};
    Object.assign(_uploadFile, uploadFile);
    _uploadFile['AttachedFileType__c'] = attachedFile['AttachedFileType__c'];
    _uploadFiles.push(_uploadFile);
  }
  return _uploadFiles;
}

/**
 * TODO あとで削除する
 * ファイルタイプに対応するMediaタイプを返す
 * ファイルタイプはContentVersionのFileType参照。
 * @param {string} type ファイルタイプ
 * @return {string} MIMEタイプ
 */
export function getMediaType(type) {
  let ret = 'text/plain';
  if (type == 'EXCEL') {
    ret = MEDIA_TYPE_XLS;
  } else if (type == 'EXCEL_X') {
    ret = MEDIA_TYPE_XLSX;
  } else if (type == 'WORD') {
    ret = MEDIA_TYPE_DOC;
  } else if (type == 'WORD_X') {
    ret = MEDIA_TYPE_DOCX;
  } else if (type == 'PDF') {
    ret = MEDIA_TYPE_PDF;
  } else if (type == 'JPG' || type == 'JPEG') {
    ret = MEDIA_TYPE_JPEG;
  } else {
    ret = 'text/plain';
  }

  return ret;
}

/**
 * TODO あとで削除する
 *画面に表示する添付ファイルに採番したContentDocumentIdを設定する
 * @param {array} dataList 登録したデータ配列
 * @param {array} list 画面に表示する添付ファイルリスト
 */
export function setContentDocumentId(dataList, list) {
  for (const data of dataList) {
    for (let i = 0; i < list.length; i++) {
      if (!!list[i].ContentDocumentId) {
        continue;
      }
      if (list[i].FileName == data.PathOnClient) {
        list[i].ContentDocumentId = data.ContentDocumentId;
        break;
      }
    }
  }
}

/**
 * ファイル名長チェック。
 * @param {File[]} addFiles 選択ファイル情報一覧
 * @return {MessageInfo} チェックエラー時はエラーメッセージ情報。チェックOK時はnull
 */
export function checkFileNameLength(addFiles) {
  for (const file of addFiles) {
    const fileName = file.name;
    if (fileName.length > MAX_FILENAME_LEN) {
      return {id: 'CE0091', values: MAX_FILENAME_LEN.toString()};
    }
  }

  return null;
}

/**
 * ファイルサイズ0バイトチェック。
 * @param {File[]} addFiles 選択ファイル情報一覧
 * @return {MessageInfo} チェックエラー時はエラーメッセージ情報。チェックOK時はnull
 */
export function checkFileSizeZero(addFiles) {
  for (const file of addFiles) {
    if (file.size == 0) {
      return {id: 'CE0092'};
    }
  }

  return null;
}

/**
 * 同名ファイルチェック。
 * @param {File[]} addFiles 選択ファイル情報一覧
 * @param {UploadFileInfo[]} registedFiles 登録済ファイル情報一覧
 * @return {MessageInfo} チェックエラー時はエラーメッセージ情報。チェックOK時はnull
 */
export function checkSameName(addFiles, registedFiles) {
  // 既に登録済のファイル名一覧を生成
  const fileNameList = [];
  for (const file of registedFiles) {
    fileNameList.push(file.FileName);
  }

  // 選択ファイルと同名ファイルが存在しないかチェック
  for (const file of addFiles) {
    const fileName = file.name;
    if (fileNameList.includes(fileName)) {
      return {id: 'CE0093', values: [fileName]};
    }

    fileNameList.push(fileName);
  }

  return null;
}

/**
 * 同名ファイルの差し替え。
 *
 * 登録済ファイル名が存在する場合、アンダーバーの後に重複しない数値を
 * ファイル名のサフィックスとして付加します。
 *
 * 例：image.jpg と重複する場合 image_1.jpg にする
 *
 * @param {File[]} addFiles 選択ファイル情報一覧
 * @param {UploadFileInfo[]} registedFiles 登録済ファイル情報一覧
 * @return {null} 本メソッドはチェックではなく重複ファイル名差し替えのため、何も返却しない
 */
export function changeSameName(addFiles, registedFiles) {
  // ファイル名重複確認用
  const fileNameList = [];
  for (const file of registedFiles) {
    fileNameList.push(file.FileName);
  }

  // 同一ファイル名が含まれるインデックスを取得
  const sameNameIdxList = [];
  for (let i = 0; i < addFiles.length; i++) {
    const addFileName = addFiles[i].name;

    // 同一ファイル名が登録済の場合、インデックスを保持
    if (fileNameList.includes(addFileName)) {
      sameNameIdxList.push(i);
    }

    // 複数ファイルが登録された際、同一ファイル名がないか確認するため要素追加
    fileNameList.push(addFileName);
  }

  // 同一ファイルが含まれない場合はここで処理終了
  if (sameNameIdxList.length == 0) {
    return null;
  }

  // 同一ファイル名の項目を差し替え
  for (const sameNameIdx of sameNameIdxList) {
    const addFile = addFiles[sameNameIdx];

    // 元のファイル名から、"_"の後に数値をつけたファイル名に変更
    const baseName = addFile.name;
    const extensionIdx = baseName.indexOf('.');
    const name = baseName.substring(0, extensionIdx);
    const extension = baseName.substring(extensionIdx + 1);

    // 重複しないファイル名が見つかるまでループ
    let idx = 1;
    while (true) {
      const fileName = `${name}_${idx}.${extension}`;

      // ファイル名が重複しない場合、選択ファイル情報を差し替え
      if (!fileNameList.includes(fileName)) {
        fileNameList.push(fileName);

        // 元のFileインスタンスから、ファイル名を変更した新たなFileインスタンスを生成
        const blob = addFile.slice(0, addFile.size, addFile.type);
        const options = {
          type: addFile.type,
          lastModified: addFile.lastModified,
        };
        const newFile = new File([blob], fileName, options);
        addFiles[sameNameIdx] = newFile;

        break;
      }

      // ファイル名が重複する場合は次のインデックスでファイル名チェック
      idx++;
    }
  }

  return null;
}

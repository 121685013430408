const GET_DNT_LIST = 'GET_DNT_LIST';
const SET_DNT_INFO = 'SET_DNT_INFO';
const GET_DNT_BY_DSPDNTNO = 'GET_DNT_BY_DSPDNTNO';

export default {
  /** 電柱一覧 取得 */
  GET_DNT_LIST,

  /** 電柱情報 設定 */
  SET_DNT_INFO,

  /** 電柱番号 電柱取得 */
  GET_DNT_BY_DSPDNTNO,
};


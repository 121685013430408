import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import {reduxForm, getFormValues} from 'redux-form';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import {API_CODE, getErrorCode, changeDateFormat} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {billingDetailOperations} from '../../../../../../reducks/billingDetail/index.js';
import {billingDetailFields} from '../../../../../../common/SFFields';
import KyogaCompanyInformation from '../../../../../molecules/KyogaCompanyInformation.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

/**
 * 定期共架料内訳
 * 画面ID:1511
 */
class BillingDetailSearchConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.KyogaZgsyaCode = null,
    this.Year = null,
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {billingDetailId} = this.props;

      // 画面引継情報取得エラー
      if (!billingDetailId) {
        this.sysErr();
        return;
      }

      let errorCode = null;
      let response = null;

      // 請求明細取得（全画面で選択された引継情報）
      response = await this.props.doGetBillingDetailId(
          billingDetailId, billingDetailFields);
      errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      } else {
        this.KyogaZgsyaCode = response.data.body.data.KyogaZgsyaCode__c;
        this.Year = response.data.body.data.Year__c;
      }

      // 検索条件を追加
      let wkData = {};
      wkData.Year__c = this.Year - 1;
      wkData.BillingCategoryOr = true;
      wkData.BillingStatus__c = ['1', '2', '3'];

      // 定期共架料内訳一覧取得
      response = await this.props.doGetBillingDetailListORFilter(
          wkData, billingDetailFields);
      errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }
      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1511', this.props.billingDetailListORFilter);
      }

      // 検索結果0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('billingDetailListORFilter') ||
          !this.props.billingDetailListORFilter ||
           this.props.billingDetailListORFilter.length == 0) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearBillingDetailListORFilter();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setItems(null);
    }
  }

   /**
   * システムエラー
   */
   sysErr = () => {
     this.props.doShowMessage({
       message: 'CS0001',
       action: () => {
         this.props.history.push('/login');
       },
     });
   }

   /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
   filterChangingHandler(s, e) {
     let edt = s.activeEditor;
     const currentColumn = edt.filter.column.binding;
     if (currentColumn === 'BillingHaseiDate__c' ||
           currentColumn === 'TargetPeriodFrom__c' ||
           currentColumn === 'TargetPeriodTo__c') {
       let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
       let lb = wijmo.Control.getControl(lbHost);

       lb.itemFormatter = (index) => {
         const ret = changeDateFormat(lb.collectionView.items[index].value, 'YYYY/MM');
         return ret ? ret : '(なし)';
       };
       lb.collectionView.refresh();
     }
   }

   /**
   * 一覧表出力
   * @return {CustomFlexGrid} 一覧表
   */
   renderTable() {
     const props = {
       rowHeaderType: 'none',
       filterOn: true,
       headersVisibility: 'All',
       allowDragging: 'Columns',
       counterOn: false,
       AddDeleteOn: false,
       isReadOnly: true,
       style: {maxHeight: '400px'},
       filterChanging: this.filterChangingHandler,
     };

     return (
       <CustomFlexGrid ref={this.gridRef} {...props}>
         <FlexGridColumnGroup binding="BillingCostConfirmDate__c" header="請求費用/確定年月日" dataType="Date" width={190}>
           <FlexGridCellTemplate
             cellType='Cell'
             template={ (context) => {
               return changeDateFormat(
                   context.item.
                       BillingCostConfirmDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="BillingDetailNo__c" header="請求明細番号" dataType="String"/>
         <FlexGridColumnGroup binding="OrderNo__c" header="申込番号" dataType="String"/>
         <FlexGridColumnGroup binding="BillingCategory" header="請求区分" dataType="String"/>
         <FlexGridColumnGroup binding="KyogaType" header="共架種別" dataType="String"/>
         <FlexGridColumnGroup binding="BillingTiming" header="請求タイミング" dataType="String" width={170}/>
         <FlexGridColumnGroup binding="Year__c" header="年度" dataType="String" format="d" width={90}/>
         <FlexGridColumnGroup binding="BillingHaseiDate__c" header="請求発生年月" dataType="Date">
           <FlexGridCellTemplate
             cellType='Cell'
             template={ (context) => {
               return changeDateFormat(
                   context.item.BillingHaseiDate__c, 'YYYY/MM');
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="BillingStatus" header="請求ステータス" dataType="String" width={170}/>
         <FlexGridColumnGroup binding="KyogaZgsya__r.Account__r.Name" header="共架事業者" dataType="String"/>
         <FlexGridColumnGroup binding="BillingDestination__r.Name" header="請求先" dataType="String"/>
         <FlexGridColumnGroup binding="IskkCategory" header="電線一束化事業者数" dataType="String" width={190}/>
         <FlexGridColumnGroup binding="UnitPrice__c" header="単価" dataType="Number"/>
         <FlexGridColumnGroup binding="TransferHonsu__c" header="異動本数" dataType="Number"/>
         <FlexGridColumnGroup binding="FreeOfChargeTransferHonsu__c" header="無償異動本数" dataType="Number"/>
         <FlexGridColumnGroup binding="BillingAmount__c" header="請求金額" dataType="Number"/>
         <FlexGridColumnGroup binding="ConsumptionTax__c" header="消費税" dataType="Number"/>
         <FlexGridColumnGroup binding="BilReason__c" header="請求理由" dataType="String"/>
         <FlexGridColumnGroup binding="TargetPeriodFrom__c" header="対象期間/FROM" dataType="Date">
           <FlexGridCellTemplate
             cellType='Cell'
             template={ (context) => {
               return changeDateFormat(
                   context.item.TargetPeriodFrom__c, 'YYYY/MM');
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="TargetPeriodTo__c" header="対象期間/TO" dataType="Date">
           <FlexGridCellTemplate
             cellType='Cell'
             template={ (context) => {
               return changeDateFormat(
                   context.item.TargetPeriodTo__c, 'YYYY/MM');
             }}
           />
         </FlexGridColumnGroup>
       </CustomFlexGrid>
     );
   }

   render() {
     const {classes, userInfo,
       billingDetailListORFilter} = this.props;

     const footerBtn = (
       <Grid
         container
         justifyContent="center"
         alignItems="flex-start"
         spacing={1}
       >

         {/* 戻る */}
         <Grid key="btn1" item>
           <BackButton props={this.props}/>
         </Grid>

       </Grid>
     );

     return (
       <form noValidate autoComplete="off" className={classes.root}>
         <MainContainer
           props={this.props}
           footerBtn={footerBtn}>

           <KyogaCompanyInformation
             companyName={userInfo.Account.Name} />

           <div style={{marginBottom: '20px'}} />
           <Divider variant="middle" />
           <div style={{marginBottom: '20px'}} />

           <Grid container
             justifyContent="flex-start"
             alignItems="flex-start"
           >
             <Grid key="gridTable" item xs={12}>
               {this.renderTable(billingDetailListORFilter)}
             </Grid>
           </Grid>

         </MainContainer>
       </form>
     );
   }
}

BillingDetailSearchConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  billingDetailId: PropTypes.string,
  accountMasterList: PropTypes.array,
  doGetBillingDetailId: PropTypes.func,
  doGetBillingDetailListORFilter: PropTypes.func,
  billingDetailListORFilter: PropTypes.array,
  doClearBillingDetailListORFilter: PropTypes.func,
  doShowMessage: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accountMasterList: state.common.accountMasterList,
  userInfo: state.auth.userInfo,
  billingDetailId: state.billingDetail.billingDetailId,
  billingDetailListORFilter: state.billingDetail.billingDetailListORFilter,
  // 表示時のデフォルト設定
  initialValues: {
  },
});

const mapDispatchToProps = {
  doGetBillingDetailId: billingDetailOperations.doGetBillingDetailId,
  doGetBillingDetailListORFilter:
   billingDetailOperations.doGetBillingDetailListORFilter,
  doClearBillingDetailListORFilter:
   billingDetailOperations.doClearBillingDetailListORFilter,
  doShowMessage: commonOperations.doShowMessage,
};

const FORM_NAME = 'BillingDetailSearchConfirm';

BillingDetailSearchConfirm = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(BillingDetailSearchConfirm));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(BillingDetailSearchConfirm),
);

import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as wjgGrid from '@grapecity/wijmo.grid';

import PositiveButton from '../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../organisms/MainContainer.js';
import PaperPart from '../../../../atoms/PaperPart.js';
import {renderTextField} from '../../../../atoms/CustomPart.js';
import CustomFlexGrid from '../../../../molecules/CustomFlexGrid.js';
import * as validateRule from '../../../../validateRule.js';
import {changeDateFormat, getComboName,
  execApexRestApi, getToDate,
  parseStatusOrderToRepairirai, getErrorCode, API_CODE,
  getResponseResult,
  createDataMap,
} from '../../../../../common/common.js';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {commonOperations} from '../../../../../reducks/common/index.js';
import {contractDntOperations} from '../../../../../reducks/contractDnt/index.js';
import {attachorderOperations} from '../../../../../reducks/attachorder/index.js';
import Moment from 'moment';
import WarningMessageArea from '../../../../molecules/WarningMessageArea.js';
import {ConfirmWarningMessageArea} from '../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

const validateCorrectFromDate = (toVal) => (value) =>
  !toVal || toVal === '' || !value || value === '' ? undefined : toVal <= value && value <= new Moment(toVal, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD') ? undefined : '改修依頼日から６か月以内で入力してください';

/**
 * 改修依頼改修工事予定入力
 * 画面ID:1473
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repairIraiDate: null,
      isModified: false,
      isDisabled: false,
      warningMessages: [],
      hasError: false,
    };
    this.gridRef = React.createRef();
    this.onFomatItemHandler = this.onFomatItemHandler.bind(this);
    this.refreshedFunction = this.refreshedFunction.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    this.getError = (item, prop, parsing) => {
      let error = null;
      switch (prop) {
        // validationチェック
        case 'RepairKoziKinds__c':
          error = validateRule.required(item[prop]);
          if (error) return error;
        case 'Memo__c':
          error = validateRule.maxLength(255)(item[prop]);
          if (item['RepairKoziKinds__c']=='99') {
            error = validateRule.required(item[prop]);
          }
          if (error) return error;
        case 'NWKoziHopeMemo__c':
          error = validateRule.maxLength(255)(item[prop]);
          if (error) return error;
        default:
          break;
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    // 初期設定
    this.initialize();
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.doClearList();
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    this.props.doClearOrderContractDnt();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 初期設定
   */
  initialize = async () =>{
    try {
      const {orderId} = this.props;

      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1473', null);
      }

      // 申込IDに紐づく申込電柱を取得する
      const orderConditions = {
        'Id': orderId,
      };
      const dntConditions = {
        'Order__c': orderId,
        'RecordType.DeveloperName': 'RepairIrai',
      };
      const appSortParams = {
        SerialNumber__c: 1,
      };
      const orderFields = [
        'Id',
        'OrderNo__c', // 改修依頼番号
        'NWZgsyo__r.name', // 事業所
        'toLabel(KyogaType__c)', // 共架種別
        'RepairIraiDate__c', // 改修依頼日
        'OrderStatus__c', // 改修依頼ステータス
        'toLabel(RepairGaisanConsentStatus__c)', // 見積結果承諾状況
        'RepairOrderNo__c', // 改修元申込番号
        'KyogasyaSendBackReason__c', // 差戻理由
        'KyogaKoziScheduleInputDeadline__c', // 改修工事予定日入力期限
        'TykkoHopeDate__c', // 改修完了希望日
        'KyogaKoziScheduleDate__c', // 改修工事予定日
        'toLabel(KoziPlacePrefectures__c)', // 都道府県
        'KoziPlaceMunicipalities__c', // 市区町村
        'KoziPlacePlace__c', // 場所
        'RepairIraiMemo__c', // 改修依頼メモ
        'RepairIraiContents__c', // 改修依頼内容
        'LastModifiedDate', // 最終更新日時
        'TemporarilySavedDate__c', // 一時保存日
      ];
      const dntFields = [
        'Id',
        'NWZgsyo__r.Name', // 事業所
        'Dsp_SenroName__c', // 線路名
        'Dsp_DntNo__c', // 電柱番号
        'InspectionResult__c', // 竣工検査結果
        'SyunkoDate__c', // 竣工日
        'toLabel(DntCategory__c)', // 電柱区分
        'RepairKoziKinds__c', // 改修工事種類
        'Memo__c', // メモ
        'NWKoziHopeKoziContents__c', // 工事内容
        'NWKoziHopeMemo__c', // メモ
        'NWKoziHopeRemarks__c', // 備考
        'LastModifiedDate', // 最終更新日時
        'Order__r.TemporarilySavedDate__c', // 申込.一時保存日
        'SerialNumber__c', // 申込の契約電柱IDの通し番号。ソートに使う
      ];

      const response =
        await this.props.doGetOrderAndContractDntList(
            orderConditions, orderFields,
            dntConditions, dntFields, appSortParams);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['申込電柱', '取得'],
          },
        });
        return false;
      }
      this.gridRef.current.setInitItems('1473', this.props.contractDntList);

      // 警告チェック
      let _hasError = false;
      let _warningMessages = [];
      let reqMessages = [];
      let messages = [];
      const contractDntList = this.gridRef.current.getItems();

      // 必須チェック
      this.isRequiredItem(contractDntList, messages);

      // 相関チェック
      this.correlationCheck(contractDntList, messages);

      // エラーあり
      if (reqMessages.length > 0 || messages.length > 0) {
        _hasError = true;
        if (messages.length > 0) {
          _warningMessages = messages;
        }
      }

      this.setState({
        dirty: false,
        isModified: false,
        repairIraiDate: this.props.order.RepairIraiDate__c,
        isDisabled: (this.props.order.OrderStatus__c == '06' ? false : true),
        warningMessages: _warningMessages,
        hasError: _hasError,
      });
      await this.props.doSetGmnWarning('1473', _hasError);
      return true;
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 写真登録画面へ遷移する前に変更有無をチェックする
   * @param {object} ctx 選択した契約電柱
  */
  checkBeforeMoveAttachedPictures = (ctx) => {
    if ((this.gridRef.current &&
      this.gridRef.current.commitItemsModified()) ||
      this.props.dirty) {
      this.props.doShowMessage({
        message: {
          id: 'CW0013',
        },
        action: () => {
          this.doMoveAttachedPictures(ctx.item.Id);
        },
      });
      return;
    }
    this.doMoveAttachedPictures(ctx.item.Id);
  }

  /**
   * 一時保存ボタン押下
   */
  doSaveTempolary = async () => {
    try {
      const {dirty} = this.props;

      if (!this.state.isModified && !dirty) {
        // 更新がなければ何もしない
        console.warn('更新されていません');
        return;
      }

      // 保存処理
      const ret = await this.saveTempolary();
      if (!ret.success) {
        this.props.doShowMessage({message: ret.messages});
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.initialize();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一時保存処理
   */
  saveTempolary = async () => {
    const {values, userInfo, order} = this.props;
    const orderCondition= {
      'Id': order.Id,
      'LastModifiedDate': order.LastModifiedDate,
    };
    const today = getToDate();
    const orderData={
      'Id': order.Id,
      'KyogaKoziScheduleDate__c':
        (values.KyogaKoziScheduleDate ? values.KyogaKoziScheduleDate : null),
      'TemporarilySavedDate__c': today, // 一時保存日付
    };

    const contractDntConditionList=[];
    const contractDntList = [];
    const repairIraiDntInfo = this.gridRef.current.getItems();
    for (const dntInfo of repairIraiDntInfo) {
      contractDntConditionList.push({
        'Id': dntInfo.Id,
        'LastModifiedDate': dntInfo.LastModifiedDate,
      });
      contractDntList.push({
        'Id': dntInfo.Id,
        'RepairKoziKinds__c': dntInfo.RepairKoziKinds__c, // 改修工事種類
        'Memo__c': dntInfo.Memo__c, // メモ
        'NWKoziHopeKoziContents__c':
          (dntInfo.NWKoziHopeKoziContents__c ? dntInfo.NWKoziHopeKoziContents__c: '01'), // 中電NWへの工事希望／工事内容
        'NWKoziHopeMemo__c': dntInfo.NWKoziHopeMemo__c, // 中電NWへの工事希望／メモ
      });
    }

    const body={'record': {
      orderCondition: orderCondition,
      contractDntConditionList: contractDntConditionList,
      order: orderData,
      contractDntList: contractDntList,
    }};
    const result = await
    execApexRestApi(userInfo, 'ApiRepairRequest/repairRequestInput', body);
    const resResult = getResponseResult(result, ['改修依頼改修予定', '一時保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }
    return {success: true, messages: []};
  }

  /**
   * 登録前の入力チェック
   * @return {bool} true: エラーあり, false:エラーなし
   */
  isErrorInRegister = () => {
    let isError = false;
    if (this.props.setGmnWarning) {
      for (const gmnkey of ['1473']) {
        if (Object.hasOwnProperty.call(
            this.props.setGmnWarning, gmnkey)) {
          isError = isError || this.props.setGmnWarning[gmnkey];
        }
      }
    }
    return isError;
  }

  /**
   * 登録ボタン押下
   */
  doSave = async () => {
    try {
      // 編集ありの場合、ユーザーに保存するか破棄するか確認
      if (this.state.isModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0161',
            values: ['登録'],
          },
          action: async () => {
            // 保存処理
            const ret = await this.saveTempolary();
            if (!ret.success) {
              this.props.doShowMessage({message: ret.messages});
              return;
            }

            // 再検索
            const searchResult = await this.initialize();
            if (!searchResult) {
              return;
            }
            // エラーがなければ申込処理を行う
            if (!this.isErrorInRegister()) {
              this.registerData();
            }
            return;
          },
        });
        return;
      }
      // エラーがなければ申込処理を行う
      if (!this.isErrorInRegister()) {
        this.registerData();
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 登録処理
   */
  registerData = async () => {
    const {userInfo, order, values} = this.props;

    // 確認メッセージ
    this.props.doShowMessage({
      message: {
        id: 'CC0004',
      },
      action: async () => {
        const orderCondition= {
          'Id': order.Id,
          'LastModifiedDate': order.LastModifiedDate,
        };
        const orderData={
          'Id': order.Id,
          'KyogaKoziScheduleDate__c':
            (values.KyogaKoziScheduleDate ?
              values.KyogaKoziScheduleDate : null),
        };
        // 申込ステータス
        if (order.OrderStatus__c == '06') {
          Object.assign(orderData, {'OrderStatus__c': '11'});
        }

        const contractDntConditionList=[];
        const contractDntList = [];
        const repairIraiDntInfo = this.gridRef.current.getItems();
        for (const dntInfo of repairIraiDntInfo) {
          contractDntConditionList.push({
            'Id': dntInfo.Id,
            'LastModifiedDate': dntInfo.LastModifiedDate,
          });
          contractDntList.push({
            'Id': dntInfo.Id,
            'RepairKoziKinds__c': dntInfo.RepairKoziKinds__c, // 改修工事種類
            'Memo__c': dntInfo.Memo__c, // メモ
            'NWKoziHopeKoziContents__c':
              (dntInfo.NWKoziHopeKoziContents__c ? dntInfo.NWKoziHopeKoziContents__c: '01'), // 中電NWへの工事希望／工事内容
            'NWKoziHopeMemo__c': dntInfo.NWKoziHopeMemo__c, // 中電NWへの工事希望／メモ
          });
        }

        const body={'record': {
          orderCondition: orderCondition,
          contractDntConditionList: contractDntConditionList,
          order: orderData,
          contractDntList: contractDntList,
        }};
        const result = await
        execApexRestApi(userInfo, 'ApiRepairRequest/repairRequestInput', body);
        const resResult = getResponseResult(result, ['改修工事予定日', '保存']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        }

        // 再読み込み
        await this.initialize();

        this.props.doShowMessage({
          message: {
            id: 'CI0009',
            values: ['登録'],
          },
        });
      },
    },
    );
  }

  /**
    * 必須チェック
    * @param {array} contractDntList チェック対象
    * @param {array} messages メッセージ格納用
    */
  isRequiredItem(contractDntList, messages) {
    for (const contractDnt of contractDntList) {
      // 改修工事種類が未選択の場合はエラー表示する。
      if (!contractDnt.RepairKoziKinds__c) {
        messages.push({
          id: 'CE0027',
          values: ['改修工事種類'],
        });
      }

      // 改修工事種類にその他が選択されている場合、メモが未入力の場合エラー表示する。
      if (contractDnt.RepairKoziKinds__c == '99' && !contractDnt.Memo__c) {
        messages.push({
          id: 'CE0024',
          values: ['改修工事種類', 'その他', 'メモ'],
        });
      }

      // メモ
      if (!contractDnt.Memo__c &&
          !validateRule.maxLength(255, contractDnt.Memo__c)) {
        messages.push({
          id: 'CE0041',
          values: ['メモ'],
        });
      }
      // 中電NWへの工事希望／メモ
      if (!contractDnt.NWKoziHopeMemo__c &&
          !validateRule.maxLength(255, contractDnt.NWKoziHopeMemo__c)) {
        messages.push({
          id: 'CE0041',
          values: ['中電NWへの工事希望／メモ'],
        });
      }
    }
  }

  /**
  * 相関チェック
  * @param {array} contractDntList チェック対象
  * @param {array} messages メッセージ格納用
  */
  correlationCheck(contractDntList, messages) {
    const {values} = this.props;
    // 電力側工事種類が「無し」の場合、改修工事予定日が未入力の場合はエラー表示する。
    let isRequiredDate = false;
    for (const contractDnt of contractDntList) {
      if (!contractDnt.NWKoziHopeKoziContents__c ||
          contractDnt.NWKoziHopeKoziContents__c == '01') {
        isRequiredDate = true;
      }
    }
    if (isRequiredDate && !values.KyogaKoziScheduleDate) {
      messages.push({
        id: 'CE0024',
        values: ['電力側工事内容', '無し', '改修工事予定日'],
      });
    }
  }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFomatItemHandler = (s, e) => {
    if (s.cells === e.panel &&
      e.panel.cellType == wjgGrid.CellType.Cell) {
      const col = e.getColumn();
      // メモ
      if (col.name =='Memo__c' && s.rows[e.row]) {
        const data2 = s.rows[e.row].dataItem;
        // 改修工事種類=その他 のとき、入力可とする。それ以外の時、入力不可 */
        if (data2.RepairKoziKinds__c == '99') {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'uneditableCell');
        } else {
          wijmo.setText(e.cell, '');
          wijmo.addClass(e.cell, 'wj-state-disabled');
          wijmo.addClass(e.cell, 'uneditableCell');
        }
      }
    }
  }

  /**
   * 一覧の更新時
   *
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction = (s, e) => {
    try {
      let islist = false;
      // 一覧から更新データがあるか判定
      if (this.gridRef && this.gridRef.current &&
          this.gridRef.current.itemsEdited().length > 0) {
        islist = true;
      }
      if (this.state.isModified != islist) {
        this.setState({isModified: islist});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'RepairKoziKinds__c' ||
      e.getColumn().binding === 'NWKoziHopeKoziContents__c' ||
      e.getColumn().binding === 'RepairGaisanConsentStatus__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'RepairKoziKinds'; // 改修工事種類
      if (e.getColumn().binding === 'NWKoziHopeKoziContents__c') {
        categoryName = 'DrSideKoziKinds'; // 中国電力NWへの工事希望/工事内容
      } else if (e.getColumn().binding === 'RepairGaisanConsentStatus__c') {
        categoryName = 'RepairGaisanConsentStatus'; // 見積結果承諾状況
      }

      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  renderTable(data) {
    const props = {
      rowHeaderType: 'none',
      filterOn: true,
      exceptFilters: ['ContentDocument'],
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      validateEdits: false,
      getError: this.getError,
      formatItemFunction: this.onFomatItemHandler,
      refreshedFunction: this.refreshedFunction,
      filterChanging: this.filterChangingHandler,
      isReadOnly: this.state.isDisabled,
    };
    const {generalPurposeMap}=this.props;

    // コンボボックス: 改修工事種類
    const repairKoziKindsMap = createDataMap(generalPurposeMap, 'RepairKoziKinds');

    // コンボボックス: 工事内容
    const DrSideKoziKindsMap = createDataMap(generalPurposeMap, 'DrSideKoziKinds');

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding='NWZgsyo__r.Name' header='事業所' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
        <FlexGridColumnGroup header="電柱">
          <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass='uneditableCell' width={120} isReadOnly={true}/>
          <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass='uneditableCell' width={95} isReadOnly={true} isRequired={true}/>
          <FlexGridColumnGroup binding='DntCategory__c' header='電柱区分' dataType='String' cssClass='uneditableCell' width={65} isReadOnly={true}></FlexGridColumnGroup>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding='RepairKoziKinds__c' header='改修工事種類' dataMap={repairKoziKindsMap}/>
        <FlexGridColumnGroup name='Memo__c' binding='Memo__c' header='メモ' dataType='String'/>
        <FlexGridColumnGroup header="中電NWへの依頼事項">
          <FlexGridColumnGroup binding='NWKoziHopeKoziContents__c' header='工事内容' dataMap={DrSideKoziKindsMap}/>
          <FlexGridColumnGroup binding='NWKoziHopeMemo__c' header='メモ' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='NWKoziHopeRemarks__c' header='備考' dataType='String' cssClass='uneditableCell' isReadOnly={true} width={176}></FlexGridColumnGroup>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding='Order__r.TemporarilySavedDate__c' header='一時保存日' dataType='Date' cssClass='uneditableCell' isReadOnly={true} width={110}>
          <FlexGridCellTemplate cellType="Cell"
            template={(context) => {
              return changeDateFormat(
                  context.item.Order__r.TemporarilySavedDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {
      classes,
      handleSubmit,
      contractDntList,
    } =this.props;
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<SaveAltIcon />}
            onClick={handleSubmit(this.doSaveTempolary) }
            variant="contained"
            size="large"
            disabled={this.state.isDisabled}
          >
            <span>一時保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled || this.state.hasError}
          >
            <span>登録</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <BackButton props={this.props}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='登録' fixAction=''/>
          }
          {/* 警告メッセージ */}
          <WarningMessageArea messages={this.state.warningMessages}/>
          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid key="key1" item xs={12} sm={6} md={3}>
                <Field
                  name="OrderNo__c"
                  id="OrderNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="改修依頼番号"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key2" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaOfficeName"
                  id="kyogaOfficeName"
                  className={classes.fields}
                  component={renderTextField}
                  label="事業所"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key3" item xs={12} sm={6} md={3}>
                <Field
                  name="status"
                  id="status"
                  className={classes.fields}
                  component={renderTextField}
                  label="ステータス"
                  fullWidth
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key3-2" item xs={12} sm={6} md={3}>
                <Field
                  name="RepairGaisanConsentStatus__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="見積結果承諾状況"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key4" item xs={12} sm={6} md={3}>
                <Field
                  name="RepairIraiDate"
                  className={classes.fields}
                  component={renderTextField}
                  label="改修依頼日"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key5" item xs={12} sm={6} md={3}>
                <Field
                  name="RepairKoziDeadline"
                  className={classes.fields}
                  component={renderTextField}
                  label="改修完了希望日"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key6" item xs={12} sm={6} md={3}>
                <Field
                  name="KyogaKoziScheduleDate"
                  id="KyogaKoziScheduleDate"
                  className={classes.textFieldDate}
                  component={renderTextField}
                  label="改修工事予定日"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.date,
                  ]}
                  warn={[
                    validateCorrectFromDate(
                      this.props.values &&
                      Object.hasOwnProperty.call(this.props.values, 'RepairIraiDate') ?
                      this.props.values.RepairIraiDate : null,
                    ),
                  ]}
                />
              </Grid>
              <Grid key="key7" item xs={12} sm={6} md={3}>
                <Field
                  name="KyogasyaSendBackReason"
                  className={classes.fields}
                  component={renderTextField}
                  label="差戻理由"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

            </Grid>

          </PaperPart>

          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable(contractDntList)}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  contractDntList: PropTypes.array,
  history: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
  doShowMessage: PropTypes.func.isRequired,
  doSetPhotoRegistrationInfo: PropTypes.func,
  initialValues: PropTypes.object,
  doGetOrderAndContractDntList: PropTypes.func,
  doClearOrderContractDnt: PropTypes.func,
  doExistsPhoto: PropTypes.func,
  doDeletePhoto: PropTypes.func,
  dirty: PropTypes.bool,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
};


const mapStateToProps = (state) => {
  try {
    const init = {
      OrderNo__c: null,
      kyogaOfficeName: null,
      status: null,
      RepairIraiDate: null,
      RepairKoziDeadline: null,
      KyogaKoziScheduleDate: null,
      KyogasyaSendBackReason: null,
    };
    const order = state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
      state.contractDnt.orderContractDntList.order : null;
    // 申込電柱
    const contractDntList = state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
      state.contractDnt.orderContractDntList.contractDntList : [];
    if (order) {
      const generalPurposeMap = state.common.generalPurposeMap;
      init['OrderNo__c'] = order.OrderNo__c;
      init['kyogaOfficeName'] = (order.NWZgsyo__r ? order.NWZgsyo__r.Name :'');
      const idx = parseStatusOrderToRepairirai(order.OrderStatus__c);
      init['status'] = (idx != null ? generalPurposeMap.RepairIraiStatus[idx].Name : '');
      init['RepairIraiDate'] = order.RepairIraiDate__c;
      init['RepairKoziDeadline'] = order.KyogaKoziScheduleInputDeadline__c;
      init['KyogaKoziScheduleDate'] = order.KyogaKoziScheduleDate__c;
      init['KyogasyaSendBackReason'] = order.KyogasyaSendBackReason__c;
      init['RepairGaisanConsentStatus__c'] = order.RepairGaisanConsentStatus__c;
    }

    return {
      generalPurposeMap: state.common.generalPurposeMap,
      userInfo: state.auth.userInfo,
      orderId: state.attachorder.orderId,
      order: order,
      contractDntList: contractDntList,
      status: state.attachorder.orderstatus,
      initialValues: init,
      setGmnWarning: state.common.setGmnWarning,
    };
  } catch (error) {
    console.error(error);
  }
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doSetPhotoRegistrationInfo: contractDntOperations.doSetPhotoRegistrationInfo,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doGetOrderAndContractDntList:
    contractDntOperations.doGetOrderAndContractDntList,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doExistsPhoto: contractDntOperations.doExistsPhoto,
  doDeletePhoto: contractDntOperations.doDeletePhoto,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
};

const FORM_NAME = 'RepairRequestInput';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

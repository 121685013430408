/* eslint-disable react/prop-types */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: { // if arrow = false
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  tooltipArrow: { // if arrow = true
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  arrow: {
    color: '#f5f5f9',
  },
}))(Tooltip);

export default function MuiTooltip({children, id, content,
  placement='bottom-end', enterDelay=300, enterNextDelay=300, leaveDelay=250}) {
  return (
    <HtmlTooltip id={id} title={content} arrow placement={placement}
      enterDelay={enterDelay} enterNextDelay={enterNextDelay}
      leaveDelay={leaveDelay}>
      {children}
    </HtmlTooltip>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import * as wjCore from '@grapecity/wijmo';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, changeDateFormat, convertCommaSeparated,
  getComboName, createTyohyoPdf, getErrorCode, API_CODE} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import {orderResultListFields, orderResultContractDntFields} from '../../../../../../common/SFFields';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
});

// Order__cの申込情報の項目
const orderInfo = [
  {key: 'OrderNo__c', name: '可否判定申込番号', value: '', type: 'string'},
  {key: 'ProprietyJudgeOrderDate__c', name: '可否判定申込年月日', value: '', type: 'date'},
  {key: 'OrderKyogaZgsya__r', name: '申込共架事業者', value: '', type: 'string'},
  {key: 'KoziTitle__c', name: '工事件名', value: '', type: 'string'},
];
const proprietyResult = [
  {key: 'BillingAmountTaxIn', name: '可否判定費用（税込）', value: '', type: 'number'},
  {key: 'KaHonsu__c', name: '可本数', value: '', type: 'number'},
  {key: 'ConditionalKaHonsu__c', name: '条件付可本数', value: '', type: 'number'},
  {key: 'HiHonsu__c', name: '否本数', value: '', type: 'number'},
  {key: 'ProprietyJudgeAnswerDate__c', name: '可否判定回答日', value: '', type: 'date'},
  {key: 'ProprietyJudgeSendBackDate__c', name: '可否判定差戻日', value: '', type: 'date'},
  {key: 'ProprietyJudgeReorderDate__c', name: '可否判定再申込日', value: '', type: 'date'},
];

/**
 * 可否判定結果確認
 * 画面ID:1009
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderResultDetail: null,
    };
    this.Expired = false; // true:申込ステータスが完結(期限切れ)
    this.gridRef = React.createRef();
    this.refreshedFunction = this.refreshedFunction.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    for (const row of orderInfo) {
      row['value'] = '';
    }
    for (const row of proprietyResult) {
      row['value'] = '';
    }
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {orderId, generalPurposeMap} = this.props;

      if (!orderId) {
        return;
      }

      // パラメータ設定
      const appConditions = {
        Id: orderId,
        OrderCategory__c: getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory01', 2),
      };
      const subquery = [{
        fields: orderResultContractDntFields,
        table: 'ContractDnts__r',
        sortParams: {SerialNumber__c: 1},
      }];

      const response = await this.props.doGetOrderResultDetail(
          appConditions, orderResultListFields, subquery);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['可否判定申込', '取得'],
          },
        });
        return;
      }

      if (this.props.orderResultDetail) {
        const _orderResultDetail = this.props.orderResultDetail;
        for (let i = 0; i < orderInfo.length; i++) {
          const key = orderInfo[i].key;
          if (Object.hasOwnProperty.call(_orderResultDetail, key)) {
            if (key == 'OrderKyogaZgsya__r') {
              orderInfo[i].value =
                Object.hasOwnProperty.call(_orderResultDetail[key], 'Name') ? _orderResultDetail[key].Name : '' +
                Object.hasOwnProperty.call(_orderResultDetail[key], 'Account__r') &&
                Object.hasOwnProperty.call(_orderResultDetail[key].Account__r, 'Name') ?
                _orderResultDetail[key].Account__r.Name: '';
            } else {
              orderInfo[i].value =
                orderInfo[i].type == 'date' ?
                changeDateFormat(_orderResultDetail[key]) :
                _orderResultDetail[key];
            }
          }
        }
        for (let i = 0; i < proprietyResult.length; i++) {
          const key = proprietyResult[i].key;
          if (Object.hasOwnProperty.call(_orderResultDetail, key)) {
            if (key == 'BillingAmountTaxIn') {
              proprietyResult[i].value = convertCommaSeparated(_orderResultDetail[key]) + '円';
            } else {
              proprietyResult[i].value =
              proprietyResult[i].type == 'date' ?
              changeDateFormat(_orderResultDetail[key]) :
              _orderResultDetail[key];
            }
          }
        }
      }
      // リロード時にレンダリングさせるためにstateを設定
      this.setState({orderResultDetail: this.props.orderResultDetail});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderResultDetail();
  }

  /**
   * 一覧のスタイルを変更
   * 可否判定結果が否の行をグレーアウトする
   * 申込が期限切れの場合は全ての行をグレーアウトする
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction(s, e) {
    try {
      const impossibleResult = '0'; // 可否判定結果 否
      const rows = s.rows;
      for (let i = 0; i < rows.length; i++) {
        if (this.Expired) {
          rows[i].cssClass = 'gray';
        } else {
          if (rows[i].dataItem.ProprietyJudgeResult__c == impossibleResult) {
            rows[i].cssClass = 'gray';
          }
        }
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c' ||
      e.getColumn().binding === 'ProprietyJudgeResult__c' ||
      e.getColumn().binding === 'IskkCategory__c'||
      e.getColumn().binding === 'AttachType__c' ||
      e.getColumn().binding === 'NWKoziHopeKoziContents__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wjCore.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分
      if (e.getColumn().binding === 'ProprietyJudgeResult__c') {
        categoryName = 'ProprietyCategory'; // 可否判定結果
      } else if (e.getColumn().binding === 'IskkCategory__c') {
        categoryName = 'IskkCategory'; // 一束化
      } else if (e.getColumn().binding === 'AttachType__c') {
        categoryName = 'AttachType'; // 取付種別
      } else if (e.getColumn().binding === 'NWKoziHopeKoziContents__c') {
        categoryName = 'DrSideKoziKinds'; // 中国電力NWへの工事希望/工事内容
      }

      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }


  /**
   * 可否判定結果回答書ダウンロード
   */
   doDownloadFile = async () => {
     try {
       const {userInfo, orderResultDetail, generalPurposeMap} = this.props;

       const orderStatusUnderCalc = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus04', 2);
       const orderStatusUnderP = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus05', 2);
       const orderStatusComplete = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus93', 2);
       if (orderResultDetail.OrderStatus__c != orderStatusUnderCalc &&
        orderResultDetail.OrderStatus__c != orderStatusUnderP &&
        orderResultDetail.OrderStatus__c != orderStatusComplete) {
         this.props.doShowMessage({
           message: {
             id: 'PE0177',
             values: [orderResultDetail.OrderNo__c],
           },
         });
         return;
       }

       const ret = await createTyohyoPdf(userInfo, 'proprietyJudgeAnswer',
           {OrderNo__c: orderResultDetail.OrderNo__c}, '可否判定結果回答書.pdf');
       if (!ret) {
         this.props.doShowMessage({
           message: {
             id: 'CE0052',
             values: ['可否判定結果回答書', 'ダウンロード'],
           },
         });
         return;
       }
       // 初回のみ申込の可否判定結果受理日を設定
       if (orderResultDetail.ProprietyResultAcceptedDate__c) {
         return;
       }
       const body = {
         record: {
           conditions: {
             Id: orderResultDetail.Id,
             LastModifiedDate: orderResultDetail.LastModifiedDate,
           },
           order: {
             Id: orderResultDetail.Id,
             ProprietyResultAcceptedDate__c: null, // 受理日はサーバーで設定する
           },
           stage: 'UPDATE_PROPRIETY_ACCEPTED_DATE',
         },
       };
       // 受理日は電力会社側の確認用項目なので、
       // 更新に失敗してもエラーダイアログは出さない
       await this.props.doUpdateProprietyOrder(
           'updateProprietyResultAcceptedDate', body);
     } catch (error) {
       this.props.doShowMessage({
         message: 'CS0001',
         action: () => {
           this.props.history.push('/login');
         },
       });
     }
   }

   /**
   * 一覧表示
   * @return {object}
   */
   renderDetailTable() {
     const {orderResultDetail, generalPurposeMap} = this.props;
     const contractDntList = orderResultDetail &&
      orderResultDetail.ContractDnts__r &&
      orderResultDetail.ContractDnts__r.totalSize > 0 ?
      orderResultDetail.ContractDnts__r.records : [];

     const props = {
       rowHeaderType: 'none',
       filterOn: true,
       headersVisibility: 'Column',
       allowDragging: 'Columns',
       counterOn: false,
       AddDeleteOn: false,
       frozenColumns: 3,
       data: contractDntList,
       style: {height: '300px'},
       isReadOnly: true,
       refreshedFunction: this.refreshedFunction,
       filterChanging: this.filterChangingHandler,
     };

     return (
       <div>
         <CustomFlexGrid ref={this.gridRef} {...props}>
           <FlexGridColumnGroup header="電柱" align="center">
             <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String"/>
             <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String"/>
             <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" width={100}>
               <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                 return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
               }}/>
             </FlexGridColumnGroup>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup header="可否判定結果" align="center">
             <FlexGridColumnGroup binding="ProprietyJudgeDoneDate__c" header="実施日" dataType="Date" width={110}>
               <FlexGridCellTemplate cellType="Cell"
                 template= {(context) => {
                   return changeDateFormat(
                       context.item.ProprietyJudgeDoneDate__c);
                 }}
               />
             </FlexGridColumnGroup>
             <FlexGridColumnGroup binding="ProprietyJudgeResult__c" header="結果" dataType="String">
               <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                 return getComboName(generalPurposeMap, 'ProprietyCategory', ctx.item.ProprietyJudgeResult__c);
               }}/>
             </FlexGridColumnGroup>
             <FlexGridColumnGroup binding="ImpossibleReason__c" header="否理由" dataType="String"/>
             <FlexGridColumnGroup binding="ProprietyJudgeRemarks__c" header="備考" dataType="String"/>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup header="電線" align="center">
             <FlexGridColumnGroup binding="IskkCategory__c" header="一束化">
               <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                 return getComboName(generalPurposeMap, 'IskkCategory', ctx.item.IskkCategory__c);
               }}/>
             </FlexGridColumnGroup>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup binding="AttachType__c" header="取付種別" dataType="String" width={110}>
             <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
               return getComboName(generalPurposeMap, 'AttachType', ctx.item.AttachType__c);
             }}/>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup header="中国電力NWへの工事希望" align="center">
             <FlexGridColumnGroup binding="NWKoziHopeKoziContents__c" header="工事内容" dataType="String">
               <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                 return getComboName(generalPurposeMap, 'DrSideKoziKinds', ctx.item.NWKoziHopeKoziContents__c);
               }}/>
             </FlexGridColumnGroup>
             <FlexGridColumnGroup binding="NWKoziHopeMemo__c" header="メモ" dataType="String"/>
             <FlexGridColumnGroup binding="NWKoziHopeRemarks__c" header="備考" dataType="String"/>
           </FlexGridColumnGroup>
         </CustomFlexGrid>
       </div>
     );
   }

   /**
    * 申込情報表示
    * @param {array} rows
    * @return {object}
    */
   renderTable(rows) {
     return (
       <TableContainer>
         <Table size="small" style={{border: '0.5px solid gray'}}>
           <TableBody>
             {rows.map((row) => (
               <TableRow key={row.name}>
                 <TableCell component="th" scope="row"
                   style={{borderRight: '1px solid gray', borderBottom: '1px solid gray', background: '#001a37', color: 'white', width: '200px'}}>
                   {row.name}
                 </TableCell>
                 {row.type == 'number' &&
                 <TableCell align="right" style={{borderBottom: '1px solid gray', width: 'auto', minWidth: '100px'}}>{row.value}</TableCell>
                 }
                 {row.type != 'number' &&
                 <TableCell align="left" style={{borderBottom: '1px solid gray', width: 'auto', minWidth: '100px', maxWidth: '400px'}}>{row.value}</TableCell>
                 }
               </TableRow>
             ))}
           </TableBody>
         </Table>
       </TableContainer>
     );
   }

   render() {
     const {classes, handleSubmit} = this.props;

     const footerBtn = (
       <Grid container
         justifyContent="center"
         alignItems="flex-start"
         spacing={1}
       >
         <Grid key="key1009DL" item>
           <PositiveButton
             id="btn1009DL"
             onClick={handleSubmit(this.doDownloadFile)}
             variant="contained"
             size="large"
             startIcon={<GetAppIcon/>}
           >
             <span>可否判定結果受理</span>
           </PositiveButton>
         </Grid>
         <Grid key="key1009Back" item>
           <BackButton props={this.props}/>
         </Grid>
       </Grid>
     );

     return (
       <form noValidate autoComplete="off" className={classes.root}>
         <MainContainer
           props={this.props}
           footerBtn={footerBtn}>
           <Grid container
             direction="column"
             justifyContent="flex-start"
             alignItems="flex-start"
             spacing={1}
           >
             <Grid item xs={12}>
               {this.renderTable(orderInfo)}
             </Grid>
             <Grid item xs={12}>
               <Typography variant="h5" gutterBottom className={classes.title}>
               回答結果
               </Typography>
             </Grid>
             <Grid item xs={12}>
               {this.renderTable(proprietyResult)}
             </Grid>
             <Grid item xs={12}>
               <Typography variant="h5" gutterBottom className={classes.title}>
               明細一覧
               </Typography>
               {this.renderDetailTable()}
             </Grid>
           </Grid>
         </MainContainer>
       </form>
     );
   }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  orderId: PropTypes.string,
  orderResultDetail: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderResultDetail: PropTypes.func,
  doUpdateProprietyOrder: PropTypes.func,
  doClearOrderResultDetail: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    orderResultDetail: state.attachorder.orderResultDetail,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetOrderResultDetail: attachorderOperations.doGetOrderResultDetail,
  doUpdateProprietyOrder: attachorderOperations.doUpdateProprietyOrder,
  doClearOrderResultDetail: attachorderOperations.doClearOrderResultDetail,
};

const FORM_NAME = 'ProprietyResultDetail';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

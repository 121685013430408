import {createTheme} from '@material-ui/core';

/**
 * 共通テーマ(Material-UI使用)
 */
const CommonTheme = createTheme({
  palette: {
    primary: {
      main: '#4F89C6',
      dark: '#001a37',
    },
    secondary: {
      main: '#EA5433',
    },
  },
  breakpoints: {
    values: {
      lg: 1366,
    },
  },
  menu: {
    width: 270,
  },
});

export default CommonTheme;

import axios from 'axios';

import {getSenroCodeList, setSenroCodeList} from './actions';

import conf from './../../config/config.js';

/**
 * 線路コード一覧 取得
 * @param {object} conditions 検索条件
 * @param {string[]|object} fields 取得するフィールド名
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetSenroCodeList = (conditions, fields) => {
  console.log('線路コード一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    const ret = await axios.post(conf.API_URL + 'SenroCode/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getSenroCodeList(ret.data.body.data ?
                                     ret.data.body.data : null);

    if (ret.data.body.errorCode != '00000') {
      console.dir();
    }
    dispatch(retData);

    return ret;
  };
};

const doSetSenroCodeList = (senroCodeList) =>{
  console.log('線路コード設定');
  return async (dispatch) =>{
    dispatch(setSenroCodeList(senroCodeList));
  };
};

export default {
  doGetSenroCodeList,
  doSetSenroCodeList,
};


import {combineReducers} from 'redux';
import types from './types';

const INITIAL_STATE = {type: '', title: '', context: [], open: false, action: ''};

const isRequesting = (state = false, action) => {
  switch (action.type) {
    case types.START_REQUEST:
      return true;
    case types.END_REQUEST:
      return false;
    default:
      return state;
  }
};

const dialogInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.OPEN_DIALOG:
      return action.dialogInfo;
    case types.CLOSE_DIALOG:
      return {...state, open: false};
    default:
      return state;
  }
};

const modalInfo = (state = {btnExist: false, open: false}, action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return action.modalInfo;
    case types.CLOSE_MODAL:
      return {btnExist: false, open: false};
    default:
      return state;
  }
};

const checkAccess = (state = {time: null}, action) => {
  switch (action.type) {
    case types.CHECK_ACCESS:
      return action.accessInfo;
    default:
      return state;
  }
};

const getParam = (state = {time: null}, action) => {
  switch (action.type) {
    case types.GET_PARAM:
      return action.getParam;
    default:
      return state;
  }
};

const stockUrl = (state = null, action) => {
  switch (action.type) {
    case types.SET_STOCK_URL:
      return action.stockUrl;

    default:
      return state;
  }
};

// 約款承諾状態
const isTermsAccepted = (state = null, action) => {
  switch (action.type) {
    case types.SET_IS_TERMS_ACCEPTED:
      return action.isTermsAccepted;
    default:
      return state;
  }
};

// 未確認重要事項確認有無
const hasJuyoZiko = (state = null, action) => {
  switch (action.type) {
    case types.SET_HAS_JUYO_ZIKO:
      return action.hasJuyoZiko;
    default:
      return state;
  }
};

// 買取保留状態
const isHoldPurchase = (state = null, action) => {
  switch (action.type) {
    case types.SET_IS_HOLD_PURCHASE:
      return action.isHoldPurchase;
    default:
      return state;
  }
};

// ログイン日時
const loginDate = (state = null, action) => {
  switch (action.type) {
    case types.SET_LOGIN_DATE:
      return action.loginDate;
    default:
      return state;
  }
};

// 切替前判定フラグ
const isKirikaeMae = (state = null, action) => {
  switch (action.type) {
    case types.SET_IS_KIRIKAE_MAE:
      return action.isKirikaeMae;
    default:
      return state;
  }
};

const generalPurposeMap = (state = null, action) => {
  switch (action.type) {
    case types.GET_ALL_GENERAL_PURPOSE_MAP:
      return action.generalPurposeMap;
    default:
      return state;
  }
};

// 汎用マスタ一覧
const generalPurposeList = (state = null, action) => {
  switch (action.type) {
    case types.GET_GENERAL_PURPOSE_LIST:
      return action.generalPurposeList;
    default:
      return state;
  }
};

// 汎用マスタ
const generalPurpose = (state = null, action) => {
  switch (action.type) {
    case types.REGIST_GENERAL_PURPOSE:
      return action.generalPurpose;
    case types.UPDATE_GENERAL_PURPOSE:
      return action.generalPurpose;
    default:
      return state;
  }
};

// 汎用マスタID
const generalPurposeId = (state = null, action) => {
  switch (action.type) {
    case types.SET_GENERAL_PURPOSE_ID:
      return action.generalPurposeId;
    default:
      return state;
  }
};

const nwzgsyoNamefilter = (state = null, action) =>{
  switch (action.type) {
    case types.GET_NWZGSYO_NAMEFILTER:
      return action.nwzgsyoNamefilter;
    default:
      return state;
  }
};

// 添付ファイル取得
const attachedFileList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ATTACHED_FILE_LIST:
      return action.attachedFileList;
    default:
      return state;
  }
};

// 会社マスタ一覧
const accountList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_LIST:
      return action.accountList;
    default:
      return state;
  }
};

// 会社マスタ 取得
const account = (state = null, action) => {
  switch (action.type) {
    case types.RETRIEVE_ACCOUNT:
      return action.account;
    default:
      return state;
  }
};

// 会社マスタID
const accountId = (state = null, action) => {
  switch (action.type) {
    case types.SET_ACCOUNT_ID:
      return action.accountId;
    default:
      return state;
  }
};

// 会社マスタ一覧取得（制限なし）
const accountMasterList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_MASTER_LIST:
      return action.accountMasterList;
    default:
      return state;
  }
};

// 会社マスタ一 登録/更新結果
const accountResult = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_ACCOUNT:
      return action.accountResult;
    default:
      return state;
  }
};

// 担当者マスタ一覧 取得
const contactList = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTACT_LIST:
      return action.contactList;
    default:
      return state;
  }
};

// 担当者マスタ1件 取得
const contact = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTACT:
      return action.contact;
    default:
      return state;
  }
};

// 担当者マスタID 設定
const contactId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CONTACT_ID:
      return action.contactId;
    default:
      return state;
  }
};

// 担当者マスタ一覧 取得（制限なし）
const contactMasterList = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTACT_MASTER_LIST:
      return action.contactMasterList;
    default:
      return state;
  }
};

// 担当者 テンポラリ情報 登録
const tempContact = (state = null, action) => {
  switch (action.type) {
    case types.SET_TEMP_CONTACT:
      return action.tempContact;
    default:
      return state;
  }
};

// 設備種別設定
const stbType = (state = null, action) => {
  switch (action.type) {
    case types.SET_STB_TYPE:
      return action.stbType;
    default:
      return state;
  }
};

// 画面の参照状態 設定
const referenceMode = (state = null, action) => {
  switch (action.type) {
    case types.SET_REFERENCE_MODE:
      return action.referenceMode;
    default:
      return state;
  }
};

// 遷移元画面ID設定
const transitionSrcId = (state = null, action) => {
  switch (action.type) {
    case types.SET_TRANSITION_SRC_ID:
      return action.transitionSrcId;
    default:
      return state;
  }
};

// 呼び出し元パス設定
const ybdsPath = (state = null, action) => {
  switch (action.type) {
    case types.SET_YBDS_PATH:
      return action.ybdsPath;
    default:
      return state;
  }
};

// 呼び出し元パス設定
const setGmnWarning = (state = null, action) => {
  switch (action.type) {
    case types.SET_GMN_WARNING:
      let data = state ? state : {};
      if (action.warning == false) {
        if (action.Id in data) {
          delete data[action.Id];
        }
      } else {
        data[action.Id] = action.warning;
      }
      return data;

    default:
      return state;
  }
};

const getDntSelectpole = (state = null, action) =>{
  switch (action.type) {
    case types.GET_DNT_SELECTPOLE:
      return action.dntList;
    default:
      return state;
  }
};

const reducer = combineReducers({
  isRequesting,
  dialogInfo,
  modalInfo,
  checkAccess,
  getParam,
  stockUrl,
  isTermsAccepted,
  hasJuyoZiko,
  isHoldPurchase,
  loginDate,
  isKirikaeMae,
  generalPurposeMap,
  generalPurposeList,
  generalPurpose,
  generalPurposeId,
  nwzgsyoNamefilter,
  accountList,
  account,
  accountId,
  accountMasterList,
  accountResult,
  contactList,
  contact,
  contactId,
  contactMasterList,
  tempContact,
  attachedFileList,
  stbType,
  referenceMode,
  transitionSrcId,
  ybdsPath,
  setGmnWarning,
  getDntSelectpole,
});

export default reducer;

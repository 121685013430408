import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, getFormSyncWarnings} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import {changeDateFormatter,
  getCodeFromGeneralPurposeMap, getErrorCode, API_CODE,
  dntNoFormat, senroNameFormat, getResponseResult} from '../../../../../../common/common.js';
import {checkFileNameLength, checkFileSizeZero,
  checkSameName} from '../../../../../../common/fileOperations.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import Required from '../../../../../atoms/RequiredMark.js';
import Upload from '../../../../../organisms/UploadCustom';
import * as validateRule from '../../../../../validateRule.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import {orderBasicFields, mainContractDntInfo} from '../../../../../../common/SFFields';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
  },

});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  OrderKyogaZgsya__c: null, // 申込共架事業者 更新しない
  KoziTitle__c: null, // 工事件名
  Dsp_KoziPlaceMainDntNo__c: null, // 表示用 代表電柱番号
  KoziPlaceMainDntNo_SenroCode__c: null, // 代表電柱_線路コード
  KoziPlaceMainDntNo_SenroName__c: null, // 代表電柱_線路名
  KoziPlaceMainDntNo__c: null, // 代表電柱番号
  KoziPlacePrefectures__c: null, // 都道府県
  KoziPlaceMunicipalities__c: null, // 市区町村
  KoziPlacePlace__c: null, // 場所
  StbType__c: null, // 設備種別 更新しない
  KyogaType__c: null, // 共架種別
  ZizenNegotiationNo__c: null, // 事前協議番号
  MemoContents__c: null, // メモ内容
  OrderCategory__c: null, // 申込区分 更新しない
  OrderStatus__c: null, // 申込ステータス 更新しない
  MainDntNoNWZgsyo__c: null, // 代表電柱番号の中電NW事業所(汎用マスタのId)
  KyogaZgsya__c: null, // 共架事業者(AccountのId)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  TemporarilySavedDate__c: null, // 一時保存日
};
const updateParams = {
  KoziTitle__c: null, // 工事件名
  Dsp_KoziPlaceMainDntNo__c: null, // 表示用 代表電柱番号
  KoziPlaceMainDntNo_SenroCode__c: null, // 代表電柱_線路コード
  KoziPlaceMainDntNo_SenroName__c: null, // 代表電柱_線路名
  KoziPlaceMainDntNo__c: null, // 代表電柱番号
  KoziPlacePrefectures__c: null, // 都道府県
  KoziPlaceMunicipalities__c: null, // 市区町村
  KoziPlacePlace__c: null, // 場所
  KyogaType__c: null, // 共架種別
  ZizenNegotiationNo__c: null, // 事前協議番号
  MemoContents__c: null, // メモ内容
  MainDntNoNWZgsyo__c: null, // 代表電柱番号の中電NW事業所(汎用マスタのId)
  UpsyaUserId__c: null,
  TemporarilySavedDate__c: null, // 一時保存日
};

/**
 * 訂正取付申込基本情報
 * 画面ID:1602
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootFiles: [],
      horizonFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      rootDelFiles: [],
      horizonDelFiles: [],
      specDelFiles: [],
      otherDelFiles: [],
      pictureDelFiles: [],
      isDisabled: true,
      modalOpen: false,
      isModified: false,
      warningMessages: null,
    };
    // 代表電柱情報
    this.MainDnt = {};

    // 線設備と点設備の画面項目を切り替えるフラグ初期化。true:線設備、false:点設備
    this.isSen = this.props.stbType == '1' ? true : false;
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {orderId, generalPurposeMap} = this.props;
      const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

      // 検索
      await this.searchData();

      this.isSen = this.props.stbType == '1' ? true : false;
      this.MainDnt = {};
      let wkIsDisabled =
        (!orderId || (this.props.order && this.props.order.OrderStatus__c ==
        orderStatusCreate) ? false : true); // 作成中のみ編集可
      this.setState({
        isDisabled: wkIsDisabled,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.props.reset();
      this.props.doClearOrderInfo();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/CorrectAttachOrderList'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * ルート図設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setRootFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A08';
        _files.push(file);
      }
      this.setState({
        rootFiles: _files,
        rootDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 平面図設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setHorizonFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A02';
        _files.push(file);
      }
      this.setState({
        horizonFiles: _files,
        horizonDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 仕様書設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setSpecFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A01';
        _files.push(file);
      }
      this.setState({
        specFiles: _files,
        specDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * その他資料設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setOtherFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A12';
        _files.push(file);
      }
      this.setState({
        otherFiles: _files,
        otherDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込写真設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setPictureFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A22';
        _files.push(file);
      }
      this.setState({
        pictureFiles: _files,
        pictureDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 選択した電柱情報を取得
   * @param {object} dnt
   */
  setSelectedDnt = async (dnt) => {
    try {
      const {change, generalPurposeMap} = this.props;
      const selectedDnt = dnt && dnt.length > 0 ? dnt[0] : null;
      if (selectedDnt) {
        // フォームに電柱情報設定
        let prefecturesCode = null;
        if (generalPurposeMap.PrefCode) {
          const masterData =
            generalPurposeMap.PrefCode.filter(
                (data) => data.Name ==
                selectedDnt.StbItiPrefectures);
          prefecturesCode =
            masterData.length > 0 ? masterData[0].Code__c : null;
        }
        // 汎用マスタから代表電柱の中電事業所のIdを取得
        let nWZgsyo = null;
        if (generalPurposeMap.NWZgsyo) {
          const masterData = generalPurposeMap.NWZgsyo.filter(
              (data) => data.Code__c ==
              selectedDnt.NWZgsyo__r_Code__c);
          nWZgsyo = masterData.length > 0 ? masterData[0].Id : null;
        }
        change('Dsp_KoziPlaceMainDntNo__c',
            dntNoFormat(
                selectedDnt['SenroCode__c'], selectedDnt['DntNoManualInput__c'],
                selectedDnt['K6KansnNo__c'], selectedDnt['K6Bunk1__c'],
                selectedDnt['K6Bunk2__c'], selectedDnt['K6Bunk3__c'],
                selectedDnt['K22SzbtNo__c'], selectedDnt['K22GatiCategory__c']));
        change('KoziPlaceMainDntNo_SenroCode__c', selectedDnt.SenroCode__c);
        change('KoziPlaceMainDntNo_SenroName__c',
            senroNameFormat(selectedDnt['SenroName__c'], selectedDnt['SenroNameKana__c'], selectedDnt['SenroCode__c']));
        change('KoziPlaceMainDntNo__c', selectedDnt['DntNo__c'] ?
          selectedDnt['DntNo__c'] : selectedDnt['DntNoManualInput__c']);
        change('KoziPlacePrefectures__c', prefecturesCode);
        change('KoziPlaceMunicipalities__c', selectedDnt.StbItiMunicipalities);
        change('MainDntNoNWZgsyo__c', nWZgsyo);
        // 代表電柱登録データを作成
        this.setMainDntInfo(selectedDnt, nWZgsyo);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 契約電柱に登録する代表電柱情報作成
   * （共架種別は保存時に設定する）
   * @param {object} dntInfo 電柱選択の戻り値
   * @param {object} nwZgsyo 中電NW事業所（汎用マスタのId）
  */
  setMainDntInfo = (dntInfo, nwZgsyo) => {
    const {userInfo, order, stbType, generalPurposeMap} = this.props;
    this.MainDnt = {};
    Object.assign(this.MainDnt, mainContractDntInfo);
    for (const key in mainContractDntInfo) {
      if (Object.hasOwnProperty.call(dntInfo, key)) {
        this.MainDnt[key] = dntInfo[key];
      }
    }
    this.MainDnt['SerialNumber__c'] = 1;
    this.MainDnt['NWZgsyo__c'] = nwZgsyo;
    if (order &&
        Object.hasOwnProperty.call(order, 'Id') &&
        order.Id) {
      this.MainDnt['StbType__c'] = order.StbType__c;
      this.MainDnt['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
    } else {
      this.MainDnt['StbType__c'] = stbType;
      this.MainDnt['KyogaZgsya__c'] = userInfo.AccountId;
    }
    // 訂正取付の取付種別は新規'1'のみ
    // 点設備だったら固定。線設備はユーザが選択するので初期値はnull。
    this.MainDnt['AttachType__c'] = stbType != '2' ?
       null : getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachType1', 1);
    // 線設備の場合、一束化区分は単独'1'固定。線設備以外はnull。
    this.MainDnt['IskkCategory__c'] = stbType == '1' ? '1' : null;
  }

  /**
   * 申込と添付ファイルの登録/更新処理
   */
  insertOrUpdate = async () => {
    const {userInfo, values, order, generalPurposeMap} = this.props;
    const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    // SF削除対象ファイル抽出
    let _rootDelFiles = this.state.rootDelFiles != null ?
      this.state.rootDelFiles : [];
    const deleteFiles = _rootDelFiles
        .concat(this.state.horizonDelFiles)
        .concat(this.state.specDelFiles)
        .concat(this.state.otherDelFiles)
        .concat(this.state.pictureDelFiles);

    const isSetMainDnt = (this.MainDnt &&
          Object.hasOwnProperty.call(this.MainDnt, 'SerialNumber__c') &&
          this.MainDnt['SerialNumber__c'] == 1);
    // 代表電柱情報を設定
    if (isSetMainDnt) {
      this.MainDnt['KyogaType__c'] =
        Object.hasOwnProperty.call(values, 'KyogaType__c') ? values.KyogaType__c : null;
    }
    let resultSave = null;
    if (order &&
        Object.hasOwnProperty.call(order, 'Id') &&
        order.Id) {
      const conditions = {
        Id: order.Id,
        LastModifiedDate: this.props.orderLastModifiedDate,
      };
      const updateData = {Id: order.Id}; // Idは更新キー
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(values, key) ) {
          updateData[key] = values[key];
        }
      }
      updateData['NWZgsyo__c'] =
        (Object.hasOwnProperty.call(values, 'MainDntNoNWZgsyo__c') ?
            values['MainDntNoNWZgsyo__c'] : null);
      resultSave = await this.props.doSaveCorrectOrder(
          conditions, updateData,
          this.state.rootFiles, this.state.horizonFiles,
          this.state.specFiles, this.state.otherFiles,
          this.state.pictureFiles, deleteFiles,
          (isSetMainDnt ? this.MainDnt : {}));
    } else {
      const insertData = {};
      for (const key in addParams) {
        if (Object.hasOwnProperty.call(values, key)) {
          insertData[key] = values[key];
        }
      }
      insertData['NWZgsyo__c'] =
        (Object.hasOwnProperty.call(values, 'MainDntNoNWZgsyo__c') ?
            values['MainDntNoNWZgsyo__c'] : null);
      insertData['OrderKyogaZgsya__c'] = userInfo.AccountId;
      insertData['KyogaTanto__c'] = userInfo.Id;
      insertData['OrderCategory__c'] =
          generalPurposeMap.OrderCategory
              .filter((data) => data.ExternalId__c == 'OrderCategory13')[0].Code__c; // 訂正取付申込
      insertData['OrderStatus__c'] = orderStatusCreate; // 作成中
      resultSave = await this.props.doSaveCorrectOrder(
          null, insertData,
          this.state.rootFiles, this.state.horizonFiles,
          this.state.specFiles, this.state.otherFiles,
          this.state.pictureFiles, deleteFiles,
          (isSetMainDnt ? this.MainDnt : {}));
    }
    const resResult = getResponseResult(resultSave, ['申込', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    let id = null;
    if (Object.hasOwnProperty.call(this.props.orderResult, 'orderId')) {
      id = this.props.orderResult.orderId;
    }
    await this.props.doSetOrderId(id);
    this.MainDnt = {};
    return {success: true, messages: resResult.messages};
  }

  /**
   * ファイルの必須チェック
   * ファイルが有ればtrue, なければfalse
   * @return {*}
   */
  requiredFilesExists() {
    let result = true;
    if (this.isSen) { // 線設備
      if (this.state.rootFiles.filter(
          (data) => data.ContentDocumentId).length == 0 ||
        this.state.specFiles.filter(
            (data) => data.ContentDocumentId).length == 0 ||
        this.state.pictureFiles.filter(
            (data) => data.ContentDocumentId).length == 0) {
        result = false;
      }
    } else { // 点設備
      if (this.state.horizonFiles.filter(
          (data) => data.ContentDocumentId).length == 0 ||
        this.state.specFiles.filter(
            (data) => data.ContentDocumentId).length == 0 ||
        this.state.pictureFiles.filter(
            (data) => data.ContentDocumentId).length == 0) {
        result = false;
      }
    }
    return result;
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        this.props.doShowMessage({
          message: ret.messages,
          action: async () => {
            if (ret.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
              this.gotoListGmn();
            }
          },
        });
        return;
      }
      // 契約電柱最大数登録済で代表電柱を追加できなかったら、
      // エラーメッセージを表示する。処理は継続する。
      const _messages = [{
        id: 'CI0010',
        values: ['保存'],
      }];
      if (ret.messages != null && ret.messages.length > 0 &&
        ret.messages[0].id == 'CE0218') {
        _messages.push({
          id: 'CE0218',
          values: ['契約電柱', '30',
          this.props.stbType == '1' ?
          '訂正取付申込（契約電柱：線設備）画面' : '訂正取付申込（契約電柱：点設備）画面',
          ],
        });
      }

      this.props.doShowMessage({
        message: _messages,
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
          // 申込の最終更新日を退避
          this.props.doSetOrderLastModifiedDate(
              this.props.order.LastModifiedDate);
        },
      });
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 申込電柱入力ボタン押下時
   */
  doMoveNext = async () => {
    try {
      const {order} = this.props;

      // 申込情報未登録はエラー
      if (order == null || order.Id == null) {
        this.props.doShowMessage({
          message: {
            id: 'CE0169',
            values: ['訂正取付申込', '申込電柱'],
          },
        });
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (this.props.dirty || this.state.isModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 画面遷移時の入力チェック
   * @param {array} attachedFile
   * @param {array} messages {id: message id, values:[]}
   * @param {array} warningMessages {id: message id, values:[]}
   */
  inputCheck(attachedFile, messages, warningMessages) {
    const {order, generalPurposeMap} = this.props;

    if (order == null) {
      return;
    }

    const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    if (order &&
      order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    if (!order.KoziTitle__c) {
      messages.push({
        id: 'CE0017',
        values: ['工事件名'],
      });
    } else {
      if (order.KoziTitle__c.length > 50) {
        messages.push({
          id: 'CE0041',
          values: ['工事件名'],
        });
      }
    }
    if (!order.KoziPlaceMainDntNo__c) {
      messages.push({
        id: 'CE0017',
        values: ['代表電柱番号'],
      });
    }
    if (!order.KoziPlacePrefectures__c) {
      messages.push({
        id: 'CE0017',
        values: ['都道府県'],
      });
    }
    if (!order.KoziPlaceMunicipalities__c) {
      messages.push({
        id: 'CE0017',
        values: ['市区町村'],
      });
    } else {
      if (order.KoziPlaceMunicipalities__c.length > 12) {
        messages.push({
          id: 'CE0041',
          values: ['市区町村'],
        });
      }
    }
    if (!order.KoziPlacePlace__c) {
      messages.push({
        id: 'CE0017',
        values: ['場所'],
      });
    } else {
      if (order.KoziPlacePlace__c.length > 255) {
        messages.push({
          id: 'CE0041',
          values: ['場所'],
        });
      }
    }
    if (!order.KyogaType__c) {
      messages.push({
        id: 'CE0017',
        values: ['共架種別'],
      });
    }
    if (order.ZizenNegotiationNo__c &&
      order.ZizenNegotiationNo__c.length > 13) {
      messages.push({
        id: 'CE0041',
        values: ['協議番号'],
      });
    }
    if (order.MemoContents__c &&
      order.MemoContents__c.length > 255) {
      messages.push({
        id: 'CE0041',
        values: ['メモ内容'],
      });
    }
    // 添付ファイルの必須チェックは行う。
    const fileNames = [];
    let contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A22');
    // 申込写真は必須
    if (contents.length == 0) {
      fileNames.push('装柱図');
    }
    // 線設備の場合、さらに、ルート図、共架設備仕様書が必須
    if (order.StbType__c == '1') {
      let contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A08');
      if (contents.length == 0) {
        fileNames.push('ルート図');
      }
      contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A01');
      if (contents.length == 0) {
        fileNames.push('共架設備仕様書');
      }
    }
    // 点設備の場合、さらに、平面図、共架設備仕様書が必須
    if (order.StbType__c == '2') {
      let contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A02');
      if (contents.length == 0) {
        fileNames.push('平面図');
      }
      contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A01');
      if (contents.length == 0) {
        fileNames.push('共架設備仕様書');
      }
    }
    for (const value of fileNames) {
      warningMessages.push(
          {id: 'CE0018',
            values: [value]},
      );
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    // 警告チェック
    let hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1602')) {
        hasError = (this.props.setGmnWarning[1602] != undefined ?
              this.props.setGmnWarning[1602] : false);
      }
    }

    // 入力チェックエラー
    if (hasError) {
      // 警告表示
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
        type: 'warn',
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   */
  doNext = () => {
    if (this.isSen) {
      this.props.history.push({pathname: '/CorrectAttachSenOrderDnt'});
    } else {
      this.props.history.push({pathname: '/CorrectAttachTenOrderDnt'});
    }
  }

  /**
   * 検索処理
   */
  searchData = async () => {
    const {orderId, generalPurposeMap} = this.props;
    const attachedFileTypeRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA08', 3);
    const attachedFileTypeHorizon = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA02', 3);
    const attachedFileTypeSpec = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA01', 3);
    const attachedFileTypeOther = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA12', 3);
    const attachedFileTypePicture = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);

    if (orderId) {
      // 申込情報、添付ファイル取得
      const appConditions = {
        'Id': orderId,
        'RecordType.DeveloperName': 'ContractDifferAttach',
        'attachedFileTypes': [
          attachedFileTypeRoot,
          attachedFileTypeHorizon,
          attachedFileTypeSpec,
          attachedFileTypeOther,
          attachedFileTypePicture,
        ],
      };
      let response =
        await this.props.doGetOrderInfo(appConditions, orderBasicFields);
      let errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['訂正取付申込', '取得'],
          },
        });
        return false;
      }
      if (!this.props.order) {
        this.sysErr();
        return false;
      }
    }

    // ファイル種別ごとにわける
    let _rootFiles = [];
    let _horizonFiles = [];
    let _specFiles = [];
    let _otherFiles = [];
    let _pictureFiles = [];
    if (this.props.contentDocuments &&
      this.props.contentDocuments.length > 0) {
      _rootFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeRoot);
      _horizonFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeHorizon);
      _specFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeSpec);
      _otherFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeOther);
      _pictureFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypePicture);
    }
    this.setState({
      rootFiles: _rootFiles,
      horizonFiles: _horizonFiles,
      specFiles: _specFiles,
      otherFiles: _otherFiles,
      pictureFiles: _pictureFiles,
      rootDelFiles: [],
      horizonDelFiles: [],
      specDelFiles: [],
      otherDelFiles: [],
      pictureDelFiles: [],
    });

    const errorMessages = [];
    const warningMessages = [];
    if (orderId) {
      // 作成中の場合は入力チェックを行う
      this.inputCheck(this.props.contentDocuments,
          errorMessages, warningMessages);
    }
    let isWarn = false;
    // 入力チェックエラー
    if (errorMessages.length > 0 || warningMessages.length > 0) {
      // 入力チェックNG
      isWarn = true;
    }
    await this.props.doSetGmnWarning('1602', isWarn);
    this.setState({isModified: false, warningMessages: warningMessages});
    return true;
  }

  /**
   * 画面描画
   * @return {*}
   */
  render() {
    const {classes, userInfo, generalPurposeMap, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1602InputOrderDnt" item>
          <PositiveButton
            id="btn1602InputOrderDnt"
            onClick={handleSubmit(this.doMoveNext)}
            variant="contained"
            size="large"
          >
            <span>申込電柱入力</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1602Save" item>
          <PositiveButton
            id="btn1602Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon />}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1602Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <>
        <form noValidate autoComplete="off" className={classes.root}>
          <MainContainer
            props={this.props}
            footerBtn={footerBtn}>
            {/* 警告メッセージ */}
            <WarningMessageArea messages={this.state.warningMessages}/>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}>
              {/* 申込情報 */}
              <Grid key="key1602OrderGrid" item xs={12}>
                <PaperPart>
                  <Typography variant="h6" gutterBottom>申込情報</Typography>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}>
                    <Grid key="key1602OrderNo" item xs={12} sm={2}>
                      <Field
                        id="id1602OrderNo"
                        name="OrderNo__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='申込番号'
                        type="text"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        required={false}
                        validate={[
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602OrderDate" item xs={12} sm={2}>
                      <Field
                        id="id1602OrderDate"
                        name="OrderDate__c"
                        label='申込年月日'
                        component={renderTextField}
                        className={classes.textFieldDate}
                        fullWidth
                        required={false}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        validate={[
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602SaveDate" item xs={12} sm={2}>
                      <Field
                        id="id1602SaveDate"
                        name="TemporarilySavedDate__c"
                        label='一時保存日'
                        component={renderTextField}
                        className={classes.textFieldDate}
                        fullWidth
                        required={false}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        validate={[
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602SendBackReason" item xs={12} sm={4}>
                      <Field
                        id="id1602SendBackReason"
                        name="KyogasyaSendBackReason__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='差戻理由'
                        type="text"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        required={false}
                        validate={[
                        ]}
                      />
                    </Grid>
                  </Grid>
                  {/* 共架者情報 */}
                  <Grid container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid key="key1602OrderKyogaZgsya" item xs={12} sm={5}>
                      {/* 共架事業者＝会社名＋事業所名 */}
                      <Field
                        id="id1602OrderKyogaZgsya"
                        name="OrderKyogaZgsyaName"
                        component={renderTextField}
                        className={classes.fields}
                        label='申込共架事業者'
                        type="text"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        required={false}
                        validate={[
                        ]}
                      >
                      </Field>
                    </Grid>
                    <Grid key="key1602KoziTitle" item xs={12} sm={8}>
                      <Field
                        id="id1602KoziTitle"
                        name="KoziTitle__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='工事件名'
                        type="text"
                        fullWidth
                        required={true}
                        validate={[
                          validateRule.maxLength(50),
                        ]}
                        warn={[
                          validateRule.required,
                        ]}
                        disabled={this.state.isDisabled}
                      />
                    </Grid>
                  </Grid>
                  {/* 工事 */}
                  <Typography variant="h6" gutterBottom>工事場所&nbsp;
                    <MuiTooltip
                      id="tl602SelectDnt"
                      placement='bottom'
                      content={
                        <React.Fragment>
                        電柱を選択して保存ボタンを押すと代表電柱を次の画面の申込電柱に追加します。<br/>
                        申込電柱の中に代表電柱がない場合、次の画面の電柱選択ボタン<br/>
                        または電柱追加ボタンを使って代表電柱を申込電柱に追加してください。
                        </React.Fragment>}>
                      <InfoOutlined fontSize="small"/>
                    </MuiTooltip>
                  </Typography>
                  <Grid container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid key="key1602KoziPlaceMainDntNoSenroName" item xs={12} sm={2}>
                      <Field
                        id="id1602KoziPlaceMainDntNoSenroName"
                        name="KoziPlaceMainDntNo_SenroName__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='線路名'
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        type="text"
                        required={false}
                        validate={[
                        ]}
                        warn={[
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602KoziPlaceMainDntNo" item xs={12} sm={2}>
                      <Field
                        id="id1602KoziPlaceMainDntNo"
                        name="Dsp_KoziPlaceMainDntNo__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='代表電柱番号'
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        type="text"
                        required={true}
                        validate={[
                        ]}
                        warn={[
                          validateRule.required,
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602SelectDnt" item xs={12} sm={8}>
                      <PositiveButton
                        id="id1602SelectDnt"
                        onClick={() => {
                          this.setState({modalOpen: true});
                        }}
                        variant="contained"
                        size="large"
                        disabled={this.state.isDisabled}
                      >
                        <span>電柱選択</span>
                      </PositiveButton>
                    </Grid>
                    <Grid key="key1602KoziPlacePrefectures" item xs={12} sm={2}>
                      <Field
                        id="id1602KoziPlacePrefectures"
                        name="KoziPlacePrefectures__c"
                        component={renderSelect}
                        className={classes.fields}
                        label='都道府県'
                        fullWidth
                        required={true}
                        InputProps={{
                          readOnly: false,
                        }}
                        validate={[
                        ]}
                        warn={[
                          validateRule.required,
                        ]}
                        disabled={this.state.isDisabled}
                      >
                        {generalPurposeMap &&
                          createSearchList(generalPurposeMap.PrefCode)}
                      </Field>
                    </Grid>
                    <Grid key="key1602KoziPlaceMunicipalities" item xs={12} sm={4}>
                      <Field
                        id="id1602KoziPlaceMunicipalities"
                        name="KoziPlaceMunicipalities__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='市区町村'
                        fullWidth
                        type="text"
                        required={true}
                        InputProps={{
                          readOnly: false,
                        }}
                        validate={[
                          validateRule.maxLength(12),
                        ]}
                        warn={[
                          validateRule.required,
                        ]}
                        disabled={this.state.isDisabled}
                      />
                    </Grid>
                    <Grid key="key1602KoziPlacePlace" item xs={12} sm={4}>
                      <Field
                        id="id1602KoziPlacePlace"
                        name="KoziPlacePlace__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='場所'
                        fullWidth
                        type="text"
                        required={true}
                        InputProps={{
                          readOnly: false,
                        }}
                        validate={[
                          validateRule.maxLength(255),
                        ]}
                        warn={[
                          validateRule.required,
                        ]}
                        disabled={this.state.isDisabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}>
                    <Grid key="key1602StbType" item xs={12} sm={3}>
                      <Field
                        id="id1602StbType"
                        name="StbType__c"
                        component={renderSelect}
                        className={classes.fields}
                        label='設備種別'
                        fullWidth
                        required={false}
                        InputProps={{
                          readOnly: true,
                        }}
                        validate={[
                        ]}
                      >
                        {generalPurposeMap &&
                          createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                      </Field>
                    </Grid>
                    {/* 共架種別は共架事業者によって異なる*/}
                    <Grid key="key1602KyogaType" item xs={12} sm={3}>
                      <Field
                        id="id1602KyogaType"
                        name="KyogaType__c"
                        component={renderSelect}
                        className={classes.fields}
                        label='共架種別'
                        fullWidth
                        required={true}
                        validate={[
                        ]}
                        warn={[
                          validateRule.required,
                        ]}
                        disabled={this.state.isDisabled}
                      >
                        {generalPurposeMap &&
                          createSearchList(generalPurposeMap.KyogaType,
                              'Code__c',
                              'Name',
                              {Code__c:
                                Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                                Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                                userInfo.Account.Account__r.KyogaType__c : '',
                              ValueString1__c: this.isSen ? ['1'] : ['2']})}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid key="key1602ZizenNegotiationNo" item xs={12} sm={2}>
                      <Field
                        id="id1602ZizenNegotiationNo"
                        name="ZizenNegotiationNo__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='協議番号'
                        type="text"
                        fullWidth
                        required={false}
                        validate={[
                          validateRule.numericHyphen,
                          validateRule.maxLength(13),
                        ]}
                        disabled={this.state.isDisabled}
                      />
                    </Grid>
                  </Grid>
                </PaperPart>
              </Grid>
              {/* 添付ファイル */}
              <Grid key="key1602AttachGrid" item xs={12} sm={7}>
                <PaperPart>
                  <Typography variant="h6" gutterBottom>添付ファイル</Typography>
                  <Grid container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid key="key1602PictureFile" item xs={12}>
                      <Typography variant="h6" display="block">
                        装柱図
                        <font size="2">(※※最大1ファイル、1ファイルあたり20MBまで登録できます)</font>
                        <Required/>
                        <MuiTooltip content={
                          <React.Fragment>
                            電柱番号，全景，共架部分拡大写真・装柱図等の資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined/>
                        </MuiTooltip>
                      </Typography>
                    </Grid>
                    <Grid key="key1602UploadPictureFile" item xs={12}>
                      <Upload
                        componentId="pictureComponent"
                        maxFileCount={1}
                        maxFileSize={20971520}
                        required={true}
                        previewFlag={false}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setPictureFiles}
                        initFile={this.state.pictureFiles}
                        disabled={this.state.isDisabled}
                        customChecks={[
                          checkFileNameLength,
                          checkFileSizeZero,
                          checkSameName,
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602RootFile" item xs={12}>
                      {this.isSen &&
                        <Typography variant="h6" display="block">
                          ルート図
                          <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                          <Required/>
                          <MuiTooltip content={
                            <React.Fragment>
                              申込ルートが分かる資料を添付してください。
                            </React.Fragment>
                          }>
                            <InfoOutlined/>
                          </MuiTooltip>
                        </Typography>
                      }
                      {!this.isSen &&
                        <Typography variant="h6" display="block">
                          平面図
                          <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                          <Required/>
                          <MuiTooltip content={
                            <React.Fragment>
                              設置場所が分かる資料を添付してください。
                            </React.Fragment>
                          }>
                            <InfoOutlined/>
                          </MuiTooltip>
                        </Typography>
                      }
                    </Grid>
                    <Grid key="key1602UploadRootFile" item xs={12}>
                      {this.isSen &&
                        <Upload
                          componentId="rootComponent"
                          maxFileCount={3}
                          maxFileSize={3145728}
                          required={true}
                          previewFlag={true}
                          previewWidth="35%"
                          acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                          fileSetHandler={this.setRootFiles}
                          initFile={this.state.rootFiles}
                          disabled={this.state.isDisabled}
                          customChecks={[
                            checkFileNameLength,
                            checkFileSizeZero,
                            checkSameName,
                          ]}
                        />
                      }
                      {!this.isSen &&
                        <Upload
                          componentId="horizonComponent"
                          maxFileCount={3}
                          maxFileSize={3145728}
                          required={true}
                          previewFlag={true}
                          previewWidth="35%"
                          acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                          fileSetHandler={this.setHorizonFiles}
                          initFile={this.state.horizonFiles}
                          disabled={this.state.isDisabled}
                          customChecks={[
                            checkFileNameLength,
                            checkFileSizeZero,
                            checkSameName,
                          ]}
                        />
                      }
                    </Grid>
                    <Grid key="key1602SpecFile" item xs={12}>
                      <Typography variant="h6" display="block">
                        共架設備仕様書
                        <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                        <Required/>
                        <MuiTooltip content={
                          <React.Fragment>
                            ・共架の対象および共架伝送路設備の資料<br/>
                            ・共架伝送路設備の諸元表<br/>
                            　を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined/>
                        </MuiTooltip>
                      </Typography>
                    </Grid>
                    <Grid key="key1602UploadSpecFile" item xs={12}>
                      <Upload
                        componentId="specComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        required={true}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setSpecFiles}
                        initFile={this.state.specFiles}
                        disabled={this.state.isDisabled}
                        customChecks={[
                          checkFileNameLength,
                          checkFileSizeZero,
                          checkSameName,
                        ]}
                      />
                    </Grid>
                    <Grid key="key1602OtherFile" item xs={12}>
                      <Typography variant="h6" display="block">
                        その他資料
                        <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                        <MuiTooltip content={
                          <React.Fragment>
                            上記以外の資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined/>
                        </MuiTooltip>
                      </Typography>
                    </Grid>
                    <Grid key="key1602UploadOtherFile" item xs={12}>
                      <Upload
                        componentId="otherComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        required={false}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setOtherFiles}
                        initFile={this.state.otherFiles}
                        disabled={this.state.isDisabled}
                      />
                    </Grid>
                  </Grid>
                </PaperPart>
              </Grid>
              {/* その他 */}
              <Grid key="key1602OOtherGrid" item xs={12} sm={5}>
                <PaperPart>
                  <Grid container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid key="key1602Memo" item xs={12}>
                      <Field
                        id="id1602Memo"
                        name="MemoContents__c"
                        component={renderTextField}
                        className={classes.fields}
                        label='メモ内容'
                        variant="outlined"
                        type="text"
                        multiline
                        rows={20}
                        fullWidth
                        required={false}
                        validate={[
                          validateRule.maxLength(255),
                        ]}
                        disabled={this.state.isDisabled}
                      />
                    </Grid>
                  </Grid>
                </PaperPart>
              </Grid>
            </Grid>
          </MainContainer>
          <SelectPoleNo
            YbdsType="A"
            modalOpen={this.state.modalOpen}
            closeButton={this.closeSelectDnt}
            setSelectedPole={this.setSelectedDnt}/>
        </form>
      </>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  order: PropTypes.object,
  history: PropTypes.object,
  stbType: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  syncWarnings: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderInfo: PropTypes.func,
  doSetOrderId: PropTypes.func,
  orderId: PropTypes.string,
  orderResult: PropTypes.object,
  orderCheckResult: PropTypes.object,
  KyogasyaSendBackReason__c: PropTypes.string,
  OrderKyogaZgsyaName: PropTypes.string,
  KoziTitle__c: PropTypes.string,
  KoziPlaceMainDntNo__c: PropTypes.string,
  KoziPlacePrefectures__c: PropTypes.string,
  KoziPlaceMunicipalities__c: PropTypes.string,
  KoziPlacePlace__c: PropTypes.string,
  StbType__c: PropTypes.string,
  KyogaType__c: PropTypes.string,
  ZizenNegotiationNo__c: PropTypes.string,
  MemoContents__c: PropTypes.string,
  rootFiles: PropTypes.array,
  horizonFiles: PropTypes.array,
  specFiles: PropTypes.array,
  otherFiles: PropTypes.array,
  pictureFiles: PropTypes.array,
  deleteFiles: PropTypes.array,
  dirty: PropTypes.bool,
  doSaveCorrectOrder: PropTypes.func,
  doClearOrderInfo: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  reset: PropTypes.func.isRequired,
  contentDocuments: PropTypes.array,
  doSetOrderLastModifiedDate: PropTypes.func,
  orderLastModifiedDate: PropTypes.string,
};

const mapStateToProps = (state) => {
  const initValues = {
    OrderNo__c: null,
    TemporarilySavedDate__c: null,
    ProprietyJudgeOrderDate__c: null,
    KyogasyaSendBackReason__c: null,
    OrderKyogaZgsya__c: state.auth.userInfo.AccountId,
    OrderKyogaZgsyaName: state.auth.userInfo.Account.Name,
    KoziTitle__c: null,
    MainDntNoNWZgsyo__c: null,
    Dsp_KoziPlaceMainDntNo__c: null,
    KoziPlaceMainDntNo_SenroCode__c: null,
    KoziPlaceMainDntNo_SenroName__c: null,
    KoziPlaceMainDntNo__c: null,
    KoziPlacePrefectures__c: null,
    KoziPlaceMunicipalities__c: null,
    KoziPlacePlace__c: null,
    StbType__c: state.common.stbType,
    KyogaType__c: null,
    ZizenNegotiationNo__c: null,
    MemoContents__c: null,
  };

  const order = state.attachorder.orderInfo &&
                Object.hasOwnProperty.call(state.attachorder.orderInfo, 'order') ?
                state.attachorder.orderInfo.order : [];
  for (const key in order) {
    if (Object.hasOwnProperty.call(order, key)) {
      if (order[key]) {
        if (key == 'TemporarilySavedDate__c') {
          initValues[key] = changeDateFormatter(order[key], 'YYYY-MM-DD');
        } else if (key == 'OrderKyogaZgsya__r') {
          initValues['OrderKyogaZgsyaName'] =
            Object.hasOwnProperty.call(order[key], 'Name') ? order[key].Name : '' +
            Object.hasOwnProperty.call(order[key], 'Account__r') &&
            Object.hasOwnProperty.call(order[key].Account__r, 'Name') ?
            order[key].Account__r.Name: '';
        } else {
          initValues[key] = order[key];
        }
      }
    }
  }

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: order,
    contentDocuments: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'contentDocuments') ?
        state.attachorder.orderInfo.contentDocuments : [],
    orderResult: state.attachorder.orderResult,
    orderCheckResult: state.attachorder.orderCheckResult,
    stbType: state.common.stbType,
    setGmnWarning: state.common.setGmnWarning,
    initialValues: initValues,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doGetOrderInfo: attachorderOperations.doGetOrderInfo,
  doSaveCorrectOrder:
    attachorderOperations.doSaveCorrectOrder,
  doClearOrderInfo: attachorderOperations.doClearOrderInfo,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'CorrectAttachOrderBasicInfo';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
    syncWarnings: getFormSyncWarnings(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ListAltIcon from '@material-ui/icons/ListAlt';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';

import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import SimpleTable from '../../../../../molecules/SimpleTable.js';
import {renderCheckbox, renderTextField} from '../../../../../atoms/CustomPart.js';
import {commonOperations} from '../../../../../../reducks/common';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import * as validateRule from '../../../../../validateRule.js';

import {
  downloadAttachedFile, changeDateFormat, changeDateFormatter,
  getToDate, execApexRestApi, API_CODE, getResponseResult,
} from '../../../../../../common/common.js';

// スタイル
const styles = (theme) => ({
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    // width: '42%',
    minWidth: '260px',
  },
});

const wideHeaderTableStyle = {
  tableHeader: {
    width: '45%',
  },
};

/** この画面で登録/更新を行うフィールド名 */
const useFields = [
  'KoziStop__c', // 工事中止
  'IsetuCompHopeDateTimeOfContact__c', // 移設完了希望日（工事日連絡時）
  'KyogaKoziScheduleInputDeadline__c', // 共架工事予定入力期限
  'DrKenTyKoziDate__c', // 電力建柱工事日
  'DrIsnKoziDate__c', // 電力移線工事日
  'DrKenBaTyKoziDate__c', // 電力抜柱工事日
  'DrKenTyKoziDateChange__c', // 電力建柱工事日（変更）
  'DrIsnKoziDateChange__c', // 電力移線工事日（変更）
  'DrKenBaTyKoziDateChange__c', // 電力抜柱工事日（変更）
  'KoziDateChangeReason__c', // 工事日変更理由
  'KoziCompanySyozoku__c', // 工事会社/所属
  'KoziTantoName__c', // 工事担当者名
  'KoziTel__c', // 工事電話番号
  'KoziMailAddress__c', // 工事メールアドレス
  'MessageTimeOfContact__c', // 連絡事項（連絡時）
];

/** 検索フィールド */
const appFields = [
  'IsetuIraiNo__c', // 一連番号
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefectures', // 都道府県
  'KoziPlaceMunicipalities__c', // 市区町村
  'KoziPlacePlace__c', // 場所
  'DntNo__c', // 電柱番号
  'Mark__c', // 目標
  'toLabel(IsetuIraiStatus__c)IsetuIraiStatus', // 移設依頼ステータス
  'KoziOverview__c', // 工事概要
  'KoziScheduleSeason__c', // 工事予定時期
  'KoziScheduleSeasonHosoku__c', // 工事予定時期補足
  'IsetuIraiDesignDocumentNo__c', // 設計書番号
  'IsetuCompHopeDateTimeOfDesign__c', // 移設完了希望日（設計時）
  'MessageTimeOfDesign__c', // 連絡事項（設計時）
  'IsetuIraiHonsu__c', // 移設依頼本数
  // 現場状況図
  'DesignCompanySyozoku__c', // 設計会社／所属
  'DesignTantoName__c', // 設計担当者名
  'DesignTel__c', // 設計電話番号
  'DesignMailAddress__c', // 設計メールアドレス
  'KoziStop__c', // 工事中止
  'KoziHold__c', // 工事保留
  'IsetuCompHopeDateTimeOfContact__c', // 移設完了希望日（工事日連絡時）
  'KyogaKoziScheduleInputDeadline__c', // 共架工事予定入力期限
  'DrKenTyKoziDate__c', // 電力建柱工事日
  'DrKenTyKoziDateChange__c', // 電力建柱工事日
  'DrIsnKoziDate__c', // 電力移線工事日
  'DrIsnKoziDateChange__c', // 電力移線工事日（変更）
  'DrKenBaTyKoziDate__c', // 電力抜柱工事日
  'DrKenBaTyKoziDateChange__c', // 電力抜柱工事日（変更）
  'KoziDateChangeReason__c', // 工事日変更理由
  'KoziCompanySyozoku__c', // 工事会社/所属
  'KoziTantoName__c', // 工事担当者名
  'KoziTel__c', // 工事電話番号
  'KoziMailAddress__c', // 工事メールアドレス
  'MessageTimeOfContact__c', // 連絡事項（連絡時）
  // 画面表示以外
  'Id',
  'IsetuIraiCarryOutDate__c', // 移設依頼発信年月日
  'IsetuIraiStatus__c', // 移設依頼ステータス
  'KoziDateConfirmRegistDate__c', // 工事日確定登録日
  'KoziDateChangeConfirmRegistDate__c', // 工事日変更確定登録日
  'LastModifiedDate', // 最終更新日時
];

/** 移設依頼設計書、添付ファイル名検索時の検索対象 */
const subquery = [
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient',
      'ContentDocument.LatestPublishedVersion.FileType',
      'ContentDocument.LatestPublishedVersion.ContentDocumentId'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['C02', 'C03', 'C04'],
    },
    'table': 'ContentDocumentLinks',
  },
];

/**
 * 移設依頼工事日入力
 * 画面ID:1422
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      constructionInfo: [],
      designPersonInfo: [],
      isStopDisabled: false,
      isChangeMode: false,
      disableChangeDate: false,
      warnInfo: {}, // 警告情報
    },
    // 添付ファイル
    this.attachedFile = {};
    this.attachedFile2 = {};
    this.attachedFile3 = {};
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // 移設依頼設計書の情報取得
      await this.getDesignDocument();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 移設依頼設計書の情報取得
   */
  async getDesignDocument() {
    if (this.props.designDocumentId != null) {
      // 設計書テーブル検索時の検索条件
      const conditions = {
        Id: this.props.designDocumentId,
      };
      // 設計書テーブル検索実行
      const ret = await this.props.doGetDesignDocument(
          conditions, appFields, null, 'getSub', subquery);

      if (!ret ||
        !this.props.hasOwnProperty('saveDesignDocument') ||
        !this.props.saveDesignDocument) {
        this.sysErr();
        return;
      }

      // 更新対象のオーダも取得しておく
      const orderCondition = {
        'IsetuIraiDesignDocument__r.Id_EQ': this.props.designDocumentId,
      };
      const orderFields = [
        'Id', 'LastModifiedDate',
        'NWKoziDateContactDateConfirm__c', 'NWKoziDateContactDateChange__c'];
      const result = await this.props.doGetOrderSubAllList(
          orderCondition, orderFields, null, null);
      const errCode = result ? result.data ? result.data.body ?
        result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return false;
      }

      // 電力側工事情報
      const wkConstructionInfo = [
        {key: 'IsetuIraiNo__c', name: '一連番号', value: ''},
        {key: 'IsetuIraiCarryOutDate__c', name: '移設依頼発信年月日', value: ''},
        {key: 'KoziPlacePrefectures', name: '都道府県', value: ''},
        {key: 'KoziPlaceMunicipalities__c', name: '市区町村', value: ''},
        {key: 'KoziPlacePlace__c', name: '場所', value: ''},
        {key: 'DntNo__c', name: '電柱番号', value: ''},
        {key: 'Mark__c', name: '目標', value: ''},
        {key: 'IsetuIraiStatus', name: '移設依頼ステータス', value: ''},
        {key: 'KoziOverview__c', name: '工事概要', value: ''},
        {key: 'KoziScheduleSeason__c', name: '工事予定時期', value: ''},
        {key: 'KoziScheduleSeasonHosoku__c', name: '工事予定時期補足', value: ''},
        {key: 'IsetuIraiDesignDocumentNo__c', name: '設計書番号', value: ''},
        {key: 'IsetuCompHopeDateTimeOfDesign__c', name: '移設完了希望日（設計時）', value: ''},
        {key: 'MessageTimeOfDesign__c', name: '連絡事項（設計時）', value: ''},
        {key: 'IsetuIraiHonsu__c', name: '工事対象本数', value: ''},
        {key: 'GenbaStateFigureFileId_File', name: '添付（工事図面）', value: '', onClick: this.doDownloadAttachedFile, link: '#'},
        {key: 'SenyoRequestInfFileId_File', name: '添付（その他）', value: '', onClick: this.doDownloadAttachedFile2, link: '#'},
        {key: 'TenpuSenyoSinseiJokyo_File', name: '添付（占用申請状況）', value: '', onClick: this.doDownloadAttachedFile3, link: '#'},
      ];

      // 設計者情報
      const wkDesignPersonInfo = [
        {key: 'DesignCompanySyozoku__c', name: '設計会社/所属', value: ''},
        {key: 'DesignTantoName__c', name: '設計担当者名', value: ''},
        {key: 'DesignTel__c', name: '設計電話番号', value: ''},
        {key: 'DesignMailAddress__c', name: '設計メールアドレス', value: ''},
      ];

      // 工事中止かつ完結の場合、入力不可とする。
      let wkIsStopDisabled = false;
      if (this.props.saveDesignDocument.KoziStop__c &&
        this.props.saveDesignDocument.IsetuIraiStatus__c == '04') {
        wkIsStopDisabled = true;
      }

      // 変更確定登録かどうか
      let wkIsChangeMode = false;
      if (this.props.saveDesignDocument.KoziDateConfirmRegistDate__c) {
        wkIsChangeMode = true;
      }

      // 工事日(変更)項目を非活性にするかどうか(移設依頼ステータスが「作成中(00)」「依頼内容確認済(01)」「工事日調整中(02)」の場合非活性)
      const isetuIraiStatus = this.props.saveDesignDocument.IsetuIraiStatus__c;
      const disableChangeDate =
        (isetuIraiStatus == '00') ||
        (isetuIraiStatus == '01') ||
        (isetuIraiStatus == '02');

      // 添付ファイル名を設定
      if (this.props.saveDesignDocument.ContentDocumentLinks) {
        for (const data of
          this.props.saveDesignDocument.ContentDocumentLinks.records) {
          const items = data.ContentDocument.LatestPublishedVersion;
          if (items.AttachedFileType__c === 'C03') {
            // 添付（移設依頼設計書）
            this.attachedFile = {
              'AttachedFileType_Type': items.AttachedFileType__c,
              'AttachedFileType_Name': items.PathOnClient,
              'AttachedFileType_Url': items.VersionData,
            };
          } else if (items.AttachedFileType__c === 'C04') {
            // 添付（その他）
            this.attachedFile2 = {
              'AttachedFileType_Type': items.AttachedFileType__c,
              'AttachedFileType_Name': items.PathOnClient,
              'AttachedFileType_Url': items.VersionData,
            };
          } else if (items.AttachedFileType__c === 'C02') {
            // 添付（占用申請状況）
            this.attachedFile3 = {
              'AttachedFileType_Type': items.AttachedFileType__c,
              'AttachedFileType_Name': items.PathOnClient,
              'AttachedFileType_Url': items.VersionData,
            };
          }
        }
      }

      // 取得データを画面表示
      for (const data of wkConstructionInfo) {
        switch (data.key) {
          case 'IsetuCompHopeDateTimeOfDesign__c':
          case 'IsetuIraiCarryOutDate__c':
            // 移設依頼発信年月日、移設完了希望日（設計時）
            if (this.props.saveDesignDocument[data.key]) {
              data.value =
                changeDateFormat(this.props.saveDesignDocument[data.key]);
            }
            break;
          case 'KoziScheduleSeason__c':
            // 工事予定時期は、年月に変換
            if (this.props.saveDesignDocument[data.key]) {
              data.value =
                changeDateFormatter(this.props.saveDesignDocument[data.key],
                    'YYYY年MM月');
            }
            break;
          case 'GenbaStateFigureFileId_File':
            // 現場状況図（移設依頼書）
            if (this.attachedFile) {
              // 添付ファイルのハイパーリンク設定
              data.value = this.attachedFile.AttachedFileType_Name;
            }
            break;
          case 'SenyoRequestInfFileId_File':
            // 現場状況図（その他）
            if (this.attachedFile2) {
              // 添付ファイルのハイパーリンク設定
              data.value = this.attachedFile2.AttachedFileType_Name;
            }
            break;

          case 'TenpuSenyoSinseiJokyo_File':
            // 添付（占用申請状況）
            if (this.attachedFile3) {
              // 添付ファイルのハイパーリンク設定
              data.value = this.attachedFile3.AttachedFileType_Name;
            }
            break;

          default:
            data.value = this.props.saveDesignDocument[data.key];
            break;
        }
      }
      // 取得データを画面表示
      for (const data of wkDesignPersonInfo) {
        if (!this.props.saveDesignDocument.hasOwnProperty(data.key)) {
          continue;
        }
        data.value = this.props.saveDesignDocument[data.key];
      }

      // 警告情報
      let wkWarnInfo = {};
      wkWarnInfo.requiredPastDate =
        [validateRule.required, validateRule.pastDate];
      wkWarnInfo.pastDate =
        [validateRule.pastDate];
      wkWarnInfo.email =
        [validateRule.email];

      // 再描画
      this.setState({
        constructionInfo: wkConstructionInfo,
        designPersonInfo: wkDesignPersonInfo,
        isStopDisabled: wkIsStopDisabled,
        isChangeMode: wkIsChangeMode,
        disableChangeDate: disableChangeDate,
        isModified: false,
        warnInfo: wkWarnInfo,
      });
    }
  }

  /**
   * 添付ファイルダウンロード
   */
  doDownloadAttachedFile = async () => {
    await this.doDownloadAttachedFileCommon(this.attachedFile);
  }

  /**
   * 添付ファイルダウンロード
   */
  doDownloadAttachedFile2 = async () => {
    await this.doDownloadAttachedFileCommon(this.attachedFile2);
  }

  /**
   * 添付ファイル3ダウンロード
   */
  doDownloadAttachedFile3 = async () => {
    await this.doDownloadAttachedFileCommon(this.attachedFile3);
  }

  /**
   * 添付ファイルダウンロード 共通処理
   *
   * @param {object} attachedFile 添付ファイル情報
   */
  doDownloadAttachedFileCommon = async (attachedFile) => {
    const {userInfo} = this.props;

    if (!attachedFile) {
      return;
    }

    try {
      downloadAttachedFile(
          userInfo,
          attachedFile.AttachedFileType_Url,
          attachedFile.AttachedFileType_Type,
          attachedFile.AttachedFileType_Name);
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.props.reset();
      this.props.doClearDesignDocument();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一時保存ボタン押下時
   */
  doTempSave = async () => {
    try {
      const {values, userInfo} = this.props;
      // 更新条件
      const conditions = {
        Id: this.props.designDocumentId,
        LastModifiedDate:
          this.props.saveDesignDocument.LastModifiedDate,
      };

      // 更新項目
      const params = {};
      for (const key in values) {
        if (!values.hasOwnProperty(key)) {
          continue;
        }
        if (!useFields.includes(key)) {
          continue;
        }
        switch (key) {
          // 工事中止は更新しない
          case 'KoziStop__c':
            break;
          // 日付項目が未設定の場合はnullに置き換え
          case 'IsetuCompHopeDateTimeOfContact__c':
          case 'KyogaKoziScheduleInputDeadline__c':
          case 'DrKenTyKoziDate__c':
          case 'DrIsnKoziDate__c':
          case 'DrKenBaTyKoziDate__c':
          case 'DrKenTyKoziDateChange__c':
          case 'DrIsnKoziDateChange__c':
          case 'DrKenBaTyKoziDateChange__c':
            if (!values[key]) {
              params[key] = null;
            } else {
              params[key] = values[key];
            }
            break;
          default:
            params[key] = values[key];
            break;
        }
      }
      // ID
      params['Id'] = this.props.designDocumentId;

      // 保存処理
      const designDocumentCondition = [];
      const designDocumentList = [];
      designDocumentCondition.push(conditions);
      designDocumentList.push(params);
      const body = {
        orderConditions: null,
        orderList: null,
        designDocumentConditions: designDocumentCondition,
        designDocumentList: designDocumentList,
      };
      console.dir(body);
      const result = await execApexRestApi(
          userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
      console.dir(result);
      const resResult = getResponseResult(result, ['移設依頼設計書', '一時保存']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['一時保存'],
        },
        action: async () => {
          // 移設依頼設計書の情報取得
          await this.getDesignDocument();
        },
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 登録ボタン押下時
   */
  doRegister = async () => {
    try {
      const messages = [];
      // 必須チェック
      this.isRequiredItem(messages);
      if (messages.length > 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0050',
          },
        });
        return;
      }

      // 相関チェック
      this.correlationCheck(messages);
      if (messages.length > 0) {
        this.props.doShowMessage({message: messages});
        return;
      }

      // 確認メッセージ
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['登録'],
        },
        action: () => {
          this.updateDesignDocument();
        },
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 登録処理実行
   */
  updateDesignDocument = async () => {
    const {values, userInfo} = this.props;

    // 更新条件
    const conditions = {
      Id: this.props.designDocumentId,
      LastModifiedDate:
        this.props.saveDesignDocument.LastModifiedDate,
    };

    // 更新項目
    const params = {};
    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }
      if (!useFields.includes(key)) {
        continue;
      }
      switch (key) {
        // 日付項目が未設定の場合はnullに置き換え
        case 'IsetuCompHopeDateTimeOfContact__c':
        case 'KyogaKoziScheduleInputDeadline__c':
        case 'DrKenTyKoziDate__c':
        case 'DrIsnKoziDate__c':
        case 'DrKenBaTyKoziDate__c':
        case 'DrKenTyKoziDateChange__c':
        case 'DrIsnKoziDateChange__c':
        case 'DrKenBaTyKoziDateChange__c':
          if (!values[key]) {
            params[key] = null;
          } else {
            params[key] = values[key];
          }
          break;
        default:
          params[key] = values[key];
          break;
      }
    }

    // ID
    params['Id'] = this.props.designDocumentId;

    /** 工事日（変更）のどれかが設定されているか */
    let hasChangeDate = false;

    // 工事日確定登録日／工事日変更確定登録日
    const today = getToDate();
    if (!this.state.isChangeMode) {
      // 工事日確定登録日
      params['KoziDateConfirmRegistDate__c'] = today;
    } else {
      // 工事日（変更）のどれかが設定された場合
      if (values['DrKenTyKoziDateChange__c'] || values['DrIsnKoziDateChange__c'] || values['DrKenBaTyKoziDateChange__c']) {
        // 工事日変更確定登録日
        params['KoziDateChangeConfirmRegistDate__c'] = today;
        hasChangeDate = true;
      }
    }

    // 工事日変更確定登録日が既に登録済の場合、工事日(変更)は設定済と判定
    if (this.props.saveDesignDocument.KoziDateChangeConfirmRegistDate__c) {
      hasChangeDate = true;
    }

    // オーダ更新
    let orderCondition = [];
    let orderList = [];
    // 工事中止の場合のみ
    if (values['KoziStop__c']) {
      for (const item of this.props.orderSubAllList) {
        let orderData = {};
        if (item.NWKoziDateContactDateChange__c) {
          // 工事日変更確定登録
          orderData['IstIriKoziDateChangeAccepted__c'] = null;
          orderData['NWKoziDateContactDateChange__c'] = null;
        } else if (item.NWKoziDateContactDateConfirm__c) {
          // 工事日確定登録
          orderData['IstIriKoziDateConfirmAccepted__c'] = null;
          orderData['NWKoziDateContactDateConfirm__c'] = null;
        }
        if (orderData) {
          orderData['Id'] = item.Id;
          orderList.push(orderData);
          const conditions = {
            'Id': item.Id,
            'LastModifiedDate': item.LastModifiedDate,
          };
          orderCondition.push(conditions);
        }
      }
    }
    if (orderList.length == 0) {
      orderList = null;
      orderCondition = null;
    }

    // 保存処理
    const designDocumentCondition = [];
    const designDocumentList = [];
    designDocumentCondition.push(conditions);
    designDocumentList.push(params);
    const body = {
      orderConditions: orderCondition,
      orderList: orderList,
      designDocumentConditions: designDocumentCondition,
      designDocumentList: designDocumentList,
    };
    console.dir(body);
    const result = await execApexRestApi(
        userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
    console.dir(result);
    const resResult = getResponseResult(result, ['移設依頼設計書', '登録']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: resResult.messages,
      });
      return;
    }

    this.props.doShowMessage({
      message: {
        id: 'MI0210',
        values: this.getMessageValues(hasChangeDate),
      },
      action: async () => {
        // 移設依頼設計書の情報取得
        await this.getDesignDocument();
      },
    });
  }

  /**
   * 保存完了後メッセージ可変部分の取得。
   * {0}: 工事日/工事
   * {1}: 確定/変更/中止
   * @param {boolean} hasChangeDate 工事日(変更)のいずれかに変更がある
   * @return {string[]} メッセージ可変部分
   */
  getMessageValues = (hasChangeDate) => {
    const {values} = this.props;

    // 中止
    if (values['KoziStop__c']) {
      return ['工事', '中止'];
    }

    // 変更
    if (hasChangeDate) {
      return ['工事日', '変更'];
    }

    return ['工事日', '確定'];
  }

  /**
  * 必須チェック
  * @param {array} messages メッセージ格納用
  */
  isRequiredItem(messages) {
    const values = this.props.values;

    // 工事中止がチェック
    if (values['KoziStop__c']) {
      // チェックしない
      return;
    }

    // 移設完了希望日（工事日連絡時）
    let val = values['IsetuCompHopeDateTimeOfContact__c'];
    // -必須チェック
    if (!val) {
      messages.push({
        id: 'CE0017',
        values: ['移設完了希望日（工事日連絡時）'],
      });
    }
    // -過去日チェック
    if (val && validateRule.pastDate(val)) {
      messages.push({
        id: 'CE0038',
        values: ['移設完了希望日（工事日連絡時）'],
      });
    }

    // 共架工事予定入力期限
    val = values['KyogaKoziScheduleInputDeadline__c'];
    // -必須チェック
    if (!val) {
      messages.push({
        id: 'CE0017',
        values: ['共架工事予定入力期限'],
      });
    }
    // -過去日チェック
    if (val && validateRule.pastDate(val)) {
      messages.push({
        id: 'CE0038',
        values: ['共架工事予定入力期限'],
      });
    }
    // 確定登録の場合
    if (!this.state.isChangeMode) {
      // 電力建柱工事日
      val = values['DrKenTyKoziDate__c'];
      // -過去日チェック
      if (val && validateRule.pastDate(val)) {
        messages.push({
          id: 'CE0038',
          values: ['電力建柱工事日'],
        });
      }

      // 電力移線工事日
      val = values['DrIsnKoziDate__c'];
      // -必須チェック
      if (!val) {
        messages.push({
          id: 'CE0017',
          values: ['電力移線工事日'],
        });
      }
      // -過去日チェック
      if (val && validateRule.pastDate(val)) {
        messages.push({
          id: 'CE0038',
          values: ['電力移線工事日'],
        });
      }

      // 電力抜柱工事日
      val = values['DrKenBaTyKoziDate__c'];
      // -過去日チェック
      if (val && validateRule.pastDate(val)) {
        messages.push({
          id: 'CE0038',
          values: ['電力抜柱工事日'],
        });
      }
    }

    // 変更確定登録の場合
    if (this.state.isChangeMode) {
      // 電力抜柱工事日（変更）
      val = values['DrKenBaTyKoziDateChange__c'];
      // -過去日チェック
      if (val && validateRule.pastDate(val)) {
        messages.push({
          id: 'CE0038',
          values: ['電力抜柱工事日（変更）'],
        });
      }
    }

    // 工事メールアドレス
    val = values['KoziMailAddress__c'];
    // -形式チェック
    if (val && validateRule.email(val)) {
      messages.push({
        id: 'CE0044',
        values: ['工事メールアドレス'],
      });
    }
  }


  /**
  * 相関チェック
  * @param {array} messages メッセージ格納用
  */
  correlationCheck(messages) {
    const values = this.props.values;
    const today = getToDate();

    // 工事中止がチェック
    if (values['KoziStop__c']) {
      // チェックしない
      return;
    }

    // 共架工事予定入力期限
    let val = values['KyogaKoziScheduleInputDeadline__c'];
    // -相関チェック
    if ((val &&
      values['IsetuCompHopeDateTimeOfContact__c']) &&
      (val < today ||
        values['IsetuCompHopeDateTimeOfContact__c'] < val)) {
      messages.push({
        id: 'CE0026',
        values: ['共架工事予定入力期限', '現在日から移設完了希望日'],
      });
    }

    // 変更確定登録の場合
    if (this.state.isChangeMode) {
      // 工事変更理由
      val = values['KoziDateChangeReason__c'];
      // -必須チェック
      if ((!val) &&
        (values['DrKenTyKoziDateChange__c'] || values['DrIsnKoziDateChange__c'] || values['DrKenBaTyKoziDateChange__c'])) {
        messages.push({
          id: 'ME0185',
        });
      }
    }
  }

  /**
   * 工事日連絡共架者一覧ボタン押下
   *
   * @return {void}
   */
  doKyogasyaList = async () => {
    try {
      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (this.props.dirty) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: () => {
            this.doNextKyogasyaList();
          },
        });
      } else {
        this.doNextKyogasyaList();
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 工事日連絡共架者一覧へ移動
   *
   * @return {void}
   */
  doNextKyogasyaList = async () => {
    try {
      // 工事日情報（工事日または工事中止）が未入力の場合はエラー
      if (!this.props.saveDesignDocument.KoziDateConfirmRegistDate__c) {
        this.props.doShowMessage({
          message: {
            id: 'ME0181',
          },
        });
        return;
      }
      this.props.history.push({pathname: '/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/KyogasyaList'});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 設備明細確認ボタン押下
   */
  doDetailInput = async () => {
    try {
      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (this.props.dirty) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: () => {
            this.doNextDetailInput();
          },
        });
      } else {
        this.doNextDetailInput();
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 設備明細確認へ移動
   */
  doNextDetailInput = async () => {
    this.props.doSetYbdsPath(this.props.history.location.pathname);
    this.props.history.push({pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/DetailInput'});
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<SaveAltIcon />}
            onClick={
              handleSubmit(this.doTempSave)
            }
            variant="contained"
            size="large"
            disabled={this.state.isStopDisabled}
          >
            <span>一時保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={
              handleSubmit(this.doRegister)
            }
            variant="contained"
            size="large"
            disabled={this.state.isStopDisabled}
          >
            <span>登録</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <PositiveButton
            onClick={
              this.doKyogasyaList
            }
            variant="contained"
            size="large"
          >
            <span>工事日連絡共架者一覧</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn4" item>
          <PositiveButton
            startIcon={<ListAltIcon />}
            onClick={
              this.doDetailInput
            }
            variant="contained"
            size="large"
          >
            <span>設備明細確認</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn5" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true} />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={5}>
              <PaperPart>
                <Typography variant="h6">電力側工事情報</Typography>
                <SimpleTable
                  dataList={this.state.constructionInfo}
                  styles={wideHeaderTableStyle} />

                <Typography variant="h6">設計者情報</Typography>
                <SimpleTable
                  dataList={this.state.designPersonInfo}
                  styles={wideHeaderTableStyle} />
              </PaperPart>
            </Grid>

            <Grid item xs={12} md={7}>
              <PaperPart>

                <Typography variant="h6">工事日確定変更連絡</Typography>

                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >

                  <Grid key="key3-1" item xs={12} sm={6}>
                    <Field className={classes.checkbox}
                      name="KoziStop__c"
                      component={renderCheckbox}
                      label="工事保留／中止"
                      disabled={this.state.isStopDisabled}
                    />
                  </Grid>

                  <Grid key="key3-2" item xs={12} sm={6}>
                    <Field
                      name="IsetuCompHopeDateTimeOfContact__c"
                      accessibilitylabel="IsetuCompHopeDateTimeOfContact__c"
                      label="移設完了希望日（工事日連絡時）"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      required={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.date,
                      ]}
                      warn={this.state.warnInfo.requiredPastDate}
                    />
                  </Grid>

                  <Grid key="key3-2-1" item xs={12} sm={6}>
                  </Grid>

                  <Grid key="key3-2-2" item xs={12} sm={6}>
                    <Field
                      name="KyogaKoziScheduleInputDeadline__c"
                      accessibilitylabel="KyogaKoziScheduleInputDeadline__c"
                      label="共架工事予定入力期限"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      required={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.date,
                      ]}
                      warn={this.state.warnInfo.requiredPastDate}
                    />
                  </Grid>

                  <Grid key="key3-3" item xs={12} sm={6}>
                    <Field
                      name="DrKenTyKoziDate__c"
                      accessibilitylabel="DrKenTyKoziDate__c"
                      label="電力建柱工事日"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled ||
                        this.state.isChangeMode}
                      validate={[
                        validateRule.date,
                      ]}
                      warn={(!this.state.isChangeMode ?
                        this.state.warnInfo.pastDate : [])}
                    />
                  </Grid>

                  <Grid key="key3-4" item xs={12} sm={6}>
                    <Field
                      name="DrIsnKoziDate__c"
                      accessibilitylabel="DrIsnKoziDate__c"
                      label="電力移線工事日"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      required={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled ||
                        this.state.isChangeMode}
                      validate={[
                        validateRule.date,
                      ]}
                      warn={(!this.state.isChangeMode ?
                        this.state.warnInfo.requiredPastDate : [])}
                    />
                  </Grid>

                  <Grid key="key3-5" item xs={12} sm={6}>
                    <Field
                      name="DrKenBaTyKoziDate__c"
                      accessibilitylabel="DrKenBaTyKoziDate__c"
                      label="電力抜柱工事日"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled ||
                        this.state.isChangeMode}
                      validate={[
                        validateRule.date,
                      ]}
                      warn={(!this.state.isChangeMode ?
                        this.state.warnInfo.pastDate : [])}
                    />
                  </Grid>

                  <Grid key="key3-6" item xs={12} sm={6}>
                    <Field
                      name="DrKenTyKoziDateChange__c"
                      accessibilitylabel="DrKenTyKoziDateChange__c"
                      label="電力建柱工事日（変更）"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled ||
                        this.state.disableChangeDate}
                      validate={[
                        validateRule.date,
                      ]}
                    />
                  </Grid>

                  <Grid key="key3-7" item xs={12} sm={6}>
                    <Field
                      name="DrIsnKoziDateChange__c"
                      accessibilitylabel="DrIsnKoziDateChange__c"
                      label="電力移線工事日（変更）"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled ||
                        this.state.disableChangeDate}
                      validate={[
                        validateRule.date,
                      ]}
                    />
                  </Grid>

                  <Grid key="key3-8" item xs={12} sm={6}>
                    <Field
                      name="DrKenBaTyKoziDateChange__c"
                      accessibilitylabel="DrKenBaTyKoziDateChange__c"
                      label="電力抜柱工事日（変更）"
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={this.state.isStopDisabled ||
                        this.state.disableChangeDate}
                      validate={[
                        validateRule.date,
                      ]}
                      warn={(!this.state.disableChangeDate ?
                        this.state.warnInfo.pastDate : [])}
                    />
                  </Grid>

                  <Grid key="key3-9" item xs={12} sm={6}>
                    <Field
                      name="KoziDateChangeReason__c"
                      className={classes.fields}
                      component={renderTextField}
                      label="変更理由"
                      fullWidth
                      type="text"
                      required={this.state.IsChangeMode}
                      disabled={this.state.isStopDisabled ||
                        this.state.disableChangeDate}
                      validate={[
                        validateRule.maxLength(255),
                      ]}
                    />
                  </Grid>

                  <Grid key="key3-10" item xs={12} sm={6}>
                    <Field
                      name="KoziCompanySyozoku__c"
                      className={classes.fields}
                      component={renderTextField}
                      label="工事会社/所属"
                      fullWidth
                      type="text"
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.maxLength(30),
                        validateRule.fullWidth,
                      ]}
                    />
                  </Grid>

                  <Grid key="key3-11" item xs={12} sm={6}>
                    <Field
                      name="KoziTantoName__c"
                      className={classes.fields}
                      component={renderTextField}
                      label="工事担当者名"
                      fullWidth
                      type="text"
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.maxLength(20),
                        validateRule.fullWidth,
                      ]}
                    />
                  </Grid>

                  <Grid key="key3-12" item xs={12} sm={6}>
                    <Field
                      name="KoziTel__c"
                      className={classes.fields}
                      component={renderTextField}
                      label="工事電話番号"
                      fullWidth
                      type="text"
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.number,
                        validateRule.minLength(10),
                        validateRule.maxLength(11),
                      ]}
                    />
                  </Grid>

                  <Grid key="key3-13" item xs={12} sm={6}>
                    <Field
                      name="KoziMailAddress__c"
                      className={classes.fields}
                      component={renderTextField}
                      label="工事メールアドレス"
                      fullWidth
                      type="text"
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.maxLength(255),
                      ]}
                      warn={this.state.warnInfo.email}
                    />
                  </Grid>

                  <Grid key="key3-14" item xs={12} sm={6}>
                    <Field
                      name="MessageTimeOfContact__c"
                      className={classes.fields}
                      component={renderTextField}
                      label="連絡事項（工事日連絡時）"
                      fullWidth
                      type="text"
                      disabled={this.state.isStopDisabled}
                      validate={[
                        validateRule.maxLength(50),
                        validateRule.fullWidth,
                      ]}
                    />
                  </Grid>

                </Grid>

              </PaperPart>
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  designDocumentId: PropTypes.string,
  saveDesignDocument: PropTypes.object,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object,
  doGetDesignDocument: PropTypes.func,
  doClearDesignDocument: PropTypes.func,
  dirty: PropTypes.bool,
  doUpdateDesignDocument: PropTypes.func,
  doSetYbdsPath: PropTypes.func,
  generalPurposeMap: PropTypes.object,
  orderSubAllList: PropTypes.array,
  doGetOrderSubAllList: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {
  };

  // 保存済の情報があれば設定
  const savedData = state.isetuIrai.saveDesignDocument;
  if (savedData) {
    // この画面で更新するデータだけを設定
    for (const name of useFields) {
      if (savedData[name]) {
        initValues[name] = savedData[name];
      }
    }
  }

  return {
    initialValues: initValues,
    designDocumentId: state.isetuIrai.designDocumentId,
    saveDesignDocument: state.isetuIrai.saveDesignDocument,
    generalPurposeMap: state.common.generalPurposeMap,
    orderSubAllList: state.attachorder.orderSubAllList,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetDesignDocument: isetuIraiOperations.doGetDesignDocument,
  doUpdateDesignDocument: isetuIraiOperations.doUpdateDesignDocument,
  doSetYbdsPath: commonOperations.doSetYbdsPath,
  doClearDesignDocument: isetuIraiOperations.doClearDesignDocument,
  doGetOrderSubAllList: attachorderOperations.doGetOrderSubAllList,
};

const FORM_NAME = 'IsetuIraiKoziDateInput';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);


import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, getFormSyncWarnings} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';

import * as wjCore from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, getComboName,
  getErrorCode, API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {renderSelect} from '../../../../../atoms/CustomPart.js';
import * as validateRule from '../../../../../validateRule.js';
import {orderDntFields} from '../../../../../../common/SFFields';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import Upload from '../../../../../organisms/UploadCustom';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SerialNumber__c: null, // 通し番号
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  KyogaCategory__c: null, // 共架区分
  IskkCategory__c: null, // 一束化
  IskkAiteZgsya1__c: null, // 一束化相手先事業者1
  IskkAiteZgsya2__c: null, // 一束化相手先事業者2
  IskkAiteZgsya3__c: null, // 一束化相手先事業者3
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  AttachType__c: null, // 取付種別
  AttachSpotHeight__c: null, // 取付点高さ(m)
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakOrder__c: null, // 最接近電力設備との離隔/離隔距離(m)/申請時
  NWKoziHopeKoziContents__c: null, // 中電NWへの工事希望/工事内容
  NWKoziHopeMemo__c: null, // 中電NWへの工事希望/メモ
  TsnHksnUmu__c: null, // 通信引込線有無
  DrHksnKiknCrossingRikak__c: null, // 電力引込線との離隔(m)/径間途中,交差部分
  DrHksnHouseSideRikakKkh__c: null, // 電力引込線との離隔確保/家屋側支持点付近
  Order__c: null, // 申込のId
  KyogaZgsya__c: null, // 共架事業者(AccountのId)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  ProprietyJudgeContractDnt__c: null, // 可否判定申込の契約電柱ID
  ProprietyJudgeResult__c: null, // 可否判定結果
  KyogaType__c: null, // 共架種別
  KyogaPriceCategory__c: null, // 共架料区分
  OtherMemo__c: null, // その他メモ
  OtherKyokaRequirements__c: null, // その他許可条件
  ProprietyJudgeDoneDate__c: null, // 可否判定実施日
  ProprietyJudgeCostBillCategory__c: null, // 可否判定費用請求区分
  ImpossibleReason__c: null, // 否理由
  ProprietyJudgeRemarks__c: null, // 可否判定備考
  ProprietyJudgeContentsMemo__c: null, // 可否判定内容メモ
  RnsTkBosiTiskTyJudge__c: null, // 連鎖倒壊防止対策柱判定
  AnswerDate__c: null, // 回答日
  DrSideKoziRequiredPeriod__c: null, // 電力側工事所要期間
  DrSideKoziUnnecessaryReason__c: null, // 電力側工事不要理由
  AfterJudgingSafetyRate__c: null, // 審査後安全率
  AfterJudgingTekigoJudge__c: null, // 審査後適合判定
  BeforeJudgingSafetyRate__c: null, // 審査前安全率
  BeforeJudgingTekigoJudge__c: null, // 審査前適合判定
  HakksDoneDate__c: null, // 風圧荷重計算実施日
  HakksApprover__c: null, // 風圧荷重計算承認者
  HakksApprovalDate__c: null, // 風圧荷重計算承認日
  HakksTanto__c: null, // 風圧荷重計算担当者
  NWKoziHopeRemarks__c: null, // NW工事希望_備考
  StbType__c: null, // 設備種別
};
const updateParams = {
  Id: null,
  SerialNumber__c: null, // 通し番号
  MaintenanceKoziCompany__c: null, // 保守工事会社
};

/**
 * 本申込（申込電柱：線設備）
 * 画面ID:1014
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      iskkFiles: [],
      warningMessages: [],
    };
    this.gridRef = React.createRef();
    this.onFormatItemHandler = this.onFormatItemHandler.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
   * 初期処理
   * 本申込作成中の場合、可否判定申込の契約電柱を表示
   *
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearFormalContractDntList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1014', 'Id');
    }
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusCreate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    const attachedFileTypeIskk = getCodeFromGeneralPurposeMap(
        generalPurposeMap, 'AttachedFileTypeA10', 3); // 一束化協議資料ファイル

    // 保守工事会社コンボと本申込と申込電柱を取得する
    const conditions = {
      'Id': orderId, // 本申込のId
      'RecordType.DeveloperName': 'MainOrder',
    };
    const response =
      await this.props.doGetFormalContractDntList(
          conditions, orderDntFields, {}, this.props.referenceMode);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    // グリッド初期化
    this.gridRef.current.setScreenState('1014');
    const cv = createCollectionView(this.props.contractDntList,
        false, {}, null);
    this.gridRef.current.getGrid().itemsSource = cv;

    let _iskkFiles = [];
    const isIskkAri = this.props.contractDntList &&
    this.props.contractDntList.filter((data) => data.IskkCategory__c != '1').length > 0;
    if (isIskkAri && this.props.contentDocuments &&
      this.props.contentDocuments.length > 0) {
      _iskkFiles = this.props.contentDocuments.filter(
          (record) => record.AttachedFileType__c == attachedFileTypeIskk);
    }

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    // 入力チェックエラー
    let isWarn = false;
    if (_warningMessages.length > 0) {
      isWarn = true;
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                    orderStatusCreate ? false : true) ||
                    this.props.referenceMode, // 作成中のみ編集可。確認画面から参照した場合は編集不可
      isModified: false,
      iskkFiles: _iskkFiles,
      warningMessages: _warningMessages,
    });
    await this.props.doSetGmnWarning('1014', isWarn);

    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
          data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'Id') ?
          data.order.Id : null;
      lastModifiedDate =
              data && Object.hasOwnProperty.call(data, 'order') &&
              Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
              data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusCreate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    if (this.props.order &&
      this.props.order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    // 電柱0件チェック
    const formalDntCount =
        this.props.order &&
        this.props.order.ContractDnts__r &&
        this.props.order.ContractDnts__r.totalSize > 0 ?
        this.props.order.ContractDnts__r.totalSize : 0;
    if (formalDntCount == 0) {
      warningMessages.push({id: 'CE0159', values: ['申込電柱']});
      return;
    }
    // 分割申込の整合性チェック
    const splitOrderUmu =
          this.props.order ? this.props.order.SplitOrderUmu__c : '0';
    if (splitOrderUmu == '1') {
      // 分割有の場合
      // 本申込の契約電柱件数と可否判定申込の契約電柱の件数が一致したら全選択
      // 全選択の場合はエラー
      if (formalDntCount == this.props.proprietyDntCount) {
        warningMessages.push({
          id: 'PE0172',
          values: ['有', '全選択'],
        });
      }
    } else {
      // 分割無の場合
      // 本申込の契約電柱件数と可否判定申込の契約電柱の件数が不一致だったら一部選択
      // 一部選択の場合はエラー
      if (formalDntCount != this.props.proprietyDntCount) {
        warningMessages.push({
          id: 'PE0172',
          values: ['無', '部分選択'],
        });
      }
    }
    // 保守工事会社選択チェック
    const formalContractDnts =
          this.props.order &&
          this.props.order.ContractDnts__r &&
          this.props.order.ContractDnts__r.totalSize > 0 ?
          this.props.order.ContractDnts__r.records : [];
    const checkKoziCompany = formalContractDnts.filter((data) =>
      Object.hasOwnProperty.call(data, 'MaintenanceKoziCompany__c') &&
          data.MaintenanceKoziCompany__c == null);
    if (checkKoziCompany.length > 0) {
      warningMessages.push({
        id: 'CE0027',
        values: ['保守工事会社'],
      });
    }
  }

  /**
   * 申込電柱の登録/更新処理
   */
  insertOrUpdate = async () => {
    const {order, values, orderModifiedCondition} = this.props;

    const updateConditions = [];
    const saveContractDntList = [];
    const selectedDntList = this.gridRef.current.selectedItem(); // 選択した申込電柱
    const formalList =
      order.ContractDnts__r && order.ContractDnts__r.totalSize > 0 ?
      order.ContractDnts__r.records : []; // 本申込の申込電柱一覧
    let serialNumber = 1;
    for (const row of selectedDntList) {
      const dnt = row.dataItem;
      if (Object.hasOwnProperty.call(dnt, 'RecordType') &&
            Object.hasOwnProperty.call(dnt.RecordType, 'DeveloperName') &&
            dnt.RecordType.DeveloperName == 'MainOrder') {
        continue;
      }
      // 選択した電柱が本申込にあれば更新、なければ登録
      const formalDnt = formalList.filter((data) =>
        data['ProprietyJudgeContractDnt__c'] == dnt['Id']);
      if (formalDnt.length > 0) {
        const updateData = {};
        Object.assign(updateData, updateParams);
        updateData['Id'] = formalDnt[0].Id;
        updateData['SerialNumber__c'] = serialNumber++;
        updateData['MaintenanceKoziCompany__c'] =
            values['MaintenanceKoziCompany__c']; // 保守工事会社参照先設定
        saveContractDntList.push(updateData);
        updateConditions.push({
          Id: formalDnt[0].Id,
          LastModifiedDate: formalDnt[0].LastModifiedDate,
        });
      } else {
        const insertData = {Id: null};
        for (const key in addParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            insertData[key] = dnt[key];
          }
        }
        insertData['ProprietyJudgeContractDnt__c'] = dnt['Id'];
        insertData['Order__c'] = order.Id; // 本申込と契約電柱紐づけ
        insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
        insertData['MaintenanceKoziCompany__c'] =
            values['MaintenanceKoziCompany__c']; // 保守工事会社参照先設定
        insertData['SerialNumber__c'] = serialNumber++;
        saveContractDntList.push(insertData);
      }
    }
    // 削除 本申込の契約電柱
    // 本申込にあって選択した電柱にないものは削除
    const deleteConditions = [];
    for (const formalData of formalList) {
      const isDeleteDnt = (selectedDntList.filter((row) =>
        row.dataItem['Id'] == formalData['ProprietyJudgeContractDnt__c']).length == 0);
      if (isDeleteDnt && formalData.Id) {
        deleteConditions.push({
          Id: formalData.Id,
          LastModifiedDate: formalData.LastModifiedDate,
        });
      }
    }
    // 本申込一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate:
        orderModifiedCondition != null &&
        order.Id == orderModifiedCondition.Id ?
        orderModifiedCondition.LastModifiedDate : null,
    };
    const updateOrder = {
      Id: order.Id,
    };

    const response = await this.props.doSaveFormalContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(response, ['申込電柱', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      if (!this.gridRef.current) {
        return;
      }

      // 電柱0件チェック
      const count = this.gridRef.current.selectedItemCount();
      if (count == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['申込電柱'],
          },
        });
        return;
      }

      // 選択状態保存
      this.gridRef.current.saveScreenState('1014', 'Id');

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/FormalOrderList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 径間入力ボタン押下
   */
  doMoveKeikan = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
          this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (this.props.dirty) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1014')) {
        _hasError = this.props.setGmnWarning['1014'];
      }
    }

    // 入力チェックエラー
    if (_hasError) {
      const noDnt =
          this.state.warningMessages.filter((data) => data.id == 'CE0159');
      if (noDnt.length > 0) {
        // 電柱0件は次画面に遷移しない
        this.props.doShowMessage({message: noDnt});
        return;
      }

      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/FormalOrderSenStb'});
  }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
   onFormatItemHandler = (s, e) => {
     if ((s.rowHeaders === e.panel &&
       e.panel.cellType == wjgGrid.CellType.RowHeader) ||
       (s.topLeftCells === e.panel &&
       e.panel.cellType == wjgGrid.CellType.TopLeft)) {
       if (this.state.isDisabled) {
         if (e.cell != null && e.cell.children != null &&
            e.cell.children.length > 0) {
           wjCore.addClass(e.cell.children[0], 'wj-state-disabled');
         }
       } else {
         if (e.cell != null && e.cell.children != null &&
          e.cell.children.length > 0) {
           wjCore.removeClass(e.cell.children[0], 'wj-state-disabled');
         }
       }
     }
     if (s.cells === e.panel &&
      e.panel.cellType == wjgGrid.CellType.Cell) {
       const col = e.getColumn();
       // チェックボックスにラベルをつける
       if (col.name === 'DrHksnHouseSideRikakKkh' && s.rows[e.row]) {
         let text = document.createTextNode('良');
         let checkbox = null;
         if (e.cell.firstChild) {
           checkbox = e.cell.firstChild.firstChild;
         }
         if (checkbox != null && checkbox.parentNode != null) {
           checkbox.parentNode.insertBefore(text, checkbox);
         }
       }
     }
   }

   /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
   filterChangingHandler(s, e) {
     if (e.getColumn().binding === 'DntCategory__c' ||
        e.getColumn().binding === 'IskkCategory__c'||
        e.getColumn().binding === 'ProprietyJudgeResult__c' ||
        e.getColumn().binding === 'AttachType__c' ||
        e.getColumn().binding === 'ClosestDrStbName__c' ||
        e.getColumn().binding === 'NWKoziHopeKoziContents__c' ||
        e.getColumn().binding === 'TsnHksnUmu__c') {
       let edt = s.activeEditor;
       let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
       let lb = wjCore.Control.getControl(lbHost);

       let categoryName = 'DntCategory'; // 電柱区分
       if (e.getColumn().binding === 'IskkCategory__c') {
         categoryName = 'IskkCategory'; // 一束化
       } else if (e.getColumn().binding === 'ProprietyJudgeResult__c') {
         categoryName = 'ProprietyCategory'; // 可否判定結果
       } else if (e.getColumn().binding === 'AttachType__c') {
         categoryName = 'AttachType'; // 取付種別
       } else if (e.getColumn().binding === 'ClosestDrStbName__c') {
         categoryName = 'ClosestDrStbStbName'; // 最接近電力設備との離隔/設備名称
       } else if (e.getColumn().binding === 'TsnHksnUmu__c') {
         categoryName = 'UmuCategory'; // 通信引込線/通信引込線の有無
       } else if (e.getColumn().binding === 'NWKoziHopeKoziContents__c') {
         categoryName = 'DrSideKoziKinds'; // 中国電力NWへの工事希望/工事内容
       }

       lb.itemFormatter = (index) => {
         const ret = getComboName(this.props.generalPurposeMap,
             categoryName, lb.collectionView.items[index].value);
         return ret ? ret : '(なし)';
       };
       lb.collectionView.refresh();
     }
     if (e.getColumn().binding === 'DrHksnHouseSideRikakKkh__c') {
       let edt = s.activeEditor;
       let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
       let lb = wjCore.Control.getControl(lbHost);
       lb.itemFormatter = (index) => {
         let result = '(なし)';
         if (lb.collectionView.items[index].value === true) {
           result = '良';
         }
         return result;
       };
       lb.collectionView.refresh();
     }
   }

   /**
   * 一覧描画
   * @return {object}
   */
   renderTable() {
     const {generalPurposeMap} = this.props;
     const props = {
       rowHeaderType: 'check',
       filterOn: true,
       headersVisibility: 'Column',
       allowSorting: 'None',
       allowDragging: 'Columns',
       counterOn: false,
       AddDeleteOn: false,
       frozenColumns: 3,
       style: {height: '400px'},
       isReadOnly: true,
       useStore: false,
       validateEdits: false,
       doShowMessage: this.props.doShowMessage,
       formatItemFunction: this.onFormatItemHandler,
       filterChanging: this.filterChangingHandler,
     };

     return (
       <CustomFlexGrid ref={this.gridRef} {...props}>
         <FlexGridColumnGroup header="電柱" align="center">
           <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" width={120}/>
           <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" width={95}/>
           <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" width={65}>
             <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
               return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
             }}/>
           </FlexGridColumnGroup>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup header="電線" align="center" collapseTo="IskkCategory__c" isCollapsed={true}>
           <FlexGridColumnGroup binding="IskkCategory__c" header="一束化" dataType="String">
             <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
               return getComboName(generalPurposeMap, 'IskkCategory', ctx.item.IskkCategory__c);
             }}/>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup binding="IskkAiteZgsya1__r.Name" header="一束化相手先\n共架事業者1" dataType="String" width={130}/>
           <FlexGridColumnGroup binding="IskkAiteZgsya2__r.Name" header="一束化相手先\n共架事業者2" dataType="String" width={130}/>
           <FlexGridColumnGroup binding="IskkAiteZgsya3__r.Name" header="一束化相手先\n共架事業者3" dataType="String" width={130}/>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="ProprietyJudgeResult__c" header="可否判定結果" dataType="String">
           <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
             return getComboName(generalPurposeMap, 'ProprietyCategory', ctx.item.ProprietyJudgeResult__c);
           }}/>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String"/>
         <FlexGridColumnGroup binding="AttachType__c" header="取付種別" dataType="String" width={110}>
           <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
             return getComboName(generalPurposeMap, 'AttachType', ctx.item.AttachType__c);
           }}/>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="AttachSpotHeight__c" header="取付点\n高さ（m）" dataType="Number" format="n1" width={110}/>
         <FlexGridColumnGroup header="最接近電力設備との離隔" align="center">
           <FlexGridColumnGroup binding="ClosestDrStbName__c" header="設備名称" dataType="String">
             <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
               return getComboName(generalPurposeMap, 'ClosestDrStbStbName', ctx.item.ClosestDrStbName__c);
             }}/>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup binding="ClosestDrStbRikakOrder__c" header="離隔距離（m）" dataType="Number" format="n2"/>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup header="中国電力NWへの工事希望" align="center">
           <FlexGridColumnGroup binding="NWKoziHopeKoziContents__c" header="工事内容" dataType="String">
             <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
               return getComboName(generalPurposeMap, 'DrSideKoziKinds', ctx.item.NWKoziHopeKoziContents__c);
             }}/>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup binding="NWKoziHopeMemo__c" header="メモ" dataType="String" width={300}/>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup header="通信引込線" align="center">
           <FlexGridColumnGroup binding="TsnHksnUmu__c" header="通信引込線の有無" dataType="String" cssClass="centerPos" width={110}>
             <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
               return getComboName(generalPurposeMap, 'UmuCategory', ctx.item.TsnHksnUmu__c);
             }}/>
           </FlexGridColumnGroup>
           <FlexGridColumnGroup binding="DrHksnKiknCrossingRikak__c" header="①電力引込線との離隔（m）（径間途中，交差部分）" dataType="Number" width={230} format="n2"/>
           <FlexGridColumnGroup name="DrHksnHouseSideRikakKkh" binding="DrHksnHouseSideRikakKkh__c" header="②電力引込線との離隔確保（家屋側支接点付近）" cssClass="centerPos" width={230}/>
         </FlexGridColumnGroup>
       </CustomFlexGrid>
     );
   }

   render() {
     const {classes, handleSubmit} = this.props;

     const footerBtn = (
       <Grid container
         justifyContent="center"
         alignItems="flex-start"
         spacing={1}
       >
         <Grid key="btnkey1014Next" item>
           <PositiveButton
             id="btn1014Next"
             onClick={handleSubmit(this.doMoveKeikan)}
             variant="contained"
             size="large"
             disabled={this.props.referenceMode}
           >
             <span>径間入力</span>
           </PositiveButton>
         </Grid>
         <Grid key="key1014Save" item>
           <PositiveButton
             id="btn1014Save"
             onClick={handleSubmit(this.doSave)}
             variant="contained"
             size="large"
             startIcon={<SaveAltIcon/>}
             disabled={this.state.isDisabled}>
             <span>保存</span>
           </PositiveButton>
         </Grid>
         <Grid key="key1014Back" item>
           <BackButton props={this.props}
             isModified={this.state.isModified}
             showWarning={true}/>
         </Grid>
       </Grid>
     );

     return (
       <form noValidate autoComplete="off" className={classes.root}>
         <MainContainer
           props={this.props}
           footerBtn={footerBtn}>
           <WarningMessageArea messages={this.state.warningMessages}/>
           <span>
             分割申込の有無の選択は「本申込画面（前画面）」の工事場所欄で選択してください。
             <br/>
             〇分割申込「無」を選択した場合、この画面ですべての電柱に &#10004; してください。
             <br/>
             〇分割申込「有」を選択した場合、この画面で申込みを希望する電柱を &#10004; してください。
             （残りの電柱は「本申込画面」から同じ申込番号を選択し、別途申込むことができます。）
           </span>
           <br/>
           <br/>
           <Grid container
             justifyContent="flex-start"
             alignItems="flex-start"
             spacing={1}
           >
             <Grid key="key1014MaintenanceKoziCompany" item>
               <Field
                 id="id1014MaintenanceKoziCompany"
                 name="MaintenanceKoziCompany__c"
                 component={renderSelect}
                 className={classes.fields}
                 style={{minWidth: '170px', minHeight: '75px'}}
                 label='保守工事会社選択'
                 required={true}
                 InputProps={{
                   readOnly: false,
                 }}
                 validate={[
                 ]}
                 warn={[
                   validateRule.required,
                 ]}
                 disabled={this.state.isDisabled}
               >
                 {this.props.koziCompanyList &&
                      createSearchList(
                          this.props.koziCompanyList, 'Id', 'DspName')}
               </Field>
             </Grid>
           </Grid>
           <Grid container
             direction="column"
             alignItems="flex-start"
           >
             <Grid key="key1014Table" item xs={12}>
               {this.renderTable()}
             </Grid>
           </Grid>
           <Grid container
             justifyContent="flex-start"
             alignItems="flex-start"
             spacing={1}
           >
             <Grid key="key1014IskkDoc" item xs={6}>
               <Grid container
                 justifyContent="flex-start"
                 alignItems="flex-start"
               >
                 <Grid key="key1014IskkFile" item xs={12}>
                   <Typography variant="h6" gutterBottom>
                     一束化協議資料
                   </Typography>
                 </Grid>
                 <Grid key="key1014IskkUpload" item>
                   <Upload
                     componentId="id1014IskkComponent"
                     maxFileCount={3}
                     maxFileSize={3145728}
                     previewFlag={false}
                     previewWidth="35%"
                     acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                     initFile={this.state.iskkFiles}
                     disabled={true}
                   />
                 </Grid>
               </Grid>
             </Grid>
           </Grid>
         </MainContainer>
       </form>
     );
   }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  contractDntList: PropTypes.array,
  koziCompanyList: PropTypes.array,
  referenceMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetFormalContractDntList: PropTypes.func,
  doSaveFormalContractDnts: PropTypes.func,
  doClearFormalContractDntList: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  syncWarnings: PropTypes.object,
  proprietyDntCount: PropTypes.number,
  contentDocuments: PropTypes.array,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  const initValues = {
    MaintenanceKoziCompany__c: null, // 保守工事会社Id
  };

  // 保守工事会社一覧
  const koziCompanyList = state.contractDnt.formalContractDntList &&
    Object.hasOwnProperty.call(
        state.contractDnt.formalContractDntList, 'koziCompanyList') ?
        state.contractDnt.formalContractDntList.koziCompanyList : null;
  // 本申込情報
  const order = state.contractDnt.formalContractDntList &&
              Object.hasOwnProperty.call(
                  state.contractDnt.formalContractDntList, 'order') ?
                  state.contractDnt.formalContractDntList.order : null;
  // 申込電柱
  const contractDntList = state.contractDnt.formalContractDntList &&
                      Object.hasOwnProperty.call(
                          state.contractDnt.formalContractDntList, 'contractDntList') ?
                      state.contractDnt.formalContractDntList.contractDntList :
                      null;
  // 一束化協議資料
  const _contentDocuments = state.contractDnt.formalContractDntList &&
  Object.hasOwnProperty.call(
      state.contractDnt.formalContractDntList, 'contentDocuments') ?
      state.contractDnt.formalContractDntList.contentDocuments :
      false;
  // 保存した保守工事会社をコンボの初期値にする
  const maintenanceKoziCompany = state.contractDnt.formalContractDntList &&
    Object.hasOwnProperty.call(
        state.contractDnt.formalContractDntList, 'maintenanceKoziCompany') ?
        state.contractDnt.formalContractDntList.maintenanceKoziCompany : null;
  initValues['MaintenanceKoziCompany__c'] = maintenanceKoziCompany;
  // 可否判定申込の契約電柱（可、条件付可）件数
  const proprietyDntCount = state.contractDnt.formalContractDntList &&
    Object.hasOwnProperty.call(
        state.contractDnt.formalContractDntList, 'proprietyContractDntCount') ?
        state.contractDnt.formalContractDntList.proprietyContractDntCount : 0;

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId, // 前画面で設定した本申込のId
    order: order,
    contractDntList: contractDntList,
    koziCompanyList: koziCompanyList,
    proprietyDntCount: proprietyDntCount,
    contentDocuments: _contentDocuments,
    referenceMode: state.common.referenceMode == true ? true : false,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetFormalContractDntList: contractDntOperations.doGetFormalContractDntList,
  doSaveFormalContractDnts: contractDntOperations.doSaveFormalContractDnts,
  doClearFormalContractDntList:
    contractDntOperations.doClearFormalContractDntList,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'FormalSenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
    syncWarnings: getFormSyncWarnings(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

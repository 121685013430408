import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {authOperations} from '../../../reducks/auth';
import {commonOperations} from '../../../reducks/common';
import Container from '../../../components/templates/DaikoInput/List';

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  getUserInfo: authOperations.getUserInfoOperation,
};

/**
 * 代行入力(ユーザ切替) ページ。
 * 画面ID:1541
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const initResult = await this.init();
    this.doJudge(initResult);
  }

  /**
   * 初期化処理。
   * @return {Promise<boolean>} 初期化結果
   */
  init = async () => {
    const {getAllGeneralPurposeMap, getUserInfo} = this.props;

    try {
      await getUserInfo();
      if (!this.props.hasOwnProperty('userInfo')) {
        return false;
      }

      await getAllGeneralPurposeMap();
      if (!this.props.hasOwnProperty('generalPurposeMap')) {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }

  /**
   * 初期化処理結果判定。
   * @param {boolean} initResult 初期化結果
   */
   doJudge = (initResult) => {
     if (!initResult) {
       const {doShowMessage} = this.props;
       const dialog = {
         message: 'CS0001',
         action: this.doMoveLogin,
       };
       doShowMessage(dialog);
     }
   }

   /**
    * ログイン画面へ遷移。
    */
  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  /**
   * 画面描画。
   * @return {JSX.Element}
   */
  render() {
    return (<Container {...this.props} />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  doShowMessage: PropTypes.func,
  history: PropTypes.object,
  getAllGeneralPurposeMap: PropTypes.func,
  getUserInfo: PropTypes.func,
  userInfo: PropTypes.object,
};

export default ContainerApp;

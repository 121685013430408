import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

import CommonTheme from '../../Theme.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    background: CommonTheme.palette.primary.light,
    position: 'fixed',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100px',
    overflowX: 'scroll',
    padding: '20px 10px 0px 0px',
    zIndex: theme.zIndex.drawer + 1,

    [theme.breakpoints.up(
        CommonTheme.breakpoints.values.lg + CommonTheme.menu.width)]: {
      maxWidth: 'calc(' + CommonTheme.breakpoints.values.lg + 'px + ' + CommonTheme.menu.width + 'px)',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      // スマホ解像度の場合はスクロールバー表示
      overflowY: 'scroll',
    },
    [theme.breakpoints.up('sm')]: {
      // スマホ解像度を超える場合スクロールバー非表示
      // IE, 旧Edge 対応
      '-ms-overflow-style': 'none', /* IE, Edge 対応 */

      // 新Edge, Chrome, Safari 対応
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      // Firefox対応
      'scrollbarWidth': 'none',
    },
  },
}));

export default function Footer({children}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={clsx(classes.footer)}>
        {children}
      </footer>
    </div>
  );
};

Footer.propTypes = {
  children: PropTypes.object,
};

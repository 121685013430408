import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddIcon from '@material-ui/icons/Add';

import * as wjCore from '@grapecity/wijmo';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {Popup, InputNumber} from '@grapecity/wijmo.react.input';

import {getCodeFromGeneralPurposeMap, getComboName,
  getErrorCode, API_CODE, isSameContractDnt,
  checkMainDntNoNWZgsyo, checkMainDntNoInContractDnts,
  getResponseResult,
  getChargeableCategoryOfKyogaType} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import CommonTheme from '../../../../../../Theme.js';
import * as validateRule from '../../../../../validateRule.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import {orderDntFields} from '../../../../../../common/SFFields';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  KyogaCategory__c: null, // 共架区分
  KyogaType__c: null, // 共架種別
  Order__c: null, // 申込のId
  SerialNumber__c: null, // 通し番号
  AttachType__c: null, // 取付種別
  AttachSpotHeight__c: null, // 取付点高さ(m)
  KyogaZgsya__c: null, // 共架事業者(AccountのId)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  StbType__c: null, // 設備種別
  ProprietyJudgeCostBillCategory__c: null, // 可否判定費用請求区分
};
const updateParams = {
  Id: null,
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  KyogaCategory__c: null, // 共架区分
  KyogaType__c: null, // 共架種別
  SerialNumber__c: null, // 通し番号
  AttachType__c: null, // 取付種別
  AttachSpotHeight__c: null, // 取付点高さ(m)
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  ProprietyJudgeCostBillCategory__c: null, // 可否判定費用請求区分
};

/**
 * 可否判定申込（申込電柱：点設備）
 * 画面ID:1007
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      modalOpen: false,
      ybdsType: 'D', // D or DM
      editItem: null,
      warningMessages: [],
    };
    this.currentCtx = null;
    this.popup = null;
    this.gridRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // 編集ダイアログ用
    this.editedAttachSpotHeight = null; // 取付点高さ（m）

    // グリッド項目初期化
    this.items = {};
    for (const key of orderDntFields) {
      this.items[key] = '';
    }

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      // 必須チェック
      // 電柱番号
      if (prop == 'Dsp_DntNo__c') {
        let str = '';
        if (item[prop] != '未設定') {
          str = item[prop];
        }
        // CE0017
        let error = validateRule.required4(str);
        if (error) {
          return error;
        }
      }
      // 取付点高さ（m）
      if (prop == 'AttachSpotHeight__c') {
        // CE0017
        let error = validateRule.required4(
            item.AttachSpotHeight__c);
        if (error) {
          return error;
        }
      }
      // データ範囲チェック
      // 取付点高さ（m）
      if (prop == 'AttachSpotHeight__c') {
        // CE0026
        if (item[prop] < 2.5 || item[prop] > 99.9) {
          return '2.5～99.9の範囲内で入力してください';
        }
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderContractDnt();
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    // 申込IDに紐づく申込電柱を取得する
    const orderConditions = {
      'Id': orderId,
      'OrderCategory__c': getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory01', 2),
      'RecordType.DeveloperName': 'ProprietyJudgeOrder',
    };
    const orderFields = ['Id', 'LastModifiedDate', 'OrderStatus__c',
      'KyogaType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c',
      'OrderNo__c', 'CheckJiko1__c', 'CheckJiko2__c', 'CheckJiko3__c',
      'MainDntNoNWZgsyo__r.Code__c', 'KoziPlaceMainDntNo__c',
      'RecordType.DeveloperName', 'StbType__c'];
    const conditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'ProprietyJudgeOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response =
      await this.props.doGetOrderAndContractDntList(
          orderConditions, orderFields,
          conditions, orderDntFields, appSortParams);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    if (this.props.order) {
      for (const key of ['OrderNo__c', 'KyogaType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c']) {
        this.items[key] = this.props.order[key];
      }
    }
    this.items['AttachType__c'] = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachType1', 1);
    this.items['AttachSpotHeight__c'] = 0.0;
    // グリッド初期化
    const cv = createCollectionView(this.props.contractDntList,
        true, this.items, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    let isWarn = false;
    if (_warningMessages.length > 0) {
      isWarn = true;
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                  orderStatusCreate ? false : true) ||
                  this.props.referenceMode, // 作成中のみ編集可
      isModified: false,
      warningMessages: _warningMessages,
    });
    await this.props.doSetGmnWarning('1007', isWarn);

    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'Id') ?
        data.order.Id : null;
      lastModifiedDate =
          data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
          data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    if (this.props.order &&
      this.props.order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    // 電柱0件チェック
    if (this.props.contractDntList &&
        this.props.contractDntList.length == 0) {
      warningMessages.push({id: 'CE0019', values: ['申込電柱']});
      return;
    }
    for (const contractDnt of this.props.contractDntList) {
      // 必須チェック
      // 電柱番号
      if (!contractDnt.DntNo__c &&
          !contractDnt.DntNoManualInput__c) {
        warningMessages.push({
          id: 'CE0017',
          values: ['電柱番号(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 取付点高さ（m）
      if (contractDnt.AttachSpotHeight__c == undefined ||
          contractDnt.AttachSpotHeight__c == null) {
        warningMessages.push({
          id: 'CE0017',
          values: ['取付点高さ（m）(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // データ範囲チェック
      if (contractDnt.AttachSpotHeight__c != null &&
         (contractDnt.AttachSpotHeight__c < 2.5 ||
          contractDnt.AttachSpotHeight__c > 99.9)) {
        warningMessages.push({
          id: 'CE0026',
          values: ['取付点高さ（m）(' + contractDnt.SerialNumber__c + '行目)', '2.5～99.9'],
        });
      }
    }
    // 代表電柱同一事業所チェック
    const nWZgsyoMessages =
      checkMainDntNoNWZgsyo(this.props.order, this.props.contractDntList);
    for (const message of nWZgsyoMessages) {
      warningMessages.push(message);
    }
    // 代表電柱番号存在チェック
    const mainDntNoMessages =
      checkMainDntNoInContractDnts(
          this.props.order, this.props.contractDntList);
    for (const message of mainDntNoMessages) {
      warningMessages.push(message);
    }
  }

  /**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ 取付点高さ
   * @param {*} ctl
   */
  initializeEditedAttachSpotHeight(ctl) {
    this.editedAttachSpotHeight = ctl;
  }

  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
  async editPopup(grid, e) {
    try {
      const ht = grid.hitTest(e);
      if (ht.panel === grid.rowHeaders) {
        let _editItem = grid.rows[ht.row].dataItem;
        this.setState({editItem: _editItem});
        this.editedAttachSpotHeight.value =
          _editItem.AttachSpotHeight__c === null ?
          0 : _editItem.AttachSpotHeight__c; // 取付点高さ（m）
        this.popup.show(true, (s) => {
          if (s.dialogResult === 'wj-hide-ok') {
            (grid.collectionView).editItem(_editItem);
            _editItem.AttachSpotHeight__c = this.editedAttachSpotHeight.value; // 取付点高さ（m）
            (grid.collectionView).commitEdit();
          }
          grid.focus();
        });
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 電柱一括追加ボタン
   */
  doAddDntList = () => {
    this.setState({modalOpen: true, ybdsType: 'DM'});
  }

  /**
   * 確認ボタン押下時
   */
  doMoveConfirm = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
        this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
   doCheckInput = async () => {
     let _hasError = false;
     if (this.props.setGmnWarning) {
       if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1007')) {
         _hasError = this.props.setGmnWarning['1007'];
       }
     }

     // 入力チェックエラー
     if (_hasError) {
       const noDnt =
            this.state.warningMessages.filter((data) => data.id == 'CE0019');
       if (noDnt.length > 0) {
         // 電柱0件は次画面に遷移しない
         this.props.doShowMessage({message: noDnt});
         return;
       }

       // 警告表示して次画面へ遷移
       this.props.doShowMessage({
         message: {id: 'CW0157', values: ['申込']},
         action: () => {
           this.doNext();
         },
       });
       return;
     }

     // 入力チェックOK
     this.doNext();
   }

  /**
   * 次画面へ遷移
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/ProprietyConfirm'});
  }

  /**
   * 申込電柱の登録/更新処理
   */
  insertOrUpdate = async () => {
    const {
      order,
      orderModifiedCondition,
      generalPurposeMap,
    } = this.props;

    const contractDntList = [];
    Object.assign(contractDntList, this.gridRef.current.getSourceCollection());
    const saveContractDntList = [];
    const updateConditions = [];
    let serialNumber = 0;
    for (const dnt of contractDntList) {
      // 一覧は1から通し番号設定
      serialNumber++;
      if (!dnt.Id) {
        // 登録項目設定
        const insertData = {Order__c: order.Id}; // 申込と契約電柱紐づけ
        for (const key in addParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            insertData[key] = dnt[key];
          }
        }
        insertData['SerialNumber__c'] = serialNumber;
        insertData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定
        insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
        insertData['StbType__c'] = order.StbType__c;

        // 可否判定費用請求区分を設定
        this._setBillCategory(order, generalPurposeMap, insertData);

        saveContractDntList.push(insertData);
      } else {
        // 更新項目設定
        const updateData = {};
        for (const key in updateParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            updateData[key] = dnt[key];
          }
        }
        updateData['SerialNumber__c'] = serialNumber;
        updateData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定

        // 可否判定費用請求区分を設定
        this._setBillCategory(order, generalPurposeMap, updateData);

        saveContractDntList.push(updateData);
        // 通し番号を変更したものを更新対象にする
        updateConditions.push({
          Id: dnt.Id,
          LastModifiedDate: dnt.LastModifiedDate,
        });
      }
    }

    // 削除
    const deleteConditions = [];
    const data = this.gridRef.current.itemsAllChanged();
    if (data.itemsRemoved.length > 0) {
      for (const row of data.itemsRemoved) {
        if (row.Id) {
          deleteConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }
    // 一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: orderModifiedCondition != null ?
          orderModifiedCondition.LastModifiedDate : null,
    };
    const updateOrder = {
      Id: order.Id,
    };

    const response = await this.props.doSaveContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(response, ['申込電柱', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

   /**
    * 可否判定費用請求区分の設定。
    *
    * @param {object} order 申込
    * @param {object} generalPurposeMap 汎用マスタ
    * @param {object} target 設定対象データ
    */
   _setBillCategory = (order, generalPurposeMap, target) => {
     // 共架種別が設定されていない場合は何もしない
     if (!order.KyogaType__c) {
       return;
     }

     const billCategory = getChargeableCategoryOfKyogaType(
         generalPurposeMap, order.KyogaType__c);
     target['ProprietyJudgeCostBillCategory__c'] = billCategory;
   }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/ProprietyList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      console.error(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  };

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり
   */
  doCheckModified = () => {
    let result = this.state.isModified;
    if (this.gridRef.current) {
      result = this.gridRef.current.isItemsModified();
    }
    if (this.state.isModified != result) {
      this.setState({isModified: result});
    }
    return result;
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 選択した電柱情報を取得
   * @param {object} dnt
   */
  setSelectedDnt = async (dnt) => {
    try {
      const {generalPurposeMap} = this.props;

      if (dnt == null || dnt.length == 0) {
        return;
      }

      // 選択した電柱情報が一覧に存在する場合、エラー
      let sourceCollection = this.gridRef.current.getSourceCollection();
      if (this.state.ybdsType == 'D') {
        // 1つ選択する場合は自分を変更できるようにチェック対象から自分を除く
        const serialNumber = this.currentCtx.item.SerialNumber__c;
        sourceCollection =
          sourceCollection.filter((data) =>
            data.SerialNumber__c != serialNumber);
      }
      let isSameDnt = false;
      for (const item of dnt) {
        if (sourceCollection.filter((data) =>
          isSameContractDnt(data, item)).length > 0) {
          isSameDnt = true;
          break;
        }
      }
      if (isSameDnt) {
        this.props.doShowMessage({
          message: {
            id: 'CE0034',
            values: ['選択した電柱'],
          },
        });
        return;
      }

      if (this.state.ybdsType == 'D') {
        // 電柱1件選択
        const selectedDnt = dnt[0];
        const editItem = this.currentCtx ? this.currentCtx.item : null;
        this.gridRef.current.editDnt(
            editItem, selectedDnt, generalPurposeMap.NWZgsyo);
      } else {
        // 電柱複数件選択
        this.gridRef.current.addDntList(dnt, generalPurposeMap.NWZgsyo);
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wjCore.Control.getControl(lbHost);
      let categoryName = 'DntCategory'; // 電柱区分
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {classes, generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      exceptFilters: ['edit', 'poleNoSelector'],
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'Both',
      counterOn: false,
      AddDeleteOn: true,
      frozenColumns: 5,
      style: {height: '500px'},
      isReadOnly: this.state.isDisabled,
      useStore: false,
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      validateEdits: false,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="poleNoSelector" header="電柱" width={50}
              cellTemplate={CellMaker.makeButton({
                text: '選択',
                cssClass: this.state.isDisabled ? 'wj-state-disabled' : 'btn_navy',
                click: (e, ctx) => {
                  // 1301_電柱選択画面起動
                  this.currentCtx = ctx;
                  this.setState({modalOpen: true, ybdsType: 'D'});
                },
                attributes: {
                  tabindex: -1,
                },
              })}
            />
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="selectedDntInfo" width={150} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="selectedDntInfo" width={150} isReadOnly={true} isRequired={true}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" cssClass="selectedDntInfo" width={100} isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="selectedDntInfo" isReadOnly={true}/>
          <FlexGridColumnGroup binding="AttachSpotHeight__c" header="取付点高さ（m）" dataType="Number" format='n1'>
            <FlexGridCellTemplate cellType="CellEdit" template={
              (ctx) => {
                return <InputNumber
                  className="flexGrid-cell-editor"
                  min={0.0}
                  max={99.9}
                  step={0.1}
                  format='n1'
                  value={ctx.value}
                  valueChanged={(inpNum) => ctx.value = inpNum.value }
                />;
              }}/>
          </FlexGridColumnGroup>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
            線路名　：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName__c') ?
                        this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
            電柱番号：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo__c') ?
                        this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <InputNumber
                id='id1007AttachSpotHeightPopup'
                format='n1'
                min={0.0}
                max={99.9}
                step={0.1}
                initialized={this.initializeEditedAttachSpotHeight.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>取付点高さ（m）</label>
            </div>
            <br/>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>
      </>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1007AddDntList" item>
          <PositiveButton
            id="id1007AddDntList"
            startIcon={<AddIcon />}
            onClick={handleSubmit(this.doAddDntList)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled}
          >
            <span>電柱追加</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1007Confirm" item>
          <PositiveButton
            id="btn1007Confirm"
            onClick={handleSubmit(this.doMoveConfirm)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode}
          >
            <span>確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1007Save" item>
          <PositiveButton
            id="btn1007Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1007Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onCheckGrid={this.doCheckModified}/>
        </Grid>
      </Grid>
    );

    return (
      <>
        <form noValidate autoComplete="off" className={classes.root}>
          <MainContainer props={this.props}
            footerBtn={footerBtn}>
            <WarningMessageArea messages={this.state.warningMessages}/>
            <Grid container
              direction="column"
              alignItems="flex-start"
            >
              <Grid key="key1007Table" item xs={12}>
                {this.renderTable()}
              </Grid>
            </Grid>
          </MainContainer>
          <SelectPoleNo
            YbdsType={this.state.ybdsType}
            modalOpen={this.state.modalOpen}
            closeButton={this.closeSelectDnt}
            setSelectedPole={this.setSelectedDnt}/>
        </form>
      </>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  contractDntList: PropTypes.array,
  referenceMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderAndContractDntList: PropTypes.func,
  doSaveContractDnts: PropTypes.func,
  doClearOrderContractDnt: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    orderId: state.attachorder.orderId,
    order: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
      state.contractDnt.orderContractDntList.order : null,
    contractDntList: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
      state.contractDnt.orderContractDntList.contractDntList : [],
    referenceMode: state.common.referenceMode == true ? true : false,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    setGmnWarning: state.common.setGmnWarning,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderAndContractDntList:
    contractDntOperations.doGetOrderAndContractDntList,
  doSaveContractDnts: contractDntOperations.doSaveContractDnts,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'TenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

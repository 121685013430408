import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddIcon from '@material-ui/icons/Add';

import * as wjgGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {Popup, ComboBox, InputDate} from '@grapecity/wijmo.react.input';
import * as wijmoInput from '@grapecity/wijmo.input';

import {getCodeFromGeneralPurposeMap, getErrorCode,
  API_CODE, getComboList, getComboName, getToDate,
  changeDateFormat, isSameContractDnt, dntNoFormat, senroNameFormat,
  changeDateUtc, checkMainDntNoNWZgsyo, checkMainDntNoInContractDnts,
  checkPhotoExists, getResponseResult, createDataMapByComboList,
} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {AttachedPicturesSenCorrectTkyoAto, TkyoKbnCorrect} from '../../../../../molecules/HelpComponent.js';
import CommonTheme from '../../../../../../Theme.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import {orderDntFields} from '../../../../../../common/SFFields';
import {dntOperations} from '../../../../../../reducks/dnt/index.js';
import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import withMediaQuery from '../../../../../atoms/withMediaQuery.js';
import HelpIcon from '@material-ui/icons/Help';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

/**
 * 登録、更新の共通項目
 */
const commonParams = {
  // 一覧項目
  SenroName__c: null, // 線路名
  SenroCode__c: null, // 線路コード
  DntNo__c: null, // 電柱番号
  DntCategory__c: null, // 電柱区分
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  TekyoCategory__c: null, // 撤去区分(編集可)
  TekyoSyunkoDate__c: null, // 撤去竣工日(編集可)
  OtherTokkiZiko__c: null, // その他特記事項(編集可)
  SyunkoReportAcceptedDate__c: null, // 竣工届受理日
  // 表示しない項目
  SenroNameKana__c: null, // 線路名カナ
  SerialNumber__c: null, // 通し番号
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  KyogaCategory__c: null, // 共架区分
  KyogaType__c: null, // 共架種別
  ContractDnt__c: null, // 親契約電柱
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  DntNoManualInput__c: null, // 電柱番号_自由入力
  UpsyaUserId__c: null,
  KyogaPriceCategory__c: null, // 共架料区分
  IskkCategory__c: null, // 一束化区分
  IskkAiteZgsya1__c: null, // 一束化相手先事業者1
  IskkAiteZgsya2__c: null, // 一束化相手先事業者2
  IskkAiteZgsya3__c: null, // 一束化相手先事業者3
};

/**
 *  登録時
 */
const addParams = {
  // 表示しない項目
  Order__c: null, // 申込のId 更新しない
  KyogaZgsya__c: null, // 共架事業者(AccountのId) 更新しない
};

/**
 *  更新時
 */
const updateParams = {
  // 表示しない項目
  Id: null,
};

// 登録、更新項目を作成
Object.assign(addParams, commonParams);
Object.assign(updateParams, commonParams);

/**
 * 契約相違撤去線設備入力（契約電柱）
 * 画面ID:1613
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      modalOpen: false,
      ybdsType: 'E', // 電柱検索画面 呼び出し種別 E(単一選択) or EM(複数選択)
      editItem: null,
      stbType: null,
      kyogaType: null,
      isKeikanDisabled: false, // 径間ボタンDisabled
      warningMessages: [],
    };

    this.currentCtx = null;
    this.popup = null;
    this.gridRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.dateEditor = new wijmoInput.InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // グリッド項目初期化
    this.items = {};
    for (const key of orderDntFields) {
      this.items[key] = '';
    }
    this.items['TekyoSyunkoDate__c'] = '';
    this.items['SyunkoReportAcceptedDate__c'] = '';

    // 編集ダイアログ用
    this.popupTekyoCategory = null; // 撤去区分
    this.popupTekyoSyunkoDate = null; // 撤去竣工日
    this.popupOtherTokkiZiko = null; // その他特記事項

    // 添付写真 施工後
    this.headerAttachBeforePicturesTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <AttachedPicturesSenCorrectTkyoAto/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 撤去区分
    this.headerRemovalKbnTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <TkyoKbnCorrect/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      // 電柱番号
      if (prop == 'Dsp_DntNo__c') {
        let str = '';
        if (item[prop] != '未設定') {
          str = item[prop];
        }
        let error = validateRule.required4(str);
        if (error) {
          return error;
        }
      }
      // 撤去種別と撤去竣工日
      if (['TekyoCategory__c', 'TekyoSyunkoDate__c'].includes(prop)) {
        let error = validateRule.required4(
            item[prop]);
        if (error) {
          return error;
        }
      }

      // その他特記事項
      if (prop == 'OtherTokkiZiko__c') {
      // 全角チェック
        let error = validateRule.fullWidth(
            item.OtherTokkiZiko__c);
        if (error) {
          return error;
        }
      }

      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {generalPurposeMap} = this.props;
      // 申込区分：作成中
      const orderStatusCreate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

      // 申込IDに紐づく撤去電柱を取得する
      const ret = await this.searchContractDnt();
      if (!ret) {
        return;
      }

      this.items['TekyoCategory__c'] =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'TekyoType1', 1);

      this.setState({
        isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                    orderStatusCreate ? false : true), // 作成中のみ編集可
        isModified: false,
        stbType: this.props.order ? this.props.order.StbType__c : null,
        kyogaType: this.props.order ? this.props.order.KyogaType__c : null,
      });

      console.log(this.state);
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込電柱検索
   * @return {boolean} 処理結果
   */
  searchContractDnt = async () => {
    const {orderId, generalPurposeMap} = this.props;
    // 申込IDに紐づく撤去電柱を取得する
    const orderConditions = {
      'Id': orderId,
      // 申込区分：撤去
      'OrderCategory__c': getCodeFromGeneralPurposeMap(
          generalPurposeMap, 'OrderCategory14', 2),
      'RecordType.DeveloperName': 'ContractDifferTekyo',
    };
    // 申込の取得項目
    const orderFields = ['Id', 'LastModifiedDate', 'OrderStatus__c',
      'KyogaType__c', 'StbType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c',
      'OrderNo__c', 'OrderDate__c', 'KoziPlaceMainDntNo__c', 'MainDntNoNWZgsyo__r.Code__c'];
    const conditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'ContractDifferTekyo',
    };
    // 撤去電柱の取得項目
    const tekyoDnt = [];
    let selectFields = ['Memo__c'];
    selectFields = selectFields.concat(orderDntFields);
    Object.assign(tekyoDnt, selectFields);
    // 撤去区分
    tekyoDnt.push('TekyoCategory__c');
    // 撤去竣工日
    tekyoDnt.push('TekyoSyunkoDate__c');
    // その他特記事項
    tekyoDnt.push('OtherTokkiZiko__c');

    const appSortParams = {
      SerialNumber__c: 1,
    };
    let response =
      await this.props.doGetOrderAndContractDntList(
          orderConditions, orderFields,
          conditions, tekyoDnt, appSortParams, true);
    let errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    // グリッド初期化
    let dntList = [];
    for (const contractDnt of this.props.contractDntList) {
      contractDnt.TekyoSyunkoDate__c =
        changeDateUtc(contractDnt.TekyoSyunkoDate__c);
      contractDnt.SyunkoReportAcceptedDate__c =
         changeDateUtc(contractDnt.SyunkoReportAcceptedDate__c);
      dntList.push(contractDnt);
    }
    console.log({dntList});

    // 検索した申込情報を初期値にセット
    if (this.props.order) {
      // 申込関連
      for (const key of ['OrderNo__c', 'KyogaType__c', 'OrderKyogaZgsya__c',
        'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c', 'KyogaPriceCategory__c']) {
        this.items[key] = this.props.order[key];
      }
    }
    // 撤去竣工日
    this.items['TekyoSyunkoDate__c'] = null;
    this.items['SyunkoReportAcceptedDate__c'] = null;

    // グリッド初期化
    const cv = createCollectionView(dntList, true, this.items, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    let isWarn = false;
    let warningMessages = [];
    // 入力チェック
    this.inputCheck(warningMessages);
    // 入力チェックでエラーがあった場合
    if (warningMessages.length > 0) {
      isWarn = true;
    }
    this.setState({isModified: false, warningMessages: warningMessages});
    await this.props.doSetGmnWarning('1613', isWarn);

    return true;
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
   inputCheck = async (warningMessages) => {
     const {generalPurposeMap} = this.props;
     const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

     // 申込ステータス ≠ 作成中の場合は、入力チェックを行わない
     if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusCreate) {
       return;
     }

     // 申込電柱件数チェック
     if (this.props.contractDntList &&
        this.props.contractDntList.length == 0) {
       warningMessages.push({id: 'CE0019', values: ['申込電柱']});
       return;
     }

     // 代表電柱同一事業所チェック
     const nWZgsyoMessages = checkMainDntNoNWZgsyo(
         this.props.order, this.props.contractDntList);
     for (const message of nWZgsyoMessages) {
       warningMessages.push(message);
     }

     // 代表電柱番号存在チェック
     const mainDntNoMessages = checkMainDntNoInContractDnts(
         this.props.order, this.props.contractDntList);
     for (const message of mainDntNoMessages) {
       warningMessages.push(message);
     }

     for (const contractDnt of this.props.contractDntList) {
       // 必須チェック
       this.isRequiredItem(contractDnt, warningMessages);
       // 写真必須チェック
       const photo = this.props.photoExists[contractDnt.Id];
       checkPhotoExists(photo, contractDnt, warningMessages);
       // 桁チェック
       this.isNormalDigits(contractDnt, warningMessages);
       // 未来日チェック
       this.isNotFutureDay(this.props.order, contractDnt, warningMessages);
     }
   }


  // 竣工結果未確認の行をグレーアウトする
  cellStyleFunction = (s, e, grid) => {
    if (e.panel.cellType == wjgGrid.CellType.Cell) {
      // グリッドをスクロールすると、スタイルを再利用してしまう。wijmoの制限事項。
      // 竣工結果以外のセルのスタイルを変更しないように、
      // セルのスタイルをリセットしてから変更対象のセルを処理する。
      e.cell.style.backgroundColor = '';
      const col = s.columns[e.col];
      if ((col.binding == 'SyunkoReportAcceptedDate__c' || col.binding == 'ResubmitNecessity__c' ||
          col.binding == 'ResubmitReason__c' || col.binding == 'Memo__c') &&
          (!grid.editRange || !grid.editRange.contains(e.row, e.col))) {
        const acceptDateValue = grid.getCellData(e.row, 'SyunkoReportAcceptedDate__c');
        if (!acceptDateValue) {
          e.cell.style.backgroundColor = 'gray';
        }
      }
    }
  }

  /**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }
  /**
   *  編集ダイアログ 撤去区分
   * @param {*} ctl
   */
  initializePopupTekyoCategory(ctl) {
    this.popupTekyoCategory = ctl;
  }

  /**
   *  編集ダイアログ 撤去竣工日
   * @param {*} ctl
   */
  initializePopupTekyoSyunkoDate(ctl) {
    this.popupTekyoSyunkoDate = ctl;
  }

  /**
   *  編集ダイアログ その他特記事項
   * @param {*} ctl
   */
  initializePopupOtherTokkiZiko(ctl) {
    this.popupOtherTokkiZiko = ctl;
  }

  /**
   * ポップアップエディタ表示
   * @param {*} grid
   * @param {*} e
   */
  editPopup(grid, e) {
    // マウスの下にあるチャート領域を特定
    const ht = grid.hitTest(e);
    if (ht.panel === grid.rowHeaders) {
      // 行データ取得
      const _editItem = grid.rows[ht.row].dataItem;
      this.setState({editItem: _editItem});
      // 撤去区分の初期値
      this.popupTekyoCategory.selectedValue = _editItem.TekyoCategory__c;
      // 撤去竣工日の初期値
      this.popupTekyoSyunkoDate.value = _editItem.TekyoSyunkoDate__c;
      // その他特記事項の初期値
      this.popupOtherTokkiZiko.selectedValue = _editItem.OtherTokkiZiko__c;

      this.popup.show(true, async (s) => {
        // OKボタン押下
        if (s.dialogResult === 'wj-hide-ok') {
          (grid.collectionView).editItem(_editItem);
          // 入力値を行データにセット
          // 撤去区分
          _editItem.TekyoCategory__c = this.popupTekyoCategory.selectedValue;
          // 撤去竣工日
          _editItem.TekyoSyunkoDate__c = this.popupTekyoSyunkoDate.value;
          // その他特記事項
          _editItem.OtherTokkiZiko__c = this.popupOtherTokkiZiko.selectedValue;

          (grid.collectionView).commitEdit();
        }
        grid.focus();
      });
    }
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 選択した電柱情報を取得
   * @param {object} dnt
   */
  setSelectedDnt = async (dnt) => {
    try {
      const selectedDntList = dnt && dnt.length > 0 ? dnt : null;
      if (!selectedDntList) {
        console.log('電柱が未選択なのに電柱選択された。');
        return;
      }
      // 選択した電柱の存在チェック
      for (const selectedDnt of selectedDntList) {
        const isExist = this.isExistDnt(selectedDnt);
        if (isExist) {
          // 存在した場合
          this.props.doShowMessage({
            message: {
              id: 'CE0034',
              values: ['選択した電柱'],
            },
          });
          return;
        }
      }

      // 単一選択の場合
      if (this.state.ybdsType == 'E') {
        const selectedDnt = selectedDntList[0];

        // グリッドの選択行のデータ取得
        const editItem = this.currentCtx.item;
        const cv = this.gridRef.current.getGrid().collectionView;
        cv.beginUpdate();
        // 編集開始
        cv.editItem(editItem);
        // 選択した電柱情報でグリッド行を編集
        this.editDntItem(selectedDnt, editItem);
        // 編集終了
        cv.commitEdit();
        cv.endUpdate();

        // 複数選択の場合
      } else {
        // グリッドの行数と選択した電柱の本数の合計
        const contractDntList = this.gridRef.current.getSourceCollection();
        const count = contractDntList.length + selectedDntList.length;
        // 最大本数チェック
        if (count > 30) {
          this.props.doShowMessage({
            message: {
              id: 'CE0055',
              values: ['30'],
            },
          });
          return;
        }
        const cv = this.gridRef.current.getGrid().collectionView;
        cv.beginUpdate();
        for (const selectedDnt of selectedDntList) {
          // 新規行追加
          const editItem = cv.addNew();
          // 選択した電柱情報で新規行を編集
          this.editDntItem(selectedDnt, editItem);
        }
        // 編集終了
        cv.commitNew();
        cv.endUpdate();
      }
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 電柱情報編集処理
   * @param {*} selectedDnt 電柱選択した電柱
   * @param {*} editItem グリッドの編集前の電柱情報
   */
  editDntItem(selectedDnt, editItem) {
    const {generalPurposeMap} = this.props;
    for (const key of ['SzbtItiId__c', 'StbKobetuId__c',
      'DntCategory__c', 'DntNo__c', 'SenroCode__c',
      'SenroName__c', 'SenroNameKana__c', 'KyogaCategory__c',
      'DntNoManualInput__c', 'K6KansnNo__c', 'K6Bunk1__c',
      'K6Bunk2__c', 'K6Bunk3__c', 'K22SzbtNo__c',
      'K22GatiCategory__c', 'K6K22HeigaCategory__c', 'ContractDnt__c',
      'IskkCategory__c', 'IskkAiteZgsya1__c', 'IskkAiteZgsya2__c',
      'IskkAiteZgsya3__c', 'KyogaPriceCategory__c', 'OldNWZgsyo__c']) {
      if (Object.hasOwnProperty.call(selectedDnt, key)) {
        editItem[key] = selectedDnt[key];
      }
    }
    // 表示用電柱番号、表示用線路名を設定
    editItem['Dsp_DntNo__c'] = dntNoFormat(
        selectedDnt['SenroCode__c'], selectedDnt['DntNoManualInput__c'],
        selectedDnt['K6KansnNo__c'], selectedDnt['K6Bunk1__c'],
        selectedDnt['K6Bunk2__c'], selectedDnt['K6Bunk3__c'],
        selectedDnt['K22SzbtNo__c'], selectedDnt['K22GatiCategory__c']);
    editItem['Dsp_SenroName__c'] = senroNameFormat(selectedDnt['SenroName__c'], selectedDnt['SenroNameKana__c'], selectedDnt['SenroCode__c']);
    // 汎用マスタから中電事業所のIdを取得して一覧に設定する;
    editItem.NWZgsyo__c = null;
    if (generalPurposeMap.NWZgsyo) {
      const masterData = generalPurposeMap.NWZgsyo.filter(
          (data) => data.Code__c ==
       selectedDnt.NWZgsyo__r_Code__c);
      editItem.NWZgsyo__c =
     masterData.length > 0 ? masterData[0].Id : null;
      editItem['NWZgsyo__r'] = masterData.length > 0 ?
     masterData[0] : {Name: '', Code__c: ''};
      editItem['NWZgsyo__r.Name'] =
     masterData.length > 0 ? masterData[0].Name : null;
      editItem['NWZgsyo__r.Code__c'] =
     masterData.length > 0 ? masterData[0].Code__c : null;
    }
    // 親契約電柱
    editItem['ContractDnt__c'] = selectedDnt['RecordId'];
  }

  /**
   * 電柱存在チェック
   * @param {*} selectedDnt チェック対象となる電柱
   * @return {boolean} true:存在する, false:存在しない
   */
  isExistDnt(selectedDnt) {
    const gridItems = this.gridRef.current.getSourceCollection();
    const result = gridItems.filter((data) =>
      isSameContractDnt(data, selectedDnt));
    if (result.length > 0) {
      return true;
    }

    return false;
  }

  /**
   * 撤去種別から「一束化」と「一部撤去」を除去する
   * @return {object}
   */
  getComboTekyoCategory() {
    const {generalPurposeMap} = this.props;

    // リスト取得
    const list = getComboList(generalPurposeMap, 'TekyoType', true, {ValueString1__c: [null, '2']});

    return list;
  }

  /**
   * 写真登録画面へ遷移する前に変更有無をチェックする
   * @param {object} ctx 選択した契約電柱
   * @param {string} photoType 遷移先
   */
  checkBeforeMoveAttachedPictures = (ctx, photoType) => {
    if ((this.gridRef.current &&
    this.gridRef.current.commitItemsModified())) {
      this.props.doShowMessage({
        message: {
          id: 'CW0013',
        },
        action: () => {
          this.doMoveAppPhotoRegistration(ctx.item, photoType);
        },
      });
      return;
    }
    this.doMoveAppPhotoRegistration(ctx.item, photoType);
  }

  /**
   *  添付写真登録
   * @param {*} item
   * @param {*} photoType
   */
  doMoveAppPhotoRegistration(item, photoType) {
    // 選択した契約電柱.Idが未設定の場合、
    // ファイルと契約電柱を紐づけられないのでエラーにする
    if (!item || !item.Id) {
      this.props.doShowMessage({
        message: {
          id: 'CE0062',
          values: ['申込電柱'],
        },
      });
      return;
    }

    // 写真登録画面へ遷移
    if (photoType == '施工後') {
      this.props.doSetPhotoRegistrationInfo({
        contractDntId: item.Id, // 写真と紐づく契約電柱のIdを渡す
        transitionSrcId: '1613',
        readOnly: this.state.isDisabled,
      });
      this.props.history.push({pathname: '/completionPhotoRegistration'});
    }
  }

  /**
   * 電柱一括追加ボタン
   */
   doAddDntList = () => {
     this.setState({modalOpen: true, ybdsType: 'EM'});
   }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/CorrectTekyoOrderList'}, null, this.props.history.option.CLEAR,
    );
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      this.setState({isErrorCheck: true});
      // 入力チェック
      const contractDntList = this.gridRef.current.getSourceCollection();

      // DB登録前のチェック
      if (!this.checkValidate(contractDntList)) {
        return;
      }

      // 保存処理
      const ret = await this.upsertdel();
      if (!ret.success) {
        this.props.doShowMessage({
          message: ret.messages,
          action: async () => {
            if (ret.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
              this.gotoListGmn();
            }
          },
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 一覧再表示
          const searchResult = await this.searchContractDnt();
          if (!searchResult) {
            return;
          }
          // 申込の最終更新日を退避
          this.props.doSetOrderLastModifiedDate(
              this.props.order.LastModifiedDate);

          this.setState({isModified: false});
        },
      });
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  };

  /**
  * 必須チェック（添付写真）
  * @param {array} contractDnt チェック対象
  * @param {array} messages メッセージ格納用
  */
  isRequiredItem(contractDnt, messages) {
    // 電柱番号
    if (!contractDnt.DntNo__c &&
        !contractDnt.DntNoManualInput__c) {
      messages.push({
        id: 'CE0017',
        values: ['電柱番号(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }

    // 撤去区分
    if (!contractDnt.TekyoCategory__c) {
      messages.push({
        id: 'CE0017',
        values: ['撤去区分(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }

    // 撤去竣工日
    if (!contractDnt.TekyoSyunkoDate__c) {
      messages.push({
        id: 'CE0017',
        values: ['撤去竣工日(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
  }

  /**
  * 桁数チェック
  * @param {array} contractDnt チェック対象
  * @param {array} messages メッセージ格納用
  */
  isNormalDigits(contractDnt, messages) {
    if (!contractDnt.OtherTokkiZiko__c) {
      return;
    }
    // その他特記事項 全角20桁
    if (contractDnt.OtherTokkiZiko__c.length > 20) {
      messages.push({
        id: 'CE0041',
        values: ['その他特記事項(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
  }

  /**
  * 日付チェック
  * @param {*} order 申込情報
  * @param {array} contractDnt チェック対象
  * @param {array} messages メッセージ格納用
  */
  isNotFutureDay(order, contractDnt, messages) {
    if (!contractDnt.TekyoSyunkoDate__c) {
      return;
    }
    let day = null;
    let msg = null;
    // 申込日が設定されている場合
    if (order.OrderDate__c) {
      day = changeDateFormat(order.OrderDate__c);
      msg = '申込日';

      // 申込日が未設定の場合
    } else {
      // 現在日
      day = getToDate('YYYY/MM/DD');
      msg = '本日';
    }

    // 撤去竣工日より未来はエラー
    if (changeDateFormat(contractDnt.TekyoSyunkoDate__c) > day) {
      messages.push({
        id: 'CE0020',
        values: ['撤去竣工日(' + contractDnt.SerialNumber__c + '行目)', msg],
      });
    }
  }

  /**
   * DB登録前のチェック
   * @param {*} contractDntList
   * @return {boolean}
   */
  checkValidate(contractDntList) {
    for (const item of contractDntList) {
      if (item.OtherTokkiZiko__c) {
        // 全角チェック
        const error = validateRule.fullWidth(item.OtherTokkiZiko__c);
        if (error) {
          this.props.doShowMessage({
            message: {
              id: 'CE0050',
            },
          });
          return false;
        }
      }
    }
    return true;
  }

  /**
   * 撤去電柱の登録/更新/削除処理
   */
  upsertdel = async () => {
    const {order} = this.props;
    const saveContractDntList = [];
    const updateConditions = [];
    let serialNumber = 0;
    // グリッドのデータを設定
    const contractDntList = [];
    Object.assign(contractDntList, this.gridRef.current.getSourceCollection());

    for (const dnt of contractDntList) {
      console.log({dnt});
      // 一覧は1から通し番号設定
      serialNumber++;

      // 登録
      if (!dnt.Id) {
        // 登録項目設定
        const insertData = {};
        for (const key in addParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            // 撤去竣工日はnullでもセットする
            if (key == 'TekyoSyunkoDate__c') {
              // nullの場合はnullを設定する
              if (!dnt[key]) {
                insertData[key] = dnt[key];
              } else {
                insertData[key] = changeDateFormat(dnt[key], 'YYYY-MM-DD');
              }
            } else {
              insertData[key] = dnt[key];
            }
          }
        }
        insertData['SerialNumber__c'] = serialNumber;
        insertData['Order__c'] = order.Id; // 申込と契約電柱紐づけ
        insertData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定
        insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
        insertData['StbType__c'] = this.state.stbType;
        saveContractDntList.push(insertData);

      // 更新
      } else {
        // 編集項目があるか
        const data = this.gridRef.current.itemsAllChanged();
        if (data.itemsEdited.length > 0) {
          // 竣工届受理日をクリア
          for (const row of data.itemsEdited) {
            if (row.Id == dnt.Id) {
              dnt['SyunkoReportAcceptedDate__c'] = null;
              break;
            }
          }
        }

        // 更新項目設定
        const updateData = {};
        for (const key in updateParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            // 日付編集項目はnullでもセットする
            if (key == 'TekyoSyunkoDate__c' || key == 'SyunkoReportAcceptedDate__c') {
              // nullの場合はnullを設定する
              if (!dnt[key]) {
                updateData[key] = dnt[key];
              } else {
                updateData[key] = changeDateFormat(dnt[key], 'YYYY-MM-DD');
              }
            } else {
              updateData[key] = dnt[key];
            }
          }
        }
        updateData['SerialNumber__c'] = serialNumber;
        saveContractDntList.push(updateData);
        // 通し番号を変更したものを更新対象にする
        updateConditions.push({
          Id: dnt.Id,
          LastModifiedDate: dnt.LastModifiedDate,
        });
      }
    }

    console.log({contractDntList});
    console.log({saveContractDntList});

    // 削除する電柱
    const deleteConditions = [];
    const data = this.gridRef.current.itemsAllChanged();
    if (data.itemsRemoved.length > 0) {
      for (const row of data.itemsRemoved) {
        if (row.Id) {
          deleteConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }

    // 一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: this.props.orderLastModifiedDate,
    };
    const updateOrder = {
      Id: order.Id,
    };

    // 保存
    const resSave = await this.props.doSaveContractDntsTekyoPrice(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(resSave, ['申込電柱', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 径間入力ボタン押下
   */
  doMoveKeikan = async () => {
    try {
      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const data = this.gridRef.current.itemsAllChanged();
      if (data.itemsAdded.length > 0 ||
          data.itemsEdited.length > 0 ||
          data.itemsRemoved.length > 0) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            // 入力チェックと画面展開
            await this.checkInputDataAndDoNext('/CorrectTekyoSenStb');
          },
        });
        return;
      } else {
        // 入力チェックと画面展開
        await this.checkInputDataAndDoNext('/CorrectTekyoSenStb');
        return;
      }
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 入力チェックと画面展開
   * @param {string} path 次画面パス
   */
  checkInputDataAndDoNext = async (path) => {
    const messages = this.state.warningMessages;

    // 警告チェック
    let hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1613')) {
        hasError = (this.props.setGmnWarning[1613] != undefined ?
              this.props.setGmnWarning[1613] : false);
      }
    }

    // 入力チェックエラー
    if (hasError) {
      // メッセージが警告だけの場合は操作可能にする
      if (messages.filter((data) => data.id.indexOf('PW') == -1).length == 0) {
        await this.props.doSetGmnWarning('1613', false);
        // 警告表示して次画面へ遷移
        this.props.doShowMessage({
          message: messages,
          action: () => {
            this.doNext(path);
          },
        });
        return;
      }
      await this.props.doSetGmnWarning('1613', true);
      const noDnt = messages.filter((data) => data.id == 'CE0019');
      if (noDnt.length > 0) {
        // 電柱0件は次画面に遷移しない
        this.props.doShowMessage({message: noDnt});
      } else {
        // 警告表示して次画面へ遷移
        this.props.doShowMessage({
          message: {id: 'CW0157', values: ['申込']},
          action: () => {
            this.doNext(path);
          },
        });
      }
      return;
    }

    // 入力チェックOK
    await this.props.doSetGmnWarning('1613', false);
    this.doNext(path);
  }
  /**
   * 次画面へ遷移
   * @param {string} path 次画面パス
   */
  doNext = async (path) => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: path});
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c' ||
        e.getColumn().binding === 'TekyoCategory__c' ||
        e.getColumn().binding === 'ResubmitNecessity__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分
      if (e.getColumn().binding === 'TekyoCategory__c') {
        categoryName = 'TekyoType'; // 撤去区分
      } else if (e.getColumn().binding === 'ResubmitNecessity__c') {
        categoryName = 'NecessityCategory'; // 再提出要否
      }
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {classes, generalPurposeMap, isMobile} = this.props;

    const props = {
      rowHeaderType: 'edit',
      filterOn: true, // 列フィルター有効
      exceptFilters: ['edit', 'poleNoSelector', 'preAttachPictures', 'beforeAttachPictures'],
      headersVisibility: 'All', // 行ヘッダ、列ヘッダの表示設定
      allowSorting: 'None',
      allowDragging: 'Both', // 行、列のD&D設定
      counterOn: false, // グリッドに設定したデータ件数表示有無を設定
      AddDeleteOn: true,
      frozenColumns: 6,
      style: {minHeight: '500px'}, // グリッドのスタイル設定
      isReadOnly: this.state.isDisabled,
      useStore: false,
      doShowMessage: this.props.doShowMessage,
      initItems: this.items,
      validateEdits: false,
      editPopupItems: this.editPopup,
      formatItemFunction: this.cellStyleFunction,
      filterChanging: this.filterChangingHandler,
    };

    // コンボボックス: 撤去区分
    const tekyoTypeList = this.getComboTekyoCategory();
    const tekyoTypeMap = createDataMapByComboList(tekyoTypeList);

    return (
      <div>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="poleNoSelector" header="既設設備" width={50}
              cellTemplate={CellMaker.makeButton({
                text: '選択',
                cssClass: this.state.isDisabled ? 'wj-state-disabled' : 'btn_navy',
                click: (e, ctx) => {
                  // 選択行の内容を保持
                  this.currentCtx = ctx;
                  this.setState({modalOpen: true, ybdsType: 'E'});
                },
                attributes: {
                  tabindex: -1,
                },
              })}
            />
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="selectedDntInfo" width={120} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="selectedDntInfo" width={95} isReadOnly={true} isRequired={true}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" cssClass="selectedDntInfo" width={65} isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>

          <FlexGridColumnGroup binding="TekyoCategory__c" header="撤去区分" dataMap={tekyoTypeMap} visible={!isMobile}>
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerRemovalKbnTemplate}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="TekyoSyunkoDate__c" header="撤去竣工日" dataType="Date"
            editor={this.dateEditor} isRequired={false} format='yyyy/MM/dd' visible={!isMobile}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.TekyoSyunkoDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="beforeAttachPictures" header="添付写真" align="center"
            cellTemplate={CellMaker.makeButton({
              text: '添付',
              cssClass: 'btn_navy',
              click: (e, ctx) => {
                this.checkBeforeMoveAttachedPictures(ctx, '施工後');
              },
              attributes: {
                tabindex: -1,
              },
            })}
          >
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerAttachBeforePicturesTemplate}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="OtherTokkiZiko__c" header="その他特記事項" dataType="String" maxLength={20} visible={!isMobile}/>
          <FlexGridColumnGroup header="竣工確認結果" align="center">
            <FlexGridColumnGroup binding="SyunkoReportAcceptedDate__c" header="竣工届受理日" isReadOnly={true} visible={!isMobile}/>
            <FlexGridColumnGroup binding="ResubmitNecessity__c" header="再提出要否" isReadOnly={true} visible={!isMobile}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'NecessityCategory', ctx.item.ResubmitNecessity__c);
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitReason__c" header="再提出理由" isReadOnly={true} visible={!isMobile}/>
            <FlexGridColumnGroup binding="Memo__c" header="メモ" isReadOnly={true} visible={!isMobile}/>
          </FlexGridColumnGroup>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
                線路名　：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName__c') ?
                        this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
                電柱番号：{this.state.editItem &&
                          Object.hasOwnProperty.call(this.state.editItem, 'DntNo__c') ?
                          this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <ComboBox
                id="popupTekyoCategory"
                initialized={this.initializePopupTekyoCategory.bind(this)}
                itemsSource={tekyoTypeList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}
              />
              <label>撤去区分</label>
            </div>
            <br/>
            <div className="wj-labeled-input">
              <span className='wj-input-label-custom'>撤去竣工日</span>
              <InputDate
                initialized={this.initializePopupTekyoSyunkoDate.bind(this)}
                id="popupTekyoSyunkoDate"
                format="d"
                isDisabled={this.state.isDisabled}
              >
              </InputDate>
            </div>
            <br/>
            <div className="wj-labeled-input">
              <ComboBox
                initialized={this.initializePopupOtherTokkiZiko.bind(this)}
                id="popupOtherTokkiZiko"
                isDisabled={this.state.isDisabled}
                maxLength={20}
              />
              <label>その他特記事項</label>
            </div>

            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)} disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>

        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="beforePictureHelpComponent">
            <AttachedPicturesSenCorrectTkyoAto/>
          </div>
          <div id="tkyoKbnHelpComponent">
            <TkyoKbnCorrect/>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            id="btnAddDntList"
            startIcon={<AddIcon />}
            onClick={handleSubmit(this.doAddDntList)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled}
          >
            <span>電柱追加</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <PositiveButton
            id="btnKeikan"
            onClick={handleSubmit(this.doMoveKeikan)}
            variant="contained"
            size="large"
            disabled={this.state.isKeikanDisabled}
          >
            <span>径間入力</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn5" item>
          <PositiveButton
            id="btnTempSaved"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn6" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onClick={this.props.doClearContractDntList}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>

        <MainContainer props={this.props} footerBtn={footerBtn}>
          {/* 警告メッセージ */}
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
        <SelectPoleNo
          YbdsType={this.state.ybdsType}
          StbType={this.state.stbType}
          KyogaType={this.state.kyogaType}
          modalOpen={this.state.modalOpen}
          closeButton={this.closeSelectDnt}
          setSelectedPole={this.setSelectedDnt}/>
      </form>
    );
  }
}

Container.propTypes = {
  doGetOrderAndContractDntList: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  doClearContractDntList: PropTypes.func,
  doSetDntInfo: PropTypes.func,
  doSetPhotoRegistrationInfo: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  handleSubmit: PropTypes.func,
  doSaveContractDntsTekyoPrice: PropTypes.func,
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  selectedDntInfo: PropTypes.object,
  orderId: PropTypes.string.isRequired,
  contractDntList: PropTypes.array.isRequired,
  photoExists: PropTypes.object,
  isMobile: PropTypes.bool,
  orderLastModifiedDate: PropTypes.string,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => {
  const orderInfo = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
      state.contractDnt.orderContractDntList.order : null;

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: orderInfo,
    contractDntList: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
      state.contractDnt.orderContractDntList.contractDntList : [],
    photoExists: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'photoExistsPerOrder') ?
      state.contractDnt.orderContractDntList.photoExistsPerOrder : {},
    selectedDntInfo: state.dnt.dntInfo,
    checkAttachOrderDntResult: state.contractDnt.checkAttachOrderDntResult,
    setGmnWarning: state.common.setGmnWarning,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderAndContractDntList:
    contractDntOperations.doGetOrderAndContractDntList,
  doClearContractDntList: contractDntOperations.doClearContractDntList,
  doSetDntInfo: dntOperations.doSetDntInfo,
  doSetPhotoRegistrationInfo: contractDntOperations.doSetPhotoRegistrationInfo,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSaveContractDntsTekyoPrice:
    contractDntOperations.doSaveContractDntsTekyoPrice,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'CorrectTekyoSenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(Container)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(withMediaQuery(
        [
          ['isMobile', (theme) => theme.breakpoints.down('xs')],
        ],
    )(Container))),
);

const CLOUD_FRONT = 'd28apip4nfsngu.cloudfront.net';

export default {
  'logger': false,
  'consolelog': true,
  'API_URL': 'https://8d0ht0cl5c.execute-api.ap-northeast-1.amazonaws.com/dev3/',
  'amplify_conf': {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'ap-northeast-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-northeast-1_vNSMkXYTH',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: 'hho96vf1bpuplh0ufca9s1s3a',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },
  },
  'timeout': 60,
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_vNSMkXYTH',
  'IdentityPoolId': '',
  'DefaultId': 'kms-dev@example.com',
  'DefaultPassword': 'KmsWebTest123',
  'MAX_DELETE_NUM': 50,
  'WIJMO_LICENSE_KEY': `${CLOUD_FRONT},779456643576556#B0IOLcGbmJCLiEjdyIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnJjbHlnaSlneCFVNVtmSCpGWzwEdDVEOaRnY6ZESGdXZxs6atJnZxcWT8EnUUZ6R4E6RixWUm34Z92Ub0dEeRdFbzN5UWR4KDV5VOx6SsVGc8lUSx8GUuNHePBjb4YFWFRENGlkZNt4NTZGal54UxJTevNFWxQGbGh6YJFUMwNDbmFFWIJ4btRmMwBFNxk4bQhWM5sSe5oHe9MjSTNzT6RWeR94MoVGVrgVe7RGUWtSaKJXMEB5L8M5bChVUsdkeZR4Kq3GWVBzLvgTWm3iTyolaytWQpdUWyI7NJdFWCREd6VHdVdmc7Q5QslzVTNGMHdkUyJVdWFEbwhTRC3CdvlVcDhXN5EEWqBHVwdWTylUUz2GRiNENVR7dFZzV9B7QXlkQUBXbrBHZhpGdQh7N4lEdShHOUVGTrVna8QUTNxGZSR5RRlVcQF7Uyl6VINzcst4ZHJkSiojITJCLiczMDRjMFFkMiojIIJCL6QTOxYjN7IjM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIyMwQjMyEDI9EjMxIjMwIjI0ICdyNkIsICdl9mL496byZGZ53Gbj9Sdn96cm9GNwlGchhjMkJiOiMXbEJCLi8LpnrJvk/IvlrKom/rgjz1gjn9gj18gjTqgjP1gjf0gjfrgjz1gjX0gjr0gj7rgjPKvvXKvv1KvvLiOiEmTDJCLiYTN5YzN5MDN6YTN4kzN7IiOiQWSiwDfst`,
  'HELP_IMAGE_URL': `https://${CLOUD_FRONT}/help-image/`,
  'FORMAT01_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F1_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E7%94%B3%E8%BE%BC%E6%9B%B8.pdf`,
  'FORMAT01_DOC_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F1_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E7%94%B3%E8%BE%BC%E6%9B%B8.docx`,
  'FORMAT02_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F2_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E3%83%BB%E4%B8%8D%E5%86%85%E8%AB%BE%E5%9B%9E%E7%AD%94%E6%9B%B8.pdf`,
  'FORMAT03_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F3_%E5%85%B1%E6%9E%B6%E3%83%BB%E5%A4%89%E6%9B%B4%E7%94%B3%E8%BE%BC%E6%9B%B8.pdf`,
  'FORMAT03_DOC_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F3_%E5%85%B1%E6%9E%B6%E3%83%BB%E5%A4%89%E6%9B%B4%E7%94%B3%E8%BE%BC%E6%9B%B8.docx`,
  'FORMAT04_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F4_%E5%85%B1%E6%9E%B6%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%81%8A%E3%82%88%E3%81%B3%E5%85%B1%E6%9E%B6%E4%BC%9D%E9%80%81%E8%B7%AF%E8%A8%AD%E5%82%99.pdf`,
  'FORMAT04_DOC_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F4_%E5%85%B1%E6%9E%B6%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%81%8A%E3%82%88%E3%81%B3%E5%85%B1%E6%9E%B6%E4%BC%9D%E9%80%81%E8%B7%AF%E8%A8%AD%E5%82%99.docx`,
  'FORMAT05_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F5_%E5%B7%A5%E4%BA%8B%E6%96%BD%E5%B7%A5%E8%80%85%E3%81%8A%E3%82%88%E3%81%B3%E4%BF%9D%E5%AE%88%E6%8B%85%E5%BD%93%E8%80%85%E3%81%AE%E5%B1%8A.pdf`,
  'FORMAT05_DOC_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F5_%E5%B7%A5%E4%BA%8B%E6%96%BD%E5%B7%A5%E8%80%85%E3%81%8A%E3%82%88%E3%81%B3%E4%BF%9D%E5%AE%88%E6%8B%85%E5%BD%93%E8%80%85%E3%81%AE%E5%B1%8A.docx`,
  'FORMAT06_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F6_%E5%85%B1%E6%9E%B6%E6%89%BF%E8%AB%BE%E3%83%BB%E4%B8%8D%E6%89%BF%E8%AB%BE%E5%9B%9E%E7%AD%94%E6%9B%B8.pdf`,
  'FORMAT07_PDF_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F7_%E5%85%B1%E6%9E%B6%E3%83%BB%E6%94%B9%E4%BF%AE%E3%83%BB%E6%92%A4%E5%8E%BB%E5%B7%A5%E4%BA%8B%20%E7%9D%80%E6%89%8B%E3%83%BB%E5%AE%8C%E4%BA%86%E5%B1%8A.pdf`,
  'FORMAT07_DOC_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F7_%E5%85%B1%E6%9E%B6%E3%83%BB%E6%94%B9%E4%BF%AE%E3%83%BB%E6%92%A4%E5%8E%BB%E5%B7%A5%E4%BA%8B%20%E7%9D%80%E6%89%8B%E3%83%BB%E5%AE%8C%E4%BA%86%E5%B1%8A.docx`,
  'FORMAT08_XLS_URL': `https://${CLOUD_FRONT}/format/%E6%A7%98%E5%BC%8F8_%E5%85%B1%E6%9E%B6%E7%94%B3%E8%BE%BC%E3%83%BB%E5%BB%83%E6%AD%A2%EF%BC%BF%E8%A3%85%E6%9F%B1%E5%9B%B3%E3%83%BB%E5%86%99%E7%9C%9F%E3%80%90%E7%94%B3%E8%AB%8B%E6%99%82%EF%BC%8F%E6%96%BD%E5%B7%A5%E5%BE%8C%E3%80%91%EF%BC%88%E6%A8%99%E6%BA%96%E6%A7%98%E5%BC%8F%EF%BC%89.xlsx`,

  // マニュアルのURLは中電NWサイトからリンクを張られるため変更しないこと
  'MANUAL_PDF_URL': `https://${CLOUD_FRONT}/manual/yupol-01.pdf`,

  // 中国電力ネットワーク契約用マニュアル
  'MANUAL_CN_PDF_URL': `https://${CLOUD_FRONT}/manual/yupol-02.pdf`,

  'KYOUGA_YAKKAN_URL': '#',
  'CONSTRUCTION_URL': '#',
  'POINTS_URL': '#',
  'INFO_HIKARI_FIBER_URL': '#',
  'TOI_URL': '#',
};

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import * as wjCore from '@grapecity/wijmo';

import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {changeDateFormat} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common/index.js';
import {authOperations} from '../../../../../../reducks/auth/index.js';
import {API_CODE} from '../../../../../../common/common';

const styles = (theme) => ({
});

/** 一覧で取得する情報の取得フィールド名 */
const searchFields = [
  'Id',
  'UserId__c',
  'LastName',
  'FirstName',
  'MainteKoziCompany__r.Name',
  'Email',
  'TantoTel__c',
  'toLabel(UserLevel__c)LevelName',
  'HakkoDate__c',
  'DeleteFlag__c',
  'HaisiDate__c',
];

/**
 * ユーザ管理（一覧） テンプレート。
 * 画面ID:1531
 */
class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableButton: true,
    };

    this.gridRef = React.createRef();
  }

  /**
   * 初期化処理。
   */
  async componentDidMount() {
    const {doShowMessage, getContactList} = this.props;

    try {
      // ユーザ情報を検索
      const result = await getContactList({}, searchFields);

      // エラーチェック
      const errorCode = result.data.body.errorCode;
      if (errorCode == API_CODE.ERROR_PERMISSION_DENIED) {
        // ユーザ取得結果が権限エラーの場合
        doShowMessage({
          message: {id: 'CE0056', values: ['ユーザ管理']},
          action: this.doMoveLogin,
        });
        return;
      } else if (errorCode != API_CODE.SUCCESS) {
        // その他のエラーの場合
        doShowMessage({
          message: 'CS0001',
          action: this.doMoveLogin,
        });
        return;
      }

      // CustomFlexGridに取得データを設定
      const contactList = result.data.body.data;
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1531', contactList);
      }
    } catch (err) {
      console.error(err);

      doShowMessage({
        message: 'CS0001',
        action: this.doMoveLogin,
      });
      return;
    }
  }

  /**
   * 画面を抜ける際の処理。
   */
  componentWillUnmount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1531', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * ログイン画面への遷移。
   */
  doMoveLogin = () => {
    this.props.history.push('/login');
  };

  /**
   * ラジオボタンチェック時の処理。
   */
  checkHandler = () => {
    if (!this.gridRef || !this.gridRef.current) {
      return;
    }

    if (this.gridRef.current.selectedItemCount() > 0) {
      this.setState({disableButton: false});
    }
  }

  /**
   * ラジオボタンの選択チェック。
   *
   * ラジオボタンが1つだけ選択されていればtrueを返却、
   * それ以外の場合はエラーメッセージを表示しfalseを返却する。
   *
   * ラジオボタンを選択しなければボタンは活性化しないため、本来エラーは発生しない。
   *
   * @private
   * @param {string} actionName 機能名。エラー時のメッセージに表示
   * @return {boolean} ラジオボタンが1つだけ選択されていればtrue
   */
  _checkSelectItem = (actionName) => {
    const selectedItemCount = this.gridRef.current.selectedItemCount();

    // ラジオボタンのチェック数が0の場合はエラー
    if (selectedItemCount == 0) {
      this.props.doShowMessage({
        message: {
          id: 'CE0023',
          values: [actionName],
        },
      });
      return false;
    }

    // ラジオボタンのチェック数が1より多い場合はエラー
    if (selectedItemCount > 1) {
      this.props.doShowMessage({
        message: 'CE0022',
      });
      return false;
    }

    return true;
  }

  /**
   * ユーザの論理削除状態チェック。
   *
   * ユーザが論理削除状態ではない場合はtrueを返却、
   * 論理削除状態の場合はエラーメッセージを表示しfalseを返却する。
   *
   * @param {object} contact ユーザ情報
   * @return {boolean} 論理削除状態ではない場合true、論理削除状態の場合false
   */
  _checkDeleteStatus = (contact) => {
    if (contact.DeleteFlag__c) {
      this.props.doShowMessage({
        message: {
          id: 'CE0144',
          values: [contact.UserId__c],
        },
      });
      return false;
    }

    return true;
  }

  /**
   * ユーザがログインユーザであるかの判定。
   *
   * @param {object} contact ユーザ情報
   * @return {boolean} ログインユーザの場合true、そうでない場合false
   */
  _isSelf = (contact) => {
    const {doShowMessage, userInfo} = this.props;
    if (contact.UserId__c == userInfo.UserId__c) {
      doShowMessage({
        message: {
          id: 'CE0057',
          values: ['自身の情報の', '削除'],
        },
      });

      return true;
    }

    return false;
  }

  /**
   * 初期パスワード再発行ボタン押下時処理。
   */
  doResetPassword = async () =>{
    // ラジオボタンのチェック数が1ではない場合エラー
    if (!this._checkSelectItem('パスワードリセット')) {
      return;
    }

    const data = this.gridRef.current.selectedItems[0].dataItem;

    // 削除済ユーザの場合エラー
    if (!this._checkDeleteStatus(data)) {
      return;
    }

    this.props.doShowMessage({
      message: {
        id: 'CC0006',
        values: [`「${data.UserId__c}」のパスワード`, '再発行'],
      },
      action: () =>{
        // パスワード再発行
        this._execResetPassword(data.UserId__c);
      },
    });
  }

  /**
   * パスワードリセット実行。
   * @private
   * @param {string} userName ユーザID
   * @return {string} 実行結果
   */
  _execResetPassword = async (userName) => {
    const {
      doLogout,
      doShowMessage,
      sendAuthenticationCode,
    } = this.props;

    const result = await sendAuthenticationCode(userName);

    switch (result) {
      // 処理成功
      case 'OK':
        doShowMessage({
          message: 'CI0126',
        });
        break;

      // リセット回数制限エラー
      case 'LIMIT_EXCEED':
        doShowMessage({
          message: 'CE0127',
        });
        break;

      // その他エラーはシステムエラー
      default:
        doShowMessage({
          message: 'CS0001',
          action: doLogout,
        });
        break;
    }
  }

  /**
   * 「削除」ボタン押下時処理
   */
  doDelete = () =>{
    // ラジオボタンのチェック数が1ではない場合エラー
    if (!this._checkSelectItem('削除')) {
      return;
    }

    const data = this.gridRef.current.selectedItems[0].dataItem;

    // 削除済ユーザの場合エラー
    if (!this._checkDeleteStatus(data)) {
      return;
    }

    // 自身の場合はエラー
    if (this._isSelf(data)) {
      return;
    }

    this.props.doShowMessage({
      message: {
        id: 'CC0008',
        values: [data.UserId__c],
      },
      action: () => {
        this._execDelete(data.Id, data.UserId__c);
      },
    });
  }

  /**
   * 削除処理の実行。
   * 実際は削除フラグをONで更新する。
   * @param {string} userId 担当者情報レコードID
   * @param {string} userName Cognitoユーザ名
   */
  _execDelete = async (userId, userName) => {
    const {doUserUpdate, doShowMessage} = this.props;

    // 対象ユーザ情報
    const conditions = {
      Id: userId,
      userId: userName,
    };

    try {
      // 削除(更新)実行
      const result = await doUserUpdate(conditions, {DeleteFlag__c: true});
      if (result === 'OK') {
        // 更新 成功
        doShowMessage({
          message: {
            id: 'CI0009',
            values: ['ユーザ情報の削除'],
          },
          action: this._updateList,
        });
      } else {
        // エラー
        doShowMessage({
          message: {
            id: 'CS0001',
          },
          action: () => this.doMoveLogin,
        });
      }
    } catch (err) {
      console.error(err);
      doShowMessage({
        message: {
          id: 'CS0001',
        },
        action: () => this.doMoveLogin,
      });
    }
  }

  /**
   * ユーザ情報一覧の更新。
   */
  _updateList = async () => {
    const {getContactList} = this.props;
    const result = await getContactList({}, searchFields);
    this.gridRef.current.setItems(result.data.body.data);
  }

  /**
   * 「更新」ボタン押下時処理
   */
  doEdit = async () => {
    // ラジオボタンのチェック数が1ではない場合エラー
    if (!this._checkSelectItem('更新')) {
      return;
    }

    const selectedItems = this.gridRef.current.selectedItems;
    this.props.setContactId(selectedItems[0].dataItem.Id);
    this.props.history.push({
      pathname: '/Other/UserManagement/UserManagement/Input',
    });
  }

  /**
   * 「新規追加」ボタン押下時処理
   */
  doCreate = async () => {
    this.props.setContactId(null);
    this.props.history.push({
      pathname: '/Other/UserManagement/UserManagement/Input',
    });
  }


  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'MultiColumn',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkHandler,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding="UserId__c" header="ユーザID" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="LastName" header="ユーザ名（姓）" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="FirstName" header="ユーザ名（名）" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="MainteKoziCompany__r.Name" header="工事保守会社名" dataType="String" isReadOnly={true} width={230}/>
          <FlexGridColumnGroup binding="Email" header="メールアドレス" dataType="String" isReadOnly={true} width={200}/>
          <FlexGridColumnGroup binding="TantoTel__c" header="電話番号" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="LevelName" header="ユーザレベル" dataType="String" isReadOnly={true} width={130}/>
          <FlexGridColumnGroup binding="HakkoDate__c" header="登録日" dataType="Date" isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(context.item.HakkoDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="DeleteFlag__c" header="削除" dataType="Boolean" isReadOnly={true} width={70}/>
          <FlexGridColumnGroup binding="HaisiDate__c" header="削除日" dataType="Date" isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(context.item.HaisiDate__c);
              }}
            />
          </FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    const column = e.getColumn().binding;

    // 削除フラグ項目
    if (column === 'DeleteFlag__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wjCore.Control.getControl(lbHost);

      // trueを"削除", falseを"－"と表示する
      lb.itemFormatter = (index) => {
        const value = lb.collectionView.items[index].value;
        return value ? '削除' : '－';
      };
    }
  }

  render() {
    const {
      handleSubmit,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn0" item>
          <DangerButton
            onClick={handleSubmit(this.doResetPassword)}
            variant="contained"
            size="large"
            type="submit"
            disabled={this.state.disableButton}
          >
            <span>パスワードリセット</span>
          </DangerButton>
        </Grid>
        <Grid key="btn1" item>
          <DangerButton
            onClick={handleSubmit(this.doDelete)}
            variant="contained"
            size="large"
            type="submit"
            disabled={this.state.disableButton}
          >
            <span>削除</span>
          </DangerButton>
        </Grid>
        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<EditIcon />}
            onClick={handleSubmit(this.doEdit)}
            variant="contained"
            size="large"
            type="submit"
            disabled={this.state.disableButton}
          >
            <span>更新</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <PositiveButton
            startIcon={<AddIcon />}
            onClick={handleSubmit(this.doCreate)}
            variant="contained"
            size="large"
            type="submit"
          >
            <span>新規追加</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn4" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  contactList: PropTypes.array,
  searchFields: PropTypes.array.isRequired,
  getContactList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  setContactId: PropTypes.func.isRequired,
  doLogout: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  doUserUpdate: PropTypes.func,
  sendAuthenticationCode: PropTypes.func,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  doShowMessage: commonOperations.doShowMessage,
  doUserUpdate: authOperations.doUserUpdateOperation,
  getContactList: commonOperations.doGetContactList,
  sendAuthenticationCode: authOperations.sendAuthenticationCodeOperation,
  setContactId: commonOperations.doSetContactId,
};

const FORM_NAME = 'UserManagementList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';

import {InputDate} from '@grapecity/wijmo.input';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import MainContainer from '../../../../organisms/MainContainer.js';
import SaveIcon from '@material-ui/icons/Save';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import BackButton from '../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../atoms/Buttons/NegativeButton.js';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import GetAppIcon from '@material-ui/icons/GetApp';
import PaperPart from '../../../../atoms/PaperPart.js';
import {changeDateFormat, execApexRestApi, API_CODE,
  getResponseResult, getDataFromGeneralPurposeMap} from '../../../../../common/common.js';
import {renderTextField, renderSelect} from '../../../../atoms/CustomPart.js';
import {changePrefectures, createSearchList, createSearchListEx} from '../../../../../common/ComboParts.js';

import CustomFlexGrid, {createCollectionView} from '../../../../molecules/CustomFlexGrid.js';
import {commonOperations} from '../../../../../reducks/common/index.js';
import {attachorderOperations} from '../../../../../reducks/attachorder';
import * as validateRule from '../../../../validateRule.js';

import KyogaCompanyInformation from '../../../../molecules/KyogaCompanyInformation.js';
import Moment from 'moment';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

/**
 * 受領／予定日登録状況
 */
const receiveStatusList = [
  {'Code__c': '2', 'Name': '未受領'},
  {'Code__c': '3', 'Name': '受領済'},
  {'Code__c': '0', 'Name': '工事日未登録'},
  {'Code__c': '1', 'Name': '工事日登録済'},
];

const appFields=[
  'Id',
  'OrderNo__c', // 改修依頼番号
  'NWZgsyo__r.Name', // 事業所
  'toLabel(KoziPlacePrefectures__c)', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'KoziPlacePlace__c', // 場所
  'toLabel(KyogaType__c)', // 共架種別の名称
  'toLabel(StbType__c)', // 設備種別の名称
  'KoziTitle__c', // 工事件名
  'OrderDate__c', // 申込受付日
  'KyogaKoziScheduleDate__c', // 共架工事予定日
  'AttentionZikoCheckDate__c', // 留意事項確認日
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroName__c', // 工事場所_線路名
  'Dsp_KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'StartNotificationDate__c', // 共架開始通知日
  'OrderStatus__c', // 申込ステータス
  'toLabel(OrderStatus__c)OrderStatus',
  'LastModifiedDate',
];
const appSortParams={StartNotificationDate__c: -1};

// 開始通知一覧で使用する申込ステータスコード
const StartNoticeOrderStatus = ['40', '41'];

/**
 * 共架開始通知一覧(改修依頼)
 * 画面ID:1485
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.selector = null;
    this.state = {
      retButtonDisabled: true,
      saveButtonDisabled: true,
    };
    this.gridRef = React.createRef();

    this.dateEditor = new InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // グリッド初期化
      this.gridRef.current.setScreenState('1485');
      const cv = createCollectionView([], false, {}, null);
      this.gridRef.current.getGrid().itemsSource = cv;
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doSetYbdsPath(null);
    this.props.doSetOrderNo(null);
    this.props.doClearOrderList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1485', 'Id');
    }
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('orderList') &&
      this.props.orderList ? this.props.orderList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let retButtonDisabled = true;
      let saveButtonDisabled = true;
      if (this.gridRef && this.gridRef.current) {
        const selectedItems = this.gridRef.current.selectedItems;
        retButtonDisabled = selectedItems.length != 1;
        saveButtonDisabled = selectedItems.length <= 0;
      }
      this.setState({
        retButtonDisabled: retButtonDisabled,
        saveButtonDisabled: saveButtonDisabled,
      });
    } catch (error) {
      this.setState({
        retButtonDisabled: true,
        saveButtonDisabled: true,
      });
    }
  }

  /**
   * 検索ボタン押下時
   * @param {boolean} isClear 選択行をクリアするどうか
   */
  searchOrder = async (isClear=true) =>{
    try {
      const wkData = JSON.parse(JSON.stringify(this.props.values));

      wkData['OrderCategory__c']= '05', // 改修依頼
      wkData['OrderStatus__c'] = StartNoticeOrderStatus; // 申込ステータス
      wkData['RecordType.DeveloperName']= 'RepairIrai'; // レコードタイプ

      // 事業所
      if ('NWZgsyo__r_Code__c' in wkData) {
        wkData['NWZgsyo__r.Code__c']=wkData.NWZgsyo__r_Code__c;
        delete wkData.NWZgsyo__r_Code__c;
      }

      // 受領／予定日登録状況
      if ('kyogaConstructionScheduledDate' in wkData) {
        if (wkData['kyogaConstructionScheduledDate'] == '0') {
          // 工事日未登録
          wkData['KyogaKoziScheduleDate__c_EQ']=null;
        } else if (wkData['kyogaConstructionScheduledDate'] == '1') {
          // 工事日登録済
          wkData['KyogaKoziScheduleDate__c_NE']=null;
        } else if (wkData['kyogaConstructionScheduledDate'] == '2') {
          // 未受領
          wkData['StartNotificationReceiveDate__c_EQ']=null;
        } else if (wkData['kyogaConstructionScheduledDate'] == '3') {
          // 受領済
          wkData['StartNotificationReceiveDate__c_NE']=null;
        }
        delete wkData.kyogaConstructionScheduledDate;
      }
      await this.props.doGetOrderList(wkData, appFields, appSortParams, []);
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
          this.props.orderList.length <= 0 ) {
        if (!(isClear==false)) {
          this.props.doShowMessage({
            message: 'CE0016',
          });
        }
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }
      // グリッド初期化
      this.gridRef.current.saveScreenState('1485', 'Id', !(isClear==false));
      const list = this.convertList(this.props.orderList);
      const cv = createCollectionView(list, false, {}, null);
      this.gridRef.current.getGrid().itemsSource = cv;
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['改修依頼情報', '検索'],
        }});
    }
  }

  /**
   * 柱上作業時の留意事項ボタン押下時
   */
  downloadAttentionZiko = async () =>{
    try {
      const {userInfo, generalPurposeMap} = this.props;
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }
      const item = selectedItems[0];
      const idx = parseStatusOrderToRepairirai(item.dataItem.OrderStatus__c);
      const intRepairIraiStatus = parseInt( (idx != null ? generalPurposeMap.RepairIraiStatus[idx].Code__c : ''));
      if (isNaN(intRepairIraiStatus) || intRepairIraiStatus < 4) {
        const value = generalPurposeMap.RepairIraiStatus.filter((item)=>item.Code__c=='4');
        const msg = value ? value.length>0 ? value[0].Name : '' : '';
        this.props.doShowMessage({
          message: {
            id: 'CE0102',
            values: ['改修依頼', msg],
          },
        });
        return;
      }

      const targetId = item._data.Id;
      const targetOrderNo = item.dataItem.OrderNo__c;

      const ret = await createTyohyoPdf(userInfo, 'OnDntWorkNotice', {'OrderNo__c': targetOrderNo}, '柱上作業時の留意事項.pdf');
      if (!ret) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['柱上作業時の留意事項', 'ダウンロード'],
          },
        });
      } else {
        const conditions = {
          'Id': targetId,
          'LastModifiedDate': item._data.LastModifiedDate,
        };
        const fields = {};
        const body={
          'conditions': conditions,
          'fields': fields,
        };
        await execApexRestApi( userInfo, 'ApiKyogaStartNotification/AttentionZikoCheckDateUpdate', body);

        // 更新後再検索
        await this.searchOrder(false);

        // 検索前に選択していた列を再選択
        const grid= this.gridRef.current.getGrid();
        if (!grid) return;

        for (const row of grid.rows) {
          const dataItem = row.dataItem;
          if (item && Object.hasOwnProperty.call(dataItem, 'Id') &&
            dataItem['Id'] == targetId) {
            row.isSelected = true;
            this.gridRef.current.onItemChecked();
            return;
          }
        }
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 受領/登録ボタン押下時
   */
  doSave = async () => {
    try {
      const {userInfo} = this.props;
      const selectedItems = this.gridRef.current.selectedItems;
      console.log(selectedItems);
      for (let irow=0; irow<selectedItems.length; irow++) {
        // 共架工事予定日必須チェック
        let wkKyogaKoziScheduleDate = changeDateFormat(
            selectedItems[irow]._data.KyogaKoziScheduleDate__c);
        if (isNullOrWhiteSpace(wkKyogaKoziScheduleDate)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0017',
              values: ['共架工事予定日'],
            },
          });
          return;
        }
        // 共架工事予定日範囲チェック
        let wkStartNotification = changeDateFormat(
            selectedItems[irow]._data.StartNotificationDate__c);
        if (!isNullOrWhiteSpace(wkKyogaKoziScheduleDate) &&
            !isNullOrWhiteSpace(wkStartNotification) &&
            (wkKyogaKoziScheduleDate < wkStartNotification ||
             wkKyogaKoziScheduleDate >
              new Moment(wkStartNotification, 'YYYY/MM/DD').add(6, 'months').add(-1, 'days').format('YYYY/MM/DD'))) {
          this.props.doShowMessage({
            message: {
              id: 'CE0026',
              values: ['共架工事予定日', '着工可能通知日から６か月以内'],
            },
          });
          return;
        }
      }

      const orders = selectedItems.map((item)=>item._data);
      for (const order of orders) {
        order.KyogaKoziScheduleDate__c =
          changeDateFormat(order.KyogaKoziScheduleDate__c, 'YYYY-MM-DD');
      }
      const fields = {};
      const body = {orders, fields};

      const ret = await execApexRestApi( userInfo, 'ApiKyogaStartNotification/KyogaKoziScheduleDateRegister', body);
      const resResult = getResponseResult(ret, ['共架工事予定日', '保存']);
      if (resResult.errorCode == API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CI0009',
            values: ['共架工事予定日の保存'],
          },
          action: async () => {
            // 更新後再検索
            await this.searchOrder(false);
          },
        });
      } else {
        this.props.doShowMessage({
          message: resResult.messages,
        });
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込内容確認
   */
  confirmOrderContent = async () => {
    try {
      const {doSetOrderId, history} = this.props;
      if (this.gridRef.current.selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }
      const targetOrder = this.gridRef.current.selectedItems[0].dataItem;
      await doSetOrderId(targetOrder.Id);
      history.push({pathname: '/RepairIrai/ConfirmIraiContent/Confirm'});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 日付をyyyy/MM/ddに変換する
   * @param {array} orderList 申込一覧
   * @return {array} 日付を変換した申込一覧
   */
  convertList(orderList) {
    const list = [];
    for (const data of orderList) {
      let copyData = {};
      Object.assign(copyData, data);
      if (!isNullOrWhiteSpace(
          copyData.KyogaKoziScheduleDate__c)) {
        copyData.KyogaKoziScheduleDate__c =
            changeDateFormat(copyData.KyogaKoziScheduleDate__c);
      }
      if (!isNullOrWhiteSpace(copyData.StartNotificationDate__c)) {
        copyData.StartNotificationDate__c =
          changeDateFormat(copyData.StartNotificationDate__c);
      }
      list.push(copyData);
    }
    return list;
  }

  /**
   * 一覧表示
   * @return {object}
  */
  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      useStore: false,
      style: {height: '300px'},
      checkedFunction: this.checkStateUpdate,
    };
    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding="OrderNo__c" header="改修依頼番号" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup binding="StbType__c" header="設備種別" dataType="String" cssClass="uneditableCell" isReadOnly= {true} width={100}/>
          <FlexGridColumnGroup binding="KyogaType__c" header="共架種別" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          <FlexGridColumnGroup header="工事場所" align="center">
            <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
            <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
            <FlexGridColumnGroup binding="KoziPlacePrefectures__c" header="都道府県" dataType="String" cssClass="uneditableCell" isReadOnly= {true} width={100}/>
            <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
            <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String" cssClass="uneditableCell" isReadOnly= {true} />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="KyogaKoziScheduleDate__c" header="共架工事予定日" dataType="Date" isReadOnly= {false}
            editor= {this.dateEditor}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="StartNotificationDate__c" header="着工可能\n通知日" dataType="Date" cssClass="uneditableCell" isReadOnly= {true} width={110}/>
          <FlexGridColumnGroup binding="OrderStatus" header="申込ステータス" dataType="String" cssClass="uneditableCell" width={150} isReadOnly= {true}/>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {classes, generalPurposeMap, userInfo,
      handleSubmit, reset, values} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<GetAppIcon />}
            onClick={() => {
              this.downloadAttentionZiko();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
            hidden
          >
            <span>柱上作業時の留意事項</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.saveButtonDisabled}
          >
            <span>受領／登録</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn3" item>
          <PositiveButton
            startIcon={<ListAltIcon />}
            onClick={handleSubmit(this.confirmOrderContent)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>改修依頼内容確認</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn4" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <KyogaCompanyInformation companyName={userInfo.Account.Name} />

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={3}>
                <Field
                  name="OrderNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="改修依頼番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key1-2" item xs={12} sm={3}>
                <Field
                  name="NWZgsyo__r_Code__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="事業所"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchListEx(generalPurposeMap.NWZgsyo, 'Code__c', 'Name', null, {Code__c: ['1001', '1065']})}
                </Field>
              </Grid>

              <Grid key="key1-3" item xs={12} sm={3}>
                <Field
                  name="StbType__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType,
                        'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={3}>
                <Field
                  name="KyogaType__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                     createSearchList(generalPurposeMap.KyogaType,
                         'Code__c',
                         'Name',
                         {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                         ValueString1__c:
                          values &&
                          Object.hasOwnProperty.call(values, 'StbType__c') ?
                          [values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key1-6" item xs={12} sm={3}>
                <Field
                  name="KoziTitle__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事件名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={3}>
                <Field
                  name="kyogaConstructionScheduledDate"
                  className={classes.fields}
                  component={renderSelect}
                  label="受領／予定日登録状況"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {createSearchList(receiveStatusList, 'Code__c', 'Name', null, false)}
                </Field>
              </Grid>

              <Grid key="key1-8" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMainDntNo_SenroName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="線路名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>

              <Grid key="key1-8-2" item xs={12} sm={3}>
                <Field
                  name="Dsp_KoziPlaceMainDntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>

              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                  createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>

              <Grid key="key1-10" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="key1-11" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key2-1" item xs={12} md={6} style={{position: 'relative'}}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="OrderDate__c_From"
                    accessibilitylabel="applicationDateFrom"
                    label="改修依頼日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="OrderDate__c_To"
                    accessibilitylabel="applicationDateTo"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key2-2" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>

          </PaperPart>
          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  reset: PropTypes.func.isRequired,
  userInfo: PropTypes.object,
  orderList: PropTypes.array,
  values: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderList: PropTypes.func,
  doClearOrderList: PropTypes.func,
  doSetOrderId: PropTypes.func.isRequired,
  ybdsPath: PropTypes.string,
  orderNo: PropTypes.string,
  doSetYbdsPath: PropTypes.func,
  doSetOrderNo: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {
    kyogaConstructionScheduledDate: '2', // 未受領
  };
  if (state.attachorder.orderNo &&
    state.common.ybdsPath == '/processProgressSearch') {
    initValues['OrderNo__c'] = state.attachorder.orderNo;
    initValues['kyogaConstructionScheduledDate'] = null;
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderList: state.attachorder.orderList,
    ybdsPath: state.common.ybdsPath,
    orderNo: state.attachorder.orderNo,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doGetOrderList: attachorderOperations.doGetOrderList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doShowMessage: commonOperations.doShowMessage,
  doSetYbdsPath: commonOperations.doSetYbdsPath,
  doSetOrderNo: attachorderOperations.doSetOrderNo,
};

const FORM_NAME = 'StartNotification';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import LoginCommon, {commonStyles} from './loginCommon.js';

import Conf from '../../../config/config.js';

// 利用可能時間
const availableTime = Conf.availableTime;
const start = availableTime ? availableTime.start : null;
const end = availableTime ? availableTime.end : null;

/**
 * ログイン画面:利用可能時間 テンプレート。
 * 画面ID:3000
 */
class Container extends React.Component {
  /**
   * 描画処理。
   * @return {JSX.Element} メニュー画面の要素
   */
  render() {
    const {classes} = this.props;

    return (
      <LoginCommon>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.typographyUnavailable}>
              <Typography variant='h5'>サービスご利用時間外のお知らせ</Typography>
              <br/>
              <Typography variant='body1'>本システムはただ今ご利用頂けません。</Typography>
              <br/>
              <Typography variant='body1'>ご利用可能な時間帯は {start} ～ {end} となっております。</Typography>
              <br/>
            </div>
          </Grid>
        </Grid>
      </LoginCommon>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(commonStyles)((Container));

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Conf from '../../../config/config.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 10px 16px 10px',
  },
  card: {
    margin: '10px 20px 10px 20px',
  },

  typographyHead: {
    margin: '20px 0 0 20px',
    fontSize: '1.0em',
  },
  typography: {
    margin: '10px 0 0 16px',
    fontSize: '0.8em',
  },
});

const ExPanelSummary = withStyles((theme) => ({
  root: {
    'padding': '5px 5px 5px 10px !important',
    'height': '18px',
    'backgroundColor': '#e8f5e9',
    'minHeight': '50px',
    '&$expanded': {
      minHeight: '50px',
    },
  },
  expanded: {
    padding: '12px 0 12px 0px',
  },
  expandIcon: {
    padding: '0',
  },
}))(ExpansionPanelSummary);

const ExPanel = withStyles((theme) => ({
  expanded: {
    margin: '0',
  },
}))(ExpansionPanel);

const ExPanelDetail = withStyles((theme) => ({
  root: {
    padding: '10px',
    display: 'block',
  },
}))(ExpansionPanelDetails);


class Help extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <div>
        <Typography className={classes.typographyHead}>xxx</Typography>
        <Card className={classes.card}>
          <ExPanel>
            <ExPanelSummary expandIcon={<ExpandMoreIcon />} >
              <Typography>xxx</Typography>
            </ExPanelSummary>
            <ExPanelDetail>
              <Typography>xxx</Typography>
            </ExPanelDetail>
          </ExPanel>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Help);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';

import * as wijmo from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Popup, ComboBox, InputNumber} from '@grapecity/wijmo.react.input';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, getComboList,
  getComboName, getErrorCode, API_CODE, isSameContractDnt,
  checkMainDntNoNWZgsyo, checkMainDntNoInContractDnts,
  getResponseResult,
  createDataMapByComboList,
  getChargeableCategoryOfKyogaType} from '../../../../../../common/common.js';
import {correlationCheck} from '../../../../../../common/correlationCheck.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import CommonTheme from '../../../../../../Theme.js';
import CustomCard from '../../../../../molecules/CustomCard.js';
import {DnsnIskk, AttachType, ClosestDrStbDnt,
  TsnHksn, DrHksnHouseSideRikakKkh} from '../../../../../molecules/HelpComponent.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import * as validateRule from '../../../../../validateRule.js';
import {orderDntFields} from '../../../../../../common/SFFields';
import {renderCheckbox} from '../../../../../atoms/CustomPart.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import HelpIcon from '@material-ui/icons/Help';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SerialNumber__c: null, // 通し番号
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  KyogaCategory__c: null, // 共架区分
  KyogaType__c: null, // 共架種別
  IskkCategory__c: null, // 一束化
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  AttachType__c: null, // 取付種別
  AttachSpotHeight__c: null, // 取付点高さ(m)
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakOrder__c: null, // 最接近電力設備との離隔/離隔距離(m)/申請時
  NWKoziHopeKoziContents__c: null, // 中電NWへの工事希望/工事内容
  NWKoziHopeMemo__c: null, // 中電NWへの工事希望/メモ
  TsnHksnUmu__c: null, // 通信引込線有無
  DrHksnKiknCrossingRikak__c: null, // 電力引込線との離隔(m)/径間途中,交差部分
  DrHksnHouseSideRikakKkh__c: null, // 電力引込線との離隔確保/家屋側支持点付近
  Order__c: null, // 申込のId 更新しない
  KyogaZgsya__c: null, // 共架事業者(AccountのId) 更新しない
  TrsyaUserId__c: null, // 更新しない
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  StbType__c: null, // 設備種別
  ProprietyJudgeCostBillCategory__c: null, // 可否判定費用請求区分
};
const updateParams = {
  Id: null,
  SerialNumber__c: null, // 通し番号
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  KyogaCategory__c: null, // 共架区分
  KyogaType__c: null, // 共架種別
  IskkCategory__c: null, // 一束化
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  AttachType__c: null, // 取付種別
  AttachSpotHeight__c: null, // 取付点高さ(m)
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakOrder__c: null, // 最接近電力設備との離隔/離隔距離(m)/申請時
  NWKoziHopeKoziContents__c: null, // 中電NWへの工事希望/工事内容
  NWKoziHopeMemo__c: null, // 中電NWへの工事希望/メモ
  TsnHksnUmu__c: null, // 通信引込線有無
  DrHksnKiknCrossingRikak__c: null, // 電力引込線との離隔(m)/径間途中,交差部分
  DrHksnHouseSideRikakKkh__c: null, // 電力引込線との離隔確保/家屋側支持点付近
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  IskkAiteZgsya1__c: null, // 一束化相手先事業者1
  IskkAiteZgsya2__c: null, // 一束化相手先事業者2
  IskkAiteZgsya3__c: null, // 一束化相手先事業者3
  ProprietyJudgeCostBillCategory__c: null, // 可否判定費用請求区分
};

/**
 * 可否判定申込（申込電柱：線設備）
 * 画面ID:1004
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      isIskkAri: false,
      modalOpen: false,
      ybdsType: 'A', // A or AM
      editItem: null,
      warningMessages: [],
    };
    this.currentCtx = null;
    this.popup = null;
    this.gridRef = React.createRef();
    this.drHksnHouseSideRikakKkhRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.onFormatItemHandler = this.onFormatItemHandler.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // グリッド項目初期化
    this.items = {};
    for (const key of orderDntFields) {
      this.items[key] = '';
    }

    // 編集ダイアログ用
    this.popupItems = {
      IskkCategory__c: null, // 一束化
      AttachType__c: null, // 取付種別
      AttachSpotHeight__c: null, // 取付点高さ（m）
      ClosestDrStbName__c: null, // 設備名称
      ClosestDrStbRikakOrder__c: null, // 離隔距離（m）
      NWKoziHopeKoziContents__c: null, // 中国電力NWへの工事希望/工事内容
      NWKoziHopeMemo__c: null, // 中国電力NWへの工事希望/メモ
      TsnHksnUmu__c: null, // 通信引込線/通信引込線の有無
      DrHksnKiknCrossingRikak__c: null, // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
      DrHksnHouseSideRikakKkh__c: false, // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
    };

    // 一覧のヘッダーにヘルプボタン追加
    // 一束化
    this.headerDnsnIskkTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <DnsnIskk/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 取付種別
    this.headerAttachTypeTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <AttachType/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 最接近電力設備との離隔
    this.headerClosestDrStbDntTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <ClosestDrStbDnt/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 通信引込線
    this.headerTsnHksnTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <TsnHksn/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // ②電力引込線との離隔確保（家屋側支接点付近）
    this.headerDrHksnHouseSideRikakKkhTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <DrHksnHouseSideRikakKkh/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      // 必須チェック
      // 電柱番号
      if (prop == 'Dsp_DntNo__c') {
        let str = '';
        if (item[prop] != '未設定') {
          str = item[prop];
        }
        // CE0017
        let error = validateRule.required4(str);
        if (error) {
          return error;
        }
      }
      // 一束化,取付種別,取付点高さ（m）,最接近電力設備との離隔/設備名称,
      // 最接近電力設備との離隔/離隔距離（m）,中国電力NWへの工事希望/工事内容
      // 通信引込線/通信引込線の有無
      if (['IskkCategory__c', 'AttachType__c', 'AttachSpotHeight__c',
        'ClosestDrStbName__c', 'ClosestDrStbRikakOrder__c',
        'NWKoziHopeKoziContents__c', 'TsnHksnUmu__c'].includes(prop)) {
        // CE0017
        let error = validateRule.required4(
            item[prop]);
        if (error) {
          return error;
        }
      }
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分） 通信引込線の有無が有の場合、必須。
      if (item.TsnHksnUmu__c == '1') {
        if (['DrHksnKiknCrossingRikak__c'].includes(prop)) {
          // CE0017
          let error = validateRule.required4(
              item[prop]);
          if (error) {
            return error;
          }
        }
        // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近） 通信引込線の有無が有の場合、必須。
        if (prop == 'DrHksnHouseSideRikakKkh__c') {
          if (item.DrHksnHouseSideRikakKkh__c !== true) {
            // CE0070
            return '通信引込線の家屋側支持点付近における離隔確保にチェックがありません。';
          }
        }
      }
      // データ範囲チェック
      // 取付点高さ（m）
      if (['AttachSpotHeight__c'].includes(prop)) {
        // CE0026
        if (item[prop] < 2.5 || item[prop] > 99.9) {
          return '2.5～99.9の範囲内で入力してください';
        }
      }
      // 最接近電力設備との離隔/離隔距離（m）
      if (['ClosestDrStbRikakOrder__c'].includes(prop)) {
        // CE0026
        if (item[prop] < 0.01 || item[prop] > 9.99) {
          return '0.01～9.99の範囲内で入力してください';
        }
      }
      if (item.TsnHksnUmu__c == '1') {
        // ①電力引込線との離隔（m）（径間途中，交差部分）
        if (['DrHksnKiknCrossingRikak__c'].includes(prop)) {
          // CE0026
          if (item[prop] < 0.01 || item[prop] > 9.99) {
            return '0.01～9.99の範囲内で入力してください';
          }
        }
      }
      // 桁数チェック
      // 中国電力NWへの工事希望/メモ
      if (['NWKoziHopeMemo__c'].includes(prop)) {
        // CE0041
        if (item[prop] && item[prop].length > 20) {
          return '桁数が超過しています';
        }
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderContractDnt();
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    // 申込IDに紐づく申込電柱を取得する
    const orderConditions = {
      'Id': orderId,
      'OrderCategory__c': getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory01', 2),
      'RecordType.DeveloperName': 'ProprietyJudgeOrder',
    };
    const orderFields = ['Id', 'LastModifiedDate', 'OrderStatus__c',
      'KyogaType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c',
      'OrderNo__c', 'CheckJiko1__c', 'CheckJiko2__c', 'CheckJiko3__c',
      'MainDntNoNWZgsyo__r.Code__c', 'KoziPlaceMainDntNo__c',
      'RecordType.DeveloperName', 'StbType__c'];
    const conditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'ProprietyJudgeOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response =
    await this.props.doGetOrderAndContractDntList(
        orderConditions, orderFields,
        conditions, orderDntFields, appSortParams);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    for (const key of ['SerialNumber__c']) {
      this.items[key] = 0;
    }
    for (const key of ['AttachSpotHeight__c']) {
      this.items[key] = 0.0;
    }
    for (const key of
      ['ClosestDrStbRikakOrder__c', 'DrHksnKiknCrossingRikak__c']) {
      this.items[key] = 0.00;
    }
    if (this.props.order) {
      for (const key of ['OrderNo__c', 'KyogaType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c']) {
        this.items[key] = this.props.order[key];
      }
    }
    this.items['DrHksnHouseSideRikakKkh__c'] = false;
    this.items['NWKoziHopeKoziContents__c'] =
     getCodeFromGeneralPurposeMap(generalPurposeMap, 'DrSideKoziKinds01', 2);
    // グリッド初期化
    const cv = createCollectionView(this.props.contractDntList,
        true, this.items, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    let isWarn = false;
    if (_warningMessages.length > 0) {
      isWarn = true;
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                  orderStatusCreate ? false : true) ||
                  this.props.referenceMode, // 作成中のみ編集可
      isModified: false,
      isIskkAri: this.isIskkAri(this.props.contractDntList),
      warningMessages: _warningMessages,
    });
    await this.props.doSetGmnWarning('1004', isWarn);
    return true;
  }

  /**
   * 申込電柱の中に一束化有りの電柱が存在するか確認
   * @param {array} contractDntList 申込電柱一覧
   * @return {boolean} 一束化有無 true:一束化有り、false:一束化無し
   */
  isIskkAri = (contractDntList) => {
    const _list = contractDntList != null ? contractDntList : [];
    return _list.filter((data) => data.IskkCategory__c && data.IskkCategory__c != '1').length > 0;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'Id') ?
        data.order.Id : null;
      lastModifiedDate =
            data && Object.hasOwnProperty.call(data, 'order') &&
            Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
            data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    // 電柱0件チェック
    if (this.props.contractDntList &&
      this.props.contractDntList.length == 0) {
      warningMessages.push({id: 'CE0019', values: ['申込電柱']});
      return;
    }
    // 必須チェック/桁数チェック/データ範囲チェック
    this.checkItemSen(this.props.contractDntList, warningMessages);
    // 相関チェック
    const correlationMessages =
      correlationCheck(this.props.order, this.props.contractDntList);
    for (const message of correlationMessages) {
      warningMessages.push(message);
    }
    // 共架種別と一束化区分チェック
    this.checkIskkCategory(this.props.contractDntList,
        this.props.order.KyogaType__c, generalPurposeMap, warningMessages);

    // 契約電柱と取付種別のチェック
    // エネコムユーザはチェックしない
    if (this.props.attachTypeCheck) {
      // 取付種別が新規で契約電柱マスタに申込電柱が存在する場合、エラー
      if (this.props.attachTypeCheck.sinkiErrDnt) {
        for (const dnt of this.props.attachTypeCheck.sinkiErrDnt) {
          let dspDntNo = Object.hasOwnProperty.call(dnt, 'Dsp_DntNo__c') ?
            dnt.Dsp_DntNo__c : '';
          warningMessages.push({id: 'PE0081', values: [dspDntNo, dnt.SerialNumber__c]});
        }
      }
      // 取付種別が増架、張替で契約電柱マスタに申込電柱が存在しない場合、エラー
      if (this.props.attachTypeCheck.zougaErrDnt) {
        for (const dnt of this.props.attachTypeCheck.zougaErrDnt) {
          let dspDntNo = Object.hasOwnProperty.call(dnt, 'Dsp_DntNo__c') ?
            dnt.Dsp_DntNo__c : '';
          warningMessages.push({id: 'PE0082', values: [dspDntNo, dnt.SerialNumber__c]});
        }
      }
    }

    // 代表電柱同一事業所チェック
    const nWZgsyoMessages =
        checkMainDntNoNWZgsyo(this.props.order, this.props.contractDntList);
    for (const message of nWZgsyoMessages) {
      warningMessages.push(message);
    }
    // 代表電柱番号存在チェック
    const mainDntNoMessages =
        checkMainDntNoInContractDnts(
            this.props.order, this.props.contractDntList);
    for (const message of mainDntNoMessages) {
      warningMessages.push(message);
    }
  }

/**
 * 可否判定申込 線設備の申込電柱 必須チェック/桁数チェック/データ範囲チェック
 * @param {array} contractDntList チェック対象
 * @param {array} warningMessages エラーメッセージ格納
 */
checkItemSen = (contractDntList, warningMessages) => {
  if (!contractDntList) {
    return;
  }
  for (const contractDnt of contractDntList) {
    // 必須チェック
    // 電柱番号
    if (!contractDnt.DntNo__c &&
        !contractDnt.DntNoManualInput__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['電柱番号(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 一束化
    if (!contractDnt.IskkCategory__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['一束化(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 取付種別
    if (!contractDnt.AttachType__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['取付種別(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 取付点高さ（m）
    if (contractDnt.AttachSpotHeight__c == undefined ||
      contractDnt.AttachSpotHeight__c == null) {
      warningMessages.push({
        id: 'CE0017',
        values: ['取付点高さ（m）(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 最接近電力設備との離隔/設備名称
    if (!contractDnt.ClosestDrStbName__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['最接近電力設備との離隔/設備名称(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 最接近電力設備との離隔/離隔距離（m）
    if (contractDnt.ClosestDrStbRikakOrder__c == undefined ||
        contractDnt.ClosestDrStbRikakOrder__c == null) {
      warningMessages.push({
        id: 'CE0017',
        values: ['最接近電力設備との離隔/離隔距離（m）(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 中国電力NWへの工事希望/工事内容
    if (!contractDnt.NWKoziHopeKoziContents__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['中国電力NWへの工事希望/工事内容(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 通信引込線/通信引込線の有無
    if (!contractDnt.TsnHksnUmu__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['通信引込線/通信引込線の有無(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    if (contractDnt.TsnHksnUmu__c == '1') {
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
      if (contractDnt.DrHksnKiknCrossingRikak__c == undefined ||
          contractDnt.DrHksnKiknCrossingRikak__c == null) {
        warningMessages.push({
          id: 'CE0017',
          values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
      if (contractDnt.DrHksnHouseSideRikakKkh__c !== true) {
        warningMessages.push({
          id: 'CE0070',
          values: ['通信引込線/②電力引込線との離隔確保（家屋側支接点付近）', contractDnt.SerialNumber__c],
        });
      }
    }
    // データ範囲チェック
    // 取付点高さ（m）
    if (contractDnt.AttachSpotHeight__c != null &&
      (contractDnt.AttachSpotHeight__c < 2.5 ||
      contractDnt.AttachSpotHeight__c > 99.9)) {
      warningMessages.push({
        id: 'CE0026',
        values: ['取付点高さ（m）(' + contractDnt.SerialNumber__c + '行目)', '2.5～99.9'],
      });
    }
    // 最接近電力設備との離隔/離隔距離（m）
    if (contractDnt.ClosestDrStbRikakOrder__c != null &&
        (contractDnt.ClosestDrStbRikakOrder__c < 0.01 ||
        contractDnt.ClosestDrStbRikakOrder__c > 9.99)) {
      warningMessages.push({
        id: 'CE0026',
        values: ['最接近電力設備との離隔/離隔距離（m）(' + contractDnt.SerialNumber__c + '行目)', '0.01～9.99'],
      });
    }
    if (contractDnt.TsnHksnUmu__c == '1') {
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
      if (contractDnt.DrHksnKiknCrossingRikak__c != null &&
          (contractDnt.DrHksnKiknCrossingRikak__c < 0.01 ||
          contractDnt.DrHksnKiknCrossingRikak__c > 9.99)) {
        warningMessages.push({
          id: 'CE0026',
          values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）(' + contractDnt.SerialNumber__c + '行目)', '0.01～9.99'],
        });
      }
    }
    // 桁数チェック
    if (contractDnt.NWKoziHopeMemo__c != null &&
        contractDnt.NWKoziHopeMemo__c.length > 20) {
      warningMessages.push({
        id: 'CE0041',
        values: ['中国電力NWへの工事希望/メモ(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
  }
}

/**
 * 可否判定申込 線設備の申込電柱 申込の共架種別と申込電柱の一束化区分チェック
 * @param {array} contractDntList チェック対象
 * @param {string} kyogaType 申込の共架種別
 * @param {object} generalPurposeMap 汎用マスタ
 * @param {array} warningMessages エラーメッセージ格納
 */
checkIskkCategory = (contractDntList, kyogaType,
    generalPurposeMap, warningMessages) => {
  if (!contractDntList || !kyogaType) {
    return;
  }
  // 一束化対象の共架種別なら、一束化区分はチェックしない
  const result =
  generalPurposeMap['KyogaType']
      .filter((data) => data.ValueString2__c == '1' && data.Code__c == kyogaType);
  if (result.length > 0) {
    return;
  }
  // 一束化対象外の共架種別の場合、一束化区分をチェックする
  // 一束化区分が単独でない場合、エラー
  for (const contractDnt of contractDntList) {
    if (contractDnt.IskkCategory__c && contractDnt.IskkCategory__c != '1') {
      warningMessages.push({
        id: 'PE0221',
        values: [contractDnt.SerialNumber__c],
      });
    }
  }
}

/**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
initializeEditPopup(ctl) {
  this.popup = ctl;
}

/**
   * 編集ダイアログ 一束化
   * @param {*} ctl
   */
initializeEditedIskkCategory(ctl) {
  this.popupItems['IskkCategory__c'] = ctl;
}

/**
   * 編集ダイアログ 取付種別
   * @param {*} ctl
   */
initializeEditedAttachType(ctl) {
  this.popupItems['AttachType__c'] = ctl;
}

/**
   * 編集ダイアログ 取付点高さ
   * @param {*} ctl
   */
initializeEditedAttachSpotHeight(ctl) {
  this.popupItems['AttachSpotHeight__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 最接近電力設備との離隔/設備名称
   * @param {*} ctl
   */
initializeEditedClosestDrStbName(ctl) {
  this.popupItems['ClosestDrStbName__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 最接近電力設備との離隔/離隔距離(m)/申請時
   * @param {*} ctl
   */
initializeEditedClosestDrStbRikakOrder(ctl) {
  this.popupItems['ClosestDrStbRikakOrder__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 中電NWへの工事希望/工事内容
   * @param {*} ctl
   */
initializeEditedNWKoziHopeKoziContents(ctl) {
  this.popupItems['NWKoziHopeKoziContents__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 中電NWへの工事希望/メモ
   * @param {*} ctl
   */
initializeEditedNWKoziHopeMemo(ctl) {
  this.popupItems['NWKoziHopeMemo__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 通信引込線有無
   * @param {*} ctl
   */
initializeEditedTsnHksnUmu(ctl) {
  this.popupItems['TsnHksnUmu__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 通信引込線有無
   * @param {*} sender
   */
changeTsnHksnUmu(sender) {
  if (sender) {
    // 通信引込線の有無で以下の項目の活性非活性を変える
    // 電力引込線との離隔(m)/径間途中,交差部分, 電力引込線との離隔確保/家屋側支持点付近
    let _disabled = true;
    if (sender.selectedValue == '1') {
      _disabled = false || this.state.isDisabled;
    }
    this.popupItems['DrHksnKiknCrossingRikak__c'].isDisabled = _disabled;
    this.drHksnHouseSideRikakKkhRef.current.disabled = _disabled;
  }
}

/**
   * 編集ダイアログ
   * 電力引込線との離隔(m)/径間途中,交差部分
   * @param {*} ctl
   */
initializeEditedDrHksnKiknCrossingRikak(ctl) {
  this.popupItems['DrHksnKiknCrossingRikak__c'] = ctl;
}

/**
   * 編集ダイアログ
   * 電力引込線との離隔確保/家屋側支持点付近
   * @param {*} e
   */
changeDrHksnHouseSideRikakKkh(e) {
  if (e.target) {
    this.popupItems['DrHksnHouseSideRikakKkh__c'] = e.target.checked;
  }
}

/**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
editPopup(grid, e) {
  try {
    const ht = grid.hitTest(e);
    if (ht.panel === grid.rowHeaders) {
      let _editItem = grid.rows[ht.row].dataItem;
      this.setState({editItem: _editItem});
      // 取付点高さ（m）,最接近電力設備との離隔/離隔距離（m）,
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
      for (const key of
        ['AttachSpotHeight__c', 'ClosestDrStbRikakOrder__c', 'DrHksnKiknCrossingRikak__c']) {
        if (Object.hasOwnProperty.call(_editItem, key)) {
          this.popupItems[key].value =
            _editItem[key] === null ? 0 : _editItem[key];
        }
      }
      // 一束化, 取付種別,
      // 最接近電力設備との離隔/設備名称, 中国電力NWへの工事希望/工事内容
      // 中国電力NWへの工事希望/メモ, 通信引込線/通信引込線の有無
      for (const key of ['IskkCategory__c', 'AttachType__c',
        'ClosestDrStbName__c', 'NWKoziHopeKoziContents__c',
        'NWKoziHopeMemo__c', 'TsnHksnUmu__c']) {
        if (Object.hasOwnProperty.call(_editItem, key)) {
          this.popupItems[key].selectedValue = _editItem[key];
        }
      }
      // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
      this.popupItems['DrHksnHouseSideRikakKkh__c'] =
          _editItem.DrHksnHouseSideRikakKkh__c;
      this.drHksnHouseSideRikakKkhRef.current.checked =
        _editItem.DrHksnHouseSideRikakKkh__c;

      this.popup.show(true, async (s) => {
        if (s.dialogResult === 'wj-hide-ok') {
          (grid.collectionView).editItem(_editItem);
          // 取付点高さ（m）,最接近電力設備との離隔/離隔距離（m）,
          // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
          for (const key of
            ['AttachSpotHeight__c', 'ClosestDrStbRikakOrder__c', 'DrHksnKiknCrossingRikak__c']) {
            if (Object.hasOwnProperty.call(_editItem, key)) {
              _editItem[key] = this.popupItems[key].value;
            }
          }
          // 一束化, 取付種別,
          // 最接近電力設備との離隔/設備名称, 中国電力NWへの工事希望/工事内容
          // 中国電力NWへの工事希望/メモ, 通信引込線/通信引込線の有無
          for (const key of ['IskkCategory__c', 'AttachType__c',
            'ClosestDrStbName__c', 'NWKoziHopeKoziContents__c',
            'NWKoziHopeMemo__c', 'TsnHksnUmu__c']) {
            if (Object.hasOwnProperty.call(_editItem, key)) {
              _editItem[key] = this.popupItems[key].selectedValue;
            }
          }
          // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
          _editItem.DrHksnHouseSideRikakKkh__c =
            this.popupItems['DrHksnHouseSideRikakKkh__c'];
          (grid.collectionView).commitEdit();
        }
        grid.focus();
      });
    }
  } catch (error) {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }
}

  /**
   * 電柱一括追加ボタン
   */
  doAddDntList = () => {
    this.setState({modalOpen: true, ybdsType: 'AM'});
  }

  /**
   * 次画面へ遷移するボタン押下
   */
  doMoveNext = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
          this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }
      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1004')) {
        _hasError = this.props.setGmnWarning['1004'];
      }
    }
    // 入力チェックエラー
    if (_hasError) {
      const noDnt =
          this.state.warningMessages.filter((data) => data.id == 'CE0019');
      if (noDnt.length > 0) {
        // 電柱0件は次画面に遷移しない
        this.props.doShowMessage({message: noDnt});
        return;
      }

      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   * 一束化有りの場合、一束化明細画面、一束化無しの場合、径間入力画面へ遷移する
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.doSetTransitionSrcId('1004');
    const _nextUrl = this.state.isIskkAri ? '/IskkDetail' : '/ProprietySenStb';
    this.props.history.push({pathname: _nextUrl});
  }

  /**
   * 画面上で非活性になっている項目のデータを保存しないため、
   * 該当データをクリアする
   * @param {object} item 登録/更新データ
   */
  clearDisabledItem = (item) => {
    // 通信引込線の有無が無の場合、通信引込線の1～4は空
    if (item['TsnHksnUmu__c'] != '1') {
      item['DrHksnKiknCrossingRikak__c'] = 0;
      item['DrHksnHouseSideRikakKkh__c'] = false;
    }
  }

  /**
   * 一束化相手先クリア
   * 一束化区分がNULL、一束化区分が空文字、または一束化区分が単独の場合、
   * 一束化相手先をクリアする
   * 一束化区分が2者一束化の場合、一束化相手先2、3をクリア
   * 一束化区分が3者一束化の場合、一束化相手先3をクリア
   * @param {object} item 更新データ
   */
  clearIskkAitesaki = (item) => {
    if (item['IskkCategory__c'] == null || item['IskkCategory__c'] == '' ||
      item['IskkCategory__c'] == '1') {
      item['IskkAiteZgsya1__c'] = null;
      item['IskkAiteZgsya2__c'] = null;
      item['IskkAiteZgsya3__c'] = null;
    }
    if (item['IskkCategory__c'] == '2') {
      item['IskkAiteZgsya2__c'] = null;
      item['IskkAiteZgsya3__c'] = null;
    }
    if (item['IskkCategory__c'] == '3') {
      item['IskkAiteZgsya3__c'] = null;
    }
  }

  /**
   * 申込電柱の登録/更新処理
   */
   insertOrUpdate = async () => {
     const {
       order,
       values,
       orderModifiedCondition,
       generalPurposeMap,
     } = this.props;

     const contractDntList = [];
     Object.assign(contractDntList, this.gridRef.current.getSourceCollection());
     const saveContractDntList = [];
     const updateConditions = [];
     let serialNumber = 0;
     // Dsp_DntNo__c,Dsp_SenroName__cはSF属性が数式なので保存しなくてよい
     for (const dnt of contractDntList) {
       // 一覧は1から通し番号設定
       serialNumber++;
       if (!dnt.Id) {
         // 登録項目設定
         const insertData = {};
         for (const key in addParams) {
           if (Object.hasOwnProperty.call(dnt, key)) {
             if (['TsnHksnUmu__c'].indexOf(key) > -1) {
               insertData[key] = dnt[key] != null && dnt[key] != '' ? dnt[key] : '0';
             } else {
               insertData[key] = dnt[key];
             }
           }
         }
         insertData['SerialNumber__c'] = serialNumber;
         insertData['Order__c'] = order.Id; // 申込と契約電柱紐づけ
         insertData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定
         insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
         insertData['StbType__c'] = order.StbType__c;

         // 可否判定費用請求区分を設定
         this._setBillCategory(order, generalPurposeMap, insertData);

         this.clearDisabledItem(insertData);
         saveContractDntList.push(insertData);
       } else {
         // 更新項目設定
         const updateData = {};
         for (const key in updateParams) {
           if (Object.hasOwnProperty.call(dnt, key) ) {
             if (['TsnHksnUmu__c'].indexOf(key) > -1) {
               updateData[key] = dnt[key] != null && dnt[key] != '' ? dnt[key] : '0';
             } else {
               updateData[key] = dnt[key];
             }
           }
         }
         updateData['SerialNumber__c'] = serialNumber;
         updateData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定

         // 可否判定費用請求区分を設定
         this._setBillCategory(order, generalPurposeMap, updateData);

         this.clearDisabledItem(updateData);
         this.clearIskkAitesaki(updateData);
         saveContractDntList.push(updateData);
         // 通し番号を変更したものを更新対象にする
         updateConditions.push({
           Id: dnt.Id,
           LastModifiedDate: dnt.LastModifiedDate,
         });
       }
     }

     // 削除
     const deleteConditions = [];
     const data = this.gridRef.current.itemsAllChanged();
     if (data.itemsRemoved.length > 0) {
       for (const row of data.itemsRemoved) {
         if (row.Id) {
           deleteConditions.push({
             Id: row.Id,
             LastModifiedDate: row.LastModifiedDate,
           });
         }
       }
     }
     // 確認事項1～3、一時保存日更新用
     // 一時保存日はサーバー側で設定する
     const orderConditions = {
       Id: order.Id,
       LastModifiedDate: orderModifiedCondition != null ?
          orderModifiedCondition.LastModifiedDate : null,
     };
     const updateOrder = {
       Id: order.Id,
       CheckJiko1__c: values['CheckJiko1__c'],
       CheckJiko2__c: values['CheckJiko2__c'],
       CheckJiko3__c: values['CheckJiko3__c'],
     };

     const resSave = await this.props.doSaveContractDnts(
         updateConditions, deleteConditions, saveContractDntList,
         orderConditions, updateOrder);
     const resResult = getResponseResult(resSave, ['申込電柱', '保存']);
     if (resResult.errorCode != API_CODE.SUCCESS) {
       return resResult;
     }

     if (this.gridRef) {
       this.gridRef.current.clearChanges();
     }
     return {success: true, messages: []};
   }

   /**
    * 可否判定費用請求区分の設定。
    *
    * @param {object} order 申込
    * @param {object} generalPurposeMap 汎用マスタ
    * @param {object} target 設定対象データ
    */
   _setBillCategory = (order, generalPurposeMap, target) => {
     // 共架種別が設定されていない場合は何もしない
     if (!order.KyogaType__c) {
       return;
     }

     const billCategory = getChargeableCategoryOfKyogaType(
         generalPurposeMap, order.KyogaType__c);
     target['ProprietyJudgeCostBillCategory__c'] = billCategory;
   }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/ProprietyList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      console.error(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  };

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり
   */
  doCheckModified = () => {
    let result = this.state.isModified;
    if (this.gridRef.current) {
      result = this.gridRef.current.isItemsModified();
    }
    if (this.state.isModified != result) {
      this.setState({isModified: result});
    }
    return result;
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 選択した電柱情報を取得
   * @param {object} dnt
   */
   setSelectedDnt = async (dnt) => {
     try {
       const {generalPurposeMap} = this.props;

       if (dnt == null || dnt.length == 0) {
         return;
       }

       // 選択した電柱情報が一覧に存在する場合、エラー
       let sourceCollection = this.gridRef.current.getSourceCollection();
       if (this.state.ybdsType == 'A') {
         // 1つ選択する場合は自分を変更できるようにチェック対象から自分を除く
         const serialNumber = this.currentCtx.item.SerialNumber__c;
         sourceCollection =
          sourceCollection.filter((data) =>
            data.SerialNumber__c != serialNumber);
       }
       let isSameDnt = false;
       for (const item of dnt) {
         if (sourceCollection.filter((data) =>
           isSameContractDnt(data, item)).length > 0) {
           isSameDnt = true;
           break;
         }
       }
       if (isSameDnt) {
         this.props.doShowMessage({
           message: {
             id: 'CE0034',
             values: ['選択した電柱'],
           },
         });
         return;
       }

       if (this.state.ybdsType == 'A') {
         // 電柱1件選択
         const selectedDnt = dnt[0];
         const editItem = this.currentCtx ? this.currentCtx.item : null;
         this.gridRef.current.editDnt(
             editItem, selectedDnt, generalPurposeMap.NWZgsyo, true);
       } else {
         // 電柱複数件選択
         this.gridRef.current.addDntList(dnt, generalPurposeMap.NWZgsyo);
       }
     } catch (error) {
       this.props.doShowMessage({
         message: 'CS0001',
         action: () => {
           this.props.history.push('/login');
         },
       });
     }
   }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    if (s.cells === e.panel &&
        e.panel.cellType == wjgGrid.CellType.Cell) {
      const col = e.getColumn();
      // 通信引込線の1～2
      if ((col.name === 'DrHksnKiknCrossingRikak' ||
          col.name === 'DrHksnHouseSideRikakKkh') && s.rows[e.row]) {
        const data = s.rows[e.row].dataItem;
        // 通信引込線の有無が有の場合、編集可。無の場合、編集不可。
        if (data.TsnHksnUmu__c != '1') {
          wijmo.addClass(e.cell, 'wj-state-disabled');
          wijmo.addClass(e.cell, 'uneditableCell');
        } else {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'uneditableCell');
        }
      }
      // チェックボックスにラベルをつける
      if (col.name === 'DrHksnHouseSideRikakKkh' && s.rows[e.row]) {
        let text = document.createTextNode('良');
        let checkbox = null;
        if (e.cell.firstChild) {
          checkbox = e.cell.firstChild.firstChild;
        }
        if (checkbox != null && checkbox.parentNode != null) {
          checkbox.parentNode.insertBefore(text, checkbox);
        }
      }
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c' ||
        e.getColumn().binding === 'IskkCategory__c'||
        e.getColumn().binding === 'AttachType__c' ||
        e.getColumn().binding === 'ClosestDrStbName__c' ||
        e.getColumn().binding === 'NWKoziHopeKoziContents__c' ||
        e.getColumn().binding === 'TsnHksnUmu__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分
      if (e.getColumn().binding === 'IskkCategory__c') {
        categoryName = 'IskkCategory'; // 一束化
      } else if (e.getColumn().binding === 'AttachType__c') {
        categoryName = 'AttachType'; // 取付種別
      } else if (e.getColumn().binding === 'ClosestDrStbName__c') {
        categoryName = 'ClosestDrStbStbName'; // 最接近電力設備との離隔/設備名称
      } else if (e.getColumn().binding === 'TsnHksnUmu__c') {
        categoryName = 'UmuCategory'; // 通信引込線/通信引込線の有無
      } else if (e.getColumn().binding === 'NWKoziHopeKoziContents__c') {
        categoryName = 'DrSideKoziKinds'; // 中国電力NWへの工事希望/工事内容
      }

      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'DrHksnHouseSideRikakKkh__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        let result = '(なし)';
        if (lb.collectionView.items[index].value === true) {
          result = '良';
        }
        return result;
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {classes, generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      exceptFilters: ['edit', 'poleNoSelector'],
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'Both',
      counterOn: false,
      AddDeleteOn: true,
      frozenColumns: 5,
      style: {height: '500px'},
      isReadOnly: this.state.isDisabled,
      useStore: false,
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      formatItemFunction: this.onFormatItemHandler,
      validateEdits: false,
      filterChanging: this.filterChangingHandler,
    };

    // コンボボックス: 一束化
    const iskkCategoryList = getComboList(generalPurposeMap, 'IskkCategory');
    const iskkCategoryMap = createDataMapByComboList(iskkCategoryList);

    // コンボボックス: 取付種別
    const attachTypeList = getComboList(generalPurposeMap, 'AttachType', true, {Code__c: ['4']});
    const attachTypeMap = createDataMapByComboList(attachTypeList);

    // コンボボックス: 設備名称
    const closestDrStbNameList = getComboList(generalPurposeMap, 'ClosestDrStbStbName');
    const closestDrStbNameMap = createDataMapByComboList(closestDrStbNameList);

    // コンボボックス: 工事内容
    const nwKoziHopeKoziContentsList = getComboList(generalPurposeMap, 'DrSideKoziKinds');
    const nwKoziHopeKoziContentsMap =
      createDataMapByComboList(nwKoziHopeKoziContentsList);

    // コンボボックス: 通信引込線の有無
    const tsnHksnUmuList = getComboList(generalPurposeMap, 'UmuCategory');
    const tsnHksnUmuMap = createDataMapByComboList(tsnHksnUmuList);

    return (
      <>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="poleNoSelector" header="電柱" width={50}
              cellTemplate={CellMaker.makeButton({
                text: '選択',
                cssClass: this.state.isDisabled ? 'wj-state-disabled' : 'btn_navy',
                click: (e, ctx) => {
                  // 1301_電柱選択画面起動
                  this.currentCtx = ctx;
                  this.setState({modalOpen: true, ybdsType: 'A'});
                },
                attributes: {
                  tabindex: -1,
                },
              })}
            />
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="selectedDntInfo" width={120} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="selectedDntInfo" width={95} isReadOnly={true} isRequired={true}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" cssClass="selectedDntInfo" width={65} isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="電線" align="center">
            <FlexGridColumnGroup binding="IskkCategory__c" header="一束化" width={110} dataMap={iskkCategoryMap}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerDnsnIskkTemplate}/>

            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="selectedDntInfo" isReadOnly={true}/>
          <FlexGridColumnGroup binding="AttachType__c" header="取付種別" dataMap={attachTypeMap}>
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerAttachTypeTemplate}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="AttachSpotHeight__c" header="取付点高さ（m）" dataType="Number" width={110} format="n1">
            <FlexGridCellTemplate cellType="CellEdit" template={
              (ctx) => {
                return <InputNumber
                  className="flexGrid-cell-editor"
                  min={0.0}
                  max={99.9}
                  step={0.1}
                  format='n1'
                  value={ctx.value}
                  valueChanged={(inpNum) => ctx.value = inpNum.value}
                />;
              }}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="最接近電力設備との離隔" align="center">
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerClosestDrStbDntTemplate}/>
            <FlexGridColumnGroup binding="ClosestDrStbName__c" header="設備名称" width={200} dataMap={closestDrStbNameMap}/>
            <FlexGridColumnGroup binding="ClosestDrStbRikakOrder__c" header="離隔距離（m）" dataType="Number" width={110} format="n2">
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="中国電力NWへの工事希望" align="center">
            <FlexGridColumnGroup binding="NWKoziHopeKoziContents__c" header="工事内容" dataMap={nwKoziHopeKoziContentsMap}/>
            <FlexGridColumnGroup binding="NWKoziHopeMemo__c" header="メモ" dataType="String" maxLength={20} width={300}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="通信引込線" align="center">
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerTsnHksnTemplate}/>
            <FlexGridColumnGroup binding="TsnHksnUmu__c" header="通信引込線の有無" dataMap={tsnHksnUmuMap} cssClass="centerPos" width={110}/>
            <FlexGridColumnGroup name="DrHksnKiknCrossingRikak" binding="DrHksnKiknCrossingRikak__c" header="①電力引込線との離隔（m）（径間途中，交差部分）" dataType="Number" width={230} format="n2">
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup name="DrHksnHouseSideRikakKkh" binding="DrHksnHouseSideRikakKkh__c" header="②電力引込線との離隔確保（家屋側支接点付近）" cssClass="centerPos" width={230}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerDrHksnHouseSideRikakKkhTemplate}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
               線路名　：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName__c') ?
                        this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
               電柱番号：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo__c') ?
                        this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <ComboBox
                id="id1004IskkCategoryPopup"
                initialized={this.initializeEditedIskkCategory.bind(this)}
                itemsSource={iskkCategoryList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>一束化</label>
            </div>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1004AttachTypePopup"
                initialized={this.initializeEditedAttachType.bind(this)}
                itemsSource={attachTypeList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>取付種別</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id='id1004AttachSpotHeightPopup'
                format='n1'
                min={0.0}
                max={99.9}
                step={0.1}
                initialized={
                  this.initializeEditedAttachSpotHeight.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>取付点高さ（m）</label>
            </div>
            <br></br>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                  最接近電力設備との離隔
            </div>
            <br></br>
            <div className="wj-labeled-input" >
              <ComboBox
                id="id1004ClosestDrStbStbNamePopup"
                initialized={
                  this.initializeEditedClosestDrStbName.bind(this)}
                itemsSource={closestDrStbNameList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>設備名称</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id='id1004ClosestDrStbRikakOrderPopup'
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedClosestDrStbRikakOrder.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>離隔距離（m）</label>
            </div>
            <br></br>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                   中国電力NWへの工事希望
            </div>
            <br></br>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1004NWKoziHopeKoziContentsPopup"
                itemsSource={nwKoziHopeKoziContentsList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                initialized={
                  this.initializeEditedNWKoziHopeKoziContents.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>工事内容</label>
            </div>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1004NWKoziHopeMemoPopup"
                initialized={
                  this.initializeEditedNWKoziHopeMemo.bind(this)}
                maxLength={20}
                isDisabled={this.state.isDisabled}/>
              <label>メモ</label>
            </div>
            <br></br>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                   通信引込線
            </div>
            <br></br>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1004TsnHksnUmuPopup"
                initialized={this.initializeEditedTsnHksnUmu.bind(this)}
                itemsSource={tsnHksnUmuList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                selectedIndexChanged={this.changeTsnHksnUmu.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>通信引込線の有無</label>
            </div>
            <div className='wj-labeled-input'>
              <InputNumber
                id='id1004DrHksnKiknCrossingRikakPopup'
                // className='wj-label-longText'
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedDrHksnKiknCrossingRikak.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>①電力引込線との離隔（m）（径間途中，交差部分）</label>
            </div>
            <div className="wj-labeled-input" style={{paddingTop: '0px'}}>
              <span className='wj-input-label-custom'>
                ②電力引込線との離隔確保（家屋側支接点付近）
              </span>
              <input
                id="id1004DrHksnHouseSideRikakKkhPopup"
                type="checkbox"
                ref={this.drHksnHouseSideRikakKkhRef}
                value={this.popupItems['DrHksnHouseSideRikakKkh__c']}
                onChange={(e) => {
                  this.changeDrHksnHouseSideRikakKkh(e);
                }
                }
                disabled={this.state.isDisabled}/>
              <label htmlFor="id1004DrHksnHouseSideRikakKkhPopup">
                良
              </label>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>

        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="dnsnIskkHelpComponent">
            <DnsnIskk/>
          </div>
          <div id="attachTypeHelpComponent">
            <AttachType/>
          </div>
          <div id="closestDrStbDntHelpComponent">
            <ClosestDrStbDnt/>
          </div>
          <div id="tsnHksnHelpComponent">
            <TsnHksn/>
          </div>
          <div id="drHksnHouseSideRikakKkhHelpComponent">
            <DrHksnHouseSideRikakKkh/>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1004AddDntList" item>
          <PositiveButton
            id="id1004AddDntList"
            startIcon={<AddIcon />}
            onClick={handleSubmit(this.doAddDntList)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled}
          >
            <span>電柱追加</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1004Next" item>
          <PositiveButton
            id="btn1004Next"
            onClick={handleSubmit(this.doMoveNext)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode}
          >
            <span>次へ</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1004Save" item>
          <PositiveButton
            id="btn1004Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1004Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onCheckGrid={this.doCheckModified}/>
        </Grid>
      </Grid>
    );

    return (
      <>
        <form noValidate autoComplete="off" className={classes.root}>
          <MainContainer
            props={this.props}
            footerBtn={footerBtn}>
            <WarningMessageArea messages={this.state.warningMessages}/>
            <Grid container
              direction="column"
              alignItems="flex-start"
            >
              <Grid key="key1004Msg" item xs={12}>
                <span style={{whiteSpace: 'pre-line'}}>
              一束化を2者一束化以上にすると、次の画面で一束化相手先を登録できます。<br/>
              ケーブルルートの始点、終点になる電柱は入力不要です。
                </span>
              </Grid>
              <Grid key="key1004Table" item xs={12}>
                {this.renderTable()}
              </Grid>
            </Grid>
            <Grid container
              direction="column"
              alignItems="center"
            >
              <Grid item xs={12}>
                <CustomCard
                  title={<strong>確認事項</strong>} isExpanded={false}>
                  <Typography variant="body1">
                  １. 申請者は，通信線と電力設備の離隔距離の緩和を要望する場合，<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;次の事項を確認し，承諾（チェック）してください。
                  </Typography>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <Field
                        id="CheckJiko1__c"
                        name="CheckJiko1__c"
                        component={renderCheckbox}
                        label={<p style={{color: '#000', maxWidth: '32em', margin: '5px auto 0 auto'}}>
                         離隔距離を緩和しても，通信線の作業者の安全は，申請者の責任において確保する。
                        </p>}
                        disabled={this.state.isDisabled}
                      />
                      <Field
                        id="CheckJiko2__c"
                        name="CheckJiko2__c"
                        component={renderCheckbox}
                        label={<p style={{color: '#000', maxWidth: '31em', margin: '5px auto 5px auto'}}>
                         離隔距離を緩和して通信線を架設し，誘導障害が発生した場合は，申請者の責任で改修する。
                        </p>}
                        disabled={this.state.isDisabled}
                      />
                    </FormGroup>
                  </FormControl>
                  <Typography variant="body1">
                  ２．設置する通信線の種別が以下に該当する場合，チェックを入れてください。
                  </Typography>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <Field
                        id="CheckJiko3__c"
                        name="CheckJiko3__c"
                        component={renderCheckbox}
                        label={<p style={{color: '#000', maxWidth: '34em', marginBottom: '0'}}>
                           光ファイバケーブルまたは添架通信用第 1 種ケーブルもしくは添架通信用第 2 種ケーブル
                        </p>}
                        disabled={this.state.isDisabled}
                      />
                    </FormGroup>
                  </FormControl>
                </CustomCard>
              </Grid>
            </Grid>
          </MainContainer>
          <SelectPoleNo
            YbdsType={this.state.ybdsType}
            modalOpen={this.state.modalOpen}
            closeButton={this.closeSelectDnt}
            setSelectedPole={this.setSelectedDnt}/>
        </form>
      </>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  userInfo: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  contractDntList: PropTypes.array,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderAndContractDntList: PropTypes.func,
  referenceMode: PropTypes.bool,
  doSaveContractDnts: PropTypes.func,
  doClearOrderContractDnt: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  attachTypeCheck: PropTypes.object,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
  doSetTransitionSrcId: PropTypes.func,
};

const mapStateToProps = (state) => {
  const orderInfo = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
    state.contractDnt.orderContractDntList.order : null;

  const initValues = {
    CheckJiko1__c: orderInfo &&
      Object.hasOwnProperty.call(orderInfo, 'CheckJiko1__c') ?
      orderInfo['CheckJiko1__c'] : false,
    CheckJiko2__c: orderInfo &&
      Object.hasOwnProperty.call(orderInfo, 'CheckJiko2__c') ?
      orderInfo['CheckJiko2__c'] : false,
    CheckJiko3__c: orderInfo &&
      Object.hasOwnProperty.call(orderInfo, 'CheckJiko3__c') ?
      orderInfo['CheckJiko3__c'] : false,
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    orderId: state.attachorder.orderId,
    order: orderInfo,
    contractDntList: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
      state.contractDnt.orderContractDntList.contractDntList : [],
    attachTypeCheck: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'attachTypeCheck') ?
      state.contractDnt.orderContractDntList.attachTypeCheck : null,
    referenceMode: state.common.referenceMode == true ? true : false,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderAndContractDntList:
    contractDntOperations.doGetOrderAndContractDntList,
  doSaveContractDnts: contractDntOperations.doSaveContractDnts,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
  doSetTransitionSrcId: commonOperations.doSetTransitionSrcId,
};

const FORM_NAME = 'SenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import PhotoCommon from '../PhotoCommon';

const styles = (theme) => ({
});

/**
 * 写真種別の情報一覧
 * @type { [key: string]: PhotoCommon.PhotoType }
 */
const PHOTO_TYPES = {
  /** 写真種別定義(電柱番号写真) */
  bangou: {
    componentId: 'bangou',
    name: '電柱番号写真',
    maxFileCount: 1,
  },

  /** 写真種別定義(全景写真) */
  zenkei: {
    componentId: 'zenkei',
    name: '全景写真',
    maxFileCount: 2,
  },

  /** 写真種別定義(近景写真) */
  kinkei: {
    componentId: 'kinkei',
    name: '近景写真',
    maxFileCount: 1,
  },

  /** 写真種別定義(通信引込線施設箇所付近) */
  tsusin: {
    componentId: 'tsusin',
    name: '通信引込線施設箇所付近',
    maxFileCount: 1,
    editableControl: 'hasCommunicationLine',
  },
};

/** 遷移元画面ごとの表示項目定義 */
const DISPLAY_ITEMS = {

  /** 1004_可否判定申込線設備入力（契約電柱） */
  '1004': {
    editablePage: true,
    items: ['bangou', 'zenkei', 'kinkei', 'tsusin'],
  },

  /** 1007_可否判定申込点設備入力（契約電柱） */
  '1007': {
    editablePage: true,
    items: ['bangou', 'zenkei', 'kinkei'],
  },

  /** 1014_本申込線設備入力（契約電柱） */
  '1014': {
    editablePage: false,
    items: ['bangou', 'zenkei', 'kinkei', 'tsusin'],
  },

  /** 1016_本申込点設備入力（契約電柱） */
  '1016': {
    editablePage: false,
    items: ['bangou', 'zenkei', 'kinkei'],
  },

  /** 1027_竣工届申込登録（契約電柱）※線設備 */
  '1027_sen': {
    editablePage: false,
    items: ['bangou', 'zenkei', 'kinkei', 'tsusin'],
  },

  /** 1027_竣工届申込登録（契約電柱）※点設備 */
  '1027_ten': {
    editablePage: false,
    items: ['bangou', 'zenkei', 'kinkei'],
  },

  /** 1038_撤去竣工届申込登録（契約電柱）（線） */
  '1038': {
    editablePage: true,
    items: ['bangou', 'zenkei', 'kinkei'],
  },

  /** 1064_撤去竣工届申込登録（契約電柱）（点） */
  '1064': {
    editablePage: true,
    items: ['bangou', 'zenkei', 'kinkei'],
  },

  /** 1473_改修依頼改修工事予定入力 */
  '1473': {
    editablePage: true,
    items: ['bangou', 'zenkei', 'kinkei'],
  },

  /** 1487_竣工届申込登録（契約電柱）(改修依頼)※線設備 */
  '1487_sen': {
    editablePage: false,
    items: ['bangou', 'zenkei', 'kinkei', 'tsusin'],
  },

  /** 1487_竣工届申込登録（契約電柱）(改修依頼)※点設備 */
  '1487_ten': {
    editablePage: false,
    items: ['bangou', 'zenkei', 'kinkei'],
  },
};

/**
 * 申込写真登録 テンプレート。
 * 画面ID:1060
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    /** @type import('../../../../reducks/contractDnt/operations.js').PhotoRegistlationInfo */
    const photoRegistrationInfo = this.props.photoRegistrationInfo;
    const displayItem = DISPLAY_ITEMS[photoRegistrationInfo.transitionSrcId];

    return (
      <PhotoCommon
        {...this.props}
        displayItem={displayItem}
        photoTypes={PHOTO_TYPES}
      />
    );
  }
}

Container.propTypes = {
  photoRegistrationInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

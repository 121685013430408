// 共架事業者連絡事項
const GET_CONTACT_ZIKO_LIST = 'GET_CONTACT_ZIKO_LIST';
const GET_CONTACT_ZIKO = 'GET_CONTACT_ZIKO';
const SET_CONTACT_ZIKO_TYPE = 'SET_CONTACT_ZIKO_TYPE';
const SET_KYOGA_CONTACT_ZIKO_ID = 'SET_KYOGA_CONTACT_ZIKO_ID';

export default {
  /* 共架事業者連絡事項一覧取得 */
  GET_CONTACT_ZIKO_LIST,
  /* 共架事業者連絡事項取得 */
  GET_CONTACT_ZIKO,
  /* 共架事業者連絡種別設定 */
  SET_CONTACT_ZIKO_TYPE,
  /* 共架者連絡事項Id設定 */
  SET_KYOGA_CONTACT_ZIKO_ID,
};


import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import BackButton from '../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../organisms/MainContainer.js';
import NegativeButton from '../../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../atoms/Buttons/PositiveButton.js';
import CustomFlexGrid from '../../../../molecules/CustomFlexGrid.js';
import {renderTextField, renderSelect} from '../../../../atoms/CustomPart.js';
import {attachorderOperations} from '../../../../../reducks/attachorder/index.js';
import {commonOperations} from '../../../../../reducks/common/index.js';
import * as validateRule from '../../../../validateRule.js';
import {createSearchList, createSearchListEx, changePrefectures} from '../../../../../common/ComboParts.js';
import {FlexGridCellTemplate, FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import {changeDateFormat, parseStatusRepairiraiToOrder, execApexRestApi,
  createTyohyoPdf, getToDate, getComboName,
  getErrorCode, API_CODE, getResponseResult,
  getDataFromGeneralPurposeMap} from '../../../../../common/common.js';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

// 申込テーブル検索時のソート条件
const appSortParams = {
};

// 申込テーブル検索時の検索対象のフィールド名
const appFields = ['Id', 'NWZgsyo__r.Name', 'OrderNo__c', 'toLabel(KyogaType__c)', 'RepairIraiDate__c',
  'RepairIraiCheckDate__c', 'RepairKoziDeadline__c', 'KyogaKoziScheduleDate__c', 'KoziTitle__c',
  'toLabel(KoziPlacePrefectures__c)', 'KoziPlaceMunicipalities__c', 'KoziPlacePlace__c',
  'OrderStatus__c', 'RepairOrderNo__c', 'OrderSendBackReason__c', 'RepairGaisanConsentStatus__c',
  'toLabel(StbType__c)', 'LastModifiedDate',
  'KoziPlaceMainDntNo_SenroName__c', // 工事場所_線路名
  'Dsp_KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
];


/**
 *改修依頼連絡一覧画面
 *画面ID:1471
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      retButtonDisabled: true,
      buttonDisabled: true,
      selectedItems: [],
    };
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    // FrexGridに初期値設定
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1471', []);
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.doClearList();
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    this.props.doClearOrderList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1471', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = async () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('orderList') &&
      this.props.orderList ? this.props.orderList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * 検索処理
   * @param {boolean} isClear クリアするかどうか
   */
  _searchOrder = async (isClear=true) => {
    const {values} = this.props;
    this.setState({retButtonDisabled: true});
    // 画面入力されたデータのみ検索対象として取得
    const wkData = JSON.parse(JSON.stringify(values));
    wkData['OrderCategory__c'] = '05'; // 改修依頼

    // レコードタイプが改修依頼
    wkData['RecordType.DeveloperName'] = 'RepairIrai';

    // 事業所
    if ('NWZgsyo__r_Code__c' in wkData) {
      wkData['NWZgsyo__r.Code__c'] = wkData.NWZgsyo__r_Code__c;
      delete wkData.NWZgsyo__r_Code__c;
    }

    // 共架種別
    if ('kyogaType__r_Code__c' in wkData) {
      wkData['KyogaType__c'] = wkData.kyogaType__r_Code__c;
      delete wkData.kyogaType__r_Code__c;
    }

    if ('RepairIraiStatus__c' in wkData) {
      const repairIraiStatus = parseInt(wkData['RepairIraiStatus__c']);
      if (Number.isNaN(repairIraiStatus)) {
        let work = [];
        if (this.props.generalPurposeMap.hasOwnProperty('RepairIraiStatus')) {
          for (const item of this.props.generalPurposeMap.RepairIraiStatus) {
            work = work.concat(parseStatusRepairiraiToOrder(item.Code__c));
          }
        }
        if (work.length > 0) {
          wkData['OrderStatus__c'] = work;
        } else {
          // 改修依頼ステータス:作成中 は非表示
          wkData['OrderStatus__c_NE']='00';
        }
      } else {
        wkData['OrderStatus__c']=parseStatusRepairiraiToOrder(repairIraiStatus);
      }

      delete wkData.RepairIraiStatus__c;
    }
    const addFields=[];
    // 申込テーブルからデータ取得
    const response =
      await this.props.doGetOrderTypeList(
          wkData, appFields, appSortParams, 'getRepairOrderListEx', [], addFields);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込', '取得'],
        },
      });
      return false;
    }
    // FrexGridに検索結果設定
    if (this.gridRef && this.gridRef.current) {
      if (isClear != false) {
        this.gridRef.current.saveSelectedItems('1471', 'Id', true);
      }
      this.gridRef.current.setInitItems('1471', this.props.orderList);
    }
    return true;
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      const result = await this._searchOrder();
      if (!result) {
        return;
      }
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
          this.props.orderList.length<=0) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
        return;
      }
      // 最大件数チェック
      if (!this.listMaxCountCheck()) {
        return;
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      // １件選択されているか判定
      let disableButton = true;
      if (this.gridRef && this.gridRef.current &&
        this.gridRef.current.selectedItemCount() == 1) {
        disableButton = false;
      }
      this.setState({retButtonDisabled: disableButton});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 共架設備改修依頼書ダウンロード
   */
  downloadRepairIrai = async () =>{
    try {
      const {userInfo} = this.props;
      const pdfname = '共架設備改修依頼書';
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }
      const item = selectedItems[0];
      const ret = await createTyohyoPdf(userInfo, 'KyogaStbRepairIrai',
          {'OrderNo__c': item.dataItem.OrderNo__c}, pdfname+'.pdf');

      if (!ret) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: [pdfname, 'ダウンロード'],
          },
        });
        return;
      }

      // 改修依頼確認日の更新
      if (isNullOrWhiteSpace(item.dataItem.RepairIraiCheckDate__c)) {
        const conditions={
          'Id': item.dataItem.Id,
          'LastModifiedDate': item.dataItem.LastModifiedDate,
        };
        const param = {
          'Id': item.dataItem.Id,
          'RepairIraiCheckDate__c': getToDate(),
        };

        const body = {
          'record': [{
            'conditions': conditions,
            'order': param,
          }],
          'stage': 'REPAIR_IRAI_CHECK_DATE_UPDATE',
        };
        // 申込テーブルの更新
        const result = await execApexRestApi(userInfo,
            'ApiRepairRequest/repairCheckDateUpdate', body);
        const resResult = getResponseResult(result, ['申込', '更新']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        }
        this.gridRef.current.saveScreenState('1471', 'Id');
        // 再検索
        await this._searchOrder(false);
        // 最大件数チェック
        if (!this.listMaxCountCheck()) {
          return;
        }
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'RepairStatus') {
      const RepairIraiStatus = this.props.generalPurposeMap.RepairIraiStatus;
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        return (lb.collectionView.items[index].value != null ?
          RepairIraiStatus[lb.collectionView.items[index].value].Name :
          '(なし)');
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'RepairGaisanConsentStatus__c') {
      const RepairGaisanConsentStatus =
        this.props.generalPurposeMap.RepairGaisanConsentStatus;
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        return (lb.collectionView.items[index].value != null ?
          RepairGaisanConsentStatus[lb.collectionView.items[index].value].Name :
          '(なし)');
      };
      lb.collectionView.refresh();
    }
  }

  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkStateUpdate,
      filterChanging: this.filterChangingHandler,
    };
    const {generalPurposeMap}= this.props;
    const RepairIraiStatus = generalPurposeMap.RepairIraiStatus;

    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='NWZgsyo__r.Name' header='事業所' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='OrderNo__c' header='改修依頼番号' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding="StbType__c" header="設備種別" dataType="String" isReadOnly={true} width={100}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KyogaType__c' header='共架種別' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='RepairIraiDate__c' header='改修依頼日' dataType="Date"isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.RepairIraiDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='RepairIraiCheckDate__c' header='改修依頼\n確認日' dataType="Date"isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.RepairIraiCheckDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='RepairKoziDeadline__c' header='改修工事\n期限日' dataType="Date"isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.RepairKoziDeadline__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KyogaKoziScheduleDate__c' header='改修工事\n予定日' dataType="Date"isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.KyogaKoziScheduleDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziTitle__c' header='工事件名' dataType="String"/>
          <FlexGridColumnGroup header='工事場所'>
            <FlexGridColumnGroup binding='KoziPlaceMainDntNo_SenroName__c' header='線路名' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='Dsp_KoziPlaceMainDntNo__c' header='代表電柱番号' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='KoziPlacePrefectures__c' header='都道府県' dataType="String"isReadOnly={true} width={100}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='RepairStatus' header='改修依頼\nステータス' width={140} dataType="String"isReadOnly={true}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) =>{
                return (context.item.RepairStatus != null ? RepairIraiStatus[context.item.RepairStatus].Name :'');
              }

              }
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='RepairGaisanConsentStatus__c' header='見積結果\n承諾状況' width={120} dataType="String"isReadOnly={true}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) =>{
                return getComboName(generalPurposeMap, 'RepairGaisanConsentStatus', context.item.RepairGaisanConsentStatus__c);
              }

              }
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='RepairOrderNo__c' header='改修元申込番号' dataType="String"isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='SyunkoDate__c' header='竣工日' dataType="Date"isReadOnly={true} width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.SyunkoDate__c);
              }}
            />
          </FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key= "btn0" item>
          <PositiveButton
            onClick={handleSubmit(this.downloadRepairIrai)}
            variant="contained"
            size="large"
            type="submit"
            disabled={this.state.retButtonDisabled}
          >
            <span>改修依頼書</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn1" item>
          <PositiveButton
            onClick={() => {
              this.props.doSetOrderId(
                  this.gridRef.current.selectedItems[0].dataItem.Id);
              this.props.history.push({pathname: '/RepairIrai/ConfirmIraiContent/Confirm'});
            }}
            variant="contained"
            size="large"
            type="submit"
            disabled={this.state.retButtonDisabled}
          >
            <span>依頼内容確認</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1" item xs={12} sm={6} md={3}>
                <Field
                  name="OrderNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="改修依頼番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key2" item xs={12} sm={3}>
                <Field
                  name="NWZgsyo__r_Code__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="事業所"
                  fullWidth
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchListEx(generalPurposeMap.NWZgsyo, 'Code__c', 'Name', null, {Code__c: ['1001', '1065']})}
                </Field>
              </Grid>

              <Grid key="key3" item xs={12} sm={3}>
                <Field
                  name="StbType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="設備種別"
                  fullWidth
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType,
                        'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key3_2" item xs={12} sm={3}>
                <Field
                  name="kyogaType__r_Code__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="共架種別"
                  fullWidth
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                     createSearchList(generalPurposeMap.KyogaType,
                         'Code__c',
                         'Name',
                         {Code__c:
                           Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                           Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                           userInfo.Account.Account__r.KyogaType__c : '',
                         ValueString1__c:
                           Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                             [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key4-0" item xs={12} sm={3}>
                <Field
                  name="KoziTitle__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事件名"
                  type="text"
                  fullWidth
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="key4" item xs={12} sm={3}>
                <Field
                  name="RepairIraiStatus__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="改修依頼ステータス"
                  fullWidth
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(generalPurposeMap.RepairIraiStatus)}
                </Field>
              </Grid>

              <Grid key="key4-2" item xs={12} sm={3}>
                <Field
                  name="RepairGaisanConsentStatus__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="見積結果承諾状況"
                  fullWidth
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(
                          generalPurposeMap.RepairGaisanConsentStatus)}
                </Field>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid key="key4-2-2" item xs={12} sm={2}>
                <Field
                  name="KoziPlaceMainDntNo_SenroName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="線路名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>

              <Grid key="key4-2-3" item xs={12} sm={2}>
                <Field
                  name="Dsp_KoziPlaceMainDntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
              <Grid key="key4-2-4" item xs={12} sm={2}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>

              <Grid key="key4-2-5" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key4-2-6" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid key="key5" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="RepairIraiDate__c_From"
                    accessibilitylabel="RepairIraiDate__c_From"
                    label="改修依頼日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="RepairIraiDate__c_To"
                    accessibilitylabel="RepairIraiDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key6" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="RepairKoziDeadline__c_From"
                    accessibilitylabel="RepairKoziDeadline__c_From"
                    label="改修工事期限日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="RepairKoziDeadline__c_To"
                    accessibilitylabel="RepairKoziDeadline__c_To"
                    label={<React.Fragment/>}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key7" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="KyogaKoziScheduleDate__c_From"
                    accessibilitylabel="KyogaKoziScheduleDate__c_From"
                    label="改修工事予定日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="KyogaKoziScheduleDate__c_To"
                    accessibilitylabel="KyogaKoziScheduleDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key8" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>

          <div key="aa" style={{marginBottom: '20px'}} />

          <Divider key="bb" variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderTypeList: PropTypes.func.isRequired,
  doSetOrderId: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderList: state.attachorder.orderList,
  // 表示時のデフォルト設定
  initialValues: {
    RepairIraiStatus__c: '1', // 工事予定未登録
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderList: attachorderOperations.doClearOrderList,
};

const FORM_NAME = 'RepairRequestList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Container from '../../components/templates/Login/newPassword';
import {commonOperations} from '../../reducks/common';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  doParseGetParam: commonOperations.doParseGetParam,
};

/**
 * ログイン画面:パスワードリセット(新パスワード入力) ページ。
 * 画面ID:3000
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const ret = this.initialize();
    this.judge(ret);
  }

  /**
   * 初期化処理
   * @return {boolean} 初期化結果
   */
  initialize() {
    const {doParseGetParam} = this.props;

    // GETパラメータから情報を取得
    const qs = doParseGetParam(this.props.location.search);
    const confirmationCode = qs.confirmation_code;
    const userName = qs.user_name;

    // 1つでも存在しないパラメータがあればエラー
    if (!confirmationCode || !userName) {
      return false;
    }

    return true;
  }

  /**
   * 初期化結果判定。
   * @param {boolean} ret 初期化結果
   */
  judge(ret) {
    const {history} = this.props;

    // 初期化失敗時
    if (!ret) {
      // ログイン画面へ遷移
      history.push('/login');
    }
  }

  /**
   * 画面描画。
   *
   * @return {JSX.Element} 描画要素
   */
  render() {
    return (<Container
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  doParseGetParam: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default ContainerApp;

export default {
  'logger': false,
  'consolelog': false,
  'API_URL': 'https://api.yupol.kyouga.com/',
  'amplify_conf': {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'ap-northeast-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-northeast-1_TD57wIh9m',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: 'h30qv01pitriktc6545hgcdjk',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },
  },
  'timeout': 60,
  'availableTime': {
    'start': '7:00',
    'end': '22:00',
  },
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_TD57wIh9m',
  'IdentityPoolId': '',
  'MAX_DELETE_NUM': 50,
  'WIJMO_LICENSE_KEY': 'yupol.kyouga.com,779456643576556#B0XIIYnMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdpV7ZrIDco3STU5WRuFHaLFUdllENYp4MLFzZqVjSpVkRylzRDF4MGRGNrUFVZlkUyIzTiNUST3kUXh6LwQlNwADTVlUN5VVc9pmeU3GeWNEampWSzM4RVZnYzYHbzYEUqZDZnJXcJZzYytSUNZWc4YGOvYXTSFmcnB5NORWOWBjM0pVQtBXercFe9QWaCpVa5RGSlJ4Mkh5NENHWlZTNYtkQwd6M5l7YQBlavIXd52UTWtkY7tGV5lzMxJEOwBVeoJDbuFFbBRXNrx4cUZTNRljc8ZjZBB5Kx3kcxY7QBJ5YIVTeDdDeC9mM44GZSpnYr8GMPt4cZlFd93iNad7UXJTWsFHdXRWValGO8JGbOpnNyNWO8wWTH5EWzFVcthDW9cEeNdGdWJmVhFUN5V6bPJkVrYzbyJnaPd7K53GbNRFZGlVT0JlSZpXeSFTcz3kb8RGd4YlI0IyUiwiIBlDO5MEODZjI0ICSiwyN8IzMygTO8cTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIyMxgjM7ADI7ITNwIjMwIjI0ICdyNkIsISbvNmLhdWdvl7auw6bwVXeiojIz5GRiwiI+S09ayL9Pyb9qCq9/K882O88ZO88OO88kK88zO88nO887K882O88lO88qO883K88jy11ly11uy11iojIh94QiwiI6UTN6cTNzQjN6UDN9czNiojIklkIs4XXbpjInxIZMw',
  'HELP_IMAGE_URL': 'https://yupol.kyouga.com/help-image/',
  'FORMAT01_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F1_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E7%94%B3%E8%BE%BC%E6%9B%B8.pdf',
  'FORMAT01_DOC_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F1_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E7%94%B3%E8%BE%BC%E6%9B%B8.docx',
  'FORMAT02_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F2_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E3%83%BB%E4%B8%8D%E5%86%85%E8%AB%BE%E5%9B%9E%E7%AD%94%E6%9B%B8.pdf',
  'FORMAT03_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F3_%E5%85%B1%E6%9E%B6%E3%83%BB%E5%A4%89%E6%9B%B4%E7%94%B3%E8%BE%BC%E6%9B%B8.pdf',
  'FORMAT03_DOC_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F3_%E5%85%B1%E6%9E%B6%E3%83%BB%E5%A4%89%E6%9B%B4%E7%94%B3%E8%BE%BC%E6%9B%B8.docx',
  'FORMAT04_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F4_%E5%85%B1%E6%9E%B6%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%81%8A%E3%82%88%E3%81%B3%E5%85%B1%E6%9E%B6%E4%BC%9D%E9%80%81%E8%B7%AF%E8%A8%AD%E5%82%99.pdf',
  'FORMAT04_DOC_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F4_%E5%85%B1%E6%9E%B6%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%81%8A%E3%82%88%E3%81%B3%E5%85%B1%E6%9E%B6%E4%BC%9D%E9%80%81%E8%B7%AF%E8%A8%AD%E5%82%99.docx',
  'FORMAT05_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F5_%E5%B7%A5%E4%BA%8B%E6%96%BD%E5%B7%A5%E8%80%85%E3%81%8A%E3%82%88%E3%81%B3%E4%BF%9D%E5%AE%88%E6%8B%85%E5%BD%93%E8%80%85%E3%81%AE%E5%B1%8A.pdf',
  'FORMAT05_DOC_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F5_%E5%B7%A5%E4%BA%8B%E6%96%BD%E5%B7%A5%E8%80%85%E3%81%8A%E3%82%88%E3%81%B3%E4%BF%9D%E5%AE%88%E6%8B%85%E5%BD%93%E8%80%85%E3%81%AE%E5%B1%8A.docx',
  'FORMAT06_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F6_%E5%85%B1%E6%9E%B6%E6%89%BF%E8%AB%BE%E3%83%BB%E4%B8%8D%E6%89%BF%E8%AB%BE%E5%9B%9E%E7%AD%94%E6%9B%B8.pdf',
  'FORMAT07_PDF_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F7_%E5%85%B1%E6%9E%B6%E3%83%BB%E6%94%B9%E4%BF%AE%E3%83%BB%E6%92%A4%E5%8E%BB%E5%B7%A5%E4%BA%8B%20%E7%9D%80%E6%89%8B%E3%83%BB%E5%AE%8C%E4%BA%86%E5%B1%8A.pdf',
  'FORMAT07_DOC_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F7_%E5%85%B1%E6%9E%B6%E3%83%BB%E6%94%B9%E4%BF%AE%E3%83%BB%E6%92%A4%E5%8E%BB%E5%B7%A5%E4%BA%8B%20%E7%9D%80%E6%89%8B%E3%83%BB%E5%AE%8C%E4%BA%86%E5%B1%8A.docx',
  'FORMAT08_XLS_URL': 'https://yupol.kyouga.com/format/%E6%A7%98%E5%BC%8F8_%E5%85%B1%E6%9E%B6%E7%94%B3%E8%BE%BC%E3%83%BB%E5%BB%83%E6%AD%A2%EF%BC%BF%E8%A3%85%E6%9F%B1%E5%9B%B3%E3%83%BB%E5%86%99%E7%9C%9F%E3%80%90%E7%94%B3%E8%AB%8B%E6%99%82%EF%BC%8F%E6%96%BD%E5%B7%A5%E5%BE%8C%E3%80%91%EF%BC%88%E6%A8%99%E6%BA%96%E6%A7%98%E5%BC%8F%EF%BC%89.xlsx',

  // マニュアルのURLは中電NWサイトからリンクを張られるため変更しないこと
  'MANUAL_PDF_URL': 'https://yupol.kyouga.com/manual/yupol-01.pdf',

  // 中国電力ネットワーク契約用マニュアル
  'MANUAL_CN_PDF_URL': 'https://yupol.kyouga.com/manual/yupol-02.pdf',

  'KYOUGA_YAKKAN_URL': '#',
  'CONSTRUCTION_URL': '#',
  'POINTS_URL': '#',
  'INFO_HIKARI_FIBER_URL': '#',
  'TOI_URL': '#',
};

import axios from 'axios';

import {
  getContactZikoList,
  getContactZiko,
  setContactZikoType,
  setKyogaContactZikoId,
} from './actions';

import conf from './../../config/config.js';


/**
 * 共架者連絡事項一覧 取得
 * @param {object} conditions 検索条件
 * @param {string[]|object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<void>} 取得結果
 */
const doGetContactZikoList = (conditions, fields, sortParams) =>{
  console.log('共架者連絡事項一覧 取得...');
  return async (dispatch, getState)=>{
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'KyogaContactZiko/getContactZikoList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(getContactZikoList(data));
    return ret;
  };
};

/**
 * 共架者連絡事項と添付ファイル 取得
 * @param {object} conditions 検索条件
 * @param {string[]|object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<void>} 取得結果
 */
const doGetContactZiko = (conditions, fields, sortParams) =>{
  console.log('共架者連絡事項 取得...');
  return async (dispatch, getState)=>{
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'KyogaContactZiko/getContactZiko', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(getContactZiko(data));
    return ret;
  };
};

/**
 * 共架者連絡事項一覧のクリア
 * @return {void} 処理結果
 */
const doClearContactZikoList = () => {
  console.log('共架者連絡事項一覧 クリア...');

  return async (dispatch) => {
    dispatch(getContactZikoList(null));
  };
};

/**
 * 共架者連絡事項のクリア
 * @return {void} 処理結果
 */
const doClearContactZiko = () => {
  console.log('共架者連絡事項 クリア...');

  return async (dispatch) => {
    dispatch(getContactZiko(null));
  };
};

/**
 * 共架者連絡事項の連絡種別 設定
 * @param {string} contactZikoType 連絡種別
 * @return {void} 処理結果
 */
const doSetContactZikoType = (contactZikoType) => {
  console.log('共架者連絡事項の連絡種別 設定...');

  return async (dispatch) => {
    dispatch(setContactZikoType(contactZikoType));
  };
};

/**
 * 共架者連絡事項Id 設定
 * @param {string} id 共架者連絡事項のId
 * @return {void} 処理結果
 */
const doSetKyogaContactZikoId = (id) => {
  console.log('共架者連絡事項Id 設定...');

  return async (dispatch) => {
    dispatch(setKyogaContactZikoId(id));
  };
};

/**
 * 共架者連絡事項 確認
 * @param {Object} updateData 更新データ
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doConfirmKyogaContactZiko = (updateData) => {
  console.log('共架者連絡事項 更新...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      kyogaContactZiko: updateData,
    };

    const ret = await axios.post(conf.API_URL + 'KyogaContactZiko/updateCheckDate', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    return ret;
  };
};

export default {
  doGetContactZikoList,
  doClearContactZikoList,
  doGetContactZiko,
  doClearContactZiko,
  doSetContactZikoType,
  doSetKyogaContactZikoId,
  doConfirmKyogaContactZiko,
};

import {
  startRequest,
  endRequest,
  openDialog,
  closeDialog,
  openModal,
  closeModal,
  checkAccess,
  getParam,
  getAllGeneralPurposeMap,
  setIsTermsAccepted,
  setHasJuyoZiko,
  setIsHoldPurchase,
  setLoginDate,
  setIsKirikaeMae,
  getGeneralPurposeList,
  registGeneralPurpose,
  updateGeneralPurpose,
  setGeneralPurposeId,
  retrieveAccount,
  setAccountId,
  getAccountList,
  getAccountMasterList,
  updateAccount,
  updateAccounts,
  getContactList,
  getContact,
  setContactId,
  getContactMasterList,
  setTempContact,
  registAttachedFiles,
  getAttachedFiles,
  deleteAttachedFiles,
  setStbType,
  setReferenceMode,
  setTransitionSrcId,
  setYbdsPath,
  setGmnWarning,
  getDntSelectpole,
  getNwzgsyoNamefilter,
} from './actions';

import {isNullOrWhiteSpace} from '@grapecity/wijmo';
import axios from 'axios';
import queryString from 'query-string';

import {getUserInfo} from '../auth/actions';

import {API_CODE} from '../../common/common';
import {setAttachedFileType} from '../../common/fileOperations.js';

import conf from './../../config/config.js';

const dialogInfo = {
  type: ['ok', 'okcancel', 'close'],
  title: '',
  context: [],
  action: '',
  open: false,
};

/** ダイアログ種別 */
const DIALOG_TYPES = {
  info: 'I',
  warn: 'W',
  error: 'E',
  confirm: 'C',
  systemerror: 'S',
};

/**
 * メッセージ種別ごとのダイアログボタン情報。
 * @type {[type: string]: 'ok'|'okcancel'}
 */
const BUTTON_TYPES = {
  /** 情報 */
  I: 'ok',

  /** 警告 */
  W: 'okcancel',

  /** エラー */
  E: 'ok',

  /** 確認 */
  C: 'okcancel',

  /** システムエラー */
  S: 'ok',
};

/**
 * @typedef MessageInfo メッセージ情報
 * @property {string} id メッセージID。'message.'部分は不要
 * @property {string[]} [values] メッセージ可変部分
 */

/**
 * @typedef DialogMessageInfo ダイアログメッセージ情報
 * @property {string|MessageInfo|(string|MessageInfo)[]} message メッセージ情報。文字列の場合メッセージID
 * @property {Function} [action] OKボタン押下時の処理
 * @property {'info'|'warn'|'error'|'confirm'|'systemerror'} [type] ダイアログ種別。
 */

/**
 * ダイアログ表示。
 * @param {DialogMessageInfo} {message, action, type}
 * @return {void}
 */
const doShowMessage = ({message, action, type}) => {
  // メッセージの型をMessageInfo[]に統一する
  const messages = createMessages(message);

  let messageType;
  if (type == null) {
    // ダイアログ種別が指定されていない場合、先頭メッセージのID 2文字目から種別を取得
    messageType = messages[0].id.substring(1, 2);
  } else {
    // ダイアログ種別が指定されている場合は定義から種別を取得
    messageType = DIALOG_TYPES[type];
  }

  // メッセージIDにプレフィックスを追加
  setPrefix(messages);

  const dialog = {
    type: BUTTON_TYPES[messageType],
    title: 'dialog.title.' + messageType,
    context: messages,
    action: action,
    open: true,
    address: window.location.pathname,
  };

  return (dispatch) => {
    dispatch(openDialog(dialog));
  };
};

/**
 * @typedef DialogInfo ダイアログメッセージ情報(doOpenDialog用)
 * @property {'ok'|'okcancel'} type ボタン種別。
 * @property {string} title タイトルID
 * @property {string|MessageInfo|(string|MessageInfo)[]} message メッセージ情報。文字列の場合メッセージID
 * @property {Function} [action] OKボタン押下時の処理
 */

/**
 * ダイアログの表示。
 *
 * @deprecated doShowMessageを使用してください
 * @param {DialogInfo} {type, title, message, action}
 * @return {void}
 */
const doOpenDialog = ({type, title, message, action}) => {
  // メッセージの型をMessageInfo[]に統一する
  const messages = createMessages(message);

  dialogInfo.type = type;
  dialogInfo.title = title;
  dialogInfo.context = messages;
  dialogInfo.action = action;
  dialogInfo.open = true;
  dialogInfo.address = window.location.pathname;
  return (dispatch) => {
    dispatch(openDialog(dialogInfo));
  };
};

/**
 * メッセージの型変換。
 * メッセージをMessageInfo[]に変換して返却する。
 *
 * @param {string|MessageInfo|(string|MessageInfo)[]} message メッセージ情報
 * @return {MessageInfo[]} 変換後のメッセージ情報
 */
function createMessages(message) {
  // 配列の場合
  if (Array.isArray(message)) {
    const ret = [];
    for (const item of message) {
      if (typeof item === 'string') {
        // stringの場合はの場合はMessageInfo化
        ret.push({id: item});
      } else {
        // MessageInfoの場合はコピーして設定
        // メッセージを上書きしてしまうため。
        let copyItem = {};
        Object.assign(copyItem, item);
        ret.push(copyItem);
      }
    }

    return ret;
  }

  // string型の場合はMessageInfo[]化して返却
  if (typeof message === 'string') {
    return [{id: message}];
  }

  // object型の場合(MessageInfo)は配列化して返却
  return [message];
}

/**
 * プレフィックスの付加。
 * メッセージIDの先頭にプレフィックス"message."を付加する
 *
 * @param {MessageInfo[]} messages メッセージ一覧
 */
function setPrefix(messages) {
  for (const item of messages) {
    item.id = 'message.' + item.id;
  }
}

const modalInfo = {
  btnExist: false,
  open: false,
};

const doOpenModal = (btnExist) => {
  modalInfo.btnExist = btnExist;
  modalInfo.open = true;
  return (dispatch) => {
    dispatch(openModal(modalInfo));
  };
};

const doCloseModal = () => {
  return (dispatch) => {
    dispatch(closeModal());
  };
};

// 住所検索
const doGetPost = (zipcode) => {
  /*
    return async(dispatch, getState) => {
        return $.getJSON('https://zip-cloud.appspot.com/api/search?callback=?',
            {
                zipcode: zipcode
            }
        );
    };
    */

  return async (dispatch, getState) => {
    // 引数：住所検索用郵便番号
    return await axios.post(conf.API_URL + '/Common/getAddress',
        {zipCode: zipcode}, {
        });
  };
};

const accessInfo = {
  time: null,
};

const doCheckAccess = () => {
  // UTC変換したタイムスタンプを保持
  accessInfo.time = (
    new Date(Date.now() + new Date().getTimezoneOffset() * 60000)).getTime();
  return (dispatch) => {
    dispatch(checkAccess(accessInfo));
  };
};

/**
 * GETパラメータを解析して保持
 * @param {string} search props.location.searchを指定
 * @return {function}
 */
const doParseGetParam = (search) => {
  return (dispatch) => {
    const qs = queryString.parse(search);
    dispatch(getParam(qs));
    return qs;
  };
};

/**
 * 保持しているGETパラメータを破棄
 * @return {function}
 */
const doClearGetParam = () => {
  return (dispatch) => {
    dispatch(getParam(null));
  };
};

/** 汎用マスタ取得用パラメータ */
const GET_GENERAL_PURPOSE_PARAM = {
  conditions: {
    CategoryType__c: {
      $in: [
        'ApprovalStatus',
        'AttachType',
        'AttachWay',
        'AttachedFileType',
        'AwsApiUserName',
        'BillingCategory',
        'BillingStatus',
        'BillingTiming',
        'CableSnsyu',
        'ClosestDrStbStbName',
        'ContactZikoType',
        'DirectionCategory',
        'DisplayMaxKensu',
        'DntCategory',
        'DrDntKoziContents',
        'DrSideKoziKinds',
        'ENECOMKyogaZgsyaCode',
        'GaisanAmountStatus',
        'GaisanCostStatus',
        'GroundCategory',
        'IsetuIraiStatus',
        'IsetuIraiTarget',
        'IskkCategory',
        'IstIriNoticeTarget',
        'KiknClosestDrStbName',
        'KirikaeDt',
        'KyogaIsetuKoziContents',
        'KyogaType',
        'KyogaCategory',
        'KyogasyaCheckStatus',
        'ListDisplayPeriod',
        'NWDesignZgsyaCode',
        'NWZgsyo',
        'NecessityCategory',
        'OrderCategory',
        'OrderStatus',
        'PositionCategory',
        'PrefCode',
        'ProprietyCategory',
        'RegisterMaxKensu',
        'RepairGaisanConsentStatus',
        'RepairIraiStatus',
        'RepairKoziKinds',
        'SenroAngle',
        'SfLastModifiedName',
        'StbType',
        'TekyoType',
        'UmuCategory',
        'UnitPriceMaster',
        'UserLevel',
      ],
    },
  },
  sortParams: {CategoryType__c: 1, DisplaySort__c: 1},
};

/**
 * ログイン時必要情報の一括取得処理。
 * @return {function}
 */
const doGetLoginInfo = () => {
  return async (dispatch) => {
    const body = {
      generalPurpose: GET_GENERAL_PURPOSE_PARAM,
    };

    const response = await axios.post(conf.API_URL + 'Login/get', body);
    const responseBody = response.data.body;
    const data = responseBody.data;

    // 実行結果が「成功」以外の場合は処理終了
    if (responseBody.errorCode != API_CODE.SUCCESS) {
      return responseBody;
    }

    // 共架種別を配列に分割して保持する
    const userInfo = data.currentUser;
    setUserKyogaType(userInfo);

    // ユーザ情報をストアへ登録
    dispatch(getUserInfo(userInfo));

    // 汎用マスタをストアへ登録
    const map = _createGeneralPurposeMap(data.generalPurpose);
    dispatch(getAllGeneralPurposeMap(map));

    // 約款承諾状態をストアへ登録
    dispatch(setIsTermsAccepted(data.isTermsAccepted));

    // 未確認重要事項確認有無をストアへ登録
    dispatch(setHasJuyoZiko(data.hasJuyoZiko));

    // 買取保留状態をストアへ登録
    dispatch(setIsHoldPurchase(data.isHoldPurchase));

    // ログイン日時をストアへ登録
    dispatch(setLoginDate(data.systemDate));

    return data;
  };
};

/**
 * 約款承諾状態の設定。
 *
 * @param {boolean} isTermsAccepted 承諾済の場合true、未承諾の場合false
 * @return {function}
 */
const doSetIsTermsAccepted = (isTermsAccepted) => {
  return (dispatch) => {
    dispatch(setIsTermsAccepted(isTermsAccepted));
  };
};

/**
 * 未確認重要事項確認有無の設定
 * @param {boolean} hasJuyoZiko 未確認重要事項確認が存在する場合true、存在しない場合false
 * @return {function}
 */
const doSetHasJuyoZiko = (hasJuyoZiko) => {
  return (dispatch) => {
    dispatch(setHasJuyoZiko(hasJuyoZiko));
  };
};

/**
 * 買取保留状態の設定
 * @param {boolean} isHoldPurchase 買取保留状態
 * @return {function}
 */
const doSetIsHoldPurchase = (isHoldPurchase) => {
  return (dispatch) => {
    dispatch(setIsHoldPurchase(isHoldPurchase));
  };
};

/**
 * ログイン日時(UTC)の設定
 * 形式:yyyy-MM-ddTHH:mm:ss.SSSxxxx
 *
 * @param {string} dateTime ログイン日時の文字列
 * @return {function}
 */
const doSetLoginDate = (dateTime) => {
  return (dispatch) => {
    dispatch(setLoginDate(dateTime));
  };
};

/**
 * システム切替日時判定フラグの設定
 * true: システム切替日時より前, false:切替日時以降
 * @param {string} loginDate
 * @param {string} kirikaeDate
 * @return {function}
 */
const doSetIsKirikaeMae = (loginDate, kirikaeDate) => {
  return (dispatch) => {
    let isKirikaeMae = true;

    if (loginDate && kirikaeDate) {
      const nowDt = (new Date(new Date(loginDate).getTime() +
        new Date().getTimezoneOffset() * 60000)).getTime();
      const KirikaeDt = (new Date(new Date(kirikaeDate)
          .getTime() + new Date().getTimezoneOffset() * 60000)).getTime();
      isKirikaeMae = (nowDt < KirikaeDt);
    }

    dispatch(setIsKirikaeMae(isKirikaeMae));
  };
};

/**
 * 共架種別の変換と再設定。
 *
 * セミコロンで分割された共架種別を、分割して配列として設定しなおします。
 * @param {object} userInfo ユーザ情報
 */
const setUserKyogaType = (userInfo) => {
  const account = userInfo.Account;

  if (!account || !account.Account__r) {
    // 会社が未設定の場合は処理終了
    return;
  }

  const kyogaType = account.Account__r.KyogaType__c;
  if (Array.isArray(kyogaType)) {
    // 既に配列となっている場合は処理終了
    return;
  }

  const KyogaTypeStr = kyogaType ? kyogaType : '';
  account.Account__r.KyogaType__c = KyogaTypeStr.split(';');
};

/**
 * 汎用マスタを全取得しMAPとして保持。
 *
 * 画面から使用する汎用マスタのみを取得する。
 * 一度本処理を実行すると、以降はデータを取得しない。
 * 強制取得フラグにtrueを指定した場合は再取得する。
 *
 * @param {boolean} force 強制取得フラグ
 * @return {Object} 取得結果MAP
 */
const doGetAllGeneralPurposeMap = (force = false) => {
  console.log('汎用マスタ 全取得...');

  return async (dispatch, getState) => {
    const {auth, common} = getState();

    // すでに取得済の場合は処理終了
    if (!force && common.generalPurposeMap) {
      console.log('データ取得済のため終了');
      return;
    }

    // 画面で使用する汎用マスタの種別一覧を設定する
    const ret = await axios.post(conf.API_URL + 'GeneralPurpose/get', GET_GENERAL_PURPOSE_PARAM, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const map = _createGeneralPurposeMap(ret.data.body.data);

    dispatch(getAllGeneralPurposeMap(map));
  };
};

/**
 * 取得した汎用マスタ一覧からマップを生成
 * @param {object[]} data 汎用マスタ一覧
 * @return {object} 汎用マスタマップ
 */
const _createGeneralPurposeMap = (data) => {
  const list = data ? data : [];

  const map = {};
  for (const data of list) {
    if (!map.hasOwnProperty(data.CategoryType__c)) {
      map[data.CategoryType__c] = [];
    }

    map[data.CategoryType__c].push(data);
  }

  return map;
};

/**
 * 汎用マスタ 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Object} 取得結果一覧
 */
const doGetGeneralPurposeList = (conditions, fields, sortParams) => {
  console.log('汎用マスタ 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'GeneralPurpose/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(getGeneralPurposeList(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 汎用マスタ ID指定取得
 * @param {Object} id ID
 * @return {Object} 取得結果
 */
const doRetrieveGeneralPurpose = (id) => {
  console.log('汎用マスタ ID指定取得...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      Id: id,
    };
    console.dir(body);
    const ret = await axios.post(conf.API_URL + 'GeneralPurpose/Retrieve', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    console.dir(ret.data);
    dispatch(getGeneralPurposeList(
      ret.data.body.data ? ret.data.body.data : null));
  };
};

/**
 * 汎用マスタ 登録
 * @param {Object} data 登録する内容
 * @return {Object} 登録結果
 */
const doRegistGeneralPurpose = (data) => {
  console.log('汎用マスタ 登録...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'GeneralPurpose/regist', data, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(registGeneralPurpose(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

const doUpdateGeneralPurpose = (id, values) => {
  console.log('汎用マスタ 更新...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'GeneralPurpose/update', data, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateGeneralPurpose(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

const doSetGeneralPurposeId = (id) => {
  console.log('汎用マスタID 設定...');

  return async (dispatch) => {
    dispatch(setGeneralPurposeId(id));
  };
};

const doGetNWZgsyoNamefilter = (NWZgsyoList, Namefilter) => {
  console.log('事業所 取得...');
  let nwzgsyoNamefilter = [];
  if (isNullOrWhiteSpace(Namefilter)) {
    nwzgsyoNamefilter = NWZgsyoList;
  } else {
    nwzgsyoNamefilter = NWZgsyoList.filter((item) => {
      return item.Name.includes(Namefilter);
    });
  }
  return async (dispatch) => {
    dispatch(getNwzgsyoNamefilter(nwzgsyoNamefilter));
  };
};

/**
 * 会社マスタ一覧 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Object} addFields 追加設定するフィールド({フィールド名：値})
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetAccountList = (conditions, fields, sortParams, addFields) => {
  console.log('会社マスタ一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'Account/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getAccountList(
      ret.data.body.data ? ret.data.body.data : null);

    if (addFields && retData.accountList) {
      // 検索結果に項目追加
      const size = Object.keys(retData.accountList).length;
      for (let i = 0; i < size; i++) {
        Object.assign(retData.accountList[i], addFields);
      }
    }
    dispatch(retData);
    return ret;
  };
};
/**
 * 会社マスタ一覧 取得 の破棄
 * @return {function}
 */
const doClearAccountList = () => {
  console.log('会社マスタ一覧 取得 を破棄...');
  return async (dispatch) => {
    dispatch(getAccountList(null));
  };
};

/**
 * 会社マスタ ID指定取得
 * @param {Object} id ID
 * @return {Object} 取得結果
 */
const doRetrieveAccount = (id) => {
  console.log('会社マスタ ID指定取得...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      Id: id,
    };
    console.dir(body);
    const ret = await axios.post(conf.API_URL + 'Account/Retrieve', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    console.dir(ret.data);
    dispatch(retrieveAccount(ret.data.body.data ? ret.data.body.data : null));
  };
};

/**
 * 会社マスタ ID設定
 *
 * @param {string} id 設定するID
 * @return {void} 設定結果
 */
const doSetAccountId = (id) => {
  console.log('会社マスタID 設定...');

  return async (dispatch) => {
    dispatch(setAccountId(id));
  };
};

/**
 * 会社マスタ一覧 取得（制限なし）
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetAccountMasterList = (conditions, fields, sortParams) => {
  console.log('会社マスタ一覧 取得（制限なし）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'Account/getAccoutMaster', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    const data = ret.data.body.data ? ret.data.body.data : [];
    dispatch(getAccountMasterList(data));
    return ret;
  };
};

/**
 * 会社マスタ一覧 取得（制限なし）の破棄
 * @return {function}
 */
const doClearAccountMasterList = () => {
  console.log('会社マスタ一覧 取得（制限なし）を破棄...');
  return async (dispatch) => {
    dispatch(getAccountMasterList(null));
  };
};
const doSetAccountMasterList = (data) => {
  console.log('会社マスタ一覧 設定...');
  return async (dispatch) => {
    dispatch(getAccountMasterList(data));
  };
};

/**
 * 会社 更新
 * @param {Object} conditions 検索条件
 * @param {Object} params 更新する内容
 * @param {string} stage MKS-apiのバリデーション定義参照
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doUpdateAccount = (conditions, params, stage) => {
  console.log('会社 更新...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      conditions: conditions,
      params: params,
      stage: stage,
    };

    const ret = await axios.post(conf.API_URL + 'Account/update', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateAccount(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 会社 更新(複数可)
 * @param {Object} conditions 検索条件
 * @param {Object} params 更新する内容
 * @param {string} stage KMS-apiのバリデーション定義参照
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doUpdateAccounts = (conditions, params, stage) => {
  console.log('会社 更新(複数可)...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      conditions: conditions,
      params: params,
      stage: stage,
    };
    console.log(body);
    const ret = await axios.post(conf.API_URL + 'Account/multipleUpdates', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateAccounts(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 担当者マスタ 一覧取得
 * @param {object} conditions
 * @param {string|string[]} fields
 * @return {Promise<AxiosResponse<any>>}
 */
const doGetContactList = (conditions, fields) => {
  console.log('担当者マスタ 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    const ret = await axios.post(conf.API_URL + 'Contact/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : [];
    dispatch(getContactList(data));

    return ret;
  };
};

/**
 * 担当者マスタ 1件取得
 * @param {string} id レコードID
 * @param {string|string[]} fields
 * @return {function}
 */
const doGetContact = (id, fields) => {
  console.log('担当者マスタ 1件取得...');

  const body = {
    conditions: {Id: id},
    fields: fields,
  };

  return async (dispatch) => {
    const ret = await axios.post(conf.API_URL + 'Contact/get', body);
    const retBody = ret.data.body;
    const data = retBody.data;
    const contact = (data && data.length > 0) ? data[0] : null;
    dispatch(getContact(contact));
    return retBody;
  };
};

/**
 * 担当者マスタ 1件取得の破棄
 * @return {function}
 */
const doClearContact = () => {
  console.log('担当者マスタ 1件取得を破棄...');
  return async (dispatch) => {
    dispatch(getContact(null));
  };
};

/**
 * 担当者マスタ ID設定
 * @param {number} id 担当者マスタID
 * @return {Promise<void>} 実行結果
 */
const doSetContactId = (id) => {
  console.log('担当者マスタID 設定...');

  return async (dispatch) => {
    dispatch(setContactId(id));
  };
};

/**
 * 担当者マスタ 一覧取得（制限なし）
 * @param {object} conditions
 * @param {string|string[]} fields
 * @param {Object} [sortParams] 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)1
 * @return {Promise<AxiosResponse<any>>}
 */
const doGetContactMasterList = (conditions, fields, sortParams) => {
  console.log('担当者マスタ 取得（ソート）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'Contact/getContactMaster', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : [];
    dispatch(getContactMasterList(data));

    return ret;
  };
};

/**
 * 担当者 テンポラリ情報登録。
 * @param {object} tempContact 担当者情報
 * @return {function}
 */
const doSetTempContact = (tempContact) => {
  console.log('担当者 テンポラリ情報登録...');

  return async (dispatch) => {
    dispatch(setTempContact(tempContact));
  };
};

/**
 * 担当者 テンポラリ情報破棄。
 * @param {object} tempContact 担当者情報
 * @return {function}
 */
const doClearTempContact = () => {
  console.log('担当者 テンポラリ情報破棄...');

  return async (dispatch) => {
    dispatch(setTempContact(null));
  };
};

/**
 * ファイルアップロード
 * @param {Object} files 登録するファイル
 *      {
 *        LinkedEntityId: 'リンクしたい情報のID(申込テーブルのID等)',
 *        Contents : [{
 *          PathOnClient: 'ファイル名',
 *          VersionData: 'base64変換したオブジェクト',
 *          AttachedFileType__c: 'コード仕様表の添付ファイル種別',
 *        }, ...]
 *      }
 * @return {Promise<AxiosResponse<any>>} 登録結果
 */
const doUploadAttachedFiles = (files) => {
  console.log('ファイルアップロード...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'AttachedFile/regist', files, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    let result = [];
    if (ret.data.body.errorCode == '00000') {
      result = ret.data.body.data;
      dispatch(registAttachedFiles(result));
    } else {
      dispatch(registAttachedFiles(result));
    }

    return result;
  };
};

/**
 * 添付ファイル 一覧取得
 * @param {object} conditions
 * @param {string|string[]} fields ※削除予定
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 * @return {Promise<AxiosResponse<any>>}
 */
const doGetAttachedFiles = (conditions, fields, sortParams) => {
  console.log('添付ファイル一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    return new Promise(async function(resolve, reject) {
      try {
        let result = true;
        const files = [];
        const promises = [];
        const contentDocumentIds = conditions.contentDocumentIds;
        for (const contentDocumentId of contentDocumentIds) {
          const body = {
            conditions: {
              contentDocumentId: contentDocumentId,
              attachedFileTypes: conditions.attachedFileTypes,
            },
          };
          const promise = await axios.post(conf.API_URL + 'AttachedFile/getAttachedFile', body, {
            headers: {Authorization: auth.loginInfo.idToken},
          });
          promises.push(promise);
        }

        Promise.all(promises).then((responses) => {
          for (const res of responses) {
            if (res &&
              Object.hasOwnProperty.call(res, 'data') &&
              Object.hasOwnProperty.call(res.data, 'body') &&
              Object.hasOwnProperty.call(res.data.body, 'errorCode') &&
              res.data.body.errorCode == '00000') {
              const file =
                res.data.body.data && res.data.body.data.length > 0 ?
                  res.data.body.data[0] : null;
              if (file) {
                files.push(file);
              }
            } else {
              result = false;
            }
          }

          if (result) {
            const _sortParams = {
              AttachedFileType__c: 1,
              LastModifiedDate: -1,
            };
            if (sortParams) {
              _sortParams = sortParams;
            }
            if (files.length > 0) {
              files.sort((src, dest) => {
                let order = 0;
                for (const key in _sortParams) {
                  if (Object.hasOwnProperty.call(src, key) &&
                    Object.hasOwnProperty.call(dest, key)) {
                    if (src[key] > dest[key]) {
                      order = _sortParams[key] * 1;
                      break;
                    }
                    if (src[key] < dest[key]) {
                      order = _sortParams[key] * -1;
                      break;
                    }
                  }
                }
                return order;
              });
            }
          }
          resolve({
            success: result,
            attachedFiles: files && files.length > 0 ? files : null,
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  };
};

/**
 * 添付ファイル一覧のクリア
 * @return {void} 処理結果
 */
const doClearAttachedFiles = () => {
  console.log('添付ファイル一覧 クリア...');
  return async (dispatch) => {
    dispatch(getAttachedFiles(null));
  };
};

/**
 * 添付ファイル 一括削除
 * @param {object} conditions ContentVersion.Idの配列
 * @return {Promise<AxiosResponse<any>>}
 */
const doDeleteAttachedFiles = (conditions) => {
  console.log('添付ファイル一括削除...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'AttachedFile/deleteAttachedFiles', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(deleteAttachedFiles(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * @typedef S3CreateInfo S3署名付きURL発行用情報
 * @property {string} id アップロード情報を一意に表すためのID
 * @property {string} contentType Content-Type
 * @property {string} fileName 拡張子付きファイル名
 * @property {string} subdir アップロードサブディレクトリ名
 */

/**
 * @typedef S3UploadInfo S3アップロード情報
 * @property {UploadFileInfo} uploadFile アップロードファイル情報
 * @property {S3CreateInfo} s3CreateInfo S3署名付きURL発行用情報
 */

/**
 * @typedef SignedUrlInfo ファイル登録用署名付きURL情報
 * @property {string} id アップロードファイルを判別するためのユニークなキー
 * @property {string} urls 署名付きURL
 */

/**
 * @typedef SignedUrlResult ファイル登録用署名付きURL情報
 * @property {string} uuid UUID(ファイルアップロードディレクトリ名)
 * @property {Object.<string, string>} urls ファイルIDと署名付きURLのマップ
 */

/**
 * ファイル S3アップロード
 * @param {Object.<string, UploadFileInfo[]>} files アップロードするファイル情報の一覧
 * @return {Promise<object>} ファイルアップロード結果
 */
const doUploadToS3 = (files) => {
  console.log('ファイル S3アップロード...');
  return async (dispatch, getState) => {
    return await doUploadToS3Common(files, getState, dispatch);
  };
};

/**
 * ファイル S3アップロード処理。(Operationから呼ぶ処理)
 * @param {Object.<string, UploadFileInfo[]>} files アップロードするファイル情報の一覧
 * @param {function} getState State取得処理
 * @param {function} dispatch ディスパッチ処理
 * @return {Promise<object>} ファイルアップロード結果
 */
const doUploadToS3Common = async (files, getState, dispatch) => {
  const {auth} = getState();
  const token = auth.loginInfo.idToken;

  let result = null;
  try {
    // S3アップロード用の情報生成
    const uploadFileInfo = _createUploadInfo(files);

    // 署名付きURLの発行
    const signedUrlInfo = await _getS3SiengedUrls(uploadFileInfo, token);

    // 署名付きURLを使用してファイルアップロード
    const responses =
      await _putToS3SignedUrl(uploadFileInfo, signedUrlInfo, dispatch);

    // アップロードレスポンスのチェック
    result = _checkS3UploadResult(responses, uploadFileInfo, signedUrlInfo);
  } catch (err) {
    console.error(err);
    return null;
  }

  return result;
};

/**
 * S3アップロード情報の生成
 * @private
 * @param {Object.<string, UploadFileInfo|UploadFileInfo[]>} uploadFiles アップロードするファイル情報の一覧
 * @return {Object.<string, S3UploadInfo[]>} S3アップロード情報
 */
const _createUploadInfo = (uploadFiles) => {
  /** @type Object.<string, S3UploadInfo[]> */
  const uploadFileInfo = {};

  for (const componentId of Object.keys(uploadFiles)) {
    let files = uploadFiles[componentId];

    if (!Array.isArray(files)) {
      files = [files];
    }

    for (const file of files) {
      // ContentDocumentId設定済の場合、アップロード済なので除外
      if (file.ContentDocumentId != null) {
        continue;
      }

      const id = [componentId, file.FileName].join('-');
      const s3createInfo = {
        id: id,
        contentType: file.ContentType,
        fileName: file.FileName,
        subdir: componentId,
      };

      uploadFileInfo[id] = {
        uploadFile: file,
        s3CreateInfo: s3createInfo,
      };
    }
  }

  return uploadFileInfo;
};

/**
 * 署名付きURLの取得。
 * @private
 * @param {Object.<string, S3UploadInfo[]>} uploadFileInfo S3アップロード情報
 * @param {string} token API実行用セキュリティトークン
 * @return {Promise<SignedUrlResult>} 署名付きURL情報。
 */
const _getS3SiengedUrls = async (uploadFileInfo, token) => {
  // S3アップロード情報から、署名付きURL発行用情報作成
  const uploadFiles = [];
  for (const id of Object.keys(uploadFileInfo)) {
    uploadFiles.push(uploadFileInfo[id].s3CreateInfo);
  }

  // 署名付きURL発行APIを実行
  const ret = await axios.post(conf.API_URL + 'File/getSignedUrl',
      {fileInfo: uploadFiles},
      {headers: {Authorization: token}});
  const body = ret.data.body;

  // エラーの場合
  if (body.errorCode != '00000') {
    throw new Error('API getSignedUrl 実行エラー');
  }

  return body.data;
};

/**
 * S3アップロード。
 * @private
 * @param {Object.<string, S3UploadInfo[]>} uploadFileInfo S3アップロード情報
 * @param {SignedUrlResult} signedUrlInfo 署名付きURL情報
 * @param {function} dispatch
 * @return {Promise<AxiosResponse[]>} S3アップロード結果
 */
const _putToS3SignedUrl = async (uploadFileInfo, signedUrlInfo, dispatch) => {
  const api = axios.create();
  dispatch(startRequest());

  // 署名付きURLでファイルのアップロードを行う
  /** @type import('axios').AxiosPromise<any>[] */
  const uploadPromises = [];
  for (const id of Object.keys(uploadFileInfo)) {
    const fileInfo = uploadFileInfo[id].uploadFile;

    const upInfo = {
      method: 'PUT',
      url: signedUrlInfo.urls[id],
      headers: {'Content-Type': fileInfo.ContentType},
      data: fileInfo.FileData,
    };

    uploadPromises.push(api(upInfo));
  }

  try {
    // 全てのアップロードが完了したら処理終了する
    const result = await axios.all(uploadPromises);
    return result;
  } finally {
    dispatch(endRequest());
  }
};

/**
 * S3アップロード結果のチェック。
 * @private
 * @param {AxiosResponse[]} uploadResponses アップロード結果一覧
 * @param {Object.<string, S3UploadInfo[]>} uploadFileInfo S3アップロード情報
 * @param {SignedUrlResult} signedUrlInfo 署名付きURL情報
 * @return {object} アップロード結果情報
 */
const _checkS3UploadResult = (
    uploadResponses, uploadFileInfo, signedUrlInfo) => {
  let success = true;
  const uploadFiles = [];
  const errorFiles = [];

  for (const response of uploadResponses) {
    const fileId = _getFileId(response, signedUrlInfo);
    if (fileId == null || !uploadFileInfo[fileId]) {
      // IDやファイル情報が取得できない場合はスキップ(本来ありえない)
      console.warn('情報不一致 File Id:' + fileId);
      continue;
    }

    const s3Info = uploadFileInfo[fileId].s3CreateInfo;

    // ファイル登録失敗のレスポンスがある場合は失敗
    if (response.statusText !== 'OK' && response.status !== 200) {
      success = false;
      errorFiles.push(s3Info);
      continue;
    }

    // アップロードしたファイルの情報を設定
    uploadFiles.push(s3Info);
  }

  const uuid = signedUrlInfo.uuid;

  return {
    success,
    uuid,
    uploadFiles,
    errorFiles,
  };
};

/**
 * ファイルアップロードチェックエラー時のAPI実行結果置き換え。
 *
 * S3ファイルアップロード(doUploadToS3Common)の結果をチェックし、
 * エラーの場合、かつAPI実行結果は成功の場合、
 * API実行結果のエラーコードをアップロードエラーに置き換えます。
 * @see doUploadToS3Common
 * @param {object} s3UploadResult S3ファイルアップロード結果
 * @param {object} apiResult API実行結果
 */
export function doCheckS3UploadResult(s3UploadResult, apiResult) {
  if (!s3UploadResult ||
    !Object.hasOwnProperty.call(s3UploadResult, 'uploadFiles')) {
    return;
  }
  if (s3UploadResult.uploadFiles &&
    s3UploadResult.uploadFiles.length == 0) {
    return;
  }
  const retBody = apiResult.data.body;
  if (!s3UploadResult.success &&
    retBody.errorCode == API_CODE.SUCCESS) {
    retBody.errorCode = API_CODE.ERROR_FILE_UPDATE;
  }
}

/**
 * Salesforce用 アップロードファイルS3登録処理
 *
 * @param  {...any} fileArrays 「UploadCustomコンポーネントに添付したファイル一覧」の一覧(可変)
 * @return {object} アップロードファイル情報。失敗時はnull
 */
const doUploadFilesToS3ForSf = (...fileArrays) => {
  return async (dispatch, getState) => {
    // 全てのファイル一覧を結合し、ContentDocumentIdが未設定の情報に絞り込む
    const uploadTargets = []
        .concat(...fileArrays)
        .filter((file) => !file.ContentDocumentId);

    const fileInfo = {
      uuid: '',
      uploadFiles: [],
    };

    // アップロード対象がない場合は処理終了
    if (uploadTargets.length === 0) {
      return fileInfo;
    }

    // ファイルをS3へ登録し、結果を返却する
    const uploadResult =
      await doUploadToS3Common(uploadTargets, getState, dispatch);
    if (!uploadResult) {
      return null;
    }

    fileInfo.uuid = uploadResult.uuid;
    fileInfo.uploadFiles =
      setAttachedFileType(uploadTargets, uploadResult.uploadFiles);

    return fileInfo;
  };
};

/**
 * アップロードしたファイルIDの判定。
 * @private
 * @param {AxiosResponse} response アップロード結果
 * @param {SignedUrlResult} signedUrlInfo 署名付きURL情報
 * @return {string} ファイルID。存在しない場合はnull
 */
const _getFileId = (response, signedUrlInfo) => {
  const checkUrls = signedUrlInfo.urls;
  const find = Object.keys(checkUrls).filter((key) => {
    return checkUrls[key] === response.config.url;
  });

  if (find.length > 0) {
    return find[0];
  } else {
    return null;
  }
};

/**
 * 設備種別 設定
 *
 * @param {string} stbType 設備種別
 * @return {void} 設定結果
 */
const doSetStbType = (stbType) => {
  console.log('設備種別 設定...');

  return async (dispatch) => {
    dispatch(setStbType(stbType));
  };
};

/**
 * 画面の参照状態 設定
 *
 * @param {bool} referenceMode true: 参照, false: 参照以外
 * @return {void} 設定結果
 */
const doSetReferenceMode = (referenceMode) => {
  console.log('画面の参照状態 設定...');

  return async (dispatch) => {
    dispatch(setReferenceMode(referenceMode));
  };
};

/**
 * 遷移元画面ID設定
 *
 * @param {string} transitionSrcId 遷移元画面ID
 * @return {void} 設定結果
 */
const doSetTransitionSrcId = (transitionSrcId) => {
  console.log('遷移元画面ID 設定...');

  return async (dispatch) => {
    dispatch(setTransitionSrcId(transitionSrcId));
  };
};

/**
 * 呼び出し元 パス設定
 * @param {string} path 設定するパス
 * @return {Promise<void>} 登録結果
 */
const doSetYbdsPath = (path) => {
  console.log('呼び出し元パス 設定...');

  return async (dispatch) => {
    dispatch(setYbdsPath(path));
  };
};

/**
 * 警告設定
 * @param {string} Id 画面ID
 * @param {string} warning 警告有無
 * @return {Promise<void>} 登録結果
 */
const doSetGmnWarning = (Id, warning) => {
  console.log('画面警告 設定...');

  return async (dispatch) => {
    dispatch(setGmnWarning(Id, warning));
  };
};

const doGetDntSelectpole = (conditions) => {
  console.log('電柱 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'Dnt/getDntList', conditions, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getDntSelectpole(ret ? ret.data.body.data ?
      ret.data.body.errorCode == '00000' ? ret.data.body.data : null : null : null);
    dispatch(retData);
    return ret;
  };
};

export default {
  startRequest,
  endRequest,
  doShowMessage,
  doOpenDialog,
  closeDialog,
  doOpenModal,
  doCloseModal,
  doGetPost,
  doCheckAccess,
  doParseGetParam,
  doClearGetParam,
  doGetLoginInfo,
  doSetIsTermsAccepted,
  doSetHasJuyoZiko,
  doSetIsHoldPurchase,
  doSetLoginDate,
  doSetIsKirikaeMae,
  setUserKyogaType,
  doGetAllGeneralPurposeMap,
  doGetGeneralPurposeList,
  doRetrieveGeneralPurpose,
  doRegistGeneralPurpose,
  doUpdateGeneralPurpose,
  doSetGeneralPurposeId,
  doGetNWZgsyoNamefilter,
  doGetAccountList,
  doClearAccountList,
  doUpdateAccount,
  doUpdateAccounts,
  doRetrieveAccount,
  doSetAccountId,
  doGetAccountMasterList,
  doClearAccountMasterList,
  doSetAccountMasterList,
  doGetContactList,
  doGetContact,
  doClearContact,
  doSetContactId,
  doGetContactMasterList,
  doSetTempContact,
  doClearTempContact,
  doUploadAttachedFiles,
  doGetAttachedFiles,
  doClearAttachedFiles,
  doDeleteAttachedFiles,
  doUploadToS3,
  doUploadToS3Common,
  doUploadFilesToS3ForSf,
  doCheckS3UploadResult,
  doSetStbType,
  doSetReferenceMode,
  doSetTransitionSrcId,
  doSetYbdsPath,
  doSetGmnWarning,
  doGetDntSelectpole,
};

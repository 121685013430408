import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as wjgGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import {CollectionView} from '@grapecity/wijmo';
import {Selector} from '@grapecity/wijmo.grid.selector';
import {FlexGridFilter} from '@grapecity/wijmo.react.grid.filter';
import {FlexGrid, FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import {DataChangeAction} from '@grapecity/wijmo.grid.immutable';
import {ImmutabilityProvider} from '@grapecity/wijmo.react.grid.immutable';

import {gridOperations} from '../../reducks/grid';
import AddIconButton from '../atoms/Buttons/AddIconButton.js';
import DeleteIconButton from '../atoms/Buttons/DeleteIconButton.js';
import {dntNoFormat, senroNameFormat, isSmartPhone} from '../../common/common.js';

/* usage
  // gridのメソッドを操作するためにrefを作成
  constructor() {
    this.gridRef = React.createRef();
  }

  // render を実装
  render() {
    let props = {
      headersVisibility: "Column", // 行ヘッダ、列ヘッダの表示設定
                                   // "All" : 列ヘッダセルと行ヘッダセルの両方表示
                                   // "Column" : 列ヘッダセルのみ表示
                                   // "None" : 表示なし
                                   // "Row" : 行ヘッダセルのみ表示
      allowSorting: "SingleColumn", // 列ソート
                                    // "MultiColumn" : 一度に複数列ソート可
                                    // "None" : ソートなし
                                    // "SingleColumn" : 一度に1列ずつソート可
      allowDragging: "Both", // 行、列のD&D設定
                             // "Both" :  行、列両方可
                             // "Columns" : 列のみ可
                             // "None" : D&Dなし
                             // "Rows" : 行のみ可
      frozenRows: 1, // 行固定(デフォルト0で固定なし)
      frozenColumns: 1, // 列固定(デフォルト0で固定なし)
      initItems: {key1: value1, ...} // 項目と初期値を設定。
                                     // 行追加したときにinitItemsに設定した項目と初期値をグリッドに追加する。
                                     // 設定しないときはpropsに書かない。
      style: {maxHeight: "600px"}, // グリッドのスタイル設定
      rowHeaderType: "check", // 行ヘッダのスタイルを設定。
                              // "none" : なし
                              // "check" : チェックボックス有効
                              // "radio" : ラジオボタン有効
                              // "edit" : 編集ダイアログ有効
      filterOn: true, // 列フィルター有効無効設定
      exceptFilters: ['id'], // filterOn=trueのとき、フィルターを無効にする列を設定
      counterOn: true, // グリッドに設定したデータ件数表示有無を設定
      AddDeleteOn: true, // 行追加削除表示設定
      isReadOnly: true, // true:グリッド編集不可, false:グリッド編集可。未指定の場合はfalse
      useStore: true, // true:ストア使用。デフォルト。false:ストアを使用しない。
                         useStore=true or 未設定かつdataなしの場合、ストアを使用する
                         useStore=true or 未設定かつdataありの場合、ストアを使用しない
                         useStore=falseの場合、dataがあってもなくてもストアを使用しない
      editPopupItems: function // 編集ダイアログ処理
      refreshedFunction: function // グリッド更新時の処理
      loadedRowsFunction: function // 行がデータソースにバインドされた時に発生する処理
      formatItemFunction: function // セルを表す要素が作成されたときに発生する処理
      checkedFunction: function // チェックボックスがクリックされたときに発生する処理
      doShowMessage: function // this.props.doShowMessageを渡す
      getError: function   // 項目の特定のプロパティに検証エラーが含まれているかどうかを判定する処理
      validateEdits: true, // 検証エラー時に編集モードを維持可否
                           // true:編集モード維持, false:編集モード解除。未指定の場合はtrue
      filterChanging: function, // 列フィルターを編集する処理
      customDataMapBinds: string[], // カスタムDataMapを使用しているbinding項目名
      onCellEditEnding: function, // セル編集終了時のカスタム処理

      //ストアを使わない場合、以下のプロパティを設定してください。
      data: [], //一覧に表示するデータ。
    }
    <CustomFlexGrid ref={this.gridRef} {...props}>
      // 子コンポーネントを記載
    </CustomFlexGrid>
  }

  // 初期値を設定する
  // タイミングは初期処理以外でも可
  // ストアを使わない場合はsetInitItemsを使用しない。
  componentDidMount() {
    this.gridRef.current.setInitItems('1007', [{初期データ}, ...]);
  }

    参考: https://www.grapecity.co.jp/developer/wijmo
*/

const styles = (theme) => ({
});

const MAX_DNT_COUNT = 30;
const RADIO_UNIQUE_KEY = 'Id';

class CustomFlexGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenId: null,
    };
    this.selector = null;
    this.grid = null;
    this.selectedItems = [];
    this.itemCount = 0;
    this.radioCheckId = null;
    this.dragIndex = null;
    this.filter = null;
    this.isRestore = false;
    this.frozenRows = 0;
    this.frozenColumns = 0;

    // 列ヘッダーのグループをまとめて移動するために使用する
    this.dragCol = null;
    this.dragRow = null;
    this.mr = null;
    this.mrs = [];

    this.initialized = this.initialized.bind(this);
    this.initializedFilter = this.initializedFilter.bind(this);
    this.onGridDataChanged = this.onGridDataChanged.bind(this);
    this.onDraggingRow = this.onDraggingRow.bind(this);
    this.onDraggedRow = this.onDraggedRow.bind(this);
    this.onDraggingRowNoStore = this.onDraggingRowNoStore.bind(this);
    this.onDraggedRowNoStore = this.onDraggedRowNoStore.bind(this);
    this.showRadioButton = this.showRadioButton.bind(this);

    this.checkedFunction = this.props.checkedFunction;
    this.editPopupItems = this.props.editPopupItems;
    this.refreshedFunction = this.props.refreshedFunction;
    this.loadedRowsFunction = this.props.loadedRowsFunction;
    this.formatItemFunction = this.props.formatItemFunction;
    this.doShowMessage = this.props.doShowMessage;
    this.filterChanging = this.props.filterChanging;
    this.customDataMapBinds = this.props.customDataMapBinds;
    this.onCellEditEnding = this.props.onCellEditEnding;

    // useStore未設定はtrueにする
    // dataなしでtrueのときはストアを使用する
    // dataありはストアを使用しない
    // data有無に関わらず、ストアを使用しない場合はuseStore=falseを指定してもらう
    this.priorityStore = 'useStore';
    if (Object.hasOwnProperty.call(this.props, 'useStore') &&
     this.props.useStore == false) {
      this.priorityStore = 'noStoreNoInitData';
    } else {
      if (Object.hasOwnProperty.call(this.props, 'data')) {
        this.priorityStore = 'noStore';
      }
    }
  }

  render() {
    this.frozenRows = isSmartPhone() ? 0 :
      this.props.frozenRows ? this.props.frozenRows : 0;
    this.frozenColumns = isSmartPhone() ? 0 :
      this.props.frozenColumns ? this.props.frozenColumns : 0;
    if (this.priorityStore == 'useStore') {
      return this.renderStore();
    } else if (this.priorityStore == 'noStore') {
      return this.renderNoStore();
    } else {
      return this.renderNoStoreNoInitialData();
    }
  }

  renderStore() {
    const {counterOn, items} = this.props;
    return (
      <React.Fragment>
        {counterOn &&
          <Typography variant="caption" display="block">
            {items ? items.length : 0} 件
          </Typography>
        }
        <FlexGrid
          autoGenerateColumns={false}
          imeEnabled={true}
          initialized={this.initialized}
          deferResizing={false}
          showMarquee={true}
          alternatingRowStep={1}
          headersVisibility={this.props.headersVisibility ? this.props.headersVisibility : 'All'}
          allowMerging="ColumnHeaders"
          allowSorting={this.props.allowSorting ? this.props.allowSorting : 'MultiColumn'}
          allowDragging={this.props.allowDragging ? this.props.allowDragging : 'Columns'}
          selectionMode="MultiRange"
          style={this.props.style ? this.props.style : {height: 'auto'}}
          frozenRows={this.frozenRows}
          frozenColumns={this.frozenColumns}
          isReadOnly={!!this.props.isReadOnly}
          validateEdits={
            typeof this.props.validateEdits !== 'undefined' ? this.props.validateEdits : true}
          draggingRow={this.onDraggingRow}
          draggedRow={this.onDraggedRow}
          showRadioButton={this.showRadioButton}
        >
          <ImmutabilityProvider
            itemsSource={items}
            dataChanged={this.onGridDataChanged}/>
          {this.props.AddDeleteOn && <FlexGridColumnGroup binding="edit" header=" " align="center" width={60}/>}
          {this.props.children}
          {this.props.filterOn &&
            <FlexGridFilter initialized={this.initializedFilter}/>}
        </FlexGrid>
        <DeleteIconButton isDisabled={!!this.props.isReadOnly}/>
        <AddIconButton isDisabled={!!this.props.isReadOnly}/>
      </React.Fragment>
    );
  }

  renderNoStore() {
    const {data, initItems, getError, counterOn} = this.props;
    const itemData = new CollectionView(
        data,
        {
          trackChanges: true, // 変更管理ON
          newItemCreator: function() {
            // 項目は新しく作成すること
            // 同じ変数を返すとGridが同じ行とみなし、同じデータが入ってしまう
            const items = {};
            for (const key in initItems) {
              if (Object.hasOwnProperty.call(initItems, key)) {
                items[key] = initItems[key];
              }
            }
            return items;
          },
          getError: getError,
        },
    );

    if (this.itemCount != itemData.itemCount) {
      this.itemCount = itemData.itemCount;
      this.radioCheckId = null;
    }

    return (
      <React.Fragment>
        {counterOn &&
          <Typography variant="caption" display="block">
            {itemData.itemCount} 件
          </Typography>
        }
        {!data &&
          <FlexGrid
            autoGenerateColumns={false}
            imeEnabled={true}
            initialized={this.initialized}
            deferResizing={false}
            showMarquee={true}
            alternatingRowStep={1}
            headersVisibility={this.props.headersVisibility ? this.props.headersVisibility : 'All'}
            allowMerging="ColumnHeaders"
            allowSorting={this.props.allowSorting ? this.props.allowSorting : 'MultiColumn'}
            allowDragging={this.props.allowDragging ? this.props.allowDragging : 'Columns'}
            selectionMode="MultiRange"
            style={this.props.style ? this.props.style : {height: 'auto'}}
            frozenRows={this.frozenRows}
            frozenColumns={this.frozenColumns}
            isReadOnly={!!this.props.isReadOnly}
            validateEdits={
                  typeof this.props.validateEdits !== 'undefined' ? this.props.validateEdits : true}
            draggingRow={this.onDraggingRowNoStore}
            draggedRow={this.onDraggedRowNoStore}
          >
            {this.props.AddDeleteOn && <FlexGridColumnGroup binding="edit" header=" " align="center" width={60}/>}
            {this.props.children}
            {this.props.filterOn &&
                  <FlexGridFilter initialized={this.initializedFilter}/>}
          </FlexGrid>
        }
        {data &&
          <FlexGrid
            autoGenerateColumns={false}
            imeEnabled={true}
            itemsSource={itemData}
            initialized={this.initialized}
            deferResizing={false}
            showMarquee={true}
            alternatingRowStep={1}
            headersVisibility={this.props.headersVisibility ? this.props.headersVisibility : 'All'}
            allowMerging="ColumnHeaders"
            allowSorting={this.props.allowSorting ? this.props.allowSorting : 'MultiColumn'}
            allowDragging={this.props.allowDragging ? this.props.allowDragging : 'Columns'}
            selectionMode="MultiRange"
            style={this.props.style ? this.props.style : {height: 'auto'}}
            frozenRows={this.frozenRows}
            frozenColumns={this.frozenColumns}
            isReadOnly={!!this.props.isReadOnly}
            validateEdits={
                    typeof this.props.validateEdits !== 'undefined' ? this.props.validateEdits : true}
            draggingRow={this.onDraggingRowNoStore}
            draggedRow={this.onDraggedRowNoStore}
          >
            {this.props.AddDeleteOn && <FlexGridColumnGroup binding="edit" header=" " align="center" width={60}/>}
            {this.props.children}
            {this.props.filterOn &&
                    <FlexGridFilter initialized={this.initializedFilter}/>}
          </FlexGrid>
        }

        <DeleteIconButton isDisabled={!!this.props.isReadOnly}/>
        <AddIconButton isDisabled={!!this.props.isReadOnly}/>
      </React.Fragment>
    );
  }

  renderNoStoreNoInitialData() {
    const {counterOn} = this.props;
    const _sourceCollection = this.getSourceCollection();
    let itemCount = 0;
    if (_sourceCollection) {
      itemCount = _sourceCollection.length;
    }
    this.radioCheckId = null;
    return (
      <React.Fragment>
        {counterOn &&
          <Typography variant="caption" display="block">
            {itemCount} 件
          </Typography>
        }
        <FlexGrid
          autoGenerateColumns={false}
          imeEnabled={true}
          initialized={this.initialized}
          deferResizing={false}
          showMarquee={true}
          alternatingRowStep={1}
          headersVisibility={this.props.headersVisibility ? this.props.headersVisibility : 'All'}
          allowMerging="ColumnHeaders"
          allowSorting={this.props.allowSorting ? this.props.allowSorting : 'MultiColumn'}
          allowDragging={this.props.allowDragging ? this.props.allowDragging : 'Columns'}
          selectionMode="MultiRange"
          style={this.props.style ? this.props.style : {height: 'auto'}}
          frozenRows={this.frozenRows}
          frozenColumns={this.frozenColumns}
          isReadOnly={!!this.props.isReadOnly}
          validateEdits={
                  typeof this.props.validateEdits !== 'undefined' ? this.props.validateEdits : true}
          draggingRow={this.onDraggingRowNoStore}
          draggedRow={this.onDraggedRowNoStore}
        >
          {this.props.AddDeleteOn && <FlexGridColumnGroup binding="edit" header=" " align="center" width={60}/>}
          {this.props.children}
          {this.props.filterOn &&
                  <FlexGridFilter initialized={this.initializedFilter}/>}
        </FlexGrid>
        <DeleteIconButton isDisabled={!!this.props.isReadOnly}/>
        <AddIconButton isDisabled={!!this.props.isReadOnly}/>
      </React.Fragment>
    );
  }

  /**
   * Draggingイベント
   * ストアにデータを保存している場合
   * @param {object} s
   * @param {object} e
   */
  onDraggingRow(s, e) {
    // save the drag row index when dragging starts
    this.dragIndex = e.row;
    s.collectionView.moveCurrentToPosition(this.dragIndex);
  }

  /**
   * Draggedイベント
   * ストアにデータを保存している場合
   * @param {object} s
   * @param {object} e
   */
  onDraggedRow(s, e) {
    // reorder items when dragging ends
    let dropIndex = e.row;
    let arr = s.collectionView.sourceCollection;
    let item = arr[this.dragIndex];
    this.props.doMoveItemAction(
        item, this.dragIndex, dropIndex);
    s.collectionView.moveCurrentToPosition(dropIndex);
  }

  /**
   * Draggingイベント
   * @param {object} s
   * @param {object} e
   */
  onDraggingRowNoStore(s, e) {
    this.dragIndex = e.row;
  }

  /**
   * Draggedイベント
   * @param {object} s
   * @param {object} e
   */
  onDraggedRowNoStore(s, e) {
    let cv = s.collectionView;
    try {
      let dropIndex = e.row;
      cv.beginUpdate();
      cv.sortDescriptions.clear();
      let items = cv.sourceCollection;
      let selectedItem = items[this.dragIndex];
      // 選択した行を削除
      cv.sourceCollection.splice(this.dragIndex, 1);
      // 行移動
      cv.sourceCollection.splice(dropIndex, 0, selectedItem);
    } catch (error) {
      // do nothing
    } finally {
      cv.endUpdate();
      cv.refresh();
    }
  }

  /**
   * データをストアに保存する処理
   * @param {object} s
   * @param {object} e
   */
  onGridDataChanged(s, e) {
    switch (e.action) {
      case DataChangeAction.Add:
        // 追加ボタン押下時のみデータ追加が発生するため、ここは通らない
        this.props.doAddItemAction(e.newItem);
        break;
      case DataChangeAction.Remove:
        // 削除ボタン押下時のみデータ削除が発生するため、ここは通らない
        this.props.doRemoveItemAction(e.itemIndex);
        break;
      case DataChangeAction.Change:
        this.props.doChangeItemAction(e.newItem, e.itemIndex);
        break;
      default:
        throw new Error('Unknown data action');
    }
  }

  showEditIcon(s, e) {
    // 行ヘッダに編集アイコンを表示
    if (e.panel === s.rowHeaders &&
        e.col === (s.rowHeaders.columns.length - 1)) {
      e.cell.innerHTML = '<div><span id="popupEdit" class="wj-glyph-pencil"></span></div>';
    }
  }

  showRadioButton(s, e) {
    if (e.panel === s.rowHeaders &&
      e.col === (s.rowHeaders.columns.length - 1)) {
      // collectionView.itemsはグリッドのフィルタリング、ソートが適用されたデータ
      const items = s.collectionView ? s.collectionView.items : [];
      if (items.length == 0) {
        return;
      }
      // ラジオボタンのidにSFのIdを使用する。SFのIdがなければ行インデックスを使用する。
      const uniqueId = items[e.row] &&
      Object.hasOwnProperty.call(items[e.row], RADIO_UNIQUE_KEY) ?
      items[e.row].Id : e.row;
      // 行ヘッダにラジオボタンを表示
      const flgcheck = uniqueId == this.radioCheckId ? 'checked': '';
      if (flgcheck) {
        e.cell.innerHTML = '<div><input type="radio" name="envId" id="'+ uniqueId +'" checked /></div>';
      } else {
        e.cell.innerHTML = '<div><input type="radio" name="envId" id="'+ uniqueId +'" /></div>';
      }
    }
  }

  setCheckBox(grid) {
    // 行ヘッダにチェックボックスを表示
    this.selector = new Selector(grid, {
      itemChecked: (s, e) => {
        this.selectedItems = grid.rows.filter((r) => r.isSelected);

        // チェックボックスクリック時イベントがあれば実行
        if (this.checkedFunction) {
          this.checkedFunction();
        }
      },
    });
    this.selector.column.grid.headersVisibility = wjgGrid.HeadersVisibility.All;
    this.selector.column =
      this.selector.column.grid.rowHeaders.columns.length == 2 ?
      this.selector.column.grid.rowHeaders.columns[1] :
      this.selector.column.grid.rowHeaders.columns[0];
    this.selectedItems = [];
  }

  initialized(grid) {
    const {initItems, getError} = this.props;
    // イベントの中でthis.stateやコンストラクタで定義した変数を使うためにselfを定義する
    const self = this;
    this.grid = grid;

    this.dragCol = null;
    this.dragRow = null;
    this.mr = null;
    this.mrs = [];

    const rowHeaderType = this.props.rowHeaderType;
    const AddDeleteOn = this.props.AddDeleteOn;

    const showEditIcon = this.showEditIcon;
    const showRadioButton = this.showRadioButton;

    if (this.priorityStore == 'useStore') {
      // ストアでデータ管理する場合は初期処理でCollectionViewの各種設定を行う
      grid.itemsSource.trackChanges = true; // 変更追跡ON
      grid.itemsSource.newItemCreator = function() { // 新規行作成時の処理
      // 項目は新しく作成すること
      // 同じ変数を返すとGridが同じ行とみなし、同じデータが入ってしまう
        const items = {};
        for (const key in initItems) {
          if (Object.hasOwnProperty.call(initItems, key)) {
            items[key] = initItems[key];
          }
        }
        return items;
      };
      grid.itemsSource.getError = getError; // 編集時の検証エラー設定
    }

    // editの場合、行ヘッダーを2行にする
    if (rowHeaderType == 'edit') {
      grid.rowHeaders.columns.push(new wjgGrid.Column());
    }
    if (grid.rowHeaders.columns.length == 2) {
      grid.rowHeaders.columns[0].width = 15;
    }

    // 行ヘッダー設定(チェックボックス)
    if (rowHeaderType === 'check') {
      this.setCheckBox(grid);
    }

    // 左上のセルを連結して1つのセルにする
    for (let c = 0; c < grid.topLeftCells.columns.length; c++) {
      grid.topLeftCells.columns[c].allowMerging = true;
      grid.topLeftCells.columns[c].cssClassAll = 'top_left_style';
    }

    // 行追加、削除ボタン設定
    if (AddDeleteOn) {
      // add a footer row
      const footerRow = new wjgGrid.GroupRow();
      footerRow.height = 50;
      grid.columnFooters.rows.push(footerRow);
    }

    // セルの書式設定を行う
    grid.formatItem.addHandler(function(s, e) {
      const col = s.columns[e.col];
      // ヘッダーのみ折り返す
      wjCore.toggleClass(e.cell, 'wj-wrap', s.columnHeaders === e.panel);

      // 列ヘッダーフォーマット
      if (s.columnHeaders === e.panel) {
        // 列ヘッダーに\nがあれば<br/>に置換する
        if (e.cell.innerHTML &&
          e.cell.innerHTML.indexOf('\\n') != -1) {
          e.cell.innerHTML = e.cell.innerHTML.replace(/\\n/g, '<br/>');
        }
        // 列ヘッダーの文字列を中央に表示する
        if (e.cell.innerHTML) {
          let html = e.cell.innerHTML;
          // 列ヘッダーが1行の場合
          if (s.columnHeaders.rows.length == 1) {
            if (html.search(/^<div>/g) == -1) {
              e.cell.innerHTML = '<div class="wj_HeaderCenter">' + html + '</div>';
            } else {
              wjCore.toggleClass(e.cell, 'wj_blockCenter');
            }
          }
          // 列ヘッダーが2行の場合
          if (s.columnHeaders.rows.length == 2 && e.row == 1) {
            if (html.search(/^<div>/g) == -1) {
              e.cell.innerHTML = '<div class="wj_HeaderCenter">' + html + '</div>';
            } else {
              wjCore.toggleClass(e.cell, 'wj_blockCenter');
            }
          }
        }
      }

      if (AddDeleteOn) {
        // add edit button
        if (e.panel === s.cells) {
          const item = s.rows[e.row].dataItem;
          if (col.binding === 'edit') {
            e.cell.innerHTML = document.getElementById('btnMode').innerHTML; // DeleteIconButtonのid
            e.cell['dataItem'] = item;
          }
        }
        // add footer button
        if (e.panel === s.columnFooters && col.binding === 'edit' &&
            document.getElementById('btnTblFooter')) {
          e.cell.innerHTML = document.getElementById('btnTblFooter').innerHTML; // AddIconButtonのid
        }
      }

      // 行ヘッダー設定
      if (rowHeaderType === 'edit') {
        showEditIcon(s, e);
      }
      if (rowHeaderType === 'radio') {
        showRadioButton(s, e);
      }

      // グレーアウトする行はチェックボックスを非活性にする
      if ((rowHeaderType == 'check') &&
          (e.panel.cellType == wjgGrid.CellType.RowHeader)) {
        if (s.rows[e.row].cssClass == 'gray') {
          wjCore.addClass(e.cell, 'wj-state-disabled');
        } else {
          wjCore.removeClass(e.cell, 'wj-state-disabled');
        }
      }

      // ヘッダー2行目高さ調整
      if (grid.columnHeaders.rows.length == 2) {
        for (let index = 0;
          index < grid.columnHeaders.columns.length; index++) {
          // width = 文字列の長さ × 2 × font-size(16px) とする
          const width =
            grid.columnHeaders.columns[index].header.length * 2 * 16;
          const size = grid.columnHeaders.columns[index].size ?
                        grid.columnHeaders.columns[index].size : width;
          if (width > size && grid.columnHeaders.columns[index].level == 1) {
            const count = Math.round(Number( width / size ));
            const calc = 37 + 10 * count;
            if (calc > grid.columnHeaders.rows[1].height) {
              grid.columnHeaders.rows[1].height = calc;
            }
          }
        }
      }

      // セルの書式設定
      if (self.formatItemFunction) {
        self.formatItemFunction(s, e, grid);
      }
    });

    grid.resizingColumn.addHandler(function(s, e) {
      // ヘッダーのみ、列幅変更時に高さを変更し、列名を見えるようにする
      for (let i = 0; i < grid.columnHeaders.rows.length; i++) {
        if (grid.columnHeaders.getCellElement(i, e.col)) {
          const height =
            grid.columnHeaders.getCellElement(i, e.col).scrollHeight;
          if (height > grid.columnHeaders.rows.defaultSize) {
            grid.autoSizeRow(i, true);
          }
        }
      }
    });

    // コントロールが内容を更新した後で発生する処理
    grid.refreshed.addHandler(function(s, e) {
      if (self.refreshedFunction) {
        self.refreshedFunction(s, e);
      }
    });

    // グリッド行がデータソースの項目に連結された後に発生する処理
    grid.loadedRows.addHandler(function(s, e) {
      if (s.rows && self.isRestore) {
        self.restoreSelectedItems(rowHeaderType);
        // self.restoreScrollPosition(); TODO 期待通りにうごかないのであとで修正する
        if (rowHeaderType === 'check') {
          // チェックボックスの選択状態を復元
          self.onItemChecked();
        }
        if (rowHeaderType === 'radio') {
          // ラジオボタンの選択状態を復元
          if (s.rows.length == 0) {
            self.selectedItems = [];
          } else {
            const hasId = (s.rows.filter((r) => r.dataItem &&
            Object.hasOwnProperty.call(
                r.dataItem, RADIO_UNIQUE_KEY)).length > 0);
            if (hasId) {
              self.selectedItems =
              s.rows.filter((r) => r.dataItem &&
                Object.hasOwnProperty.call(r.dataItem, RADIO_UNIQUE_KEY) &&
                (r.dataItem[RADIO_UNIQUE_KEY] == self.radioCheckId));
            } else {
              self.selectedItems =
              s.rows.filter((r) => r.index == self.radioCheckId);
            }
          }
          if (self.selectedItems && self.selectedItems.length == 0) {
            grid.select(-1, -1);
          }
          if (self.checkedFunction) {
            self.checkedFunction();
          }
        }
      }
      if (self.loadedRowsFunction) {
        self.loadedRowsFunction(s, e, grid);
      }
    });

    // 列ヘッダーのグループをまとめて移動するために使用する start
    grid.draggingColumn.addHandler(function(s, e) {
      self.dragCol = e.range.col;
      self.dragRow = e.range.row;

      self.mr = s.getMergedRange(s.columnHeaders, 0, self.dragCol);

      // 子要素の場合はドラッグさせない
      if (self.dragRow == 1 && self.mr.col != self.mr.col2) {
        e.cancel = true;
        return;
      }
      if (self.dragRow == 1 &&
          self.mr.col == self.mr.col2 && self.mr.rowSpan == 1) {
        e.cancel = true;
        return;
      }

      if (s.frozenColumns > 0) {
        // ドロップ位置がfrozenColumnよりも小さい場合はキャンセル
        if (e.col < s.frozenColumns) {
          e.cancel = true;
          return;
        }
      }

      // ドラッグ時の結合状態を取得
      self.mrs.length = 0;
      for (let i = 0; i < s.columns.length; i++) {
        let tmpMr = s.getMergedRange(s.columnHeaders, 0, i);
        if (tmpMr && tmpMr.col != tmpMr.col2) {// 複数列のグループ
          self.mrs.push(tmpMr);
          i = tmpMr.col2;
        } else if (s.columnHeaders.rows.length > 1) {// 1列のグループ
          if (tmpMr && tmpMr.col == tmpMr.col2 && tmpMr.rowSpan == 1) {
            self.mrs.push(tmpMr);
            i = tmpMr.col2;
          }
        }
      }
    });

    grid.draggingColumnOver.addHandler(function(s, e) {
      // ドロップ先がドラッグ時の左側か右側か判定
      let flg = e.range.col - self.dragCol > 0;

      if (s.frozenColumns > 0) {
        // ドロップ位置がfrozenColumnよりも小さい場合はキャンセル
        if (e.col < s.frozenColumns) {
          e.cancel = true;
          return;
        }
      }

      // ドロップ先が結合セル内かどうか判定
      for (let i = 0; i < self.mrs.length; i++) {
        // ドロップ先が結合セル内の場合
        if (self.mrs[i].col <= e.range.col && self.mrs[i].col2 >= e.range.col) {
          if (self.mr.col == self.mrs[i].col &&
            self.mr.col2 == self.mrs[i].col2) { // 同一結合セル内の場合
            // キャンセル
            e.cancel = true;
            return;
          } else if (flg && self.mrs[i].col2 > e.range.col) { // ドロップ先が右側で結合セルの右端でない場合
            // キャンセル
            e.cancel = true;
            return;
          } else if (!flg && self.mrs[i].col < e.range.col) { // ドロップ先が左側で結合セルの左端でない場合
            // キャンセル
            e.cancel = true;
            return;
          } else {
            if (s.columnHeaders.rows.length > 1 && e.range.row != 0) {// グループの1行目以外の場合
              // キャンセル
              e.cancel = true;
              return;
            }
          }
        }
      }
    });
    // 列ヘッダーのグループをまとめて移動するために使用する end

    grid.beginningEdit.addHandler(function(s, e) {
      const element = e.panel.getCellElement(e.row, e.col);
      if (element && element.className) {
        if (element.className.indexOf('wj-state-disabled') != -1) {
          // 'wj-state-disabled'が設定してあるセルは編集不可
          e.cancel = true;
        }
      }
    });

    grid.hostElement.addEventListener('click', async (e) => {
      let key = '';
      if (e.target.nodeName == 'path') {
        // material UI のSVG IconはnodeNameがpathになる
        key = e.target.parentNode.id;
      } else {
        key = e.target.id;
      }
      const cv = grid.collectionView;
      switch (key) {
        case 'btnDeleteRow':
          const ht = grid.hitTest(e);
          if (ht) {
            if (this.priorityStore == 'useStore') {
              const delItem = this.props.items[ht.row];
              if (delItem && Object.keys(delItem).length > 0) {
                await this.props.doRemoveItemAction(ht.row);
                if (cv.trackChanges) {
                  cv.itemsRemoved.push(delItem);
                }
                cv.refresh();
              }
            } else {
              cv.beginUpdate();
              cv.removeAt(ht.row);
              cv.endUpdate();
            }
          }
          break;
        case 'btnAddRow':
          let _itemsCount = cv.sourceCollection.length;
          if (this.priorityStore == 'useStore') {
            _itemsCount = this.props.items.length;
          }
          if (_itemsCount < MAX_DNT_COUNT) {
            if (this.priorityStore == 'useStore') {
              const _newItem = cv.newItemCreator ? (cv.newItemCreator)() : {};
              if (Object.hasOwnProperty.call(_newItem, 'Dsp_DntNo__c')) {
                _newItem['Dsp_DntNo__c'] = '未設定';
              }
              await this.props.doAddItemAction(_newItem);
              if (cv.trackChanges) {
                cv.itemsAdded.push(_newItem);
              }
              cv.refresh();
            } else {
              const _sourceCollection = cv.sourceCollection;
              cv.beginUpdate();
              const _newItem = cv.addNew();
              if (_newItem) {
                if (Object.hasOwnProperty.call(_newItem, 'SerialNumber__c')) {
                  // 最大通し番号を取得
                  let maxSerialNumber = 0;
                  for (const item of _sourceCollection) {
                    if (maxSerialNumber < item.SerialNumber__c) {
                      maxSerialNumber = item.SerialNumber__c;
                    }
                  }
                  _newItem['SerialNumber__c'] = maxSerialNumber + 1;
                }
                if (Object.hasOwnProperty.call(_newItem, 'Dsp_DntNo__c')) {
                  _newItem['Dsp_DntNo__c'] = '未設定';
                }
                cv.commitNew();
                cv.endUpdate();
              }
            }
          } else {
            if (this.doShowMessage) {
              this.doShowMessage({
                message: {
                  id: 'CE0055',
                  values: [MAX_DNT_COUNT],
                },
              });
            }
          }
          break;
        case 'popupEdit':
          if (rowHeaderType === 'edit' && this.editPopupItems) {
            this.editPopupItems(grid, e);
          }
          break;
        default:
          break;
      }
    });

    grid.hostElement.addEventListener('change', (e) => {
      if (e.target instanceof HTMLInputElement && e.target.type === 'radio' && e.target.name === 'envId') {
        // 選択行を取得
        const hasId = (grid.rows.filter((r) => r.dataItem &&
          Object.hasOwnProperty.call(r.dataItem, RADIO_UNIQUE_KEY)).length > 0);
        self.radioCheckId = e.target.id;
        if (hasId) {
          self.selectedItems =
            grid.rows.filter((r) => r.dataItem &&
              Object.hasOwnProperty.call(r.dataItem, RADIO_UNIQUE_KEY) &&
              (r.dataItem[RADIO_UNIQUE_KEY] == self.radioCheckId));
          // ソートすると前回保存した選択状態を復元してしまうので、ラジオONにしたときに状態を保存する
          this.saveSelectedItems(this.state.screenId, 'Id');
        } else {
          self.selectedItems =
            grid.rows.filter((r) => r.index == self.radioCheckId);
        }

        // クリック時イベントがあれば設定
        if (self.checkedFunction) {
          self.checkedFunction();
        }
      }
    });

    grid.hostElement.addEventListener('mouseup', (e) => {
      // 行ヘッダーの1列目をクリックしたら行全体を選択する
      // チェックボックスがあるとgrid.selectは効かない
      let ht = grid.hitTest(e);
      if (ht.cellType == wjgGrid.CellType.RowHeader &&
          ht.col == 0 && rowHeaderType !== 'check') {
        grid.select(new wjgGrid.CellRange(
            ht.row, 0, ht.row, grid.columns.length - 1));
      }
    });

    // セル編集完了時の処理
    grid.cellEditEnding.addHandler(function(s, e) {
      // 入力値はactiveEditorから取得
      const editor = s.activeEditor;
      const inputValue = editor ? editor.value : '';
      self.cellEditEndFunc(s, e, inputValue);
    });

    // ペースト時の処理
    grid.pastingCell.addHandler(function(s, e) {
      // 入力値はイベントから取得
      const inputValue = e.data;
      self.cellEditEndFunc(s, e, inputValue);
    });
  }

  /**
   * セル編集終了時共通処理。
   *
   * @param {FlexGrid} s FlexGrid
   * @param {CellRangeEventArgs} e CellRangeEventArgs
   * @param {string} inputValue 入力値。cellEditEndingから呼ぶ際はs.activeEditor.value、pastingCellから呼ぶ際はe.dataを指定
   */
  cellEditEndFunc(s, e, inputValue) {
    let displayValue = inputValue;

    // カスタムDataMap使用判定
    const dataMap = this._checkCustomDataMapColumn(s, e);
    if (dataMap != null) {
      // 表示値を取得
      const dataItem = s.rows[e.row].dataItem;
      displayValue =
        this._getDataMapDisplayValue(dataMap, dataItem, inputValue);
      if (displayValue == null) {
        // 入力可能な文字列でない場合は入力イベントキャンセル
        e.cancel = true;
        return;
      }
    }

    // セル編集完了時の処理が指定されていれば実行
    if (this.onCellEditEnding) {
      this.onCellEditEnding(s, e, displayValue);
    }
  }

  /**
   * カスタムDataMap使用判定。
   *
   * @param {FlexGrid} s FlexGrid
   * @param {CellRangeEventArgs} e CellRangeEventArgs
   * @return {DataMap|null} カスタムDataMap使用時はDataMap。未使用時はnull
   */
  _checkCustomDataMapColumn(s, e) {
    // 対象のカラムを取得
    const column = s.columns[e.col];

    // DataMapが未設定の場合はカスタムDataMap未使用
    const dataMap = column.dataMap;
    if (!dataMap) {
      return null;
    }

    // customDataMapBindsの指定がない場合はカスタムDataMap未使用
    if (!this.customDataMapBinds) {
      return null;
    }

    // customDataMapBindsのバインド項目ではない場合はカスタムDataMap未使用
    if (!this.customDataMapBinds.includes(column.binding)) {
      return null;
    }

    return dataMap;
  }

  /**
   * 表示値の取得
   *
   * DataMapで入力を許可された文字列の場合、その文字列を返却する。
   * 許可されない文字列の場合、nullを返却する。
   * コード値が入力されていた場合は、表示値に変換して返却する。
   *
   * @param {DataMap} dataMap DataMap
   * @param {object} dataItem 行の情報
   * @param {string} value 入力値
   * @return {string|null} 表示値
   */
  _getDataMapDisplayValue(dataMap, dataItem, value) {
    const displayValues = dataMap.getDisplayValues(dataItem);

    // 入力値が表示値の場合、displayValuesに含まれればペースト可
    if (displayValues.includes(value)) {
      return value;
    } else {
      // 入力値がコード値の場合、DisplayValuesからコードを取得して比較
      // (単純にgetDisplayValue(key)を使用すると、入力不可の項目も取得されるため)
      for (const displayValue of displayValues) {
        const codeValue = dataMap.getKeyValue(displayValue);

        // 入力値と一致するコード値の場合、その表示値を返却
        if (value == codeValue) {
          return displayValue;
        }
      }
    }

    return null;
  }

  initializedFilter(filter) {
    this.filter = filter;
    const self = this;
    // フィルターが不要な列を"フィルターなし"にする
    const str = [];
    let exceptFilters = this.props.exceptFilters;
    if (!exceptFilters) {
      exceptFilters = [];
    }
    const columns = this.filter.grid.columns;
    columns.forEach(function(col) {
      if (col.binding && !exceptFilters.includes(col.binding)) {
        str.push(col.binding);
      }
    });
    this.filter.filterColumns = str;
    this.filter.showSortButtons = false;

    this.filter.filterChanging.addHandler((s, e) => {
      if (e.getColumn().dataType == wjCore.DataType.Date) {
        let edt = s.activeEditor;
        let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
        let lb = wjCore.Control.getControl(lbHost);
        lb.itemFormatter = (index) => {
          if (lb.collectionView.items[index].value == undefined ||
            lb.collectionView.items[index].value == null ||
            lb.collectionView.items[index].value == '') {
            return '(なし)';
          }
          const formattedValue = wjCore.Globalize.formatDate(
              new Date(lb.collectionView.items[index].value),
              'yyyy/MM/dd',
          );
          return formattedValue;
        };

        lb.collectionView.refresh();
      }

      if (self.filterChanging) {
        self.filterChanging(s, e);
      }
    });
  }

  /**
   * チェックボックスまたはラジオボタンで選択した行データを返す
   * @return {array} 選択行
   */
  selectedItem() {
    return this.selectedItems != null ? this.selectedItems : [];
  }

  /**
   * 選択行の件数を返す
   * @return {number}
   */
  selectedItemCount() {
    return this.selectedItems != null ? this.selectedItems.length : 0;
  }

  /**
   * チェックボックスチェンジイベント発行
   */
  onItemChecked() {
    if (this.selector) {
      this.selector.onItemChecked();
    }
  }

  /**
   * レンダー前のチェックボックス状態を復元する
   * @param {array} items RowCollection
   */
  reItemChecked(items) {
    if (!items || items.length == 0) {
      return;
    }
    if (!this.grid || !this.grid.rows ||
        this.grid.rows.length == 0) {
      return;
    }
    for (const item of items) {
      const rowIndex = this.grid.rows.indexOf(item);
      if (rowIndex != -1) {
        this.grid.rows[rowIndex].isSelected = true;
      }
    }
    this.onItemChecked();
  }

  /**
   * バインドしたデータのisSelectedを設定
   * @param {bool} selected true:選択、false:未選択
   */
  setAllSelected(selected) {
    if (this.grid != null && this.grid.rows.length > 0) {
      for (const row of this.grid.rows) {
        row.isSelected = selected;
      }
    }
  }

  /**
   * ラジオボタン、チェックボックスの選択状態復元
   * @param {string} rowHeaderType ヘッダーに表示する選択項目名
   */
  restoreSelectedItems(rowHeaderType) {
    if (this.state.screenId) {
      if (this.grid == null || this.grid.rows == null ||
        this.grid.rows.length == 0) {
        return;
      }
      const selection = this.props.selections.filter(
          (data) => data.screenId == this.state.screenId);
      if (selection.length == 0 ||
          selection[0].compareKey == null ||
          selection[0].compareKey == '' ||
          selection[0].selectedItems == null ||
          selection[0].selectedItems.length == 0) {
        return;
      }
      // キーが一致するデータを選択する
      const compareKey = selection[0].compareKey;
      const selectedItems = selection[0].selectedItems ?
        selection[0].selectedItems : [];
      if (!Object.hasOwnProperty.call(selectedItems[0], compareKey) ||
        !Object.hasOwnProperty.call(this.grid.rows[0].dataItem, compareKey)) {
        return;
      }
      for (const selectedItem of selectedItems) {
        const sameRows = this.grid.rows.filter(
            (row) => row.dataItem[compareKey] == selectedItem[compareKey]);
        if (sameRows.length > 0 && rowHeaderType === 'check') {
          sameRows[0].isSelected = true;
        }
        if (sameRows.length > 0 && rowHeaderType === 'radio') {
          const hasKey = sameRows[0].dataItem &&
            Object.hasOwnProperty.call(sameRows[0].dataItem, compareKey);
          if (hasKey) {
            this.radioCheckId = sameRows[0].dataItem[compareKey];
          } else {
            this.radioCheckId = sameRows[0].index;
          }
        }
      }
    }
  }

  /**
   * スクロール位置復元
   */
  restoreScrollPosition() {
    if (this.state.screenId) {
      if (this.grid == null || this.grid.rows == null ||
          this.grid.rows.length == 0) {
        return;
      }
      const dataList = this.props.scrollPosition.filter(
          (data) => data.screenId == this.state.screenId);
      if (dataList.length == 0 ||
        dataList[0].scrollPosition == null) {
        return;
      }
      // TODO y軸が復元できない。値を設定しても0になる
      this.grid.scrollPosition = new wijmo.Point(
          dataList[0].scrollPosition.x, dataList[0].scrollPosition.y);
      this.grid.refresh(false);
    }
  }

  /**
   * グリッドを返す
   * @return {object} グリッド本体
   */
  getGrid() {
    return this.grid;
  }

  /**
   * データを返す
   * @return {object} ストアに保存したデータ
   */
  getItems() {
    return this.props.items;
  }

  /**
   * CollectionViewのデータを返す
   * @return {object} CollectionViewのデータ
   */
  getSourceCollection() {
    if (!this.grid || !this.grid.collectionView) {
      return null;
    }
    return this.grid.collectionView.sourceCollection;
  }

  /**
   * 電柱情報を選択した行に反映する
   * @param {object} editItem 編集データ
   * @param {array} dnt 電柱選択画面の戻り値
   * @param {array} nWZgsyoList 汎用マスタの中電事業所一覧
   * @param {bool} isClearIskkAite true:一束化相手先クリア
   */
  editDnt(editItem, dnt, nWZgsyoList, isClearIskkAite = false) {
    if (editItem == null || dnt == null) {
      return;
    }

    const cv = this.grid.collectionView;
    cv.beginUpdate();
    cv.editItem(editItem);
    if (isClearIskkAite) {
      editItem['IskkAiteZgsya1__c'] = null;
      editItem['IskkAiteZgsya2__c'] = null;
      editItem['IskkAiteZgsya3__c'] = null;
    }
    for (const key of ['SzbtItiId__c', 'StbKobetuId__c',
      'DntCategory__c', 'DntNo__c', 'SenroCode__c',
      'SenroName__c', 'SenroNameKana__c', 'KyogaCategory__c',
      'DntNoManualInput__c', 'K6KansnNo__c', 'K6Bunk1__c',
      'K6Bunk2__c', 'K6Bunk3__c', 'K22SzbtNo__c',
      'K22GatiCategory__c', 'K6K22HeigaCategory__c',
      'OldNWZgsyo__c']) {
      if (Object.hasOwnProperty.call(dnt, key)) {
        editItem[key] = dnt[key];
      }
    }
    // 表示用電柱番号、表示用線路名を設定
    editItem['Dsp_DntNo__c'] = dntNoFormat(
        dnt['SenroCode__c'], dnt['DntNoManualInput__c'],
        dnt['K6KansnNo__c'], dnt['K6Bunk1__c'],
        dnt['K6Bunk2__c'], dnt['K6Bunk3__c'],
        dnt['K22SzbtNo__c'], dnt['K22GatiCategory__c']);
    editItem['Dsp_SenroName__c'] = senroNameFormat(dnt['SenroName__c'], dnt['SenroNameKana__c'], dnt['SenroCode__c']);
    // 汎用マスタから中電事業所のIdを取得して設定する
    editItem['NWZgsyo__c'] = null;
    if (nWZgsyoList) {
      const masterData = nWZgsyoList.filter(
          (data) => data.Code__c ==
          dnt.NWZgsyo__r_Code__c);
      editItem['NWZgsyo__c'] = masterData.length > 0 ?
        masterData[0].Id : null;
      editItem['NWZgsyo__r'] = masterData.length > 0 ?
        masterData[0] : {Name: '', Code__c: ''};
      editItem['NWZgsyo__r.Name'] =
        masterData.length > 0 ? masterData[0].Name : null;
      editItem['NWZgsyo__r.Code__c'] =
        masterData.length > 0 ? masterData[0].Code__c : null;
    }
    cv.commitEdit();
    cv.endUpdate();
  }

  /**
   * 複数の電柱情報を一覧に追加する
   * @param {array} dntList 電柱選択画面の戻り値
   * @param {array} nWZgsyoList 汎用マスタの中電事業所一覧
   */
  addDntList(dntList, nWZgsyoList) {
    if (dntList == null || dntList.length == 0) {
      return;
    }

    const cv = this.grid.collectionView;
    // 申込電柱は最大30件
    const count = cv.sourceCollection.length + dntList.length;
    if (count > MAX_DNT_COUNT) {
      if (this.doShowMessage) {
        this.doShowMessage({
          message: {
            id: 'CE0055',
            values: [MAX_DNT_COUNT],
          },
        });
      }
      return;
    }

    cv.beginUpdate();
    // 最大通し番号を取得
    const _sourceCollection = cv.sourceCollection;
    let maxSerialNumber = 0;
    for (const sc of _sourceCollection) {
      if (Object.hasOwnProperty.call(sc, 'SerialNumber__c')) {
        if (maxSerialNumber < sc['SerialNumber__c']) {
          maxSerialNumber = sc['SerialNumber__c'];
        }
      }
    }
    // 新規行追加
    for (const dnt of dntList) {
      const _newItem = cv.addNew();
      if (_newItem) {
        _newItem['SerialNumber__c'] = ++maxSerialNumber;
        for (const key of ['SzbtItiId__c', 'StbKobetuId__c',
          'DntCategory__c', 'DntNo__c', 'SenroCode__c',
          'SenroName__c', 'SenroNameKana__c', 'KyogaCategory__c',
          'DntNoManualInput__c', 'K6KansnNo__c', 'K6Bunk1__c',
          'K6Bunk2__c', 'K6Bunk3__c', 'K22SzbtNo__c',
          'K22GatiCategory__c', 'K6K22HeigaCategory__c',
          'OldNWZgsyo__c']) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            _newItem[key] = dnt[key];
          }
        }
        _newItem['Dsp_DntNo__c'] = dntNoFormat(
            dnt['SenroCode__c'], dnt['DntNoManualInput__c'],
            dnt['K6KansnNo__c'], dnt['K6Bunk1__c'],
            dnt['K6Bunk2__c'], dnt['K6Bunk3__c'],
            dnt['K22SzbtNo__c'], dnt['K22GatiCategory__c']);
        _newItem['Dsp_SenroName__c'] = senroNameFormat(dnt['SenroName__c'], dnt['SenroNameKana__c'], dnt['SenroCode__c']);
        // 汎用マスタから中電事業所のIdを取得して設定する
        _newItem['NWZgsyo__c'] = null;
        if (nWZgsyoList) {
          const masterData = nWZgsyoList.filter(
              (data) => data.Code__c == dnt.NWZgsyo__r_Code__c);
          _newItem['NWZgsyo__c'] = masterData.length > 0 ?
            masterData[0].Id : null;
          _newItem['NWZgsyo__r'] = masterData.length > 0 ?
            masterData[0] : {Name: '', Code__c: ''};
          _newItem['NWZgsyo__r.Name'] =
            masterData.length > 0 ? masterData[0].Name : null;
          _newItem['NWZgsyo__r.Code__c'] =
            masterData.length > 0 ? masterData[0].Code__c : null;
        }
      }
    }
    cv.commitNew();
    cv.endUpdate();
  }

  /**
   * 変更したデータを返す
   * 変更データに以下の形式で入っている。
   * [{グリッドにバインドしたキー: 値, ...}, {...}, ...]
   * 注意)同じ行を複数回変更したとき、変更した回数分itemsEditedにデータが入る。
   * @return {array} 変更データの配列
   */
  itemsEdited() {
    return this.grid.collectionView.itemsEdited;
  }

  /**
   * 追加、変更、削除したデータを返す
   * 注意)CollectionView メソッド（editItem /commitEdit、 addNew /commitNew、remove）を使用しないと
   *     同じ行を追加、削除したとき、 itemsAddedとitemsRemovedにそれぞれデータが入る。
   *     同じ行を複数回変更したとき、変更した回数分itemsEditedにデータが入る。
   * @return {object} 追加、変更、削除したデータ
   * {
   *   itemsAdded: ObservableArray[{追加行データ}, {...}, ...],
   *   itemsEdited: ObservableArray[{変更行データ}, {...}, ...],
   *   itemsRemoved: ObservableArray[{削除行データ}, {...}, ...],
   * }
   */
  itemsAllChanged() {
    return {
      itemsAdded: this.grid.collectionView.itemsAdded,
      itemsEdited: this.grid.collectionView.itemsEdited,
      itemsRemoved: this.grid.collectionView.itemsRemoved,
    };
  }

  /**
   * 編集した内容をコミットする
   */
  commit() {
    if (this.grid != null && this.grid.collectionView != null) {
      const cv = this.grid.collectionView;
      if (cv.trackChanges) {
        cv.commitEdit();
      }
    }
  }

  /**
   * データを追加、変更、削除したかチェックする
   * @return {bool} true:変更あり, false:変更なし
   */
  isItemsModified() {
    let _isModified = false;
    if (this.grid && this.grid.collectionView) {
      _isModified = this.grid.collectionView.itemsAdded.length > 0 ||
                  this.grid.collectionView.itemsEdited.length > 0 ||
                  this.grid.collectionView.itemsRemoved.length > 0;
    }
    return _isModified;
  }

  /**
   * 編集した内容をコミットし、データが変更されているかチェックする
   * グリッドが読み取り専用の場合はfalseを返す
   * @return {bool} true:変更あり, false:変更なし
   */
  commitItemsModified() {
    let _isModified = false;
    if (this.props.isReadOnly !== true) {
      this.commit();
      _isModified = this.isItemsModified();
    }
    return _isModified;
  }

  /**
   * グリッドのデータをDBに登録した後呼び出す。
   * clearChangesを実行すると
   * itemsAdded 、itemsRemoved 、itemsEdited
   * の全ての変更をクリアする。
   */
  clearChanges() {
    if (this.grid && this.grid.collectionView) {
      this.grid.collectionView.clearChanges();
    }
  }

  /**
   * ストアに初期値を設定する
   * @param {string} key 画面Id
   *                     画面Idが重複する場合は1027_sen,1027_tenのようにユニークな文字列を渡す
   * @param {object} items オブジェクト
   * @param {bool} restore true:画面の状態を復元する、false:復元しない
   */
  setInitItems(key, items, restore=true) {
    this.setAllSelected(false);
    this.selectedItems = [];
    this.radioCheckId = null;
    this.setScreenState(key, restore);
    this.props.doSetListAction(items);
    this.clearChanges();
  }

  /**
   * ストアにデータを設定する
   * @param {object} items オブジェクト
   * @param {bool} restore true:画面の状態を復元する、false:復元しない
   */
  setItems(items, restore=true) {
    this.setAllSelected(false);
    this.isRestore = restore;
    this.props.doSetListAction(items);
  }

  /**
   * 画面の情報を設定する
   * @param {string} _screenId 画面Id
   * @param {bool} restore true:画面の状態を復元する、false:復元しない
   */
  setScreenState(_screenId, restore=true) {
    this.isRestore = restore;
    this.setState({screenId: _screenId});
  }

  /**
   * 変更したデータをストアに保存する
   * @param {object} item 変更データ
   * @param {number} itemIndex 行番号
   */
  storeEditItem(item, itemIndex) {
    if (!item) {
      return;
    }
    if (itemIndex == undefined ||
      itemIndex == null ||
      itemIndex < 0) {
      return;
    }
    this.props.doChangeItemAction(item, itemIndex);
    if (this.grid?.collectionView?.trackChanges) {
      if (item) {
        this.grid.collectionView.itemsEdited.push(item);
      }
    }
  }

  /**
   * 以下の状態を保存する
   * ・ラジオボタンまたはチェックボックスで選択したデータ
   * ・スクロール位置
   * @param {string} screenId 画面Id
   * @param {array} compareKey
   *                  一覧のデータと保存した選択データを比較して同じ行か判別するためのキー
   *                  selectedItemsに存在するキーを1つ設定する
   * @param {bool} isClear true: ストアに保存した状態をクリアする
   */
  saveScreenState(screenId, compareKey, isClear=false) {
    this.saveSelectedItems(screenId, compareKey, isClear);
    this.saveScrollPosition(screenId, isClear);
  }

  /**
   * ラジオボタンまたはチェックボックスで選択したデータを保存する
   * @param {string} screenId 画面Id
   * @param {array} compareKey
   *                  一覧のデータと保存した選択データを比較して同じ行か判別するためのキー
   *                  selectedItemsに存在するキーを1つ設定する
   * @param {bool} isClear true: ストアに保存した選択行をクリアする
   */
  saveSelectedItems(screenId, compareKey, isClear=false) {
    if (!isClear) {
      let _dataItems = [];
      for (const selectedItem of this.selectedItems) {
        _dataItems.push(selectedItem.dataItem);
      }
      this.props.doSaveSelectedItems(
          screenId, compareKey, _dataItems);
    } else {
      this.clearSelectedState();
      this.props.doSaveSelectedItems(
          screenId, compareKey, null);
    }
  }

  /**
   * 一覧の選択状態をクリアする
   */
  clearSelectedState() {
    this.selectedItems = [];
    this.radioCheckId = null;
    if (this.grid && this.props &&
        this.props.rowHeaderType == 'radio') {
      this.grid.select(-1, -1);
    }
  }

  /**
   * スクロール位置を保存する
   * @param {string} screenId 画面Id
   * @param {bool} isClear true: ストアに保存した状態をクリアする
   */
  saveScrollPosition(screenId, isClear=false) {
    if (this.grid == null) {
      return;
    }
    if (!isClear) {
      const _scrollPosition = this.grid.scrollPosition;
      this.props.doSaveScrollPosition(
          screenId, _scrollPosition);
    } else {
      this.props.doSaveScrollPosition(
          screenId, null);
    }
  }
}

/**
 * CollectionView生成
 * @param {array} items
 * @param {bool} trackChanges
 * @param {object} initItems
 * @param {func} getError
 * @return {object} CollectionView
 */
export function createCollectionView(
    items, trackChanges=true, initItems={}, getError=null) {
  const _list = [];
  const _items = items ? items : [];
  for (const item of _items) {
    let data = {};
    Object.assign(data, item);
    _list.push(data);
  }
  const cv = new CollectionView(
      _list,
      {
        trackChanges: trackChanges, // 変更管理ON
        newItemCreator: function() {
          // 項目は新しく作成すること
          // 同じ変数を返すとGridが同じ行とみなし、同じデータが入ってしまう
          const items = {};
          for (const key in initItems) {
            if (Object.hasOwnProperty.call(initItems, key)) {
              items[key] = initItems[key];
            }
          }
          return items;
        },
        getError: getError,
      },
  );
  return cv;
}

CustomFlexGrid.propTypes = {
  AddDeleteOn: PropTypes.bool,
  allowDragging: PropTypes.string,
  allowSorting: PropTypes.string,
  checkedFunction: PropTypes.func,
  children: PropTypes.array,
  counterOn: PropTypes.bool,
  data: PropTypes.array,
  editPopupItems: PropTypes.func,
  exceptFilters: PropTypes.array,
  filterOn: PropTypes.bool,
  formatItemFunction: PropTypes.func,
  frozenRows: PropTypes.number,
  frozenColumns: PropTypes.number,
  headersVisibility: PropTypes.string,
  loadedRowsFunction: PropTypes.func,
  refreshedFunction: PropTypes.func,
  rowHeaderType: PropTypes.string,
  style: PropTypes.object,
  isReadOnly: PropTypes.bool,
  initItems: PropTypes.object,
  items: PropTypes.array,
  validateEdits: PropTypes.bool,
  useStore: PropTypes.bool,
  selections: PropTypes.array,
  scrollPosition: PropTypes.array,
  doShowMessage: PropTypes.func,
  getError: PropTypes.func,
  doSetListAction: PropTypes.func,
  doAddItemAction: PropTypes.func,
  doRemoveItemAction: PropTypes.func,
  doChangeItemAction: PropTypes.func,
  doMoveItemAction: PropTypes.func,
  filterChanging: PropTypes.func,
  customDataMapBinds: PropTypes.array,
  onCellEditEnding: PropTypes.func,
  doSaveSelectedItems: PropTypes.func,
  doSaveScrollPosition: PropTypes.func,
};

const mapStateToProps = (state) => {
  const _items = state.grid.editItems?.items ?
                    state.grid.editItems.items : [];
  return {
    items: _items,
    selections: state.grid.selections != null ? state.grid.selections : [],
    scrollPosition:
      state.grid.scrollPosition != null ? state.grid.scrollPosition : [],
  };
};

const mapDispatchToProps = {
  doSetListAction: gridOperations.doSetListAction,
  doAddItemAction: gridOperations.doAddItemAction,
  doRemoveItemAction: gridOperations.doRemoveItemAction,
  doChangeItemAction: gridOperations.doChangeItemAction,
  doMoveItemAction: gridOperations.doMoveItemAction,
  doSaveSelectedItems: gridOperations.doSaveSelectedItems,
  doSaveScrollPosition: gridOperations.doSaveScrollPosition,
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        {forwardRef: true},
    )(CustomFlexGrid),
);

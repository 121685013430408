
import {withStyles} from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import CommonTheme from '../../../Theme.js';

/** ボタン共通部で使用するパレット */
const palette = CommonTheme.palette;

// ボタン共通スタイル
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      'minWidth': 180,
    },
    'border': '1px solid',
    'whiteSpace': 'nowrap',

    '&:disabled': {
      color: palette.text.disabled,
      backgroundImage: 'none',
      backgroundColor: palette.action.disabledBackground,
    },
  },

});

/** ボタン共通部 */
export const CommonButton = withStyles(styles)(MuiButton);
export default CommonButton;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DeleteIcon from '@material-ui/icons/Delete';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, getResponseResult,
  changeDateFormat, getErrorCode, API_CODE, getMaxDeleteNum,
  getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import * as validateRule from '../../../../../validateRule.js';
import {formalOrderListFields} from '../../../../../../common/SFFields';
import PaperPart from '../../../../../atoms/PaperPart.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

// 本申込で使用する申込ステータスコード
// 作成中,可否判定済,本申込中,受付中,
// 受付済,設計中,概算金額算定中,概算金額確定,概算金額受理,電力工事待ち,
// 電力工事完了,共架料算定中,竣工待ち／工事開始可,竣工確認中,
// 完結,完結（期限切れ）
const dispFormalOrderStatus =
  ['00', '04', '05', '10', '12', '13', '14', '15', '16', '20', '21', '31', '40', '41', '90', '91'];
const formalOrderStatus =
  ['00', '10', '12', '13', '14', '15', '16', '20', '21', '31', '40', '41', '90', '91'];
// 可否判定申込で使用する申込ステータスコード
// 可否判定済,本申込中
const proprietyOrderStatus = ['04', '05'];

// 検索パラメータ
const appSortParams = {
  CreatedDate: -1,
};
// 可否判定申込のサブクエリ
const subQuery =[
  {
    'fields': ['Id', 'OrderStatus__c'],
    'table': 'MainOrders__r',
    'conditions': {
      'OrderStatus__c': '00', // 作成中の本申込があるかチェックするために使用する
    },
  },
];

/**
 * 本申込一覧
 * 画面ID:1011
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      this.props.doSetReferenceMode(false);
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1011', []);
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearOrderList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1011', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 一覧のスタイルを変更
   * 可否判定回答期限を超えている行をグレーアウトする
   * cssClassにgrayを設定するとチェックボックスが非活性になる
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction(s, e) {
    try {
      const rows = s.rows;
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].dataItem.ResultDeadline == true) {
          rows[i].cssClass = 'gray';
        }
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let bRet = false;

      if (this.gridRef && this.gridRef.current &&
        this.gridRef.current.selectedItemCount() > 0) {
        bRet = true;
      }

      if (bRet) {
        this.setState({buttonDisabled: false});
      } else {
        this.setState({buttonDisabled: true});
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧表示
   * @return {object}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      style: {height: '300px'},
      isReadOnly: true,
      refreshedFunction: this.refreshedFunction,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="ProprietyJudgeOrderNo__c" header="可否判定申込番号" dataType="String" width={150} />
        <FlexGridColumnGroup binding="OrderNo__c" header="本申込番号" dataType="String" width={150}/>
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType="String"/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType="String"/>
        <FlexGridColumnGroup binding="OrderDate__c" header="本申込年月日" width={170} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.OrderDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType="String"/>
        <FlexGridColumnGroup header="工事場所" align="center">
          <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
          <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePrefecturesName" header="都道府県" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String"/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="ProprietyJudgeAnswerDate__c" header="可否判定\n回答日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.ProprietyJudgeAnswerDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KaHonsu__c" header="可本数" dataType="Number" width={80}/>
        <FlexGridColumnGroup binding="ConditionalKaHonsu__c" header="条件付\n可本数" dataType="Number" width={80}/>
        <FlexGridColumnGroup binding="HiHonsu__c" header="否本数" dataType="Number" width={80}/>
        <FlexGridColumnGroup binding="SplitOrderUmuName" header="分割申込\n有無" dataType="String" cssClass="centerPos" width={100}/>
        <FlexGridColumnGroup binding="NoOrderDntCount" header="申込未実施\n施設数" dataType="Number" width={110}/>
        <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType="String"/>
        <FlexGridColumnGroup binding="KyogasyaSendBackReason__c" header="差戻理由" dataType="String"/>
        <FlexGridColumnGroup binding="ContractNo__c" header="契約書番号" dataType="String"/>
        <FlexGridColumnGroup binding="TemporarilySavedDate__c" header="一時保存日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.TemporarilySavedDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  /**
   * 本申込詳細ボタン押下時
   */
  doDetail = () => {
    try {
      if (!this.gridRef || !this.gridRef.current) {
        return;
      }

      const count = this.gridRef.current.selectedItemCount();
      if (count != 1) {
        this.props.doShowMessage({
          message: {
            id: 'CE0022',
          },
        });
        return;
      }

      // 可否判定結果期限日を超過して本申込されていないものがあればダイアログを表示する
      // 期限切れフラグ(ResultDeadline)はサーバー側で設定している
      const items = this.gridRef.current.selectedItem();
      if (items.filter((item) =>
        item.dataItem.ResultDeadline == true).length > 0) {
        this.props.doShowMessage({
          message: {
            id: 'PE0083',
          },
        });
        return;
      }

      const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
      const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
      this.props.doSetOrderId(items[0].dataItem.Id); // 可否判定申込または本申込のId
      this.props.doSetOrderModifiedCondition(
          items[0].dataItem.Id, items[0].dataItem.LastModifiedDate); // 可否判定申込または本申込の情報
      if (items[0].dataItem.StbType__c == stbTypeSen) {
        this.props.history.push({pathname: '/SenFormalOrderBasicInfo'});
      }
      if (items[0].dataItem.StbType__c == stbTypeTen) {
        this.props.history.push({pathname: '/TenFormalOrderBasicInfo'});
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 削除ボタン押下時
   */
  doDelete = async () => {
    try {
      if (!this.gridRef || !this.gridRef.current) {
        return;
      }

      const count = this.gridRef.current.selectedItemCount();
      if (count == 0) {
        return;
      }

      const orderStatusCreate = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'OrderStatus00', 2);
      const orderCategoryFormal = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'OrderCategory02', 2);

      // 本申込の作成中のみ削除可
      const items = this.gridRef.current.selectedItem();
      const messages = [];
      for (let index = 0; index < items.length; index++) {
        const element = items[index].dataItem;
        if (element && element.OrderCategory__c != orderCategoryFormal) {
          messages.push({
            id: 'PE0170',
            values: [element.ProprietyJudgeOrderNo__c ? element.ProprietyJudgeOrderNo__c : ''],
          });
        }
        if (element && element.OrderCategory__c == orderCategoryFormal &&
          element.OrderStatus__c != orderStatusCreate) {
          messages.push({
            id: 'CE0171',
            values: [element.OrderNo__c],
          });
        }
      }
      if (messages.length > 0) {
        this.props.doShowMessage({message: messages});
        return;
      }

      // 選択件数が50件以下のとき、削除可能
      const maxDeleteCount = getMaxDeleteNum();
      if (count > maxDeleteCount) {
        this.props.doShowMessage({
          message: {
            id: 'CE0198',
            values: ['申込', maxDeleteCount],
          },
        });
        return;
      }

      // 選択した本申込と本申込に紐づく契約電柱、線設備、一束化協議資料を削除する
      // 可否判定申込に紐づく添付ファイル、添付写真は削除しない。
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['削除'],
        },
        action: async () => {
          const orderConditions = [];
          const proprietyOrderConditions = [];
          const contractDntConditions = [];
          const senStbConditions = [];
          for (const item of items) {
            orderConditions.push({
              Id: item.dataItem.Id,
              LastModifiedDate: item.dataItem.LastModifiedDate,
            });
            proprietyOrderConditions.push({
              Id: item.dataItem.ProprietyJudgeOrder__r.Id,
              LastModifiedDate:
                item.dataItem.ProprietyJudgeOrder__r.LastModifiedDate,
            });
          }

          const response = await this.props.doDeleteFormalOrder(
              orderConditions, proprietyOrderConditions,
              contractDntConditions, senStbConditions);
          const resResult = getResponseResult(response, ['本申込', '削除']);
          if (resResult.errorCode != API_CODE.SUCCESS) {
            if (resResult.messages.filter((data) => data.id == 'CE0048').length > 0) {
              this.props.doShowMessage({
                message: resResult.messages,
                action: async () => {
                  // 排他エラーになったらデータ再読み込み
                  await this._searchOrder();
                },
              });
            } else {
              this.props.doShowMessage({
                message: resResult.messages,
              });
            }
            return;
          }

          this.props.doShowMessage({
            message: {
              id: 'CI0009',
              values: ['削除'],
            },
            action: async () => {
              // 再検索
              await this._searchOrder();
            },
          });
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  _searchOrder = async () => {
    const {generalPurposeMap} = this.props;

    this.setState({buttonDisabled: true});

    // 検索条件
    let wkData = {};
    if (this.props.values) {
      wkData = JSON.parse(JSON.stringify(this.props.values));
    }

    // パラメータ設定
    // 可否判定申込と本申込を検索
    // 可否判定申込の検索条件：可否判定申込番号、設備種別、共架種別、工事件名、分割申込有無、申込ステータス、
    //                       線路名、代表電柱番号、都道府県、市区町村、場所、可否判定回答日
    // 本申込の検索条件：可否判定申込番号、本申込番号、設備種別、共架種別、工事件名、分割申込有無、申込ステータス、
    //                  線路名、代表電柱番号、都道府県、市区町村、場所、本申込年月日、可否判定回答日
    const appConditions = {
      propriety: { // 可否判定申込の検索条件
        'OrderCategory__c': getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory01', 2),
        'RecordType.DeveloperName': 'ProprietyJudgeOrder',
      },
      formal: { // 本申込の検索条件
        'OrderCategory__c': getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory02', 2),
        'RecordType.DeveloperName': 'MainOrder',
      },
    };
    if (wkData != null) {
      // 可否判定申込番号
      if (wkData['ProprietyJudgeOrderNo__c']) {
        Object.assign(appConditions.propriety, {OrderNo__c: wkData['ProprietyJudgeOrderNo__c']});
        Object.assign(appConditions.formal,
            {ProprietyJudgeOrderNo__c: wkData['ProprietyJudgeOrderNo__c']});
        delete wkData['ProprietyJudgeOrderNo__c'];
      }
      // 本申込番号
      if (wkData['OrderNo__c']) {
        Object.assign(appConditions.formal, {OrderNo__c: wkData['OrderNo__c']});
        delete wkData['OrderNo__c'];
      }
      // 申込ステータス
      if (!Object.hasOwnProperty.call(wkData, 'OrderStatus__c')) {
        Object.assign(appConditions.propriety,
            {OrderStatus__c: proprietyOrderStatus});
        Object.assign(appConditions.formal,
            {OrderStatus__c: formalOrderStatus});
      } else {
        if (!proprietyOrderStatus.includes(wkData['OrderStatus__c'])) {
          Object.assign(appConditions.propriety,
              {OrderStatus__c: '99'}); // 検索から除外
        } else {
          Object.assign(appConditions.propriety,
              {OrderStatus__c: wkData['OrderStatus__c']});
        }
        Object.assign(appConditions.formal,
            {OrderStatus__c: wkData['OrderStatus__c']});
        delete wkData['OrderStatus__c'];
      }
      // 本申込年月日
      if (wkData['OrderDate__c_From']) {
        Object.assign(appConditions.formal,
            {OrderDate__c_From: wkData['OrderDate__c_From']});
        delete wkData['OrderDate__c_From'];
      }
      if (wkData['OrderDate__c_To']) {
        Object.assign(appConditions.formal,
            {OrderDate__c_To: wkData['OrderDate__c_To']});
        delete wkData['OrderDate__c_To'];
      }
      // 設備種別、共架種別、工事件名、分割申込有無、線路名、代表電柱番号、
      // 都道府県、市区町村、場所、可否判定回答日
      Object.assign(appConditions.propriety, wkData);
      Object.assign(appConditions.formal, wkData);
    }

    const response = await this.props.doGetOrderTypeList(
        appConditions, formalOrderListFields, appSortParams,
        'getFormalOrderList', subQuery, '');
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['本申込', '取得'],
        },
      });
      return false;
    }
    // 件数超過エラー
    const maxKensu = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    const dataLen = response.data.body.data ?
      response.data.body.data.length : 0;
    if (dataLen >= maxKensu) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
    }
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveSelectedItems('1011', 'Id', true);
      this.gridRef.current.setItems(this.props.orderList);
    }
    return true;
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      const result = await this._searchOrder();
      if (!result) {
        return;
      }
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
          !this.props.orderList ||
          this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;
    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1011Order" item>
          <PositiveButton
            id="btn1011Order"
            onClick={handleSubmit(this.doDetail)}
            variant="contained"
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>本申込詳細</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1011DeleteOrder" item>
          <DangerButton
            id="btn1011DeleteOrder"
            onClick={handleSubmit(this.doDelete)}
            variant="contained"
            startIcon={<DeleteIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>削除</span>
          </DangerButton>
        </Grid>
        <Grid key="key1011Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1011ProprietyOrderNo" item xs={12} sm={2}>
                <Field
                  id="id1011ProprietyOrderNo"
                  name="ProprietyJudgeOrderNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="可否判定申込番号"
                  margin="normal"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1011FormalOrderNo" item xs={12} sm={2}>
                <Field
                  id="id1011FormalOrderNo"
                  name="OrderNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="本申込番号"
                  margin="normal"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1011StbType" item xs={12} sm={2}>
                <Field
                  id="id1011StbType"
                  name="StbType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType,
                        'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key1011KyogaType" item xs={12} sm={2}>
                <Field
                  id="id1011KyogaType"
                  name="KyogaType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1011KoziTitle" item xs={12} sm={4}>
                <Field
                  id="id1011KoziTitle"
                  name="KoziTitle__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事件名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>
              <Grid key="key1011SplitOrderUmu" item xs={12} sm={2}>
                <Field
                  id="id1011SplitOrderUmu"
                  name="SplitOrderUmu__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="分割申込有無"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(generalPurposeMap.UmuCategory)}
                </Field>
              </Grid>
              <Grid key="key1011OrderStatus" item xs={12} sm={2}>
                <Field
                  id="id1011OrderStatus"
                  name="OrderStatus__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="申込ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                       createSearchList(generalPurposeMap.OrderStatus,
                           'Code__c', 'Name', {Code__c: dispFormalOrderStatus})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1011KoziPlaceMainDntNo_SenroName" item xs={12} sm={2}>
                <Field
                  id="id1011KoziPlaceMainDntNo_SenroName"
                  name="KoziPlaceMainDntNo_SenroName__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／線路名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>
              <Grid key="key1011KoziPlaceMainDntNo" item xs={12} sm={2}>
                <Field
                  id="id1011KoziPlaceMainDntNo"
                  name="Dsp_KoziPlaceMainDntNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／代表電柱番号"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
              <Grid key="key1011KoziPlacePrefectures" item xs={12} sm={2}>
                <Field
                  id="id1011KoziPlacePrefectures"
                  name="KoziPlacePrefectures__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="工事場所／都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>
              <Grid key="key1011KoziPlaceMunicipalities" item xs={12} sm={3}>
                <Field
                  id="id1011KoziPlaceMunicipalities"
                  name="KoziPlaceMunicipalities__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／市区町村"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1011KoziPlacePlace" item xs={12} sm={3}>
                <Field
                  id="id1011KoziPlacePlace"
                  name="KoziPlacePlace__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／場所"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1011OrderDate" item xs={12} sm>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1011OrderDateFrom"
                    name="OrderDate__c_From"
                    accessibilitylabel="OrderDate__c_From"
                    label="本申込年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1011OrderDateTo"
                    name="OrderDate__c_To"
                    accessibilitylabel="OrderDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1011ProprietyJudgeAnswerDate" item xs={12} sm>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1011ProprietyJudgeAnswerDateFrom"
                    name="ProprietyJudgeAnswerDate__c_From"
                    accessibilitylabel="ProprietyJudgeAnswerDate__c_From"
                    label="可否判定回答日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1011ProprietyJudgeAnswerDateTo"
                    name="ProprietyJudgeAnswerDate__c_To"
                    accessibilitylabel="ProprietyJudgeAnswerDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1011Btn" item xs={12} sm>
                <NegativeButton
                  id="id1011Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="id1011Search"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>
          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key1011List" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderList: PropTypes.array,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderTypeList: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doDeleteFormalOrder: PropTypes.func,
  doClearOrderList: PropTypes.func,
  doSetReferenceMode: PropTypes.func,
  doSetOrderModifiedCondition: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderList: state.attachorder.orderList,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doDeleteFormalOrder: attachorderOperations.doDeleteFormalOrder,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doSetReferenceMode: commonOperations.doSetReferenceMode,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'FormalOrderList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

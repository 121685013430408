import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import {DataMap} from '@grapecity/wijmo.grid';
import Typography from '@material-ui/core/Typography';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {getComboName, execApexRestApi, getToDate, changeStringDate,
  isNWDesignZgsya, API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import Upload from '../../../../../organisms/UploadCustom';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {NegativeButton} from '../../../../../atoms/Buttons/NegativeButton.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createList} from '../../../../../../common/ComboParts.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {FlexGridCellTemplate, FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

/** Formで利用するフィールド名 */
const useFields = [
  'IsetuIraiNo__c',
  'IsetuIraiCarryOutDate__c',
  'KoziPlacePrefectures__c',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'Mark__c',
  'KoziOverview__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'MessageTimeOfDesign__c',
  'IsetuIraiHonsu__c',
  'DesignCompanySyozoku__c',
  'DesignTantoName__c',
  'DesignTel__c',
  'DesignMailAddress__c',
  'IsetuIraiStatus__c',
];

/**
 * 移設依頼設計書 検索条件
 */
const appFields = [
  'IsetuIraiNo__c',
  'IsetuIraiCarryOutDate__c',
  'KoziPlacePrefectures__c',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'Mark__c',
  'IsetuIraiStatus__c',
  'KoziOverview__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'MessageTimeOfDesign__c',
  'IsetuIraiHonsu__c',
  'NumberOfIsetuIraiZgsya__c',
  'DesignCompanySyozoku__c',
  'DesignTantoName__c',
  'DesignTel__c',
  'DesignMailAddress__c',
  'Id',
  'LastModifiedDate',
];

/** 移設依頼設計書サブクエリ */
const subquery = [
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient',
      'ContentDocument.LatestPublishedVersion.FileType',
      'ContentDocument.LatestPublishedVersion.ContentDocumentId'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['C02', 'C03', 'C04'],
    },
    'table': 'ContentDocumentLinks',
  },
  {
    'fields': [
      'NWZgsyo__r.Name',
      'NWZgsyo__c',
      'SenroName__c',
      'SenroCode__c',
      'DntNo__c',
      'Dsp_DntNo__c',
      'toLabel(DntCategory__c)DntCategoryName',
      'toLabel(DrSideKoziEachDntContents__c)DrSideKoziEachDntContents',
      'EachDntKoziContentHosoku__c',
      'EachDntMessage__c',
      'Id',
      'LastModifiedDate',
      'IsetuIraiNo__c',
      'SerialNumber__c',
    ],
    'table': 'IsetuIraiContractDnts__r',
  },
  {
    'fields': [
      // 共架事業者
      'OrderKyogaZgsya__r.KyogaZgsyaCode__c',
      'OrderKyogaZgsya__r.Account__r.Name',
      // 共架事業所
      'OrderKyogaZgsya__r.KyogaZgsyoCode__c',
      'OrderKyogaZgsya__r.Name',
      // 保守担当<保守担当箇所>
      'MaintenanceKoziCompany__c',
      'MaintenanceKoziCompany__r.ZipCode__c',
      'MaintenanceKoziCompany__r.Address__c',
      'MaintenanceKoziCompany__r.Name',
      'MaintenanceKoziCompany__r.Tel__c',
      'MaintenanceKoziCompany__r.Account__c',
      'MaintenanceKoziCompany__r.MailAddress__c',
      'MaintenanceKoziCompany__r.RepresentativeName__c',
      // 共架担当者<共架事業者>
      'OrderKyogaZgsya__c',
      'OrderKyogaZgsya__r.ZipCode__c',
      'OrderKyogaZgsya__r.Address__c',
      'OrderKyogaZgsya__r.Tel__c',
      'OrderKyogaZgsya__r.MailAddress__c',
      'KyogaTanto__r.Name',
      'KyogaTanto__r.Email',
      'MailKyogaZgsya__c',
      'EachKyogasyaMessage__c',
      'EachKyogasyaRemarks__c',
      'IsetuIraiContentsAcceptedDate__c',
      'toLabel(IsetuIraiTarget__c)IsetuIraiTarget',
      'toLabel(IsetuIraiNoticeTarget__c)IsetuIraiNoticeTarget',
      // 画面表示外
      'Id',
      'OrderStatus__c',
      'LastModifiedDate',
    ],
    'table': 'IsetuDocOrders__r',
  },
];

/**
 * 移設依頼登録確認 テンプレート。
 * 画面ID:1404
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isetuFiles: [],
      otherFiles: [],
      isetuDelFiles: [],
      otherDelFiles: [],
      isButtonDisabled: false,
      isDisabled: false,
    };

    this.gridDntRef = React.createRef();
    this.gridRef = React.createRef();
  }

  /**
  * 初期処理
  */
  async componentDidMount() {
    const {userInfo, generalPurposeMap} = this.props;
    // 汎用マスタからデータ取得
    await this.props.doGetAllGeneralPurposeMap();
    if (!this.props.hasOwnProperty('generalPurposeMap') ||
        !this.props.generalPurposeMap) {
      this.sysErr();
      return;
    }

    // 電力側電柱工事内容のコンボ用データ取得
    this.drDntKoziContentsMap = new DataMap(this.props.generalPurposeMap.DrDntKoziContents, 'Code__c', 'Name');
    // 移設依頼設計書の情報取得
    await this.getDesignDocument();

    // グリッド初期化
    if (!this.gridDntRef.current) {
      return;
    }
    // 電柱一覧表示部
    const dntList =
      this.props.saveDesignDocument &&
      this.props.saveDesignDocument.IsetuIraiContractDnts__r ?
      this.props.saveDesignDocument.IsetuIraiContractDnts__r.records : null;
    const dntCV = createCollectionView(dntList,
        false, {}, null);
    this.gridDntRef.current.getGrid().itemsSource = dntCV;
    // 共架者一覧表示部
    const kyogasyaList =
      this.props.saveDesignDocument &&
      this.props.saveDesignDocument.IsetuDocOrders__r ?
      this.props.saveDesignDocument.IsetuDocOrders__r.records : null;
    const kyogaCV = createCollectionView(kyogasyaList,
        false, {}, null);
    this.gridRef.current.getGrid().itemsSource = kyogaCV;
    this.setState({
      isDisabled: (isNWDesignZgsya(userInfo, generalPurposeMap) &&
        userInfo.MainteKoziCompany__c != null && userInfo.MainteKoziCompany__c),
    });
  }

  /**
   * 移設依頼設計書の情報取得
   */
  async getDesignDocument() {
    const docId = this.props.designDocumentId;
    if (docId != null) {
      // 移設依頼設計書IDが指定されている場合は編集のため取得
      const conditions = {'Id': docId};
      await this.props.doGetDesignDocument(
          conditions, appFields, null, 'getSub', subquery);
      if (!this.props.hasOwnProperty('saveDesignDocument') ||
        !this.props.saveDesignDocument) {
        this.sysErr();
        return;
      }

      // 取得値から添付ファイル名取得
      const wkIsetuFiles = [];
      const wkOtherFiles = [];
      const wkNttFiles = [];
      if (this.props.saveDesignDocument.ContentDocumentLinks) {
        for (const data of
          this.props.saveDesignDocument.ContentDocumentLinks.records) {
          const vesionData = data.ContentDocument.LatestPublishedVersion;
          if (vesionData.AttachedFileType__c === 'C03') {
            // 添付（移設依頼設計書）
            wkIsetuFiles.push(vesionData);
          } else if (vesionData.AttachedFileType__c === 'C04') {
            // 添付（その他）
            wkOtherFiles.push(vesionData);
          } else if (vesionData.AttachedFileType__c === 'C02') {
            // 添付（占用申請状況）
            wkNttFiles.push(vesionData);
          }
        }

        this.setState({
          isetuFiles: wkIsetuFiles,
          otherFiles: wkOtherFiles,
          nttFiles: wkNttFiles,
          isetuDelFiles: [],
          otherDelFiles: [],
          nttDelFiles: [],
        });
      }
    }
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/List'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    const {reset} = this.props;
    reset();
  }

  /**
   * 確定ボタン押下
   *
   * @return {void}
   */
  doSave = async () => {
    this.props.doShowMessage({
      message: 'MW0201',
      action: () => {
        this.doUpdate();
      },
    });
  }

  doUpdate = async () => {
    const {userInfo, saveDesignDocument} = this.props;
    const orderConditions = [];
    const orderList = [];
    let isetuIraiTarget = false;
    if (saveDesignDocument.IsetuDocOrders__r &&
      saveDesignDocument.IsetuDocOrders__r.records &&
      saveDesignDocument.IsetuDocOrders__r.records.length > 0) {
      for (const order of saveDesignDocument.IsetuDocOrders__r.records) {
        const conditions = {
          Id: order.Id,
          LastModifiedDate: order.LastModifiedDate,
        };
        orderConditions.push(conditions);
        const orderData = {
          Id: order.Id,
          OrderStatus__c: (order.IsetuIraiTarget == '対象' ? '50' : '90'), // 移設依頼対象外の場合、完結に設定
        };
        orderList.push(orderData);
        if (order.IsetuIraiTarget == '対象') {
          isetuIraiTarget = true;
        }
      }
    }
    const designDocument = {};
    designDocument['Id'] = saveDesignDocument.Id;
    designDocument['IsetuIraiStatus__c'] = (!isetuIraiTarget ? '04' : '01'); // すべて移設依頼対象外の場合、完結に設定
    designDocument['IsetuIraiCarryOutDate__c'] = getToDate();
    const body = {
      orderConditions: orderConditions,
      orderList: orderList,
      designDocumentConditions: [
        {
          Id: saveDesignDocument.Id,
          LastModifiedDate: this.props.orderLastModifiedDate,
        },
      ],
      designDocumentList: [designDocument],
    };
    console.dir(body);
    const result = await execApexRestApi(
        userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
    console.dir(result);
    const resResult = getResponseResult(result, ['移設依頼情報', '登録']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: resResult.messages,
        action: () => {
          if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
            this.gotoListGmn();
          }
        },
      });
      return;
    }
    await this.getDesignDocument();
    this.props.doSetOrderLastModifiedDate(
        this.props.saveDesignDocument.LastModifiedDate);
    let transition = this.props.history.popStockUrl();
    this.props.history.push(transition, null,
        this.props.history.option.NOT_SET);
  }

  renderUtilityPoleTable() {
    const props = {
      rowHeaderType: 'none',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px', border: 'none'},
      isReadOnly: true,
      useStore: false,
    };
    return (
      <>
        <CustomFlexGrid ref={this.gridDntRef} {...props}>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" width={100}/>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="SenroName__c" header="線路名" dataType="String" width={185}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" width={200}/>
            <FlexGridColumnGroup binding="DntCategoryName" header="電柱区分" dataType="String" width={100}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="DrSideKoziEachDntContents" header="電力側電柱別工事内容" dataType="String" width={200}/>
          <FlexGridColumnGroup binding="EachDntKoziContentHosoku__c" header="電力側電柱別工事内容／補足事項" dataType="String" width={200}/>
          <FlexGridColumnGroup binding="EachDntMessage__c" header="電柱別連絡事項" dataType="String" width={200}/>
        </CustomFlexGrid>
      </>
    );
  }

  renderKyogasyaListTable() {
    const props = {
      allowMerging: 'ColumnHeaders',
      filterOn: false,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      useStore: false,
    };

    return (
      <>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.KyogaZgsyaCode__c" header="共架者コード" dataType="String" isReadOnly={true} width={110}/>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Account__r.Name" header="共架者名" dataType="String" isReadOnly={true} width={160}/>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.KyogaZgsyoCode__c" header="事業所コード" dataType="String" isReadOnly={true} width={110}/>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Name" header="事業所" dataType="String" isReadOnly="true" width={200}/>
          <FlexGridColumnGroup header='保守担当箇所'>
            <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Name" header="保守工事会社" dataType="String" isReadOnly="true" width={250}/>
            <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.ZipCode__c" header="郵便番号" dataType="String" isReadOnly="true" width={80}/>
            <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Address__c" header="住所" dataType="String" isReadOnly="true" width={200}/>
            <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.RepresentativeName__c" header="宛先" dataType="String" isReadOnly="true" width={200}/>
            <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Tel__c" header="電話番号" dataType="String" isReadOnly="true" width={200}/>
            <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.MailAddress__c" header="メールアドレス" dataType="String" isReadOnly="true" width={200}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header='共架事業者'>
            <FlexGridColumnGroup binding="OrderKyogaZgsya__r.ZipCode__c" header="郵便番号" dataType="String" isReadOnly="true" width={80}/>
            <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Address__c" header="住所" dataType="String" isReadOnly="true" width={200}/>
            <FlexGridColumnGroup binding="KyogaTanto__r.Name" header="宛先（担当者名）" dataType="String" isReadOnly="true" width={200}/>
            <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Tel__c" header="電話番号" dataType="String" isReadOnly="true" width={220}/>
            <FlexGridColumnGroup header="メールアドレス" dataType="String" isReadOnly="true" width={200}>
              <FlexGridCellTemplate cellType="Cell"
                template={(ctx) => {
                  const item = ctx.item;

                  if (!item.KyogaTanto__r) {
                    // 宛先に共架担当者を設定していない場合、以前に設定済の共架担当者_メールアドレスを表示
                    return item.MailKyogaZgsya__c;
                  } else {
                    // 宛先に共架担当者を設定している場合、共架担当者のメールアドレスを表示
                    return item.KyogaTanto__r.Email;
                  }
                }} />
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="EachKyogasyaMessage__c" header="共架者別連絡事項" dataType="String" width={200} maxLength={30}/>
          <FlexGridColumnGroup binding="EachKyogasyaRemarks__c" header="共架者別備考" dataType="String" width={200} maxLength={30}/>
          <FlexGridColumnGroup header='依頼送付対象選択'>
            <FlexGridColumnGroup binding="IsetuIraiTarget" header="依頼対象" dataType="String" width={80} maxLength={30}/>
            <FlexGridColumnGroup binding="IsetuIraiNoticeTarget" header="システム通知" dataType="String" width={160} maxLength={30}/>
          </FlexGridColumnGroup>
        </CustomFlexGrid>
      </>
    );
  }

  render() {
    const {
      classes,
      handleSubmit,
      generalPurposeMap,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn1" item>
          <PositiveButton
            id="btnSave"
            startIcon={<SaveAltIcon />}
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            disabled={this.state.isButtonDisabled || this.state.isDisabled}
          >
            <span>確定</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <NegativeButton
            id="btnCancel"
            size="large"
            variant="contained"
            onClick={(event) => {
              let transition = this.props.history.popStockUrl();
              this.props.history.push(transition, null,
                  this.props.history.option.NOT_SET);
            }}
            props={this.props}
          >
            <span>キャンセル</span>
          </NegativeButton>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6">電力側工事場所</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  placeholder="入力不可"
                  validate={[]}
                />
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiCarryOutDate__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼発信年月日"
                  fullWidth
                  type={this.props.values.IsetuIraiCarryOutDate__c ? (this.type='date'):(this.type='text')}
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                >
                  {generalPurposeMap != null &&
                    createList(generalPurposeMap.PrefCode)
                  }
                </Field>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key1-6" item xs={12} sm={3}>
                <Field
                  name="DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={6} md={3}>
                <Field
                  name="Mark__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="目標"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key1-8" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼ステータス"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  type="text"
                  validate={[]}
                >
                  {generalPurposeMap != null &&
                    getComboName(generalPurposeMap, 'IsetuIraiStatus',
                        this.props.values.IsetuIraiStatus__c)
                  }
                </Field>
              </Grid>

            </Grid>

          </PaperPart>

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="h6">電力側工事概要</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid key="key2-1" item xs={12} sm={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="2-2" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziScheduleSeason__c"
                  accessibilitylabel="KoziScheduleSeason"
                  label="工事予定時期"
                  component={renderTextField}
                  className={classes.fields}
                  fullWidth
                  type="month"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key2-3" item xs={12} sm={3}>
                <Field
                  name="KoziScheduleSeasonHosoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事予定時期補足"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key2-4" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="2-5" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuCompHopeDateTimeOfDesign__c"
                  accessibilitylabel="IsetuCompHopeDateTimeOfDesign"
                  label="移設完了希望日"
                  component={renderTextField}
                  className={classes.fields}
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key2-6" item xs={12} sm={3}>
                <Field
                  name="MessageTimeOfDesign__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="連絡事項（設計時）"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key2-7" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiHonsu__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事対象本数"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key2-8" item xs={12} sm={3}>
                <Field
                  name="NumberOfIsetuIraiZgsya__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼送付先数"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>
            </Grid>
          </PaperPart>

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="h6">設計者</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key3-1" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社/部署名"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key3-2" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key3-1-3" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTel__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電話番号"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

              <Grid key="key3-1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignMailAddress__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="メールアドレス"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  validate={[]}
                />
              </Grid>

            </Grid>
          </PaperPart>

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid item xs={12}>
                <Typography variant="h6">工事図面・その他</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography>工事図面</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Upload
                      componentId="GenbaStateFigureFileId_File"
                      maxFileCount={1}
                      maxFileSize={3145728}
                      previewFlag={true}
                      required={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      initFile={this.state.isetuFiles}
                      disabled={true}
                      InputProps={{readOnly: true}}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >

                  <Grid item xs={12}>
                    <Typography>その他</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Upload
                      componentId="SenyoRequestInfFileId_File"
                      maxFileCount={1}
                      maxFileSize={3145728}
                      previewFlag={true}
                      required={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      initFile={this.state.otherFiles}
                      disabled={true}
                      InputProps={{readOnly: true}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PaperPart>

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="h6">占用申請状況に係る連絡票（NTTのみ）</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >

                  <Grid item xs={12}>
                    <Typography>占用申請状況に係る連絡票</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Upload
                      componentId="NttFileId_File"
                      maxFileCount={1}
                      maxFileSize={3145728}
                      previewFlag={true}
                      required={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      initFile={this.state.nttFiles}
                      disabled={true}
                      InputProps={{readOnly: true}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PaperPart>
          {this.renderUtilityPoleTable()}
          {this.renderKyogasyaListTable()}
          <Typography variant="h6" align="center" style={{color: 'red'}}>
            登録確定では，システム通知(メール送信)は行いません。　　　　　　　　　　　　　　<br/>
            確定後，移設依頼共架者一覧より，システム通知(メール送信)の操作を行ってください。
          </Typography>

          <div style={{marginBottom: '20px'}} />
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doGetAllGeneralPurposeMap: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  doGetDesignDocument: PropTypes.func,
  doGetContactMasterList: PropTypes.func,
  generalPurposeMap: PropTypes.object,
  designDocumentId: PropTypes.string,
  saveDesignDocument: PropTypes.object,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object,
  orderLastModifiedDate: PropTypes.string,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {
    IraiMotoCompanySyozoku__c: state.auth.userInfo.Account.Name,
  };

  const savedData = state.isetuIrai.saveDesignDocument;
  if (savedData) {
    for (const name of useFields) {
      if (savedData[name]) {
        initValues[name] = savedData[name];

        if (name == 'IsetuIraiStatus__c') {
          initValues['IsetuIraiStatus'] =
           getComboName(state.common.generalPurposeMap,
               'IsetuIraiStatus', savedData[name]);
        }

        if (name == 'KoziScheduleSeason__c') {
          // 工事予定時期は、年月に変換
          initValues['KoziScheduleSeason__c'] =
           changeStringDate(savedData.KoziScheduleSeason__c);
        }
      }
    }
  }

  return {
    initialValues: initValues,
    generalPurposeMap: state.common.generalPurposeMap,
    saveDesignDocument: state.isetuIrai.saveDesignDocument,
    designDocumentId: state.isetuIrai.designDocumentId,
    userInfo: state.auth.userInfo,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doGetDesignDocument: isetuIraiOperations.doGetDesignDocument,
  doGetContactMasterList: commonOperations.doGetContactMasterList,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'IsetuIraiConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

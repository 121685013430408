import {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

export let intl = null; // eslint-disable-line

class IntlGlobalProvider extends Component {
  constructor(props) {
    super(props);
    intl = this.props.intl; // eslint-disable-line
  }
  render() {
    const {children} = this.props;
    return children;
  }
}

IntlGlobalProvider.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node.isRequired,
};

export default injectIntl(IntlGlobalProvider);

const GET_DESIGN_DOCUMENT_LIST = 'GET_DESIGN_DOCUMENT_LIST';
const GET_DESIGN_DOCUMENT_TYPE_LIST = 'GET_DESIGN_DOCUMENT_TYPE_LIST';
const RETRIEVE_DESIGN_DOCUMENT = 'RETRIEVE_DESIGN_DOCUMENT';
const REGIST_DESIGN_DOCUMENT = 'REGIST_DESIGN_DOCUMENT';
const UPDATE_DESIGN_DOCUMENT = 'UPDATE_DESIGN_DOCUMENT';
const DELETE_DESIGN_DOCUMENT = 'DELETE_DESIGN_DOCUMENT';
const SET_DESIGN_DOCUMENT_ID = 'SET_DESIGN_DOCUMENT_ID';
const GET_DESIGN_DOCUMENT = 'GET_DESIGN_DOCUMENT';
const DEL_DESIGN_DOCUMENT = 'DEL_DESIGN_DOCUMENT';
const GET_DETAIL_DESIGN_DOCUMENT = 'GET_DETAIL_DESIGN_DOCUMENT';
const DEL_DETAIL_DESIGN_DOCUMENT = 'DEL_DETAIL_DESIGN_DOCUMENT';

export default {
  /** 移設依頼設計書一覧 取得 */
  GET_DESIGN_DOCUMENT_LIST,

  /** 移設依頼設計書一覧 取得（関数名指定） */
  GET_DESIGN_DOCUMENT_TYPE_LIST,

  /** 移設依頼設計書 ID指定取得 */
  RETRIEVE_DESIGN_DOCUMENT,

  /** 移設依頼設計書 登録 */
  REGIST_DESIGN_DOCUMENT,

  /** 移設依頼設計書 更新 */
  UPDATE_DESIGN_DOCUMENT,

  /** 移設依頼設計書 削除 */
  DELETE_DESIGN_DOCUMENT,

  /** 移設依頼設計書ID 設定 */
  SET_DESIGN_DOCUMENT_ID,

  /** 移設依頼設計書 ID指定検索 */
  GET_DESIGN_DOCUMENT,

  /** 移設依頼設計書 ID指定削除 */
  DEL_DESIGN_DOCUMENT,

  /** 移設依頼設計書 ID指定詳細検索 */
  GET_DETAIL_DESIGN_DOCUMENT,

  /** 移設依頼設計書 ID指定詳細削除 */
  DEL_DETAIL_DESIGN_DOCUMENT,
};

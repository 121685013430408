import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getErrorCode, changeDateFormat,
  getCodeFromGeneralPurposeMap, API_CODE,
  getResponseResult, getDataFromGeneralPurposeMap, getMaxDeleteNum} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';

import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';

import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';
import {createList, createSearchList,
  changePrefectures} from '../../../../../../common/ComboParts.js';

import * as validateRule from '../../../../../validateRule.js';
import {orderListFields} from '../../../../../../common/SFFields';

// スタイル
const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '200px',
  },
});

// 申込テーブル検索時のソート条件
const appSortParams = {
  OrderDate__c: -1, // 撤去申込日
  OrderNo__c: -1, // 申込番号
};

// 訂正取付申込で使用する申込ステータスコード
// 作成中,本申込中,完結
const CorrectTekyoStatus = ['00', '41', '90'];

/**
 * 訂正撤去申込一覧
 * 画面ID:1611
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.selector = null;
    this.state = {
      buttonDisabled: true, // ボタンDisabled制御
      contractDntCount: 0, // 電柱本数
    };
    this.gridRef = React.createRef();
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.props.reset();
      this.props.doClearOrderList();
      this.doClearList();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // 申込の最終更新日をクリア
      this.props.doSetOrderLastModifiedDate(null);

      // 汎用マスタからデータ取得
      await this.props.doGetAllGeneralPurposeMap();
      if (!this.props.hasOwnProperty('generalPurposeMap') ||
        !this.props.generalPurposeMap) {
        this.sysErr();
        return;
      }

      // FrexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1611', []);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索ボタン押下時処理
   */
  doSearch = async () => {
    try {
      const {values, generalPurposeMap} = this.props;
      // 検索条件生成
      const appConditions = this.createConditions(values);
      // 申込テーブルからデータ取得
      const result =
        await this.props.doGetOrderTypeList(
            appConditions, orderListFields, appSortParams,
            'getAttachOrderList', [], '');
      const errCode = getErrorCode(result);
      if (errCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
          !this.props.orderList || this.props.orderList.length <= 0 ) {
        this.doClearList();
        this.props.doShowMessage({
          message: 'CE0016',
        });
        return;
      }

      // 取得結果が最大件数の場合
      const maxLen = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      if (this.props.orderList.length >= maxLen) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }

      // FrexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1611', this.props.orderList);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索条件生成
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
  */
  createConditions = (values) => {
    const {generalPurposeMap} = this.props;
    const conditions = {};

    // レコードタイプが契約相違撤去
    conditions['RecordType.DeveloperName'] = 'ContractDifferTekyo';

    // 申込区分が契約相違撤去
    conditions['OrderCategory__c'] = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory14', 2);

    // 入力されている条件を設定
    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }
      if (values[key]) {
        conditions[key] = values[key];
      }
    }
    return conditions;
  }

  /**
  * システムエラー
  */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * ボタンの活性、非活性化
   */
  checkStateUpdate = () => {
    try {
      let isDisabled = true;
      if (this.gridRef && this.gridRef.current &&
          this.gridRef.current.selectedItemCount() > 0) {
        isDisabled = false;
      }
      this.setState({buttonDisabled: isDisabled});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1611', 'Id');
      this.gridRef.current.setItems(null);
    }
  }
  /**
   * 編集ボタン押下時
   */
  doEdit = async () => {
    try {
      const {generalPurposeMap} = this.props;
      // 未選択チェック
      let selectedItemCount = 0;
      if (this.gridRef && this.gridRef.current) {
        selectedItemCount = this.gridRef.current.selectedItemCount();
      }
      if (selectedItemCount <= 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['編集'],
          },
        });
        return;
      }

      // 単一選択チェック
      if (selectedItemCount != 1) {
        this.props.doShowMessage({
          message: {
            id: 'CE0022',
          },
        });
        return;
      }

      // 画面遷移
      const stbTypeSen = getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType1', 1);
      const stbTypeTen = getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType2', 1);
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.doSetStbType(items[0].dataItem.StbType__c);
      this.props.doSetOrderLastModifiedDate(items[0].dataItem.LastModifiedDate);
      if (items[0].dataItem.StbType__c == stbTypeSen) {
        this.props.history.push({pathname: '/SenCorrectTekyoBasicInfo', equip: '線設備'});
      }
      if (items[0].dataItem.StbType__c == stbTypeTen) {
        this.props.history.push({pathname: '/TenCorrectTekyoBasicInfo', equip: '点設備'});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 削除ボタン押下時
   */
  doDelete = async () => {
    try {
      const {generalPurposeMap} = this.props;
      const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
      let selectedItemCount = 0;
      if (this.gridRef && this.gridRef.current) {
        selectedItemCount = this.gridRef.current.selectedItemCount();
      }
      if (selectedItemCount > 0) {
        // 作成中のみ削除可
        const items = this.gridRef.current.selectedItem();
        const messages = [];
        for (let index = 0; index < items.length; index++) {
          const element = items[index];
          if (element.dataItem.OrderStatus__c != orderStatusCreate) {
            messages.push({
              id: 'CE0171',
              values: [element.dataItem.OrderNo__c],
            });
          }
        }
        if (messages.length > 0) {
          this.props.doShowMessage({message: messages});
          return;
        }

        // 最大削除件数より大きい場合、削除不可
        const maxDeleteCount = getMaxDeleteNum();
        if (selectedItemCount > maxDeleteCount) {
          this.props.doShowMessage({
            message: {
              id: 'CE0198',
              values: ['申込', maxDeleteCount],
            },
          });
          return;
        }

        // 選択した申込と申込に紐づく契約電柱、線設備、添付ファイル、添付写真を削除
        this.props.doShowMessage({
          message: {
            id: 'CC0005',
            values: ['削除'],
          },
          action: async () => {
            const orderConditions = [];
            const contractDntConditions = [];
            const senStbConditions = [];
            for (const item of items) {
              orderConditions.push({
                Id: item.dataItem.Id,
                LastModifiedDate: item.dataItem.LastModifiedDate,
              });
            }

            const response = await this.props.doDeleteProprietyOrder(
                orderConditions, contractDntConditions, senStbConditions);
            const resResult = getResponseResult(response, ['訂正撤去申込', '削除']);
            if (resResult.errorCode != API_CODE.SUCCESS) {
              this.props.doShowMessage({
                message: resResult.messages,
                action: async () => {
                  if (resResult.errorCode ==
                          API_CODE.ERROR_LAST_MODIFIED_DATE) {
                    // 再検索
                    await this.doSearch();
                    // 最大件数チェック
                    if (!this.listMaxCountCheck()) {
                      return;
                    }
                  }
                },
              });
              return;
            }

            this.props.doShowMessage({
              message: {
                id: 'CI0009',
                values: ['削除'],
              },
              action: async () => {
                await this.doSearch();
              },
            });
          },
        });
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 線設備新規登録ボタン押下時
   */
  doSenBasicInfo = async () => {
    try {
      const {userInfo, generalPurposeMap} = this.props;
      // 契約電柱マスタ存在チェック
      const countConditions = {};
      countConditions['RecordType.DeveloperName'] = 'ContractDntMaster'; // 契約電柱マスタ
      countConditions['KyogaHaisiDate__c_EQ'] = null; // 共架廃止日
      countConditions['StbType__c'] =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType1', 1); // 線設備

      if (userInfo.MainteKoziCompanyCode__c == null) {
        // ログインユーザが共架事業者
        countConditions['KyogaZgsyaCode__c'] = userInfo.KyogaZgsyaCode__c;
      } else {
        // ログインユーザが保守会社
        countConditions['MaintenanceKoziCompany__c'] = userInfo.MainteKoziCompany__c;
      }
      // 契約電柱検索
      await this.props.doGetContractDntCount(countConditions);
      if (!this.props.hasOwnProperty('contractDntCount') ||
          this.props.contractDntCount <= 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0076',
          },
        });
        return;
      }
      await this.props.doSetOrderId(null);
      await this.props.doClearOrderInfo();
      // 画面遷移
      this.props.doSetStbType(getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType1', 1));
      this.props.history.push({pathname: '/SenCorrectTekyoBasicInfo', equip: '線設備'});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 点設備新規登録ボタン押下時
   */
  doTenBasicInfo = async () => {
    try {
      const {userInfo, generalPurposeMap} = this.props;
      // 契約電柱マスタ存在チェック
      const countConditions = {};
      countConditions['RecordType.DeveloperName'] = 'ContractDntMaster'; // 契約電柱マスタ
      countConditions['KyogaHaisiDate__c_EQ'] = null; // 共架廃止日
      countConditions['StbType__c'] =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType2', 1); // 点設備
      if (userInfo.MainteKoziCompanyCode__c == null) {
        // ログインユーザが共架事業者
        countConditions['KyogaZgsyaCode__c'] = userInfo.KyogaZgsyaCode__c;
      } else {
        // ログインユーザが保守会社
        countConditions['MaintenanceKoziCompany__c'] = userInfo.MainteKoziCompany__c;
      }
      // 契約電柱検索
      await this.props.doGetContractDntCount(countConditions);
      if (!this.props.hasOwnProperty('contractDntCount') ||
          this.props.contractDntCount <= 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0076',
          },
        });
        return;
      }
      await this.props.doSetOrderId(null);
      await this.props.doClearOrderInfo();
      // 画面遷移
      this.props.doSetStbType(getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType2', 1));
      this.props.history.push({pathname: '/TenCorrectTekyoBasicInfo', equip: '点設備'});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧表示
   * @return {object}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true, // 列フィルター有効
      headersVisibility: 'All', // 行ヘッダ、列ヘッダの表示設定
      allowDragging: 'Columns',
      counterOn: true, // グリッドに設定したデータ件数表示有無を設定
      AddDeleteOn: false,
      style: {maxHeight: '300px'}, // グリッドのスタイル設定
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="OrderNo__c" header="申込番号" dataType='String' width={150} />
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType='String' width={100}/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType='String'/>
        <FlexGridColumnGroup binding="OrderDate__c" header="申込年月日" dataType='Date' width={110}>
          <FlexGridCellTemplate
            cellType='Cell'
            template={ (context) => {
              return changeDateFormat(context.item.
                  OrderDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType='String'/>
        <FlexGridColumnGroup header="工事場所" align="center">
          <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
          <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType='String'/>
          <FlexGridColumnGroup binding="KoziPlacePrefecturesName" header="都道府県" dataType='String' width={100}/>
          <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType='String'/>
          <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType='String'/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType='String' width={160}/>
        <FlexGridColumnGroup binding="KyogasyaSendBackReason__c" header="差戻理由" dataType='String'/>
        <FlexGridColumnGroup binding="TemporarilySavedDate__c" header="一時保存日" dataType='Date' width={110}>
          <FlexGridCellTemplate
            cellType='Cell'
            template={ (context) => {
              return changeDateFormat(
                  context.item.
                      TemporarilySavedDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes,
      generalPurposeMap,
      reset,
      handleSubmit,
      userInfo,
    } = this.props;

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="key1611CreateSen" item>
          <PositiveButton
            id="key1611CreateSen"
            onClick={handleSubmit(this.doSenBasicInfo)}
            variant="contained"
            startIcon={<AddIcon />}
            size="large"
          >
            <span>新規登録（線設備）</span>
          </PositiveButton>
        </Grid>

        <Grid key="key1611CreateTen" item>
          <PositiveButton
            id="key1611CreateTen"
            onClick={handleSubmit(this.doTenBasicInfo)}
            variant="contained"
            startIcon={<AddIcon />}
            size="large"
          >
            <span>新規登録（点設備）</span>
          </PositiveButton>
        </Grid>

        <Grid key="key1611EditPropriety" item>
          <PositiveButton
            id="key1611EditPropriety"
            onClick={handleSubmit(this.doEdit)}
            variant="contained"
            startIcon={<EditIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>編集</span>
          </PositiveButton>
        </Grid>

        <Grid key="key1611DeletePropriety" item>
          <DangerButton
            id="key1611DeletePropriety"
            onClick={handleSubmit(this.doDelete)}
            variant="contained"
            startIcon={<DeleteIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>削除</span>
          </DangerButton>
        </Grid>

        <Grid key="btn5" item>
          <BackButton props={this.props}/>
        </Grid>

      </Grid>
    );

    return (
      <form noValidate autoComplete="off">

        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>

          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >

            <Grid key="key1611OrderNo" item xs={12} sm={2}>
              <Field
                id="id1611OrderNo"
                name="OrderNo__c"
                component={renderTextField}
                className={classes.fields}
                label="申込番号"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.alphaNumericSymbol,
                  validateRule.maxLength(12),
                ]}
              />
            </Grid>

            <Grid key="key1611StbType" item xs={12} sm={2}>
              <Field
                id="id1611StbType"
                name="StbType__c"
                component={renderSelect}
                className={classes.fields}
                label="設備種別"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                createSearchList(generalPurposeMap.StbType,
                    'Code__c', 'Name', {Code__c: ['1', '2']})}
              </Field>
            </Grid>

            {/* 共架種別は共架事業者によって異なる*/}
            <Grid key="key1611KyogaType" item xs={12} sm={2}>
              <Field
                id="id1611KyogaType"
                name="KyogaType__c"
                component={renderSelect}
                className={classes.fields}
                label="共架種別"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c: userInfo.Account.Account__r.KyogaType__c,
                          ValueString1__c:
                            Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                              [this.props.values.StbType__c] : ['1', '2']})}
              </Field>
            </Grid>
          </Grid>

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid key="key1611KoziTitle" item xs={12} sm={4}>
              <Field
                id="id1611KoziTitle"
                name="KoziTitle__c"
                component={renderTextField}
                className={classes.fields}
                label="工事件名"
                type="text"
                fullWidth
                required={false}
                validate={[
                  validateRule.maxLength(50),
                ]}
              />
            </Grid>

            <Grid key="key1611OrderStatus" item xs={12} sm={2}>
              <Field
                id="id1611OrderStatus"
                name="OrderStatus__c"
                component={renderSelect}
                className={classes.fields}
                label="申込ステータス"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                   createSearchList(generalPurposeMap.OrderStatus, 'Code__c', 'Name', {Code__c: CorrectTekyoStatus}, false)}
              </Field>
            </Grid>


          </Grid>

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid key="key1611KoziPlaceMainDntNo_SenroName" item xs={12} sm={2}>
              <Field
                id="id1611KoziPlaceMainDntNo_SenroName"
                name="KoziPlaceMainDntNo_SenroName__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／線路名"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.maxLength(30),
                ]}
              />
            </Grid>

            <Grid key="key1611KoziPlaceMainDntNo" item xs={12} sm={2}>
              <Field
                id="id1611KoziPlaceMainDntNo"
                name="Dsp_KoziPlaceMainDntNo__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／代表電柱番号"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.maxLength(23),
                ]}
              />
            </Grid>

            <Grid key="key1611KoziPlacePrefectures" item xs={12} sm={2}>
              <Field
                id="id1611KoziPlacePrefectures"
                name="KoziPlacePrefectures__c"
                component={renderSelect}
                className={classes.fields}
                label="工事場所／都道府県"
                fullWidth
                validate={[
                ]}
                onChange={(event) => {
                  changePrefectures(event, this);
                }}
              >
                {generalPurposeMap != null &&
                  createList(generalPurposeMap.PrefCode)
                }
              </Field>
            </Grid>

            <Grid key="key1611KoziPlaceMunicipalities" item xs={12} sm={3}>
              <Field
                id="id1611KoziPlaceMunicipalities"
                name="KoziPlaceMunicipalities__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／市区町村"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.maxLength(12),
                ]}
              />
            </Grid>

            <Grid key="key1611KoziPlacePlace" item xs={12} sm={3}>
              <Field
                id="id1611KoziPlacePlace"
                name="KoziPlacePlace__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／場所"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.maxLength(255),
                ]}
              />
            </Grid>

            <Grid key="key1611OrderDate" item xs={12} sm={4}>
              <div style={{position: 'relative'}}>
                <Field
                  id="id1611OrderDateFrom"
                  name="OrderDate__c_From"
                  accessibilitylabel="OrderDate__c_From"
                  label="申込年月日"
                  component={renderTextField}
                  className={classes.textFieldDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={[
                    validateRule.date,
                  ]}
                />

                <span className={classes.wave}>～</span>

                <Field
                  id="id1611OrderDateTo"
                  name="OrderDate__c_To"
                  accessibilitylabel="OrderDate__c_To"
                  label={<React.Fragment />}
                  component={renderTextField}
                  className={classes.textFieldDate}
                  required={false}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={[
                    validateRule.date,
                  ]}
                />
              </div>
            </Grid>

            <Grid key="key1611Clear">
              <NegativeButton
                id="id1611Clear"
                variant="contained"
                startIcon={<RotateLeftIcon />}
                className={classes.button}
                size="large"
                onClick={reset}
              >
                <span>クリア</span>
              </NegativeButton>

              <PositiveButton
                id="id1611Search"
                variant="contained"
                startIcon={<SearchIcon />}
                className={clsx(classes.button)}
                size="large"
                onClick={handleSubmit(this.doSearch)}
              >
                <span>検索</span>
              </PositiveButton>
            </Grid>
          </Grid>

          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key1611List" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object.isRequired,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  orderList: PropTypes.array,
  doGetOrderTypeList: PropTypes.func.isRequired,
  doSetOrderId: PropTypes.func.isRequired,
  doClearOrderInfo: PropTypes.func.isRequired,
  doGetAllGeneralPurposeMap: PropTypes.func,
  doDeleteProprietyOrder: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
  doSetStbType: PropTypes.func.isRequired,
  doGetContractDntCount: PropTypes.func.isRequired,
  contractDntCount: PropTypes.object,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderList: state.attachorder.orderList,
  contractDntCount: state.contractDnt.contractDntCount,
  // 表示時のデフォルト設定
  initialValues: {
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderInfo: attachorderOperations.doClearOrderInfo,
  doDeleteProprietyOrder: attachorderOperations.doDeleteProprietyOrder,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doSetStbType: commonOperations.doSetStbType,
  doGetContractDntCount: contractDntOperations.doGetContractDntCount,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'CorrectTekyoOrderList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

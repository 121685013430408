import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

import CommonTheme from '../../../Theme.js';
import * as validateRule from '../../validateRule';

/**
 * 共通部分スタイル定義。
 */
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    flexDirection: 'column',
    marginTop: '100px',
    padding: '16px 2px',
  },
  box: {
    background: '#fff',
    boxShadow: '0 0 10px #333',
    maxWidth: theme.breakpoints.values.sm,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    margin: '0px 20px 16px 20px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: CommonTheme.palette.primary.contrastText,
    backgroundColor: CommonTheme.palette.primary.main,
    padding: '10px 20px',
    position: 'relative',
  },
}));

/**
 * 子要素用共通スタイル定義。
 * @param {object} theme テーマ
 * @return {fuction}
 */
export const commonStyles = (theme) => ({
  button: {
    marginTop: '20px',
  },
  textField: {
    width: '70%',
  },
  typography: {
    textAlign: 'left',
    width: '70%',
    margin: 'auto',
  },
  copyright: {
    fontSize: '0.8em',
    marginTop: '30px',
    width: '98%',
    textAlign: 'right',
    display: 'block',
    position: 'relative',
    color: 'black',
  },
  helpDesk: {
    margin: '0 auto',
    textAlign: 'left',
    fontSize: '0.87em',
    border: '1px solid red',
    background: 'white',
    padding: 10,
  },
  noticeArea: {
    margin: '0 auto',
    marginBottom: 10,
    textAlign: 'left',
    fontSize: '1.016em',
    fontWeight: 'bold',
    border: '1px solid orange',
    background: 'white',
    padding: '4px 10px',
  },
  noticeTitle: {
    margin: '0 auto',
    textAlign: 'center',
    fontSize: '1.1em',
  },
  noticeRed: {
    color: 'red',
  },
  typographyUnavailable: {
    textAlign: 'left',
    width: '71%',
    margin: 'auto',
  },
  availableTimeArea: {
    textAlign: 'center',
    margin: '8px auto -30px auto',
  },
  availableTime: {
    fontWeight: 'bold',
    marginRight: '12px',
  },
  maintenanceExclusion: {
    fontSize: '0.87em',
  },
});

/**
 * ログイン画面 テキストフィールドの描画。
 * @param {object} param0 描画オプション
 * @return {JSX.Element} テキストフィールド
 */
export const renderTextField = ({
  input,
  label,
  meta: {touched, error, valid},
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && !valid}
    InputProps={{
      min: 1,
    }}
    {...input}
    {...custom}
  />
);

/**
 * ログイン画面系列 必須チェックの手動実行
 *
 * ログイン画面系の画面で、バリデーションが動作しない場合があるため
 * 本メソッドで暫定的にチェックを行いチェックエラーとする。
 *
 * 項目に入力を行うとバリデーションが動作するようになるため、
 * 必須チェックのみ行う。
 *
 * @param {function} createCheckTargets 手動で未入力チェックを行う入力項目情報を生成するメソッド
 * @param {function} showMessage メッセージ表示関数
 * @return {boolean} チェック結果
 */
export const checkInputEmpty = (createCheckTargets, showMessage) => {
  const checkTargets = createCheckTargets();

  for (const checkTarget of checkTargets) {
    // 必須チェックエラーの場合はメッセージを表示
    if (validateRule.required(checkTarget.value)) {
      showMessage({
        message: {
          id: 'CE0017',
          values: [checkTarget.name],
        },
      });

      return false;
    }
  }

  return true;
};

/**
 * ログイン画面 共通部分の描画。
 * @param {object} children 子要素
 * @return {JSX.Element} ログイン画面共通部分
 */
export default function Container({children}) {
  const classes = useStyles();

  return (
    <div>
      <form noValidate autoComplete="off" className={classes.root}>
        <div className={classes.box}>
          <div style={{height: '10px'}}></div>
          <Box className={classes.title} boxShadow={3}>
            共架Webシステム「Yupol(ユーポル)」
          </Box>
          {children}
          <div style={{height: '10px'}}></div>
        </div>
      </form>
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.object,
};

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {changeDateFormat, getCodeFromGeneralPurposeMap,
  getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import {renderCheckbox, renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList, changePrefectures} from '../../../../../../common/ComboParts.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

/**
 * 移設依頼ステータス
 */
const isetuIraiStatusList = [
  {'Code__c': 2, 'Name': '工事日確定・未竣工'},
  {'Code__c': 3, 'Name': '工事日変更・未竣工'},
  {'Code__c': 4, 'Name': '工事中止'},
  {'Code__c': 5, 'Name': '竣工済'},
];

// 申込、移設依頼設計書テーブル検索時の検索対象のフィールド名
const appFields = [
  'IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c',
  'IsetuIraiDesignDocument__r.DesignCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.IsetuIraiNo__c',
  'OrderNo__c', 'toLabel(KoziPlacePrefectures__c)',
  'KoziPlaceMunicipalities__c', 'KoziPlacePlace__c',
  'IsetuIraiDesignDocument__r.DntNo__c',
  'IsetuIraiDesignDocument__r.KoziOverview__c',
  'IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfDesign__c',
  'IsetuIraiDesignDocument__r.KoziScheduleSeason__c',
  'IsetuIraiDesignDocument__r.KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocument__r.DesignTantoName__c',
  'IsetuIraiDesignDocument__r.IsetuIraiDesignDocumentNo__c',
  'IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfContact__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDate__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c',
  'IsetuIraiDesignDocument__r.KoziCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.KoziTantoName__c',
  // 画面表示以外
  'Id', 'IsetuIraiDesignDocument__r.IsetuIraiStatus__c', 'OrderStatus__c',
];

// 申込、移設依頼設計書テーブル検索時のソート条件
const appSortParams = {
  'IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c': 1,
};

// 契約電柱テーブルの検索時の検索対象
const subquery = [{
  'fields': ['Id', 'Order__c'],
  'table': 'ContractDnts__r',
}];

/**
 * 移設依頼工事完了届対象一覧
 * 画面ID:1451
 *
 * @param {string} ret
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      retButtonDisabled: true,
      dataList: [],
    };
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1451', []);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearOrderList();
    this.doClearList();
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('orderList') &&
      this.props.orderList ? this.props.orderList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    // 一覧表示部に１件以上表示されているか判定
    let bRet = false;
    if (this.gridRef && this.gridRef.current &&
      this.gridRef.current.selectedItemCount() > 0) {
      bRet = true;
    }
    this.setState({retButtonDisabled: !bRet});
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      const {generalPurposeMap} = this.props;
      // 画面入力されたデータのみ検索対象として取得
      const wkData = JSON.parse(JSON.stringify(this.props.values));

      if (!wkData.hasOwnProperty('IsetuIraiStatus')) {
        // 仕様上、移設依頼ステータスが空になることはないが、念のため判定
        console.error('移設依頼ステータスが未設定のため、選択して下さい');
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['移設依頼ステータス'],
          },
        });
        return;
      }

      // 工事保留/工事中止の条件設定
      if (wkData.KoziHoldOrStop == true) {
        wkData['IsetuIraiDesignDocument__r.KoziStop__c_EQ'] = wkData.KoziHoldOrStop;
      } else {
        wkData['IsetuIraiDesignDocument__r.KoziStop__c'] = wkData.KoziHoldOrStop;
        wkData['IsetuIraiDesignDocument__r.KoziHold__c'] = wkData.KoziHoldOrStop;
      }

      // 移設依頼ステータス取得
      const isetuStatus = isetuIraiStatusList.find(
          (value) => value.Code__c == wkData.IsetuIraiStatus);
      // 移設依頼ステータスによって条件設定
      switch (isetuStatus.Code__c) {
        case 2:// 工事日確定・未竣工
          // 申込ステータスが竣工待ち/工事日確定済
          wkData['OrderStatus__c'] = '51';
          break;
        case 3:// 工事日変更・未竣工
          // 申込ステータスが竣工待ち/工事日確定済かつ電力移線工事日（変更）に値があるもの
          wkData['OrderStatus__c'] = '51';
          wkData['IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c_NE'] = null;
          break;
        case 4:// 工事中止
          // 工事中止
          wkData['IsetuIraiDesignDocument__r.KoziStop__c'] = true;
          break;
        case 5:// 竣工済
          // 申込ステータスが完結
          wkData['OrderStatus__c'] = '90';
          break;
        default:
          wkData['OrderStatus__c'] = ['51', '90'];
          break;
      }

      // 移設依頼設計書のIdが存在する条件設定
      wkData['IsetuIraiDesignDocument__r.Id_NE'] = null;
      // レコードタイプ
      wkData['RecordType.DeveloperName'] = 'IsetuIrai';
      // 移設依頼対象
      wkData['IsetuIraiTarget__c'] =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'IsetuIraiTarget1', 1);
      // 工事予定時期
      if (wkData.hasOwnProperty('KoziScheduleSeason__c_From')) {
        if (wkData.KoziScheduleSeason__c_From) {
          wkData['KoziScheduleSeason__c_From'] = wkData.KoziScheduleSeason__c_From + '-01';
        }
      }
      if (wkData.hasOwnProperty('KoziScheduleSeason__c_To')) {
        if (wkData.KoziScheduleSeason__c_To) {
          wkData['KoziScheduleSeason__c_To'] = wkData.KoziScheduleSeason__c_To + '-01';
        }
      }

      // 移設依頼設計書の検索条件作成
      // 検索条件の設定値が以下で格納されるため変換
      // IsetuIraiDesignDocument__r: {xxx__c: 'xxx'}
      //  -> IsetuIraiDesignDocument__r.xxx__c: 'xxx'
      if ('IsetuIraiDesignDocument__r' in wkData) {
        const isetuData = wkData['IsetuIraiDesignDocument__r'];
        for (const item in isetuData) {
          if (isetuData.hasOwnProperty(item)) {
            wkData['IsetuIraiDesignDocument__r.' + item] = isetuData[item];
          }
        }
        delete wkData['IsetuIraiDesignDocument__r'];
      }

      let isetuiraiStatusName = isetuStatus.Name;
      if (wkData.KoziHoldOrStop == true) {
        // 移設依頼ステータス取得
        const wkIsetuStatus = isetuIraiStatusList.find(
            (value) => value.Code__c == 4);
        isetuiraiStatusName = wkIsetuStatus.Name;
      }
      // 移設依頼ステータスを検索結果に追加
      const addFields = {
        IsetuIraiStatus: isetuiraiStatusName,
      };

      // 検索条件から空文字条件除去
      const conditions = {};
      for (const item in wkData) {
        if (!wkData.hasOwnProperty(item)) {
          continue;
        }
        if (typeof wkData[item] == 'string' && !wkData[item].trim()) {
          continue;
        }
        conditions[item] = wkData[item];
      }

      // 申込テーブルからデータ取得
      const result = await this.props.doGetOrderTypeList(
          conditions, appFields, appSortParams,
          'getKoziCompSubEx', subquery, addFields);
      const errCode = result ? result.data ? result.data.body ?
        result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return;
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
        !this.props.orderList ||
        this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }

      let wkDataList = [];
      if (this.props.hasOwnProperty('orderList') &&
        this.props.orderList) {
        for (const items of this.props.orderList) {
          let order = {};
          for (const item in items) {
            if (!items.hasOwnProperty(item)) {
              continue;
            }
            order[item] = items[item];
          }
          order['KoziTargetHonsu'] = items.ContractDnts__r ? items.ContractDnts__r.totalSize : 0;
          wkDataList.push(order);
        }
        this.setState({
          dataList: wkDataList,
        });
      }

      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1451', 'Id', true);
        this.gridRef.current.setItems(wkDataList);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  // 移設工事完了届ボタン押下時
  doConfirm = async () => {
    try {
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (selectedItems.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['編集対象'],
          },
        });
        return;
      }

      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }

      this.props.doSetOrderId(selectedItems[0].dataItem.Id);
      this.props.history.push({pathname: '/IsetuIrai/KoziDone/TransferConstructionCompleteReport/Input'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1451', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    // 工事予定時期
    if (e.getColumn().binding === 'KoziScheduleSeason__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        const ret = changeDateFormat(lb.collectionView.items[index].value, 'YYYY年MM月');
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
      filterChanging: this.filterChangingHandler,
    };

    return (
    // 一覧表示部の表示設定
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='IsetuIraiStatus' header='移設依頼ステータス' dataType='String' width={165}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c' header='移設依頼発信日' dataType='Date'>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return context.item.IsetuIraiDesignDocument__r ?
                 changeDateFormat(context.item.
                     IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c) : '';
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DesignCompanySyozoku__c' header='設計会社／所属' dataType='String' width={165}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuIraiNo__c' header='一連番号' dataType='String' width={100}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziPlacePrefectures__c' header='都道府県' dataType='String' width={100}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DntNo__c' header='電柱番号' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziOverview__c' header='工事概要' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfDesign__c' header='移設完了希望日 （設計時）' dataType='Date' width={220}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.IsetuIraiDesignDocument__r.
                        IsetuCompHopeDateTimeOfDesign__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziScheduleSeason__c' header='工事予定時期' dataType='Date'>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.
                        IsetuIraiDesignDocument__r.KoziScheduleSeason__c, 'YYYY年MM月');
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziScheduleSeasonHosoku__c' header='工事予定時期補足' dataType='String' width={165}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuIraiDesignDocumentNo__c' header='設計書番号' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziTargetHonsu' header='工事対象本数' dataType='Number'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DesignTantoName__c' header='設計担当者名' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfContact__c' header='移設完了希望日（工事日連絡時）' dataType='Date' width={250}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.IsetuIraiDesignDocument__r.
                        IsetuCompHopeDateTimeOfContact__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DrIsnKoziDate__c' header='電力移線工事日' dataType='Date'>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.IsetuIraiDesignDocument__r.DrIsnKoziDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c' header='電力移線工事日（変更）' dataType='Date' width={200}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.IsetuIraiDesignDocument__r.
                        DrIsnKoziDateChange__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziCompanySyozoku__c' header='工事会社／所属' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocument__r.KoziTantoName__c' header='工事担当者名' dataType='String'></FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<ListAltIcon />}

            onClick={() => {
              this.doConfirm();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>移設工事完了届</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderSelect}
                  label="移設依頼ステータス"
                  fullWidth
                  validate={[
                  ]}
                >
                  {createSearchList(isetuIraiStatusList, 'Code__c', 'Name', null, 'false')}
                </Field>
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuIraiCarryOutDate__c_From"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_From"
                    label="移設依頼発信年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuIraiCarryOutDate__c_To"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(8),
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="key1-6" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                     createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>

              <Grid key="key1-8" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-10" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                />
              </Grid>

              <Grid key="key1-11" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocument__r.KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  validate={[
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

              <Grid key="key1-12" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_From"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_From"
                    label="移設完了希望日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_To"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-13" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="KoziScheduleSeason__c_From"
                    accessibilitylabel="KoziScheduleSeason__c_From"
                    label="工事予定時期"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="KoziScheduleSeason__c_To"
                    accessibilitylabel="KoziScheduleSeason__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-14" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="DrIsnKoziDate__c_From"
                    accessibilitylabel="DrIsnKoziDate__c_From"
                    label="電力移線工事日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="DrIsnKoziDate__c_To"
                    accessibilitylabel="DrIsnKoziDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-15" item xs={12} sm={6} md={3}>
                <div style={{paddingLeft: '15px'}}>
                  <Field className={classes.checkbox}
                    name="KoziHoldOrStop"
                    component={renderCheckbox}
                    label="工事保留／工事中止"
                  />
                </div>
              </Grid>

              <Grid key="key2-1" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>

          </PaperPart>

          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderTypeList: PropTypes.func,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  doClearOrderList: PropTypes.func,
  doSetOrderId: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  generalPurposeMap: state.common.generalPurposeMap,
  orderList: state.attachorder.orderList,
  // 表示時のデフォルト設定
  initialValues: {
    IsetuIraiStatus: 2,
    KoziHoldOrStop: false,
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doSetOrderId: attachorderOperations.doSetOrderId,
};

const FORM_NAME = 'TransferConstructionCompleteReportList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

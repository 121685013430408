import types from './types';

// 線設備一覧 取得
export const getSenStbList = (senStbList) => ({
  type: types.GET_SEN_STB_LIST,
  senStbList,
});

// 線設備 業務チェック
export const checkAttachOrderSenStb = (checkAttachOrderSenStbResult) => ({
  type: types.CHECK_SEN_STB,
  checkAttachOrderSenStbResult,
});


// 線設備 保存
export const saveSenStbs = (senStbResult) => ({
  type: types.SAVE_SEN_STB,
  senStbResult,
});

import types from './types';

// 申込一覧 取得
export const getOrderList = (orderList) => ({
  type: types.GET_ORDER_LIST,
  orderList,
});

// 申込 取得（関数名指定）
export const getOrderTypeList = (orderList) => ({
  type: types.GET_ORDER_TYPE_LIST,
  orderList,
});

// 申込 取得
export const retrieveOrder = (order) => ({
  type: types.RETRIEVE_ORDER,
  order,
});

// 申込情報 取得
export const getOrderInfo = (orderInfo) => ({
  type: types.GET_ORDER_INFO,
  orderInfo,
});

// 申込 登録
export const registOrder = (orderResult) => ({
  type: types.REGIST_ORDER,
  orderResult,
});

// 申込 更新
export const updateOrder = (orderResult) => ({
  type: types.UPDATE_ORDER,
  orderResult,
});

// 申込ID 設定
export const setOrderId = (orderId) => ({
  type: types.SET_ORDER_ID,
  orderId,
});

// 可否判定結果
export const getOrderResultList = (orderResultList) => ({
  type: types.ORDER_RESULT_LIST,
  orderResultList,
});

// 可否判定結果詳細
export const getOrderResultDetail = (orderResultDetail) => ({
  type: types.ORDER_RESULT_DETAIL,
  orderResultDetail,
});

// 可否判定申込 登録/更新
export const updateProprietyOrder = (orderResult) => ({
  type: types.UPDATE_PROPRIETY_ORDER,
  orderResult,
});


// 可否判定申込 削除
export const deleteProprietyOrder = (orderResult) => ({
  type: types.DELETE_PROPRIETY_ORDER,
  orderResult,
});

// 本申込 削除
export const deleteFormalOrder = (orderResult) => ({
  type: types.DELETE_FORMAL_ORDER,
  orderResult,
});

// 本申込情報 取得
export const getFormalOrderInfo = (orderInfo) => ({
  type: types.GET_FORMAL_ORDER_INFO,
  orderInfo,
});

// 本申込 登録/更新
export const updateFormalOrder = (orderResult) => ({
  type: types.UPDATE_FORMAL_ORDER,
  orderResult,
});

// 申込 入力チェック
export const checkOrder = (orderCheckResult) => ({
  type: types.CHECK_ORDER,
  orderCheckResult,
});

// 申込一覧 取得
export const getOrderSubList = (orderSubList) => ({
  type: types.GET_ORDER_SUB_LIST,
  orderSubList,
});

// 申込一覧 取得
export const getOrderSubAllList = (orderSubAllList) => ({
  type: types.GET_ORDER_SUB_ALL_LIST,
  orderSubAllList,
});

// 一束化有無 設定
export const setIskkUmu = (isIskk) => ({
  type: types.SET_ISKK_UMU,
  isIskk,
});

// 申込番号 設定
export const setOrderNo = (orderNo) => ({
  type: types.SET_ORDER_NO,
  orderNo,
});

// 最終更新日時 設定
export const setOrderLastModifiedDate = (orderLastModifiedDate) => ({
  type: types.SET_ORDER_LAST_MODIFIED_DATE,
  orderLastModifiedDate,
});

// Idと最終更新日時 設定
export const setOrderModifiedCondition = (orderModifiedCondition) => ({
  type: types.SET_ORDER_MODIFIED_CONDITION,
  orderModifiedCondition,
});

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, getFormSyncWarnings} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {getComboName, execApexRestApi, changeDateFormatter, changeStringDate,
  isNWDesignZgsya, API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {checkFileNameLength, checkFileSizeZero} from '../../../../../../common/fileOperations.js';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import Upload from '../../../../../organisms/UploadCustom';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createList} from '../../../../../../common/ComboParts.js';
import {commonOperations} from '../../../../../../reducks/common';
import * as validateRule from '../../../../../validateRule';
import Required from '../../../../../atoms/RequiredMark.js';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

/** この画面で登録/更新を行うフィールド名 */
const useFields = [
  'IsetuIraiNo__c',
  'IsetuIraiCarryOutDate__c',
  'KoziPlacePrefectures__c',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'Mark__c',
  'KoziOverview__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'MessageTimeOfDesign__c',
  'IsetuIraiHonsu__c',
  'DesignCompanySyozoku__c',
  'DesignTantoName__c',
  'DesignTel__c',
  'DesignMailAddress__c',
  'IsetuIraiStatus__c',
  'LastModifiedDate',
];
const updateParams = [
  'KoziPlacePrefectures__c',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'Mark__c',
  'KoziOverview__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'MessageTimeOfDesign__c',
  'DesignCompanySyozoku__c',
  'DesignTantoName__c',
  'DesignTel__c',
  'DesignMailAddress__c',
];

/**
 * 移設依頼設計書 検索条件
 */
const appFields = [
  'IsetuIraiNo__c',
  'IsetuIraiCarryOutDate__c',
  'KoziPlacePrefectures__c',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'Mark__c',
  'IsetuIraiStatus__c',
  'KoziOverview__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'MessageTimeOfDesign__c',
  'IsetuIraiHonsu__c',
  'DesignCompanySyozoku__c',
  'DesignTantoName__c',
  'DesignTel__c',
  'DesignMailAddress__c',
  'Id',
  'LastModifiedDate',
];


/** 移設依頼設計書、添付ファイル名検索時の検索対象 */
const subquery = [
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient',
      'ContentDocument.LatestPublishedVersion.FileType',
      'ContentDocument.LatestPublishedVersion.ContentDocumentId'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['C02', 'C03', 'C04'],
    },
    'table': 'ContentDocumentLinks',
  },
];


/**
 * 移設依頼基本情報入力 テンプレート。
 * 画面ID:1402
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefectureId: null,
      isetuFiles: [],
      otherFiles: [],
      nttFiles: [],
      isetuDelFiles: [],
      otherDelFiles: [],
      nttDelFiles: [],
      isModified: false,
      isDisabled: false,
      isButtonDisabled: false,
      warningMessages: [],
    };
  }

  /**
  * 初期処理
  */
  async componentDidMount() {
    try {
    // 移設依頼設計書の情報取得
      await this.getDesignDocument();
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 移設依頼設計書の情報取得
   */
  async getDesignDocument() {
    const {userInfo, generalPurposeMap} = this.props;
    const docId = this.props.designDocumentId;
    if (docId != null) {
      // 移設依頼設計書IDが指定されている場合は編集のため取得
      const conditions = {
        'Id': docId,
        'RecordType.DeveloperName': 'IsetuIraiDesignDocument',
      };
      let result = await this.props.doGetDesignDocument(
          conditions, appFields, null, 'getSub', subquery);
      let errCode = result ? result.data ? result.data.body ?
          result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return false;
      }

      // 更新対象のオーダも取得しておく
      const orderCondition = {
        'IsetuIraiDesignDocument__r.Id_EQ': docId,
      };
      const orderFields = ['Id', 'LastModifiedDate'];
      result = await this.props.doGetOrderSubAllList(
          orderCondition, orderFields, null, null);
      errCode = result ? result.data ? result.data.body ?
          result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return false;
      }
    }

    const _isetuFiles = [];
    const _otherFiles = [];
    const _nttFiles = [];

    let _isDisabled = false;

    // 取得値から添付ファイル名取得
    if (this.props.hasOwnProperty('saveDesignDocument') &&
          this.props.saveDesignDocument) {
      if (this.props.saveDesignDocument.IsetuIraiStatus__c != '00') {
        // 移設依頼ステータスが作成中以外は表示のみに設定
        _isDisabled = true;
      }

      // 添付ファイルのハイパーリンク設定
      if (this.props.saveDesignDocument.ContentDocumentLinks) {
        for (const data of
          this.props.saveDesignDocument.ContentDocumentLinks.records) {
          const vesionData = data.ContentDocument.LatestPublishedVersion;

          let targetFiles;
          if (vesionData.AttachedFileType__c === 'C03') {
            // 添付（移設依頼設計書）
            targetFiles = _isetuFiles;
          } else if (vesionData.AttachedFileType__c === 'C04') {
            // 添付（その他）
            targetFiles = _otherFiles;
          } else if (vesionData.AttachedFileType__c === 'C02') {
            // 添付（占用申請情報）
            targetFiles = _nttFiles;
          }

          if (targetFiles) {
            targetFiles.push({
              'AttachedFileType__c': vesionData.AttachedFileType__c,
              'VersionData': vesionData.VersionData,
              'PathOnClient': vesionData.PathOnClient,
              'FileType': vesionData.FileType,
              'ContentDocumentId': vesionData.ContentDocumentId,
            });
          }
        }
      }
    }

    // ログインユーザが、外線工事会社の場合
    if (isNWDesignZgsya(userInfo, generalPurposeMap) &&
        userInfo.MainteKoziCompany__c != null &&
        userInfo.MainteKoziCompany__c) {
      // 表示のみに設定
      _isDisabled = true;
    }

    this.setState({
      isetuFiles: _isetuFiles,
      otherFiles: _otherFiles,
      nttFiles: _nttFiles,
      isetuDelFiles: [],
      otherDelFiles: [],
      nttDelFiles: [],
      isDisabled: _isDisabled,
      isButtonDisabled: _isDisabled,
    });
    const _warningMessages = [];
    if (docId != null) {
      if (this.state.isetuFiles.length <= 0) {
        _warningMessages.push({
          id: 'CE0018',
          values: ['移設依頼設計書'],
        });
      }
    }
    let isWarn = false;
    // 入力チェックエラー
    if ((this.props.syncWarnings &&
      Object.keys(this.props.syncWarnings).length > 0) ||
      this.state.isetuFiles.length <= 0) {
      // 入力チェックNG
      isWarn = true;
    }
    this.setState({
      isModified: false,
      warningMessages: _warningMessages,
    });
    await this.props.doSetGmnWarning('1402', isWarn);
    return true;
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doDelDesignDocument();
  }

  /**
   * 保存ボタン押下
   *
   * @return {void}
   */
  doTemporarilySave = async () => {
    try {
      // 保存処理（Promiseで返却されるため、await追加）
      const result = await this.saveOrUpdate();
      if (!result) {
        return;
      }
      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 移設依頼設計書の情報取得
          const searchResult = await this.getDesignDocument();
          if (!searchResult) {
            return;
          }
          // 移設依頼設計書の最終更新日を退避
          this.props.doSetOrderLastModifiedDate(
              this.props.saveDesignDocument.LastModifiedDate);
        },
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 詳細入力ボタン押下
   *
   * @return {void}
   */
  toDetailInput = async () => {
    const {
      designDocumentId,
      dirty,
    } = this.props;

    try {
      if (!designDocumentId || designDocumentId.length <= 0) {
        // データ保存されていない場合は、エラー表示
        this.props.doShowMessage({
          message: {
            id: 'ME0182',
          },
        });
        return;
      }

      if (this.state.isDisabled) {
        this.doNextDetailInput();
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (dirty || this.state.isModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    // 警告チェック
    let hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1402')) {
        hasError = (this.props.setGmnWarning[1402] != undefined ?
              this.props.setGmnWarning[1402] : false);
      }
    }

    // 入力チェックエラー
    if (hasError) {
      // 警告表示
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['登録']},
        action: () => {
          this.doNextDetailInput();
        },
        type: 'warn',
      });
      return;
    }

    // 入力チェックOK
    this.doNextDetailInput();
  }

  /**
   * 移設依頼詳細情報入力画面へ移動
   */
  doNextDetailInput = async () => {
    this.props.history.push({pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/DetailInput'});
  }

  /**
   * DB更新
   *
   * @return {Promise<boolean>} 処理結果
   */
  saveOrUpdate = async () => {
    const {
      designDocumentId,
      doSetDesignDocumentId,
      saveDesignDocument,
      values,
      userInfo,
    } = this.props;

    let result = null;
    if (designDocumentId) {
      // 設計書番号が指定されている場合は更新
      if (saveDesignDocument == null) {
        // 仕様上、データが存在しないことはないが、念のため判定
        console.error('移設依頼基本情報入力の対象データがありません');
        this.props.doShowMessage({
          message: {
            id: 'ME0183',
          },
        });
        return false;
      }

      // 未登録の添付ファイルをS3に登録し、登録したファイルの情報を取得する
      const fileInfo = await this.uploadFilesToS3();

      // SF削除対象ファイル抽出
      const concatFiles = [].concat(
          this.state.isetuDelFiles,
          this.state.otherDelFiles,
          this.state.nttDelFiles,
      );

      const contentDocumentIdList = concatFiles.filter(
          (file) => !!file.ContentDocumentId)
          .map((file)=> file.ContentDocumentId);

      const deleteFiles = [];
      for (const contentDocumentId of contentDocumentIdList) {
        deleteFiles.push({Id: contentDocumentId});
      }

      const updateData = {};
      for (const key of updateParams) {
        if (Object.hasOwnProperty.call(values, key)) {
          if (values[key] != saveDesignDocument[key]) {
            // 更新されている項目だけ更新対象に追加
            updateData[key] = values[key];
          }
        }
      }

      // 移設完了希望日が未設定に変更された場合はnullに置き換え
      if (updateData.IsetuCompHopeDateTimeOfDesign__c === '') {
        updateData.IsetuCompHopeDateTimeOfDesign__c = null;
      }

      if ('DesignCompanySyozoku__c' in updateData) {
        Object.assign(updateData, {
          'IraimotoCompanySyozoku__c': updateData.DesignCompanySyozoku__c});
      }

      if ('KoziScheduleSeason__c' in updateData) {
        // 工事予定時期は、日は01で登録
        const koziDate = changeDateFormatter(
            updateData.KoziScheduleSeason__c, 'YYYY-MM') + '-01';
        const saveData = changeDateFormatter(
            saveDesignDocument['KoziScheduleSeason__c'], 'YYYY-MM-DD');

        if (koziDate !== saveData) {
          Object.assign(updateData, {
            'KoziScheduleSeason__c': koziDate});
        } else {
          console.warn(koziDate + '   ' + saveData);
          delete updateData.KoziScheduleSeason__c;
        }
      }

      Object.assign(updateData, {'Id': saveDesignDocument.Id});

      // 既に作成されているオーダ更新
      let orderList = [];
      for (const item of this.props.orderSubAllList) {
        let orderData = {};
        if ('KoziPlacePrefectures__c' in updateData) {
          orderData['KoziPlacePrefectures__c'] = updateData.KoziPlacePrefectures__c;
        }
        if ('KoziPlaceMunicipalities__c' in updateData) {
          orderData['KoziPlaceMunicipalities__c'] = updateData.KoziPlaceMunicipalities__c;
        }
        if ('KoziPlacePlace__c' in updateData) {
          orderData['KoziPlacePlace__c'] = updateData.KoziPlacePlace__c;
        }
        if ('KoziOverview__c' in updateData) {
          orderData['IsetuIraiContents__c'] = updateData.KoziOverview__c;
        }
        if (orderData) {
          orderData['Id'] = item.Id;
          orderData['LastModifiedDate'] = item.LastModifiedDate;
          orderList.push(orderData);
        }
      }
      if (orderList.length == 0) {
        orderList = null;
      }

      const body = {
        'record': {
          'conditions': {
            'Id': saveDesignDocument.Id,
            'LastModifiedDate': this.props.orderLastModifiedDate,
          },
          'designDocument': updateData,
          'deleteFiles': deleteFiles,
          'stage': 'BASIC_INF_INPUT_SAVE',
          'order': orderList,
        },
        'fileInfo': fileInfo,
      };

      result = await execApexRestApi(
          userInfo, 'ApiEnergia/basicInfInputSave', body);
    } else {
      // 設計書番号が指定されていない場合は新規登録

      // 未登録の添付ファイルをS3に登録し、登録したファイルの情報を取得する
      const fileInfo = await this.uploadFilesToS3();

      // 新規登録のため削除対象ファイルは空
      fileInfo['deleteFileKeys'] = [];

      const updateData = {
        'IsetuIraiStatus__c': '00',
        'IraimotoCompanySyozoku__c': values.DesignCompanySyozoku__c,
        'TrZgsyaCode__c': userInfo.KyogaZgsyaCode__c,
        'TrZgsyoCode__c': userInfo.KyogaZgsyoCode__c,
      };

      for (const key of updateParams) {
        if (Object.hasOwnProperty.call(values, key) &&
          values[key]) {
          updateData[key] = values[key];
        }
      }

      if ('KoziScheduleSeason__c' in updateData) {
        // 工事予定時期は、日は01で登録
        const koziDate = changeDateFormatter(
            updateData.KoziScheduleSeason__c, 'YYYY-MM') + '-01';
        Object.assign(updateData, {
          'KoziScheduleSeason__c': koziDate});
      }

      const body = {
        'record': {
          'conditions': {},
          'designDocument': updateData,
          'deleteFiles': [],
          'stage': 'BASIC_INF_INPUT_SAVE',
        },
        'fileInfo': fileInfo,
      };

      result = await execApexRestApi(
          userInfo, 'ApiEnergia/basicInfInputSave', body);

      doSetDesignDocumentId(result.data.body.data.id);
    }

    const resResult = getResponseResult(result, ['移設依頼基本情報入力', '更新']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: resResult.messages,
        action: async () => {
          if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
            this.gotoListGmn();
          }
        },
      });
      return false;
    }
    return true;
  }

  /**
   * 移設依頼設計書資料設定
   *
   * @param {object} files
   * @param {object} deleteFiles
   */
  setRootFiles = (files, deleteFiles) => {
    const _files = [];
    for (const file of files) {
      file['AttachedFileType__c'] = 'C03';
      _files.push(file);
    }

    this.setState({
      isetuFiles: _files,
      isetuDelFiles: deleteFiles,
      isModified: true,
    });
  }

  /**
   * その他資料設定
   *
   * @param {object} files
   * @param {object} deleteFiles
   */
  setOtherFiles = (files, deleteFiles) => {
    const _files = [];
    for (const file of files) {
      file['AttachedFileType__c'] = 'C04';
      _files.push(file);
    }

    this.setState({
      otherFiles: _files,
      otherDelFiles: deleteFiles,
      isModified: true,
    });
  }

  /**
   * 占用申請情報 設定
   *
   * @param {*} files
   * @param {*} deleteFiles
   */
  setNttFiles = (files, deleteFiles) => {
    const _files = [];
    for (const file of files) {
      file['AttachedFileType__c'] = 'C02';
      _files.push(file);
    }

    this.setState({
      nttFiles: _files,
      nttDelFiles: deleteFiles,
      isModified: true,
    });
  }

  /**
   * ファイルのS3登録
   *
   * 未登録の 移設依頼設計書資料/その他資料/占用申請情報 を
   * S3にまとめて登録し、Salesforceの添付として登録するために必要な情報を返却する。
   */
  uploadFilesToS3 = async () => {
    const uploadInfo = await this.props.doUploadFilesToS3ForSf(
        this.state.isetuFiles,
        this.state.otherFiles,
        this.state.nttFiles,
    );

    return uploadInfo;
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/List'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  render() {
    const {classes, handleSubmit, generalPurposeMap} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn1" item>
          <PositiveButton
            id="btnSave"
            startIcon={<SaveAltIcon />}
            onClick={handleSubmit(this.doTemporarilySave)}
            variant="contained"
            size="large"
            disabled={this.state.isButtonDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            id="btnDetailInput"
            startIcon={<EditIcon />}
            onClick={handleSubmit(this.toDetailInput)}
            variant="contained"
            size="large"
            type="button"
          >
            <span>詳細入力</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <BackButton
            showWarning={true}
            props={this.props}
            onClick={() => {
              this.props.doSetGmnWarning('1402', false);
            }}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          {/* 警告メッセージ */}
          <WarningMessageArea messages={this.state.warningMessages}/>

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h6">電力側工事場所</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  placeholder="入力不可"
                  disabled={this.state.isDisabled}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiCarryOutDate__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼発信年月日"
                  fullWidth
                  type={this.props.values.IsetuIraiCarryOutDate__c ? (this.type='date'):(this.type='text')}
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  placeholder="入力不可"
                  disabled={this.state.isDisabled}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                  ]}
                  warn={[
                    validateRule.required3,
                  ]}
                >
                  {generalPurposeMap != null &&
                    createList(generalPurposeMap.PrefCode)
                  }
                </Field>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  required={true}
                  type="text"
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="key1-6" item xs={12} sm={3}>
                <Field
                  name="DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={6} md={3}>
                <Field
                  name="Mark__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="目標"
                  fullWidth
                  type="text"
                  required={false}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-8" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼ステータス"
                  fullWidth
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  type="text"
                  required={false}
                  placeholder="入力不可"
                  disabled={this.state.isDisabled}
                  validate={[
                  ]}
                >
                  {generalPurposeMap != null &&
                    getComboName(generalPurposeMap, 'IsetuIraiStatus',
                        this.props.values.IsetuIraiStatus__c)
                  }
                </Field>
              </Grid>

            </Grid>

          </PaperPart>

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="h6">電力側工事概要</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid key="key2-1" item xs={12} sm={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(100),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="2-2" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziScheduleSeason__c"
                  accessibilitylabel="KoziScheduleSeason"
                  label="工事予定時期"
                  component={renderTextField}
                  className={classes.fields}
                  fullWidth
                  required={true}
                  type="month"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.month,
                  ]}
                  warn={[
                    validateRule.required,
                    validateRule.pastMonthDate,
                  ]}
                />
              </Grid>

              <Grid key="key2-3" item xs={12} sm={3}>
                <Field
                  name="KoziScheduleSeasonHosoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事予定時期補足"
                  fullWidth
                  type="text"
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key2-4" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  required={true}
                  type="text"
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(255),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="2-5" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuCompHopeDateTimeOfDesign__c"
                  accessibilitylabel="IsetuCompHopeDateTimeOfDesign"
                  label="移設完了希望日"
                  component={renderTextField}
                  className={classes.fields}
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.date,
                  ]}
                  warn={[
                    validateRule.pastDate,
                  ]}
                />
              </Grid>

              <Grid key="key2-6" item xs={12} sm={3}>
                <Field
                  name="MessageTimeOfDesign__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="連絡事項（設計時）"
                  fullWidth
                  type="text"
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="key2-7" item xs={12} sm={3}>
                <Field
                  name="IsetuIraiHonsu__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事対象本数"
                  fullWidth
                  type="text"
                  InputLabelProps={{shrink: true}}
                  InputProps={{readOnly: true}}
                  placeholder="入力不可"
                  disabled={this.state.isDisabled}
                  validate={[
                  ]}
                />
              </Grid>

            </Grid>
          </PaperPart>

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography variant="h6">設計者</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key3-1" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社/部署名"
                  fullWidth
                  type="text"
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="key3-2" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(20),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="key3-1-3" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTel__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電話番号"
                  fullWidth
                  type="text"
                  required={true}
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                />
              </Grid>

              <Grid key="key3-1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignMailAddress__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="メールアドレス"
                  fullWidth
                  type="text"
                  disabled={this.state.isDisabled}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                  warn={[
                    validateRule.email,
                  ]}
                />
              </Grid>

            </Grid>
          </PaperPart>

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid item xs={12}>
                <Typography variant="h6">工事図面・その他</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography>工事図面
                      <font size="2">(※最大1ファイル、1ファイルあたり3MBまで登録できます)</font>
                      <Required/></Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Upload
                      componentId="GenbaStateFigureFileId_File"
                      maxFileCount={1}
                      maxFileSize={3145728}
                      previewFlag={true}
                      required={true}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      fileSetHandler={this.setRootFiles}
                      initFile={this.state.isetuFiles}
                      disabled={this.state.isDisabled}
                      customChecks={[
                        checkFileNameLength,
                        checkFileSizeZero,
                      ]}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >

                  <Grid item xs={12}>
                    <Typography>その他
                      <font size="2">(※最大1ファイル、1ファイルあたり3MBまで登録できます)</font>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Upload
                      componentId="SenyoRequestInfFileId_File"
                      maxFileCount={1}
                      maxFileSize={3145728}
                      previewFlag={true}
                      required={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      fileSetHandler={this.setOtherFiles}
                      initFile={this.state.otherFiles}
                      disabled={this.state.isDisabled}
                      customChecks={[
                        checkFileNameLength,
                        checkFileSizeZero,
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </PaperPart>

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid item xs={12}>
                <Typography variant="h6">占用申請状況に係る連絡票（NTTのみ）</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography>占用申請状況に係る連絡票
                      <font size="2">(※最大1ファイル、1ファイルあたり3MBまで登録できます)</font>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Upload
                      componentId="GenbaStateFigureFileId_File"
                      maxFileCount={1}
                      maxFileSize={3145728}
                      previewFlag={true}
                      required={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      fileSetHandler={this.setNttFiles}
                      initFile={this.state.nttFiles}
                      disabled={this.state.isDisabled}
                      customChecks={[
                        checkFileNameLength,
                        checkFileSizeZero,
                      ]}
                    />
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </PaperPart>

          <div style={{marginBottom: '20px'}} />

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object,
  syncWarnings: PropTypes.object,
  dirty: PropTypes.bool.isRequired,
  generalPurposeMap: PropTypes.object,
  designDocumentId: PropTypes.string,
  saveDesignDocument: PropTypes.object,
  setGmnWarning: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doSetDesignDocumentId: PropTypes.func.isRequired,
  doDelDesignDocument: PropTypes.func,
  doGetDesignDocument: PropTypes.func,
  doUploadFilesToS3ForSf: PropTypes.func.isRequired,
  doSetGmnWarning: PropTypes.func,
  orderSubAllList: PropTypes.array,
  doGetOrderSubAllList: PropTypes.func,
  doSetOrderLastModifiedDate: PropTypes.func,
  orderLastModifiedDate: PropTypes.string,
};

const mapStateToProps = (state) => {
  const initValues = {
    IraiMotoCompanySyozoku__c: state.auth.userInfo.Account.Name,
  };

  // 保存済の情報があれば設定
  const savedData = state.isetuIrai.saveDesignDocument;
  if (savedData) {
    // この画面で更新するデータだけを設定
    for (const name of useFields) {
      if (savedData[name]) {
        initValues[name] = savedData[name];

        if (name == 'IsetuIraiStatus__c') {
          initValues['IsetuIraiStatus'] =
           getComboName(state.common.generalPurposeMap,
               'IsetuIraiStatus', savedData[name]);
        }

        if (name == 'KoziScheduleSeason__c') {
          // 工事予定時期は、年月に変換
          initValues['KoziScheduleSeason__c'] =
           changeStringDate(savedData.KoziScheduleSeason__c);
        }
      }
    }
  }

  return {
    initialValues: initValues,
    generalPurposeMap: state.common.generalPurposeMap,
    saveDesignDocument: state.isetuIrai.saveDesignDocument,
    designDocumentId: state.isetuIrai.designDocumentId,
    setGmnWarning: state.common.setGmnWarning,
    orderSubAllList: state.attachorder.orderSubAllList,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doDelDesignDocument: isetuIraiOperations.doDelDesignDocument,
  doGetDesignDocument: isetuIraiOperations.doGetDesignDocument,
  doSetDesignDocumentId: isetuIraiOperations.doSetDesignDocumentId,
  doUploadFilesToS3ForSf: commonOperations.doUploadFilesToS3ForSf,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
  doGetOrderSubAllList: attachorderOperations.doGetOrderSubAllList,
};

const FORM_NAME = 'IsetuIraiBasicInfInput';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
    syncWarnings: getFormSyncWarnings(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import 'bootstrap/dist/css/bootstrap.min.css';
import '@grapecity/wijmo.styles/wijmo.css';
import './assets/css/kms-base.css';

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {Provider as ReduxProvider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {IntlProvider} from 'react-intl';
import 'react-app-polyfill/ie11';
import ContainerRouter from './router';
import * as serviceWorker from './serviceWorker';
import configureStore, {history, persistor} from './reducks/store';
import axios from 'axios';
import {authOperations} from './reducks/auth';
import {commonOperations} from './reducks/common';
import GlobalIntlProvider from './core/globalIntl';
import Conf from './config/config.js';

import {PersistGate} from 'redux-persist/integration/react';

import * as wjcCore from '@grapecity/wijmo';
import '@grapecity/wijmo.cultures/wijmo.culture.ja';
import ErrorBoundary from './pages/ErrorBoundary';

wjcCore.setLicenseKey(Conf.WIJMO_LICENSE_KEY);

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);
const {dispatch} = reduxStore;

let MainComponent = ({locale, messages}) => (
  <IntlProvider locale={locale} messages={messages}>
    <GlobalIntlProvider>
      <ContainerRouter />
    </GlobalIntlProvider>
  </IntlProvider>
);

MainComponent = connect((state) => ({
  locale: state.style.locale,
  messages: state.style.messages,
}))(MainComponent);

// 言語切り替えが実装したい場合は、local / messages を
// redux の store で管理するようにし、動的に入れ替える。
// Material-ui のテーマについても同様、Provider に与える
// テーマを事前に複数用意しておき、ユーザ操作によりその値
// を変更することで、複数パターンの動的テーマ切り替えを可能とする。

ReactDOM.render(

    <ReduxProvider store={reduxStore}>
      <ErrorBoundary history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <MainComponent/>
          </ConnectedRouter>
        </PersistGate>
      </ErrorBoundary>
    </ReduxProvider>

    ,
    document.getElementById('root'),
);

// ServiceWorker を利用する場合にはこちらの利用を検討する。
// デフォルトでは unregister なので利用しない。
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Add a request interceptor
axios.interceptors.request.use(async function(config) {
  console.log('axios.interceptors.request start');
  dispatch(commonOperations.doCheckAccess());
  await dispatch(authOperations.doRefreshTokenOperation());

  if (reduxStore.getState().auth.loginInfo) {
    config.headers.Authorization = reduxStore.getState().auth.loginInfo.idToken;
  }
  // Do something before request is sent
  // styleAction.startRequest(); -> リクエストの開始を意味する。isRequesting = true にする。
  const method = config.method;
  console.log('----METHOD----', method);
  if (method === 'post') {
    dispatch(commonOperations.startRequest());
  }
  debugLogIN(config);
  console.log('axios.interceptors.request end');
  return config;
}, function(error) {
  // Do something with request error
  // styleAction.endRequest(); -> リクエストの終了を意味する。isRequesting = false にする。

  console.log('axios.interceptors END ERR1');
  dispatch(commonOperations.endRequest());
  errorDialog(error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function(response) {
  console.log('axios.interceptors.response start');
  try {
    // Do something with response data
    // styleAction.endRequest(); -> リクエストの終了を意味する。isRequesting = false にする。
    const method = response.config.method;
    console.log('----METHOD----', method);
    if (method === 'post') {
      dispatch(commonOperations.endRequest());
    }
    debugLogOUT(response);
    if (!response.data.hasOwnProperty('body')) {
      const error = new Error('Lambda unhandled Error');
      console.log('axios.interceptors.response error');
      console.error(error);
      return Promise.reject(error);
    } else {
      console.log('axios.interceptors.response end');
      return Promise.resolve(response);
    }
  } catch (e) {
    console.log('axios.interceptors.response error');
    console.error(e);
    return Promise.reject(e);
  }
}, function(error) {
  // Do something with response error
  // styleAction.endRequest(); -> リクエストの終了を意味する。isRequesting = false にする。
  console.log('axios.interceptors END ERR2');
  console.log('ERROR_INFO::' + error);
  dispatch(commonOperations.endRequest());
  errorDialog(error);

  return Promise.reject(error);
});

function errorDialog(error) {
  console.log('DEBUG');
  console.dir(error);

  let errDialog = {};
  if (error.response) {
    console.log('ERROR_INFO >> RESPONSE');
    switch (error.response.status) {
      case 403:
        errDialog = {
          message: 'CE0116',
        };
        break;
      case 429:
        errDialog = {
          message: 'CE0117',
        };
        break;
      default:
        errDialog = {
          message: 'CS0113',
        };
    }
  } else if (error.request) {
    console.log('ERROR_INFO >> REQUEST');
    errDialog = {
      message: 'CS0113',
    };
    if (error.message == 'Network Error') {
      errDialog = {
        message: 'CE0200',
      };
    }
  } else {
    console.log('ERROR_INFO >> ELSE');
    console.log('ERROR_MESSAGE::' + error.message);
    errDialog = {
      message: 'CS0113',
    };
  }
  dispatch(commonOperations.doShowMessage(errDialog));
}

function debugLogIN(config) {
  console.log('-----API DEBUG LOG IN-----');
  console.log('##  ' + config.url.split('/')[config.url.split('/').length - 1] + '  ##');
  console.log('data');
  console.dir(config.data);
}

function debugLogOUT(config) {
  console.log('-----API DEBUG LOG OUT-----');
  console.log('##  ' + config.request.responseURL.split('/')[config.request.responseURL.split('/').length - 1] + '  ##');
  if (config.data.hasOwnProperty('body')) {
    for (const bodyItem in config.data.body) {
      if (bodyItem != 'data') {
        console.log(bodyItem + ' => ' + config.data.body[bodyItem]);
      }
    }
    console.log('data');
    const logoutCriteriaLen = 10000;
    if (typeof(config.data.body.data) == 'string' &&
    config.data.body.data.length > logoutCriteriaLen) {
      console.dir(`長さが${logoutCriteriaLen}字を超えています`);
    } else {
      console.dir(config.data.body.data);
    }
  } else {
    for (const item in config.data) {
      if (item != 'trace') {
        console.log(item + ' => ' + config.data[item]);
      }
    }
    if (config.data.hasOwnProperty('trace')) {
      console.log('trace');
      console.dir(config.data.trace);
    }
  }
}

MainComponent.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import * as wjCore from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {DataMap} from '@grapecity/wijmo.grid';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, getComboName,
  getErrorCode, API_CODE, getResponseResult} from '../../../common/common.js';
import {checkFileNameLength, checkFileSizeZero,
  checkSameName} from '../../../common/fileOperations.js';
import {authOperations} from '../../../reducks/auth';
import {commonOperations} from '../../../reducks/common';
import {attachorderOperations} from '../../../reducks/attachorder';
import {contractDntOperations} from '../../../reducks/contractDnt';
import MainContainer from '../../organisms/MainContainer.js';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../atoms/Buttons/NegativeButton.js';
import Upload from '../../organisms/UploadCustom';
import Required from '../../atoms/RequiredMark.js';
import BackButton from '../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../molecules/CustomFlexGrid.js';
import * as validateRule from '../../validateRule.js';
import {iskkDntFields} from '../../../common/SFFields';
import WarningMessageArea from '../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

//  画面ID 可否判定申込（申込電柱：線設備）
const PROPRIETY_SEN_DNT_SCREEN_ID = '1004';
//  画面ID 可否判定申込（径間：線設備）
const PROPRIETY_SEN_STB_SCREEN_ID = '1003';

/** この画面で更新を行うフィールド名 */
const updateParams = {
  Id: null,
  IskkAiteZgsya1__c: null, // 会社.Id
  IskkAiteZgsya2__c: null, // 会社.Id
  IskkAiteZgsya3__c: null, // 会社.Id
  UpsyaUserId__c: null,
};

/**
 * 一束化明細（一束化相手先共架者）
 * 画面ID:1122
 */
class Container extends React.Component {
  /** 一束化相手先 共架事業者名 DataMap */
  iskkAiteZgsyaDataMap;

  constructor(props) {
    super(props);
    this.state = {
      contractDntList: null,
      isDisabled: true,
      isModified: false,
      iskkFiles: [],
      iskkDelFiles: [],
      warningMessages: [],
      isEdited: (this.props.transitionSrcId == PROPRIETY_SEN_DNT_SCREEN_ID ||
        this.props.transitionSrcId == PROPRIETY_SEN_STB_SCREEN_ID),
    };
    this.gridRef = React.createRef();
    this.onFormatItemHandler = this.onFormatItemHandler.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      if (!this.props.transitionSrcId) {
        // 可否判定申込以外は入力チェックしない
        return null;
      }
      // 一束化区分は2～4
      const iskkCategory = item['IskkCategory__c'];
      // 必須チェック
      // 共架事業者名1
      if (prop == 'IskkAiteZgsya1__c') {
        if (['2', '3', '4'].includes(iskkCategory)) {
          let error = validateRule.required4(item['IskkAiteZgsya1__c']);
          if (error) {
            return error;
          }
        }
      }
      // 共架事業者名2
      if (prop == 'IskkAiteZgsya2__c') {
        if (['3', '4'].includes(iskkCategory)) {
          let error = validateRule.required4(item['IskkAiteZgsya2__c']);
          if (error) {
            return error;
          }
        }
      }
      // 共架事業者名3
      if (prop == 'IskkAiteZgsya3__c') {
        if (['4'].includes(iskkCategory)) {
          let error = validateRule.required4(item['IskkAiteZgsya3__c']);
          if (error) {
            return error;
          }
        }
      }
      // 一束化相手先重複チェック
      if (prop == 'IskkAiteZgsya1__c' && item[prop]) {
        if (item[prop] == item['IskkAiteZgsya2__c'] ||
        item[prop] == item['IskkAiteZgsya3__c']) {
          return '一束化相手先が重複している';
        }
      }
      if (prop == 'IskkAiteZgsya2__c' && item[prop]) {
        if (item[prop] == item['IskkAiteZgsya1__c'] ||
        item[prop] == item['IskkAiteZgsya3__c']) {
          return '一束化相手先が重複している';
        }
      }
      if (prop == 'IskkAiteZgsya3__c' && item[prop]) {
        if (item[prop] == item['IskkAiteZgsya1__c'] ||
        item[prop] == item['IskkAiteZgsya2__c']) {
          return '一束化相手先が重複している';
        }
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearIskkDntList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1122', 'Id');
    }
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;

    const attachedFileTypeIskk = getCodeFromGeneralPurposeMap(
        generalPurposeMap, 'AttachedFileTypeA10', 3); // 一束化協議資料ファイル
    const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2); // 作成中

    if (!orderId) {
      return;
    }

    const orderConditions = {
      'Id': orderId,
      'RecordType.DeveloperName': 'ProprietyJudgeOrder',
      'attachedFileTypes': [
        attachedFileTypeIskk,
      ],
    };
    const response = await this.props.doGetIskkDntList(
        orderConditions, iskkDntFields);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['一束化相手先', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    this.gridRef.current.setScreenState('1122');
    const cv = createCollectionView(
        this.props.contractDntList, true, {}, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    let _iskkFiles = [];
    let isWarn = false;
    let _messages = [];
    let _warningMessages = [];
    // 添付ファイルを表示する
    if (this.props.contentDocuments &&
            this.props.contentDocuments.length > 0) {
      _iskkFiles = this.props.contentDocuments.filter(
          (record) => record.AttachedFileType__c == attachedFileTypeIskk);
    }

    // 入力チェック
    await this.inputCheck(_iskkFiles, _messages, _warningMessages);
    isWarn = (_messages.length > 0 || _warningMessages.length > 0) ?
          true : false;

    // 一束化相手先 共架事業者名のDataMap生成
    this.iskkAiteZgsyaDataMap =
      this._createIskkAiteZgsyaDataMap(this.props.contractDntList);

    this.setState({
      contractDntList: this.props.contractDntList, // 変更前のデータ
      iskkFiles: _iskkFiles,
      iskkDelFiles: [],
      isDisabled: (this.props.order &&
                  this.props.order.OrderStatus__c ==
                   orderStatusCreate ? false : true) ||
                  this.props.referenceMode || !this.state.isEdited, // 可否判定申込作成中のみ編集可
      isModified: false,
      warningMessages: _warningMessages,
    });

    await this.props.doSetGmnWarning('1122', isWarn);

    return true;
  }

  /**
   * 一束化相手先 共架事業者名のDataMap生成
   * @param {object[]} contractDntList 取得情報
   * @return {DataMap} 一束化相手先 共架事業者名のDataMap
   */
  _createIskkAiteZgsyaDataMap(contractDntList) {
    // 先頭項目に空の情報を設定
    const iskkAiteZgsyaList = [{Name: '', KyogaZgsya__c: ''}];

    // 全ての契約電柱に紐づく共架事業者を設定する
    for (const contractDnt of contractDntList) {
      for (const iskkAiteZgsya of contractDnt.IskkAiteZgsyaList) {
        iskkAiteZgsyaList.push(iskkAiteZgsya);
      }
    }

    const dataMap = new DataMap(iskkAiteZgsyaList, 'KyogaZgsya__c', 'Name');

    // 行に紐づいた共架事業者名のみ表示するようにする
    dataMap.getDisplayValues = (dataItem) => {
      // 先頭項目に空の情報を設定
      const displayValues = [''];

      // 現在行の共架事業者のみを設定する
      for (const iskkAiteZgsya of dataItem.IskkAiteZgsyaList) {
        displayValues.push(iskkAiteZgsya.Name);
      }

      return displayValues;
    };

    return dataMap;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
          data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'Id') ?
          data.order.Id : null;
      lastModifiedDate =
              data && Object.hasOwnProperty.call(data, 'order') &&
              Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
              data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} iskkFiles
   * @param {array} messages
   * @param {array} warningMessages
   */
  inputCheck = async (iskkFiles, messages, warningMessages) => {
    const {generalPurposeMap} = this.props;

    const contractDntList =
      this.gridRef.current.getSourceCollection();
    let index = 0;
    for (const contractDnt of contractDntList) {
      index++;
      const iskkCategoryCount = contractDnt.IskkCategory__c ?
        Number(contractDnt.IskkCategory__c) : 0;
      // 一束化相手先必須チェック
      if (iskkCategoryCount >= 2) {
        if (!contractDnt.IskkAiteZgsya1__c) {
          messages.push({
            id: 'CE0017',
            values: ['共架事業者名1(' + index + '行目)'],
          });
        }
      }
      if (iskkCategoryCount >= 3) {
        if (!contractDnt.IskkAiteZgsya2__c) {
          messages.push({
            id: 'CE0017',
            values: ['共架事業者名2(' + index + '行目)'],
          });
        }
      }
      if (iskkCategoryCount == 4) {
        if (!contractDnt.IskkAiteZgsya3__c) {
          messages.push({
            id: 'CE0017',
            values: ['共架事業者名3(' + index + '行目)'],
          });
        }
      }

      // 一束化相手先重複チェック
      if ((contractDnt.IskkAiteZgsya1__c && contractDnt.IskkAiteZgsya2__c &&
        contractDnt.IskkAiteZgsya1__c == contractDnt.IskkAiteZgsya2__c) ||
        (contractDnt.IskkAiteZgsya1__c && contractDnt.IskkAiteZgsya3__c &&
          contractDnt.IskkAiteZgsya1__c == contractDnt.IskkAiteZgsya3__c) ||
        (contractDnt.IskkAiteZgsya2__c && contractDnt.IskkAiteZgsya3__c &&
          contractDnt.IskkAiteZgsya2__c == contractDnt.IskkAiteZgsya3__c)) {
        messages.push({
          id: 'PE0173',
        });
      }
      // 一束化相手先数チェック
      const IskkAiteZgsya1Count =
        contractDnt.IskkAiteZgsyaList.filter((data) =>
          data.KyogaZgsya__c == contractDnt.IskkAiteZgsya1__c).length;
      const IskkAiteZgsya2Count =
        contractDnt.IskkAiteZgsyaList.filter((data) =>
          data.KyogaZgsya__c == contractDnt.IskkAiteZgsya2__c).length;
      const IskkAiteZgsya3Count =
        contractDnt.IskkAiteZgsyaList.filter((data) =>
          data.KyogaZgsya__c == contractDnt.IskkAiteZgsya3__c).length;
      const iskkCount = (contractDnt.IskkAiteZgsya1__c &&
                          IskkAiteZgsya1Count > 0 ? 1 : 0) +
                        (contractDnt.IskkAiteZgsya2__c &&
                          IskkAiteZgsya2Count > 0 ? 1 : 0) +
                        (contractDnt.IskkAiteZgsya3__c &&
                          IskkAiteZgsya3Count > 0 ? 1 : 0) + 1; // 自社分 +1
      if (iskkCount != iskkCategoryCount) {
        const iskkCategoryName =
          getComboName(generalPurposeMap, 'IskkCategory', contractDnt.IskkCategory__c);
        warningMessages.push({
          id: 'PE0160',
          values: [iskkCategoryName, index],
        });
      }
    }
    // 一束化協議資料必須チェック
    if (iskkFiles.length == 0) {
      warningMessages.push({
        id: 'CE0018',
        values: ['一束化協議資料'],
      });
    }
  }

  /**
   * 一束化資料設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setIskkFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A10';
        _files.push(file);
      }
      this.setState({
        iskkFiles: files,
        iskkDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * クリアボタン押下時
   */
  doClearList = () => {
    try {
      const count = this.gridRef.current.selectedItemCount();
      if (count == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['クリア'],
          },
        });
        return;
      }
      // 選択した行を初期値に戻す
      const selectedDntList = this.gridRef.current.selectedItem();
      const grid = this.gridRef.current.getGrid();
      for (const selectedDnt of selectedDntList) {
        const orgDnt =
            this.props.contractDntList.filter(
                (data) => data.Id == selectedDnt.dataItem.Id);
        if (orgDnt.length == 0) {
          continue;
        }
        (grid.collectionView).editItem(selectedDnt.dataItem);
        selectedDnt.dataItem.IskkAiteZgsya1__c = orgDnt[0].IskkAiteZgsya1__c;
        selectedDnt.dataItem.IskkAiteZgsya2__c = orgDnt[0].IskkAiteZgsya2__c;
        selectedDnt.dataItem.IskkAiteZgsya3__c = orgDnt[0].IskkAiteZgsya3__c;
        (grid.collectionView).commitEdit();
      }
      this.gridRef.current.reItemChecked(selectedDntList);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一束化相手先と添付ファイルの登録/更新処理
   */
  insertOrUpdate = async () => {
    const {order, orderModifiedCondition} = this.props;

    // 契約電柱更新項目設定
    const contractDntList = this.gridRef.current.getSourceCollection();
    const saveContractDntList = [];
    const updateConditions = [];
    for (const dnt of contractDntList) {
      const updateData = {};
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(dnt, key)) {
          updateData[key] = dnt[key];
        }
      }
      // 排他チェック用
      updateConditions.push({
        Id: dnt.Id,
        LastModifiedDate: dnt.LastModifiedDate,
      });
      saveContractDntList.push(updateData);
    }
    // 一時保存日 排他チェック用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate:
        orderModifiedCondition != null &&
        order.Id == orderModifiedCondition.Id ?
        orderModifiedCondition.LastModifiedDate : null,
    };
    const updateOrder = {
      Id: order.Id,
    };
    const resSave =
      await this.props.doSaveIskkContractDnts(
          updateConditions, saveContractDntList,
          orderConditions, updateOrder,
          this.state.iskkFiles, this.state.iskkDelFiles);
    const resResult = getResponseResult(resSave, ['一束化相手先', '登録']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    return {success: true, messages: []};
  }

  /**
   * 登録ボタン押下時
   */
  doRegister = async () => {
    try {
      if (!this.gridRef.current) {
        return;
      }

      // 入力チェック
      // エラーの場合は登録しない
      let _messages = [];
      let _warningMessages = [];
      await this.inputCheck(this.state.iskkFiles, _messages, _warningMessages);
      let isWarn = (_messages.length > 0 || _warningMessages.length > 0) ?
        true : false;
      if (isWarn) {
        this.props.doShowMessage({message: {id: 'CE0174', values: ['登録']}});
        return;
      }

      // 一覧の状態を保持する
      this.gridRef.current.saveScreenState('1122', 'Id');

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.doSetTransitionSrcId(null);
              this.props.history.push(
                  {pathname: '/ProprietyList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['登録'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 次画面へ遷移するボタン押下
   */
  doMoveNext = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
          this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }
      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 径間入力画面へ遷移する
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/ProprietySenStb'});
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1122')) {
        _hasError = this.props.setGmnWarning['1122'];
      }
    }
    // 入力チェックエラー
    if (_hasError) {
      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    if ((s.rowHeaders === e.panel &&
        e.panel.cellType == wjgGrid.CellType.RowHeader) ||
        (s.topLeftCells === e.panel &&
        e.panel.cellType == wjgGrid.CellType.TopLeft)) {
      if (this.state.isDisabled) {
        if (e.cell != null && e.cell.children != null &&
          e.cell.children.length > 0) {
          wjCore.addClass(e.cell.children[0], 'wj-state-disabled');
        }
      } else {
        if (e.cell != null && e.cell.children != null &&
            e.cell.children.length > 0) {
          wjCore.removeClass(e.cell.children[0], 'wj-state-disabled');
        }
      }
    }
    if (s.cells === e.panel &&
      e.panel.cellType == wjgGrid.CellType.Cell) {
      const col = e.getColumn();
      // 共架事業者名2
      if (col.binding === 'IskkAiteZgsya2__c' &&
           s.rows[e.row]) {
        const data = s.rows[e.row].dataItem;
        // 3者一束化または4者一束化の場合、共架事業者名2は編集可
        if (data.IskkCategory__c == '3' ||
            data.IskkCategory__c == '4') {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'gray');
        } else {
          wijmo.addClass(e.cell, 'wj-state-disabled');
          wijmo.addClass(e.cell, 'gray');
        }
      }
      // 共架事業者名3
      if (col.binding === 'IskkAiteZgsya3__c' &&
           s.rows[e.row]) {
        const data = s.rows[e.row].dataItem;
        // 4者一束化の場合、共架事業者名3は編集可
        if (data.IskkCategory__c == '4') {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'gray');
        } else {
          wijmo.addClass(e.cell, 'wj-state-disabled');
          wijmo.addClass(e.cell, 'gray');
        }
      }
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wjCore.Control.getControl(lbHost);
      let categoryName = 'DntCategory'; // 電柱区分
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧表示
   * @return {*}
   */
  renderTable() {
    const {generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      exceptFilters: ['IskkAiteZgsya1__c', 'IskkAiteZgsya2__c', 'IskkAiteZgsya3__c'],
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      AddDeleteOn: false,
      style: {height: '400px'},
      isReadOnly: this.state.isDisabled,
      useStore: false, // dataはないけどストアを使用しない
      frozenColumns: 3,
      validateEdits: false,
      formatItemFunction: this.onFormatItemHandler,
      filterChanging: this.filterChangingHandler,
      customDataMapBinds: ['IskkAiteZgsya1__c', 'IskkAiteZgsya2__c', 'IskkAiteZgsya3__c'],
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup header="電柱" align="center">
          <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" isReadOnly={true}>
            <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
              return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
            }}/>
          </FlexGridColumnGroup>
        </FlexGridColumnGroup>
        {this.state.isEdited &&
          <FlexGridColumnGroup header="一束化相手先" align="center">
            <FlexGridColumnGroup binding="IskkAiteZgsya1__c" header="共架事業者名1" dataMap={this.iskkAiteZgsyaDataMap} width={300}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup name="IskkAiteZgsya2__c" binding="IskkAiteZgsya2__c" header="共架事業者名2" dataMap={this.iskkAiteZgsyaDataMap} width={300}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup name="IskkAiteZgsya3__c" binding="IskkAiteZgsya3__c" header="共架事業者名3" dataMap={this.iskkAiteZgsyaDataMap} width={300}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
        }
        {!this.state.isEdited &&
          <FlexGridColumnGroup header="一束化相手先" align="center">
            <FlexGridColumnGroup binding="IskkAiteZgsya1__c" header="共架事業者名1" width={300}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false}/>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return ctx.item.IskkAiteZgsya1__c ?
                  ctx.item.IskkAiteZgsya1__r.KyogaZgsyaCode__c + ' ' +
                  ctx.item.IskkAiteZgsya1__r.Name : '';
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup name="IskkAiteZgsya2__c" binding="IskkAiteZgsya2__c" header="共架事業者名2" width={300}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false}/>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return ctx.item.IskkAiteZgsya2__c ?
                  ctx.item.IskkAiteZgsya2__r.KyogaZgsyaCode__c + ' ' +
                  ctx.item.IskkAiteZgsya2__r.Name : '';
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup name="IskkAiteZgsya3__c" binding="IskkAiteZgsya3__c" header="共架事業者名3" width={300}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false}/>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return ctx.item.IskkAiteZgsya3__c ?
                  ctx.item.IskkAiteZgsya3__r.KyogaZgsyaCode__c + ' ' +
                  ctx.item.IskkAiteZgsya3__r.Name : '';
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
        }
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1122Register" item>
          <PositiveButton
            id="btn1122Register"
            onClick={handleSubmit(this.doRegister)}
            variant="contained"
            size="large"
            startIcon={<SaveIcon />}
            disabled={this.state.isDisabled}>
            <span>登録</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1122Next" item>
          <PositiveButton
            id="btn1122Next"
            onClick={handleSubmit(this.doMoveNext)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode}
          >
            <span>次へ</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1122Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Typography variant="h6" gutterBottom>
              一束化明細一覧（一束化相手先共架者）
          </Typography>
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}>
            {this.state.isEdited &&
              <Grid key="key1122Clear" item>
                <NegativeButton
                  id="id1122Clear"
                  onClick={handleSubmit(this.doClearList)}
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  size="large"
                  disabled={this.state.isDisabled}>
                  <span>クリア</span>
                </NegativeButton>
              </Grid>
            }
            <Grid key="key1122Table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid key="key1122IskkDoc" item xs={6}>
              <Grid container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid key="key1122IskkFile" item xs={12}>
                  <Typography variant="h6" gutterBottom>
                     一束化協議資料
                    <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                    <Required/>
                  </Typography>
                </Grid>
                <Grid key="key1122IskkUpload" item>
                  <Upload
                    componentId="idIskkComponent"
                    maxFileCount={3}
                    maxFileSize={3145728}
                    required={true}
                    previewFlag={false}
                    previewWidth="35%"
                    acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                    fileSetHandler={this.setIskkFiles}
                    initFile={this.state.iskkFiles}
                    disabled={this.state.isDisabled}
                    customChecks={[
                      checkFileNameLength,
                      checkFileSizeZero,
                      checkSameName,
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br/>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  isDisabled: PropTypes.bool,
  transitionSrcId: PropTypes.string,
  orderId: PropTypes.string,
  order: PropTypes.object,
  contractDntList: PropTypes.array,
  contentDocuments: PropTypes.array,
  contractDntResult: PropTypes.object,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetAllGeneralPurposeMap: PropTypes.func,
  doGetIskkDntList: PropTypes.func,
  doClearIskkDntList: PropTypes.func,
  doSaveIskkContractDnts: PropTypes.func,
  referenceMode: PropTypes.bool,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  doSetTransitionSrcId: PropTypes.func,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  // 本申込情報または撤去申込
  const order = state.contractDnt.iskkContractDntList &&
          Object.hasOwnProperty.call(
              state.contractDnt.iskkContractDntList, 'order') ?
              state.contractDnt.iskkContractDntList.order : null;
  // 一束化を設定する申込電柱
  const contractDntList = state.contractDnt.iskkContractDntList &&
          Object.hasOwnProperty.call(
              state.contractDnt.iskkContractDntList, 'contractDntList') ?
              state.contractDnt.iskkContractDntList.contractDntList :
              null;
  // 一束化協議資料
  const contentDocuments = state.contractDnt.iskkContractDntList &&
          Object.hasOwnProperty.call(
              state.contractDnt.iskkContractDntList, 'contentDocuments') ?
              state.contractDnt.iskkContractDntList.contentDocuments :
              [];
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    transitionSrcId: state.common.transitionSrcId,
    orderId: state.attachorder.orderId,
    order: order,
    contractDntResult: state.contractDnt.contractDntResult,
    contractDntList: contractDntList,
    contentDocuments: contentDocuments,
    referenceMode: state.common.referenceMode == true ? true : false,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    setGmnWarning: state.common.setGmnWarning,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetIskkDntList: contractDntOperations.doGetIskkDntList,
  doClearIskkDntList: contractDntOperations.doClearIskkDntList,
  doSaveIskkContractDnts: contractDntOperations.doSaveIskkContractDnts,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetTransitionSrcId: commonOperations.doSetTransitionSrcId,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'IskkDetail';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

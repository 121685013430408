import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, getFormSyncWarnings} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import Required from '../../../../../atoms/RequiredMark.js';
import Upload from '../../../../../organisms/UploadCustom';

import {getCodeFromGeneralPurposeMap,
  getErrorCode, API_CODE, changeDateFormatter,
  dntNoFormat, senroNameFormat, getResponseResult} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import * as validateRule from '../../../../../validateRule.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import {dntOperations} from '../../../../../../reducks/dnt/index.js';
import {orderBasicFields, mainTekyoContractDntInfo} from '../../../../../../common/SFFields';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';

import {checkFileNameLength, checkFileSizeZero,
  checkSameName} from '../../../../../../common/fileOperations.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
  },
});

/**
 * 登録、更新の共通項目
 */
const commonParams = {
  KoziTitle__c: null, // 工事件名
  Dsp_KoziPlaceMainDntNo__c: null, // 表示用 代表電柱番号
  KoziPlaceMainDntNo_SenroCode__c: null, // 代表電柱_線路コード
  KoziPlaceMainDntNo_SenroName__c: null, // 代表電柱_線路名
  KoziPlaceMainDntNo__c: null, // 代表電柱番号
  KoziPlacePrefectures__c: null, // 都道府県
  KoziPlaceMunicipalities__c: null, // 市区町村
  KoziPlacePlace__c: null, // 場所
  StbType__c: null, // 設備種別
  KyogaType__c: null, // 共架種別
  MemoContents__c: null, // メモ内容
  OrderCategory__c: null, // 申込区分
  MainDntNoNWZgsyo__c: null, // 代表電柱番号の中電NW事業所(汎用マスタのId)
  KyogaZgsya__c: null, // 共架事業者(AccountのId)
  UpsyaUserId__c: null,
  TemporarilySavedDate__c: null, // 一時保存日
  NWZgsyo__c: null, // 中電NW事業所(汎用マスタのId) 代表電柱番号の中電NW事業所と同じ値を設定する
};

// 登録時の項目
const addParams = {};

// 更新時の項目
const updateParams = {};

// 登録、更新項目を作成
Object.assign(addParams, commonParams);
Object.assign(updateParams, commonParams);

/**
 * 契約相違撤去基本情報入力
 * 画面ID:1612
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      horizonFiles: [],
      rootDelFiles: [],
      specDelFiles: [],
      otherDelFiles: [],
      pictureDelFiles: [],
      // 活性/非活性
      isDisabled: true,
      // 編集あり/なし
      isModified: false,
      modalOpen: false,
      // 共架種別
      kyogaType: null,
      warningMessages: null,
    };
    // 代表電柱情報
    this.MainDnt = {};
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {orderId, generalPurposeMap} = this.props;
      console.log('orderId:' + orderId);
      this.MainDnt = {};

      if (orderId) {
        // 申込情報+ファイルIDを取得
        const errorCode = await this.getOrderInfo(orderId);
        if (errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['訂正撤去申込', '取得'],
            },
          });
          return;
        }

        // 添付ファイル取得
        const results = await this.getAttachedFiles();
        if (!results.result) {
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['添付ファイル', '取得'],
            },
          });
          return;
        }

        const order = this.props.order;

        // 申込ステータス取得：作成中
        const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
        this.setState({
          rootFiles: results.rootFiles,
          specFiles: results.specFiles,
          otherFiles: results.otherFiles,
          pictureFiles: results.pictureFiles,
          rootDelFiles: [],
          horizonDelFiles: [],
          specDelFiles: [],
          otherDelFiles: [],
          pictureDelFiles: [],
          // 作成中のみ編集可
          isDisabled: order && order.OrderStatus__c ==
              orderStatusCreate ? false : true,
          isModified: false,
        });
      } else {
        this.setState({
          isDisabled: false,
          isModified: false,
        });
      }

      // 現在の設備種別を設定
      const stbTypeSen = getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType1', 1);
      this.isSen = this.props.stbType == stbTypeSen ? true : false;

      // 共架種別
      this.setState({
        kyogaType: this.props.order.KyogaType__c,
      });

      const errorMessages = [];
      const warningMessages = [];
      if (orderId) {
        // 作成中の場合は入力チェックを行う
        this.inputCheck(this.props.contentDocuments,
            errorMessages, warningMessages);
      }
      let isWarn = false;
      // 入力チェックエラー
      if (errorMessages.length > 0 || warningMessages.length > 0) {
        // 入力チェックNG
        isWarn = true;
      }
      await this.props.doSetGmnWarning('1612', isWarn);
      this.setState({isModified: false, warningMessages: warningMessages});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   * @param {array} attachedFile
   * @param {array} messages {id: message id, values:[]}
   * @param {array} warningMessages {id: message id, values:[]}
   */
  inputCheck(attachedFile, messages, warningMessages) {
    const {order, generalPurposeMap} = this.props;

    if (order == null) {
      return;
    }

    const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    if (order &&
      order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    if (!order.KoziTitle__c) {
      messages.push({
        id: 'CE0017',
        values: ['工事件名'],
      });
    } else {
      if (order.KoziTitle__c.length > 50) {
        messages.push({
          id: 'CE0041',
          values: ['工事件名'],
        });
      }
    }
    if (!order.KoziPlaceMainDntNo__c) {
      messages.push({
        id: 'CE0017',
        values: ['代表電柱番号'],
      });
    }
    if (!order.KoziPlacePrefectures__c) {
      messages.push({
        id: 'CE0017',
        values: ['都道府県'],
      });
    }
    if (!order.KoziPlaceMunicipalities__c) {
      messages.push({
        id: 'CE0017',
        values: ['市区町村'],
      });
    } else {
      if (order.KoziPlaceMunicipalities__c.length > 12) {
        messages.push({
          id: 'CE0041',
          values: ['市区町村'],
        });
      }
    }
    if (!order.KoziPlacePlace__c) {
      messages.push({
        id: 'CE0017',
        values: ['場所'],
      });
    } else {
      if (order.KoziPlacePlace__c.length > 255) {
        messages.push({
          id: 'CE0041',
          values: ['場所'],
        });
      }
    }
    if (!order.KyogaType__c) {
      messages.push({
        id: 'CE0017',
        values: ['共架種別'],
      });
    }
    if (order.MemoContents__c &&
      order.MemoContents__c.length > 255) {
      messages.push({
        id: 'CE0041',
        values: ['メモ内容'],
      });
    }
    // 添付ファイルの必須チェックは行う。
    const fileNames = [];
    let contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A22');
    // 申込写真は必須
    if (contents.length == 0) {
      fileNames.push('装柱図');
    }
    // 線設備の場合、ルート図、共架設備仕様書が必須
    if (order.StbType__c == '1') {
      contents = attachedFile.filter((data)=>data.AttachedFileType__c == 'A08');
      if (contents.length == 0) {
        fileNames.push('ルート図');
      }
    }
    for (const value of fileNames) {
      warningMessages.push(
          {id: 'CE0018',
            values: [value]},
      );
    }
  }

  /**
   * 申込電柱入力ボタン押下
   */
  doInputDnt = async () => {
    try {
      const {order, values} = this.props;
      // 申込情報未登録はエラー
      if (order == null || order.Id == null) {
        this.props.doShowMessage({
          message: {
            id: 'CE0169',
            values: ['訂正撤去申込', '申込電柱'],
          },
        });
        return;
      }

      // 共架種別未設定
      if (!values['KyogaType__c']) {
        this.props.doShowMessage({
          message: {
            id: 'CE0133',
            values: ['共架種別'],
          },
        });
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (this.props.dirty || this.state.isModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    // 警告チェック
    let hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1612')) {
        hasError = (this.props.setGmnWarning[1612] != undefined ?
              this.props.setGmnWarning[1612] : false);
      }
    }

    // 入力チェックエラー
    if (hasError) {
      // 警告表示
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNextGmn();
        },
        type: 'warn',
      });
      return;
    }

    // 入力チェックOK
    this.doNextGmn();
  }

  doNextGmn = () => {
    // 設備種別で遷移先を切り替える
    if (this.isSen) {
      this.props.history.push({
        pathname: '/CorrectTekyoSenOrderDnt',
        equip: this.props.location.equip,
      });
    } else {
      this.props.history.push({
        pathname: '/CorrectTekyoTenOrderDnt',
        equip: this.props.location.equip,
      });
    }
  }

  /**
   *  保存ボタン押下
   */
  doSave = async () => {
    const {order} = this.props;
    console.log('保存ボタン押下');
    try {
      // 申込+電柱+線設備を取得
      const errorReGet = await this.getOrderInfoList();
      if (errorReGet != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['訂正撤去申込', '取得'],
          },
        });
        return;
      }

      console.log(this.props.orderList);

      // 契約電柱が設定されているか
      if (this.props.orderList && this.props.orderList.length != 0) {
        const hasContractDnt = this.props.orderList[0].ContractDnts__r &&
          this.props.orderList[0].ContractDnts__r.totalSize > 0 ? true : false;
        // 契約電柱が設定されていれば共架種別チェックする
        if (hasContractDnt) {
          // 共架種別のチェック
          const isSame = this.isSameKyogaType();
          if (!isSame) {
            this.props.doShowMessage({
              message: {
                id: 'DC0122',
              },
              action: async () => {
                // 写真を削除するかチェック
                let isDeletePhoto = false;
                const dntList = this.props.orderList[0].ContractDnts__r.records;
                for (const dnt of dntList) {
                  const result = await this.props.doExistsPhoto(dnt.Id);
                  const errorCode = getErrorCode(result);
                  if (errorCode == API_CODE.SUCCESS) {
                    const photoKind = result.data.body.data;
                    // どれか1つでもtrue(写真あり)の場合は写真削除とする
                    for (let key in photoKind) {
                      if (photoKind[key]) {
                        isDeletePhoto = true;
                        break;
                      }
                    }
                    if (isDeletePhoto) {
                      break;
                    }
                  }
                }

                // 保存実行
                await this.execSave();

                if (isDeletePhoto) {
                  // 電柱の写真削除
                  const result = await this.props.doDeletePhoto(order.Id);
                  // 保存結果判定
                  const errorCode = getErrorCode(result);
                  console.log('写真削除結果:' + errorCode);
                  if (errorCode == API_CODE.ERROR_DELETE) {
                    // ログ出力
                    console.log('写真削除失敗 orderId:' + order.Id);
                  }
                }
              },
            });

            // 共架種別
            this.setState({
              kyogaType: this.props.order.KyogaType__c,
            });
            return;
          }
        }
      }

      // 保存実行
      await this.execSave();

      // 共架種別
      this.setState({
        kyogaType: this.props.order.KyogaType__c,
      });
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 保存処理
   */
  execSave = async () => {
    // 必須入力チェック結果
    // const {syncWarnings} = this.props;
    // 新規/更新処理
    const ret = await this.upsert();
    if (!ret.success) {
      this.props.doShowMessage({
        message: ret.messages,
        action: async () => {
          if (ret.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
            this.gotoListGmn();
          }
        },
      });
      return;
    }

    /* if ((syncWarnings && Object.keys(syncWarnings).length > 0) ||
        !this.requiredFilesExists()) {
      // 必須入力エラーあり
      await this.props.doSetGmnWarning('1612', true);
    } else {
      await this.props.doSetGmnWarning('1612', false);
    }*/

    // 編集なしにする
    this.setState({isModified: false});
    console.log('編集状態:' + this.state.isModified);
  }

  /**
   * 戻るボタン押下
   */
  doBack = async () => {
    console.log('戻るボタン');
    console.log('編集状態:' + this.state.isModified);
  }

  /**
 * 終了処理
 */
  async componentWillUnmount() {
    this.props.doClearOrderInfo();
    this.props.doClearOrderList();
    this.props.doClearAttachedFiles();
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/CorrectTekyoOrderList'}, null, this.props.history.option.CLEAR,
    );
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 選択した電柱情報を取得
   * @param {object} dnt
   */
  setSelectedDnt = async (dnt) => {
    try {
      const {change, generalPurposeMap} = this.props;
      const selectedDnt = dnt && dnt.length > 0 ? dnt[0] : null;
      if (selectedDnt) {
        // フォームに電柱情報設定
        // let prefecturesCode = null;
        // if (generalPurposeMap.PrefCode) {
        //   const masterData =
        //     generalPurposeMap.PrefCode.filter(
        //         (data) => data.Name ==
        //         selectedDnt.StbItiPrefectures);
        //   prefecturesCode =
        //     masterData.length > 0 ? masterData[0].Code__c : null;
        // }
        // 汎用マスタから代表電柱の中電事業所のIdを取得
        let nWZgsyo = null;
        if (generalPurposeMap.NWZgsyo) {
          const masterData = generalPurposeMap.NWZgsyo.filter(
              (data) => data.Code__c ==
              selectedDnt.NWZgsyo__r_Code__c);
          nWZgsyo = masterData.length > 0 ? masterData[0].Id : null;
        }
        // 表示用_工事場所_代表電柱番
        change('Dsp_KoziPlaceMainDntNo__c',
            dntNoFormat(
                selectedDnt['SenroCode__c'], selectedDnt['DntNoManualInput__c'],
                selectedDnt['K6KansnNo__c'], selectedDnt['K6Bunk1__c'],
                selectedDnt['K6Bunk2__c'], selectedDnt['K6Bunk3__c'],
                selectedDnt['K22SzbtNo__c'], selectedDnt['K22GatiCategory__c']));
        // 代表電柱_線路コード
        change('KoziPlaceMainDntNo_SenroCode__c', selectedDnt.SenroCode__c);
        // 代表電柱_線路名
        change('KoziPlaceMainDntNo_SenroName__c',
            senroNameFormat(selectedDnt['SenroName__c'], selectedDnt['SenroNameKana__c'], selectedDnt['SenroCode__c']));
        // 工事場所_代表電柱番号
        change('KoziPlaceMainDntNo__c', selectedDnt['DntNo__c'] ?
            selectedDnt['DntNo__c'] : selectedDnt['DntNoManualInput__c']);
        // // 工事場所_都道府県
        // change('KoziPlacePrefectures__c', prefecturesCode);
        // // 工事場所_市区町村
        // change('KoziPlaceMunicipalities__c', selectedDnt.StbItiMunicipalities);
        // 代表電柱番号_中電NW事業
        change('MainDntNoNWZgsyo__c', nWZgsyo);
        // 代表電柱登録データを作成
        this.setMainDntInfo(selectedDnt, nWZgsyo);
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 契約電柱に登録する代表電柱情報作成
   * （共架種別は保存時に設定する）
   * @param {object} dntInfo 電柱選択の戻り値
   * @param {object} nwZgsyo 中電NW事業所（汎用マスタのId）
  */
  setMainDntInfo = (dntInfo, nwZgsyo) => {
    const {userInfo, order, stbType, generalPurposeMap} = this.props;
    this.MainDnt = {};
    Object.assign(this.MainDnt, mainTekyoContractDntInfo);
    for (const key in mainTekyoContractDntInfo) {
      if (Object.hasOwnProperty.call(dntInfo, key)) {
        this.MainDnt[key] = dntInfo[key];
      }
    }
    this.MainDnt['ContractDnt__c'] = dntInfo['RecordId']; // 親契約電柱
    this.MainDnt['SerialNumber__c'] = 1;
    this.MainDnt['NWZgsyo__c'] = nwZgsyo;
    this.MainDnt['TekyoCategory__c'] =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'TekyoType1', 1);
    if (order &&
        Object.hasOwnProperty.call(order, 'Id') &&
        order.Id) {
      this.MainDnt['StbType__c'] = order.StbType__c;
      this.MainDnt['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
    } else {
      this.MainDnt['StbType__c'] = stbType;
      this.MainDnt['KyogaZgsya__c'] = userInfo.AccountId;
    }
  }

  /**
   * 共架種別選択
   * 撤去は共架種別ごとに契約しているため、
   * 共架種別を変更したら代表電柱情報をクリアする
   * @param {*} e
   */
  changeKyogaType = (e) => {
    const {change} = this.props;
    change('KoziPlaceMainDntNo_SenroCode__c', null);
    change('KoziPlaceMainDntNo_SenroName__c', null);
    change('Dsp_KoziPlaceMainDntNo__c', null);
    change('KoziPlaceMainDntNo__c', null);
    change('MainDntNoNWZgsyo__c', null);
    this.MainDnt = {};
  }

  /**
   * ルート図設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setRootFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A08';
        _files.push(file);
      }
      this.setState({
        rootFiles: _files,
        rootDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 仕様書設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setSpecFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A02';
        _files.push(file);
      }
      this.setState({
        specFiles: _files,
        specDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * その他資料設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setOtherFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A12';
        _files.push(file);
      }
      this.setState({
        otherFiles: _files,
        otherDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込写真設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setPictureFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A22';
        _files.push(file);
      }
      this.setState({
        pictureFiles: _files,
        pictureDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * ファイルの必須チェック
   * ファイルが有ればtrue, なければfalse
   * @return {*}
   */
  requiredFilesExists() {
    let result = true;
    if (this.isSen) { // 線設備
      if (this.state.rootFiles.filter(
          (data) => data.ContentDocumentId).length == 0) {
        result = false;
      }
    } else { // 点設備
      if (this.state.specFiles.filter(
          (data) => data.ContentDocumentId).length == 0) {
        result = false;
      }
    }
    return result;
  }

  /**
   * 保存処理
   */
  upsert = async () => {
    console.log('保存処理');

    const {userInfo, values, order, generalPurposeMap} = this.props;

    // SF削除対象ファイル抽出
    let _rootDelFiles = this.state.rootDelFiles != null ?
      this.state.rootDelFiles : [];
    const deleteFiles = _rootDelFiles
        .concat(this.state.specDelFiles)
        .concat(this.state.otherDelFiles)
        .concat(this.state.pictureDelFiles);
    console.log('SF削除対象ファイル:' + deleteFiles);

    const isSetMainDnt = (this.MainDnt &&
      Object.hasOwnProperty.call(this.MainDnt, 'SerialNumber__c') &&
      this.MainDnt['SerialNumber__c'] == 1);
    // 代表電柱情報を設定
    if (isSetMainDnt) {
      this.MainDnt['KyogaType__c'] =
      Object.hasOwnProperty.call(values, 'KyogaType__c') ? values.KyogaType__c : null;
    }

    // 処理結果
    let resultSave = null;
    // 申込情報がある場合＝更新
    if (order &&
        Object.hasOwnProperty.call(order, 'Id') &&
        order.Id) {
      // 更新条件
      const conditions = {
        Id: order.Id,
        LastModifiedDate: this.props.orderLastModifiedDate,
      };
      // 更新内容
      const updateData = {Id: order.Id};
      // 更新項目に更新する値を設定していく
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(values, key) ) {
          updateData[key] = values[key];
        }
      }
      // 中電NW事業所
      updateData['NWZgsyo__c'] = updateData['MainDntNoNWZgsyo__c'];

      // 申込テーブル更新
      resultSave = await this.props.doSaveCorrectOrder(
          conditions, updateData,
          this.state.rootFiles, this.state.horizonFiles,
          this.state.specFiles, this.state.otherFiles,
          this.state.pictureFiles, deleteFiles,
          (isSetMainDnt ? this.MainDnt : {}));

    // 申込情報がない場合＝新規
    } else {
      const insertData = {};
      for (const key in addParams) {
        if (Object.hasOwnProperty.call(values, key)) {
          insertData[key] = values[key];
        }
      }
      // 中電NW事業所
      insertData['NWZgsyo__c'] = insertData['MainDntNoNWZgsyo__c'];
      // 申込共架事業者
      insertData['OrderKyogaZgsya__c'] = userInfo.AccountId;
      // 共架担当者
      insertData['KyogaTanto__c'] = userInfo.Id;
      // 申込区分(撤去)
      insertData['OrderCategory__c'] =
          generalPurposeMap.OrderCategory
              .filter((data) => data.ExternalId__c == 'OrderCategory14')[0].Code__c;
      // 申込ステータス(作成中)
      insertData['OrderStatus__c'] =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

      // 申込テーブル登録
      resultSave = await this.props.doSaveCorrectOrder(
          null, insertData,
          this.state.rootFiles, this.state.horizonFiles,
          this.state.specFiles, this.state.otherFiles,
          this.state.pictureFiles, deleteFiles,
          (isSetMainDnt ? this.MainDnt : {}));
    }
    // 保存結果判定
    const resResult = getResponseResult(resultSave, ['訂正撤去申込', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    // propsに申込番号を設定
    let id = null;
    if (Object.hasOwnProperty.call(this.props.orderResult, 'orderId')) {
      id = this.props.orderResult.orderId;
    }
    this.props.doSetOrderId(id);
    this.MainDnt = {};

    // 契約電柱最大数登録済で代表電柱を追加できなかったら、
    // エラーメッセージを表示する。処理は継続する。
    const _messages = [{
      id: 'CI0010',
      values: ['保存'],
    }];
    if (resResult.messages != null && resResult.messages.length > 0 &&
      resResult.messages[0].id == 'CE0218') {
      _messages.push({
        id: 'CE0218',
        values: ['契約電柱', '30',
        this.props.stbType == '1' ?
        '訂正撤去申込（契約電柱：線設備）画面' : '訂正撤去申込（契約電柱：点設備）画面'],
      });
    }

    this.props.doShowMessage({
      message: _messages,
      action: async () => {
        // 申込情報+ファイルIDを取得
        const errorReGet = await this.getOrderInfo(id);
        if (errorReGet != API_CODE.SUCCESS) {
          return {success: false,
            messages: [{id: 'CE0052', values: ['訂正撤去申込', '取得']}]};
        }

        // 申込の最終更新日を退避
        this.props.doSetOrderLastModifiedDate(
            this.props.order.LastModifiedDate);

        // 添付ファイル取得
        const results = await this.getAttachedFiles();
        console.log('results:' + results.result);
        if (!results.result) {
          return {success: false,
            messages: [{id: 'CE0052', values: ['添付ファイル', '取得']}]};
        }
        this.setState({
          rootFiles: results.rootFiles,
          specFiles: results.specFiles,
          otherFiles: results.otherFiles,
          pictureFiles: results.pictureFiles,
          rootDelFiles: [],
          horizonDelFiles: [],
          specDelFiles: [],
          otherDelFiles: [],
          pictureDelFiles: [],
        });

        const errorMessages = [];
        const warningMessages = [];
        if (id) {
          // 作成中の場合は入力チェックを行う
          this.inputCheck(this.props.contentDocuments,
              errorMessages, warningMessages);
        }
        let isWarn = false;
        // 入力チェックエラー
        if (errorMessages.length > 0 || warningMessages.length > 0) {
          // 入力チェックNG
          isWarn = true;
        }
        await this.props.doSetGmnWarning('1612', isWarn);
        this.setState({isModified: false, warningMessages: warningMessages});
      },
    });

    return {success: true, messages: []};
  }

  /**
   * 申込情報取得
   * @param {*} id
   * @return {*}
   */
  getOrderInfo = async (id) => {
    const {generalPurposeMap} = this.props;

    /** ルート図 */
    const attachedFileTypeRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA08', 3);

    /** 設備仕様書(未使用となったが、既登録済データ表示のため取得) */
    const attachedFileTypeSpec = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA01', 3);

    /** 平面図 */
    const attachedFileTypePlan = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA02', 3);

    /** その他資料 */
    const attachedFileTypeOther = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA12', 3);

    /** 装柱図 */
    const attachedFileTypePicture = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);
    // 画面再描画時のデータを取得する
    const appConditions = {
      'Id': id,
      'OrderCategory__c': getCodeFromGeneralPurposeMap(
          generalPurposeMap, 'OrderCategory14', 2),
      'RecordType.DeveloperName': 'ContractDifferTekyo',
      'attachedFileTypes': [
        attachedFileTypeRoot,
        attachedFileTypeSpec,
        attachedFileTypePlan,
        attachedFileTypeOther,
        attachedFileTypePicture,
      ],
    };
    // 取得項目
    const resReGet =
          await this.props.doGetOrderInfo(appConditions, orderBasicFields);
    return getErrorCode(resReGet);
  }

  /**
   * 申込情報取得
   * 申込－契約電柱－線設備 を取得
   * @return {*}
   */
  getOrderInfoList = async () => {
    const {orderId, generalPurposeMap} = this.props;
    // 画面再描画時のデータを取得する
    const appConditions = {
      'Id': orderId,
      'OrderCategory__c': getCodeFromGeneralPurposeMap(
          generalPurposeMap, 'OrderCategory14', 2),
      'RecordType.DeveloperName': 'ContractDifferTekyo',
    };
    // サブクエリ生成
    const subQuery = [
      {
        'fields': ['Id', 'LastModifiedDate'],
        'table': 'ContractDnts__r',
      },
      {
        'fields': ['Id', 'LastModifiedDate'],
        'table': 'OrderSenStbs__r',
      },
    ];
    // 申込テーブル検索時のソート条件
    const appSortParams = {};

    // 申込テーブルからデータ取得
    // 申込－契約電柱－線設備 を取得
    const result =
            await this.props.doGetOrderTypeList(
                appConditions, orderBasicFields, appSortParams,
                'getTekkyoSyunkoOrderList', subQuery, '');

    return getErrorCode(result);
  }

  /**
   * 添付ファイルを取得する
   * @return {*}
   */
  getAttachedFiles = async () => {
    const {generalPurposeMap} = this.props;
    // lambdaのレスポンスサイズに制限があるので、添付ファイルは1つずつ取得する
    const attachedFileTypeRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA08', 3);
    const attachedFileTypeSpec = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA01', 3);
    const attachedFileTypePlan = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA02', 3);
    const attachedFileTypeOther = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA12', 3);
    const attachedFileTypePicture = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);
    let _rootFiles = [];
    let _specFiles = [];
    let _otherFiles = [];
    let _pictureFiles = [];
    let ret = true;

    if (this.props.contentDocuments &&
      this.props.contentDocuments.length > 0) {
      _rootFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeRoot);
      _specFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeSpec);
      _otherFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeOther);
      _pictureFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypePicture);

      // 平面図をspecFilesに追加
      const planFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypePlan);
      _specFiles.push(...planFiles);
    }

    return {
      'result': ret,
      'rootFiles': _rootFiles,
      'specFiles': _specFiles,
      'otherFiles': _otherFiles,
      'pictureFiles': _pictureFiles,
    };
  }

  /**
   * 画面表示時と現在の共架種別をチェックする
   * @return {boolean} true:同じ
   *                   false:異なる
   */
  isSameKyogaType() {
    const {values} = this.props;

    // 画面表示当初の共架種別
    const prevKyogaType = this.state.kyogaType;

    // 画面表示時の共架種別が空の場合は処理しない
    if (!prevKyogaType) {
      console.log('共架種別が画面表示時に未設定のため契約電柱と線設備を削除しない');
      return true;
    }

    // 現在の共架種別
    const nowKyogaType = values.KyogaType__c;

    // 共架種別に変更なしの場合は処理しない
    if (nowKyogaType == prevKyogaType) {
      console.log('共架種別が変更なしのため契約電柱と線設備を削除しない');
      return true;
    }

    return false;
  }


  /**
   * 画面描画
   * @return {*}
   */
  render() {
    const {classes, userInfo, generalPurposeMap,
      handleSubmit, values} = this.props;
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1612CorrectTekyoOrderInput" item>
          <PositiveButton
            id="btn1612CorrectTekyoOrderInput"
            onClick={handleSubmit(this.doInputDnt)}
            variant="contained"
            size="large"
          >
            <span>申込電柱入力</span>
          </PositiveButton>
        </Grid>

        <Grid key="key1612Save" item>
          <PositiveButton
            id="btn1612Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon />}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="key1612Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onClick={this.doBack}
          />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>

        <MainContainer props={this.props} footerBtn={footerBtn}>
          {/* 警告メッセージ */}
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}>

            {/* 申込情報 */}
            <Grid key="key1612OrderGrid" item xs={12}>
              <PaperPart>
                <Typography variant="h6" gutterBottom>申込情報</Typography>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid key="key1612OrderNo" item xs={12} sm={2}>
                    <Field
                      id="id1612OrderNo"
                      name="OrderNo__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='申込番号'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1612TekyoOrderDate" item xs={12} sm={2}>
                    <Field
                      id="id1612TekyoOrderDate"
                      name="OrderDate__c"
                      label='申込年月日'
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      required={false}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1612SaveDate" item xs={12} sm={2}>
                    <Field
                      id="id1612SaveDate"
                      name="TemporarilySavedDate__c"
                      label='一時保存日'
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      required={false}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>

                  <Grid key="key1612SendBackReason" item xs={12} sm={4}>
                    <Field
                      id="id1612SendBackReason"
                      name="KyogasyaSendBackReason__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='差戻理由'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                </Grid>

                {/* 共架者情報 */}
                <Grid container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid key="key1612OrderKyogaZgsya" item xs={12} sm={5}>
                    {/* 共架事業者＝会社名＋事業所名 */}
                    <Field
                      id="id1612OrderKyogaZgsya"
                      name="OrderKyogaZgsyaName"
                      component={renderTextField}
                      className={classes.fields}
                      label='申込共架事業者'
                      type="text"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    >
                    </Field>
                  </Grid>

                  <Grid key="key1612KoziTitle" item xs={12} sm={8}>
                    <Field
                      id="id1612KoziTitle"
                      name="KoziTitle__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='工事件名'
                      type="text"
                      fullWidth
                      required={true}
                      validate={[
                        validateRule.maxLength(50),
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                      disabled={this.state.isDisabled}
                    />
                  </Grid>
                </Grid>

                {/* 工事場所 */}
                <Typography variant="h6" gutterBottom>工事場所&nbsp;
                  <MuiTooltip
                    id="tl612SelectDnt"
                    placement='bottom'
                    content={
                      <React.Fragment>
                        電柱を選択して保存ボタンを押すと代表電柱を次の画面の撤去電柱に追加します。<br/>
                        撤去電柱の中に代表電柱がない場合、次の画面の電柱選択ボタン<br/>
                        または電柱追加ボタンを使って代表電柱を撤去電柱に追加してください。
                      </React.Fragment>}>
                    <InfoOutlined fontSize="small"/>
                  </MuiTooltip>
                </Typography>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid key="key1612KoziPlaceMainDntNoSenroName" item xs={12} sm={2}>
                    <Field
                      id="id1612KoziPlaceMainDntNoSenroName"
                      name="KoziPlaceMainDntNo_SenroName__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='線路名'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      required={false}
                      validate={[
                      ]}
                      warn={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1612KoziPlaceMainDntNo" item xs={12} sm={2}>
                    <Field
                      id="id1612KoziPlaceMainDntNo"
                      name="Dsp_KoziPlaceMainDntNo__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='代表電柱番号'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      required={true}
                      validate={[
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                    />
                  </Grid>
                  <Grid key="key1612SelectDnt" item xs={12} sm={8}>
                    <PositiveButton
                      id="id1612SelectDnt"
                      onClick={() => {
                        if (values == null || !values.KyogaType__c) {
                          this.props.doShowMessage({
                            message: {
                              id: 'CE0027',
                              values: ['共架種別'],
                            },
                          });
                          return;
                        }
                        this.setState({modalOpen: true});
                      }}
                      variant="contained"
                      size="large"
                      disabled={this.state.isDisabled}
                    >
                      <span>電柱選択</span>
                    </PositiveButton>
                  </Grid>

                  <Grid key="key1612KoziPlacePrefectures" item xs={12} sm={2}>
                    <Field
                      id="id1612KoziPlacePrefectures"
                      name="KoziPlacePrefectures__c"
                      component={renderSelect}
                      className={classes.fields}
                      label='都道府県'
                      fullWidth
                      required={true}
                      InputProps={{
                        readOnly: false,
                      }}
                      validate={[
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                      disabled={this.state.isDisabled}
                    >
                      {generalPurposeMap &&
                          createSearchList(generalPurposeMap.PrefCode)}
                    </Field>
                  </Grid>
                  <Grid key="key1612KoziPlaceMunicipalities" item xs={12} sm={4}>
                    <Field
                      id="id1612KoziPlaceMunicipalities"
                      name="KoziPlaceMunicipalities__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='市区町村'
                      fullWidth
                      type="text"
                      required={true}
                      InputProps={{
                        readOnly: false,
                      }}
                      validate={[
                        validateRule.maxLength(12),
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                      disabled={this.state.isDisabled}
                    />
                  </Grid>

                  <Grid key="key1612KoziPlacePlace" item xs={12} sm={4}>
                    <Field
                      id="id1612KoziPlacePlace"
                      name="KoziPlacePlace__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='場所'
                      fullWidth
                      type="text"
                      required={true}
                      InputProps={{
                        readOnly: false,
                      }}
                      validate={[
                        validateRule.maxLength(255),
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                      disabled={this.state.isDisabled}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={3}>
                  <Grid key="key1612StbType" item xs={12} sm={3}>
                    <Field
                      id="id1612StbType"
                      name="StbType__c"
                      component={renderSelect}
                      className={classes.fields}
                      label='設備種別'
                      fullWidth
                      required={false}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    >
                      {generalPurposeMap &&
                          createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                    </Field>
                  </Grid>
                  {/* 共架種別は共架事業者によって異なる*/}
                  <Grid key="key1612KyogaType" item xs={12} sm={3}>
                    <Field
                      id="id1612KyogaType"
                      name="KyogaType__c"
                      component={renderSelect}
                      className={classes.fields}
                      label='共架種別'
                      fullWidth
                      onChange={this.changeKyogaType}
                      required={true}
                      validate={[
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                      disabled={this.state.isDisabled}
                    >
                      {generalPurposeMap &&
                        createSearchList(generalPurposeMap.KyogaType,
                            'Code__c',
                            'Name',
                            {Code__c: userInfo.Account.Account__r.KyogaType__c,
                              ValueString1__c: this.isSen ? ['1'] : ['2']})}
                    </Field>
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>

            {/* 添付ファイル */}
            <Grid key="key1612AttachGrid" item xs={12} sm={7}>
              <PaperPart>
                <Typography variant="h6" gutterBottom>添付ファイル</Typography>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid key="key1612PictureFile" item xs={12}>
                    <Typography variant="h6" display="block">
                      装柱図
                      <font size="2">(※最大1ファイル、1ファイルあたり20MBまで登録できます)</font>
                      <Required/>
                      <MuiTooltip content={
                        <React.Fragment>
                          電柱番号，全景，共架部分拡大写真・装柱図等の資料を添付してください。
                        </React.Fragment>
                      }>
                        <InfoOutlined/>
                      </MuiTooltip>
                    </Typography>
                  </Grid>
                  <Grid key="key1612UploadPictureFile" item xs={12}>
                    <Upload
                      componentId="pictureComponent"
                      maxFileCount={1}
                      maxFileSize={20971520}
                      required={true}
                      previewFlag={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      fileSetHandler={this.setPictureFiles}
                      initFile={this.state.pictureFiles}
                      disabled={this.state.isDisabled}
                      customChecks={[
                        checkFileNameLength,
                        checkFileSizeZero,
                        checkSameName,
                      ]}
                    />
                  </Grid>
                  {/* 線設備：ルート図、点設備：共架設備仕様書 */}
                  <Grid key="key1612RootFile" item xs={12}>
                    {(this.isSen) &&
                      <Typography variant="h6" display="block">
                        ルート図
                        <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                        <Required/>
                        <MuiTooltip content={
                          <React.Fragment>
                            申込ルートが分かる資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined/>
                        </MuiTooltip>
                      </Typography>
                    }
                    {(!this.isSen) &&
                      <Typography variant="h6" display="block">
                        平面図
                        <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                        <MuiTooltip content={
                          <React.Fragment>
                            設置場所が分かる資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined/>
                        </MuiTooltip>
                      </Typography>
                    }
                  </Grid>
                  <Grid key="key1002UploadRootFile" item xs={12}>
                    {(this.isSen) &&
                      <Upload
                        componentId="rootComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        required={true}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setRootFiles}
                        initFile={this.state.rootFiles}
                        disabled={this.state.isDisabled}
                        customChecks={[
                          checkFileNameLength,
                          checkFileSizeZero,
                          checkSameName,
                        ]}
                      />
                    }
                    {(!this.isSen) &&
                      <Upload
                        componentId="specComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        required={false}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setSpecFiles}
                        initFile={this.state.specFiles}
                        disabled={this.state.isDisabled}
                        customChecks={[
                          checkFileNameLength,
                          checkFileSizeZero,
                          checkSameName,
                        ]}
                      />
                    }
                  </Grid>
                  {/* その他資料 */}
                  <Grid key="key10347OtherFile" item xs={12}>
                    <Typography variant="h6" display="block">
                      その他資料
                      <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                      <MuiTooltip content={
                        <React.Fragment>
                          上記以外の資料を添付してください。
                        </React.Fragment>
                      }>
                        <InfoOutlined/>
                      </MuiTooltip>
                    </Typography>
                  </Grid>
                  <Grid key="key1612UploadOtherFile" item xs={12}>
                    <Upload
                      componentId="otherComponent"
                      maxFileCount={3}
                      maxFileSize={3145728}
                      previewFlag={true}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      fileSetHandler={this.setOtherFiles}
                      initFile={this.state.otherFiles}
                      disabled={this.state.isDisabled}
                      customChecks={[
                        checkFileNameLength,
                        checkFileSizeZero,
                        checkSameName,
                      ]}
                    />
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>

            {/* その他 */}
            <Grid key="key1612OtherGrid" item xs={12} sm={5}>
              <PaperPart>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid key="key1612Memo" item xs={12}>
                    <Field
                      id="id1612Memo"
                      name="MemoContents__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='メモ内容'
                      variant="outlined"
                      type="text"
                      multiline
                      rows={15}
                      fullWidth
                      required={false}
                      validate={[
                        validateRule.maxLength(255),
                      ]}
                      disabled={this.state.isDisabled}
                    />
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>
          </Grid>
        </MainContainer>
        <SelectPoleNo
          YbdsType="E"
          StbType={this.props.stbType}
          KyogaType={values.KyogaType__c}
          modalOpen={this.state.modalOpen}
          IsClear={true}
          closeButton={this.closeSelectDnt}
          setSelectedPole={this.setSelectedDnt}/>
      </form>
    );
  }
}

Container.propTypes = {
  handleSubmit: PropTypes.func,
  doClearOrderInfo: PropTypes.func,
  doClearAttachedFiles: PropTypes.func,
  doShowMessage: PropTypes.func,
  change: PropTypes.func,
  doSetDntInfo: PropTypes.func,
  doSetStbType: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  doSaveCorrectOrder: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doGetOrderInfo: PropTypes.func,
  doGetOrderTypeList: PropTypes.func,
  doClearOrderList: PropTypes.func,
  doDeletePhoto: PropTypes.func,
  doExistsPhoto: PropTypes.func,
  doSetOrderLastModifiedDate: PropTypes.func,

  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  userInfo: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  order: PropTypes.object,
  selectedDntInfo: PropTypes.object,
  syncWarnings: PropTypes.object,
  setGmnWarning: PropTypes.object,
  orderResult: PropTypes.object,
  values: PropTypes.object,
  orderCheckResult: PropTypes.object,

  attachedFile: PropTypes.array,
  rootFiles: PropTypes.array,
  specFiles: PropTypes.array,
  otherFiles: PropTypes.array,
  pictureFiles: PropTypes.array,
  contentDocuments: PropTypes.array,
  orderList: PropTypes.array,

  orderId: PropTypes.string,
  OrderKyogaZgsyaName: PropTypes.string,
  KoziTitle__c: PropTypes.string,
  KyogasyaSendBackReason__c: PropTypes.string,
  KoziPlaceMainDntNo__c: PropTypes.string,
  KoziPlacePrefectures__c: PropTypes.string,
  KoziPlaceMunicipalities__c: PropTypes.string,
  KoziPlacePlace__c: PropTypes.string,
  StbType__c: PropTypes.string,
  KyogaType__c: PropTypes.string,
  MemoContents__c: PropTypes.string,
  stbType: PropTypes.string,
  kyogaType: PropTypes.string,
  orderLastModifiedDate: PropTypes.string,

  dirty: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const initValues = {
    // 申込番号
    OrderNo__c: null,
    // 申込日
    OrderDate__c: null,
    // 一時保存日
    TemporarilySavedDate__c: null,
    // 共架者差戻し理由
    KyogasyaSendBackReason__c: null,
    // 申込共架事業者
    OrderKyogaZgsya__c: state.auth.userInfo.AccountId,
    // 申込共架事業者名
    OrderKyogaZgsyaName: state.auth.userInfo.Account.Name,
    // 工事件名
    KoziTitle__c: null,
    // 代表電柱番号_中電NW事業所
    MainDntNoNWZgsyo__c: null,
    // 工事場所_代表電柱番号
    Dsp_KoziPlaceMainDntNo__c: null,
    // 代表電柱_線路コード
    KoziPlaceMainDntNo_SenroCode__c: null,
    // 代表電柱_線路名
    KoziPlaceMainDntNo_SenroName__c: null,
    // 工事場所_代表電柱番号
    KoziPlaceMainDntNo__c: null,
    // 工事場所_都道府県
    KoziPlacePrefectures__c: null,
    // 工事場所_市区町村
    KoziPlaceMunicipalities__c: null,
    // 工事場所_場所
    KoziPlacePlace__c: null,
    // 設備種別
    StbType__c: state.common.stbType,
    // 共架種別
    KyogaType__c: null,
    // メモ内容
    MemoContents__c: null,
  };

  const order = state.attachorder.orderInfo &&
                 Object.hasOwnProperty.call(state.attachorder.orderInfo, 'order') ?
                 state.attachorder.orderInfo.order : [];
  for (const key in order) {
    if (Object.hasOwnProperty.call(order, key)) {
      if (order[key]) {
        if (key == 'TemporarilySavedDate__c' || key == 'OrderDate__c') {
          initValues[key] = changeDateFormatter(order[key], 'YYYY-MM-DD');
        } else if (key == 'OrderKyogaZgsya__r') {
          initValues['OrderKyogaZgsyaName'] =
            Object.hasOwnProperty.call(order[key], 'Name') ? order[key].Name : '' +
            Object.hasOwnProperty.call(order[key], 'Account__r') &&
            Object.hasOwnProperty.call(order[key].Account__r, 'Name') ?
            order[key].Account__r.Name: '';
        } else {
          initValues[key] = order[key];
        }
      }
    }
  }

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: order,
    orderList: state.attachorder.orderList,
    stbType: state.common.stbType,
    selectedDntInfo: state.dnt.dntInfo,
    setGmnWarning: state.common.setGmnWarning,
    orderResult: state.attachorder.orderResult,
    contentDocuments: state.attachorder.orderInfo &&
        Object.hasOwnProperty.call(state.attachorder.orderInfo, 'contentDocuments') ?
          state.attachorder.orderInfo.contentDocuments : [],
    attachedFile: state.common.attachedFileList,
    orderCheckResult: state.attachorder.orderCheckResult,
    initialValues: initValues,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doClearOrderInfo: attachorderOperations.doClearOrderInfo,
  doClearAttachedFiles: commonOperations.doClearAttachedFiles,
  doSetDntInfo: dntOperations.doSetDntInfo,
  doSetStbType: commonOperations.doSetStbType,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSaveCorrectOrder: attachorderOperations.doSaveCorrectOrder,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doGetOrderInfo: attachorderOperations.doGetOrderInfo,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doDeletePhoto: contractDntOperations.doDeletePhoto,
  doExistsPhoto: contractDntOperations.doExistsPhoto,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'CorrectTekyoOrderSenBasicInfo';
Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
    syncWarnings: getFormSyncWarnings(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

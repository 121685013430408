const GET_CONTRACTDNT_LIST = 'GET_CONTRACTDNT_LIST';
const RETRIEVE_CONTRACTDNT = 'RETRIEVE_CONTRACTDNT';
const REGIST_CONTRACTDNT = 'REGIST_CONTRACTDNT';
const UPDATE_CONTRACTDNT = 'UPDATE_CONTRACTDNT';
const DELETE_CONTRACTDNT = 'DELETE_CONTRACTDNT';
const SET_CONTRACTDNT_ID = 'SET_CONTRACTDNT_ID';
const GET_CONTRACTDNT_COUNT = 'GET_CONTRACTDNT_COUNT';
const CHECK_CONTRACTDNT = 'CHECK_CONTRACTDNT';
const SAVE_CONTRACTDNT = 'SAVE_CONTRACTDNT';
const GET_CONTRACTDNT_MASTER_LIST = 'GET_CONTRACTDNT_MASTER_LIST';
const GET_ORDER_CONTRACTDNT = 'GET_ORDER_CONTRACTDNT';
const GET_FORMAL_CONTRACTDNT = 'GET_FORMAL_CONTRACTDNT';
const GET_CONTRACTDNTDNT_OR_FILTER = 'GET_CONTRACTDNTDNT_OR_FILTER';
const GET_ISKK_CONTRACTDNT = 'GET_ISKK_CONTRACTDNT';
const GET_CONTRACTDNT_SEARCH_LIST = 'GET_CONTRACTDNT_SEARCH_LIST';
const SET_PHOTO_REGISTRATION_INFO = 'SET_PHOTO_REGISTRATION_INFO';
const GET_PHOTO_INFO = 'GET_PHOTO_INFO';
const GET_NWZGSYO_SEARCHFILTER = 'GET_NWZGSYO_SEARCHFILTER';
const SET_SENRO_CODE_SEARCH_LIST = 'SET_SENRO_CODE_SEARCH_LIST';

export default {
  /** 契約電柱一覧 取得 */
  GET_CONTRACTDNT_LIST,

  /** 契約電柱 ID指定取得 */
  RETRIEVE_CONTRACTDNT,

  /** 契約電柱 登録 */
  REGIST_CONTRACTDNT,

  /** 契約電柱 更新 */
  UPDATE_CONTRACTDNT,

  /** 契約電柱 削除 */
  DELETE_CONTRACTDNT,

  /** 契約電柱ID 設定 */
  SET_CONTRACTDNT_ID,

  /** 契約電柱件数 取得 */
  GET_CONTRACTDNT_COUNT,

  /** 業務チェック */
  CHECK_CONTRACTDNT,

  /** 契約電柱 登録/更新/削除 */
  SAVE_CONTRACTDNT,

  /** 契約電柱マスタ 取得 */
  GET_CONTRACTDNT_MASTER_LIST,

  /** 可否判定申込の申込情報と申込電柱情報 取得 */
  GET_ORDER_CONTRACTDNT,

  /** 本申込の申込電柱情報 取得 */
  GET_FORMAL_CONTRACTDNT,

  /** 契約電柱取得 OR条件 */
  GET_CONTRACTDNTDNT_OR_FILTER,

  /** 一束化設定申込電柱情報 取得 */
  GET_ISKK_CONTRACTDNT,

  /** 契約電柱一覧（検索画面用） 取得 */
  GET_CONTRACTDNT_SEARCH_LIST,

  /** 写真登録情報 設定 */
  SET_PHOTO_REGISTRATION_INFO,

  /** 写真情報 取得 */
  GET_PHOTO_INFO,

  /** 事業所 抽出 */
  GET_NWZGSYO_SEARCHFILTER,

  /** 線路コード一覧 設定得 */
  SET_SENRO_CODE_SEARCH_LIST,
};

import React from 'react';
import conf from '../../config/config.js';

// 取付/契約電柱（線設備）/添付写真施工後ヘルプ
export function AttachedPicturesSenSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesSenSyunko.png'} alt="取付添付写真（線設備）施工後"/>
  );
};

// 取付/契約電柱（点設備）/添付写真施工後ヘルプ
export function AttachedPicturesTenSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesTenSyunko.png'} alt="取付添付写真（点設備）施工後"/>
  );
};

// 撤去/契約電柱（線設備）/添付写真（施工前）ヘルプ
export function AttachedPicturesSenTkyoMae() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesSenTkyoMae.png'} alt="撤去添付写真（線設備）"/>
  );
};

// 撤去/契約電柱（線設備）/添付写真（施工後）ヘルプ
export function AttachedPicturesSenTkyoAto() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesSenTkyoAto.png'} alt="撤去添付写真（線設備）施工後"/>
  );
};

// 契約相違/契約電柱（線設備）/添付写真ヘルプ
export function AttachedPicturesSenCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesSenCorrect.png'} alt="契約相違添付写真（線設備）"/>
  );
};

// 契約相違撤去/契約電柱（線設備）/添付写真（施工後）ヘルプ
export function AttachedPicturesSenCorrectTkyoAto() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesSenCorrectTkyoAto.png'} alt="契約相違撤去添付写真（線設備）施工後"/>
  );
};

// 改修依頼/契約電柱（線設備）/添付写真（施工後）ヘルプ
export function AttachedPicturesSenRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesSenRepairSyunko.png'} alt="改修依頼添付写真（線設備）施工後"/>
  );
};

// 撤去/契約電柱（点設備）/添付写真（施工前）ヘルプ
export function AttachedPicturesTenTkyoMae() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesTenTkyoMae.png'} alt="撤去添付写真（点設備）"/>
  );
};

// 撤去/契約電柱（点設備）/添付写真（施工後）ヘルプ
export function AttachedPicturesTenTkyoAto() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesTenTkyoAto.png'} alt="撤去添付写真（点設備）施工後"/>
  );
};

// 契約相違/契約電柱（点設備）/添付写真ヘルプ
export function AttachedPicturesTenCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesTenCorrect.png'} alt="契約相違添付写真（点設備）"/>
  );
};

// 契約相違撤去/契約電柱（点設備）/添付写真（施工後）ヘルプ
export function AttachedPicturesTenCorrectTkyoAto() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesTenCorrectTkyoAto.png'} alt="契約相違撤去添付写真（点設備）施工後"/>
  );
};

// 改修依頼/契約電柱（点設備）/添付写真（施工後）ヘルプ
export function AttachedPicturesTenRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachedPicturesTenRepairSyunko.png'} alt="改修依頼添付写真（点設備）施工後"/>
  );
};

// 一束化ヘルプ
export function DnsnIskk() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'dnsnIskk.png'} alt="取付一束化"/>
  );
};

// 取付種別ヘルプ
export function AttachType() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachType.png'} alt="取付種別"/>
  );
};

// 契約相違/取付種別ヘルプ
export function AttachTypeCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'attachTypeCorrect.png'} alt="契約相違取付種別"/>
  );
};

// 契約相違/線路角度ヘルプ
export function SenroAngleCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'senroAngleCorrect.png'} alt="契約相違線路角度"/>
  );
};

// 取付/契約電柱/最接近電力設備との離隔ヘルプ
export function ClosestDrStbDnt() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbDnt.png'} alt="取付最接近電力設備との離隔"/>
  );
};

// 取付/契約電柱/最接近電力設備との離隔（施工後）ヘルプ
export function ClosestDrStbDntSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbDntSyunko.png'} alt="取付最接近電力設備との離隔（施工後）"/>
  );
};

// 契約相違/契約電柱/最接近電力設備との離隔ヘルプ
export function ClosestDrStbDntCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbDntCorrect.png'} alt="契約相違最接近電力設備との離隔"/>
  );
};

// 改修依頼/契約電柱/最接近電力設備との離隔（施工後）ヘルプ
export function ClosestDrStbDntRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbDntRepairSyunko.png'} alt="改修依頼最接近電力設備との離隔（施工後）"/>
  );
};

// 取付/径間/最接近電力設備との離隔ヘルプ
export function ClosestDrStbSen() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbSen.png'} alt="取付最接近電力設備との離隔（線設備）"/>
  );
};

// 取付/径間/最接近電力設備との離隔（施工後）ヘルプ
export function ClosestDrStbSenSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbSenSyunko.png'} alt="取付最接近電力設備との離隔（線設備）（施工後）"/>
  );
};

// 契約相違/径間/最接近電力設備との離隔（施工後）ヘルプ
export function ClosestDrStbSenCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'closestDrStbSenCorrect.png'} alt=" 契約相違最接近電力設備との離隔（線設備）（施工後）"/>
  );
};

// 改修依頼/他の共架設備との離隔（施工後）ヘルプ
export function KyogabtDntRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogabtDntRepairSyunko.png'} alt="改修依頼他の共架設備との離隔（施工後）"/>
  );
};

// 契約相違/他の共架設備との離隔ヘルプ
export function KyogabtDntCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogabtDntCorrect.png'} alt="契約相違他の共架設備との離隔"/>
  );
};

// 契約相違/共架標識取付有無ヘルプ
export function KyogaHyoskAttachUmuCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogaHyoskAttachUmuCorrect.png'} alt="契約相違共架標識取付有無"/>
  );
};

// 契約相違/電柱への取付方法ヘルプ
export function DntAttachWayCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'dntAttachWayCorrect.png'} alt="契約相違電柱への取付方法"/>
  );
};

// 電柱選択/電柱番号表示ヘルプ
export function DntNoDisplay() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'dntNoDisplay.png'} alt="電柱選択電柱番号表示"/>
  );
}

// 通信引込線ヘルプ
export function TsnHksn() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'tsnHksn.png'} alt="取付通信引込線"/>
  );
};

// 通信引込線（施工後）ヘルプ
export function TsnHksnSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'tsnHksnSyunko.png'} alt="取付通信引込線（施工後）"/>
  );
};

// 改修依頼/通信引込線ヘルプ
export function TsnHksnRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'tsnHksnRepairSyunko.png'} alt="改修依頼通信引込線"/>
  );
};

// 契約相違/通信引込線ヘルプ
export function TsnHksnCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'tsnHksnCorrect.png'} alt="契約相違通信引込線"/>
  );
};

// 契約相違/地表上の高さヘルプ
export function GroundSurfaceHeightCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'groundSurfaceHeightCorrect.png'} alt="契約相違地表上の高さ"/>
  );
};

// 改修依頼/地表上の高さ（施工後）ヘルプ
export function GroundSurfaceHeightRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'groundSurfaceHeightRepairSyunko.png'} alt="改修依頼地表上の高さ（施工後）"/>
  );
};

// 電力引込線との離隔（家屋側支接点付近）
export function DrHksnHouseSideRikakKkh() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'drHksnHouseSideRikakKkh.png'} alt="取付電力引込線との離隔（家屋側支接点付近）"/>
  );
};

// 電力引込線との離隔（家屋側支接点付近）（施工後）ヘルプ
export function DrHksnHouseSideRikakKkhSynk() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'drHksnHouseSideRikakKkhSynk.png'} alt="取付電力引込線との離隔（家屋側支接点付近）（施工後）"/>
  );
};

// 契約相違/電力引込線との離隔（家屋側支接点付近）ヘルプ
export function DrHksnHouseSideRikakKkhCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'drHksnHouseSideRikakKkhCorrect.png'} alt="契約相違電力引込線との離隔（家屋側支接点付近）"/>
  );
};

// 改修依頼/電力引込線との離隔（家屋側支接点付近）（施工後）ヘルプ
export function DrHksnHouseSideRikakKkhRepairSyunko() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'drHksnHouseSideRikakKkhRepairSyunko.png'} alt="改修依頼電力引込線との離隔（家屋側支接点付近）（施工後）"/>
  );
};

// 取付/共架設備ヘルプ
export function KyogaStb() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogaStb.png'} alt="取付共架設備"/>
  );
};

// 契約相違/共架設備ヘルプ
export function KyogaStbCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogaStbCorrect.png'} alt="契約相違共架設備"/>
  );
};

// 契約相違/土地区分ヘルプ
export function GroundCategoryCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'groundCategoryCorrect.png'} alt="契約相違土地区分"/>
  );
};

// 契約相違/方向ヘルプ
export function DirectionCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'directionCorrect.png'} alt="契約相違方向"/>
  );
};

// 撤去区分ヘルプ
export function TkyoKbn() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'tkyoKbn.png'} alt="撤去区分"/>
  );
};

// 契約相違/撤去区分ヘルプ
export function TkyoKbnCorrect() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'tkyoKbnCorrect.png'} alt="契約相違撤去区分"/>
  );
};

// 移設依頼/共架者別備考ヘルプ
export function KyogasyaRemarks() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogasyaRemarks.png'} alt="移設依頼共架者別備考"/>
  );
}

// 工事日連絡/共架者別備考ヘルプ
export function KyogasyaRemarksKozi() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'kyogasyaRemarksKozi.png'} alt="工事日連絡共架者別備考"/>
  );
}

// 移設依頼/システム通知ヘルプ
export function IsetuIraiNotice() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'isetuIraiNotice.png'} alt="移設依頼システム通知"/>
  );
}

// 工事日連絡/システム通知ヘルプ
export function IsetuIraiNoticeKozi() {
  return (
    <img className="help_image_alt" src={conf.HELP_IMAGE_URL + 'isetuIraiNoticeKozi.png'} alt="工事日連絡システム通知"/>
  );
}

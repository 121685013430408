import axios from 'axios';

import {
  deleteDesignDocument,
  getDesignDocumentList,
  getDesignDocumentTypeList,
  registDesignDocument,
  retrieveDesignDocument,
  setDesignDocumentId,
  updateDesignDocument,
  getDesignDocument,
  deleteSaveDesignDocument,
  getDetailDesignDocument,
  deleteSaveDetailDesignDocument,
} from './actions';

import conf from './../../config/config.js';

/**
 * 移設依頼設計書 取得
 * @param {object} conditions 検索条件
 * @param {string[]|object} fields 取得するフィールド名
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetDesignDocumentList = (conditions, fields) => {
  console.log('移設依頼設計書 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    const ret = await axios.post(conf.API_URL + 'DesignDocument/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(getDesignDocumentList(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 移設依頼設計書 取得（関数名指定）
 * @param {object} conditions 検索条件
 * @param {string[]|object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {string} type     実行する関数名
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @param {Object} [addFields] 追加設定するフィールド({フィールド名：値})
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetDesignDocumentTypeList = (
    conditions, fields, sortParams, type, subquery, addFields) => {
  console.log('移設依頼設計書 取得（関数名指定）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'DesignDocument/' + type, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getDesignDocumentTypeList(
      ret.data.body.data ? ret.data.body.data : null);

    if (addFields && retData.designDocumentList) {
      // 検索結果に項目追加
      const size = Object.keys(retData.designDocumentList).length;
      for (let i = 0; i < size; i++) {
        Object.assign(retData.designDocumentList[i], addFields);
      }
    }
    dispatch(retData);

    return ret;
  };
};

/**
 * 移設依頼設計書 ID指定取得
 * @param {string|string[]} id ID
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doRetrieveDesignDocument = (id) => {
  console.log('移設依頼設計書 ID指定取得...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      Id: id,
    };

    const ret = await axios.post(conf.API_URL + 'DesignDocument/retrieve', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(retrieveDesignDocument(
      ret.data.body.data ? ret.data.body.data : null));
  };
};

/**
 * 移設依頼設計書 破棄
 * @param {string|string[]} id ID
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doClearDesignDocument = () => {
  console.log('移設依頼設計書 破棄...');

  return async (dispatch, getState) => {
    dispatch(getDesignDocument(null));
  };
};

/**
 * 移設依頼設計書 登録
 * @param {object} data 登録する内容
 * @return {Promise<AxiosResponse<any>>} 登録結果
 */
const doRegistDesignDocument = (data) => {
  console.log('移設依頼設計書 登録...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'DesignDocument/regist', data, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(registDesignDocument(ret.data.body));

    return ret;
  };
};

/**
 * 移設依頼設計書 更新
 * @param {string} id 更新する対象のID
 * @param {object} values 更新する内容
 * @param {'BASIC'|'DETAIL'} stage 更新段階
 * @return {Promise<AxiosResponse<any>>} 登録結果
 */
const doUpdateDesignDocument = (id, values, stage) => {
  console.log('移設依頼設計書 更新...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      conditions: {Id: id},
      params: values,
      stage: stage,
    };

    const ret = await axios.post(conf.API_URL + 'DesignDocument/update', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateDesignDocument(ret.data.body));

    return ret;
  };
};

/**
 * 移設依頼設計書 削除
 * @param {string|string[]} id 削除する対象のID
 * @return {Promise<AxiosResponse<any>>} 登録結果
 */
const doDeleteDesignDocument = (id) => {
  console.log('移設依頼設計書 削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      Id: id,
    };

    const ret = await axios.post(conf.API_URL + 'DesignDocument/delete', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(deleteDesignDocument(ret.data.body));

    return ret;
  };
};

/**
 * 移設依頼設計書 ID設定
 * @param {string} id 設定するID
 * @return {Promise<void>} 登録結果
 */
const doSetDesignDocumentId = (id) => {
  console.log('移設依頼設計書ID 設定...');

  return async (dispatch) => {
    dispatch(setDesignDocumentId(id));
  };
};

/**
 * 移設依頼設計書の情報クリア
 * @return {Promise<void>} 処理結果
 */
const doClearDesignDocumentList = () => {
  console.log('移設依頼設計書リスト クリア...');

  return async (dispatch) => {
    dispatch(getDesignDocumentList(null));
  };
};

/**
 * 移設依頼設計書の情報クリア
 * @return {Promise<void>} 処理結果
 */
const doClearDesignDocumentTypeList = () => {
  console.log('移設依頼設計書 情報クリア...');

  return async (dispatch) => {
    dispatch(getDesignDocumentTypeList(null));
  };
};

/**
 * 移設依頼設計書 designDocumentListへ設定
 * @param {array} list 設定するリスト
 * @return {Promise<void>} 設定結果
 */
const doSetDesignDocumentList = (list) => {
  console.log('移設依頼設計書 designDocumentList設定...');

  return async (dispatch) => {
    dispatch(getDesignDocumentTypeList(list));
  };
};

/**
 * 移設依頼設計書 検索(ID指定)
 * @param {object} conditions 検索条件(ID必須)
 * @param {string[]|object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {string} type     実行する関数名
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 * @return {Promise<void>} 登録結果
 */
const doGetDesignDocument = (
    conditions, fields, sortParams, type, subquery) => {
  console.log('移設依頼設計書 ID指定取得（関数名指定）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'DesignDocument/' + type, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(getDesignDocument(
        ret.data.body.data ? ret.data.body.data[0] : null));
    return ret;
  };
};

/**
 * 移設依頼設計書 詳細検索(ID指定)
 * @param {object} conditions 検索条件(ID必須)
 * @param {string[]|object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {string} type     実行する関数名
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 * @return {Promise<void>} 登録結果
 */
const doGetDetailDesignDocument = (
    conditions, fields, sortParams, type, subquery) => {
  console.log('移設依頼設計書 ID指定取得（関数名指定）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'DesignDocument/' + type, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(getDetailDesignDocument(
      ret.data.body.data ? ret.data.body.data[0] : null));
    return ret;
  };
};


/**
 * 移設依頼設計書の削除
 * @return {Promise<void>} 処理結果
 */
const doDelDesignDocument = () => {
  console.log('移設依頼設計書 ID指定情報クリア...');

  return async (dispatch) => {
    dispatch(deleteSaveDesignDocument(null));
  };
};

/**
 * 移設依頼設計書の詳細削除
 * @return {Promise<void>} 処理結果
 */
const doDelDetailDesignDocument = () => {
  console.log('移設依頼設計書 ID指定詳細情報クリア...');

  return async (dispatch) => {
    dispatch(deleteSaveDetailDesignDocument(null));
  };
};

export default {
  doGetDesignDocumentList,
  doGetDesignDocumentTypeList,
  doRetrieveDesignDocument,
  doRegistDesignDocument,
  doClearDesignDocument,
  doUpdateDesignDocument,
  doDeleteDesignDocument,
  doSetDesignDocumentId,
  doClearDesignDocumentList,
  doClearDesignDocumentTypeList,
  doSetDesignDocumentList,
  doGetDesignDocument,
  doDelDesignDocument,
  doGetDetailDesignDocument,
  doDelDetailDesignDocument,
};

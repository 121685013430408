import axios from 'axios';

import {
  getBillingDetailId,
  getBillingDetailList,
  getBillingDetailListORFilter,
  setBillingDetailId,
} from './actions';

import conf from '../../config/config.js';

/**
 * 請求明細 取得(ID指定)
 * @param {Object} id 検索条件(ID)
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetBillingDetailId = (id, fields, sortParams) => {
  console.log('請求明細 取得(ID指定)...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (id) {
      body['Id'] = id;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'BillingDetail/retrieve', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(getBillingDetailId(
      ret.data.body.data ? ret.data.body.data : null));
    return ret;
  };
};

/**
 * 請求明細一覧 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            未指定時：「1502_請求明細検索」の独自ソート
 *                            1)共架事業者：昇順、2)共架種別（表示順）：昇順、3)請求費用／確定年月日：昇順、4)請求明細番号：昇順
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetBillingDetailList = (conditions, fields, sortParams) => {
  console.log('請求明細一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'BillingDetail/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(getBillingDetailList(
      ret.data.body.data ? ret.data.body.data : null));
    return ret;
  };
};

/**
 * 定期共架料内訳一覧 取得（OR条件あり）
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            未指定時：「1511_定期共架料内訳」の独自ソート
 *                            1)共架種別（表示順）：昇順、2)請求費用／確定年月日：昇順、3)請求明細番号：昇順
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetBillingDetailListORFilter = (conditions, fields, sortParams) => {
  console.log('請求明細一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'BillingDetail/getORFilter', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(getBillingDetailListORFilter(
      ret.data.body.data ? ret.data.body.data : null));
    return ret;
  };
};

/**
 *請求明細 ID設定
 * @param {string} id 設定するID
 * @return {Promise<void>} 登録結果
 */
const doSetBillingDetailId = (id) => {
  console.log('請求明細ID 設定...');

  return async (dispatch) => {
    dispatch(setBillingDetailId(id));
  };
};

/**
 * 請求明細のクリア
 * @return {void} 処理結果
 */
const doClearBillingDetailList = () => {
  console.log('請求明細 クリア...');

  return async (dispatch) => {
    const retData = getBillingDetailList(null);
    dispatch(retData);
  };
};

/**
 * 定期共架料内訳のクリア
 * @return {void} 処理結果
 */
const doClearBillingDetailListORFilter = () => {
  console.log('定期共架料内訳 クリア...');

  return async (dispatch) => {
    const retData = getBillingDetailListORFilter(null);
    dispatch(retData);
  };
};

/**
 * 請求明細の更新。
 *
 * @param {object|object[]} data 更新データ。複数更新の場合は配列。
 * @return {Promise<AxiosResponse<any, any>>} 実行結果
 */
const doUpdateBillingDetail = (data) => {
  console.log('請求明細一覧 更新...');

  return async () => {
    const ret = await axios.post(conf.API_URL + 'BillingDetail/update', data);
    return ret;
  };
};

export default {
  doGetBillingDetailId,
  doGetBillingDetailList,
  doGetBillingDetailListORFilter,
  doSetBillingDetailId,
  doClearBillingDetailList,
  doClearBillingDetailListORFilter,
  doUpdateBillingDetail,
};

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';

import {API_CODE, changeDateFormat, getErrorCode,
  getCodeFromGeneralPurposeMap, createTyohyoCsv,
  getDataFromGeneralPurposeMap} from '../../../../common/common.js';
import MainContainer from '../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../atoms/CustomPart.js';
import CustomFlexGrid from '../../../molecules/CustomFlexGrid.js';
import {createSearchList, createSearchListEx} from '../../../../common/ComboParts.js';
import BackButton from '../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../atoms/PaperPart.js';

import {commonOperations} from '../../../../reducks/common';
import {attachorderOperations} from '../../../../reducks/attachorder';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import * as validateRule from '../../../validateRule.js';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  TextPosition: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    // left: "50%",
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '165px',
    minWidth: '165px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

/** 取得するフィールド名一覧 */
const GET_FIELDS = [
  'Id',
  'OrderDate__c',
  'OrderNo__c',
  'toLabel(KyogaType__c)KyogaType',
  'StbType__c',
  'toLabel(StbType__c)StbType',
  'KoziTitle__c',
  'KoziPlaceMainDntNo_SenroCode__c',
  'KoziPlaceMainDntNo_SenroName__c',
  'Dsp_KoziPlaceMainDntNo__c',
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefectures',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'ProprietyJudgeAnswerDate__c', // 可否判定回答日
  'ProprietyResultAcceptedDate__c',
  'KaHonsu__c',  // 可本数
  'ConditionalKaHonsu__c', // 条件付可本数
  'HiHonsu__c', // 否本数
  'ProprietyJudgeOrderNo__c',
  'DesignDocumentCreateIraiDate__c',
  'DesignIraiAcceptedDate__c',
  'DrSideKoziUmu__c',
  'toLabel(DrSideKoziUmu__c)DrSideKoziUmu',
  'KoziDoneIraiDate__c',
  'DrSideKoziRequiredPeriod__c',
  'DrSideKoziCompScheduleDate__c',
  'DrSideKoziCompletionDate__c',
  'KyogaKoziScheduleDate__c',
  'StartNotificationDate__c',
  'OrderUndoDate__c',
  'SyunkoReportRegisterDate__c',
  'SyunkoInspectionCompletionDate__c',
  'ContractNo__c',
  'OrderCategory__c',
  'toLabel(OrderCategory__c)OrderCategory',
  'OrderStatus__c',
  'toLabel(OrderStatus__c)OrderStatus',
  'toLabel(GaisanAmountStatus__c)GaisanAmountStatus',
  'AnswerUmu__c',
  'TrsyaUserId__c',
  'UpsyaUserId__c',
  'GsKoziStatus__c',
  'RecordType.DeveloperName', // レコードタイプ
];

/** ソートするフィールド名一覧 */
const SORT_FIELDS = {
  'OrderDate__c': '-1',
};

/** 本画面で取り扱う申込区分 */
const ACCEPT_ORDERCATEGORY = ['01', '02', '03', '05', '13', '14'];

/** Salesforce ドキュメント名（サブクエリ）: 契約電柱 */
const SUB_OBJECT_NAME = 'ContractDnts__r';

/** 取得するフィールド名一覧（サブクエリ） */
const SUB_GET_FIELDS = [
  'IskkCategory__c',
  'ResubmitNecessity__c',
  'AttachType__c',
];

/** 遷移先一覧 */
const MENUS = [
  {Prefix: 'A', Name: '可否判定申込内容確認', GmnId: '1002'},
  {Prefix: 'A', Name: '可否判定結果確認', GmnId: '1009'},
  {Prefix: 'A', Name: '本申込内容確認', GmnId: '1012'},
  {Prefix: 'A', Name: '改造工事費用算定結果確認', GmnId: '1112'},
  {Prefix: 'A', Name: '改造工事完了件名確認', GmnId: '1132'},
  {Prefix: 'A', Name: '開始通知内容', GmnId: '1141'},
  {Prefix: 'A', Name: '竣工届内容・結果確認', GmnId: '1027'},
  {Prefix: 'B', Name: '撤去竣工届内容・結果確認', GmnId: '1037'},
  {Prefix: 'D', Name: '改修依頼内容確認', GmnId: '1472'},
  {Prefix: 'D', Name: '改造工事費用算定結果確認', GmnId: '1482'},
  {Prefix: 'D', Name: '改造工事完了件名確認', GmnId: '1484'},
  {Prefix: 'D', Name: '着工可能通知内容', GmnId: '1485'},
  {Prefix: 'D', Name: '竣工届内容・結果確認', GmnId: '1487'},
  {Prefix: 'A', Name: '訂正取付申込内容確認', GmnId: '1602'},
  {Prefix: 'B', Name: '訂正撤去申込内容確認', GmnId: '1612'},
];

/**
 * 工程進捗検索
 * 画面ID:1501
 */
class ProcessProgressSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisabled: true,
      csvBtnDisabled: true,
      menuList: [{Name: '', GmnId: ''}],
    };
    this.isDisabled = false;
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // FlexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1501', null);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 最大件数チェック
   * @param {number} listCount チェック対象件数
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = (listCount) => {
    const {generalPurposeMap} = this.props;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      const {values} = this.props;

      // 検索条件設定
      const appConditions = this.createConditions(values);
      const appFields = GET_FIELDS;
      const appSortParams = SORT_FIELDS;
      let subQuery = [{'table': SUB_OBJECT_NAME, 'fields': SUB_GET_FIELDS}];
      if (values) {
        if ('AttachType__c' in values) {
          const conditions = {'AttachType__c': values.AttachType__c};
          subQuery = [{'table': SUB_OBJECT_NAME, 'fields': SUB_GET_FIELDS, 'conditions': conditions}];
        }
      }

      // 共架処理状況取得
      const response = await this.props.doGetOrderTypeList(
          appConditions, appFields, appSortParams,
          'getProcessStateSubEx', subQuery, '');
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }
      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1501', 'Id', true);
        this.gridRef.current.setItems(this.props.orderList);
      }

      // 最大件数チェック用件数
      let listCount = 0;
      if (!this.props.hasOwnProperty('orderList') ||
          !this.props.orderList) {
        listCount = this.props.orderList.length;
      }
      if (response.hasOwnProperty('data') &&
          response.data.hasOwnProperty('body') &&
          response.data.body.hasOwnProperty('LimitCheckCount')) {
        listCount = response.data.body.LimitCheckCount;
      }

      // 検索結果0件の場合、CSVボタン非活性 & エラーダイアログ表示
      if (listCount == 0) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
        this.setState({btnDisabled: true});
        this.setState({csvBtnDisabled: true});
      // 検索結果1件以上の場合、各ボタン活性
      } else {
        // 最大件数チェック
        this.listMaxCountCheck(listCount);
        this.setState({btnDisabled: true});
        this.setState({csvBtnDisabled: false});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 検索条件生成
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
  */
  createConditions = (values) => {
    let addOrderCategory = [];
    let isNotSearch = false;
    // 画面入力されたデータを検索対象として取得
    const wkData = values ?
        JSON.parse(JSON.stringify(values)): {};
    const conditions = wkData;

    // 検索条件未指定の場合、処理を抜ける
    if (!values) {
      return conditions;
    }

    // フッターの項目を削除
    if ('SelectedMenu' in conditions) {
      delete conditions.SelectedMenu;
    }

    // 取付種別の削除（subQueryへ設定）
    if ('AttachType__c' in conditions) {
      delete conditions.AttachType__c;
    }

    // 事業所の設定
    if ('NWZgsyo__r_Code__c' in conditions) {
      conditions['NWZgsyo__r.Code__c'] = conditions.NWZgsyo__r_Code__c;
      delete conditions.NWZgsyo__r_Code__c;
    }

    // 申込ステータスの設定
    if (conditions.hasOwnProperty('OrderStatus__c')) {
      const OrderStatus = conditions.OrderStatus__c;
      conditions['OrderStatus__c'] = [OrderStatus];
    } else {
      conditions['OrderStatus__c'] = [];
    }
    // 可否判定／状況：受付未＝0の場合:可否判定受付中(01)、受付済＝1の場合:強度計算中以降(02,03,04,05,90,91,92,93)を申込ステータスの対象とする。
    if (conditions.hasOwnProperty('ProprietyJudgeOrderStatus')) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '01') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('01'); // 可否判定
      if (values.ProprietyJudgeOrderStatus == '0') {
        conditions['OrderStatus__c'].push('01');
      } else if (values.ProprietyJudgeOrderStatus == '1') {
        conditions['OrderStatus__c'].push('02', '03', '04', '05', '90', '91', '92', '93');
      }
      delete conditions.ProprietyJudgeOrderStatus;
    }
    // 可否判定／実施状況：可否判定中＝0の場合:可否判定中(03)、可否判定済＝1の場合:可否判定済(04)、の申込ステータスの対象とする。
    if (conditions.hasOwnProperty('ProprietyJudgeAnswerStatus')) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '01') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('01'); // 可否判定
      if (values.ProprietyJudgeAnswerStatus == '0') {
        conditions['OrderStatus__c'].push('03');
      } else if (values.ProprietyJudgeAnswerStatus == '1') {
        conditions['OrderStatus__c'].push('04');
      }
      delete conditions.ProprietyJudgeAnswerStatus;
    }
    // 本申込／状況：受付未＝0の場合:本申込受付中(10)、受付済み＝1の場合:本申込受付済以降(12,13,14,15,16,20,21,31,40,41,90,91,92)を対象とする。
    if (conditions.hasOwnProperty('OrderStatus')) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '02') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('02'); // 本申込
      if (values.OrderStatus == '0') {
        conditions['OrderStatus__c'].push('10');
      } else if (values.OrderStatus == '1') {
        conditions['OrderStatus__c'].push('12', '13', '14', '15', '16', '20', '21', '31', '40', '41', '90', '91', '92');
      }
      delete conditions.OrderStatus;
    }
    // 可否判定／申込年月日
    if ((conditions.hasOwnProperty('ProprietyJudgeOrderDate__c_From') && conditions.ProprietyJudgeOrderDate__c_From) ||
        (conditions.hasOwnProperty('ProprietyJudgeOrderDate__c_To') && conditions.ProprietyJudgeOrderDate__c_To)) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '01') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('01'); // 可否判定
    }
    // 可否判定／回答年月日
    if ((conditions.hasOwnProperty('ProprietyJudgeAnswerDate__c_From') && conditions.ProprietyJudgeAnswerDate__c_From) ||
        (conditions.hasOwnProperty('ProprietyJudgeAnswerDate__c_To') && conditions.ProprietyJudgeAnswerDate__c_To)) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '01') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('01'); // 可否判定
    }
    // 本申込／受付日
    if ((conditions.hasOwnProperty('OrderDate__c_From') && conditions.OrderDate__c_From) ||
        (conditions.hasOwnProperty('OrderDate__c_To') && conditions.OrderDate__c_To)) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '02') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('02'); // 本申込
    }
    // 開始(着工可能)通知日
    if ((conditions.hasOwnProperty('StartNotificationDate__c_From') && conditions.StartNotificationDate__c_From) ||
        (conditions.hasOwnProperty('StartNotificationDate__c_To') && conditions.StartNotificationDate__c_To)) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '02' && oc != '05') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('02', '05'); // 本申込、改修依頼
    }
    // 開始通知／状況：未＝0の場合:共架料算定中以前(06,10,11,12,13,14,15,16,20,21,31)、開始通知済＝1の場合:＝竣工待ち／工事開始可以降(40,41,90,91,92)を対象とする。
    if (conditions.hasOwnProperty('StartNotificationStatus')) {
      if (addOrderCategory.length > 0) {
        for (const oc of addOrderCategory) {
          if (oc != '02' && oc != '05') {
            isNotSearch = true;
            break;
          }
        }
      }
      addOrderCategory.push('02', '05'); // 本申込、改修依頼
      if (values.StartNotificationStatus == '0') {
        conditions['OrderStatus__c'].push('06', '10', '11', '12', '13', '14', '15', '16', '20', '21', '31');
      } else if (values.StartNotificationStatus == '1') {
        conditions['OrderStatus__c'].push('40', '41', '90', '91', '92');
      }
      delete conditions.StartNotificationStatus;
    }
    // 竣工届／状況：未＝0の場合:竣工待ち／工事日開始可(40)、済＝1の場合:竣工確認中以降(41,90,91,92)を対象とする。
    if (conditions.hasOwnProperty('SyunkoReportRegisterStatus')) {
      if (values.SyunkoReportRegisterStatus == '0') {
        conditions['OrderStatus__c'].push('40');
      } else if (values.SyunkoReportRegisterStatus == '1') {
        conditions['OrderStatus__c'].push('41', '90', '91', '92');
      }
      delete conditions.SyunkoReportRegisterStatus;
    }
    // 竣工確認結果／状況：未＝0の場合:受付済(12)、再提出要＝1の場合:竣工待ち／工事開始可(40)かつ契約電柱の再提出要否に要が１つ以上、再提出不要＝2の場合:完結(90)を対象とする。
    if (conditions.hasOwnProperty('OProprietyResultAcceptedStatus')) {
      if (values.OProprietyResultAcceptedStatus == '0') {
        conditions['OrderStatus__c'].push('12');
      } else if (values.OProprietyResultAcceptedStatus == '1') {
        conditions['OrderStatus__c'].push('40');
      } else if (values.OProprietyResultAcceptedStatus == '2') {
        conditions['OrderStatus__c'].push('90');
      }
      // delete conditions.OProprietyResultAcceptedStatus;
    }

    // 申込区分の設定
    if (!conditions.hasOwnProperty('OrderCategory__c') || !conditions.OrderCategory__c) {
      if (addOrderCategory.length > 0) {
        conditions['OrderCategory__c'] = addOrderCategory;
      } else {
        conditions['OrderCategory__c'] = ACCEPT_ORDERCATEGORY;
      }
    } else {
      if (addOrderCategory.length > 0 &&
          !addOrderCategory.includes(conditions.OrderCategory__c)) {
        isNotSearch = true;
      }
    }

    // 申込区分に矛盾がある場合
    if (isNotSearch) {
      // 存在しない申込番号を指定することで、該当無しとする
      conditions['OrderNo__c'] = '!';
    }

    return conditions;
  }

  /**
   * ボタンの活性、非活性化
   */
  checkStateUpdate = () => {
    try {
      // 未選択／単一選択チェック
      let bRet = false;
      if (this.gridRef && this.gridRef.current) {
        if (this.gridRef.current.selectedItemCount() == 0) {
          bRet = true;
        } else if (this.gridRef.current.selectedItemCount() == 1) {
          bRet = false;
        } else {
          bRet = true;
        }
      }
      // リスト更新
      let list = [];
      if (this.gridRef && this.gridRef.current &&
          this.gridRef.current.selectedItemCount() == 1) {
        const gmnList = this.checkProcess();
        for (const menu of MENUS) {
          if (gmnList.indexOf(menu.GmnId) !== -1) {
            list.push({Name: menu.Name, GmnId: menu.GmnId});
          }
        }
      }
      if (list.length == 0) {
        bRet = true;
      }

      delete this.props.values.SelectedMenu;
      this.setState({btnDisabled: bRet, menuList: list});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 処理不可チェック
   * （申込が表示対象であるか、申込区分と申込ステータによりチェック）
   * @return {Array} [gmnList] 遷移先画面リスト
   */
  checkProcess = () => {
    try {
      let gmnList = [];
      let checkStatus = [];
      let checkStatus1 = [];
      let checkStatus2 = [];
      const category =
          this.gridRef.current.selectedItems[0].dataItem.OrderCategory__c;
      const status =
          this.gridRef.current.selectedItems[0].dataItem.OrderStatus__c;
      const contractNo =
          this.gridRef.current.selectedItems[0].dataItem.ContractNo__c;
      const designDocumentCreateIraiDate =
          this.gridRef.current.selectedItems[0].dataItem.
              DesignDocumentCreateIraiDate__c;
      const drSideKoziUmu =
          this.gridRef.current.selectedItems[0].dataItem.DrSideKoziUmu__c;
      const koziScheduleDate =
          this.gridRef.current.selectedItems[0].
              dataItem.KyogaKoziScheduleDate__c;

      // 可否判定申込内容確認
      checkStatus = ['00', '01', '02', '03', '04', '05', '90', '91', '92', '93'];
      if ((category == '01' && checkStatus.indexOf(status) !== -1)) {
        gmnList.push('1002');
      }

      // 可否判定結果確認
      checkStatus = ['04', '05', '91', '93'];
      if ((category == '01' && checkStatus.indexOf(status) !== -1)) {
        gmnList.push('1009');
      }

      // 本申込内容確認
      checkStatus = ['00', '10', '12', '13', '14', '15', '16', '20', '21', '31', '40', '41', '90', '91'];
      if (category == '02' && checkStatus.indexOf(status) !== -1) {
        gmnList.push('1012');
      }

      // 概算費用結果確認
      checkStatus1 = ['15', '16', '20', '21', '31', '40', '41'];
      if (category == '02' && checkStatus1.indexOf(status) !== -1 &&
          !isNullOrWhiteSpace(designDocumentCreateIraiDate) ) {
        gmnList.push('1112');
      }
      checkStatus2 = ['15', '16', '20', '21', '31', '40', '41'];
      if (category == '05' && checkStatus2.indexOf(status) !== -1 &&
          !isNullOrWhiteSpace(designDocumentCreateIraiDate)) {
        gmnList.push('1482');
      }

      // 工事完了件名確認
      checkStatus1 = ['31', '40', '41'];
      if (category == '02' && checkStatus1.indexOf(status) !== -1 &&
          drSideKoziUmu == '1') {
        gmnList.push('1132');
      }
      checkStatus2 = ['31', '40', '41'];
      if (category == '05' && checkStatus2.indexOf(status) !== -1 &&
          drSideKoziUmu == '1') {
        gmnList.push('1484');
      }

      // 開始通知内容
      checkStatus1 = ['40', '41'];
      if (category == '02' && checkStatus1.indexOf(status) !== -1) {
        gmnList.push('1141');
      }
      checkStatus2 = ['40', '41'];
      if (category == '05' && checkStatus2.indexOf(status) !== -1) {
        gmnList.push('1485');
      }

      // 竣工届内容・検査結果確認
      checkStatus1 = ['40', '41', '90'];
      if (category == '02' && checkStatus1.indexOf(status) !== -1 &&
          isNullOrWhiteSpace(contractNo) &&
          !isNullOrWhiteSpace(koziScheduleDate)) {
        gmnList.push('1027');
      }
      checkStatus2 = ['40', '41', '90'];
      if (category == '05' && checkStatus2.indexOf(status) !== -1 &&
          !isNullOrWhiteSpace(koziScheduleDate)) {
        gmnList.push('1487');
      }

      // 撤去竣工届内容・結果確認
      checkStatus = ['00', '41', '90'];
      if (((category == '03' && checkStatus.indexOf(status) !== -1))) {
        gmnList.push('1037');
      }

      // 改修依頼内容確認
      checkStatus = ['06', '10', '11', '12', '13', '14', '15', '16', '20', '21', '31', '40', '41', '90'];
      if ((category == '05' && checkStatus.indexOf(status) !== -1)) {
        gmnList.push('1472');
      }

      // 訂正申込内容確認
      checkStatus = ['00', '10', '12', '90'];
      if ((category == '13' && checkStatus.indexOf(status) !== -1)) {
        gmnList.push('1602');
      }

      // 訂正申込撤去内容確認
      checkStatus = ['00', '41', '90'];
      if ((category == '14' && checkStatus.indexOf(status) !== -1)) {
        gmnList.push('1612');
      }

      return gmnList;
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 可否判定申込内容確認ボタン押下時
   */
  doNext1002 = async () => {
    try {
      // 可否判定申込基本情報入力画面(1002)へ遷移
      const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
      const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.doSetStbType(items[0].dataItem.StbType__c);
      if (items[0].dataItem.StbType__c == stbTypeSen) {
        this.props.history.push({pathname: '/SenProprietyBasicInfo'});
      }
      if (items[0].dataItem.StbType__c == stbTypeTen) {
        this.props.history.push({pathname: '/TenProprietyBasicInfo'});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
    * 可否判定結果確認ボタン押下時
    */
  doNext1009 = async () => {
    try {
      // 可否判定結果明細一覧画面(1009)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/ProprietyResultDetail'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 本申込内容確認ボタン押下時
     */
  doNext1012 = async () => {
    try {
      // 本申込詳細入力画面(1012)へ遷移
      const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
      const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id); // 可否判定申込または本申込のId
      if (items[0].dataItem.StbType__c == stbTypeSen) {
        this.props.history.push({pathname: '/SenFormalOrderBasicInfo'});
      }
      if (items[0].dataItem.StbType__c == stbTypeTen) {
        this.props.history.push({pathname: '/TenFormalOrderBasicInfo'});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 概算費用結果確認ボタン押下時
     */
  doNext1112 = async () => {
    try {
      // 概算費用結果確認画面(1112)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/AttachOrder/AttachOrder/GaisanCostCalculationResultConfirm/Confirm'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 改修依頼概算費用結果確認ボタン押下時
     */
  doNext1482 = async () => {
    try {
      // 概算費用結果確認画面(1482)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/RepairIrai/KoziDone/GaisanCostCalculationResultConfirm/Confirm'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 工事完了件名確認ボタン押下時
     */
  doNext1132 = async () => {
    try {
      // 工事完了件名確認画面(1132)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/Confirm'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 改修依頼工事完了件名確認ボタン押下時
     */
  doNext1484 = async () => {
    try {
      // 工事完了件名確認画面(1484)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/RepairIrai/KoziDone/KoziCostPayOffContactReceive/Confirm'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 開始通知内容ボタン押下時
     */
  doNext1141 = async () => {
    try {
      // 開始通知一覧画面(1141)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderNo(items[0].dataItem.OrderNo__c);
      this.props.doSetYbdsPath(this.props.history.location.pathname);
      this.props.history.push({pathname: '/kyogaStartNotificationList'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 改修依頼開始通知内容ボタン押下時
     */
  doNext1485 = async () => {
    try {
      // 開始通知一覧画面(1485)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderNo(items[0].dataItem.OrderNo__c);
      this.props.doSetYbdsPath(this.props.history.location.pathname);
      this.props.history.push({pathname: '/RepairIrai/KoziDone/StartNotification'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 竣工届内容・検査結果確認ボタン押下時
     */
  doNext1027 = async () => {
    try {
      // 竣工届登録画面(1027)へ遷移
      let equipStr = '線設備';
      const selectedItems = this.gridRef.current.selectedItem();
      if (selectedItems && selectedItems.length > 0) {
        equipStr = selectedItems[0].dataItem.StbType;
      }
      this.props.doSetOrderId(selectedItems[0].dataItem.Id);
      if (equipStr == '点設備') {
        this.props.history.push({pathname: '/SyunkoReportTenOrderDnt', equip: equipStr});
      } else {
        this.props.history.push({pathname: '/SyunkoReportSenOrderDnt', equip: equipStr});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 撤去竣工届内容・結果確認ボタン押下時
     */
  doNext1037 = async () => {
    try {
      // 撤去竣工届登録画面(1037)へ遷移
      const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
      const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.doSetStbType(items[0].dataItem.StbType__c);
      if (items[0].dataItem.StbType__c == stbTypeSen) {
        this.props.history.push({pathname: '/SenTekyoSyunkoReportBasicInfo', equip: '線設備'});
      }
      if (items[0].dataItem.StbType__c == stbTypeTen) {
        this.props.history.push({pathname: '/TenTekyoSyunkoReportBasicInfo', equip: '点設備'});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 改修依頼竣工届内容・結果確認ボタン押下時
     */
  doNext1487 = async () => {
    try {
      // 竣工届登録画面(1487)へ遷移
      let equipStr = '線設備';
      const selectedItems = this.gridRef.current.selectedItem();
      if (selectedItems && selectedItems.length > 0) {
        equipStr = selectedItems[0].dataItem.StbType;
      }
      this.props.doSetOrderId(selectedItems[0].dataItem.Id);
      if (equipStr == '点設備') {
        this.props.history.push({pathname: '/RepairIraiSyunkoReportTenOrderDnt', equip: equipStr});
      } else {
        this.props.history.push({pathname: '/RepairIraiSyunkoReportSenOrderDnt', equip: equipStr});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 改修依頼内容確認ボタン押下時
     */
  doNext1472 = async () => {
    try {
      // 改修依頼内容画面(1472)へ遷移
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/RepairIrai/ConfirmIraiContent/Confirm'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
     * 訂正申込内容確認ボタン押下時
     */
   doNext1602 = async () => {
     try {
       // 訂正取付申込基本情報入力画面(1602)へ遷移
       const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
       const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
       const items = this.gridRef.current.selectedItem();
       this.props.doSetOrderId(items[0].dataItem.Id);
       this.props.doSetStbType(items[0].dataItem.StbType__c);
       if (items[0].dataItem.StbType__c == stbTypeSen) {
         this.props.history.push({pathname: '/SenCorrectAttachBasicInfo'});
       }
       if (items[0].dataItem.StbType__c == stbTypeTen) {
         this.props.history.push({pathname: '/TenCorrectAttachBasicInfo'});
       }
     } catch (error) {
       console.dir(error);
       this.sysErr();
     }
   }

   /**
     * 訂正申込撤去内容確認ボタン押下時
     */
   doNext1612 = async () => {
     try {
       // 契約相違撤去基本情報入力画面(1612)へ遷移
       const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
       const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
       const items = this.gridRef.current.selectedItem();
       this.props.doSetOrderId(items[0].dataItem.Id);
       this.props.doSetStbType(items[0].dataItem.StbType__c);
       if (items[0].dataItem.StbType__c == stbTypeSen) {
         this.props.history.push({pathname: '/SenCorrectTekyoBasicInfo', equip: '線設備'});
       }
       if (items[0].dataItem.StbType__c == stbTypeTen) {
         this.props.history.push({pathname: '/TenCorrectTekyoBasicInfo', equip: '点設備'});
       }
     } catch (error) {
       console.dir(error);
       this.sysErr();
     }
   }

   /**
    * メニュー選択
    */
   doSelectMenu = () => {
     try {
       const {values} = this.props;

       if (!this.gridRef ||
        !this.gridRef.current) {
         return;
       }
       const count = this.gridRef.current.selectedItemCount();
       if (count != 1) {
         this.props.doShowMessage({
           message: {
             id: 'CE0022',
           },
         });
         return;
       }

       let gmnId = '';
       if ('SelectedMenu' in values) {
         gmnId = values.SelectedMenu;
       }
       if (gmnId == '1002') { // 可否判定申込内容確認
         this.doNext1002();
       } else if (gmnId == '1009') {// 可否判定結果確認
         this.doNext1009();
       } else if (gmnId == '1012') {// 本申込内容確認
         this.doNext1012();
       } else if (gmnId == '1112') {// 概算費用結果確認
         this.doNext1112();
       } else if (gmnId == '1132') {// 工事完了件名確認
         this.doNext1132();
       } else if (gmnId == '1141') {// 開始通知内容
         this.doNext1141();
       } else if (gmnId == '1027') {// 竣工届内容・検査結果確認
         this.doNext1027();
       } else if (gmnId == '1037') {// 撤去竣工届内容・結果確認
         this.doNext1037();
       } else if (gmnId == '1472') {// 改修依頼内容確認
         this.doNext1472();
       } else if (gmnId == '1482') {// 改修依頼概算費用結果確認
         this.doNext1482();
       } else if (gmnId == '1484') {// 改修依頼工事完了件名確認
         this.doNext1484();
       } else if (gmnId == '1485') {// 改修依頼開始通知内容
         this.doNext1485();
       } else if (gmnId == '1487') {// 改修依頼竣工届内容・検査結果確認
         this.doNext1487();
       } else if (gmnId == '1602') {// 訂正申込内容確認
         this.doNext1602();
       } else if (gmnId == '1612') {// 訂正申込撤去内容確認
         this.doNext1612();
       } else {
         this.props.doShowMessage({
           message: {
             id: 'CE0027',
             values: ['次画面遷移先'],
           },
         });
         return;
       }
     } catch (error) {
       console.dir(error);
       this.sysErr();
     }
   }

   /**
   * ダウンロードボタン押下時
   */
   doDownload = async () => {
     try {
       const {values} = this.props;

       // 検索条件設定
       const appConditions = this.createConditions(values);

       let appSubQueryConditions = null;
       if (values) {
         if ('AttachType__c' in values) {
           const conditions = {'AttachType__c': values.AttachType__c};
           appSubQueryConditions = [{'conditions': conditions}];
         }
       }

       // 帳票(CSV)の作成（C0001_共架処理状況）
       const ret = await createTyohyoCsv('kyogaProcessState', appConditions, '共架処理状況.csv', appSubQueryConditions);
       if (!ret) {
         this.sysErr();
         return;
       }
     } catch (error) {
       console.dir(error);
       this.sysErr();
     }
   }

   /**
   * 終了処理
   */
   async componentWillUnmount() {
     this.props.reset();
     this.props.doClearOrderList();
     if (this.gridRef && this.gridRef.current) {
       this.gridRef.current.saveScreenState('1501', 'Id');
       this.gridRef.current.setItems(null);
     }
   }

   /**
   * システムエラー
   */
   sysErr = () => {
     this.props.doShowMessage({
       message: 'CS0001',
       action: () => {
         this.props.history.push('/login');
       },
     });
   }

   /**
    * 一覧表出力
    * @return {CustomFlexGrid} 一覧表
    */
   renderTable() {
     const props = {
       rowHeaderType: 'check',
       filterOn: true,
       headersVisibility: 'All',
       allowDragging: 'None',
       counterOn: false,
       style: {maxHeight: '400px'},
       isReadOnly: true,
       checkedFunction: this.checkStateUpdate,
     };

     return (
       <CustomFlexGrid ref={this.gridRef} {...props}>
         {this.isDisabled &&
           <FlexGridColumnGroup binding='OrderCategory__c' header='OrderCategory__c' dataType="String"></FlexGridColumnGroup>
         }
         {this.isDisabled &&
           <FlexGridColumnGroup binding='OrderStatus__c' header='OrderStatus__c' dataType="String"></FlexGridColumnGroup>
         }
         {this.isDisabled &&
         <FlexGridColumnGroup binding='OrderDate__c' header='OrderDate__c' dataType="Date">
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.OrderDate__c);
             }}
           />
         </FlexGridColumnGroup>
         }
         <FlexGridColumnGroup binding='OrderNo__c' header='申込番号' dataType="String"></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='OrderCategory' header='申込区分' dataType="String"></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='StbType' header='設備種別' dataType="String" width={100}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='KyogaType' header='共架種別' dataType="String"></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='KoziTitle__c' header='工事件名' dataType="String"></FlexGridColumnGroup>
         <FlexGridColumnGroup header='工事場所' align="center">
           <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
           <FlexGridColumnGroup binding='Dsp_KoziPlaceMainDntNo__c' header='代表電柱番号' dataType="String"/>
           <FlexGridColumnGroup binding='KoziPlacePrefectures' header='都道府県' dataType="String" width={100}/>
           <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType="String"/>
           <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType="String"/>
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='ProprietyJudgeAnswerDate__c' header='可否判定\n回答日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.ProprietyJudgeAnswerDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='ProprietyResultAcceptedDate__c' header='可否判定結果\n受理日' dataType="Date" width={130}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.ProprietyResultAcceptedDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding="KaHonsu__c" header="可本数" dataType="Number" width={100}/>
         <FlexGridColumnGroup binding="ConditionalKaHonsu__c" header="条件付\n可本数" dataType="Number" width={100}/>
         <FlexGridColumnGroup binding="HiHonsu__c" header="否本数" dataType="Number" width={100}/>
         <FlexGridColumnGroup binding='ProprietyJudgeOrderNo__c' header='可否判定申込番号' dataType="String" width={170}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='DesignDocumentCreateIraiDate__c' header='設計書作成\n依頼日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.DesignDocumentCreateIraiDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='DesignIraiAcceptedDate__c' header='設計依頼\n受理日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.DesignIraiAcceptedDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='DrSideKoziUmu' header='改造工事\n有無' cssClass="centerPos" dataType="String" width={100}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='KoziDoneIraiDate__c' header='改造工事\n依頼日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.KoziDoneIraiDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='DrSideKoziRequiredPeriod__c' header='改造工事期間(目安)\n〔か月〕' dataType="Number" format="n1" width={165}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='DrSideKoziCompScheduleDate__c' header='改造工事\n完了予定日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.DrSideKoziCompScheduleDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='DrSideKoziCompletionDate__c' header='改造工事\n完了日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.DrSideKoziCompletionDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='KyogaKoziScheduleDate__c' header='共架工事\n予定日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.KyogaKoziScheduleDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='StartNotificationDate__c' header='共架開始(着工可能)\n通知日' dataType="Date" width={170}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.StartNotificationDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='IskkCategory' header='一束化\n有無' cssClass="centerPos" dataType="String" width={100}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='OrderUndoDate__c' header='申込取止日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.OrderUndoDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='SyunkoReportRegisterDate__c' header='竣工届\n提出日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.SyunkoReportRegisterDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='SyunkoInspectionCompletionDate__c' header='確認結果\n回答日' dataType="Date" width={110}>
           <FlexGridCellTemplate cellType="Cell"
             template= {(context) => {
               return changeDateFormat(
                   context.item.SyunkoInspectionCompletionDate__c);
             }}
           />
         </FlexGridColumnGroup>
         <FlexGridColumnGroup binding='ResubmitNecessity' header='再提出\n要否' cssClass="centerPos" dataType="String" width={100}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='ContractNo__c' header='契約書番号' dataType="String"></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='OrderStatus' header='申込ステータス' dataType="String"></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='GaisanAmountStatus' header='改造工事費用ステータス' dataType="String" width={135}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='TrsyaUserId__c' header='件名作成ユーザID' dataType="String" width={175}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='TrsyaUserName' header='件名作成ユーザ名' dataType="String" width={175}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='UpsyaUserId__c' header='更新共架者ユーザID' dataType="String" width={175}></FlexGridColumnGroup>
         <FlexGridColumnGroup binding='UpsyaUserName' header='更新共架者ユーザ名' dataType="String" width={175}></FlexGridColumnGroup>
       </CustomFlexGrid>
     );
   }

   render() {
     const {classes, generalPurposeMap, reset,
       handleSubmit, userInfo} = this.props;

     const footerBtn = (
       <Grid
         container
         justifyContent="center"
         alignItems="center"
         spacing={1}
       >
         <Grid key="btn1501MenuTitle" item>
           <span style={{fontSize: '1.2em', color: 'white'}}>
             次画面遷移先
           </span>
         </Grid>
         <Grid key="btn1501MenuComb" item>
           <Field
             id="id1501MenuComb"
             name="SelectedMenu"
             style={{width: '300px', backgroundColor: 'white', border: '2px solid'}}
             component={renderSelect}
             fullWidth
             required={false}
             validate={[
             ]}
             disabled={this.state.btnDisabled}
           >
             {createSearchList(this.state.menuList, 'GmnId', 'Name', null, 'false')}
           </Field>
         </Grid>
         <Grid key="btn1501SelectMenu" item>
           <PositiveButton
             id="id1501SelectMenu"
             onClick={handleSubmit(this.doSelectMenu)}
             variant="contained"
             size="large"
             type="button"
             disabled={this.state.btnDisabled}
           >
             <span>次画面へ</span>
           </PositiveButton>
         </Grid>
         <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
         {/* ダウンロード */}
         <Grid key="btnDownload" item>
           <PositiveButton
             id="btnDownload"
             onClick={() => {
               this.doDownload();
             }}
             variant="contained"
             startIcon={<ArrowDownwardIcon />}
             size="large"
             disabled={this.state.csvBtnDisabled}
           >
             <span>ダウンロード</span>
           </PositiveButton>
         </Grid>

         {/* 戻る */}
         <Grid key="btnBack" item>
           <BackButton props={this.props}/>
         </Grid>
       </Grid>
     );

     return (
       <form noValidate autoComplete="off" className={classes.root}>
         <MainContainer
           props={this.props}
           footerBtn={footerBtn}>

           <PaperPart>
             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key1-1" item xs={12} sm={2}>
                 <Field
                   id="OrderNo__c"
                   name="OrderNo__c"
                   className={classes.fields}
                   component={renderTextField}
                   label="申込番号"
                   fullWidth
                   type="text"
                   required={false}
                   validate={[
                     validateRule.alphaNumericSymbol,
                     validateRule.maxLength(12),
                   ]}
                 />
               </Grid>
               <Grid key="key1-1-1" item xs={12} sm={2}>
                 <Field
                   id="OrderCategory__c"
                   name="OrderCategory__c"
                   className={classes.fields}
                   component={renderSelect}
                   label="申込区分"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                    createSearchList(generalPurposeMap.OrderCategory, 'Code__c', 'Name', {'Code__c': ACCEPT_ORDERCATEGORY}, false)}
                 </Field>
               </Grid>
               <Grid key="key1-2" item xs={12} sm={2}>
                 <Field
                   id="AttachType__c"
                   name="AttachType__c"
                   className={classes.fields}
                   component={renderSelect}
                   label="取付種別"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                    createSearchList(generalPurposeMap.AttachType, 'Code__c', 'Name', {Code__c: ['1', '2', '3']})}
                 </Field>
               </Grid>
               <Grid key="key1-3" item xs={12} sm={2}>
                 <Field
                   id="NWZgsyo__r_Code__c"
                   name="NWZgsyo__r_Code__c"
                   className={classes.fields}
                   component={renderSelect}
                   label="事業所"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                     createSearchListEx(generalPurposeMap.NWZgsyo, 'Code__c', 'Name', null, {Code__c: ['1001', '1065']})}
                 </Field>
               </Grid>
               <Grid key="key1-4" item xs={12} sm={2}>
                 <Field
                   id="StbType__c"
                   name="StbType__c"
                   component={renderSelect}
                   className={classes.fields}
                   label="設備種別"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                 </Field>
               </Grid>
               <Grid key="key1-5" item xs={12} sm={2}>
                 <Field
                   id="KyogaType__c"
                   name="KyogaType__c"
                   className={classes.fields}
                   component={renderSelect}
                   label="共架種別"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                 </Field>
               </Grid>
             </Grid>
             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key1-6" item xs={12} sm={2}>
                 <Field
                   id="KoziPlaceMainDntNo_SenroName__c"
                   name="KoziPlaceMainDntNo_SenroName__c"
                   className={classes.fields}
                   component={renderTextField}
                   label="線路名"
                   fullWidth
                   type="text"
                   required={false}
                   validate={[
                     validateRule.maxLength(30),
                   ]}
                 />
               </Grid>
               <Grid key="key1-7" item xs={12} sm={2}>
                 <Field
                   id="Dsp_KoziPlaceMainDntNo__c"
                   name="Dsp_KoziPlaceMainDntNo__c"
                   className={classes.fields}
                   component={renderTextField}
                   label="代表電柱番号"
                   fullWidth
                   type="text"
                   required={false}
                   validate={[
                     validateRule.maxLength(23),
                   ]}
                 />
               </Grid>
               <Grid key="key2-1" item xs={12} sm={4}>
                 <Field
                   id="KoziTitle__c"
                   name="KoziTitle__c"
                   className={classes.fields}
                   component={renderTextField}
                   label="工事件名"
                   fullWidth
                   type="text"
                   required={false}
                   validate={[
                     validateRule.maxLength(50),
                   ]}
                 />
               </Grid>
               <Grid key="key2-2" item xs={12} sm={2}>
                 <Field
                   id="DrSideKoziUmu__c"
                   name="DrSideKoziUmu__c"
                   className={classes.fields}
                   component={renderSelect}
                   label="改造工事有無"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                    createSearchList(generalPurposeMap.UmuCategory)}
                 </Field>
               </Grid>
               <Grid key="key2-3" item xs={12} sm={2}>
                 <Field
                   id="OrderStatus__c"
                   name="OrderStatus__c"
                   component={renderSelect}
                   className={classes.fields}
                   label="ステータス"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                       createSearchList(generalPurposeMap.OrderStatus)}
                 </Field>
               </Grid>
             </Grid>

             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key3-1" item xs={12} sm={4}>
                 <Field
                   id="KoziPlacePrefectures__c"
                   name="KoziPlacePrefectures__c"
                   component={renderSelect}
                   className={classes.fields}
                   label="工事場所／都道府県"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                      createSearchList(generalPurposeMap.PrefCode)}
                 </Field>
               </Grid>
               <Grid key="key3-2" item xs={12} sm={4}>
                 <Field
                   id="KoziPlaceMunicipalities__c"
                   name="KoziPlaceMunicipalities__c"
                   component={renderTextField}
                   className={classes.fields}
                   label="工事場所／市区町村"
                   type="text"
                   fullWidth
                   required={false}
                   validate={[
                     validateRule.maxLength(12),
                   ]}
                 />
               </Grid>
               <Grid key="key3-3" item xs={12} sm={4}>
                 <Field
                   id="KoziPlacePlace__c"
                   name="KoziPlacePlace__c"
                   component={renderTextField}
                   className={classes.fields}
                   label="工事場所／場所"
                   type="text"
                   fullWidth
                   required={false}
                   validate={[
                     validateRule.maxLength(255),
                   ]}
                 />
               </Grid>
             </Grid>

             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key='key-4-1' item xs={12} sm={3}>
                 <Field
                   id="ContractNo__c"
                   name="ContractNo__c"
                   label="契約書番号"
                   className={classes.fields}
                   component={renderTextField}
                   fullWidth
                   type="text"
                   required={false}
                   validate={[
                     validateRule.alphaNumericSymbol,
                     validateRule.maxLength(20),
                   ]}
                 />
               </Grid>
             </Grid>

             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key-5-1" item xs={12} sm>
                 <div style={{position: 'relative'}}>
                   <Field
                     id="ProprietyJudgeOrderDate__c_From"
                     name="ProprietyJudgeOrderDate__c_From"
                     accessibilitylabel="ProprietyJudgeOrderDate__c_From"
                     label="可否判定／申込年月日"
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                   <span className={classes.wave}>～</span>
                   <Field
                     id="ProprietyJudgeOrderDate__c_To"
                     name="ProprietyJudgeOrderDate__c_To"
                     accessibilitylabel="ProprietyJudgeOrderDate__c_To"
                     label={<React.Fragment />}
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                 </div>
               </Grid>
               <Grid key="key5-2" item xs={12} sm={2}>
                 <Field
                   id="ProprietyJudgeOrderStatus"
                   name="ProprietyJudgeOrderStatus"
                   className={classes.fields}
                   component={renderSelect}
                   label="可否判定／状況"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   <MenuItem value="">&nbsp;</MenuItem>
                   <MenuItem value={0}>受付未</MenuItem>
                   <MenuItem value={1}>受付済</MenuItem>
                 </Field>
               </Grid>
               <Grid key="key-5-3" item xs={12} sm>
                 <div style={{position: 'relative'}}>
                   <Field
                     id="ProprietyJudgeAnswerDate__c_From"
                     name="ProprietyJudgeAnswerDate__c_From"
                     accessibilitylabel="ProprietyJudgeAnswerDate__c_From"
                     label="可否判定／回答年月日"
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                   <span className={classes.wave}>～</span>
                   <Field
                     id="ProprietyJudgeAnswerDate__c_To"
                     name="ProprietyJudgeAnswerDate__c_To"
                     accessibilitylabel="ProprietyJudgeAnswerDate__c_To"
                     label={<React.Fragment />}
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                 </div>
               </Grid>
               <Grid key="key5-4" item xs={12} sm={2}>
                 <Field
                   id="ProprietyJudgeAnswerStatus"
                   name="ProprietyJudgeAnswerStatus"
                   className={classes.fields}
                   component={renderSelect}
                   label="可否判定／実施状況"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   <MenuItem value="">&nbsp;</MenuItem>
                   <MenuItem value={0}>可否判定中</MenuItem>
                   <MenuItem value={1}>可否判定済</MenuItem>
                 </Field>
               </Grid>
             </Grid>

             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key-6-1" item xs={12} sm>
                 <div style={{position: 'relative'}}>
                   <Field
                     id="OrderDate__c_From"
                     name="OrderDate__c_From"
                     accessibilitylabel="OrderDate__c_From"
                     label="本申込／受付日"
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                   <span className={classes.wave}>～</span>
                   <Field
                     id="OrderDate__c_To"
                     name="OrderDate__c_To"
                     accessibilitylabel="OrderDate__c_To"
                     label={<React.Fragment />}
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                 </div>
               </Grid>
               <Grid key="key6-2" item xs={12} sm={2}>
                 <Field
                   id="OrderStatus"
                   name="OrderStatus"
                   className={classes.fields}
                   component={renderSelect}
                   label="本申込／状況"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   <MenuItem value="">&nbsp;</MenuItem>
                   <MenuItem value={0}>受付未</MenuItem>
                   <MenuItem value={1}>受付済</MenuItem>
                 </Field>
               </Grid>
               <Grid key="key-6-3" item xs={12} sm>
                 <div style={{position: 'relative'}}>
                   <Field
                     id="StartNotificationDate__c_From"
                     name="StartNotificationDate__c_From"
                     accessibilitylabel="StartNotificationDate__c_From"
                     label="開始(着工可能)通知日"
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                   <span className={classes.wave}>～</span>
                   <Field
                     id="StartNotificationDate__c_To"
                     name="StartNotificationDate__c_To"
                     accessibilitylabel="StartNotificationDate__c_To"
                     label={<React.Fragment />}
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                 </div>
               </Grid>
               <Grid key="key6-4" item xs={12} sm={2}>
                 <Field
                   id="StartNotificationStatus"
                   name="StartNotificationStatus"
                   className={classes.fields}
                   component={renderSelect}
                   label="開始(着工可能)通知／状況"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   <MenuItem value="">&nbsp;</MenuItem>
                   <MenuItem value={0}>未</MenuItem>
                   <MenuItem value={1}>開始通知済</MenuItem>
                 </Field>
               </Grid>
             </Grid>

             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key7-1" item xs={12} sm={2}>
                 <Field
                   id="SyunkoReportRegisterStatus"
                   name="SyunkoReportRegisterStatus"
                   className={classes.fields}
                   component={renderSelect}
                   label="竣工届／状況"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   {generalPurposeMap &&
                    createSearchList(generalPurposeMap.UmuCategory, 'Code__c', 'ValueString1__c')}
                 </Field>
               </Grid>
               <Grid key="key-7-2" item xs={12} sm={4}>
                 <div style={{position: 'relative'}}>
                   <Field
                     id="SyunkoInspectionCompletionDate__c_From"
                     name="SyunkoInspectionCompletionDate__c_From"
                     accessibilitylabel="SyunkoInspectionCompletionDate__c_From"
                     label="竣工確認結果／完了日"
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                   <span className={classes.wave}>～</span>
                   <Field
                     id="SyunkoInspectionCompletionDate__c_To"
                     name="SyunkoInspectionCompletionDate__c_To"
                     accessibilitylabel="SyunkoInspectionCompletionDate__c_To"
                     label={<React.Fragment />}
                     component={renderTextField}
                     className={classes.textFieldDate}
                     required={false}
                     type="date"
                     InputLabelProps={{
                       shrink: true,
                     }}
                     validate={[
                       validateRule.date,
                     ]}
                   />
                 </div>
               </Grid>
               <Grid key="key6-2" item xs={12} sm={2}>
                 <Field
                   id="OProprietyResultAcceptedStatus"
                   name="OProprietyResultAcceptedStatus"
                   className={classes.fields}
                   component={renderSelect}
                   label="竣工確認結果／状況"
                   fullWidth
                   required={false}
                   validate={[
                   ]}
                 >
                   <MenuItem value="">&nbsp;</MenuItem>
                   <MenuItem value={0}>未</MenuItem>
                   <MenuItem value={1}>再提出要</MenuItem>
                   <MenuItem value={2}>再提出不要</MenuItem>
                 </Field>
               </Grid>
             </Grid>

             <Grid
               container
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
             >
               <Grid key="key8-1" item xs={12} sm>
                 <NegativeButton
                   id="btnClear"
                   variant="contained"
                   startIcon={<RotateLeftIcon />}
                   className={classes.button}
                   size="large"
                   onClick={reset}
                 >
                   <span>クリア</span>
                 </NegativeButton>
                 <PositiveButton
                   id="btnSearch"
                   variant="contained"
                   startIcon={<SearchIcon />}
                   className={clsx(classes.button)}
                   size="large"
                   onClick={handleSubmit(this.searchOrder)}
                 >
                   <span>検索</span>
                 </PositiveButton>
               </Grid>
             </Grid>

           </PaperPart>
           <div style={{marginBottom: '20px'}} />
           <Divider variant="middle" />
           <div style={{marginBottom: '20px'}} />
           <Grid container
             justifyContent="flex-start"
             alignItems="flex-start"
           >
             <Grid key="key-table" item xs={12}>
               {this.renderTable()}
             </Grid>
           </Grid>
         </MainContainer>

       </form>
     );
   }
}

ProcessProgressSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderList: PropTypes.array.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetAllGeneralPurposeMap: PropTypes.func.isRequired,
  doGetOrderTypeList: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
  doSetOrderId: PropTypes.func.isRequired,
  doSetStbType: PropTypes.func,
  doSetYbdsPath: PropTypes.func,
  doSetOrderNo: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderList: state.attachorder.orderList,
  // 表示時のデフォルト設定
  initialValues: {
    // SelectedMenu: 1012,
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doSetStbType: commonOperations.doSetStbType,
  doSetYbdsPath: commonOperations.doSetYbdsPath,
  doSetOrderNo: attachorderOperations.doSetOrderNo,
};

const FORM_NAME = 'ProcessProgressSearch';

ProcessProgressSearch = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(ProcessProgressSearch));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ProcessProgressSearch),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ListAltIcon from '@material-ui/icons/ListAlt';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, getDataFromGeneralPurposeMap,
  changeDateFormat, getErrorCode, API_CODE} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {otherOperations} from '../../../../../../reducks/other';
import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';
import * as validateRule from '../../../../../validateRule.js';
import {contactZikoListFields} from '../../../../../../common/SFFields';
import PaperPart from '../../../../../atoms/PaperPart.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

const CONTACT_ZIKO_PATH = '/Other/ContactZiko/ContactZiko/List';

/**
 * 重要・個別連絡事項確認一覧
 * 画面ID:1621
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {generalPurposeMap} = this.props;
      if (this.props.location.pathname == CONTACT_ZIKO_PATH) {
        // 個別事項
        const contactZikoType =
          getCodeFromGeneralPurposeMap(generalPurposeMap, 'ContactZikoType1', 1);
        await this.props.doSetContactZikoType(contactZikoType);
      } else {
        // 重要事項
        const contactZikoType =
          getCodeFromGeneralPurposeMap(generalPurposeMap, 'ContactZikoType2', 1);
        await this.props.doSetContactZikoType(contactZikoType);
      }
      const ApprovalStatus =
          getCodeFromGeneralPurposeMap(generalPurposeMap, 'ApprovalStatus2', 1);

      // パラメータ設定
      const appConditions = {
        ContactType__c: this.props.contactZikoType,
        DeleteFlag__c: false,
        ApprovalStatus__c: ApprovalStatus, // 承認済のみ
        KyogaCheckDate__c_EQ: null, // 未確認の項目のみ
      };

      // 検索時のソートキー
      const appSortParams = {
        CreatedDate: -1,
      };

      if (this.props.hasOwnProperty('contactZikoList')) {
        // 共架事業者連絡事項テーブルからデータ取得
        const response = await this.props.doGetContactZikoList(
            appConditions, contactZikoListFields, appSortParams);
        const errorCode = getErrorCode(response);
        if (errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['連絡事項', '取得'],
            },
          });
          return;
        }
        // 件数超過エラー
        const maxKensu = getDataFromGeneralPurposeMap(
            generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
        const dataLen = response.data.body.data ?
          response.data.body.data.length : 0;
        if (dataLen >= maxKensu) {
          this.props.doShowMessage({
            message: 'CE0015',
          });
        }
        // FlexGridに初期値設定
        if (this.gridRef && this.gridRef.current) {
          this.gridRef.current.setInitItems('1621', this.props.contactZikoList);
        }

        // 未確認の重要事項があればフラグをたてて画面遷移できないようにする
        if (this.props.contactZikoType == '2') {
          // 戻るボタンを連打するとthis.propsにデータが入らないタイミングでここを通るので
          // responseを参照する。
          const _contactZikoList = response.data.body.data ?
            response.data.body.data : [];
          const _hasJuyoZiko = _contactZikoList.filter((data) =>
            data.ContactType__c == '2' && data.KyogaCheckDate__c == null).length > 0;
          this.props.setHasJuyoZiko(_hasJuyoZiko);
        }
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearContactZikoList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1621', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
  * システムエラー
  */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 検索ボタン押下時処理
   */
  search = async () => {
    try {
      const {contactZikoType, generalPurposeMap} = this.props;
      this.setState({buttonDisabled: true});

      const ApprovalStatus =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'ApprovalStatus2', 1);

      // パラメータ設定
      // ログインユーザーIDはサーバーで設定する
      const appConditions = {
        ContactType__c: contactZikoType, // 重要事項か個別連絡事項
        DeleteFlag__c: false,
        ApprovalStatus__c: ApprovalStatus,
      };

      // 検索条件
      let wkData = {};
      if (this.props.values) {
        wkData = JSON.parse(JSON.stringify(this.props.values));
      }
      // 確認ステータスは共架者確認日の検索条件
      if (Object.hasOwnProperty.call(wkData, 'KyogasyaCheckStatus')) {
        if (wkData['KyogasyaCheckStatus'] == '0') { // 未確認は空白を検索
          Object.assign(appConditions, {'KyogaCheckDate__c_EQ': null});
        }
        if (wkData['KyogasyaCheckStatus'] == '1') { // 確認済は空白以外を検索
          Object.assign(appConditions, {'KyogaCheckDate__c_NE': null});
        }
        delete wkData['KyogasyaCheckStatus'];
      }

      if (wkData != null) {
        Object.assign(appConditions, wkData);
      }

      // 検索時のソートキー
      const appSortParams = {
        CreatedDate: -1,
      };

      // 検索
      const response = await this.props.doGetContactZikoList(
          appConditions, contactZikoListFields, appSortParams);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['連絡事項', '取得'],
          },
        });
        return;
      }
      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1621', 'Id', true);
      }
      // 件数超過エラー
      const maxKensu = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      const dataLen = response.data.body.data ?
        response.data.body.data.length : 0;
      if (dataLen >= maxKensu) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1621', this.props.contactZikoList);
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('contactZikoList') ||
        !this.props.contactZikoList ||
        this.props.contactZikoList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let bRet = false;

      if (this.gridRef && this.gridRef.current &&
        this.gridRef.current.selectedItemCount() > 0) {
        bRet = true;
      }

      if (bRet) {
        this.setState({buttonDisabled: false});
      } else {
        this.setState({buttonDisabled: true});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 連絡事項確認ボタン押下時
   */
  doConfirm = () => {
    try {
      if (!this.gridRef ||
        !this.gridRef.current) {
        return;
      }

      const count = this.gridRef.current.selectedItemCount();
      if (count != 1) {
        this.props.doShowMessage({
          message: {
            id: 'CE0022',
          },
        });
        return;
      }

      const items = this.gridRef.current.selectedItem();
      this.props.doSetKyogaContactZikoId(items[0].dataItem.Id);
      if (this.props.contactZikoType == '1') {
        this.props.history.push({pathname: '/Other/ContactZiko/ContactZiko/Confirm'});
      }
      if (this.props.contactZikoType == '2') {
        this.props.history.push({pathname: '/Other/ContactZiko/JuyoZiko/Confirm'});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧表示
   * @return {object}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      style: {height: '300px'},
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="Name" header="件名" dataType="String" width={400}/>
        <FlexGridColumnGroup binding="NotificationContents__c" header="通知内容" dataType="String" width={600}/>
        <FlexGridColumnGroup binding="NotificDate__c" header="通知日" dataType="Date" width={190}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.NotificDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KyogaCheckDate__c" header="確認日" dataType="Date" width={190}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.KyogaCheckDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;
    const _hasJuyoZiko = userInfo.DaikoUserId == null && this.props.hasJuyoZiko;
    console.dir(_hasJuyoZiko);

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1621Confirm" item>
          <PositiveButton
            id="btn1621Confirm"
            onClick={handleSubmit(this.doConfirm)}
            variant="contained"
            startIcon={<ListAltIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>連絡事項確認</span>
          </PositiveButton>
        </Grid>
        {!_hasJuyoZiko &&
        <Grid key="key1621Back" item>
          <BackButton props={this.props}/>
        </Grid>}
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
          disableSideMenu={_hasJuyoZiko}>
          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1621Name" item xs={12} sm={5}>
                <Field
                  id="id1621Name"
                  name="Name"
                  component={renderTextField}
                  className={classes.fields}
                  label="件名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(80),
                  ]}
                />
              </Grid>
              <Grid key="key1621NotificationContents" item xs={12} sm={5}>
                <Field
                  id="id1621NotificationContents"
                  name="NotificationContents__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="通知内容"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>
              <Grid key="key1621KyogasyaCheckStatus" item xs={12} sm={2}>
                <Field
                  id="id1621Type"
                  name="KyogasyaCheckStatus"
                  component={renderSelect}
                  className={classes.fields}
                  label="確認ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(generalPurposeMap.KyogasyaCheckStatus)}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1621NotificDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1621NotificDateFrom"
                    name="NotificDate__c_From"
                    accessibilitylabel="NotificDate__c_From"
                    label="通知日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1621NotificDateTo"
                    name="NotificDate__c_To"
                    accessibilitylabel="NotificDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1621KyogaCheckDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1621KyogaCheckDateFrom"
                    name="KyogaCheckDate__c_From"
                    accessibilitylabel="KyogaCheckDate__c_From"
                    label="確認日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1621KyogaCheckDateTo"
                    name="KyogaCheckDate__c_To"
                    accessibilitylabel="KyogaCheckDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1621Clear" item xs={12} sm>
                <NegativeButton
                  id="id1621Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="id1621Search"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.search)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>
          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />
          <Grid container
            direction="column"
            alignItems="flex-start"
          >
            <Grid key="key1621List" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  contactZikoList: PropTypes.array,
  location: PropTypes.object,
  pathname: PropTypes.string,
  contactZikoType: PropTypes.string,
  hasJuyoZiko: PropTypes.bool,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetContactZikoList: PropTypes.func,
  doClearContactZikoList: PropTypes.func,
  doSetContactZikoType: PropTypes.func,
  doSetKyogaContactZikoId: PropTypes.func,
  setHasJuyoZiko: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    hasJuyoZiko: state.common.hasJuyoZiko,
    userInfo: state.auth.userInfo,
    contactZikoType: state.other.contactZikoType,
    contactZikoList: state.other.contactZikoList,
    initialValues: {
      KyogasyaCheckStatus: '0',
    },
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  setHasJuyoZiko: commonOperations.doSetHasJuyoZiko,
  doGetContactZikoList: otherOperations.doGetContactZikoList,
  doClearContactZikoList: otherOperations.doClearContactZikoList,
  doSetContactZikoType: otherOperations.doSetContactZikoType,
  doSetKyogaContactZikoId: otherOperations.doSetKyogaContactZikoId,
};

const FORM_NAME = 'ContactZikoList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import {isNullOrWhiteSpace} from '@grapecity/wijmo';
import {DataMap} from '@grapecity/wijmo.grid';

import axios from 'axios';
import {saveAs} from 'file-saver';
import Moment from 'moment';
import {Buffer} from 'buffer';
import iconv from 'iconv-lite';

import conf from '../config/config.js';

/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */

/**
 * APIエラーコード。
 * KMS-APIのエラーコード定義と同時に更新する必要有。
 */
export const API_CODE = {
  /** 成功 */
  SUCCESS: '00000',

  /** 登録エラー */
  ERROR_REGIST: '00001',

  /** 取得エラー */
  ERROR_GET: '00002',

  /** ID指定取得エラー */
  ERROR_RETRIEVE: '00003',

  /** 更新エラー */
  ERROR_UPDATE: '00004',

  /** 削除エラー */
  ERROR_DELETE: '00005',

  /** ファイルアップデートエラー */
  ERROR_FILE_UPDATE: '00006',

  /** ウィルスチェックエラー */
  ERROR_VIRUS: '10001',

  /** 権限不足エラー */
  ERROR_PERMISSION_DENIED: '20000',

  /** Cognitoユーザーデータ取得失敗 */
  ERROR_GET_USER_DATA: '21200',

  /** 利用可能時間外のAPI利用 */
  ERROR_UNAVAILABLE_TIME: '30000',

  /** バリデーションエラー */
  ERROR_VALIDATION: '90000',

  /** 最終更新日時不一致 */
  ERROR_LAST_MODIFIED_DATE: '90001',

  /** システムエラー */
  SYS_ERROR: '99999',
};

/**
 * SF APEX エラーコード。
 * ApiErrTigと同時に更新する必要有。
 */
export const SF_API_CODE = {
  /** 正常 */
  SUCCESS: '00000',

  /** システムエラー */
  SYSTEM_ERR: '99999',

  /** 入力パラメータ不正 */
  INPUT_PARAM_ERR: '10001',

  /** 最終更新日時不一致 */
  LAST_MODIFIED_DATE_ERR: '10002',

  /** 更新処理失敗 */
  UPDATE_ERR: '20001',
};

const harfKanaMap = {
  'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
  'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
  'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
  'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
  'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
  'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
  'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
  'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
  'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
  'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
  'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
  'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
  'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
  'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
  'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
  'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
  'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
  'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
  '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・',
};

const synkPhotoItems = {zenkei: '全景写真(施工後)'};

/** ページ表示権限 */
export const PERMISSION = {
  /** 電力サポート中国契約 */
  DENRYOKU_SP_KEIYAKU: 1,

  /** 中電NW設計 */
  CHUDEN_NW_SEKKEI: 2,

  /** 電力サポート中国契約 */
  CHUDEN_NW_KEIYAKU: 3,
};

// 全角→半角変換
export const full2Harf = (full) => {
  if (!full) return '';

  // アルファベット 数字 記号
  let harf = full.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });

  // 全角カナ→半角カナ
  const reg = new RegExp('(' + Object.values(harfKanaMap).join('|') + ')', 'g');
  const fullKanaMap = {};
  for (const item in harfKanaMap) {
    if (harfKanaMap.hasOwnProperty(item)) {
      fullKanaMap[harfKanaMap[item]] = item;
    }
  }
  harf = harf.replace(reg, function(match) {
    return fullKanaMap[match];
  })

      .replace('゛', /ﾞ/g)
      .replace('゜', /ﾟ/g)
      .replace(/　/g, ' ');

  return harf;
};

// 全角かな→半角ｶﾅ変換
export const full2Harf2 = (full) => {
  if (!full) return '';

  // 全角かな→全角カナ
  const kana = full.replace(/[\u3041-\u3096]/g, function(match) {
    const chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });

  // 全角→半角変換
  const harf = full2Harf(kana);

  return harf;
};

// 半角→全角変換
export const harf2Full = (harf) => {
  if (!harf) return '';

  // アルファベット 数字 記号
  let full = harf.replace(/[A-Za-z0-9!-~]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });

  // 半角カナ→全角カナ
  const reg = new RegExp('(' + Object.keys(harfKanaMap).join('|') + ')', 'g');
  full = full.replace(reg, function(match) {
    return harfKanaMap[match];
  })
      .replace(/ﾞ/g, '゛')
      .replace(/ﾟ/g, '゜')
      .replace(/ /g, '　');

  return full;
};

// base64からBlob変換
export const base64toBlob = (base64, mimeCtype) => {
  const bin = window.atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  let blob;
  try {
    blob = new window.Blob([buffer.buffer], {
      type: mimeCtype,
    });
  } catch (e) {
    return false;
  }
  return blob;
};

// base64からBlob変換（CSV用 UTF-8 → SJIS変換）
export const base64toBlobCsv = (base64, mimeCtype) => {
  try {
    // base64デコード
    const buffer = Buffer.from(base64, 'base64').toString();

    // UTF-8からSJIS変換
    const csvData = utf8toSjis(buffer);

    // Blobを作成
    return new window.Blob([csvData], {
      type: mimeCtype,
    });
  } catch (e) {
    console.error(e);
    return false;
  }
};

// 文字列からBlob変換
export const string64toBlob = (str, mimeCtype) => {
  try {
    return new window.Blob([str], {
      type: mimeCtype,
    });
  } catch (e) {
    console.error(e);
    return false;
  }
};

// UTF-8からSJIS変換
export const utf8toSjis = (str) => {
  try {
    // 改行コード変換(\n → \r\n)
    const dataCrlf = str.replace(/\n/g, '\r\n');
    // 文字コード変換(UTF-8 → SJIS)
    const dataSjis = iconv.encode(dataCrlf, 'Shift_JIS');
    return dataSjis;
  } catch (e) {
    console.error(e);
    return false;
  }
};

// ブラウザ判定
export const browser = () => {
  const ua = window.navigator.userAgent.toLowerCase();

  // Mac
  if (ua.indexOf('mac os x') !== -1) {
    // Chrome
    if (ua.indexOf('chrome' && ua.indexOf('edge') == -1) !== -1) {
      return 'MAC_CHROME';
    }
    // Safari
    if (ua.indexOf('safari') !== -1) {
      return 'MAC_SAFARI';
    }
  }

  // iPhone
  if (ua.indexOf('iphone') !== -1) {
    // Chrome
    if (ua.indexOf('chrome') !== -1 && ua.indexOf('edge') == -1) {
      return 'IOS_CHROME';
    }
    // Safari
    if (ua.indexOf('safari') !== -1) {
      return 'IOS_SAFARI';
    }
  }

  return 'OTHER';
};

/**
 * スマートフォン判定
 * @return {boolean} iOS/Androidの場合true、それ以外の場合false
 */
export const isSmartPhone = () => {
  return navigator.userAgent.match(/iPhone|iPad|Android/);
};

/**
 * 帳票(EXCEL)の作成
 *
 * @param {object} userInfo
 * @param {string} tyohyoName
 * @param {object} param
 * @param {string} fileName
 * @return {Promise<boolean>} 成功時はtrue、失敗時はfalse
 */
export async function createTyohyoExcel(userInfo, tyohyoName, param, fileName) {
  return await createTyohyo(userInfo, tyohyoName, fileName, param,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
}

/**
 * 帳票(PDF)の作成
 *
 * @param {object} userInfo
 * @param {string} tyohyoName
 * @param {object} param
 * @param {string} fileName
 * @return {Promise<boolean>} 成功時はtrue、失敗時はfalse
 */
export async function createTyohyoPdf(userInfo, tyohyoName, param, fileName) {
  return await createTyohyo(userInfo, tyohyoName, fileName, param,
      'application/pdf');
}

/**
 * 帳票(CSV)の作成
 *
 * @param {string} tyohyoName
 * @param {object} param
 * @param {string} fileName
 * @param {object} subParam
 * @return {Promise<boolean>} 成功時はtrue、失敗時はfalse
 */
export async function createTyohyoCsv(tyohyoName, param, fileName, subParam) {
  try {
    const body = {};
    if (param) {
      body['conditions'] = param;
    }
    if (subParam) {
      body['subquery'] = subParam;
    }
    const ret = await axios.post(conf.API_URL + 'Tyohyo/' + tyohyoName, body, {});

    if (ret.data.body.errorCode === '00000') {
      const blob = base64toBlobCsv(ret.data.body.data.contentDocumentBody, 'text/csv');
      if (blob) {
        saveAs(blob, fileName);
        return true;
      }
      return false;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}

/**
 * 帳票(CSV)の作成（salesforceの検索上限2000件を超えてデータを取得）
 *
 * @param {string} tyohyoName
 * @param {object} param
 * @param {string} fileName
 * @param {object} subParam
 * @param {number} LimitSize
 * @return {Promise<number|boolean>} totalSize 取得した件数（エラーの場合は -1を返却）
 */
export async function createTyohyoCsvLimitOver(
    tyohyoName, param, fileName, subParam, LimitSize) {
  try {
    const body = {};
    if (param) {
      body['conditions'] = param;
    }
    if (subParam) {
      body['subquery'] = subParam;
    }
    const ret = await axios.post(conf.API_URL + 'Tyohyo/' + tyohyoName, body, {});
    const totalSize = ret.data.body.totalSize;

    if (ret.data.body.errorCode === '00000') {
      if (totalSize && totalSize > LimitSize) {
        return totalSize;
      } else if (totalSize == 0) {
        return totalSize;
      }
      const blob = base64toBlobCsv(ret.data.body.data.contentDocumentBody, 'text/csv');
      if (blob) {
        saveAs(blob, fileName);
        return totalSize;
      }
      return -1;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}

/**
 * 帳票の作成 共通部分
 *
 * @param {object} userInfo
 * @param {string} tyohyoName
 * @param {string} fileName
 *  @param {object} param
 * @param {string} type
 * @return {Promise<boolean>} 成功時はtrue、失敗時はfalse
 */
async function createTyohyo(userInfo, tyohyoName, fileName, param = {}, type) {
  try {
    const body = {};
    if (param) {
      body['conditions'] = param;
    }
    const ret = await axios.post(conf.API_URL + 'Tyohyo/' + tyohyoName, body, {
      headers: {
        Authorization: userInfo.idToken,
      },
    });

    if (ret.data.body.errorCode === '00000') {
      const blob = base64toBlob(ret.data.body.data.contentDocumentBody, type);
      saveAs(blob, fileName);
      return true;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
}

/**
 * 添付ファイルダウンロード
 *
 * @param {object} userInfo ユーザ情報(必須)
 * @param {string} filePath ファイルパス(必須)
 * @param {string} type     ファイルタイプ(必須)
 * @param {string} fileName 出力ファイル名(必須)
 * @return {Promise<boolean>} 成功時はtrue、失敗時はfalse
 */
export async function downloadAttachedFile(userInfo, filePath, type, fileName) {
  const wkType = 'application/' + type;
  return downloadFile(userInfo, filePath, wkType, fileName);
}

/**
 * ファイルダウンロード
 *
 * @param {object} userInfo ユーザ情報(必須)
 * @param {string} filePath ファイルパス(必須)
 * @param {string} type     ファイルタイプ(必須)
 * @param {string} fileName 出力ファイル名(必須)
 * @return {Promise<boolean>} 成功時はtrue、失敗時はfalse
 */
export async function downloadFile(userInfo, filePath, type, fileName) {
  // すでに取得済の場合は処理終了
  if (!(userInfo && filePath && type && fileName)) {
    console.log('引数が未設定のため、終了');
    return;
  }

  const body = {};
  body['fileName'] = filePath;
  body['downloadFileName'] = fileName;

  try {
    const ret = await axios.post(conf.API_URL + 'AttachedFile/getFile', body, {
      headers: {
        Authorization: userInfo.idToken,
      },
    });

    if (ret.data.body.errorCode === '00000') {
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = ret.data.body.data;
      anchor.click();
      return true;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
}

/**
 * ファイルダウンロードコンテンツ取得
 *
 * @param {object} userInfo ユーザ情報(必須)
 * @param {string} filePath ファイルパス(必須)
 * @param {string} type     ファイルタイプ(必須)
 * @param {string} fileName 出力ファイル名(必須)
 * @return {Blob} 失敗時はnull
 */
export async function downloadFileContent(userInfo, filePath, type) {
  // すでに取得済の場合は処理終了
  if (!(userInfo && filePath && type)) {
    console.log('引数が未設定のため、終了');
    return null;
  }

  const body = {};
  body['fileName'] = filePath;

  try {
    const ret = await axios.post(conf.API_URL + 'AttachedFile/getFile', body, {
      headers: {
        Authorization: userInfo.idToken,
      },
    });

    if (ret.data.body.errorCode === '00000') {
      return ret.data.body.data;
    }

    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * 現在日時取得
 *
 * @param {string} [dateFormat] 出力フォーマット
 * @return {string}} 現在日時
 */
export function getToDate(dateFormat = 'YYYY-MM-DD') {
  return new Moment().format(dateFormat);
}

/**
 * 日付型変換
 *
 * @param {string} date 文字列
 * @param {string} [dateFormat] 入力フォーマット
 * @param {string} [changeFormat] 出力フォーマット
 * @return {string}} 変換した日付
 */
export function changeStringDate(
    date, dateFormat = 'YYYY-MM-DD', changeFormat = 'YYYY-MM') {
  return new Moment(date, dateFormat).format(changeFormat);
}

/**
 * 現在月から加算した年月日取得
 *
 * @param {number} [months] 加算月
 * @param {number} [days] 加算日
 * @param {string} [dateFormat] 出力フォーマット
 * @return {string}} 変換した年月日
 */
export function getAddDate(months = -12, days = 0, dateFormat = 'YYYY-MM-DD') {
  return new Moment().add(months, 'months').add(days, 'days').format(dateFormat);
}

/**
 * 年月日から年度を取得
 *
 * @param {number} [date] 年月日
 * @return {string}} 変換した年度
 */
export function getNendo(date = getToDate()) {
  return new Moment(date).subtract(3, 'months').year().toString();
}

/**
 * 日付型のフォーマット変換(画面表示用)
 * @param {content} date
 * @param {content} format
 * @return {string} 変換後の日付データ
 */
export function changeDateFormat(date, format = 'YYYY/MM/DD') {
  return changeDateFormatter(date, format);
}

/**
 * 日付型のフォーマット変換
 * @param {content} date
 * @param {string} format
 * @return {string} 変換後の日付データ
 */
export function changeDateFormatter(date, format) {
  return date ? new Moment(date).format(format) : '';
}

/**
 * 日付型のUTCフォーマット変換
 * @param {content} date
 * @return {Date} 変換後の日付データ
 */
export function changeDateUtc(date) {
  return date ? new Moment(date).utc().toDate() : null;
}

/**
 * UTC日付文字列をJST日付に変換する
 * @param {string} dateUtc
 * @param {string} format
 * @return {Date} 変換後の日付データ
 */
export function changeDateJst(dateUtc, format = 'YYYY/MM/DD') {
  let value = '';
  if (!dateUtc) {
    return value;
  }
  try {
    const dateJst = new Date(dateUtc).toLocaleString('ja-JP', {timeZone: 'JST'});
    value = changeDateFormat(dateJst, format);
  } catch (err) {
    value = '';
  }
  return value;
}

/**
 * 指定した日付の月末取得
 * @param {content} date
 * @return {string} 変換後の日付データ
 */
export function getEndOfMonth(date) {
  const _date = new Moment(date);
  if (!_date.isValid()) {
    return '';
  }
  return _date.endOf('month').format('YYYY-MM-DD');
}

/**
 * DB検索
 * @param {object} userInfo ユーザ情報(必須)
 * @param {string} execPath 実行パス(必須)
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} [sortParams] 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Object} [addFields] 追加設定するフィールド({フィールド名：値})
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @return {Object} 取得結果一覧
 */
export async function searchDb(
    userInfo, execPath, conditions, fields, sortParams, addFields, subquery) {
  if (!(userInfo && execPath)) {
    console.log('引数が未設定のため、終了');
    return;
  }

  const body = {};
  if (conditions) {
    body['conditions'] = conditions;
  }

  if (fields) {
    body['fields'] = fields;
  }

  if (sortParams) {
    body['sortParams'] = sortParams;
  }

  if (subquery) {
    body['subquery'] = subquery;
  }

  let result;
  try {
    result = await axios.post(conf.API_URL + execPath, body, {
      headers: {
        Authorization: userInfo.idToken,
      },
    });
  } catch (err) {
    console.error(err);
    return result;
  }

  const retData = result.data.body.data;

  if (addFields && retData) {
  // 検索結果に項目追加
    const size = Object.keys(retData).length;
    for (let i = 0; i < size; i++) {
      Object.assign(retData[i], addFields);
    }
  }
  return result;
}

/**
 * DBアクセス（Apex REST API）
 * @param {object} userInfo ユーザ情報
 * @param {string} execPath 実行パス
 * @param {Object} body 入力パラメータ
 * @return {Object} 実行結果
 */
export async function execApexRestApi(
    userInfo, execPath, body) {
  if (!(userInfo && execPath)) {
    console.log('引数が未設定のため、終了');
    return;
  }

  let result;
  try {
    result = await axios.post(conf.API_URL + execPath, body, {
      headers: {Authorization: userInfo.idToken},
    });
  } catch (err) {
    console.error(err);
    return result;
  }
  return result;
}

/**
 * 権限IDの取得。
 *
 * ログインユーザから紐づく権限IDを判定して返却する。
 * ・1: 共架事業者(電力サポート中国契約)
 * ・2: 中電NW設計担当
 * ・3: 共架事業者(中国電力ネットワーク契約)
 *
 * @param {object} userInfo
 * @param {object} generalPurpose
 * @return {number} メニューID
 */
export function getPermissionId(userInfo, generalPurpose) {
  // 中電NW設計担当
  if (isNWDesignZgsya(userInfo, generalPurpose)) {
    return PERMISSION.CHUDEN_NW_SEKKEI;
  }

  // 共架事業者(中国電力ネットワーク契約)
  if (isChudenNwContract(userInfo)) {
    return PERMISSION.CHUDEN_NW_KEIYAKU;
  }

  // 共架事業者(電力サポート中国契約)
  return PERMISSION.DENRYOKU_SP_KEIYAKU;
}

/**
 * 事業者コードがエネコムかどうか判定する
 * @param {string} kyogaZgsyaCode チェック対象
 * @param {array} items 汎用マスタのエネコム共架事業者コード
 * @return {boolean} true:エネコム, false:エネコム以外
 */
export function isEnecom(kyogaZgsyaCode, items) {
  let ret = false;

  if (!kyogaZgsyaCode) {
    return ret;
  }
  if (!items) {
    return ret;
  }

  for (const item in items) {
    if (items[item].ValueString1__c == kyogaZgsyaCode) {
      ret = true;
      break;
    }
  }
  return ret;
}

/**
 * 事業者コードが中電NW設計事業者かどうか判定する。
 * @param {object} userInfo ユーザ情報
 * @param {object} generalPurposeMap 汎用マスタ
 * @return {boolean} true:中電NW設計事業者, false:中電NW設計事業者以外
 */
export function isNWDesignZgsya(userInfo, generalPurposeMap) {
  const account = userInfo.Account;
  if (!account) {
    // SFユーザの場合はfalse
    return false;
  }

  const kyogaZgsyaCode = userInfo.Account.KyogaZgsyaCode__c;
  const items = generalPurposeMap.NWDesignZgsyaCode;

  // 実装はisEnecomと同じ
  return isEnecom(kyogaZgsyaCode, items);
}

/**
 * ユーザが中国電力ネットワーク契約かどうかを判定する
 * @param {object} userInfo ユーザ情報
 * @return {boolean} true:中国電力ネットワーク契約, false:中国電力ネットワーク契約以外
 */
export function isChudenNwContract(userInfo) {
  return userInfo.Account.Account__r.ContractCategory__c == '1';
}

/**
 * 事業者コードがエネコムかどうか判定する。
 * @param {object} userInfo ユーザ情報
 * @param {object} generalPurposeMap 汎用マスタ
 * @return {boolean} true:エネコム事業者, false:エネコム事業者以外
 */
export function isENECOMKyogaZgsya(userInfo, generalPurposeMap) {
  const account = userInfo.Account;
  if (!account) {
    // SFユーザの場合はfalse
    return false;
  }

  const kyogaZgsyaCode = userInfo.Account.KyogaZgsyaCode__c;
  const items = generalPurposeMap.ENECOMKyogaZgsyaCode;

  return isEnecom(kyogaZgsyaCode, items);
}

/**
 * 汎用マスタの全データから汎用マスタの外部IDに紐づくデータを取得する
 * 外部IDは「区分種別＋コード」になっているので、
 * 汎用マスタにデータがなければ外部IDからコード値を切り出して返す
 * @param {object} allGeneralPurposeMap 汎用マスタの全データ
 * @param {string} externalId 汎用マスタの外部ID
 * @param {number} digit コード値の桁数
 * @return {object} 汎用マスタのデータ
 */
export function getDataFromGeneralPurposeMap(
    allGeneralPurposeMap, externalId, digit) {
  if (!externalId || !digit) {
    return '';
  }
  const key = externalId.substring(0, externalId.length - digit);
  const result =
  allGeneralPurposeMap[key]
      .filter((data) => data.ExternalId__c == externalId);
  return result.length > 0 ? result[0] : '';
}

/**
 * 汎用マスタの全データから汎用マスタの外部IDに紐づくコード値を取得する
 * 外部IDは「区分種別＋コード」になっているので、
 * 汎用マスタにデータがなければ外部IDからコード値を切り出して返す
 * @param {object} allGeneralPurposeMap 汎用マスタの全データ
 * @param {string} externalId 汎用マスタの外部ID
 * @param {number} digit コード値の桁数
 * @return {string} コード値
 */
export function getCodeFromGeneralPurposeMap(
    allGeneralPurposeMap, externalId, digit) {
  if (!externalId || !digit) {
    return '';
  }
  const key = externalId.substring(0, externalId.length - digit);
  const code = externalId.substring(
      externalId.length - digit, externalId.length);
  if (!allGeneralPurposeMap || !allGeneralPurposeMap[key]) {
    return code;
  }
  const result =
    allGeneralPurposeMap[key]
        .filter((data) => data.ExternalId__c == externalId);
  return result.length > 0 ? result[0].Code__c : code;
}

/**
 * 汎用マスタの全データからコードに該当する単価を返す
 * @param {object} allGeneralPurposeMap 汎用マスタの全データ
 * @param {string} code コード値
 * @param {bool} isYear 年単価かどうか true:年単価、false:月単価
 * @param {string} loginDate ログイン日時
 * @return {string} 単価
 */
export function getUnitPrice(allGeneralPurposeMap, code, isYear, loginDate) {
  let retValue = 0;
  if (!allGeneralPurposeMap || !code) {
    return retValue;
  }

  let systemDate = changeDateJst(loginDate, 'YYYY-MM-DD');
  if (!systemDate) {
    const nowDate = new Date();
    systemDate = nowDate.getFullYear() + '-' +
      (nowDate.getMonth() + 1) + '-' + nowDate.getDate();
  }

  const key = 'UnitPriceMaster';
  const generalPurposes = allGeneralPurposeMap[key] ?
    allGeneralPurposeMap[key] : [];
  const result =
    generalPurposes.filter((data) => data.Code__c == code &&
      data.ApplyStartDate__c <= systemDate &&
      data.ApplyEndDate__c >= systemDate);
  if (result && result.length > 0) {
    retValue = isYear ?
      result[0].UnitPriceYear__c : result[0].UnitPriceMonth__c;
  }

  return retValue;
}

/**
 * 汎用マスタの区分種別「共架種別」から指定した共架種別の有償無償区分を返す
 * @param {object} allGeneralPurposeMap 汎用マスタの全データ
 * @param {string} code 共架種別のコード値
 * @return {string} 共架種別の有償無償区分
 */
export function getChargeableCategoryOfKyogaType(allGeneralPurposeMap, code) {
  let retValue = '0';
  if (!allGeneralPurposeMap || !code) {
    return retValue;
  }

  const key = 'KyogaType';
  const generalPurposes = allGeneralPurposeMap[key] ?
    allGeneralPurposeMap[key] : [];
  const result = generalPurposes.filter((data) => data.Code__c == code);
  if (result.length > 0) {
    retValue = result[0].ValueString5__c != null ?
      result[0].ValueString5__c : '0';
  }

  return retValue;
}

/**
 * 数値を3桁カンマ区切りにする
 * @param {number} num 数値
 * @return {string} 3桁カンマ区切りにした文字列
 */
export function convertCommaSeparated(num) {
  return num ? num.toLocaleString('ja-JP') : num;
}

/**
 * 汎用マスタからコンボボックス表示用リストを取得する
 * @param {object} generalPurposeMap 汎用マスタデータ
 * @param {string} kbn 汎用マスタの区分種別
 * @param {bool} emptyHeader true:表示リストの先頭行に空白あり, false:先頭行に空白なし
 * @param {object} options 除外条件 {key: [value, ...]}
 * @return {array} コンボボックス表示用リスト [{Name: 表示名称, Code__c:コード値}]
 */
export function getComboList(
    generalPurposeMap, kbn, emptyHeader=true, options={}) {
  let resultList = [{Name: '', Code__c: ''}];
  if (!generalPurposeMap ||
    !Object.hasOwnProperty.call(generalPurposeMap, kbn)) {
    return resultList;
  }

  if (!emptyHeader) {
    resultList = [];
  }

  for (const data of generalPurposeMap[kbn]) {
    if (Object.keys(options).length > 0) {
      let skip = false;
      for (const key of Object.keys(options)) {
        if (!options[key]) {
          continue;
        }
        let _array = [];
        if (Array.isArray(options[key])) {
          _array = options[key];
        } else {
          _array.push(options[key]);
        }
        skip = Object.hasOwnProperty.call(data, key) &&
          (_array.includes(data[key]));
      }
      if (skip) {
        continue;
      }
    }
    resultList.push({Name: data.Name, Code__c: data.Code__c});
  }

  return resultList;
}

/**
 * コンボボックス表示用リストからDataMapを生成する。
 *
 * @see getComboList で生成したリストからDataMapを生成する。
 * getComboListもDataMapも必要な画面で使用。
 *
 * @param {{Name:string, Code__c:string}[]} comboList
 * @return {DataMap} DataMap
 */
export function createDataMapByComboList(comboList) {
  return new DataMap(comboList, 'Code__c', 'Name');
}

/**
 * 汎用マスタからDataMapを生成する。
 *
 * DataMapに設定する情報は @see getComboList で作成する。
 *
 * @param {object} generalPurposeMap 汎用マスタデータ
 * @param {string} kbn 汎用マスタの区分種別
 * @param {bool} emptyHeader true:表示リストの先頭行に空白あり, false:先頭行に空白なし
 * @param {object} options 除外条件 {key: [value, ...]}
 * @return {DataMap} DataMap
 */
export function createDataMap(
    generalPurposeMap, kbn, emptyHeader = true, options = {},
) {
  const list = getComboList(generalPurposeMap, kbn, emptyHeader, options);
  return createDataMapByComboList(list);
}

/**
 * コード値をキーにしてコンボボックス表示用リストから表示名称を取得する
 * @param {object} generalPurposeMap 汎用マスタデータ
 * @param {string} kbn 汎用マスタの区分種別
 * @param {string} code コード値
 * @return {array} コード値に対応する表示名称
 */
export function getComboName(generalPurposeMap, kbn, code) {
  const list = getComboList(generalPurposeMap, kbn);
  const obj = list ? list.filter(
      (data)=>data.Code__c == code) : [];
  return obj && obj.length > 0 ? obj[0].Name : '';
}

/**
 * 改修依頼ステータスと申込ステータスの対応
 */
const relationOrderRepairstatuses = [
  ['06'],
  ['11'],
  ['12', '13', '14', '15', '16', '20', '21', '31'],
  ['40'],
  ['41'],
  ['90'],
];

/**
 * 改修依頼ステータスから申込ステータス配列への変換
 * @param {string} repairIraiStasus 改修依頼ステータス
 * @return {array} 申込ステータス配列
 */
export function parseStatusRepairiraiToOrder(repairIraiStasus) {
  const retArray=relationOrderRepairstatuses.map((orderstatuses, index) =>{
    const temp=[];
    if (parseInt(repairIraiStasus) === index+1) {
      temp.push( orderstatuses);
      return temp;
    }
  },
  );
  return retArray.filter((v)=>v)[0][0];
};

/**
 * 申込ステータスから改修依頼ステータスへの変換
 * @param {string} orderstatus 申込ステータス
 * @return {string} 改修依頼ステータス
 */
export function parseStatusOrderToRepairirai(orderstatus) {
  let index = 0;
  for (const items of relationOrderRepairstatuses) {
    if (items.includes(orderstatus)) {
      return index;
    }
    index++;
  }
  return null;
};

/**
 * 一覧に同じ径間が存在するかチェックする
 * 第一電柱と第二電柱の順序が逆（電柱1-電柱2と電柱2-電柱1）は同じ径間とする。
 * 比較する項目は以下の通り。
 * 電柱区分、共架区分、設備個別ID、２５桁電柱番号、線路名、自由入力電柱番号
 * @param {array} items 一覧データ
 * @param {object} compObj チェック対象データ
 * @return {bool} true: 同じ径間が存在する, false: 同じ径間が存在しない
 */
export function isSameKikn(items, compObj) {
  if (!items || !compObj) {
    return result;
  }

  let result = items.filter((data) =>
    ( data.DntCategory1__c == compObj.DntCategory1__c &&
      data.KyogaCategory1__c == compObj.KyogaCategory1__c &&
      data.StbKobetuId1__c == compObj.StbKobetuId1__c &&
      data.DntNo1__c == compObj.DntNo1__c &&
      data.SenroName1__c == compObj.SenroName1__c &&
      data.DntNo1ManualInput__c == compObj.DntNo1ManualInput__c &&
      data.DntCategory2__c == compObj.DntCategory2__c &&
      data.KyogaCategory2__c == compObj.KyogaCategory2__c &&
      data.StbKobetuId2__c == compObj.StbKobetuId2__c &&
      data.DntNo2__c == compObj.DntNo2__c &&
      data.SenroName2__c == compObj.SenroName2__c &&
      data.DntNo2ManualInput__c == compObj.DntNo2ManualInput__c));
  if (result.length > 0) {
    return true;
  }

  result = items.filter((data) =>
    ( data.DntCategory1__c == compObj.DntCategory2__c &&
    data.KyogaCategory1__c == compObj.KyogaCategory2__c &&
    data.StbKobetuId1__c == compObj.StbKobetuId2__c &&
    data.DntNo1__c == compObj.DntNo2__c &&
    data.SenroName1__c == compObj.SenroName2__c &&
    data.DntNo1ManualInput__c == compObj.DntNo2ManualInput__c &&
    data.DntCategory2__c == compObj.DntCategory1__c &&
    data.KyogaCategory2__c == compObj.KyogaCategory1__c &&
    data.StbKobetuId2__c == compObj.StbKobetuId1__c &&
    data.DntNo2__c == compObj.DntNo1__c &&
    data.SenroName2__c == compObj.SenroName1__c &&
    data.DntNo2ManualInput__c == compObj.DntNo1ManualInput__c));
  if (result.length > 0) {
    return true;
  }

  return false;
}

/**
 * 電柱番号表示フォーマット
 * @param {String} SenroCode  線路コード
 * @param {String} DntNoManualInput 電柱番号_自由入力
 * @param {String} K6KansnNo 6kV幹線No
 * @param {String} K6Bunk1 6kV分岐1
 * @param {String} K6Bunk2 6kV分岐2
 * @param {String} K6Bunk3 6kV分岐3
 * @param {String} K22SzbtNo 22kV支持物番号
 * @param {String} K22GatiCategory 22kV架地区分
 * @return {String} 表示用電柱番号
 */
export function dntNoFormat(
    SenroCode,
    DntNoManualInput,
    K6KansnNo,
    K6Bunk1,
    K6Bunk2,
    K6Bunk3,
    K22SzbtNo,
    K22GatiCategory,
) {
  const strBunkHiddenCode = ' 00';
  const strBunkHiddenCode2 = '00';
  // null,undefined対応
  SenroCode = SenroCode ? SenroCode : '';
  DntNoManualInput = DntNoManualInput ? DntNoManualInput : '';
  K6KansnNo = K6KansnNo ? K6KansnNo : '';
  K6Bunk1 = K6Bunk1 ? K6Bunk1 : '';
  K6Bunk2 = K6Bunk2 ? K6Bunk2 : '';
  K6Bunk3 = K6Bunk3 ? K6Bunk3 : '';
  K22SzbtNo = K22SzbtNo ? K22SzbtNo : '';
  K22GatiCategory = K22GatiCategory ? K22GatiCategory : '';

  // 6k単独、6k22k併架の場合(6kｖ幹線Noが有る)
  if (!isNullOrWhiteSpace(K6KansnNo)) {
    return K6KansnNo +
      ((isNullOrWhiteSpace(K6Bunk1) || K6Bunk1 == strBunkHiddenCode || K6Bunk1 == strBunkHiddenCode2) ? '' : (' '+ K6Bunk1)) +
      ((isNullOrWhiteSpace(K6Bunk2) || K6Bunk2 == strBunkHiddenCode || K6Bunk2 == strBunkHiddenCode2) ? '' : (' '+ K6Bunk2)) +
      ((isNullOrWhiteSpace(K6Bunk3) || K6Bunk3 == strBunkHiddenCode || K6Bunk3 == strBunkHiddenCode2) ? '' : (' '+ K6Bunk3));
  } else {
  // 22k単独の場合(6kv幹線Noが無いかつ22kV支持物番号が有る)
    if (!isNullOrWhiteSpace(K22SzbtNo)) {
      return K22SzbtNo+K22GatiCategory;
    } else { // 自由入力の場合(6kv幹線Noが無いかつ22kV支持物番号が無い)
      return DntNoManualInput;
    }
  }
}

/**
* 線路名表示フォーマット
* @param {String} SenroName 線路名
* @param {String} SenroNameKana 線路名カナ
* @param {String} SenroCode 線路コード
* @return {String} 表示用線路名
*/
export function senroNameFormat(
    SenroName,
    SenroNameKana,
    SenroCode,
) {
// null,undefined対応
  SenroName = SenroName ? SenroName : '';
  SenroNameKana = SenroNameKana ? SenroNameKana : '';
  SenroCode = SenroCode ? SenroCode : '';

  let retVal;
  if (!isNullOrWhiteSpace(SenroName)) {
    retVal = SenroName;
  } else {
    retVal = SenroNameKana;
  }
  if (!isNullOrWhiteSpace(SenroCode)) {
    retVal += '(' + SenroCode + ')';
  }
  return retVal;
}

/**
 * KMS-apiの戻り値からエラーコードを取得する
 * @param {object} axiosResponse
 * @return {*} API_CODE
 */
export function getErrorCode(axiosResponse) {
  return axiosResponse ? axiosResponse.data ? axiosResponse.data.body ?
  axiosResponse.data.body.errorCode : undefined : undefined : undefined;
}

/**
 * KMS-apiの戻り値からエラーメッセージを取得する
 * @param {object} axiosResponse
 * @return {array} エラーメッセージ一覧
 */
export function getErrorMessages(axiosResponse) {
  const data = axiosResponse ?
                axiosResponse.data ?
                  axiosResponse.data.body ?
                     axiosResponse.data.body.data ?
                      axiosResponse.data.body.data :
                      null : null : null : null;
  return data && Object.hasOwnProperty.call(data, 'messages') ?
      data.messages : [];
}

/**
 * KMS-apiの戻り値からエラーメッセージを取得する
 * @param {string} errorCode
 * @param {array} msgValues CE0052の埋込文字列
 * @return {array} エラーメッセージ
 */
export function convertErrCodeToMsg(errorCode, msgValues=['データ', '処理']) {
  let errorMsg = {};

  if (errorCode == API_CODE.SUCCESS) {
    // 成功
    return errorMsg;
  }

  if (errorCode == API_CODE.ERROR_REGIST ||
    errorCode == API_CODE.ERROR_UPDATE) {
    // 登録エラー、更新エラー
    errorMsg = {id: 'CE0052', values: msgValues};
  } else if (errorCode == API_CODE.ERROR_GET ||
    errorCode == API_CODE.ERROR_RETRIEVE) {
    // 取得エラー、ID指定取得エラー
    errorMsg = {id: 'CE0052', values: msgValues};
  } else if (errorCode == API_CODE.ERROR_DELETE) {
    // 削除エラー
    errorMsg = {id: 'CE0052', values: msgValues};
  } else if (errorCode == API_CODE.ERROR_FILE_UPDATE) {
    // ファイルアップデートエラー
    errorMsg = {id: 'CE0099'};
  } else if (errorCode == API_CODE.ERROR_VIRUS) {
    // ウィルスチェックエラー
    errorMsg = {id: 'CE0167'};
  } else if (errorCode == API_CODE.ERROR_PERMISSION_DENIED) {
    // 権限不足エラー
    errorMsg = {id: 'CE0056', values: ['画面']};
  } else if (errorCode == API_CODE.ERROR_GET_USER_DATA) {
    // Cognitoユーザーデータ取得失敗
    errorMsg = {id: 'CE0052', values: msgValues};
  } else if (errorCode == API_CODE.ERROR_VALIDATION) {
    // バリデーションエラー
    errorMsg = {id: 'CE0050'};
  } else if (errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
    // 最終更新日時不一致
    errorMsg = {id: 'CE0048'};
  } else {
    // 上記以外はシステムエラー
    errorMsg = {id: 'CS0001'};
  }
  return errorMsg;
}

/**
 * KMS-apiの戻り値を解析し、結果を返す
 * @param {object} axiosResponse axiosの戻り値
 * @param {array} msgValues 処理失敗メッセージ(CE0052)に埋め込む文字列
 * @return {object} API実行結果
 */
export function getResponseResult(axiosResponse, msgValues) {
  let data = null;
  let _errorCode = getErrorCode(axiosResponse);
  if (_errorCode == undefined || _errorCode == null ||
      _errorCode == API_CODE.SUCCESS) {
    const _messages = getErrorMessages(axiosResponse);
    // 成功か、入力チェック結果を返す
    return {success: true, errorCode: _errorCode, messages: _messages};
  }
  // jsforceのエラー、apexのエラーを返す
  try {
    data = axiosResponse ?
      axiosResponse.data ?
        axiosResponse.data.body ?
          axiosResponse.data.body.data ?
            axiosResponse.data.body.data :
            null : null : null : null;
    let jsonObj = null;
    if (data != null) {
      // apexの戻り値だったらparseできる
      jsonObj = JSON.parse(data);
    }
    let sfErrorCode = null;
    if (jsonObj != null && Object.hasOwnProperty.call(jsonObj, 'errorCode')) {
      sfErrorCode = jsonObj.errorCode;
    }
    // apexのエラーをawsのエラーに変換
    if (sfErrorCode == SF_API_CODE.INPUT_PARAM_ERR) {
      // 入力パラメータ不正
      // aws apiで設定しているerrorCodeを使用する
    } else if (sfErrorCode == SF_API_CODE.UPDATE_ERR) {
      // 更新処理失敗
      // aws apiで設定しているerrorCodeを使用する
    } else if (sfErrorCode == SF_API_CODE.LAST_MODIFIED_DATE_ERR) {
      // 最終更新日時不一致
      _errorCode = API_CODE.ERROR_LAST_MODIFIED_DATE;
    } else if (sfErrorCode == SF_API_CODE.SYSTEM_ERR) {
      // システムエラー
      // aws apiで設定しているerrorCodeを使用する
    } else {
      // ignore
      // sfErrorCodeがないときはaws apiで設定しているerrorCodeを使用する
    }
  } catch (err) {
    // ignore
  }
  // _errorCodeをエラーメッセージに変換
  let _errorMsgs = [];
  // axiosResponse.data.body.dataにメッセージIDの配列が設定されていたらmessagesに設定する
  if (data != null && Array.isArray(data) && data.length > 0 &&
      Object.hasOwnProperty.call(data[0], 'id')) {
    _errorMsgs = [].concat(data);
  } else {
    const _errorMsg = convertErrCodeToMsg(_errorCode, msgValues);
    if (_errorMsg && Object.keys(_errorMsg).length > 0) {
      _errorMsgs.push(_errorMsg);
    }
  }
  return {success: false, errorCode: _errorCode, messages: _errorMsgs};
}

/**
 * 比較する電柱が同じかチェックする
 * 比較する項目は以下の通り。
 * 電柱区分、共架区分、設備個別ID、２５桁電柱番号、線路名、自由入力電柱番号
 * @param {object} cmp1 電柱
 * @param {object} cmp2 一覧に追加する電柱
 * @return {bool} 結果 true:同じ電柱 false:異なる電柱
 */
export function isSameContractDnt(cmp1, cmp2) {
  let error = false;
  let result = false;

  let _cmp1 = {};
  let _cmp2 = {};
  Object.assign(_cmp1, cmp1);
  Object.assign(_cmp2, cmp2);

  for (const key of ['DntCategory__c', 'KyogaCategory__c',
    'StbKobetuId__c', 'DntNo__c', 'SenroName__c', 'DntNoManualInput__c']) {
    if (!Object.hasOwnProperty.call(_cmp1, key) ||
        !Object.hasOwnProperty.call(_cmp2, key)) {
      error = true;
      break;
    }
    let data1 = _cmp1[key];
    let data2 = _cmp2[key];
    if (typeof _cmp1[key] === 'string' || _cmp1[key] instanceof String) {
      data1 = _cmp1[key].trim();
    }
    if (typeof _cmp2[key] === 'string' || _cmp2[key] instanceof String) {
      data2 = _cmp2[key].trim();
    }
    // 比較するためにnull,undefinedは空文字にする
    _cmp1[key] = data1 ? data1 : '';
    _cmp2[key] = data2 ? data2 : '';
  }
  if (error) {
    return result;
  }

  if (_cmp1.DntCategory__c == _cmp2.DntCategory__c &&
    _cmp1.KyogaCategory__c == _cmp2.KyogaCategory__c &&
    _cmp1.StbKobetuId__c == _cmp2.StbKobetuId__c &&
    _cmp1.DntNo__c == _cmp2.DntNo__c &&
    _cmp1.SenroName__c == _cmp2.SenroName__c &&
    _cmp1.DntNoManualInput__c == _cmp2.DntNoManualInput__c) {
    result = true;
  }
  return result;
};

/**
 * 比較する径間が同じかチェックする
 * 比較する項目は以下の通り。
 * 電柱区分、共架区分、設備個別ID、２５桁電柱番号、線路名、自由入力電柱番号
 * @param {object} cmp1 径間
 * @param {object} cmp2 一覧に追加する径間
 * @return {bool} 結果 true:同じ径間 false:異なる径間
 */
export function isSameSenStb(cmp1, cmp2) {
  let error = false;
  let result = false;

  let _cmp1 = {};
  let _cmp2 = {};
  Object.assign(_cmp1, cmp1);
  Object.assign(_cmp2, cmp2);

  for (const key of [
    'DntCategory1__c', 'DntCategory2__c', 'KyogaCategory1__c', 'KyogaCategory2__c',
    'StbKobetuId1__c', 'StbKobetuId2__c', 'DntNo1__c', 'DntNo2__c',
    'SenroName1__c', 'SenroName2__c', 'DntNo1ManualInput__c', 'DntNo2ManualInput__c']) {
    if (!Object.hasOwnProperty.call(_cmp1, key) ||
        !Object.hasOwnProperty.call(_cmp2, key)) {
      error = true;
      break;
    }
    let data1 = _cmp1[key];
    let data2 = _cmp2[key];
    if (typeof _cmp1[key] === 'string' || _cmp1[key] instanceof String) {
      data1 = _cmp1[key].trim();
    }
    if (typeof _cmp2[key] === 'string' || _cmp2[key] instanceof String) {
      data2 = _cmp2[key].trim();
    }
    // 比較するためにnull,undefinedは空文字にする
    _cmp1[key] = data1 ? data1 : '';
    _cmp2[key] = data2 ? data2 : '';
  }
  if (error) {
    return result;
  }

  // どちらかの電柱が'未設定'の場合、異なる電柱とみなす
  if ((_cmp1.DntNo1__c == '' && _cmp1.DntNo1ManualInput__c == '') ||
  (_cmp1.DntNo2__c == '' && _cmp1.DntNo2ManualInput__c == '') ||
  (_cmp2.DntNo1__c == '' && _cmp2.DntNo1ManualInput__c == '') ||
  (_cmp2.DntNo2__c == '' && _cmp2.DntNo2ManualInput__c == '')) {
    return result;
  }

  if ((_cmp1.DntCategory1__c == _cmp2.DntCategory1__c &&
    _cmp1.KyogaCategory1__c == _cmp2.KyogaCategory1__c &&
    _cmp1.StbKobetuId1__c == _cmp2.StbKobetuId1__c &&
    _cmp1.DntNo1__c == _cmp2.DntNo1__c &&
    _cmp1.SenroName1__c == _cmp2.SenroName1__c &&
    _cmp1.DntNo1ManualInput__c == _cmp2.DntNo1ManualInput__c &&
    _cmp1.DntCategory2__c == _cmp2.DntCategory2__c &&
    _cmp1.KyogaCategory2__c == _cmp2.KyogaCategory2__c &&
    _cmp1.StbKobetuId2__c == _cmp2.StbKobetuId2__c &&
    _cmp1.DntNo2__c == _cmp2.DntNo2__c &&
    _cmp1.SenroName2__c == _cmp2.SenroName2__c &&
    _cmp1.DntNo2ManualInput__c == _cmp2.DntNo2ManualInput__c) ||
    (_cmp1.DntCategory1__c == _cmp2.DntCategory2__c &&
      _cmp1.KyogaCategory1__c == _cmp2.KyogaCategory2__c &&
      _cmp1.StbKobetuId1__c == _cmp2.StbKobetuId2__c &&
      _cmp1.DntNo1__c == _cmp2.DntNo2__c &&
      _cmp1.SenroName1__c == _cmp2.SenroName2__c &&
      _cmp1.DntNo1ManualInput__c == _cmp2.DntNo2ManualInput__c &&
      _cmp1.DntCategory2__c == _cmp2.DntCategory1__c &&
      _cmp1.KyogaCategory2__c == _cmp2.KyogaCategory1__c &&
      _cmp1.StbKobetuId2__c == _cmp2.StbKobetuId1__c &&
      _cmp1.DntNo2__c == _cmp2.DntNo1__c &&
      _cmp1.SenroName2__c == _cmp2.SenroName1__c &&
      _cmp1.DntNo2ManualInput__c == _cmp2.DntNo1ManualInput__c)) {
    result = true;
  }
  return result;
};

/**
 * 径間の第一電柱と第二電柱が同じかチェックする
 * 比較する項目は以下の通り。
 * 電柱区分、共架区分、設備個別ID、２５桁電柱番号、線路名、自由入力電柱番号
 * @param {object} cmp1 第一電柱
 * @param {object} cmp2 第二電柱
 * @return {bool} 結果 true:同じ電柱 false:異なる電柱
 */
export function isSameContractDntInSenStb(cmp1, cmp2) {
  let error = false;
  let result = false;

  let _cmp1 = {};
  let _cmp2 = {};
  Object.assign(_cmp1, cmp1);
  Object.assign(_cmp2, cmp2);

  for (const key of ['DntCategory1__c', 'KyogaCategory1__c',
    'StbKobetuId1__c', 'DntNo1__c', 'SenroName1__c', 'DntNo1ManualInput__c']) {
    if (!Object.hasOwnProperty.call(_cmp1, key) ) {
      error = true;
      break;
    }
    let data1 = _cmp1[key];
    if (typeof _cmp1[key] === 'string' || _cmp1[key] instanceof String) {
      data1 = _cmp1[key].trim();
    }
    // 比較するためにnull,undefinedは空文字にする
    _cmp1[key] = data1 ? data1 : '';
  }
  for (const key of ['DntCategory2__c', 'KyogaCategory2__c',
    'StbKobetuId2__c', 'DntNo2__c', 'SenroName2__c', 'DntNo2ManualInput__c']) {
    if (!Object.hasOwnProperty.call(_cmp2, key)) {
      error = true;
      break;
    }
    let data2 = _cmp2[key];
    if (typeof _cmp2[key] === 'string' || _cmp2[key] instanceof String) {
      data2 = _cmp2[key].trim();
    }
    // 比較するためにnull,undefinedは空文字にする
    _cmp2[key] = data2 ? data2 : '';
  }
  if (error) {
    return result;
  }

  if ( _cmp1.DntCategory1__c == _cmp2.DntCategory2__c &&
    _cmp1.KyogaCategory1__c == _cmp2.KyogaCategory2__c &&
    _cmp1.StbKobetuId1__c == _cmp2.StbKobetuId2__c &&
    _cmp1.DntNo1__c == _cmp2.DntNo2__c &&
    _cmp1.SenroName1__c == _cmp2.SenroName2__c &&
    _cmp1.DntNo1ManualInput__c == _cmp2.DntNo2ManualInput__c) {
    result = true;
  }
  return result;
};

/**
* チェック対象の電柱がＮＴＴ単柱・自営柱かチェックする
* @param {object} item チェック対象の電柱
* @return {bool} true:ＮＴＴ単柱・自営柱, false:ＮＴＴ単柱・自営柱以外
*/
export function isNTTDntOrSelfDnt(item) {
  let result = false;
  if (!item ||
    !Object.hasOwnProperty.call(item, 'KyogaCategory1__c') ||
    !Object.hasOwnProperty.call(item, 'KyogaCategory2__c')) {
    return result;
  }
  result = (item.KyogaCategory1__c == 'O' || item.KyogaCategory2__c == 'O');
  return result;
}

/**
 * 代表電柱同一事業所チェック
 * @param {object} order チェック対象申込
 * @param {array} contractDntList チェック対象申込電柱一覧
 * @return {array} エラーメッセージ
 */
export function checkMainDntNoNWZgsyo(order, contractDntList) {
  const messages = [];

  if (!order || !contractDntList) {
    return messages;
  }

  const NWZgsyoCode =
      Object.hasOwnProperty.call(order, 'MainDntNoNWZgsyo__r') &&
        order.MainDntNoNWZgsyo__r &&
        Object.hasOwnProperty.call(order.MainDntNoNWZgsyo__r, 'Code__c') ?
        order.MainDntNoNWZgsyo__r.Code__c : null;
  if (NWZgsyoCode) {
    const results = contractDntList.filter((data) =>
      Object.hasOwnProperty.call(data, 'NWZgsyo__r') &&
        data.NWZgsyo__r &&
        Object.hasOwnProperty.call(data.NWZgsyo__r, 'Code__c') &&
        data.NWZgsyo__r.Code__c != NWZgsyoCode);
    for (const result of results) {
      const dspDntNo =
        Object.hasOwnProperty.call(result, 'Dsp_DntNo__c') ?
         '（' + result.Dsp_DntNo__c + '）' : '';
      messages.push({
        id: 'CE0063',
        values: ['申込電柱' + dspDntNo, result.SerialNumber__c],
      });
    }
  }
  return messages;
};

/**
 * 代表電柱番号存在チェック
 * @param {object} order チェック対象申込
 * @param {array} contractDntList チェック対象申込電柱一覧
 * @return {array} エラーメッセージ
 */
export function checkMainDntNoInContractDnts(order, contractDntList) {
  const messages = [];

  if (!order || !contractDntList) {
    return messages;
  }

  const KoziPlaceMainDntNo =
    Object.hasOwnProperty.call(order, 'KoziPlaceMainDntNo__c') ?
                      order.KoziPlaceMainDntNo__c : null;
  let results = [];
  if (KoziPlaceMainDntNo) {
    results = contractDntList.filter(
        (data) => (data.DntNo__c && KoziPlaceMainDntNo == data.DntNo__c) ||
          (data.DntNoManualInput__c &&
            KoziPlaceMainDntNo == data.DntNoManualInput__c));
  }
  if (!KoziPlaceMainDntNo || results.length == 0) {
    messages.push({
      id: 'CE0033',
      values: ['代表電柱', '申込電柱'],
    });
  }

  return messages;
};

/**
 * 添付写真存在チェック
 * @param {object} photo 写真の存在有無
 * @param {object} contractDnt チェック対象契約電柱
 * @param {array} warningMessages エラーメッセージ格納
 */
export function checkPhotoExists(photo, contractDnt, warningMessages) {
  if (contractDnt == null || warningMessages == null) {
    return;
  }
  // どの機能も全景写真（施工後）のみ
  let photoResult = {zenkei: false};
  if (photo) {
    Object.assign(photoResult, photo);
  }
  for (const key in synkPhotoItems) {
    if (Object.hasOwnProperty.call(photoResult, key)) {
      if (!photoResult[key]) {
        warningMessages.push({id: 'CE0134', values: [synkPhotoItems[key], contractDnt.SerialNumber__c]});
      }
    }
  }
}

/**
 * 最大削除件数取得
 * @return {Integer} 最大削除件数
 */
export function getMaxDeleteNum() {
  let maxCount = conf.MAX_DELETE_NUM;
  return maxCount != undefined || maxCount != null ? maxCount : 50;
};

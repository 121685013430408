import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardHeader: {
    borderBottom: '1px solid grey',
  },
}));

function CustomCard({title, isExpanded, children}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      {isExpanded &&
        <React.Fragment>
          <CardHeader
            titleTypographyProps={{variant: 'h6'}}
            title={title}
            className={classes.cardHeader}
            action={
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {children}
            </CardContent>
          </Collapse>
        </React.Fragment>
      }
      {!isExpanded &&
        <React.Fragment>
          <CardHeader
            titleTypographyProps={{variant: 'h6'}}
            title={title}
            className={classes.cardHeader}
          />
          <CardContent>
            {children}
          </CardContent>
        </React.Fragment>
      }
    </Card>
  );
};

CustomCard.propTypes = {
  title: PropTypes.object,
  isExpanded: PropTypes.bool,
  children: PropTypes.array,
};

export default CustomCard;

/**
 * 可否判定申込の申込電柱 相関チェック
 * @param {object} order チェック対象申込
 * @param {array} contractDntList チェック対象
 * @return {array} エラーメッセージ
 */
export function correlationCheck(order, contractDntList) {
  const messages = [];
  let result = 0;
  const confirmFlg =
        [order['CheckJiko1__c'], order['CheckJiko2__c'], order['CheckJiko3__c']];
  for (const contractDnt of contractDntList) {
    // 最接近電力設備との離隔/離隔距離（m）
    result = closestDrStbRikakCorrelationCheck(
        contractDnt.ClosestDrStbName__c,
        contractDnt.ClosestDrStbRikakOrder__c,
        confirmFlg);
    if (!result.success) {
      messages.push({
        id: result.messageId,
        values: ['最接近電力設備との離隔/離隔距離（m）', contractDnt.SerialNumber__c],
      });
    }
    // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
    result = drHksnKiknCrossingRikakCorrelationCheck(
        contractDnt.TsnHksnUmu__c,
        contractDnt.DrHksnKiknCrossingRikak__c,
        confirmFlg);
    if (!result.success) {
      messages.push({
        id: result.messageId,
        values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）', contractDnt.SerialNumber__c],
      });
    }
  }
  return messages;
};

/**
 * 取付竣工の申込電柱 相関チェック
 * @param {object} order チェック対象申込
 * @param {array} contractDntList チェック対象
 * @return {array} エラーメッセージ
 */
export function correlationCheckSyunko(order, contractDntList) {
  const messages = [];
  let result = 0;
  const confirmFlg =
        [order['SyunkoReportCheckJiko1__c'], order['SyunkoReportCheckJiko2__c']];
  for (const contractDnt of contractDntList) {
    // 工事取止の場合、チェックしない
    if (contractDnt.KoziUndo__c == true) {
      continue;
    }
    // 最接近電力設備との離隔/離隔距離（m）
    result = closestDrStbRikakSynkoCorrelationCheck(
        contractDnt.ClosestDrStbName__c,
        contractDnt.ClosestDrStbRikakSyunko__c ?
        contractDnt.ClosestDrStbRikakSyunko__c : 0.00,
        confirmFlg);
    if (!result.success) {
      messages.push({
        id: result.messageId,
        values: ['最接近電力設備との離隔/離隔距離（m）（施工後）', contractDnt.SerialNumber__c],
      });
    }
    // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
    result = drHksnKiknCrossingRikakSynkoCorrelationCheck(
        contractDnt.TsnHksnUmu__c,
        contractDnt.DrHksnKiknCrossRikakSynk__c ?
        contractDnt.DrHksnKiknCrossRikakSynk__c : 0.00,
        confirmFlg);
    if (!result.success) {
      messages.push({
        id: result.messageId,
        values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）', contractDnt.SerialNumber__c],
      });
    }
  }
  return messages;
};

/**
 * 線設備（径間） 相関チェック
 * @param {string} process 処理名 propriety:可否判定申込,syunko:取付の竣工届,correct:訂正取付申込
 * @param {object} order チェック対象申込
 * @param {array} senStbList チェック対象
 * @return {array} エラーメッセージ
 */
export function correlationCheckSenStb(process, order, senStbList) {
  const messages = [];
  if (process == 'propriety') {
    let confirmFlg =
      [order['CheckJiko1__c'], order['CheckJiko2__c'], order['CheckJiko3__c']];
    for (const senStb of senStbList) {
      // 最接近電力設備との離隔/離隔距離（m）
      let result = closestDrStbRikakKeikanCorrelationCheck(
          senStb.ClosestDrStbName__c,
          senStb.ClosestDrStbRikakOrder__c,
          confirmFlg);
      if (!result.success) {
        messages.push({
          id: result.messageId,
          values: ['最接近電力設備との離隔/離隔距離（m）', senStb.SerialNumber__c],
        });
      }
    }
  }
  if (process == 'syunko') {
    let confirmFlg =
        [order['SyunkoReportCheckJiko1__c'], order['SyunkoReportCheckJiko2__c']];
    for (const senStb of senStbList) {
      // 工事取止の場合、チェックしない
      if (Object.hasOwnProperty.call(senStb, 'KoziUndo') &&
          senStb.KoziUndo == true) {
        continue;
      }
      // 最接近電力設備との離隔/離隔距離（m）
      let result = closestDrStbRikakKeikanSynkoCorrelationCheck(
          senStb.ClosestDrStbName__c,
          senStb.DrStbRikakSyunko__c,
          confirmFlg);
      if (!result.success) {
        messages.push({
          id: result.messageId,
          values: ['最接近電力設備との離隔/離隔距離（m）（施工後）', senStb.SerialNumber__c],
        });
      }
    }
  }
  if (process == 'correct') {
    let confirmFlg =
      [order['CheckJiko1__c'], order['CheckJiko2__c'], order['CheckJiko3__c']];
    for (const senStb of senStbList) {
      // 最接近電力設備との離隔/離隔距離（m）
      let result = closestDrStbRikakKeikanCorrelationCheck(
          senStb.ClosestDrStbName__c,
          senStb.DrStbRikakSyunko__c,
          confirmFlg);
      if (!result.success) {
        messages.push({
          id: result.messageId,
          values: ['最接近電力設備との離隔/離隔距離（m）', senStb.SerialNumber__c],
        });
      }
    }
  }

  return messages;
}

/**
 * 訂正取付申込の申込電柱 相関チェック
 * @param {object} order チェック対象申込
 * @param {array} contractDntList チェック対象
 * @return {array} エラーメッセージ
 */
export function correlationCorrectCheck(order, contractDntList) {
  const messages = [];
  let result = 0;
  const confirmFlg =
        [order['CheckJiko1__c'], order['CheckJiko2__c'], order['CheckJiko3__c']];
  for (const contractDnt of contractDntList) {
    // 最接近電力設備との離隔/離隔距離（m）
    result = closestDrStbRikakCorrelationCheck(
        contractDnt.ClosestDrStbName__c,
        contractDnt.ClosestDrStbRikakSyunko__c,
        confirmFlg);
    if (!result.success) {
      messages.push({
        id: result.messageId,
        values: ['最接近電力設備との離隔/離隔距離（m）', contractDnt.SerialNumber__c],
      });
    }
    // // 他の共架設備との離隔/上部通信線（m）
    // result = kyogabtTsnsnCorrelationCheck(
    //     contractDnt.KyogabtRikakTopTsnsnSyunko__c);
    // if (!result.success) {
    //   messages.push({
    //     id: result.messageId,
    //     values: ['他の共架設備との離隔/上部通信線（m）', contractDnt.SerialNumber__c],
    //   });
    // }
    // // 他の共架設備との離隔/下部通信線（m）
    // result = kyogabtTsnsnCorrelationCheck(
    //     contractDnt.KyogabtRikakBottomTsnsnSyunko__c);
    // if (!result.success) {
    //   messages.push({
    //     id: result.messageId,
    //     values: ['他の共架設備との離隔/下部通信線（m）', contractDnt.SerialNumber__c],
    //   });
    // }
    // // 通信引込線/②通信引込線と電柱との離隔（m）
    // result = tsnHksnDntRikakCorrelationCheck(
    //     contractDnt.TsnHksnUmu__c,
    //     contractDnt.TsnHksnDntRikakSyunko__c);
    // if (!result.success) {
    //   messages.push({
    //     id: result.messageId,
    //     values: ['通信引込線/②通信引込線と電柱との離隔（m）', contractDnt.SerialNumber__c],
    //   });
    // }
    // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
    result = drHksnKiknCrossingRikakCorrelationCheck(
        contractDnt.TsnHksnUmu__c,
        contractDnt.DrHksnKiknCrossRikakSynk__c,
        confirmFlg);
    if (!result.success) {
      messages.push({
        id: result.messageId,
        values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）', contractDnt.SerialNumber__c],
      });
    }
  }
  return messages;
};

/**
 * 可否判定申込の「最接近電力設備との離隔」の相関チェック
 * @param {string} stbCode 設備名称のコード
 * @param {number} value 離隔距離（m）
 * @param {array} check [確認事項1, 確認事項2, 確認事項3] 確認事項1～3はbool
 * @return {object} チェック結果{success: true or false, messageId: メッセージID}
 */
function closestDrStbRikakCorrelationCheck(
    stbCode, value, check=[false, false, false]) {
  const result = {success: true, messageId: ''};
  if (!stbCode || value == null) {
    return result;
  }
  switch (stbCode) {
    // 低圧：高圧絶縁電線,低圧：特別高圧絶縁電線,低圧：ケーブル
    case '01':
    case '02':
    case '03':
      // 0.30m以上->OK
      if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
    // 低圧：低圧絶縁電線,低圧：引込線,低圧：引込線装置（ｱｰﾑﾀｲを除く）
    case '04':
    case '05':
    case '06':
      // 0.75m以上->OK
      // 0.60m以上0.75m未満かつ確認事項1,2=true->OK
      // 0.30m以上0.60m未満かつ確認事項1～3=true->OK
      if (value >= 0.6 && value < 0.75 &&
          (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.3 && value < 0.6 &&
        (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 高圧：ケーブル
    case '07':
      // 0.50m以上->OK
      // 0.30m以上0.50m未満かつ確認事項1～3=true->OK
      if (value >= 0.3 && value < 0.5 &&
        (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 高圧：高圧絶縁電線,高圧：引込線,高圧：変圧器装置（ｱｰﾑﾀｲを除く）
    case '08':
    case '09':
    case '10':
      // 1.50m以上->OK
      // 1.00m以上1.50m未満かつ確認事項1～2=true->OK
      // 0.60m以上1.00m未満かつ確認事項1～3=true->OK
      if (value >= 1.0 && value < 1.5 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.6 && value < 1.0 &&
            (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.6) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 通信：保安通信線等,通信：配電用遠制ケーブル
    case '11':
    case '12':
      // 0.30m以上->OK
      // 0.30m未満かつ確認事項1～2=true->OK
      if (value < 0.3 &&
      (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      }
      break;
    default:
      // do nothing
      break;
  }
  return result;
}

// /**
// * 可否判定申込/取付竣工の「他の共架物との離隔」の相関チェック
// * @param {number} value 上部通信線(m) or 下部通信線(m)
// * @return {object} チェック結果{success: true or false, messageId: メッセージID}
// */
// function kyogabtTsnsnCorrelationCheck(value) {
//   const result = {success: true, messageId: ''};
//   // 0.30m以上->OK
//   if (value != null && value < 0.3) {
//     result.success = false;
//     result.messageId = 'CW0067'; // 処理継続可
//   }
//   return result;
// }

// /**
// * 可否判定申込/取付竣工の「②通信引込線と電柱との離隔(m)」の相関チェック
// * @param {string} umu 通信引込線の有無(有:'1', 無:'0')
// * @param {number} value ②通信引込線と電柱との離隔(m)
// * @return {object} チェック結果{success: true or false, messageId: メッセージID}
// */
// function tsnHksnDntRikakCorrelationCheck(umu, value) {
//   const result = {success: true, messageId: ''};
//   // 通信引込線有かつ1m以上->OK
//   if (umu == '1' && value != null && value < 1) {
//     result.success = false;
//     result.messageId = 'CE0068';
//   }
//   return result;
// }

/**
* 可否判定申込の「①電力引込線との離隔(m)（径間途中・交差部分）」の相関チェック
* @param {string} umu 通信引込線の有無(有:'1', 無:'0')
* @param {number} value 離隔距離（m）
* @param {array} check [確認事項1, 確認事項2, 確認事項3] 確認事項1～3はbool
* @return {object} チェック結果{success: true or false, messageId: メッセージID}
*/
function drHksnKiknCrossingRikakCorrelationCheck(
    umu, value, check=[false, false, false]) {
  const result = {success: true, messageId: ''};
  // 通信引込線有かつ0.60m以上->OK
  // 通信引込線有かつ0.30m以上0.60m未満かつ確認事項1～2=true->OK
  // 通信引込線有かつ0.15m以上0.30m未満かつ確認事項1～3=true->OK
  if (umu == '1' && value != null) {
    if (value >= 0.3 && value < 0.6 &&
      (!check[0] || !check[1])) {
      result.success = false;
      result.messageId = 'CE0065';
    } else if (value >= 0.15 && value < 0.3 &&
      (!check[0] || !check[1] || !check[2])) {
      result.success = false;
      result.messageId = 'CE0065';
    } else if (value < 0.15) {
      result.success = false;
      result.messageId = 'CE0066';
    } else {
      // do nothing
    }
  }
  return result;
}

/**
* 取付竣工の「最接近電力設備との離隔」の相関チェック
* @param {string} stbCode 設備名称のコード
* @param {number} value 離隔距離（m）
* @param {array} check [確認事項1, 確認事項2] 確認事項1～2はbool
* @return {object} チェック結果{success: true or false, messageId: メッセージID}
*/
function closestDrStbRikakSynkoCorrelationCheck(
    stbCode, value, check=[false, false]) {
  const result = {success: true, messageId: ''};
  if (!stbCode || value == null) {
    return result;
  }
  switch (stbCode) {
    // 低圧：高圧絶縁電線,低圧：特別高圧絶縁電線,低圧：ケーブル
    case '01':
    case '02':
    case '03':
      // 0.30m以上->OK
      if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
    // 低圧：低圧絶縁電線,低圧：引込線,低圧：引込線装置（ｱｰﾑﾀｲを除く）
    case '04':
    case '05':
    case '06':
      // 0.75m以上->OK
      // 0.60m以上0.75m未満かつ確認事項1=true->OK
      // 0.30m以上0.60m未満かつ確認事項1,2=true->OK
      if (value >= 0.6 && value < 0.75 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.3 && value < 0.6 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 高圧：ケーブル
    case '07':
      // 0.50m以上->OK
      // 0.30m以上0.50m未満かつ確認事項1,2=true->OK
      if (value >= 0.3 && value < 0.5 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 高圧：高圧絶縁電線,高圧：引込線,高圧：変圧器装置（ｱｰﾑﾀｲを除く）
    case '08':
    case '09':
    case '10':
      // 1.50m以上->OK
      // 1.00m以上1.50m未満かつ確認事項1=true->OK
      // 0.60m以上1.00m未満かつ確認事項1,2=true->OK
      if (value >= 1.0 && value < 1.5 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.6 && value < 1.0 &&
          (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.6) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 通信：保安通信線等,通信：配電用遠制ケーブル
    case '11':
    case '12':
      // 0.30m以上->OK
      // 0.30m未満かつ確認事項1=true->OK
      if (value < 0.3 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      }
      break;
    default:
      // do nothing
      break;
  }
  return result;
}

/**
* 取付竣工の「①電力引込線との離隔(m)（径間途中・交差部分）」の相関チェック
* @param {string} umu 通信引込線の有無(有:'1', 無:'0')
* @param {number} value 離隔距離（m）
* @param {array} check [確認事項1, 確認事項2] 確認事項1～2はbool
* @return {object} チェック結果{success: true or false, messageId: メッセージID}
*/
function drHksnKiknCrossingRikakSynkoCorrelationCheck(
    umu, value, check=[false, false]) {
  const result = {success: true, messageId: ''};
  // 通信引込線有かつ0.60m以上->OK
  // 通信引込線有かつ0.30m以上0.60m未満かつ確認事項1=true->OK
  // 通信引込線有かつ0.15m以上0.30m未満かつ確認事項1,2=true->OK
  if (umu == '1' && value != null) {
    if (value >= 0.3 && value < 0.6 && !check[0]) {
      result.success = false;
      result.messageId = 'CE0065';
    } else if (value >= 0.15 && value < 0.3 &&
    (!check[0] || !check[1])) {
      result.success = false;
      result.messageId = 'CE0065';
    } else if (value < 0.15) {
      result.success = false;
      result.messageId = 'CE0066';
    } else {
    // do nothing
    }
  }
  return result;
}

/**
* 可否判定申込/訂正申込(径間)の「最接近電力設備との離隔」の相関チェック
* @param {string} stbCode 設備名称のコード
* @param {number} value 離隔距離（m）
* @param {array} check [確認事項1, 確認事項2, 確認事項3] 確認事項1～3はbool
* @return {object} チェック結果{success: true or false, messageId: メッセージID}
*/
function closestDrStbRikakKeikanCorrelationCheck(
    stbCode, value, check=[false, false, false]) {
  const result = {success: true, messageId: ''};
  if (!stbCode || value == null) {
    return result;
  }
  switch (stbCode) {
  // 低圧：高圧絶縁電線(同一柱に設置),低圧：特別高圧絶縁電線(同一柱に設置),低圧：ケーブル(同一柱に設置)
    case '01':
    case '02':
    case '03':
      // 0.30m以上->OK
      if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
      // 低圧：低圧絶縁電線(同一柱に設置)
    case '04':
      // 0.75m以上->OK
      // 0.60m以上0.75m未満かつ確認事項1,2=true->OK
      // 0.30m以上0.60m未満かつ確認事項1～3=true->OK
      if (value >= 0.6 && value < 0.75 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.3 && value < 0.6 &&
        (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
    // 低圧：引込線(同一柱に設置),低圧：引込線装置（ｱｰﾑﾀｲを除く）(同一柱に設置)
    case '05':
    case '06':
    // 0.60m以上->OK
    // 0.30m以上0.60m未満かつ確認事項1,2=true->OK
    // 0.15m以上0.30m未満かつ確認事項1～3=true->OK
      if (value >= 0.3 && value < 0.6 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.15 && value < 0.3 &&
        (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.15) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
    // 高圧：ケーブル(同一柱に設置)
    case '07':
      // 0.50m以上->OK
      // 0.30m以上0.50m未満かつ確認事項1～3=true->OK
      if (value >= 0.3 && value < 0.5 &&
        (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
      // 高圧：高圧絶縁電線(同一柱に設置),高圧：引込線(同一柱に設置)
    case '08':
    case '09':
      // 1.50m以上->OK
      // 1.00m以上1.50m未満かつ確認事項1～2=true->OK
      // 0.60m以上1.00m未満かつ確認事項1～3=true->OK
      if (value >= 1.0 && value < 1.5 &&
          (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.6 && value < 1.0 &&
          (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.6) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
      // 通信：保安通信線等(同一柱に設置),通信：配電用遠制ケーブル (同一柱に設置)
    case '10':
    case '11':
    // 0.30m以上->OK
    // 0.30m未満かつ確認事項1～2=true->OK
      if (value < 0.3 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      }
      break;
      // 低圧：高圧絶縁電線(異なる柱に設置),低圧：特別高圧絶縁電線(異なる柱に設置),低圧：ケーブル(異なる柱に設置)
    case '12':
    case '13':
    case '14':
    // 0.30m以上->OK
    // 0.15以上0.30m未満かつ確認事項1～2=true->OK
      if (value >= 0.15 && value < 0.3 &&
         (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.15) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
      // 低圧：低圧絶縁電線(異なる柱に設置)
    case '15':
    // 0.60m以上->OK
    // 0.30m以上0.60m未満かつ確認事項1～3=true->OK
      if (value >= 0.3 && value < 0.6 &&
         (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
      // 低圧：引込線(異なる柱に設置),低圧：引込線装置（ｱｰﾑﾀｲを除く）(異なる柱に設置)
    case '16':
    case '17':
    // 0.60m以上->OK
    // 0.30m以上0.60m未満かつ確認事項1～2=true->OK
    // 0.15m以上0.30m未満かつ確認事項1～3=true->OK
      if (value >= 0.3 && value < 0.6 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.15 && value < 0.3 &&
        (!check[0] || !check[1] || !check[2])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.15) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
      // 高圧：ケーブル(異なる柱に設置)
    case '18':
    // 0.40m以上->OK
      if (value < 0.4) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
      // 高圧：高圧絶縁電線(異なる柱に設置),高圧：引込線(異なる柱に設置)
    case '19':
    case '20':
    // 0.80m以上->OK
      if (value < 0.8) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
      // 通信：保安通信線等(異なる柱に設置),通信：配電用遠制ケーブル (異なる柱に設置)
    case '21':
    case '22':
    // 0.30m以上->OK
    // 0.01m以上0.30m未満かつ確認事項1～2=true->OK
    // (接触不可なので、確認事項1～2=trueでも0.00mはNG)
      if (value >= 0.01 && value < 0.3 &&
      (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value == 0.0) {
        result.success = false;
        result.messageId = 'CE0065';
      } else {
      // do nothing
      }
      break;
    default:
      // do nothing
      break;
  }
  return result;
}

/**
* 取付竣工(径間)の「最接近電力設備との離隔」の相関チェック
* @param {string} stbCode 設備名称のコード
* @param {number} value 離隔距離（m）
* @param {array} check [確認事項1, 確認事項2] 確認事項1～2はbool
* @return {object} チェック結果{success: true or false, messageId: メッセージID}
*/
function closestDrStbRikakKeikanSynkoCorrelationCheck(
    stbCode, value, check=[false, false]) {
  const result = {success: true, messageId: ''};
  if (!stbCode || value == null) {
    return result;
  }
  switch (stbCode) {
    // 低圧：高圧絶縁電線(同一柱に設置),低圧：特別高圧絶縁電線(同一柱に設置),低圧：ケーブル(同一柱に設置)
    case '01':
    case '02':
    case '03':
    // 0.30m以上->OK
      if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
    // 低圧：低圧絶縁電線(同一柱に設置)
    case '04':
    // 0.75m以上->OK
    // 0.60m以上0.75m未満かつ確認事項1=true->OK
    // 0.30m以上0.60m未満かつ確認事項1～2=true->OK
      if (value >= 0.6 && value < 0.75 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.3 && value < 0.6 &&
      (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
    // 低圧：引込線(同一柱に設置),低圧：引込線装置（ｱｰﾑﾀｲを除く）(同一柱に設置)
    case '05':
    case '06':
    // 0.60m以上->OK
    // 0.30m以上0.60m未満かつ確認事項1=true->OK
    // 0.15m以上0.30m未満かつ確認事項1～2=true->OK
      if (value >= 0.3 && value < 0.6 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.15 && value < 0.3 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.15) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
      // 高圧：ケーブル(同一柱に設置)
    case '07':
    // 0.50m以上->OK
    // 0.30m以上0.50m未満かつ確認事項1～2=true->OK
      if (value >= 0.3 && value < 0.5 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
    // 高圧：高圧絶縁電線(同一柱に設置),高圧：引込線(同一柱に設置)
    case '08':
    case '09':
    // 1.50m以上->OK
    // 1.00m以上1.50m未満かつ確認事項1=true->OK
    // 0.60m以上1.00m未満かつ確認事項1～2=true->OK
      if (value >= 1.0 && value < 1.5 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.6 && value < 1.0 &&
        (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.6) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
      // 通信：保安通信線等(同一柱に設置),通信：配電用遠制ケーブル (同一柱に設置)
    case '10':
    case '11':
      // 0.30m以上->OK
      // 0.30m未満かつ確認事項1=true->OK
      if (value < 0.3 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      }
      break;
      // 低圧：高圧絶縁電線(異なる柱に設置),低圧：特別高圧絶縁電線(異なる柱に設置),低圧：ケーブル(異なる柱に設置)
    case '12':
    case '13':
    case '14':
    // 0.30m以上->OK
    // 0.15以上0.30m未満かつ確認事項1=true->OK
      if (value >= 0.15 && value < 0.3 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.15) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
      // 低圧：低圧絶縁電線(異なる柱に設置)
    case '15':
      // 0.60m以上->OK
      // 0.30m以上0.60m未満かつ確認事項1～2=true->OK
      if (value >= 0.3 && value < 0.6 &&
          (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.3) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
        // do nothing
      }
      break;
      // 低圧：引込線(異なる柱に設置),低圧：引込線装置（ｱｰﾑﾀｲを除く）(異なる柱に設置)
    case '16':
    case '17':
      // 0.60m以上->OK
      // 0.30m以上0.60m未満かつ確認事項1=true->OK
      // 0.15m以上0.30m未満かつ確認事項1,2=true->OK
      if (value >= 0.3 && value < 0.6 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value >= 0.15 && value < 0.3 &&
      (!check[0] || !check[1])) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value < 0.15) {
        result.success = false;
        result.messageId = 'CE0066';
      } else {
      // do nothing
      }
      break;
      // 高圧：ケーブル(異なる柱に設置)
    case '18':
      // 0.40m以上->OK
      if (value < 0.4) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
      // 高圧：高圧絶縁電線(異なる柱に設置),高圧：引込線(異なる柱に設置)
    case '19':
    case '20':
      // 0.80m以上->OK
      if (value < 0.8) {
        result.success = false;
        result.messageId = 'CE0064';
      }
      break;
      // 通信：保安通信線等(異なる柱に設置),通信：配電用遠制ケーブル (異なる柱に設置)
    case '21':
    case '22':
      // 0.30m以上->OK
      // 0.01m以上0.30m未満かつ確認事項1=true->OK
      // (接触不可なので、確認事項1=trueでも0.00mはNG)
      if (value >= 0.01 && value < 0.3 && !check[0]) {
        result.success = false;
        result.messageId = 'CE0065';
      } else if (value == 0.0) {
        result.success = false;
        result.messageId = 'CE0065';
      } else {
      // do nothing
      }
      break;
    default:
    // do nothing
      break;
  }
  return result;
}

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as wjgGrid from '@grapecity/wijmo.grid';
import * as wijmo from '@grapecity/wijmo';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {Popup, InputDate} from '@grapecity/wijmo.react.input';
import * as wijmoInput from '@grapecity/wijmo.input';

import {getCodeFromGeneralPurposeMap, changeDateUtc,
  getComboName, changeDateFormatter, changeDateFormat, getErrorCode,
  API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {checkFileNameLength, checkFileSizeZero,
  checkSameName} from '../../../../../../common/fileOperations.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import CommonTheme from '../../../../../../Theme.js';
import {commonOperations} from '../../../../../../reducks/common';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import PaperPart from '../../../../../atoms/PaperPart.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import Required from '../../../../../atoms/RequiredMark.js';
import Upload from '../../../../../organisms/UploadCustom';

import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import {ConfirmWarningMessageArea} from '../../../../../molecules/WarningMessageArea.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// 申込テーブル検索時の検索対象のフィールド名
const appFields = [
  'Id', // 申込のId
  'RepairOrderNo__c', // 改修元申込番号
  'LastModifiedDate',
  'UpsyaUserId__c', // 更新者ユーザーID
  'KoziTitle__c', // 工事件名
  'OrderCategory__c', // 申込区分
  'KoziPlacePrefectures__c', // 工事場所_都道府県
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroName__c', // 工事場所_線路名
  'Dsp_KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KyogaZgsyaCode__c', // 共架事業者コード
  'RepairIraiDate__c', // 改修依頼日
  'KyogaZgsyoCode__c', // 共架事業所コード
  'KoziPlacePlace__c', // 場所
  'OrderNo__c', // 申込番号（可否判定申込番号 or 本申込番号）
  'OrderKyogaZgsya__c', // 申込共架事業者（会社のId）
  'OrderKyogaZgsya__r.Name', // 申込共架事業者名
  'OrderKyogaZgsya__r.Account__r.Name', // 申込共架事業所名
  'KyogaType__c', // 共架種別
  'toLabel(KyogaType__c)KyogaTypeName', // 共架種別の名称
  'KyogaTanto__c', // 共架担当者
  'StbType__c', // 設備種別
  'toLabel(StbType__c)StbTypeName', // 設備種別の名称
  'OrderStatus__c', // 申込ステータス
  'OrderType__c', // 申込種別
  'TemporarilySavedDate__c', // 一時保存日
  'MainDntNoNWZgsyo__c', // 代表電柱の中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  'MainDntNoNWZgsyo__r.Code__c', // 代表電柱の中電ＮＷ事業所コード
];

// 契約電柱テーブルの検索対象フィールド名
const orderDntFields = [
  'Id', // 契約電柱のId
  'LastModifiedDate',
  'UpsyaUserId__c', // 更新者ユーザーID
  'SyunkoDate__c', // 竣工日
  'KyogaType__c', // 共架種別
  'KyogaZgsyaCode__c', // 共架事業者コード
  'OrderNo__c', // 申込番号
  'KyogaZgsyoCode__c', // 共架事業所コード
  'Dsp_SenroName__c', // 線路名
  'SerialNumber__c', // 通し番号
  'StbType__c', // 設備種別
  'DntCategory__c', // 電柱区分
  'tolabel(DntCategory__c)DntCategory', // 電柱区分

  'NWZgsyo__c', // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  'NWZgsyo__r.Name', // 中電ＮＷ事業所名
  'NWZgsyo__r.Code__c', // 中電ＮＷ事業所コード
  'Dsp_DntNo__c', // 電柱番号
  'KyogaCategory__c', // 共架区分

  'KoziUndo__c', // 工事取止
  'RecordType.DeveloperName', // レコードタイプ
  'SyunkoReportAcceptedDate__c', // 竣工届受理日
  'ResubmitNecessity__c', // 再提出要否
  'tolabel(ResubmitNecessity__c)ResubmitNecessity', // 再提出要否
  'ResubmitReason__c', // 再提出理由
];

const updateDntParam = {
  Id: null,
  SyunkoDate__c: null, // 竣工日
  SyunkoReportAcceptedDate__c: null, // 竣工届受理日
  LastModifiedDate: null, // 更新年月日
  UpsyaUserId__c: null, // 更新ユーザID
};

/** 申込、添付ファイル名検索時の検索対象 */
const orderSubquery = [
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient',
      'ContentDocument.LatestPublishedVersion.FileType',
      'ContentDocument.LatestPublishedVersion.ContentDocumentId'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['A24'],
    },
    'table': 'ContentDocumentLinks',
  },
];

/**
 * 点設備竣工届申込登録(契約電柱)(改修依頼)
 * 画面ID:1487
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null,
      open: false,
      agree: false,
      warningMessages: [],
      hasError: false,
      syunkoFiles: [],
      syunkoDelFiles: [],
    };
    this.gridRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
    this.dateEditor = new wijmoInput.InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      if (prop == 'SyunkoDate__c') {
        // 竣工日
        let error = validateRule.required4(
            item.SyunkoDate__c);
        if (error) {
          return error;
        }
        error = validateRule.futureDate(
            changeDateFormat(item.SyunkoDate__c, 'YYYY-MM-DD'));
        if (error) {
          return error;
        }
      }
      return null;
    };
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    await this.props.doClearOrderContractDnt();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // 検索
      await this.searchData();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 申込確認ダイアログを表示
   */
  handleClickOpen = () => {
    this.setState({open: true});
  };

  /**
   * 申込確認ダイアログを閉じる
   */
  handleClose = () => {
    this.setState({open: false});
  };

  // 竣工結果未確認の行をグレーアウトする
  cellStyleFunction = (s, e, grid) => {
    if (e.panel.cellType == wjgGrid.CellType.Cell ||
       e.panel.cellType == wjgGrid.CellType.CellEdit) {
      // グリッドをスクロールすると、スタイルを再利用してしまう。wijmoの制限事項。
      // 竣工結果以外のセルのスタイルを変更しないように、
      // セルのスタイルをリセットしてから変更対象のセルを処理する。
      e.cell.style.backgroundColor = '';
      const col = s.columns[e.col];
      if ((col.binding == 'SyunkoReportAcceptedDate__c' || col.binding == 'ResubmitNecessity__c' ||
          col.binding == 'ResubmitReason__c') &&
          (!grid.editRange || !grid.editRange.contains(e.row, e.col))) {
        const acceptDateValue = grid.getCellData(e.row, 'SyunkoReportAcceptedDate__c');
        const rereportNeedOrNotValue = grid.getCellData(e.row, 'ResubmitNecessity__c');
        const rereportReasonValue = grid.getCellData(e.row, 'ResubmitReason__c');
        if (!acceptDateValue && !rereportNeedOrNotValue &&
          !rereportReasonValue) {
          e.cell.style.backgroundColor = 'gray';
        }
      }

      const data = s.rows[e.row].dataItem;
      // 工事取止チェックがONかつ申込ステータスが竣工検査中または完結の場合、工事取止した行を編集不可にしてグレーアウトする。
      if (data.KoziUndo__c == true && this.state.isDisabled) {
        e.cell.style.backgroundColor = 'gray';
      }
    }
  };

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'ResubmitNecessity__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'NecessityCategory'; // 再提出要否
      lb.itemFormatter = (index) => {
        let result = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return result ? result : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ
   * 竣工日
   * @param {*} sender
   */
  changeSyunkoDate(sender) {
    if (sender.value) {
      this.setState({SyunkoDate__c: sender.value});
    }
  }

  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
  editPopup(grid, e) {
    try {
      const ht = grid.hitTest(e);
      if (ht.panel === grid.rowHeaders) {
        const editItem = grid.rows[ht.row].dataItem;
        this.setState({
          editItem: editItem,
          SyunkoDate__c:
            (editItem.SyunkoDate__c &&
              editItem.SyunkoDate__c != null ?
              changeDateUtc(editItem.SyunkoDate__c) : null), // 竣工日
        });
        this.popup.show(true, (s) => {
          let _editItem = {};
          Object.assign(_editItem, this.state.editItem);
          if (s.dialogResult === 'wj-hide-ok') {
            // 変更ありとする
            this.setState({isModified: true});
            _editItem.SyunkoDate__c =
              (this.state.SyunkoDate__c ?
                changeDateFormat(this.state.SyunkoDate__c, 'YYYY-MM-DD') : null);
            this.gridRef.current.storeEditItem(_editItem, ht.row);
          }
          grid.focus();
          this.setState({editItem: null});
        });
      }
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 申込ボタン押下
  */
  doApply = async () => {
    try {
      if (!this.props.order.Id) {
        return;
      }
      if (!this.gridRef.current) {
        return;
      }
      const orderStatusKoziStartOk =
        getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
      if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusKoziStartOk) {
        return;
      }
      // 編集ありの場合、ユーザーに保存するか破棄するか確認
      const itemsModified = this.gridRef.current.isItemsModified();
      if (this.state.isModified || itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0161',
            values: ['申込'],
          },
          action: async () => {
            // 保存処理
            const ret = await this.insertOrUpdate();
            if (!ret.success) {
              this.props.doShowMessage({message: ret.messages});
              return;
            }

            // 再検索
            const searchResult = await this.searchData();
            if (!searchResult) {
              return;
            }
            // エラーがなければ申込処理を行う
            if (!this.isErrorInApply()) {
              this.handleClickOpen();
            }
          },
        });
        return;
      }
      // エラーがなければ申込処理を行う
      if (!this.isErrorInApply()) {
        this.handleClickOpen();
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込前の入力チェック
   * @return {bool} true: エラーあり, false:エラーなし
   */
  isErrorInApply = () => {
    let isError = false;
    if (this.props.setGmnWarning) {
      for (const gmnkey of ['1487_ten']) {
        if (Object.hasOwnProperty.call(
            this.props.setGmnWarning, gmnkey)) {
          isError = isError || this.props.setGmnWarning[gmnkey];
        }
      }
    }
    return isError;
  }

  /**
   * 申込処理実行
  */
  updateOrder = async () => {
    try {
      this.setState({open: false});

      const {userInfo, order, generalPurposeMap} = this.props;
      const orderStatusUpdate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus41', 2);
      const contractDntList = this.gridRef.current.getItems();
      const saveContractDntList = [];
      const updateConditions = [];
      for (const dnt of contractDntList) {
        // 更新項目設定
        const updateData = {};
        for (const key in updateDntParam) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            switch (key) {
              case 'SyunkoReportAcceptedDate__c':
                updateData[key] = null;
                break;
              case 'SyunkoDate__c':
                updateData[key] = (dnt[key] ? changeDateFormat(dnt[key], 'YYYY-MM-DD') : null);
                break;
              default:
                updateData[key] = dnt[key];
                break;
            }
          }
        }
        // 申込電柱一覧
        saveContractDntList.push(updateData);
        // 申込電柱更新条件
        updateConditions.push({
          Id: dnt.Id,
          LastModifiedDate: dnt.LastModifiedDate,
        });
      }
      // 申込電柱削除条件
      const deleteConditions = [];
      // 申込更新条件
      const orderConditions = {
        Id: order.Id,
        LastModifiedDate: order.LastModifiedDate,
      };
      // 申込
      const updateOrder = {
        Id: order.Id,
        OrderStatus__c: orderStatusUpdate,
        DaikoUserId__c: userInfo.DaikoUserId,
      };
      // 更新処理
      const response =await this.props.doSaveSyunkoContractDnts(
          updateConditions, deleteConditions, saveContractDntList,
          orderConditions, updateOrder, true);
      const resResult = getResponseResult(response, ['竣工届申込', '申込']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0009',
          values: ['竣工届申込'],
        },
        action: () => {
          //  申込が成功したら一覧へ遷移
          this.props.history.push(
              {pathname: '/RepairIrai/KoziDone/RepairIraiSyunkoReport/List'},
              null, this.props.history.option.CLEAR,
          );
        },
      });
    } catch {
      this.sysErr();
    }
  }

  /**
  * 必須チェック
  * @param {array} contractDntList チェック対象
  * @param {array} messages メッセージ格納用
  */
  isRequiredItem(contractDntList, messages) {
    for (const contractDnt of contractDntList) {
      // 竣工日
      if (!contractDnt.SyunkoDate__c) {
        messages.push({
          id: 'CE0017',
          values: ['竣工日(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // -未来日チェック
      if (contractDnt.SyunkoDate__c &&
          validateRule.futureDate(changeDateFormat(contractDnt.SyunkoDate__c, 'YYYY-MM-DD'))) {
        messages.push({
          id: 'CE0136',
          values: ['竣工日', contractDnt.SerialNumber__c],
        });
      }
    }
  }

  /**
  * 申込電柱の登録/更新処理
  */
  insertOrUpdate = async () => {
    const {order, userInfo} = this.props;
    const contractDntList = this.gridRef.current.getItems();
    const saveContractDntList = [];
    const updateConditions = [];
    for (const dnt of contractDntList) {
      // 更新項目設定
      const updateData = {};
      for (const key in updateDntParam) {
        if (Object.hasOwnProperty.call(dnt, key)) {
          switch (key) {
            case 'SyunkoReportAcceptedDate__c':
              updateData[key] = null;
              break;
            case 'SyunkoDate__c':
              updateData[key] = (dnt[key] ? changeDateFormat(dnt[key], 'YYYY-MM-DD') : null);
              break;
            default:
              updateData[key] = dnt[key];
              break;
          }
        }
      }
      // 申込電柱一覧
      saveContractDntList.push(updateData);
      // 申込電柱更新条件
      updateConditions.push({
        Id: dnt.Id,
        LastModifiedDate: dnt.LastModifiedDate,
      });
    }
    // 申込電柱削除条件
    const deleteConditions = [];
    // 申込更新条件
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: order.LastModifiedDate,
    };
    // 申込
    const updateOrder = {
      Id: order.Id,
      DaikoUserId__c: userInfo.DaikoUserId,
    };

    // SFアップロードファイル
    let uploadFiles = this.state.syunkoFiles != null ?
      this.state.syunkoFiles : [];
    let deleteFiles = this.state.syunkoDelFiles != null ?
      this.state.syunkoDelFiles : [];

    // 更新処理
    const resSave =await this.props.doSaveSyunkoContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder, false, uploadFiles, deleteFiles);
    const resResult = getResponseResult(resSave, ['竣工届申込', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
  */
  doSave = async () => {
    try {
      if (!this.state.isModified &&
          this.gridRef.current.itemsEdited().length == 0) {
        // 更新がなければ何もしない
        console.warn('更新されていません');
        return undefined;
      }

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        this.props.doShowMessage({message: ret.messages});
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 検索処理
   */
  async searchData() {
    try {
      const {orderId} = this.props;
      const {generalPurposeMap} = this.props;
      const orderStatusKoziStartOk =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2);

      // 申込IDに紐づく申込電柱を取得する
      const orderConditions = {
        Id: orderId, // Id
      };
      const dntConditions = {
        Order__c: orderId,
      };
      const appSortParams = {
        SerialNumber__c: 1,
      };
      const response =
        await this.props.doGetRepairOrderAndContractDntList(
            orderConditions, orderSubquery, appFields,
            dntConditions, orderDntFields, appSortParams, true);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['申込電柱', '取得'],
          },
        });
        return false;
      }

      let _syunkoFiles = [];
      let _syunkoDelFiles = [];
      // 添付ファイル名を設定
      if (this.props.order.ContentDocumentLinks) {
        for (const data of
          this.props.order.ContentDocumentLinks.records) {
          const items = data.ContentDocument.LatestPublishedVersion;
          if (items.AttachedFileType__c === 'A24') {
            // 添付（申込:改修工事竣工資料）
            _syunkoFiles.push({
              'AttachedFileType__c': items.AttachedFileType__c,
              'VersionData': items.VersionData,
              'PathOnClient': items.PathOnClient,
              'FileType': items.FileType,
              'ContentDocumentId': items.ContentDocumentId});
          }
        }
      }
      this.gridRef.current.setInitItems('1487_ten', this.props.contractDntList);

      // 警告チェック
      // 竣工待ち／工事開始可のときだけチェックする
      let _hasError = false;
      let warningMessages = [];
      if (this.props.order &&
        this.props.order.OrderStatus__c == orderStatusKoziStartOk) {
        const contractDntList = this.gridRef.current.getItems();
        const messages = [];

        // 必須チェック 添付は対象外
        this.isRequiredItem(contractDntList, messages);
        if (_syunkoFiles.length == 0) {
          messages.push({
            id: 'CE0018',
            values: ['竣工報告資料']},
          );
        }


        // 入力チェックエラー
        if (messages.length > 0) {
          _hasError = true;
          if (messages.length > 0) {
            warningMessages = messages;
          }
        }
      }
      this.setState({
        isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
          orderStatusKoziStartOk ? false : true) ||
          this.props.referenceMode, // 竣工待ち/工事開始可のみ編集可
        isModified: false,
        hasError: _hasError,
        warningMessages: warningMessages,
        syunkoFiles: _syunkoFiles,
        syunkoDelFiles: _syunkoDelFiles,
      });
      await this.props.doSetGmnWarning('1487_ten', _hasError);
      return true;
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込写真設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setSyunkoFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A24';
        _files.push(file);
      }
      this.setState({
        syunkoFiles: _files,
        syunkoDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧の更新時
   *
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction = (s, e) => {
    try {
      let isModified =false;
      if (this.gridRef &&
           this.gridRef.current &&
           this.gridRef.current.itemsEdited().length > 0) {
        isModified = true;
      }
      if (this.state.isModified != isModified) {
        this.setState({isModified: isModified});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  renderTable() {
    const {classes, generalPurposeMap} = this.props;

    const props = {
      rowHeaderType: 'edit',
      filterOn: true, // 列フィルター有効
      exceptFilters: ['edit'],
      headersVisibility: 'All', // 行ヘッダ、列ヘッダの表示設定
      allowSorting: 'None',
      allowDragging: 'Columns', // 行、列のD&D設定
      counterOn: false, // グリッドに設定したデータ件数表示有無を設定
      AddDeleteOn: false,
      frozenColumns: 4,
      style: {maxHeight: '300px'}, // グリッドのスタイル設定
      editPopupItems: this.editPopup,
      formatItemFunction: this.cellStyleFunction,
      validateEdits: false,
      getError: this.getError,
      refreshedFunction: this.refreshedFunction,
      filterChanging: this.filterChangingHandler,
      isReadOnly: this.state.isDisabled,
    };

    return (
      <div>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="uneditableCell" width={120} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="uneditableCell" width={95} isReadOnly={true}/>
            <FlexGridColumnGroup binding="DntCategory" header="電柱区分" dataType="String" cssClass="uneditableCell" width={65} isReadOnly={true}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="uneditableCell" isReadOnly={true}/>
          <FlexGridColumnGroup binding="SyunkoDate__c" header="竣工日" dataType='Date' width={165}
            isReadOnly={this.state.isDisabled} editor= {this.dateEditor}
            isRequired={false}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(context.item.SyunkoDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="竣工確認結果" align="center">
            <FlexGridColumnGroup binding="SyunkoReportAcceptedDate__c" header="竣工届受理日" dataType="Date" cssClass="uneditableCell" isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell"
                template= {(context) => {
                  return changeDateFormat(
                      context.item.SyunkoReportAcceptedDate__c);
                }}
              />
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitNecessity__c" header="再提出要否" dataType="String" cssClass="uneditableCell" isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'NecessityCategory', ctx.item.ResubmitNecessity__c);
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitReason__c" header="再提出理由" dataType="String" cssClass="uneditableCell" isReadOnly={true}/>
          </FlexGridColumnGroup>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              線路名：{(this.state.editItem && this.state.editItem.Dsp_SenroName__c) ? this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
              電柱番号：{(this.state.editItem && this.state.editItem.Dsp_DntNo__c) ? this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <span className='wj-input-label-custom'>竣工日</span>
              <InputDate
                id="iCompletionDate"
                isRequired={false}
                value={this.state.SyunkoDate__c ?
                  this.state.SyunkoDate__c : null}
                valueChanged={this.changeSyunkoDate.bind(this)}
                isDisabled={this.state.isDisabled}>
              </InputDate>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>

      </div>
    );
  }

  render() {
    const {classes, applyInfo1, applyInfo2, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            id="btnApply"
            onClick={handleSubmit(this.doApply)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled || this.state.hasError}
          >
            <span>申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn5" item>
          <PositiveButton
            id="btnTempSaved"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn6" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>

        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='申込' fixAction=''/>
          }
          {/* 警告メッセージ */}
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={12} sm={6}>
              <TableContainer>
                <Table size="small" aria-label="result table" style={{border: '1px solid gray'}}>
                  <TableBody>
                    {applyInfo1.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row"
                          style={{borderRight: '1px solid gray', borderBottom: '1px solid gray', background: '#001a37', color: 'white', width: '200px'}}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" style={{borderBottom: '1px solid gray', width: '400px'}}>{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TableContainer>
                <Table size="small" aria-label="result table" style={{border: '1px solid gray'}}>
                  <TableBody>
                    {applyInfo2.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row"
                          style={{borderRight: '1px solid gray', borderBottom: '1px solid gray', background: '#001a37', color: 'white', width: '200px'}}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" style={{borderBottom: '1px solid gray', width: '400px'}}>{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <br/>
          <Grid container
            direction="column"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {/* 添付ファイル */}
            <Grid key="key1487AttachGrid" item xs={12} sm={7}>
              <PaperPart>
                <Grid container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid key="key1487SyunkoFile" item xs={12}>
                    <Typography variant="h6" display="block">
                      竣工報告資料
                      <font size="2">(※最大1ファイル、1ファイルあたり20MBまで登録できます)</font>
                      <Required/>
                      <MuiTooltip content={
                        <React.Fragment>
                          竣工後の状況が分かる資料を添付してください。
                        </React.Fragment>
                      }>
                        <InfoOutlined/>
                      </MuiTooltip>
                    </Typography>
                  </Grid>
                  <Grid key="key1487TenUploadSyunkoFile" item xs={12}>
                    <Upload
                      componentId="syunkoTenComponent"
                      maxFileCount={1}
                      maxFileSize={20971520}
                      required={true}
                      previewFlag={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      fileSetHandler={this.setSyunkoFiles}
                      initFile={this.state.syunkoFiles}
                      disabled={this.state.isDisabled}
                      customChecks={[
                        checkFileNameLength,
                        checkFileSizeZero,
                        checkSameName,
                      ]}
                    />
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>
          </Grid>
        </MainContainer>

        {/* 確認ダイアログ
          竣工届登録時に、「報告内容の重要性」について承諾・誓約する旨を
          ポップアップ表示し、ボタン押下させる。
        */}
        <React.Fragment>
          <Dialog onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="md">
            <DialogContent dividers>
              <Typography variant="body1" gutterBottom>
                  よろしければ以下をチェックして申込ボタンを押してください。
              </Typography>
              <Typography style={{whiteSpace: 'pre-wrap'}} component="div" gutterBottom>
                <Box fontWeight="fontWeightBold">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='chb1487SenConfirm'
                        checked={this.state.agree ? true : false}
                        onChange={(e) =>
                          this.setState({agree: e.target.checked})}
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                      />
                    }
                    label={
                      <div style={{maxWidth: '540px'}}>
                        <span style={{fontWeight: 'bold'}}>
                      自主点検により，自らの共架工事に不備は無く，本報告内容に誤り・虚偽が無いことを誓約します。
                      本報告後，共架工事の不備が判明し，改修等の指示があった場合は，速やかに対応します。
                        </span>
                      </div>}
                  />
                </Box>
              </Typography>
            </DialogContent>
            <DialogActions>
              <PositiveButton
                id="id1487DialogApply"
                onClick={handleSubmit(this.updateOrder)}
                variant="contained"
                size="large"
                disabled={this.state.agree === true? false: true}
                startIcon={<AddIcon/>}
              >
                <span>申込</span>
              </PositiveButton>
              <NegativeButton
                id="id1487DialogCancel"
                onClick={this.handleClose}
                variant="contained"
                startIcon={<KeyboardReturnIcon />}
                size="large"
              >
                <span>閉じる</span>
              </NegativeButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>

      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  applyInfo1: PropTypes.object.isRequired,
  applyInfo2: PropTypes.object.isRequired,
  orderId: PropTypes.string,
  contractDntList: PropTypes.array.isRequired,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doSetPhotoRegistrationInfo: PropTypes.func,
  referenceMode: PropTypes.bool.isRequired,
  doSaveSyunkoContractDnts: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  doClearOrderContractDnt: PropTypes.func.isRequired,
  doGetRepairOrderAndContractDntList: PropTypes.func,
  orderSubAllList: PropTypes.array,
};

const mapStateToProps = (state) => {
  const dataList1 = [
    {key: 'OrderNo__c', name: '改修依頼番号', value: ''},
    {key: 'RepairIraiDate__c', name: '改修依頼年月日', value: '', format: 'date'},
    {key: 'OrderKyogaZgsya__r.Name', name: '申込共架事業者', value: ''},
    {key: 'StbTypeName', name: '設備種別', value: ''},
    {key: 'KyogaTypeName', name: '共架種別', value: ''},
  ];
  const dataList3 = [
    {key: 'KoziTitle__c', name: '工事件名', value: ''},
    {key: 'KoziPlaceMainDntNo_SenroName__c', name: '工事場所／線路名', value: ''},
    {key: 'Dsp_KoziPlaceMainDntNo__c', name: '工事場所／代表電柱番号', value: ''},
    {key: 'KoziPlacePrefecturesName', name: '工事場所／都道府県', value: ''},
    {key: 'KoziPlaceMunicipalities__c', name: '工事場所／市区町村', value: ''},
    {key: 'KoziPlacePlace__c', name: '工事場所／場所', value: ''},
  ];

  let applyInfo1 = dataList1;

  // 本申込
  const order = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
    state.contractDnt.orderContractDntList.order : null;

  // 申込電柱
  const contractDntList = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
    state.contractDnt.orderContractDntList.contractDntList : [];

  // 画面表示
  if (order) {
    for (const data of applyInfo1) {
      switch (data.key) {
        case 'RepairIraiDate__c':
          data.value = changeDateFormatter(order[data.key], 'YYYY/MM/DD');
          break;
        case 'OrderKyogaZgsya__r.Name':
          let work = '';
          if (order['OrderKyogaZgsya__r']) {
            work = order['OrderKyogaZgsya__r']['Name'];
          }
          data.value = work;
          break;
        default:
          data.value = order[data.key];
          break;
      }
    }

    for (const data of dataList3) {
      data.value = order[data.key];
    }
  }

  const initValues = {
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: order,
    contractDntList: contractDntList,
    applyInfo1: applyInfo1,
    applyInfo2: dataList3,
    referenceMode: state.common.referenceMode == true ? true : false,
    orderSubAllList: state.attachorder.orderSubAllList,
    setGmnWarning: state.common.setGmnWarning,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doSaveSyunkoContractDnts: contractDntOperations.doSaveSyunkoContractDnts,
  doSetPhotoRegistrationInfo: contractDntOperations.doSetPhotoRegistrationInfo,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doGetRepairOrderAndContractDntList:
    contractDntOperations.doGetRepairOrderAndContractDntList,
};

const FORM_NAME = 'RepairIraiSyunkoReportTenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import SimpleTable from '../../../../../molecules/SimpleTable.js';
import {downloadAttachedFile, convertCommaSeparated} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';

const styles = (theme) => ({
});

// 申込テーブル検索時の検索対象のフィールド名
const appFields = ['OrderNo__c', 'KoziTitle__c', 'KoziAmount__c',
  'DrSideKoziCompletionDate__c', 'OrderStatus__c',
  'Id', 'LastModifiedDate',
];

/** 申込、添付ファイル名検索時の検索対象 */
const subquery = [
  {
    'fields': [
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
      'ContentDocument.LatestPublishedVersion.VersionData',
      'ContentDocument.LatestPublishedVersion.PathOnClient'],
    'conditions': {
      'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['A18'],
    },
    'table': 'ContentDocumentLinks',
  },
];

/**
 * 改造工事完了件名確認
 * 画面ID:1132
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
    };
  }

  /**
  * 初期処理
  */
  async componentDidMount() {
    try {
      if (this.props.orderId) {
        // 申込テーブル検索時の検索条件
        const appConditions = {
          'Id': this.props.orderId,
          'RecordType.DeveloperName': 'MainOrder',
        };
        // 申込テーブルからデータ取得
        const result = await this.props.doGetOrderSubList(
            appConditions, appFields, null, subquery);
        const errCode = result ? result.data ? result.data.body ?
          result.data.body.errorCode : undefined : undefined : undefined;
        if (errCode != '00000' ||
         !this.props.hasOwnProperty('orderSubList') ||
          this.props.orderSubList.length <= 0 ) {
          this.sysErr();
          return;
        }

        if (this.props.orderSubList) {
          // Mapに申込テーブル検索結果を保存
          const appData = this.props.orderSubList[0];
          const wkDataMap = new Map();
          for (const key of Object.keys(appData)) {
            wkDataMap.set(key, appData[key]);
            // 添付ファイルのハイパーリンク設定
            if (key === 'ContentDocumentLinks' && appData[key]) {
              const items = appData[key].records[0].
                  ContentDocument.LatestPublishedVersion;
              this.attachedFile = {
                'AttachedFileType_Type': items.AttachedFileType__c,
                'AttachedFileType_Name': items.PathOnClient,
                'AttachedFileType_Url': items.VersionData};
            }
          }

          const dataList = [
            {key: 'OrderNo__c', name: '申込番号', value: ''},
            {key: 'KoziTitle__c', name: '工事件名', value: ''},
            {key: 'KoziAmount__c', name: '改造工事費用（税抜）', value: ''},
            {key: 'AttachedDocument__c', name: '添付資料', value: '', onClick: this.doDownloadAttachedFile, link: '#'},
            {key: 'DrSideKoziCompletionDate__c', name: '改造工事完了日', value: '', format: 'date'},
          ];

          // 取得データを画面表示
          for (const data of dataList) {
            if (wkDataMap.has(data.key)) {
              data.value = wkDataMap.get(data.key);
            }

            // 添付資料の場合、添付ファイル名を設定
            if (data.key == 'AttachedDocument__c') {
              if (this.attachedFile) {
                data.value = this.attachedFile.AttachedFileType_Name;
              }
            }

            // 工事金額の表示形式変更
            if (data.key == 'KoziAmount__c') {
              // 確定前の場合（申込ステータスが15以下）、空設定
              if (appData['OrderStatus__c'] <= 15) {
                data.value = '';
              } else {
                data.value = data.value ? convertCommaSeparated(data.value) + '円' : '';
              }
            }
          }
          // 再描画
          this.setState({
            dataList: dataList,
          });
        }
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderSubList();
    // 画面表示内容クリア
    for (const data of this.state.dataList) {
      data.value = '';
    }
  }

  /**
   * 添付ファイルダウンロード
   */
  doDownloadAttachedFile = async () => {
    const {userInfo} = this.props;

    if (this.attachedFile) {
      downloadAttachedFile(
          userInfo, this.attachedFile.AttachedFileType_Url,
          this.attachedFile.AttachedFileType_Type,
          this.attachedFile.AttachedFileType_Name);
    }
    return;
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  render() {
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <SimpleTable dataList={this.state.dataList} />
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  userInfo: PropTypes.object,
  history: PropTypes.object.isRequired,
  orderId: PropTypes.string,
  orderSubList: PropTypes.array,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderSubList: PropTypes.func,
  doClearOrderSubList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  orderId: state.attachorder.orderId,
  orderSubList: state.attachorder.orderSubList,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderSubList: attachorderOperations.doGetOrderSubList,
  doClearOrderSubList: attachorderOperations.doClearOrderSubList,
};

const FORM_NAME = 'PayoffCostCalculationConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

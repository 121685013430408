import types from './types';

// 一覧設定
export const setSetListAction = (items) => ({
  type: types.SET_LIST,
  items,
});

// 行追加
export const addItemAction = (item) => ({
  type: types.ADD_ITEM,
  item,
});

// 行削除
export const removeItemAction = (index) => ({
  type: types.REMOVE_ITEM,
  index,
});

// 行変更
export const changeItemAction = (item, index) => ({
  type: types.CHANGE_ITEM,
  item,
  index,
});

// 行移動
export const moveItemAction = (item, dragIndex, dropIndex) => ({
  type: types.MOVE_ITEM,
  item,
  dragIndex,
  dropIndex,
});

// 一覧設定（MultiRow）
export const setSetListMultiRowAction = (multiRowItems) => ({
  type: types.SET_LIST_MULTI_ROW,
  multiRowItems,
});

// 行追加（MultiRow）
export const addItemMultiRowAction = (multiRowItem) => ({
  type: types.ADD_ITEM_MULTI_ROW,
  multiRowItem,
});

// 行削除（MultiRow）
export const removeItemMultiRowAction = (multiRowIndex) => ({
  type: types.REMOVE_ITEM_MULTI_ROW,
  multiRowIndex,
});

// 行変更（MultiRow）
export const changeItemMultiRowAction = (multiRowItem, multiRowIndex) => ({
  type: types.CHANGE_ITEM_MULTI_ROW,
  multiRowItem,
  multiRowIndex,
});

// 行移動（MultiRow）
export const moveItemMultiRowAction = (multiRowItem, multiRowIndex,
    destination) => ({
  type: types.MOVE_ITEM_MULTI_ROW,
  multiRowItem,
  multiRowIndex,
  destination,
});

// 選択データを保存
export const saveSelectedItemsAction = (
    screenId, compareKey, selectedItems) => ({
  type: types.SAVE_SELECTED_ITEMS,
  screenId,
  compareKey,
  selectedItems,
});

// スクロール位置を保存
export const saveScrollPositionAction = (screenId, scrollPosition) => ({
  type: types.SAVE_SCROLL_POSITION,
  screenId,
  scrollPosition,
});

import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  fields: {
    minWidth: 300,
    borderBottom: 'solid 1px gray',
    color: 'gray',
  },
}));

/**
 * 共架事業者情報を表示する
 * @param  {string} {companyName}
 * @return {object}
 */
function KyogaCompanyInformation({companyName}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="companyName" item>
          <Typography variant="subtitle1" component="div">
            <Box
              display="flex"
              justifyContent="space-between"
              lineHeight="normal"
              m={1}
              className={classes.fields}>
              <span>{'申込共架事業者'}&nbsp;&nbsp;</span>
              <span>{companyName}</span>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

KyogaCompanyInformation.propTypes = {
  companyName: PropTypes.string,
};

export default KyogaCompanyInformation;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {Divider} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import axios from 'axios';

import conf from '../../../../config/config';
import MainContainer from '../../../organisms/MainContainer.js';
import BackButton from '../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../atoms/Buttons/DangerButton.js';
import NegativeButton from '../../../atoms/Buttons/NegativeButton.js';
import PositiveButton from '../../../atoms/Buttons/PositiveButton.js';
import {renderSelect, renderTextField} from '../../../atoms/CustomPart.js';
import PaperPart from '../../../atoms/PaperPart.js';
import CustomFlexGrid from '../../../molecules/CustomFlexGrid.js';
import {authOperations} from '../../../../reducks/auth/index.js';
import {commonOperations} from '../../../../reducks/common';
import {createSearchList} from '../../../../common/ComboParts.js';
import {changeDateFormat, getDataFromGeneralPurposeMap} from '../../../../common/common.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

/**
 * 代行入力(ユーザ切替)
 * 画面ID:1541
 */
class DaikoInput extends React.Component {
  constructor(props) {
    super(props);

    const {userInfo} = props;

    // 代行状態の場合、代行解除ボタンを活性化
    const isDaiko = userInfo.DaikoUserId != null;

    this.state = {
      disableBeginButton: true,
      isDaiko: isDaiko,
    };

    this.gridRef = React.createRef();
  }

  /**
   * 初期化処理。
   */
  async componentDidMount() {
    if (this.props.daikoUserList) {
      this.gridRef.current.setInitItems('1541', this.props.daikoUserList);
    }
  }

  /**
   * 画面を抜ける際の処理。
   */
  componentWillUnmount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1541', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * チェックボックス選択時処理。
   */
  checkStateUpdate = () => {
    this.setState({disableBeginButton: false});
  }

  /**
   * 代行開始ボタンクリック時処理。
   */
  doStartDaiko = async () => {
    const {doShowMessage} = this.props;

    const selectedItems = this.gridRef.current.selectedItems;

    // 選択項目がない場合はエラー(ボタン活性条件により本来ありえない)
    if (selectedItems.length == 0) {
      doShowMessage({message: 'CE0022'});
      return;
    }

    const userInfo = selectedItems[0].dataItem;
    const name = this._getUserName(userInfo);
    const body = {targetUserId: userInfo.Id};
    this._doDaiko(name, 'Daiko/start', body, '開始');
  }

  /**
   * 代行解除ボタンクリック時処理。
   */
  doCancelDaiko = async () => {
    const {doShowMessage, userInfo} = this.props;

    // 代行中でない場合はエラー(ボタン活性条件により本来ありえない)
    if (!this.state.isDaiko) {
      doShowMessage({
        message: {
          id: 'CE0023',
          values: ['代行入力'],
        },
      });
      return;
    }

    const name = this._getUserName(userInfo);
    const body = {};
    this._doDaiko(name, 'Daiko/cancel', body, '解除');
  }

  /**
   * ユーザ名情報の生成。
   * @private
   * @param {object} userInfo ユーザ情報
   * @return {string} ユーザ名情報
   */
  _getUserName = (userInfo) => {
    return userInfo.Name + ' (' + userInfo.UserId__c + ')';
  }

  /**
    * 代行開始/解除ボタンクリック共通処理。
   * @param {object} name ユーザ名
   * @param {string} url API URL
   * @param {object} body API BODY
   * @param {string} type 実行処理名称
    */
  _doDaiko(name, url, body, type) {
    const {doShowMessage} = this.props;
    doShowMessage({
      message: {
        id: 'CC0111',
        values: [name, type],
      },
      action: () => {
        this._execDaiko(name, url, body, type);
      },
    });
  }

  /**
   * 代行開始/解除共通処理
   * @private
   * @param {object} name ユーザ名
   * @param {string} url API URL
   * @param {object} body API BODY
   * @param {string} type 実行処理名称
   */
  _execDaiko = async (name, url, body, type) => {
    const {doShowMessage, getUserInfo, history,
      setIsTermsAccepted, setHasJuyoZiko} = this.props;

    try {
      // 代行開始/解除
      const ret = await axios.post(conf.API_URL + url, body, {});

      // 成功したらユーザ情報を再取得
      if (ret.data.body.errorCode === '00000') {
        await getUserInfo(true);

        // 代行状態を設定
        const {userInfo} = this.props;
        const isDaiko = userInfo.DaikoUser != null;
        this.setState({
          isDaiko: isDaiko,
        });

        if (isDaiko) {
          // 代行開始の場合は代行ユーザの重要事項承諾を設定
          let isAccepted = false;
          if (userInfo.Account && userInfo.Account.Account__r) {
            isAccepted =
              (userInfo.Account.Account__r.StipulationConsentDate__c != null);
          }
          setIsTermsAccepted(isAccepted);
        } else {
          setIsTermsAccepted(true);
          setHasJuyoZiko(false);
        }

        // 成功メッセージ
        doShowMessage({
          message: {
            id: 'CI0112',
            values: [name, type],
          },
          action: () => {
            // 代行開始の場合、メニュー画面に戻る
            if (isDaiko) {
              history.push('/menu', null, history.option.CLEAR);
            }
          },
        });
      } else {
        this.sysErr(ret.data.body.errorCode);
      }
    } catch (err) {
      this.sysErr(err);
    }
  }

  /**
   * システムエラー表示。
   * @param {any} err エラー情報
   */
  sysErr = (err) => {
    const {doShowMessage} = this.props;
    console.dir(err);

    const dialog = {
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    };
    doShowMessage(dialog);
  }

  /**
   * 検索の実行。
   */
  doSearch = async () => {
    const {
      doShowMessage,
      getDaikoUserList,
      generalPurposeMap,
      values,
    } = this.props;

    try {
      const conditions = this.createConditions(values);
      const getResult = await getDaikoUserList(conditions);

      const daikoUserList = getResult.data.body.data;
      const dataLen = daikoUserList.length;

      // 取得結果が0件の場合
      if (dataLen == 0) {
        doShowMessage({
          message: 'CE0016',
        });
      }

      // 取得結果が最大件数の場合
      const maxLen = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      if (dataLen == maxLen) {
        doShowMessage({
          message: 'CE0015',
        });
      }

      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1541', 'Id', true);
        this.gridRef.current.setInitItems('1541', daikoUserList);
      }
    } catch (err) {
      doShowMessage({
        message: 'CS0001',
      });
    }
  }

  /**
   * 検索条件の生成。
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
   */
  createConditions = (values) => {
    // 初期検索条件
    const conditions = {};

    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }
      conditions[key] = values[key];
    }
    return conditions;
  }

  /**
   * FlexGrid描画。
   * @param {object[]} data データ
   * @return {JSX.Element}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      isReadOnly: true,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkStateUpdate,
    };
    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="UserId__c" header="ユーザID" isReadOnly={true} width={150} />
        <FlexGridColumnGroup binding="Name" header="ユーザ氏名(漢字)" isReadOnly={true} width={200} />
        <FlexGridColumnGroup binding="Account.Account__r.Name" header="事業者名" isReadOnly={true} width={250} />
        <FlexGridColumnGroup binding="Account.Name" header="事業所名" isReadOnly={true} width={250} />
        <FlexGridColumnGroup binding="Email" header="メールアドレス" isReadOnly={true} width={200} />
        <FlexGridColumnGroup binding="TantoTel__c" header="電話番号" isReadOnly={true} width={150} />
        <FlexGridColumnGroup binding="LevelName" header="ユーザレベル(種別)" dataType="String" isReadOnly={true} width={200} />
        <FlexGridColumnGroup binding="HakkoDate__c" header="登録日" dataType="Date" isReadOnly={true} width={100}>
          <FlexGridCellTemplate cellType="Cell"
            template={(context) => {
              return changeDateFormat(context.item.HakkoDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  /**
   * 描画。
   * @return {JSX.Element}
   */
  render() {
    const {
      classes,
      generalPurposeMap,
      handleSubmit,
      reset,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <DangerButton
            onClick={handleSubmit(this.doCancelDaiko)}
            variant="contained"
            size="large"
            disabled={!this.state.isDaiko}
          >
            <span>代行解除</span>
          </DangerButton>
        </Grid>
        <Grid key="btn2" item>
          <PositiveButton
            onClick={handleSubmit(this.doStartDaiko)}
            variant="contained"
            size="large"
            type="submit"
            disabled={this.state.disableBeginButton}
          >
            <span>代行開始</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <BackButton props={this.props} />
        </Grid>

      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <PaperPart>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}>
              <Grid key="key-1-1" item xs={12} sm={3}>
                <Field
                  name="UserId__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="ユーザID"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key-1-2" item xs={12} sm={3}>
                <Field
                  name="Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="ユーザ氏名(漢字)"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key-1-3" item xs={12} sm={3}>
                <Field
                  name="KyogaZgsyaCode__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="共架事業者コード"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key-1-4" item xs={12} sm={3}>
                <Field
                  name="KyogaZgsyoCode__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="共架事業所コード"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key-2-1" item xs={12} sm={3}>
                <Field
                  name="Email"
                  className={classes.fields}
                  component={renderTextField}
                  label="メールアドレス"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key-2-2" item xs={12} sm={3}>
                <Field
                  name="TantoTel__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電話番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                />
              </Grid>
              <Grid key="key-2-3" item xs={12} sm={3}>
                <Field
                  name="UserLevel__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="ユーザレベル(種別)"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                  ]}
                >
                  {
                    generalPurposeMap &&
                    createSearchList(generalPurposeMap.UserLevel)
                  }
                </Field>
              </Grid>
              <Grid item key="key-3-1">
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  onClick={handleSubmit(this.doSearch)}
                  variant="contained"
                  size="large"
                  type="button"
                  className={classes.searchButton}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>

          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

DaikoInput.propTypes = {
  classes: PropTypes.object.isRequired,
  daikoUserList: PropTypes.array,
  doShowMessage: PropTypes.func.isRequired,
  generalPurposeMap: PropTypes.object,
  getDaikoUserList: PropTypes.func,
  getUserInfo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object,
  setIsTermsAccepted: PropTypes.func,
  setHasJuyoZiko: PropTypes.func,
};

const mapStateToProps = (state) => ({
  daikoUserList: state.auth.daikoUserList,
});

const mapDispatchToProps = {
  getDaikoUserList: authOperations.doGetDaikoUserList,
  setIsTermsAccepted: commonOperations.doSetIsTermsAccepted,
  setHasJuyoZiko: commonOperations.doSetHasJuyoZiko,
};

const FORM_NAME = 'DaikoInput';

DaikoInput = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(DaikoInput));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DaikoInput),
);

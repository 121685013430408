import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import {CommonButton} from './CommonButton.js';

// ネガティブボタンスタイル
const styles = (theme) => createStyles({
  root: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
});

/** ネガティブボタン */
export const NegativeButton = withStyles(styles)(CommonButton);
export default NegativeButton;

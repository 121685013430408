import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import {Divider} from '@material-ui/core';
import MainContainer from '../../../organisms/MainContainer.js';
import BackButton from '../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../atoms/Buttons/PositiveButton.js';
import {renderTextField, renderSelect, renderSelectMultiLine} from '../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../common/ComboParts.js';
import {reduxForm, getFormValues, Field} from 'redux-form';

import * as validateRule from '../../../validateRule.js';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';

import PaperPart from '../../../atoms/PaperPart.js';
import NegativeButton from '../../../atoms/Buttons/NegativeButton.js';

import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CustomFlexGrid from '../../../molecules/CustomFlexGrid.js';
import MuiTooltip from '../../../atoms/MuiTooltip.js';
import {commonOperations} from '../../../../reducks/common';
import {contractDntOperations} from '../../../../reducks/contractDnt/index.js';
import {API_CODE, getErrorCode, createTyohyoCsvLimitOver, full2Harf, full2Harf2,
  execApexRestApi, getDataFromGeneralPurposeMap} from '../../../../common/common.js';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
    background: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#fff',
    height: '100%',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },

  button: {
    margin: '5px 10px 5px 10px',
  },
});

/**
 * 契約電柱検索
 * 画面ID:1512
 */

class ContractDntSearch extends React.Component {
  constructor(props) {
    super(props);
    this.selector = null;
    this.state = {
      dntNoDisabled: false,
    };
    this.gridRef = React.createRef();
    this.senrocodecategory22KV = '1';
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // FlexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1512', null);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 事業所検索の表示
   */
  setNWZgsyoList = async () =>{
    try {
      const {values} = this.props;
      const namefilter = values.office;
      const {generalPurposeMap, doGetNWZgsyoSearchfilter} = this.props;

      let nwzgsyoValueFilter = generalPurposeMap.NWZgsyo.filter((item) =>
        item.Code__c != '1001' && item.Code__c != '1065'); // 本店とエネコムを除外

      await doGetNWZgsyoSearchfilter(nwzgsyoValueFilter, namefilter);
      console.dir(this.props.NWZgsyoSearchfilter);
      if (this.props.NWZgsyoSearchfilter.length <=0 ) {
        this.props.doShowMessage({
          message: {
            id: 'CE0016',
          },
        });
        return;
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 線路検索の表示
   */
  setSenroList = async () =>{
    try {
      const {values, userInfo} = this.props;
      if (isNullOrWhiteSpace(values.NWZgsyo__c)) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['事業所'],
          },
        });
        return;
      }

      const list = [];
      const body = {
        NWZgsyoCode: values.NWZgsyo__c,
        senroName: values.line,
        senroNameKana: (values.line ?
           full2Harf(full2Harf2(values.line)) : undefined),
        kyogaZgsyaCode: userInfo.KyogaZgsyaCode__c,
        kyogaZgsyoCode: userInfo.KyogaZgsyoCode__c,
      };
      // 契約電柱マスタで使用されている線路から取得
      const ret1 = await execApexRestApi(userInfo, 'ContractDnt/senroSearch', body);
      if (ret1 == null) {
        return;
      }
      if (ret1.data.body.errorCode != '00000') {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['線路情報', '取得'],
          },
        });
        return;
      };
      const filterdContractDntList = Array.from(
          new Map(ret1.data.body.data.data.map((o) =>
            [(o.SenroCode__c ? o.SenroCode__c : o.SenroName__c), o],
          )),
      );
      filterdContractDntList.map((item) =>{
        if (!isNullOrWhiteSpace(item[0])) {
          list.push({
            'senrocode': item[0],
            'senroname': item[1].SenroName__c,
            'senronamekana': item[1].SenroNameKana__c,
          });
        }
      },
      );
      const senroInfo = list.filter((o) => o.senrocode);
      if (senroInfo && senroInfo.length > 0) {
        const senroFilter = {};
        senroFilter['NWZgsyo'] = values.NWZgsyo__c;
        senroFilter['SenroCode_EQ'] = senroInfo.map((o) => o.senrocode);
        // 線路マスタから取得
        const ret = await execApexRestApi(userInfo, 'SenroCode/get', {'conditions': senroFilter});
        const errCode = ret && ret.data && ret.data.body ?
        ret.data.body.errorCode : undefined;
        if (errCode != '00000') {
          console.error(errCode);
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['線路情報', '取得'],
            },
          });
          return;
        }
        const senroResult = ret.data.body.data;
        if (senroResult && senroResult.length > 0) {
          for (const senroData of list) {
            const senro = senroResult.filter(
                (o) => o.senrocode == senroData.senrocode);
            if (senro && senro.length > 0) senroData['senrocodecategory'] = senro[0].senrocodecategory;
          }
        }
      }
      this.props.doSetSenroCodeSearchList(list);
      // 検索結果0件の場合、エラーダイアログ表示
      if (list.length <= 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0016',
          },
        });
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 線路一覧リスト生成
   * @return {option} オプション配列
   */
  createSenroOptions = () => {
    try {
      const {senroCodeSearchList}= this.props;
      if (senroCodeSearchList) {
        const options = senroCodeSearchList.map((data, index)=>{
          return <option key={data.senrocode} value={data.senrocode}>
            {(isNullOrWhiteSpace(data.senroname)&&
            isNullOrWhiteSpace(data.senronamekana))?
              data.senrocode :
            (data.senroname ? data.senroname + ' ' : '') +
            (data.senronamekana ? data.senronamekana : '') }
          </option>;
        });
        return options;
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 事業所一覧リスト生成
   * @return {option} オプション配列
   */
   createNWZgsyoOption = () =>{
     try {
       const {NWZgsyoSearchfilter}= this.props;
       let options = null;
       if (NWZgsyoSearchfilter) {
         options = NWZgsyoSearchfilter.map((item, index)=> {
           return <option key={item.Code__c} value={item.Code__c}>
             {item.Name}
           </option>;
         });
       }
       return options;
     } catch (error) {
       this.sysErr();
     }
   }

  /**
   * 電柱番号の入力可否制御
   * @param {array} senroCodeSearchList
   * @param {object} values
   */
  dntNoInputControl = (senroCodeSearchList, values) => {
    try {
      console.dir(values);
      // ２２Kの線路を選択した場合は、自、至の指定は無効とする。
      const targetSenro = (!senroCodeSearchList ? null :
      senroCodeSearchList.filter((data) => data.senrocode ==
       values.currentTarget.value));
      const flg = (targetSenro && targetSenro[0] ?
        targetSenro[0].senrocodecategory == this.senrocodecategory22KV : false);
      this.setState({dntNoDisabled: flg});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索ボタン押下時処理
   */
  searchContractDnt = async () => {
    try {
      const {generalPurposeMap, values} = this.props;

      // 電柱番号入力時、事業所と路線の必須選択チェック
      if (values.DntNo__c_From || values.DntNo__c_To) {
        if (isNullOrWhiteSpace(values.NWZgsyo__c)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['事業所'],
            },
          });
          return;
        }
        if (isNullOrWhiteSpace(values.SenroCode__c)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['線路'],
            },
          });
          return;
        }
      }

      // 検索条件設定
      const wkData = this.createConditions();

      const fields=['Id',
        'NWZgsyo__r.Name', // 事業所
        'SenroName__c', // 線路名
        'Dsp_SenroName__c', // 線路名
        'Dsp_DntNo__c', // 電柱番号
        'toLabel(DntCategory__c)', // 電柱区分
        'AttachOrderNo__c', // 取付申込番号
        'ContractNo__c', // 契約書番号
        'toLabel(StbType__c)StbTypeName', // 設備種別名称
        'StbType__c', // 設備種別
        'toLabel(KyogaType__c)KyogaTypeName', // 共架種別名称
        'KyogaType__c', // 共架種別
        'Point__c', // ポイント
        'KyogaPriceCategory__c', // 共架料区分
        'toLabel(KyogaPriceCategory__c)KyogaPriceCategory', // 有償／無償
        'IskkCategory__c', // 一束化区分
        'toLabel(IskkCategory__c)IskkCategory', // 一束化事業者数
        'IskkAiteZgsya1__c', // 一束化事業者数
        'IskkAiteZgsya2__c', // 一束化事業者数
        'IskkAiteZgsya3__c', // 一束化事業者数
      ];

      /** ソートするフィールド名一覧 */
      const sortFields = {
        'DntNo__c': '1',
      };

      const response = await this.props.doGetContractDntSearchList(
          wkData, fields, sortFields);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }

      // 表示最大件数「画面一覧件数：2,000件」
      const maxKensu = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      console.dir(this.props.contractDntSearchList);
      // 検索結果0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('contractDntSearchList') ||
          !this.props.contractDntSearchList ||
           this.props.contractDntSearchList.length == 0) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      // 検索結果2000件以上の場合、エラーダイアログ表示
      } else if (this.props.contractDntSearchList.length == maxKensu ) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }
      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1512', 'Id', true);
        this.gridRef.current.setItems(this.props.contractDntSearchList);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
 * CSV出力処理
 */
  output = async () =>{
    try {
      const {generalPurposeMap, values} = this.props;

      // 電柱番号入力時、事業所と路線の必須選択チェック
      if (values.DntNo__c_From || values.DntNo__c_To) {
        if (isNullOrWhiteSpace(values.NWZgsyo__c)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['事業所'],
            },
          });
          return;
        }
        if (isNullOrWhiteSpace(values.SenroCode__c)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['線路'],
            },
          });
          return;
        }
      }

      // 検索条件設定
      const wkData = this.createConditions();

      // 表示最大件数「CSV出力件数：10,000件」
      const LimitSize = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu2', 1).ValueNum1__c;
      // 帳票(CSV)の作成
      const totalSize = await createTyohyoCsvLimitOver('contractDntList', wkData, '契約電柱一覧表.csv', null, LimitSize);
      if (totalSize >= 0) {
        if (totalSize > LimitSize) {
          this.props.doShowMessage({
            message: {
              id: 'CE0015',
            },
          });
        } else if (totalSize == 0) {
          this.props.doShowMessage({
            message: 'CE0016',
          });
        } else {
          this.props.doShowMessage({
            message: {
              id: 'CI0013',
              values: ['契約電柱一覧表'],
            },
          });
        }
        return;
      } else {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['契約電柱一覧表', 'ダウンロード'],
          },
        });
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索条件生成
   *
   * @return {object} 検索条件
  */
  createConditions = () => {
    const {values, senroCodeSearchList, userInfo} = this.props;

    const wkData = this.props.values ?
          JSON.parse(JSON.stringify(this.props.values)): {};

    // 事業所 検索欄
    if ('office' in wkData) {
      delete wkData['office'];
    }
    // 選択事業所
    if ('NWZgsyo__c' in wkData) {
      wkData['NWZgsyo__r.Code__c'] = wkData['NWZgsyo__c'];
      delete wkData['NWZgsyo__c'];
    }
    // 線路 検索欄
    if ('line' in wkData) {
      delete wkData['line'];
    }
    // 線路名
    if ('SenroCode__c' in wkData) {
      delete wkData['SenroCode__c'];
    }
    // 電柱番号
    if ('DntNo__c_From' in wkData) {
      delete wkData['DntNo__c_From'];
    }
    if ('DntNo__c_To' in wkData) {
      delete wkData['DntNo__c_To'];
    }

    wkData['RecordType.DeveloperName']='ContractDntMaster';
    wkData['KyogaHaisiDate__c_EQ'] = null; // 共架廃止日

    const senroInfo = {};
    const senroNames = [];
    const senroCodes = [];
    let is6kv = false;
    let is22kv = false;
    let isFree = false;

    if (senroCodeSearchList) {
      if (values.hasOwnProperty('SenroCode__c')) {
        for (const v of values.SenroCode__c) {
          const targetSenro = senroCodeSearchList.filter(
              (senro) => senro.senrocode == v);
          if (targetSenro && targetSenro.length > 0 &&
              targetSenro[0].senrocode == targetSenro[0].senroname) {
            senroNames.push(v);
            isFree = true;
          } else {
            senroCodes.push(v);
            if (targetSenro && targetSenro.length > 0 &&
              targetSenro[0].senrocodecategory != this.senrocodecategory22KV) {
              is6kv = true;
            } else {
              is22kv = true;
            }
          }
        }
      }
      if (senroCodes.length > 0) {
        senroInfo['Code'] = senroCodes;
      }
      if (senroNames.length > 0) {
        senroInfo['Name'] = senroNames;
      }

      if (senroInfo.hasOwnProperty('Code') || senroInfo.hasOwnProperty('Name')) {
        wkData['Senro'] = senroInfo;
      }

      if (is6kv && !is22kv && !isFree) {
        // 6kvのみ
        if (values.hasOwnProperty('DntNo__c_From')) {
          wkData['K6KansnNo__c_From'] = (values.DntNo__c_From ? values.DntNo__c_From.padStart(3, '0') : values.DntNo__c_From);
        }
        if (values.hasOwnProperty('DntNo__c_To')) {
          wkData['K6KansnNo__c_To'] = (values.DntNo__c_To ? values.DntNo__c_To.padStart(3, '0') : values.DntNo__c_To);
        }
      } else if (!is6kv && !is22kv && isFree) {
        // 自由のみ
        if (values.hasOwnProperty('DntNo__c_From')) {
          wkData['DntNoManualInput__c_From'] = values.DntNo__c_From;
        }
        if (values.hasOwnProperty('DntNo__c_To')) {
          wkData['DntNoManualInput__c_To'] = values.DntNo__c_To;
        }
      } else if (is6kv && isFree) {
        // API側で6kvと自由のOR検索
        if (values.hasOwnProperty('DntNo__c_From') && values.DntNo__c_From) {
          wkData['DntNo__c_From'] = values.DntNo__c_From;
        }
        if (values.hasOwnProperty('DntNo__c_To') && values.DntNo__c_To) {
          wkData['DntNo__c_To'] = values.DntNo__c_To;
        }
      }
    }

    wkData['KyogaZgsyaCode__c'] = userInfo.KyogaZgsyaCode__c;
    wkData['KyogaZgsyoCode__c'] = userInfo.KyogaZgsyoCode__c;

    return wkData;
  }
  /**
   * クリアボタン押下時
   */
  clear = () => {
    this.props.doGetNWZgsyoSearchfilter(null, null);
    this.props.doSetSenroCodeSearchList(null);
    this.props.reset();
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doGetNWZgsyoSearchfilter(null, null);
    this.props.doSetSenroCodeSearchList(null);
    this.props.reset();
    this.props.doClearContractDntList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1512', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 一覧表出力
   * @return {CustomFlexGrid} 一覧表
   */
  renderTable() {
    const props ={
      rowHeaderType: 'none',
      filterOn: true,
      headersVisibility: 'Column',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      isReadOnly: true,
      style: {maxHeight: '300px'},
    };
    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" />
        <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名"/>
        <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" />
        <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" />
        <FlexGridColumnGroup binding="AttachOrderNo__c" header="取付申込番号"/>
        <FlexGridColumnGroup binding="ContractNo__c" header="契約書番号"/>
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType="String" width={100}/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType="String" width={200}/>
        <FlexGridColumnGroup binding="Point__c" header="ポイント" width={100}/>
        <FlexGridColumnGroup binding="KyogaPriceCategory" header="有償／無償" width={110}/>
        <FlexGridColumnGroup binding="UnitPrice" header="単価" width={100}/>
        <FlexGridColumnGroup binding="IskkCategory" header="一束化事業者数"/>
      </CustomFlexGrid>
    );
  }


  render() {
    const {classes, generalPurposeMap, handleSubmit, userInfo,
      doSetSenroCodeSearchList, senroCodeSearchList} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn2" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <PaperPart>
            <Grid alignItems="flex-start"
              fullwidth>
              <Grid container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                fullwidth
              >
                <Grid item xs={12} md={6}>
                  <Grid key="key-1-1-1">
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <Field
                          id="office"
                          name="office"
                          className={classes.fields}
                          component={renderTextField}
                          label="事業所"
                          fullWidth
                          type="text"
                          required={false}
                          validate={[
                            validateRule.fullWidth,
                            validateRule.maxLength(50),
                          ]}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <PositiveButton
                          id="btnSearchOffice"
                          onClick={handleSubmit(this.setNWZgsyoList)}
                          variant="contained"
                          size="large"
                          type="button"
                          fullWidth={true}
                          className={classes.searchButton}
                        >
                          <span>事業所検索</span>
                        </PositiveButton>
                      </Grid>
                    </Grid>
                    <Field
                      id="NWZgsyo__c"
                      name="NWZgsyo__c"
                      component={renderSelectMultiLine}
                      multiple={true}
                      label="事業所一覧"
                      fullWidth
                      onChange={()=>doSetSenroCodeSearchList(null)}
                    >
                      {this.createNWZgsyoOption()}
                    </Field>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid key="key-1-2-1" >
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <Field
                          id="line"
                          name="line"
                          className={classes.fields}
                          component={renderTextField}
                          label="線路名(漢字/カナ)"
                          fullWidth
                          type="text"
                          required={false}
                          validate={[
                            validateRule.maxLength(15),
                          ]}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <PositiveButton
                          id="btnSearchLine"
                          onClick={handleSubmit(this.setSenroList)}
                          variant="contained"
                          size="large"
                          type="button"
                          fullWidth={true}
                          className={classes.searchButton}
                        >
                          <span>線路検索</span>
                        </PositiveButton>
                      </Grid>
                    </Grid>
                    <Field
                      id="SenroCode__c"
                      name="SenroCode__c"
                      component={renderSelectMultiLine}
                      multiple={true}
                      label="線路一覧"
                      fullWidth
                      onChange={(e)=>this.dntNoInputControl(
                          senroCodeSearchList, e)}
                    >
                      {this.createSenroOptions()}
                    </Field>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              fullwidth
            >
              <Grid key="key-3-1" item xs={3}>
                <Field
                  id="DntNo__c_From"
                  name="DntNo__c_From"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  disabled={this.state.dntNoDisabled}
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                />

              </Grid>
              <Grid key="key-3-2" item xs={1} className={classes.waveGrid} align="center">
                <span>～</span>
              </Grid>
              <Grid key="key-3-3" item xs={3}>
                <Field
                  id="DntNo__c_To"
                  name="DntNo__c_To"
                  className={classes.fields}
                  component={renderTextField}
                  label={<React.Fragment />}
                  fullWidth
                  type="text"
                  required={false}
                  disabled={this.state.dntNoDisabled}
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                />
              </Grid>

            </Grid>

            <Grid container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}>

              <Grid key='key-4-1' item xs={12} sm={3}>
                <Field
                  id="AttachOrderNo__c"
                  name="AttachOrderNo__c"
                  label="取付申込番号"
                  className={classes.fields}
                  component={renderTextField}
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key='key-4-2' item xs={12} sm={3}>
                <Field
                  id="ContractNo__c"
                  name="ContractNo__c"
                  label="契約書番号"
                  className={classes.fields}
                  component={renderTextField}
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>
              <Grid key='key-4-3' item xs={12} sm={3}>
                <Field
                  id="StbType__c"
                  name="StbType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap && createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name',
                      {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key='key-4-4' item xs={12} sm={3}>
                <Field
                  id="KyogaType__c"
                  name="KyogaType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key-btn" item xs={12} align="left">
                <NegativeButton
                  id="Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={this.clear}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="ListSearch"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchContractDnt)}
                >
                  <span>検索</span>
                </PositiveButton>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <MuiTooltip
                  id="tlCsvDownload"
                  content='※CSVファイルは，最大 10,000件まで出力できます。'>
                  <PositiveButton
                    id="btnCsvDownload"
                    variant="contained"
                    startIcon={<GetAppIcon />}
                    className={clsx(classes.button)}
                    size="large"
                    onClick={handleSubmit(this.output)}
                  >
                    <span>CSVダウンロード</span>
                    <InfoOutlined fontSize="small"/>
                  </PositiveButton>
                </MuiTooltip>
              </Grid>
            </Grid>
          </PaperPart>

          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

ContractDntSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doGetNWZgsyoSearchfilter: PropTypes.func.isRequired,
  doGetSenroCodeSearchList: PropTypes.func.isRequired,
  doSetSenroCodeSearchList: PropTypes.func.isRequired,
  doGetContractDntORFilter: PropTypes.func,
  doGetContractDntSearchList: PropTypes.func,
  doClearContractDntList: PropTypes.func,
  doShowMessage: PropTypes.func,
  NWZgsyoSearchfilter: PropTypes.array,
  senroCodeSearchList: PropTypes.array,
  contractDntORFilter: PropTypes.array,
  contractDntOrFilter: PropTypes.array,
  contractDntSearchList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  NWZgsyoSearchfilter: state.contractDnt.nwzgsyoSearchfilter,
  senroCodeSearchList: state.contractDnt.senroCodeSearchList,
  contractDntOrFilter: state.contractDnt.contractDntORFilter,
  contractDntSearchList: state.contractDnt.contractDntSearchList,
  initialValues: {},
});

const mapDispatchToProps = {
  doGetNWZgsyoSearchfilter: contractDntOperations.doGetNWZgsyoSearchfilter,
  doGetSenroCodeSearchList: contractDntOperations.doGetSenroCodeSearchList,
  doSetSenroCodeSearchList: contractDntOperations.doSetSenroCodeSearchList,
  doGetContractDntSearchList: contractDntOperations.doGetContractDntSearchList,
  doGetContractDntORFilter: contractDntOperations.doGetContractDntORFilter,
  doClearContractDntList: contractDntOperations.doClearContractDntList,
  doShowMessage: commonOperations.doShowMessage,
};

const FORM_NAME = 'ContractDntSearch';

ContractDntSearch = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(ContractDntSearch));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ContractDntSearch),
);

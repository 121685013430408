import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Field, getFormValues, reduxForm} from 'redux-form';
import * as validateRule from '../../validateRule';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';

import LoginCommon, {checkInputEmpty, commonStyles, renderTextField} from './loginCommon.js';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import {commonOperations} from '../../../reducks/common';
import {authOperations} from '../../../reducks/auth';

/**
 * ログイン画面:パスワードリセット(新パスワード入力) テンプレート。
 * 画面ID:3000
 */
class Container extends React.Component {
  /**
   * パスワード変更ボタンクリック時処理
   */
  doPasswordChange = async () => {
    const {doShowMessage} = this.props;

    // Valdationが動作していない事があるため、手動でチェックする
    if (!checkInputEmpty(this.createCheckTargets, doShowMessage)) {
      return;
    }

    const dialog = {
      message: {
        id: 'CC0005',
        values: ['パスワードを変更'],
      },
      action: this._execPasswordChange,
    };

    doShowMessage(dialog);
  };

  /**
   * 手動で未入力チェックを行う対象の入力項目情報を生成。
   *
   * @return {{name: string, value: string}[]} 入力項目情報
   */
   createCheckTargets = () => {
     const {
       values,
     } = this.props;

     // チェックする情報
     const checkTargets = [
       {name: '「新しいパスワード」', value: values.newPassword},
       {name: '「新しいパスワード(確認)」', value: values.newPasswordConfirm},
     ];

     return checkTargets;
   }

  /**
   * パスワード変更処理実施
   */
  _execPasswordChange = async () => {
    const {
      doLogout,
      doShowMessage,
      history,
      doPasswordReset,
      values,
    } = this.props;

    try {
      const result = await doPasswordReset(
          values.userName,
          values.code,
          values.newPassword,
      );

      switch (result) {
        case 'OK':
          doShowMessage({
            message: 'CI0124',
            action: doLogout,
          });
          break;

        case 'CODE_EXPIRED':
          doShowMessage({
            message: 'CE0128',
            action: () => {
              history.push('/resetPassword');
            },
          });
          break;

        default:
          doShowMessage({message: 'CS0001'});
          break;
      }
    } catch (err) {
      doShowMessage({message: 'CS0001'});
    }
  }

  /**
   * 描画処理。
   * @return {JSX.Element} メニュー画面の要素
   */
  render() {
    const {classes, handleSubmit} = this.props;
    return (
      <LoginCommon>
        <Grid container>

          <Grid item xs={12}>
            <Field
              name="userName"
              label="ユーザID"
              type="text"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              InputProps={{
                readOnly: true,
              }}
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="code"
              label="認証コード"
              type="text"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              InputProps={{
                readOnly: true,
              }}
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              name="newPassword"
              label="新しいパスワード"
              type="password"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              validate={[
                validateRule.required,
                validateRule.minLength(8),
                validateRule.maxLength(32),
                validateRule.password,
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.typography}>
              <Typography>※パスワードは半角英数字(大文字、小文字)を含めて</Typography>
              <Typography>　8文字以上32文字以内で入力してください。</Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Field
              name="newPasswordConfirm"
              label="新しいパスワード(確認)"
              type="password"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <PositiveButton
              onClick={handleSubmit(this.doPasswordChange)}
              variant="contained"
              size="large"
              className={classes.button}
            >
              <span>パスワード変更</span>
            </PositiveButton>
          </Grid>
        </Grid>
      </LoginCommon>
    );
  }
}

/**
 * 複数項目のバリデーション。
 *
 * @param {object} values フォームの値
 * @return {object} エラー情報
 */
const validate = (values) => {
  const errors = {};

  const newPassword = values.newPassword;
  const newPasswordConfirm = values.newPasswordConfirm;

  // 新しいパスワード(確認)が未入力の場合は終了
  if (newPasswordConfirm == null) {
    return errors;
  }

  // パスワードと確認が一致しない場合
  if (newPassword != newPasswordConfirm) {
    errors['newPasswordConfirm'] = 'パスワードが一致しません';
  }

  return errors;
};

const FORM_NAME = 'NewPassword';

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  doClearGetParam: PropTypes.func,
  doLogout: PropTypes.func,
  doPasswordReset: PropTypes.func,
  sendAuthenticationCode: PropTypes.func,
  doShowMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  values: PropTypes.object,
};

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  doClearGetParam: commonOperations.doClearGetParam,
  doPasswordReset: authOperations.doPasswordResetOperation,
  doShowMessage: commonOperations.doShowMessage,
  sendAuthenticationCode: authOperations.sendAuthenticationCodeOperation,
};

const mapStateToProps = (state) => {
  const qs = state.common.getParam;

  return {
    initialValues: {
      userName: qs.user_name,
      code: qs.confirmation_code,
      newPassword: null,
      newPasswordConfirm: null,
    },
  };
};

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));
export default withStyles(commonStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

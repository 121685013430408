import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';

import CustomFlexGrid, {createCollectionView} from '../../../../molecules/CustomFlexGrid.js';
import MainContainer from '../../../../organisms/MainContainer.js';
import PaperPart from '../../../../atoms/PaperPart.js';
import {renderSelect, renderTextField} from '../../../../atoms/CustomPart.js';
import BackButton from '../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../atoms/Buttons/PositiveButton.js';
import {commonOperations} from '../../../../../reducks/common/index.js';
import {authOperations} from '../../../../../reducks/auth/index.js';
import {API_CODE, changeDateFormatter, getDataFromGeneralPurposeMap,
  getResponseResult} from '../../../../../common/common';
import * as validateRule from '../../../../validateRule.js';

const styles = (theme) => ({
});

/**
 * 更新時対象フィールド一覧
 */
const UPDATE_FIELDS = [
  'Name',
  'NameKana__c',
  'RepresentativeName__c',
  'RepresentativeNameKana__c',
  'ZipCode__c',
  'Address__c',
  'Tel__c',
  'Fax__c',
  'MailAddress__c',
  'TelMobilePhone__c',
  'ProprietyJudgeBillingDestination__c',
  'GsKoziBillingDestination__c',
];

/**
 * 共架者基本情報 テンプレート。
 * 画面ID:1533
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModified: false,
    };

    this.gridBillingRef = React.createRef();
    this.gridMaintenanceRef = React.createRef();

    // 一覧の入力チェック(請求先情報)
    // 必須/属性チェック
    this.getGridError = (item, prop, parsing) => {
      return this.checkInputData(item, prop);
    };

    // 一覧の入力チェック(保守工事会社)
    this.getGridErrorMaintenance = (item, prop, parsing) => {
      return this.checkInputDataMaintenance(item, prop);
    };
  }

  /**
   * 一覧の入力チェック
   * @param {*} item 行の情報
   * @param {*} prop 名称
   * @return {*} メッセージ
   */
  checkInputData(item, prop) {
    // 名称
    if (prop == 'Name') {
      let error = validateRule.required4(item.Name);
      if (error) {
        return error;
      }
      // 全角チェック
      error = validateRule.fullWidth(item.Name);
      if (error) {
        return error;
      }
    }
    // 名称カナ
    if (prop == 'NameKana__c') {
      let error = validateRule.required4(item.NameKana__c);
      if (error) {
        return error;
      }
      // 半角チェック
      error = validateRule.halfWidth(item.NameKana__c);
      if (error) {
        return error;
      }
    }
    // 郵便番号
    if (prop == 'ZipCode__c') {
      let error = validateRule.required4(item.ZipCode__c);
      if (error) {
        return error;
      }
      // 数値チェック
      error = validateRule.number(item.ZipCode__c);
      if (error) {
        return error;
      }
      // 数値チェック
      error = validateRule.minLength(7)(item.ZipCode__c);
      if (error) {
        return error;
      }
    }
    // 住所
    if (prop == 'Address__c') {
      let error = validateRule.required4(item.Address__c);
      if (error) {
        return error;
      }
    }
    // 電話番号
    if (prop == 'Tel__c') {
      let error = validateRule.required4(item.Tel__c);
      if (error) {
        return error;
      }
      // 数値チェック
      error = validateRule.number(item.Tel__c);
      if (error) {
        return error;
      }
      // 数値チェック
      error = validateRule.minLength(10)(item.Tel__c);
      if (error) {
        return error;
      }
    }
    // 携帯電話番号
    if (prop == 'TelMobilePhone__c') {
      // 数値チェック
      let error = validateRule.number(item.TelMobilePhone__c);
      if (error) {
        return error;
      }
      // 数値チェック
      error = validateRule.minLength(10)(item.TelMobilePhone__c);
      if (error) {
        return error;
      }
    }
    // FAX番号
    if (prop == 'Fax__c') {
      // 数値チェック
      let error = validateRule.number(item.Fax__c);
      if (error) {
        return error;
      }
      // 数値チェック
      error = validateRule.minLength(10)(item.Fax__c);
      if (error) {
        return error;
      }
    }
    // メールアドレス
    if (prop == 'MailAddress__c') {
      // 数値チェック
      let error = validateRule.email(item.MailAddress__c);
      if (error) {
        return error;
      }
    }
    // 代表者名
    if (prop == 'RepresentativeName__c') {
      let error = validateRule.required4(item.RepresentativeName__c);
      if (error) {
        return error;
      }
      // 全角チェック
      error = validateRule.fullWidth(item.RepresentativeName__c);
      if (error) {
        return error;
      }
    }
    // 代表者名カナ
    if (prop == 'RepresentativeNameKana__c') {
      let error = validateRule.required4(item.RepresentativeNameKana__c);
      if (error) {
        return error;
      }
      // 半角チェック
      error = validateRule.halfWidth(item.RepresentativeNameKana__c);
      if (error) {
        return error;
      }
    }
    return null;
  }

  /**
   * 一覧の入力チェック(保守工事会社用)
   * @param {object} item 行の情報
   * @param {string} prop 名称
   * @return {string|null} エラーメッセージ。チェックOK時はnull
   */
  checkInputDataMaintenance(item, prop) {
    try {
      // 名称
      this.checkInputDataCommon(item, prop, 'Name', [
        validateRule.required4,
        validateRule.fullWidth,
      ]);

      // 名称カナ
      this.checkInputDataCommon(item, prop, 'NameKana__c', [
        validateRule.required4,
        validateRule.halfWidth,
      ]);

      // 代表者名
      this.checkInputDataCommon(item, prop, 'RepresentativeName__c', [
        validateRule.required4,
        validateRule.fullWidth,
      ]);

      // 代表者名カナ
      this.checkInputDataCommon(item, prop, 'RepresentativeNameKana__c', [
        validateRule.required4,
        validateRule.halfWidth,
      ]);

      // 郵便番号
      this.checkInputDataCommon(item, prop, 'ZipCode__c', [
        validateRule.required4,
        validateRule.number,
        validateRule.minLength(7),
      ]);

      // 住所
      this.checkInputDataCommon(item, prop, 'Address__c', [
        validateRule.required4,
      ]);

      // 電話番号
      this.checkInputDataCommon(item, prop, 'Tel__c', [
        validateRule.required4,
        validateRule.number,
        validateRule.minLength(10),
      ]);

      // FAX番号
      this.checkInputDataCommon(item, prop, 'Fax__c', [
        validateRule.number,
        validateRule.minLength(10),
      ]);

      // メールアドレス
      this.checkInputDataCommon(item, prop, 'MailAddress__c', [
        validateRule.required4,
        validateRule.email,
      ]);
    } catch (err) {
      return err;
    }

    return null;
  }

  /**
   * 共通チェック処理
   * @param {object} item 行の情報
   * @param {string} prop 名称
   * @param {string} name 項目要素名
   * @param {Function[]} validates バリデーション処理一覧
   * @throws {string} エラーメッセージ
   */
  checkInputDataCommon(item, prop, name, validates) {
    // チェック対象ではない場合処理終了
    if (prop != name) {
      return;
    }

    // バリデーション処理をループして実行
    const target = item[name];
    for (const validate of validates) {
      const result = validate(target);
      if (result != null) {
        // チェックNGの場合はエラーメッセージをスロー
        console.error({itemName: name, value: target, method: validate.name});
        throw result;
      }
    }

    // 最後までエラーが無ければチェックOK
    return;
  }

  /**
   * 初期化処理。
   */
  async componentDidMount() {
    const {userInfo, doShowMessage} = this.props;

    // 権限チェック
    if (userInfo.UserLevel__c != '2' ||
      userInfo.RecordType.DeveloperName != 'KyogaStaffRecordType') {
      // ユーザ取得結果が権限エラーの場合
      doShowMessage({
        message: {id: 'CE0056', values: ['共架者基本情報']},
        action: this.doMoveLogin,
      });
      return false;
    }

    // 検索と表示
    await this.searchAccount();
  }

  /**
   * Accountを検索し、画面表示する。
   * @return {*}
   */
  searchAccount = async () => {
    const {doShowMessage} = this.props;

    try {
      // 会社情報検索
      let result = await this.getAccountInfo();
      if (!result) {
        return;
      }

      // グリッドデータ作成
      const {initialValues, generalPurposeMap} = this.props;

      // 請求先情報
      changeDateFormat(initialValues.billingDestinationInfo);
      setLastModifiedName(initialValues.billingDestinationInfo,
          generalPurposeMap);

      // 保守工事会社情報
      changeDateFormat(initialValues.maintenanceKoziCompanyInfo);
      setLastModifiedName(initialValues.maintenanceKoziCompanyInfo,
          generalPurposeMap);

      // CustomFlexGridに取得データを設定
      // 請求先情報
      const cv = createCollectionView(
          initialValues.billingDestinationInfo,
          true, {}, this.getGridError);
      this.gridBillingRef.current.getGrid().itemsSource = cv;

      // 保守工事会社情報
      const cv2 = createCollectionView(
          initialValues.maintenanceKoziCompanyInfo,
          true, {}, this.getGridErrorMaintenance);
      this.gridMaintenanceRef.current.getGrid().itemsSource = cv2;
    } catch (err) {
      console.error(err);

      doShowMessage({
        message: 'CS0001',
        action: this.doMoveLogin,
      });
      return;
    }
  }
  /**
   * ユーザの会社情報を検索
   * @return {Promise<boolean>} true:成功, false:失敗
   */
  getAccountInfo = async () => {
    const {
      userInfo,
      doGetAccountList,
      doGetAccountMasterList,
      doShowMessage,
    } = this.props;

    const fields = [
      '*',
      'toLabel(ProprietyJudgeBillingTiming__c)ProprietyJudgeBillingTimingName',
      'toLabel(GsKoziBillingTiming__c)GsKoziBillingTimingName',
      'LastModifiedBy.Username',
      'RecordType.DeveloperName',
    ];

    const sortParams = {
      'RecordType.DeveloperName': 1,
      'BillingDestinationId__c': 1,
      'MainteKoziCompanyCode__c': 1,
    };

    // 共架事業者情報 取得
    let result = await doGetAccountList(
        {
          'KyogaZgsyaCode__c': userInfo.KyogaZgsyaCode__c,
          'RecordType.DeveloperName': 'KyogaZgsya',
        },
        fields,
        sortParams,
    );

    // エラーチェック
    let errorCode = result.data.body.errorCode;
    if (errorCode != API_CODE.SUCCESS) {
      // その他のエラーの場合
      doShowMessage({
        message: 'CS0001',
        action: this.doMoveLogin,
      });
      return false;
    }

    // 共架事業所情報, 請求先情報, 保守工事会社情報取得
    result = await doGetAccountMasterList(
        {},
        fields,
        sortParams,
    );

    // エラーチェック
    errorCode = result.data.body.errorCode;
    if (errorCode != API_CODE.SUCCESS) {
      // その他のエラーの場合
      doShowMessage({
        message: 'CS0001',
        action: this.doMoveLogin,
      });
      return false;
    }

    return true;
  }

  /**
   * 画面を抜ける際の処理。
   */
  componentWillUnmount() {
    this.props.reset();
    this.props.doClearAccountList();
    this.props.doClearAccountMasterList();
    this.gridBillingRef.current.setItems(null);
    this.gridMaintenanceRef.current.setItems(null);
  }

  /**
   * ログイン画面への遷移。
   */
  doMoveLogin = () => {
    this.props.history.push('/login');
  };

  /**
   * 更新データと更新条件を作成する。
   * @return {*} {condition: 更新条件, updateData: 更新データ}
   */
  createUpdateData() {
    const {values, initialValues} = this.props;

    // 編集データ
    // 共架事業者情報
    const inputKyogaZgsya = values.kyogaZgsyaInfo;
    // 共架事業所情報
    const inputKyogaZgsyo = values.kyogaZgsyoInfo;
    // 請求先情報
    const inputBillingList = [];
    Object.assign(inputBillingList,
        this.gridBillingRef.current.getSourceCollection());
    // 保守工事会社情報
    const inputMaintenanceList = [];
    Object.assign(inputMaintenanceList,
        this.gridMaintenanceRef.current.getSourceCollection());

    console.log({inputKyogaZgsya});
    console.log({inputKyogaZgsyo});
    console.log({inputBillingList});
    console.log({inputMaintenanceList});

    // 編集前データ
    const initKyogaZgsya = initialValues.kyogaZgsyaInfo;
    const initKyogaZgsyo = initialValues.kyogaZgsyoInfo;
    const initBillingList = initialValues.billingDestinationInfo;
    const initMaintenanceList = initialValues.maintenanceKoziCompanyInfo;
    console.log({initKyogaZgsya});
    console.log({initKyogaZgsyo});
    console.log({initBillingList});
    console.log({initMaintenanceList});

    // 更新情報作成
    let updateData = [];
    let condition = [];
    this.setUpdateInfo(inputKyogaZgsya, initKyogaZgsya,
        updateData, condition);
    this.setUpdateInfo(inputKyogaZgsyo, initKyogaZgsyo,
        updateData, condition);
    this.setUpdateInfo(inputBillingList, initBillingList,
        updateData, condition);
    this.setUpdateInfo(inputMaintenanceList, initMaintenanceList,
        updateData, condition);

    return {condition: condition, updateData: updateData};
  };

  /**
    * 更新情報を設定する。
    * @param {*} inputData 編集データ
    * @param {*} initData 初期データ
    * @param {*} updateData 更新データ
    * @param {*} condition 更新条件
    */
  setUpdateInfo(inputData, initData, updateData, condition) {
    // 変更された値のみを更新情報として設定
    const len = inputData.length;
    for (let i = 0; i < len; i++) {
      let data = {};
      for (const field of Object.keys(inputData[i])) {
        // 更新に使用しないフィールドは無視
        if (!UPDATE_FIELDS.includes(field)) {
          continue;
        }

        // 未変更なら更新対象に入れないフィールドの場合
        if (initData[i][field] == inputData[i][field]) {
          // 変更前後の値が同値の場合はContinue
          continue;
        }

        data[field] = inputData[i][field];
      }
      // 更新データがある場合にセットする
      const keys = Object.keys(data);
      if (keys.length != 0) {
        data['Id'] = inputData[i]['Id'];
        updateData.push(data);

        let con = {};
        con['Id'] = initData[i]['Id'];
        con['LastModifiedDate'] = initData[i]['LastModifiedDate'];
        condition.push(con);
      }
    }
  }

  /**
   * 更新処理
   * @return  {*}
   */
  _execUpdate = async () => {
    const {doShowMessage} = this.props;

    // 更新情報作成
    const updateInfo = this.createUpdateData();
    console.log({updateInfo});

    // 変更チェック
    const updateData = updateInfo.updateData;
    if (!updateData || updateData.length == 0) {
      // 変更データなしは完了ダイアログを出すだけ
      doShowMessage({
        message: {
          id: 'CI0009',
          values: ['共架者基本情報の更新'],
        },
      });
      return;
    }

    // 保存前のデータチェック
    for (const data of updateData) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const msg = this.checkInputData(data, key);
          if (msg) {
            // エラーあり
            doShowMessage({
              message: {
                id: 'CE0050',
              },
            });
            return;
          }
        }
      }
    }

    try {
      const stage = ['KYOGAZGSYA_BASICINFO_UPDATE'];
      // 会社マスタ更新
      const response = await this.props.doUpdateAccounts(
          updateInfo.condition, updateData, stage);
      const resResult = getResponseResult(response, ['共架者基本情報', '更新']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      // 更新 成功
      doShowMessage({
        message: {
          id: 'CI0009',
          values: ['共架者基本情報の更新'],
        },
        action: this.searchAccount,
      });
    } catch (error) {
      console.error(error);
      doShowMessage({
        message: {
          id: 'CS0001',
        },
        action: () => this.doMoveLogin,
      });
    }
  }

  /**
   * 「更新」ボタン押下時処理
   */
  doEdit = async () => {
    const {doShowMessage} = this.props;

    // 非共通部分の入力チェック
    // それ以外の共通チェックは_execUpdate()内で実施
    if (!this.checkNoCommonInput()) {
      return;
    }

    doShowMessage({
      message: {
        id: 'CC0005',
        values: ['更新'],
      },
      action: this._execUpdate,
    });
  }

  /**
   * 非共通部分の入力チェック
   *
   * 項目ごとに異なる入力チェックを行う
   * ・共架事業所.可否判定_請求先/改造工事_請求先 必須チェック
   * ・保守工事会社.メールアドレス 必須チェック
   * @return {boolean} チェック結果
   */
  checkNoCommonInput = () => {
    const {doShowMessage} = this.props;

    // 共架事業所.可否判定_請求先/改造工事_請求先の必須チェック
    const billingCheckErrorItem = this.checkBillingDestination();
    if (billingCheckErrorItem != null) {
      doShowMessage({
        message: {
          id: 'CE0017',
          values: [billingCheckErrorItem],
        },
      });

      return false;
    }

    // 保守工事会社のメールアドレス必須チェック
    if (!this.checkMaintenanceKoziCompanyMail()) {
      doShowMessage({
        message: {
          id: 'CE0017',
          values: ['保守工事会社情報のメールアドレス'],
        },
      });

      return false;
    }

    return true;
  }

  /**
   * 共架事業所の可否判定_請求先、改造工事_請求先 必須チェック
   *
   * @return {string} チェック結果(未入力の項目名)
   */
  checkBillingDestination = () => {
    // 画面に入力された共架事業所情報を取得
    const kyogaZgsyo = this.props.values.kyogaZgsyoInfo[0];

    // 可否判定 請求先の入力判定
    if (!kyogaZgsyo.ProprietyJudgeBillingDestination__c) {
      return '可否判定 請求先';
    }

    // 可否判定 請求先の入力判定
    if (!kyogaZgsyo.GsKoziBillingDestination__c) {
      return '改造工事 請求先';
    }

    return null;
  }

  /**
   * 保守工事会社のメールアドレス必須チェック
   *
   * 保守工事会社のメールアドレスはSalesforce側で空で作成される可能性があるため、
   * 入力必須でも入力されていないデータが存在する。
   * そのため、入力確定時に改めてチェックを行う。
   *
   * @return {boolean} チェック結果
   */
  checkMaintenanceKoziCompanyMail = () => {
    const checkInfoList =
      this.gridMaintenanceRef.current.getSourceCollection();

    for (const info of checkInfoList) {
      const mailAddress = info.MailAddress__c;
      if (!mailAddress || mailAddress === '') {
        return false;
      }
    }

    return true;
  }

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり, false: 変更なし
   */
  doCheckModified = () => {
    let eidtBilling = false;
    if (this.gridBillingRef.current) {
      eidtBilling = this.gridBillingRef.current.isItemsModified();
    }

    let eidtMaintenance = false;
    if (this.gridMaintenanceRef.current) {
      eidtMaintenance = this.gridMaintenanceRef.current.isItemsModified();
    }
    let result = false;
    if (eidtBilling || eidtMaintenance) {
      result = true;
    }
    return result;
  }

  /**
   * 請求先情報グリッド描画
   * @return {*}
   */
  renderBillingTable() {
    const props = {
      // rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'MultiColumn',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkHandler,
      useStore: false,
      validateEdits: false,
    };

    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridBillingRef} {...props}>
          <FlexGridColumnGroup binding="BillingDestinationId__c" header="請求先ID" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="Name" header="名称*" dataType="String" width={230} maxLength={50}/>
          <FlexGridColumnGroup binding="NameKana__c" header="名称カナ*" dataType="String" width={230} maxLength={100}/>
          <FlexGridColumnGroup binding="ZipCode__c" header="郵便番号*" dataType="String" maxLength={7}/>
          <FlexGridColumnGroup binding="Address__c" header="住所*" dataType="String" width={230} maxLength={255}/>
          <FlexGridColumnGroup binding="Tel__c" header="電話番号*" dataType="String" width={200} maxLength={11}/>
          <FlexGridColumnGroup binding="TelMobilePhone__c" header="携帯電話番号" dataType="String" width={200} maxLength={11}/>
          <FlexGridColumnGroup binding="LastModifiedUsername" header="最終更新者" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="LastModifiedDateDisp" header="更新日時" dataType="String" width={200} isReadOnly={true}/>
        </CustomFlexGrid>
      </div>
    );
  }

  /**
   * 保守工事会社情報グリッド描画
   * @return {*}
   */
  renderMaintenanceTable() {
    const props = {
      // rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'MultiColumn',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      checkedFunction: this.checkHandler,
      useStore: false,
      validateEdits: false,
    };

    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridMaintenanceRef} {...props}>
          <FlexGridColumnGroup binding="MainteKoziCompanyCode__c" header="保守工事\n会社コード" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="Name" header="名称*" dataType="String" width={230} maxLength={50}/>
          <FlexGridColumnGroup binding="NameKana__c" header="名称カナ*" dataType="String" width={230} maxLength={100}/>
          <FlexGridColumnGroup binding="RepresentativeName__c" header="代表者名*" dataType="String" maxLength={20}/>
          <FlexGridColumnGroup binding="RepresentativeNameKana__c" header="代表者名カナ*" dataType="String" maxLength={40}/>
          <FlexGridColumnGroup binding="ZipCode__c" header="郵便番号*" dataType="String" maxLength={7}/>
          <FlexGridColumnGroup binding="Address__c" header="住所*" dataType="String" width={230} maxLength={255}/>
          <FlexGridColumnGroup binding="Tel__c" header="電話番号*" dataType="String" width={200} maxLength={11}/>
          <FlexGridColumnGroup binding="Fax__c" header="FAX番号" dataType="String" width={200} maxLength={11}/>
          <FlexGridColumnGroup binding="MailAddress__c" header="メールアドレス*" dataType="String" width={250} maxLength={80}/>
          <FlexGridColumnGroup binding="LastModifiedUsername" header="最終更新者" dataType="String" isReadOnly={true}/>
          <FlexGridColumnGroup binding="LastModifiedDateDisp" header="更新日時" dataType="String" width={200} isReadOnly={true}/>
        </CustomFlexGrid>
      </div>
    );
  }

  /**
   * 請求先コンボボックスの生成
   *
   * @return {JSX.Element[]}
   */
  createBillingDestinationList() {
    const {initialValues} = this.props;

    const items = initialValues.billingDestinationInfo;
    if (!items) {
      return [];
    }

    const result = [];
    for (const item of items) {
      result.push(
          <MenuItem value={item.Id}>
            {item.BillingDestinationId__c} {item.Name}
          </MenuItem>,
      );
    }

    return result;
  }

  /**
   * 画面描画
   * @return {*}
   */
  render() {
    const {
      classes,
      handleSubmit,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<EditIcon />}
            onClick={handleSubmit(this.doEdit)}
            variant="contained"
            size="large"
            type="submit"
          >
            <span>更新</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn4" item>
          <BackButton
            isModified={this.state.isModified}
            onCheckGrid={this.doCheckModified}
            showWarning={true}
            props={this.props}/>
        </Grid>
      </Grid>
    );

    // 請求先コンボボックス生成
    this.billingDestination = this.createBillingDestinationList();

    return (
      <form noValidate autoComplete="off" >
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>
            {/* 共架事業者情報 */}
            <Typography variant="h6" gutterBottom>共架事業者情報</Typography>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid key="KyogaZgsyaCode_panel" item xs={12}>
                <Grid key="KyogaZgsyaCode" item xs={3}>
                  <Field
                    name="kyogaZgsyaInfo[0].KyogaZgsyaCode__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="共架事業者コード"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid key="kyogaZgsyaInfo_Name" item xs={12} sm={7}>
                <Field
                  name="kyogaZgsyaInfo[0].Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="名称"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.fullWidth,
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyaInfo_NameKana" item xs={12} sm={5}>
                <Field
                  name="kyogaZgsyaInfo[0].NameKana__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="名称カナ"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.halfWidth,
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyaInfo_RepresentativeName" item xs={12} sm={7}>
                <Field
                  name="kyogaZgsyaInfo[0].RepresentativeName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表者名"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.fullWidth,
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyaInfo_RepresentativeNameKana" item xs={12} sm={5}>
                <Field
                  name="kyogaZgsyaInfo[0].RepresentativeNameKana__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表者名カナ"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.halfWidth,
                    validateRule.maxLength(40),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyaInfo_ZipCode" item xs={3}>
                <Field
                  name="kyogaZgsyaInfo[0].ZipCode__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="郵便番号"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.number,
                    validateRule.minLength(7),
                    validateRule.maxLength(7),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyaInfo_Address_panel" container spacing={1} item xs={12}>
                <Grid key="kyogaZgsyaInfo_Address" item xs={7} >
                  <Field
                    name="kyogaZgsyaInfo[0].Address__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="住所"
                    fullWidth
                    type="text"
                    required={true}
                    validate={[
                      validateRule.required,
                      validateRule.maxLength(255),
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid key="kyogaZgsyaInfo_Tel" item xs={12} sm={6} md={3} >
                <Field
                  name="kyogaZgsyaInfo[0].Tel__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電話番号"
                  fullWidth
                  required={true}
                  type="text"
                  isReadOnly={true}
                  validate={[
                    validateRule.required,
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                />
              </Grid>
              <Grid key="kyogaZgsyaInfo_Fax" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaZgsyaInfo[0].Fax__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="FAX番号"
                  fullWidth
                  type="text"
                  readOnly={false}
                  validate={[
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="kyogaZgsyaInfo_Email_panel" container spacing={1} item xs={12}>
                <Grid key="kyogaZgsyaInfo_Email" item xs={7}>
                  <Field
                    name="kyogaZgsyaInfo[0].MailAddress__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="メールアドレス"
                    fullWidth
                    type="text"
                    validate={[
                      validateRule.email,
                      validateRule.maxLength(80),
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid key="kyogaZgsyaInfo_LastModifiedUsername" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaZgsyaInfo[0].LastModifiedUsername"
                  className={classes.fields}
                  component={renderTextField}
                  label="最終更新者"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyaInfo_LastModifiedDateDisp" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaZgsyaInfo[0].LastModifiedDateDisp"
                  className={classes.fields}
                  component={renderTextField}
                  label="更新日時"
                  fullWidth
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>
            </Grid>
          </PaperPart>

          <PaperPart>
            {/* 共架事業所情報 */}
            <Typography variant="h6" gutterBottom>共架事業所情報</Typography>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid key="KyogaZgsyoCode_panel" item xs={12}>
                <Grid key="KyogaZgsyoCode" item xs={3}>
                  <Field
                    name="kyogaZgsyoInfo[0].KyogaZgsyoCode__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="共架事業所コード"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid key="kyogaZgsyoInfo_Name" item xs={12} sm={7}>
                <Field
                  name="kyogaZgsyoInfo[0].Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="名称"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.fullWidth,
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyoInfo_NameKana" item xs={12} sm={5}>
                <Field
                  name="kyogaZgsyoInfo[0].NameKana__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="名称カナ"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.halfWidth,
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyoInfo_RepresentativeName" item xs={12} sm={7}>
                <Field
                  name="kyogaZgsyoInfo[0].RepresentativeName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表者名"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.fullWidth,
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyoInfo_RepresentativeNameKana" item xs={12} sm={5}>
                <Field
                  name="kyogaZgsyoInfo[0].RepresentativeNameKana__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表者名カナ"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.halfWidth,
                    validateRule.maxLength(40),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyoInfo_ZipCode" item xs={3}>
                <Field
                  name="kyogaZgsyoInfo[0].ZipCode__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="郵便番号"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.number,
                    validateRule.minLength(7),
                    validateRule.maxLength(7),
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyoInfo_Address_panel" container spacing={1} item xs={12}>
                <Grid key="kyogaZgsyoInfo_Address" item xs={7} >
                  <Field
                    name="kyogaZgsyoInfo[0].Address__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="住所"
                    fullWidth
                    type="text"
                    required={true}
                    validate={[
                      validateRule.required,
                      validateRule.maxLength(255),
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid key="kyogaZgsyoInfo_Tel" item xs={12} sm={6} md={3} >
                <Field
                  name="kyogaZgsyoInfo[0].Tel__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電話番号"
                  fullWidth
                  required={true}
                  type="text"
                  isReadOnly={true}
                  validate={[
                    validateRule.required,
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                />
              </Grid>
              <Grid key="kyogaZgsyoInfo_Fax" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaZgsyoInfo[0].Fax__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="FAX番号"
                  fullWidth
                  type="text"
                  readOnly={false}
                  validate={[
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="kyogaZgsyoInfo_Email_panel" container spacing={1} item xs={12}>
                <Grid key="kyogaZgsyoInfo_Email" item xs={7}>
                  <Field
                    name="kyogaZgsyoInfo[0].MailAddress__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="メールアドレス"
                    fullWidth
                    type="text"
                    validate={[
                      validateRule.email,
                      validateRule.maxLength(80),
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid key="kyogaZgsyoInfo_ProprietyJudgeBilling_panel" container spacing={1} item xs={12}>
                <Grid key="kyogaZgsyoInfo_ProprietyJudgeBillingTiming" item xs={12} sm={4} md={3}>
                  <Field
                    name="kyogaZgsyoInfo[0].ProprietyJudgeBillingTimingName"
                    className={classes.fields}
                    component={renderTextField}
                    label="可否判定 請求タイミング"
                    fullWidth
                    type="text"
                    placeholder="未設定"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="kyogaZgsyoInfo_ProprietyJudgeBillingDestination" item xs={12} sm={8} md={9}>
                  <Field
                    name="kyogaZgsyoInfo[0].ProprietyJudgeBillingDestination__c"
                    className={classes.fields}
                    component={renderSelect}
                    label="可否判定 請求先"
                    fullWidth
                    required={true}
                    validate={[
                    ]}
                  >
                    {this.billingDestination}
                  </Field>
                </Grid>
              </Grid>

              <Grid key="kyogaZgsyoInfo_GsKoziBilling_panel" container spacing={1} item xs={12}>
                <Grid key="kyogaZgsyoInfo_GsKoziBillingTiming" item xs={12} sm={4} md={3}>
                  <Field
                    name="kyogaZgsyoInfo[0].GsKoziBillingTimingName"
                    className={classes.fields}
                    component={renderTextField}
                    label="改造工事 請求タイミング"
                    fullWidth
                    type="text"
                    placeholder="未設定"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>
                <Grid key="kyogaZgsyoInfo_GsKoziBillingDestination" item xs={12} sm={8} md={9}>
                  <Field
                    name="kyogaZgsyoInfo[0].GsKoziBillingDestination__c"
                    className={classes.fields}
                    component={renderSelect}
                    label="改造工事 請求先"
                    fullWidth
                    required={true}
                    validate={[
                    ]}
                  >
                    {this.billingDestination}
                  </Field>
                </Grid>
              </Grid>

              <Grid key="kyogaZgsyoInfo_LastModifiedUsername" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaZgsyoInfo[0].LastModifiedUsername"
                  className={classes.fields}
                  component={renderTextField}
                  label="最終更新者"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="kyogaZgsyoInfo_LastModifiedDateDisp" item xs={12} sm={6} md={3}>
                <Field
                  name="kyogaZgsyoInfo[0].LastModifiedDateDisp"
                  className={classes.fields}
                  component={renderTextField}
                  label="更新日時"
                  fullWidth
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>
            </Grid>
          </PaperPart>
          <PaperPart>
            {/* 請求先情報 */}
            <Typography variant="h6" gutterBottom>請求先情報</Typography>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                {this.renderBillingTable()}
              </Grid>

            </Grid>
          </PaperPart>
          <PaperPart>
            {/* 保守工事会社情報 */}
            <Typography variant="h6" gutterBottom>保守工事会社情報</Typography>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                {this.renderMaintenanceTable()}
              </Grid>

            </Grid>
          </PaperPart>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  handleSubmit: PropTypes.func,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  doLogout: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  doGetAccountList: PropTypes.func,
  doGetAccountMasterList: PropTypes.func,
  accountList: PropTypes.array,
  accountMasterList: PropTypes.array,
  kyogaZgsyaInfo: PropTypes.array,
  kyogaZgsyoInfo: PropTypes.array,
  billingDestinationInfo: PropTypes.array,
  maintenanceKoziCompanyInfo: PropTypes.array,
  userInfo: PropTypes.object,
  classes: PropTypes.object,
  initialValues: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  doUpdateAccounts: PropTypes.func,
  doClearAccountList: PropTypes.func,
  doClearAccountMasterList: PropTypes.func,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const accountList = state.common.accountList;
  const accountMasterList = state.common.accountMasterList;

  // 初期データ作成
  const resultInfo = createInitialValues(accountList, accountMasterList);
  const kyogaZgsyaInfo = resultInfo.kyogaZgsyaInfo;
  changeDateFormat(kyogaZgsyaInfo);
  setLastModifiedName(kyogaZgsyaInfo, state.common.generalPurposeMap);

  const kyogaZgsyoInfo = resultInfo.kyogaZgsyoInfo;
  changeDateFormat(kyogaZgsyoInfo);
  setLastModifiedName(kyogaZgsyoInfo, state.common.generalPurposeMap);

  const maintenanceKoziCompanyInfo = resultInfo.maintenanceKoziCompanyInfo;
  const billingDestinationInfo = resultInfo.billingDestinationInfo;

  const initialValues = {
    kyogaZgsyaInfo,
    kyogaZgsyoInfo,
    billingDestinationInfo,
    maintenanceKoziCompanyInfo,
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    accountList: state.common.accountList,
    initialValues,
  };
};

/**
 *  初期データ作成
 * @param {object[]} accountList
 * @param {object[]} accountMasterList
 * @return {*}
 */
const createInitialValues = (accountList, accountMasterList) => {
  if (!accountList) {
    return ({
      kyogaZgsyaInfo: [],
      kyogaZgsyoInfo: [],
      billingDestinationInfo: [],
      maintenanceKoziCompanyInfo: [],
    });
  }

  if (!accountMasterList) {
    accountMasterList = [];
  }

  const kyogaZgsyaInfo = [];
  const kyogaZgsyoInfo = [];
  const billingDestinationInfo = [];
  const maintenanceKoziCompanyInfo = [];

  const allAccountList = [...accountList, ...accountMasterList];

  allAccountList.forEach(function(account) {
    let developerName = account.RecordType.DeveloperName;

    switch (developerName) {
      case 'KyogaZgsya':
        kyogaZgsyaInfo.push(account);
        break;

      case 'KyogaZgsyo':
        kyogaZgsyoInfo.push(account);
        break;

      case 'BillingDestination':
        billingDestinationInfo.push(account);
        break;

      case 'MaintenanceKoziCompany':
        maintenanceKoziCompanyInfo.push(account);
        break;

      default:
        console.log('対象外のdeveloperName: ' + developerName);
        break;
    }
  });

  return {
    kyogaZgsyaInfo: kyogaZgsyaInfo,
    kyogaZgsyoInfo: kyogaZgsyoInfo,
    billingDestinationInfo: billingDestinationInfo,
    maintenanceKoziCompanyInfo: maintenanceKoziCompanyInfo,
  };
};

/**
 * 日付変換
 * @param {*} target 変換対象リスト
 */
const changeDateFormat = (target) => {
  for (let i = 0; i < target.length; i++) {
    target[i].LastModifiedDateDisp =
        changeDateFormatter(target[i].LastModifiedDate, 'YYYY/MM/DD HH:mm:ss');
  }
};

/**
 * 最終更新者設定
 * @param {*} target 変換対象リスト
 * @param {*} generalPurposeMap
 */
const setLastModifiedName = (target, generalPurposeMap) => {
  if (!generalPurposeMap) {
    return;
  }
  const awsApiUserName =
      getDataFromGeneralPurposeMap(generalPurposeMap, 'AwsApiUserName1', 1).Name;
  const sfLastModifiedName =
      getDataFromGeneralPurposeMap(generalPurposeMap, 'SfLastModifiedName1', 1).Name;

  for (let i = 0; i < target.length; i++) {
    // 最終更新者がAWS-APIなら更新者ユーザIDを設定
    const name = target[i].LastModifiedBy.Username;
    if (name.indexOf(awsApiUserName) != -1) {
      target[i].LastModifiedUsername = target[i].UpsyaUserId__c;

      // 上記以外(SFユーザ)は固定文言
    } else {
      target[i].LastModifiedUsername = sfLastModifiedName;
    }
  }
};

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  doShowMessage: commonOperations.doShowMessage,
  doGetAccountList: commonOperations.doGetAccountList,
  doGetAccountMasterList: commonOperations.doGetAccountMasterList,
  doUpdateAccounts: commonOperations.doUpdateAccounts,
  doClearAccountList: commonOperations.doClearAccountList,
  doClearAccountMasterList: commonOperations.doClearAccountMasterList,
};

const FORM_NAME = 'KyogaZgshaBasicInfo';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as wijmo from '@grapecity/wijmo';
import {MultiRowCellGroup, MultiRowCell, MultiRowCellTemplate} from '@grapecity/wijmo.react.grid.multirow';
import {Popup, InputNumber, ComboBox} from '@grapecity/wijmo.react.input';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {senStbOperations} from '../../../../../../reducks/senStb';
import {getCodeFromGeneralPurposeMap, getComboName, getErrorCode, API_CODE,
  getResponseResult, getComboList, createDataMapByComboList} from '../../../../../../common/common.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomMultiRow, {createCollectionView} from '../../../../../molecules/CustomMultiRow';
import {ClosestDrStbSenSyunko} from '../../../../../molecules/HelpComponent.js';
import {orderSenStbFields} from '../../../../../../common/SFFields';
import CommonTheme from '../../../../../../Theme.js';
import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import {ConfirmWarningMessageArea} from '../../../../../molecules/WarningMessageArea.js';
import {correlationCheckSenStb} from '../../../../../../common/correlationCheck.js';
import HelpIcon from '@material-ui/icons/Help';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const updateParams = {
  Id: null,
  SerialNumber__c: null, // 通し番号
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakOrder__c: 0, // 最接近電力設備との離隔/離隔距離（m）（申込時）
  DrStbRikakSyunko__c: null, // 電力設備離隔(m)_竣工
};

/**
 * 竣工届申込登録（径間：線設備）
 * 画面ID:1028
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      editItem: null,
      open: false,
      agree: false,
      warningMessages: [],
    };
    this.popup = null;
    this.gridRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // 編集ダイアログ用
    this.popupItems = {
      ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
      DrStbRikakSyunko__c: null, // 最接近電力設備との離隔/離隔距離（m）（竣工後）
    };

    // フィルターを表示する列
    this.filterCols = ['SerialNumber__c', 'SenroName1__c', 'DntNo1__c', 'DntCategory1__c',
      'SenroName2__c', 'DntNo2__c', 'DntCategory2__c', 'ClosestDrStbName__c',
      'ClosestDrStbRikakOrder__c', 'DrStbRikakSyunko__c'];

    // 最接近電力設備との離隔
    this.headerClosestDrStbTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <ClosestDrStbSenSyunko/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      return this.checkGrid(item, prop, parsing);
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearSenStbList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1028', 'Id');
    }
  }

  /**
  * システムエラー
  */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusKoziStartOk =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2); // 竣工待ち／工事開始可

    // 申込IDに紐づく線設備を取得する
    const conditions = {
      'Id': orderId,
      'RecordType.DeveloperName': 'MainOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response = await this.props.doGetFormalSenStbList(
        conditions, orderSenStbFields, appSortParams);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['線設備', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    let _hasError = false;
    if (_warningMessages.length > 0) {
      _hasError = true;
    }

    // グリッド初期化
    this.gridRef.current.setScreenState('1028');
    const convertSenStbList = this.convertSenStbList(this.props.senStbList);
    const cv = createCollectionView(convertSenStbList,
        true, {}, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    await this.props.doSetGmnWarning('1028', _hasError);
    const isErrorInApply = this.isErrorInApply();
    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
        orderStatusKoziStartOk ? false : true), // 竣工待ち/工事開始可のみ編集可
      hasError: isErrorInApply,
      isModified: false,
      warningMessages: _warningMessages,
    });

    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
          data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'Id') ?
          data.order.Id : null;
      lastModifiedDate =
          data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
          data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusKoziStartOk =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2);
    if (this.props.order &&
      this.props.order.OrderStatus__c != orderStatusKoziStartOk) {
      // 竣工待ち／工事開始可のときだけ入力チェックする
      return;
    }

    for (const senStb of this.props.senStbList) {
      // 工事取止の場合、チェックしない
      if (Object.hasOwnProperty.call(senStb, 'KoziUndo') &&
          senStb.KoziUndo == true) {
        continue;
      }
      // 必須チェック
      // 最接近電力設備との離隔/設備名称
      if (!senStb.ClosestDrStbName__c) {
        warningMessages.push({
          id: 'CE0017',
          values: ['最接近電力設備との離隔/設備名称(' + senStb.SerialNumber__c + '行目)'],
        });
      }
      // 最接近電力設備との離隔/離隔距離（m）（竣工後）
      if (senStb.DrStbRikakSyunko__c == undefined ||
          senStb.DrStbRikakSyunko__c == null) {
        warningMessages.push({
          id: 'CE0017',
          values: ['最接近電力設備との離隔/離隔距離（m）（竣工後）(' +
                    senStb.SerialNumber__c + '行目)'],
        });
      }
      // データ範囲チェック
      // 最接近電力設備との離隔/離隔距離（m）（竣工後）
      if (senStb.DrStbRikakSyunko__c != null &&
          (senStb.DrStbRikakSyunko__c < 0.01 ||
          senStb.DrStbRikakSyunko__c > 9.99)) {
        warningMessages.push({
          id: 'CE0026',
          values: ['最接近電力設備との離隔/離隔距離（m）（竣工後）(' +
                    senStb.SerialNumber__c + '行目)', '0.01～9.99'],
        });
      }
    }
    // 相関チェック
    const correlationMessages =
      correlationCheckSenStb('syunko', this.props.order, this.props.senStbList);
    for (const message of correlationMessages) {
      warningMessages.push(message);
    }
  }

  /**
   * 入力チェック
   * @param {object} item
   * @param {object} prop
   * @param {object} parsing
   * @return {string} チェック結果
   */
  checkGrid = (item, prop, parsing) => {
    // 工事取止の場合、チェックしない
    if (Object.hasOwnProperty.call(item, 'KoziUndo') &&
        item.KoziUndo == true) {
      return null;
    }
    // 必須チェック
    // 最接近電力設備との離隔/設備名称
    // 最接近電力設備との離隔/離隔距離（m）（施工後）
    if (['ClosestDrStbName__c', 'DrStbRikakSyunko__c'].includes(prop)) {
      // CE0017
      let error = validateRule.required4(
          item[prop]);
      if (error) {
        return error;
      }
    }
    // データ範囲チェック
    // 最接近電力設備との離隔/離隔距離（m）（施工後）
    if (['DrStbRikakSyunko__c'].includes(prop)) {
      // CE0026
      if (item[prop] < 0.01 || item[prop] > 9.99) {
        return '0.01～9.99の範囲内で入力してください';
      }
    }
    return null;
  }

  /**
   * 線設備のnullデータを初期化する
   * @param {array} senStbList
   * @return {array} 初期化した線設備リスト
   */
  convertSenStbList(senStbList) {
    // 数値項目がnullだとwijmoの部品でエラーになるので、0で初期化する
    const convertList = [];
    for (const senStb of senStbList) {
      let data = {};
      Object.assign(data, senStb);
      for (const key of ['DrStbRikakSyunko__c']) {
        if (Object.hasOwnProperty.call(data, key) &&
             data[key] == null) {
          data[key] = 0;
        }
      }
      convertList.push(data);
    }
    return convertList;
  }

  // 編集ダイアログ初期化
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/設備名称
   * @param {*} ctl
   */
  initializeEditedClosestDrStbName(ctl) {
    this.popupItems['ClosestDrStbName__c'] = ctl;
  }

  /**
   * 最接近電力設備との離隔/離隔距離(m)/竣工後
   * @param {*} ctl
  */
  initializeEditedDrStbRikakSyunko(ctl) {
    this.popupItems['DrStbRikakSyunko__c'] = ctl;
  }

  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
  */
  editPopup(grid, e) {
    try {
      const ht = grid.hitTest(e);
      let index = 0;
      if (ht.row > 1) {
        index = ht.row % 2 == 0 ? ht.row / 2 : (ht.row - 1) / 2;
      }
      if (ht.panel === grid.rowHeaders) {
        let _editItem = grid.collectionView.sourceCollection[index];
        this.setState({editItem: _editItem});
        // 最接近電力設備との離隔/設備名称
        for (const key of ['ClosestDrStbName__c']) {
          if (Object.hasOwnProperty.call(_editItem, key)) {
            this.popupItems[key].selectedValue = _editItem[key];
          }
        }
        // 最接近電力設備との離隔/離隔距離（m）/竣工後
        for (const key of ['DrStbRikakSyunko__c']) {
          if (Object.hasOwnProperty.call(_editItem, key)) {
            this.popupItems[key].value =
              _editItem[key] === null ? 0 : _editItem[key];
          }
        }
        this.popup.show(true, (s) => {
          if (s.dialogResult === 'wj-hide-ok') {
            (grid.collectionView).editItem(_editItem);
            // 最接近電力設備との離隔/設備名称
            if (_editItem.ClosestDrStbName__c !=
              this.popupItems['ClosestDrStbName__c'].selectedValue) {
              _editItem.ClosestDrStbName__c =
              this.popupItems['ClosestDrStbName__c'].selectedValue;
              // 設備名称を申込時から変更したので離隔距離（m）（申込時）をクリアする
              _editItem.ClosestDrStbRikakOrder__c = 0.00;
            }
            // 最接近電力設備との離隔/離隔距離（m）/竣工後
            for (const key of ['DrStbRikakSyunko__c']) {
              if (Object.hasOwnProperty.call(_editItem, key)) {
                this.popupItems[key].value =
                  _editItem[key] = this.popupItems[key].value;
              }
            }
            (grid.collectionView).commitEdit();
          }
          grid.focus();
        });
      }
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 申込確認ダイアログを表示
  */
  handleClickOpen = () => {
    this.setState({open: true});
  };

  /**
   * 申込確認ダイアログを閉じる
   */
  handleClose = () => {
    this.setState({
      open: false,
      agree: false,
    });
  };

  /**
   * 保存ボタン押下時
  */
  doSave = async () => {
    try {
      if (!this.gridRef.current) {
        return;
      }
      // 選択状態保存
      this.gridRef.current.saveScreenState('1028', 'Id');

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.moveToList();
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり
   */
  doCheckModified = () => {
    let result = this.state.isModified;
    if (this.gridRef.current) {
      result = this.gridRef.current.isItemsModified();
    }
    if (this.state.isModified != result) {
      this.setState({isModified: result});
    }
    return result;
  }

  /**
   * 線設備の更新処理
  */
  insertOrUpdate = async () => {
    const {order} = this.props;

    const senStbList = this.gridRef.current.itemsAllChanged().itemsEdited;
    const saveSenStbListList = [];
    const updateConditions = [];

    for (const senStb of senStbList) {
      // 更新項目設定
      const updateData = {};
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(senStb, key)) {
          updateData[key] = senStb[key];
        }
      }
      saveSenStbListList.push(updateData);
      // 線設備更新条件
      updateConditions.push({
        Id: senStb.Id,
        LastModifiedDate: senStb.LastModifiedDate,
      });
    }

    // 削除
    const deleteConditions = [];

    // 一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: this.props.orderModifiedCondition != null ?
        this.props.orderModifiedCondition.LastModifiedDate : null,
    };
    const updateOrder = {
      Id: order.Id,
    };

    const response = await this.props.doSaveFormalSenStbs(
        updateConditions, deleteConditions, saveSenStbListList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(response, ['線設備', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 申込前の確認
   */
  doConfirm = async () => {
    try {
      if (!this.props.order.Id) {
        return;
      }
      if (!this.gridRef.current) {
        return;
      }
      const orderStatusKoziStartOk =
        getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
      if (this.props.order &&
          this.props.order.OrderStatus__c != orderStatusKoziStartOk) {
        return;
      }

      // 編集ありの場合、ユーザーに保存するか破棄するか確認
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0161',
            values: ['申込'],
          },
          action: async () => {
            // 保存処理
            const ret = await this.insertOrUpdate();
            if (!ret.success) {
              if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
                this.props.doShowMessage({
                  message: ret.messages,
                  action: async () => {
                    // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
                    this.moveToList();
                  },
                });
              } else {
                this.props.doShowMessage({message: ret.messages});
              }
              return;
            }

            // 再検索
            const searchResult = await this.searchData();
            if (!searchResult) {
              return;
            }
            // エラーがなければ申込処理を行う
            if (!this.isErrorInApply()) {
              this.handleClickOpen();
            }
          },
        });
        return;
      } else {
        // エラーがなければ申込処理を行う
        if (!this.isErrorInApply()) {
          this.handleClickOpen();
        }
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 竣工届一覧へ遷移
   */
  moveToList() {
    this.props.history.push(
        {pathname: '/SyunkoReportList'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * 申込前の入力チェック
   * @return {bool} true: エラーあり, false:エラーなし
   */
  isErrorInApply = () => {
    let isError = false;
    if (this.props.setGmnWarning) {
      for (const gmnkey of ['1027_sen', '1028']) {
        if (Object.hasOwnProperty.call(this.props.setGmnWarning, gmnkey)) {
          isError = isError || this.props.setGmnWarning[gmnkey];
        }
      }
    }
    return isError;
  }

  /**
   * 申込確認ダイアログの申込ボタン押下
  */
  doApply = async () => {
    try {
      this.handleClose();
      this.updateOrder();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込処理実行
   */
  updateOrder = async () => {
    const {generalPurposeMap} = this.props;
    const orderStatusUpdate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus41', 2);// 竣工確認中
    const senStbList = [];

    Object.assign(senStbList, this.gridRef.current.getSourceCollection());
    const saveSenStbListList = [];
    const updateConditions = [];

    for (const senStb of senStbList) {
      // 更新項目設定
      const updateData = {};
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(senStb, key) &&
            senStb[key]) {
          updateData[key] = senStb[key];
        }
      }

      saveSenStbListList.push(updateData);

      updateConditions.push({
        Id: senStb.Id,
        LastModifiedDate: senStb.LastModifiedDate,
      });
    }

    // 削除
    const deleteConditions = [];

    // 申込更新条件
    const orderConditions = {
      Id: this.props.order.Id,
      LastModifiedDate: this.props.orderModifiedCondition != null ?
        this.props.orderModifiedCondition.LastModifiedDate : null,
    };
    // 申込 竣工届登録日はサーバー側で設定する
    const updateOrder = {
      Id: this.props.order.Id,
      OrderStatus__c: orderStatusUpdate,
    };

    const response = await this.props.doSaveFormalSenStbs(
        updateConditions, deleteConditions, saveSenStbListList,
        orderConditions, updateOrder, true);
    const resResult = getResponseResult(response, ['竣工届申込', '申込']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      if (resResult.messages.filter((data) => data.id == 'CE0048').length > 0) {
        this.props.doShowMessage({
          message: resResult.messages,
          action: async () => {
            // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
            this.moveToList();
          },
        });
      } else {
        this.props.doShowMessage({
          message: resResult.messages,
        });
      }
      return;
    }

    this.props.doShowMessage({
      message: {
        id: 'CI0009',
        values: ['竣工届申込'],
      },
      action: () => {
        //  申込が成功したら一覧へ遷移
        this.moveToList();
      },
    });
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    let edt = s.activeEditor;
    const currentColumn = edt.filter.column.binding;
    if (currentColumn === 'DntCategory1__c' ||
        currentColumn === 'DntCategory2__c' ||
        currentColumn === 'ClosestDrStbName__c') {
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分（一）,電柱区分（二）
      if (currentColumn === 'ClosestDrStbName__c') {
        categoryName = 'KiknClosestDrStbName'; // 最接近電力設備との離隔/設備名称
      }
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * セル編集終了時カスタム処理。
   *
   * @param {FlexGrid} s FlexGrid
   * @param {CellRangeEventArgs} e CellRangeEventArgs
   * @param {string} newValue 入力値
   */
  onCellEditEnding = (s, e, newValue) => {
    // 対象のカラムを取得
    const column = s.columns[e.col];

    // 設備名称(ClosestDrStbName__c)の列でない場合は終了
    if (column.binding != 'ClosestDrStbName__c') {
      return;
    }

    const dataMap = column.dataMap;

    // 入力値がコードの場合は表示値に変換
    const newDisplayValue = dataMap.getDisplayValue(newValue);
    if (newDisplayValue != null) {
      newValue = newDisplayValue;
    }

    // 入力不可項目の場合は処理終了
    if (!dataMap.getKeyValue(newValue)) {
      return;
    }

    // 入力前の値を取得(getCellDataはコード値を返却のため表示値に変更)
    const oldCode = s.getCellData(e.row, e.col);
    const oldValue = dataMap.getDisplayValue(oldCode);

    // 値が変わっていない場合は処理終了
    if (newValue == oldValue) {
      return;
    }

    // 設備名称変更時にClosestDrStbRikakOrder__cを0にする
    const item = s.rows[e.row].dataItem;
    item.ClosestDrStbRikakOrder__c = 0.00;
  }

  renderTable() {
    const {classes, generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      filters: this.filterCols,
      headersVisibility: 'All',
      allowSorting: 'None',
      counterOn: false,
      AddDeleteOn: false,
      frozenColumns: 4,
      style: {height: '600px'},
      isReadOnly: this.state.isDisabled,
      useStore: false,
      validateEdits: false,
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      filterChanging: this.filterChangingHandler,
      onCellEditEnding: this.onCellEditEnding,
    };

    // コンボボックス: 設備名称
    const stbNameList = getComboList(generalPurposeMap, 'KiknClosestDrStbName');
    const stbNameMap = createDataMapByComboList(stbNameList);

    return (
      <React.Fragment>
        <CustomMultiRow ref={this.gridRef} {...props}>
          <MultiRowCellGroup header="" align="center">
            <MultiRowCell binding='SerialNumber__c' header='通し番号' cssClass="uneditableCell" width={50} isReadOnly={true}/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="ケーブルルート" align="center" colspan={3}>
            <MultiRowCell binding='Dsp_SenroName1__c' header='線路名（一）' dataType="String" cssClass="uneditableCell" width={125} isReadOnly={true}/>
            <MultiRowCell binding='Dsp_DntNo1__c' header='電柱番号（一）' dataType="String" cssClass="uneditableCell" isReadOnly={true} width={140} isRequired={true}/>
            <MultiRowCell binding='DntCategory1__c' header='電柱区分（一）' dataType="String" cssClass="uneditableCell" width={140} isReadOnly={true}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory1__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='Dsp_SenroName2__c' header='線路名（二）' dataType="String" cssClass="uneditableCell" isReadOnly={true}/>
            <MultiRowCell binding='Dsp_DntNo2__c' header='電柱番号（二）' dataType="String" cssClass="uneditableCell" isReadOnly={true} isRequired={true}/>
            <MultiRowCell binding='DntCategory2__c' header='電柱区分（二）' dataType="String" cssClass="uneditableCell" isReadOnly={true}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory2__c);
              }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="最接近電力設備との離隔" align="center" colspan={3}>
            <MultiRowCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerClosestDrStbTemplate}/>
            <MultiRowCell binding='ClosestDrStbName__c' header='設備名称' dataMap={stbNameMap} width={270}/>
            <MultiRowCell binding='ClosestDrStbRikakOrder__c' header='離隔距離（m）（申込時）' dataType="Number" format="n2" cssClass="uneditableCell" width={140} isReadOnly={true} >
            </MultiRowCell>
            <MultiRowCell binding='DrStbRikakSyunko__c' header='離隔距離（m）（竣工後）' dataType="Number" width={140} format="n2">
              <MultiRowCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="multiRow-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value ? ctx.value : 0}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
        </CustomMultiRow>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              線路名　（一）：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName1__c') ?
                        this.state.editItem.Dsp_SenroName1__c : ''}
              <br/>
                電柱番号（一）：{this.state.editItem &&
                                Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo1__c') ?
                                this.state.editItem.Dsp_DntNo1__c : ''}
              <br/>
               線路名　（二）：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName2__c') ?
                        this.state.editItem.Dsp_SenroName2__c : ''}
              <br/>
                電柱番号（二）：{this.state.editItem &&
                                Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo2__c') ?
                                this.state.editItem.Dsp_DntNo2__c : ''}
            </h4>
            <button className="btn-close wj-hide" style={{marginLeft: '50px'}}/>
          </div>
          <div className="modal-body">
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
              最接近電力設備との離隔
            </div>
            <br/>
            <div className="wj-labeled-input" >
              <ComboBox
                id="id1028ClosestDrStbStbNamePopup"
                initialized={
                  this.initializeEditedClosestDrStbName.bind(this)}
                itemsSource={stbNameList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>設備名称</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id="id1028DrStbRikakSyunkoPopup"
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedDrStbRikakSyunko.bind(this)}
                isDisabled={this.state.isDisabled}
              />
              <label>離隔距離（m）（施工後）</label>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>
                  キャンセル</button>
            </div>
          </div>
        </Popup>

        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="closestDrStbHelpComponent">
            <ClosestDrStbSenSyunko/>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1028Confirm" item>
          <PositiveButton
            id="btn1028Confirm"
            onClick={handleSubmit(this.doConfirm)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled || this.state.hasError}
          >
            <span>申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1028Save" item>
          <PositiveButton
            id="btn1028Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon />}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1028Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onCheckGrid={this.doCheckModified}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='申込' fixAction=''/>
          }
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid container
            direction="column"
            alignItems="flex-start">
            <Grid key="key1028Table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>

        <React.Fragment>
          <Dialog onClose={this.handleClose}
            aria-labelledby="dialogTitle1028"
            open={this.state.open}
            maxWidth="md">
            <DialogContent dividers>
              <Typography variant="body1" gutterBottom>
                  よろしければ以下をチェックして申込ボタンを押してください。
              </Typography>
              <Typography style={{whiteSpace: 'pre-wrap'}} component="div" gutterBottom>
                <Box fontWeight="fontWeightBold">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='chb1028Confirm'
                        checked={this.state.agree ? true : false}
                        onChange={(e) =>
                          this.setState({agree: e.target.checked})}
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                      />
                    }
                    label={
                      <div style={{maxWidth: '540px'}}>
                        <span style={{fontWeight: 'bold'}}>
                      自主点検により，自らの共架工事に不備は無く，本報告内容に誤り・虚偽が無いことを誓約します。
                      本報告後，共架工事の不備が判明し，改修等の指示があった場合は，速やかに対応します。
                        </span>
                      </div>}
                  />
                </Box>
              </Typography>
            </DialogContent>
            <DialogActions>
              <PositiveButton
                id="id1028DialogApply"
                onClick={handleSubmit(this.doApply)}
                variant="contained"
                size="large"
                disabled={this.state.agree === true? false: true}
                startIcon={<AddIcon/>}
              >
                <span>申込</span>
              </PositiveButton>
              <NegativeButton
                id="id1028DialogCancel"
                onClick={this.handleClose}
                variant="contained"
                startIcon={<KeyboardReturnIcon />}
                size="large"
              >
                <span>閉じる</span>
              </NegativeButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  senStbList: PropTypes.array,
  setGmnWarning: PropTypes.object,
  orderModifiedCondition: PropTypes.object,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetFormalSenStbList: PropTypes.func,
  doSaveFormalSenStbs: PropTypes.func,
  doClearSenStbList: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  doSetOrderModifiedCondition: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: state.senStb.senStbList &&
          Object.hasOwnProperty.call(state.senStb.senStbList, 'order') ?
              state.senStb.senStbList.order : null,
    senStbList: state.senStb.senStbList &&
                  Object.hasOwnProperty.call(state.senStb.senStbList, 'senStbList') ?
                  state.senStb.senStbList.senStbList : [],
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetFormalSenStbList: senStbOperations.doGetFormalSenStbList,
  doSaveFormalSenStbs: senStbOperations.doSaveFormalSenStbs,
  doClearSenStbList: senStbOperations.doClearSenStbList,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'SyunkoSenStb';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {execApexRestApi, changeDateFormat, getToDate, getAddDate,
  getResponseResult, API_CODE, getDataFromGeneralPurposeMap, getErrorCode} from '../../../../../../common/common.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import KyogaCompanyInformation from '../../../../../molecules/KyogaCompanyInformation.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList, createSearchListEx, changePrefectures} from '../../../../../../common/ComboParts.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

// 申込テーブル検索時のソート条件
const appSortParams = {
  PayOffResultRegisterDate__c: -1,
};

// 申込テーブル検索時の検索対象のフィールド名
const appFields = ['OrderNo__c', 'KyogaType__c',
  'toLabel(KyogaType__c)KyogaTypeName',
  'toLabel(StbType__c)', 'OrderDate__c',
  'KoziTitle__c', 'KoziPlaceMainDntNo__c', 'NWZgsyo__r.Name',
  'KoziPlaceMainDntNo_SenroName__c', 'Dsp_KoziPlaceMainDntNo__c',
  'toLabel(KoziPlacePrefectures__c)', 'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c', 'ProprietyJudgeOrderNo__c',
  'KoziAmount__c', 'GaisanResultRegisterDate__c',
  'GaisanConsiderResultDeadline__c', 'toLabel(AnswerUmu__c)',
  'DrSideKoziRequiredPeriod__c', 'DrSideKoziCompletionDate__c',
  'OrderStatus__c', 'toLabel(OrderStatus__c)OrderStatusName',
  // 画面表示以外
  'Id', 'PayOffResultRegisterDate__c', 'PayOffAmountReceiveDate__c',
  'LastModifiedDate',
];

/**
 * 改造工事完了件名一覧
 * 画面ID:1131
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.selector = null;
    this.state = {
      retButtonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1131', []);
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderList();
    this.doClearList();
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      // 一覧表示部に１件以上表示されているか判定
      const selectedItems = this.gridRef && this.gridRef.current ?
          this.gridRef.current.selectedItem() : [];
      const disableButton = (selectedItems.length <= 0);
      this.setState({retButtonDisabled: disableButton});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      // 画面入力されたデータのみ検索対象として取得
      const wkData = JSON.parse(JSON.stringify(this.props.values));

      if (!wkData.hasOwnProperty('PayOffCostStatus')) {
        // 仕様上、改造工事完了登録ステータスが空になることはないが、念のため判定
        console.error('改造工事完了登録ステータスが未設定のため、選択して下さい');
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['改造工事完了登録ステータス'],
          },
        });
        return;
      }

      // パラメータ設定
      // 事業所
      if ('NWZgsyo__r_Code__c' in wkData) {
        wkData['NWZgsyo__r.Code__c'] = wkData.NWZgsyo__r_Code__c;
        delete wkData.NWZgsyo__r_Code__c;
      }
      // 申込区分(本申込)
      wkData['OrderCategory__c'] = '02';
      // 電力側工事有無
      wkData['DrSideKoziUmu__c'] = '1';
      // レコードタイプ
      wkData['RecordType.DeveloperName'] = 'MainOrder';

      const payOffCostStatus = wkData.PayOffCostStatus;
      // 改造工事完了登録ステータスを検索対象から削除
      delete wkData.PayOffCostStatus;

      // 検索条件からnull、空文字条件除去
      const conditions = {};
      for (const item in wkData) {
        if (!wkData.hasOwnProperty(item)) {
          continue;
        }
        if (typeof wkData[item] == 'string' && !wkData[item].trim()) {
          continue;
        }
        if (wkData[item]) {
          conditions[item] = wkData[item];
        }
      }

      // ステータスは、申込の精算結果登録日で判断
      // 日付が入っていれば、「登録済」、入っていなければ、「未登録」
      if (payOffCostStatus === '1') {
        // ステータスに登録済を選択した場合
        conditions['PayOffResultRegisterDate__c_NE'] = null;
      } else {
        // 上記以外(未登録)
        conditions['PayOffResultRegisterDate__c_EQ'] = null;
      }

      // 申込ステータス設定
      conditions['OrderStatus__c'] = ['31', '40', '41'];

      // 申込テーブルからデータ取得
      const result = await this.props.doGetOrderList(
          conditions, appFields, appSortParams);
      const errorCode = getErrorCode(result);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['改造工事完了件名', '取得'],
          },
        });
        return;
      }
      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1131', 'Id', true);
      }

      // 件数超過エラー
      const maxKensu = getDataFromGeneralPurposeMap(
          this.props.generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      const dataLen = result.data.body.data ?
        result.data.body.data.length : 0;
      if (dataLen >= maxKensu) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
        this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }

      // 検索結果を画面表示用に更新
      const dataList = [];
      for (const item of this.props.orderList) {
        let wkOrder = item;

        // 確定前の場合、概算金額は空設定
        if (wkOrder['OrderStatus__c'] <= 15) {
          wkOrder['KoziAmount__c'] = '';
        }
        dataList.push(wkOrder);
      }

      // FlexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1131', dataList);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
   * 改造工事完了件名確認ボタン押下時
   * @return {void} 戻り値なし
   */
  doConfirm = async () => {
    try {
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (selectedItems.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['編集対象'],
          },
        });
        return;
      }

      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }

      // 改造工事完了登録ステータスが登録済であるか判定
      if (!selectedItems[0].dataItem.PayOffResultRegisterDate__c) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['改造工事完了した申込'],
          },
        });
        return;
      }

      // 精算金額受領日が未設定の場合、更新
      if (!selectedItems[0].dataItem.PayOffAmountReceiveDate__c) {
        const body = {
          'record': [{
            'conditions': {
              'Id': selectedItems[0].dataItem.Id,
              'LastModifiedDate': selectedItems[0].dataItem.LastModifiedDate,
            },
            'order': {
              'Id': selectedItems[0].dataItem.Id,
              'PayOffAmountReceiveDate__c': getToDate(), // 精算金額受領日(システム日付)
            },
          }],
          'stage': 'PAYOFFAMOUNTRECEIVEDATE_UPDATE',
        };

        const result = await execApexRestApi(this.props.userInfo,
            'ApiAttachOrder/payoffCostCalculationAccept', body);
        const resResult = getResponseResult(result, ['選択項目', '更新']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
            this.props.doShowMessage({
              message: resResult.messages,
              action: async () => {
                // 排他チェックエラーになったら、データの整合性をとるため再検索
                await this.searchOrder();
              },
            });
          } else {
            this.props.doShowMessage({
              message: resResult.messages,
            });
          }
          return;
        }
      }

      this.props.doSetOrderId(selectedItems[0].dataItem.Id);
      this.props.history.push({pathname: '/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/Confirm'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1131', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      style: {height: '300px'},
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      // 一覧表示部の表示設定
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='OrderNo__c' header='申込番号' dataType="String"></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='StbType__c' header='設備種別' dataType="String" width={100}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KyogaTypeName' header='共架種別' dataType="String"></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='OrderDate__c' header='申込年月日' dataType="Date" width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(context.item.OrderDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziTitle__c' header='工事件名' dataType="String"></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='NWZgsyo__r.Name' header='事業所名' dataType="String"></FlexGridColumnGroup>
          <FlexGridColumnGroup header='工事場所'>
            <FlexGridColumnGroup binding='KoziPlaceMainDntNo_SenroName__c' header='線路名' dataType="String"></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='Dsp_KoziPlaceMainDntNo__c' header='代表電柱番号' dataType="String"></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='KoziPlacePrefectures__c' header='都道府県' dataType="String" width={100}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType="String"></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType="String"></FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='ProprietyJudgeOrderNo__c' header='可否判定申込番号' dataType="String" width={150}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziAmount__c' header='改造工事費用\n（税抜）' dataType="Number" width={130}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrSideKoziCompletionDate__c' header='改造工事\n完了日' dataType="Date" width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template= {(context) => {
                return changeDateFormat(
                    context.item.DrSideKoziCompletionDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='OrderStatusName' header='申込ステータス' dataType="String"></FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<ListAltIcon />}

            onClick={() => {
              this.doConfirm();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>改造工事完了件名確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn2" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <KyogaCompanyInformation
            companyName={userInfo.Account.Name} />

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={3}>
                <Field
                  name="OrderNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="申込番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key1-2" item xs={12} sm={3}>
                <Field
                  name="NWZgsyo__r_Code__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="事業所"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                   createSearchListEx(generalPurposeMap.NWZgsyo, 'Code__c', 'Name', null, {Code__c: ['1001', '1065']})}
                </Field>
              </Grid>

              <Grid key="key1-3" item xs={12} sm={3}>
                <Field
                  name="StbType__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                     createSearchList(generalPurposeMap.StbType,
                         'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={3}>
                <Field
                  name="KyogaType__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>

              <Grid key="key1-6" item xs={12} sm={3}>
                <Field
                  name="KoziTitle__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事件名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={3}>
                <Field
                  select
                  name="PayOffCostStatus"
                  className={classes.fields}
                  component={renderSelect}
                  label="改造工事完了登録ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                   createSearchList(generalPurposeMap.UmuCategory, 'Code__c', 'ValueString2__c', {Code__c: '1'}, 'false')}
                </Field>
              </Grid>

              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  id="id1-9"
                  name="KoziPlaceMainDntNo_SenroName__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="線路名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>

              <Grid key="key1-8" item xs={12} sm={3}>
                <Field
                  name="Dsp_KoziPlaceMainDntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="代表電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key2-1" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                   createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>

              <Grid key="key2-2" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key2-3" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key3-1" item xs={12} md={6} style={{position: 'relative'}}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="OrderDate__c_From"
                    accessibilitylabel="OrderDate__c_From"
                    label="申込年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="OrderDate__c_To"
                    accessibilitylabel="OrderDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key3-2" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="GaisanResultRegisterDate__c_From"
                    accessibilitylabel="GaisanResultRegisterDate__c_From"
                    label="改造工事完了登録日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="GaisanResultRegisterDate__c_To"
                    accessibilitylabel="GaisanResultRegisterDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key3-3" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>
          </PaperPart>

          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderList: PropTypes.func.isRequired,
  doSetOrderId: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderList: state.attachorder.orderList,
  // 表示時のデフォルト設定
  initialValues: {
    PayOffCostStatus: '1',
    OrderDate__c_From: getAddDate(),
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderList: attachorderOperations.doGetOrderList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderList: attachorderOperations.doClearOrderList,
};

const FORM_NAME = 'PayoffCostCalculationList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

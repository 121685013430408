import axios from 'axios';

import {
  getSenStbList,
  checkAttachOrderSenStb,
  saveSenStbs,
} from './actions';

import conf from '../../config/config.js';

/**
 * 線設備一覧 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {bool} createKeikanFlag 径間作成フラグ
 *                  true:申込ステータスが作成中の場合、申込電柱と径間マスタを使って径間を作成する
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetOrderSenStbList = (
    conditions, fields, sortParams, createKeikanFlag=false) => {
  console.log('線設備一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    body['createKeikanFlag'] = createKeikanFlag;

    const ret = await axios.post(conf.API_URL + 'SenStb/getOrderSenStbList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getSenStbList(ret.data.body.data ?
                                  ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 線設備一覧のクリア
 * @return {void} 処理結果
 */
const doClearSenStbList = () => {
  console.log('線設備一覧 クリア...');

  return async (dispatch) => {
    dispatch(getSenStbList(null));
  };
};

/**
 * 線設備の業務チェック
 * @param {string} path 業務チェックAPI
 * @param {Object} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doCheckAttachOrderSenStb = (path, conditions) => {
  console.log('線設備の業務チェック...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'SenStb/' + path, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = checkAttachOrderSenStb(ret.data.body.data ?
                                        ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 線設備の業務チェッククリア
 * @return {Promise<AxiosResponse<any>>} 結果
 */
const doClearCheckAttachOrderSenStb = () => {
  console.log('線設備の業務チェッククリア...');
  return async (dispatch) => {
    dispatch(checkAttachOrderSenStb(null));
  };
};

/**
 * 線設備 登録/更新/削除
 * @param {Object} updateConditions 線設備更新条件
 * @param {Object} deleteConditions 線設備削除条件
 * @param {Object} senStbList 線設備一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveSenStbs = (
    updateConditions, deleteConditions, senStbList,
    orderConditions, order) => {
  console.log('線設備 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        senStbList: senStbList,
        orderConditions: orderConditions,
        order: order,
        orderStage: 'UPDATE_ORDER_IN_SEN_STB',
        insertSenStbStage: 'INSERT_SEN_STB',
        updateSenStbStage: 'UPDATE_SEN_STB',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/saveSenStbs', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveSenStbs(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};


/**
 * 線設備一覧 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {bool} isRef true:本申込データを参照する
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetFormalSenStbList = (conditions, fields, sortParams, isRef=false) => {
  console.log('線設備一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    body['isRef'] = isRef;

    const ret = await axios.post(conf.API_URL + 'SenStb/getFormalSenStbList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getSenStbList(ret.data.body.data ?
                                  ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 撤去線設備一覧 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Object} addFields 追加設定するフィールド({フィールド名：値})
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetTekkyoSenStbList = (conditions, fields, sortParams) => {
  console.log('線設備一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'SenStb/getTekkyoSenStbList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getSenStbList(ret.data.body.data ?
                                  ret.data.body.data : null);
    dispatch(retData);
    return ret;
  };
};

/**
 * 本申込の線設備 登録/更新/削除
 * @param {Object} updateConditions 線設備更新条件
 * @param {Object} deleteConditions 線設備削除条件
 * @param {Object} senStbList 線設備一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @param {bool} isSyunkoApply 竣工届登録日を更新する場合, true, 左記以外, false
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveFormalSenStbs = (
    updateConditions, deleteConditions, senStbList,
    orderConditions, order, isSyunkoApply=false) => {
  console.log('線設備 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        senStbList: senStbList,
        orderConditions: orderConditions,
        order: order,
        isSyunkoApply: isSyunkoApply,
        orderStage: 'UPDATE_ORDER_IN_SEN_STB',
        insertSenStbStage: 'INSERT_FORMAL_SEN_STB',
        updateSenStbStage: 'UPDATE_FORMAL_SEN_STB',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiFormal/saveFormalSenStbs', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveSenStbs(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 線設備（撤去） 登録/更新/削除
 * @param {Object} updateConditions 線設備更新条件
 * @param {Object} deleteConditions 線設備削除条件
 * @param {Object} senStbList 線設備一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @param {bool} isSyunkoApply 竣工届登録日を更新する場合, true, 左記以外, false
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveSenStbsTekyo = (
    updateConditions, deleteConditions, senStbList,
    orderConditions, order, isSyunkoApply=false) => {
  console.log('線設備 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        senStbList: senStbList,
        orderConditions: orderConditions,
        order: order,
        isSyunkoApply: isSyunkoApply,
        orderStage: 'TEKYOORDER_APPLY_UPDATE',
        insertSenStbStage: 'INSERT_FORMAL_SEN_STB',
        updateSenStbStage: 'UPDATE_FORMAL_SEN_STB',
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiFormal/saveFormalSenStbs', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveSenStbs(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 訂正取付申込 線設備 登録/更新/削除
 * @param {Object} updateConditions 線設備更新条件
 * @param {Object} deleteConditions 線設備削除条件
 * @param {Object} senStbList 線設備一覧
 * @param {Object} orderConditions 申込更新条件
 * @param {Object} order 申込
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveCorrectOrderSenStbs = (
    updateConditions, deleteConditions, senStbList,
    orderConditions, order) => {
  console.log('訂正取付申込 線設備 登録/更新/削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        updateConditions: updateConditions,
        deleteConditions: deleteConditions,
        senStbList: senStbList,
        orderConditions: orderConditions,
        order: order,
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiCorrectOrder/saveCorrectOrderSenStbs', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(saveSenStbs(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

export default {
  doGetOrderSenStbList,
  doClearSenStbList,
  doCheckAttachOrderSenStb,
  doSaveSenStbs,
  doGetFormalSenStbList,
  doGetTekkyoSenStbList,
  doSaveFormalSenStbs,
  doSaveSenStbsTekyo,
  doClearCheckAttachOrderSenStb,
  doSaveCorrectOrderSenStbs,
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Menu from '../../components/templates/Menu';
import {authOperations} from '../../reducks/auth';
import {commonOperations} from '../../reducks/common';

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
};

/**
 * メニュー ページ。
 * 画面ID:1000/2000
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const initResult = await this.init();
    this.doJudge(initResult);
  }

  /**
   * 初期化処理。
   *
   * @return {Promise<boolean>} 処理成功の場合true、失敗の場合false
   */
  init = async () => {
    const {getUserInfo} = this.props;
    try {
      await getUserInfo();
      if (!this.props.hasOwnProperty('userInfo')) {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }

  /**
   * 初期化処理の結果判定。
   *
   * @param {boolean} initResult 初期化処理の結果
   */
  doJudge = (initResult) => {
    if (!initResult) {
      const {doShowMessage} = this.props;
      const dialog = {
        message: 'CS0001',
        action: this.doMoveLogin,
      };
      doShowMessage(dialog);
    }
  }

  /**
   * ログイン画面へ遷移。
   */
  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  /**
   * 画面描画。
   *
   * @return {JSX.Element} 描画要素
   */
  render() {
    return (<Menu
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  doShowMessage: PropTypes.func,
  getUserInfo: PropTypes.func,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

export default ContainerApp;

import types from './types';

// 契約電柱一覧 取得
export const getContractDntList = (contractDntList) => ({
  type: types.GET_CONTRACTDNT_LIST,
  contractDntList,
});

// 契約電柱件数 取得
export const getContractDntCount = (contractDntCount) => ({
  type: types.GET_CONTRACTDNT_COUNT,
  contractDntCount,
});

// 契約電柱 取得
export const retrieveContractDnt = (contractDnt) => ({
  type: types.RETRIEVE_CONTRACTDNT,
  contractDnt,
});

// 契約電柱 登録
export const registContractDnt = (contractDntResult) => ({
  type: types.REGIST_CONTRACTDNT,
  contractDntResult,
});

// 契約電柱 削除
export const deleteContractDnt = (contractDntResult) => ({
  type: types.DELETE_CONTRACTDNT,
  contractDntResult,
});

// 契約電柱ID 設定
export const setContractDntId = (contractDntId) => ({
  type: types.SET_CONTRACTDNT_ID,
  contractDntId,
});

// 契約電柱業務チェック
export const checkAttachOrderDnt = (checkAttachOrderDntResult) => ({
  type: types.CHECK_CONTRACTDNT,
  checkAttachOrderDntResult,
});

// 申込電柱 登録/更新/削除
export const saveContractDnts = (contractDntResult) => ({
  type: types.SAVE_CONTRACTDNT,
  contractDntResult,
});

// 契約電柱マスタ一覧 取得
export const getContractDntMasterList = (contractDntMasterList) => ({
  type: types.GET_CONTRACTDNT_MASTER_LIST,
  contractDntMasterList,
});

// 可否判定申込の申込情報と申込電柱情報 取得
export const getOrderAndContractDntList = (orderContractDntList) => ({
  type: types.GET_ORDER_CONTRACTDNT,
  orderContractDntList,
});

// 本申込の申込電柱情報 取得
export const getFormalContractDntList = (formalContractDntList) => ({
  type: types.GET_FORMAL_CONTRACTDNT,
  formalContractDntList,
});

export const getContractDntORFilter = (contractDntOrFilter) => ({
  type: types.GET_CONTRACTDNTDNT_OR_FILTER,
  contractDntOrFilter,
});

// 本申込の申込電柱 登録/削除
export const saveFormalContractDnts = (contractDntResult) => ({
  type: types.SAVE_CONTRACTDNT,
  contractDntResult,
});

// 本申込/撤去の一束化設定申込電柱 取得
export const getIskkDntList = (iskkContractDntList) => ({
  type: types.GET_ISKK_CONTRACTDNT,
  iskkContractDntList,
});

// 契約電柱一覧（検索画面用） 取得
export const getContractDntSearchList = (contractDntSearchList) => ({
  type: types.GET_CONTRACTDNT_SEARCH_LIST,
  contractDntSearchList,
});

// 本申込/撤去の一束化設定申込電柱 取得
export const saveIskkContractDnts = (contractDntResult) => ({
  type: types.SAVE_CONTRACTDNT,
  contractDntResult,
});

// 写真登録情報の設定
export const setPhotoRegistrationInfo = (photoRegistrationInfo) => ({
  type: types.SET_PHOTO_REGISTRATION_INFO,
  photoRegistrationInfo,
});

// 写真情報 取得
export const getPhotoInfo = (photoInfo) => ({
  type: types.GET_PHOTO_INFO,
  photoInfo,
});

// 竣工申込の申込電柱 登録/削除
export const saveSyunkoContractDnts = (contractDntResult) => ({
  type: types.SAVE_CONTRACTDNT,
  contractDntResult,
});

// NW事業所検索フィルター 取得
export const getNwzgsyoSearchfilter = (nwzgsyoSearchfilter) => ({
  type: types.GET_NWZGSYO_SEARCHFILTER,
  nwzgsyoSearchfilter,
});

// 線路一覧 設定
export const setSenroCodeSearchList = (senroCodeSearchList) => ({
  type: types.SET_SENRO_CODE_SEARCH_LIST,
  senroCodeSearchList,
});

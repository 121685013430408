import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import * as wijmo from '@grapecity/wijmo';
import {MultiRowCellGroup, MultiRowCell, MultiRowCellTemplate} from '@grapecity/wijmo.react.grid.multirow';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Popup, ComboBox, InputNumber} from '@grapecity/wijmo.react.input';

import {getCodeFromGeneralPurposeMap, getComboList,
  getComboName, getErrorCode, API_CODE,
  dntNoFormat, senroNameFormat, isSameSenStb, isSameContractDntInSenStb,
  getResponseResult,
  createDataMapByComboList,
} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {senStbOperations} from '../../../../../../reducks/senStb';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import {KyogaStb, ClosestDrStbSen} from '../../../../../molecules/HelpComponent.js';
import CommonTheme from '../../../../../../Theme.js';
import CustomMultiRow, {createCollectionView} from '../../../../../molecules/CustomMultiRow.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import {orderSenStbFields} from '../../../../../../common/SFFields';
import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import HelpIcon from '@material-ui/icons/Help';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SerialNumber__c: null, // 通し番号
  SzbtItiId1__c: null, // 支持物位置ID1
  SzbtItiId2__c: null, // 支持物位置ID2
  StbKobetuId1__c: null, // 設備個別ID1
  StbKobetuId2__c: null, // 設備個別ID2
  SenroCode1__c: null, // 線路コード1
  SenroCode2__c: null, // 線路コード2
  SenroName1__c: null, // 線路名1
  SenroName2__c: null, // 線路名2
  SenroNameKana1__c: null, // 線路名カナ1
  SenroNameKana2__c: null, // 線路名カナ2
  DntNo1__c: null, // 電柱番号1
  DntNo2__c: null, // 電柱番号2
  DntCategory1__c: null, // 電柱区分1
  DntCategory2__c: null, // 電柱区分2
  KyogaCableSnsyu__c: null, // 共架ケーブル線種
  KyogaCableGaikei__c: null, // 共架ケーブル外径(mm)
  ClosestDrStbName__c: null, // 最接近電力設備_名称
  ClosestDrStbRikakOrder__c: null, // 最接電力設備_離隔_申込
  KyogaCategory1__c: null, // 共架区分1
  KyogaCategory2__c: null, // 共架区分2
  Order__c: null, // 参照(可否判定申込)
  KyogaZgsya__c: null, // 参照(申込共架事業者)
  ContractDnt1__c: null, // 参照(契約電柱始点側)
  ContractDnt2__c: null, // 参照(契約電柱終点側)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  DntNo1ManualInput__c: null, // 電柱番号1_自由入力
  DntNo2ManualInput__c: null, // 電柱番号2_自由入力
  K6KansnNo1__c: null, // 6kV幹線No1
  K6KansnNo2__c: null, // 6kV幹線No2
  K6BunkOne1__c: null, // 6kV分岐1_1
  K6BunkOne2__c: null, // 6kV分岐1_2
  K6BunkTwo1__c: null, // 6kV分岐2_1
  K6BunkTwo2__c: null, // 6kV分岐2_2
  K6BunkThree1__c: null, // 6kV分岐3_1
  K6BunkThree2__c: null, // 6kV分岐3_2
  K22SzbtNo1__c: null, // 22kV支持物番号1
  K22SzbtNo2__c: null, // 22kV支持物番号2
  K22GatiCategory1__c: null, // 22kV架地区分
  K22GatiCategory2__c: null, // 22kV架地区分
  K6K22HeigaCategory1__c: null, // 6kV22kV併架区分
  K6K22HeigaCategory2__c: null, // 6kV22kV併架区分
};
const updateParams = {
  Id: null,
  SerialNumber__c: null, // 通し番号
  SzbtItiId1__c: null, // 支持物位置ID1
  SzbtItiId2__c: null, // 支持物位置ID2
  StbKobetuId1__c: null, // 設備個別ID1
  StbKobetuId2__c: null, // 設備個別ID2
  SenroCode1__c: null, // 線路コード1
  SenroCode2__c: null, // 線路コード2
  SenroName1__c: null, // 線路名1
  SenroName2__c: null, // 線路名2
  SenroNameKana1__c: null, // 線路名カナ1
  SenroNameKana2__c: null, // 線路名カナ2
  DntNo1__c: null, // 電柱番号1
  DntNo2__c: null, // 電柱番号2
  DntCategory1__c: null, // 電柱区分1
  DntCategory2__c: null, // 電柱区分2
  KyogaCableSnsyu__c: null, // 共架ケーブル線種
  KyogaCableGaikei__c: null, // 共架ケーブル外径(mm)
  ClosestDrStbName__c: null, // 最接近電力設備_名称
  ClosestDrStbRikakOrder__c: null, // 最接電力設備_離隔_申込
  KyogaCategory1__c: null, // 共架区分1
  KyogaCategory2__c: null, // 共架区分2
  ContractDnt1__c: null, // 参照(契約電柱始点側)
  ContractDnt2__c: null, // 参照(契約電柱終点側)
  UpsyaUserId__c: null,
  DntNo1ManualInput__c: null, // 電柱番号1_自由入力
  DntNo2ManualInput__c: null, // 電柱番号2_自由入力
  K6KansnNo1__c: null, // 6kV幹線No1
  K6KansnNo2__c: null, // 6kV幹線No2
  K6BunkOne1__c: null, // 6kV分岐1_1
  K6BunkOne2__c: null, // 6kV分岐1_2
  K6BunkTwo1__c: null, // 6kV分岐2_1
  K6BunkTwo2__c: null, // 6kV分岐2_2
  K6BunkThree1__c: null, // 6kV分岐3_1
  K6BunkThree2__c: null, // 6kV分岐3_2
  K22SzbtNo1__c: null, // 22kV支持物番号1
  K22SzbtNo2__c: null, // 22kV支持物番号2
  K22GatiCategory1__c: null, // 22kV架地区分
  K22GatiCategory2__c: null, // 22kV架地区分
  K6K22HeigaCategory1__c: null, // 6kV22kV併架区分
  K6K22HeigaCategory2__c: null, // 6kV22kV併架区分
};

/**
 * 可否判定申込（径間：線設備）
 * 画面ID:1003
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      modalOpen1: false,
      modalOpen2: false,
      SzbtItiId1: null,
      StbKobetuId1: null,
      DntNo1: null,
      DspSenroName1: null,
      DspDntNo1: null,
      editItem: null,
      warningMessages: [],
    };
    this.currentCtx = null;
    this.selectedDntList = [];
    this.popup = null;
    this.gridRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // 編集ダイアログ用
    this.popupItems = {
      KyogaCableSnsyu__c: null, // ケーブル線種
      KyogaCableGaikei__c: null, // ケーブル外径（mm）
      ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
      ClosestDrStbRikakOrder__c: null, // 最接近電力設備との離隔/離隔距離（m）
    };

    // フィルターを表示する列
    this.filterCols = ['SerialNumber__c', 'Dsp_SenroName1__c', 'SenroCode1__c', 'Dsp_DntNo1__c',
      'DntCategory1__c', 'Dsp_SenroName2__c', 'SenroCode2__c', 'Dsp_DntNo2__c', 'DntCategory2__c',
      'KyogaCableSnsyu__c', 'KyogaCableGaikei__c', 'ClosestDrStbName__c', 'ClosestDrStbRikakOrder__c'];

    // グリッド項目初期化
    this.items = {};
    for (const key of orderSenStbFields) {
      this.items[key] = '';
    }
    for (const key of ['SerialNumber__c']) {
      this.items[key] = 0;
    }
    this.items['KyogaCableGaikei__c'] = 0.0;
    this.items['ClosestDrStbRikakOrder__c'] = 0.00;

    // 一覧のヘッダーにヘルプボタン追加
    // 共架設備
    this.headerKyogaStbTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <KyogaStb/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 最接近電力設備との離隔
    this.headerClosestDrStbTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <ClosestDrStbSen/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      // 必須チェック
      // ケーブル線種,ケーブル外径（mm）,最接近電力設備との離隔/設備名称
      // 最接近電力設備との離隔/離隔距離（m）
      if (['KyogaCableSnsyu__c', 'KyogaCableGaikei__c', 'ClosestDrStbName__c',
        'ClosestDrStbRikakOrder__c'].includes(prop)) {
        // CE0017
        let error = validateRule.required4(
            item[prop]);
        if (error) {
          return error;
        }
      }
      // データ範囲チェック
      // ケーブル外径（mm）,径間長（m）
      if (['KyogaCableGaikei__c'].includes(prop)) {
        // CE0026
        if (item[prop] < 0.1 || item[prop] > 999.9) {
          return '0.1～999.9の範囲内で入力してください';
        }
      }
      // 最接近電力設備との離隔/離隔距離（m）
      if (['ClosestDrStbRikakOrder__c'].includes(prop)) {
        // CE0026
        if (item[prop] < 0.01 || item[prop] > 9.99) {
          return '0.01～9.99の範囲内で入力してください';
        }
      }
      // 相関チェック
      // let confirmFlg =
      // [order['CheckJiko1__c'], order['CheckJiko2__c'], order['CheckJiko3__c']];
      // this.props.order;
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const searchResult = await this.searchData(true);
      if (!searchResult) {
        return;
      }
      this.props.doSetTransitionSrcId('1003');
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearSenStbList();
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    // 申込IDに紐づく線設備を取得する
    // 可否判定申込の申込ステータスが作成中の場合、申込電柱から線設備を自動生成
    // 保存済の線設備と自動生成した線設備をマージして一覧表示
    const conditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'ProprietyJudgeOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    let createKeikan =
      this.props.referenceMode == true ? false : isInit;
    const response =
      await this.props.doGetOrderSenStbList(
          conditions, orderSenStbFields, appSortParams, createKeikan);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['線設備', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }
    const cv = createCollectionView(this.props.senStbList,
        true, this.items, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    if (isInit) {
      // 初期処理時に電柱選択に渡す選択済契約電柱一覧を作成
      const contractDntItems = {DntCategory__c: null, DntNo__c: null,
        DntNoManualInput__c: null, K22GatiCategory__c: null,
        K22SzbtNo__c: null, K6Bunk1__c: null,
        K6Bunk2__c: null, K6Bunk3__c: null,
        K6K22HeigaCategory__c: null, K6KansnNo__c: null,
        KyogaCategory__c: null, NWZgsyo__r_Code__c: null,
        SenroCode__c: null, SenroName__c: null,
        SenroNameKana__c: null, StbItiMunicipalities: null,
        StbItiPlace: null, StbItiPrefectures: null,
        StbKobetuId__c: null, SzbtItiId__c: null};
      for (const selectedContractDnt of this.props.selectedDntList) {
        let _items = {};
        Object.assign(_items, contractDntItems);
        for (const key in _items) {
          if (key == 'NWZgsyo__r_Code__c') {
            if (generalPurposeMap.NWZgsyo) {
              const masterData = generalPurposeMap.NWZgsyo.filter(
                  (data) => data.Id ==
                      selectedContractDnt.NWZgsyo__c);
              _items[key] = masterData.length > 0 ? masterData[0].Code__c : '';
            }
          } else {
            if (Object.hasOwnProperty.call(selectedContractDnt, key)) {
              _items[key] = selectedContractDnt[key];
            }
          }
        }
        this.selectedDntList.push(_items);
      }
    }

    // 入力チェック
    let isWarn = false;
    if (this.props.warningMessages.length > 0) {
      isWarn = true;
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                    orderStatusCreate ? false : true) ||
                    this.props.referenceMode, // 作成中のみ編集可
      isModified: this.props.isNewSenStb,
      warningMessages: this.props.warningMessages,
    });
    await this.props.doSetGmnWarning('1003', isWarn);

    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'Id') ?
        data.order.Id : null;
      lastModifiedDate =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
        data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  // 編集ダイアログ初期化
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ 共架設備/ケーブル線種
   * @param {*} ctl
   */
  initializeEditedKyogaCableSnsyu(ctl) {
    this.popupItems['KyogaCableSnsyu__c'] = ctl;
  }

  /**
   * 編集ダイアログ 共架設備/ケーブル外径（mm）
   * @param {*} ctl
   */
  initializeEditedKyogaCableGaikei(ctl) {
    this.popupItems['KyogaCableGaikei__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/設備名称
   * @param {*} ctl
   */
  initializeEditedClosestDrStbName(ctl) {
    this.popupItems['ClosestDrStbName__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/離隔距離(m)/申請時
   * @param {*} ctl
   */
  initializeEditedClosestDrStbRikakOrder(ctl) {
    this.popupItems['ClosestDrStbRikakOrder__c'] = ctl;
  }

  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
  editPopup(grid, e) {
    try {
      const ht = grid.hitTest(e);
      let index = 0;
      if (ht.row > 1) {
        index = ht.row % 2 == 0 ? ht.row / 2 : (ht.row - 1) / 2;
      }
      if (ht.panel === grid.rowHeaders) {
        let _editItem = grid.collectionView.sourceCollection[index];
        this.setState({editItem: _editItem});
        // ケーブル外径（mm）, 最接近電力設備との離隔/離隔距離（m）
        for (const key of ['KyogaCableGaikei__c', 'ClosestDrStbRikakOrder__c']) {
          if (Object.hasOwnProperty.call(_editItem, key)) {
            this.popupItems[key].value =
            _editItem[key] === null ? 0 : _editItem[key];
          }
        }
        // ケーブル線種, 最接近電力設備との離隔/設備名称
        for (const key of ['KyogaCableSnsyu__c', 'ClosestDrStbName__c']) {
          if (Object.hasOwnProperty.call(_editItem, key)) {
            this.popupItems[key].selectedValue = _editItem[key];
          }
        }

        this.popup.show(true, (s) => {
          if (s.dialogResult === 'wj-hide-ok') {
            (grid.collectionView).editItem(_editItem);
            // ケーブル外径（mm）, 最接近電力設備との離隔/離隔距離（m）
            for (const key of ['KyogaCableGaikei__c', 'ClosestDrStbRikakOrder__c']) {
              if (Object.hasOwnProperty.call(_editItem, key)) {
                _editItem[key] = this.popupItems[key].value;
              }
            }
            // ケーブル線種, 最接近電力設備との離隔/設備名称
            for (const key of ['KyogaCableSnsyu__c', 'ClosestDrStbName__c']) {
              if (Object.hasOwnProperty.call(_editItem, key)) {
                _editItem[key] = this.popupItems[key].selectedValue;
              }
            }
            (grid.collectionView).commitEdit();
          }
          grid.focus();
        });
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 確認ボタン押下時
   */
  doMoveConfirm = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
        this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }
      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1003')) {
        _hasError = this.props.setGmnWarning['1003'];
      }
    }

    // 入力チェックエラー
    if (_hasError) {
      const noKeikan =
            this.state.warningMessages.filter((data) => data.id == 'CE0019');
      if (noKeikan.length > 0) {
        // 径間0件は次画面に遷移しない
        this.props.doShowMessage({message: noKeikan});
        return;
      }

      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/ProprietyConfirm'});
  }

  /**
   * 径間の登録/更新処理
   */
  insertOrUpdate = async () => {
    const {order, orderModifiedCondition} = this.props;
    const senStbList = [];
    Object.assign(senStbList, this.gridRef.current.getSourceCollection());
    const saveSenStbListList = [];
    const updateConditions = [];
    let serialNumber = 0;
    // Dsp_DntNo1__c,Dsp_DntNo2__c, Dsp_SenroName1__c, Dsp_SenroName2__c
    // はSF属性が数式なので保存しない
    for (const senStb of senStbList) {
      // 一覧は1から通し番号設定
      serialNumber++;
      if (!senStb.Id) {
        // 登録項目設定
        const insertData = {};
        for (const key in addParams) {
          if (Object.hasOwnProperty.call(senStb, key)) {
            insertData[key] = senStb[key];
          }
        }
        insertData['SerialNumber__c'] = serialNumber;
        insertData['Order__c'] = order.Id; // 申込と契約電柱紐づけ
        insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
        // 共架事業者、契約電柱の紐づけはサーバー側で行う
        saveSenStbListList.push(insertData);
      } else {
        // 更新項目設定
        const updateData = {};
        for (const key in updateParams) {
          if (Object.hasOwnProperty.call(senStb, key)) {
            updateData[key] = senStb[key];
          }
        }
        updateData['SerialNumber__c'] = serialNumber;
        // 契約電柱の紐づけはサーバー側で行う
        saveSenStbListList.push(updateData);
        // 通し番号を変更したものも更新対象にする
        updateConditions.push({
          Id: senStb.Id,
          LastModifiedDate: senStb.LastModifiedDate,
        });
      }
    }
    // 削除
    const data = this.gridRef.current.itemsAllChanged();
    const deleteConditions = [];
    if (data.itemsRemoved.length > 0) {
      for (const row of data.itemsRemoved) {
        if (row.Id) {
          deleteConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }

    // 一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: orderModifiedCondition != null ?
          orderModifiedCondition.LastModifiedDate : null,
    };
    const updateOrder = {
      Id: order.Id,
    };

    const response = await this.props.doSaveSenStbs(
        updateConditions, deleteConditions, saveSenStbListList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(response, ['線設備', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/ProprietyList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  };

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり
   */
  doCheckModified = () => {
    let result = this.state.isModified;
    if (this.gridRef.current) {
      result = this.gridRef.current.isItemsModified() || this.props.isNewSenStb;
    }
    if (this.state.isModified != result) {
      this.setState({isModified: result});
    }
    return result;
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({
      modalOpen1: false,
      modalOpen2: false,
    });
  }

  /**
   * 選択した電柱情報を取得
   * 第一電柱に設定
   * @param {object} dnt
   */
  setSelectedDnt1 = async (dnt) => {
    try {
      const selectedDnt = dnt && dnt.length > 0 ? dnt[0] : null;
      if (selectedDnt) {
        // 第一電柱は選択電柱、第二電柱は既存
        const compObj1 = {
          SzbtItiId1__c: selectedDnt.SzbtItiId__c,
          StbKobetuId1__c: selectedDnt.StbKobetuId__c,
          SenroCode1__c: selectedDnt.SenroCode__c,
          SenroName1__c: selectedDnt.SenroName__c,
          SenroNameKana1__c: selectedDnt.SenroNameKana__c,
          DntNo1__c: selectedDnt.DntNo__c,
          DntCategory1__c: selectedDnt.DntCategory__c,
          KyogaCategory1__c: selectedDnt.KyogaCategory__c,
          DntNo1ManualInput__c: selectedDnt.DntNoManualInput__c,
          K6KansnNo1__c: selectedDnt.K6KansnNo__c,
          K6BunkOne1__c: selectedDnt.K6Bunk1__c,
          K6BunkTwo1__c: selectedDnt.K6Bunk2__c,
          K6BunkThree1__c: selectedDnt.K6Bunk3__c,
          K22SzbtNo1__c: selectedDnt.K22SzbtNo__c,
          K22GatiCategory1__c: selectedDnt.K22GatiCategory__c,
          K6K22HeigaCategory1__c: selectedDnt.K6K22HeigaCategory__c,
        };
        const compObj2 = {
          SzbtItiId2__c: this.currentCtx.item.SzbtItiId2__c,
          StbKobetuId2__c: this.currentCtx.item.StbKobetuId2__c,
          SenroCode2__c: this.currentCtx.item.SenroCode2__c,
          SenroName2__c: this.currentCtx.item.SenroName2__c,
          SenroNameKana2__c: this.currentCtx.item.SenroNameKana2__c,
          DntNo2__c: this.currentCtx.item.DntNo2__c,
          DntCategory2__c: this.currentCtx.item.DntCategory2__c,
          KyogaCategory2__c: this.currentCtx.item.KyogaCategory2__c,
          DntNo2ManualInput__c: this.currentCtx.item.DntNo2ManualInput__c,
          K6KansnNo2__c: this.currentCtx.item.K6KansnNo2__c,
          K6BunkOne2__c: this.currentCtx.item.K6BunkOne2__c,
          K6BunkTwo2__c: this.currentCtx.item.K6BunkTwo2__c,
          K6BunkThree2__c: this.currentCtx.item.K6BunkThree2__c,
          K22SzbtNo2__c: this.currentCtx.item.K22SzbtNo2__c,
          K22GatiCategory2__c: this.currentCtx.item.K22GatiCategory2__c,
          K6K22HeigaCategory2__c: this.currentCtx.item.K6K22HeigaCategory2__c,
        };
        // チェック対象データの第一電柱と第二電柱が同じ場合、エラー
        if (isSameContractDntInSenStb(compObj1, compObj2)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0034',
              values: ['選択した電柱'],
            },
          });
          return;
        }
        // 選択した電柱情報を第一電柱に設定することによって
        // 同じ径間になってしまう場合はエラー
        // 選択行はチェック対象から外す
        let compObj = {};
        Object.assign(compObj, compObj1);
        Object.assign(compObj, compObj2);
        let sourceCollection = this.gridRef.current.getSourceCollection();
        const serialNumber = this.currentCtx.item.SerialNumber__c;
        sourceCollection =
          sourceCollection.filter((data) =>
            data.SerialNumber__c != serialNumber);
        const result = sourceCollection.filter((data) =>
          isSameSenStb(data, compObj));
        if (result.length > 0) {
          this.props.doShowMessage({
            message: {
              id: 'CE0034',
              values: ['選択した径間'],
            },
          });
          return;
        }
        const editItem = this.currentCtx.item;
        (this.gridRef.current.getGrid().collectionView).editItem(editItem);
        for (const key of ['SzbtItiId', 'StbKobetuId',
          'DntCategory', 'DntNo', 'SenroCode',
          'SenroName', 'SenroNameKana', 'KyogaCategory',
          'K6KansnNo', 'K22SzbtNo', 'K22GatiCategory', 'K6K22HeigaCategory']) {
          if (Object.hasOwnProperty.call(selectedDnt, key + '__c')) {
            editItem[key + '1__c'] = selectedDnt[key + '__c'];
          }
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'DntNoManualInput__c')) {
          editItem['DntNo1ManualInput__c'] = selectedDnt['DntNoManualInput__c'];
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'K6Bunk1__c')) {
          editItem['K6BunkOne1__c'] = selectedDnt['K6Bunk1__c'];
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'K6Bunk2__c')) {
          editItem['K6BunkTwo1__c'] = selectedDnt['K6Bunk2__c'];
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'K6Bunk3__c')) {
          editItem['K6BunkThree1__c'] = selectedDnt['K6Bunk3__c'];
        }
        // 表示用電柱番号、表示用線路名を設定
        editItem['Dsp_DntNo1__c'] = dntNoFormat(
            selectedDnt['SenroCode__c'], selectedDnt['DntNoManualInput__c'],
            selectedDnt['K6KansnNo__c'], selectedDnt['K6Bunk1__c'],
            selectedDnt['K6Bunk2__c'], selectedDnt['K6Bunk3__c'],
            selectedDnt['K22SzbtNo__c'], selectedDnt['K22GatiCategory__c']);
        editItem['Dsp_SenroName1__c'] = senroNameFormat(selectedDnt['SenroName__c'], selectedDnt['SenroNameKana__c'], selectedDnt['SenroCode__c']);
        (this.gridRef.current.getGrid().collectionView).commitEdit();
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 選択した電柱情報を取得
   * 第二電柱に設定
   * @param {object} dnt
   */
  setSelectedDnt2 = async (dnt) => {
    try {
      const selectedDnt = dnt && dnt.length > 0 ? dnt[0] : null;
      if (selectedDnt) {
        // 第一電柱は既存、第二電柱は選択電柱
        const compObj1 = {
          SzbtItiId1__c: this.currentCtx.item.SzbtItiId1__c,
          StbKobetuId1__c: this.currentCtx.item.StbKobetuId1__c,
          SenroCode1__c: this.currentCtx.item.SenroCode1__c,
          SenroName1__c: this.currentCtx.item.SenroName1__c,
          SenroNameKana1__c: this.currentCtx.item.SenroNameKana1__c,
          DntNo1__c: this.currentCtx.item.DntNo1__c,
          DntCategory1__c: this.currentCtx.item.DntCategory1__c,
          KyogaCategory1__c: this.currentCtx.item.KyogaCategory1__c,
          DntNo1ManualInput__c: this.currentCtx.item.DntNo1ManualInput__c,
          K6KansnNo1__c: this.currentCtx.item.K6KansnNo1__c,
          K6BunkOne1__c: this.currentCtx.item.K6BunkOne1__c,
          K6BunkTwo1__c: this.currentCtx.item.K6BunkTwo1__c,
          K6BunkThree1__c: this.currentCtx.item.K6BunkThree1__c,
          K22SzbtNo1__c: this.currentCtx.item.K22SzbtNo1__c,
          K22GatiCategory1__c: this.currentCtx.item.K22GatiCategory1__c,
          K6K22HeigaCategory1__c: this.currentCtx.item.K6K22HeigaCategory1__c,
        };
        const compObj2 = {
          SzbtItiId2__c: selectedDnt.SzbtItiId__c,
          StbKobetuId2__c: selectedDnt.StbKobetuId__c,
          SenroCode2__c: selectedDnt.SenroCode__c,
          SenroName2__c: selectedDnt.SenroName__c,
          SenroNameKana2__c: selectedDnt.SenroNameKana__c,
          DntNo2__c: selectedDnt.DntNo__c,
          DntCategory2__c: selectedDnt.DntCategory__c,
          KyogaCategory2__c: selectedDnt.KyogaCategory__c,
          DntNo2ManualInput__c: selectedDnt.DntNoManualInput__c,
          K6KansnNo2__c: selectedDnt.K6KansnNo__c,
          K6BunkOne2__c: selectedDnt.K6Bunk1__c,
          K6BunkTwo2__c: selectedDnt.K6Bunk2__c,
          K6BunkThree2__c: selectedDnt.K6Bunk3__c,
          K22SzbtNo2__c: selectedDnt.K22SzbtNo__c,
          K22GatiCategory2__c: selectedDnt.K22GatiCategory__c,
          K6K22HeigaCategory2__c: selectedDnt.K6K22HeigaCategory__c,
        };
        // チェック対象データの第一電柱と第二電柱が同じ場合、エラー
        if (isSameContractDntInSenStb(compObj1, compObj2)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0034',
              values: ['選択した電柱'],
            },
          });
          return;
        }
        // 選択した電柱情報を第一電柱に設定することによって
        // 同じ径間になってしまう場合はエラー
        let compObj = {};
        Object.assign(compObj, compObj1);
        Object.assign(compObj, compObj2);
        let sourceCollection = this.gridRef.current.getSourceCollection();
        const serialNumber = this.currentCtx.item.SerialNumber__c;
        sourceCollection =
          sourceCollection.filter((data) =>
            data.SerialNumber__c != serialNumber);
        const result = sourceCollection.filter((data) =>
          isSameSenStb(data, compObj));
        if (result.length > 0) {
          this.props.doShowMessage({
            message: {
              id: 'CE0034',
              values: ['選択した径間'],
            },
          });
          return;
        }
        const editItem = this.currentCtx.item;
        (this.gridRef.current.getGrid().collectionView).editItem(editItem);
        for (const key of ['SzbtItiId', 'StbKobetuId',
          'DntCategory', 'DntNo', 'SenroCode',
          'SenroName', 'SenroNameKana', 'KyogaCategory',
          'K6KansnNo', 'K22SzbtNo', 'K22GatiCategory', 'K6K22HeigaCategory']) {
          if (Object.hasOwnProperty.call(selectedDnt, key + '__c')) {
            editItem[key + '2__c'] = selectedDnt[key + '__c'];
          }
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'DntNoManualInput__c')) {
          editItem['DntNo2ManualInput__c'] = selectedDnt['DntNoManualInput__c'];
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'K6Bunk1__c')) {
          editItem['K6BunkOne2__c'] = selectedDnt['K6Bunk1__c'];
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'K6Bunk2__c')) {
          editItem['K6BunkTwo2__c'] = selectedDnt['K6Bunk2__c'];
        }
        if (Object.hasOwnProperty.call(selectedDnt, 'K6Bunk3__c')) {
          editItem['K6BunkThree2__c'] = selectedDnt['K6Bunk3__c'];
        }
        // 表示用電柱番号、表示用線路名を設定
        editItem['Dsp_DntNo2__c'] = dntNoFormat(
            selectedDnt['SenroCode__c'], selectedDnt['DntNoManualInput__c'],
            selectedDnt['K6KansnNo__c'], selectedDnt['K6Bunk1__c'],
            selectedDnt['K6Bunk2__c'], selectedDnt['K6Bunk3__c'],
            selectedDnt['K22SzbtNo__c'], selectedDnt['K22GatiCategory__c']);
        editItem['Dsp_SenroName2__c'] = senroNameFormat(selectedDnt['SenroName__c'], selectedDnt['SenroNameKana__c'], selectedDnt['SenroCode__c']);
        (this.gridRef.current.getGrid().collectionView).commitEdit();
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    let edt = s.activeEditor;
    const currentColumn = edt.filter.column.binding;
    if (currentColumn === 'DntCategory1__c' ||
          currentColumn === 'DntCategory2__c' ||
          currentColumn === 'KyogaCableSnsyu__c'||
          currentColumn === 'ClosestDrStbName__c') {
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分（一）,電柱区分（二）
      if (currentColumn === 'KyogaCableSnsyu__c') {
        categoryName = 'CableSnsyu'; // ケーブル線種
      } else if (currentColumn === 'ClosestDrStbName__c') {
        categoryName = 'KiknClosestDrStbName'; // 最接近電力設備との離隔/設備名称
      }
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {classes, generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      filters: this.filterCols,
      headersVisibility: 'All',
      allowSorting: 'None',
      counterOn: false,
      AddDeleteOn: true,
      frozenColumns: 6,
      ArrowButtonOn: true,
      style: {height: '600px'},
      isReadOnly: this.state.isDisabled,
      useStore: false,
      validateEdits: false,
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      filterChanging: this.filterChangingHandler,
    };

    // コンボボックス: ケーブル線種
    const cableSnsyuList = getComboList(generalPurposeMap, 'CableSnsyu');
    const cableSnsyuMap = createDataMapByComboList(cableSnsyuList);

    // コンボボックス: 設備名称
    const stbNameList = getComboList(generalPurposeMap, 'KiknClosestDrStbName');
    const stbNameMap = createDataMapByComboList(stbNameList);

    return (
      <React.Fragment>
        <CustomMultiRow ref={this.gridRef} {...props}>
          <MultiRowCellGroup header="" align="center">
            <MultiRowCell binding='SerialNumber__c' header='通し番号' cssClass="uneditableCell" width={50} isReadOnly={true}/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="ケーブルルート" align="center" colspan={4}>
            <MultiRowCell binding='poleNoSelector1' header='電柱（一）' width={90}
              cellTemplate={CellMaker.makeButton({
                text: '選択',
                cssClass: this.state.isDisabled ? 'wj-state-disabled' : 'btn_navy',
                click: (e, ctx) => {
                  // 1301_電柱選択画面起動
                  this.currentCtx = ctx;
                  this.setState({modalOpen1: true});
                },
                attributes: {
                  tabindex: -1,
                },
              })}/>
            <MultiRowCell binding='Dsp_SenroName1__c' header='線路名（一）' dataType="String" cssClass="selectedDntInfo" width={125} isReadOnly={true}/>
            <MultiRowCell binding='Dsp_DntNo1__c' header='電柱番号（一）' dataType="String" cssClass="selectedDntInfo" isReadOnly={true} width={140} isRequired={true}/>
            <MultiRowCell binding='DntCategory1__c' header='電柱区分（一）' dataType="String" cssClass="selectedDntInfo" width={140} isReadOnly={true}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory1__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='poleNoSelector2' header='電柱（二）'
              cellTemplate={CellMaker.makeButton({
                text: '選択',
                cssClass: this.state.isDisabled ? 'wj-state-disabled' : 'btn_navy',
                click: (e, ctx) => {
                  // 1301_電柱選択画面起動
                  this.currentCtx = ctx;
                  console.dir(ctx.item);
                  this.setState({
                    SzbtItiId1: ctx.item.SzbtItiId1__c,
                    StbKobetuId1: ctx.item.StbKobetuId1__c,
                    DntNo1: ctx.item.DntNo1__c,
                    DspSenroName1: ctx.item.Dsp_SenroName1__c,
                    DspDntNo1: ctx.item.Dsp_DntNo1__c,
                    modalOpen2: true,
                  });
                },
                attributes: {
                  tabindex: -1,
                },
              })}/>
            <MultiRowCell binding='Dsp_SenroName2__c' header='線路名（二）' dataType="String" cssClass="selectedDntInfo" isReadOnly={true}/>
            <MultiRowCell binding='Dsp_DntNo2__c' header='電柱番号（二）' dataType="String" cssClass="selectedDntInfo" isReadOnly={true} isRequired={true}/>
            <MultiRowCell binding='DntCategory2__c' header='電柱区分（二）' dataType="String" cssClass="selectedDntInfo" isReadOnly={true}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory2__c);
              }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="共架設備" align="center" colspan={2}>
            <MultiRowCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerKyogaStbTemplate}/>
            <MultiRowCell binding='KyogaCableSnsyu__c' header='ケーブル線種' dataMap={cableSnsyuMap} width={175}>
              <MultiRowCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerDnsnIskkTemplate}/>
            </MultiRowCell>
            <MultiRowCell binding='KyogaCableGaikei__c' header='ケーブル外径（mm）' dataType="Number" format="n1" width={130}>
              <MultiRowCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="multiRow-cell-editor"
                    min={0}
                    max={999.9}
                    step={0.1}
                    format='n1'
                    value={ctx.value}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="最接近電力設備との離隔" align="center" colspan={2}>
            <MultiRowCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerClosestDrStbTemplate}/>
            <MultiRowCell binding='ClosestDrStbName__c' header='設備名称' dataMap={stbNameMap} width={235}/>
            <MultiRowCell binding='ClosestDrStbRikakOrder__c' header='離隔距離（m）' dataType="Number" format="n2" width={120}>
              <MultiRowCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="multiRow-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
        </CustomMultiRow>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
                線路名　（一）：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName1__c') ?
                        this.state.editItem.Dsp_SenroName1__c : ''}
              <br/>
                電柱番号（一）：{this.state.editItem &&
                                Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo1__c') ?
                                this.state.editItem.Dsp_DntNo1__c : ''}
              <br/>
               線路名　（二）：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName2__c') ?
                        this.state.editItem.Dsp_SenroName2__c : ''}
              <br/>
                電柱番号（二）：{this.state.editItem &&
                                Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo2__c') ?
                                this.state.editItem.Dsp_DntNo2__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                共架設備
            </div>
            <br/>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1003CableSnsyuPopup"
                initialized={this.initializeEditedKyogaCableSnsyu.bind(this)}
                itemsSource={cableSnsyuList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>ケーブル線種</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id='id1003KyogaCableGaikeiPopup'
                format='n1'
                min={0}
                max={999.9}
                step={0.1}
                initialized={this.initializeEditedKyogaCableGaikei.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>ケーブル外径（mm）</label>
            </div>
            <br/>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                最接近電力設備との離隔
            </div>
            <br/>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1003ClosestDrStbNamePopup"
                initialized={this.initializeEditedClosestDrStbName.bind(this)}
                itemsSource={stbNameList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>設備名称</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id='id1003ClosestDrStbRikakOrderPopup'
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedClosestDrStbRikakOrder.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>離隔距離（m）</label>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>
                  キャンセル</button>
            </div>
          </div>
        </Popup>
        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="kyogaStbHelpComponent">
            <KyogaStb/>
          </div>
          <div id="closestDrStbHelpComponent">
            <ClosestDrStbSen/>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1003Confirm" item>
          <PositiveButton
            id="btn1003Confirm"
            onClick={handleSubmit(this.doMoveConfirm)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode}
          >
            <span>確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1003Save" item>
          <PositiveButton
            id="btn1003Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1003Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onCheckGrid={this.doCheckModified}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer props={this.props}
          footerBtn={footerBtn}>
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid container
            direction="column"
            alignItems="flex-start">
            <Grid key="key1003Table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
        {/* 第一電柱用 */}
        <SelectPoleNo
          YbdsType="B"
          SelectedDntList={this.selectedDntList}
          modalOpen={this.state.modalOpen1}
          closeButton={this.closeSelectDnt}
          setSelectedPole={this.setSelectedDnt1}/>
        {/* 第二電柱用 */}
        <SelectPoleNo
          YbdsType="C"
          SzbtItiId={this.state.SzbtItiId1}
          StbKobetuId={this.state.StbKobetuId1}
          DntNo={this.state.DntNo1}
          DspSenroName={this.state.DspSenroName1}
          DspDntNo={this.state.DspDntNo1}
          SelectedDntList={this.selectedDntList}
          modalOpen={this.state.modalOpen2}
          closeButton={this.closeSelectDnt}
          setSelectedPole={this.setSelectedDnt2}/>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  senStbList: PropTypes.array,
  referenceMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderSenStbList: PropTypes.func,
  doSaveSenStbs: PropTypes.func,
  doClearSenStbList: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  selectedDntList: PropTypes.array,
  isNewSenStb: PropTypes.bool,
  warningMessages: PropTypes.array,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
  doSetTransitionSrcId: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {};
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    orderId: state.attachorder.orderId,
    order: state.senStb.senStbList &&
          Object.hasOwnProperty.call(state.senStb.senStbList, 'order') ?
              state.senStb.senStbList.order : null,
    senStbList: state.senStb.senStbList &&
                  Object.hasOwnProperty.call(state.senStb.senStbList, 'senStbList') ?
                  state.senStb.senStbList.senStbList : [],
    selectedDntList: state.senStb.senStbList &&
            Object.hasOwnProperty.call(state.senStb.senStbList, 'selectedDntList') ?
            state.senStb.senStbList.selectedDntList : [],
    isNewSenStb: state.senStb.senStbList &&
            Object.hasOwnProperty.call(state.senStb.senStbList, 'isNewSenStb') ?
            state.senStb.senStbList.isNewSenStb : false,
    warningMessages: state.senStb.senStbList &&
            Object.hasOwnProperty.call(state.senStb.senStbList, 'warningMessages') ?
            state.senStb.senStbList.warningMessages : [],
    referenceMode: state.common.referenceMode == true ? true : false,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderSenStbList: senStbOperations.doGetOrderSenStbList,
  doSaveSenStbs: senStbOperations.doSaveSenStbs,
  doClearSenStbList: senStbOperations.doClearSenStbList,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
  doSetTransitionSrcId: commonOperations.doSetTransitionSrcId,
};

const FORM_NAME = 'ProprietySenStb';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import {combineReducers} from 'redux';
import types from './types';

// 請求書明細一覧
const billinglDocumentDetail = (state = null, action) => {
  switch (action.type) {
    case types.GET_BILLINGLDOCUMENTDETAIL_LIST:
      return action.billinglDocumentDetail;
    default:
      return state;
  }
};

const reducer = combineReducers({
  billinglDocumentDetail,
});

export default reducer;

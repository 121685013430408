import jaForApp from './../../translations/ja';
// import enForApp from './../../translations/en';

import {combineReducers} from 'redux';
import types from './types';
/* State shape
state: {
  todos: [],
  visibilityFilter: SHOW_ALL
}
*/

// this method can be confusing because it serves two purposes:
// 1 - it create a new todo
// 2 - it toggles the completed state of an existing todo

const theme = (state = 'default', action) => {
  switch (action.type) {
    case types.CHANGE_THEME:
      return action.theme || state;
    default:
      return state;
  }
};


const fontStyle = (state = 'FontM', action) => {
  switch (action.type) {
    case types.CHANGE_COLOR:
      return action.fontStyle || state;
    default:
      return state;
  }
};

const isRequesting = (state = false, action) => {
  switch (action.type) {
    case types.START_REQUEST:
      return true;
    case types.END_REQUEST:
      return false;
    default:
      return state;
  }
};


const locale = (state = 'ja', action) => {
  return 'ja';
  /*
    switch (action.type) {
      case types.CHANGE_THEME:
        return (action.theme === 'default') ? 'ja' : 'en';
      default:
        return state;
    }
  */
};

const messages = (state = jaForApp, action) => {
  return jaForApp;
  /*
    switch (action.type) {
      case types.CHANGE_THEME:
        return (action.theme === 'default') ? jaForApp : enForApp;
      default:
        return state;
    }
  */
};

const reducer = combineReducers({
  theme,
  fontStyle,
  isRequesting,
  locale,
  messages,
});

export default reducer;

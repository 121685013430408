// operations
// 1つ以上の action を組み合わせたもの。
// 個別の action は単純なままに、必要に応じて複雑な operations を作成できる。
// redux-thunkなどのミドルウェアはここで使う。
import {push} from 'connected-react-router';
import {changeTheme, doLogin, doLogout, getUserInfo, getDaikoUserList} from './actions';
import axios from 'axios';
import {commonOperations} from '../common';

// API_URL取得
import conf from './../../config/config.js';

import {Amplify, Auth} from 'aws-amplify'; // Amplify
import AWS from 'aws-sdk'; // AWS-SDK
// import {getToDate} from '../../components/common';

Amplify.configure(conf.amplify_conf);
AWS.config.region = conf.region;

// storeリセット
const doClear = (info) => {
  return (dispatch) => {
    dispatch(doLogout());
  };
};


// ログイン
const doLoginOperation = (fm) => {
  return async (dispatch, getState) => {
    const {form} = getState();
    let values;
    if (fm === 'redirect') {
      values = form.Redirect.values;

      console.log(values.username, values.password);
    } else {
      values = form.Login.values;
    }

    // 入力値が取得できない場合は入力エラー
    if (!values || !values.username || !values.password) {
      console.error('Get Values Error.');
      return 'NO_LOGIN';
    }

    const userName = values.username ? values.username.trim() : null;
    const password = values.password ? values.password.trim() : null;

    // トリム後のユーザID/パスワードが空の場合はエラー
    if (!userName || !password) {
      console.error('ID/Password Empty Error.');
      return 'NO_LOGIN';
    }

    try {
      console.log('ログイン情報取得');
      dispatch(commonOperations.startRequest());
      const retLogin = await Auth.signIn(userName, password);
      dispatch(commonOperations.endRequest());

      console.log('retLogin:', retLogin);

      const attributes = retLogin.attributes;

      const userId = attributes ? attributes['custom:userId'] : null;
      console.log('userId:', userId);

      const sub = attributes ? attributes.sub : null;

      const session = retLogin.signInUserSession;
      const idToken = session ? session.idToken.jwtToken : null;

      const loginInfo = {
        isLoggedIn: true,
        idToken: idToken,
        cognitoUser: sub,
        userId: userId,
      };

      dispatch(doLogin(loginInfo));
      if (retLogin.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // 仮パスワードログイン時はAuth.signInの結果を保持
        loginInfo['signInUser'] = retLogin;
        return 'REDIRECT_NEW_PASSWORD';
      } else if (userId === undefined || userId ==='') {
        return 'NO_SF_USER';
      } else {
        return 'OK';
      }
    } catch (err) {
      console.log('signIn err:', err);
      dispatch(commonOperations.endRequest());
      if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        return 'NO_LOGIN';
      } else if (err.code === 'PasswordResetRequiredException') {
        return 'RESET_REQUIRE';
      } else {
        return 'SYS_ERROR';
      }
    }
  };
};

const sendAuthenticationCodeOperation = (username) => {
  console.log('パスワードリセット(認証コード送付)');

  return async (dispatch) => {
    try {
      dispatch(commonOperations.startRequest());

      const result = await Auth.forgotPassword(username);
      console.dir(result);
      return 'OK';
    } catch (err) {
      console.log('forgotPassword err:', err);

      if (err.code === 'UserNotFoundException') {
        // ユーザIDが誤っていた場合は成功扱い
        return 'OK';
      } else if (err.code === 'NotAuthorizedException') {
        // 未認証のユーザの場合は成功扱い
        return 'OK';
      } else if (err.code === 'LimitExceededException') {
        // リセット回数過多
        return 'LIMIT_EXCEED';
      } else {
        return 'SYS_ERROR';
      }
    } finally {
      dispatch(commonOperations.endRequest());
    }
  };
};

// パスワードリセット(パスワード更新)
const doPasswordResetOperation = (userName, code, password) => {
  console.log('パスワードリセット(パスワード更新)');
  return async (dispatch) => {
    try {
      dispatch(commonOperations.startRequest());

      await Auth.forgotPasswordSubmit(userName, code, password);
      return 'OK';
    } catch (err) {
      console.error(err);

      if (err.code === 'CodeMismatchException') {
        return 'CODE_ERROR';
      } else if (err.code === 'ExpiredCodeException') {
        return 'CODE_EXPIRED';
      } else {
        return 'SYS_ERROR';
      }
    } finally {
      dispatch(commonOperations.endRequest());
    }
  };
};

/**
 * 仮パスワードでログイン時のパスワード変更
 * @param {Auth.CognitoUser} user
 * @param {string} password 新しいパスワード
 * @return {function}
 */
const doCompletePassword = (user, password) => {
  return async (dispatch) => {
    try {
      dispatch(commonOperations.startRequest());
      await Auth.completeNewPassword(user, password);
      return 'OK';
    } catch (err) {
      console.error(err);
      if (err.code) {
        return err.code;
      }

      return 'SYS_ERROR';
    } finally {
      dispatch(commonOperations.endRequest());
    }
  };
};

/**
 * パスワードの変更
 * @param {string} oldPassword 現在のパスワード
 * @param {string} newPassword 新しいパスワード
 * @return {function}
 */
const doChangePassword = (oldPassword, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch(commonOperations.startRequest());
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      return 'OK';
    } catch (err) {
      console.error(err);
      if (err.code) {
        return err.code;
      }

      return 'SYS_ERROR';
    } finally {
      dispatch(commonOperations.endRequest());
    }
  };
};

// ログアウト（storeリセット）
const doLogoutOperation = (info) => {
  // logout api 呼び出し
  // ログイン画面
  return (dispatch) => {
    dispatch(doLogout());
    // Amplify
    console.log('###signOut');
    Auth.signOut()
        .then((data) => console.log('data:', data))
        .catch((err) => console.log('err:', err));
    dispatch(push('/login'));
  };
};

// サインアウト(ログイン画面遷移なし)
const doSignOutOperation = () => {
  console.log('サインアウト...');

  return async (dispatch) => {
    dispatch(doLogout());
    try {
      await Auth.signOut();
    } catch (err) {
      console.error(err);
    }
  };
};

// 利用者登録
const doUserRegistOperation = (userInfo) => {
  console.log('Cognito登録...');
  return async (dispatch) => {
    try {
      const result = await axios.post(conf.API_URL + 'Cognito/put', userInfo);

      // 登録成功時は終了
      if (result.data.body.errorCode === '00000') {
        return 'OK';
      }

      // 結果をチェック
      // 失敗時はdataにエラー情報が設定されているため取得
      const errorDetail = result.data.body.data;
      console.error(errorDetail);

      switch (errorDetail.code) {
        // 既に登録済のID
        case 'UsernameExistsException':
          return 'USER_EXIST';

        // リセット回数超過
        case 'LimitExceededException':
          return 'LIMIT_EXCEED';

        // メール送信エラー
        case 'CodeDeliveryFailureException':
          return 'MAIL_ERROR';

        // その他のエラー
        default:
          return 'SYS_ERROR';
      }
    } catch (error) {
      console.error(error);
      return 'SYS_ERROR';
    }
  };
};

// メールアドレス変更(認証)
const mailUpdateVerifyOperation = () => {
  console.log('メールアドレス変更(認証)');
  return async (dispatch, getState) => {
    const {form, auth} = getState();
    console.log('認証コード:', form.MailUpdateVerify.values.authenticationCode);
    const params = {
      Mail__c: form.MailUpdate.values.email,
      AuthCode: form.MailUpdateVerify.values.authenticationCode,
    };
    const retAPI = await axios.post(conf.API_URL + 'MailAddress/verifyMailAddress', params, {
      headers: {
        Authorization: auth.loginInfo.idToken,
      },
    });
    console.log('retAPI:', retAPI);
    if (retAPI.data.body.errorCode == '00000') {
      return 'OK';
    } else if (retAPI.data.body.errorCode == '21800') {
      return 'USER_EXIST';
    } else if (retAPI.data.body.errorCode == '21801') {
      return 'CODE_ERROR';
    } else if (retAPI.data.body.errorCode == '21802') {
      return 'CODE_EXPIRED';
    } else if (retAPI.data.body.errorCode == '21803') {
      return 'UPDATE_ERROR';
    } else {
      return 'SYS_ERROR';
    }
  };
};

/**
 * ユーザー情報取得。
 * @param {boolean} force 強制取得フラグ
 * @return {string} 取得結果。成功の場合'OK'、失敗の場合エラーコード
 */
const getUserInfoOperation = (force = false) => {
  return async (dispatch, getState) => {
    console.log('ユーザー情報取得');

    const {auth} = getState();

    // すでに取得済の場合は処理終了
    if (!force && auth.userInfo) {
      console.log('データ取得済のため終了');
      return 'OK';
    }


    const retUser = await axios.post(conf.API_URL + 'Contact/getCurrent', {}, {
      headers: {
        Authorization: auth.loginInfo.idToken,
      },
    });

    const body = retUser.data.body;

    // 取得に失敗した場合はエラーコードを返却
    if (body.errorCode !== '00000') {
      return body.errorCode;
    }

    const userInfo = body.data;

    // カナ文字を半角から全角に変換
    // const userInfo = retUser.data.body;
    // userInfo.data.OfficeNameKana__c = userInfo.data.OfficeNameKana__c ? convert_katakana(userInfo.data.OfficeNameKana__c, 0) : "";
    // userInfo.data.ApplicantFamilyNameKana__c = userInfo.data.ApplicantFamilyNameKana__c ? convert_katakana(userInfo.data.ApplicantFamilyNameKana__c, 0) : "";
    // userInfo.data.ApplicantFirstNameKana__c = userInfo.data.ApplicantFirstNameKana__c ? convert_katakana(userInfo.data.ApplicantFirstNameKana__c, 0) : "";

    /**
     * userInfo抜粋
     * {
     *  Id: "xxx", // ContactのId
     *  AccountId: "xxx", // AccountのId
     *  Name: "xxx", // ログインユーザー名
     *  KyogaZgsyaCode__c: "xxx", // ログインユーザーが所属する共架事業者のコード
     *  KyogaZgsyoCode__c: "xxx", // ログインユーザーが所属する共架事業所のコード
     *  UserId__c: "xxx", // ログインユーザーID
     *  MainteKoziCompany__c: "xxx", // ログインユーザーが保守工事会社に所属する場合、AccountのIdが入る。
     *                               // userInfo.AccountIdとは別物。
     *  MainteKoziCompanyCode__c: "xxx", // MainteKoziCompanyCode__cに値が入っていればユーザーが保守工事会社所属とみなす？
     *  UserLevel__c: "1", // 管理者か一般ユーザーか判別する項目
     *  Account { // AccountIdに紐づくAccountデータ
     *   Id: "xxx", // AccountIdと同じ
     *   Name: "xxx", // 会社名
     *   KyogaType__c: "01;02", // 申込共架事業者が使用可能な共架種別。画面の共架種別コンボ等に使用する。
     *   BuyHoldCustomerLevel__c: "xxx", // 買取保留レベル
     *  }
     *  Account.Account__r { // 共架事業所に紐づく共架事業者データ
     *   KyogaType__c: "01;02", // 申込共架事業者が使用可能な共架種別。画面の共架種別コンボ等に使用する。
     *  }
     * }
     */

    // userInfo.Account.Account__r.KyogaType__cを配列(例:"01;02"→['01','02'])にする
    commonOperations.setUserKyogaType(userInfo);

    dispatch(getUserInfo(userInfo));
    return 'OK';
  };
};

/**
 * ユーザ情報更新。
 * @param {object} conditions 検索条件
 * @param {string} conditions.Id 担当者情報のレコードID
 * @param {string} conditions.userId Cognitoのユーザ名
 * @param {object} contact 更新内容
 * @param {string} [email] メールアドレス。変更がある場合のみ指定
 * @return {finction}
 */
const doUserUpdateOperation = (conditions, contact, email) => {
  return async () => {
    let errorCode = '00000';

    const hasUpdateItem = Object.keys(contact).length > 0;

    // 変更された値がある場合は更新処理実行
    if (hasUpdateItem) {
      try {
        console.log('ユーザー情報更新');

        const param = {conditions, contact};
        const result = await axios.post(conf.API_URL + 'Cognito/update', param);
        errorCode = result.data.body.errorCode;
      } catch (err) {
        console.error(err);
        return 'ERROR';
      }
    }

    if (errorCode === '00000') {
      // メールアドレスが指定されている場合はメールアドレス変更APIを実行
      if (email) {
        console.log('メールアドレス変更');

        const param = {
          Id: conditions.Id,
          userId: conditions.userId,
          email: email,
        };

        try {
          const result = await axios.post(conf.API_URL + 'Cognito/changeEmail', param);
          if (result.data.body.errorCode === '00000') {
            return 'MAIL_OK';
          } else {
            return 'MAIL_ERROR';
          }
        } catch (err) {
          console.error(err);
          return 'ERROR';
        }
      } else {
        return 'OK';
      }
    } else {
      return 'ERROR';
    }
  };
};

// const doUserUpdateOperation = () => {
//   return async (dispatch, getState) => {
//     const {form, auth} = getState();
//     const values = form.UserUpdate.values;

//     const params = {
//       OfficeName__c: trim(values.companynameKanji),
//       OfficeNameKana__c: convertKatakana(trim(values.companyname), 1),
//       ApplicantFamilyName__c: trim(values.usernameFamily),
//       ApplicantFirstName__c: trim(values.usernameFirst),
//       ApplicantFamilyNameKana__c:
//        convertKatakana(trim(values.usernameFamilyKana), 1),
//       ApplicantFirstNameKana__c:
//        convertKatakana(trim(values.usernameFirstKana), 1),
//       OfficePostalCode__c: values.zipCode,
//       OfficeAddress__c: trim(values.companyaddress),
//       LastModifiedDate: auth.userInfo.LastModifiedDate,
//     };

//     // 任意項目を設定
//     if (values.mobile.length > 0) {
//       params.Cellphone__c = values.mobile;  // 携帯電話番号
//     } else {
//       params.Cellphone__c = ' ';
//     }
//     if (values.telephone.length > 0) {
//       params.Tel__c = values.telephone;     // 固定電話番号
//     } else {
//       params.Tel__c = ' ';
//     }

//     console.log(params);

//     const ret = await axios.post(conf.API_URL + 'CustomerInformation/updateUser', params, {
//       headers: {Authorization: auth.loginInfo.idToken},
//     });

//     if (values.changePassword) {
//       // パスワード変更ありの場合
//       if (ret.data.body.errorCode === '00000') {
//         // パスワード変更処理
//         try {
//           dispatch(commonOperations.startRequest());
//           console.log('#####currentAuthenticatedUser');
//           const user = await Auth.currentAuthenticatedUser();
//           console.log('currentAuthenticatedUser:', user);
//           console.log('#####changePassword');
//           const retChangePassword = await Auth.changePassword(
//               user, form.UserUpdate.values.oldPassword,
//               form.UserUpdate.values.password);
//           console.log('retChangePassword:', retChangePassword);
//           dispatch(commonOperations.endRequest());
//           return '00000';
//         } catch (err) {
//           console.log('retChangePassword err:', err);
//           dispatch(commonOperations.endRequest());
//           return '80005';
//         }
//       } else {
//         // APIのエラーをそのまま返却
//         return ret.data.body.errorCode;
//       }
//     } else {
//       return ret.data.body.errorCode;
//     }
//   };
// };

/**
 * 代行ユーザ 取得。
 * @param {object} conditions 検索条件
 * @return {function}
 */
const doGetDaikoUserList = (conditions) => {
  console.log('代行ユーザ 取得...');

  return async (dispatch) => {
    const body = {
      conditions: conditions,
    };

    const ret = await axios.post(conf.API_URL + 'Daiko/getUser', body);
    dispatch(getDaikoUserList(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

// トークン更新
const doRefreshTokenOperation = () => {
  return async (dispatch, getState) => {
    const {auth} = getState();
    const loginInfo = auth.loginInfo;
    if (loginInfo) {
      try {
        console.log('トークン更新');
        const currentSession = await Auth.currentSession();
        const idToken = currentSession.getIdToken().getJwtToken();
        // IDトークン更新
        loginInfo.idToken = idToken;
        dispatch(doLogin(loginInfo));
      } catch (err) {
        console.log(err);
      }
    }
  };
};

export default {
  changeTheme,
  doLoginOperation,
  sendAuthenticationCodeOperation,
  doPasswordResetOperation,
  doCompletePassword,
  doChangePassword,
  doLogoutOperation,
  doSignOutOperation,
  doUserRegistOperation,
  getUserInfoOperation,
  doUserUpdateOperation,
  mailUpdateVerifyOperation,
  doRefreshTokenOperation,
  doClear,
  doGetDaikoUserList,
};

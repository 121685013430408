import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {changeDateFormat, getErrorCode,
  getCodeFromGeneralPurposeMap, API_CODE,
  getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';

import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';

import {createSearchList} from '../../../../../../common/ComboParts.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';

import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '200px',
  },
});

// 竣工届申込対象一覧(改修依頼)で使用する申込ステータスコード
// 竣工待ち／工事開始可,竣工確認中,完結
const repairIraiSyunkoReportOrderStatus = ['40', '41', '90'];

// 申込テーブル検索時のソート条件
const appSortParams = {
  StartNotificationDate__c: -1, // 開始通知日
  OrderNo__c: -1, // 申込番号
};

// 申込テーブル検索時の検索対象のフィールド名
const appFields = [
  'OrderNo__c', // 申込番号
  'toLabel(StbType__c)StbTypeName', // 設備種別の名称
  'toLabel(KyogaType__c)KyogaTypeName', // 共架種別の名称
  'RepairIraiDate__c', // 改修依頼日
  'StartNotificationDate__c', // 開始通知日
  'KoziTitle__c', // 工事件名
  'KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'KoziPlaceMainDntNo_SenroName__c', // 工事場所_線路名
  'Dsp_KoziPlaceMainDntNo__c', // 工事場所_代表電柱番号
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName', // 工事場所_都道府県の名称
  'KoziPlaceMunicipalities__c', // 工事場所_市区町村
  'KoziPlacePlace__c', // 場所
  'toLabel(OrderStatus__c)OrderStatusName', // 申込ステータスの名称
  'TemporarilySavedDate__c', // 一時保存日
  // 画面表示以外
  'Id', // 申込のId
  'KyogaType__c', // 共架種別
  'StbType__c', // 設備種別
  'KoziPlacePrefectures__c', // 工事場所_都道府県
  'OrderStatus__c', // 申込ステータス
];

/**
 * 竣工届申込対象一覧(改修依頼)
 * 画面ID:1486
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.props.reset();
      this.doClearList();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    // FrexGridに初期値設定
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1486', []);
    }
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('orderList') &&
      this.props.orderList ? this.props.orderList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * 検索ボタン押下時処理
   */
  doSearch = async () => {
    try {
      const {values} = this.props;
      // 検索条件生成
      const appConditions = this.createConditions(values);
      // 申込テーブルからデータ取得
      const result =
      await this.props.doGetOrderList(
          appConditions, appFields, appSortParams, '');
      const errCode = getErrorCode(result);
      if (errCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }
      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1486', 'Id', true);
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
          !this.props.orderList || this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }
      // FrexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1486', 'Id', true);
        this.gridRef.current.setInitItems('1486', this.props.orderList);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索条件生成
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
   */
  createConditions = (values) => {
    const {generalPurposeMap} = this.props;
    const conditions = {};

    // レコードタイプが改修依頼
    conditions['RecordType.DeveloperName'] = 'RepairIrai';

    // 申込区分が改修依頼
    conditions['OrderCategory__c'] = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory05', 2);

    // 工事予定日
    conditions['KyogaKoziScheduleDate__c_NE'] = null;

    // 入力されている条件を設定
    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }
      if (values[key]) {
        conditions[key] = values[key];
      }
    }

    // 申込ステータスがなければ追加
    if (!Object.hasOwnProperty.call(conditions, 'OrderStatus__c')) {
      conditions['OrderStatus__c'] = repairIraiSyunkoReportOrderStatus;
    }

    return conditions;
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let isDisabled = true;
      if (this.gridRef && this.gridRef.current &&
          this.gridRef.current.selectedItemCount() > 0) {
        isDisabled = false;
      }
      this.setState({buttonDisabled: isDisabled});
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    this.props.doClearOrderList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1486', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 竣工届申込ボタン押下時
   */
  doSyunkoReportOrder = () => {
    try {
      const count = this.gridRef.current.selectedItemCount();
      if (count == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['竣工届申込'],
          },
        });
        return;
      }

      let equipStr = '線設備';
      const selectedItems = this.gridRef.current.selectedItem();
      if (selectedItems && selectedItems.length > 0) {
        equipStr = selectedItems[0].dataItem.StbTypeName;
      }
      this.props.doSetOrderId(selectedItems[0].dataItem.Id);
      if (equipStr == '点設備') {
        this.props.history.push({pathname: '/RepairIraiSyunkoReportTenOrderDnt', equip: equipStr});
      } else {
        this.props.history.push({pathname: '/RepairIraiSyunkoReportSenOrderDnt', equip: equipStr});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧表示
   * @return {object}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true, // 列フィルター有効
      headersVisibility: 'All', // 行ヘッダ、列ヘッダの表示設定
      allowDragging: 'Columns', // 行、列のD&D設定
      counterOn: true, // グリッドに設定したデータ件数表示有無を設定
      AddDeleteOn: false,
      style: {maxHeight: '300px'}, // グリッドのスタイル設定
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="OrderNo__c" header="改修依頼番号" dataType="String" width={150}/>
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType="String" width={100}/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType="String"/>
        <FlexGridColumnGroup binding="RepairIraiDate__c" header="改修依頼日" dataType="Date" width={110}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.RepairIraiDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="StartNotificationDate__c" header='着工可能\n通知日' dataType="Date" width={110}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.StartNotificationDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType="String"/>
        <FlexGridColumnGroup header="工事場所" align="center">
          <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
          <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePrefecturesName" header="都道府県" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String"/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType="String" width={150}/>
        <FlexGridColumnGroup binding="TemporarilySavedDate__c" header='一時保存日' dataType="Date" width={110}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.TemporarilySavedDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            id="btnNew"
            onClick={handleSubmit(this.doSyunkoReportOrder)}
            variant="contained"
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>竣工届申込</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn5" item>
          <BackButton props={this.props}/>
        </Grid>

      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>

        <MainContainer props={this.props} footerBtn={footerBtn}>
          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid key="key1" item xs={12} sm={2}>
              <Field
                id="repairIraiNo"
                name="OrderNo__c"
                component={renderTextField}
                className={classes.fields}
                label="改修依頼番号"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.alphaNumericSymbol,
                  validateRule.maxLength(12),
                ]}
              />
            </Grid>

            <Grid key="key5" item xs={12} sm={2}>
              <Field
                id="equipSbt"
                name="StbType__c"
                component={renderSelect}
                className={classes.fields}
                label="設備種別"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                  createSearchList(generalPurposeMap.StbType,
                      'Code__c', 'Name', {Code__c: ['1', '2']})}
              </Field>
            </Grid>

            <Grid key="key4" item xs={12} sm={2}>
              <Field
                id="kyogaSbt"
                name="KyogaType__c"
                component={renderSelect}
                className={classes.fields}
                label="共架種別"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                     createSearchList(generalPurposeMap.KyogaType,
                         'Code__c',
                         'Name',
                         {Code__c: userInfo.Account.Account__r.KyogaType__c,
                           ValueString1__c:
                           Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                             [this.props.values.StbType__c] : ['1', '2']})}
              </Field>
            </Grid>

          </Grid>

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid key="key6" item xs={12} sm={4}>
              <Field
                id="constructionsubject"
                name="KoziTitle__c"
                component={renderTextField}
                className={classes.fields}
                label="工事件名"
                type="text"
                fullWidth
                required={false}
                validate={[
                  validateRule.maxLength(50),
                ]}
              />
            </Grid>

            <Grid key="key8" item xs={12} sm={2}>
              <Field
                id="applicationstatus"
                name="OrderStatus__c"
                component={renderSelect}
                className={classes.fields}
                label="申込ステータス"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                   createSearchList(generalPurposeMap.OrderStatus,
                       'Code__c', 'Name', {Code__c: repairIraiSyunkoReportOrderStatus})}
              </Field>
            </Grid>


          </Grid>

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid key="key111" item xs={12} sm={2}>
              <Field
                id="representativeutilitysenrono"
                name="KoziPlaceMainDntNo_SenroName__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／線路名"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.maxLength(30),
                ]}
              />
            </Grid>

            <Grid key="key113" item xs={12} sm={2}>
              <Field
                id="representativeutilitypoleno"
                name="Dsp_KoziPlaceMainDntNo__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／代表電柱番号"
                fullWidth
                type="text"
                required={false}
                validate={[
                  validateRule.maxLength(23),
                ]}
              />
            </Grid>

            <Grid key="key115" item xs={12} sm={2}>
              <Field
                id="prefectures"
                name="KoziPlacePrefectures__c"
                component={renderSelect}
                className={classes.fields}
                label="工事場所／都道府県"
                fullWidth
                required={false}
                validate={[
                ]}
              >
                {generalPurposeMap &&
                    createSearchList(generalPurposeMap.PrefCode)}
              </Field>
            </Grid>

            <Grid key="key2" item xs={12} sm={3}>
              <Field
                id="municipality"
                name="KoziPlaceMunicipalities__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／市区町村"
                type="text"
                fullWidth
                required={false}
                validate={[
                  validateRule.maxLength(12),
                ]}
              />
            </Grid>

            <Grid key="key114" item xs={12} sm={3}>
              <Field
                id="location"
                name="KoziPlacePlace__c"
                component={renderTextField}
                className={classes.fields}
                label="工事場所／場所"
                type="text"
                fullWidth
                required={false}
                validate={[
                  validateRule.maxLength(255),
                ]}
              />
            </Grid>

          </Grid>

          <Grid container
          >
            <Grid key="key10" item>
              <div style={{position: 'relative'}}>
                <Field
                  id="repairIraidatefrom"
                  name="RepairIraiDate__c_From"
                  accessibilitylabel="RepairIraiDate__c_From"
                  label="改修依頼日"
                  component={renderTextField}
                  className={classes.textFieldDate}
                  required={false}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={[
                    validateRule.date,
                  ]}
                />

                <span className={classes.wave}>～</span>

                <Field
                  id="repairIraidateto"
                  name="RepairIraiDate__c_To"
                  accessibilitylabel="RepairIraiDate__c_To"
                  label={<React.Fragment />}
                  component={renderTextField}
                  className={classes.textFieldDate}
                  required={false}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={[
                    validateRule.date,
                  ]}
                />
              </div>
            </Grid>

            <Grid key="key101" item>
              <div style={{position: 'relative'}}>
                <Field
                  id="kyogaStartDatefrom"
                  name="StartNotificationDate__c_From"
                  label="着工可能通知日"
                  component={renderTextField}
                  className={classes.textFieldDate}
                  required={false}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={[
                    validateRule.date,
                  ]}
                />
                <span className={classes.wave}>～</span>
                <Field
                  id="kyogaStartDateTo"
                  name="StartNotificationDate__c_To"
                  label={<React.Fragment />}
                  component={renderTextField}
                  className={classes.textFieldDate}
                  required={false}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validate={[
                    validateRule.date,
                  ]}
                />
              </div>
            </Grid>

            <Grid key="key11" item>
              <NegativeButton
                id="btnClear"
                variant="contained"
                startIcon={<RotateLeftIcon />}
                className={classes.button}
                size="large"
                onClick={reset}
              >
                <span>クリア</span>
              </NegativeButton>

              <PositiveButton
                id="btnSearch"
                variant="contained"
                startIcon={<SearchIcon />}
                className={clsx(classes.button)}
                size="large"
                onClick={handleSubmit(this.doSearch)}
              >
                <span>検索</span>
              </PositiveButton>
            </Grid>

          </Grid>

          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />

          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key14" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  orderList: PropTypes.array,
  doGetAllGeneralPurposeMap: PropTypes.func,
  doGetOrderList: PropTypes.func.isRequired,
  doSetOrderId: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderList: state.attachorder.orderList,
  initialValues: {
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetOrderList: attachorderOperations.doGetOrderList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderList: attachorderOperations.doClearOrderList,
};

const FORM_NAME = 'RepairIraiSyunkoReportList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, changeDateFormat,
  getErrorCode, API_CODE, getErrorMessages, getResponseResult,
  getDataFromGeneralPurposeMap, getMaxDeleteNum} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';
import * as validateRule from '../../../../../validateRule.js';
import {orderListFields} from '../../../../../../common/SFFields';
import PaperPart from '../../../../../atoms/PaperPart.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

// 訂正取付申込で使用する申込ステータスコード
// 作成中,本申込中,完結
const CorrectAttachStatus = ['00', '10', '12', '90'];

/**
 * 訂正取付申込一覧
 * 画面ID:1601
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      this.props.doSetReferenceMode(false);

      // 申込の最終更新日をクリア
      this.props.doSetOrderLastModifiedDate(null);

      // FlexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1601', []);
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearOrderList();
    this.props.doClearOrder();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1601', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('orderList') &&
      this.props.orderList ? this.props.orderList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * 検索メイン処理
   */
  _searchOrder = async () => {
    const {generalPurposeMap} = this.props;

    this.setState({buttonDisabled: true});

    // 検索条件
    let wkData = {};
    if (this.props.values) {
      wkData = JSON.parse(JSON.stringify(this.props.values));
    }
    wkData['OrderCategory__c'] = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory13', 2);
    wkData['RecordType.DeveloperName'] = 'ContractDifferAttach';
    // 申込ステータスがなければ追加
    if (!Object.hasOwnProperty.call(wkData, 'OrderStatus__c')) {
      wkData['OrderStatus__c'] = CorrectAttachStatus;
    }

    const appSortParams = {
      CreatedDate: -1,
    };

    if (this.props.hasOwnProperty('orderList')) {
      // 申込テーブルからデータ取得
      const response = await this.props.doGetOrderTypeList(
          wkData, orderListFields, appSortParams,
          'getAttachOrderList', [], '');
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['訂正取付申込', '取得'],
          },
        });
        return false;
      }
      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1601', 'Id', true);
        this.gridRef.current.setItems(this.props.orderList);
      }
    }
    return true;
  }

  /**
   * 検索ボタン押下時処理
   * @param {bool} show
   */
  searchOrder = async () => {
    try {
      const result = await this._searchOrder();
      if (!result) {
        return;
      }
      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
        !this.props.orderList ||
        this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let bRet = false;

      if (this.gridRef && this.gridRef.current &&
        this.gridRef.current.selectedItemCount() > 0) {
        bRet = true;
      }

      if (bRet) {
        this.setState({buttonDisabled: false});
      } else {
        this.setState({buttonDisabled: true});
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 線設備新規登録ボタン押下時
   */
  doSenNext = async () => {
    try {
      const {generalPurposeMap} = this.props;
      this.props.doSetOrderId(null);
      this.props.doClearOrderInfo(); // 前回データをクリア
      this.props.doSetStbType(getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType1', 1));
      this.props.history.push({pathname: '/SenCorrectAttachBasicInfo'});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 点設備新規登録ボタン押下時
   */
  doTenNext = async () => {
    try {
      const {generalPurposeMap} = this.props;
      this.props.doSetOrderId(null);
      this.props.doClearOrderInfo(); // 前回データをクリア
      this.props.doSetStbType(getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType2', 1));
      this.props.history.push({pathname: '/TenCorrectAttachBasicInfo'});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 編集ボタン押下時
   */
  doEdit = () => {
    try {
      if (!this.gridRef ||
        !this.gridRef.current) {
        return;
      }

      const count = this.gridRef.current.selectedItemCount();
      if (count != 1) {
        this.props.doShowMessage({
          message: {
            id: 'CE0022',
          },
        });
        return;
      }
      const stbTypeSen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType1', 1);
      const stbTypeTen = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'StbType2', 1);
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.doSetStbType(items[0].dataItem.StbType__c);
      this.props.doSetOrderLastModifiedDate(items[0].dataItem.LastModifiedDate);
      if (items[0].dataItem.StbType__c == stbTypeSen) {
        this.props.history.push({pathname: '/SenCorrectAttachBasicInfo'});
      }
      if (items[0].dataItem.StbType__c == stbTypeTen) {
        this.props.history.push({pathname: '/TenCorrectAttachBasicInfo'});
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 削除ボタン押下時
   */
  doDelete = async () => {
    try {
      if (!this.gridRef ||
        !this.gridRef.current) {
        return;
      }

      const count = this.gridRef.current.selectedItemCount();
      if (count == 0) {
        return;
      }

      // 作成中のみ削除可
      const orderStatusCreate = getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'OrderStatus00', 2);
      const items = this.gridRef.current.selectedItem();
      const messages = [];
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (element.dataItem.OrderStatus__c != orderStatusCreate) {
          messages.push({
            id: 'CE0171',
            values: [element.dataItem.OrderNo__c],
          });
        }
      }
      if (messages.length > 0) {
        this.props.doShowMessage({message: messages});
        return;
      }

      // 最大削除軒数より大きい場合、削除不可
      const maxDeleteCount = getMaxDeleteNum();
      if (count > maxDeleteCount) {
        this.props.doShowMessage({
          message: {
            id: 'CE0198',
            values: ['申込', maxDeleteCount],
          },
        });
        return;
      }

      // 選択した申込と申込に紐づく契約電柱、線設備、添付ファイル、添付写真を削除
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['削除'],
        },
        action: async () => {
          const orderConditions = [];
          const contractDntConditions = [];
          const senStbConditions = [];
          for (const item of items) {
            orderConditions.push({
              Id: item.dataItem.Id,
              LastModifiedDate: item.dataItem.LastModifiedDate,
            });
          }

          const response = await this.props.doDeleteProprietyOrder(
              orderConditions, contractDntConditions, senStbConditions);
          const resResult = getResponseResult(response, ['訂正取付申込', '削除']);
          if (resResult.errorCode != API_CODE.SUCCESS) {
            this.props.doShowMessage({
              message: resResult.messages,
              action: async () => {
                if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
                  // 再検索
                  await this._searchOrder();
                  // 最大件数チェック
                  if (!this.listMaxCountCheck()) {
                    return;
                  }
                }
              },
            });
            return;
          }
          const errorMessages = getErrorMessages(response);
          if (errorMessages.length > 0) {
            this.props.doShowMessage({message: errorMessages});
            return;
          }

          this.props.doShowMessage({
            message: {
              id: 'CI0009',
              values: ['削除'],
            },
            action: async () => {
              // 再検索
              await this._searchOrder();
              // 最大件数チェック
              if (!this.listMaxCountCheck()) {
                return;
              }
            },
          });
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧表示
   * @return {object}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      style: {height: '300px'},
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="OrderNo__c" header="申込番号" dataType="String" width={190}/>
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType="String" width={100}/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType="String"/>
        <FlexGridColumnGroup binding="OrderDate__c" header="申込年月日" dataType="Date" width={110}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.OrderDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType="String"/>
        <FlexGridColumnGroup header="工事場所" align="center">
          <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
          <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePrefecturesName" header="都道府県" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String"/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType="String"/>
        <FlexGridColumnGroup binding="KyogasyaSendBackReason__c" header="差戻理由" dataType="String"/>
        <FlexGridColumnGroup binding="TemporarilySavedDate__c" header="一時保存日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(context.item.TemporarilySavedDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1601CreateSen" item>
          <PositiveButton
            id="btn1601CreateSen"
            onClick={handleSubmit(this.doSenNext)}
            variant="contained"
            startIcon={<AddIcon />}
            size="large"
          >
            <span>新規申込（線設備）</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1601CreateTen" item>
          <MuiTooltip
            id="tl1601CreateTen"
            content='小型無線基地局，防犯カメラの申込はこちら'>
            <PositiveButton
              id="btn1601CreateTen"
              onClick={handleSubmit(this.doTenNext)}
              variant="contained"
              startIcon={<AddIcon />}
              size="large"
            >
              <span>新規申込（点設備）</span>
              <InfoOutlined fontSize="small"/>
            </PositiveButton>
          </MuiTooltip>
        </Grid>
        <Grid key="key1601EditPropriety" item>
          <PositiveButton
            id="btn1601EditPropriety"
            onClick={handleSubmit(this.doEdit)}
            variant="contained"
            startIcon={<EditIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>編集</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1601DeletePropriety" item>
          <DangerButton
            id="btn1601DeletePropriety"
            onClick={handleSubmit(this.doDelete)}
            variant="contained"
            startIcon={<DeleteIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>削除</span>
          </DangerButton>
        </Grid>
        <Grid key="key1601Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1601ProprietyOrderNo" item xs={12} sm={2}>
                <Field
                  id="id1601ProprietyOrderNo"
                  name="OrderNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="申込番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1601StbType" item xs={12} sm={2}>
                <Field
                  id="id1601StbType"
                  name="StbType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key1601KyogaType" item xs={12} sm={2}>
                <Field
                  id="id1601KyogaType"
                  name="KyogaType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                       createSearchList(generalPurposeMap.KyogaType,
                           'Code__c',
                           'Name',
                           {Code__c: userInfo.Account.Account__r.KyogaType__c,
                             ValueString1__c:
                             this.props.values &&
                             Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                               [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1601KoziTitle" item xs={12} sm={4}>
                <Field
                  id="id1601KoziTitle"
                  name="KoziTitle__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事件名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>
              <Grid key="key1601OrderStatus" item xs={12} sm={2}>
                <Field
                  id="id1601OrderStatus"
                  name="OrderStatus__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="申込ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                       createSearchList(generalPurposeMap.OrderStatus,
                           'Code__c', 'Name', {Code__c: CorrectAttachStatus})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1601KoziPlaceMainDntNo_SenroName" item xs={12} sm={2}>
                <Field
                  id="id1601KoziPlaceMainDntNo_SenroName"
                  name="KoziPlaceMainDntNo_SenroName__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／線路名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>
              <Grid key="key1601KoziPlaceMainDntNo" item xs={12} sm={2}>
                <Field
                  id="id1601KoziPlaceMainDntNo"
                  name="Dsp_KoziPlaceMainDntNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／代表電柱番号"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
              <Grid key="key1601KoziPlacePrefectures" item xs={12} sm={2}>
                <Field
                  id="id1601KoziPlacePrefectures"
                  name="KoziPlacePrefectures__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="工事場所／都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>
              <Grid key="key1601KoziPlaceMunicipalities" item xs={12} sm={3}>
                <Field
                  id="id1601KoziPlaceMunicipalities"
                  name="KoziPlaceMunicipalities__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／市区町村"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1601KoziPlacePlace" item xs={12} sm={3}>
                <Field
                  id="id1601KoziPlacePlace"
                  name="KoziPlacePlace__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／場所"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1601OrderDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1601OrderDateFrom"
                    name="OrderDate__c_From"
                    accessibilitylabel="OrderDate__c_From"
                    label="申込年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1601OrderDateTo"
                    name="OrderDate__c_To"
                    accessibilitylabel="OrderDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1601Clear" item xs={12} sm>
                <NegativeButton
                  id="id1601Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="id1601Search"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>
          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key1601List" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  order: PropTypes.object,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderTypeList: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doDeleteProprietyOrder: PropTypes.func,
  doClearOrderInfo: PropTypes.func,
  doSetStbType: PropTypes.func,
  doClearOrderList: PropTypes.func,
  doRetrieveOrder: PropTypes.func,
  doClearOrder: PropTypes.func,
  doSetReferenceMode: PropTypes.func,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderList: state.attachorder.orderList,
    order: state.attachorder.order,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doDeleteProprietyOrder: attachorderOperations.doDeleteProprietyOrder,
  doClearOrderInfo: attachorderOperations.doClearOrderInfo,
  doSetStbType: commonOperations.doSetStbType,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doRetrieveOrder: attachorderOperations.doRetrieveOrder,
  doClearOrder: attachorderOperations.doClearOrder,
  doSetReferenceMode: commonOperations.doSetReferenceMode,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'CorrectAttachOrderList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

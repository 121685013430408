import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import {NegativeButton} from './NegativeButton.js';

/**
 * @typedef BackButtonOption 「戻る」ボタンオプション
 * @property {object} props プロパティ
 * @property {function} [onClick] ボタン押下時の処理
 * @property {boolean} [isModified] 変更有フラグ
 * @property {boolean} [showWarning] 警告表示有無。true指定時、props.dirtyまたはisModifiedがtrueの際に警告ダイアログを表示する。
 * @property {object} [style] Style指定
 */

/**
 * 「戻る」ボタン。
 * @param {BackButtonOption} {props, onClick, isModified, showWarning, style} オプション
 * @return {JSX.Element} 「戻る」ボタン要素
 */
function BackButton(
    {
      props,
      onClick,
      onCheckGrid,
      isModified = false,
      showWarning = false,
      useDirty = true,
      style = {},
      fullWidth,
    }) {
  return (
    <NegativeButton
      id="btnCancel"
      onClick={(event) => {
        let isGirdModified = false;
        if (onCheckGrid) {
          isGirdModified = onCheckGrid(event);
        }
        // reduxFormで検出した変更とreduxForm以外の変更をチェック
        // どちらかが変更あり(true)の場合、警告表示
        if (showWarning &&
          ((useDirty && props.dirty) || isModified || isGirdModified)) {
          props.doShowMessage({
            message: {
              id: 'CW0013',
            },
            action: async () => {
              if (onClick) {
                onClick(event);
              }

              back(props.history);
            },
          });
          return;
        }

        if (onClick) {
          onClick(event);
        }

        back(props.history);
      }}
      variant="contained"
      startIcon={<KeyboardReturnIcon />}
      size="large"
      props={props}
      style={style}
      fullWidth={fullWidth}
    >
      <span>戻る</span>
    </NegativeButton>
  );
};

/**
 * 戻る処理の実行。
 * @param {object} history Historyオブジェクト
 */
function back(history) {
  let transition = history.popStockUrl();

  // stockUrlが空の場合はメニューに戻る
  if (!transition) {
    transition = '/menu';
  }

  history.push(transition, null, history.option.NOT_SET);
}

BackButton.propTypes = {
  props: PropTypes.object.isRequired,
  history: PropTypes.object,
  onClick: PropTypes.func,
  doShowMessage: PropTypes.func,
  dirty: PropTypes.bool,
  isModified: PropTypes.bool,
  showWarning: PropTypes.bool,
  useDirty: PropTypes.bool,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  onCheckGrid: PropTypes.func,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(BackButton);

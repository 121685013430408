import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Container from '../../../../../components/templates/IsetuIrai/IsetuIraiRegister/IsetuIrai/BasicInput';
import {authOperations} from '../../../../../reducks/auth';
import {commonOperations} from '../../../../../reducks/common';

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
};

/**
 * 移設依頼基本情報入力 ページ。
 * 画面ID:1402
 *
 * @param {string} ret
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const ret = await this.props.getUserInfo();
    this.doJudge(ret);
  }

  doJudge = (ret) => {
    if (ret !== 'OK' || !this.props.userInfo) {
      const {doShowMessage} = this.props;
      const dialog = {
        message: 'CS0001',
        action: this.doMoveLogin,
      };
      doShowMessage(dialog);
    }
  }

  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    return (<Container
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  doShowMessage: PropTypes.func,
  getUserInfo: PropTypes.func,
  userInfo: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default ContainerApp;

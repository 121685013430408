import {combineReducers} from 'redux';
import types from './types';

// 請求明細取得(ID指定)
const billingDetailIdSpecify = (state = null, action) => {
  switch (action.type) {
    case types.GET_BILLINGDETAIL_ID:
      return action.billingDetailIdSpecify;
    default:
      return state;
  }
};

// 請求明細一覧
const billingDetailList = (state = null, action) => {
  switch (action.type) {
    case types.GET_BILLINGDETAIL_LIST:
      return action.billingDetailList;
    default:
      return state;
  }
};

// 定期共架料内訳一覧（OR条件あり）
const billingDetailListORFilter = (state = null, action) => {
  switch (action.type) {
    case types.GET_BILLINGDETAIL_LIST_ORFILTER:
      return action.billingDetailListORFilter;
    default:
      return state;
  }
};

// 請求明細ID
const billingDetailId = (state = null, action) => {
  switch (action.type) {
    case types.SET_BILLINGDETAIL_ID:
      return action.billingDetailId;
    default:
      return state;
  }
};

const reducer = combineReducers({
  billingDetailIdSpecify,
  billingDetailList,
  billingDetailListORFilter,
  billingDetailId,
});

export default reducer;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {searchDb, execApexRestApi, changeDateFormat, isNWDesignZgsya,
  API_CODE, getResponseResult, getDataFromGeneralPurposeMap,
  getMaxDeleteNum} from '../../../../../../common/common.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createList} from '../../../../../../common/ComboParts.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

/** 一覧で取得する情報の取得フィールド名 */
const fields = [
  'Id',
  'IsetuIraiCarryOutDate__c',
  'DesignCompanySyozoku__c',
  'IsetuIraiNo__c',
  'KoziPlacePrefectures__c',
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefectures',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'KoziOverview__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'IsetuCompHopeDateTimeOfContact__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'IsetuIraiDesignDocumentNo__c',
  'DesignTantoName__c',
  'IsetuIraiHonsu__c',
  'IsetuIraiStatus__c',
  'toLabel(IsetuIraiStatus__c)IsetuIraiStatus',
  'LastModifiedDate',
];

// 削除検索対象のフィールド名
const appFields = [
  'Id', 'LastModifiedDate', 'IsetuIraiStatus__c',
];

// 移設依頼設計書に紐づく、契約電柱の検索対象
const subqueryIsetu = [
  {
    'fields': ['Id', 'LastModifiedDate'],
    'table': 'IsetuIraiContractDnts__r',
  },
  {
    'fields': ['Id', 'LastModifiedDate'],
    'table': 'IsetuDocOrders__r',
  },
];

// 申込の削除検索対象のフィールド名
const orderFields = [
  'Id', 'LastModifiedDate',
];

// 申込に紐づく、契約電柱の検索対象
const subqueryOrder = [{
  'fields': ['Id', 'LastModifiedDate'],
  'table': 'ContractDnts__r',
}];

/**
 * 移設依頼内容登録一覧 テンプレート。
 * 画面ID:1401
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefectureId: null,
      disableButton: true,
      isReference: false,
      dataList: [],
    };
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {userInfo, generalPurposeMap} = this.props;
      // 移設依頼設計書の最終更新日をクリア
      this.props.doSetOrderLastModifiedDate(null);

      // FlexGridに初期値設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1401', []);
      }

      let _isReference = false;
      // ログインユーザが、外線工事会社の場合
      if (isNWDesignZgsya(userInfo, generalPurposeMap) &&
          userInfo.MainteKoziCompany__c != null &&
          userInfo.MainteKoziCompany__c) {
        // 表示のみに設定
        _isReference = true;
      }
      this.setState({isReference: _isReference});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearDesignDocumentTypeList();
    this.doClearList();
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('designDocumentList') &&
      this.props.designDocumentList ? this.props.designDocumentList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
  * ボタンの活性、非活性化
  */
  checkHandler = () => {
    let bRet = false;
    if (this.gridRef && this.gridRef.current &&
      this.gridRef.current.selectedItemCount() > 0) {
      bRet = true;
    }
    this.setState({disableButton: !bRet});
  }

  /**
   * 検索の実行。
   */
  doSearch = async () => {
    const {values} = this.props;
    try {
      let conditions = this.createConditions(values);
      // レコードタイプ
      conditions['RecordType.DeveloperName'] = 'IsetuIraiDesignDocument';
      const sortParams = {
        IsetuIraiCarryOutDate__c: -1,
      };
      const result = await this.props.doGetDesignDocumentTypeList(
          conditions, fields, sortParams, 'getRegisterList');

      const errCode = result ? result.data ? result.data.body ?
        result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return;
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('designDocumentList') ||
        this.props.designDocumentList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      } else {
        // 最大件数チェック
        this.listMaxCountCheck();
      }

      let wkDataList = [];
      if (this.props.hasOwnProperty('designDocumentList') &&
        this.props.designDocumentList) {
        for (const items of this.props.designDocumentList) {
          let data = {};
          for (const item in items) {
            if (!items.hasOwnProperty(item)) {
              continue;
            }
            data[item] = items[item];
          }
          // 移設依頼完了希望日には、工事日連絡時を設定、未指定の場合、設計時を設定
          if (data['IsetuCompHopeDateTimeOfContact__c']) {
            data['IsetuCompHopeDateTime'] = data['IsetuCompHopeDateTimeOfContact__c'];
          } else {
            data['IsetuCompHopeDateTime'] = data['IsetuCompHopeDateTimeOfDesign__c'];
          }
          wkDataList.push(data);
        }
        this.setState({
          dataList: wkDataList,
        });
      }

      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.saveSelectedItems('1401', 'Id', true);
        this.gridRef.current.setItems(wkDataList);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 検索条件の生成。
   *
   * @param {object} values Reduxが保持する画面の入力情報
   * @return {object} 検索条件
   */
  createConditions = (values) => {
    const conditions = {};

    for (const key in values) {
      if (!values.hasOwnProperty(key)) {
        continue;
      }

      switch (key) {
        case 'IsetuIraiStatus':
          // 移設依頼ステータスの値をDB検索用に置き換える
          conditions['IsetuIraiStatus__c'] = this.getIsetuIraiStatus(values[key]);
          break;

        case 'KoziScheduleSeason__c_From':
          // 工事予定時期は、日は01で登録
          conditions['KoziScheduleSeason__c_From'] = values[key] + '-01';
          break;
        case 'KoziScheduleSeason__c_To':
          // 工事予定時期は、日は01で登録
          conditions['KoziScheduleSeason__c_To'] = values[key] + '-01';
          break;

        default:
          conditions[key] = values[key];
          break;
      }
    }

    return conditions;
  }

  /**
   * 移設依頼ステータスの置き換え。
   *
   * 画面で選択した移設依頼ステータスを、検索条件に置き換える。
   * ・一時保存 → 作成中(00)
   * ・依頼中 → 依頼内容確認済(01), 工事日調整中(02), 工事日確定済(03)
   * ・竣工済 → 完結(04)
   *
   * @param {number} status 画面で選択した移設依頼ステータス
   * @return {string|string[]} 検索対象の移設依頼ステータス
   */
  getIsetuIraiStatus = (status) => {
    switch (status) {
      case 1:
        // 一時保存
        return '00';

      case 2:
        // 依頼中
        return ['01', '02', '03'];

      case 3:
        // 竣工済
        return '04';

      default:
        return null;
    }
  }

  /**
   * 編集ボタン押下
   *
   * @return {void}
   */
  doEdit = async () => {
    try {
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItemCount = this.gridRef.current.selectedItemCount();
      if (selectedItemCount == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['編集'],
          },
        });
        return;
      }

      if (selectedItemCount != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });

        return;
      }

      const selectedItems = this.gridRef.current.selectedItems;
      this.props.doSetDesignDocumentId(selectedItems[0].dataItem.Id);
      this.props.doSetOrderLastModifiedDate(
          selectedItems[0].dataItem.LastModifiedDate);
      this.props.history.push({pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/BasicInput'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 新規登録ボタン押下
   *
   * @return {void}
   */
  doCreate = async () => {
    try {
      this.props.doSetDesignDocumentId(null);
      this.props.doClearDesignDocument();
      this.props.history.push({pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/BasicInput'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 削除ボタン押下
   *
   * @return {void}
   */
  doDelete = async () => {
    try {
      // チェックボックスのチェック数が1以上でない場合エラー
      const selectedItemCount = this.gridRef.current.selectedItemCount();
      if (selectedItemCount == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['削除'],
          },
        });
        return;
      }

      // チェックした項目に作成中以外があればエラー
      const selectedItems = this.gridRef.current.selectedItems;
      for (const item of selectedItems) {
        // チェックした項目に作成中以外があればエラー
        if (item.dataItem.IsetuIraiStatus__c != '00') {
          this.props.doShowMessage({
            message: {
              id: 'ME0180',
              values: [item.dataItem.IsetuIraiNo__c],
            },
          });
          return;
        }
      }

      // 最大削除軒数より大きい場合、削除不可
      const maxDeleteCount = getMaxDeleteNum();
      if (selectedItemCount > maxDeleteCount) {
        this.props.doShowMessage({
          message: {
            id: 'CE0198',
            values: ['移設依頼', maxDeleteCount],
          },
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['削除'],
        },
        action: this.executeDelete,
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 移設依頼設計書削除
   * @return {void}
   */
  executeDelete = async () => {
    const {userInfo} = this.props;
    try {
      const selectedItems = this.gridRef.current.selectedItems;
      const targets = [];
      for (const item of selectedItems) {
        targets.push(item.dataItem.Id);
      }

      // 移設依頼設計書テーブル検索時の検索条件
      const appConditions = {
        'Id': targets,
        'RecordType.DeveloperName': 'IsetuIraiDesignDocument',
      };

      // データ取得
      const retApp = await searchDb(
          userInfo, 'DesignDocument/getSub', appConditions, appFields,
          null, null, subqueryIsetu);
      let errCode = retApp ? retApp.data ? retApp.data.body ?
        retApp.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return;
      }

      const delIsetuList = [];
      const delOrderList = [];
      const delContractList = [];
      const orderIdList = [];
      for (const items of retApp.data.body.data) {
        delIsetuList.push({
          'Id': items.Id,
          'LastModifiedDate': items.LastModifiedDate,
          'IsetuIraiStatus__c': items.IsetuIraiStatus__c,
        });
        if (items.IsetuDocOrders__r) {
          for (const record of items.IsetuDocOrders__r.records) {
            delOrderList.push({
              'Id': record.Id,
              'LastModifiedDate': record.LastModifiedDate,
            });
            orderIdList.push(record.Id);
          }
        }
        if (items.IsetuIraiContractDnts__r) {
          for (const record of items.IsetuIraiContractDnts__r.records) {
            delContractList.push({
              'Id': record.Id,
              'LastModifiedDate': record.LastModifiedDate,
            });
          }
        }
      }

      if (orderIdList && orderIdList.length > 0) {
        // 申込テーブル検索時の検索条件
        const orderConditions = {
          'Id': orderIdList,
          'RecordType.DeveloperName': 'IsetuIrai',
        };

        // データ取得
        const retSub = await searchDb(
            userInfo, 'Order/getSub', orderConditions, orderFields,
            null, null, subqueryOrder);

        const errCodeOrder = retSub ? retSub.data ? retSub.data.body ?
        retSub.data.body.errorCode : undefined : undefined : undefined;
        if (errCodeOrder != '00000') {
          this.sysErr();
          return;
        }

        for (const items of retSub.data.body.data) {
          if (items.ContractDnts__r) {
            for (const record of items.ContractDnts__r.records) {
              delContractList.push({
                'Id': record.Id,
                'LastModifiedDate': record.LastModifiedDate,
              });
            }
          }
        }
      }

      const body = {
        'record': {
          'designDocument': delIsetuList,
          'order': delOrderList,
          'contractDnt': delContractList,
        },
      };

      const result = await execApexRestApi(
          userInfo, 'ApiEnergia/registerListDelete', body);

      // 削除失敗時
      const resResult = getResponseResult(result, ['移設依頼設計書', '削除']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      // 成功の場合、一覧から選択項目を削除
      const dataList = [];
      for (const itemList of this.props.designDocumentList) {
        let bDelete = false;
        for (const item of selectedItems) {
          // 削除したIdと一致した場合
          if (item.dataItem.Id == itemList.Id) {
            bDelete = true;
            break;
          }
        }
        if (bDelete == false) {
          dataList.push(itemList);
        }
      }

      this.setState({dataList: dataList},
          ()=>{
            // 移設依頼設計書ストアを更新
            this.props.doSetDesignDocumentList(this.state.dataList);
          });

      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setItems(dataList);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1401', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    // 工事予定時期
    if (e.getColumn().binding === 'KoziScheduleSeason__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        const ret = changeDateFormat(lb.collectionView.items[index].value, 'YYYY年MM月');
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      isReadOnly: true,
      checkedFunction: this.checkHandler,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='IsetuIraiCarryOutDate__c' header="移設依頼発信日" dataType="Date">
            <FlexGridCellTemplate
              cellType="Cell"
              template={ (context) => {
                return changeDateFormat(
                    context.item.IsetuIraiCarryOutDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DesignCompanySyozoku__c' header='設計会社／所属' dataType='String' width={165}/>
          <FlexGridColumnGroup binding='DesignTantoName__c' header='設計担当者名' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocumentNo__c' header='設計書番号' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuIraiNo__c' header='一連番号' dataType='String'/>
          <FlexGridColumnGroup binding='KoziPlacePrefectures' header='都道府県' dataType='String' width={100}/>
          <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType='String'/>
          <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType='String'/>
          <FlexGridColumnGroup binding='DntNo__c' header='電柱番号' dataType='String'/>
          <FlexGridColumnGroup binding='KoziOverview__c' header='工事概要' dataType='String'/>
          <FlexGridColumnGroup binding='IsetuCompHopeDateTime' header='移設完了希望日' dataType='Date'>
            <FlexGridCellTemplate
              cellType='Cell'
              template={ (context) => {
                return changeDateFormat(
                    context.item.IsetuCompHopeDateTime);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziScheduleSeason__c' header='工事予定時期' dataType='String'>
            <FlexGridCellTemplate
              cellType='Cell'
              template={ (context) => {
                return changeDateFormat(
                    context.item.KoziScheduleSeason__c, 'YYYY年MM月');
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziScheduleSeasonHosoku__c' header='工事予定時期補足' dataType='String' width={165}/>
          <FlexGridColumnGroup binding='IsetuIraiHonsu__c' header='工事対象本数' dataType='Number'/>
          <FlexGridColumnGroup binding='IsetuIraiStatus' header='移設依頼ステータス' dataType='String' width={165}/>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {
      classes,
      generalPurposeMap,
      handleSubmit,
      reset,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<EditIcon />}
            onClick={
              this.doEdit
            }
            variant="contained"
            size="large"
            disabled={this.state.disableButton}
          >
            <span>編集</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<AddIcon />}
            onClick={
              this.doCreate
            }
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isReference}
          >
            <span>新規登録</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <DangerButton
            startIcon={<DeleteIcon />}
            onClick={
              this.doDelete
            }
            variant="contained"
            size="large"
            disabled={this.state.disableButton || this.state.isReference}
          >
            <span>削除</span>
          </DangerButton>
        </Grid>

        <Grid key="btn4" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderSelect}
                  label="移設依頼ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value={1}>一時保存</MenuItem>
                  <MenuItem value={2}>依頼中</MenuItem>
                  <MenuItem value={3}>竣工済</MenuItem>
                </Field>
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuIraiCarryOutDate__c_From"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_From"
                    label="移設依頼発信日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuIraiCarryOutDate__c_To"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(8),
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="key1-6" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap != null &&
                    createList(generalPurposeMap.PrefCode)
                  }
                </Field>
              </Grid>

              <Grid key="key1-8" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-10" item xs={12} sm={3}>
                <Field
                  name="DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(25),
                  ]}
                />
              </Grid>

              <Grid key="key1-11" item xs={12} sm={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

              <Grid key="key1-12" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_From"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_From"
                    label="移設完了希望日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_To"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-13" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="KoziScheduleSeason__c_From"
                    accessibilitylabel="KoziScheduleSeason__c_From"
                    label="工事予定時期"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="KoziScheduleSeason__c_To"
                    accessibilitylabel="KoziScheduleSeason__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-14" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.doSearch)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>
          </PaperPart>

          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  values: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  designDocumentList: PropTypes.array,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  doGetDesignDocumentTypeList: PropTypes.func.isRequired,
  doClearDesignDocumentTypeList: PropTypes.func,
  doClearDesignDocument: PropTypes.func,
  doSetDesignDocumentId: PropTypes.func.isRequired,
  doSetDesignDocumentList: PropTypes.func,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  designDocumentList: state.isetuIrai.designDocumentList,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetDesignDocumentTypeList: isetuIraiOperations.doGetDesignDocumentTypeList,
  doClearDesignDocumentTypeList:
   isetuIraiOperations.doClearDesignDocumentTypeList,
  doClearDesignDocument:
   isetuIraiOperations.doClearDesignDocument,
  doSetDesignDocumentList: isetuIraiOperations.doSetDesignDocumentList,
  doSetDesignDocumentId: isetuIraiOperations.doSetDesignDocumentId,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'IsetuIraiContentsRegisterList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    position: 'relative',
  },
  container: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  iconStyle: {
    backgroundColor: '#001a37',
    color: '#fff',
  },
}));

export default function AddIconButton({isDisabled}) {
  const classes = useStyles();

  return (
    <div id="btnTblFooter" className={classes.root} >
      <Fab id="btnAddRow" aria-label="add" size="small"
        className={clsx(classes.container, classes.iconStyle)}
        disabled={isDisabled}>
        <AddIcon id="btnAddRow"/>
      </Fab>
    </div>
  );
};

AddIconButton.propTypes = {
  isDisabled: PropTypes.bool,
};

const GET_ORDER_LIST = 'GET_ORDER_LIST';
const GET_ORDER_TYPE_LIST = 'GET_ORDER_TYPE_LIST';
const GET_ORDER_INFO = 'GET_ORDER_INFO';
const RETRIEVE_ORDER = 'RETRIEVE_ORDER';
const REGIST_ORDER = 'REGIST_ORDER';
const UPDATE_ORDER = 'UPDATE_ORDER';
const SET_ORDER_ID = 'SET_ORDER_ID';
const UPDATE_PROPRIETY_CONTRACT_DNT = 'UPDATE_PROPRIETY_CONTRACT_DNT';
const ORDER_PROPRIETY = 'ORDER_PROPRIETY';
const ORDER_RESULT_LIST = 'ORDER_RESULT_LIST';
const ORDER_RESULT_DETAIL = 'ORDER_RESULT_DETAIL';
const UPDATE_PROPRIETY_ORDER = 'UPDATE_PROPRIETY_ORDER';
const DELETE_PROPRIETY_ORDER = 'DELETE_PROPRIETY_ORDER';
const DELETE_FORMAL_ORDER = 'DELETE_FORMAL_ORDER';
const GET_FORMAL_ORDER_INFO = 'GET_FORMAL_ORDER_INFO';
const UPDATE_FORMAL_ORDER = 'UPDATE_FORMAL_ORDER';
const CHECK_ORDER = 'CHECK_ORDER';
const GET_ORDER_SUB_LIST = 'GET_ORDER_SUB_LIST';
const GET_ORDER_SUB_ALL_LIST = 'GET_ORDER_SUB_ALL_LIST';
const SET_ISKK_UMU = 'SET_ISKK_UMU';
const SET_ORDER_NO = 'SET_ORDER_NO';
const SET_ORDER_LAST_MODIFIED_DATE = 'SET_ORDER_LAST_MODIFIED_DATE';
const SET_ORDER_MODIFIED_CONDITION = 'SET_ORDER_MODIFIED_CONDITION';

export default {
  /** 申込一覧 取得 */
  GET_ORDER_LIST,

  /** 申込一覧 取得（関数名指定） */
  GET_ORDER_TYPE_LIST,

  /** 申込 ID指定取得 */
  RETRIEVE_ORDER,

  /** 申込情報 取得 */
  GET_ORDER_INFO,

  /** 申込 登録 */
  REGIST_ORDER,

  /** 申込 更新 */
  UPDATE_ORDER,

  /** 申込ID 設定 */
  SET_ORDER_ID,

  /** 可否判定申込契約電柱 */
  UPDATE_PROPRIETY_CONTRACT_DNT,

  /** 可否判定申込 */
  ORDER_PROPRIETY,

  /** 可否判定結果 */
  ORDER_RESULT_LIST,

  /** 可否判定結果詳細 */
  ORDER_RESULT_DETAIL,

  /** 可否判定申込更新 */
  UPDATE_PROPRIETY_ORDER,

  /** 可否判定申込削除 */
  DELETE_PROPRIETY_ORDER,

  /** 本申込削除 */
  DELETE_FORMAL_ORDER,

  /** 本申込取得 */
  GET_FORMAL_ORDER_INFO,

  /** 本申込更新 */
  UPDATE_FORMAL_ORDER,

  /** 申込入力チェック */
  CHECK_ORDER,

  /** 申込一覧サブクエリ 取得 */
  GET_ORDER_SUB_LIST,

  /** 申込一覧サブクエリ 取得（アクセス制限なし） */
  GET_ORDER_SUB_ALL_LIST,

  /** 一束化有無 設定 */
  SET_ISKK_UMU,

  /** 申込番号 設定 */
  SET_ORDER_NO,

  /** 申込の最終更新日時 設定 */
  SET_ORDER_LAST_MODIFIED_DATE,

  /** 申込のIdと最終更新日時 設定 */
  SET_ORDER_MODIFIED_CONDITION,
};

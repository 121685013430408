import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {changeDateFormat, getCodeFromGeneralPurposeMap,
  getErrorCode, API_CODE, getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import * as validateRule from '../../../../../validateRule.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';
import {formalOrderListFields} from '../../../../../../common/SFFields';
import PaperPart from '../../../../../atoms/PaperPart.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

// 申込テーブル検索時のソート条件
const appSortParams = {
  StartNotificationDate__c: -1,
};

// 竣工届で使用する申込ステータスコード
// 竣工待ち／工事開始可,竣工確認中,完結
const syunkoOrderStatus = ['40', '41', '90'];

/**
 * 竣工届申込対象一覧
 * 画面ID:1026
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1026', []);
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1026', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      const {generalPurposeMap} = this.props;

      // 検索条件
      let wkData = {};
      if (this.props.values) {
        wkData = JSON.parse(JSON.stringify(this.props.values));
      }

      // パラメータ設定
      const appConditions = {
        'OrderCategory__c': [
          getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory02', 2), // 本申込
        ],
        'RecordType.DeveloperName': 'MainOrder',
        'KyogaKoziScheduleDate__c_NE': null,
      };
      if (wkData != null) {
        // 申込ステータスがなければ追加
        if (!Object.hasOwnProperty.call(wkData, 'OrderStatus__c')) {
          wkData['OrderStatus__c'] = syunkoOrderStatus;
        }
        Object.assign(appConditions, wkData);
      }
      // 検索
      const result =
      await this.props.doGetOrderList(
          appConditions, formalOrderListFields, appSortParams, '');
      const errorCode = getErrorCode(result);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['竣工届一覧', '取得'],
          },
        });
        return;
      }
      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1026', 'Id', true);
      }

      // 件数超過エラー
      const maxKensu = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      const dataLen = result.data.body.data ?
        result.data.body.data.length : 0;
      if (dataLen >= maxKensu) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setItems(this.props.orderList);
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
        !this.props.orderList ||
        this.props.orderList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
  * システムエラー
  */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let bRet = false;

      if (this.gridRef && this.gridRef.current &&
          this.gridRef.current.selectedItemCount() > 0) {
        bRet = true;
      }

      if (bRet) {
        this.setState({buttonDisabled: false});
      } else {
        this.setState({buttonDisabled: true});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 竣工届申込ボタン押下時
   */
  doSyunkoReportOrder = () => {
    try {
      const count = this.gridRef.current.selectedItemCount();
      if (count != 1) {
        this.props.doShowMessage({
          message: {
            id: 'CE0022',
          },
        });
        return;
      }

      const selectedItems = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(selectedItems[0].dataItem.Id);
      this.props.doSetStbType(selectedItems[0].dataItem.StbType__c);
      this.props.doSetOrderModifiedCondition(
          selectedItems[0].dataItem.Id,
          selectedItems[0].dataItem.LastModifiedDate);
      if (selectedItems[0].dataItem.StbType__c == '2') {
        this.props.history.push({pathname: '/SyunkoReportTenOrderDnt'});
      } else {
        this.props.history.push({pathname: '/SyunkoReportSenOrderDnt'});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      style: {height: '300px'},
      isReadOnly: true,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="OrderNo__c" header="本申込番号" dataType="String" width={150}/>
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType="String" width={100}/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType="String"/>
        <FlexGridColumnGroup binding="OrderDate__c" header='本申込年月日' dataType="Date" width={130}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.OrderDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="StartNotificationDate__c" header='開始通知日' dataType="Date" width={130}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.StartNotificationDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType="String"/>
        <FlexGridColumnGroup header="工事場所" align="center">
          <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
          <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePrefecturesName" header="都道府県" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String"/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType="String"/>
        <FlexGridColumnGroup binding="TemporarilySavedDate__c" header='一時保存日' dataType="Date" width={110}>
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.TemporarilySavedDate__c);
            }}
          />
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;
    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1026SyunkoReport" item>
          <PositiveButton
            id="id1026SyunkoReport"
            onClick={
              handleSubmit(this.doSyunkoReportOrder)
            }
            variant="contained"
            size="large"
            disabled={this.state.buttonDisabled}>
            <span>竣工届申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1026Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1" item xs={12} sm={2}>
                <Field
                  id="id1026OrderNo"
                  name="OrderNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="本申込番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1026StbType" item xs={12} sm={2}>
                <Field
                  id="id1026StbType"
                  name="StbType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType,
                        'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key1026KyogaType" item xs={12} sm={2}>
                <Field
                  id="id1026KyogaType"
                  name="KyogaType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1026KoziTitle" item xs={12} sm={4}>
                <Field
                  id="id1026KoziTitle"
                  name="KoziTitle__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事件名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>
              <Grid key="key1026OrderStatus" item xs={12} sm={2}>
                <Field
                  id="id1026OrderStatus"
                  name="OrderStatus__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="申込ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                       createSearchList(generalPurposeMap.OrderStatus,
                           'Code__c', 'Name', {Code__c: syunkoOrderStatus})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1026KoziPlaceMainDntNo_SenroName" item xs={12} sm={2}>
                <Field
                  id="id1026KoziPlaceMainDntNo_SenroName"
                  name="KoziPlaceMainDntNo_SenroName__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／線路名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>
              <Grid key="key1026KoziPlaceMainDntNo" item xs={12} sm={2}>
                <Field
                  id="id1026KoziPlaceMainDntNo"
                  name="Dsp_KoziPlaceMainDntNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／代表電柱番号"
                  type="text"
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
              <Grid key="key1026KoziPlacePrefectures" item xs={12} sm={2}>
                <Field
                  id="id1026KoziPlacePrefectures"
                  name="KoziPlacePrefectures__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="工事場所／都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>
              <Grid key="key1026KoziPlaceMunicipalities" item xs={12} sm={3}>
                <Field
                  id="id1026KoziPlaceMunicipalities"
                  name="KoziPlaceMunicipalities__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／市区町村"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>

              <Grid key="key1026KoziPlacePlace" item xs={12} sm={3}>
                <Field
                  id="id1026KoziPlacePlace"
                  name="KoziPlacePlace__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／場所"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid key="key1026OrderDate" item xs={12} sm>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1026OrderDateFrom"
                    name="OrderDate__c_From"
                    accessibilitylabel="OrderDate__c_From"
                    label="本申込年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1026OrderDateTo"
                    name="OrderDate__c_To"
                    accessibilitylabel="OrderDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1026StartNotificationDate" item xs={12} sm>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1026StartNotificationDateFrom"
                    name="StartNotificationDate__c_From"
                    accessibilitylabel="StartNotificationDate__c_From"
                    label="開始通知日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1026StartNotificationDateTo"
                    name="StartNotificationDate__c_To"
                    accessibilitylabel="StartNotificationDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1026Clear" item>
                <NegativeButton
                  id="id1026Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="id1026Search"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>
          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key14" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderList: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doClearOrderList: PropTypes.func,
  doSetStbType: PropTypes.func,
  doSetOrderModifiedCondition: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderList: state.attachorder.orderList,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetOrderList: attachorderOperations.doGetOrderList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderList: attachorderOperations.doClearOrderList,
  doSetStbType: commonOperations.doSetStbType,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'SyunkoReportList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import Grid from '@material-ui/core/Grid';

import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';

import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {renderTextField} from '../../../../../atoms/CustomPart.js';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {commonOperations} from '../../../../../../reducks/common';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

const fieldsDataMap = [
  {field: 'IsetuIraiNo__c', data: 'IsetuIraiNo__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'KoziPlacePrefectures__c', data: 'KoziPlacePrefectures__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'KoziPlaceMunicipalities__c', data: 'KoziPlaceMunicipalities__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'KoziPlacePlace__c', data: 'KoziPlacePlace__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'DntNo__c', data: 'DntNo__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'KoziOverview__c', data: 'KoziOverview__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'IsetuIraiDesignDocumentNo__c', data: 'IsetuIraiDesignDocumentNo__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'DesignCompanySyozoku__c', data: 'DesignCompanySyozoku__c', ref: 'IsetuIraiDesignDocument__r'},
  {field: 'DesignTantoName__c', data: 'DesignTantoName__c', ref: 'IsetuIraiDesignDocument__r'},
];

// 取得項目
const appFields = [
  'IsetuIraiDesignDocument__r.IsetuIraiNo__c',
  'OrderStatus__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c',
  'toLabel(IsetuIraiDesignDocument__r.KoziPlacePrefectures__c)',
  'IsetuIraiDesignDocument__r.KoziPlaceMunicipalities__c',
  'IsetuIraiDesignDocument__r.KoziPlacePlace__c',
  'IsetuIraiDesignDocument__r.DntNo__c',
  'IsetuIraiDesignDocument__r.Mark__c',
  'IsetuIraiDesignDocument__r.KoziOverview__c',
  'IsetuIraiDesignDocument__r.IsetuIraiDesignDocumentNo__c',
  'IsetuIraiDesignDocument__r.DesignCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.DesignTantoName__c',
  // 画面表示以外
  'Id',
  'IsetuIraiDesignDocument__c',
  'LastModifiedDate',
];

const subquery = [{
  'fields': [
    'Id',
    'NWZgsyo__c',
    'NWZgsyo__r.Name',
    'SenroName__c',
    'SenroCode__c',
    'DntNo__c',
    'Dsp_SenroName__c',
    'Dsp_DntNo__c',
    'toLabel(DntCategory__c)',
    'toLabel(DrSideKoziEachDntContents__c)',
    'EachDntKoziContentHosoku__c',
    'EachDntMessage__c',
  ],
  'conditions': {
    'RecordType.DeveloperName': 'IsetuIrai',
  },
  'table': 'ContractDnts__r',
}];

/**
 * 移設依頼詳細情報確認 テンプレート。
 * 画面ID:1413
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    const {orderId} = this.props;
    // 取得条件
    const conditions = {
      Id: orderId,
    };
    await this.props.doGetIsetuIraiOrderSubList(
        conditions, appFields, null, subquery);
    if (!this.props.hasOwnProperty('orderSubList') ||
        this.props.orderSubList.length <= 0 ) {
      this.sysErr();
      return;
    }

    // グリッド初期化
    if (!this.gridRef.current) {
      return;
    }
    const cv = createCollectionView(this.props.contractDntsList,
        false, {}, null);
    this.gridRef.current.getGrid().itemsSource = cv;
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  renderTable() {
    const props = {
      rowHeaderType: 'none',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      isReadOnly: true,
      useStore: false,
    };

    return (
      <>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" width={100}/>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" width={185}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" width={200}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" width={100}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="DrSideKoziEachDntContents__c" header="電力側電柱別工事内容" dataType="String" width={220}/>
          <FlexGridColumnGroup binding="EachDntKoziContentHosoku__c" header="電力側電柱別工事内容\n補足事項" dataType="String" width={220}/>
          <FlexGridColumnGroup binding="EachDntMessage__c" header="電柱別連絡事項" dataType="String" width={200}/>
        </CustomFlexGrid>
      </>
    );
  }

  render() {
    const {
      classes,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}>
        <Grid key="btn1413Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1413-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-2" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderTextField}
                  label="ステータス"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-3" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="都道府県"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-4" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-5" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-6" item xs={12} sm={6} md={3}>
                <Field
                  name="DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-6-2" item xs={12} sm={6} md={3}>
                <Field
                  name="Mark__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="目標"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-7" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-8" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-9" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid key="key1413-10" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

            </Grid>

          </PaperPart>

          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid key="key1413Table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

          <div style={{marginBottom: '20px'}} />

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  doGetIsetuIraiOrderSubList: PropTypes.func,
  orderSubList: PropTypes.array,
  contractDntsList: PropTypes.array,
};

const mapStateToProps = (state) => {
  const initValues = {};
  const contractDntsList = [];
  const orderData = state.attachorder.orderSubList ?
   state.attachorder.orderSubList[0] : undefined;
  if (orderData) {
    if (orderData.OrderStatus__c == '50') {
      initValues['IsetuIraiStatus'] = '予定連絡';
    } else if (orderData.OrderStatus__c == '51') {
      if (orderData.IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c) {
        initValues['IsetuIraiStatus'] = '工事日変更連絡';
      } else {
        initValues['IsetuIraiStatus'] = '工事日確定連絡';
      }
    }
    for (const fieldDataMap of fieldsDataMap) {
      initValues[fieldDataMap.field] = fieldDataMap.ref ?
       orderData[fieldDataMap.ref][fieldDataMap.data] :
        orderData[fieldDataMap.data];
    }
    if (orderData.ContractDnts__r && orderData.ContractDnts__r.records) {
      Object.assign(contractDntsList, orderData.ContractDnts__r.records);
    }
  }
  return {
    initialValues: initValues,
    contractDntsList: contractDntsList,
    orderId: state.attachorder.orderId,
    orderSubList: state.attachorder.orderSubList,
  };
};

const mapDispatchToProps = {
  doGetIsetuIraiOrderSubList: attachorderOperations.doGetIsetuIraiOrderSubList,
  doShowMessage: commonOperations.doShowMessage,
};

const FORM_NAME = 'IsetuIraiDetailInfCheck';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import types from './types';

export const startRequest = () => ({
  type: types.START_REQUEST,
});

export const endRequest = () => ({
  type: types.END_REQUEST,
});

export const openDialog = (dialogInfo) => ({
  type: types.OPEN_DIALOG,
  dialogInfo,
});

export const closeDialog = () => ({
  type: types.CLOSE_DIALOG,
});

export const openModal = (modalInfo) => ({
  type: types.OPEN_MODAL,
  modalInfo,
});

export const closeModal = (modalInfo) => ({
  type: types.CLOSE_MODAL,
});

export const checkAccess = (accessInfo) => ({
  type: types.CHECK_ACCESS,
  accessInfo,
});

export const getParam = (getParam) => ({
  type: types.GET_PARAM,
  getParam,
});

export const setStockUrl = (stockUrl) => ({
  type: types.SET_STOCK_URL,
  stockUrl,
});

// 約款承諾状態
export const setIsTermsAccepted = (isTermsAccepted) => ({
  type: types.SET_IS_TERMS_ACCEPTED,
  isTermsAccepted,
});

// 未確認重要事項確認有無
export const setHasJuyoZiko = (hasJuyoZiko) => ({
  type: types.SET_HAS_JUYO_ZIKO,
  hasJuyoZiko,
});

// 買取保留状態
export const setIsHoldPurchase = (isHoldPurchase) => ({
  type: types.SET_IS_HOLD_PURCHASE,
  isHoldPurchase,
});

// ログイン日時
export const setLoginDate = (loginDate) => ({
  type: types.SET_LOGIN_DATE,
  loginDate,
});

// システム切替日時前フラグ
export const setIsKirikaeMae = (isKirikaeMae) => ({
  type: types.SET_IS_KIRIKAE_MAE,
  isKirikaeMae,
});

export const getAllGeneralPurposeMap = (generalPurposeMap) => ({
  type: types.GET_ALL_GENERAL_PURPOSE_MAP,
  generalPurposeMap,
});

// 汎用マスタ一覧 取得
export const getGeneralPurposeList = (generalPurposeList) => ({
  type: types.GET_GENERAL_PURPOSE_LIST,
  generalPurposeList,
});

// 汎用マスタ 登録
export const registGeneralPurpose = (generalPurpose) => ({
  type: types.REGIST_GENERAL_PURPOSE,
  generalPurpose,
});

// 汎用マスタ 更新
export const updateGeneralPurpose = (generalPurpose) => ({
  type: types.UPDATE_GENERAL_PURPOSE,
  generalPurpose,
});

// 汎用マスタID 設定
export const setGeneralPurposeId = (generalPurposeId) => ({
  type: types.SET_GENERAL_PURPOSE_ID,
  generalPurposeId,
});

// NW事業所名称フィルター 取得
export const getNwzgsyoNamefilter = (nwzgsyoNamefilter) => ({
  type: types.GET_NWZGSYO_NAMEFILTER,
  nwzgsyoNamefilter,
});

// 会社マスタ一覧 取得
export const getAccountList = (accountList) => ({
  type: types.GET_ACCOUNT_LIST,
  accountList,
});

// 会社マスタ 取得
export const retrieveAccount = (account) => ({
  type: types.RETRIEVE_ACCOUNT,
  account,
});

// 会社マスタID 設定
export const setAccountId = (accountId) => ({
  type: types.SET_ACCOUNT_ID,
  accountId,
});

// 会社マスタ一覧 取得（制限なし）
export const getAccountMasterList = (accountMasterList) => ({
  type: types.GET_ACCOUNT_MASTER_LIST,
  accountMasterList,
});

// 会社 更新
export const updateAccount = (accountResult) => ({
  type: types.UPDATE_ACCOUNT,
  accountResult,
});

// 会社 更新
export const updateAccounts = (accountResult) => ({
  type: types.UPDATE_ACCOUNTS,
  accountResult,
});

// 担当者マスタ一覧 取得
export const getContactList = (contactList) => ({
  type: types.GET_CONTACT_LIST,
  contactList,
});

// 担当者マスタ1件 取得
export const getContact = (contact) => ({
  type: types.GET_CONTACT,
  contact,
});

// 担当者マスタID 設定
export const setContactId = (contactId) =>({
  type: types.SET_CONTACT_ID,
  contactId,
});

// 担当者マスタ一覧 取得（ソート）
export const getContactMasterList = (contactMasterList) => ({
  type: types.GET_CONTACT_MASTER_LIST,
  contactMasterList,
});

// 担当者 テンポラリ情報 設定
export const setTempContact = (tempContact)=> ({
  type: types.SET_TEMP_CONTACT,
  tempContact,
});

// 添付ファイルアップロード
export const registAttachedFiles = (attachedFiles) => ({
  type: types.REGIST_ATTACHEDFILES,
  attachedFiles,
});

// 添付ファイル一覧取得
export const getAttachedFiles = (attachedFileList) => ({
  type: types.GET_ATTACHED_FILE_LIST,
  attachedFileList,
});

// 添付ファイル一括削除
export const deleteAttachedFiles = (attachedFileResult) => ({
  type: types.DELETE_ATTACHED_FILE_LIST,
  attachedFileResult,
});

// 設備種別設定
export const setStbType = (stbType) => ({
  type: types.SET_STB_TYPE,
  stbType,
});

// 画面の活性状態設定
export const setReferenceMode = (referenceMode) => ({
  type: types.SET_REFERENCE_MODE,
  referenceMode,
});

// 遷移元画面ID設定
export const setTransitionSrcId = (transitionSrcId) => ({
  type: types.SET_TRANSITION_SRC_ID,
  transitionSrcId,
});

// 呼び出し元パス設定
export const setYbdsPath = (ybdsPath) => ({
  type: types.SET_YBDS_PATH,
  ybdsPath,
});

// 画面警告設定
export const setGmnWarning = (Id, warning) => ({
  type: types.SET_GMN_WARNING,
  Id,
  warning,
});

export const getDntSelectpole = (dntList) => ({
  type: types.GET_DNT_SELECTPOLE,
  dntList,
});

export default {
  startRequest,
  endRequest,
  openDialog,
  closeDialog,
  openModal,
  closeModal,
  checkAccess,
  setIsTermsAccepted,
  setHasJuyoZiko,
  setLoginDate,
  getGeneralPurposeList,
  registGeneralPurpose,
  updateGeneralPurpose,
  setGeneralPurposeId,
  getAccountList,
  retrieveAccount,
  setAccountId,
  getAccountMasterList,
  registAttachedFiles,
  deleteAttachedFiles,
  getContactList,
  getContactMasterList,
  getAttachedFiles,
  setStbType,
  setReferenceMode,
  setTransitionSrcId,
  setYbdsPath,
  setGmnWarning,
  getDntSelectpole,
};


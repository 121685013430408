import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {Divider, Grid} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';

import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createList, createSearchList, changePrefectures} from '../../../../../../common/ComboParts.js';

import {
  downloadAttachedFile, changeDateFormat, getToDate, getAddDate,
  getDataFromGeneralPurposeMap, createTyohyoExcel, execApexRestApi,
  API_CODE, getResponseResult, createTyohyoCsvLimitOver,
} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '240px',
  },
  button: {
    margin: '5px 10px 5px 10px',
  },
});

/** 申込ステータスの完結コード以外 */
const OrderStatusConclusion = ['00', '01', '02', '03', '04', '05', '06', '10', '11', '12', '13', '14', '15', '16', '20', '21', '31', '40', '41', '50', '51'];

/**
 * 移設依頼ステータス
 */
const isetuIraiStatusList = [
  {'Code__c': 2, 'Name': '依頼中', 'Status': ['01', '02', '03']},
  {'Code__c': 3, 'Name': '竣工済', 'Status': ['04']},
];

// 申込、移設依頼設計書テーブル検索時の検索対象のフィールド名
const appFields = [
  'IsetuIraiCarryOutDate__c',
  'DesignCompanySyozoku__c',
  'IsetuIraiNo__c',
  'toLabel(KoziPlacePrefectures__c)',
  'KoziPlaceMunicipalities__c', 'KoziPlacePlace__c',
  'DntNo__c',
  'KoziOverview__c',
  'IsetuCompHopeDateTimeOfDesign__c',
  'KoziScheduleSeason__c',
  'KoziScheduleSeasonHosoku__c',
  'DesignTantoName__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuCompHopeDateTimeOfContact__c',
  'DrIsnKoziDate__c',
  'DrIsnKoziDateChange__c',
  'KoziCompanySyozoku__c',
  'KoziTantoName__c',
  'TyohyoOutputDate__c',
  // 画面表示以外
  'Id', 'IsetuIraiStatus__c', 'IsetuIraiHonsu__c', 'LastModifiedDate',
];

// 申込、移設依頼設計書テーブル検索時のソート条件
const appSortParams = {
  'IsetuIraiCarryOutDate__c': 1,
};

/** 申込、添付ファイル名検索時の検索対象 */
const subquery = [{
  'fields': ['Id'],
  'conditions': {'OrderStatus__c': OrderStatusConclusion},
  'table': 'IsetuDocOrders__r',
},
{
  'fields': [
    'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
    'ContentDocument.LatestPublishedVersion.VersionData',
    'ContentDocument.LatestPublishedVersion.PathOnClient'],
  'conditions': {
    'ContentDocument.LatestPublishedVersion.AttachedFileType__c': ['C03', 'C04'],
  },
  'table': 'ContentDocumentLinks',
},
];

/**
 * 外線工事（抜柱）可能設計書確認
 * 画面ID:1461
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      retButtonDisabled: true,
      buttonDisabled: true,
      dataList: [],
    };
    this.gridRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
  }

  /**
  * 初期処理
  */
  async componentDidMount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setInitItems('1461', []);
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearDesignDocumentTypeList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1461', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 最大件数チェック
   * @return {Boolean} true:ok, false:ng
   */
  listMaxCountCheck = () => {
    const {generalPurposeMap} = this.props;
    let listCount = this.props.hasOwnProperty('designDocumentList') &&
      this.props.designDocumentList ? this.props.designDocumentList.length : 0;
    // 最大件数以上の場合、エラーダイアログ表示
    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
    console.log({maxCount});
    if (maxCount && maxCount > 0 && listCount >= maxCount) {
      this.props.doShowMessage({
        message: 'CE0015',
      });
      return false;
    }
    return true;
  }

  /**
   * 検索条件の生成(検索ボタン、電柱一覧ボタン押下共通)
   * @return {object} 検索条件
   */
  _createConditions = () => {
    let wkData = {};
    if (this.props.values) {
      // 画面入力されたデータのみ検索対象として取得
      wkData = JSON.parse(JSON.stringify(this.props.values));

      // 移設依頼ステータスの設定
      if (wkData.hasOwnProperty('IsetuIraiStatus')) {
        const isetuStatus = isetuIraiStatusList.find(
            (value) => value.Code__c == wkData.IsetuIraiStatus);

        // 移設依頼ステータスによって条件設定
        switch (isetuStatus.Code__c) {
          case 1:// 一時保存
            // 移設依頼ステータス：作成中
            wkData['IsetuIraiStatus__c'] = isetuStatus.Status;
            break;
          case 2:// 依頼中
            // 移設依頼ステータス：移設依頼内容確認済、工事日調整中、工事日確定済
            wkData['IsetuIraiStatus__c'] = isetuStatus.Status;
            break;
          case 3:// 竣工済
            // 移設依頼ステータス：完結
            wkData['IsetuIraiStatus__c'] = isetuStatus.Status;
            break;
          default:
            break;
        }
      }

      // 帳票出力有無
      if (wkData.hasOwnProperty('TyohyoOutputDate')) {
        if (wkData.TyohyoOutputDate === '1') {
          wkData['TyohyoOutputDate__c_NE'] = null;
        } else {
          wkData['TyohyoOutputDate__c_EQ'] = null;
        }
      }
    }
    // レコードタイプ
    wkData['RecordType.DeveloperName'] = 'IsetuIraiDesignDocument';

    // 工事予定時期
    if (wkData.hasOwnProperty('KoziScheduleSeason__c_From')) {
      if (wkData.KoziScheduleSeason__c_From) {
        wkData['KoziScheduleSeason__c_From'] = wkData.KoziScheduleSeason__c_From + '-01';
      }
    }
    if (wkData.hasOwnProperty('KoziScheduleSeason__c_To')) {
      if (wkData.KoziScheduleSeason__c_To) {
        wkData['KoziScheduleSeason__c_To'] = wkData.KoziScheduleSeason__c_To + '-01';
      }
    }

    // 工事中止は表示対象外
    wkData['KoziStop__c'] = false;
    wkData['KoziHold__c'] = false;

    return wkData;
  }

  /**
   * 検索ボタン押下時処理
   * @param {boolean} isFirst 初期表示かどうか
   */
  searchOrder = async (isFirst = false) => {
    try {
      const wkData = this._createConditions();

      // 移設依頼設計書テーブルからデータ取得
      const result = await this.props.doGetDesignDocumentTypeList(
          wkData, appFields, appSortParams, 'getGsKoziDesignDocument', subquery);
      const errCode = result ? result.data ? result.data.body ?
        result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return;
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('designDocumentList') ||
        !this.props.designDocumentList ||
        this.props.designDocumentList.length <= 0) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }

      // 抽出条件に一致するレコード取得
      const wkTargetDataList =
        this.doGetShowList(this.props.designDocumentList);

      const wkDataList = [];
      for (const items of wkTargetDataList) {
        let wkSearchValues = {};
        if (this.props.values) {
          // 画面入力変換
          wkSearchValues = JSON.parse(JSON.stringify(this.props.values));
          // 検索結果から「抜柱工事可否」の検索条件に対応したレコード抽出
          if (wkSearchValues.hasOwnProperty('KenBaTyKoujiPropriety')) {
            if (wkSearchValues.KenBaTyKoujiPropriety === '1') {
              // 画面指定が可の場合、完結のレコード追加
              if (!items.IsetuDocOrders__r ||
                items.IsetuDocOrders__r.records.length == 0) {
                wkDataList.push(items);
              }
            } else if (wkSearchValues.KenBaTyKoujiPropriety === '0') {
              // 画面指定が不可の場合、完結以外のレコード追加
              if (items.IsetuDocOrders__r &&
                items.IsetuDocOrders__r.records.length > 0) {
                wkDataList.push(items);
              }
            }
          } else {
            // 検索条件で抜柱工事可否が未設定の場合
            wkDataList.push(items);
          }
        } else {
          // 検索条件が未設定の場合
          wkDataList.push(items);
        }
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (wkDataList.length <= 0) {
        this.setState({dataList: wkDataList},
            () => {
            // 移設依頼設計書ストアを更新
              this.props.doSetDesignDocumentList(this.state.dataList);
            });

        this.props.doShowMessage({
          message: 'CE0016',
        });
      }

      for (const items of wkDataList) {
        // 移設依頼ステータス
        if (items.IsetuIraiStatus__c) {
          for (const data of isetuIraiStatusList) {
            if (data.Status.includes(items.IsetuIraiStatus__c)) {
              items['IsetuIraiStatus'] = data.Name;
            }
          }
        }

        // 抜柱工事可否
        // 移設依頼設計書に紐づく申込のステータスが完結でかつ、移設依頼実施件数が同じ場合
        if (!items.IsetuDocOrders__r ||
          items.IsetuDocOrders__r.records.length == 0) {
          items['KenBaTyKoujiPropriety'] = '可';
        } else {
          items['KenBaTyKoujiPropriety'] = '不可';
        }

        // 帳票出力有無
        if (items.TyohyoOutputDate__c) {
          items['TyohyoOutputDate'] = '済(' +
            changeDateFormat(items.TyohyoOutputDate__c) + ')';
        } else {
          items['TyohyoOutputDate'] = '未';
        }

        // 添付ファイルのハイパーリンク設定
        if (items.ContentDocumentLinks) {
          for (const data of items.ContentDocumentLinks.records) {
            if (data.ContentDocument.LatestPublishedVersion.AttachedFileType__c === 'C03') {
              // 添付（移設依頼設計書）
              items['AttachedFileTypeC03_Type'] = data.ContentDocument.LatestPublishedVersion.AttachedFileType__c;
              items['AttachedFileTypeC03_Name'] = data.ContentDocument.LatestPublishedVersion.PathOnClient;
              items['AttachedFileTypeC03_Url'] = data.ContentDocument.LatestPublishedVersion.VersionData;
            } else if (data.ContentDocument.LatestPublishedVersion.AttachedFileType__c === 'C04') {
              // 添付（その他）
              items['AttachedFileTypeC04_Type'] = data.ContentDocument.LatestPublishedVersion.AttachedFileType__c;
              items['AttachedFileTypeC04_Name'] = data.ContentDocument.LatestPublishedVersion.PathOnClient;
              items['AttachedFileTypeC04_Url'] = data.ContentDocument.LatestPublishedVersion.VersionData;
            }
          }
        }
      }

      this.setState({dataList: wkDataList},
          () => {
          // 移設依頼設計書ストアを更新
            this.props.doSetDesignDocumentList(this.state.dataList);
          });

      // FlexGridに検索結果設定
      if (this.gridRef && this.gridRef.current) {
        if (!(isFirst == true)) {
          this.gridRef.current.saveSelectedItems('1461', 'Id', true);
        }
        this.gridRef.current.setInitItems('1461', this.state.dataList);
      }
      // 最大件数チェック
      this.listMaxCountCheck();
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let disableButton = true;
      if (this.gridRef && this.gridRef.current) {
        // 一覧表示部に１件以上表示されているか判定
        disableButton = (this.gridRef.current.selectedItemCount() <= 0);
      }
      this.setState({buttonDisabled: disableButton});

      let bRet = true;
      // 抜柱工事可否が可となっている移設依頼設計書か判定
      if (!disableButton && this.gridRef && this.gridRef.current &&
        this.gridRef.current.selectedItems[0].dataItem.hasOwnProperty('KenBaTyKoujiPropriety')) {
        if (this.gridRef.current.selectedItems[0].dataItem.KenBaTyKoujiPropriety == '可') {
          bRet = false;
        }
      }
      this.setState({retButtonDisabled: bRet});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 帳票出力ボタン押下時
   */
  doTyohyoOutput = async () => {
    const {userInfo} = this.props;

    try {
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (selectedItems.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['編集対象'],
          },
        });
        return;
      }

      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }

      // 帳票出力の呼出（F0009_移設送付先兼完了日一覧表）
      const ret = await createTyohyoExcel(
          userInfo, 'IsetuSendAndCompletion',
          {'IsetuIraiNo__c': selectedItems[0].dataItem.IsetuIraiNo__c},
          '移設送付先兼完了日一覧表.xlsx');
      if (!ret) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['移設送付先兼完了日一覧表', 'ダウンロード'],
          },
        });
      }

      const items = selectedItems[0].dataItem;
      // 帳票出力日が入ってなければ更新
      if (!items.TyohyoOutputDate__c) {
        const body = {
          'record': [
            {
              'conditions': {
                'Id': items.Id,
                'LastModifiedDate': items.LastModifiedDate,
              },
              'designDocument': {
                'Id': items.Id,
                'TyohyoOutputDate__c': getToDate(),
              },
            }],
          'stage': 'GS_KOZI_POSSIBLE_CHECK_UPDATE',
        };

        const result = await execApexRestApi(
            userInfo, 'ApiEnergia/gsKoziPossibleCheckUpdate', body);
        const resResult = getResponseResult(result, ['選択項目', '更新']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        }
        this.searchOrder(true);
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 電柱一覧出力ボタン押下時
   */
  doCsvOutput = async () => {
    // 検索条件部の内容から条件生成
    const conditions = this._createConditions();

    // 電柱工事日確認CSV(C0028)
    const size = await createTyohyoCsvLimitOver('confirmDntKoji', conditions, '電柱工事日確認.csv');

    // 例外発生、CSV作成失敗の場合はエラー
    if (size === false || size === -1) {
      this.sysErr();
      return;
    }

    const {doShowMessage} = this.props;

    // サイズが0の場合は検索結果なしのメッセージ
    if (size === 0) {
      doShowMessage({message: 'CE0016'});
      return;
    }

    // サイズが最大の場合は最大件数のメッセージ(ダウンロードは行う)
    if (this._isOverMaxSize(size)) {
      doShowMessage({message: 'CE0015'});
      return;
    }
  }

  /**
   * 最大件数超過チェック
   * @param {number} size
   * @return {boolean} 最大件数を超過する場合true、しない場合false
   */
  _isOverMaxSize = (size) => {
    const {generalPurposeMap} = this.props;

    const maxCount = getDataFromGeneralPurposeMap(
        generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;

    return size >= maxCount;
  }

  /**
   * 移設依頼表示ボタン押下時
   */
  doIsetuIraiShow = async () => {
    try {
      // チェックボックスのチェック数が1ではない場合エラー
      const selectedItems = this.gridRef.current.selectedItems;
      if (selectedItems.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['編集対象'],
          },
        });
        return;
      }

      if (selectedItems.length != 1) {
        this.props.doShowMessage({
          message: 'CE0022',
        });
        return;
      }

      this.props.doSetDesignDocumentId(selectedItems[0].dataItem.Id);
      this.props.history.push({pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/BasicInput'});
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 添付ファイルダウンロード
   *
   * @param {String} fileType
   * @param {String} fileName
   * @param {String} url
  */
  doDownloadAttachedFile = async (fileType, fileName, url) => {
    const {userInfo} = this.props;
    try {
      downloadAttachedFile(
          userInfo, url, fileType, fileName);
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
   * 検索対象レコード抽出
   * 現在日付から、帳票出力日が２か月前または、
   * 電力移線工事日_変更に値があれば、電力移線工事日_変更の３か月前
   * 電力移線工事日_変更に値がなければ、電力移線工事日の３か月前のレコードを抽出
   *
   * @param {Array} list 抽出対象
   * @return {Array} 抽出したリスト
  */
  doGetShowList = (list) => {
    const {generalPurposeMap} = this.props;

    // 汎用マスタから一覧表示期間を取得
    const listDisplayPeriod1 =
      getDataFromGeneralPurposeMap(generalPurposeMap, 'ListDisplayPeriod1', 1);
    const listDisplayPeriod2 =
      getDataFromGeneralPurposeMap(generalPurposeMap, 'ListDisplayPeriod2', 1);
    const twoMonthsAgo = getAddDate(-(listDisplayPeriod1.ValueNum1__c));
    const threeMonthsAgo = getAddDate(-(listDisplayPeriod2.ValueNum1__c));

    // 一覧表示期間内のものだけ抽出
    const wkDataList = [];
    for (const items of list) {
      if (items.TyohyoOutputDate__c >= twoMonthsAgo) {
        wkDataList.push(items);
        continue;
      }
      if (items.DrIsnKoziDateChange__c) {
        if (items.DrIsnKoziDateChange__c >= threeMonthsAgo) {
          wkDataList.push(items);
        }
      } else {
        if (items.DrIsnKoziDate__c >= threeMonthsAgo) {
          wkDataList.push(items);
        }
      }
    }
    return wkDataList;
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    // 工事予定時期
    if (e.getColumn().binding === 'KoziScheduleSeason__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        const ret = changeDateFormat(lb.collectionView.items[index].value, 'YYYY年MM月');
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      isReadOnly: true,
      exceptFilters: ['AttachedIsetuIraiDesignDocument', 'AttachedOther'],
      checkedFunction: this.checkStateUpdate,
      filterChanging: this.filterChangingHandler,
    };

    return (
      // 一覧表示部の表示設定
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='IsetuIraiStatus' header='移設依頼ステータス' dataType='String' width={165}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KenBaTyKoujiPropriety' header='抜柱工事可否' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='TyohyoOutputDate' header='帳票出力' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiCarryOutDate__c' header='発信年月日' dataType='Date' width={110}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(context.item.
                    IsetuIraiCarryOutDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DesignCompanySyozoku__c' header='設計会社／所属' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiNo__c' header='一連番号' dataType='String' width={100}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziPlacePrefectures__c' header='都道府県' dataType='String' width={100}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziPlaceMunicipalities__c' header='市区町村' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziPlacePlace__c' header='場所' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DntNo__c' header='電柱番号' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziOverview__c' header='工事概要' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='AttachedIsetuIraiDesignDocument' header='添付（移設依頼書）' dataType='String' width={165}
            cellTemplate={CellMaker.makeLink({
              text: '<b>${item.AttachedFileTypeC03_Name}</b>',
              click: (e, ctx) => this.doDownloadAttachedFile(
                  ctx.item.AttachedFileTypeC03_Type,
                  ctx.item.AttachedFileTypeC03_Name,
                  ctx.item.AttachedFileTypeC03_Url,
              ),
            })}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='AttachedOther' header='添付（その他）' dataType='String'
            cellTemplate={CellMaker.makeLink({
              text: '<b>${item.AttachedFileTypeC04_Name}</b>',
              click: (e, ctx) => this.doDownloadAttachedFile(
                  ctx.item.AttachedFileTypeC04_Type,
                  ctx.item.AttachedFileTypeC04_Name,
                  ctx.item.AttachedFileTypeC04_Url,
              ),
            })}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuCompHopeDateTimeOfDesign__c' header='移設完了希望日 （設計時）' dataType='Date' width={220}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.IsetuCompHopeDateTimeOfDesign__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziScheduleSeason__c' header='工事予定時期' dataType='Date'>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.KoziScheduleSeason__c, 'YYYY年MM月');
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziScheduleSeasonHosoku__c' header='工事予定時期補足' dataType='String' width={165}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiDesignDocumentNo__c' header='設計書番号' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuIraiHonsu__c' header='工事対象本数' dataType='Number'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DesignTantoName__c' header='設計担当者名' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='IsetuCompHopeDateTimeOfContact__c' header='移設完了希望日（工事日連絡時）' dataType='Date' width={250}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.IsetuCompHopeDateTimeOfContact__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrIsnKoziDate__c' header='電力移線工事日' dataType='Date'>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.DrIsnKoziDate__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrIsnKoziDateChange__c' header='電力移線工事日（変更）' dataType='Date' width={200}>
            <FlexGridCellTemplate cellType="Cell"
              template={(context) => {
                return changeDateFormat(
                    context.item.DrIsnKoziDateChange__c);
              }}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziCompanySyozoku__c' header='工事会社／所属' dataType='String'></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KoziTantoName__c' header='工事担当者名' dataType='String'></FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }


  render() {
    const {classes, reset, handleSubmit, generalPurposeMap} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<GetAppIcon />}
            onClick={() => {
              this.doTyohyoOutput();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.retButtonDisabled}
          >
            <span>帳票出力</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<GetAppIcon />}
            onClick={() => {
              this.doCsvOutput();
            }}
            variant="contained"
            size="large"
            type="button"
          >
            <span>電柱一覧出力</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <PositiveButton
            startIcon={<EditIcon />}
            onClick={handleSubmit(this.doIsetuIraiShow)}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.buttonDisabled}
          >
            <span>移設依頼表示</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn4" item>
          <BackButton props={this.props}
            onClick={this.doClearList} />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiStatus"
                  className={classes.fields}
                  component={renderSelect}
                  label="移設依頼ステータス"
                  fullWidth
                  validate={[
                  ]}
                >
                  {createSearchList(isetuIraiStatusList, 'Code__c', 'Name')}
                </Field>
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="KenBaTyKoujiPropriety"
                  className={classes.fields}
                  component={renderSelect}
                  label="抜柱工事可否"
                  fullWidth
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.ProprietyCategory,
                        'Code__c', 'ValueString1__c', {'Code__c': ['1', '0']})}
                </Field>
              </Grid>

              <Grid key="key1-3" item xs={12} sm={6} md={3}>
                <Field
                  name="TyohyoOutputDate"
                  className={classes.fields}
                  component={renderSelect}
                  label="帳票出力有無"
                  fullWidth
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.UmuCategory,
                        'Code__c', 'ValueString1__c')}
                </Field>
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuIraiCarryOutDate__c_From"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_From"
                    label="移設依頼発信年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuIraiCarryOutDate__c_To"
                    accessibilitylabel="IsetuIraiCarryOutDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-6" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(8),
                  ]}
                />
              </Grid>

              <Grid key="key1-7" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignTantoName__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計担当者名"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(20),
                  ]}
                />
              </Grid>

              <Grid key="key1-8" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiDesignDocumentNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計書番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-9" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePrefectures__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="都道府県"
                  fullWidth
                  validate={[
                  ]}
                  onChange={(event) => {
                    changePrefectures(event, this);
                  }}
                >
                  {generalPurposeMap != null &&
                    createList(generalPurposeMap.PrefCode)
                  }
                </Field>
              </Grid>

              <Grid key="key1-10" item xs={12} sm={3}>
                <Field
                  name="KoziPlaceMunicipalities__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="市区町村"
                  fullWidth
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                >
                </Field>
              </Grid>

              <Grid key="key1-11" item xs={12} sm={3}>
                <Field
                  name="KoziPlacePlace__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="場所"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="key1-12" item xs={12} sm={6} md={3}>
                <Field
                  name="DntNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電柱番号"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.alphaNumeric,
                    validateRule.maxLength(25),
                  ]}
                />
              </Grid>

              <Grid key="key1-13" item xs={12} sm={6} md={3}>
                <Field
                  name="KoziOverview__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="工事概要"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(100),
                  ]}
                />
              </Grid>

              <Grid key="key1-14" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_From"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_From"
                    label="移設完了希望日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="IsetuCompHopeDateTimeOfDesign__c_To"
                    accessibilitylabel="IsetuCompHopeDateTimeOfDesign__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-15" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="KoziScheduleSeason__c_From"
                    accessibilitylabel="KoziScheduleSeason__c_From"
                    label="工事予定時期"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="KoziScheduleSeason__c_To"
                    accessibilitylabel="KoziScheduleSeason__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="month"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.month,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-16" item xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <Field
                    name="DrIsnKoziDate__c_From"
                    accessibilitylabel="DrIsnKoziDate__c_From"
                    label="電力移線工事日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />

                  <span className={classes.wave}>～</span>

                  <Field
                    name="DrIsnKoziDate__c_To"
                    accessibilitylabel="DrIsnKoziDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>

              <Grid key="key1-17" item xs={6} md={6}>
                <NegativeButton
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>

                <PositiveButton
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>

            </Grid>

          </PaperPart>

          <div style={{marginBottom: '20px'}} />

          <Divider variant="middle" />

          <div style={{marginBottom: '20px'}} />
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  userInfo: PropTypes.object,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  values: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  designDocumentList: PropTypes.array,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetDesignDocumentTypeList: PropTypes.func,
  doClearDesignDocumentTypeList: PropTypes.func,
  doSetDesignDocumentId: PropTypes.func,
  doSetDesignDocumentList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  designDocumentList: state.isetuIrai.designDocumentList,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetDesignDocumentTypeList: isetuIraiOperations.doGetDesignDocumentTypeList,
  doClearDesignDocumentTypeList:
    isetuIraiOperations.doClearDesignDocumentTypeList,
  doSetDesignDocumentId: isetuIraiOperations.doSetDesignDocumentId,
  doSetDesignDocumentList: isetuIraiOperations.doSetDesignDocumentList,
};

const FORM_NAME = 'GsKoziPossibleCheckDesignDocumentCofirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

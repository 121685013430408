import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

  },
  paper: {
    backgroundColor: '#fefefe',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
  paperContext: {
    // maxHeight: 400,
    // overflow: 'auto',
    padding: '0px 15px 0px 15px',
  },
}));

export default function PaperPart({children, visible=true, ...props}) {
  const classes = useStyles();
  if (visible) {
    return (
      <Paper
        className={classes.paper}
        elevation={3}
        {...props}>
        <div className={clsx(classes.paperContext)}>
          {children}
        </div>
      </Paper>
    );
  } else {
    return <div />;
  }
};

PaperPart.propTypes = {
  children: PropTypes.array,
  visible: PropTypes.bool,
};

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import * as wjgGrid from '@grapecity/wijmo.grid';
import * as wijmo from '@grapecity/wijmo';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {Popup, InputNumber, ComboBox, InputDate} from '@grapecity/wijmo.react.input';
import * as wijmoInput from '@grapecity/wijmo.input';
import {DataType} from '@grapecity/wijmo';

import {getCodeFromGeneralPurposeMap, getComboList, getComboName,
  changeDateFormatter, changeDateFormat, getErrorCode, API_CODE,
  changeDateUtc, checkPhotoExists, getResponseResult, createDataMapByComboList} from '../../../../../../common/common.js';
import {correlationCheckSyunko} from '../../../../../../common/correlationCheck.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {AttachedPicturesSenSyunko, ClosestDrStbDntSyunko, TsnHksnSyunko,
  DrHksnHouseSideRikakKkhSynk} from '../../../../../molecules/HelpComponent.js';
import CommonTheme from '../../../../../../Theme.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import CustomCard from '../../../../../molecules/CustomCard.js';
import {renderCheckbox} from '../../../../../atoms/CustomPart.js';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import {orderDntFields, orderBasicFields} from '../../../../../../common/SFFields';
import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import Required from '../../../../../atoms/RequiredMark.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import Upload from '../../../../../organisms/UploadCustom';
import {checkFileNameLength, checkFileSizeZero,
  checkSameName} from '../../../../../../common/fileOperations.js';
import withMediaQuery from '../../../../../atoms/withMediaQuery.js';
import HelpIcon from '@material-ui/icons/Help';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

const orderInfo = [
  {key: 'OrderNo__c', name: '本申込番号', value: ''},
  {key: 'OrderDate__c', name: '本申込年月日', value: '', format: 'date'},
  {key: 'OrderKyogaZgsya__r', name: '申込共架事業者', value: ''},
  {key: 'StbTypeName', name: '設備種別', value: ''},
  {key: 'KyogaTypeName', name: '共架種別', value: ''},
];
const koziInfo = [
  {key: 'KoziTitle__c', name: '工事件名', value: ''},
  {key: 'KoziPlaceMainDntNo_SenroName__c', name: '工事場所／線路名', value: ''},
  {key: 'Dsp_KoziPlaceMainDntNo__c', name: '工事場所／代表電柱番号', value: ''},
  {key: 'KoziPlacePrefecturesName', name: '工事場所／都道府県', value: ''},
  {key: 'KoziPlaceMunicipalities__c', name: '工事場所／市区町村', value: ''},
  {key: 'KoziPlacePlace__c', name: '工事場所／場所', value: ''},
];

const updateParams = {
  Id: null,
  AttachSpotHeightSyunko__c: null, // 取付点高さ_竣工
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakOrder__c: null, //  最接近電力設備との離隔/離隔距離（m）（申込時）
  ClosestDrStbRikakSyunko__c: null, // 最接近電力設備との離隔/離隔距離（m）（施工後）
  DrHksnKiknCrossRikakSynk__c: null, // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）
  DrHksnHouseSideRikakKkhSynk__c: null, // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）（施工後）
  SyunkoDate__c: null, // 竣工日
  SyunkoReportAcceptedDate__c: null, // 竣工届受理日
  KoziUndo__c: null, // 工事取止
  UpsyaUserId__c: null, // 更新ユーザID
};

/**
 * 竣工届申込登録（契約電柱）
 * 画面ID:1027
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      editItem: null,
      warningMessages: [],
      refFiles: [],
      syunkoFiles: [],
      syunkoDelFiles: [],
    };
    this.popup = null;
    this.gridRef = React.createRef();
    this.drHksnHouseSideRikakKkhSynkRef = React.createRef();
    this.koziUndoRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
    this.cellStyleFunction = this.cellStyleFunction.bind(this);
    this.editPopup = this.editPopup.bind(this);
    this.dateEditor = new wijmoInput.InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });

    // 編集ダイアログ用
    this.popupItems = {
      AttachSpotHeightSyunko__c: null, // 取付点高さ（m）（施工後）
      ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
      ClosestDrStbRikakSyunko__c: null, // 最接近電力設備との離隔/離隔距離（m）（施工後）
      TsnHksnUmu__c: null, // 通信引込線/通信引込線の有無 編集不可
      DrHksnKiknCrossRikakSynk__c: null, // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）
      DrHksnHouseSideRikakKkhSynk__c: false, // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）（施工後）
      SyunkoDate__c: null, // 竣工日
      KoziUndo__c: false, // 工事取止
    };

    // 表示データ初期化
    for (const row of orderInfo) {
      row['value'] = '';
    }
    for (const row of koziInfo) {
      row['value'] = '';
    }

    // 添付写真
    this.headerAttachPicturesTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <AttachedPicturesSenSyunko/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 最接近電力設備との離隔
    this.headerClosestDrStbDntTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <ClosestDrStbDntSyunko/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 通信引込線
    this.headerTsnHksnTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <TsnHksnSyunko/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // ②電力引込線との離隔確保（家屋側支接点付近）
    this.headerDrHksnHouseSideRikakKkhTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <DrHksnHouseSideRikakKkhSynk/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      return this.checkGrid(item, prop, parsing);
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderContractDnt();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1027_sen', 'Id');
    }
  }

  /**
  * システムエラー
  */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 竣工写真設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setSyunkoFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A23';
        _files.push(file);
      }
      this.setState({
        syunkoFiles: _files,
        syunkoDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusKoziStartOk =
    getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
    const attachedFileTypeOrder = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);
    const attachedFileTypeSyunko = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA23', 3);

    // 申込IDに紐づく申込電柱を取得する
    const orderConditions = {
      'Id': orderId,
      'RecordType.DeveloperName': 'MainOrder',
    };
    const dntConditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'MainOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response =
      await this.props.doGetSyunkoOrderAndContractDnt(
          orderConditions, orderBasicFields,
          dntConditions, orderDntFields, appSortParams, true);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    let _hasError = false;
    if (_warningMessages.length > 0) {
      _hasError = true;
    }

    // グリッド初期化
    let _contractDntList = [];
    for (const contractDnt of this.props.contractDntList) {
      contractDnt.SyunkoDate__c = changeDateUtc(contractDnt.SyunkoDate__c);
      _contractDntList.push(contractDnt);
    }
    this.gridRef.current.setScreenState('1027_sen');
    const cv = createCollectionView(_contractDntList,
        true, {}, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 添付ファイルを画面に表示する
    let _refFiles = [];
    let _syunkoFiles = [];
    if (this.props.contentDocuments &&
      this.props.contentDocuments.length > 0) {
      _refFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeOrder);
      _syunkoFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeSyunko);
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
      orderStatusKoziStartOk ? false : true), // 竣工待ち/工事開始可のみ編集可
      hasError: _hasError,
      isModified: false,
      warningMessages: _warningMessages,
      refFiles: _refFiles,
      syunkoFiles: _syunkoFiles,
      syunkoDelFiles: [],
    });
    await this.props.doSetGmnWarning('1027_sen', _hasError);
    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
          data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'Id') ?
          data.order.Id : null;
      lastModifiedDate =
              data && Object.hasOwnProperty.call(data, 'order') &&
              Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
              data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusKoziStartOk =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
    if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusKoziStartOk) {
      // 竣工待ち／工事開始可のときだけ入力チェックする
      return;
    }
    let isKoziAri = false;
    for (const contractDnt of this.props.contractDntList) {
      // 工事取止の場合、必須チェックしない
      if (contractDnt.KoziUndo__c != true) {
        isKoziAri = true;
        // 必須チェック
        this.isRequiredItem(contractDnt, warningMessages);
        // 添付写真
        const photo = this.props.photoExists[contractDnt.Id];
        checkPhotoExists(photo, contractDnt, warningMessages);
      }
      // データ範囲チェック
      this.isWithinRange(contractDnt, warningMessages);
      // 未来日チェック
      // 竣工日
      if (contractDnt.SyunkoDate__c) {
        if (validateRule.futureDate(changeDateFormat(contractDnt.SyunkoDate__c, 'YYYY-MM-DD'))) {
          warningMessages.push({
            id: 'CE0136',
            values: ['竣工日', contractDnt.SerialNumber__c],
          });
        }
      }
    }
    // 相関チェック
    const correlationMessages =
      correlationCheckSyunko(this.props.order, this.props.contractDntList);
    for (const message of correlationMessages) {
      warningMessages.push(message);
    }
    // 添付ファイルの必須チェックを行う。
    // 全て工事取止する場合、必須チェックしない
    if (isKoziAri == true) {
      const attachedFiles = this.props.contentDocuments != null ?
        this.props.contentDocuments : [];
      let contents = attachedFiles.filter((data)=>data.AttachedFileType__c == 'A23');
      if (contents.length == 0) {
        warningMessages.push(
            {id: 'CE0018',
              values: ['装柱図（施工後）']},
        );
      }
    }
  }

  /**
   * 必須チェック
   * @param {object} contractDnt
   * @param {array} warningMessages
   */
  isRequiredItem = (contractDnt, warningMessages) => {
    // 取付点高さ（m）
    if (contractDnt.AttachSpotHeightSyunko__c == undefined ||
      contractDnt.AttachSpotHeightSyunko__c == null) {
      warningMessages.push({
        id: 'CE0017',
        values: ['取付点高さ（m）（施工後）(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 最接近電力設備との離隔/設備名称
    if (!contractDnt.ClosestDrStbName__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['最接近電力設備との離隔/設備名称(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 最接近電力設備との離隔/離隔距離（m）
    if (contractDnt.ClosestDrStbRikakSyunko__c == undefined ||
      contractDnt.ClosestDrStbRikakSyunko__c == null) {
      warningMessages.push({
        id: 'CE0017',
        values: ['最接近電力設備との離隔/離隔距離（m）（施工後）(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
    // 通信引込線が有の場合
    if (contractDnt.TsnHksnUmu__c == '1') {
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
      if (contractDnt.DrHksnKiknCrossRikakSynk__c == undefined ||
        contractDnt.DrHksnKiknCrossRikakSynk__c == null) {
        warningMessages.push({
          id: 'CE0017',
          values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
      if (contractDnt.DrHksnHouseSideRikakKkhSynk__c !== true) {
        warningMessages.push({
          id: 'CE0070',
          values: ['通信引込線/②電力引込線との離隔確保（家屋側支接点付近）（施工後）', contractDnt.SerialNumber__c],
        });
      }
    }
    // 竣工日
    if (!contractDnt.SyunkoDate__c) {
      warningMessages.push({
        id: 'CE0017',
        values: ['竣工日(' + contractDnt.SerialNumber__c + '行目)'],
      });
    }
  }

  /**
   * データ範囲チェック
   * @param {object} contractDnt
   * @param {array} warningMessages
   */
  isWithinRange = (contractDnt, warningMessages) => {
    const minHeightSen = contractDnt.KoziUndo__c != true ? 2.5 : 0.0;
    const minRikakuSen = contractDnt.KoziUndo__c != true ? 0.01 : 0.00;
    // 取付点高さ（m）（施工後）
    if (contractDnt.AttachSpotHeightSyunko__c != null &&
        (contractDnt.AttachSpotHeightSyunko__c < minHeightSen ||
        contractDnt.AttachSpotHeightSyunko__c > 99.9)) {
      warningMessages.push({
        id: 'CE0026',
        values: ['取付点高さ（m）（施工後）(' + contractDnt.SerialNumber__c + '行目)', minHeightSen + '～99.9'],
      });
    }
    // 最接近電力設備との離隔/離隔距離（m）（施工後）
    if (contractDnt.ClosestDrStbRikakSyunko__c != null &&
      (contractDnt.ClosestDrStbRikakSyunko__c < minRikakuSen ||
      contractDnt.ClosestDrStbRikakSyunko__c > 9.99)) {
      warningMessages.push({
        id: 'CE0026',
        values: ['最接近電力設備との離隔/離隔距離（m）（施工後）(' + contractDnt.SerialNumber__c + '行目)', minRikakuSen + '～9.99'],
      });
    }
    // 通信引込線有の場合
    if (contractDnt.TsnHksnUmu__c == '1') {
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）
      if (contractDnt.DrHksnKiknCrossRikakSynk__c != null &&
          (contractDnt.DrHksnKiknCrossRikakSynk__c < minRikakuSen ||
          contractDnt.DrHksnKiknCrossRikakSynk__c > 9.99)) {
        warningMessages.push({
          id: 'CE0026',
          values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）(' + contractDnt.SerialNumber__c + '行目)', minRikakuSen + '～9.99'],
        });
      }
    }
  }

  /**
   * グリッド入力チェック
   * @param {object} item
   * @param {object} prop
   * @param {object} parsing
   * @return {string} チェック結果
   */
  checkGrid = (item, prop, parsing) => {
    if (item['KoziUndo__c'] != true) {
      // 必須チェック
      // 取付点高さ（m）（施工後）,最接近電力設備との離隔/設備名称,
      // 最接近電力設備との離隔/離隔距離（m）（施工後）,竣工日
      if (['AttachSpotHeightSyunko__c', 'SyunkoDate__c',
        'ClosestDrStbName__c', 'ClosestDrStbRikakSyunko__c'].includes(prop)) {
      // CE0017
        let error = validateRule.required4(
            item[prop]);
        if (error) {
          return error;
        }
      }
      if (item.TsnHksnUmu__c == '1') {
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分） 通信引込線の有無が有の場合、必須。
        if (['DrHksnKiknCrossRikakSynk__c'].includes(prop)) {
        // CE0017
          let error = validateRule.required4(
              item[prop]);
          if (error) {
            return error;
          }
        }
        // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近） 通信引込線の有無が有の場合、必須。
        if (prop == 'DrHksnHouseSideRikakKkhSynk__c') {
          if (item[prop] !== true) {
          // CE0070
            return '通信引込線の家屋側支持点付近における離隔確保にチェックがありません。';
          }
        }
      }
    }
    // データ範囲チェック
    const minHeight = item['KoziUndo__c'] != true ? 2.5 : 0.0;
    const minRikaku = item['KoziUndo__c'] != true ? 0.01 : 0.00;
    // 取付点高さ（m）（施工後）
    if (['AttachSpotHeightSyunko__c'].includes(prop)) {
      // CE0026
      if (item[prop] < minHeight || item[prop] > 99.9) {
        return minHeight == 2.5 ?
          '2.5～99.9の範囲内で入力してください' :
          '0.0～99.9の範囲内で入力してください';
      }
    }
    // 最接近電力設備との離隔/離隔距離（m）（施工後）
    if (['ClosestDrStbRikakSyunko__c'].includes(prop)) {
      // CE0026
      if (item[prop] < minRikaku || item[prop] > 9.99) {
        return minRikaku == 0.01 ?
          '0.01～9.99の範囲内で入力してください':
          '0.00～9.99の範囲内で入力してください';
      }
    }
    if (item.TsnHksnUmu__c == '1') {
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
      if (['DrHksnKiknCrossRikakSynk__c'].includes(prop)) {
        // CE0026
        if (item[prop] < minRikaku || item[prop] > 9.99) {
          return minRikaku == 0.01 ?
            '0.01～9.99の範囲内で入力してください':
            '0.00～9.99の範囲内で入力してください';
        }
      }
    }
    // 未来日チェック
    // 竣工日
    if (prop == 'SyunkoDate__c') {
      // CE0136
      let error = validateRule.futureDate(
          changeDateFormatter(item[prop], 'YYYY-MM-DD'));
      if (error) {
        return error;
      }
    }
    return null;
  }

  /**
   * cellStyleFunctionハンドラ
   * @param {object} s
   * @param {object} e
   * @param {object} grid
   */
  cellStyleFunction = (s, e, grid) => {
    if (s.cells === e.panel &&
      e.panel.cellType == wjgGrid.CellType.Cell) {
      // 竣工結果未確認の行をグレーアウトする
      // グリッドをスクロールすると、スタイルを再利用してしまう。wijmoの制限事項。
      // 竣工結果以外のセルのスタイルを変更しないように、
      // セルのスタイルをリセットしてから変更対象のセルを処理する。
      e.cell.style.backgroundColor = '';
      const col = s.columns[e.col];
      if ((col.binding == 'SyunkoReportAcceptedDate__c' || col.binding == 'ResubmitNecessity__c' ||
          col.binding == 'ResubmitReason__c') &&
          (!grid.editRange || !grid.editRange.contains(e.row, e.col))) {
        const acceptDateValue = grid.getCellData(e.row, 'SyunkoReportAcceptedDate__c');
        const rereportNeedOrNotValue = grid.getCellData(e.row, 'ResubmitNecessity__c');
        const rereportReasonValue = grid.getCellData(e.row, 'ResubmitReason__c');
        if (!acceptDateValue && !rereportNeedOrNotValue &&
          !rereportReasonValue) {
          e.cell.style.backgroundColor = 'gray';
        }
      }
      const data = s.rows[e.row].dataItem;
      // 工事取止チェックがONかつ申込ステータスが竣工検査中または完結の場合、
      // 工事取止した行を編集不可にしてグレーアウトする。
      if (data.KoziUndo__c === true && this.state.isDisabled) {
        s.rows[e.row].cssClass = 'gray';
      }
      // チェックボックスにラベルをつける
      if ((col.binding === 'DrHksnHouseSideRikakKkh__c' ||
          col.binding === 'DrHksnHouseSideRikakKkhSynk__c') && s.rows[e.row]) {
        let text = document.createTextNode('良');
        let checkbox = null;
        if (e.cell.firstChild) {
          checkbox = e.cell.firstChild.firstChild;
        }
        if (checkbox != null && checkbox.parentNode != null) {
          checkbox.parentNode.insertBefore(text, checkbox);
        }
      }
      // 通信引込線の1～2
      if ((col.binding == 'DrHksnKiknCrossRikakSynk__c' ||
            col.binding == 'DrHksnHouseSideRikakKkhSynk__c') && s.rows[e.row]) {
        // 通信引込線の有無が有の場合、編集可。無の場合、編集不可。
        if (data.TsnHksnUmu__c != '1') {
          // 工事取止かつ編集不可状態のときは以下のスタイル不要。
          if (data.KoziUndo__c !== true || !this.state.isDisabled) {
            wijmo.addClass(e.cell, 'wj-state-disabled');
            wijmo.addClass(e.cell, 'uneditableCell');
          }
        } else {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'uneditableCell');
        }
      }
      // 工事取止
      if (col.binding == 'KoziUndo__c' && s.rows[e.row] &&
        !this.state.isDisabled) {
        // 竣工確認結果にデータが入っている場合は編集不可
        if (data.SyunkoReportAcceptedDate__c ||
          data.ResubmitNecessity__c ||
          data.ResubmitReason__c) {
          // 工事取止かつ編集不可状態のときは以下のスタイル不要。
          if (data.KoziUndo__c !== true || !this.state.isDisabled) {
            wijmo.addClass(e.cell, 'wj-state-disabled');
            wijmo.addClass(e.cell, 'uneditableCell');
          }
        } else {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'uneditableCell');
        }
      }
    }
  };

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c' ||
          e.getColumn().binding === 'IskkCategory__c'||
          e.getColumn().binding === 'AttachType__c' ||
          e.getColumn().binding === 'ClosestDrStbName__c' ||
          e.getColumn().binding === 'NWKoziHopeKoziContents__c' ||
          e.getColumn().binding === 'TsnHksnUmu__c' ||
          e.getColumn().binding === 'ResubmitNecessity__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分
      if (e.getColumn().binding === 'IskkCategory__c') {
        categoryName = 'IskkCategory'; // 一束化
      } else if (e.getColumn().binding === 'AttachType__c') {
        categoryName = 'AttachType'; // 取付種別
      } else if (e.getColumn().binding === 'ClosestDrStbName__c') {
        categoryName = 'ClosestDrStbStbName'; // 最接近電力設備との離隔/設備名称
      } else if (e.getColumn().binding === 'TsnHksnUmu__c') {
        categoryName = 'UmuCategory'; // 通信引込線/通信引込線の有無
      } else if (e.getColumn().binding === 'NWKoziHopeKoziContents__c') {
        categoryName = 'DrSideKoziKinds'; // 中国電力NWへの工事希望/工事内容
      } else if (e.getColumn().binding === 'ResubmitNecessity__c') {
        categoryName = 'NecessityCategory'; // 再提出要否
      }

      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'DrHksnHouseSideRikakKkh__c' ||
        e.getColumn().binding === 'DrHksnHouseSideRikakKkhSynk__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        let result = '(なし)';
        if (lb.collectionView.items[index].value === true) {
          result = '良';
        }
        return result;
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'KoziUndo__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        let result = '－';
        if (lb.collectionView.items[index].value === true) {
          result = '取止';
        }
        return result;
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ 取付点高さ 施工後
   * @param {*} ctl
   */
  initializeEditedAttachSpotHeightSyunko(ctl) {
    this.popupItems['AttachSpotHeightSyunko__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/設備名称
   * @param {*} ctl
   */
  initializeEditedClosestDrStbName(ctl) {
    this.popupItems['ClosestDrStbName__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/離隔距離(m)/施工後
   * @param {*} ctl
   */
  initializeEditedClosestDrStbRikakSyunko(ctl) {
    this.popupItems['ClosestDrStbRikakSyunko__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 通信引込線有無
   * @param {*} ctl
   */
  initializeEditedTsnHksnUmu(ctl) {
    this.popupItems['TsnHksnUmu__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 電力引込線との離隔(m)/径間途中,交差部分 施工後
   * @param {*} ctl
   */
  initializeEditedDrHksnKiknCrossRikakSynk(ctl) {
    this.popupItems['DrHksnKiknCrossRikakSynk__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 電力引込線との離隔確保/家屋側支持点付近 施工後
   * @param {*} e
   */
  changeDrHksnHouseSideRikakKkhSynk(e) {
    if (e.target) {
      this.popupItems['DrHksnHouseSideRikakKkhSynk__c'] = e.target.checked;
    }
  }

  /**
   * 編集ダイアログ 竣工日
   * @param {*} ctl
   */
  initializeEditedSyunkoDate(ctl) {
    this.popupItems['SyunkoDate__c'] = ctl;
  }

  /**
   * 編集ダイアログ
   * 工事取止
   * @param {*} e
   */
  changeKoziUndo(e) {
    if (e.target) {
      this.popupItems['KoziUndo__c'] = e.target.checked;
    }
  }
  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
  editPopup(grid, e) {
    const ht = grid.hitTest(e);
    if (ht.panel === grid.rowHeaders) {
      let _editItem = grid.rows[ht.row].dataItem;
      this.setState({editItem: _editItem});
      // 取付点高さ（m）（施工後）,最接近電力設備との離隔/離隔距離（m）（施工後）,
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）
      for (const key of ['AttachSpotHeightSyunko__c', 'ClosestDrStbRikakSyunko__c',
        'DrHksnKiknCrossRikakSynk__c']) {
        if (Object.hasOwnProperty.call(_editItem, key)) {
          this.popupItems[key].value =
          _editItem[key] === null ? 0 : _editItem[key];
        }
      }
      // 竣工日
      this.popupItems['SyunkoDate__c'].value =_editItem['SyunkoDate__c'];
      // 最接近電力設備との離隔/設備名称, 通信引込線/通信引込線の有無 編集不可
      for (const key of ['ClosestDrStbName__c', 'TsnHksnUmu__c']) {
        if (Object.hasOwnProperty.call(_editItem, key)) {
          this.popupItems[key].selectedValue = _editItem[key];
        }
      }
      // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）（施工後）
      this.popupItems['DrHksnHouseSideRikakKkhSynk__c'] =
      _editItem.DrHksnHouseSideRikakKkhSynk__c;
      this.drHksnHouseSideRikakKkhSynkRef.current.checked =
      _editItem.DrHksnHouseSideRikakKkhSynk__c;
      // 工事取止
      this.popupItems['KoziUndo__c'] = _editItem.KoziUndo__c;
      this.koziUndoRef.current.checked = _editItem.KoziUndo__c;
      // 竣工確認結果にデータが入っている場合は結果設定済フラグをたてて編集不可にする
      let _isSyunkoReportResult = false;
      if (_editItem.SyunkoReportAcceptedDate__c ||
        _editItem.ResubmitNecessity__c ||
        _editItem.ResubmitReason__c) {
        _isSyunkoReportResult = true;
      }
      this.koziUndoRef.current.disabled =
        _isSyunkoReportResult || this.state.isDisabled;

      // 通信引込線が無の場合、非活性
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）
      // ②電力引込線との離隔確保（家屋側支接点付近）（施工後）
      let _isDisabledTsnHksn = false;
      if (_editItem['TsnHksnUmu__c'] != '1') {
        _isDisabledTsnHksn = true;
      }
      for (const key of ['DrHksnKiknCrossRikakSynk__c']) {
        if (Object.hasOwnProperty.call(_editItem, key)) {
          this.popupItems[key].isDisabled =
          _isDisabledTsnHksn || this.state.isDisabled;
        }
      }
      this.drHksnHouseSideRikakKkhSynkRef.current.disabled =
      _isDisabledTsnHksn || this.state.isDisabled;

      this.popup.show(true, (s) => {
        if (s.dialogResult === 'wj-hide-ok') {
          (grid.collectionView).editItem(_editItem);
          // 取付点高さ（m）（施工後）,最接近電力設備との離隔/離隔距離（m）（施工後）,
          // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）（施工後）,
          // 竣工日
          for (const key of ['AttachSpotHeightSyunko__c', 'ClosestDrStbRikakSyunko__c',
            'DrHksnKiknCrossRikakSynk__c', 'SyunkoDate__c']) {
            if (Object.hasOwnProperty.call(_editItem, key)) {
              _editItem[key] = this.popupItems[key].value;
            }
          }
          // 最接近電力設備との離隔/設備名称
          if (_editItem.ClosestDrStbName__c !=
            this.popupItems['ClosestDrStbName__c'].selectedValue) {
            _editItem.ClosestDrStbName__c =
            this.popupItems['ClosestDrStbName__c'].selectedValue; // 最接近電力設備との離隔/設備名称
            // 設備名称を申込時から変更したので離隔距離（m）（申込時）をクリアする
            _editItem.ClosestDrStbRikakOrder__c = 0.00;
          }
          // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）（施工後）, 工事取止
          for (const key of ['DrHksnHouseSideRikakKkhSynk__c', 'KoziUndo__c']) {
            if (Object.hasOwnProperty.call(_editItem, key)) {
              _editItem[key] = this.popupItems[key];
            }
          }
          (grid.collectionView).commitEdit();
        }
        grid.focus();
      });
    }
  };

  /**
  * 径間入力ボタン押下
  */
  doMoveKeikan = async () => {
    try {
      // 申込ステータスが竣工待ち／工事開始可以外は参照なので、入力チェックしない
      if (this.props.order &&
        this.props.order.OrderStatus__c != '40') {
        this.doNext();
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () =>{
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1027_sen')) {
        _hasError = this.props.setGmnWarning['1027_sen'];
      }
    }
    // 入力チェックエラー
    if (_hasError) {
      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
  * 次画面へ遷移
  */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/SyunkoReportSenStb'});
  }

  /**
   * 画面上で非活性になっている項目のデータを保存しないため、
   * 該当データをクリアする
   * @param {object} contractDnt 申込電柱
   * @param {object} item 更新データ
   */
  clearDisabledItem = (contractDnt, item) => {
    // 通信引込線の有無が無の場合、通信引込線の1～2は空
    if (contractDnt['TsnHksnUmu__c'] != '1') {
      item['DrHksnKiknCrossRikakSynk__c'] = 0;
      item['DrHksnHouseSideRikakKkhSynk__c'] = false;
    }
  }

  /**
  * 申込電柱の登録/更新処理
  */
  insertOrUpdate = async () => {
    const {order, values, orderModifiedCondition} = this.props;
    const contractDntList = this.gridRef.current.itemsEdited();
    const saveContractDntList = [];

    for (const dnt of contractDntList) {
      // 更新項目設定
      const updateData = {};
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(dnt, key)) {
          if (key == 'SyunkoReportAcceptedDate__c') {
            updateData[key] = null; // 変更があった行だけ竣工届受理日をクリア
          } else if (key == 'SyunkoDate__c') {
            const syunkoDate = changeDateFormat(dnt[key], 'YYYY-MM-DD');
            updateData[key] = syunkoDate != '' ? syunkoDate : null;
          } else {
            updateData[key] = dnt[key];
          }
        }
      }
      this.clearDisabledItem(dnt, updateData);
      saveContractDntList.push(updateData);
    }

    // 申込電柱更新条件
    const data = this.gridRef.current.itemsAllChanged();
    const updateConditions = [];
    if (data.itemsEdited.length > 0) {
      for (const row of data.itemsEdited) {
        if (row.Id) {
          updateConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }
    // 申込電柱削除条件
    const deleteConditions = [];
    // 申込更新条件
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: orderModifiedCondition != null ?
        orderModifiedCondition.LastModifiedDate : null,
    };
    // 申込
    const updateOrder = {
      Id: order.Id,
      SyunkoReportCheckJiko1__c: values['SyunkoReportCheckJiko1__c'],
      SyunkoReportCheckJiko2__c: values['SyunkoReportCheckJiko2__c'],
    };

    // SFアップロードファイル
    let uploadFiles = this.state.syunkoFiles != null ?
      this.state.syunkoFiles : [];
    let deleteFiles = this.state.syunkoDelFiles != null ?
      this.state.syunkoDelFiles : [];

    const resSave =await this.props.doSaveSyunkoContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder, false, uploadFiles, deleteFiles);
    const resResult = getResponseResult(resSave, ['申込電柱', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      if (!this.gridRef.current) {
        return;
      }
      // 選択状態保存
      this.gridRef.current.saveScreenState('1027_sen', 'Id');

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/SyunkoReportList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり
   */
  doCheckModified = () => {
    let result = this.state.isModified;
    if (this.gridRef.current) {
      result = this.gridRef.current.isItemsModified();
    }
    if (this.state.isModified != result) {
      this.setState({isModified: result});
    }
    return result;
  }

  /**
   * 写真登録画面へ遷移する前に変更有無をチェックする
   * @param {object} ctx 選択した契約電柱
   * @param {string} transfer 遷移先
  */
  checkBeforeMoveAttachedPictures = (ctx, transfer) => {
    if ((this.gridRef.current &&
      this.gridRef.current.commitItemsModified()) ||
      this.props.dirty) {
      this.props.doShowMessage({
        message: {
          id: 'CW0013',
        },
        action: () => {
          this.doMoveAttachedPictures(ctx.item, transfer);
        },
      });
      return;
    }
    this.doMoveAttachedPictures(ctx.item, transfer);
  }

  /**
   * 写真登録画面へ遷移
   * @param {object} item 選択した契約電柱
   * @param {string} transfer 遷移先
  */
  doMoveAttachedPictures =(item, transfer) => {
    if (transfer == '施工後') {
      const id = item ? item.Id : null;
      if (!id) {
        return;
      }
      // 遷移元情報の設定
      this.props.doSetPhotoRegistrationInfo({
        contractDntId: id, // 写真と紐づくレコードIdを渡す
        transitionSrcId: '1027_sen',
        hasCommunicationLine: item.TsnHksnUmu__c == '1' ? true : false,
        readOnly: this.state.isDisabled,
      });
      // 竣工写真登録へ遷移
      this.props.history.push({pathname: '/completionPhotoRegistration'});
    }
  }

  /**
   * セル編集終了時カスタム処理。
   *
   * @param {FlexGrid} s FlexGrid
   * @param {CellRangeEventArgs} e CellRangeEventArgs
   * @param {string} newValue 入力値
   */
  onCellEditEnding = (s, e, newValue) => {
    // 対象のカラムを取得
    const column = s.columns[e.col];

    // 設備名称(ClosestDrStbName__c)の列でない場合は終了
    if (column.binding != 'ClosestDrStbName__c') {
      return;
    }

    const dataMap = column.dataMap;

    // 入力値がコードの場合は表示値に変換
    const newDisplayValue = dataMap.getDisplayValue(newValue);
    if (newDisplayValue != null) {
      newValue = newDisplayValue;
    }

    // 入力不可項目の場合は処理終了
    if (!dataMap.getKeyValue(newValue)) {
      return;
    }

    // 入力前の値を取得(getCellDataはコード値を返却のため表示値に変更)
    const oldCode = s.getCellData(e.row, e.col);
    const oldValue = dataMap.getDisplayValue(oldCode);

    // 値が変わっていない場合は処理終了
    if (newValue == oldValue) {
      return;
    }

    // 設備名称変更時にClosestDrStbRikakOrder__cを0にする
    const item = s.rows[e.row].dataItem;
    item.ClosestDrStbRikakOrder__c = 0.00;
  }

  renderTable() {
    const {classes, generalPurposeMap, isMobile} = this.props;

    // コンボボックス: 設備名称
    const stbNameList = getComboList(generalPurposeMap, 'ClosestDrStbStbName');
    const stbNameMap = createDataMapByComboList(stbNameList);

    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      exceptFilters: ['edit', 'AttachPicturesSyunko'],
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      frozenColumns: 3,
      style: {height: '400px'},
      isReadOnly: this.state.isDisabled,
      useStore: false,
      validateEdits: false,
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      formatItemFunction: this.cellStyleFunction,
      filterChanging: this.filterChangingHandler,
      onCellEditEnding: this.onCellEditEnding,
    };

    return (
      <div>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="uneditableCell" width={120} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="uneditableCell" width={95} isReadOnly={true}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" cssClass="uneditableCell" width={65} isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="電線" align="center">
            <FlexGridColumnGroup binding="IskkCategory__c" header="一束化" cssClass="uneditableCell" width={85} isReadOnly={true} visible={!isMobile}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'IskkCategory', ctx.item.IskkCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          <FlexGridColumnGroup binding="AttachType__c" header="取付種別" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile} width={100}>
            <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
              return getComboName(generalPurposeMap, 'AttachType', ctx.item.AttachType__c);
            }}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="取付点高さ（m）" align="center">
            <FlexGridColumnGroup binding="AttachSpotHeight__c" header="申込時" dataType="Number" format="n1" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile} width={100}/>
            <FlexGridColumnGroup binding="AttachSpotHeightSyunko__c" header="施工後" dataType="Number" format="n1" visible={!isMobile} width={100}>
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.0}
                    max={99.9}
                    step={0.1}
                    format='n1'
                    value={ctx.value ? ctx.value : 0}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="添付写真" align="center">
            <FlexGridColumnGroup binding="AttachPicturesSyunko" header="施工後" align="center"
              cellTemplate={CellMaker.makeButton({
                text: '添付',
                cssClass: 'btn_navy',
                click: (e, ctx) => {
                  this.checkBeforeMoveAttachedPictures(ctx, '施工後');
                },
                attributes: {
                  tabindex: -1,
                },
              })}
            >
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerAttachPicturesTemplate}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="最接近電力設備との離隔" align="center">
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerClosestDrStbDntTemplate}/>
            <FlexGridColumnGroup binding="ClosestDrStbName__c" header="設備名称" dataMap={stbNameMap} visible={!isMobile} width={180}/>
            <FlexGridColumnGroup binding="ClosestDrStbRikakOrder__c" header="離隔距離（m）（申込時）" dataType="Number" format="n2" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
            <FlexGridColumnGroup binding="ClosestDrStbRikakSyunko__c" header="離隔距離（m）（施工後）" dataType="Number" format="n2" width={150} visible={!isMobile}>
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value ? ctx.value : 0}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="中国電力NWへの工事希望" align="center">
            <FlexGridColumnGroup binding="NWKoziHopeKoziContents__c" header="工事内容" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DrSideKoziKinds', ctx.item.NWKoziHopeKoziContents__c);
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="NWKoziHopeMemo__c" header="メモ" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="通信引込線" align="center">
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerTsnHksnTemplate}/>
            <FlexGridColumnGroup binding="TsnHksnUmu__c" header="通信引込線の有無" dataType="String" cssClass={clsx('centerPos', 'uneditableCell')} width={110} isReadOnly={true} visible={!isMobile}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'UmuCategory', ctx.item.TsnHksnUmu__c);
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="DrHksnKiknCrossingRikak__c" header="①電力引込線との離隔（m）（径間途中，交差部分）（申込時）" dataType="Number" format="n2" cssClass="uneditableCell" width={210} isReadOnly={true} visible={!isMobile}/>
            <FlexGridColumnGroup binding="DrHksnKiknCrossRikakSynk__c" header="①電力引込線との離隔（m）（径間途中，交差部分）（施工後）" dataType="Number" format="n2" width={210} visible={!isMobile}>
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value ? ctx.value : 0}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="DrHksnHouseSideRikakKkh__c" header="②電力引込線との離隔確保（家屋側支接点付近）（申込時）" cssClass={clsx('centerPos', 'uneditableCell')} width={250} isReadOnly={true} visible={!isMobile}/>
            <FlexGridColumnGroup binding="DrHksnHouseSideRikakKkhSynk__c" header="②電力引込線との離隔確保（家屋側支接点付近）（施工後）" cssClass="centerPos" width={250} visible={!isMobile}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerDrHksnHouseSideRikakKkhTemplate}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Name" header="保守工事会社" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          <FlexGridColumnGroup binding="SyunkoDate__c" header="竣工日" dataType='Date' width={165}
            editor= {this.dateEditor} format='yyyy/MM/dd' isRequired={false} visible={!isMobile}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="竣工確認結果" align="center">
            <FlexGridColumnGroup binding="SyunkoReportAcceptedDate__c" header="竣工届\n受理日" dataType="Date" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile} width={110}>
              <FlexGridCellTemplate cellType="Cell"
                template= {(context) => {
                  return changeDateFormat(
                      context.item.SyunkoReportAcceptedDate__c);
                }}
              />
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitNecessity__c" header="再提出要否" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'NecessityCategory', ctx.item.ResubmitNecessity__c);
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitReason__c" header="再提出理由" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="KoziUndo__c" header="工事取止" dataType={DataType.Boolean} cssClass="centerPos" visible={!isMobile}>
          </FlexGridColumnGroup>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
            線路名　：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName__c') ?
                        this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
            電柱番号：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo__c') ?
                        this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <InputNumber
                id='id1027AttachSpotHeightSynkPopup'
                format='n1'
                min={0.0}
                max={99.9}
                step={0.1}
                initialized={
                  this.initializeEditedAttachSpotHeightSyunko.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>取付点高さ（m）（施工後）</label>
            </div>
            <br/>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
              最接近電力設備との離隔
            </div>
            <br/>
            <div className="wj-labeled-input" >
              <ComboBox
                id="id1027ClosestDrStbStbNamePopup"
                initialized={
                  this.initializeEditedClosestDrStbName.bind(this)}
                itemsSource={stbNameList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>設備名称</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id="id1027ClosestDrStbRikakSyunkoPopup"
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedClosestDrStbRikakSyunko.bind(this)}
                isDisabled={this.state.isDisabled}
              />
              <label>離隔距離（m）（施工後）</label>
            </div>
            <br/>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
            通信引込線
            </div><br></br>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1027TsnHksnUmuPopup"
                initialized={this.initializeEditedTsnHksnUmu.bind(this)}
                itemsSource={getComboList(generalPurposeMap, 'UmuCategory')}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={true}/>
              <label>通信引込線の有無</label>
            </div>
            <br/>
            <div className='wj-labeled-input'>
              <InputNumber
                id='id1027DrHksnKiknCrossRikakSynkPopup'
                className='wj-label-longText'
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedDrHksnKiknCrossRikakSynk.bind(this)}/>
              <label>①電力引込線との離隔（m）<br/>（径間途中，交差部分）（施工後）</label>
            </div>
            <div className="wj-labeled-input">
              <span className='wj-input-label-custom'>
                ②電力引込線との離隔確保（家屋側支接点付近）
              </span>
              <input
                id="id1027DrHksnHouseSideRikakKkhSynkPopup"
                type="checkbox"
                ref={this.drHksnHouseSideRikakKkhSynkRef}
                value={this.popupItems['DrHksnHouseSideRikakKkhSynk__c']}
                onChange={(e) => {
                  this.changeDrHksnHouseSideRikakKkhSynk(e);
                }
                }/>
              <label htmlFor="id1027DrHksnHouseSideRikakKkhSynkPopup">
              良
              </label>
            </div>
            <br/>
            <div className="wj-labeled-input">
              <span className='wj-input-label-custom'>
              竣工日
              </span>
              <InputDate
                id="id1027SenSyunkoDate"
                initialized={
                  this.initializeEditedSyunkoDate.bind(this)}
                isDisabled={this.state.isDisabled}>
              </InputDate>
            </div>
            <div className="wj-labeled-input">
              <input
                id="id1027SenKoziUndoPopup"
                type="checkbox"
                ref={this.koziUndoRef}
                value={this.popupItems['KoziUndo__c']}
                onChange={(e) => {
                  this.changeKoziUndo(e);
                }}/>
              <label htmlFor="id1027SenKoziUndoPopup">
                    工事取止
              </label>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>

        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="attachedPicturesSenHelpComponent">
            <AttachedPicturesSenSyunko/>
          </div>
          <div id="closestDrStbDntHelpComponent">
            <ClosestDrStbDntSyunko/>
          </div>
          <div id="tsnHksnHelpComponent">
            <TsnHksnSyunko/>
          </div>
          <div id="drHksnHouseSideRikakKkhHelpComponent">
            <DrHksnHouseSideRikakKkhSynk/>
          </div>
        </div>
      </div>
    );
  }

  /**
   * 申込情報表示
   * @param {array} rows
   * @return {object}
   */
  renderOrderInfo(rows) {
    return (
      <TableContainer>
        <Table size="small" style={{border: '0.5px solid gray'}}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row"
                  style={{borderRight: '1px solid gray', borderBottom: '1px solid gray', background: '#001a37', color: 'white', width: '200px'}}>
                  {row.name}
                </TableCell>
                <TableCell align="left" style={{borderBottom: '1px solid gray', width: '400px'}}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const {classes, applyInfo1, applyInfo2, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1027SenNext" item>
          <PositiveButton
            id="btn1027SenNext"
            onClick={handleSubmit(this.doMoveKeikan)}
            variant="contained"
            size="large"
          >
            <span>径間入力</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1027SenSave" item>
          <PositiveButton
            id="btn1027SenSave"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1027SenBack" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onCheckGrid={this.doCheckModified}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={12} sm={6}>
              {this.renderOrderInfo(applyInfo1)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.renderOrderInfo(applyInfo2)}
            </Grid>
          </Grid>
          <br/>
          <Grid container
            direction="column"
            alignItems="flex-start">
            <Grid key="key1027SenTable" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
          <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            style={{paddingBottom: '20px'}}>
            <Grid key="key1027SenFile" item xs={12} sm={6}>
              <Grid container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid key="key1027SenRefFile" item xs={12}>
                  <Typography variant="h6" display="block">
                    装柱図（申込時）
                  </Typography>
                </Grid>
                <Grid key="key1027SenUploadRefFile" item xs={12}>
                  <Upload
                    componentId="refSenComponent"
                    maxFileCount={1}
                    maxFileSize={20971520}
                    required={false}
                    previewFlag={false}
                    previewWidth="35%"
                    acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                    initFile={this.state.refFiles}
                    disabled={true}
                  />
                </Grid>
                <Grid key="key1027SenSyunkoFile" item xs={12}>
                  <Typography variant="h6" display="block">
                    装柱図（施工後）
                    <font size="2">(※最大1ファイル、1ファイルあたり20MBまで登録できます)</font>
                    <Required/>
                    <MuiTooltip content={
                      <React.Fragment>
                        竣工後の全景, 近景等の写真を添付してください。
                      </React.Fragment>
                    }>
                      <InfoOutlined/>
                    </MuiTooltip>
                  </Typography>
                </Grid>
                <Grid key="key1027SenUploadSyunkoFile" item xs={12}>
                  <Upload
                    componentId="syunkoSenComponent"
                    maxFileCount={1}
                    maxFileSize={20971520}
                    required={true}
                    previewFlag={false}
                    previewWidth="35%"
                    acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                    fileSetHandler={this.setSyunkoFiles}
                    initFile={this.state.syunkoFiles}
                    disabled={this.state.isDisabled}
                    customChecks={[
                      checkFileNameLength,
                      checkFileSizeZero,
                      checkSameName,
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid key="key1027Kakunin" item xs={12} sm={6}>
              <CustomCard title={<strong>確認事項</strong>} isExpanded={false}>
                <Typography variant="body1">
                  １. 申請者は，通信線と電力設備の離隔距離の緩和を要望する場合，<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;次の事項を確認し，承諾（チェック）してください。
                </Typography>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <Field
                      id="id1027SyunkoReportCheckJiko1__c"
                      name="SyunkoReportCheckJiko1__c"
                      component={renderCheckbox}
                      label={<p style={{color: '#000', maxWidth: '31em', margin: '5px auto 0 auto'}}>
                         離隔距離を緩和して通信線を架設し，誘導障害が発生した場合は，申請者の責任で改修する。
                      </p>}
                      disabled={this.state.isDisabled}
                    />
                  </FormGroup>
                </FormControl>
                <Typography variant="body1">
                  ２．設置する通信線の種別が以下に該当する場合，チェックを入れてください。
                </Typography>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <Field
                      id="id1027SyunkoReportCheckJiko2__c"
                      name="SyunkoReportCheckJiko2__c"
                      component={renderCheckbox}
                      label={<p style={{color: '#000', maxWidth: '34em', marginBottom: '0'}}>
                           光ファイバケーブルまたは添架通信用第 1 種ケーブルもしくは添架通信用第 2 種ケーブル
                      </p>}
                      disabled={this.state.isDisabled}
                    />
                  </FormGroup>
                </FormControl>
              </CustomCard>
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  order: PropTypes.object,
  applyInfo1: PropTypes.array,
  applyInfo2: PropTypes.array,
  orderId: PropTypes.string,
  contractDntList: PropTypes.array,
  photoExists: PropTypes.array,
  refFiles: PropTypes.array,
  syunkoFiles: PropTypes.array,
  syunkoDelFiles: PropTypes.array,
  contentDocuments: PropTypes.array,
  setGmnWarning: PropTypes.object,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doSetPhotoRegistrationInfo: PropTypes.func,
  doSaveSyunkoContractDnts: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  doGetSyunkoOrderAndContractDnt: PropTypes.func,
  doClearOrderContractDnt: PropTypes.func,
  isMobile: PropTypes.bool,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  let applyInfo1 = orderInfo;

  // 本申込
  const order = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
    state.contractDnt.orderContractDntList.order : null;
  // 申込電柱
  const contractDntList = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
    state.contractDnt.orderContractDntList.contractDntList : [];
  // 写真存在チェック結果
  const photoExists = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'photoExistsPerOrder') ?
    state.contractDnt.orderContractDntList.photoExistsPerOrder : {};
  // 添付ファイル
  const contentDocuments = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contentDocuments') ?
    state.contractDnt.orderContractDntList.contentDocuments : [];

  // 画面表示
  if (order) {
    for (const key in order) {
      if (Object.hasOwnProperty.call(order, key)) {
        if (order[key] != undefined && order[key] != null) {
          for (const data of applyInfo1) {
            if (key == data.key) {
              if (key == 'OrderDate__c') {
                data.value = changeDateFormat(order[key]);
              } else if (key == 'OrderKyogaZgsya__r') {
                data.value =
                  Object.hasOwnProperty.call(order[key], 'Name') ? order[key].Name : '' +
                  Object.hasOwnProperty.call(order[key], 'Account__r') &&
                  Object.hasOwnProperty.call(order[key].Account__r, 'Name') ?
                  order[key].Account__r.Name: '';
              } else {
                data.value = order[key];
              }
            }
          }
          for (const data of koziInfo) {
            if (key == data.key) {
              data.value = order[key];
            }
          }
        }
      }
    }
  }

  const initValues = {
    SyunkoReportCheckJiko1__c: order &&
      Object.hasOwnProperty.call(order, 'SyunkoReportCheckJiko1__c') ?
      order['SyunkoReportCheckJiko1__c'] : false,
    SyunkoReportCheckJiko2__c: order &&
      Object.hasOwnProperty.call(order, 'SyunkoReportCheckJiko2__c') ?
      order['SyunkoReportCheckJiko2__c'] : false,
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: order,
    contractDntList: contractDntList,
    photoExists: photoExists,
    contentDocuments: contentDocuments,
    applyInfo1: applyInfo1,
    applyInfo2: koziInfo,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doSaveSyunkoContractDnts: contractDntOperations.doSaveSyunkoContractDnts,
  doSetPhotoRegistrationInfo: contractDntOperations.doSetPhotoRegistrationInfo,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doGetSyunkoOrderAndContractDnt:
    contractDntOperations.doGetSyunkoOrderAndContractDnt,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'SyunkoSenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(withMediaQuery(
        [
          ['isMobile', (theme) => theme.breakpoints.down('xs')],
        ],
    )(Container))),
);

import types from './types';

export const getContactZikoList = (contactZikoList) => ({
  type: types.GET_CONTACT_ZIKO_LIST,
  contactZikoList,
});

export const getContactZiko = (contactZiko) => ({
  type: types.GET_CONTACT_ZIKO,
  contactZiko,
});

export const setContactZikoType = (contactZikoType) => ({
  type: types.SET_CONTACT_ZIKO_TYPE,
  contactZikoType,
});

export const setKyogaContactZikoId = (kyogaContactZikoId) => ({
  type: types.SET_KYOGA_CONTACT_ZIKO_ID,
  kyogaContactZikoId,
});

export default {
  getContactZikoList,
  getContactZiko,
  setContactZikoType,
};

export default {
  'logger': false,
  'consolelog': true,
  'API_URL': 'https://o0ewl2p9ai.execute-api.ap-northeast-1.amazonaws.com/dev1/',
  'amplify_conf': {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'ap-northeast-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-northeast-1_0Hkse33aw',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '2m59p7cknbkcvfrkpr1tkjobj',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
    },
  },
  'timeout': 60,
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_0Hkse33aw',
  'IdentityPoolId': '',
  'DefaultId': 'kms-dev@example.com',
  'DefaultPassword': 'KmsWebTest123',
  'MAX_DELETE_NUM': 50,
  'WIJMO_LICENSE_KEY': 'd1iyvoxsqwehf6.cloudfront.net,779456643576556#B0IOLcGbmJCLiEjdyIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnVDdo36a5MmVvQTdvNGWIlGRq3CW49kajlENyg5MrYVe4YmU5pmU9REaOhkdxIVWadGb4NTb9kDT68GdyZjcPFTclZkYrcXOxFENNx6KCFjbY3SdHRHMpFnV4Mnc7hGSKRUau9mYXxGNDV4ZWNDT5t6Q6k7dvUVcB3kZWdkVkl6N9N7alZEevR5arI5YoJ6RDBTNzZ7dZ9GSYpkN9syM43yaWdVbGNlW63CT5UEcrZ6b4UGZ8YjapNWY4AlMERVdDxWZ8x6NY3WO6VTYVhEZqp5bMdDTZpnTQBVdiFHbt3mdJNTZwhHWxJmYRh7czdUSiJHWi9WcTJkM7k7U6EUb9RHTslEMPd6U4RVOxVndQh6am3mM8kkZnZVZxJWalZDcJNURBBTQxtmWHBja9lmV5kWWwYURqNVZyIlcwQ5Z7lndxFDUkRHapNUNMhTY4UkMq3WO4hDTiojITJCLiYjRDRTN7QkNiojIIJCL6cDN4gTM5ITM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIyN4UDN5ADI7EzMwIjMwIjI0ICdyNkIsICdl9mL496byZGZ53Gbj9iNmhWZ7F7c83md9lWMkJiOiMXbEJCLi8LpnrJvk/IvlrKom/rgjz1gjn9gj18gjTqgjP1gjf0gjfrgjz1gjX0gjr0gj7rgjPKvvXKvv1KvvLiOiEmTDJCLiYTN5YzN5MDN6YTN4kzN7IiOiQWSiwDfst',
  'HELP_IMAGE_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/help-image/',
  'FORMAT01_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F1_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E7%94%B3%E8%BE%BC%E6%9B%B8.pdf',
  'FORMAT01_DOC_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F1_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E7%94%B3%E8%BE%BC%E6%9B%B8.docx',
  'FORMAT02_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F2_%E5%85%B1%E6%9E%B6%E5%86%85%E8%AB%BE%E3%83%BB%E4%B8%8D%E5%86%85%E8%AB%BE%E5%9B%9E%E7%AD%94%E6%9B%B8.pdf',
  'FORMAT03_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F3_%E5%85%B1%E6%9E%B6%E3%83%BB%E5%A4%89%E6%9B%B4%E7%94%B3%E8%BE%BC%E6%9B%B8.pdf',
  'FORMAT03_DOC_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F3_%E5%85%B1%E6%9E%B6%E3%83%BB%E5%A4%89%E6%9B%B4%E7%94%B3%E8%BE%BC%E6%9B%B8.docx',
  'FORMAT04_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F4_%E5%85%B1%E6%9E%B6%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%81%8A%E3%82%88%E3%81%B3%E5%85%B1%E6%9E%B6%E4%BC%9D%E9%80%81%E8%B7%AF%E8%A8%AD%E5%82%99.pdf',
  'FORMAT04_DOC_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F4_%E5%85%B1%E6%9E%B6%E3%81%AE%E5%AF%BE%E8%B1%A1%E3%81%8A%E3%82%88%E3%81%B3%E5%85%B1%E6%9E%B6%E4%BC%9D%E9%80%81%E8%B7%AF%E8%A8%AD%E5%82%99.docx',
  'FORMAT05_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F5_%E5%B7%A5%E4%BA%8B%E6%96%BD%E5%B7%A5%E8%80%85%E3%81%8A%E3%82%88%E3%81%B3%E4%BF%9D%E5%AE%88%E6%8B%85%E5%BD%93%E8%80%85%E3%81%AE%E5%B1%8A.pdf',
  'FORMAT05_DOC_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F5_%E5%B7%A5%E4%BA%8B%E6%96%BD%E5%B7%A5%E8%80%85%E3%81%8A%E3%82%88%E3%81%B3%E4%BF%9D%E5%AE%88%E6%8B%85%E5%BD%93%E8%80%85%E3%81%AE%E5%B1%8A.docx',
  'FORMAT06_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F6_%E5%85%B1%E6%9E%B6%E6%89%BF%E8%AB%BE%E3%83%BB%E4%B8%8D%E6%89%BF%E8%AB%BE%E5%9B%9E%E7%AD%94%E6%9B%B8.pdf',
  'FORMAT07_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F7_%E5%85%B1%E6%9E%B6%E3%83%BB%E6%94%B9%E4%BF%AE%E3%83%BB%E6%92%A4%E5%8E%BB%E5%B7%A5%E4%BA%8B%20%E7%9D%80%E6%89%8B%E3%83%BB%E5%AE%8C%E4%BA%86%E5%B1%8A.pdf',
  'FORMAT07_DOC_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F7_%E5%85%B1%E6%9E%B6%E3%83%BB%E6%94%B9%E4%BF%AE%E3%83%BB%E6%92%A4%E5%8E%BB%E5%B7%A5%E4%BA%8B%20%E7%9D%80%E6%89%8B%E3%83%BB%E5%AE%8C%E4%BA%86%E5%B1%8A.docx',
  'FORMAT08_XLS_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/format/%E6%A7%98%E5%BC%8F8_%E5%85%B1%E6%9E%B6%E7%94%B3%E8%BE%BC%E3%83%BB%E5%BB%83%E6%AD%A2%EF%BC%BF%E8%A3%85%E6%9F%B1%E5%9B%B3%E3%83%BB%E5%86%99%E7%9C%9F%E3%80%90%E7%94%B3%E8%AB%8B%E6%99%82%EF%BC%8F%E6%96%BD%E5%B7%A5%E5%BE%8C%E3%80%91%EF%BC%88%E6%A8%99%E6%BA%96%E6%A7%98%E5%BC%8F%EF%BC%89.xlsx',

  // マニュアルのURLは中電NWサイトからリンクを張られるため変更しないこと
  'MANUAL_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/manual/yupol-01.pdf',

  // 中国電力ネットワーク契約用マニュアル
  'MANUAL_CN_PDF_URL': 'https://d1iyvoxsqwehf6.cloudfront.net/manual/yupol-02.pdf',

  'KYOUGA_YAKKAN_URL': '#',
  'CONSTRUCTION_URL': '#',
  'POINTS_URL': '#',
  'INFO_HIKARI_FIBER_URL': '#',
  'TOI_URL': '#',
};

import types from './types';

// 電柱 取得
export const getDntList = (dntList) => ({
  type: types.GET_DNT_LIST,
  dntList,
});

// 電柱情報 設定
export const setDntInfo = (dntInfo) => ({
  type: types.SET_DNT_INFO,
  dntInfo,
});

export const getDntbyDstDntNo = (dntList)=> ({
  type: types.GET_DNT_LIST,
  dntList,
});

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ListAltIcon from '@material-ui/icons/ListAlt';

import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, changeDateFormat,
  getErrorCode, API_CODE, getDataFromGeneralPurposeMap} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import KyogaCompanyInfo from '../../../../../molecules/KyogaCompanyInformation.js';
import * as validateRule from '../../../../../validateRule.js';
import {orderResultListFields} from '../../../../../../common/SFFields';
import PaperPart from '../../../../../atoms/PaperPart.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
  wave: {
    fontSize: '1.2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
    width: '42%',
    minWidth: '100px',
  },
});

// 可否判定結果で使用する申込ステータスコード
// 可否判定済,本申込中,完結（期限切れ）,完結（申込済）
const proprietyOrderStatus = ['04', '05', '91', '93'];

/**
 * 可否判定結果一覧
 * 画面ID:1008
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
    };
    this.gridRef = React.createRef();
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setInitItems('1008', []);
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.reset();
    this.props.doClearOrderResultList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1008', 'Id');
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 一覧のスタイルを変更
   * 可否判定回答期限を超えている行をグレーアウトする
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction(s, e) {
    try {
      const orderStatusExpired = '91'; // 完結(期限切れ)
      const rows = s.rows;
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].dataItem.OrderStatus__c == orderStatusExpired) {
          rows[i].cssClass = 'gray';
        }
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧表示
   * @return {object}
   */
  renderTable() {
    const props = {
      rowHeaderType: 'radio',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      style: {height: '300px'},
      isReadOnly: true,
      refreshedFunction: this.refreshedFunction,
      checkedFunction: this.checkStateUpdate,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="OrderNo__c" header="可否判定申込番号" dataType="String" width={150}/>
        <FlexGridColumnGroup binding="StbTypeName" header="設備種別" dataType="String" width={100}/>
        <FlexGridColumnGroup binding="KyogaTypeName" header="共架種別" dataType="String" />
        <FlexGridColumnGroup binding="ProprietyJudgeOrderDate__c" header="可否判定\n申込年月日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(context.item.ProprietyJudgeOrderDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="KoziTitle__c" header="工事件名" dataType="String" />
        <FlexGridColumnGroup header="工事場所" align="center">
          <FlexGridColumnGroup binding="KoziPlaceMainDntNo_SenroName__c" header="線路名" dataType="String"/>
          <FlexGridColumnGroup binding="Dsp_KoziPlaceMainDntNo__c" header="代表電柱番号" dataType="String"/>
          <FlexGridColumnGroup binding="KoziPlacePrefecturesName" header="都道府県" dataType="String" width={100}/>
          <FlexGridColumnGroup binding="KoziPlaceMunicipalities__c" header="市区町村" dataType="String" />
          <FlexGridColumnGroup binding="KoziPlacePlace__c" header="場所" dataType="String" />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="BillingAmountTaxIn" header="可否判定費用（税込）" dataType="Number"/>
        <FlexGridColumnGroup binding="KaHonsu__c" header="可本数" dataType="Number" width={80}/>
        <FlexGridColumnGroup binding="ConditionalKaHonsu__c" header="条件付\n可本数" dataType="Number" width={80}/>
        <FlexGridColumnGroup binding="HiHonsu__c" header="否本数" dataType="Number" width={80}/>
        <FlexGridColumnGroup binding="ProprietyJudgeAnswerDate__c" header="可否判定\n回答日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(context.item.ProprietyJudgeAnswerDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="ProprietyJudgeSendBackDate__c" header="可否判定\n差戻日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.ProprietyJudgeSendBackDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="ProprietyJudgeReorderDate__c" header="可否判定\n再申込日" width={110} dataType="Date">
          <FlexGridCellTemplate cellType="Cell"
            template= {(context) => {
              return changeDateFormat(
                  context.item.ProprietyJudgeReorderDate__c);
            }}
          />
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="OrderStatusName" header="申込ステータス" dataType="String"/>
      </CustomFlexGrid>
    );
  }

  /**
  * ボタンの活性、非活性化
  */
  checkStateUpdate = () => {
    try {
      let bRet = false;

      if (this.gridRef && this.gridRef.current &&
        this.gridRef.current.selectedItemCount() > 0) {
        bRet = true;
      }

      if (bRet) {
        this.setState({buttonDisabled: false});
      } else {
        this.setState({buttonDisabled: true});
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 参照ボタン押下時
   * 期限切れの申込でも次画面へ遷移するのでラジオボタンは使用できること。
   */
  doRef = async () => {
    try {
      if (!this.gridRef ||
        !this.gridRef.current) {
        return;
      }

      const count = this.gridRef.current.selectedItemCount();
      if (count != 1) {
        this.props.doShowMessage({
          message: {
            id: 'CE0022',
          },
        });
        return;
      }
      const items = this.gridRef.current.selectedItem();
      this.props.doSetOrderId(items[0].dataItem.Id);
      this.props.history.push({pathname: '/ProprietyResultDetail'});
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 検索ボタン押下時処理
   */
  searchOrder = async () => {
    try {
      const {generalPurposeMap} = this.props;

      this.setState({buttonDisabled: true});

      // 申告テーブル検索のパラメータ設定
      let wkData = {};
      if (this.props.values) {
        wkData = JSON.parse(JSON.stringify(this.props.values));
      }
      wkData['OrderCategory__c'] = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory01', 2);
      wkData['RecordType.DeveloperName'] = 'ProprietyJudgeOrder';
      // 申込ステータスがなければ追加
      if (!Object.hasOwnProperty.call(wkData, 'OrderStatus__c')) {
        wkData['OrderStatus__c'] = proprietyOrderStatus;
      }

      const appSortParams = {
        OrderDate__c: -1,
      };

      const response = await this.props.doGetOrderResultList(
          wkData, orderResultListFields, appSortParams);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['可否判定申込', '取得'],
          },
        });
        return;
      }
      if (this.gridRef && this.gridRef.current) {
        // 検索処理が正常だったらラジオの選択状態クリア
        this.gridRef.current.saveScreenState('1008', 'Id', true);
      }

      // 件数超過エラー
      const maxKensu = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      const dataLen = response.data.body.data ?
        response.data.body.data.length : 0;
      if (dataLen >= maxKensu) {
        this.props.doShowMessage({
          message: 'CE0015',
        });
      }

      if (this.gridRef && this.gridRef.current) {
        this.gridRef.current.setItems(this.props.orderResultList);
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderResultList') ||
          !this.props.orderResultList ||
          this.props.orderResultList.length <= 0 ) {
        this.props.doShowMessage({
          message: 'CE0016',
        });
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  render() {
    const {classes, generalPurposeMap, reset,
      handleSubmit, userInfo} = this.props;
    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1008Ref" item>
          <PositiveButton
            id="btn1008Ref"
            onClick={handleSubmit(this.doRef)}
            variant="contained"
            startIcon={<ListAltIcon />}
            size="large"
            disabled={this.state.buttonDisabled}
          >
            <span>参照</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1008Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <KyogaCompanyInfo companyName={userInfo.Account.Name}/>
          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1" item xs={12} sm={2}>
                <Field
                  id="id1008ProprietyOrderNo"
                  name="OrderNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="可否判定申込番号"
                  fullWidth
                  type="text"
                  required={false}
                  validate={[
                    validateRule.alphaNumericSymbol,
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1008StbType" item xs={12} sm={2}>
                <Field
                  id="id1008StbType"
                  name="StbType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="設備種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                </Field>
              </Grid>
              <Grid key="key1008KyogaType" item xs={12} sm={2}>
                <Field
                  id="id1008KyogaType"
                  name="KyogaType__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="共架種別"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                    createSearchList(generalPurposeMap.KyogaType,
                        'Code__c',
                        'Name',
                        {Code__c:
                          Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                          Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                          userInfo.Account.Account__r.KyogaType__c : '',
                        ValueString1__c:
                          this.props.values &&
                          Object.hasOwnProperty.call(this.props.values, 'StbType__c') ?
                          [this.props.values.StbType__c] : ['1', '2']})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1008KoziTitle" item xs={12} sm={4}>
                <Field
                  id="id1008KoziTitle"
                  name="KoziTitle__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事件名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>
              <Grid key="key1008OrderStatus" item xs={12} sm={2}>
                <Field
                  id="id1008OrderStatus"
                  name="OrderStatus__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="申込ステータス"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                       createSearchList(generalPurposeMap.OrderStatus, 'Code__c', 'Name', {Code__c: proprietyOrderStatus})}
                </Field>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1008KoziPlaceMainDntNo_SenroName" item xs={12} sm={2}>
                <Field
                  id="id1008KoziPlaceMainDntNo_SenroName"
                  name="KoziPlaceMainDntNo_SenroName__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／線路名"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(30),
                  ]}
                />
              </Grid>
              <Grid key="key1008KoziPlaceMainDntNo" item xs={12} sm={2}>
                <Field
                  id="id1008KoziPlaceMainDntNo"
                  name="Dsp_KoziPlaceMainDntNo__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／代表電柱番号"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(23),
                  ]}
                />
              </Grid>
              <Grid key="key1008KoziPlacePrefectures" item xs={12} sm={2}>
                <Field
                  id="id1008KoziPlacePrefectures"
                  name="KoziPlacePrefectures__c"
                  component={renderSelect}
                  className={classes.fields}
                  label="工事場所／都道府県"
                  fullWidth
                  required={false}
                  validate={[
                  ]}
                >
                  {generalPurposeMap &&
                      createSearchList(generalPurposeMap.PrefCode)}
                </Field>
              </Grid>
              <Grid key="key1008KoziPlaceMunicipalities" item xs={12} sm={3}>
                <Field
                  id="id1008KoziPlaceMunicipalities"
                  name="KoziPlaceMunicipalities__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／市区町村"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(12),
                  ]}
                />
              </Grid>
              <Grid key="key1008KoziPlacePlace" item xs={12} sm={3}>
                <Field
                  id="id1008KoziPlacePlace"
                  name="KoziPlacePlace__c"
                  component={renderTextField}
                  className={classes.fields}
                  label="工事場所／場所"
                  type="text"
                  fullWidth
                  required={false}
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1008OrderDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1008OrderDateFrom"
                    name="ProprietyJudgeOrderDate__c_From"
                    accessibilitylabel="ProprietyJudgeOrderDate__c_From"
                    label="可否判定申込年月日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1008OrderDateTo"
                    name="ProprietyJudgeOrderDate__c_To"
                    accessibilitylabel="ProprietyJudgeOrderDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1008ProprietyJudgeAnswerDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1008ProprietyJudgeAnswerDateFrom"
                    name="ProprietyJudgeAnswerDate__c_From"
                    accessibilitylabel="ProprietyJudgeAnswerDate__c_From"
                    label="可否判定回答日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1008ProprietyJudgeAnswerDateTo"
                    name="ProprietyJudgeAnswerDate__c_To"
                    accessibilitylabel="ProprietyJudgeAnswerDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1008ProprietyJudgeSendBackDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1008ProprietyJudgeSendBackDateFrom"
                    name="ProprietyJudgeSendBackDate__c_From"
                    accessibilitylabel="ProprietyJudgeSendBackDate__c_From"
                    label="可否判定差戻日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1008ProprietyJudgeSendBackDateTo"
                    name="ProprietyJudgeSendBackDate__c_To"
                    accessibilitylabel="ProprietyJudgeSendBackDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid key="key1008ProprietyJudgeReorderDate" item xs={12} sm={4}>
                <div style={{position: 'relative'}}>
                  <Field
                    id="id1008ProprietyJudgeReorderDateFrom"
                    name="ProprietyJudgeReorderDate__c_From"
                    accessibilitylabel="ProprietyJudgeReorderDate__c_From"
                    label="可否判定再申込日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                  <span className={classes.wave}>～</span>
                  <Field
                    id="id1008ProprietyJudgeReorderDateTo"
                    name="ProprietyJudgeReorderDate__c_To"
                    accessibilitylabel="ProprietyJudgeReorderDate__c_To"
                    label={<React.Fragment />}
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[
                      validateRule.date,
                    ]}
                  />
                </div>
              </Grid>
              <Grid key="key1008Clear" item xs={12} sm>
                <NegativeButton
                  id="id1008Clear"
                  variant="contained"
                  startIcon={<RotateLeftIcon />}
                  className={classes.button}
                  size="large"
                  onClick={reset}
                >
                  <span>クリア</span>
                </NegativeButton>
                <PositiveButton
                  id="id1008Search"
                  variant="contained"
                  startIcon={<SearchIcon />}
                  className={clsx(classes.button)}
                  size="large"
                  onClick={handleSubmit(this.searchOrder)}
                >
                  <span>検索</span>
                </PositiveButton>
              </Grid>
            </Grid>
          </PaperPart>
          <div style={{marginBottom: '20px'}} />
          <Divider variant="middle" />
          <div style={{marginBottom: '20px'}} />
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid key="key1008List" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  orderResultList: PropTypes.array,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  reset: PropTypes.func,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderResultList: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doClearOrderResultList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  userInfo: state.auth.userInfo,
  orderResultList: state.attachorder.orderResultList,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetOrderResultList: attachorderOperations.doGetOrderResultList,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doClearOrderResultList: attachorderOperations.doClearOrderResultList,
};

const FORM_NAME = 'ProprietyResultList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const styles = (theme) => ({
  card: {
    margin: '10px 20px 10px 20px',
  },
  typography: {
    margin: '10px 0 0 16px',
    fontSize: '0.8em',
  },
  paper: {
    backgroundColor: '#fafafa',
    border: '5px solid #4caf50',
    width: '630px',
    margin: '20% auto',
    overflow: 'auto',
  },
  button: {
    marginBottom: '10px',
    textAlign: 'center',
  },
});

const ExPanelSummary = withStyles((theme) => ({
  root: {
    'backgroundColor': '#e8f5e9',
  },
}))(ExpansionPanelSummary);

const ExPanelDetail = withStyles((theme) => ({
  root: {
    padding: '10px',
    display: 'block',
  },
}))(ExpansionPanelDetails);

class Container extends React.Component {
  render() {
    const {classes, children} = this.props;

    return (
      <div className={classes.paper}>
        <Card className={classes.card}>
          <ExPanelSummary>
            <Typography>お知らせ</Typography>
          </ExPanelSummary>
          <ExPanelDetail>
            <Typography>共架契約約款に定められた事項を履行されていないため、現在お客様からの</Typography>
            <Typography>新規可否判定申込および本申込を制限させていただいております。</Typography>
            <Typography>なお、新規可否判定申込・本申込以外はご利用いただくことができます。</Typography>
          </ExPanelDetail>
        </Card>

        <div className={classes.button}>
          {children}
        </div>
      </div>
    );
  }
}

Container.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Container);

import {combineReducers} from 'redux';
import types from './types';

// 電柱一覧
const dntList = (state = null, action) => {
  switch (action.type) {
    case types.GET_DNT_LIST:
      return action.dntList;
    default:
      return state;
  }
};

// 電柱情報
const dntInfo = (state = null, action) => {
  switch (action.type) {
    case types.SET_DNT_INFO:
      return action.dntInfo;
    default:
      return state;
  }
};

// 電柱一覧 表示用電柱番号
const dntListbyDspDntNo = (state = null, action) => {
  switch (action.type) {
    case types.GET_DNT_BY_DSPDNTNO:
      return action.dntListbyDspDntNo;
    default:
      return state;
  }
};


const reducer = combineReducers({
  dntList,
  dntInfo,
  dntListbyDspDntNo,
});

export default reducer;

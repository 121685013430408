import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TemplateList from '../../components/templates/TemplateList';
import {commonOperations} from '../../reducks/common';

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
};

/**
 * 申込関連書類書式一覧
 * 画面ID:1522
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const ret = 'OK';
    this.doJudge(ret);
  }

  /**
   * ログイン判定
   * @param {*} ret
   */
  doJudge = (ret) => {
  }

  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    return (<TemplateList
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  doShowMessage: PropTypes.func,
  getUserInfo: PropTypes.func,
  userInfo: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default ContainerApp;

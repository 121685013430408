import React from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import HelpIcon from '@material-ui/icons/Help';

import {withStyles} from '@material-ui/core/styles';
import {FormControl, FormControlLabel, FormLabel, Grid, RadioGroup} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import {dntNoFormat, full2Harf, full2Harf2, execApexRestApi, getComboName, senroNameFormat} from '../../common/common.js';
import {renderCheckbox, renderTextField, renderSelect, RadioB} from '../atoms/CustomPart.js';
import PaperPart from '../atoms/PaperPart.js';
import PositiveButton from '../atoms/Buttons/PositiveButton.js';
import CustomFlexGrid from '../molecules/CustomFlexGrid.js';
import {DntNoDisplay} from '../molecules/HelpComponent.js';
import {FlexGridCellTemplate, FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';

import {commonOperations} from '../../reducks/common';
import {contractDntOperations} from '../../reducks/contractDnt/index.js';
import NegativeButton from '../atoms/Buttons/NegativeButton.js';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {senroCodeOperations} from '../../reducks/senroCode/index.js';
import {createSearchList} from '../../common/ComboParts.js';
import {isNullOrWhiteSpace} from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import MuiTooltip from '../atoms/MuiTooltip.js';
import {dntOperations} from '../../reducks/dnt/index.js';
import {getDataFromGeneralPurposeMap} from '../../common/common.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
  searchButton: {
    minWidth: 'initial',
  },
  waveGrid: {
    fontSize: '1.2em',
    textalign: 'center',
  },
  wave: {
    paddingLeft: 16,
  },
  fieldButton: {
    marginLeft: 16,
  },
  modal: {
    position: 'absolute!important',
    overflow: 'scroll',
    display: 'block',
    inset: '5%!important',
  },
});

/**
 * 電柱選択
 * 画面ID：1301_0802
 * ■ 呼び出し情報
 * @property {string} YbdsType     呼び出し種別(A~G、AM、DM、EM) ※AM、DM、EM：複数選択
 * @property {string} YdbsKey      呼出しキー（申込番号＋呼び出し元画面ID）(A~F:必須)
 * @property {string} StbType      設備種別 (E:必須)
 * @property {string} KyogaType    共架種別 (E:必須)
 * @property {string} SzbtItiId    基準電柱情報 - 支持物位置ID(C:必須)
 * @property {string} StbKobetuId  基準電柱情報 - 設備個別ID(C:必須)
 * @property {string} DntNo        基準電柱情報 - 電柱番号(C:必須)
 * @property {string} DspSenroName 基準電柱情報 - 表示用線路名(C:必須)
 * @property {string} DspDntNo     基準電柱情報 - 表示用電柱番号(C:必須)
 * @property {array[string]} SelectedDntList 選択済契約電柱 - 電柱番号
 * @property {boolean} modalOpen  モーダル画面 表示/非表示 ステートは親が持つ
 * @property {boolean} IsClear     検索結果クリアフラグ - TRUE：検索結果をクリアする
 * @function closeButton          モーダル画面 非表示化処理
 * @function setSelectedPole      電柱選択ボタン押下時処理
 *
 * ■ 返却情報
 * @property {[object]} selectedPoles 電柱オブジェクトの配列
 *  電柱オブジェクト
 *   NWZgsyo__r_Code__c    (選択電柱の)事業所コード
 *   OldNWZgsyo__c         旧事業所コード
 *   SenroCode__c          線路コード
 *   SenroName__c          線路名
 *   SenroNameKana__c      線路名カナ
 *   DntNo__c              電柱番号(ソート用25桁)
 *   DntNoManualInput__c   電柱番号_自由入力
 *   K6KansnNo             6kV幹線No
 *   K6Bunk1__c            6kV分岐1
 *   K6Bunk2__c            6kV分岐2
 *   K6Bunk3__c            6kV分岐3
 *   K22SzbtNo__c          22kV支持物番号
 *   K22GatiCategory__c    22kV架地区分
 *   K6K22HeigaCategory__c 6kV22kV併架区分
 *   SzbtItiId__c          支持物位置ID
 *   StbKobetuId__c        設備個別ID
 *   StbItiPrefectures     設備位置_都道府県
 *   StbItiMunicipalities  設備位置_市区町村
 *   StbItiPlace           設備位置_場所
 *   DntCategory__c        電柱区分
 *   KyogaCategory__c      共架区分(1,2,5,6,7,9,(空白),C,H,S,T,O)
 *   RecordId              契約電柱(salesforce)ID
 **/

class SelectPoleNo extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      selectedPoles: [],
      NWZgsyoList: [],
      SenroList: [],
      tableData: [],
      checkedboxitem: null,
      checkedradioitem: null,
    };
    this.gridRef = React.createRef();
    this.senrocodecategory22KV = '1';
    this.AttachKinsiTy = [];
    this.DntCategoryOur = [];
    this.DntCategoryOther = [];
    this.KyogaCategoryOurContract = [];
    this.KyogaCategoryOtherContract = [];
    this.KyogaCategoryOurSpan = [];
    this.KyogaCategoryOtherSpan = [];
    this.targetKyogaCategory = [];
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    const {generalPurposeMap} = this.props;
    console.dir('generalPurposeMap.KyogaCategory');
    console.dir(generalPurposeMap);
    this.DntCategoryOur = generalPurposeMap.DntCategory
        .filter((item) => item.ValueNum1__c == 1).map((item) => item.Code__c);
    this.DntCategoryOther = generalPurposeMap.DntCategory
        .filter((item) => item.ValueNum2__c == 1).map((item) => item.Code__c);
    this.KyogaCategoryOurContract = generalPurposeMap.KyogaCategory
        .filter((item) => item.ValueString1__c == '1' && item.ValueNum1__c == 1).map((item) => item.Code__c);
    this.KyogaCategoryOtherContract = generalPurposeMap.KyogaCategory
        .filter((item) => item.ValueString1__c == '0' && item.ValueNum1__c == 1).map((item) => item.Code__c);
    this.KyogaCategoryOurSpan = generalPurposeMap.KyogaCategory
        .filter((item) => item.ValueString1__c == '1' && item.ValueNum2__c == 1).map((item) => item.Code__c);
    this.KyogaCategoryOtherSpan = generalPurposeMap.KyogaCategory
        .filter((item) => item.ValueString1__c == '0' && item.ValueNum2__c == 1).map((item) => item.Code__c);
    this.targetKyogaCategory = generalPurposeMap.KyogaCategory
        .filter((item) => item.ValueNum1__c == 1).map((item) => item.Code__c);
    this.setState({
      checkedradioitem: this.props.initialValues.targetPole,
    });
  }

  /**
   * 事業所検索
   */
  setNWZgsyoList = async () =>{
    try {
      const {values} = this.props;
      const namefilter = values.office;
      const {generalPurposeMap, doGetNWZgsyoNamefilter} = this.props;

      // 汎用マスタから中電NW事業所の一覧を取得
      let nwzgsyoValueFilter = generalPurposeMap.NWZgsyo;

      // 有効な事業所(ValueString3__cが"1")のみに絞り込む
      nwzgsyoValueFilter = nwzgsyoValueFilter.filter((item) => {
        return item.ValueString3__c == '1';
      });

      // チェックボックスによる絞り込み
      if (this.state.checkedboxitem == 'inputSouden'|| this.state.checkedradioitem == 'souden') {
        // 送電柱対象事業所(ValueNum1__cが1)
        nwzgsyoValueFilter = nwzgsyoValueFilter.filter((item)=>
          item.ValueNum1__c == 1);
      } else if (this.state.checkedboxitem == 'inputTushin' || this.state.checkedradioitem == 'tusin') {
        // 通信柱対象事業所(ValueNum2__cが1)
        nwzgsyoValueFilter = nwzgsyoValueFilter.filter((item)=>
          item.ValueNum2__c == 1);
      }

      // 入力した事業所名で絞り込み
      await doGetNWZgsyoNamefilter(nwzgsyoValueFilter, namefilter);
      if (this.props.NWZgsyoNamefilter.length <=0 ) {
        values.lineList = null;
        this.props.doShowMessage({
          message: {
            id: 'CE0016',
          },
        });
        return;
      }
      if (this.state.checkedradioitem == '6kV' || this.state.checkedradioitem == '22kV') {
        await this.setSenroList();
      }
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['事業所', '取得'],
        },
      });
    }
  }

  /**
   * 線路検索
   */
  setSenroList = async () => {
    try {
      const {values, YbdsType, KyogaType, StbType, userInfo,
        generalPurposeMap} = this.props;
      if (isNullOrWhiteSpace(values.NWZgsyo__c)) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['事業所'],
          },
        });
        return;
      }
      // 件数超過エラー
      const maxKensu = getDataFromGeneralPurposeMap(
          generalPurposeMap, 'DisplayMaxKensu1', 1).ValueNum1__c;
      const filter = {};

      const list = [];
      if (['E', 'EM'].includes(YbdsType)) {
        const body = {
          NWZgsyoCode: [values.NWZgsyo__c],
          senroName: values.line,
          senroNameKana: (values.line ?
             full2Harf(full2Harf2(values.line)) : undefined),
          stbType: StbType,
          kyogaType: KyogaType,
          kyogaZgsyaCode: userInfo.KyogaZgsyaCode__c,
          kyogaZgsyoCode: userInfo.KyogaZgsyoCode__c,
        };
        const ret1 = await execApexRestApi(userInfo, 'ContractDnt/senroSearch', body);
        if (ret1 == null) {
          return;
        }
        if (ret1.data.body.errorCode != '00000') {
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['線路情報', '取得'],
            },
          });
          return;
        };
        if (ret1.data.body.data && ret1.data.body.data.data &&
          ret1.data.body.data.data.length >= maxKensu) {
          this.props.doShowMessage({
            message: 'OW0197',
            type: 'info',
          });
        }
        const filterdContractDntList = Array.from(
            new Map(ret1.data.body.data.data.map((o) =>
              [(o.SenroCode__c ? o.SenroCode__c : o.SenroName__c), o],
            )),
        );
        filterdContractDntList.map((item) =>{
          if (!isNullOrWhiteSpace(item[0])) {
            list.push({
              'senrocode': item[0],
              'senroname': item[1].SenroName__c,
              'senronamekana': item[1].SenroNameKana__c,
            });
          }
        },
        );
        const senroInfo = list.filter((o) => o.senrocode);
        if (senroInfo && senroInfo.length > 0) {
          const senroFilter = {};
          senroFilter['NWZgsyo'] = values.NWZgsyo__c;
          senroFilter['SenroCode_EQ'] = senroInfo.map((o) => o.senrocode);
          // 線路マスタから取得
          const ret = await execApexRestApi(userInfo, 'SenroCode/get', {'conditions': senroFilter});
          const errCode = ret && ret.data && ret.data.body ?
          ret.data.body.errorCode : undefined;
          if (errCode != '00000') {
            console.error(errCode);
            this.props.doShowMessage({
              message: {
                id: 'CE0052',
                values: ['線路情報', '取得'],
              },
            });
            return;
          }
          const senroResult = ret.data.body.data;
          if (senroResult && senroResult.length > 0) {
            for (const senroData of list) {
              const senro = senroResult.filter(
                  (o) => o.senrocode == senroData.senrocode);
              if (senro && senro.length > 0) senroData['senrocodecategory'] = senro[0].senrocodecategory;
            }
          }
        }
        this.props.doSetSenroCodeList(list);
        // 検索結果0件の場合、エラーダイアログ表示
        if (list.length <= 0) {
          this.props.doShowMessage({
            message: {
              id: 'CE0016',
            },
          });
        }
      } else {
        filter['NWZgsyo'] = values.NWZgsyo__c;
        if (['G'].includes(YbdsType)) {
          if (this.state.checkedradioitem == '6kV') {
            filter['SenroCodeCategory'] = '0';
          } else if (this.state.checkedradioitem == '22kV') {
            filter['SenroCodeCategory'] = '1';
          }
        }
        if (!isNullOrWhiteSpace(values.line)) {
          filter['OR'] = {};
          filter['OR']['SenroCode_EQ'] = values.line;
          filter['OR']['SenroName'] = values.line;
          filter['OR']['SenroNameKana'] = full2Harf(full2Harf2(values.line));
        }
        // 線路マスタから取得
        const ret2 = await this.props.doGetSenroCodeList(filter);
        if (ret2.data.body.errorCode != '00000') {
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['線路情報', '取得'],
            },
          });
          return;
        };
        // 検索結果0件の場合、エラーダイアログ表示
        if (this.props.senroCodeList.length <= 0) {
          this.props.doShowMessage({
            message: {
              id: 'CE0016',
            },
          });
        }
      }
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['線路情報', '取得'],
        },
      });
    }
  }

  createSenroOptions = () => {
    try {
      const {senroCodeList}= this.props;
      const options = !senroCodeList ? [] : senroCodeList.map((data, index)=>{
        return <option key={data.senrocode} value={data.senrocode}>
          {(isNullOrWhiteSpace(data.senroname)&&
          isNullOrWhiteSpace(data.senronamekana))?
            data.senrocode :
          (data.senroname ? data.senroname + ' ' : '') +
          (data.senronamekana ? data.senronamekana : '') }
        </option>;
      },
      );
      return options;
    } catch (error) {
      console.dir(error);
    }
  }

  /**
   * 電柱マスタから取得
   */
  searchPole = async () =>{
    try {
      const {values, YbdsType, SelectedDntList,
        DntNo, senroCodeList, generalPurposeMap,
        SzbtItiId, StbKobetuId} = this.props;

      // 選択済契約電柱のみのとき 前画面で選択された電柱を表示
      if (this.state.checkedboxitem == 'useFilterSelectedDnt') {
        const list =[];
        for (const dnt in SelectedDntList) {
          if (SelectedDntList.hasOwnProperty(dnt)) {
            const nwzgsyo = generalPurposeMap.NWZgsyo.filter((item) =>
              item.Id == SelectedDntList[dnt].NWZgsyo__c);
            list.push({
              'nwzgsyo': nwzgsyo.length==1 ?nwzgsyo[0].Code__c:null,
              'oldnwzgsyo': SelectedDntList[dnt].OldNWZgsyo__c,
              'senrocode': SelectedDntList[dnt].SenroCode__c,
              'senroname': SelectedDntList[dnt].SenroName__c,
              'senronamekana': SelectedDntList[dnt].SenroNameKana__c,
              'dntno': SelectedDntList[dnt].DntNo__c,
              'dntnomanualinput': SelectedDntList[dnt].DntNoManualInput__c,
              'k6kansnno': SelectedDntList[dnt].K6KansnNo__c,
              'k6bunk1': SelectedDntList[dnt].K6Bunk1__c,
              'k6bunk2': SelectedDntList[dnt].K6Bunk2__c,
              'k6bunk3': SelectedDntList[dnt].K6Bunk3__c,
              'k22szbtno': SelectedDntList[dnt].K22SzbtNo__c,
              'k22gaticategory': SelectedDntList[dnt].K22GatiCategory__c,
              'k6k22heigacategory': SelectedDntList[dnt].K6K22HeigaCategory__c,
              'szbtitiid': SelectedDntList[dnt].SzbtItiId__c,
              'stbkobetuid': SelectedDntList[dnt].StbKobetuId__c,
              'stbitiprefectures': SelectedDntList[dnt].StbItiPrefectures,
              'stbitimunicipalities': SelectedDntList[dnt].StbItiMunicipalities,
              'stbitiplace': SelectedDntList[dnt].StbItiPlace,
              'dntcategory': SelectedDntList[dnt].DntCategory__c,
              'kyogacategory': SelectedDntList[dnt].KyogaCategory__c,
              'id': SelectedDntList[dnt].RecordId,
            });
          }
        }
        this.setState({
          tableData: list,
        });
        return;
      }

      if (['A', 'AM', 'B', 'BM', 'C', 'D', 'DM', 'G'].includes(YbdsType)) {
        if (isNullOrWhiteSpace(values.NWZgsyo__c)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['事業所'],
            },
          });
          return;
        }
        if (isNullOrWhiteSpace(values.lineList)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['線路'],
            },
          });
          return;
        }
      }

      if (YbdsType == 'A' || YbdsType == 'AM' || YbdsType == 'D' || YbdsType == 'DM') {
        // 取付禁止柱データ取得
        const field =['Id', 'SzbtItiId__c', 'DntCategory__c', 'AttachProhLevel__c'];
        const filter = {
          'RecordType.DeveloperName': 'AttachKinsiTy',
          'SenroCode__c': values.lineList,
        };
        const ret = await this.props.doGetContractDntList(filter, field);
        if (ret.data.body.errorCode != '00000') {
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['電柱情報', '取得'],
            },
          });
          return;
        };
        this.AttachKinsiTy = ret.data.body.data;
      } else {
        this.AttachKinsiTy = [];
      }

      // ２２Kの線路を選択した場合は、自、至の指定は無効とし、全件表示する。
      const targetSenro = (!senroCodeList ? null :
        senroCodeList.filter((data) => data.senrocode == values.lineList));
      const disableSearchDntNo = (targetSenro && targetSenro[0] ?
         targetSenro[0].senrocodecategory == this.senrocodecategory22KV :
         false);

      const ret = await this.props.doGetDntSelectpole( {
        'ybdsType': YbdsType, // 呼び出し種別
        'targetPole': this.state.checkedradioitem, //  電柱マスタ選択 '6kV' or '22kV' ※呼び出し種別がGの場合、必須
        'adjoinAndAttachmentpole': values.adjoinAndAttachmentpole, //  隣接柱・付属柱を表示
        'firstDntNo': DntNo, // 第一電柱番号 ※隣接柱・付属柱を表示がtrueの場合、必須
        'useFilterSelectedDnt': false,  // 選択済契約電柱のみ(未使用)
        'SelectedDntList': [],  // 選択済契約電柱 ※選択済契約電柱のみがtrueの場合、必須(未使用)
        'NWZgsyo': values.NWZgsyo__c,  // 中電NW事業所（コード値）
        'SenroCode': values.lineList, // 線路コード
        'SenroCodeCategory': (targetSenro && targetSenro[0] ? targetSenro[0].senrocodecategory : '0'),
        'DntNo_From': (disableSearchDntNo ? '' : values.poleNoFrom), //  電柱番号（FROM）
        'DntNo_To': (disableSearchDntNo ? '' : values.poleNoTo), //  電柱番号（To）
        'DntCategoryOur': this.DntCategoryOur,
        'DntCategoryOther': this.DntCategoryOther,
        'KyogaCategoryOurContract': this.KyogaCategoryOurContract,
        'KyogaCategoryOtherContract': this.KyogaCategoryOtherContract,
        'KyogaCategoryOurSpan': this.KyogaCategoryOurSpan,
        'KyogaCategoryOtherSpan': this.KyogaCategoryOtherSpan,
        'SzbtItiId': SzbtItiId,
        'StbKobetuId': StbKobetuId,
      });

      if (ret == null) {
        this.props.doShowMessage({
          message: {
            id: 'CE0057',
            values: ['件数が多すぎる', '取得'],
          },
        });
        return;
      } else if (ret.data.body.errorCode != '00000') {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['電柱番号', '表示'],
          },
        });
        return;
      } else if (ret.data.body.data.length == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0016',
          },
        });
      }

      this.setState({
        tableData: ret.data.body.data,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['電柱番号', '表示'],
        },
      });
    }
  }

  serchPoleIsetsuirai = async () => {
    try {
      const {values, YbdsType, StbType, KyogaType,
        senroCodeList, userInfo, generalPurposeMap} = this.props;
      const filter = {};
      if (['E', 'EM'].includes(YbdsType)) {
        if (isNullOrWhiteSpace(values.NWZgsyo__c)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['事業所'],
            },
          });
          return;
        }
        if (isNullOrWhiteSpace(values.lineList)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0027',
              values: ['線路'],
            },
          });
          return;
        }
      }
      filter['NWZgsyo__r.Code__c'] = values.NWZgsyo__c;
      if (['E', 'EM'].includes(YbdsType)) {
        const targetSenro = senroCodeList.filter(
            (senro) => senro.senrocode == values.lineList);
        if (targetSenro &&
          targetSenro[0].senrocode == targetSenro[0].senroname) {
          filter['SenroName__c_EQ'] = values.lineList;
          filter['DntNoManualInput__c_From'] = values.poleNoFrom;
          filter['DntNoManualInput__c_To'] = values.poleNoTo;
        } else {
          filter['SenroCode__c'] = values.lineList;
          if (targetSenro &&
            targetSenro[0].senrocodecategory != this.senrocodecategory22KV) {
            filter['K6KansnNo__c_From'] = (values.poleNoFrom ? values.poleNoFrom.padStart(3, '0') : values.poleNoFrom);
            filter['K6KansnNo__c_To'] = (values.poleNoTo ? values.poleNoTo.padStart(3, '0') : values.poleNoTo);
          }
        }
      } else {
        filter['SenroName__c'] = values.isetsuiraiFreeLine;
        filter['DntNo__c_From'] = values.isetsuiraiFreePoleNoFrom;
        filter['DntNo__c_To'] = values.isetsuiraiFreePoleNoTo;
      }
      if (['G'].includes(YbdsType)) {
        // 移設依頼対象共架種別の絞り込み
        const targetKyogaTypes = generalPurposeMap.KyogaType
            .filter((item) => item.ValueString3__c == '1')
            .map((o) => o.Code__c);
        filter['KyogaType__c'] = targetKyogaTypes;
      }
      if (!isNullOrWhiteSpace(values.DntCategory) && !['E', 'EM'].includes(YbdsType)) {
        filter['DntCategory__c'] = values.DntCategory;
      }
      filter['RecordType.DeveloperName'] = 'ContractDntmaster';
      filter['KyogaHaisiDate__c_EQ'] = null; // 共架廃止日
      let kyogacategory = null;
      if (this.state.checkedradioitem == 'haiden') {
        kyogacategory = 'C';
      } else if (this.state.checkedradioitem == 'souden') {
        kyogacategory = 'S';
      } else if (this.state.checkedradioitem == 'tusin') {
        kyogacategory = 'T';
      }
      if (['E', 'EM'].includes(YbdsType)) {
        filter['StbType__c'] = StbType;
        filter['KyogaType__c'] = KyogaType;
        filter['KyogaZgsyaCode__c'] = userInfo.KyogaZgsyaCode__c;
        filter['KyogaZgsyoCode__c'] = userInfo.KyogaZgsyoCode__c;
      } else {
        filter['KyogaCategory__c'] = kyogacategory;
      }

      const field =['Id', 'NWZgsyo__c', 'OldNWZgsyo__c', 'NWZgsyo__r.Code__c', 'SenroCode__c', 'SenroName__c', 'SenroNameKana__c',
        'DntNo__c', 'DntNoManualInput__c', 'K6KansnNo__c', 'K6Bunk1__c', 'K6Bunk2__c', 'KyogaCategory__c',
        'K6Bunk3__c', 'K22SzbtNo__c', 'K22GatiCategory__c', 'K6K22HeigaCategory__c', 'DntCategory__c', 'SzbtItiId__c', 'StbKobetuId__c',
        'IskkCategory__c', 'IskkAiteZgsya1__c', 'IskkAiteZgsya1__r.Name', 'IskkAiteZgsya2__c', 'IskkAiteZgsya2__r.Name',
        'IskkAiteZgsya3__c', 'IskkAiteZgsya3__r.Name', 'Dsp_DntNo__c', 'KyogaPriceCategory__c'];
      const sortParams = {'Dsp_DntNo__c': 1};
      const ret =
        await this.props.doGetContractDntList(filter, field, sortParams);
      if (ret.data.body.errorCode != '00000') {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['電柱情報', '取得'],
          },
        });
        return;
      };
      const filterdContractDntList = Array.from(
          new Map(this.props.contractDntList.map((o) =>[
            o.NWZgsyo__c +'-'+ (o.DntNo__c ? o.SenroCode__c+'-'+ o.DntNo__c + (o.StbKobetuId__c ? '-'+o.StbKobetuId__c : '') : (o.SenroName__c ? o.SenroName__c+'-': '') + o.DntNoManualInput__c) +'-'+o.DntCategory__c, // 一意のMap key
            o,
          ] )),
      );
      const list = [];
      filterdContractDntList.map((item) =>
        list.push({
          'id': item[1].Id,
          'nwzgsyo': item[1].NWZgsyo__r ? item[1].NWZgsyo__r.Code__c: null,
          'oldnwzgsyo': item[1].OldNWZgsyo__c,
          'k6senrocode': item[1].SenroCode__c,
          'senroname': item[1].SenroName__c,
          'senronamekana': item[1].SenroNameKana__c,
          'senrocode': item[1].SenroCode__c,
          'dntno': item[1].DntNo__c,
          'dntnomanualinput': item[1].DntNoManualInput__c,
          'k6kansnno': item[1].K6KansnNo__c,
          'k6bunk1': item[1].K6Bunk1__c,
          'k6bunk2': item[1].K6Bunk2__c,
          'k6bunk3': item[1].K6Bunk3__c,
          'k22szbtno': item[1].K22SzbtNo__c,
          'k22gaticategory': item[1].K22GatiCategory__c,
          'k6k22heigacategory': item[1].K6K22HeigaCategory__c,
          'dntcategory': item[1].DntCategory__c,
          'szbtitiid': item[1].SzbtItiId__c,
          'stbkobetuid': item[1].StbKobetuId__c,
          'kyogacategory': item[1].KyogaCategory__c,
          'IskkCategory__c': item[1].IskkCategory__c,
          'IskkAiteZgsya1__c': item[1].IskkAiteZgsya1__c,
          'IskkAiteZgsya1Name': item[1].IskkAiteZgsya1__r ? item[1].IskkAiteZgsya1__r.Name : null,
          'IskkAiteZgsya2__c': item[1].IskkAiteZgsya2__c,
          'IskkAiteZgsya2Name': item[1].IskkAiteZgsya2__r ? item[1].IskkAiteZgsya2__r.Name : null,
          'IskkAiteZgsya3__c': item[1].IskkAiteZgsya3__c,
          'IskkAiteZgsya3Name': item[1].IskkAiteZgsya3__r ? item[1].IskkAiteZgsya3__r.Name : null,
          'KyogaPriceCategory__c': item[1].KyogaPriceCategory__c,
        }),
      );
      // 検索結果0件の場合、エラーダイアログ表示
      if (list.length <= 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0016',
          },
        });
      } else {
        this.setState({tableData: list});
      }
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['電柱番号', '表示'],
        },
      });
    }
  }


  returnPole = () =>{
    // 表示外の選択電柱を除く
    const tableIds=(this.state.tableData ?
      this.state.tableData.map((data)=>data.id) : []);
    if (tableIds.length<=0) {
      this.props.doShowMessage({
        message: {
          id: 'CE0027',
          values: ['電柱'],
        },
      });
      return;
    }
    const kinsiTy = (this.AttachKinsiTy && this.AttachKinsiTy.length > 0 ?
      this.AttachKinsiTy.filter((kinsiData) => kinsiData.AttachProhLevel__c == '2') :
      []);
    const selectedPoles =this.gridRef.current.selectedItems.filter((item) => {
      // 契約禁止柱を除く
      if (kinsiTy && kinsiTy.length > 0) {
        const hasKinsi = kinsiTy.filter((data) =>
          (data.SzbtItiId__c == item.dataItem.szbtitiid &&
           data.DntCategory__c == item.dataItem.dntcategory));
        if (hasKinsi && hasKinsi.length > 0) {
          return false;
        }
      }
      return tableIds.includes(item.dataItem.id);
    });

    if (selectedPoles.length<=0) {
      this.props.doShowMessage({
        message: {
          id: 'CE0027',
          values: ['電柱'],
        },
      });
      return;
    }

    const warnTy = (this.AttachKinsiTy && this.AttachKinsiTy.length > 0 ?
      this.AttachKinsiTy.filter((kinsiData) => kinsiData.AttachProhLevel__c == '1') :
      []);
    if (warnTy && warnTy.length > 0) {
      const warnPoles = selectedPoles.filter((item) => {
        const hasWarn = warnTy.filter((data) =>
          (data.SzbtItiId__c == item.dataItem.szbtitiid &&
            data.DntCategory__c == item.dataItem.dntcategory));
        if (hasWarn && hasWarn.length > 0) {
          return true;
        }
        return false;
      });
      if (warnPoles && warnPoles.length > 0) {
        // 特殊電柱が含まれる場合警告
        this.props.doShowMessage({
          message: {
            id: 'CW0152',
            values: ['電柱'],
          },
          action: () => {
            this.retunPoleCallback(selectedPoles);
          },
          type: 'warn',
        });
        return;
      }
    }
    this.retunPoleCallback(selectedPoles);
  }

  retunPoleCallback = async (selectedPoles) => {
    const retPoles = [];
    const poledata = selectedPoles.map((row)=>row._data);
    for (const row in poledata) {
      if (poledata.hasOwnProperty(row)) {
        const retPole = new Pole(poledata[row]);
        if (this.state.checkedradioitem == 'haiden') {
          retPole.KyogaCategory__c = 'C';
        } else if (this.state.checkedradioitem == 'souden') {
          retPole.KyogaCategory__c = 'S';
        } else if (this.state.checkedradioitem == 'tusin') {
          retPole.KyogaCategory__c = 'T';
        }
        retPoles.push(retPole);
      }
    }
    this.props.setSelectedPole(retPoles);
    this.clearItems();
    this.props.closeButton();
  }

  returnPoleFreeInput = async () =>{
    const {values, YbdsType, userInfo} = this.props;
    try {
      if (this.state.checkedboxitem != 'inputJieiNTT' && isNullOrWhiteSpace(values.NWZgsyo__c)) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['事業所'],
          },
        });
        return;
      }

      if (['G'].includes(YbdsType) && isNullOrWhiteSpace(values.lineFree)) {
        this.props.doShowMessage({
          message: {
            id: 'CE0017',
            values: ['線路名'],
          },
        });
        return;
      }

      if (isNullOrWhiteSpace(values.poleNoFree)) {
        this.props.doShowMessage({
          message: {
            id: 'CE0017',
            values: ['電柱番号'],
          },
        });
        return;
      }
      if (isNullOrWhiteSpace(values.DntCategory)) {
        this.props.doShowMessage({
          message: {
            id: 'CE0027',
            values: ['電柱区分'],
          },
        });
        return;
      }

      let retPole = null;
      if (['G'].includes(YbdsType)) {
        // 線路名に対応する線路コードがあるかチェック
        const filter = {};
        filter['NWZgsyo'] = values.NWZgsyo__c;
        filter['SenroName_EQ'] = values.lineFree;
        // 線路マスタから取得
        const ret = await execApexRestApi(userInfo, 'SenroCode/get', {'conditions': filter});
        const errCode = ret && ret.data && ret.data.body ?
          ret.data.body.errorCode : undefined;
        if (errCode != '00000') {
          console.error(errCode);
          this.props.doShowMessage({
            message: {
              id: 'CE0052',
              values: ['電柱', '指定'],
            },
          });
          return;
        }
        const senroResult = ret.data.body.data;
        if (senroResult.length != 1) {
          this.props.doShowMessage({
            message: {
              id: 'CE0017',
              values: ['存在する線路名'],
            },
          });
          return;
        }
        // 線路コード一致確認
        const senroCode = senroResult[0].senrocode;
        if (!values.poleNoFree.startsWith(senroCode)) {
          this.props.doShowMessage({
            message: {
              id: 'CE0017',
              values: ['線路名に対応した電柱番号'],
            },
          });
          return;
        }
        const result = await this.props.doGetDntListbyDspDntNo(
            values.poleNoFree, this.state.checkedradioitem, values.DntCategory);
        if (result.data.body.errorCode != '00000' || this.props.dntList.length != 1) {
          this.props.doShowMessage({
            message: {
              id: 'CE0017',
              values: ['存在する電柱番号'],
            },
          });
          return;
        }
        retPole = new Pole(this.props.dntList[0]);
      } else {
        let kyogacategory = null;
        if (this.state.checkedradioitem == 'inputHaiden') {
          kyogacategory='C';
        } else if ( this.state.checkedboxitem=='inputSouden') {
          kyogacategory='S';
        } else if (this.state.checkedboxitem=='inputTushin') {
          kyogacategory='T';
        } else if (this.state.checkedboxitem=='inputUnregisterd') {
          kyogacategory='C';
        } else if (this.state.checkedboxitem == 'inputJieiNTT') {
          kyogacategory='O';
        }
        const poleInfo ={
          nwzgsyo: (this.state.checkedboxitem == 'inputJieiNTT' ? null : values.NWZgsyo__c),
          oldnwzgsyo: values.NWZgsyo__c,
          senroname: values.lineFree,
          dntnomanualinput: values.poleNoFree,
          dntcategory: values.DntCategory,
          kyogacategory: kyogacategory,
          targetKyogaCategory: this.targetKyogaCategory,
        };
        retPole = new Pole(poleInfo);
      }

      this.props.setSelectedPole([retPole]);
      this.props.closeButton();
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['電柱', '指定'],
        },
      });
    }
  }

  setCheckItem = (e) => {
    const {values} = this.props;
    if (this.state.checkedboxitem == e.target.id || e.target.id == 'adjoinAndAttachmentpole') {
      this.setState({checkedboxitem: null}, ()=>this.setNWZgsyoList());
    } else {
      if (e.target.id != 'adjoinAndAttachmentpole') values.adjoinAndAttachmentpole = false;
      this.setState({checkedboxitem: e.target.id}, ()=>this.setNWZgsyoList());
    }
  }

  setRadioItem = (e) => {
    try {
      if (this.state.tableData &&
      this.state.tableData.length > 0) {
        this.props.doShowMessage({
          message: {
            id: 'CC0006',
            values: ['対象電柱', '変更'],
          },
          action: () =>{
            this.setState({
              checkedradioitem: e.target.value,
              tableData: null,
            }, ()=>this.setNWZgsyoList());
          },
        });
      } else {
        this.setState({
          checkedradioitem: e.target.value,
          tableData: null,
        }, ()=>this.setNWZgsyoList());
      }
    } catch (error) {

    }
  }

  addstrKyoyochu = (strKyogaCategory) => {
    if (['1', '2', 'O'].includes(strKyogaCategory)) {
      return '*';
    } else {
      return '';
    }
  }

  /**
   * クリアフラグがTRUEのとき、画面項目をクリアする
   */
  clearItems = () => {
    if (this.props.IsClear === true) {
      this.setState({tableData: null});
    }
  }

  /**
   * onFormtItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    const {YbdsType} = this.props;
    if ((YbdsType != 'A' && YbdsType != 'AM' && YbdsType != 'D' && YbdsType != 'DM') || !s.rows[e.row] || e.panel.cellType == wjgGrid.CellType.ColumnHeader || e.panel.cellType == wjgGrid.CellType.TopLeft) return;
    const data = s.rows[e.row]._data;
    const kinsiTy = this.AttachKinsiTy.filter((kinsiData) =>
      (kinsiData.SzbtItiId__c == data.szbtitiid &&
      kinsiData.DntCategory__c == data.dntcategory));
    if (kinsiTy && kinsiTy.length > 0) {
      if (kinsiTy[0].AttachProhLevel__c == '2') { // 取付禁止レベル：禁止
        e.cell.classList.add('wj-state-disabled');
        e.cell.classList.add('alert');
        if (e.panel.cellType == wjgGrid.CellType.RowHeader) {
          const inputElem = e.cell.querySelector('input');
          if (inputElem) {
            inputElem.disabled = true;
            if (inputElem.checked) inputElem.checked = false;
          }
        }
      } else if (kinsiTy[0].AttachProhLevel__c == '1') { // 取付禁止レベル：警告
        e.cell.classList.add('warning');
      }
    }
  }

  /**
   * onFilterChangeHandlerハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFilterChangeHandler = (s, e) => {
    const {generalPurposeMap} = this.props;
    let edt = s.activeEditor;
    let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
    let lb = wjCore.Control.getControl(lbHost);
    if (e.getColumn().binding === 'nwzgsyo') {
      lb.itemFormatter = (index) => {
        const ret = getComboName(generalPurposeMap, 'NWZgsyo', lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'dntcategory') {
      lb.itemFormatter = (index) => {
        const ret = getComboName(generalPurposeMap, 'DntCategory', lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  renderTable(data) {
    const {generalPurposeMap, YbdsType} = this.props;
    const headerType = ['A', 'B', 'C', 'D', 'E'].includes(YbdsType) ? 'radio': 'check';
    if (data) {
      for (const rowData of data) {
        rowData['dispsenroname'] = senroNameFormat(rowData.senroname, rowData.senronamekana, rowData.senrocode);
        if (rowData.k6k22heigacategory == '1') { // 併架あり
          rowData['dispdntno'] = dntNoFormat(
              undefined,
              rowData.dntnomanualinput,
              rowData.k6kansnno,
              rowData.k6bunk1,
              rowData.k6bunk2,
              rowData.k6bunk3,
              rowData.k22szbtno,
              rowData.k22gaticategory,
          ) + this.addstrKyoyochu(rowData.kyogacategory) + '/' +
          dntNoFormat(
              undefined,
              rowData.dntnomanualinput,
              '',
              rowData.k6bunk1,
              rowData.k6bunk2,
              rowData.k6bunk3,
              rowData.k22szbtno,
              rowData.k22gaticategory,
          ) + this.addstrKyoyochu(rowData.kyogacategory);
        } else { // 併架なし
          rowData['dispdntno'] = dntNoFormat(
              undefined,
              rowData.dntnomanualinput,
              rowData.k6kansnno,
              rowData.k6bunk1,
              rowData.k6bunk2,
              rowData.k6bunk3,
              rowData.k22szbtno,
              rowData.k22gaticategory,
          ) + (!isNullOrWhiteSpace(rowData.k22szbtno) ? '(22k)': '') +
          this.addstrKyoyochu(rowData.kyogacategory);
        }
        rowData['dispdntno'] = dntNoFormat(
            undefined,
            rowData.dntnomanualinput,
            rowData.k6kansnno,
            rowData.k6bunk1,
            rowData.k6bunk2,
            rowData.k6bunk3,
            rowData.k22szbtno,
            rowData.k22gaticategory,
        ) + (rowData.k6k22heigacategory != '1' && !isNullOrWhiteSpace(rowData.k22szbtno) ? '(22k)': '') +
        this.addstrKyoyochu(rowData.kyogacategory);
        rowData['dspStbkobetuid'] = (rowData['stbkobetuid'] ? String(rowData['stbkobetuid']) : '');
      }
    }

    const props = {
      checkboxOn: true,
      radioOn: false,
      colHeadersMergeOn: false,
      mergeHeaders: [],
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'None',
      counterOn: false,
      data: data,
      formatItemFunction: this.onFormatItemHandler,
      filterChanging: this.onFilterChangeHandler,
      style: {maxHeight: '400px'},
    };

    return (
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef}
          rowHeaderType={headerType}
          {...props}>
          <FlexGridColumnGroup binding='nwzgsyo' header='事業所' dataType="String" isReadOnly={true} width={95}>
            <FlexGridCellTemplate cellType="Cell"
              template={(ctx) => {
                const zgsyo = generalPurposeMap.NWZgsyo.filter((zgsyo) =>{
                  return zgsyo.Code__c == ctx.item.nwzgsyo;
                })[0];
                return zgsyo ? zgsyo.Name : null;
              }} />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='dispsenroname' header='線路名(線路コード）' dataType="String" isReadOnly={true} width={200}/>
          <FlexGridColumnGroup binding='dispdntno' header='電柱番号' dataType="String" isReadOnly={true} width={160}/>
          <FlexGridColumnGroup binding='dntcategory' header='電柱区分' dataType="String" isReadOnly={true} width={115}>
            <FlexGridCellTemplate cellType="Cell"
              template={(ctx) => {
                const category =
                 generalPurposeMap.DntCategory.filter((category) => {
                   return category.Code__c == ctx.item.dntcategory;
                 } )[0];
                return category ? category.Name : null;
              } } />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='dspStbkobetuid' header='設備個別ID' dataType="String" isReadOnly={true}></FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {forwardRef, classes, generalPurposeMap, handleSubmit,
      YbdsType, NWZgsyoNamefilter, DntNo, doSetSenroCodeList,
      values, senroCodeList, DspSenroName, DspDntNo} = this.props;
    // ２２Kの線路を選択した場合は、自、至の指定は無効とする。
    const targetSenro = (!senroCodeList ? null :
     senroCodeList.filter((data) => data.senrocode == values.lineList));
    const disableSearchDntNo = (targetSenro && targetSenro[0] ?
       targetSenro[0].senrocodecategory == this.senrocodecategory22KV : false);

    return (
      <Modal
        open={this.props.modalOpen}
        className={classes.modal}
        forwardRef={forwardRef}
        style={{
          maxWidth: 800,
          marginLeft: 'auto',
          marginRight: 'auto',
          overflow: 'visible',
        }}
      >
        <form noValidate autoComplete="off" style={{maxHeight: '100vh'}} >
          <PaperPart style={{maxHeight: '90vh', overflow: 'auto scroll'}}>
            {/* 2022/10/14 add start 恒久対応までの暫定対応コメント表示*/}
            <PaperPart >
              6kV-22kV併架柱については、6kV電柱番号で選択ください。(22kV電柱番号は選択不可)
            </PaperPart>
            {/* 2022/10/14 add end */}
            <PaperPart > {/* 条件入力制御部 */}
              <Grid
                container
                justifyContent="flex-start"
                alignitems="flex-start"
                textalign="flex-start"
                direction='column'
                spacing={1}
              >
                {((['A', 'AM', 'B', 'C', 'D', 'DM'].includes(YbdsType) &&
                (this.state.checkedboxitem == null || this.state.checkedboxitem == 'useFilterSelectedDnt')) ||
                (['G'].includes(YbdsType) &&
                ['6kV', '22kV'].includes(this.state.checkedradioitem)) )&&
                <>
                  {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
                  <div style={{display: 'none'}}>
                    <div id="dntNoDisplayHelpComponent">
                      <DntNoDisplay/>
                    </div>
                  </div>
                </>
                }
                {['G'].includes(YbdsType) &&
                  <Grid container key="key-s-1-1" item xs={12}>
                    <Grid container item xs={12}>
                      <FormControl>
                        <FormLabel style={{color: 'black'}}>対象電柱</FormLabel>
                        <FormLabel id='selectMst' style={{color: 'black'}}>電柱マスタ選択</FormLabel>
                        <RadioGroup
                          id="targetPole"
                          name="targetPole"
                          value={this.state.checkedradioitem}
                          onChange={this.setRadioItem}
                          label="電柱マスタ選択"
                          aria-labelledby='selectMst'
                          defaultValue={'6kV'}
                          row
                        >
                          <FormControlLabel value="6kV" control={<RadioB />} label="6kV  " />
                          <FormControlLabel value="22kV" control={<RadioB />} label="22kV" />
                        </RadioGroup>
                        <FormLabel id='freeInput' style={{color: 'black'}}>自由入力柱選択</FormLabel>
                        <RadioGroup
                          id="targetPole"
                          name="targetPole"
                          value={this.state.checkedradioitem}
                          onChange={this.setRadioItem}
                          row
                        >
                          <FormControlLabel value="haiden" control={<RadioB />} label="配電柱" />
                          <FormControlLabel value="souden" control={<RadioB />} label="送電柱" />
                          <FormControlLabel value="tusin" control={<RadioB />} label="通信柱" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                }

                {['B', 'C'].includes(YbdsType) &&
                    <Grid key="key-s-1-2-1" item xs={12}>
                      <div style={{paddingLeft: '15px'}}>
                        <Field className={classes.checkbox}
                          id="inputJieiNTT"
                          name="inputJieiNTT"
                          component={renderCheckbox}
                          checked={this.state.checkedboxitem=='inputJieiNTT'}
                          onChange={this.setCheckItem}
                          label="自営柱・NTT単独柱を入力"
                        />
                      </div>
                    </Grid>
                }

                {['A', 'AM', 'B', 'C', 'D', 'DM'].includes(YbdsType) &&
                <>
                  <Grid key="key-s-1-2-2" item xs={12}>
                    <div style={{paddingLeft: '15px'}}>
                      <Field className={classes.checkbox}
                        id="inputSouden"
                        name="inputSouden"
                        component={renderCheckbox}
                        checked={this.state.checkedboxitem == 'inputSouden'}
                        onChange={this.setCheckItem}
                        label="送電柱を入力"
                      />
                    </div>
                  </Grid>
                  <Grid key="key-s-1-2-3" item xs={12}>
                    <div style={{paddingLeft: '15px'}}>
                      <Field className={classes.checkbox}
                        id="inputTushin"
                        name="inputTushin"
                        component={renderCheckbox}
                        checked={this.state.checkedboxitem == 'inputTushin'}
                        onChange={this.setCheckItem}
                        label="通信柱を入力"
                      />
                    </div>
                  </Grid>
                  <Grid key="key-s-1-2-4" item xs={12}>
                    <div style={{paddingLeft: '15px'}}>
                      <Field className={classes.checkbox}
                        id="inputUnregisterd"
                        name="inputUnregisterd"
                        component={renderCheckbox}
                        checked={this.state.checkedboxitem == 'inputUnregisterd'}
                        onChange={this.setCheckItem}
                        label="未登録電柱を入力"
                      />
                    </div>
                  </Grid>
                </>
                }

                { !isNullOrWhiteSpace(DntNo) &&
               <>
                 <Grid key="key-s-1-3" item xs={12}>
                   <div style={{paddingLeft: '15px'}}>
                     <Field className={classes.checkbox}
                       id="adjoinAndAttachmentpole"
                       name="adjoinAndAttachmentpole"
                       onChange={this.setCheckItem}
                       component={renderCheckbox}
                       label="隣接柱・付属柱を表示"
                     />
                   </div>
                 </Grid>
                 <div style={{paddingLeft: '50px'}}>
                   {DntNo ? '電柱番号 : '+ (DspSenroName ? DspSenroName : '') + ' ' + (DspDntNo ? DspDntNo : '') : ''}
                 </div>
               </>
                }
                {['E', 'EM'].includes(YbdsType) &&
                 <Grid key="key-s-1-4" item xs={12}>
                   <div style={{paddingLeft: '15px'}}>
                   ※契約に含まれる設備のみとなります。<br />
                   該当の電柱が一覧に表示されない場合は，訂正取付申込をお願いします。
                   </div>
                 </Grid>
                }

                {(['B', 'C'].includes(YbdsType) ) &&
                 <Grid key="key-s-1-5" item xs={12}>
                   <div style={{paddingLeft: '15px'}}>
                     <Field className={classes.checkbox}
                       id="useFilterSelectedDnt"
                       name="useFilterSelectedDnt"
                       component={renderCheckbox}
                       checked={this.state.checkedboxitem == 'useFilterSelectedDnt'}
                       onChange={this.setCheckItem}
                       label="選択済契約電柱のみ"
                     />
                   </div>
                 </Grid>
                }
              </Grid>
            </PaperPart>

            {((['A', 'AM', 'B', 'C', 'D', 'DM', 'E', 'EM', 'G'].includes(YbdsType) &&
              (this.state.checkedboxitem != 'inputJieiNTT'&& this.state.checkedboxitem != 'useFilterSelectedDnt') ) )&&
            <>
              <PaperPart visible={this.state.visibleOffice}> {/* 検索条件部 事業所 */}
                <Grid container
                  justifyContent="flex-start"
                  alignitems="flex-start"
                  spacing={1}
                >
                  <Grid key="key-s-2-1-1" item xs={12} sm={8}>
                    <Field
                      id="office"
                      name="office"
                      className={classes.fields}
                      component={renderTextField}
                      label="事業所"
                      fullWidth
                      type="text"
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key-s-2-1-2" item xs={12} sm={4}>
                    <PositiveButton
                      id="btnSearchOffice"
                      onClick={handleSubmit(this.setNWZgsyoList)}
                      variant="contained"
                      size="large"
                      type="button"
                      fullWidth={true}
                      className={classes.searchButton}
                    >
                      <span>事業所検索</span>
                    </PositiveButton>
                  </Grid>
                </Grid>
                <Grid container
                  justifyContent="flex-start"
                  alignitems="flex-start"
                  spacing={1}
                >
                  <Grid key="key-s-2-1-3" item xs={12}>
                    <Field
                      id="NWZgsyo__c"
                      name="NWZgsyo__c"
                      component={renderSelect}
                      label="事業所一覧"
                      fullWidth
                      onChange={()=>doSetSenroCodeList(null)}
                    >
                      {createSearchList(NWZgsyoNamefilter, 'Code__c', 'Name', null, true)}
                    </Field>
                  </Grid>
                </Grid>
              </PaperPart>
            </>
            }
            { ((['A', 'AM', 'B', 'C', 'D', 'DM', 'E', 'EM'].includes(YbdsType) &&
           this.state.checkedboxitem == null ) ||
           (['G'].includes(YbdsType) &&
            ['6kV', '22kV'].includes(this.state.checkedradioitem)) )&&
             <>
               <PaperPart visible={this.state.visibleLine}> {/* 検索条件部 線路 */}
                 <Grid container
                   justifyContent="flex-start"
                   alignitems="flex-start"
                   spacing={1}
                 >
                   <Grid key="key-s-2-2-1" item xs={12} sm={8}>
                     <Field
                       id="line"
                       name="line"
                       className={classes.fields}
                       component={renderTextField}
                       label="線路名"
                       fullWidth
                       type="text"
                       required={false}
                       validate={[
                       ]}
                     />
                   </Grid>
                   <Grid key="key-s-2-2-2" item xs={12} sm={4}>
                     <PositiveButton
                       id="btnSearchLine"
                       onClick = {handleSubmit(this.setSenroList)}
                       variant="contained"
                       size="large"
                       type="button"
                       fullWidth={true}
                       className={classes.searchButton}
                     >
                       <span>線路名検索</span>
                     </PositiveButton>
                   </Grid>
                 </Grid>
                 <Grid container
                   justifyContent="flex-start"
                   alignitems="flex-start"
                   spacing={1}
                 >
                   <Grid key="key-s-2-2-3" item xs={12}>
                     <Field
                       id="lineList"
                       name="lineList"
                       component={renderSelect}
                       label="線路名一覧"
                       fullWidth
                     >
                       {this.createSenroOptions()}
                     </Field>
                   </Grid>
                 </Grid>
               </PaperPart>
             </>
            }
            {((['E', 'EM'].includes(YbdsType) &&(this.state.checkedboxitem == null || this.state.checkedboxitem == 'useFilterSelectedDnt')))&&
              <>
                <PaperPart> {/* 検索条件部 電柱番号 */}
                  <Grid container
                    justifyContent="flex-start"
                    alignitems="center"
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Grid container
                        justifyContent="flex-start"
                        alignitems="center"
                        spacing={2}
                      >
                        <Grid key="key-s-3-1" item xs={4}>
                          <Field
                            id="poleNoFrom"
                            name="poleNoFrom"
                            className={classes.fields}
                            component={renderTextField}
                            label="電柱番号"
                            fullWidth
                            type="text"
                            required={false}
                            disabled={disableSearchDntNo}
                            validate={[
                            ]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid key="key-s-3-2" item xs={1} className={classes.waveGrid}>
                          <span className={classes.wave}>～</span>
                        </Grid>
                        <Grid key="key-s-3-3" item xs={4}>
                          <Field
                            id="poleNoTo"
                            name="poleNoTo"
                            className={classes.fields}
                            component={renderTextField}
                            label={<React.Fragment />}
                            fullWidth
                            type="text"
                            disabled={disableSearchDntNo}
                            required={false}
                            validate={[
                            ]}
                          />
                        </Grid>

                        <Grid key="key-s-3-4" item xs={12} sm={3}>
                          <PositiveButton
                            id="btnDisplayPoleNo"
                            className={classes.searchButton}
                            onClick={handleSubmit(this.serchPoleIsetsuirai)}
                            variant="contained"
                            size="large"
                            type="button"
                            fullWidth
                          >
                            <span>電柱番号表示</span>
                          </PositiveButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </PaperPart>
              </>
            }
            {((['A', 'AM', 'B', 'C', 'D', 'DM'].includes(YbdsType) &&
            (this.state.checkedboxitem == null || this.state.checkedboxitem == 'useFilterSelectedDnt')) ||
            (['G'].includes(YbdsType) &&
            ['6kV', '22kV'].includes(this.state.checkedradioitem)) )&&
              <>
                <PaperPart> {/* 検索条件部 電柱番号 */}
                  <Grid container
                    justifyContent="flex-start"
                    alignitems="center"
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Grid container
                        justifyContent="flex-start"
                        alignitems="center"
                        spacing={2}
                      >
                        <Grid key="key-s-3-1" item xs={3}>
                          <Field
                            id="poleNoFrom"
                            name="poleNoFrom"
                            className={classes.fields}
                            component={renderTextField}
                            label="電柱番号"
                            fullWidth
                            type="text"
                            required={false}
                            disabled={disableSearchDntNo}
                            validate={[
                            ]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid key="key-s-3-2" item xs={1} className={classes.waveGrid}>
                          <span className={classes.wave}>～</span>
                        </Grid>
                        <Grid key="key-s-3-3" item xs={3}>
                          <Field
                            id="poleNoTo"
                            name="poleNoTo"
                            className={classes.fields}
                            component={renderTextField}
                            label={<React.Fragment />}
                            fullWidth
                            type="text"
                            required={false}
                            disabled={disableSearchDntNo}
                            validate={[
                            ]}
                          />
                        </Grid>

                        <Grid key="key-s-3-4" item xs={12} sm={3}>
                          <PositiveButton
                            id="btnDisplayPoleNo"
                            className={classes.searchButton}
                            onClick={handleSubmit(this.searchPole)}
                            variant="contained"
                            size="large"
                            type="button"
                            fullWidth
                          >
                            <span>電柱番号表示</span>
                          </PositiveButton>
                        </Grid>
                        <Grid key="key-s-3-5" item xs={1} className={classes.waveGrid}>
                          <MuiTooltip content={
                            <React.Fragment>
                              <DntNoDisplay/>
                            </React.Fragment>
                          }>
                            <HelpIcon/>
                          </MuiTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </PaperPart>
              </>
            }
            {['G'].includes(YbdsType) &&
           ['haiden', 'souden', 'tusin'].includes(this.state.checkedradioitem) &&
           <>
             <PaperPart>
               <Grid container
                 justifyContent="flex-start"
                 alignitems="center"
                 spacing={3}
               >
                 <Grid item xs={12}>
                   <Field
                     id="isetsuiraiFreeLine"
                     name="isetsuiraiFreeLine"
                     className={classes.fields}
                     component={renderTextField}
                     label="線路名"
                     fullWidth
                     type="text"
                     required={false}
                     validate={[
                     ]}
                   />
                 </Grid>
                 <Grid item xs={4}>
                   <Field
                     id="isetsuiraiFreePoleNoFrom"
                     name="poleNoFrom"
                     className={classes.fields}
                     component={renderTextField}
                     label="電柱番号"
                     fullWidth
                     type="text"
                     required={false}
                     validate={[
                     ]}
                     InputLabelProps={{
                       shrink: true,
                     }}
                   />
                 </Grid>
                 <Grid item xs={1} className={classes.waveGrid}>
                   <span className={classes.wave}>～</span>
                 </Grid>
                 <Grid item xs={4}>
                   <Field
                     id="isetsuiraiFreePoleNoTo"
                     name="isetsuiraiFreePoleNoTo"
                     className={classes.fields}
                     component={renderTextField}
                     label={<React.Fragment />}
                     fullWidth
                     type="text"
                     required={false}
                     validate={[
                     ]}
                   />
                 </Grid>
                 <Grid container item xs={12} sm={3}>
                   <Field
                     id="DntCategory"
                     name="DntCategory"
                     label="電柱区分"
                     className={classes.fields}
                     component={renderSelect}
                     fullWidth
                     required={false}
                     validate={[]} >
                     {createSearchList(generalPurposeMap.DntCategory,
                         'Code__c', 'Name', {'ValueNum1__c': [1]})}
                   </Field>
                 </Grid>
                 <Grid container
                   justifyContent='flex-start'
                   alignitems='flex-start'
                   spacing={1}>
                   <Grid item xs={12} sm={3}>
                     <PositiveButton
                       id="btnApplyPole"
                       className={classes.fieldButton}
                       onClick={handleSubmit(this.serchPoleIsetsuirai)}
                       variant="contained"
                       size="large"
                       type="button"
                       fullWidth={true}
                     >
                       <span>電柱検索</span>
                     </PositiveButton>
                   </Grid>
                   <Grid item xs={1}/>
                   <Grid item xs={12} sm={3}>
                     <NegativeButton props={this.props}
                       variant="contained"
                       startIcon={<KeyboardReturnIcon />}
                       size="large"
                       fullWidth={true}
                       onClick={
                         this.props.closeButton} >
                       <span>戻る</span>
                     </NegativeButton>
                   </Grid>
                 </Grid>
               </Grid>
             </PaperPart>
           </>
            }
            { (['A', 'AM', 'B', 'C', 'D', 'DM', 'E', 'EM', 'G'].includes(YbdsType) &&
           (this.state.checkedboxitem == 'useFilterSelectedDnt'||this.state.checkedboxitem == null) ) &&
           <>
             <PaperPart> {/* 一覧表示部 */}
               <Grid container
                 justifyContent="flex-start"
                 alignitems="flex-start"
                 spacing={1}
               >
                 <Grid key="key-s-4-1" item xs={12}>
                   {this.renderTable(this.state.tableData)}
                 </Grid>
                 <Grid key="key-s-4-2" item xs={12} sm={3}>
                   <PositiveButton
                     id="btnSelectPole"
                     className={classes.fieldButton}
                     onClick={handleSubmit(this.returnPole)}
                     variant="contained"
                     size="large"
                     type="button"
                     fullWidth
                   >
                     <span>電柱選択</span>
                   </PositiveButton>
                 </Grid>
                 <Grid item xs={1}/>
                 <Grid key="key-s-4-3" item xs={12} sm={3} >
                   <NegativeButton props={this.props}
                     variant="contained"
                     startIcon={<KeyboardReturnIcon />}
                     size="large"
                     onClick={() => {
                       this.clearItems();
                       this.props.closeButton();
                     }}
                     fullWidth
                   >
                     <span>戻る</span>
                   </NegativeButton>
                 </Grid>
               </Grid>
             </PaperPart>
           </>
            }

            {((['A', 'AM', 'B', 'C', 'D', 'DM'].includes(YbdsType) &&
           (this.state.checkedboxitem != 'useFilterSelectedDnt'&&this.state.checkedboxitem !=null) ) ||
           (['G'].includes(YbdsType) &&
           ['6kV', '22kV'].includes(this.state.checkedradioitem))) &&
           <PaperPart >{/* 自由入力部（他社柱：NTT、自営柱）*/}
             <Grid container
               justifyContent="flex-start"
               alignitems="flex-start">
               <Grid container
                 justifyContent="flex-start"
                 alignitems="flex-start"
                 spacing={2}
               >
                 <Grid container key="key-s-5-1" direction='row' item xs={12} sm={4}>
                   <Field
                     id="lineFree"
                     name="lineFree"
                     className={classes.fields}
                     component={renderTextField}
                     label="線路名"
                     fullWidth
                     type="text"
                     required={false}
                     validate={[
                     ]}
                   />
                 </Grid>

                 <Grid container direction='row' key="key-s-5-2" item xs={12} sm={4}>
                   <Field
                     id="poleNoFree"
                     name="poleNoFree"
                     className={classes.fields}
                     component={renderTextField}
                     label="電柱番号"
                     fullWidth
                     type="text"
                     required={false}
                     validate={[
                     ]}
                   />
                 </Grid>
                 <Grid container key="key-s-5-3" item xs={12} sm={4}>
                   <Field
                     id="DntCategory"
                     name="DntCategory"
                     label="電柱区分"
                     className={classes.fields}
                     component={renderSelect}
                     fullWidth
                     required={false}
                     validate={[]} >
                     {createSearchList(generalPurposeMap.DntCategory,
                         'Code__c', 'Name', {'ValueNum1__c': [1]}, true)}
                   </Field>

                 </Grid>
               </Grid>
               <Grid container
                 justifyContent="flex-start"
                 alignitems="flex-start"
                 spacing={2}>
                 <Grid item xs={12} sm={3}>
                   <PositiveButton
                     id="btnApplyPole"
                     className={classes.fieldButton}
                     onClick={handleSubmit(this.returnPoleFreeInput)}
                     variant="contained"
                     size="large"
                     type="button"
                     fullWidth
                   >
                     <span>電柱指定</span>
                   </PositiveButton>
                 </Grid>
                 <Grid item xs={1}/>
                 <Grid item xs={12} sm={3}>
                   <NegativeButton props={this.props}
                     variant="contained"
                     startIcon={<KeyboardReturnIcon />}
                     size="large"
                     onClick={ this.props.closeButton}
                     fullWidth
                   >
                     <span>戻る</span>
                   </NegativeButton>
                 </Grid>
               </Grid>
             </Grid>
           </PaperPart>
            }
          </PaperPart>
        </form>
      </Modal>
    );
  }
};

SelectPoleNo.propTypes={
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  history: PropTypes.object,
  forwardRef: PropTypes.object,
  generalPurposeMap: PropTypes.object.isRequired,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  // 共通関数
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetContractDntList: PropTypes.func,
  doGetContractDntORFilter: PropTypes.func,
  doGetSenroCodeList: PropTypes.func.isRequired,
  doSetSenroCodeList: PropTypes.func.isRequired,
  doGetDntList: PropTypes.func.isRequired,
  doGetDntSelectpole: PropTypes.func.isRequired,
  doGetDntListbyDspDntNo: PropTypes.func.isRequired,
  doGetNWZgsyoNamefilter: PropTypes.func.isRequired,
  // Storeオブジェクト
  contractDntList: PropTypes.array,
  contractDntORFilter: PropTypes.array,
  contractDntOrFilter: PropTypes.array,
  senroCodeList: PropTypes.array,
  NWZgsyoNamefilter: PropTypes.array,
  dntList: PropTypes.array,
  // 独自関数
  setNWZgsyoList: PropTypes.func,
  // 呼び出し元設定関数
  modalOpen: PropTypes.bool.isRequired,
  closeButton: PropTypes.func.isRequired,
  setSelectedPole: PropTypes.func.isRequired,
  // 呼び出し元設定プロパティ
  YbdsType: PropTypes.string.isRequired,
  YdbsKey: PropTypes.string,
  StbType: PropTypes.string,
  KyogaType: PropTypes.string,
  SzbtItiId: PropTypes.string,
  StbKobetuId: PropTypes.string,
  DntNo: PropTypes.string,
  DspSenroName: PropTypes.string,
  DspDntNo: PropTypes.string,
  SelectedDntList: PropTypes.array,
  IsClear: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const initValue = {
    DntCategory: '01', // 電柱区分 - 本柱
    targetPole: '6kV', // 電柱マスタ選択 - 6kV
  };
  const values = state.form.SelectPoleNo &&
    {...state.form.SelectPoleNo.values};
  if (values) {
    if (values.useFilterSelectedDnt ) { // 選択済契約電柱のみ
      initValue['useFilterSelectedDnt'] = true;
    }

    if (state.common.nwzgsyoNamefilter &&
        state.common.nwzgsyoNamefilter.map((o)=>o.Code__c) // 事業所コンボ
            .includes(values.NWZgsyo__c)) {
      initValue['NWZgsyo__c'] = values.NWZgsyo__c;
    } else {
      if (state.common.nwzgsyoNamefilter &&
          state.common.nwzgsyoNamefilter.length > 0) {
        initValue['NWZgsyo__c'] = state.common.nwzgsyoNamefilter[0].Code__c;
      }
    }
    if (state.senroCode.senroCodeList &&
      state.senroCode.senroCodeList.map((o)=>o.senrocode) // 線路コンボ
          .includes(values.lineList)) {
      initValue['lineList'] = values.lineList;
    } else {
      if (state.senroCode.senroCodeList &&
          state.senroCode.senroCodeList.length > 0) {
        initValue['lineList'] = state.senroCode.senroCodeList[0].senrocode;
      }
    }
    initValue['office']=values.office; // 事業所検索欄
    initValue['line'] = values.line; // 線路検索欄
  }

  return {
    userInfo: state.auth.userInfo,
    generalPurposeMap: state.common.generalPurposeMap,
    contractDntList: state.contractDnt.contractDntList,
    contractDntOrFilter: state.contractDnt.contractDntORFilter,
    dntList: state.dnt.dntList,
    senroCodeList: state.senroCode.senroCodeList,
    NWZgsyoNamefilter: state.common.nwzgsyoNamefilter,
    initialValues: initValue,
  };
};

const mapDispatchToProps = {
  doGetContractDntList: contractDntOperations.doGetContractDntList,
  doGetContractDntORFilter: contractDntOperations.doGetContractDntORFilter,
  doGetSenroCodeList: senroCodeOperations.doGetSenroCodeList,
  doSetSenroCodeList: senroCodeOperations.doSetSenroCodeList,
  doGetNWZgsyoNamefilter: commonOperations.doGetNWZgsyoNamefilter,
  doGetDntList: dntOperations.doGetDntList,
  doGetDntListbyDspDntNo: dntOperations.doGetDntbyDspDntNo,
  doShowMessage: commonOperations.doShowMessage,
  doGetDntSelectpole: commonOperations.doGetDntSelectpole,
};

const FORM_NAME = 'SelectPoleNo';

SelectPoleNo = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(SelectPoleNo));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SelectPoleNo),
); ;

class Pole {
  constructor(props) {
    this.NWZgsyo__r_Code__c = props.nwzgsyo ? props.nwzgsyo : null;// (選択電柱の)事業所コード
    this.OldNWZgsyo__c = props.oldnwzgsyo ? props.oldnwzgsyo : null;// 旧事業所コード
    this.SenroCode__c = props.senrocode ? props.senrocode : null; // 線路コード
    this.SenroName__c = props.senroname ? props.senroname : null; // 線路名
    this.SenroNameKana__c = props.senronamekana ? props.senronamekana : null;// 線路名カナ
    this.DntNo__c = props.dntno ? props.dntno : null;             // 電柱番号
    this.DntNoManualInput__c = props.dntnomanualinput ?
                               props.dntnomanualinput : null;     // 電柱番号_自由入力
    this.K6KansnNo__c = props.k6kansnno ? props.k6kansnno : null; // 6kV幹線No
    this.K6Bunk1__c = props.k6bunk1 ? props.k6bunk1 : null;       // 6kV分岐1
    this.K6Bunk2__c = props.k6bunk2 ? props.k6bunk2 : null;       // 6kV分岐2
    this.K6Bunk3__c = props.k6bunk3 ? props.k6bunk3 : null;       // 6kV分岐3
    this.K22SzbtNo__c = props.k22szbtno ? props.k22szbtno : null; // 22kV支持物番号
    this.K22GatiCategory__c = props.k22gaticategory ?
                               props.k22gaticategory : null;      // 22kV架地区分
    this.K6K22HeigaCategory__c = props.k6k22heigacategory ?
                                 props.k6k22heigacategory : null; // 6kV22kV併架区分
    this.SzbtItiId__c = props.szbtitiid ? props.szbtitiid : null; // 支持物位置ID
    this.StbKobetuId__c = props.stbkobetuid ? props.stbkobetuid : null;// 設備個別ID
    this.StbItiPrefectures = props.stbitiprefectures ?
                        props.stbitiprefectures: null;            // 設備位置_都道府県
    this.StbItiMunicipalities = props.stbitimunicipalities ?
                        props.stbitimunicipalities : null;        // 設備位置_市区町村
    this.StbItiPlace = props.stbitiplace ?
                        props.stbitiplace : null;                 // 設備位置_場所
    this.DntCategory__c = props.dntcategory ? props.dntcategory : null;// 電柱区分
    this.KyogaCategory__c = props.kyogacategory ? props.kyogacategory : null;// 共架区分(1,2,5,6,7,9,(空白),C,H,S,T,O)
    this.RecordId = props.id ? props.id : null;                   // レコードID
    this.IskkCategory__c = props.IskkCategory__c ? props.IskkCategory__c : null;
    this.IskkAiteZgsya1__c = props.IskkAiteZgsya1__c ?
     props.IskkAiteZgsya1__c : null;
    this.IskkAiteZgsya1Name = props.IskkAiteZgsya1Name ?
     props.IskkAiteZgsya1Name : null;
    this.IskkAiteZgsya2__c = props.IskkAiteZgsya2__c ?
     props.IskkAiteZgsya2__c : null;
    this.IskkAiteZgsya2Name = props.IskkAiteZgsya2Name ?
     props.IskkAiteZgsya2Name : null;
    this.IskkAiteZgsya3__c = props.IskkAiteZgsya3__c ?
     props.IskkAiteZgsya3__c : null;
    this.IskkAiteZgsya3Name = props.IskkAiteZgsya3Name ?
     props.IskkAiteZgsya3Name : null;
    this.KyogaPriceCategory__c = props.KyogaPriceCategory__c ?
     props.KyogaPriceCategory__c : null;                          // 共架料区分
  }
}



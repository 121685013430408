const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const START_REQUEST = 'START_REQUEST';
const END_REQUEST = 'END_REQUEST';
const GET_APPLY_LIST = 'GET_APPLY_LIST';
const CHECK_ACCESS = 'CHECK_ACCESS';
const GET_PARAM = 'GET_PARAM';
const SET_STOCK_URL = 'SET_STOCK_URL';
const SET_STB_TYPE = 'SET_STB_TYPE';
const SET_REFERENCE_MODE = 'SET_REFERENCE_MODE';
const SET_TRANSITION_SRC_ID = 'SET_TRANSITION_SRC_ID';
const SET_YBDS_PATH = 'SET_YBDS_PATH';
const SET_GMN_WARNING = 'SET_GMN_WARNING';
const GET_DNT_SELECTPOLE = 'GET_DNT_SELECTPOLE';

// 汎用マスタ
const GET_ALL_GENERAL_PURPOSE_MAP = 'GET_ALL_GENERAL_PURPOSE_MAP';
const GET_GENERAL_PURPOSE_LIST = 'GET_GENERAL_PURPOSE_LIST';
const REGIST_GENERAL_PURPOSE = 'REGIST_GENERAL_PURPOSE';
const UPDATE_GENERAL_PURPOSE = 'UPDATE_GENERAL_PURPOSE';
const SET_GENERAL_PURPOSE_ID = 'SET_GENERAL_PURPOSE_ID';
const GET_NWZGSYO_NAMEFILTER = 'GET_NWZGSYO_NAMEFILTER';

// ログイン情報
const SET_IS_TERMS_ACCEPTED = 'SET_IS_TERMS_ACCEPTED';
const SET_HAS_JUYO_ZIKO = 'SET_HAS_JUYO_ZIKO';
const SET_IS_HOLD_PURCHASE = 'SET_IS_HOLD_PURCHASE';
const SET_LOGIN_DATE = 'SET_LOGIN_DATE';
const SET_IS_KIRIKAE_MAE = 'SET_IS_KIRIKAE_MAE';

// 添付ファイル
const REGIST_ATTACHEDFILES = 'REGIST_ATTACHEDFILES';
const GET_ATTACHED_FILE_LIST = 'GET_ATTACHED_FILE_LIST';
const DELETE_ATTACHED_FILE_LIST = 'DELETE_ATTACHED_FILE_LIST';
// 会社マスタ
const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
const RETRIEVE_ACCOUNT = 'RETRIEVE_ACCOUNT';
const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';
const GET_ACCOUNT_MASTER_LIST = 'GET_ACCOUNT_MASTER_LIST';
const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS';

// 担当者マスタ
const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
const GET_CONTACT = 'GET_CONTACT';
const SET_CONTACT_ID = 'SET_CONTACT_ID';
const GET_CONTACT_MASTER_LIST = 'GET_CONTACT_MASTER_LIST';
const SET_TEMP_CONTACT = 'SET_TEMP_CONTACT';

export default {
  START_REQUEST,
  END_REQUEST,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OPEN_MODAL,
  CLOSE_MODAL,
  GET_APPLY_LIST,
  CHECK_ACCESS,
  GET_PARAM,
  SET_STOCK_URL,
  GET_ALL_GENERAL_PURPOSE_MAP,
  SET_IS_TERMS_ACCEPTED,
  SET_HAS_JUYO_ZIKO,
  SET_IS_HOLD_PURCHASE,
  SET_LOGIN_DATE,
  SET_IS_KIRIKAE_MAE,
  GET_GENERAL_PURPOSE_LIST,
  REGIST_GENERAL_PURPOSE,
  UPDATE_GENERAL_PURPOSE,
  SET_GENERAL_PURPOSE_ID,
  GET_NWZGSYO_NAMEFILTER,
  GET_ACCOUNT_LIST,
  RETRIEVE_ACCOUNT,
  SET_ACCOUNT_ID,
  GET_ACCOUNT_MASTER_LIST,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNTS,
  GET_CONTACT_LIST,
  GET_CONTACT,
  SET_CONTACT_ID,
  GET_CONTACT_MASTER_LIST,
  SET_TEMP_CONTACT,
  REGIST_ATTACHEDFILES,
  GET_ATTACHED_FILE_LIST,
  DELETE_ATTACHED_FILE_LIST,
  SET_STB_TYPE,
  SET_REFERENCE_MODE,
  SET_TRANSITION_SRC_ID,
  SET_YBDS_PATH,
  SET_GMN_WARNING,
  GET_DNT_SELECTPOLE,
};

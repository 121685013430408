import types from './types';

// 請求明細 取得(ID指定)
export const getBillingDetailId = (billingDetailIdSpecify) => ({
  type: types.GET_BILLINGDETAIL_ID,
  billingDetailIdSpecify,
});

// 請求明細一覧 取得
export const getBillingDetailList = (billingDetailList) => ({
  type: types.GET_BILLINGDETAIL_LIST,
  billingDetailList,
});

// 定期共架料内訳一覧 取得（OR条件あり）
export const getBillingDetailListORFilter = (billingDetailListORFilter) => ({
  type: types.GET_BILLINGDETAIL_LIST_ORFILTER,
  billingDetailListORFilter,
});

// 請求明細ID 設定
export const setBillingDetailId = (billingDetailId) => ({
  type: types.SET_BILLINGDETAIL_ID,
  billingDetailId,
});

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import {Grid} from '@material-ui/core';
import {DataMap, CellType} from '@grapecity/wijmo.grid';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {KyogasyaRemarksKozi, IsetuIraiNoticeKozi} from '../../../../../molecules/HelpComponent.js';
import {changeDateFormat, getDataFromGeneralPurposeMap,
  getCodeFromGeneralPurposeMap, execApexRestApi, getToDate,
  API_CODE, getResponseResult, createDataMapByComboList} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import HelpIcon from '@material-ui/icons/Help';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';

const styles = (theme) => ({
});

const appFields = [
  // 共架事業者
  'OrderKyogaZgsya__r.KyogaZgsyaCode__c',
  'OrderKyogaZgsya__r.Account__r.Name',
  // 共架事業所
  'OrderKyogaZgsya__r.KyogaZgsyoCode__c',
  'OrderKyogaZgsya__r.Name',
  // 保守担当<保守担当箇所>
  'MaintenanceKoziCompany__r.ZipCode__c',
  'MaintenanceKoziCompany__r.Address__c',
  'MaintenanceKoziCompany__r.Name',
  'MaintenanceKoziCompany__r.RepresentativeName__c',
  'MaintenanceKoziCompany__r.Tel__c',
  'MaintenanceKoziCompany__r.Account__c',
  'MaintenanceKoziCompany__r.MailAddress__c',
  // 共架担当者<共架事業者>
  'KyogaTanto__c',
  'OrderKyogaZgsya__r.ZipCode__c',
  'OrderKyogaZgsya__r.Address__c',
  'OrderKyogaZgsya__r.RepresentativeName__c',
  'OrderKyogaZgsya__r.Tel__c',
  'OrderKyogaZgsya__r.MailAddress__c',
  'MailKyogaZgsya__c',
  'EachKyogasyaMessage__c',
  'EachKyogasyaRemarks__c',
  'IsetuIraiContentsAcceptedDate__c',
  'IstIriKoziDateConfirmAccepted__c',
  'IstIriKoziDateChangeAccepted__c',
  'KyogaKoziScheduleDate__c',
  'IsetuIraiTarget__c',
  'IsetuIraiNoticeTarget__c',
  'IstIriNoticeDateContact__c',
  'NWKoziDateContactDateChange__c',
  'NWKoziDateContactDateConfirm__c',
  'NWKoziDateContactDateStop__c',
  'TargetStbNone__c',
  // 画面表示外
  'Id',
  'KyogaType__c',
  'OrderKyogaZgsya__c',
  'MaintenanceKoziCompany__c',
  'OrderStatus__c',
  'IsetuIraiDesignDocument__c',
  'IsetuIraiDesignDocument__r.IsetuIraiStatus__c',
  'IsetuIraiDesignDocument__r.KoziStop__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDate__c',
  'IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c',
  'IsetuIraiDesignDocument__r.KoziDateConfirmRegistDate__c',
  'IsetuIraiDesignDocument__r.KoziDateChangeConfirmRegistDate__c',
  'IsetuIraiDesignDocument__r.LastModifiedDate',
  'LastModifiedDate',
];
// 申込に紐づく、契約電柱の検索対象
const subqueryOrder = [{
  'fields': [
    'Id', 'KyogaType__c', 'LastModifiedDate', 'DntNo__c', 'KyogaZgsyaCode__c', 'KyogaIsetuKoziCompletionDate__c',
    'KyogaIsetuKoziContents__c', 'MainteKoziCompanyCode__c', 'SerialNumber__c'],
  'table': 'ContractDnts__r',
}];

/** カスタムDataMapを使用する項目名 */
const customDataMapItems = ['MaintenanceKoziCompany__c', 'KyogaTanto__c'];

/**
 * 移設依頼工事日連絡共架者一覧 テンプレート。
 * 画面ID:1424
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grid: {
        data: null,
        instance: null,
      },
      beforeData: [],
      isModified: false,
    };
    this.gridRef = React.createRef();

    this.isetuIraiTargetMap = this.getIsetuIraiTargetMap();
    this.isetuIraiNoticeTargetMap = this.getIsetuIraiNoticeTargetMap();
    this.beforeUpdateList = [];
    this.webIgnoreOrder = [];

    /**
     * 保守工事会社MAP
     * キー：[共架事業者コード]-[共架事業所コード]
     * 値：保守工事会社 一覧
     */
    this.koziCompanyListMap = {};

    /** 保守工事会社DataMap */
    this.koziCompanyDataMap;

    /** 共架事業者 担当者DataMap */
    this.kyogaZgsyaContactDataMap;

    /**
     * 表示用保守工事会社MAP
     * 「保守工事会社」にbindされたIdから保守会社情報を取得するために使用
     * キー：保守工事会社.Id
     * 値：保守工事会社
     */
    this.displayKoziCompanyMap = {};

    /**
     * 共架事業者 担当者MAP
     * 申込.共架担当者から担当者を取得するために使用
     * キー：担当者.Id
     * 値：担当者
     */
    this.kyogaZgsyaContactMap = {};

    // ヘルプ
    this.headerKyogaStbTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <KyogasyaRemarksKozi/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    this.headerIsetuIraiNoticeTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <IsetuIraiNoticeKozi/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setScreenState('1424');
      const cv = createCollectionView(null, true, {}, null);
      this.gridRef.current.getGrid().itemsSource = cv;
    }
    await this.getOrder();
  }

  /**
   * 申込取得
   */
  getOrder = async () => {
    const {generalPurposeMap} = this.props;
    // Web対応外の共架種別
    const webIgnoreKyogaType = generalPurposeMap.KyogaType.filter((item) => item.ValueString3__c != '1').map((item) => item.Code__c);
    const appCondition = {
      'IsetuIraiDesignDocument__r.Id_EQ': this.props.designDocumentId,
    };
    await this.props.doGetOrderSubAllList(appCondition, appFields,
        null, subqueryOrder);
    if (!this.props.hasOwnProperty('orderSubAllList') ||
        !this.props.orderSubAllList) {
      this.sysErr();
      return;
    }
    this.beforeUpdateList.length = [];
    this.webIgnoreOrder = [];
    this.addressOrderKyogaZgsyaMap = {};
    const fields = ['Id', 'Name', 'AccountId', 'Email', 'UserLevel__c'];
    const contSortParam = {'HakkoDate__c': 1};
    let accountIdArray = [];
    for (const order of this.props.orderSubAllList) {
      this.beforeUpdateList.push({
        Id: order.Id,
        IsetuIraiTarget__c: order.IsetuIraiTarget__c,
        IsetuIraiNoticeTarget__c: order.IsetuIraiNoticeTarget__c,
        EachKyogasyaMessage__c: order.EachKyogasyaMessage__c,
        EachKyogasyaRemarks__c: order.EachKyogasyaRemarks__c,
        MailKyogaZgsya__c: order.MailKyogaZgsya__c,
        MaintenanceKoziCompany__c: order.MaintenanceKoziCompany__c,
      });

      if (order.OrderKyogaZgsya__c) {
        accountIdArray.push(order.OrderKyogaZgsya__c);
      }
      if (webIgnoreKyogaType && order.ContractDnts__r &&
        order.ContractDnts__r.records &&
        order.ContractDnts__r.records.length > 0) {
        let webIgnore = true;
        for (const dnt of order.ContractDnts__r.records) {
          if (!webIgnoreKyogaType.includes(dnt.KyogaType__c)) {
            webIgnore = false;
          }
        }
        if (webIgnore) {
          this.webIgnoreOrder.push(order.Id);
        }
      }
    }
    const condition = {
      'AccountId': accountIdArray,
      'RecordType.DeveloperName': 'KyogaStaffRecordType',
    };
    const result = await this.props.doGetContactMasterList(
        condition, fields, contSortParam);
    const errCode = result && result.data && result.data.body ?
      result.data.body.errorCode : undefined;
    if (errCode != '00000') {
      this.sysErr();
      return;
    }

    // 共架事業者 担当者MAPを初期化
    this.kyogaZgsyaContactMap = {};

    // 共架事業者 担当者DataMap生成用リスト
    const contactList = [];

    if (result.data.body.data) {
      for (const order of this.props.orderSubAllList) {
        if (order.OrderKyogaZgsya__c) {
          const addressOrderKyogaZgsya = result.data.body.data.filter(
              (data) => {
                // 過去に登録済の担当者は無条件で使用
                if (order.KyogaTanto__c && order.KyogaTanto__c == data.Id) {
                  return true;
                }

                // メールアドレスが存在しない場合は除外
                if (!data.Email) {
                  return false;
                }

                // 通常の担当者絞り込み条件(ユーザレベル:管理者 かつ 申込.申込共架事業者に属する)
                if (data.UserLevel__c == '2' && data.AccountId == order.OrderKyogaZgsya__c) {
                  return true;
                }

                return false;
              });
          this.addressOrderKyogaZgsyaMap[order.Id] = addressOrderKyogaZgsya;

          for (const address of addressOrderKyogaZgsya) {
            // 共架事業者 担当者DataMap生成用リストに項目追加
            contactList.push(address);

            // 共架事業者 担当者MAPに担当者を設定
            this.kyogaZgsyaContactMap[address.Id] = address;
          }
        }
      }
    }

    // 共架事業者 担当者DataMap生成
    this.kyogaZgsyaContactDataMap = new DataMap(contactList, 'Id', 'Name');
    this.kyogaZgsyaContactDataMap.getDisplayValues = (dataItem) => {
      return this._createKyogaZgsyaContactNameList(dataItem);
    };

    try {
      // 保守工事会社を取得
      await this.getAccountMap(this.props.orderSubAllList);

      // 保守工事会社の不整合チェック(本来ありえないがパッチによる情報変更の確認)
      this.checkInvalidMaintenanceKoziCompany(this.props.orderSubAllList);
    } catch (err) {
      console.error(err);
      this.sysErr();
      return;
    }

    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setScreenState('1424');
      const cv = createCollectionView(this.props.orderSubAllList,
          true, {}, null);
      this.gridRef.current.getGrid().itemsSource = cv;
    }

    this.setState({isModified: false});
  }

  /**
   * 共架事業者 担当者名一覧の生成。
   *
   * @param {object} item 行情報
   * @return {string[]} 担当者名一覧
   */
  _createKyogaZgsyaContactNameList = (item) => {
    // 返却する担当者名一覧
    const items = [];

    if (this.addressOrderKyogaZgsyaMap[item.Id] &&
      this.addressOrderKyogaZgsyaMap[item.Id].length > 0) {
      for (const address of
        this.addressOrderKyogaZgsyaMap[item.Id]) {
        items.push(address.Name);
      }
    }

    return items;
  }

  /**
   * 保守工事会社を取得しMAPとして保持
   *
   * @see koziCompanyListMap
   * @see displayKoziCompanyMap
   * @param {object} orderSubAllList 申込一覧
   */
  getAccountMap = async (orderSubAllList) => {
    const {doGetAccountList} = this.props;

    // 申込.申込共架事業者.共架事業者コード の一覧を生成
    const kyogaZgsyaList = [];
    for (const order of orderSubAllList) {
      const orderKyogaZgsyaCode = order.OrderKyogaZgsya__r.KyogaZgsyaCode__c;
      if (!kyogaZgsyaList.includes(orderKyogaZgsyaCode)) {
        kyogaZgsyaList.push(orderKyogaZgsyaCode);
      }
    }

    // 対象の共架事業所に紐づく保守工事会社一覧を取得
    const result = await doGetAccountList(
        {
          'KyogaZgsyaCode__c': kyogaZgsyaList,
          'RecordType.DeveloperName': 'MaintenanceKoziCompany',
        },
        [
          'Id',
          'Name',
          'ZipCode__c',
          'Address__c',
          'RepresentativeName__c',
          'Tel__c',
          'MailAddress__c',
          'MainteKoziCompanyCode__c',
          'KyogaZgsyaCode__c',
          'KyogaZgsyoCode__c',
        ],
        {
          'MainteKoziCompanyCode__c': 1,
        },
    );

    const body = result.data.body;

    // API実行結果が成功以外の場合は終了
    if (body.errorCode != '00000') {
      console.error('保守工事会社 取得失敗');
      throw new Error();
    }

    // 表示用保守工事会社MAPを初期化
    this.displayKoziCompanyMap = {};

    const koziCompanyList = [];

    // 「共架事業者コード-共架事業所コード」をキーとした、保守工事会社一覧を保持するMAPを生成
    const map = {};
    for (const data of body.data) {
      const key = this.createKey(data);
      if (!map.hasOwnProperty(key)) {
        map[key] = [];
      }

      // 表示用名称を設定
      data['DisplayName'] = `${data.MainteKoziCompanyCode__c} ${data.Name}`;

      map[key].push(data);

      // 表示用保守工事会社MAPに要素を設定
      this.displayKoziCompanyMap[data.Id] = data;

      koziCompanyList.push({
        Name: data['DisplayName'],
        Code__c: data.Id,
      });
    }

    // 保守工事会社のDataMapを生成
    this.koziCompanyDataMap = createDataMapByComboList(koziCompanyList);
    this.koziCompanyDataMap.getDisplayValues = (dataItem) => {
      const displayValues = [];
      const list = this._createMaintenanceKoziCompanyList(dataItem);
      for (const item of list) {
        displayValues.push(item.DisplayName);
      }

      return displayValues;
    };

    // 保守工事会社MAPを差し換え
    this.koziCompanyListMap = map;
  }

  /**
   * 保守工事会社一覧の生成。
   *
   * @param {object} item 行情報
   * @return {object[]} 保守工事会社情報一覧
   */
  _createMaintenanceKoziCompanyList = (item) => {
    const key = this.createKey(item.OrderKyogaZgsya__r);
    return this.koziCompanyListMap[key];
  }

  /**
   * キーの生成
   * 「共架事業者コード-共架事業所コード」をキーとして返却する。
   * @param {object} data キー生成元
   * @return {string} キー
   */
  createKey = (data) => {
    return data.KyogaZgsyaCode__c + '-' + data.KyogaZgsyoCode__c;
  }

  /**
   * 保守工事会社 不整合判定
   * @param {object} orderSubAllList 申込一覧
   */
  checkInvalidMaintenanceKoziCompany = (orderSubAllList) => {
    for (const order of orderSubAllList) {
      const key = order.MaintenanceKoziCompany__c;

      if (!this.displayKoziCompanyMap.hasOwnProperty(key)) {
        console.error('保守工事会社情報不一致');
        console.dir({order});
        throw new Error();
      }
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1424', 'Id');
    }
  }

  /**
   * 移設依頼対象データMap取得
   * @return {Array}
   */
  getIsetuIraiTargetMap = () => {
    const {generalPurposeMap} = this.props;
    this.codeIsetuIraiTarget0 = getCodeFromGeneralPurposeMap(generalPurposeMap, 'IsetuIraiTarget0', 1);
    return new DataMap(generalPurposeMap['IsetuIraiTarget'], 'Code__c', 'Name');
  }

  /**
   * 移設依頼システム通知対象データMap取得
   * @return {Array}
   */
  getIsetuIraiNoticeTargetMap = () => {
    const {generalPurposeMap} = this.props;
    const istIriNoticeTarget2 = getDataFromGeneralPurposeMap(generalPurposeMap, 'IstIriNoticeTarget2', 1);
    this.valIstIriNoticeTarget2 = istIriNoticeTarget2.Name;
    const istIriNoticeTarget3 = getDataFromGeneralPurposeMap(generalPurposeMap, 'IstIriNoticeTarget3', 1);
    this.valIstIriNoticeTarget3 = istIriNoticeTarget3.Name;
    const istIriNoticeTarget0 = getDataFromGeneralPurposeMap(generalPurposeMap, 'IstIriNoticeTarget0', 1);
    istIriNoticeTarget0['Name'] = 'しない';
    this.valIstIriNoticeTarget0 = istIriNoticeTarget0.Name;
    const items = [];
    items.push(istIriNoticeTarget2);
    items.push(istIriNoticeTarget3);
    items.push(istIriNoticeTarget0);

    const dataMap = new DataMap(items, 'Code__c', 'Name');

    // メールアドレスの状態によって一覧に表示する内容を制御
    dataMap.getDisplayValues = (dataItem) => {
      return this._createIsetuIraiNoticeTargetList(dataItem);
    };

    return dataMap;
  }

  /**
   * 移設依頼システム通知対象 一覧の生成。
   *
   * @param {object} data 行情報
   * @return {string[]} 移設依頼システム通知対象 文字列一覧
   */
  _createIsetuIraiNoticeTargetList = (data) => {
    const items = [];
    if (data.MaintenanceKoziCompany__r.MailAddress__c) {
      // 送付先(保守担当箇所）のメールアドレスが設定されている場合、「する（保守担当）」を追加
      items.push(this.valIstIriNoticeTarget2);
    }

    // 「する（事業者含）」「しない」は固定で追加
    items.push(this.valIstIriNoticeTarget3);
    items.push(this.valIstIriNoticeTarget0);

    return items;
  }

  /**
   * onFormtItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    // ヘッダ行の場合はなにもしない
    if (e.panel.cellType == CellType.ColumnHeader) {
      return;
    }

    const col = e.getColumn();
    if (col.name === 'IsetuIraiTarget' && s.rows[e.row] && this.webIgnoreOrder.length > 0) {
      const data = s.rows[e.row].dataItem;

      // Web対応外の場合、非活性
      if (this.webIgnoreOrder.includes(data.Id)) {
        const cell = e.cell;

        // 非活性化
        cell.isReadOnly = true;
        wijmo.addClass(cell, 'wj-state-disabled');
      }
    }

    // 依頼送付対象選択.システム通知のカラム
    if (col.name === 'IsetuIraiNoticeTarget' && s.rows[e.row]) {
      // 依頼送付対象選択.依頼対象 が対象外の場合、項目を非活性化する
      const dataItem = s.rows[e.row].dataItem;
      if (dataItem.IsetuIraiTarget__c == this.codeIsetuIraiTarget0) {
        const cell = e.cell;

        // 非活性化
        cell.isReadOnly = true;
        wijmo.addClass(cell, 'wj-state-disabled');
      }
    }
  }

  /**
   * refreshedFunction
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  refreshedFunction = (s, e) => {
    try {
      const isModified = (this.gridRef.current.itemsEdited().length > 0);
      if (this.state.isModified != isModified) {
        this.setState({isModified: isModified});
      }
    } catch (err) {
      console.dir(err);
      this.sysErr();
    }
  }

  /**
   * システム通知
   */
  systemNotification = async () => {
    const {orderSubAllList} = this.props;
    if (orderSubAllList[0].IsetuIraiDesignDocument__r.IsetuIraiStatus__c != '04' &&
      orderSubAllList[0].IsetuIraiDesignDocument__r.KoziStop__c) {
      this.props.doShowMessage({
        message: {
          id: 'CE0057',
          values: ['登録処理が未完了の', 'システム通知'],
        },
      });
      return;
    }
    if (!this.gridRef.current.selectedItems ||
      this.gridRef.current.selectedItems.length == 0) {
      this.props.doShowMessage({
        message: {
          id: 'CE0027',
          values: ['通知対象'],
        },
      });
      return;
    }
    const recordMap = {};
    const kyogaZgsyaMailMap = {};
    let noticeTargets = '';
    let err = false;
    for (const selectedItem of this.gridRef.current.selectedItems) {
      if (!selectedItem.dataItem.IsetuIraiTarget__c ||
        selectedItem.dataItem.IsetuIraiTarget__c == '0' ||
        !selectedItem.dataItem.IsetuIraiNoticeTarget__c ||
        selectedItem.dataItem.IsetuIraiNoticeTarget__c == '0') {
        err = true;
        break;
      }
      noticeTargets += selectedItem.dataItem.OrderKyogaZgsya__r.Account__r.Name + '\n';
      recordMap[selectedItem.dataItem.Id] =
        selectedItem.dataItem.MaintenanceKoziCompany__r.MailAddress__c;

      // 宛先選択時は選択した担当者のメールアドレス、未選択時は共架事業者_メールアドレスを設定
      const data = selectedItem.dataItem;
      kyogaZgsyaMailMap[data.Id] =
        data.KyogaTanto__c != null ?
        this.kyogaZgsyaContactMap[data.KyogaTanto__c].Email :
        data.MailKyogaZgsya__c;
    }
    if (err) {
      this.props.doShowMessage({
        message: {
          id: 'ME0090',
        },
      });
      return;
    }
    this.props.doShowMessage({
      message: {
        id: 'MC0089',
        values: [noticeTargets],
      },
      action: () => {
        this.sendMail(recordMap, kyogaZgsyaMailMap);
      },
    });
  }

  /**
   * メール通知
   * @param {Map} recordMap
   * @param {Map} kyogaZgsyaMailMap
   */
  sendMail = async (recordMap, kyogaZgsyaMailMap) => {
    await this.save();
    const {userInfo} = this.props;
    const body = {
      recordMap: recordMap,
      kyogaZgsyaMailMap: kyogaZgsyaMailMap,
    };
    const result = await execApexRestApi(
        userInfo, 'ApiIsetuIrai/isetuIraiKoziDateMailSend', body);
    console.dir(result);
    const errCode = result ? result.data ? result.data.body ?
     result.data.body.errorCode : undefined : undefined : undefined;
    if (errCode != '00000') {
      console.error(errCode);
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['システム通知メール', '送信'],
        },
      });
      return;
    }
    this.props.doShowMessage({
      message: {
        id: 'CI0009',
        values: ['システム通知メールの送信'],
      },
      action: async () => {
        await this.getOrder();
      },
    });
  }

  /**
   * 保存処理
   */
  save = async () => {
    const {userInfo} = this.props;
    const body = this.getSaveOrderBody();
    if (body) {
      const result = await execApexRestApi(
          userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
      console.dir(result);
      const resResult = getResponseResult(result, ['移設依頼情報', '保存']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }
      await this.getOrder();
    } else {
      console.dir('更新対象データなし');
    }
  }

  /**
   * 更新用リクエストbody取得
   * @return {Object} 更新用リクエストbody
   */
  getSaveOrderBody = () => {
    const orderConditions = [];
    const orderList = [];
    const updateItems = this.gridRef.current.itemsEdited();
    if (!updateItems || updateItems.length <=0) {
      // 更新がなければ何もしない
      console.warn('更新されていません');
      return undefined;
    }
    const conditionMap = {};
    const paramMap = {};
    for (const order of updateItems) {
      const condition = {
        Id: order.Id,
        LastModifiedDate: order.LastModifiedDate,
      };
      const param = {
        Id: order.Id,
      };
      const beforeOrder =
        this.beforeUpdateList.filter((data) => data.Id == order.Id)[0];
      let isChange = false;
      // 変更がある場合のみ更新する。
      if (order.IsetuIraiTarget__c != beforeOrder.IsetuIraiTarget__c) {
        param['IsetuIraiTarget__c'] = order.IsetuIraiTarget__c;
        isChange = true;
      }

      if (order.IsetuIraiNoticeTarget__c == '2') {
        if (!order.MaintenanceKoziCompany__r.MailAddress__c) {
          this.props.doShowMessage({
            message: {
              id: 'CE0054',
              values: [order.OrderKyogaZgsya__r.Account__r.Name + 'のシステム通知 ' + this.valIstIriNoticeTarget2, '送付先(保守担当箇所）のメールアドレスが未設定'],
            },
          });
          return null;
        }
      }

      if (order.IsetuIraiNoticeTarget__c !=
         beforeOrder.IsetuIraiNoticeTarget__c) {
        param['IsetuIraiNoticeTarget__c'] = order.IsetuIraiNoticeTarget__c;
        isChange = true;
      }

      if (order.EachKyogasyaMessage__c != beforeOrder.EachKyogasyaMessage__c) {
        param['EachKyogasyaMessage__c'] = order.EachKyogasyaMessage__c;
        isChange = true;
      }
      if (order.EachKyogasyaRemarks__c != beforeOrder.EachKyogasyaRemarks__c) {
        param['EachKyogasyaRemarks__c'] = order.EachKyogasyaRemarks__c;
        isChange = true;
      }
      if (order.MailKyogaZgsya__c != beforeOrder.MailKyogaZgsya__c) {
        param['MailKyogaZgsya__c'] = order.MailKyogaZgsya__c;
        isChange = true;
      }
      if (order.MaintenanceKoziCompany__c !=
        beforeOrder.MaintenanceKoziCompany__c) {
        param['MaintenanceKoziCompany__c'] = order.MaintenanceKoziCompany__c;
        isChange = true;
      }

      // 共架事業者.宛先 変更チェック
      if (order.KyogaTanto__c != beforeOrder.KyogaTanto__c) {
        param['KyogaTanto__c'] = order.KyogaTanto__c;
        isChange = true;
      }

      if (!isChange) {
        continue;
      }
      conditionMap[order.Id] = condition;
      paramMap[order.Id] = param;
    }
    Object.keys(conditionMap).forEach((key) => {
      orderConditions.push(conditionMap[key]);
      orderList.push(paramMap[key]);
    });
    if (orderConditions.length > 0) {
      return {
        orderConditions: orderConditions,
        orderList: orderList,
      };
    }
    return undefined;
  }

  /**
   * 登録処理
   */
  doRegist = async () => {
    const {orderSubAllList} = this.props;
    if (orderSubAllList[0].OrderStatus__c == '50' &&
      !orderSubAllList[0].IsetuIraiDesignDocument__r.KoziStop__c) {
      this.props.doShowMessage({
        message: 'MC0211',
        action: () => {
          this.regist();
        },
      });
    } else {
      this.props.doShowMessage({
        message: 'MC0212',
        action: () => {
          this.regist();
        },
      });
    }
  }
  regist = async () => {
    const {userInfo, orderSubAllList} = this.props;
    let saveBody = this.getSaveOrderBody();
    if (saveBody === null) {
      return;
    }
    const orderConditions = [];
    const orderList = [];
    // 設計書データ更新
    const designDocumentConditions = [];
    const designDocumentList = [];
    if ((orderSubAllList[0].IsetuIraiDesignDocument__r.IsetuIraiStatus__c == '01' ||
      orderSubAllList[0].IsetuIraiDesignDocument__r.IsetuIraiStatus__c == '02') &&
      !orderSubAllList[0].IsetuIraiDesignDocument__r.KoziStop__c) {
      // 申込ステータス＝工事日調整中 かつ 移設依頼.工事中止=FALSEの場合:工事日確定済 に更新する。
      designDocumentConditions.push({
        Id: orderSubAllList[0].IsetuIraiDesignDocument__c,
        LastModifiedDate:
          orderSubAllList[0].IsetuIraiDesignDocument__r.LastModifiedDate,
      });
      designDocumentList.push({
        Id: orderSubAllList[0].IsetuIraiDesignDocument__c,
        IsetuIraiStatus__c: '03',
      });
    } else if (orderSubAllList[0].IsetuIraiDesignDocument__r.KoziStop__c &&
      orderSubAllList[0].IsetuIraiDesignDocument__r.IsetuIraiStatus__c != '04') {
      // 移設依頼.工事中止＝TRUE かつ、申込ステータス≠完結、完結（取止め）の場合：完結に更新する。
      designDocumentConditions.push({
        Id: orderSubAllList[0].IsetuIraiDesignDocument__c,
        LastModifiedDate:
          orderSubAllList[0].IsetuIraiDesignDocument__r.LastModifiedDate,
      });
      designDocumentList.push({
        Id: orderSubAllList[0].IsetuIraiDesignDocument__c,
        IsetuIraiStatus__c: '04',
      });
    }

    let isNoticeTarget = false;
    if (!orderSubAllList[0].IsetuIraiDesignDocument__r.KoziStop__c) {
      for (const order of orderSubAllList) {
        if (order.IsetuIraiNoticeTarget__c && order.IsetuIraiNoticeTarget__c != '0') {
          isNoticeTarget = true;
          break;
        }
      }
    }
    for (const order of orderSubAllList) {
      let isTarget = false;
      const condition = {
        Id: order.Id,
        LastModifiedDate: order.LastModifiedDate,
      };
      let saveBodyParam;
      if (saveBody) {
        saveBodyParam = saveBody.orderList.
            filter((obj) => obj.Id == order.Id);
      }
      if (saveBodyParam && saveBodyParam[0]) isTarget = true;
      const param = saveBodyParam && saveBodyParam[0] ?
        saveBodyParam[0] : {Id: order.Id};
      if (order.OrderStatus__c == '50' && !order.IsetuIraiDesignDocument__r.KoziStop__c) {
        param['OrderStatus__c'] = '51';
        isTarget = true;
      } else if (order.IsetuIraiDesignDocument__r.KoziStop__c && order.OrderStatus__c != '90' && order.OrderStatus__c != '92') {
        param['OrderStatus__c'] = '92';
        isTarget = true;
      }
      if (!isNoticeTarget) { // すべて通知対象外の場合
        if (order.IsetuIraiDesignDocument__r.
            KoziDateChangeConfirmRegistDate__c) {
          param['NWKoziDateContactDateChange__c'] = getToDate(); // 変更
        } else {
          param['NWKoziDateContactDateConfirm__c'] = getToDate(); // 確定
        }
        isTarget = true;
      }
      if (isTarget) {
        orderConditions.push(condition);
        orderList.push(param);
      }
    }
    if (orderConditions.length > 0) {
      saveBody = {
        orderConditions: orderConditions,
        orderList: orderList,
      };
    }
    if (saveBody || designDocumentConditions.length > 0) {
      const body = saveBody ? saveBody : {};
      if (designDocumentConditions.length > 0) {
        body['designDocumentConditions'] = designDocumentConditions;
        body['designDocumentList'] = designDocumentList;
      }
      const result = await execApexRestApi(
          userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
      console.dir(result);
      const resResult = getResponseResult(result, ['移設依頼情報', '登録']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }
      await this.getOrder();
    } else {
      console.dir('更新対象データなし');
    }
    this.props.doShowMessage({
      message: 'MI0212',
    });
  }

  /**
   * セル編集終了時カスタム処理。
   *
   * @param {FlexGrid} s FlexGrid
   * @param {CellRangeEventArgs} e CellRangeEventArgs
   * @param {string} newValue 入力値
   */
  onCellEditEnding = (s, e, newValue) => {
    // 対象のカラムを取得
    const column = s.columns[e.col];

    // バインド項目名を取得
    const binding = column.binding;

    // カスタムDataMapを使用する項目ではない場合処理終了
    if (!customDataMapItems.includes(binding)) {
      return;
    }

    // カラムからDataMapを取得
    const dataMap = column.dataMap;

    // 表示値をコード値に変換
    const code = dataMap.getKeyValue(newValue);

    // 保守工事会社(MaintenanceKoziCompany__c) の場合
    if (binding == 'MaintenanceKoziCompany__c') {
      // 保守担当箇所 他項目の値更新
      const item = s.rows[e.row].dataItem;
      const koziCompany = this.displayKoziCompanyMap[code];
      item.MaintenanceKoziCompany__r = koziCompany;
    }

    // 共架事業者.宛先 の場合
    if (binding == 'KyogaTanto__c') {
      // 過去に設定した共架事業者_メールアドレスをクリアする
      const item = s.rows[e.row].dataItem;
      item.MailKyogaZgsya__c = null;
    }
  }


  renderTable() {
    const {contactType} = this.props;
    const props = {
      rowHeaderType: 'check',
      checkboxOn: true,
      radioOn: false,
      colHeadersMergeOn: true,
      mergeHeaders: [
        ['依頼送付対象選択', 'requesttarget', 'notificationmail'],
        ['依頼状態', 'mail', 'requestpaper'],
      ],
      filterOn: false,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      useStore: false,
      refreshedFunction: this.refreshedFunction,
      generalPurposeMap: this.props.generalPurposeMap,
      style: {maxHeight: '400px'},
      formatItemFunction: this.onFormatItemHandler,
      customDataMapBinds: customDataMapItems,
      onCellEditEnding: this.onCellEditEnding,
    };

    return (
      <CustomFlexGrid ref={this.gridRef} {...props}>
        <FlexGridColumnGroup binding="OrderKyogaZgsya__r.KyogaZgsyaCode__c" header="共架者コード" dataType="String" isReadOnly={true} width={110}/>
        <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Account__r.Name" header="共架者名" dataType="String" isReadOnly={true} width={160}/>
        <FlexGridColumnGroup binding="OrderKyogaZgsya__r.KyogaZgsyoCode__c" header="事業所コード" dataType="String" isReadOnly={true} width={110}/>
        <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Name" header="事業所" dataType="String" isReadOnly="true" width={200}/>
        <FlexGridColumnGroup header='保守担当箇所'>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__c" header="保守工事会社" dataMap={this.koziCompanyDataMap} width={250}/>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.ZipCode__c" header="郵便番号" dataType="String" isReadOnly="true" width={80}/>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Address__c" header="住所" dataType="String" isReadOnly="true" width={200}/>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.RepresentativeName__c" header="宛先" dataType="String" isReadOnly="true" width={200}/>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Tel__c" header="電話番号" dataType="String" isReadOnly="true" width={200}/>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.MailAddress__c" header="メールアドレス" dataType="String" isReadOnly="true" width={200}/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup header='共架事業者'>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.ZipCode__c" header="郵便番号" dataType="String" isReadOnly="true" width={80}/>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Address__c" header="住所" dataType="String" isReadOnly="true" width={200}/>
          <FlexGridColumnGroup binding="KyogaTanto__c" header="宛先（担当者名）" dataMap={this.kyogaZgsyaContactDataMap} width={200}/>
          <FlexGridColumnGroup binding="OrderKyogaZgsya__r.Tel__c" header="電話番号" dataType="String" isReadOnly="true" width={200}/>
          <FlexGridColumnGroup header="メールアドレス" dataType="String" isReadOnly="true" width={220}>
            <FlexGridCellTemplate cellType="Cell"
              template={(ctx) => {
                const item = ctx.item;

                if (!item.KyogaTanto__c) {
                  // 宛先に共架担当者を設定していない場合、以前に設定済の共架担当者_メールアドレスを表示
                  return item.MailKyogaZgsya__c;
                } else {
                  // 宛先に共架担当者を設定している場合、共架担当者のメールアドレスを表示
                  const tanto = this.kyogaZgsyaContactMap[item.KyogaTanto__c];
                  if (tanto) {
                    return tanto.Email;
                  }

                  return null;
                }
              }} />
          </FlexGridColumnGroup>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="EachKyogasyaMessage__c" header="共架者別連絡事項" dataType="String" width={200} maxLength={30}/>
        <FlexGridColumnGroup binding="EachKyogasyaRemarks__c" header="共架者別備考" dataType="String" width={200} maxLength={30}>
          <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerKyogaStbTemplate}/>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup binding="IstIriKoziDateConfirmAccepted__c" header="受理日" dataType="Date" isReadOnly={true} width={120}
          cellTemplate={(ctx) => {
            if (contactType == '工事中止連絡') {
              if (ctx.item.IsetuIraiDesignDocument__r &&
                ctx.item.IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c) {
                return changeDateFormat(
                    ctx.item.IstIriKoziDateChangeAccepted__c);
              }
              return changeDateFormat(
                  ctx.item.IstIriKoziDateConfirmAccepted__c);
            } else if (contactType == '工事日確定連絡') {
              return changeDateFormat(
                  ctx.item.IstIriKoziDateConfirmAccepted__c);
            } else if (contactType == '工事日変更連絡') {
              return changeDateFormat(
                  ctx.item.IstIriKoziDateChangeAccepted__c);
            }
            return '';
          }}/>
        <FlexGridColumnGroup binding="KyogaKoziScheduleDate__c" header="移設工事予定日" dataType="Date" isReadOnly={true} width={120}
          cellTemplate={(ctx) => {
            return changeDateFormat(
                ctx.item.KyogaKoziScheduleDate__c);
          }}/>
        <FlexGridColumnGroup binding="ContractDnts__r" header="竣工日" dataType="Date" isReadOnly={true} width={120}
          cellTemplate={(ctx) => {
            let targetDate = null;
            if (ctx.item.TargetStbNone__c) {
              return '対象設備なし';
            }
            let stbNone = false;
            if (ctx.item.ContractDnts__r && ctx.item.ContractDnts__r.records &&
                ctx.item.ContractDnts__r.records.length > 0) {
              stbNone = true;
              for (const dnt of ctx.item.ContractDnts__r.records) {
                if (!dnt.KyogaIsetuKoziContents__c || dnt.KyogaIsetuKoziContents__c != '03') {
                  stbNone = false;
                }
                if (dnt.KyogaIsetuKoziCompletionDate__c) {
                  if (!targetDate ||
                    targetDate < dnt.KyogaIsetuKoziCompletionDate__c) {
                    targetDate = dnt.KyogaIsetuKoziCompletionDate__c;
                  }
                } else {
                  targetDate = null;
                  break;
                }
              }
            }
            return stbNone ? '対象設備なし' : changeDateFormat(targetDate);
          }}/>
        <FlexGridColumnGroup header='依頼送付対象選択'>
          <FlexGridColumnGroup name="IsetuIraiTarget" binding="IsetuIraiTarget__c" header="依頼対象" dataMap={this.isetuIraiTargetMap} isReadOnly={true} width={100}/>
          <FlexGridColumnGroup name="IsetuIraiNoticeTarget" binding="IsetuIraiNoticeTarget__c" header="システム通知" dataMap={this.isetuIraiNoticeTargetMap} width={160}>
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerIsetuIraiNoticeTemplate}/>
          </FlexGridColumnGroup>
        </FlexGridColumnGroup>
        <FlexGridColumnGroup header='依頼状態'>
          <FlexGridColumnGroup binding="IstIriNoticeDateContact__c" header="システム通知" dataType="String" isReadOnly={true} width={160}
            cellTemplate={(ctx) => {
              if (ctx.item.IsetuIraiTarget__c == '0' || ctx.item.IsetuIraiNoticeTarget__c == '0') {
                return '－';
              }
              if (contactType == '工事中止連絡') {
                return (ctx.item.NWKoziDateContactDateStop__c ? '送信済(' + changeDateFormat(ctx.item.NWKoziDateContactDateStop__c) + ')' : '未送信');
              } else if (contactType == '工事日確定連絡') {
                return (ctx.item.NWKoziDateContactDateConfirm__c ? '送信済(' + changeDateFormat(ctx.item.NWKoziDateContactDateConfirm__c) + ')' : '未送信');
              } else if (contactType == '工事日変更連絡') {
                return (ctx.item.NWKoziDateContactDateChange__c ? '送信済(' + changeDateFormat(ctx.item.NWKoziDateContactDateChange__c) + ')' : '未送信');
              }
              return '未送信';
            }}/>
        </FlexGridColumnGroup>
      </CustomFlexGrid>
    );
  }

  render() {
    const {contactType, handleSubmit, disableButton} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn4" item>
          <PositiveButton
            startIcon={<MailIcon />}
            onClick={handleSubmit(this.systemNotification)}
            variant="contained"
            size="large"
            type="submit"
            disabled={disableButton}
          >
            <span>システム通知</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn5" item>
          <PositiveButton
            startIcon={<SaveAltIcon />}
            onClick={handleSubmit(this.save)}
            variant="contained"
            size="large"
            type="submit"
          >
            <span>一時保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn6" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={handleSubmit(this.doRegist)}
            variant="contained"
            size="large"
            type="submit"
          >
            <span>登録</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn7" item>
          <BackButton isModified={this.state.isModified}
            showWarning={true} props={this.props}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography variant="h6">{contactType}</Typography>
            </Grid>

            <Grid item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>

          <div style={{marginBottom: '20px'}} />
          {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
          <div style={{display: 'none'}}>
            <div id="kyogasyaRemarksHelpComponent">
              <KyogasyaRemarksKozi/>
            </div>
            <div id="isetuIraiNoticeHelpComponent">
              <IsetuIraiNoticeKozi/>
            </div>
          </div>

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  orderSubAllList: PropTypes.array,
  doGetOrderSubAllList: PropTypes.func,
  doGetAccountList: PropTypes.func,
  doGetAllGeneralPurposeMap: PropTypes.func,
  doGetContactMasterList: PropTypes.func,
  doShowMessage: PropTypes.func.isRequired,
  designDocumentId: PropTypes.string,
  doSetOrderId: PropTypes.func.isRequired,
  generalPurposeMap: PropTypes.object,
  contactType: PropTypes.string,
  disableButton: PropTypes.bool,
};

const mapStateToProps = (state) => {
  let contactType = '';
  const orderData = state.attachorder.orderSubAllList ?
   state.attachorder.orderSubAllList[0] : undefined;
  let disableButton = true;
  if (orderData && orderData.IsetuIraiDesignDocument__r) {
    if (orderData.IsetuIraiDesignDocument__r.KoziStop__c) {
      contactType = '工事中止連絡';
    } else if (orderData.IsetuIraiDesignDocument__r.DrIsnKoziDate__c) {
      contactType = '工事日確定連絡';
      if (orderData.IsetuIraiDesignDocument__r.
          KoziDateChangeConfirmRegistDate__c) {
        contactType = '工事日変更連絡';
      }
    }
    if ((orderData.IsetuIraiDesignDocument__r.KoziStop__c &&
       orderData.IsetuIraiDesignDocument__r.IsetuIraiStatus__c == '04') ||
       (!orderData.IsetuIraiDesignDocument__r.KoziStop__c &&
       orderData.IsetuIraiDesignDocument__r.IsetuIraiStatus__c == '03')) {
      disableButton = false;
    }
  }

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    disableButton: disableButton,
    contactType: contactType,
    designDocumentId: state.isetuIrai.designDocumentId,
    userInfo: state.auth.userInfo,
    initialValues: {},
    orderSubAllList: state.attachorder.orderSubAllList,
  };
};

const mapDispatchToProps = {
  doGetAccountList: commonOperations.doGetAccountList,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doShowMessage: commonOperations.doShowMessage,
  doGetContactMasterList: commonOperations.doGetContactMasterList,
  doGetOrderSubAllList: attachorderOperations.doGetOrderSubAllList,
  doSetOrderId: attachorderOperations.doSetOrderId,
};

const FORM_NAME = 'IsetuIraiKoziDateContactKyogasyaList';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

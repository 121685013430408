import {combineReducers} from 'redux';
import types from './types';

// 移設依頼設計書一覧
const designDocumentList = (state = null, action) => {
  switch (action.type) {
    case types.GET_DESIGN_DOCUMENT_LIST:
      return action.designDocumentList;
    case types.GET_DESIGN_DOCUMENT_TYPE_LIST:
      return action.designDocumentList;
    default:
      return state;
  }
};

// 移設依頼設計書
const designDocument = (state = null, action) => {
  switch (action.type) {
    case types.RETRIEVE_DESIGN_DOCUMENT:
      return action.designDocument;
    default:
      return state;
  }
};

// 移設依頼設計書 登録/更新/削除結果
const designDocumentResult = (state = null, action) => {
  switch (action.type) {
    case types.REGIST_DESIGN_DOCUMENT:
      return action.designDocumentResult;
    case types.UPDATE_DESIGN_DOCUMENT:
      return action.designDocumentResult;
    case types.DELETE_DESIGN_DOCUMENT:
      return action.designDocumentResult;
    default:
      return state;
  }
};

// 移設依頼設計書ID
const designDocumentId = (state = null, action) => {
  switch (action.type) {
    case types.SET_DESIGN_DOCUMENT_ID:
      return action.designDocumentId;
    default:
      return state;
  }
};


// 移設依頼設計書ID
const saveDesignDocument = (state = null, action) => {
  switch (action.type) {
    case types.GET_DESIGN_DOCUMENT:
      return action.saveDesignDocument;
    case types.DEL_DESIGN_DOCUMENT:
      return action.saveDesignDocument;
    default:
      return state;
  }
};


// 移設依頼設計書ID詳細
const saveDetailDesignDocument = (state = null, action) => {
  switch (action.type) {
    case types.GET_DETAIL_DESIGN_DOCUMENT:
      return action.saveDetailDesignDocument;
    case types.DEL_DETAIL_DESIGN_DOCUMENT:
      return action.saveDetailDesignDocument;
    default:
      return state;
  }
};

const reducer = combineReducers({
  designDocumentList,
  designDocument,
  designDocumentResult,
  designDocumentId,
  saveDesignDocument,
  saveDetailDesignDocument,
});

export default reducer;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues, getFormSyncWarnings} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import {
  changeDateFormatter, API_CODE,
  getCodeFromGeneralPurposeMap, getErrorCode, getErrorMessages,
  getResponseResult,
} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import {renderTextField, renderSelect} from '../../../../../atoms/CustomPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import Upload from '../../../../../organisms/UploadCustom';
import * as validateRule from '../../../../../validateRule.js';
import {orderBasicFields} from '../../../../../../common/SFFields';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import {checkFileNameLength, checkFileSizeZero, checkSameName} from '../../../../../../common/fileOperations.js';
import Required from '../../../../../atoms/RequiredMark.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
  textFieldDate: {
    margin: '0px 10px 15px 10px',
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  OrderNo__c: null, // 本申込番号
  OrderKyogaZgsya__c: null, // 申込共架事業者
  KoziTitle__c: null, // 工事件名
  Dsp_KoziPlaceMainDntNo__c: null, // 表示用 代表電柱番号
  KoziPlaceMainDntNo_SenroCode__c: null, // 代表電柱_線路コード
  KoziPlaceMainDntNo_SenroName__c: null, // 代表電柱_線路名
  KoziPlaceMainDntNo__c: null, // 代表電柱番号
  KoziPlacePrefectures__c: null, // 都道府県
  KoziPlaceMunicipalities__c: null, // 市区町村
  KoziPlacePlace__c: null, // 場所
  StbType__c: null, // 設備種別
  KyogaType__c: null, // 共架種別
  SplitOrderUmu__c: '0', // 分割申込有無 未選択の場合は無
  ZizenNegotiationNo__c: null, // 事前協議番号
  MemoContents__c: null, // メモ内容
  OrderCategory__c: null, // 申込区分
  OrderStatus__c: null, // 申込ステータス
  NWZgsyo__c: null, // 中電NW事業所(汎用マスタのId) 代表電柱番号の中電NW事業所と同じ値を設定する
  MainDntNoNWZgsyo__c: null, // 代表電柱番号の中電NW事業所(汎用マスタのId)
  KyogaTanto__c: null, // 共架担当者
  ProprietyJudgeOrder__c: null, // 可否判定申込(可否判定申込のId)
  ProprietyJudgeOrderNo__c: null, // 可否判定申込番号
  KyogaZgsya__c: null, // 共架事業者(AccountのId)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  TemporarilySavedDate__c: null, // 一時保存日
  ProprietyJudgeOrderDate__c: null, // 可否判定申込年月日
  SyunkoReportCheckJiko1__c: false, // 竣工届確認事項1
  SyunkoReportCheckJiko2__c: false, // 竣工届確認事項2
  KdKisnTanto__c: null, // 強度計算担当者
  ProprietyJudgeTanto__c: null, // 可否判定担当者
  CheckJiko1__c: false, // 可否判定時に保存した確認事項1
  CheckJiko2__c: false, // 可否判定時に保存した確認事項2
  CheckJiko3__c: false, // 可否判定時に保存した確認事項3
};
const updateParams = {
  SplitOrderUmu__c: '0', // 分割申込有無 未選択の場合は無
  MemoContents__c: null, // メモ内容
  UpsyaUserId__c: null,
  TemporarilySavedDate__c: null, // 一時保存日
};

/**
 * 本申込基本情報
 * 画面ID:1012
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootFiles: [],
      horizonFiles: [],
      specFiles: [],
      otherFiles: [],
      pictureFiles: [],
      changedRootFiles: [],
      changedRootDelFiles: [],
      changedHorizonFiles: [],
      changedHorizonDelFiles: [],
      isDisabled: true,
      warningMessages: [],
    };

    // 線設備と点設備の画面項目を切り替えるフラグ初期化。true:線設備、false:点設備
    this.isSen = this.props.stbType == '1' ? true : false;
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      const {orderId} = this.props;

      if (orderId == null) {
        return;
      }
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearFormalOrderInfo();
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit = false) => {
    const {orderId, generalPurposeMap} = this.props;
    const attachedFileTypeRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA08', 3);
    const attachedFileTypeHorizon = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA02', 3);
    const attachedFileTypeSpec = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA01', 3);
    const attachedFileTypeOther = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA12', 3);
    const attachedFileTypePicture = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);

    /** コード：申込:ルート図（変更後） */
    const attachedFileTypeChangedRoot = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA27', 3);

    /**  コード：申込:平面図（変更後） */
    const attachedFileTypeChangedHorizon = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA28', 3);

    // 申込情報、添付ファイル取得
    const appConditions = {
      'Id': orderId, // 可否判定申込または本申込のId
      'attachedFileTypes': [
        attachedFileTypeRoot,
        attachedFileTypeHorizon,
        attachedFileTypeSpec,
        attachedFileTypeOther,
        attachedFileTypePicture,
      ],
      'formalAttachedFileTypes': [ // 本申込で取得するファイル種別
        attachedFileTypeChangedRoot,
        attachedFileTypeChangedHorizon,
      ],
    };
    const response =
      await this.props.doGetFormalOrderInfo(appConditions, orderBasicFields);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['本申込', '取得'],
        },
      });
      return false;
    }

    if (!isInit) {
      // 保存したとき、排他チェックのために申込の最終更新日時を保存する
      this.setLastModifiedDate(response);
    }

    // 可否判定申込で登録した添付ファイルを表示する
    let _rootFiles = [];
    let _horizonFiles = [];
    let _specFiles = [];
    let _otherFiles = [];
    let _pictureFiles = [];

    /** 申込:ルート図（変更後） ファイル一覧 */
    let _changedRootFiles = [];

    /** 申込:平面図（変更後） ファイル一覧 */
    let _changedHorizonFiles = [];

    // contentDocuments取得済の場合は添付ファイル種別ごとに分ける
    if (this.props.contentDocuments &&
      this.props.contentDocuments.length > 0) {
      _rootFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeRoot);
      _horizonFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeHorizon);
      _specFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeSpec);
      _otherFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeOther);
      _pictureFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypePicture);
    }

    // 本申込で設定したファイル取得済の場合は添付ファイル種別ごとに分ける
    if (this.props.formalContentDocuments &&
      this.props.formalContentDocuments.length > 0) {
      _changedRootFiles =
        this.props.formalContentDocuments.filter(
            (record) =>
              record.AttachedFileType__c == attachedFileTypeChangedRoot);

      _changedHorizonFiles =
        this.props.formalContentDocuments.filter(
            (record) =>
              record.AttachedFileType__c == attachedFileTypeChangedHorizon);
    }

    // 登録済の申込情報から設備種別を判定
    // 本申込か可否判定申込の設備種別をチェックする
    const stbTypeSen = getCodeFromGeneralPurposeMap(generalPurposeMap, 'StbType1', 1);
    this.isSen = this.props.order &&
      Object.hasOwnProperty.call(this.props.order, 'StbType__c') ?
      (this.props.order.StbType__c == stbTypeSen ? true : false) :
      (this.props.proprietyOrder &&
        Object.hasOwnProperty.call(this.props.proprietyOrder, 'StbType__c') &&
        this.props.proprietyOrder.StbType__c ==
        stbTypeSen ? true : false);
    // 後の画面制御のために本申込の設備種別をストアに保存する
    this.props.doSetStbType(
      this.props.order && Object.hasOwnProperty.call(this.props.order, 'StbType__c') ?
        this.props.order.StbType__c : null,
    );

    // 本申込があれば、本申込の状態をチェック
    // 本申込がなければ、可否判定の状態をチェック
    let _isDisabled = false;
    if (this.props.order) {
      _isDisabled = this.props.order.OrderStatus__c != '00' ? true : false;
    } else if (this.props.proprietyOrder) {
      _isDisabled = (this.props.proprietyOrder.OrderStatus__c != '04' &&
        this.props.proprietyOrder.OrderStatus__c != '05') ? true : false;
    }

    // 入力チェックを行う
    const _warningMessages = [];
    this.inputCheck();

    this.setState({
      rootFiles: _rootFiles,
      horizonFiles: _horizonFiles,
      specFiles: _specFiles,
      otherFiles: _otherFiles,
      pictureFiles: _pictureFiles,
      changedRootFiles: _changedRootFiles,
      changedHorizonFiles: _changedHorizonFiles,
      isDisabled: _isDisabled,
      warningMessages: _warningMessages,
    });

    return true;
  }

  /**
   * ルート図(変更後)設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setChangedRootFiles = (files, deleteFiles) => {
    try {
      const _files = [];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A27';
        _files.push(file);
      }

      this.setState({
        changedRootFiles: _files,
        changedRootDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 平面図(変更後)設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setChangedHorizonFiles = (files, deleteFiles) => {
    try {
      const _files = [];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A28';
        _files.push(file);
      }

      this.setState({
        changedHorizonFiles: _files,
        changedHorizonDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
        data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'Id') ?
          data.order.Id : null;
      lastModifiedDate =
        data && Object.hasOwnProperty.call(data, 'order') &&
          Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
          data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 本申込の登録/更新処理
   * 本申込は添付ファイルを登録/削除しない
   */
  insertOrUpdate = async () => {
    const {values, order, proprietyOrder,
      generalPurposeMap, orderModifiedCondition} = this.props;

    const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    const orderCategoryFormal = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderCategory02', 2);

    /** 添付ファイル一覧 */
    const attachFiles =
      this.state.changedRootFiles.concat(this.state.changedHorizonFiles);

    /** 削除ファイル一覧 */
    const deleteFiles =
      this.state.changedRootDelFiles.concat(this.state.changedHorizonDelFiles);

    let resultSave = null;
    if (order &&
      Object.hasOwnProperty.call(order, 'Id') &&
      order.Id) {
      // 更新
      const updateData = {Id: order.Id}; // Idは更新キー
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(values, key)) {
          if (key == 'SplitOrderUmu__c') {
            // 分割有無が空のときは「無」にする
            if (values[key]) {
              updateData[key] = values[key];
            } else {
              updateData[key] = '0';
            }
          } else {
            updateData[key] = values[key];
          }
        }
      }
      const body = {
        record: {
          conditions: {
            Id: order.Id,
            LastModifiedDate:
              orderModifiedCondition != null &&
                order.Id == orderModifiedCondition.Id ?
                orderModifiedCondition.LastModifiedDate : null,
          },
          proprietyConditions: {
            Id: order.ProprietyJudgeOrder__r.Id,
            LastModifiedDate: order.ProprietyJudgeOrder__r.LastModifiedDate,
          },
          order: updateData,
          stage: 'FORMAL_UPDATE',
        },
      };
      resultSave = await this.props.doUpdateFormalOrder(
          'saveFormalOrder', body, attachFiles, deleteFiles);
    } else {
      // 登録
      const insertData = {};
      for (const key in addParams) {
        if (key in ['OrderKyogaZgsya__c', 'MainDntNoNWZgsyo__c',
          'KyogaTanto__c', 'KdKisnTanto__c', 'ProprietyJudgeTanto__c']) {
          insertData[key] = proprietyOrder[key];
        } else if (key == 'NWZgsyo__c') {
          insertData[key] = proprietyOrder['MainDntNoNWZgsyo__c'];
        } else if (key == 'ProprietyJudgeOrder__c') {
          insertData[key] = proprietyOrder.Id;
        } else if (key == 'OrderCategory__c') {
          insertData[key] = orderCategoryFormal;
        } else if (key == 'OrderStatus__c') {
          insertData[key] = orderStatusCreate;
        } else if (key == 'SplitOrderUmu__c') {
          // 分割有無が空のときは「無」にする
          if (values[key]) {
            insertData[key] = values[key]; ;
          } else {
            insertData[key] = '0';
          }
        } else if (key == 'SyunkoReportCheckJiko1__c') {
          insertData[key] = proprietyOrder['CheckJiko2__c'];
        } else if (key == 'SyunkoReportCheckJiko2__c') {
          insertData[key] = proprietyOrder['CheckJiko3__c'];
        } else {
          if (Object.hasOwnProperty.call(values, key)) {
            insertData[key] = values[key];
          }
        }
      }
      const body = {
        record: {
          proprietyConditions: {
            Id: proprietyOrder.Id,
            LastModifiedDate: proprietyOrder.LastModifiedDate,
          },
          order: insertData,
        },
      };
      resultSave = await this.props.doUpdateFormalOrder(
          'saveFormalOrder', body, attachFiles, deleteFiles);
    }
    const resResult = getResponseResult(resultSave, ['申込', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }
    // 分割有無でエラーがあれば表示する
    const errorMsg = getErrorMessages(resultSave);
    if (errorMsg.length > 0) {
      return {success: false, messages: errorMsg};
    }

    let id = null;
    if (Object.hasOwnProperty.call(this.props.orderResult, 'orderId')) {
      id = this.props.orderResult.orderId; // 本申込のIdが返ってくる
    }
    this.props.doSetOrderId(id);

    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.props.history.push(
                  {pathname: '/FormalOrderList'},
                  null, this.props.history.option.CLEAR,
              );
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 画面再描画時のデータを取得する
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      console.error(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込電柱入力ボタン押下時
   */
  doMoveNext = async () => {
    try {
      const {order} = this.props;

      // 本申込未登録はエラー
      if (order == null || order.Id == null) {
        this.props.doShowMessage({
          message: {
            id: 'CE0169',
            values: ['本申込', '申込電柱'],
          },
        });
        return;
      }

      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      if (this.props.dirty) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  inputCheck = async () => {
    const {order, generalPurposeMap} = this.props;
    const messages = []; // {id: message id, values:[]}

    // 本申込未登録
    if (order == null) {
      await this.props.doSetGmnWarning('1012', true);
      return;
    }

    const orderStatusCreate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    if (order &&
      order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    // 必須チェック
    if (!order.SplitOrderUmu__c) {
      messages.push({
        id: 'CE0017',
        values: ['分割申込有無'],
      });
    }

    // 桁数チェック
    if (order.MemoContents__c &&
      order.MemoContents__c.length > 255) {
      messages.push({
        id: 'CE0041',
        values: ['メモ内容'],
      });
    }

    let result = messages.length > 0;
    await this.props.doSetGmnWarning('1012', result);
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    let _hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1012')) {
        _hasError = this.props.setGmnWarning['1012'];
      }
    }

    // 警告表示
    // 入力チェックNG
    if (_hasError) {
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
      });
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   */
  doNext = () => {
    // 後の画面制御のために本申込の設備種別をストアに保存する
    this.props.doSetStbType(
      this.props.order && Object.hasOwnProperty.call(this.props.order, 'StbType__c') ?
        this.props.order.StbType__c : null,
    );

    if (this.isSen) {
      this.props.history.push({pathname: '/FormalOrderSenOrderDnt'});
    } else {
      this.props.history.push({pathname: '/FormalOrderTenOrderDnt'});
    }
  }

  /**
   * 画面描画
   * @return {*}
   */
  render() {
    const {classes, userInfo, generalPurposeMap, handleSubmit} = this.props;
    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1012InputOrderDnt" item>
          <PositiveButton
            id="btn1012InputOrderDnt"
            onClick={handleSubmit(this.doMoveNext)}
            variant="contained"
            size="large"
          >
            <span>申込電柱入力</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1012Save" item>
          <PositiveButton
            id="btn1012Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon />}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1012Back" item>
          <BackButton props={this.props}
            showWarning={true} />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          <WarningMessageArea messages={this.state.warningMessages} />
          <span>
            可否判定結果が「条件付き可」の場合は、中国電力ネットワークが改造工事費用を算定後、金額を通知します。
            <br />可否判定結果は「可」の場合は、本申込審査後、「共架開始通知」を通知します。（共架開始通知後、共架工事が可能となります。）
          </span>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}>
            {/* 申込情報 */}
            <Grid key="key1012OrderGrid" item xs={12}>
              <PaperPart>
                <Typography variant="h6" gutterBottom>申込情報</Typography>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid key="key1012FormalOrderNo" item xs={12} sm={2}>
                    <Field
                      id="id1012FormalOrderNo"
                      name="OrderNo__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='本申込番号'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012FormalOrderDate" item xs={12} sm={2}>
                    <Field
                      id="id1012FormalOrderDate"
                      name="OrderDate__c"
                      label='本申込年月日'
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      required={false}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012SaveDate" item xs={12} sm={2}>
                    <Field
                      id="id1012SaveDate"
                      name="TemporarilySavedDate__c"
                      label='一時保存日'
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      required={false}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012SendBackReason" item xs={12} sm={6}>
                    <Field
                      id="id1012SendBackReason"
                      name="KyogasyaSendBackReason__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='差戻理由'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid key="key1012ProprietyOrderNo" item xs={12} sm={2}>
                    <Field
                      id="id1012ProprietyOrderNo"
                      name="ProprietyJudgeOrderNo__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='可否判定申込番号'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012ProprietyOrderDate" item xs={12} sm={2}>
                    <Field
                      id="key1012ProprietyOrderDate"
                      name="ProprietyJudgeOrderDate__c"
                      label='可否判定申込年月日'
                      component={renderTextField}
                      className={classes.textFieldDate}
                      fullWidth
                      required={false}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>
                </Grid>
                {/* 共架者情報 */}
                <Grid container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid key="key1012OrderKyogaZgsya" item xs={12} sm={5}>
                    {/* 共架事業者＝会社名＋事業所名 */}
                    <Field
                      id="id1012OrderKyogaZgsya"
                      name="OrderKyogaZgsyaName"
                      component={renderTextField}
                      className={classes.fields}
                      label='申込共架事業者'
                      type="text"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    >
                    </Field>
                  </Grid>
                  <Grid key="key1012KoziTitle" item xs={12} sm={8}>
                    <Field
                      id="id1012KoziTitle"
                      name="KoziTitle__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='工事件名'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                </Grid>
                {/* 工事場所 */}
                <Typography variant="h6" gutterBottom>工事場所</Typography>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid key="id1012KoziPlaceMainDntNoSenroName" item xs={12} sm={2}>
                    <Field
                      id="id1012KoziPlaceMainDntNoSenroName"
                      name="KoziPlaceMainDntNo_SenroName__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='線路名'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012KoziPlaceMainDntNo" item xs={12} sm={2}>
                    <Field
                      id="id1012KoziPlaceMainDntNo"
                      name="Dsp_KoziPlaceMainDntNo__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='代表電柱番号'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012Blank1" item xs={12} sm={8}></Grid>
                  <Grid key="key1012KoziPlacePrefectures" item xs={12} sm={2}>
                    <Field
                      id="id1012KoziPlacePrefectures"
                      name="KoziPlacePrefectures__c"
                      component={renderSelect}
                      className={classes.fields}
                      label='都道府県'
                      fullWidth
                      required={false}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    >
                      {generalPurposeMap &&
                        createSearchList(generalPurposeMap.PrefCode)}
                    </Field>
                  </Grid>
                  <Grid key="key1012KoziPlaceMunicipalities" item xs={12} sm={4}>
                    <Field
                      id="id1012KoziPlaceMunicipalities"
                      name="KoziPlaceMunicipalities__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='市区町村'
                      fullWidth
                      type="text"
                      required={false}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012KoziPlacePlace" item xs={12} sm={4}>
                    <Field
                      id="id1012KoziPlacePlace"
                      name="KoziPlacePlace__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='場所'
                      fullWidth
                      type="text"
                      required={false}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}>
                  <Grid key="key1012StbType" item xs={12} sm={3}>
                    <Field
                      id="id1012StbType"
                      name="StbType__c"
                      component={renderSelect}
                      className={classes.fields}
                      label='設備種別'
                      fullWidth
                      required={false}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    >
                      {generalPurposeMap &&
                        createSearchList(generalPurposeMap.StbType, 'Code__c', 'Name', {Code__c: ['1', '2']})}
                    </Field>
                  </Grid>
                  <Grid key="key1012KyogaType" item xs={12} sm={3}>
                    <Field
                      id="id1012KyogaType"
                      name="KyogaType__c"
                      component={renderSelect}
                      className={classes.fields}
                      label='共架種別'
                      fullWidth
                      required={false}
                      InputProps={{
                        readOnly: true,
                      }}
                      validate={[
                      ]}
                    >
                      {generalPurposeMap &&
                        createSearchList(generalPurposeMap.KyogaType,
                            'Code__c',
                            'Name',
                            {
                              Code__c:
                              Object.hasOwnProperty.call(userInfo.Account, 'Account__r') &&
                                Object.hasOwnProperty.call(userInfo.Account.Account__r, 'KyogaType__c') ?
                                userInfo.Account.Account__r.KyogaType__c : '',
                              ValueString1__c: this.isSen ? ['1'] : ['2'],
                            })}
                    </Field>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid key="key1012NoOrderDntCount" item xs={12} sm={2}>
                    <Field
                      id="id1012NoOrderDntCount"
                      name="NoOrderDntCount"
                      component={renderTextField}
                      className={clsx(classes.fields, 'number')}
                      label='申込未実施施設数'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                  <Grid key="key1012SplitOrderUmu" item xs={12} sm={2}>
                    <Field
                      id="id1012SplitOrderUmu"
                      name="SplitOrderUmu__c"
                      component={renderSelect}
                      className={clsx(classes.fields, 'centerPos')}
                      style={{width: '130px'}}
                      label="分割申込有無"
                      InputProps={{
                        readOnly: false,
                      }}
                      required={true}
                      validate={[
                      ]}
                      warn={[
                        validateRule.required,
                      ]}
                      disabled={this.state.isDisabled ||
                        this.props.isDisabledSplitOrder}
                    >
                      {generalPurposeMap &&
                        createSearchList(generalPurposeMap.UmuCategory,
                            'Code__c', 'Name', null, true)}
                    </Field>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid key="key1012ZizenNegotiationNo" item xs={12} sm={2}>
                    <Field
                      id="id1012ZizenNegotiationNo"
                      name="ZizenNegotiationNo__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='協議番号'
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      required={false}
                      validate={[
                      ]}
                    />
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>
            {/* 添付ファイル */}
            <Grid key="key1012AttachGrid" item xs={12} sm={7}>
              <PaperPart>
                <Typography variant="h6" gutterBottom>ファイル一覧（可否判定申込時添付）</Typography>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid key="key1012PictureFile" item xs={12}>
                    <Typography variant="h6" display="block">
                      装柱図
                      <MuiTooltip content={
                        <React.Fragment>
                          電柱番号，全景，共架部分拡大写真・装柱図等の資料を添付してください。
                        </React.Fragment>
                      }>
                        <InfoOutlined />
                      </MuiTooltip>
                    </Typography>
                  </Grid>
                  <Grid key="key1012UploadPictureFile" item xs={12}>
                    <Upload
                      componentId="pictureComponent"
                      maxFileCount={1}
                      maxFileSize={20971520}
                      previewFlag={false}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      initFile={this.state.pictureFiles}
                      disabled={true}
                    />
                  </Grid>
                  <Grid key="key1012RootFile" item xs={12}>
                    {this.isSen &&
                      <Typography variant="h6" display="block">
                        ルート図
                        <MuiTooltip content={
                          <React.Fragment>
                            申込ルートが分かる資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined />
                        </MuiTooltip>
                      </Typography>
                    }
                    {!this.isSen &&
                      <Typography variant="h6" display="block">
                        平面図
                        <MuiTooltip content={
                          <React.Fragment>
                            設置場所が分かる資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined />
                        </MuiTooltip>
                      </Typography>
                    }
                  </Grid>
                  <Grid key="key1012UploadRootFile" item xs={12}>
                    {this.isSen &&
                      <Upload
                        componentId="rootComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        initFile={this.state.rootFiles}
                        disabled={true}
                      />
                    }
                    {!this.isSen &&
                      <Upload
                        componentId="horizonComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        initFile={this.state.horizonFiles}
                        disabled={true}
                      />
                    }
                  </Grid>
                  <Grid key="key1012SpecFile" item xs={12}>
                    <Typography variant="h6" display="block">
                      共架設備仕様書
                      <MuiTooltip content={
                        <React.Fragment>
                          ・共架の対象および共架伝送路設備の資料<br />
                          ・共架伝送路設備の諸元表<br />
                          を添付してください。
                        </React.Fragment>
                      }>
                        <InfoOutlined />
                      </MuiTooltip>
                    </Typography>
                  </Grid>
                  <Grid key="key1012UploadSpecFile" item xs={12}>
                    <Upload
                      componentId="specComponent"
                      maxFileCount={3}
                      maxFileSize={3145728}
                      previewFlag={true}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      initFile={this.state.specFiles}
                      disabled={true}
                    />
                  </Grid>
                  <Grid key="key1012OtherFile" item xs={12}>
                    <Typography variant="h6" display="block">
                      その他資料
                      <MuiTooltip content={
                        <React.Fragment>
                          上記以外の資料を添付してください。
                        </React.Fragment>
                      }>
                        <InfoOutlined />
                      </MuiTooltip>
                    </Typography>
                  </Grid>
                  <Grid key="key1012UploadOtherFile" item xs={12}>
                    <Upload
                      componentId="otherComponent"
                      maxFileCount={3}
                      maxFileSize={3145728}
                      previewFlag={true}
                      previewWidth="35%"
                      acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                      initFile={this.state.otherFiles}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </PaperPart>

              <PaperPart>
                <Typography variant="h6" gutterBottom>ファイル一覧（本申込時添付）</Typography>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid key="key1012RootFile" item xs={12}>
                    {this.isSen &&
                      <Typography variant="h6" display="block">
                        ルート図（変更後）
                        {
                          !this.state.isDisabled &&
                          <>
                            <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                            <Required />
                          </>
                        }
                        <MuiTooltip content={
                          <React.Fragment>
                            申込ルートが分かる資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined />
                        </MuiTooltip>
                      </Typography>
                    }
                    {!this.isSen &&
                      <Typography variant="h6" display="block">
                        平面図（変更後）
                        {
                          !this.state.isDisabled &&
                          <>
                            <font size="2">(※最大3ファイル、1ファイルあたり3MBまで登録できます)</font>
                            <Required />
                          </>
                        }
                        <MuiTooltip content={
                          <React.Fragment>
                            設置場所が分かる資料を添付してください。
                          </React.Fragment>
                        }>
                          <InfoOutlined />
                        </MuiTooltip>
                      </Typography>
                    }
                  </Grid>
                  <Grid key="key1012UploadRootFile" item xs={12}>
                    {this.isSen &&
                      <Upload
                        componentId="changedRootComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setChangedRootFiles}
                        initFile={this.state.changedRootFiles}
                        disabled={this.state.isDisabled}
                        customChecks={[
                          checkFileNameLength,
                          checkFileSizeZero,
                          checkSameName,
                        ]}
                      />
                    }
                    {!this.isSen &&
                      <Upload
                        componentId="changedHorizonComponent"
                        maxFileCount={3}
                        maxFileSize={3145728}
                        previewFlag={true}
                        previewWidth="35%"
                        acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                        fileSetHandler={this.setChangedHorizonFiles}
                        initFile={this.state.changedHorizonFiles}
                        disabled={this.state.isDisabled}
                        customChecks={[
                          checkFileNameLength,
                          checkFileSizeZero,
                          checkSameName,
                        ]}
                      />
                    }
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>
            {/* その他 */}
            <Grid key="key1012OOtherGrid" item xs={12} sm={5}>
              <PaperPart>
                <Grid container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid key="key1012Memo" item xs={12}>
                    <Field
                      id="id1012Memo"
                      name="MemoContents__c"
                      component={renderTextField}
                      className={classes.fields}
                      label='メモ内容'
                      variant="outlined"
                      type="text"
                      multiline
                      rows={20}
                      fullWidth
                      required={false}
                      validate={[
                        validateRule.maxLength(255),
                      ]}
                      disabled={this.state.isDisabled}
                    />
                  </Grid>
                </Grid>
              </PaperPart>
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  order: PropTypes.object,
  proprietyOrder: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  stbType: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  orderResult: PropTypes.object,
  KyogasyaSendBackReason__c: PropTypes.string,
  OrderKyogaZgsyaName: PropTypes.string,
  KoziTitle__c: PropTypes.string,
  KoziPlaceMainDntNo__c: PropTypes.string,
  KoziPlacePrefectures__c: PropTypes.string,
  KoziPlaceMunicipalities__c: PropTypes.string,
  KoziPlacePlace__c: PropTypes.string,
  StbType__c: PropTypes.string,
  KyogaType__c: PropTypes.string,
  SplitOrderUmu__c: PropTypes.string,
  ZizenNegotiationNo__c: PropTypes.string,
  MemoContents__c: PropTypes.string,
  rootFiles: PropTypes.array,
  horizonFiles: PropTypes.array,
  specFiles: PropTypes.array,
  otherFiles: PropTypes.array,
  pictureFiles: PropTypes.array,
  dirty: PropTypes.bool,
  contentDocuments: PropTypes.array,
  formalContentDocuments: PropTypes.array,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetFormalOrderInfo: PropTypes.func,
  doSetOrderId: PropTypes.func,
  doUpdateFormalOrder: PropTypes.func,
  doClearFormalOrderInfo: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  syncWarnings: PropTypes.object,
  doSetStbType: PropTypes.func,
  isDisabledSplitOrder: PropTypes.bool,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  const initValues = {
    OrderNo__c: null, // 本申込番号
    OrderDate__c: null, // 本申込年月日
    TemporarilySavedDate__c: null, // 一時保存日
    KyogasyaSendBackReason__c: null, // 差戻理由
    ProprietyJudgeOrderNo__c: null, // 可否判定申込番号
    ProprietyJudgeOrderDate__c: null, // 可否判定申込年月日
    OrderKyogaZgsya__c: state.auth.userInfo.AccountId,
    OrderKyogaZgsyaName: state.auth.userInfo.Account.Name, // 申込共架事業者
    KoziTitle__c: null, // 工事件名
    KoziPlaceMainDntNo__c: null, // 代表電柱番号
    Dsp_KoziPlaceMainDntNo__c: null,
    KoziPlaceMainDntNo_SenroCode__c: null,
    KoziPlaceMainDntNo_SenroName__c: null,
    KoziPlacePrefectures__c: null, // 都道府県
    KoziPlaceMunicipalities__c: null, // 市区町村
    KoziPlacePlace__c: null, // 場所
    StbType__c: null, // 設備種別
    KyogaType__c: null, // 共架種別
    SplitOrderUmu__c: '0', // 分割申込有無 デフォルト無
    ZizenNegotiationNo__c: null, // 協議番号
    MemoContents__c: null, // メモ内容
    NoOrderDntCount: '0', // 申込未実施施設数
    rootFiles: [],
    horizonFiles: [],
    specFiles: [],
    otherFiles: [],
    pictureFiles: [],
  };
  // 本申込
  const order = state.attachorder.orderInfo &&
    Object.hasOwnProperty.call(state.attachorder.orderInfo, 'order') ?
    state.attachorder.orderInfo.order : null;
  // 可否判定申込
  const proprietyOrder = state.attachorder.orderInfo &&
    Object.hasOwnProperty.call(state.attachorder.orderInfo, 'proprietyOrder') ?
    state.attachorder.orderInfo.proprietyOrder : null;
  // 画面表示
  if (order) {
    for (const key in order) {
      if (Object.hasOwnProperty.call(initValues, key)) {
        if (order[key] != undefined && order[key] != null) {
          if (key == 'TemporarilySavedDate__c') {
            initValues[key] = changeDateFormatter(order[key], 'YYYY-MM-DD');
          } else if (key == 'OrderKyogaZgsya__r') {
            initValues['OrderKyogaZgsyaName'] =
              Object.hasOwnProperty.call(order[key], 'Name') ? order[key].Name : '' +
                Object.hasOwnProperty.call(order[key], 'Account__r') &&
                Object.hasOwnProperty.call(order[key].Account__r, 'Name') ?
                order[key].Account__r.Name : '';
          } else if (key == 'NoOrderDntCount') {
            initValues[key] = order[key] ? order[key] + '' : '0';
          } else {
            initValues[key] = order[key];
          }
        }
      }
    }
  } else if (proprietyOrder) {
    for (const key in proprietyOrder) {
      if (Object.hasOwnProperty.call(proprietyOrder, key)) {
        if (proprietyOrder[key]) {
          // 一時保存日、差戻理由、本申込番号、本申込年月日は空表示
          // OrderNo__cは可否判定申込番号として表示する
          if (key == 'TemporarilySavedDate__c' ||
            key == 'KyogasyaSendBackReason__c' ||
            key == 'OrderDate__c') {
            initValues[key] = null;
          } else if (key == 'OrderNo__c') {
            initValues['ProprietyJudgeOrderNo__c'] = proprietyOrder[key];
            initValues[key] = null;
          } else if (key == 'OrderKyogaZgsya__r') {
            initValues['OrderKyogaZgsyaName'] =
              Object.hasOwnProperty.call(proprietyOrder[key], 'Name') ? proprietyOrder[key].Name : '' +
                Object.hasOwnProperty.call(proprietyOrder[key], 'Account__r') &&
                Object.hasOwnProperty.call(proprietyOrder[key].Account__r, 'Name') ?
                proprietyOrder[key].Account__r.Name : '';
          } else {
            initValues[key] = proprietyOrder[key];
          }
        }
      }
    }
  } else {
    // do nothing
  }

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId, // 可否判定または本申込のId
    order: order,
    proprietyOrder: proprietyOrder,
    contentDocuments: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'contentDocuments') ?
      state.attachorder.orderInfo.contentDocuments : [],
    formalContentDocuments: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'formalContentDocuments') ?
      state.attachorder.orderInfo.formalContentDocuments : [],
    isDisabledSplitOrder: state.attachorder.orderInfo &&
      Object.hasOwnProperty.call(state.attachorder.orderInfo, 'isDisabledSplitOrder') ?
      state.attachorder.orderInfo.isDisabledSplitOrder : false,
    orderResult: state.attachorder.orderResult,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doSetOrderId: attachorderOperations.doSetOrderId,
  doGetFormalOrderInfo: attachorderOperations.doGetFormalOrderInfo,
  doUpdateFormalOrder: attachorderOperations.doUpdateFormalOrder,
  doClearFormalOrderInfo: attachorderOperations.doClearFormalOrderInfo,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetStbType: commonOperations.doSetStbType,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'FormalBasicInfo';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
    syncWarnings: getFormSyncWarnings(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);


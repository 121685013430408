import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Container from '../../../components/templates/Photo/AppPhotoRegistration';
import {authOperations} from '../../../reducks/auth';
import {commonOperations} from '../../../reducks/common';
import {contractDntOperations} from '../../../reducks/contractDnt';

/**
 * 申込写真登録 ページ。
 * 画面ID:1060
 *
 * @param {string} ret
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    const ret = await this.init();
    this.doJudge(ret);
  }

  init = async () => {
    const {getPhotoInfo, photoRegistrationInfo} = this.props;

    try {
      const conditions = [{
        name: 'contractDnt',
        contractDntId: photoRegistrationInfo.contractDntId,
        suffix: photoRegistrationInfo.suffix,
      }];
      await getPhotoInfo(conditions, true);
      if (!this.props.hasOwnProperty('photoInfo')) {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }

  doJudge = (ret) => {
    if (!ret) {
      const {doShowMessage} = this.props;
      const dialog = {
        message: 'CS0001',
        action: this.doMoveLogin,
      };
      doShowMessage(dialog);
    }
  }

  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    return (<Container {...this.props} />);
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  photoInfo: state.contractDnt.photoInfo,
  photoRegistrationInfo: state.contractDnt.photoRegistrationInfo,
});

const mapDispatchToProps = {
  getPhotoInfo: contractDntOperations.doGetPhotoInfo,
  sendAuthenticationAction: authOperations.sendAuthenticationCodeOperation,
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
};

App.propTypes = {
  doShowMessage: PropTypes.func,
  getPhotoInfo: PropTypes.func,
  history: PropTypes.object.isRequired,
  getUserInfo: PropTypes.func,
  photoInfo: PropTypes.object,
  photoRegistrationInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

export default ContainerApp;

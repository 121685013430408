import React from 'react';
import classNames from 'classnames';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';

export const RadioB = withStyles((theme) => ({
  root: {
    'padding': '0px 5px 0px 10px',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {
  },
}))(Radio);

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInput-input': {
      color: '#A1A1A1',
    },
  },
}));

const useHelperTextStyles = makeStyles((theme) => ({
  root: {
    color: '#FF0000',           // ヘルパーテキストの文字色
  },
}));

const useErrorOutLineStyles = makeStyles((theme) => ({
  root: {
    '& label': {
      color: '#FF0000',           // 通常時のタイトルの文字色
    },
    '& label.Mui-focused': {
      color: '#FF0000',           // 入力時のタイトルの文字色
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderColor: '#FF0000',    // 通常時のボーダー色(アウトライン)
      },
      '&:hover:not(.Mui-disabled):before': {
        borderColor: '#FF0000',    // ホバー時のボーダー色(アウトライン)
      },
      '&:after': {
        borderColor: '#FF0000',    // 入力時のボーダー色(アウトライン)
      },
    },
  },
}));

/**
 * 項目がRead Onlyであるかの判定。
 *
 * @param {object} custom 入力項目パラメータ
 * @return {boolean} Read Only項目の場合true
 */
function isReadOnly(custom) {
  const inputProps = custom.InputProps;
  return inputProps && inputProps.readOnly;
}

/**
 * Read Only項目に共通のパラメータ設定を行う。
 *
 * @param {object} custom 入力項目パラメータ
 * @param {object} input 入力情報
 */
function setReadOnlyProps(custom, input) {
  const classes = useStyles();
  if (custom == null || !isReadOnly(custom)) {
    return;
  }
  // 文字入力されている場合、文字色をグレーに設定
  if (input.value) {
    custom['className'] = classNames(custom.className, classes.root);
  }

  // ラベルの位置を固定
  if (!custom.InputLabelProps) {
    custom['InputLabelProps'] = {};
  }
  custom.InputLabelProps.shrink = true;

  // Placeholderが未設定の場合は共通の値を設定
  if (!custom.placeholder) {
    custom['placeholder'] = '入力不可';
  }
}

/**
 * エラーテキスト下線のスタイル設定を行う
 *
 * @param {object} custom 入力項目パラメータ
 * @param {object} touched
 * @param {object} error
 * @param {object} warning
 */
function setErrorOutLineProps(custom, touched, error, warning) {
  const outlineStyle = useErrorOutLineStyles();
  if ((custom != null) &&
      ((touched && error) || warning)) {
    custom['className'] = classNames(custom.className, outlineStyle.root);
  }
}

/**
 * ヘルパーテキストのスタイル設定を行う
 *
 * @param {object} custom 入力項目パラメータ
 * @param {object} touched
 * @param {object} error
 * @param {object} warning
 */
function setFormHelperTextProps(custom, touched, error, warning) {
  const helperTextStyles = useHelperTextStyles();
  if ((custom != null) &&
      ((touched && error) || warning)) {
    custom['FormHelperTextProps'] = {classes: {root: helperTextStyles.root}};
  }
}

export const renderTextField = ({
  input,
  label,
  meta: {touched, error, valid, warning},
  ...custom
}) => {
  // ReadOnlyの場合、パラメータを自動設定
  setReadOnlyProps(custom, input);
  if (isReadOnly(custom)) {
    // 未入力の日付項目の場合、yyyy/mm/ddを表示させないため属性をtextに変更する
    if (!input.value && custom.type == 'date') {
      custom.type = 'text';
    }
  }
  // ヘルパーテキストのスタイル設定を行う
  setFormHelperTextProps(custom, touched, error, warning);
  setErrorOutLineProps(custom, touched, error, warning);
  return (<TextField
    label={label}
    helperText={(touched && error) || warning}
    error={touched && !valid}
    {...input}
    {...custom}
  />);
};

export const renderSelect = ({
  input,
  label,
  meta: {touched, error, valid, warning},
  ...custom
}) => {
  let isSelect = true;

  // ReadOnlyの場合、パラメータを自動設定
  setReadOnlyProps(custom, input);
  if (isReadOnly(custom)) {
    // 値が未選択の場合、テキストボックスとして描画しPlaceholderを有効にする
    if (!input.value) {
      isSelect = false;
    }
  }
  // ヘルパーテキストのスタイル設定を行う
  setFormHelperTextProps(custom, touched, error, warning);
  setErrorOutLineProps(custom, touched, error, warning);

  return (
    <TextField
      select={isSelect}
      label={label}
      helperText={(touched && error) || warning}
      error={touched && !valid}
      {...input}
      {...custom}
    />
  );
};

export const renderSelectMultiLine = ({
  input,
  label,
  meta: {touched, error, valid, warning},
  ...custom
}) => {
  // useStylesの呼び出し数を合わせるため
  setReadOnlyProps(null, null);
  // ヘルパーテキストのスタイル設定を行う
  setFormHelperTextProps(custom, touched, error, warning);
  setErrorOutLineProps(custom, touched, error, warning);
  return (
    <Select
      native={true}
      multiple={true}
      label={label}
      helperText={(touched && error) || warning}
      error={touched && !valid}
      {...input}
      {...custom}
    />
  );
};

export const renderRadioGroup = ({
  input,
  label,
  meta: {touched, error},
  ...rest
}) => {
  // useStylesの呼び出し数を合わせるため
  setReadOnlyProps(null, null);
  setFormHelperTextProps(null, null, null, null);
  setErrorOutLineProps(null, null, null, null);
  return (
    <div> {label}
      <RadioGroup
        {...input}
        {...rest}
      />
      {touched && error &&
          <Typography variant="caption" color="error">
            {error}
          </Typography>
      }
    </div>
  );
};

export const renderCheckbox = ({input, label, disabled, ...props}) => {
  // useStylesの呼び出し数を合わせるため
  setReadOnlyProps(null, null);
  setFormHelperTextProps(null, null, null, null);
  setErrorOutLineProps(null, null, null, null);
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          color="primary"
          disabled={disabled ? true : false}
          {...props}
        />
      }
      label = { label }
    />
  );
};

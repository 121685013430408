import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import {getToDate, execApexRestApi, harf2Full, getDataFromGeneralPurposeMap,
  dntNoFormat, senroNameFormat, isSameContractDnt, isNWDesignZgsya,
  API_CODE, getResponseResult} from '../../../../../../common/common.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {renderTextField} from '../../../../../atoms/CustomPart.js';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {isetuIraiOperations} from '../../../../../../reducks/isetuIrai';
import {commonOperations} from '../../../../../../reducks/common';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  button: {
    margin: '5px 10px 5px 10px',
  },
});

/**
 * 移設依頼設計書 検索条件
 */
const appFields = [
  'KoziPlacePrefectures__c',
  'toLabel(KoziPlacePrefectures__c)KoziPlacePrefecturesName',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'DntNo__c',
  'KoziOverview__c',
  'IsetuIraiDesignDocumentNo__c',
  'DesignTantoName__c',
  'Id',
  'LastModifiedDate',
  'IsetuIraiStatus__c',
  'IsetuIraiNo__c',
];

/** 移設依頼設計書、契約電柱の検索対象 */
const subquery = [
  {
    'fields': [
      'NWZgsyo__r.Name',
      'NWZgsyo__r.Code__c',
      'NWZgsyo__c',
      'OldNWZgsyo__c',
      'SenroName__c',
      'SenroCode__c',
      'DntNo__c',
      'toLabel(DntCategory__c)DntCategoryName',
      'DrSideKoziEachDntContents__c',
      'EachDntKoziContentHosoku__c',
      'EachDntMessage__c',
      'Id',
      'LastModifiedDate',
      'IsetuIraiNo__c',
      'SerialNumber__c',
      'Dsp_SenroName__c',
      'Dsp_DntNo__c',
      'DntNoManualInput__c',
      'SzbtItiId__c',
      'StbKobetuId__c',
      'DntCategory__c',
      'KyogaCategory__c',
      'K6Bunk1__c',
      'K6Bunk2__c',
      'K6Bunk3__c',
      'K6K22HeigaCategory__c',
      'K6KansnNo__c',
      'K22GatiCategory__c',
      'K22SzbtNo__c',
      'SenroNameKana__c',
    ],
    'table': 'IsetuIraiContractDnts__r',
  },
  {
    'fields': ['Id', 'LastModifiedDate'],
    'table': 'IsetuDocOrders__r',
  },
];

// 申込の検索対象
const orderFields = [
  'Id',
  'LastModifiedDate',
  'OrderNo__c',
  'KoziTitle__c',
  'OrderKyogaZgsya__c',
  'NWZgsyo__c',
  'OrderCategory__c',
  'KoziPlacePrefectures__c',
  'KoziPlaceMunicipalities__c',
  'KoziPlacePlace__c',
  'IsetuIraiContents__c',
  'IsetuIraiNoticeTarget__c',
  'OrderStatus__c',
  'IsetuIraiDesignDocumentNo__c',
  'IsetuIraiDesignDocument__c',
];

// 申込に紐づく、契約電柱の検索対象
const subqueryOrder = [{
  'fields': [
    'Id', 'LastModifiedDate', 'DntNo__c', 'KyogaZgsyaCode__c', 'KyogaZgsya__c',
    'MainteKoziCompanyCode__c', 'MaintenanceKoziCompany__c',
    'KyogaCategory__c', 'DntNoManualInput__c',
    'NWZgsyo__r.Code__c', 'SenroName__c', 'DntCategory__c',
    'DrSideKoziEachDntContents__c', 'EachDntKoziContentHosoku__c',
    'EachDntMessage__c', 'SerialNumber__c'],
  'table': 'ContractDnts__r',
}];

/** 契約電柱の電柱選択からの追加項目 */
const dntParams = [
  'NWZgsyo__c',
  'OldNWZgsyo__c',
  'SenroCode__c',
  'SenroName__c',
  'SenroNameKana__c',
  'DntNo__c',
  'DntNoManualInput__c',
  'SzbtItiId__c',
  'StbKobetuId__c',
  'DntCategory__c',
  'KyogaCategory__c',
  'DntCategoryName',
  'K6Bunk1__c',
  'K6Bunk2__c',
  'K6Bunk3__c',
  'K6K22HeigaCategory__c',
  'K6KansnNo__c',
  'K22GatiCategory__c',
  'K22SzbtNo__c',
  'KyogaCategory__c',
  'Dsp_DntNo__c',
  'Dsp_SenroName__c',
];

// 契約電柱マスタの検索対象
const contractDntMasterFields = [
  'Id',
  'LastModifiedDate',
  'SzbtItiId__c',
  'StbKobetuId__c',
  'SenroName__c',
  'SenroNameKana__c',
  'SenroCode__c',
  'DntCategory__c',
  'DntNo__c',
  'DntNoManualInput__c',
  'KyogaCategory__c',
  'KyogaType__c',
  'K6KansnNo__c',
  'K6Bunk1__c',
  'K6Bunk2__c',
  'K6Bunk3__c',
  'K22SzbtNo__c',
  'K22GatiCategory__c',
  'K6K22HeigaCategory__c',
  'NWZgsyo__r.Code__c',
  'NWZgsyo__c',
  'OldNWZgsyo__c',
  'Dsp_DntNo__c',
  'Dsp_SenroName__c',
  'MaintenanceKoziCompany__c',
  'KyogaZgsyaCode__c',
  'KyogaZgsya__c',
  'KyogaZgsyoCode__c',
];

// 自由入力の共架区分詳細
const manualInputCategory = ['C', 'S', 'T'];

/**
 * 移設依頼詳細情報入力 テンプレート。
 * 画面ID:1403
 */
class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      grid: {
        data: null,
        instance: null,
      },
      modalOpen: false,
      isModified: false,
      isDisabled: false,
      isDisabledStatus: false,
      disableButton: true,
      dataList: [],
      warningMessage: [],
    };
    this.gridRef = React.createRef();

    this.drDntKoziContentsMap = '';

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      if (prop == 'DrSideKoziEachDntContents__c') {
        // 電力側電柱別工事内容が未設定
        let error = validateRule.required4(
            item.DrSideKoziEachDntContents__c);
        if (error) {
          return error;
        }
      }
      if (prop == 'EachDntKoziContentHosoku__c') {
        // 電力側電柱別工事内容が03（その他）の場合、電力側電柱別工事内容／補足事項は必須
        if (item.DrSideKoziEachDntContents__c == '03') {
          let error = validateRule.required4(
              item.EachDntKoziContentHosoku__c);
          if (error) {
            return error;
          }
        }
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // 電力側電柱工事内容のコンボ用データ取得
      this.drDntKoziContentsMap = new wjcGrid.DataMap(this.props.generalPurposeMap.DrDntKoziContents, 'Code__c', 'Name');

      this.gridRef.current.setScreenState('1403');

      // 移設依頼設計書の情報取得
      await this.getDesignDocument();
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderSubAllList();
    this.props.doDelDetailDesignDocument();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1403', 'Id');
    }
  }

  /**
   * 移設依頼設計書の情報取得
   */
  async getDesignDocument() {
    const {userInfo, generalPurposeMap} = this.props;
    const docId = this.props.designDocumentId;
    const conditions = {
      'Id': docId,
      'RecordType.DeveloperName': 'IsetuIraiDesignDocument',
    };
    let result = await this.props.doGetDetailDesignDocument(
        conditions, appFields, null, 'getSub', subquery);
    let errCode = result ? result.data ? result.data.body ?
        result.data.body.errorCode : undefined : undefined : undefined;
    if (errCode != '00000') {
      this.sysErr();
      return;
    }

    const dataList = {
      'KoziPlacePrefecturesName': '',
      'KoziPlacePrefectures__c': '',
      'KoziPlaceMunicipalities__c': '',
      'KoziPlacePlace__c': '',
      'DntNo__c': '',
      'KoziOverview__c': '',
      'IsetuIraiDesignDocumentNo__c': '',
      'DesignTantoName__c': '',
      'Id': '',
      'LastModifiedDate': '',
      'IsetuIraiStatus__c': '',
      'IsetuIraiNo__c': '',
    };

    // 取得データを画面表示
    for (const data in dataList) {
      if (this.props.saveDetailDesignDocument.hasOwnProperty(data)) {
        dataList[data] = this.props.saveDetailDesignDocument[data];
      }
    }
    Object.assign(this.props.values, dataList);
    this.setState({
      dataList: dataList,
    });

    // 移設依頼設計書に紐づく、契約電柱をリストに表示
    if (this.props.saveDetailDesignDocument &&
      this.props.saveDetailDesignDocument.hasOwnProperty('IsetuIraiContractDnts__r') &&
      this.props.saveDetailDesignDocument.IsetuIraiContractDnts__r
    ) {
      // 一覧画面の初期設定
      const cv = createCollectionView(
          this.props.saveDetailDesignDocument
              .IsetuIraiContractDnts__r.records, true, {}, this.getError);
      this.gridRef.current.getGrid().itemsSource = cv;
    } else {
      const cv = createCollectionView([], true, {}, this.getError);
      this.gridRef.current.getGrid().itemsSource = cv;
    }

    // 移設依頼設計書に紐づく、申込取得
    const orderIdList = [];
    if ('IsetuDocOrders__r' in this.props.saveDetailDesignDocument &&
      this.props.saveDetailDesignDocument.IsetuDocOrders__r) {
      for (const record of
        this.props.saveDetailDesignDocument.IsetuDocOrders__r.records) {
        orderIdList.push(record.Id);
      }
    }

    // 申込に紐づく、契約電柱取得
    if (orderIdList && orderIdList.length > 0) {
      // 申込テーブル検索時の検索条件
      const orderConditions = {
        'Id': orderIdList,
        'RecordType.DeveloperName': 'IsetuIrai',
      };

      result = await this.props.doGetOrderSubAllList(
          orderConditions, orderFields, null, subqueryOrder);
      errCode = result ? result.data ? result.data.body ?
          result.data.body.errorCode : undefined : undefined : undefined;
      if (errCode != '00000') {
        this.sysErr();
        return;
      }
    } else {
      this.props.doClearOrderSubAllList();
    }

    let isWarn = false;
    let warningMessages = [];
    let isStatus = false;
    if (this.props.hasOwnProperty('saveDetailDesignDocument')) {
      if (this.props.saveDetailDesignDocument.IsetuIraiStatus__c != '00') {
        // 移設依頼ステータスが作成中以外は表示のみに設定
        isStatus = true;
      }
      // 入力内容のチェック
      if (this.props.saveDetailDesignDocument.hasOwnProperty('IsetuIraiContractDnts__r') &&
          this.props.saveDetailDesignDocument.IsetuIraiContractDnts__r) {
        let chAddResult = this.checkInputData(
            this.props.saveDetailDesignDocument.
                IsetuIraiContractDnts__r.records);
        if (chAddResult) {
          // 警告発生
          isWarn = true;
        }
      } else {
        // 電柱0件
        warningMessages.push({id: 'CE0019', values: ['移設対象電柱']});
        // 警告発生
        isWarn = true;
      }
    }

    // ログインユーザが、外線工事会社の場合
    if (isNWDesignZgsya(userInfo, generalPurposeMap) &&
        userInfo.MainteKoziCompany__c != null &&
        userInfo.MainteKoziCompany__c) {
      // 表示のみに設定
      isStatus = true;
    }

    let isReferenceMode = false;
    if (this.props.hasOwnProperty('ybdsPath') &&
    this.props.ybdsPath ==
    '/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/Input') {
      // 1422_移設依頼工事日入力画面からの遷移の場合
      isReferenceMode = true;
    }

    this.setState({
      isDisabled: isReferenceMode,
      isDisabledStatus: isStatus,
      warningMessages: warningMessages,
    });
    await this.props.doSetGmnWarning('1403', isWarn);
  }

  /**
   * 保存ボタン押下
   * 移設依頼設計書に紐づく、契約電柱、申込に紐づく契約電柱の
   * DB更新を行う。
   * 追加時、契約電柱追加、マスタから取得した情報を元に申込追加、申込に関連する契約電柱追加
   * 更新時、契約電柱更新、申込に関連する契約電柱更新
   * 削除時、契約電柱削除、削除した契約電柱と電柱番号が一致する申込に関連する契約電柱削除し、
   * 申込に関連する契約電柱がなくなった場合、申込削除
   *
   * また、上記実施時、以下も行う。
   * 移設依頼設計書の契約電柱の電柱番号、通し番号更新（電柱番号の昇順）
   * 申込の契約電柱の通し番号更新（電柱番号の昇順）
   * 申込の工事件名更新（「移設依頼」＋移設依頼番号＋電柱番号）
   *
   * @return {void}
   */
  doSave = async () => {
    const {userInfo} = this.props;

    try {
      // 一覧に追加、変更、削除したデータ取得
      const grid = this.gridRef.current.getGrid();
      const addItems = (grid.collectionView).itemsAdded;
      const editItems = (grid.collectionView).itemsEdited;
      const delItems = (grid.collectionView).itemsRemoved;

      if (addItems.length <= 0 &&
          editItems.length <= 0 &&
          delItems.length <= 0) {
        return;
      }


      // 契約電柱(共架事業者)をキーに、申込IDのリスト設定
      // （削除した申込IDを追加処理で使用しないように管理するために使用）
      let orderContractDntMap = {};
      // 申込IDをキーに、契約電柱の連想配列設定
      // 申込に紐づく、契約電柱の更新があった場合に更新。
      // （申込に関連する契約電柱の通し番号を編集するために使用）
      let orderSerialNumber = {};
      this.setOrderContractData(orderContractDntMap, orderSerialNumber);

      // 削除項目
      let delOrderList = [];
      let delOrderContractList = [];
      let delContractList = [];
      if (delItems.length > 0) {
        this.createDeleteData(orderContractDntMap, orderSerialNumber,
            delOrderList, delOrderContractList, delContractList,
            delItems);
      }

      // 追加項目
      let contractList = [];
      let inOrderList = [];
      if (addItems.length > 0) {
        let errFlg = false;
        await this.createInsertData(orderContractDntMap, orderSerialNumber,
            contractList, inOrderList, addItems, errFlg);
        if (errFlg == true) {
          return;
        }
      }

      // 更新項目
      let upContractList = [];
      let upOrderContractList = [];
      if (editItems.length > 0) {
        this.createUpdateData(upContractList, upOrderContractList, editItems);
      }

      // 移設依頼設計書に紐づく、契約電柱の通し番号設定
      let isetuContractDnt = [];
      this.changeSerialNumber(isetuContractDnt, upContractList,
          contractList, delContractList);

      // 申込に紐づく、契約電柱の通し番号設定
      let orderContractDnt = [];
      this.changeOrderContDntSerialNumber(
          orderSerialNumber, orderContractDnt, upOrderContractList);

      // 申込の工事件名変更
      let orderList = [];
      this.changeOrderKoziTitle(orderSerialNumber, orderList);

      // 設計書の移設依頼本数変更
      let dummyList = [];
      let designDocumentList = [];
      let design = {};
      design['Id'] = this.props.saveDetailDesignDocument.Id;
      design['LastModifiedDate'] = this.props.orderLastModifiedDate;
      design['IsetuIraiHonsu__c'] = this.gridRef.current.getSourceCollection().length;
      designDocumentList.push(design);

      // 選択状態保存
      this.gridRef.current.saveSelectedItems('1403', 'Id');

      const body = {
        'record': {
          'add': {
            'contractDnt': contractList,
            'orderUnder': inOrderList,
          },
          'del': {
            'contractDnt': delContractList,
            'order': delOrderList,
            'orderContractDnt': delOrderContractList,
            'designDocument': dummyList,
          },
          'up': {
            'contractDnt': isetuContractDnt,
            'order': orderList,
            'orderContractDnt': orderContractDnt,
            'designDocument': designDocumentList,
          },
        },
      };

      let result = await execApexRestApi(
          userInfo, 'ApiEnergia/detailInfInputSave', body);
      const resResult = getResponseResult(result, ['選択項目', '更新']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
          action: async () => {
            if (resResult.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
              this.gotoListGmn();
            }
          },
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 移設依頼設計書の情報取得
          await this.getDesignDocument();
          // 移設依頼設計書の最終更新日を退避
          this.props.doSetOrderLastModifiedDate(
              this.props.saveDetailDesignDocument.LastModifiedDate);
        },
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 更新データチェック
   *
   * @param {Array} items 更新データ
   * @return {String} エラーメッセージ(正常時、null)
   */
  checkInputData = (items) => {
    if (!items) {
      return null;
    }

    for (const item of items) {
      // 電力側電柱別工事内容が未設定
      let error = validateRule.required4(
          item.DrSideKoziEachDntContents__c);
      if (error) {
        return error;
      }

      // 電力側電柱別工事内容が03（その他）の場合、電力側電柱別工事内容／補足事項は必須
      if (item.DrSideKoziEachDntContents__c == '03') {
        error = validateRule.required4(
            item.EachDntKoziContentHosoku__c);
        if (error) {
          return error;
        }
      }
    }
    return null;
  }

  /**
   * 申込、契約電柱の組み合わせ設定
   * @param {Object} orderContractDnt 組み合わせ一覧
   *              契約電柱の「共架事業書」をキーに申込IDを設定
   * @param {Object} orderSerialNumber 申込-契約電柱の組み合わせ
   * @return {void} なし
   */
  setOrderContractData = (orderContractDnt, orderSerialNumber) => {
    if (!this.props.orderSubAllList) {
      // 申込なし
      return;
    }

    // 契約電柱の「共架事業所」をキーに申込IDを設定
    for (const items of this.props.orderSubAllList) {
      const contractList = [];
      if (items.ContractDnts__r) {
        for (const record of items.ContractDnts__r.records) {
          const key = record.KyogaZgsya__c;
          if (!key) {
            // 契約電柱に「共架事業所」が未設定の場合
            // 申込の登録は出来ないので対応しない
            continue;
          }
          if (!(key in orderContractDnt)) {
            orderContractDnt[key] = [];
          }

          // 同一キーは保存しない
          let list = orderContractDnt[key].filter(function(data) {
            return data.Id == items.Id;
          });
          if (!list || list.length == 0) {
            orderContractDnt[key].push(items);
          }
          contractList.push(record);
        }
      }
      orderSerialNumber[items.Id] = contractList;
    }
  }

  /**
   * 工事件名取得
   * 「移設依頼」+ ' ' + 移設依頼番号 + ' ' + 電柱番号を全角にして設定する。
   * @param {String} isetuIraiNo 移設依頼番号
   * @param {String} dntNo 電柱番号
   * @return {String}
   */
  getKoziTitle = (isetuIraiNo, dntNo) => {
    return harf2Full('「移設依頼」' + '　' + isetuIraiNo + '　' + dntNo);
  }

  /**
   * 移設依頼設計書の契約電柱の通し番号変更
   * @param {Object} contDntList 更新後の契約電柱
   * @param {Object} upContractList 更新対象の契約電柱
   * @param {Object} contractList 追加対象の契約電柱
   * @param {Object} delContractList 削除対象の契約電柱
   * @return {void}
   */
  changeSerialNumber = (contDntList, upContractList,
      contractList, delContractList) => {
    const {saveDetailDesignDocument} = this.props;

    let wkContDntList = [];

    if (saveDetailDesignDocument &&
       saveDetailDesignDocument.hasOwnProperty('IsetuIraiContractDnts__r') &&
       saveDetailDesignDocument.IsetuIraiContractDnts__r
    ) {
      // 削除された契約電柱を抜いた契約電柱の一覧取得
      for (const item of
        saveDetailDesignDocument.IsetuIraiContractDnts__r.records) {
        const list = delContractList.filter(function(data) {
          return data.Id == item.Id;
        });

        if (list.length > 0) {
          continue;
        }
        wkContDntList.push(item);
      }
    }

    // 追加した契約電柱追加
    for (const item of contractList) {
      wkContDntList.push(item);
    }

    // 削除対象を除いた契約電柱を電柱番号でソートした結果をリストに格納
    let sortContDntList = wkContDntList.sort((x, y) => {
      return this.sortDntNo(x, y);
    });

    // 更新した契約電柱
    let j = 0;
    for (let i=0; i<sortContDntList.length; i++) {
      j = i+1;
      if (!sortContDntList[i].hasOwnProperty('Id')) {
        // 追加対象の場合
        sortContDntList[i]['SerialNumber__c'] = j;
        continue;
      }

      // 更新対象のリストにあれば抽出
      let list = upContractList.filter(function(data) {
        return data.Id == sortContDntList[i].Id;
      });

      if (list.length > 0) {
        // 更新対象の場合
        list[0]['SerialNumber__c'] = j;
        contDntList.push(list[0]);
      } else {
        if (sortContDntList[i].hasOwnProperty('SerialNumber__c') &&
        sortContDntList[i]['SerialNumber__c'] == j) {
          continue;
        }
        // 通し番号の変更がある場合
        contDntList.push({
          'Id': sortContDntList[i].Id,
          'LastModifiedDate': sortContDntList[i].LastModifiedDate,
          'SerialNumber__c': j,
        });
      }
    }
  }

  /**
   * 申込の契約電柱の通し番号変更
   * @param {Object} orderSerialNumber 申込ID、契約電柱を格納したリスト
   * @param {Object} orderContractDnt 更新後の契約電柱
   * @param {Object} upOrderContractList 更新対象の契約電柱
   * @return {void}
   */
   changeOrderContDntSerialNumber = (
       orderSerialNumber, orderContractDnt, upOrderContractList) => {
     if (Object.keys(orderSerialNumber).length <= 0) {
       return;
     }

     for (const key in orderSerialNumber) {
       if (!orderSerialNumber.hasOwnProperty(key)) {
         continue;
       }
       const sortContDntList = orderSerialNumber[key].sort((x, y) => {
         return this.sortDntNo(x, y);
       });

       let j = 0;
       for (let i=0; i<sortContDntList.length; i++) {
         j = i+1;
         if (!sortContDntList[i].hasOwnProperty('Id')) {
           // 追加対象の場合
           sortContDntList[i]['SerialNumber__c'] = j;
           orderContractDnt.push(sortContDntList[i]);
           continue;
         }

         let list = upOrderContractList.filter(function(data) {
           return data.Id == sortContDntList[i].Id;
         });

         if (list.length > 0) {
           // 更新対象の場合
           list[0]['SerialNumber__c'] = j;
           orderContractDnt.push(list[0]);
         } else {
           if (sortContDntList[i].hasOwnProperty('SerialNumber__c') &&
             sortContDntList[i]['SerialNumber__c'] == j) {
             continue;
           }
           // 通し番号の変更がある場合
           orderContractDnt.push({
             'Id': sortContDntList[i].Id,
             'LastModifiedDate': sortContDntList[i].LastModifiedDate,
             'SerialNumber__c': j,
           });
         }
       }
     }
   }

  /**
   * 申込の工事件名変更
   * @param {Object} orderSerialNumber 申込ID、契約電柱を格納したリスト
   * @param {Object} orderList 更新後の申込
   * @return {void}
   */
  changeOrderKoziTitle= (orderSerialNumber, orderList) => {
    if (Object.keys(orderSerialNumber).length <= 0) {
      return;
    }

    for (const item of this.props.orderSubAllList) {
      if (!(item.Id in orderSerialNumber)) {
        // 削除対象の申込は除外
        continue;
      }

      const sortContDntList = orderSerialNumber[item.Id].sort((x, y) => {
        return this.sortDntNo(x, y);
      });

      // 申込に紐づく契約電柱がない場合、以降の処理は出来ない
      if (!sortContDntList || sortContDntList.length <= 0) {
        continue;
      }

      // 申込の工事件名は、契約電柱の先頭の電柱番号を設定する
      const koziTitle = this.getKoziTitle(
          this.props.saveDetailDesignDocument.IsetuIraiNo__c,
          manualInputCategory.includes(sortContDntList[0].KyogaCategory__c) ?
          sortContDntList[0]['DntNoManualInput__c'] : sortContDntList[0]['DntNo__c']);

      if (item.KoziTitle__c == koziTitle) {
        // 変更がなければ対象外
        continue;
      }

      // 申込を更新対象に追加
      orderList.push({
        'Id': item.Id,
        'LastModifiedDate': item.LastModifiedDate,
        'KoziTitle__c': koziTitle,
      });
    }
  }

  /**
   * 更新項目取得
   * 契約電柱更新により、申込に紐づく、契約電柱の更新を行う
   * また、申込が存在している場合、申込に紐づく契約電柱の追加を行う
   * @param {Object} upContractList 更新後の契約電柱
   * @param {Object} upOrderContractList 更新後の申込に紐づく契約電柱
   * @param {array} selectedItems 選択した更新対象データ
   * @return {void}
   */
  createUpdateData = (
      upContractList, upOrderContractList, selectedItems) => {
    const {orderSubAllList, saveDetailDesignDocument} = this.props;

    // 申込に紐づく契約電柱の更新用
    const contractDntList = [];
    // 申込に紐づく契約電柱用の検索対象
    const orderDntFields = [
      'KyogaCategory__c',
      'DntNo__c',
      'SenroName__c',
      'DntNoManualInput__c',
      'DntCategory__c',
    ];

    // 更新対象
    const updateFields = [
      'DrSideKoziEachDntContents__c',
      'EachDntKoziContentHosoku__c',
      'EachDntMessage__c',
    ];
    // 移設依頼設計書に関連する契約電柱（画面表示時）
    const dntList = saveDetailDesignDocument.IsetuIraiContractDnts__r.records;

    for (const item of selectedItems) {
      let updateData = {};
      for (const key of updateFields) {
        for (const record of dntList) {
          if (record.Id != item.Id) {
            continue;
          }
          if (Object.hasOwnProperty.call(record, key)) {
            if (record[key] != item[key]) {
              // 更新されている項目だけ更新対象に追加
              updateData[key] = item[key];
            }
          }
        }
      }
      if (updateData) {
        Object.assign(updateData, {
          'Id': item.Id,
          'LastModifiedDate': item.LastModifiedDate,
        });
        upContractList.push(updateData);

        // 申込に紐づく契約電柱用のデータ作成
        let upContDnt = {};
        for (const data in updateData) {
          if (!updateData.hasOwnProperty(data)) {
            continue;
          }
          upContDnt[data] = updateData[data];
        }
        for (const key of orderDntFields) {
          upContDnt[key] = item[key];
        }
        // 事業所コードを設定
        Object.assign(upContDnt,
            {'NWZgsyo__r': {'Code__c': item['NWZgsyo__r']['Code__c'] ? item['NWZgsyo__r']['Code__c'] : ''}});
        contractDntList.push(upContDnt);
      }
    }

    if (!orderSubAllList || orderSubAllList.length <= 0) {
      // 移設依頼設計書に関連する申込がない場合は終了
      return;
    }

    // 更新対象の電柱番号が一致する契約電柱を抽出
    for (const items of orderSubAllList) {
      if (items.ContractDnts__r) {
        for (const record of items.ContractDnts__r.records) {
          let upContDnt = {};
          for (const contDnt of contractDntList) {
            if (manualInputCategory.includes(contDnt.KyogaCategory__c)) {
              // 自由入力
              if (this.checkManualDntNo(contDnt, record)) {
                // 一致するレコードが存在
                upContDnt = contDnt;
                break;
              }
            } else {
              // 電柱選択
              if (record.DntNo__c == contDnt.DntNo__c) {
                upContDnt = contDnt;
                break;
              }
            }
          }

          if (Object.keys(upContDnt).length) {
            // 移設依頼設計書の契約電柱の設定値を取得
            let updateData = {};
            // 電柱番号が一致するのは1件だけなので、一致した値を設定
            if ('DrSideKoziEachDntContents__c' in upContDnt) {
              updateData['DrSideKoziEachDntContents__c'] = upContDnt.DrSideKoziEachDntContents__c;
            }
            if ('EachDntKoziContentHosoku__c' in upContDnt) {
              updateData['EachDntKoziContentHosoku__c'] = upContDnt.EachDntKoziContentHosoku__c;
            }
            if ('EachDntMessage__c' in upContDnt) {
              updateData['EachDntMessage__c'] = upContDnt.EachDntMessage__c;
            }
            Object.assign(updateData, {
              'Id': record.Id,
              'LastModifiedDate': record.LastModifiedDate,
            });
            upOrderContractList.push(updateData);
          }
        }
      }
    }
  }

  /**
   * 削除項目取得
   * 契約電柱を削除する。
   * 削除した契約電柱と同じ、申込に紐づく契約電柱を削除する。
   * 申込に紐づく、契約電柱が無くなった場合、申込も削除する。
   *
   * @param {Object} orderContractDntMap 契約電柱マップ（キー：共架事業所、値：申込ID）
   * @param {Object} orderSerialNumber 申込ID、契約電柱
   * @param {Object} delOrderList 削除対象の申込
   * @param {Object} delOrderContractList 削除対象の申込に紐づく契約電柱
   * @param {Object} delContractList 削除対象の契約電柱
   * @param {array} selectedItems 選択した更新対象データ
   * @return {void}
   */
  createDeleteData = (orderContractDntMap, orderSerialNumber,
      delOrderList, delOrderContractList, delContractList, selectedItems) => {
    // 移設依頼設計書に紐づく、契約電柱を削除対象に追加
    for (const item of selectedItems) {
      delContractList.push({
        'Id': item.Id,
        'LastModifiedDate': item.LastModifiedDate,
      });
    }

    if (!this.props.orderSubAllList || this.props.orderSubAllList.length <= 0) {
      // 移設依頼設計書に関連する申込がない場合は終了
      return;
    }

    // 削除対象の電柱番号が一致する契約電柱を抽出（申込に紐づく契約電柱）
    for (const items of this.props.orderSubAllList) {
      if (items.ContractDnts__r) {
        for (const record of items.ContractDnts__r.records) {
          for (const contDnt of selectedItems) {
            if (manualInputCategory.includes(record.KyogaCategory__c)) {
              // 自由入力の場合
              if (!this.checkManualDntNo(record, contDnt)) {
                continue;
              }
            } else {
              // 電柱選択の場合
              if (record.DntNo__c != contDnt.DntNo__c) {
                continue;
              }
            }

            delOrderContractList.push({
              'Id': record.Id,
              'LastModifiedDate': record.LastModifiedDate,
            });

            // リストから申込IDに紐づく、契約電柱削除
            const list = orderSerialNumber[items.Id].filter(function(data) {
              return data.Id != record.Id;
            });
            orderSerialNumber[items.Id] = list;
          }
        }
      }

      if (orderSerialNumber.hasOwnProperty(items.Id) &&
        orderSerialNumber[items.Id].length <= 0) {
        // 申込に紐づく、契約電柱が０件の場合、申込を削除対象に追加
        delOrderList.push({
          'Id': items.Id,
          'LastModifiedDate': items.LastModifiedDate,
        });
        // リストから、申込IDのキー削除
        delete orderSerialNumber[items.Id];

        // 契約電柱(共架事業者)から、申込ID削除
        for (const item in orderContractDntMap) {
          if (!orderContractDntMap.hasOwnProperty(item)) {
            continue;
          }
          const list = orderContractDntMap[item].filter(function(data) {
            return data.Id != items.Id;
          });
          orderContractDntMap[item] = list;
        }
      }
    }
  }

  /**
   * 追加項目取得
   * 契約電柱、申込、申込に紐づく契約電柱の追加を行う
   * @param {Object} orderContractDntMap 契約電柱マップ（キー：共架事業者、値：申込ID）
   * @param {Object} orderSerialNumber 申込ID、契約電柱
   * @param {Object} contractList 追加対象の契約電柱
   * @param {Object} orderList 追加対象の申込
   * @param {array} selectedItems 選択した更新対象データ
   * @param {boolean} errFlg エラーフラグ
   * @return {void}
   */
  createInsertData = async (orderContractDntMap, orderSerialNumber,
      contractList, orderList, selectedItems, errFlg) => {
    const {saveDetailDesignDocument} = this.props;
    errFlg = false;
    // 移設依頼設計書に紐づく、契約電柱を追加対象に追加
    for (const item of selectedItems) {
      contractList.push({
        'IsetuIraiDesignDocument__c': saveDetailDesignDocument.Id,
        'IsetuIraiNo__c': saveDetailDesignDocument.IsetuIraiNo__c,
        'DrSideKoziEachDntContents__c': item.DrSideKoziEachDntContents__c,
        'EachDntKoziContentHosoku__c': item.EachDntKoziContentHosoku__c,
        'EachDntMessage__c': item.EachDntMessage__c,
        'SzbtItiId__c': item.SzbtItiId__c,
        'StbKobetuId__c': item.StbKobetuId__c,
        'NWZgsyo__c': item.NWZgsyo__c,
        'OldNWZgsyo__c': item.OldNWZgsyo__c,
        'SenroCode__c': item.SenroCode__c,
        'DntNo__c': item.DntNo__c,
        'DntNoManualInput__c': item.DntNoManualInput__c,
        'DntCategory__c': item.DntCategory__c,
        'SenroName__c': item.SenroName__c,
        'SenroNameKana__c': item.SenroNameKana__c,
        'K6Bunk1__c': item.K6Bunk1__c,
        'K6Bunk2__c': item.K6Bunk2__c,
        'K6Bunk3__c': item.K6Bunk3__c,
        'K6K22HeigaCategory__c': item.K6K22HeigaCategory__c,
        'K6KansnNo__c': item.K6KansnNo__c,
        'K22GatiCategory__c': item.K22GatiCategory__c,
        'K22SzbtNo__c': item.K22SzbtNo__c,
        'KyogaCategory__c': item.KyogaCategory__c,
      });
    }

    // 契約電柱追加項目
    const updateParams = [
      'DrSideKoziEachDntContents__c',
      'EachDntKoziContentHosoku__c',
      'EachDntMessage__c',
      'SzbtItiId__c',
      'StbKobetuId__c',
      'NWZgsyo__c',
      'OldNWZgsyo__c',
      'SenroCode__c',
      'DntNo__c',
      'DntNoManualInput__c',
      'DntCategory__c',
      'SenroName__c',
      'SenroNameKana__c',
      'K6Bunk1__c',
      'K6Bunk2__c',
      'K6Bunk3__c',
      'K6K22HeigaCategory__c',
      'K6KansnNo__c',
      'K22GatiCategory__c',
      'K22SzbtNo__c',
      'KyogaCategory__c',
    ];

    // 重複チェック用契約電柱マスタリスト
    let wkDupDntMasterMap = new Map();
    // 契約電柱マスタリスト
    let wkDntMasterList = [];

    // 申込、契約電柱リスト（作業用）
    const wkOrderList = {};
    const wkContDntList = {};

    // 契約電柱に関連する申込の存在確認
    // 契約電柱マスタに対象レコードがあるか確認
    for (const item of selectedItems) {
      if (manualInputCategory.includes(item.KyogaCategory__c)) {
        // ==============
        // 自由入力の場合
        // ==============
        // 契約電柱マスタ取得
        const mstCondition = {
          'NWZgsyo__r.Code__c': item.NWZgsyo__r.Code__c,    // NW事業所
          'SenroName__c': item.SenroName__c,                // 線路名
          'DntNoManualInput__c': item.DntNoManualInput__c,  // 電柱番号_自由入力電柱
          'DntCategory__c': item.DntCategory__c,            // 電柱区分
        };
        const sortParam = {
          'Dsp_DntNo__c': 1,
        };
        let result = await this.props.doGetContractDntMasterList(
            mstCondition, contractDntMasterFields, sortParam);
        let errCode = result ? result.data ? result.data.body ?
            result.data.body.errorCode : undefined : undefined : undefined;
        if (errCode != '00000') {
          this.sysErr();
          errFlg = true;
          return;
        }
        // 検索結果が0件の場合
        if (!this.props.hasOwnProperty('contractDntMasterList') ||
            this.props.contractDntMasterList.length <= 0 ) {
          continue;
        }
      } else {
        // ==============
        // 電柱選択の場合
        // ==============
        // 契約電柱マスタ取得
        const mstCondition = {
          'DntNo__c': item.DntNo__c,              // 電柱番号
          'DntCategory__c': item.DntCategory__c,  // 電柱区分
        };
        // 電柱区分が01:本柱以外の場合
        if (item.DntCategory__c != '01' && item.StbKobetuId__c != null && item.StbKobetuId__c) {
          mstCondition['StbKobetuId__c'] = Number(item.StbKobetuId__c); // 設備個別ID
        };
        const sortParam = {
          'Dsp_DntNo__c': 1,
        };
        let result = await this.props.doGetContractDntMasterList(
            mstCondition, contractDntMasterFields, sortParam);
        let errCode = result ? result.data ? result.data.body ?
            result.data.body.errorCode : undefined : undefined : undefined;
        if (errCode != '00000') {
          this.sysErr();
          errFlg = true;
          return;
        }
        // 検索結果が0件の場合
        if (!this.props.hasOwnProperty('contractDntMasterList') ||
            this.props.contractDntMasterList.length <= 0 ) {
          continue;
        }
      }
      // 契約電柱マスタ取得分繰り返し
      for (const master of this.props.contractDntMasterList) {
        let data = {};
        for (const key in master) {
          if (!master.hasOwnProperty(key)) {
            continue;
          }
          data[key] = master[key];
        }
        data['selectedItems'] = [];
        for (const updata of updateParams) {
          if (Object.hasOwnProperty.call(item, updata)) {
            data.selectedItems[updata] = item[updata];
          }
        }
        // 重複チェック用文字列
        // ...電柱区分、電柱番号、設備個別ID、線路名、電柱番号_自由入力、共架事業所
        //    ※電柱区分が01:本柱の場合、設備個別IDはみない
        let dupKey = master.DntCategory__c + '_' +
                     master.DntNo__c + '_' +
                     (master.DntCategory__c == '01' ? '' : master.StbKobetuId__c) + '_' +
                     master.SenroName__c + '_' +
                     master.DntNoManualInput__c + '_' +
                     master.KyogaZgsya__c;
        if (!wkDupDntMasterMap.has(dupKey)) {
          wkDntMasterList.push(data);
          wkDupDntMasterMap.set(dupKey);
        }
      }
    }

    // 契約電柱マスタ取得分繰り返し
    for (const master of wkDntMasterList) {
      const key = master.KyogaZgsya__c;
      if (!key) {
        // 契約電柱マスタに「共架事業所」が未設定の場合
        // 申込の登録は出来ないので対応しない
        continue;
      }

      if (orderContractDntMap.hasOwnProperty(key) &&
          orderContractDntMap[key].length > 0) {
        // 申込が存在した場合、契約電柱作成して追加
        for (const order of orderContractDntMap[key]) {
          const updateData = {};
          for (const updata of updateParams) {
            if (Object.hasOwnProperty.call(master, 'selectedItems') &&
                Object.hasOwnProperty.call(master.selectedItems, updata)) {
              updateData[updata] = master.selectedItems[updata];
            }
          }
          if (Object.keys(updateData).length) {
            Object.assign(updateData, {
              'Order__c': order.Id,
              'OrderNo__c': order.OrderNo__c,
              'KyogaZgsya__c': master.KyogaZgsya__c,
              'KyogaType__c': master.KyogaType__c,
            });
          }
          orderSerialNumber[order.Id].push(updateData);
        }
      } else {
        // 申込が存在しない場合、申込、契約電柱作成
        // 契約電柱作成
        const updateData = {};
        for (const updata of updateParams) {
          if (Object.hasOwnProperty.call(master, 'selectedItems') &&
              Object.hasOwnProperty.call(master.selectedItems, updata)) {
            updateData[updata] = master.selectedItems[updata];
          }
        }
        if (Object.keys(updateData).length) {
          Object.assign(updateData, {
            'KyogaZgsya__c': master.KyogaZgsya__c,
            'KyogaType__c': master.KyogaType__c,
          });
        }

        if (!wkContDntList.hasOwnProperty(key)) {
          wkContDntList[key] = [];
        }
        wkContDntList[key].push(updateData);

        // 申込が作成されていなければ作成
        if (!wkOrderList.hasOwnProperty(key)) {
          wkOrderList[key] = [];

          // 申込作成
          const inOrderData = {
            'OrderKyogaZgsya__c': master.KyogaZgsya__c,
            'NWZgsyo__c': master.NWZgsyo__c,
            'OrderCategory__c': '04',
            'KoziPlacePrefectures__c': saveDetailDesignDocument.KoziPlacePrefectures__c,
            'KoziPlaceMunicipalities__c': saveDetailDesignDocument.KoziPlaceMunicipalities__c,
            'KoziPlacePlace__c': saveDetailDesignDocument.KoziPlacePlace__c,
            'TemporarilySavedDate__c': getToDate(),
            'IsetuIraiContents__c': saveDetailDesignDocument.KoziOverview__c,
            'IsetuIraiNoticeTarget__c': '0',
            'OrderStatus__c': '00',
            'IsetuIraiDesignDocument__c': saveDetailDesignDocument.Id,
            'MaintenanceKoziCompany__c': master.MaintenanceKoziCompany__c,
          };

          wkOrderList[key].push(inOrderData);
        }
      }
    }

    // 申込、契約電柱の登録データ作成
    for (const item in wkOrderList) {
      if (!wkOrderList.hasOwnProperty(item)) {
        continue;
      }

      // 電柱番号で並び替え
      let wkDataList = wkContDntList[item].sort((x, y) => {
        return this.sortDntNo(x, y);
      });

      for (let i=0; i<wkDataList.length; i++) {
        wkDataList[i]['SerialNumber__c'] = i+1;
      }

      // 追加する申込の工事件名は、契約電柱の先頭の電柱番号を設定する
      wkOrderList[item][0]['KoziTitle__c'] = this.getKoziTitle(
          saveDetailDesignDocument['IsetuIraiNo__c'],
          manualInputCategory.includes(wkDataList[0].KyogaCategory__c) ?
          wkDataList[0]['DntNoManualInput__c'] : wkDataList[0]['DntNo__c']);

      orderList.push({
        'order': wkOrderList[item][0],
        'orderContractDnt': wkDataList,
      });
    }
  }

  /**
   * 削除ボタン押下
   *
   * @return {void}
   */
  doDelete = async () => {
    try {
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['削除'],
        },
        action: this.executeDelete,
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  };

  /**
   * 一覧画面から選択行削除
   *
   * @return {void}
   */
  executeDelete = async () => {
    try {
      const itemCount =
        this.gridRef.current.getGrid().collectionView.items.length;
      // チェックボックスのチェック数が1以上でない場合エラー
      const selectedItemCount = this.gridRef.current.selectedItemCount();
      if (selectedItemCount == 0) {
        this.props.doShowMessage({
          message: {
            id: 'CE0023',
            values: ['削除'],
          },
        });
        return;
      }

      // 一覧画面から選択行削除
      for (const data of this.gridRef.current.selectedItems) {
        this.gridRef.current.getGrid().collectionView.removeAt(data.dataIndex);
      }
      // 全て削除した場合
      if (itemCount == selectedItemCount) {
        // 削除ボタン押下不可
        this.setState({disableButton: true});
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 共架者一覧ボタン押下
   *
   * @return {void}
   */
  doKyogasyaList = async () => {
    try {
      // 参照モードの場合、チェックしない
      if (this.state.isDisabled ||
        this.state.isDisabledStatus) {
        this.moveKyogasyaList();
        return;
      }

      // 変更チェック
      if ((this.gridRef.current &&
        this.gridRef.current.commitItemsModified()) ||
        this.props.dirty) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: () => {
            this.doCheckInput();
          },
        });
        return;
      }
      this.doCheckInput();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    // 警告チェック
    let hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1403')) {
        hasError = (this.props.setGmnWarning[1403] != undefined ?
              this.props.setGmnWarning[1403] : false);
      }
    }

    // 入力チェックエラー
    if (hasError) {
      const noDnt =
        this.state.warningMessages.filter((data) => data.id == 'CE0019');
      if (noDnt.length > 0) {
        // 電柱0件は次画面に遷移しない
        this.props.doShowMessage({message: noDnt});
        return;
      }

      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['登録']},
        action: () => {
          this.moveKyogasyaList();
        },
        type: 'warn',
      });
      return;
    }

    // 入力チェックOK
    this.moveKyogasyaList();
  }

  /**
   * 共架者一覧表示
   *
   * @return {void}
   */
  moveKyogasyaList = () => {
    this.props.history.push({pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/KyogasyaList'});
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 電柱選択で選ばれた電柱を設定する
   * @param {array} dnt
   */
  setSelectedDnt = (dnt) => {
    console.dir(dnt);

    // 必須項目が入っていない場合はエラーとする。
    const messages = [];
    this.checkInput(dnt, messages);
    if (messages.length > 0) {
      this.props.doShowMessage({message: messages});
      return;
    }

    // すでに追加されている電柱番号はエラーとする。
    // 一覧画面表示データ
    const listData = this.gridRef.current.getSourceCollection();
    for (const data of listData) {
      if (dnt.length > 0) {
        const result = dnt.filter(
            (item) => isSameContractDnt(data, item));
        if (result.length > 0) {
          this.props.doShowMessage({
            message: {
              id: 'CE0034',
              values: ['選択した電柱'],
            },
          });
          return;
        }
      }
    }

    // 追加レコードと現在表示中のレコード数加算
    const countData = dnt.length + listData.length;

    // 汎用マスタから登録最大件数を取得
    const maxKensu = getDataFromGeneralPurposeMap(
        this.props.generalPurposeMap, 'RegisterMaxKensu2', 1);
    if (countData > maxKensu.ValueNum1__c) {
      this.props.doShowMessage({
        message: {
          id: 'CE0055',
          values: [maxKensu.ValueNum1__c],
        },
      });
      return;
    }

    // 一覧画面から選択行追加
    for (const data of dnt) {
      const addItem = this.gridRef.current.getGrid().collectionView.addNew();

      for (const key of dntParams) {
        if (Object.hasOwnProperty.call(data, key)) {
          addItem[key] = data[key];
        }
      }

      // 表示用線路名取得
      addItem['Dsp_SenroName__c'] =
        senroNameFormat(data['SenroName__c'], data['SenroNameKana__c'], data['SenroCode__c']);

      // 表示用電柱番号取得
      addItem['Dsp_DntNo__c'] = dntNoFormat(
          data['SenroCode__c'], data['DntNoManualInput__c'],
          data['K6KansnNo__c'], data['K6Bunk1__c'],
          data['K6Bunk2__c'], data['K6Bunk3__c'],
          data['K22SzbtNo__c'], data['K22GatiCategory__c']);

      // 事業所取得
      const nWZgsyoName = this.props.generalPurposeMap.NWZgsyo.filter(
          (value) => value.Code__c == data['NWZgsyo__r_Code__c']);
      // DB登録されるまでの繋ぎのため、事業所名称を設定
      addItem['NWZgsyo__r.Name'] = nWZgsyoName ? nWZgsyoName[0].Name : '';
      // 事業所コードを設定
      Object.assign(addItem,
          {'NWZgsyo__r': {'Code__c': nWZgsyoName ? nWZgsyoName[0].Code__c : ''}});
      // 中電ＮＷ事業所
      addItem['NWZgsyo__c'] = nWZgsyoName ? nWZgsyoName[0].Id : '';

      // 電柱区分
      const dntCategory = this.props.generalPurposeMap.DntCategory.filter(
          (value) => value.Code__c == data['DntCategory__c']);
      addItem['DntCategoryName'] = dntCategory ? dntCategory[0].Name : '';

      this.gridRef.current.getGrid().collectionView.commitNew();
    }
  }

  /**
   * 自由入力_電柱番号の一致判定
   * @param {Object} target 比較対象
   * @param {Object} master 比較対象
   * @return {boolean} true：一致、false：不一致
   */
  checkManualDntNo(target, master) {
    if (
      (master.NWZgsyo__r &&
        target.NWZgsyo__r.Code__c == master.NWZgsyo__r.Code__c) &&
      target.SenroName__c == master.SenroName__c &&
      target.DntNoManualInput__c == master.DntNoManualInput__c &&
      target.DntCategory__c == master.DntCategory__c &&
      target.KyogaCategory__c == master.KyogaCategory__c) {
      return true;
    }
    return false;
  }

  /**
   * 電柱番号の並び替え
   * @param {*} x ソート対象
   * @param {*} y ソート対象
   * @return {*} ソート結果
   */
  sortDntNo(x, y) {
    if (manualInputCategory.includes(x.KyogaCategory__c) &&
      manualInputCategory.includes(y.KyogaCategory__c)) {
      // 自由入力の場合
      if (x.DntNoManualInput__c < y.DntNoManualInput__c) return -1;
      if (x.DntNoManualInput__c > y.DntNoManualInput__c) return 1;
      return 0;
    } else if (manualInputCategory.includes(x.KyogaCategory__c) &&
     !manualInputCategory.includes(y.KyogaCategory__c)) {
      // 自由入力、電柱番号選択の場合
      if (x.DntNoManualInput__c < y.DntNo__c) return -1;
      if (x.DntNoManualInput__c > y.DntNo__c) return 1;
      return 0;
    } else if (!manualInputCategory.includes(x.KyogaCategory__c) &&
     manualInputCategory.includes(y.KyogaCategory__c)) {
      // 電柱番号選択、自由入力の場合
      if (x.DntNo__c < y.DntNoManualInput__c) return -1;
      if (x.DntNo__c > y.DntNoManualInput__c) return 1;
      return 0;
    } else {
      // 電柱番号選択の場合
      if (x.DntNo__c < y.DntNo__c) return -1;
      if (x.DntNo__c > y.DntNo__c) return 1;
      return 0;
    }
  }

  /**
  * 入力チェック
  * @param {array} items チェック対象
  * @param {array} messages メッセージ格納用
  */
  checkInput(items, messages) {
    for (const item of items) {
      // 表示用電柱番号取得
      const dntNo = dntNoFormat(
          item['SenroCode__c'], item['DntNoManualInput__c'],
          item['K6KansnNo__c'], item['K6Bunk1__c'],
          item['K6Bunk2__c'], item['K6Bunk3__c'],
          item['K22SzbtNo__c'], item['K22GatiCategory__c']);

      if (!item['DntCategory__c']) {
        console.warn('電柱区分が未設定のため、追加不可');
        messages.push({
          id: 'ME0184',
          values: [dntNo],
        });
        continue;
      }

      if (manualInputCategory.includes(item.KyogaCategory__c)) {
        // 自由入力の場合
        if (!item['DntNoManualInput__c']) {
          console.warn('電柱番号_自由入力が未設定のため、追加不可');
          messages.push({
            id: 'ME0184',
            values: [dntNo],
          });
          continue;
        }
        if (!item['NWZgsyo__r_Code__c']) {
          console.warn('事業所コードが未設定のため、追加不可');
          messages.push({
            id: 'ME0184',
            values: [dntNo],
          });
          continue;
        }
        if (!item['SenroName__c']) {
          console.warn('線路名が未設定のため、追加不可');
          messages.push({
            id: 'ME0184',
            values: [dntNo],
          });
          continue;
        }
      } else {
        // 電柱番号選択の場合
        if (!item['DntNo__c']) {
          console.warn('電柱番号が未設定のため、追加不可');
          messages.push({
            id: 'ME0184',
            values: [dntNo],
          });
          continue;
        }
      }
    }
  }

  /**
  * ボタンの活性、非活性化
  */
  checkHandler = () => {
    let bRet = false;
    if (this.gridRef && this.gridRef.current &&
      this.gridRef.current.selectedItemCount() > 0) {
      bRet = true;
    }
    this.setState({disableButton: !bRet});
  }

  /**
   * 一覧をクリアする
   */
  doClearList = () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.setItems(null);
    }
  }

  /**
   * 一覧の更新時
   *
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction = (s, e) => {
    try {
      let islist = false;
      // 一覧に追加、変更、削除したデータ取得
      const grid = this.gridRef.current.getGrid();

      // 一覧から更新データがあるか判定
      if (
        ((grid.collectionView)?.itemsAdded &&
         (grid.collectionView).itemsAdded.length > 0) ||
        ((grid.collectionView)?.itemsEdited &&
         (grid.collectionView).itemsEdited.length > 0) ||
        ((grid.collectionView)?.itemsRemoved &&
         (grid.collectionView).itemsRemoved.length > 0)) {
        islist = true;
      }

      if (this.state.isModified != islist) {
        this.setState({isModified: islist});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/List'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  renderTable() {
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'Rows',
      counterOn: false,
      style: {maxHeight: '600px'},
      initItems: this.items,
      isReadOnly: this.state.isDisabled,
      useStore: false,
      validateEdits: false,
      getError: this.getError,
      checkedFunction: this.checkHandler,
      refreshedFunction: this.refreshedFunction,
    };

    return (
      // 一覧表示部の表示設定
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='NWZgsyo__r.Name' header='事業所' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup header='電柱'>
            <FlexGridColumnGroup binding='Dsp_SenroName__c' header='線路名' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='Dsp_DntNo__c' header='電柱番号' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
            <FlexGridColumnGroup binding='DntCategoryName' header='電柱区分' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrSideKoziEachDntContents__c' header='電力側電柱別\n工事内容' dataType='String'
            isReadOnly = {this.state.isDisabled || this.state.isDisabledStatus}
            dataMap =
              {this.props.generalPurposeMap && this.drDntKoziContentsMap}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='EachDntKoziContentHosoku__c' header='電力側電柱別\n工事内容／補足事項' dataType='String' maxLength={20} width={180}
            isReadOnly={this.state.isDisabled || this.state.isDisabledStatus}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='EachDntMessage__c' header='電柱別連絡事項' dataType='String' maxLength={20} width={300}
            isReadOnly={this.state.isDisabled || this.state.isDisabledStatus}>
          </FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {classes} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<AddIcon />}
            onClick={() => {
              this.setState({modalOpen: true});
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isDisabled || this.state.isDisabledStatus}
          >
            <span>追加</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <DangerButton
            startIcon={<DeleteIcon />}
            onClick={() => {
              this.doDelete();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isDisabled || this.state.disableButton ||
              this.state.isDisabledStatus}
          >
            <span>削除</span>
          </DangerButton>
        </Grid>

        <Grid key="btn3" item>
          <PositiveButton
            startIcon={<SaveAltIcon />}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isDisabled || this.state.isDisabledStatus}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn4" item>
          <PositiveButton
            onClick={this.doKyogasyaList}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isDisabled}
          >
            <span>共架者一覧</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn5" item>
          <BackButton
            showWarning={true}
            props={this.props}
            isModified={this.state.isModified}
            onClick={() => {
              this.doClearList();
              this.props.doSetYbdsPath(null);
              this.props.doSetGmnWarning('1403', false);
            }}/>
        </Grid>
      </Grid>
    );

    return (
      <div>
        <form noValidate autoComplete="off">
          <MainContainer
            props={this.props}
            footerBtn={footerBtn}
          >
            {/* 警告メッセージ */}
            <WarningMessageArea messages={this.state.warningMessages}/>

            <PaperPart>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >

                <Grid key="key1" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlacePrefecturesName"
                    className={classes.fields}
                    component={renderTextField}
                    label="都道府県"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key2" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlaceMunicipalities__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="市区町村"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key3" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlacePlace__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="場所"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key4" item xs={12} sm={6} md={3}>
                  <Field
                    name="DntNo__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="電柱番号"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key5" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziOverview__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="工事概要"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key6" item xs={12} sm={6} md={3}>
                  <Field
                    name="IsetuIraiDesignDocumentNo__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="設計書番号"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key7" item xs={12} sm={6} md={3}>
                  <Field
                    name="DesignTantoName__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="設計担当者"
                    fullWidth
                    type="text"
                    InputProps={{readOnly: true}}
                    validate={[
                    ]}
                  />
                </Grid>

              </Grid>
            </PaperPart>

            <Grid container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                {this.renderTable()}
              </Grid>
            </Grid>

          </MainContainer>
          <SelectPoleNo
            YbdsType="G"
            modalOpen={this.state.modalOpen}
            closeButton={this.closeSelectDnt}
            setSelectedPole={this.setSelectedDnt}/>
        </form>
      </div>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  values: PropTypes.object,
  designDocumentId: PropTypes.string,
  generalPurposeMap: PropTypes.object,
  contactMasterList: PropTypes.array,
  saveDetailDesignDocument: PropTypes.object,
  contractDntMasterList: PropTypes.array,
  orderSubAllList: PropTypes.array,
  ybdsPath: PropTypes.string,
  setGmnWarning: PropTypes.object,
  doShowMessage: PropTypes.func.isRequired,
  doGetDetailDesignDocument: PropTypes.func,
  doDelDetailDesignDocument: PropTypes.func,
  doGetContractDntMasterList: PropTypes.func,
  doGetOrderSubAllList: PropTypes.func,
  doGetContactMasterList: PropTypes.func,
  doClearOrderSubAllList: PropTypes.func,
  doSetYbdsPath: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  dirty: PropTypes.bool,
  orderLastModifiedDate: PropTypes.string,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => ({
  generalPurposeMap: state.common.generalPurposeMap,
  saveDetailDesignDocument: state.isetuIrai.saveDetailDesignDocument,
  designDocumentId: state.isetuIrai.designDocumentId,
  contractDntMasterList: state.contractDnt.contractDntMasterList,
  contactMasterList: state.common.contactMasterList,
  orderSubAllList: state.attachorder.orderSubAllList,
  ybdsPath: state.common.ybdsPath,
  setGmnWarning: state.common.setGmnWarning,
  initialValues: {
    // 初回表示時、valuesがundefinedになるため、設定する
  },
  orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
});

const mapDispatchToProps = {
  doGetContactMasterList: commonOperations.doGetContactMasterList,
  doGetDetailDesignDocument: isetuIraiOperations.doGetDetailDesignDocument,
  doDelDetailDesignDocument: isetuIraiOperations.doDelDetailDesignDocument,
  doGetContractDntMasterList: contractDntOperations.doGetContractDntMasterList,
  doGetOrderSubAllList: attachorderOperations.doGetOrderSubAllList,
  doClearOrderSubAllList: attachorderOperations.doClearOrderSubAllList,
  doSetYbdsPath: commonOperations.doSetYbdsPath,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'IsetuIraiDetailInfInput';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import * as wjCore from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';
import {Popup, InputNumber, InputDate} from '@grapecity/wijmo.react.input';
import * as wijmoInput from '@grapecity/wijmo.input';
import {DataType} from '@grapecity/wijmo';

import {getCodeFromGeneralPurposeMap, getComboName, changeDateUtc,
  changeDateFormat, changeDateFormatter, getErrorCode, API_CODE,
  checkPhotoExists, getResponseResult} from '../../../../../../common/common.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../../../../atoms/Buttons/NegativeButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import {AttachedPicturesTenSyunko} from '../../../../../molecules/HelpComponent.js';
import CommonTheme from '../../../../../../Theme.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import {orderDntFields, orderBasicFields} from '../../../../../../common/SFFields';
import * as validateRule from '../../../../../validateRule.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import {ConfirmWarningMessageArea} from '../../../../../molecules/WarningMessageArea.js';
import Required from '../../../../../atoms/RequiredMark.js';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';
import Upload from '../../../../../organisms/UploadCustom';
import {checkFileNameLength, checkFileSizeZero,
  checkSameName} from '../../../../../../common/fileOperations.js';
import withMediaQuery from '../../../../../atoms/withMediaQuery.js';
import HelpIcon from '@material-ui/icons/Help';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

const orderInfo = [
  {key: 'OrderNo__c', name: '本申込番号', value: ''},
  {key: 'OrderDate__c', name: '本申込年月日', value: '', format: 'date'},
  {key: 'OrderKyogaZgsya__r', name: '申込共架事業者', value: ''},
  {key: 'StbTypeName', name: '設備種別', value: ''},
  {key: 'KyogaTypeName', name: '共架種別', value: ''},
];
const koziInfo = [
  {key: 'KoziTitle__c', name: '工事件名', value: ''},
  {key: 'KoziPlaceMainDntNo_SenroName__c', name: '工事場所／線路名', value: ''},
  {key: 'Dsp_KoziPlaceMainDntNo__c', name: '工事場所／代表電柱番号', value: ''},
  {key: 'KoziPlacePrefecturesName', name: '工事場所／都道府県', value: ''},
  {key: 'KoziPlaceMunicipalities__c', name: '工事場所／市区町村', value: ''},
  {key: 'KoziPlacePlace__c', name: '工事場所／場所', value: ''},
];

const updateParams = {
  Id: null,
  AttachSpotHeightSyunko__c: null, // 取付点高さ(m)
  SyunkoDate__c: null, // 竣工日
  SyunkoReportAcceptedDate__c: null, // 竣工届受理日
  KoziUndo__c: null, // 工事取止
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null,
      open: false,
      agree: false,
      isDisabled: true,
      isModified: false,
      warningMessages: [],
      refFiles: [],
      syunkoFiles: [],
      syunkoDelFiles: [],
    };
    this.popup = null;
    this.gridRef = React.createRef();
    this.koziUndoRef = React.createRef();
    this.filterChangingHandler = this.filterChangingHandler.bind(this);
    this.cellStyleFunction = this.cellStyleFunction.bind(this);
    this.editPopup = this.editPopup.bind(this);
    this.dateEditor = new wijmoInput.InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });

    // 編集ダイアログ用
    this.editedAttachSpotHeightSyunko = null;
    this.editedSyunkoDate = null;
    this.editedKoziUndo = false;

    // 表示データ初期化
    for (const row of orderInfo) {
      row['value'] = '';
    }
    for (const row of koziInfo) {
      row['value'] = '';
    }

    // 添付写真
    this.headerAttachPicturesTemplate = (cell) =>

      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <AttachedPicturesTenSyunko/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      return this.checkGrid(item, prop, parsing);
    };
  }

  /**
   * 初期処理
  */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderContractDnt();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1027_ten', 'Id');
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 竣工写真設定
   * @param {object} files
   * @param {object} deleteFiles
   */
  setSyunkoFiles = (files, deleteFiles) => {
    try {
      const _files =[];
      for (const file of files) {
        file['AttachedFileType__c'] = 'A23';
        _files.push(file);
      }
      this.setState({
        syunkoFiles: _files,
        syunkoDelFiles: deleteFiles,
        isModified: true,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusKoziStartOk =
    getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
    const attachedFileTypeOrder = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA22', 3);
    const attachedFileTypeSyunko = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeA23', 3);

    // 申込IDに紐づく申込電柱を取得する
    const orderConditions = {
      'Id': orderId,
      'RecordType.DeveloperName': 'MainOrder',
    };
    const dntConditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'MainOrder',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response =
    await this.props.doGetSyunkoOrderAndContractDnt(
        orderConditions, orderBasicFields,
        dntConditions, orderDntFields, appSortParams, true);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    // 排他チェックのために申込の最終更新日時を保存する
    if (!isInit) {
      this.setLastModifiedDate(response);
    }

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    let _hasError = false;
    if (_warningMessages.length > 0) {
      _hasError = true;
    }

    // グリッド初期化
    let _contractDntList = [];
    for (const contractDnt of this.props.contractDntList) {
      contractDnt.SyunkoDate__c = changeDateUtc(contractDnt.SyunkoDate__c);
      _contractDntList.push(contractDnt);
    }
    this.gridRef.current.setScreenState('1027_ten');
    const cv = createCollectionView(_contractDntList,
        true, {}, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 添付ファイルを画面に表示する
    let _refFiles = [];
    let _syunkoFiles = [];
    if (this.props.contentDocuments &&
          this.props.contentDocuments.length > 0) {
      _refFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeOrder);
      _syunkoFiles = this.props.contentDocuments.filter(
          (record) =>
            record.AttachedFileType__c == attachedFileTypeSyunko);
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
      orderStatusKoziStartOk ? false : true), // 竣工待ち/工事開始可のみ編集可
      hasError: _hasError,
      isModified: false,
      warningMessages: _warningMessages,
      refFiles: _refFiles,
      syunkoFiles: _syunkoFiles,
      syunkoDelFiles: [],
    });
    await this.props.doSetGmnWarning('1027_ten', _hasError);

    return true;
  }

  /**
   * 申込の最終更新日時を保存
   * @param {object} response APIの戻り値
   */
  setLastModifiedDate = async (response) => {
    let orderId = null;
    let lastModifiedDate = null;
    if (response) {
      const data = response.data.body.data ? response.data.body.data : null;
      orderId =
        data && Object.hasOwnProperty.call(data, 'order') &&
        Object.hasOwnProperty.call(data.order, 'Id') ?
        data.order.Id : null;
      lastModifiedDate =
            data && Object.hasOwnProperty.call(data, 'order') &&
            Object.hasOwnProperty.call(data.order, 'LastModifiedDate') ?
            data.order.LastModifiedDate : null;
    }
    await this.props.doSetOrderModifiedCondition(orderId, lastModifiedDate);
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusKoziStartOk =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
    if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusKoziStartOk) {
      // 竣工待ち／工事開始可のときだけ入力チェックする
      return;
    }
    let isKoziAri = false;
    for (const contractDnt of this.props.contractDntList) {
      // 工事取止の場合、必須チェックしない
      if (contractDnt.KoziUndo__c != true) {
        isKoziAri = true;
        // 必須チェック
        // 取付点高さ（m）
        if (contractDnt.AttachSpotHeightSyunko__c == undefined ||
          contractDnt.AttachSpotHeightSyunko__c == null) {
          warningMessages.push({
            id: 'CE0017',
            values: ['取付点高さ（m）（施工後）(' + contractDnt.SerialNumber__c + '行目)'],
          });
        }
        // 竣工日
        if (!contractDnt.SyunkoDate__c) {
          warningMessages.push({
            id: 'CE0017',
            values: ['竣工日(' + contractDnt.SerialNumber__c + '行目)'],
          });
        }
        // 添付写真
        const photo = this.props.photoExists[contractDnt.Id];
        checkPhotoExists(photo, contractDnt, warningMessages);
      }
      // データ範囲チェック。工事取止の場合、0.0もOKにする
      const minHeightTen = contractDnt.KoziUndo__c != true ? 2.5 : 0.0;
      if (contractDnt.AttachSpotHeightSyunko__c != null &&
        contractDnt.AttachSpotHeightSyunko__c < minHeightTen ||
        contractDnt.AttachSpotHeightSyunko__c > 99.9) {
        warningMessages.push({
          id: 'CE0026',
          values: ['取付点高さ（m）（施工後）(' + contractDnt.SerialNumber__c + '行目)', minHeightTen + '～99.9'],
        });
      }
      // 未来日チェック
      // 竣工日
      if (contractDnt.SyunkoDate__c) {
        if (validateRule.futureDate(changeDateFormat(contractDnt.SyunkoDate__c, 'YYYY-MM-DD'))) {
          warningMessages.push({
            id: 'CE0136',
            values: ['竣工日', contractDnt.SerialNumber__c],
          });
        }
      }
    }
    // 添付ファイルの必須チェックを行う。
    // 全て工事取止する場合、必須チェックしない
    if (isKoziAri == true) {
      const attachedFiles = this.props.contentDocuments != null ?
        this.props.contentDocuments : [];
      let contents = attachedFiles.filter((data)=>data.AttachedFileType__c == 'A23');
      if (contents.length == 0) {
        warningMessages.push(
            {id: 'CE0018',
              values: ['装柱図（施工後）']},
        );
      }
    }
  }

  /**
   * グリッド入力チェック
   * @param {object} item
   * @param {object} prop
   * @param {object} parsing
   * @return {string} チェック結果
   */
  checkGrid = (item, prop, parsing) => {
    // 必須チェック
    // 取付点高さ（m）（施工後）,竣工日
    if (item['KoziUndo__c'] != true &&
        ['AttachSpotHeightSyunko__c', 'SyunkoDate__c'].includes(prop)) {
      // CE0017
      let error = validateRule.required4(
          item[prop]);
      if (error) {
        return error;
      }
    }
    // データ範囲チェック
    // 取付点高さ（m）（施工後）
    const minHeight = item['KoziUndo__c'] != true ? 2.5 : 0.0;
    if (['AttachSpotHeightSyunko__c'].includes(prop)) {
      // CE0026
      if (item[prop] < minHeight || item[prop] > 99.9) {
        return minHeight == 2.5 ?
          '2.5～99.9の範囲内で入力してください' :
          '0.0～99.9の範囲内で入力してください';
      }
    }
    // 未来日チェック
    // 竣工日
    if (prop == 'SyunkoDate__c') {
      // CE0136
      let error = validateRule.futureDate(
          changeDateFormatter(item.SyunkoDate__c, 'YYYY-MM-DD'));
      if (error) {
        return error;
      }
    }
    return null;
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({
      open: false,
      agree: false,
    });
  };

  cellStyleFunction = (s, e, grid) => {
    if (s.cells === e.panel &&
      e.panel.cellType == wjgGrid.CellType.Cell) {
      // 竣工結果未確認の行をグレーアウトする
      // グリッドをスクロールすると、スタイルを再利用してしまう。wijmoの制限事項。
      // 竣工結果以外のセルのスタイルを変更しないように、
      // セルのスタイルをリセットしてから変更対象のセルを処理する。
      e.cell.style.backgroundColor = '';
      const col = s.columns[e.col];
      if ((col.binding == 'SyunkoReportAcceptedDate__c' ||
         col.binding == 'ResubmitNecessity__c' ||
          col.binding == 'ResubmitReason__c') &&
          (!grid.editRange || !grid.editRange.contains(e.row, e.col))) {
        const acceptDateValue = grid.getCellData(e.row, 'SyunkoReportAcceptedDate__c');
        const rereportNeedOrNotValue = grid.getCellData(e.row, 'ResubmitNecessity__c');
        const rereportReasonValue = grid.getCellData(e.row, 'ResubmitReason__c');
        if (!acceptDateValue && !rereportNeedOrNotValue &&
          !rereportReasonValue) {
          e.cell.style.backgroundColor = 'gray';
        }
      }
      const data = s.rows[e.row].dataItem;
      // 工事取止チェックがONかつ申込ステータスが竣工検査中または完結の場合、
      // 工事取止した行を編集不可にしてグレーアウトする。
      if (data.KoziUndo__c === true && this.state.isDisabled) {
        s.rows[e.row].cssClass = 'gray';
      }
      // 工事取止
      if (col.binding == 'KoziUndo__c' && s.rows[e.row] &&
        !this.state.isDisabled) {
        // 竣工確認結果にデータが入っている場合は編集不可
        if (data.SyunkoReportAcceptedDate__c ||
          data.ResubmitNecessity__c ||
          data.ResubmitReason__c) {
          // 工事取止かつ編集不可状態のときは以下のスタイル不要。
          if (data.KoziUndo__c !== true || !this.state.isDisabled) {
            wijmo.addClass(e.cell, 'wj-state-disabled');
            wijmo.addClass(e.cell, 'uneditableCell');
          }
        } else {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'uneditableCell');
        }
      }
    }
  };

  /**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ 取付点高さ
   * @param {*} ctl
   */
  initializeEditedAttachSpotHeightSyunko(ctl) {
    this.editedAttachSpotHeightSyunko = ctl;
  }

  /**
   * 編集ダイアログ 竣工日
   * @param {*} ctl
   */
  initializeEditedSyunkoDate(ctl) {
    this.editedSyunkoDate = ctl;
  }

  /**
   * 編集ダイアログ
   * 工事取止
   * @param {*} e
   */
  changeKoziUndo(e) {
    if (e.target) {
      this.editedKoziUndo = e.target.checked;
    }
  }

  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
  editPopup(grid, e) {
    const ht = grid.hitTest(e);
    if (ht.panel === grid.rowHeaders) {
      let _editItem = grid.rows[ht.row].dataItem;
      this.setState({editItem: _editItem});
      this.editedAttachSpotHeightSyunko.value =
        _editItem.AttachSpotHeightSyunko__c === null ?
        0 : _editItem.AttachSpotHeightSyunko__c; // 取付点高さ（m）施工後
      this.editedSyunkoDate.value =
        _editItem.SyunkoDate__c; // 竣工日
      this.editedKoziUndo =
          _editItem.KoziUndo__c; // 工事取止
      this.koziUndoRef.current.checked =
        _editItem.KoziUndo__c;
      // 竣工確認結果にデータが入っている場合は結果設定済フラグをたてて編集不可にする
      let _isSyunkoReportResult = false;
      if (_editItem.SyunkoReportAcceptedDate__c ||
        _editItem.ResubmitNecessity__c ||
        _editItem.ResubmitReason__c) {
        _isSyunkoReportResult = true;
      }
      this.koziUndoRef.current.disabled =
        _isSyunkoReportResult || this.state.isDisabled;
      this.popup.show(true, (s) => {
        if (s.dialogResult === 'wj-hide-ok') {
          (grid.collectionView).editItem(_editItem);
          _editItem.AttachSpotHeightSyunko__c =
            this.editedAttachSpotHeightSyunko.value; // 取付点高さ（m）
          _editItem.SyunkoDate__c =
            this.editedSyunkoDate.value; // 竣工日
          _editItem.KoziUndo__c = this.editedKoziUndo;// 工事取止
          (grid.collectionView).commitEdit();
        }
        grid.focus();
      });
    }
  };

  /**
  * 申込電柱の登録/更新処理
  */
  insertOrUpdate = async () => {
    const {order} = this.props;
    const contractDntList = this.gridRef.current.itemsEdited();
    const saveContractDntList = [];

    for (const dnt of contractDntList) {
      // 更新項目設定
      const updateData = {};
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(dnt, key)) {
          if (key == 'SyunkoReportAcceptedDate__c') {
            updateData[key] = null;
          } else if (key == 'SyunkoDate__c') {
            const syunkoDate = changeDateFormat(dnt[key], 'YYYY-MM-DD');
            updateData[key] = syunkoDate != '' ? syunkoDate : null;
          } else {
            updateData[key] = dnt[key];
          }
        }
      }
      // 申込電柱一覧
      saveContractDntList.push(updateData);
    }

    // 申込電柱更新条件
    const updateConditions = [];
    if (contractDntList.length > 0) {
      for (const row of contractDntList) {
        if (row.Id) {
          updateConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }
    // 申込電柱削除条件
    const deleteConditions = [];
    // 申込更新条件
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: this.props.orderModifiedCondition != null ?
        this.props.orderModifiedCondition.LastModifiedDate : null,
    };
    // 申込
    const updateOrder = {
      Id: order.Id,
    };

    // SFアップロードファイル
    let uploadFiles = this.state.syunkoFiles != null ?
        this.state.syunkoFiles : [];
    let deleteFiles = this.state.syunkoDelFiles != null ?
        this.state.syunkoDelFiles : [];

    const resSave = await this.props.doSaveSyunkoContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder, false, uploadFiles, deleteFiles);
    const resResult = getResponseResult(resSave, ['申込電柱', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      if (!this.gridRef.current) {
        return;
      }
      // 選択状態保存
      this.gridRef.current.saveScreenState('1027_ten', 'Id');

      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
          this.props.doShowMessage({
            message: ret.messages,
            action: async () => {
              // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
              this.moveToList();
            },
          });
        } else {
          this.props.doShowMessage({message: ret.messages});
        }
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
        },
      });
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 一覧に変更があるかチェックする
   * @return {bool} true: 変更あり
   */
   doCheckModified = () => {
     let result = this.state.isModified;
     if (this.gridRef.current) {
       result = this.gridRef.current.isItemsModified();
     }
     if (this.state.isModified != result) {
       this.setState({isModified: result});
     }
     return result;
   }

  /**
   * 写真登録画面へ遷移する前に変更有無をチェックする
   * @param {object} ctx 選択した契約電柱
   * @param {string} transfer 遷移先
  */
  checkBeforeMoveAttachedPictures = (ctx, transfer) => {
    if ((this.gridRef.current &&
        this.gridRef.current.commitItemsModified())) {
      this.props.doShowMessage({
        message: {
          id: 'CW0013',
        },
        action: () => {
          this.doMoveAttachedPictures(ctx.item, transfer);
        },
      });
      return;
    }
    this.doMoveAttachedPictures(ctx.item, transfer);
  }

  /**
  * 写真登録画面へ遷移
  * @param {string} item 選択した契約電柱
  * @param {string} transfer 遷移先
  */
  doMoveAttachedPictures =(item, transfer) => {
    if (transfer == '施工後') {
      const id = item ? item.Id : null;
      if (!id) {
        return;
      }
      // 遷移元情報の設定
      this.props.doSetPhotoRegistrationInfo({
        contractDntId: id, // 写真と紐づくレコードIdを渡す
        transitionSrcId: '1027_ten',
        readOnly: this.state.isDisabled,
      });
      // 竣工写真登録へ遷移
      this.props.history.push({pathname: '/completionPhotoRegistration'});
    }
  }

  /**
   * 申込前の確認
   */
  doConfirm = async () => {
    try {
      if (!this.props.order.Id) {
        return;
      }
      if (!this.gridRef.current) {
        return;
      }
      const orderStatusKoziStartOk =
      getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'OrderStatus40', 2);// 竣工待ち／工事開始可
      if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusKoziStartOk) {
        return;
      }

      // 編集ありの場合、ユーザーに保存するか破棄するか確認
      const itemsModified = this.gridRef.current.isItemsModified();
      if (itemsModified || this.state.isModified) {
        this.props.doShowMessage({
          message: {
            id: 'CW0161',
            values: ['申込'],
          },
          action: async () => {
            // 保存処理
            const ret = await this.insertOrUpdate();
            if (!ret.success) {
              if (ret.messages.filter((data) => data.id == 'CE0048').length > 0) {
                this.props.doShowMessage({
                  message: ret.messages,
                  action: async () => {
                    // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
                    this.moveToList();
                  },
                });
              } else {
                this.props.doShowMessage({message: ret.messages});
              }
              return;
            }

            // 再検索
            const searchResult = await this.searchData();
            if (!searchResult) {
              return;
            }
            // エラーがなければ申込処理を行う
            if (!this.isErrorInApply()) {
              this.handleClickOpen();
            }
          },
        });
        return;
      } else {
        // エラーがなければ申込処理を行う
        if (!this.isErrorInApply()) {
          this.handleClickOpen();
        }
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込前の入力チェック
   * @return {bool} true: エラーあり, false:エラーなし
   */
  isErrorInApply = () => {
    let isError = false;
    if (this.props.setGmnWarning) {
      for (const gmnkey of ['1027_ten']) {
        if (Object.hasOwnProperty.call(
            this.props.setGmnWarning, gmnkey)) {
          isError = isError || this.props.setGmnWarning[gmnkey];
        }
      }
    }
    return isError;
  }

  /**
   * 申込確認ダイアログの申込ボタン押下
  */
  doApply = async () => {
    try {
      this.handleClose();
      this.updateOrder();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 申込処理実行
  */
  updateOrder = async () => {
    const {order, generalPurposeMap} = this.props;
    const orderStatusUpdate = getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus41', 2);
    const contractDntList = this.gridRef.current.itemsEdited();
    const saveContractDntList = [];

    for (const dnt of contractDntList) {
      // 更新項目設定
      const updateData = {};
      for (const key in updateParams) {
        if (Object.hasOwnProperty.call(dnt, key)) {
          if (key == 'SyunkoReportAcceptedDate__c') {
            updateData[key] = null; // 変更があった行だけ竣工届受理日をクリア
          } else if (key == 'SyunkoDate__c') {
            const syunkoDate = changeDateFormat(dnt[key], 'YYYY-MM-DD');
            updateData[key] = syunkoDate != '' ? syunkoDate : null;
          } else {
            updateData[key] = dnt[key];
          }
        }
      }
      // 申込電柱一覧
      saveContractDntList.push(updateData);
    }

    // 申込電柱更新条件
    const updateConditions = [];
    if (contractDntList.length > 0) {
      for (const row of contractDntList) {
        if (row.Id) {
          updateConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }
    // 申込電柱削除条件
    const deleteConditions = [];
    // 申込更新条件
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: this.props.orderModifiedCondition != null ?
        this.props.orderModifiedCondition.LastModifiedDate : null,
    };
    // 申込 竣工届登録日はサーバー側で設定する
    const updateOrder = {
      Id: order.Id,
      OrderStatus__c: orderStatusUpdate,
    };

    const response = await this.props.doSaveSyunkoContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder, true);
    const resResult = getResponseResult(response, ['竣工届申込', '申込']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      if (resResult.messages.filter((data) => data.id == 'CE0048').length > 0) {
        this.props.doShowMessage({
          message: resResult.messages,
          action: async () => {
            // 排他チェックエラーになったら、データの整合性をとるため一覧へ遷移する
            this.moveToList();
          },
        });
      } else {
        this.props.doShowMessage({
          message: resResult.messages,
        });
      }
      return;
    }

    this.props.doShowMessage({
      message: {
        id: 'CI0009',
        values: ['竣工届申込'],
      },
      action: () => {
        //  申込が成功したら一覧へ遷移
        this.moveToList();
      },
    });
  }

  /**
   * 竣工届一覧へ遷移
   */
  moveToList() {
    this.props.history.push(
        {pathname: '/SyunkoReportList'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c' ||
        e.getColumn().binding === 'ResubmitNecessity__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wjCore.Control.getControl(lbHost);
      let categoryName = 'DntCategory'; // 電柱区分
      if (e.getColumn().binding === 'ResubmitNecessity__c') {
        categoryName = 'NecessityCategory'; // 再提出要否
      }
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'KoziUndo__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        let result = '－';
        if (lb.collectionView.items[index].value === true) {
          result = '取止';
        }
        return result;
      };
      lb.collectionView.refresh();
    }
  }

  renderTable() {
    const {classes, generalPurposeMap, isMobile} = this.props;
    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      exceptFilters: ['AttachPicturesSyunko'],
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'Columns',
      counterOn: false,
      AddDeleteOn: false,
      frozenColumns: 3,
      isReadOnly: this.state.isDisabled,
      useStore: false,
      validateEdits: false,
      style: {height: '400px'},
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      formatItemFunction: this.cellStyleFunction,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <div>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="uneditableCell" width={120} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="uneditableCell" width={95} isReadOnly={true}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" cssClass="uneditableCell" width={65} isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          <FlexGridColumnGroup header="取付点高さ（m）" align="center">
            <FlexGridColumnGroup binding="AttachSpotHeight__c" header="申込時" dataType="Number" format="n1" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile} width={100}/>
            <FlexGridColumnGroup binding="AttachSpotHeightSyunko__c" header="施工後" dataType="Number" format="n1" visible={!isMobile} width={100}>
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.0}
                    max={99.9}
                    step={0.1}
                    format='n1'
                    value={ctx.value ? ctx.value : 0}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="添付写真" align="center">
            <FlexGridColumnGroup binding="AttachPicturesSyunko" header="施工後" align="center"
              cellTemplate={CellMaker.makeButton({
                text: '添付',
                cssClass: 'btn_navy',
                click: (e, ctx) => {
                  this.checkBeforeMoveAttachedPictures(ctx, '施工後');
                },
                attributes: {
                  tabindex: -1,
                },
              })}
            >
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerAttachPicturesTemplate}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="MaintenanceKoziCompany__r.Name" header="保守工事会社" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          <FlexGridColumnGroup binding="SyunkoDate__c" header="竣工日" dataType='Date' width={165}
            editor= {this.dateEditor} format='yyyy/MM/dd' isRequired={false} visible={!isMobile}>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="竣工確認結果" align="center">
            <FlexGridColumnGroup binding="SyunkoReportAcceptedDate__c" header="竣工届\n受理日" dataType="Date" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile} width={100}>
              <FlexGridCellTemplate cellType="Cell"
                template= {(context) => {
                  return changeDateFormat(
                      context.item.SyunkoReportAcceptedDate__c);
                }}
              />
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitNecessity__c" header="再提出要否" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'NecessityCategory', ctx.item.ResubmitNecessity__c);
              }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup binding="ResubmitReason__c" header="再提出理由" dataType="String" cssClass="uneditableCell" isReadOnly={true} visible={!isMobile}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="KoziUndo__c" header="工事取止" dataType={DataType.Boolean} cssClass="centerPos" visible={!isMobile} width={100}/>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              線路名　：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName__c') ?
                        this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
               電柱番号：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo__c') ?
                        this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <InputNumber
                id='id1027AttachSpotHeightSyunkoPopup'
                format='n1'
                min={0.0}
                max={99.9}
                step={0.1}
                initialized={
                  this.initializeEditedAttachSpotHeightSyunko.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>取付点高さ（m）（施工後）</label>
            </div>
            <br></br>
            <div className="wj-labeled-input">
              <span className='wj-input-label-custom'>
              竣工日
              </span>
              <InputDate
                id="id1027SyunkoDate"
                initialized={
                  this.initializeEditedSyunkoDate.bind(this)}
                isDisabled={this.state.isDisabled}>
              </InputDate>
            </div>
            <br></br>
            <div className="wj-labeled-input">
              <input
                id="id1027KoziUndoPopup"
                type="checkbox"
                ref={this.koziUndoRef}
                value={this.editedKoziUndo}
                onChange={(e) => {
                  this.changeKoziUndo(e);
                }}/>
              <label htmlFor="id1027KoziUndoPopup">
                    工事取止
              </label>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>

        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="attachedPicturesTenHelpComponent">
            <AttachedPicturesTenSyunko/>
          </div>
        </div>
      </div>
    );
  }

  /**
   * 申込情報表示
   * @param {array} rows
   * @return {object}
   */
  renderOrderInfo(rows) {
    return (
      <TableContainer>
        <Table size="small" style={{border: '0.5px solid gray'}}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row"
                  style={{borderRight: '1px solid gray', borderBottom: '1px solid gray', background: '#001a37', color: 'white', width: '200px'}}>
                  {row.name}
                </TableCell>
                <TableCell align="left" style={{borderBottom: '1px solid gray', width: '400px'}}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const {classes, applyInfo1, applyInfo2, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1027Apply" item>
          <PositiveButton
            id="btn1027Apply"
            onClick={handleSubmit(this.doConfirm)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled || this.state.hasError}>
            <span>申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1027TenSave" item>
          <PositiveButton
            id="btn1027TenSave"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            type="submit"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1027TenBack" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}
            onCheckGrid={this.doCheckModified}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='申込' fixAction=''/>
          }
          <WarningMessageArea messages={this.state.warningMessages}/>
          <Grid container
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={12} sm={6}>
              {this.renderOrderInfo(applyInfo1)}
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.renderOrderInfo(applyInfo2)}
            </Grid>
          </Grid>
          <br/>
          <Grid container
            direction="column"
            alignItems="flex-start">
            <Grid key="key1027TenTable" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
          <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            style={{paddingBottom: '20px'}}>
            <Grid key="key1027TenFile" item xs={12} sm={6}>
              <Grid container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid key="key1027TenRefFile" item xs={12}>
                  <Typography variant="h6" display="block">
                  装柱図（申込時）
                  </Typography>
                </Grid>
                <Grid key="key1027TenUploadRefFile" item xs={12}>
                  <Upload
                    componentId="refTenComponent"
                    maxFileCount={1}
                    maxFileSize={20971520}
                    required={false}
                    previewFlag={false}
                    previewWidth="35%"
                    acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                    initFile={this.state.refFiles}
                    disabled={true}
                  />
                </Grid>
                <Grid key="key1027TenSyunkoFile" item xs={12}>
                  <Typography variant="h6" display="block">
                  装柱図（施工後）
                    <font size="2">(※最大1ファイル、1ファイルあたり20MBまで登録できます)</font>
                    <Required/>
                    <MuiTooltip content={
                      <React.Fragment>
                        竣工後の全景, 近景等の写真を添付してください。
                      </React.Fragment>
                    }>
                      <InfoOutlined/>
                    </MuiTooltip>
                  </Typography>
                </Grid>
                <Grid key="key1027TenUploadSyunkoFile" item xs={12}>
                  <Upload
                    componentId="syunkoTenComponent"
                    maxFileCount={1}
                    maxFileSize={20971520}
                    required={true}
                    previewFlag={false}
                    previewWidth="35%"
                    acceptFileType=".pdf,.doc,.docx,.xls,.xlsx"
                    fileSetHandler={this.setSyunkoFiles}
                    initFile={this.state.syunkoFiles}
                    disabled={this.state.isDisabled}
                    customChecks={[
                      checkFileNameLength,
                      checkFileSizeZero,
                      checkSameName,
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainContainer>

        <React.Fragment>
          <Dialog onClose={this.handleClose}
            aria-labelledby="dialogTitle1027Ten"
            open={this.state.open}
            maxWidth="md">
            <DialogContent dividers>
              <Typography variant="body1" gutterBottom>
                  よろしければ以下をチェックして申込ボタンを押してください。
              </Typography>
              <Typography style={{whiteSpace: 'pre-wrap'}} component="div" gutterBottom>
                <Box fontWeight="fontWeightBold">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='chb1027TenConfirm'
                        checked={this.state.agree ? true : false}
                        onChange={(e) =>
                          this.setState({agree: e.target.checked})}
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                      />
                    }
                    label={
                      <div style={{maxWidth: '540px'}}>
                        <span style={{fontWeight: 'bold'}}>
                      自主点検により，自らの共架工事に不備は無く，本報告内容に誤り・虚偽が無いことを誓約します。
                      本報告後，共架工事の不備が判明し，改修等の指示があった場合は，速やかに対応します。
                        </span>
                      </div>}
                  />
                </Box>
              </Typography>
            </DialogContent>
            <DialogActions>
              <PositiveButton
                id="id1027TenDialogApply"
                onClick={handleSubmit(this.doApply)}
                variant="contained"
                size="large"
                disabled={this.state.agree === true? false: true}
                startIcon={<AddIcon/>}
              >
                <span>申込</span>
              </PositiveButton>
              <NegativeButton
                id="id1027TenDialogCancel"
                onClick={this.handleClose}
                variant="contained"
                startIcon={<KeyboardReturnIcon />}
                size="large"
              >
                <span>閉じる</span>
              </NegativeButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  order: PropTypes.object,
  applyInfo1: PropTypes.array,
  applyInfo2: PropTypes.array,
  orderId: PropTypes.string,
  contractDntList: PropTypes.array,
  photoExists: PropTypes.array,
  refFiles: PropTypes.array,
  syunkoFiles: PropTypes.array,
  syunkoDelFiles: PropTypes.array,
  contentDocuments: PropTypes.array,
  setGmnWarning: PropTypes.object,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doSetPhotoRegistrationInfo: PropTypes.func,
  doSaveSyunkoContractDnts: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  doGetSyunkoOrderAndContractDnt: PropTypes.func,
  doExistsPhoto: PropTypes.func,
  doClearOrderContractDnt: PropTypes.func,
  isMobile: PropTypes.bool,
  doSetOrderModifiedCondition: PropTypes.func,
  orderModifiedCondition: PropTypes.object,
};

const mapStateToProps = (state) => {
  // 本申込
  const order = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
    state.contractDnt.orderContractDntList.order : null;
  // 申込電柱
  const contractDntList = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
    state.contractDnt.orderContractDntList.contractDntList : [];
  // 写真存在チェック結果
  const photoExists = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'photoExistsPerOrder') ?
    state.contractDnt.orderContractDntList.photoExistsPerOrder : {};
    // 添付ファイル
  const contentDocuments = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contentDocuments') ?
    state.contractDnt.orderContractDntList.contentDocuments : [];

  // 画面表示
  if (order) {
    for (const key in order) {
      if (Object.hasOwnProperty.call(order, key)) {
        if (order[key] != undefined && order[key] != null) {
          for (const data of orderInfo) {
            if (key == data.key) {
              if (key == 'OrderDate__c') {
                data.value = changeDateFormat(order[key]);
              } else if (key == 'OrderKyogaZgsya__r') {
                data.value =
                  Object.hasOwnProperty.call(order[key], 'Name') ? order[key].Name : '' +
                  Object.hasOwnProperty.call(order[key], 'Account__r') &&
                  Object.hasOwnProperty.call(order[key].Account__r, 'Name') ?
                  order[key].Account__r.Name: '';
              } else {
                data.value = order[key];
              }
            }
          }
          for (const data of koziInfo) {
            if (key == data.key) {
              data.value = order[key];
            }
          }
        }
      }
    }
  }
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId,
    order: order,
    contractDntList: contractDntList,
    photoExists: photoExists,
    contentDocuments: contentDocuments,
    applyInfo1: orderInfo,
    applyInfo2: koziInfo,
    setGmnWarning: state.common.setGmnWarning,
    orderModifiedCondition: state.attachorder.orderModifiedCondition,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doSaveSyunkoContractDnts: contractDntOperations.doSaveSyunkoContractDnts,
  doSetPhotoRegistrationInfo: contractDntOperations.doSetPhotoRegistrationInfo,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doGetSyunkoOrderAndContractDnt:
    contractDntOperations.doGetSyunkoOrderAndContractDnt,
  doExistsPhoto: contractDntOperations.doExistsPhoto,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doSetOrderModifiedCondition:
    attachorderOperations.doSetOrderModifiedCondition,
};

const FORM_NAME = 'SyunkoTenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(withMediaQuery(
        [
          ['isMobile', (theme) => theme.breakpoints.down('xs')],
        ],
    )(Container))),
);

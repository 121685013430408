import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Container from '../../components/templates/Login/changePassword';

const mapStateToProps = (state) => ({
  loginInfo: state.auth.loginInfo,
});

const mapDispatchToProps = {
};

/**
 * ログイン画面:パスワード変更 ページ。
 * 画面ID:3000
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * 画面描画。
   *
   * @return {JSX.Element} 描画要素
   */
  render() {
    return (<Container
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  loginInfo: PropTypes.object.isRequired,
};

export default ContainerApp;

import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import {CommonButton} from './CommonButton.js';

import CommonTheme from '../../../Theme.js';

/** デンジャーボタンで使用するパレット */
const palette = CommonTheme.palette.secondary;

// デンジャーボタンスタイル
const styles = (theme) => createStyles({
  root: {
    color: palette.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.light + ' 0%, ' + palette.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.light + ' 0%, ' + palette.main + ' 100%)',
    ],
    borderColor: palette.dark,
  },
});

/** デンジャーボタン */
export const DangerButton = withStyles(styles)(CommonButton);
export default DangerButton;

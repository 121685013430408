import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Container from '../../../../../components/templates/Other/UserManagement/UserManagement/Input';
import {authOperations} from '../../../../../reducks/auth';
import {commonOperations} from '../../../../../reducks/common';
import {API_CODE} from '../../../../../common/common';

/** ユーザ管理(詳細)画面(自身の情報変更)パス */
const SELF_EDIT_PATH = '/Other/UserManagement/UserManagement/InputSelf';

export const mapStateToProps = (state) => ({
  accountMasterList: state.common.accountMasterList,
  contact: state.common.contact,
  contactId: state.common.contactId,
  userInfo: state.auth.userInfo,
});

export const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getAccountList: commonOperations.doGetAccountMasterList,
  getContact: commonOperations.doGetContact,
  getUserInfo: authOperations.getUserInfoOperation,
};

/**
 * 担当者情報取得フィールド一覧
 */
const contactFields = [
  'Id',
  'UserId__c',
  'LastName',
  'FirstName',
  'MainteKoziCompany__c',
  'TantoAddress__c',
  'Email',
  'TantoTel__c',
  'TantoFaxNo__c',
  'UserLevel__c',
  'HakkoDate__c',
  'HaisiDate__c',
  'DeleteFlag__c',
  'KyogaZgsyaCode__c',
  'KyogaZgsyoCode__c',
];

/**
 * 保守工事会社情報取得フィールド一覧
 */
const mainteKoziCompanyFields = [
  'Id',
  'Name',
  'MainteKoziCompanyCode__c',
];

/**
 * ユーザ管理（詳細） ページ。
 * 画面ID:1532
 *
 * @param {string} ret
 */
export class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);

    const ret = await this.initialize();
    this.doJudge(ret);
  }

  /**
   * 初期化。
   *
   * @return {Promise<boolean>} 成功時または独自のエラーを表示する場合true。システムエラーを表示する場合false
   */
  initialize = async () => {
    const {
      contactId,
      doShowMessage,
      history,
      getAccountList,
      getContact,
      getUserInfo,
    } = this.props;

    try {
      // ユーザ情報取得
      await getUserInfo();
      const {userInfo} = this.props;
      if (userInfo == null) {
        return false;
      }

      // 保守工事会社一覧取得
      const getResult = await getAccountList(
          {
            'RecordType.DeveloperName': 'MaintenanceKoziCompany',
          },
          mainteKoziCompanyFields,
      );

      if (getResult.data.body.errorCode != API_CODE.SUCCESS) {
        console.error('Failed get accountMasterList');
        return false;
      }

      // URLが自身の情報編集の場合は自身のIDを取得対象にする
      const isSelf = history.location.pathname == SELF_EDIT_PATH;
      const targetId = isSelf ? userInfo.Id : contactId;

      // 担当者IDが設定されていた場合、担当者を取得
      if (targetId != null) {
        const resultBody = await getContact(targetId, contactFields);

        // エラーコードチェック
        const errorCode = resultBody.errorCode;
        if (errorCode == API_CODE.ERROR_PERMISSION_DENIED) {
          // ユーザ取得結果が権限エラーの場合
          doShowMessage({
            message: {id: 'CE0056', values: ['ユーザ管理']},
            action: this.doMoveLogin,
          });
          return true;
        } else if (errorCode != API_CODE.SUCCESS) {
          // その他のエラーの場合
          console.error('Error Code: ' + errorCode);
          return false;
        }

        const contact = this.props.contact;
        if (contact == null) {
          console.error('contact is null');
          return false;
        }

        // ログインユーザのメールアドレス未設定時、登録を促すメッセージを表示
        if (!userInfo.Email && contact.Id == userInfo.Id) {
          doShowMessage({message: 'CE0186'});
        }
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }

  doJudge = (ret) => {
    if (!ret) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: this.doMoveLogin,
      });
    }
  }

  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    return (<Container
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  accountMasterList: PropTypes.array,
  contact: PropTypes.object,
  contactId: PropTypes.string,
  doShowMessage: PropTypes.func,
  getAccountList: PropTypes.func,
  getContact: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

export default ContainerApp;

import {getPermissionId} from '../../common/common.js';

/**
 * @typedef MinorItem 小項目単位の型
 * @property {string} name メニュー項目名
 * @property {string} [path] 遷移先
 */

/**
 * @typedef MiddleItem 中項目単位の型
 * @property {string} name メニュー項目名
 * @property {function[]} [conditions] 項目表示判定function
 * @property {MinorItem[]} items 小項目の一覧
 */

/**
 * @typedef MajorItem 大項目単位の型
 * @property {string} name メニュー項目名
 * @property {function[]} [conditions] 項目表示判定function
 * @property {MiddleItem[]} items 中項目の一覧
 * @property {function} [action] クリック時実行処理。指定時pathは使用しない
 * @property {string} [path] 遷移先
 */

/**
 * メニュー項目定義
 * @type {{[id:number]: MajorItem[]}}
 * */
const MENU_ITEMS = {
  // 共架事業者(電力サポート中国契約)
  1: [
    {
      name: 'メニュー',
      conditions: [isSideMenu],
      items: [],
      path: '/menu',
    },
    {
      name: '取付申込',
      conditions: [showMenuItem],
      items: [
        {
          name: '可否判定申込',
          items: [
            {name: '可否判定申込', path: '/ProprietyList'},
            {name: '可否判定申込結果受理', path: '/ProprietyResultList'},
          ],
        },
        {
          name: '取付申込',
          items: [
            {name: '本申込実施', path: '/FormalOrderList'},
            {name: '改造工事費用算定結果確認', path: '/AttachOrder/AttachOrder/GaisanCostCalculationResultConfirm/List'},
            {name: '改造工事完了件名確認', path: '/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/List'},
            {name: '開始通知受領', path: '/kyogaStartNotificationList'},
            {name: '竣工届作成・提出', path: '/SyunkoReportList'},
          ],
        },
      ],
    },
    {
      name: '撤去申込',
      conditions: [showMenuItem],
      items: [
        {
          name: '撤去申込',
          items: [
            {name: '竣工届作成・提出', path: '/TekyoSyunkoReportList'},
          ],
        },
      ],
    },
    {
      name: '移設依頼',
      conditions: [showMenuItem],
      items: [
        {
          name: '依頼内容確認',
          items: [
            {name: '移設依頼予定連絡一覧', path: '/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/List'},
          ],
        },
        {
          name: '工事実施',
          items: [
            {name: '工事日確定連絡受理', path: '/IsetuIrai/IsetuIraiCheck/IsetuIraiKoziAccept/List'},
            {name: '竣工届作成・提出', path: '/IsetuIrai/KoziDone/TransferConstructionCompleteReport/List'},
          ],
        },
      ],
    },
    {
      name: '改修依頼',
      conditions: [showMenuItem],
      items: [
        {
          name: '依頼内容確認',
          items: [
            {name: '改修依頼連絡受理', path: '/RepairIrai/ConfirmIraiContent/List'},
          ],
        },
        {
          name: '工事実施',
          items: [
            {name: '改造工事費用算定結果確認', path: '/RepairIrai/KoziDone/GaisanCostCalculationResultConfirm/List'},
            {name: '改造工事完了件名確認', path: '/RepairIrai/KoziDone/KoziCostPayOffContactReceive/List'},
            {name: '着工可能通知受領', path: '/RepairIrai/KoziDone/StartNotification'},
            {name: '竣工届作成・提出', path: '/RepairIrai/KoziDone/RepairIraiSyunkoReport/List'},
          ],
        },
      ],
    },
    {
      name: '訂正申込',
      conditions: [showMenuItem],
      items: [
        {
          name: '取付申込',
          items: [
            {name: '取付申込', path: '/CorrectAttachOrderList'},
          ],
        },
        {
          name: '撤去申込',
          items: [
            {name: '撤去申込', path: '/CorrectTekyoOrderList'},
          ],
        },
      ],
    },
    {
      name: '検索',
      conditions: [showMenuItem],
      items: [
        {
          name: '進捗確認',
          items: [
            {name: '工程進捗検索', path: '/processProgressSearch'},
          ],
        },
        {
          name: '請求',
          items: [
            {name: '請求明細検索', path: '/Search/Billing/BillingDetailSearch/List'},
          ],
        },
        {
          name: '契約電柱',
          items: [
            {name: '契約電柱検索', path: '/contractDntSearch'},
          ],
        },
      ],
    },
    {
      name: 'その他',
      conditions: [showMenuItem],
      items: [
        {
          name: '重要事項説明',
          items: [
            {name: '重要事項説明', path: '/importantSubjects'},
          ],
        },
        {
          name: '連絡事項',
          items: [
            {name: '重要連絡事項', path: '/Other/ContactZiko/JuyoZiko/List'},
            {name: '個別連絡事項', path: '/Other/ContactZiko/ContactZiko/List'},
          ],
        },
        {
          name: 'ユーザ管理',
          conditions: [isAdmin],
          items: [
            {name: 'ユーザ管理', path: '/Other/UserManagement/UserManagement/List'},
            {name: '共架者基本情報', path: '/Other/UserManagement/KyogaZgsyaBasicInfo'},
          ],
        },
      ],
    },
    {
      name: '代行入力（ユーザ切替）',
      conditions: [showDaikoInput],
      items: [
        {
          name: '代行入力（ユーザ切替）',
          items: [
            {name: '代行入力（ユーザ切替）', path: '/DaikoInput/List'},
          ],
        },
      ],
    },
    {
      name: 'ログアウト',
      items: [],
      action: cofirmLogout,
      conditions: [isSideMenu],
    },
  ],

  // 中電NW設計担当
  2: [
    {
      name: 'メニュー',
      conditions: [isSideMenu],
      items: [],
      path: '/menu',
    },
    {
      name: '移設依頼',
      items: [
        {
          name: '移設依頼登録',
          items: [
            {name: '移設依頼内容登録一覧', path: '/IsetuIrai/IsetuIraiRegister/IsetuIrai/List'},
          ],
        },
        {
          name: '工事日登録',
          items: [
            {name: '移設依頼工事日入力', path: '/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/List'},
          ],
        },
        {
          name: '外線工事可能確認',
          items: [
            {name: '外線工事(抜柱)可能設計書確認', path: '/IsetuIrai/GsKoziPossibleCheck/GsKoziPossibleCheckDesignDocument/Confirm'},
          ],
        },
      ],
    },
    {
      name: '代行入力（ユーザ切替）',
      conditions: [showDaikoInput],
      items: [
        {
          name: '代行入力（ユーザ切替）',
          items: [
            {name: '代行入力（ユーザ切替）', path: '/DaikoInput/List'},
          ],
        },
      ],
    },
    {
      name: 'ログアウト',
      conditions: [isSideMenu],
      items: [],
      action: cofirmLogout,
    },
  ],
  // 共架事業者(中国電力ネットワーク契約)
  3: [
    {
      name: 'メニュー',
      conditions: [isSideMenu],
      items: [],
      path: '/menu',
    },
    {
      name: '移設依頼',
      conditions: [showMenuItem],
      items: [
        {
          name: '依頼内容確認',
          items: [
            {name: '移設依頼予定連絡一覧', path: '/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/List'},
          ],
        },
        {
          name: '工事実施',
          items: [
            {name: '工事日確定連絡受理', path: '/IsetuIrai/IsetuIraiCheck/IsetuIraiKoziAccept/List'},
            {name: '竣工届作成・提出', path: '/IsetuIrai/KoziDone/TransferConstructionCompleteReport/List'},
          ],
        },
      ],
    },
    {
      name: 'その他',
      conditions: [isAdmin],
      items: [
        {
          name: 'ユーザ管理',
          items: [
            {name: 'ユーザ管理', path: '/Other/UserManagement/UserManagement/List'},
            {name: '共架者基本情報', path: '/Other/UserManagement/KyogaZgsyaBasicInfo'},
          ],
        },
      ],
    },
    {
      name: 'ログアウト',
      conditions: [isSideMenu],
      items: [],
      action: cofirmLogout,
    },
  ],
};

/**
 * メニュー項目表示判定。
 *
 * 共架事業者の場合、必ずtrueを返却します。
 * 共架事業者以外の場合、代行入力開始していればtrueを返却します。
 *
 * @param {'main'|'side'} menuType (未使用)
 * @param {object} userInfo ユーザ情報
 * @return {boolean} メニュー項目を表示する場合true、表示しない場合false
 */
function showMenuItem(menuType, userInfo) {
  // 共架事業者の場合は必ず項目を表示
  if (userInfo.RecordType.DeveloperName != 'Daikosya') {
    return true;
  }

  // 共架事業者以外の場合、代行開始していれば項目を表示
  if (userInfo.DaikoUserId != null) {
    return true;
  }

  return false;
}

/**
 * 管理者ユーザ判定。
 *
 * ユーザが管理者(UserLevel__cが"2")の場合trueを返却します。
 *
 * @param {'main'|'side'} menuType (未使用)
 * @param {object} userInfo ユーザ情報
 * @return {boolean} メニュー項目を表示する場合true、表示しない場合false
 */
function isAdmin(menuType, userInfo) {
  return userInfo.UserLevel__c == '2';
}

/**
 * サイドメニュー判定。
 *
 * @param {'main'|'side'} menuType メニュー種別
 * @return {boolean} サイドメニューの場合true、そうでない場合false
 */
function isSideMenu(menuType) {
  return menuType == 'side';
}

/**
 * 代行入力項目表示判定。
 *
 * @param {'main'|'side'} menuType メニュー種別(未使用)
 * @param {object} userInfo ユーザ情報
 * @param {object} generalPurpose 汎用マスタ
 * @return {boolean} 代行入力を表示する場合true、そうでない場合false
 */
function showDaikoInput(menuType, userInfo) {
  // 代行者ユーザが直接ログインしている場合
  if (userInfo.RecordType.DeveloperName == 'Daikosya') {
    return true;
  }

  // 代行入力中の場合
  if (userInfo.DaikoUserId != null) {
    return true;
  }

  return false;
}

/**
 * ログアウト確認ダイアログ表示。
 * @param {object} props プロパティ
 */
function cofirmLogout(props) {
  const {
    dirty,
    doLogout,
    doShowMessage,
    isEditPage,
  } = props;

  // 編集画面で値が変更されているかどうかでメッセージを差し換え
  const messageId = (isEditPage && dirty) ? 'CW0208' : 'CC0005';

  doShowMessage({
    message: {
      id: messageId,
      values: ['ログアウト'],
    },
    action: () => {
      doLogout();
    },
  });
}

/**
 * メニュー項目の取得。
 *
 * @param {'main'|'side'} menuType メニュー種別
 * @param {object} userInfo ユーザ情報
 * @param {object} generalPurpose 汎用マスタ
 * @return {MajorItem[]} メニュー大項目一覧
 */
export default function getMenuItems(menuType, userInfo, generalPurpose) {
  // 未ログイン時はメニューを返却しない
  if (!userInfo) {
    return [];
  }

  // ユーザによって異なるメニューを取得
  const id = getPermissionId(userInfo, generalPurpose);
  const menuItems = MENU_ITEMS[id].map((list) => ({...list}));

  // 大項目の絞り込み
  const retMenu = filterItem(menuItems, menuType, userInfo, generalPurpose);

  // 中項目の絞り込み
  for (const majorItem of retMenu) {
    majorItem.items =
      filterItem(majorItem.items, menuType, userInfo, generalPurpose);
  }

  return retMenu;
}

function filterItem(items, menuType, userInfo, generalPurpose) {
  return items.filter((item) => {
    // 項目表示判定が未指定の場合はメニュー項目追加
    if (!item.conditions) {
      return true;
    }

    // 表示判定を全て満たす場合はメニュー項目追加
    let checkAll = true;
    for (const condition of item.conditions) {
      if (!condition(menuType, userInfo, generalPurpose)) {
        checkAll = false;
        break;
      }
    }

    return checkAll;
  });
}

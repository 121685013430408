import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import HelpIcon from '@material-ui/icons/Help';
import ContactMailIcon from '@material-ui/icons/ContactMailOutlined';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {ListSubheader} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';

import {authOperations} from './../../../reducks/auth';
import {commonOperations} from './../../../reducks/common';
import {withRouter} from 'react-router-dom';

import SideMenu from '../SideMenu.js';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';

import CommonTheme from '../../../Theme.js';
import {isChudenNwContract, isNWDesignZgsya} from '../../../common/common';

import conf from '../../../config/config.js';

/** ユーザ管理(詳細)画面(自身の情報変更)パス */
const SELF_EDIT_PATH = '/Other/UserManagement/UserManagement/InputSelf';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  topgrow: {
    flexGrow: 1,
    margin: '3px 3px 3px 3px',
  },
  grow: {
    flexGrow: 1,
    textAlign: 'left',
  },
  UserHeader: {
    textAlign: 'center',
    margin: '86px 24px 20px 24px',
  },
  appBarTop: {
    top: 0,
  },
  appBar: {
    top: 22,
  },
  appBarCommon: {
    maxWidth: CommonTheme.breakpoints.values.lg,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up(CommonTheme.breakpoints.values.lg)]: {
      maxWidth: 'calc(' + CommonTheme.breakpoints.values.lg + 'px + ' + CommonTheme.menu.width + 'px)',
      width: '100%',
    },
  },
  toolHeight: {
    minHeight: 40,
  },
  sideColor: {
    background: 'rgb(79,134,197)',
    color: '#fff',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    display: 'none',

    [theme.breakpoints.down(
        CommonTheme.breakpoints.values.lg + CommonTheme.menu.width,
    )]: {
      display: 'inline',
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  userInfo: {
    margin: '0 0 0 auto',
    textAlign: 'center',
  },
  daikoUser: {
    color: '#F28156',
  },
  titleSystem: {
    marginLeft: '10px',
    fontWeight: 'bold',
  },
});

/** 重要事項説明画面パス */
const JUYO_ZIKO_SETUMEI_PATH = '/importantSubjects';
/** 重要連絡事項一覧画面パス */
const JUYO_ZIKO_LIST_PATH = '/Other/ContactZiko/JuyoZiko/List';
/** 重要連絡事項確認画面パス */
const JUYO_ZIKO_CONFIRM_PATH = '/Other/ContactZiko/JuyoZiko/Confirm';

class Header extends React.Component {
  state = {
    anchorEl: null,
    tabValue: 0,
    tab: 'home',
    openSide: false,
  };
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  componentDidMount = () => {
  }

  componentWillUnmount = () => {
  }

  handleChange = (event) => {
    this.setState({auth: event.target.checked});
  };

  handleMenu = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleDrawerOpen = (event) => {
    this.setState({openSide: true});
  };

  handleDrawerClose = () => {
    this.setState({openSide: false});
  };

  doChangePassword = () => {
    // パスワード変更画面への遷移処理
    const func = () => {
      this.handleClose();
      this.props.history.push('/changePassword');
    };

    this.checkDirtyAndExecute(func);
  };

  doUserUpdate = () => {
    const {history} = this.props;

    // 既に自身の情報編集画面を表示中の場合はユーザメニューを閉じて終了
    if (history.location.pathname == SELF_EDIT_PATH) {
      this.handleClose();
      return;
    }

    // ユーザ管理（詳細）画面の遷移処理
    const func = () => {
      this.handleClose();
      history.push(SELF_EDIT_PATH);
    };

    this.checkDirtyAndExecute(func);
  };

  checkDirtyAndExecute = (func) => {
    const {
      dirty,
      doShowMessage,
      isEditPage,
    } = this.props;

    if (isEditPage && dirty) {
      // 編集画面で値が変更されている場合、確認メッセージを表示
      doShowMessage({
        message: 'CW0013',
        action: () => {
          func();
        },
      });

      // ユーザメニューを閉じる
      this.handleClose();
    } else {
      // 編集画面ではないか値が変更されていない場合、処理をそのまま実行
      func();
    }
  }

  doHelp = () => {
    this.handleClose();
    const manualUrl = this.getManualUrl(this.props.userInfo);
    window.open(manualUrl, '_blank');
  };

  getManualUrl = (userInfo) => {
    const url = isChudenNwContract(userInfo) ?
      conf.MANUAL_CN_PDF_URL : conf.MANUAL_PDF_URL;
    return url;
  }

  doLogout = () => {
    const {
      dirty,
      doLogout,
      doShowMessage,
      isEditPage,
    } = this.props;

    // 編集画面で値が変更されているかどうかでメッセージを差し換え
    const messageId = (isEditPage && dirty) ? 'CW0208' : 'CC0005';

    doShowMessage({
      message: {
        id: messageId,
        values: ['ログアウト'],
      },
      action: () => {
        this.handleClose();
        doLogout();
      },
    });
  };

  doClear = () => {
    this.props.doLogout();
  };

  /**
   * ログインユーザ名の取得
   * @param {object} userInfo ユーザ情報
   * @return {string} ログインユーザ名
   */
  getUserName = (userInfo) => {
    let nameItems;
    if (userInfo.DaikoUserId != null) {
      // 代行入力状態の場合
      nameItems = [userInfo.Name, '（', userInfo.DaikoUser.Name, '）'];
    } else {
      // 通常のログインの場合
      nameItems = [userInfo.Name, '（ID:', userInfo.UserId__c, '）'];
    }

    return nameItems.join('');
  }

  render() {
    const {
      classes,
      disableSideMenu,
      generalPurpose,
      isLoggedIn,
      userInfo,
      history,
      isTermsAccepted,
      hasJuyoZiko,
    } = this.props;
    const anchorEl = this.state.anchorEl;
    const openIcon = Boolean(anchorEl);
    const title = this.props.title;

    const openSide = this.state.openSide;

    // ログインユーザ名
    let userName = '';

    // 代行入力状態CSSスタイル
    let daikoStyle = null;

    // 中電NW設計ユーザ判定(ユーザー情報変更/ヘルプ表示)
    let isNWDesign = false;

    // ユーザ情報が設定された場合、各種情報を取得
    if (userInfo) {
      userName = this.getUserName(userInfo);

      daikoStyle = (userInfo.DaikoUserId != null) ?
        classes.daikoUser : null;

      // 中電NW設計ユーザの場合はユーザー情報変更/ヘルプ項目を非表示
      isNWDesign = !isNWDesignZgsya(userInfo, generalPurpose);
    }

    // 約款未承諾、重要事項未確認の場合、
    // ヘッダーからユーザ管理へ遷移できないようにする
    let disabledUserMng = false;
    if (userInfo && userInfo.DaikoUserId == null) {
      if (!isTermsAccepted &&
        history.location.pathname == JUYO_ZIKO_SETUMEI_PATH) {
        disabledUserMng = true;
      }
      if (hasJuyoZiko &&
        (history.location.pathname == JUYO_ZIKO_LIST_PATH ||
          history.location.pathname == JUYO_ZIKO_CONFIRM_PATH)) {
        disabledUserMng = true;
      }
    }

    return (
      <ThemeProvider theme={CommonTheme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="absolute" className={clsx(classes.appBarTop, classes.appBarCommon)} color="secondary">
            <Typography variant="caption" color="inherit" className={classes.topgrow} >
              <span className={classes.titleSystem}>
                共架Webシステム「Yupol(ユーポル)」
              </span>
            </Typography>
          </AppBar>
          <AppBar position="absolute" className={clsx(classes.appBar, openSide && classes.appBarShift, classes.appBarCommon)}>
            <Toolbar className={classes.toolbar}>

              <>
                {!disableSideMenu &&
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    className={clsx(
                        classes.menuButton,
                        openSide && classes.menuButtonHidden)}
                  >
                    <MenuIcon />
                  </IconButton>
                }
              </>

              <Typography variant="h6" color="inherit" className={classes.grow} >
                {title}
              </Typography>

              {isLoggedIn &&
                <Hidden xsDown>
                  <Typography
                    color="inherit"
                    className={clsx(classes.userInfo, daikoStyle)}
                  >
                    {userName}
                  </Typography>
                </Hidden>
              }

              {isLoggedIn &&
                <div>
                  <IconButton
                    aria-owns={openIcon ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={openIcon}
                    onClose={this.handleClose}
                  >

                    <Hidden smUp>
                      <ListSubheader className={daikoStyle}>
                        {userName}
                      </ListSubheader>
                    </Hidden>

                    <ListItem button onClick={this.doChangePassword}>
                      <ListItemIcon>
                        <ContactMailIcon />
                      </ListItemIcon>
                      <ListItemText primary="パスワード変更" />
                    </ListItem>
                    {isNWDesign &&
                      <>
                        <ListItem button onClick={this.doUserUpdate}
                          disabled={disabledUserMng}>
                          <ListItemIcon>
                            <AccountCircle />
                          </ListItemIcon>
                          <ListItemText primary="ユーザー情報変更" />
                        </ListItem>
                        <ListItem button onClick={this.doHelp}>
                          <ListItemIcon>
                            <HelpIcon />
                          </ListItemIcon>
                          <ListItemText primary="ヘルプ" />
                        </ListItem>
                      </>
                    }
                    <ListItem button onClick={this.doLogout}>
                      <ListItemIcon>
                        <ExitToApp />
                      </ListItemIcon>
                      <ListItemText primary="ログアウト" />
                    </ListItem>
                  </Menu>
                </div>
              }
            </Toolbar>
          </AppBar>

          <Drawer
            variant="temporary"
            classes={{
              paper: clsx(
                  classes.drawerPaper,
                  !openSide && classes.drawerPaperClose,
                  classes.sideColor,
              ),
            }}
            open={openSide}
          >
            <div className={clsx(classes.toolbarIcon, classes.sideColor)}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon style={{color: 'white'}} />
              </IconButton>
            </div>
            <SideMenu {...this.props} />
          </Drawer>
        </div>
      </ThemeProvider>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  doLogout: PropTypes.func.isRequired,
  doOpenModal: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  generalPurpose: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  disableSideMenu: PropTypes.bool,
  title: PropTypes.string,
  userInfo: PropTypes.object,
  isEditPage: PropTypes.bool,
  isTermsAccepted: PropTypes.bool,
  hasJuyoZiko: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentTheme: state.style.theme,
  isLoggedIn: state.auth.isLoggedIn,
  generalPurpose: state.common.generalPurposeMap,
  isTermsAccepted: state.common.isTermsAccepted,
  hasJuyoZiko: state.common.hasJuyoZiko,
});

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  doOpenModal: commonOperations.doOpenModal,
  doShowMessage: commonOperations.doShowMessage,
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(Header)),
);

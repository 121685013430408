import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import {API_CODE, getResponseResult,
  getCodeFromGeneralPurposeMap, getToDate} from '../../../common/common.js';
import MainContainer from '../../organisms/MainContainer.js';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import NegativeButton from '../../atoms/Buttons/NegativeButton.js';
// import Conf from '../../../config/config.js'; TODO リンク先が決まったら使用する

import {commonOperations} from '../../../reducks/common';

import {reduxForm, getFormValues} from 'redux-form';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  root2: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
  },
  grow: {
    flexGrow: 1,
  },
  card: {
    textAlign: 'center',
    margin: '10px',
  },
  typography: {
    margin: '10px 0 0 5px',
    fontSize: '1.0em',
  },
  typoLink: {
    margin: '10px 0 0 18px',
    fontSize: '1.0em',
  },
  typographySec: {
    textAlign: 'left',
    color: '#d50000',
    border: 'solid 2px #d50000',
    margin: '20px 10px 0 10px',
    padding: '0 0 0 5px',
    borderRadius: '0.2em',
    // fontSize: "1.5em",
  },
  typographyText: {
    textAlign: 'left',
    margin: '5px 10px 0 16px',
    // fontSize: '1.0em'
  },
  conf: {
    paddingRight: '100px',
  },
  conf2: {
    // paddingRight: "18px",
    paddingRight: '100px',
  },
  conf3: {
    // paddingRight: "127px",
    paddingRight: '100px',
  },
});

const ExPanel = withStyles((theme) => ({
  root: {
    'fontSize': '1.0em',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
}))(Accordion);

const ExPanelSummary = withStyles((theme) => ({
  root: {
    'padding': '5px 15px 5px 10px !important',
    'height': '18px',
    'backgroundColor': '#c6e2ff',
    'minHeight': '45px',
    '&$expanded': {
      minHeight: '45px',
    },
  },
  expanded: {
    padding: '12px 0 12px 0px',
  },
  expandIcon: {
    padding: '0',
  },
}))(AccordionSummary);

const ExPanelDetail = withStyles((theme) => ({
  root: {
    // padding: "0 10px 10px 10px",
    display: 'block',
  },
}))(AccordionDetails);

/**
 * 重要事項説明仕様書
 * 画面ID:1521
*/
class ImportantSubjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkFlgs: [false, false, false, false, false],
      BtnDisabled: true,
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // ログインユーザーが管理者の場合、承諾ボタンを押下可とする
      if (this.props.userInfo.UserLevel__c ==
      getCodeFromGeneralPurposeMap(this.props.generalPurposeMap, 'UserLevel2', 1)) {
        this.setState({BtnDisabled: false});
      }

      // ログインユーザーが約款承諾済みの場合、承諾ボタンを押下不可とする
      // isTermsAcceptedの初期値はログイン時に設定。
      // 代行者の場合は代行開始時に設定している。
      if (this.props.isTermsAccepted) {
        this.setState({BtnDisabled: true});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

   /**
   * 必須の既読チェック
   * @param {number} val 既読チェック用添え字
   */
   setValue = (val) => {
     try {
       let {checkFlgs} = this.state;
       checkFlgs[val] = true;
       this.setState({checkFlgs: checkFlgs});
     } catch (error) {
       console.dir(error);
       this.sysErr();
     }
   }

   /**
   * 承諾ボタン押下時
   */
   doYakkanConsent = async () => {
     try {
       this.props.doShowMessage({
         message: {
           id: 'CC0005',
           values: ['承諾'],
         },
         action: () => {
           this.updateAccount();
         },
       });
     } catch (error) {
       console.dir(error);
       this.sysErr();
     }
   }

   /**
   * 承諾処理実行
   */
    updateAccount = async () => {
      try {
        // 更新情報を設定
        const conditions = {
          'KyogaZgsyaCode__c': this.props.userInfo.KyogaZgsyaCode__c,
          'RecordType.DeveloperName': 'KyogaZgsya',
          'DeleteFlag__c': false,
        };
        const params = {
          'StipulationConsentDate__c': getToDate(),
          'StipulationConsentTanto__c': this.props.userInfo.Id,
        };
        const stage = [
          'STIPULATION_UPDATE',
        ];

        // 約款承諾更新
        const response = await this.props.doUpdateAccount(
            conditions, params, stage);
        const resResult = getResponseResult(response, ['約款', '承諾']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        } else {
          this.setState({BtnDisabled: true});
          this.props.setIsTermsAccepted(true);
          if (this.props.hasJuyoZiko) {
            this.props.history.push('/Other/ContactZiko/JuyoZiko/List', null, 'CLEAR');
          }
        }
      } catch (error) {
        console.dir(error);
        this.sysErr();
      }
    }

    /**
   * 戻るボタン押下時
   */
    doBackButton = async () => {
      try {
        // 約款承諾済みの場合、メニュー画面へ戻る
        // 重要連絡事項未確認があれば、重要連絡事項一覧へ遷移する
        if (this.props.isTermsAccepted) {
          this.doMoveJuyoZiko();
          return;
        }

        const {isKirikaeMae} = this.props;
        if (isKirikaeMae) {
          this.doMoveJuyoZiko();
        } else {
          // 切替日時以降の場合、ログアウトしてログイン画面へ戻る
          // 代行は切替日時以降でもメニュー画面へ戻る
          if (this.props.userInfo.DaikoUserId != null) {
            this.props.history.push('/menu');
            return;
          }
          this.props.history.push('/login');
        }
      } catch (error) {
        console.dir(error);
        this.sysErr();
      }
    }

    /**
   * システムエラー
   */
   sysErr = () => {
     this.props.doShowMessage({
       message: 'CS0001',
       action: () => {
         this.props.history.push('/login');
       },
     });
   }

   /**
    * 未確認の重要事項有無をチェックし、遷移先をかえる
    * ・代行者はメニューへ遷移
    * ・代行者以外かつ未確認の重要事項確認が存在する場合、重要事項確認画面へ遷移
    * ・代行者以外かつ未確認がない場合、メニューへ遷移
    */
   doMoveJuyoZiko() {
     if (this.props.userInfo.DaikoUserId != null) {
       this.props.history.push('/menu');
     } else {
       if (this.props.hasJuyoZiko) {
         this.props.history.push('/Other/ContactZiko/JuyoZiko/List', null, 'CLEAR');
       } else {
         this.props.history.push('/menu');
       }
     }
   }

   render() {
     const {
       classes,
       userInfo,
       isTermsAccepted,
     } = this.props;

     // 管理者かつ未承諾の場合、承諾ボタン操作可。管理者が承諾するまで戻るボタンでログインへ遷移する（切替前はメニュー画面へ遷移する）。
     // 一般かつ未承諾の場合、承諾ボタン操作不可。管理者が承諾するまで戻るボタンでログインへ遷移する（切替前はメニュー画面へ遷移する）。
     // 代行者は未承諾でも画面遷移可能。承諾ボタンは代行ユーザのレベルに合わせる。
     let _canMove = true; // true:画面遷移可,false:画面遷移不可
     if (userInfo && userInfo.DaikoUserId == null &&
        !isTermsAccepted) {
       _canMove = false;
     }

     // 必須の既読チェック
     const {checkFlgs} = this.state;
     let nextable = false;
     for (const checkFlg of checkFlgs) {
       nextable = checkFlg;
       if (!checkFlg) break;
     }

     let _btnDisabled = this.state.BtnDisabled ?
      this.state.BtnDisabled : !nextable;

     const footerBtn = (
       <Grid container
         justifyContent="center"
         alignItems="flex-start"
         spacing={1}
       >
         {/* 承諾 */}
         <Grid key="btn1" item>
           <PositiveButton
             id="btnConsent"
             onClick={() => {
               this.doYakkanConsent();
             }}
             variant="contained"
             size="large"
             disabled={_btnDisabled}
           >
             <span>承諾</span>
           </PositiveButton>
         </Grid>
         <Grid key="btn2" item>
           <NegativeButton
             id="btnBack"
             onClick={() => {
               this.doBackButton();
             }}
             variant="contained"
             startIcon={<KeyboardReturnIcon />}
             size="large"
           >
             <span>戻る</span>
           </NegativeButton>
         </Grid>
       </Grid>
     );

     return (
       <form noValidate autoComplete="off" className={classes.root}>
         <MainContainer
           props={this.props}
           footerBtn={footerBtn}
           disableSideMenu={!_canMove}>
           <Typography variant="h6" className={classes.typographySec}><span>確認いただきたい事項（２０２３年４月１日版）</span></Typography>
           <Typography className={classes.typographyText}>
             <font color="#d50000">
              2023年4月1日をもちまして、電柱共架業務については、中国電力ネットワーク株式会社から株式会社電力サポート中国<br/>
              へ事業移管されました。<br/>
              次の事項は、共架契約およびに共架Webシステム「Yupol(ユーポル)」に関する重要事項のため必ずご確認ください。<br/>
              各事項のご確認が終わりましたら、承諾ボタンをクリックしてください。<br/>
              承諾ボタンをクリックできるのは、ユーザレベルが「管理者」の方になります。ご確認いただいた「管理者」の方は、<br/>
              所属する他の「管理者」「一般」ユーザにも周知いただきますようお願いします。2023年4月1日以降の初めての<br/>
              ログイン時は、この画面の承諾ボタンを押下するまで、新たにご利用いただくことができません。
             </font>
           </Typography>
           <Grid container >
             <Grid item xs={12} md={12}>
               <Card className={classes.root2}>
                 <ExPanel >
                   <ExPanelSummary expandIcon={<ExpandMoreIcon />}
                     onClick={()=>this.setValue(0)}>
                     <Typography><font color="#d50000">【必須】</font>共架契約約款および共架工事技術基準等</Typography>
                   </ExPanelSummary>
                   <ExPanelDetail >
                     <Typography className={classes.typography}>
                    ・2023年4月1日以降、電力サポート中国の
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.KYOUGA_YAKKAN_URL}>*/}
                       共架契約約款{/* </a> */}
                      に同意のうえ、共架申込をお願いします。<br/>
                    ・電柱共架の利用手続きにあたっては、
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.CONSTRUCTION_URL}>*/}
                       各種工事技術基準{/* </a> */}
                       を遵守のうえ、お申込み・工事実施をお願いします。<br/>
                    ・工事実施にあたっては、
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.POINTS_URL}>*/}
                       「共架工事における安全上の留意事項」{/* </a> */}
                      を遵守してください。<br/>
                    ・特に「光ﾌｧｲﾊﾞを用いた引込線等に関する共架申込手続きの簡素化及び効率化」の適用を希望する共架者については、こちらの
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.INFO_HIKARI_FIBER_URL}>*/}
                       基本的事項{/* </a> */}
                      を遵守のうえ、工事実施をお願いします。<br/>
                     </Typography>
                   </ExPanelDetail>
                 </ExPanel>
                 <ExPanel >
                   <ExPanelSummary expandIcon={<ExpandMoreIcon />}
                     onClick={()=>this.setValue(1)}>
                     <Typography><font color="#d50000">【必須】</font>共架者基本情報の表示・修正</Typography>
                   </ExPanelSummary>
                   <ExPanelDetail >
                     <Typography className={classes.typography}>
                    ・「共架者基本情報」画面の各項目情報（共架事業者、共架事業所、請求先、保守工事会社）の最新化（更新）をお願いします。
                    更新にあたっては、以下の事項に留意してください。<br/>
                    ・Yupolにおいては、共架事業所情報が共架契約のかなめとなる情報となりますので、
                    こちらに契約者としての情報を反映してください。
                    （請求先、保守工事会社、ユーザ等の各情報は、全て共架事業所情報と紐付きます。）<br/>
                    ・共架事業者情報については、特段の事由がない限り、共架事業所情報と同じ情報を入力ください。
                    （共架事業者情報は、共架受付センターが必要により、
                    Yupol上の複数の共架事業所情報をグルーピングする場合に用いる項目です。）<br/>
                    ・なお、請求先、保守工事会社の追加が必要な場合は、
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.TOI_URL}>*/}
                       共架受付センター{/* </a> */}
                    へお問い合わせをお願いします。<br/>
                     </Typography>
                   </ExPanelDetail>
                 </ExPanel>
                 <ExPanel >
                   <ExPanelSummary expandIcon={<ExpandMoreIcon />}
                     onClick={()=>this.setValue(2)}>
                     <Typography><font color="#d50000">【必須】</font>ユーザ作成・管理</Typography>
                   </ExPanelSummary>
                   <ExPanelDetail>
                     <Typography className={classes.typography}>
                    ・管理者ユーザは、「ユーザ管理」画面から新たなユーザを作成することができます。<br/>
                    ・新たなユーザを作成する場合、共架事業所または保守工事会社のどちらに所属するかを選択します。
                    共架事業所に所属するユーザの場合、「保守工事会社名」を空白のままとし、
                    保守工事会社に所属するユーザの場合、プルダウンで表示される保守工事会社を選択します。<br/>
                    ・共架事業所の場合のユーザレベル（種別）は、管理者または一般ユーザのどちらも作成できます。
                    保守工事会社の場合は、一意的に一般ユーザとなります。<br/>
                    ・管理者ユーザは、前述のとおり、必要数のユーザを作成することができます。
                    作成したユーザの管理については、管理者ユーザが適正に行ってください。<br/>
                    ・管理者ユーザ（共架者）は共架に係る申込手続等について、作成したユーザ（代理者）に委任することができます。
                    この場合、共架者と代理者との間で発生したトラブルおよび共架者または第三者に生じた損害等については、
                    すべて共架者およびその代理者間で解決するものとし、電力サポート中国は一切の責任を負いません。
                       <br/>
                     </Typography>
                   </ExPanelDetail>
                 </ExPanel>
                 <ExPanel >
                   <ExPanelSummary expandIcon={<ExpandMoreIcon />}
                     onClick={()=>this.setValue(3)}>
                     <Typography><font color="#d50000">【必須】</font>契約電柱の表示・確認</Typography>
                   </ExPanelSummary>
                   <ExPanelDetail>
                     <Typography className={classes.typography}>
                    ・「契約電柱検索」画面から現時点の契約電柱の一覧を表示することができます。
                    Yupol運開（2022年10月）以降、未確認の共架者におかれましては
                    契約電柱に相違がないことを確認してください。<br/>
                    ・契約電柱をＣＳＶダウンロードする場合、10,000件までが上限となります。
                    10,000件以上の契約電柱がある場合は事業所等できりわけて、ダウンロードいたただくか、もしくはお手数ですが、
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.TOI_URL}>*/}
                       共架受付センター{/* </a> */}
                    まで、データ抽出を別途ご相談くださいますようお願いいたします。<br/>
                    ・契約電柱データについて、相違すると思われるところまたは不明点等がありましたら、
                    該当部分の具体的な情報（電柱番号等）を確認のうえ、
                       {/* <a target="_blank" rel="noopener noreferrer" href={Conf.TOI_URL}>*/}
                       共架受付センター{/* </a> */}
                    へお問い合わせをお願いします。<br/>
                     </Typography>
                   </ExPanelDetail>
                 </ExPanel>
                 <ExPanel >
                   <ExPanelSummary expandIcon={<ExpandMoreIcon />}
                     onClick={()=>this.setValue(4)}>
                     <Typography><font color="#d50000">【必須】</font>共架料およびその他費用の請求・支払</Typography>
                   </ExPanelSummary>
                   <ExPanelDetail>
                     <Typography className={classes.typography}>
                    ・2023年4月以降の請求は、Yupolへ登録の請求先「Ｂ１」を宛名・宛先として送付します。
                    送付先に相違がないことを今一度確認ください。<br/>
                    ・共架料は、年一回の後払いとします。<br/>
                    ・可否判定費用、代行入力費用は、原則年一回の後払いとします。
                    ただし、当年度3月1日以降に発生した費用は翌年度に請求します。また、希望する共架者には月単位の一括後払いも<br/>
                    可能です。月単位の支払いあるいは、共架料と可否判定費用の請求先を別の送付先にしたい等のご要望があれば、<br/>
                    共架受付センターへお問い合わせお願いします。<br/>
                    ・電柱改造工事費は、中国電力ネットワークの設計完了後速やかに請求し、原則月単位の一括後払いとします。<br/>
                    ・上記費用の請求は、三井住友カード株式会社へ債権を譲渡して行いますので、
                    お支払いは指定の方法、指定の期日までにお願いします。<br/>
                     </Typography>
                   </ExPanelDetail>
                 </ExPanel>
               </Card>
             </Grid>
           </Grid>
         </MainContainer>
       </form>
     );
   }
}

ImportantSubjects.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  isTermsAccepted: PropTypes.bool,
  hasJuyoZiko: PropTypes.bool,
  isKirikaeMae: PropTypes.bool,
  doShowMessage: PropTypes.func,
  doGetAllGeneralPurposeMap: PropTypes.func.isRequired,
  doUpdateAccount: PropTypes.func.isRequired,
  setIsTermsAccepted: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  generalPurposeMap: state.common.generalPurposeMap,
  isTermsAccepted: state.common.isTermsAccepted,
  hasJuyoZiko: state.common.hasJuyoZiko,
  isKirikaeMae: state.common.isKirikaeMae,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetAllGeneralPurposeMap: commonOperations.doGetAllGeneralPurposeMap,
  doUpdateAccount: commonOperations.doUpdateAccount,
  setIsTermsAccepted: commonOperations.doSetIsTermsAccepted,
};

const FORM_NAME = 'ImportantSubjects';

ImportantSubjects = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(ImportantSubjects)(state),
  };
})(ImportantSubjects));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ImportantSubjects),
);

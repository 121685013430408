import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import {withRouter} from 'react-router-dom';
import {commonOperations} from './../../../reducks/common';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 10px 16px 10px',
  },
  button: {
    left: '50%',
    transform: 'translateX(-50%)',
    margin: '20px 0 20px 0',
  },
  modal: {
    display: 'absolute',
    top: '10%',
    left: '5%',
    right: '5%',
    bottom: '5%',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'none',
  },
  paper: {
    backgroundColor: '#fafafa',
    border: '5px solid #4caf50',
    height: '85%',
    overflow: 'auto',
  },
});


class ModalPopUp extends React.Component {
  closeModal = () => {
    console.log('close');
    this.props.doCloseModal();
  }

  render() {
    const {classes, modalInfo, content} = this.props;

    return (
      <Modal
        open={modalInfo.open}
        onClose={this.closeModal}
        className={classes.modal}>
        <div className={classes.paper}>
          {content}
          { modalInfo.btnExist &&
              <Button
                id="close"
                variant="contained"
                color="primary"
                className={classes.button}
                size="large"
                onClick={this.closeModal}
              >
                <span>閉じる</span>
              </Button>
          }
        </div>
      </Modal>
    );
  }
}

ModalPopUp.propTypes = {
  classes: PropTypes.object,
  modalInfo: PropTypes.object,
  content: PropTypes.object,
  doCloseModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  modalInfo: state.common.modalInfo,
});

const mapDispatchToProps = {
  doCloseModal: commonOperations.doCloseModal,
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(ModalPopUp)),
);

// export default withStyles(styles)(ModalPopUp);

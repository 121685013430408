import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  warningField: {
    'width': '100%',
    'height': '100px', // 5行分
    'overflow-y': 'scroll',
    'border-bottom': 'solid 1px',
    // backgroundColor: palette.error.light,
  },
  warningForeColor: {
    'color': 'orange',
    'display': 'block',
  },
  errorForeColor: {
    'color': 'red',
    'display': 'block',
  },
  normalForeColor: {
    'color': 'black',
    'display': 'block',
  },
}));

/**
 * 警告メッセージを表示する
 * @param  {array} {messages}
 * @return {object}
 */
function WarningMessageArea({messages}) {
  const classes = useStyles();

  let warnlist = null;
  const intl = useIntl();
  if (messages) {
    warnlist = [];
    for (const message of messages) {
      console.debug(message.id);
      let msg = '';
      let messageId = message.id.indexOf('message') == -1 ?
        'message.' + message.id : message.id;
      if (Object.hasOwnProperty.call(message, 'values')) {
        msg = intl.formatMessage({id: messageId}, message.values);
      } else {
        msg = intl.formatMessage({id: messageId});
      }
      console.debug(msg);
      if (message.id.indexOf('E') >= 0) {
        warnlist.push(<span className={classes.errorForeColor}>{msg}</span>);
      } else if (message.id.indexOf('W') >= 0) {
        warnlist.push(<span className={classes.warningForeColor}>{msg}</span>);
      } else {
        warnlist.push(<span className={classes.normalForeColor}>{msg}</span>);
      }
    }
  }

  return (
    <React.Fragment>
      {warnlist && warnlist.length > 0 &&
      <Grid key="warningMessageGrid">
        <Typography key="warningMessageArea" variant="caption" className={classes.warningField} component="div">
          {warnlist}
        </Typography>
      </Grid>
      }
    </React.Fragment>
  );
};


/**
 * 確認画面用警告メッセージ
 * @param {string} procName 処理名
 * @param {string} fixAction 修正方法
 * @return {object}
 */
export function ConfirmWarningMessageArea({procName, fixAction='前画面に戻って'}) {
  const classes = useStyles();

  let message = {
    id: 'CE0158',
    values: [procName, fixAction],
  };

  const intl = useIntl();
  let msg = intl.formatMessage({id: 'message.' + message.id}, message.values);

  return (
    <React.Fragment>
      <Grid key="confirmWarningMessageGrid">
        <Typography key="confirmWarningMessageArea" variant="h6" component="div" className={classes.errorForeColor}>
          {msg}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

WarningMessageArea.propTypes = {
  messages: PropTypes.array,
};
ConfirmWarningMessageArea.propTypes = {
  procName: PropTypes.string,
  fixAction: PropTypes.string,
};

export default WarningMessageArea;

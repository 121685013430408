import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {downloadAttachedFile, changeDateFormat, getErrorCode,
  API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {otherOperations} from '../../../../../../reducks/other';
import MainContainer from '../../../../../organisms/MainContainer.js';
import {renderTextField} from '../../../../../atoms/CustomPart.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {contactZikoListFields} from '../../../../../../common/SFFields';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

const contactZikoItems = [
  {key: 'Name', name: '件名：', value: '', type: ''},
  {key: 'NotificDate__c', name: '連絡年月日：', value: '', type: 'date'},
];

const updateParams = {
  Id: null,
  LastModifiedDate: null,
};

/**
 * 重要・個別連絡事項確認
 * 画面ID:1622
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      files: [],
    };
    this.title = '';
    if (props.contactZikoType == '1') {
      this.title = '個別連絡事項確認';
    } else {
      this.title = '重要連絡事項確認';
    }
    for (const row of contactZikoItems) {
      row['value'] = '';
    }
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // パラメータ設定
      const appConditions = {
        Id: this.props.kyogaContactZikoId,
        DeleteFlag__c: false,
      };

      // 検索時のソートキー
      const appSortParams = {
        CreatedDate: -1,
      };

      // 共架事業者連絡事項と資料を取得
      const response = await this.props.doGetContactZiko(
          appConditions, contactZikoListFields, appSortParams);
      const errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: {
            id: 'CE0052',
            values: ['連絡事項', '取得'],
          },
        });
        return;
      }

      // 連絡事項設定
      if (this.props.kyogaContactZiko) {
        for (const row of contactZikoItems) {
          if (Object.hasOwnProperty.call(
              this.props.kyogaContactZiko, row.key)) {
            row.value = row.type != 'date' ? this.props.kyogaContactZiko[row.key] :
                      changeDateFormat(this.props.kyogaContactZiko[row.key], 'YYYY年MM月DD日');
          }
        };
      }
      // 資料表示データ設定
      const _files = [];
      for (const row of this.props.attachedFiles) {
        const anchorAttrs = {};
        anchorAttrs['onClick'] = (event) => {
          this.doDownloadAttachedFile(
              row.ContentDocument.LatestPublishedVersion.VersionData,
              row.ContentDocument.LatestPublishedVersion.FileType,
              row.ContentDocument.LatestPublishedVersion.PathOnClient);
        };
        anchorAttrs['href']='javascript:void(0)';
        let data = {anchor: null, value: ''};
        data.value = row.ContentDocument.LatestPublishedVersion.PathOnClient;
        data.anchor = anchorAttrs;
        _files.push(data);
      }

      this.setState({
        isDisabled: this.props.kyogaContactZiko.KyogaCheckDate__c != null,
        files: _files,
      });
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearContactZiko();
  }

  /**
  * システムエラー
  */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 添付ファイルダウンロード
   * @param {*} url
   * @param {*} fileType
   * @param {*} fileName
   */
  doDownloadAttachedFile = async (url, fileType, fileName) => {
    const {userInfo} = this.props;
    try {
      downloadAttachedFile(
          userInfo, url,
          fileType, fileName);
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 確認ボタン押下時
   */
  doConfirm = async () => {
    try {
      if (Object.hasOwnProperty.call(this.props.kyogaContactZiko, 'KyogaCheckDate__c') &&
          (this.props.kyogaContactZiko.KyogaCheckDate__c == null ||
          this.props.kyogaContactZiko.KyogaCheckDate__c == '')) {
        // 確認日が未設定だったら更新
        const updateData = {};
        Object.assign(updateData, updateParams);
        updateData['Id'] = this.props.kyogaContactZiko.Id;
        updateData['LastModifiedDate'] = this.props.kyogaContactZiko.LastModifiedDate;
        const response = await this.props.doConfirmKyogaContactZiko(updateData);
        const resResult = getResponseResult(response, ['連絡事項', '確認']);
        if (resResult.errorCode != API_CODE.SUCCESS) {
          this.props.doShowMessage({
            message: resResult.messages,
          });
          return;
        }
      }
      // 前画面に遷移
      if (this.props.contactZikoType == '1') {
        this.props.history.push(
            {pathname: '/Other/ContactZiko/ContactZiko/List'},
            null, this.props.history.option.CLEAR);
      }
      if (this.props.contactZikoType == '2') {
        this.props.history.push(
            {pathname: '/Other/ContactZiko/JuyoZiko/List'},
            null, this.props.history.option.CLEAR);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  render() {
    const {classes, handleSubmit, userInfo, hasJuyoZiko} = this.props;
    const _hasJuyoZiko = userInfo.DaikoUserId == null && hasJuyoZiko;

    const footerBtn = (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1622Confirm" item>
          <PositiveButton
            id="btn1622Confirm"
            onClick={handleSubmit(this.doConfirm)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled}
          >
            <span>確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1622Back" item>
          <BackButton props={this.props}/>
        </Grid>
      </Grid>
    );
    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
          disableSideMenu={_hasJuyoZiko}>
          <Grid container spacing={1}>
            <Grid item container direction="column" xs={12}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                {this.title}
              </Typography>
            </Grid>
          </Grid>
          <div style={{paddingLeft: 20}}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item xs={4} container spacing={0}>
                {contactZikoItems.map((data) =>
                  <React.Fragment key={data.name}>
                    <Grid item xs={3}>
                      <Typography gutterBottom>{data.name}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography gutterBottom>{data.value}</Typography>
                    </Grid>
                  </React.Fragment>,
                )}
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={1}>
            <Grid key="key1622NotificationContents" item xs={10}>
              <Field
                id="id1622NotificationContents"
                name="NotificationContents__c"
                component={renderTextField}
                className={classes.fields}
                label=''
                variant="outlined"
                type="text"
                multiline
                rows={15}
                fullWidth
                required={false}
                InputProps={{
                  readOnly: true,
                }}
                validate={[
                ]}
              />
            </Grid>
          </Grid>
          <div style={{paddingLeft: 20}}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item xs={4} container spacing={0}>
                <Grid item xs={2}>
                  <Typography gutterBottom>{'資料：'}</Typography>
                </Grid>
                <Grid item xs={10}>
                  {this.state.files.map((data) => (
                    <>
                      <a key={data.value} {...data.anchor}>{data.value}</a>
                      <br></br>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  contactZikoType: PropTypes.string,
  kyogaContactZikoId: PropTypes.string,
  kyogaContactZiko: PropTypes.object,
  attachedFiles: PropTypes.array,
  hasJuyoZiko: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetContactZiko: PropTypes.func,
  doClearContactZiko: PropTypes.func,
  doConfirmKyogaContactZiko: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {
    NotificationContents__c: '',
  };
  const _kyogaContactZiko = state.other.contactZiko &&
        Object.hasOwnProperty.call(
            state.other.contactZiko, 'kyogaContactZiko') ?
            state.other.contactZiko.kyogaContactZiko : null;
  const _attachedFiles = state.other.contactZiko &&
            Object.hasOwnProperty.call(
                state.other.contactZiko, 'attachedFiles') ?
                state.other.contactZiko.attachedFiles : [];
  // 連絡事項設定
  if (_kyogaContactZiko) {
    initValues['NotificationContents__c'] = _kyogaContactZiko['NotificationContents__c'];
  }
  return {
    generalPurposeMap: state.common.generalPurposeMap,
    hasJuyoZiko: state.common.hasJuyoZiko,
    userInfo: state.auth.userInfo,
    contactZikoType: state.other.contactZikoType,
    kyogaContactZikoId: state.other.kyogaContactZikoId,
    kyogaContactZiko: _kyogaContactZiko,
    attachedFiles: _attachedFiles,
    initialValues: initValues,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetContactZiko: otherOperations.doGetContactZiko,
  doClearContactZiko: otherOperations.doClearContactZiko,
  doConfirmKyogaContactZiko: otherOperations.doConfirmKyogaContactZiko,
};

const FORM_NAME = 'ContactZikoConfirm';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import {combineReducers} from 'redux';
import types from './types';

// 契約電柱一覧
const contractDntList = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTRACTDNT_LIST:
      return action.contractDntList;
    default:
      return state;
  }
};

const contractDntCount = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTRACTDNT_COUNT:
      return action.contractDntCount;
    default:
      return state;
  }
};

// 契約電柱
const contractDnt = (state = null, action) => {
  switch (action.type) {
    case types.RETRIEVE_CONTRACTDNT:
      return action.contractDnt;
    default:
      return state;
  }
};

// 契約電柱 登録/更新結果
const contractDntResult = (state = null, action) => {
  switch (action.type) {
    case types.REGIST_CONTRACTDNT:
      return action.contractDntResult;
    case types.UPDATE_CONTRACTDNT:
      return action.contractDntResult;
    case types.SAVE_CONTRACTDNT:
      return action.contractDntResult;
    default:
      return state;
  }
};

// 契約電柱ID
const contractDntId = (state = null, action) => {
  switch (action.type) {
    case types.SET_CONTRACTDNT_ID:
      return action.contractDntId;
    default:
      return state;
  }
};

// 業務チェック
const checkAttachOrderDntResult = (state = null, action) => {
  switch (action.type) {
    case types.CHECK_CONTRACTDNT:
      return action.checkAttachOrderDntResult;
    default:
      return state;
  }
};

// 契約電柱マスタ一覧
const contractDntMasterList = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTRACTDNT_MASTER_LIST:
      return action.contractDntMasterList;
    default:
      return state;
  }
};

// 本申込の申込電柱情報
const formalContractDntList = (state = null, action) => {
  switch (action.type) {
    case types.GET_FORMAL_CONTRACTDNT:
      return action.formalContractDntList;
    default:
      return state;
  }
};

const contractDntORFilter = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTRACTDNTDNT_OR_FILTER:
      return action.contractDntOrFilter;
    default:
      return state;
  }
};

// 本申込/撤去の一束化設定申込電柱情報
const iskkContractDntList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ISKK_CONTRACTDNT:
      return action.iskkContractDntList;
    default:
      return state;
  }
};

// 契約電柱一覧（検索画面用）
const contractDntSearchList = (state = null, action) => {
  switch (action.type) {
    case types.GET_CONTRACTDNT_SEARCH_LIST:
      return action.contractDntSearchList;
    default:
      return state;
  }
};

// 写真登録情報
const photoRegistrationInfo = (state = null, action) => {
  switch (action.type) {
    case types.SET_PHOTO_REGISTRATION_INFO:
      return action.photoRegistrationInfo;
    default:
      return state;
  }
};

// 写真情報 情報
const photoInfo = (state = null, action) => {
  switch (action.type) {
    case types.GET_PHOTO_INFO:
      return action.photoInfo;
    default:
      return state;
  }
};

// 申込と申込電柱取得
const orderContractDntList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_CONTRACTDNT:
      return action.orderContractDntList;
    default:
      return state;
  }
};

// 事業所 抽出
const nwzgsyoSearchfilter = (state = null, action) =>{
  switch (action.type) {
    case types.GET_NWZGSYO_SEARCHFILTER:
      return action.nwzgsyoSearchfilter;
    default:
      return state;
  }
};

// 線路コード一覧
const senroCodeSearchList = (state = null, action) => {
  switch (action.type) {
    case types.SET_SENRO_CODE_SEARCH_LIST:
      return action.senroCodeSearchList;
    default:
      return state;
  }
};

const reducer = combineReducers({
  contractDntList,
  contractDnt,
  contractDntResult,
  contractDntId,
  contractDntCount,
  checkAttachOrderDntResult,
  contractDntMasterList,
  formalContractDntList,
  contractDntORFilter,
  iskkContractDntList,
  contractDntSearchList,
  photoRegistrationInfo,
  photoInfo,
  orderContractDntList,
  nwzgsyoSearchfilter,
  senroCodeSearchList,
});

export default reducer;

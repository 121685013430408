import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import XLSX from 'xlsx';
import Jimp from 'jimp';
import {commonOperations} from './../../../reducks/common';
import {downloadAttachedFile} from '../../../common/common.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 16px 16px 16px',
  },
  imageFile: {
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: '#a5a5a5',
  },
});

/** 写真長辺の最大長 */
const MAX_PHOTO_LENGTH = 1008;

/**
 * @typedef UploadFileInfo アップロードファイル情報
 * @property {string} ContentDocumentId ContentDocumentテーブルのId。S3登録データの場合はキー
 * @property {string} FileName 拡張子付きファイル名。同名ファイルチェックに使用する。
 * @property {string} FileBody dataスキーム付きBase64形式データ。SF未保存のファイルのみ。
 * @property {string} ContentType dataスキームSF未保存のファイルのみ。
 * @property {File} FileData アップロードするファイルの実体。SF未保存のファイルのみ。
 * @property {object} anchor ダウンロードリンク。SF保存済のファイルのみ。
 */

/**
 * ファイルアップロード
 */
class Upload extends React.Component {
  constructor(props) {
    super(props);

    /** @type UploadFileInfo[] */
    this.files = [];

    /** @type UploadFileInfo[] */
    this.deleteFiles = [];
    this.image = null;

    for (const row of this.props.initFile) {
      if (Object.hasOwnProperty.call(row, 'ContentType') ||
          Object.hasOwnProperty.call(row, 'anchor')) {
        // ファイル未保存、または保存済かつリンク変換済の場合
        this.files.push(row);
      } else {
        const anchorAttrs = {};
        anchorAttrs['onClick'] = (event) => {
          this.doDownloadAttachedFile(
              row.VersionData,
              row.FileType,
              row.PathOnClient);
        };
        anchorAttrs['href']='javascript:void(0)';
        let data = {anchor: null, FileName: '', ContentDocumentId: ''};
        data.FileName = row.PathOnClient;
        data.anchor = anchorAttrs;
        data.ContentDocumentId = row.ContentDocumentId;
        this.files.push(data);
      }
    }

    // 画像ファイルを拡大するフラグが消えてしまうのでstateに保存する
    this.state = {
      dialog: false,
    };
  }

  /**
   * 添付ファイルダウンロード
   * @param {*} url
   * @param {*} fileType
   * @param {*} fileName
   */
  doDownloadAttachedFile = async (url, fileType, fileName) => {
    const {userInfo} = this.props;
    try {
      downloadAttachedFile(
          userInfo, url,
          fileType, fileName);
    } catch (error) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['ファイル', 'ダウンロード'],
        },
      });
    }
  }

  /**
   * 拡張子取得
   * @param {object} file
   * @return {string} 拡張子
   */
  getFileType = (file) => {
    const pos = file.name.lastIndexOf('.');
    let filetype = '';
    if (pos !== -1) filetype = file.name.slice(pos);
    return filetype;
  }

  /**
   * ファイルがエクセル(XLS)かどうか判定する
   * @param {object} file
   * @return {boolean} true: エクセル(XLS), false:エクセル(XLS)以外
   */
  isExcel = (file) => {
    let result = false;
    const types = ['.xls']; // xlsx has not macro.
    const filetype = this.getFileType(file);
    types.forEach((type) => {
      if (filetype != '' && type.toLowerCase().match(filetype.toLowerCase())) {
        result = true;
      }
    });
    return result;
  }

  /**
   * Excelにマクロが含まれているかチェック
   * @param {object} wb
   * @return {boolean} true:マクロあり, false:マクロなし
   */
  workbookHasMacro = (wb) => {
    if (!!wb.vbaraw) return true;
    const sheets = wb.SheetNames.map((n) => wb.Sheets[n]);
    return sheets.some((ws) => !!ws && ws['!type']=='macro');
  }

  /**
   * エラーメッセージをまとめて表示
   * @param {string} msgId
   * @param {array<string>} fileNames
   * @param {string} option
   */
  setErr = (msgId, fileNames, option) => {
    // fileNamesは以下の形式にする
    // ['ファイル名1','ファイル名2', ...]
    this.clearFile();

    const messages = [];
    if (fileNames) {
      for (const fileName of fileNames) {
        messages.push({
          id: msgId,
          values: !!option ? [fileName, option] : [fileName],
        });
      }
    } else {
      messages.push({
        id: msgId,
      });
    }

    this.props.doShowMessage({message: messages});
  }

  /**
   * 親コンポーネントへの情報返却
   * @param {object} files 登録ファイルリスト
   * @param {object} deleteFiles 削除ファイルリスト
   */
  returnFile = (files, deleteFiles) => {
    const {componentId, fileSetHandler} = this.props;
    if (fileSetHandler) {
      fileSetHandler(files, deleteFiles, componentId);
    }
  }

  /**
   * マクロチェック
   * @param {object} file
   * @return {*} チェック結果
   */
  checkMacro = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.fileName = file.name;
      // xlsのみマクロチェックする。拡張子偽装は考慮しない。
      if (this.isExcel(file)) {
        reader.fileName = file.name;
        reader.readAsArrayBuffer(file); // ファイルデータを読み込むとマクロがみえる
        reader.onload = (e) => {
          const workbook = XLSX.read(e.target.result, {type: 'array', bookVBA: true});
          const hasMacro = this.workbookHasMacro(workbook);
          if (hasMacro) {
            // エラーになったファイル名はerror.messageに格納する
            return reject(new Error(e.target.fileName));
          } else {
            return resolve();
          }
        };
      } else {
        return resolve();
      }
    });
  }

  /**
   * 選択ファイル読み込み
   * @param {File} file 選択ファイル
   * @param {string} contentDocumentId ファイルアップロード済の場合、ContentVersionのIdを渡す
   */
  pushFile = (file, contentDocumentId) => {
    const reader = new FileReader();
    reader.fileName = file.name;

    reader.onload = (e) => {
      const contentType = (e.target.result).split(';')[0].split(':')[1];
      const newFile = {'FileBody': e.target.result,
        'FileName': e.target.fileName,
        'ContentType': contentType,
        'ContentDocumentId': contentDocumentId,
        'FileData': file,
      };
      const createFiles = this.files.concat(newFile);
      this.files = createFiles;
      this.returnFile(this.files, this.deleteFiles);

      // JPEGファイルの場合、サイズを変更し差し替える
      if (file.type == Jimp.MIME_JPEG) {
        this.resizeImage(file).then((resizedFile) => {
          newFile.FileData = resizedFile;
        });
      }
    };
    reader.readAsDataURL(file);
    // input:fileの情報をクリア
    this.clearFile();
  }

  /**
   * 画像のリサイズ
   * @param {File} file JPEGファイル
   */
  resizeImage = async (file) => {
    const buf = Buffer.from(await file.arrayBuffer());

    return new Promise((resolve) => {
      Jimp.read(buf, async (err, image) => {
        // 読み込み時エラー
        if (err) {
          console.info('画像ファイル読み込みエラーのため指定ファイルをそのまま使用');
          console.error(err);
          resolve(file);
        }

        // 現在の画像の幅/高さを取得
        const bmp = image.bitmap;
        const width = bmp.width;
        const height = bmp.height;

        // 長辺の長さが最大長を超える場合は最大長にする
        let changeWidth = null;
        let changeHeight = null;
        if (width > height) {
          if (width > MAX_PHOTO_LENGTH) {
            changeWidth = MAX_PHOTO_LENGTH;
            changeHeight = Jimp.AUTO;
          }
        } else {
          if (height > MAX_PHOTO_LENGTH) {
            changeHeight = MAX_PHOTO_LENGTH;
            changeWidth = Jimp.AUTO;
          }
        }
        if (changeWidth != null) {
          image.resize(changeWidth, changeHeight);
        }

        // JPEGのクオリティを80%に設定
        image.quality(80);

        // 新たなFileを生成する
        const type = file.type;
        const buf = await image.getBufferAsync(type);

        try {
          const newFile = new File([buf], file.name, {
            type: type,
            lastModified: file.lastModified,
          });

          resolve(newFile);
        } catch (err) {
          // 変換エラー
          console.info('画像ファイル生成エラーのため指定ファイルをそのまま使用');
          console.error(err);
          resolve(file);
        }
      });
    });
  }

  /**
   * 選択ファイルの選択解除
   * @param {object} e
   */
  delFile = (e) => {
    const files = this.files;
    const delFiles = this.deleteFiles;
    const createFiles = [];
    files.forEach((file) => {
      if (file !== e) createFiles.push(file);
      if (file === e && e.ContentDocumentId) {
        delFiles.push(e);
      }
    });
    this.files = createFiles;
    this.deleteFiles = delFiles;
    this.returnFile(this.files, this.deleteFiles);
  }

  /**
   * ダミーの追加ボタン押下時にinput:fileボタンを押下
   * @param {object} e
   */
  addFile = (e) => {
    document.getElementById('btn_addfile_' + this.props.componentId).click();
  }

  /**
   * input:fileの情報をクリア
   * @param {object} e
   */
  clearFile = (e) => {
    document.getElementById('btn_addfile_' + this.props.componentId).value = '';
  }

  /**
   * イメージデータのプレビュー
   * @param {object} e
   */
  viewFile = (e) => {
    this.image = e;
    this.setState({dialog: true});
  }

  /**
   * ダイアログのクローズ
   * @param {object} e
   */
  handleClose = (e) => {
    this.image = null;
    this.setState({dialog: false});
  }

  /**
   * ファイルドロップ時の処理
   * @param {array} acceptedFiles
   */
  onDrop = async (acceptedFiles) => {
    const files = acceptedFiles;

    // 添付ファイル数が最大ファイル数を超える場合、エラー
    if ((files.length + this.files.length) >
            this.props.maxFileCount) {
      this.setErr('CE0061', null);
      return;
    }

    // カスタムチェック処理
    const {customChecks} = this.props;
    if (customChecks != null) {
      for (const check of customChecks) {
        const error = check(files, this.files);
        if (error != null) {
          this.setErr(error.id, error.values);
          return;
        }
      }
    }

    const sizeErrFiles = [];
    const macroErrFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > this.props.maxFileSize) {
        sizeErrFiles.push(file.name);
      } else {
        try {
          await this.checkMacro(file);
        } catch (error) {
          macroErrFiles.push(error.message);
          continue;
        }
        await this.pushFile(file);
      }
    }
    if (sizeErrFiles.length > 0) {
      const maxSize = this.props.maxFileSize ?
       Math.floor(this.props.maxFileSize / 1000000) : 0;
      this.setErr('CE0028', sizeErrFiles, maxSize);
    }
    if (macroErrFiles.length > 0) {
      this.setErr('CE0030', macroErrFiles);
    }
  }

  render() {
    const {classes, required} = this.props;
    const isImage = this.files.filter((file) =>
      Object.hasOwnProperty.call(file, 'ContentType') &&
      (file.ContentType).match('image')).length > 0;
    if (!isImage) {
      this.files = [];
    }
    if (this.files.length == 0) {
      for (const row of this.props.initFile) {
        if (Object.hasOwnProperty.call(row, 'ContentType') ||
            Object.hasOwnProperty.call(row, 'anchor')) {
          // ファイル未保存、または保存済かつリンク変換済の場合
          this.files.push(row);
        } else {
          const anchorAttrs = {};
          anchorAttrs['onClick'] = (event) => {
            this.doDownloadAttachedFile(
                row.VersionData,
                row.FileType,
                row.PathOnClient);
          };
          anchorAttrs['href']='javascript:void(0)';
          let data = {anchor: null, FileName: '', ContentDocumentId: ''};
          data.FileName = row.PathOnClient;
          data.anchor = anchorAttrs;
          data.ContentDocumentId = row.ContentDocumentId;
          this.files.push(data);
        }
      }
    }

    const files = this.files;

    let addDisabled = true;
    if (files.length < this.props.maxFileCount) {
      addDisabled = false;
    }
    addDisabled |= this.props.disabled;

    let preview = '';

    preview = files.map((file, index) => {
      return (
        <React.Fragment key={index}>
          <table width="100%" border="0" cellPadding="0" cellSpacing="0">
            <tbody>
              {this.props.previewFlag &&
              Object.hasOwnProperty.call(file, 'ContentType') &&
              (file.ContentType).match('image') &&
              <tr>
                <td width={this.props.previewWidth}>
                  <img width="100%" src={file.FileBody} alt={file.FileName} className={classes.imageFile}
                    onClick={()=>{
                      this.viewFile(file);
                    }}/>
                </td>
                <td width="65%">&nbsp;</td>
              </tr>
              }
              <tr>
                <td colSpan="2">
                  <IconButton color="primary" aria-label="delete" size="medium" disabled={this.props.disabled}
                    onClick={()=>{
                      this.delFile(file);
                    }}>
                    <DeleteIcon />
                  </IconButton>
                  {Object.hasOwnProperty.call(file, 'ContentType') &&
                  (file.ContentType).match('image') &&
                  file.FileName
                  }
                  {Object.hasOwnProperty.call(file, 'ContentType') &&
                  !(file.ContentType).match('image') &&
                  <Link href={file.FileBody} download={file.FileName} target="_blank" rel="noopener">
                    {file.FileName}
                  </Link>
                  }
                  {!Object.hasOwnProperty.call(file, 'ContentType') &&
                   file.ContentDocumentId &&
                   <a key={file.FileName} {...file.anchor}>{file.FileName}</a>
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    });

    let requireText = ' ';
    if (required === true) {
      requireText = '必須入力です';
    }

    return (
      <div>
        {!this.props.disabled && files.length == 0 &&
          <FormHelperText error>{requireText}</FormHelperText>
        }
        {preview}
        <div>
          {!this.props.disabled &&
          <Box style={{padding: '5px', width: '100%', borderStyle: 'dashed'}} border={1} borderColor="grey.500" borderRadius={8}>
            <Dropzone
              onDropRejected={(fileRejections) => {
                const files = fileRejections;
                const fileNames = [];
                for (let i = 0; i < files.length; i++) {
                  const file = files[i].file;
                  // ファイル拡張子チェック
                  const chk = this.props.acceptFileType;
                  const filetype = this.getFileType(file);
                  let fileName = '';
                  chk.split(',').forEach((type) => {
                    if (!filetype.toLowerCase().match(type.toLowerCase()) && filetype != '') {
                      fileName = file.name;
                    }
                  });
                  if (fileName != '') {
                    fileNames.push(fileName);
                  }
                }
                if (fileNames.length > 0) {
                  this.setErr('CE0060', fileNames);
                }
              }}
              onDrop={this.onDrop}
              accept={this.props.acceptFileType}
              disabled={addDisabled}
            >
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <Box display="flex" alignItems="center">
                      <input {...getInputProps()} id={'btn_addfile_' + this.props.componentId} />
                      <Fab id="add" color="primary" aria-label="add" size="medium" disabled={addDisabled}>
                        <AddIcon />
                      </Fab>
                      <Typography align="center" variant="caption" display="block" style={{width: '80%'}} gutterBottom>
                        ここにファイルをドラッグアンドドロップするか，ここをクリックしてファイルを選択してください。
                      </Typography>
                    </Box>
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>}
        </div>

        <Dialog
          open={this.state.dialog}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth="xl"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          {(this.image) &&
            <React.Fragment>
              <DialogTitle id="scroll-dialog-title">
                {this.image.FileName}
                <IconButton
                  className={classes.closeButton}
                  onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <img width="100%" src={this.image.FileBody} alt={this.image.FileName} />
                </DialogContentText>
              </DialogContent>
            </React.Fragment>
          }
        </Dialog>
      </div>
    );
  }
}

Upload.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  componentId: PropTypes.string,
  customChecks: PropTypes.array,        // カスタムチェックメソッド一覧
  maxFileCount: PropTypes.number,       // 1度にアップロード可能なファイル数
  maxFileSize: PropTypes.number,        // 1ファイルあたりの許容サイズ
  previewFlag: PropTypes.bool,          // イメージファイルのプレビュー機能有無
  previewWidth: PropTypes.string,       // プレビューイメージの表示幅
  acceptFileType: PropTypes.string,     // 許可する拡張子を「.（ドット）」込みでカンマ区切りで指定 ex）.jpg,.pdf
  fileSetHandler: PropTypes.func,        // 選択ファイルを格納するための関数
  initFile: PropTypes.array,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
};

Upload.defaultProps = {
  componentId: 'default',
  maxFileCount: 3,
  maxFileSize: 1000000,
  previewFlag: false,
  acceptFileType: '.txt',
  initFile: [],
  disabled: false,
};

Upload.propTypes = {
  userInfo: PropTypes.object,
  doShowMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(Upload)),
);

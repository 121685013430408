import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, getFormValues} from 'redux-form';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import * as wjCore from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {MultiRowCellGroup, MultiRowCell, MultiRowCellTemplate} from '@grapecity/wijmo.react.grid.multirow';

import {getCodeFromGeneralPurposeMap, getComboName,
  getErrorCode, API_CODE, getToDate, getResponseResult} from '../../../../../../common/common.js';
import {authOperations} from '../../../../../../reducks/auth';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {senStbOperations} from '../../../../../../reducks/senStb';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomMultiRow, {createCollectionView} from '../../../../../molecules/CustomMultiRow';
import {orderSenStbFields} from '../../../../../../common/SFFields';
import {ConfirmWarningMessageArea} from '../../../../../molecules/WarningMessageArea.js';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});


/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SerialNumber__c: null, // 通し番号
  SzbtItiId1__c: null, // 支持物位置ID1
  SzbtItiId2__c: null, // 支持物位置ID2
  StbKobetuId1__c: null, // 設備個別ID1
  StbKobetuId2__c: null, // 設備個別ID2
  SenroCode1__c: null, // 線路コード1
  SenroCode2__c: null, // 線路コード2
  SenroName1__c: null, // 線路名1
  SenroName2__c: null, // 線路名2
  SenroNameKana1__c: null, // 線路名カナ1
  SenroNameKana2__c: null, // 線路名カナ2
  DntNo1__c: null, // 電柱番号1
  DntNo2__c: null, // 電柱番号2
  DntCategory1__c: null, // 電柱区分1
  DntCategory2__c: null, // 電柱区分2
  KyogaCableSnsyu__c: null, // 共架ケーブル線種
  KyogaCableGaikei__c: null, // 共架ケーブル外径(mm)
  KyogaCategory1__c: null, // 共架区分1
  KyogaCategory2__c: null, // 共架区分2
  Order__c: null, // 参照(本申込)
  KyogaZgsya__c: null, // 参照(申込共架事業者)
  ContractDnt1__c: null, // 参照(契約電柱始点側)
  ContractDnt2__c: null, // 参照(契約電柱終点側)
  TrsyaUserId__c: null,
  UpsyaUserId__c: null,
  DntNo1ManualInput__c: null, // 電柱番号1_自由入力
  DntNo2ManualInput__c: null, // 電柱番号2_自由入力
  K6KansnNo1__c: null, // 6kV幹線No1
  K6KansnNo2__c: null, // 6kV幹線No2
  K6BunkOne1__c: null, // 6kV分岐1_1
  K6BunkOne2__c: null, // 6kV分岐1_2
  K6BunkTwo1__c: null, // 6kV分岐2_1
  K6BunkTwo2__c: null, // 6kV分岐2_2
  K6BunkThree1__c: null, // 6kV分岐3_1
  K6BunkThree2__c: null, // 6kV分岐3_2
  K22SzbtNo1__c: null, // 22kV支持物番号1
  K22SzbtNo2__c: null, // 22kV支持物番号2
  K22GatiCategory1__c: null, // 22kV架地区分
  K22GatiCategory2__c: null, // 22kV架地区分
  K6K22HeigaCategory1__c: null, // 6kV22kV併架区分
  K6K22HeigaCategory2__c: null, // 6kV22kV併架区分
  AttachOrder__c: null, // 取付申込
  SenStb__c: null, // 親線設備
};

/**
 * 撤去完了届（径間：線設備）
 * 画面ID:1063
 */
class RemovalLineEquipInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      hasError: true, // 各画面のチェック結果。trueのときはエラーあり。
    };
    this.gridRef = React.createRef();
    this.onFormatItemHandler = this.onFormatItemHandler.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // フィルターを表示する列
    this.filterCols = ['SerialNumber__c', 'Dsp_SenroName1__c', 'SenroCode1__c', 'Dsp_DntNo1__c',
      'DntCategory1__c', 'Dsp_SenroName2__c', 'SenroCode2__c', 'Dsp_DntNo2__c', 'DntCategory2__c',
      'AttachOrderNo__c', 'KyogaCableSnsyu__c', 'KyogaCableGaikei__c'];
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      await this.searchData(true);
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearSenStbList();
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.saveScreenState('1063', 'Id');
    }
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/TekyoSyunkoReportList'}, null, this.props.history.option.CLEAR,
    );
  }

  /**
   * 検索処理
   * @param {bool} isInit true:初期処理,false:初期処理以外
   */
  searchData = async (isInit=false) => {
    const {orderId, generalPurposeMap} = this.props;

    const orderStatusCreate =
        getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    // 径間を取得する
    const conditions = {
      'Id': orderId, // 撤去のId
      'RecordType.DeveloperName': 'Tekyo',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    const response =
      await this.props.doGetTekkyoSenStbList(
          conditions, orderSenStbFields, appSortParams);
    const errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['線設備', '取得'],
        },
      });
      return;
    }

    this.gridRef.current.setScreenState('1063');
    const cv = createCollectionView(this.props.senStbList,
        false, {}, null);
    this.gridRef.current.setCellRange(0, 0);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 電柱の撤去区分が全部撤去の場合、チェックONにする。編集不可。
    // 上記以外の場合、チェックOFFにする。編集可。
    if (isInit && this.gridRef && this.gridRef.current) {
      const grid = this.gridRef.current.getGrid();
      if (grid != null && grid.rows.length > 0) {
        for (const row of grid.rows) {
          const item = row.dataItem;
          if (item) {
            if (item.TekyoCategory == '1') {
              row.isSelected = true;
            }
          }
        }
      }
      this.gridRef.current.onItemChecked();
    }

    // 警告チェック
    let _hasError = false;
    if (this.props.setGmnWarning) {
      for (const gmnkey of ['1037', '1038']) {
        if (Object.hasOwnProperty.call(this.props.setGmnWarning, gmnkey)) {
          _hasError = _hasError || this.props.setGmnWarning[gmnkey];
        }
      }
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                    orderStatusCreate ? false : true) ||
                    this.props.referenceMode, // 作成中のみ編集可
      isModified: false,
      hasError: _hasError,
    });

    return true;
  }

  /**
   * 画面更新処理
   */
  async componentDidUpdate() {
    try {
      if (this.props.order &&
        this.props.order.OrderStatus__c != '00') {
        // 作成中以外はチェックをつけない
        return;
      }
      // 保存している撤去申込の線設備にチェックをつける
      if (this.gridRef && this.gridRef.current) {
        const grid = this.gridRef.current.getGrid();
        if (grid != null && grid.rows.length > 0) {
          for (const row of grid.rows) {
            const item = row.dataItem;
            if (item &&
                this.props.order.OrderSenStbs__r &&
                this.props.order.OrderSenStbs__r.totalSize > 0) {
              // 既設の径間と撤去申込の径間が一致したら前回保存した径間とみなし、
              // チェックONにする
              row.isSelected = await this.isSameSenStb(
                  this.props.order.OrderSenStbs__r.records, item);
            }
          }
        }
        this.gridRef.current.onItemChecked();
      }
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 一覧に同じ径間が存在するかチェックする
   * 比較する項目は以下の通り。
   * 電柱区分、共架区分、設備個別ID、２５桁電柱番号、線路名、自由入力電柱番号
   * @param {array} items 一覧データ
   * @param {object} compObj チェック対象データ
   * @return {bool} true: 同じ径間が存在する, false: 同じ径間が存在しない
   */
  isSameSenStb = async (items, compObj) => {
    if (!items || !compObj) {
      return result;
    }

    let result = items.filter((data) =>
      ( data.AttachOrderNo__c == compObj.AttachOrderNo__c &&
      data.DntCategory1__c == compObj.DntCategory1__c &&
      data.KyogaCategory1__c == compObj.KyogaCategory1__c &&
      data.StbKobetuId1__c == compObj.StbKobetuId1__c &&
      data.DntNo1__c == compObj.DntNo1__c &&
      data.SenroName1__c == compObj.SenroName1__c &&
      data.DntNo1ManualInput__c == compObj.DntNo1ManualInput__c &&
      data.DntCategory2__c == compObj.DntCategory2__c &&
      data.KyogaCategory2__c == compObj.KyogaCategory2__c &&
      data.StbKobetuId2__c == compObj.StbKobetuId2__c &&
      data.DntNo2__c == compObj.DntNo2__c &&
      data.SenroName2__c == compObj.SenroName2__c &&
      data.DntNo2ManualInput__c == compObj.DntNo2ManualInput__c));
    if (result.length > 0) {
      return true;
    }

    return false;
  }

  /**
   * 線設備の登録/更新処理
   * @param {boolean} apply 申込：true、保存：false
   * @param {object} selectedSenStbList
   */
  insertOrUpdate = async (apply, selectedSenStbList) => {
    try {
      const {order, generalPurposeMap} = this.props;
      let orderStatusUpdate = null;
      if (apply) {
        orderStatusUpdate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus41', 2);// 竣工確認中
      }


      // 登録済の線設備を全て削除し、現在選択している線設備を登録する
      const saveSenStbList = [];
      // 登録
      let serialNumber = 1;
      for (const row of selectedSenStbList) {
        const item = row.dataItem;
        const insertData = {};
        for (const key in addParams) {
          if (Object.hasOwnProperty.call(item, key)) {
            insertData[key] = item[key];
          }
        }
        // 撤去申込と紐づく契約電柱情報を取得
        const formalDnts =
        order.ContractDnts__r && order.ContractDnts__r.totalSize > 0 ?
        order.ContractDnts__r.records : [];
        // 始点に設定する契約電柱情報を取得
        const startDnt = formalDnts.filter((data) =>
          item.DntNo1__c != null &&
          data.DntNo__c == item.DntNo1__c &&
          data.DntCategory__c == item.DntCategory1__c);
        // 終点に設定する契約電柱情報を取得
        const endDnt = formalDnts.filter((data) =>
          item.DntNo2__c != null &&
          data.DntNo__c == item.DntNo2__c &&
          data.DntCategory__c == item.DntCategory2__c);
        insertData['Order__c'] = order.Id; // 撤去申込と線設備紐づけ
        insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
        insertData['ContractDnt1__c'] = startDnt.length > 0 ? startDnt[0].Id : null; // 撤去申込の契約電柱と線設備紐づけ
        insertData['ContractDnt2__c'] = endDnt.length > 0 ? endDnt[0].Id : null; // 撤去申込の契約電柱と線設備紐づけ
        insertData['SenStb__c'] = item.Id; // 親線設備
        insertData['SerialNumber__c'] = serialNumber++;
        saveSenStbList.push(insertData);
      }
      // 削除 撤去申込の線設備
      const deleteConditions = [];
      const formalList =
          order.OrderSenStbs__r && order.OrderSenStbs__r.totalSize > 0 ?
          order.OrderSenStbs__r.records : [];
      for (const formalData of formalList) {
        if (formalData.Id) {
          deleteConditions.push({
            Id: formalData.Id,
            LastModifiedDate: formalData.LastModifiedDate,
          });
        }
      }
      // 撤去申込一時保存日更新用
      // 一時保存日はサーバー側で設定する
      const orderConditions = {
        Id: order.Id,
        LastModifiedDate: this.props.orderLastModifiedDate,
      };
      let updateOrder = null;
      if (apply) {
        updateOrder = {
          Id: order.Id,
          OrderStatus__c: orderStatusUpdate,
          OrderDate__c: getToDate(),
          TekyoOrderDate__c: getToDate(),
          KyogasyaSendBackReason__c: null,
        };
      } else {
        updateOrder = {
          Id: order.Id,
        };
      }

      const response = await this.props.doSaveSenStbsTekyo(
          [], deleteConditions, saveSenStbList,
          orderConditions, updateOrder);
      let errorStrings = ['線設備', '保存'];
      if (apply) {
        errorStrings = ['撤去完了届', '申込'];
      }
      const resResult = getResponseResult(response, errorStrings);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        return resResult;
      }

      return {success: true, messages: []};
    } catch (error) {
      console.log(error);
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      // 選択状態保存
      this.gridRef.current.saveScreenState('1063', 'Id');

      // 保存処理
      const selectedSenStbList = this.gridRef.current.selectedItem();
      const ret = await this.insertOrUpdate(false, selectedSenStbList);
      if (!ret.success) {
        this.props.doShowMessage({
          message: ret.messages,
          action: async () => {
            if (ret.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
              this.gotoListGmn();
            }
          },
        });
        return;
      }

      await this.props.doSetGmnWarning('1063', false);

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
          // 申込の最終更新日を退避
          this.props.doSetOrderLastModifiedDate(
              this.props.order.LastModifiedDate);
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  };

  /**
   * 申込ボタン押下時
   */
  doApply = async () => {
    try {
      const selectedSenStbList = this.gridRef.current.selectedItem();

      // 申込実行確認
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['申込'],
        },
        action: () => {
          this.updateOrder(selectedSenStbList);
        },
      });
    } catch (error) {
      this.props.doShowMessage({
        message: 'CS0001',
        action: () => {
          this.props.history.push('/login');
        },
      });
    }
  }

  /**
   * 申込処理実行
   * @param {object} selectedSenStbList
  */
  updateOrder = async (selectedSenStbList) => {
    // 申込処理
    const ret = await this.insertOrUpdate(true, selectedSenStbList);
    if (!ret.success) {
      this.props.doShowMessage({
        message: ret.messages,
        action: async () => {
          if (ret.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
            this.gotoListGmn();
          }
        },
      });
      return;
    }

    // 申込が成功したら一覧へ遷移
    this.props.doShowMessage({
      message: {
        id: 'CI0009',
        values: ['撤去完了届申込'],
      },
      action: () => {
        this.props.history.push(
            {pathname: '/TekyoSyunkoReportList'},
            null, this.props.history.option.CLEAR,
        );
      },
    });
  }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    if ((s.rowHeaders === e.panel &&
      e.panel.cellType == wjgGrid.CellType.RowHeader) ||
      (s.topLeftCells === e.panel &&
      e.panel.cellType == wjgGrid.CellType.TopLeft)) {
      if (e.cell == null || e.cell.children == null ||
         e.cell.children.length == 0) {
        return;
      }
      if (this.state.isDisabled) {
        wjCore.addClass(e.cell.children[0], 'wj-state-disabled');
      } else {
        if (s.rowHeaders === e.panel &&
       e.panel.cellType == wjgGrid.CellType.RowHeader) {
          const data = s.rows[e.row].dataItem;
          if (data.TekyoCategory == '1') {
            wijmo.addClass(e.cell.children[0], 'wj-state-disabled');
          } else {
            wjCore.removeClass(e.cell.children[0], 'wj-state-disabled');
          }
        }
        if (s.topLeftCells === e.panel &&
       e.panel.cellType == wjgGrid.CellType.TopLeft) {
          const sc = s.collectionView ? s.collectionView.sourceCollection : [];
          if (sc.filter((data) => data.TekyoCategory == '1').length > 0) {
            wijmo.addClass(e.cell.children[0], 'wj-state-disabled');
          }
        }
      }
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    let edt = s.activeEditor;
    const currentColumn = edt.filter.column.binding;
    if (currentColumn === 'DntCategory1__c' ||
           currentColumn === 'DntCategory2__c' ||
           currentColumn === 'KyogaCableSnsyu__c') {
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分（一）,電柱区分（二）
      if (currentColumn === 'KyogaCableSnsyu__c') {
        categoryName = 'CableSnsyu'; // ケーブル線種
      }
      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {generalPurposeMap} = this.props;
    const props = {
      rowHeaderType: 'check',
      filterOn: true,
      filters: this.filterCols,
      headersVisibility: 'All',
      allowSorting: 'None',
      counterOn: false,
      AddDeleteOn: false,
      frozenColumns: 4,
      style: {height: '600px'},
      isReadOnly: true,
      useStore: false,
      doShowMessage: this.props.doShowMessage,
      formatItemFunction: this.onFormatItemHandler,
      filterChanging: this.filterChangingHandler,
    };

    return (
      <React.Fragment>
        <CustomMultiRow ref={this.gridRef} {...props}>
          <MultiRowCellGroup header="" align="center">
            <MultiRowCell binding='SerialNumber__c' header='通し番号' width={50}/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="ケーブルルート" align="center" colspan={3}>
            <MultiRowCell binding='Dsp_SenroName1__c' header='線路名（一）' dataType="String" width={125}/>
            <MultiRowCell binding='Dsp_DntNo1__c' header='電柱番号（一）' dataType="String" width={140}/>
            <MultiRowCell binding='DntCategory1__c' header='電柱区分（一）' dataType="String" width={140}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory1__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='Dsp_SenroName2__c' header='線路名（二）' dataType="String"/>
            <MultiRowCell binding='Dsp_DntNo2__c' header='電柱番号（二）' dataType="String"/>
            <MultiRowCell binding='DntCategory2__c' header='電柱区分（二）' dataType="String">
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory2__c);
              }}/>
            </MultiRowCell>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="" align="center">
            <MultiRowCell binding='AttachOrderNo__c' header='取付申込番号'/>
          </MultiRowCellGroup>
          <MultiRowCellGroup header="共架設備" align="center" colspan={2}>
            <MultiRowCell binding='KyogaCableSnsyu__c' header='ケーブル線種' dataType="String" width={230}>
              <MultiRowCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'CableSnsyu', ctx.item.KyogaCableSnsyu__c);
              }}/>
            </MultiRowCell>
            <MultiRowCell binding='KyogaCableGaikei__c' header='ケーブル外径（mm）' dataType="Number" format="n1"/>
          </MultiRowCellGroup>
        </CustomMultiRow>
      </React.Fragment>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1063Confirm" item>
          <PositiveButton
            id="btn1063Confirm"
            onClick={handleSubmit(this.doApply)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode ||
                      this.state.isDisabled || this.state.hasError}
          >
            <span>申込</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1063Save" item>
          <PositiveButton
            id="btn1063Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1063Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off" className={classes.root}>
        <MainContainer props={this.props}
          footerBtn={footerBtn}>
          {!this.state.isDisabled && this.state.hasError &&
            <ConfirmWarningMessageArea procName='申込' />
          }
          <span>
            撤去する設備を選択してください。
          </span>
          <br/>
          <Grid container
            direction="column"
            alignItems="flex-start">
            <Grid key="key1063Table" item xs={12}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </MainContainer>
      </form>
    );
  }
}

RemovalLineEquipInput.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  values: PropTypes.object,
  userInfo: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  senStbList: PropTypes.object,
  referenceMode: PropTypes.bool,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetTekkyoSenStbList: PropTypes.func,
  doSaveSenStbsTekyo: PropTypes.func,
  doClearSenStbList: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  orderLastModifiedDate: PropTypes.string,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => {
  const initValues = {
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    userInfo: state.auth.userInfo,
    orderId: state.attachorder.orderId, // 前画面で設定した撤去申込のId
    order: state.senStb.senStbList &&
          Object.hasOwnProperty.call(state.senStb.senStbList, 'order') ?
              state.senStb.senStbList.order : null,
    senStbList: state.senStb.senStbList &&
                  Object.hasOwnProperty.call(state.senStb.senStbList, 'senStbList') ?
                  state.senStb.senStbList.senStbList : null,
    referenceMode: state.common.referenceMode == true ? true : false,
    setGmnWarning: state.common.setGmnWarning,
    initialValues: initValues,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  getUserInfo: authOperations.getUserInfoOperation,
  doGetTekkyoSenStbList: senStbOperations.doGetTekkyoSenStbList,
  doSaveSenStbsTekyo: senStbOperations.doSaveSenStbsTekyo,
  doClearSenStbList: senStbOperations.doClearSenStbList,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'RemovalLineEquipInput';

RemovalLineEquipInput = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(RemovalLineEquipInput)(state),
  };
})(RemovalLineEquipInput));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RemovalLineEquipInput),
);

const SET_LIST = 'SET_LIST';
const ADD_ITEM = 'ADD_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const CHANGE_ITEM = 'CHANGE_ITEM';
const SET_LIST_MULTI_ROW = 'SET_LIST_MULTI_ROW';
const ADD_ITEM_MULTI_ROW = 'ADD_ITEM_MULTI_ROW';
const REMOVE_ITEM_MULTI_ROW = 'REMOVE_ITEM_MULTI_ROW';
const CHANGE_ITEM_MULTI_ROW = 'CHANGE_ITEM_MULTI_ROW';
const MOVE_ITEM_MULTI_ROW = 'MOVE_ITEM_MULTI_ROW';
const MOVE_ITEM = 'MOVE_ITEM';
const SET_EDIT_DIALOG_DATA = 'SET_EDIT_DIALOG_DATA';
const SAVE_SELECTED_ITEMS = 'SAVE_SELECTED_ITEMS';
const SAVE_SCROLL_POSITION = 'SAVE_SCROLL_POSITION';

export default {
  /** 一覧設定 */
  SET_LIST,

  /** 行追加 */
  ADD_ITEM,

  /** 行削除 */
  REMOVE_ITEM,

  /** 行変更 */
  CHANGE_ITEM,

  /** 行移動 */
  MOVE_ITEM,

  /** 一覧設定（MultiRow） */
  SET_LIST_MULTI_ROW,

  /** 行追加（MultiRow） */
  ADD_ITEM_MULTI_ROW,

  /** 行削除（MultiRow） */
  REMOVE_ITEM_MULTI_ROW,

  /** 行変更（MultiRow） */
  CHANGE_ITEM_MULTI_ROW,

  /** 行移動（MultiRow） */
  MOVE_ITEM_MULTI_ROW,

  /** 編集ダイアログデータ設定 */
  SET_EDIT_DIALOG_DATA,

  /** 選択データ保存 */
  SAVE_SELECTED_ITEMS,

  /** スクロール位置保存 */
  SAVE_SCROLL_POSITION,
};

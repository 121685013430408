import {combineReducers} from 'redux';
import types from './types';

// 線設備一覧
const senStbList = (state = null, action) => {
  switch (action.type) {
    case types.GET_SEN_STB_LIST:
      return action.senStbList;
    default:
      return state;
  }
};

// 線設備 業務チェック
const checkAttachOrderSenStbResult = (state = null, action) => {
  switch (action.type) {
    case types.CHECK_SEN_STB:
      return action.checkAttachOrderSenStbResult;
    default:
      return state;
  }
};

// 線設備 処理結果
const senStbResult = (state = null, action) => {
  switch (action.type) {
    case types.SAVE_SEN_STB:
      return action.senStbResult;
    default:
      return state;
  }
};

const reducer = combineReducers({
  senStbList,
  checkAttachOrderSenStbResult,
  senStbResult,
});

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Field, getFormValues, reduxForm} from 'redux-form';
import * as validateRule from '../../validateRule';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';

import LoginCommon, {commonStyles, renderTextField} from './loginCommon.js';
import BackButton from '../../atoms/Buttons/BackButton';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import {commonOperations} from '../../../reducks/common';
import {authOperations} from '../../../reducks/auth';

/**
 * ログイン画面:パスワードリセット(認証コード発行) テンプレート。
 * 画面ID:3000
 */
class Container extends React.Component {
  /**
   * 変更ボタンクリック時処理
   */
  doPasswordReset = async () => {
    const {doShowMessage} = this.props;

    const dialog = {
      message: {
        id: 'CC0005',
        values: ['パスワードをリセット'],
      },
      action: this._execPasswordReset,
    };

    doShowMessage(dialog);
  };

  /**
   * パスワードリセット処理実施
   */
  _execPasswordReset = async () => {
    const {
      doLogout,
      doShowMessage,
      sendAuthenticationCode,
      values,
    } = this.props;
    try {
      const result = await sendAuthenticationCode(values.UserName);
      if (result === 'OK') {
        // 正常処理
        doShowMessage({message: 'CI0126'});
      } else if (result === 'LIMIT_EXCEED') {
        // 試行回数を超えた
        doShowMessage({message: 'CE0127'});
      } else {
        // システムエラー
        doShowMessage({
          message: 'CS0001',
          action: doLogout,
        });
      }
    } catch (err) {
      console.log(`エラー: ${err}`);
      // システムエラー
      doShowMessage({message: 'CS0001'});
    }
  }

  /**
   * 描画処理。
   * @return {JSX.Element} メニュー画面の要素
   */
  render() {
    const {classes, handleSubmit} = this.props;

    return (
      <LoginCommon>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.typography}>
              <Typography>※パスワードを忘れた方は，以下の情報を入力し，</Typography>
              <Typography>　送信してください。</Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Field
              name="UserName"
              label="ユーザID"
              type="text"
              className={classes.textField}
              required={true}
              margin="normal"
              variant="outlined"
              component={renderTextField}
              validate={[
                validateRule.required,
              ]}
            />
          </Grid>

          <Grid container xs={12} className={classes.button}>
            <Grid item xs={1} sm={2}/>
            <Grid item xs={5} sm={4} style={{marginLeft: -4, marginRight: 4}}>
              <PositiveButton
                onClick={handleSubmit(this.doPasswordReset)}
                variant="contained"
                size="large"
                fullWidth
                style={{minWidth: 0}}
              >
                <span>リセット</span>
              </PositiveButton>
            </Grid>
            <Grid item xs={5} sm={4} style={{marginLeft: 4, marginRight: -4}}>
              <BackButton
                props={this.props}
                fullWidth
                style={{minWidth: 0}}
              />
            </Grid>
            <Grid item xs={1} sm={2}/>
          </Grid>
        </Grid>
      </LoginCommon>
    );
  }
}

const FORM_NAME = 'ResetPassword';

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  doLogout: PropTypes.func,
  sendAuthenticationCode: PropTypes.func,
  doShowMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
};

const mapDispatchToProps = {
  doLogout: authOperations.doLogoutOperation,
  sendAuthenticationCode: authOperations.sendAuthenticationCodeOperation,
  doShowMessage: commonOperations.doShowMessage,
};

const mapStateToProps = (state) => ({
  initialValues: {
    UserName: null,
    Email: null,
  },
});

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));
export default withStyles(commonStyles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

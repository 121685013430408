import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';

import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import DangerButton from '../../../../../atoms/Buttons/DangerButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import {renderTextField, renderSelect, renderCheckbox} from '../../../../../atoms/CustomPart.js';

import {commonOperations} from '../../../../../../reducks/common/index.js';
import * as validateRule from '../../../../../validateRule';
import {authOperations} from '../../../../../../reducks/auth/index.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },

  textFieldDate: {
    margin: '0px 10px 15px 10px',
  },
});

/**
 * 更新時対象フィールド一覧
 */
const UPDATE_FIELDS = [
  'LastName',
  'FirstName',
  'MainteKoziCompany__c',
  'TantoAddress__c',
  'TantoTel__c',
  'TantoFaxNo__c',
  'UserLevel__c',
  'DeleteFlag__c',
];

/**
 * ユーザ管理（詳細） テンプレート。
 * 画面ID:1532
 *
 * @param {string} ret
 */

class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModified: false,
    };
  }

  /**
   * 初期処理
   */
  componentDidMount() {
  }

  /**
   * 破棄時処理
   */
  async componentWillUnmount() {
    const {
      clearAccountMasterList,
      clearContact,
      clearTempContact,
      reset,
    } = this.props;

    await clearAccountMasterList();
    await clearContact();
    await clearTempContact();
    reset();
  }

  create = async () => {
    try {
      const {doShowMessage, dirty} = this.props;

      // 内容を変更せず再度新規登録ボタンをクリックした場合
      if (!dirty) {
        this.props.doShowMessage({
          message: {
            id: 'CE0034',
            values: ['ユーザ情報'],
          },
        });

        return;
      };

      this.props.doShowMessage({
        message: {
          id: 'CC0004',
        },
        action: async () => {
          const {values, doUserRegist, setTempContact} = this.props;
          const contact = values.contact;

          const result = await doUserRegist(contact);

          if (result === 'OK') {
            // テンポラリ情報に担当者情報を格納
            setTempContact(contact);

            // 正常
            doShowMessage({
              message: {
                id: 'CI0009',
                values: ['認証メールの送信'],
              },
              action: () => {
                // OKを押すと画面を再描画し、テンポラリ情報を設定
                window.location.reload();
              },
            });
          } else if (result === 'USER_EXIST') {
            // 登録済メールアドレス
            doShowMessage({
              message: {
                id: 'CE0034',
                values: ['入力されたユーザID'],
              },
            });
          } else if (result === 'LIMIT_EXCEED') {
            // 試行回数を超えた
            doShowMessage({
              message: 'CE0127',
            });
          } else if (result === 'MAIL_ERROR') {
            // メールアドレス不正
            doShowMessage({
              message: {
                id: 'CE0052',
                values: ['認証メール', '送信'],
              },
            });
          } else if (result === 'REGIST_ERROR') {
            // 利用者登録失敗(Dynamo)
            doShowMessage({
              message: {
                id: 'CE0052',
                values: ['ユーザ', '登録'],
              },
            });
          } else {
            // その他エラー
            console.error(result);
            doShowMessage({
              message: 'CS0001',
              action: () => this.doMoveLogin,
            });
          }
        },
      });
    } catch (error) {
      console.error(error);
      doShowMessage({
        message: 'CS0001',
        action: () => this.doMoveLogin,
      });
    }
  }

  update = () => {
    const {doShowMessage} = this.props;

    // 削除フラグチェック
    if (!this._checkDeleteFlag()) {
      return;
    }

    // メールアドレス更新を行う場合はメッセージ差し替え
    let message = {
      id: 'CC0005',
      values: ['更新'],
    };

    const email = this._getChangedEmail();
    if (email != null) {
      message = 'CC0140';
    }

    const dialog = {
      message,
      action: this._execUpdate(email),
    };

    doShowMessage(dialog);
  }

  /**
   * 削除フラグのチェック
   * @return {boolean} チェックOKの場合true、NGの場合false
   */
  _checkDeleteFlag = () => {
    const {
      doShowMessage,
      initialValues,
      isSelf,
      values,
    } = this.props;

    const initDelFlg = initialValues.contact.DeleteFlag__c;
    const delFlg = values.contact.DeleteFlag__c;

    // 削除フラグの変更が行われていない場合はOK
    if (initDelFlg == delFlg) {
      return true;
    }

    // 自身の更新時に削除が指定されている場合はエラー(項目がないため本来ありえない)
    if (isSelf && delFlg) {
      doShowMessage({
        message: {
          id: 'CE0057',
          values: ['自身の情報の', '削除'],
        },
      });
      return false;
    }

    return true;
  }

  _getChangedEmail = () => {
    const {initialValues, values} = this.props;
    if (initialValues.contact.Email != values.contact.Email) {
      return values.contact.Email;
    }

    return null;
  }

  _execUpdate = (email) => async () => {
    const {
      doUserUpdate,
      doShowMessage,
      getUserInfo,
      hasEmail,
      history,
      initialValues,
      isSelf,
      values,
    } = this.props;
    const initCon = initialValues.contact;
    const inputCon = values.contact;

    // 対象ユーザ情報
    const conditions = {
      Id: inputCon.Id,
      userId: inputCon.UserId__c,
    };

    // 変更された値のみを更新情報として設定
    const contact = {};
    for (const field of Object.keys(inputCon)) {
      // 更新に使用しないフィールドは無視
      if (!UPDATE_FIELDS.includes(field)) {
        continue;
      }

      // 未変更なら更新対象に入れないフィールドの場合
      if (initCon[field] == inputCon[field]) {
        // 変更前後の値が同値の場合はContinue
        continue;
      }

      contact[field] = inputCon[field];
      initCon[field] = inputCon[field];
    }

    try {
      const result = await doUserUpdate(conditions, contact, email);
      if (result === 'OK') {
        // 更新 成功
        doShowMessage({
          message: {
            id: 'CI0009',
            values: ['ユーザ情報の更新'],
          },
          action: async () => {
            // 自身の情報更新の場合は再取得
            if (isSelf) {
              getUserInfo(true);
            }
          },
        });
      } else if (result === 'MAIL_OK') {
        // 更新&メールアドレス変更 成功
        let dialog;

        if (hasEmail) {
          // メールアドレス登録済の場合
          dialog = {
            message: 'CI0142',
            action: async () => {
              if (isSelf) {
                await getUserInfo(true);
              }
            },
          };
        } else {
          // メールアドレス未登録の場合
          dialog = {
            message: 'CI0187',
            action: async () => {
              history.push('/login');
            },
          };
        }

        doShowMessage(dialog);
      } else if (result === 'MAIL_ERROR') {
        // メールアドレス変更 エラー
        doShowMessage({message: 'CE0141'});
      } else {
        // エラー
        console.error(result);
        doShowMessage({
          message: {
            id: 'CS0001',
          },
          action: () => this.doMoveLogin,
        });
      }
    } catch (err) {
      console.error(err);
      doShowMessage({
        message: {
          id: 'CS0001',
        },
        action: () => this.doMoveLogin,
      });
    }
  }

  /**
   * パスワードリセットボタン押下時処理。
   */
  resetPassword = async () =>{
    const {values, doShowMessage} = this.props;
    const contact = values.contact;

    // ユーザIDを取得
    const userId = contact.UserId__c;

    // 削除済ユーザの場合はエラー
    if (contact.DeleteFlag__c) {
      doShowMessage({
        message: {
          id: 'CE0144',
          values: [userId],
        },
      });
      return;
    }

    // 確認メッセージ表示
    doShowMessage({
      message: {
        id: 'CC0006',
        values: [`「${userId}」のパスワード`, '再発行'],
      },
      action: () =>{
        // パスワード再発行
        this._execResetPassword(userId);
      },
    });
  }

  /**
   * パスワードリセット実行。
   * @private
   * @param {string} userName ユーザID
   * @return {string} 実行結果
   */
   _execResetPassword = async (userName) => {
     const {
       doShowMessage,
       sendAuthenticationCode,
     } = this.props;

     const result = await sendAuthenticationCode(userName);

     switch (result) {
       // 処理成功
       case 'OK':
         doShowMessage({
           message: 'CI0126',
         });
         break;

         // リセット回数制限エラー
       case 'LIMIT_EXCEED':
         doShowMessage({
           message: 'CE0127',
         });
         break;

         // その他エラーはシステムエラー
       default:
         doShowMessage({
           message: 'CS0001',
           action: this.doMoveLogin(),
         });
         break;
     }
   }

  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    const {
      accountMasterList,
      classes,
      handleSubmit,
      hasEmail,
      isEdit,
      isSelf,
    } = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        {(isEdit && !isSelf) &&
          <Grid key="btn1" item>
            <DangerButton
              onClick={handleSubmit(this.resetPassword)}
              variant="contained"
              size="large"
              type="submit"
            >
              <span>パスワードリセット</span>
            </DangerButton>
          </Grid>
        }

        {isEdit &&
          <Grid key="btn2" item>
            <PositiveButton
              startIcon={<EditIcon />}
              onClick={handleSubmit(this.update)}
              variant="contained"
              size="large"
              type="submit"
            >
              <span>更新</span>
            </PositiveButton>
          </Grid>
        }

        {!isEdit &&
          <Grid key="btn3" item>
            <PositiveButton
              startIcon={<AddIcon />}
              onClick={handleSubmit(this.create)}
              variant="contained"
              size="large"
              type="submit"
            >
              <span>新規追加</span>
            </PositiveButton>
          </Grid>
        }

        {hasEmail &&
        <Grid key="btn4" item>
          <BackButton
            props={this.props}
            showWarning={true}
          />
        </Grid>
        }

      </Grid>
    );

    return (
      <form noValidate autoComplete="off" >
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
          disableSideMenu={!hasEmail}
        >

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >

              <Grid key="kyogaCompanyName" item xs={12} sm={6} lg={3}>
                <Field
                  name="kyogaCompanyName"
                  className={classes.fields}
                  component={renderTextField}
                  label="共架事業者名"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="KyogaOfficeName" item xs={12} sm={6} lg={3}>
                <Field
                  name="KyogaOfficeName"
                  className={classes.fields}
                  component={renderTextField}
                  label="事業所名"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="UserId__c" item xs={12} sm={3} lg={2}>
                <Field
                  name="contact.UserId__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="ユーザーID"
                  fullWidth
                  type="text"
                  required={true}
                  InputProps={{
                    readOnly: isEdit,
                  }}
                  validate={[
                    validateRule.required,
                    validateRule.userName,
                    validateRule.minLength(7),
                    validateRule.maxLength(50),
                  ]}
                />
              </Grid>

              <Grid key="Name" container spacing={1} item xs={12} sm={9} lg={4}>
                <Grid key="LastName" item xs={6} >
                  <Field
                    name="contact.LastName"
                    className={classes.fields}
                    component={renderTextField}
                    label="ユーザ名（姓）"
                    fullWidth
                    type="text"
                    required={true}
                    validate={[
                      validateRule.required,
                      validateRule.fullWidth,
                      // this.validateMaxLegthName,
                    ]}
                  />
                </Grid>
                <Grid key="FirstName" item xs={6} >
                  <Field
                    name="contact.FirstName"
                    className={classes.fields}
                    component={renderTextField}
                    label="ユーザ名（名）"
                    fullWidth
                    type="text"
                    validate={[
                      validateRule.fullWidth,
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid key="MainteKoziCompany__c" item xs={12} md={4}>
                <Field
                  name="contact.MainteKoziCompany__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="保守工事会社名"
                  fullWidth
                  type="text"
                  InputProps={{
                    readOnly: isSelf,
                  }}
                  validate={[
                  ]}
                  helpertext="※保守工事会社のユーザーの場合のみ選択してください。"
                >
                  {accountMasterList &&
                    createSearchList(accountMasterList, 'Id', 'Name')}
                </Field>
              </Grid>
              <Grid key="TantoAddress__c" item xs={12} md={8}>
                <Field
                  name="contact.TantoAddress__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="住所"
                  fullWidth
                  type="text"
                  validate={[
                    validateRule.maxLength(255),
                  ]}
                />
              </Grid>

              <Grid key="Email" item xs={12} md={4}>
                <Field
                  name="contact.Email"
                  className={classes.fields}
                  component={renderTextField}
                  label="メールアドレス"
                  fullWidth
                  type="text"
                  required={true}
                  validate={[
                    validateRule.required,
                    validateRule.email,
                    validateRule.maxLength(80),
                  ]}
                />
              </Grid>

              <Grid key="TantoTel__c" item xs={12} sm={6} md={3}>
                <Field
                  name="contact.TantoTel__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="電話番号"
                  fullWidth
                  required={true}
                  type="text"
                  isReadOnly={true}
                  validate={[
                    validateRule.required,
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                />
              </Grid>

              <Grid key="TantoFaxNo__c" item xs={12} sm={6} md={3}>
                <Field
                  name="contact.TantoFaxNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="FAX番号"
                  fullWidth
                  type="text"
                  readOnly={false}
                  validate={[
                    validateRule.number,
                    validateRule.minLength(10),
                    validateRule.maxLength(11),
                  ]}
                />
              </Grid>

              <Grid key="UserLevel__c" item xs={12} sm={6} md={2}>
                <Field
                  name="contact.UserLevel__c"
                  className={classes.fields}
                  component={renderSelect}
                  label="ユーザレベル（種別）"
                  fullWidth
                  required={true}
                  validate={[
                    validateRule.required,
                  ]}
                  InputProps={{
                    readOnly: isSelf,
                  }}
                >
                  <MenuItem id="0" value={undefined}>　</MenuItem>
                  <MenuItem id="1" value="1">一般</MenuItem>
                  <MenuItem id="2" value="2">管理者</MenuItem>
                </Field>
              </Grid>

              <Grid key="HakkoDate__c" item xs={12} sm={6} md={3}>
                <Field
                  name="contact.HakkoDate__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="登録日"
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="HaisiDate__c" item xs={12} sm={6} md={3}>
                <Field
                  name="contact.HaisiDate__c"
                  accessibilitylabel="HaisiDate"
                  label="削除日"
                  component={renderTextField}
                  className={classes.textFieldDate}
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  validate={[
                  ]}
                />
              </Grid>
              {(isEdit && !isSelf) &&
                <Grid key="DeleteFlag__c" item xs={12} sm={6} md={3}>
                  <Field
                    name="contact.DeleteFlag__c"
                    accessibilitylabel="DeleteFlag"
                    label="削除"
                    component={renderCheckbox}
                    className={classes.textFieldDate}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>
              }
            </Grid>

          </PaperPart>

          <div style={{marginBottom: '20px'}} />

        </MainContainer>
      </form>
    );
  }
}

/**
 * 複数項目のバリデーション。
 *
 * @param {object} values フォームの値
 * @return {object} エラー情報
 */
const validate = (values) => {
  const contact = values.contact;

  const errors = {contact: {}};
  const err = errors.contact;

  if (!contact) {
    return errors;
  }

  // 管理者でかつ保守工事会社を指定した場合はエラー
  if (contact.UserLevel__c == '2' && contact.MainteKoziCompany__c) {
    err['UserLevel__c'] = '管理者は保守工事会社を指定できません';
    err['MainteKoziCompany__c'] = '管理者は保守工事会社を指定できません';
  }

  // 姓・名の合計文字数が20文字を超える場合はエラー
  const firstLen = contact.FirstName ? contact.FirstName.length : 0;
  const lastLen = contact.LastName ? contact.LastName.length : 0;
  const nameLen = firstLen + lastLen;
  if (nameLen > 20) {
    err['LastName'] = '姓・名合わせて20文字以内で入力してください';
  }

  return errors;
};

Container.propTypes = {
  accountMasterList: PropTypes.array,
  auth: PropTypes.object,
  classes: PropTypes.object.isRequired,
  clearAccountMasterList: PropTypes.func,
  clearContact: PropTypes.func,
  clearTempContact: PropTypes.func,
  contact: PropTypes.object,
  dirty: PropTypes.bool,
  doShowMessage: PropTypes.func.isRequired,
  doUserRegist: PropTypes.func,
  doUserUpdate: PropTypes.func,
  generalPurposeMap: PropTypes.object,
  getUserInfo: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  hasEmail: PropTypes.bool,
  history: PropTypes.object,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  isSelf: PropTypes.bool,
  location: PropTypes.object,
  reset: PropTypes.func.isRequired,
  sendAuthenticationCode: PropTypes.func,
  setTempContact: PropTypes.func,
  userInfo: PropTypes.object,
  values: PropTypes.object,
};

const mapStateToProps = (state) => {
  // 担当者情報がストアに登録されていれば「編集」と判定
  const isEdit = state.common.contact != null;
  const contact = getContact(isEdit, state);

  // ログインユーザのIDと担当者情報のIDが同一の場合、「自身の編集」と判定
  const userInfo = state.auth.userInfo;
  const isSelf = userInfo.Id == contact.Id;

  // 自身の編集時、メールアドレスが設定されているかどうか
  let hasEmail = true;
  if (isEdit && isSelf && !contact.Email) {
    hasEmail = false;
  }

  // 共架事業者・事業所名
  const kyogaCompanyName = userInfo.Account.Account__r.Name;
  const KyogaOfficeName = userInfo.Account.Name;

  // 新規追加の場合は共架事業所IDを設定
  if (!isEdit) {
    contact['AccountId'] = userInfo.AccountId;
  }

  const initialValues = {
    contact,
    kyogaCompanyName,
    KyogaOfficeName,
  };

  return {
    hasEmail,
    isEdit,
    isSelf,
    generalPurposeMap: state.common.generalPurposeMap,
    contactEditInfo: state.common.contactEditInfo,
    initialValues,
  };
};

const getContact = (isEdit, state) => {
  // 編集時は取得済の情報を返却
  if (isEdit) {
    return state.common.contact;
  }

  // 新規登録時
  const tempContact = state.common.tempContact;
  return tempContact ? tempContact : {DeleteFlag__c: false};
};

const mapDispatchToProps = {
  clearAccountMasterList: commonOperations.doClearAccountMasterList,
  clearContact: commonOperations.doClearContact,
  clearTempContact: commonOperations.doClearTempContact,
  doShowMessage: commonOperations.doShowMessage,
  doUserRegist: authOperations.doUserRegistOperation,
  doUserUpdate: authOperations.doUserUpdateOperation,
  sendAuthenticationCode: authOperations.sendAuthenticationCodeOperation,
  setTempContact: commonOperations.doSetTempContact,
};

const FORM_NAME = 'UserManagementInput';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MainContainer from '../../organisms/MainContainer.js';
import Upload from '../../organisms/UploadCustom';
import PaperPart from '../../atoms/PaperPart.js';

import {reduxForm, getFormValues} from 'redux-form';

import BackButton from '../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../atoms/Buttons/PositiveButton.js';
import {
  changeSameName,
  checkFileNameLength,
  checkFileSizeZero,
} from '../../../common/fileOperations.js';
import {API_CODE} from '../../../common/common.js';

import {contractDntOperations} from '../../../reducks/contractDnt';

/**
 * @typedef PhotoType 写真種別の情報
 * @property {string} componentId コンポーネントID
 * @property {string} [type] 写真種別。同一の写真種別を異なるコンポーネントIDで使う際に指定。省略時はcomponentIdを使用すること
 * @property {string} name 名称
 * @property {number} maxFileCount ファイル登録最大数
 * @property {string} [editableControl] 編集可能判定要素名
 */

const styles = (theme) => ({
});

export const GridTitle = withStyles((theme) => ({
  item: {
    '.MuiGrid-spacing-xs-2 > &': {
      padding: '0 0 0 8px',
    },
  },
}))(Grid);

export const GridItem = withStyles((theme) => ({
  item: {
    '.MuiGrid-spacing-xs-2 > &': {
      padding: '0 0 0 4px',
    },
  },
}))(Grid);

/**
 * 写真登録 共通テンプレート。
 * 画面ID:1060, 1061
 */
class Container extends React.Component {
  constructor(props) {
    super(props);

    const {displayItem} = props;

    this.selector = null;
    this.state = {
      selectImages: this.createImageContainer(displayItem.items),
      deleteImages: this.createImageContainer(displayItem.items),
      displayItem: displayItem,
      isModified: false,
    };
  }

  /**
   * コンポーネント破棄時処理。
   * 取得した写真情報を破棄する。
   */
  async componentWillUnmount() {
    const {clearPhotoInfo} = this.props;
    clearPhotoInfo();
  }

  /**
   * 写真情報格納情報の生成。
   *
   * @param {string[]} items 画面に表示する写真種別名一覧
   * @return {object} 写真情報格納情報
   */
  createImageContainer = (items) => {
    const result = {};

    for (const item of items) {
      const name = this.getDisplayItemName(item);
      result[name] = [];
    }

    return result;
  }

  doUpload = async () => {
    const {
      doShowMessage,
      photoInfo,
      photoRegistrationInfo,
      photoTypes,
      uploadPhoto,
    } = this.props;

    const {
      selectImages,
      isModified,
      deleteImages,
    } = this.state;

    // 変更が行われていない場合は処理終了
    if (!isModified) {
      doShowMessage({message: 'CE0094'});
      return;
    }

    // 登録実行確認
    let message = null;

    const dialog = {
      message: {id: 'CC0005', values: ['登録']},
      action: async () => {
        // アップロード処理実行
        const suffix = photoRegistrationInfo.suffix;

        try {
          const result =
            await uploadPhoto(
                photoInfo.contractDnt.dntInfo,
                suffix, selectImages, deleteImages,
                photoTypes,
            );

          // 結果チェック
          const body = result.data.body;
          switch (body.errorCode) {
            // 処理成功
            case API_CODE.SUCCESS:
              message = {
                message: {id: 'CI0009', values: ['登録']},
                action: () => {
                  window.location.reload();
                },
              };

              break;

            // ウィルスチェックエラー
            case API_CODE.ERROR_VIRUS:
              const infectedFiles = body.data.errorFiles;
              message = {
                message: {
                  id: 'CE0030',
                  values: this.createFileNames(infectedFiles),
                },
              };
              break;

            // ファイル登録数超過エラー
            case 'FILE_MAX':
              message = {
                message: 'CE0048',
                action: () => {
                  window.location.reload();
                },
              };
              break;

            // 一部ファイルの登録エラー
            case 'ERROR_UPLOAD':
              message = {message: 'CE0095'};
              break;

            default:
              // システムエラー
              message = {
                message: 'CS0001',
                action: () => {
                  this.props.history.push('/login');
                },
              };
              break;
          }
        } catch (err) {
          console.error(err);

          // 例外発生時はシステムエラー
          message = {
            message: 'CS0001',
            action: () => {
              this.props.history.push('/login');
            },
          };
        }

        // メッセージ表示
        doShowMessage(message);
      },
    };

    // 登録実行確認表示
    doShowMessage(dialog);
  }

  /**
   * ファイル名連結。
   * @param {object[]} files ファイル情報一覧
   * @return {string} 連結したファイル名
   */
  createFileNames = (files) => {
    const fileNames = [];
    for (const file of files) {
      fileNames.push(file.substring(file.lastIndexOf('/') + 1));
    }
    return fileNames.join(',');
  }

  /**
   * ファイル選択イベント
   * @param {UploadFileInfo[]} selectFiles 選択ファイル情報一覧
   * @param {UploadFileInfo[]} deleteFiles 削除ファイル情報一覧
   * @param {string} componentId コンポーネントID
   */
  setFiles = (selectFiles, deleteFiles, componentId) => {
    const selectImages = this.state.selectImages;
    selectImages[componentId] = selectFiles;

    const deleteImages = this.state.deleteImages;
    deleteImages[componentId] = deleteFiles;

    this.setState({
      selectImages: selectImages,
      deleteImages: deleteImages,
      isModified: true,
    });
  }

  getDisplayItemName(displayItem) {
    return typeof displayItem == 'object' ? displayItem.name : displayItem;
  }

  getDntName(displayItem) {
    return typeof displayItem == 'object' ? displayItem.dntName : 'contractDnt';
  }

  getPhotoType(photoTypes) {
    return photoTypes.hasOwnProperty('type') ? photoTypes.type : photoTypes.componentId;
  }

  /**
   * アップロード部の描画。
   * @param {boolean} pageDisabled ページ単位の非活性状態
   * @return {JSX.Element} アップロード部
   */
  renderUploads(pageDisabled) {
    const displayItem = this.state.displayItem;
    const {photoInfo, photoRegistrationInfo, photoTypes} = this.props;

    let index = 1;

    const uploads = [];
    for (const item of displayItem.items) {
      let disabled = pageDisabled;

      // 項目名
      const itemName = this.getDisplayItemName(item);

      // 電柱情報名
      const dntName = this.getDntName(item);

      // 項目単位の非活性条件
      if (item.disabledItem) {
        disabled = true;
      }

      const photoType = photoTypes[itemName];

      // 特別な条件で非活性となる項目の場合はチェック
      const editableControl = photoType.editableControl;
      if (editableControl != null ) {
        const control = photoRegistrationInfo[editableControl];
        if (control != null) {
          disabled = disabled || !control;
        }
      }

      // 初期表示ファイル
      let initFile = [];

      // データ取得している場合は初期ファイル設定
      if (photoInfo != null) {
        const dnt = photoInfo[dntName];
        const type = this.getPhotoType(photoType);
        if (dnt && dnt.images[type] != null) {
          initFile = dnt.images[type];

          // 以降の描画で再指定されないよう削除
          dnt.images[type] = null;
        }
      }

      const key = 'key_photo' + index;

      const uploadText = disabled ? '' :
        '(※最大' + photoType.maxFileCount + 'ファイル、1ファイルあたり10MBまで登録できます ※jpeg, jpgが登録可能です)';

      uploads.push(
          <Grid key={key} item xs={12}>
            <PaperPart>
              <Grid key={key + '1'} item xs={12}>
                <Typography variant="subtitle1">
                  {photoType.name}
                  <font size="2">{uploadText}</font>
                </Typography>
              </Grid>
              <Upload
                componentId={photoType.componentId}
                maxFileCount={photoType.maxFileCount}
                disabled={disabled}
                maxFileSize={10485760}
                previewFlag={true}
                previewWidth="35%"
                acceptFileType=".jpg,.jpeg"
                fileSetHandler={this.setFiles}
                initFile={initFile}
                customChecks={[
                  changeSameName,
                  checkFileNameLength,
                  checkFileSizeZero,
                ]}
              />
            </PaperPart>
          </Grid>,
      );

      index++;
    }

    return uploads;
  }

  render() {
    const {handleSubmit, photoInfo, photoRegistrationInfo} = this.props;
    const {displayItem} = this.state;

    /** ページ単位の項目非活性 */
    const pageDisabled =
      !displayItem.editablePage || photoRegistrationInfo.readOnly;

    const dntInfo = photoInfo != null ? photoInfo.contractDnt.dntInfo : {};

    // 申込情報を生成
    let accountName = '';
    let orderNo = '';
    let koziTitle = '';
    if (dntInfo.Order__r != null) {
      accountName = dntInfo.Order__r.OrderKyogaZgsya__r.Account__r.Name;
      orderNo = dntInfo.Order__r.OrderNo__c;
      koziTitle = dntInfo.Order__r.KoziTitle__c;
    }

    // 電柱内容を生成
    // 事業所
    let zgsyo = '';
    if (dntInfo.NWZgsyo__r != null) {
      zgsyo = dntInfo.NWZgsyo__r.Name;
    }

    // 線路名(線路コード) 電柱番号 タイトルと値を生成
    let senroInfo = null;
    let senroInfoTitle = '線路名(線路コード) 電柱番号';
    if (dntInfo) {
      const data = [];
      const title = [];

      // 線路名
      if (dntInfo.Dsp_SenroName__c) {
        data.push(dntInfo.Dsp_SenroName__c);
        title.push('線路名');
      }

      // 線路コード
      const senroCode = dntInfo.SenroCode__c;
      if (senroCode) {
        title.push('(線路コード)');
      }

      // 電柱番号
      data.push(dntInfo.Dsp_DntNo__c);
      title.push('電柱番号');

      senroInfo = data.join(' ');
      senroInfoTitle = title.join(' ');
    }

    // 電柱区分
    const dntCategory = dntInfo.DntCategory;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        {!pageDisabled &&
          <Grid key="btn1" item>
            <PositiveButton
              onClick={handleSubmit(this.doUpload)}
              variant="contained"
              size="large"
              type="button"
            >
              <span>登録</span>
            </PositiveButton>
          </Grid>
        }
        <Grid key="btn5" item>
          <BackButton
            isModified={this.state.isModified}
            showWarning={true}
            props={this.props}
          />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}>

          {/* 申込情報 */}
          <PaperPart>
            <Typography variant="h6">申込情報</Typography>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              style={{marginTop: 0}}
            >

              <GridTitle item xs={3} >
                <Typography gutterBottom>申込共架事業者</Typography>
              </GridTitle>
              <GridItem item xs={9}>
                <Typography gutterBottom>
                  {accountName}
                </Typography>
              </GridItem>

              <GridTitle item xs={3}>
                <Typography gutterBottom>申込番号</Typography>
              </GridTitle>
              <GridItem item xs={9}>
                <Typography gutterBottom>
                  {orderNo}
                </Typography>
              </GridItem>

              <GridTitle item xs={3}>
                <Typography gutterBottom>工事件名</Typography>
              </GridTitle>
              <GridItem item xs={9}>
                <Typography gutterBottom>
                  {koziTitle}
                </Typography>
              </GridItem>

            </Grid>
          </PaperPart>

          {/* 電柱内容 */}
          <PaperPart>
            <Typography variant="h6">電柱内容</Typography>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              style={{marginTop: 0}}
            >

              <GridTitle item xs={3} >
                <Typography gutterBottom>事業所</Typography>
              </GridTitle>
              <GridItem item xs={9}>
                <Typography gutterBottom>
                  {zgsyo}
                </Typography>
              </GridItem>

              <GridTitle item xs={3}>
                <Typography gutterBottom>{senroInfoTitle}</Typography>
              </GridTitle>
              <GridItem item xs={9}>
                <Typography gutterBottom>
                  {senroInfo}
                </Typography>
              </GridItem>

              <GridTitle item xs={3}>
                <Typography gutterBottom>電柱区分</Typography>
              </GridTitle>
              <GridItem item xs={9}>
                <Typography gutterBottom>
                  {dntCategory}
                </Typography>
              </GridItem>

            </Grid>
          </PaperPart>

          {/* 写真選択 */}
          {this.renderUploads(pageDisabled)}

          {/* </Grid> */}
        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  clearPhotoInfo: PropTypes.func,
  displayItem: PropTypes.object.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  photoInfo: PropTypes.object.isRequired,
  photoRegistrationInfo: PropTypes.object.isRequired,
  photoTypes: PropTypes.object.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
};

// 各項目の値
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  clearPhotoInfo: contractDntOperations.doClearPhotoInfo,
  uploadPhoto: contractDntOperations.doUploadPhoto,
};

const FORM_NAME = 'PhotoRegistration';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import {combineReducers} from 'redux';
import types from './types';

// 線路コード一覧
const senroCodeList = (state = null, action) => {
  switch (action.type) {
    case types.GET_SENRO_CODE_LIST:
      return action.senroCodeList;
    case types.SET_SENRO_CODE_LIST:
      return action.senroCodeList;
    default:
      return state;
  }
};

const reducer = combineReducers({
  senroCodeList,
});

export default reducer;

import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import Login from './pages/Login/index.js';
import UnavailableTime from './pages/Login/unavailableTime.js';
import ChangePassword from './pages/Login/changePassword.js';
import ResetPassword from './pages/Login/resetPassword.js';
import NewPassword from './pages/Login/newPassword.js';
import Loading from './components/organisms/Loading/index.js';
import Dialog from './components/organisms/Dialog/index.js';
import Scroll from './components/organisms/Scroll/index.js';
import Help from './components/templates/Help/index.js';
import Modal from './components/organisms/Modal/index.js';

import Menu from './pages/Menu/index.js';

// 可否判定申込
import ProprietyList from './pages/AttachOrder/ProprietyJudging/ProprietyJudging/List/index.js';
import ProprietyBasicInfo from './pages/AttachOrder/ProprietyJudging/ProprietyJudging/BasicInfo/index.js';
import ProprietySenStb from './pages/AttachOrder/ProprietyJudging/ProprietyJudging/SenStb/index.js';
import ProprietySenOrderDnt from './pages/AttachOrder/ProprietyJudging/ProprietyJudging/SenOrderDnt/index.js';
import ProprietyTenOrderDnt from './pages/AttachOrder/ProprietyJudging/ProprietyJudging/TenOrderDnt/index.js';
import ProprietyConfirm from './pages/AttachOrder/ProprietyJudging/ProprietyJudging/Confirm/index.js';
// 可否判定申込結果
import ProprietyResultList from './pages/AttachOrder/ProprietyJudging/ProprietyJudgingResult/List/index.js';
import ProprietyResultDetail from './pages/AttachOrder/ProprietyJudging/ProprietyJudgingResult/Detail/index.js';
// 本申込
import FormalOrderList from './pages/AttachOrder/AttachOrder/FormalOrder/List/index.js';
import FormalOrderBasicInfo from './pages/AttachOrder/AttachOrder/FormalOrder/BasicInfo/index.js';
import FormalOrderSenStb from './pages/AttachOrder/AttachOrder/FormalOrder/SenStb/index.js';
import FormalOrderSenOrderDnt from './pages/AttachOrder/AttachOrder/FormalOrder/SenOrderDnt/index.js';
import FormalOrderTenOrderDnt from './pages/AttachOrder/AttachOrder/FormalOrder/TenOrderDnt/index.js';
import FormalOrderConfirm from './pages/AttachOrder/AttachOrder/FormalOrder/Confirm/index.js';

import GaisanCostCalculationResultList from './pages/AttachOrder/AttachOrder/GaisanCostCalculationResultConfirm/List/index.js';
import GaisanCostCalculationResultConfirm from './pages/AttachOrder/AttachOrder/GaisanCostCalculationResultConfirm/Confirm/index.js';

import PayoffCostCalculationList from './pages/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/List/index.js';
import PayoffCostCalculationConfirm from './pages/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/Confirm/index.js';

import KyogaStartNotificationList from './pages/AttachOrder/AttachOrder/KyogaStartNotification/List/index.js';
// 一束化明細
import IskkDetail from './pages/IskkDetail/index.js';
// 竣工届
import SyunkoReportList from './pages/AttachOrder/AttachOrder/SyunkoReport/List/index.js';
import SyunkoReportSenOrderDnt from './pages/AttachOrder/AttachOrder/SyunkoReport/SenOrderDnt/index.js';
import SyunkoReportSenStb from './pages/AttachOrder/AttachOrder/SyunkoReport/SenStb/index.js';
import SyunkoReportTenOrderDnt from './pages/AttachOrder/AttachOrder/SyunkoReport/TenOrderDnt/index.js';
// 撤去竣工届
import TekyoSyunkoReportList from './pages/TekyoOrder/TekyoOrder/TekyoSyunkoReport/List/index.js';
import TekyoSyunkoReportBasicInfo from './pages/TekyoOrder/TekyoOrder/TekyoSyunkoReport/BasicInfo/index.js';
import TekyoSyunkoReportSenStb from './pages/TekyoOrder/TekyoOrder/TekyoSyunkoReport/SenStb/index.js';
import TekyoSyunkoReportSenOrderDnt from './pages/TekyoOrder/TekyoOrder/TekyoSyunkoReport/SenOrderDnt/index.js';
import TekyoSyunkoReportTenOrderDnt from './pages/TekyoOrder/TekyoOrder/TekyoSyunkoReport/TenOrderDnt/index.js';

import IsetuIraiContentsRegisterList from './pages/IsetuIrai/IsetuIraiRegister/IsetuIrai/List/index.js';
import IsetuIraiBasicInfInput from './pages/IsetuIrai/IsetuIraiRegister/IsetuIrai/BasicInput/index.js';
import IsetuIraiDetailInfInput from './pages/IsetuIrai/IsetuIraiRegister/IsetuIrai/DetailInput/index.js';
import IsetuIraiKyogasyaList from './pages/IsetuIrai/IsetuIraiRegister/IsetuIrai/KyogasyaList/index.js';
import IsetuIraiConfirm from './pages/IsetuIrai/IsetuIraiRegister/IsetuIrai/Confirm/index.js';
import IsetuIraiTargetDntList from './pages/IsetuIrai/IsetuIraiRegister/IsetuIrai/DntList/index.js';
import IsetuIraiScheduleContactList from './pages/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/List/index.js';
import IsetuIraiBasicInfCheck from './pages/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/BasicCheck/index.js';
import IsetuIraiDetailInfCheck from './pages/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/DetailCheck/index.js';
import IsetuIraiKoziAcceptList from './pages/IsetuIrai/IsetuIraiCheck/IsetuIraiKoziAccept/List/index.js';

import IsetuIraiKoziDateContactList from './pages/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/List/index.js';
import IsetuIraiKoziDateInput from './pages/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/Input/index.js';
import IsetuIraiKoziDateContactKyogasyaList from './pages/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/KyogasyaList/index.js';

import TransferConstructionCompleteReportList from './pages/IsetuIrai/KoziDone/TransferConstructionCompleteReport/List/index.js';
import TransferConstructionCompleteReportInput from './pages/IsetuIrai/KoziDone/TransferConstructionCompleteReport/Input/index.js';

import GsKoziPossibleCheckDesignDocumentCofirm from './pages/IsetuIrai/GsKoziPossibleCheck/GsKoziPossibleCheckDesignDocument/Confirm/index.js';

import RepairRequestList from './pages/RepairIrai/ConfirmIraiContent/List/index.js';
import RepairRequestInput from './pages/RepairIrai/ConfirmIraiContent/Input/index.js';
import RepairRequestConfirm from './pages/RepairIrai/ConfirmIraiContent/Confirm/index.js';

import RepairIraiGaisanCostCalculationResultList from './pages/RepairIrai/KoziDone/GaisanCostCalculationResultConfirm/List/index.js';
import RepairIraiGaisanCostCalculationResultConfirm from './pages/RepairIrai/KoziDone/GaisanCostCalculationResultConfirm/Confirm/index.js';
import RepairIraiPayoffCostCalculationList from './pages/RepairIrai/KoziDone/KoziCostPayOffContactReceive/List/index.js';
import RepairIraiPayoffCostCalculationConfirm from './pages/RepairIrai/KoziDone/KoziCostPayOffContactReceive/Confirm/index.js';
import RepairIraiSyunkoReportList from './pages/RepairIrai/KoziDone/RepairIraiSyunkoReport/List/index.js';
import RepairIraiSyunkoReportSenOrderDnt from './pages/RepairIrai/KoziDone/RepairIraiSyunkoReport/SenOrderDnt/index.js';
import RepairIraiSyunkoReportTenOrderDnt from './pages/RepairIrai/KoziDone/RepairIraiSyunkoReport/TenOrderDnt/index.js';
import StartNotification from './pages/RepairIrai/KoziDone/StartNotification/index.js';
import Conf from './config/config.js';
// その他
import UserManagementList from './pages/Other/UserManagement/UserManagement/List/index.js';
import UserManagementInput from './pages/Other/UserManagement/UserManagement/Input/index.js';
import UserManagementInputSelf from './pages/Other/UserManagement/UserManagement/Input/self.js';
import JuyoZikoList from './pages/Other/ContactZiko/JuyoZiko/List/index.js';
import JuyoZikoConfirm from './pages/Other/ContactZiko/JuyoZiko/Confirm/index.js';
import ContactZikoList from './pages/Other/ContactZiko/ContactZiko/List/index.js';
import ContactZikoConfirm from './pages/Other/ContactZiko/ContactZiko/Confirm/index.js';

import AppPhotoRegistration from './pages/Photo/AppPhotoRegistration/index.js';
import CompletionPhotoRegistration from './pages/Photo/CompletionPhotoRegistration/index.js';

import ProcessProgressSearch from './pages/Search/ProcessProgress/index.js';
import ContractDntSearch from './pages/Search/ContractDnt/index.js';

import ImportantSubjects from './pages/ImportantSubjects/index.js';
import TemplateList from './pages/TemplateList/index.js';

import BillingDetailSearchList from './pages/Search/Billing/BillingDetailSearch/List/index.js';
import BillingDetailSearchConfirm from './pages/Search/Billing/BillingDetailSearch/Confirm/index.js';
import DaikoInput from './pages/DaikoInput/List/index.js';

import KyogaZgsyaBasicInfo from './pages/Other/UserManagement/KyogaZgsyaBasicInfo/index.js';

// 訂正取付申込
import CorrectAttachOrderList from './pages/CorrectOrder/AttachOrder/AttachOrder/List/index.js';
import CorrectAttachBasicInfo from './pages/CorrectOrder/AttachOrder/AttachOrder/BasicInfo/index.js';
import CorrectAttachTenOrderDnt from './pages/CorrectOrder/AttachOrder/AttachOrder/TenOrderDnt/index.js';
import CorrectAttachSenOrderDnt from './pages/CorrectOrder/AttachOrder/AttachOrder/SenOrderDnt/index.js';
import CorrectAttachConfirm from './pages/CorrectOrder/AttachOrder/AttachOrder/Confirm/index.js';
import CorrectAttachSenStb from './pages/CorrectOrder/AttachOrder/AttachOrder/SenStb/index.js';

// 訂正撤去申込
import CorrectTekyoOrderList from './pages/CorrectOrder/TekyoOrder/TekyoOrder/List/index.js';
import CorrectTekyoOrderBasicInfo from './pages/CorrectOrder/TekyoOrder/TekyoOrder/BasicInfo/index.js';
import CorrectTekyoSenOrderDnt from './pages/CorrectOrder/TekyoOrder/TekyoOrder/SenOrderDnt/index.js';
import CorrectTekyoSenStb from './pages/CorrectOrder/TekyoOrder/TekyoOrder/SenStb/index.js';
import CorrectTekyoTenOrderDnt from './pages/CorrectOrder/TekyoOrder/TekyoOrder/TenOrderDnt/index.js';

// 表示権限判定
import {PERMISSION, getPermissionId} from './common/common.js';

if (!Conf.consolelog) {
  console.log = function() { };
  console.info = function() { };
}

// システム利用可能時間
let availableTimeStart = null;
let availableTimeEnd = null;
if (Conf.availableTime) {
  availableTimeStart = getAvailableTime(Conf.availableTime.start);
  availableTimeEnd = getAvailableTime(Conf.availableTime.end);
}

/**
 * 利用可能時刻の取得。
 *
 * 時刻の文字列から、当日のその時刻のDateを生成して返却する。
 * @param {string} time ":"区切りの時刻
 * @return {Date} 利用可能時刻
 */
function getAvailableTime(time) {
  if (!time) {
    return null;
  }

  const date = new Date();
  const times = time.split(':');
  date.setHours(times[0], times[1], 0, 0);

  return date;
}

const themeDefault = createTheme({
  palette: {
    primary: {
      light: '#4791db',
      main: '#1976d2',
      dark: '#115293',
      contrastText: '#fafafa',
    },
    secondary: {
      light: '#ffb6c1',
      main: '#ff0000',
      dark: '#8b0000',
      contrastText: '#fafafa',
    },
    typography: {
      useNextVariants: true,
    },
  },
});

const themes = {
  default: themeDefault,
};

const routes = ({theme}) => (
  <MuiThemeProvider theme={themes[theme]}>
    <Loading />
    <Dialog />
    <Scroll />
    <Modal content={<Help />} />
    <Switch>
      <PublicRoute exact
        path="/"
        component={Login}
      />
      <PublicRoute exact
        path="/login"
        component={Login}
      />
      <PublicRoute exact
        path="/templateList"
        title="申込関連書類書式一覧"
        component={TemplateList}
      />
      <PublicRoute exact
        path="/resetPassword"
        component={ResetPassword}
      />
      <PublicRoute exact
        path="/newPassword"
        component={NewPassword}
      />
      <PrivateRoute exact
        path="/changePassword"
        component={ChangePassword}
        title="パスワード変更"
      />
      <PrivateRoute exact
        path="/menu"
        component={Menu}
        title="メニュー"
      />

      <PrivateRoute exact
        path="/ProprietyList"
        component={ProprietyList}
        title="可否判定申込一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SenProprietyBasicInfo"
        component={ProprietyBasicInfo}
        title="可否判定申込（基本情報：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TenProprietyBasicInfo"
        component={ProprietyBasicInfo}
        title="可否判定申込（基本情報：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/ProprietySenStb"
        component={ProprietySenStb}
        title="可否判定申込（径間：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/ProprietySenOrderDnt"
        component={ProprietySenOrderDnt}
        title="可否判定申込（申込電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/ProprietyTenOrderDnt"
        component={ProprietyTenOrderDnt}
        title="可否判定申込（申込電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/ProprietyResultList"
        component={ProprietyResultList}
        title="可否判定結果一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/ProprietyResultDetail"
        component={ProprietyResultDetail}
        title="可否判定結果確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/ProprietyConfirm"
        component={ProprietyConfirm}
        title="可否判定申込（申込内容のご確認）"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/FormalOrderList"
        component={FormalOrderList} title="本申込一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SenFormalOrderBasicInfo"
        component={FormalOrderBasicInfo}
        title="本申込（基本情報：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TenFormalOrderBasicInfo"
        component={FormalOrderBasicInfo}
        title="本申込（基本情報：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/FormalOrderSenStb"
        component={FormalOrderSenStb}
        title="本申込（径間：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/FormalOrderSenOrderDnt"
        component={FormalOrderSenOrderDnt}
        title="本申込（申込電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/FormalOrderTenOrderDnt"
        component={FormalOrderTenOrderDnt}
        title="本申込（申込電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/FormalOrderConfirm"
        component={FormalOrderConfirm}
        title="本申込（申込内容のご確認）"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/AttachOrder/AttachOrder/GaisanCostCalculationResultConfirm/List"
        component={GaisanCostCalculationResultList}
        title="改造工事費用算定結果一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/AttachOrder/AttachOrder/GaisanCostCalculationResultConfirm/Confirm"
        component={GaisanCostCalculationResultConfirm}
        title="改造工事費用算定結果確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/List"
        component={PayoffCostCalculationList}
        title="改造工事完了件名一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/AttachOrder/AttachOrder/KoziCostPayOffContactReceive/Confirm"
        component={PayoffCostCalculationConfirm}
        title="改造工事完了件名確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/kyogaStartNotificationList"
        component={KyogaStartNotificationList}
        title="共架開始通知一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/IskkDetail"
        component={IskkDetail}
        title="一束化明細（一束化相手先共架者）"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/SyunkoReportList"
        component={SyunkoReportList}
        title="竣工届一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SyunkoReportSenOrderDnt"
        component={SyunkoReportSenOrderDnt}
        title="竣工届（申込電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SyunkoReportSenStb"
        component={SyunkoReportSenStb}
        title="竣工届（径間：線設備）"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
        isEditPage={true}
      />
      <PrivateRoute exact
        path="/SyunkoReportTenOrderDnt"
        component={SyunkoReportTenOrderDnt}
        title="竣工届（申込電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/TekyoSyunkoReportList"
        component={TekyoSyunkoReportList}
        title="撤去完了届一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SenTekyoSyunkoReportBasicInfo"
        component={TekyoSyunkoReportBasicInfo}
        title="撤去完了届（基本情報：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TenTekyoSyunkoReportBasicInfo"
        component={TekyoSyunkoReportBasicInfo}
        title="撤去完了届（基本情報：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TekyoSyunkoReportSenStb"
        component={TekyoSyunkoReportSenStb}
        title="撤去完了届（径間：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TekyoSyunkoReportSenOrderDnt"
        component={TekyoSyunkoReportSenOrderDnt}
        title="撤去完了届（撤去電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TekyoSyunkoReportTenOrderDnt"
        component={TekyoSyunkoReportTenOrderDnt}
        title="撤去完了届（撤去電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiRegister/IsetuIrai/List"
        component={IsetuIraiContentsRegisterList}
        title="移設依頼内容登録一覧"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiRegister/IsetuIrai/BasicInput"
        component={IsetuIraiBasicInfInput}
        title="移設依頼基本情報入力"
        isEditPage={true}
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiRegister/IsetuIrai/DetailInput"
        component={IsetuIraiDetailInfInput}
        title="移設依頼詳細情報入力"
        isEditPage={true}
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiRegister/IsetuIrai/KyogasyaList"
        component={IsetuIraiKyogasyaList}
        title="移設依頼共架者一覧"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiRegister/IsetuIrai/Confirm"
        component={IsetuIraiConfirm}
        title="移設依頼登録確認"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiRegister/IsetuIrai/DntList"
        component={IsetuIraiTargetDntList}
        title="移設依頼対象電柱一覧"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/List"
        component={IsetuIraiScheduleContactList}
        title="移設依頼予定連絡一覧"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/BasicCheck"
        component={IsetuIraiBasicInfCheck}
        title="移設依頼基本情報確認"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/DetailCheck"
        component={IsetuIraiDetailInfCheck}
        title="移設依頼詳細情報確認"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/IsetuIraiCheck/IsetuIraiKoziAccept/List"
        component={IsetuIraiKoziAcceptList}
        title="工事日確定連絡受理"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />

      <PrivateRoute exact
        path="/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/List"
        component={IsetuIraiKoziDateContactList}
        title="移設依頼工事日連絡一覧"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/Input"
        component={IsetuIraiKoziDateInput}
        title="移設依頼工事日入力"
        isEditPage={true}
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/KoziDateRegister/IsetuIraiKoziDate/KyogasyaList"
        component={IsetuIraiKoziDateContactKyogasyaList}
        title="移設依頼工事日連絡共架者一覧"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />

      <PrivateRoute exact
        path="/IsetuIrai/KoziDone/TransferConstructionCompleteReport/List"
        component={TransferConstructionCompleteReportList}
        title="移設工事完了届対象一覧"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/IsetuIrai/KoziDone/TransferConstructionCompleteReport/Input"
        component={TransferConstructionCompleteReportInput}
        title="移設依頼工事完了届作成提出"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />

      <PrivateRoute exact
        path="/IsetuIrai/GsKoziPossibleCheck/GsKoziPossibleCheckDesignDocument/Confirm"
        component={GsKoziPossibleCheckDesignDocumentCofirm}
        title="外線工事（抜柱）可能設計書確認"
        permission={[PERMISSION.CHUDEN_NW_SEKKEI]}
      />

      <PrivateRoute exact
        path="/RepairIrai/ConfirmIraiContent/Confirm"
        component={RepairRequestConfirm}
        title="改修依頼内容確認受理"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIrai/ConfirmIraiContent/Input"
        component={RepairRequestInput}
        title="改修依頼改修予定入力"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/RepairIrai/ConfirmIraiContent/List"
        component={RepairRequestList}
        title="改修依頼連絡一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIrai/KoziDone/GaisanCostCalculationResultConfirm/List"
        component={RepairIraiGaisanCostCalculationResultList}
        title="改造工事費用算定結果一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIrai/KoziDone/GaisanCostCalculationResultConfirm/Confirm"
        component={RepairIraiGaisanCostCalculationResultConfirm}
        title="改造工事費用算定結果確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/RepairIrai/KoziDone/KoziCostPayOffContactReceive/List"
        component={RepairIraiPayoffCostCalculationList}
        title="改造工事完了件名一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIrai/KoziDone/KoziCostPayOffContactReceive/Confirm"
        component={RepairIraiPayoffCostCalculationConfirm}
        title="改造工事完了件名確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIrai/KoziDone/StartNotification"
        component={StartNotification}
        title="着工可能通知一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIrai/KoziDone/RepairIraiSyunkoReport/List"
        component={RepairIraiSyunkoReportList}
        title="竣工届一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIraiSyunkoReportSenOrderDnt"
        component={RepairIraiSyunkoReportSenOrderDnt}
        title="竣工届（申込電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/RepairIraiSyunkoReportTenOrderDnt"
        component={RepairIraiSyunkoReportTenOrderDnt}
        title="竣工届（申込電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Other/UserManagement/UserManagement/List"
        component={UserManagementList}
        title="ユーザ管理（一覧）"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/Other/UserManagement/UserManagement/Input"
        component={UserManagementInput}
        title="ユーザ管理（詳細）"
        isEditPage={true}
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/Other/UserManagement/UserManagement/InputSelf"
        component={UserManagementInputSelf}
        title="ユーザ管理（詳細）"
        isEditPage={true}
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <PrivateRoute exact
        path="/Other/ContactZiko/JuyoZiko/List"
        component={JuyoZikoList}
        title="重要連絡事項一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Other/ContactZiko/JuyoZiko/Confirm"
        component={JuyoZikoConfirm}
        title="重要連絡事項確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Other/ContactZiko/ContactZiko/List"
        component={ContactZikoList}
        title="個別連絡事項一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Other/ContactZiko/ContactZiko/Confirm"
        component={ContactZikoConfirm}
        title="個別連絡事項確認"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/DaikoInput/List"
        component={DaikoInput}
        title="代行入力（ユーザ切替）"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_SEKKEI,
        ]}
      />

      <PrivateRoute exact
        path="/appPhotoRegistration"
        component={AppPhotoRegistration}
        title="申込写真登録"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/completionPhotoRegistration"
        component={CompletionPhotoRegistration}
        title="竣工写真登録"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />

      <PrivateRoute exact
        path="/processProgressSearch"
        component={ProcessProgressSearch}
        title="工程進捗検索"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/contractDntSearch"
        component={ContractDntSearch}
        title="契約電柱検索"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/importantSubjects"
        component={ImportantSubjects}
        title="重要事項説明"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Search/Billing/BillingDetailSearch/List"
        component={BillingDetailSearchList}
        title="請求明細検索"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Search/Billing/BillingDetailSearch/Confirm"
        component={BillingDetailSearchConfirm}
        title="定期共架料内訳"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectAttachOrderList"
        component={CorrectAttachOrderList}
        title="訂正取付申込一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SenCorrectAttachBasicInfo"
        component={CorrectAttachBasicInfo}
        title="訂正取付申込（基本情報：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TenCorrectAttachBasicInfo"
        component={CorrectAttachBasicInfo}
        title="訂正取付申込（基本情報：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectAttachTenOrderDnt"
        component={CorrectAttachTenOrderDnt}
        title="訂正取付申込（契約電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectAttachSenOrderDnt"
        component={CorrectAttachSenOrderDnt}
        title="訂正取付申込（契約電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectAttachSenStb"
        component={CorrectAttachSenStb}
        title="訂正取付申込（径間：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectAttachConfirm"
        component={CorrectAttachConfirm}
        title="訂正取付申込（申込内容のご確認）"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectTekyoOrderList"
        component={CorrectTekyoOrderList}
        title="訂正撤去申込一覧"
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/SenCorrectTekyoBasicInfo"
        component={CorrectTekyoOrderBasicInfo}
        title="訂正撤去申込（基本情報：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/TenCorrectTekyoBasicInfo"
        component={CorrectTekyoOrderBasicInfo}
        title="訂正撤去申込（基本情報：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectTekyoSenOrderDnt"
        component={CorrectTekyoSenOrderDnt}
        title="訂正撤去申込（契約電柱：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectTekyoSenStb"
        component={CorrectTekyoSenStb}
        title="訂正撤去申込（径間：線設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/CorrectTekyoTenOrderDnt"
        component={CorrectTekyoTenOrderDnt}
        title="訂正撤去申込（契約電柱：点設備）"
        isEditPage={true}
        permission={[PERMISSION.DENRYOKU_SP_KEIYAKU]}
      />
      <PrivateRoute exact
        path="/Other/UserManagement/KyogaZgsyaBasicInfo"
        component={KyogaZgsyaBasicInfo}
        title="共架者基本情報"
        permission={[
          PERMISSION.DENRYOKU_SP_KEIYAKU,
          PERMISSION.CHUDEN_NW_KEIYAKU,
        ]}
      />
      <UnavailableRoute exact
        path="/unavailable"
      />
      <Route exact path="*" component={Login} />
    </Switch>
  </MuiThemeProvider>

);

/**
 * ログイン画面へリダイレクトする共通パーツを返却。
 * @param {object} props プロパティ
 * @return {JSX.Element} ログイン画面リダイレクト共通パーツ
 */
function getLoginRedirectElement(props) {
  return (
    <Redirect to="/login" {...props} />
  );
}

/**
 * 利用時間外画面へリダイレクトする共通パーツを返却。
 * @param {object} props プロパティ
 * @return {JSX.Element} 利用時間外画面リダイレクト共通パーツ
 */
function getUnavailableRedirectElement(props) {
  return (
    <Redirect to="/unavailable" {...props} />
  );
}

/**
 * 利用時間外画面表示用Route。
 *
 * 利用時間外の場合利用時間外画面を表示、
 * 利用時間内の場合ログイン画面へリダイレクトします。
 * @param {object} パラメータ
 * @return {JSX.Element} 描画するElement
 */
function unavailableRoute({...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => getUnavailableElement(props)}
    />
  );
}

/**
 * 描画エレメントの選択(PublicRoute)
 * @param {object} props プロパティ
 * @return {JSX.Element} 描画エレメント
 */
function getUnavailableElement(props) {
  let element;
  if (!isAvailableTime()) {
    // 使用可能時間外の場合、使用時間外画面を表示
    element = (<UnavailableTime {...props} />);
  } else {
    // 使用可能時間内の場合、ログイン画面にリダイレクト
    element = getLoginRedirectElement(props);
  }

  return element;
}

/**
 * ログイン無しで表示可能なRoute
 * @param {object} パラメータ
 * @return {JSX.Element} 描画するElement
 */
function publicRoute(
    {component: Component, title, ...rest},
) {
  return (
    <Route
      {...rest}
      render={(props) => getPublicElement(
          {Component, props, title},
      )}
    />
  );
}

/**
 * 描画エレメントの選択(PublicRoute)
 * @param {object} パラメータ
 * @return {JSX.Element} 描画エレメント
 */
function getPublicElement(
    {Component, props, title},
) {
  let element;
  if (!isAvailableTime()) {
    // 使用可能時間外の場合、使用時間外画面へリダイレクト
    element = getUnavailableRedirectElement(props);
  } else {
    // 使用可能時間内の場合、画面を表示
    element = (
      <div>
        <Component {...props} title={title} />
      </div>
    );
  }

  return element;
}

/**
 * ログインが必要なRoute
 * @param {object} パラメータ
 * @return {JSX.Element} 描画するElement
 */
function privateRoute(
    {
      component: Component,
      isEditPage,
      permission,
      title,
      ...rest
    },
) {
  return (
    <Route
      {...rest}
      render={(props) => getPrivateElement(
          {
            checkAccess: rest.checkAccess,
            Component,
            generalPurpose: rest.generalPurpose,
            isEditPage,
            isLoggedIn: rest.isLoggedIn,
            permission,
            props,
            userInfo: rest.userInfo,
            title,
          },
      )}
    />
  );
}

/**
 * 描画エレメントの選択(PrivateRoute)
 * @param {object} パラメータ
 * @return {JSX.Element} 描画エレメント
 */
function getPrivateElement(
    {
      checkAccess,
      Component,
      generalPurpose,
      isEditPage,
      isLoggedIn,
      permission,
      props,
      title,
      userInfo,
    },
) {
  // 使用可能時間外の場合、使用時間外画面へリダイレクト
  if (!isAvailableTime()) {
    return getUnavailableRedirectElement(props);
  }

  // ログインしていない場合、ログイン画面にリダイレクト
  if (!isLoggedIn) {
    return getLoginRedirectElement(props);
  }

  // 表示できる権限ではない場合、ログイン画面にリダイレクト
  if (!checkPermission(permission, userInfo, generalPurpose)) {
    return getLoginRedirectElement(props);
  }

  // タイムアウト時間以上を経過している場合、ログイン画面にリダイレクト
  // if (checkAccess != null && checkAccess.time != null) {
  //   const dateArg = Date.now() + new Date().getTimezoneOffset() * 60000;
  //   const diffTime = (new Date(dateArg)).getTime() - checkAccess.time;

  //   // 画面のタイムアウトメッセージ表示とぶつからないよう30秒付加
  //   const timeout = (Conf.timeout * 60) + 30;
  //   if (diffTime / 1000 > timeout) {
  //     return getLoginRedirectElement(props);
  //   }
  // }

  // URLに紐づく画面を表示
  return (
    <div>
      <Component {...props} title={title} isEditPage={isEditPage} />
    </div>
  );
}

/**
 * システム使用可能時間の判定。
 *
 * config.jsのavailableTimeと現在時刻を比較し、システム使用時間内であるかどうかを判定する。
 * availableTimeの定義が存在しない場合は必ずtrueを返却する。
 * @return {boolean} システム使用可能時間内の場合true、時間外の場合false
 */
function isAvailableTime() {
  if (!availableTimeStart) {
    return true;
  }
  // システム使用可能時間内の場合はtrueを返却
  const now = new Date();
  if ((now >= availableTimeStart) && (availableTimeEnd >= now)) {
    return true;
  }

  // システム使用可能時間外の場合はfalseを返却
  return false;
}

/**
 * 表示権限の判定。
 *
 * @param {number[]} permission ページを表示できる権限一覧
 * @param {object} userInfo ログインユーザ情報
 * @param {object} generalPurpose 汎用マスタ
 * @return {boolean} 表示可否
 */
function checkPermission(permission, userInfo, generalPurpose) {
  // 表示権限が設定されていない場合は、権限チェックをせず表示OKとする
  if (permission === undefined) {
    return true;
  }

  const permissionId = getPermissionId(userInfo, generalPurpose);
  return permission.includes(permissionId);
}

privateRoute.propTypes = {
  checkAccess: PropTypes.object,
  generalPurpose: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  checkAccess: state.common.checkAccess,
  generalPurpose: state.common.generalPurposeMap,
  isLoggedIn: state.auth.isLoggedIn,
  userInfo: state.auth.userInfo,
});

const UnavailableRoute = connect()(unavailableRoute);
const PublicRoute = connect()(publicRoute);
const PrivateRoute = connect(mapStateToProps)(privateRoute);

// ///////////////////////////////////////////////////////////

const mapStateToPropsForMui = (state) => ({
  theme: state.style.theme,
});

const Routes = connect(mapStateToPropsForMui)(routes);

export default Routes;

import axios from 'axios';

import {
  getDntList,
  setDntInfo,
} from './actions';

import conf from './../../config/config.js';

/**
 * 電柱一覧 取得
 * @param {object} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetDntList = (conditions) => {
  console.log('電柱一覧 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'Dnt/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    if (!ret) {
      return null;
    }
    const retData = getDntList(ret.data.body.data ?
                                     ret.data.body.data : null);

    if (ret.data.body.errorCode == '00000') {
      dispatch(retData);
    }

    return ret;
  };
};

/**
 * 電柱情報 設定
 *
 * @param {object} dnt 電柱情報
 * @return {void} 設定結果
 */
const doSetDntInfo = (dnt) => {
  console.log('電柱情報 設定...');

  return async (dispatch) => {
    dispatch(setDntInfo(dnt));
  };
};

/**
 * 電柱一覧 取得 by 表示用電柱番号
 * @param {string} dspDntNo 表示用電柱番号
 * @param {string} targetPole 電柱マスタ選択 '6kV' or '22kV'
 * @param {string} dntCategory 電柱区分
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetDntbyDspDntNo = (dspDntNo, targetPole, dntCategory) => {
  console.log('電柱 取得 by 電柱番号...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (dspDntNo) {
      body['dspDntNo'] = dspDntNo;
    }

    if (targetPole) {
      body['targetPole'] = targetPole;
    }

    if (dntCategory) {
      body['dntCategory'] = dntCategory;
    }

    const ret = await axios.post(conf.API_URL + 'Dnt/getDntbyDspDntNo', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    if (!ret) {
      return null;
    }
    const retData = getDntList(ret ? ret.data.body.data ?
      ret.data.body.errorCode == '00000' ? ret.data.body.data: null : null : null);

    if (ret.data.body.errorCode == '00000') {
      dispatch(retData);
    }

    return ret;
  };
};


export default {
  doGetDntList,
  doSetDntInfo,
  doGetDntbyDspDntNo,
};


import {combineReducers} from 'redux';
import types from './types';

// 申込一覧
const orderList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_LIST:
      return action.orderList;
    case types.GET_ORDER_TYPE_LIST:
      return action.orderList;
    default:
      return state;
  }
};

// 申込
const order = (state = null, action) => {
  switch (action.type) {
    case types.RETRIEVE_ORDER:
      return action.order;
    default:
      return state;
  }
};

// 申込情報(申込と添付ファイル)
const orderInfo = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_INFO:
      return action.orderInfo;
    case types.GET_FORMAL_ORDER_INFO:
      return action.orderInfo;
    default:
      return state;
  }
};

// 申込 登録/更新結果
const orderResult = (state = null, action) => {
  switch (action.type) {
    case types.REGIST_ORDER:
      return action.orderResult;
    case types.UPDATE_ORDER:
      return action.orderResult;
    case types.ORDER_PROPRIETY:
      return action.orderResult;
    case types.UPDATE_PROPRIETY_ORDER:
      return action.orderResult;
    case types.DELETE_PROPRIETY_ORDER:
      return action.orderResult;
    case types.DELETE_FORMAL_ORDER:
      return action.orderResult;
    case types.UPDATE_FORMAL_ORDER:
      return action.orderResult;
    default:
      return state;
  }
};

// 申込ID
const orderId = (state = null, action) => {
  switch (action.type) {
    case types.SET_ORDER_ID:
      return action.orderId;
    default:
      return state;
  }
};

// 可否判定結果
const orderResultList = (state = null, action) => {
  switch (action.type) {
    case types.ORDER_RESULT_LIST:
      return action.orderResultList;
    default:
      return state;
  }
};

// 可否判定結果詳細
const orderResultDetail = (state = null, action) => {
  switch (action.type) {
    case types.ORDER_RESULT_DETAIL:
      return action.orderResultDetail;
    default:
      return state;
  }
};

// 申込入力チェック結果
const orderCheckResult = (state = null, action) => {
  switch (action.type) {
    case types.CHECK_ORDER:
      return action.orderCheckResult;
    default:
      return state;
  }
};

// 申込一覧サブクエリ
const orderSubList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_SUB_LIST:
      return action.orderSubList;
    default:
      return state;
  }
};

// 申込一覧サブクエリ（アクセス制限なし）
const orderSubAllList = (state = null, action) => {
  switch (action.type) {
    case types.GET_ORDER_SUB_ALL_LIST:
      return action.orderSubAllList;
    default:
      return state;
  }
};

// 一束化有無 設定
const isIskk = (state = null, action) => {
  switch (action.type) {
    case types.SET_ISKK_UMU:
      return action.isIskk;
    default:
      return state;
  }
};

// 申込番号
const orderNo = (state = null, action) => {
  switch (action.type) {
    case types.SET_ORDER_NO:
      return action.orderNo;
    default:
      return state;
  }
};

// 申込の最終更新日時
const orderLastModifiedDate = (state = null, action) => {
  switch (action.type) {
    case types.SET_ORDER_LAST_MODIFIED_DATE:
      return action.orderLastModifiedDate;
    default:
      return state;
  }
};

// 申込のIdと最終更新日時
const orderModifiedCondition = (state = null, action) => {
  switch (action.type) {
    case types.SET_ORDER_MODIFIED_CONDITION:
      return action.orderModifiedCondition;
    default:
      return state;
  }
};

const reducer = combineReducers({
  orderList,
  order,
  orderInfo,
  orderResult,
  orderId,
  orderResultList,
  orderResultDetail,
  orderCheckResult,
  orderSubList,
  orderSubAllList,
  isIskk,
  orderNo,
  orderLastModifiedDate,
  orderModifiedCondition,
});

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {FlexGridColumnGroup} from '@grapecity/wijmo.react.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import {InputDate} from '@grapecity/wijmo.input';
import CustomFlexGrid from '../../../../../molecules/CustomFlexGrid.js';
import MainContainer from '../../../../../organisms/MainContainer.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PaperPart from '../../../../../atoms/PaperPart.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import {renderTextField} from '../../../../../atoms/CustomPart.js';
import {changeDateFormat, changeDateFormatter, changeStringDate,
  execApexRestApi, changeDateUtc, getErrorCode, API_CODE, getResponseResult} from '../../../../../../common/common.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import * as validateRule from '../../../../../validateRule.js';

const styles = (theme) => ({
  fields: {
    margin: '0px 10px 15px 10px',
  },
});

// 申込、移設依頼設計書テーブル検索時の検索対象のフィールド名
const appFields = [
  'IsetuIraiDesignDocument__r.IsetuIraiCarryOutDate__c',
  'IsetuIraiDesignDocument__r.DesignCompanySyozoku__c',
  'IsetuIraiDesignDocument__r.IsetuIraiNo__c',
  'OrderKyogaZgsya__r.Name',
  'OrderKyogaZgsya__r.Account__r.Name',
  // 画面表示以外
  'Id', 'IsetuIraiDesignDocument__r.IsetuIraiStatus__c', 'OrderStatus__c',
  'LastModifiedDate',
  'IsetuIraiDesignDocument__r.Id',
  'IsetuIraiDesignDocument__r.LastModifiedDate',
];

// 契約電柱テーブルの検索時の検索対象
const subquery = [{
  'fields': ['Id', 'MaintenanceKoziCompany__r.Name', 'NWZgsyo__r.Name',
    'SenroCode__c', 'SerialNumber__c',
    'Dsp_DntNo__c', 'Dsp_SenroName__c',
    'toLabel(DntCategory__c)DntCategoryName',
    'toLabel(DrSideKoziEachDntContents__c)DrSideKoziEachDntContentsName',
    'EachDntKoziContentHosoku__c',
    'EachDntMessage__c', 'KyogaIsetuKoziCompletionDate__c',
    'KyogaIsetuKoziContents__c',
    'LastModifiedDate', 'Order__r.Id', 'Order__r.LastModifiedDate',
  ],
  'table': 'ContractDnts__r',
}];


/**
 * 移設依頼工事完了届作成提出
 * 画面ID:1452
 *
 * @param {string} ret
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: {},
      isModified: false,
      isDisabled: false,
    };
    this.gridRef = React.createRef();

    this.items = {
      KyogaIsetuKoziCompletionDate__c: '',
      KyogaIsetuKoziContents__c: '',
    };

    this.dateEditor = new InputDate(document.createElement('div'), {
      format: 'd',
      isRequired: false,
    });

    this.isetuDataMap = '';

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      if (prop == 'KyogaIsetuKoziCompletionDate__c') {
        let error = validateRule.correctPastDate(
            changeStringDate(this.props.values.IsetuIraiCarryOutDate__c,
                'YYYY/MM/DD', 'YYYY-MM-DD'))(
            changeDateFormatter(item.KyogaIsetuKoziCompletionDate__c, 'YYYY-MM-DD'));
        if (error) {
          console.warn(this.props.values.IsetuIraiCarryOutDate__c + '  ' + item.KyogaIsetuKoziCompletionDate__c);
          return error;
        }

        if (item.KyogaIsetuKoziContents__c == '01' || item.KyogaIsetuKoziContents__c == '02') {
          error = validateRule.required4(
              item.KyogaIsetuKoziCompletionDate__c);
          if (error) {
            return error;
          }
        }
      }

      if (prop == 'KyogaIsetuKoziContents__c') {
        const error = validateRule.required(item.KyogaIsetuKoziContents__c);
        if (error) {
          return error;
        }
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      this.isetuDataMap = new wjcGrid.DataMap(
          this.props.generalPurposeMap.KyogaIsetuKoziContents, 'Code__c', 'Name');

      // 一覧画面表示
      this.showContractDntList();
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    this.props.doClearOrderList();
    // 画面表示内容クリア
    for (const data in this.state.dataList) {
      if (this.state.dataList.hasOwnProperty(data)) {
        this.state.dataList[data] = '';
      }
    }
  }

  /**
   * 一時保存ボタン押下時
   * 一時保存では更新データのバリデーションチェック不要
   * （更新項目も必須項目ではなく、InputDateやコンボボックスでの指定のため不要）
   */
  doTempSave= async () => {
    const {userInfo} = this.props;

    try {
      // 更新レコードを抽出
      const updateItems = this.gridRef.current.itemsEdited();
      if (!updateItems || updateItems.length <=0) {
        // 更新がなければ何もしない
        return;
      }
      const records = [];
      for (const items of this.gridRef.current.getItems()) {
        const contractDnt = {};
        contractDnt['Id'] = items.Id;
        contractDnt['KyogaIsetuKoziContents__c'] = items.KyogaIsetuKoziContents__c;
        contractDnt['KyogaIsetuKoziCompletionDate__c'] =
              (items.KyogaIsetuKoziCompletionDate__c ?
                changeDateFormatter(
                    items.KyogaIsetuKoziCompletionDate__c, 'YYYY-MM-DD') : null);
        records.push(
            {
              'conditions': {
                'Id': items.Id,
                'LastModifiedDate': items.LastModifiedDate,
              },
              'contractDnt': contractDnt,
            });
      }

      const body = {
        'record': records,
        'stage': 'COMPLETE_REPORT_SAVED',
      };

      const result = await execApexRestApi(
          userInfo, 'ApiIsetuIrai/completeReportTemporarilySaved', body);
      const resResult = getResponseResult(result, ['更新内容', '保存']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['一時保存'],
        },
        action: async () => {
          // 一覧画面表示
          this.showContractDntList();
        },
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
    return;
  }

  /**
   * 登録ボタン押下時
   */
  doAccept= async () => {
    try {
      const updateItems = this.gridRef.current.getSourceCollection();
      if (this.checkValidate(updateItems) != true) {
        // 更新データのバリデーションチェックでエラーの場合
        console.warn('バリデーションチェックでエラーが発生しているため、登録出来ません');
        this.props.doShowMessage({
          message: {
            id: 'CE0050',
          },
        });
        return;
      }
      this.props.doShowMessage({
        message: {
          id: 'CC0005',
          values: ['登録'],
        },
        action: async () => {
          this.acceptMain();
        },
      });
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 登録メイン処理
   */
  acceptMain= async () => {
    const {userInfo} = this.props;
    // 移設依頼設計書
    const _appData = this.props.orderList[0];
    const _isetuIrai = _appData['IsetuIraiDesignDocument__r'];

    const records = [];

    for (const items of this.gridRef.current.getItems()) {
      const contractDnt = {};
      contractDnt['Id'] = items.Id;
      contractDnt['KyogaIsetuKoziContents__c'] = items.KyogaIsetuKoziContents__c;
      contractDnt['KyogaIsetuKoziCompletionDate__c'] =
            (items.KyogaIsetuKoziCompletionDate__c ?
              changeDateFormatter(
                  items.KyogaIsetuKoziCompletionDate__c, 'YYYY-MM-DD') : null);
      records.push(
          {
            'conditions': {
              'Id': items.Id,
              'LastModifiedDate': items.LastModifiedDate,
            },
            'contractDnt': contractDnt,
            'order': {
              'Id': items.Order__r.Id,
              'LastModifiedDate': items.Order__r.LastModifiedDate,
            },
            'isetuIrai': { // 移設依頼は1つなので同じものを設定
              'Id': _isetuIrai.Id,
              'LastModifiedDate': _isetuIrai.LastModifiedDate,
            },
          });
    }

    const body = {
      'record': records,
      'stage': 'COMPLETE_REPORT_SAVED',
    };

    console.log(body);

    const result = await execApexRestApi(
        userInfo, 'ApiIsetuIrai/completeReportRegister', body);
    const resResult = getResponseResult(result, ['更新内容', '登録']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: resResult.messages,
      });
      return;
    }

    this.props.doShowMessage({
      message: {
        id: 'CI0010',
        values: ['登録'],
      },
      action: async () => {
        // 一覧画面表示
        this.showContractDntList();
      },
    });
  }

  /**
   * 一覧画面表示
   */
  async showContractDntList() {
    if (this.props.orderId) {
      // 申込テーブル検索時の検索条件
      const appConditions = {
        'Id': this.props.orderId,
        'RecordType.DeveloperName': 'IsetuIrai',
      };
      // 申込、移設依頼設計書、契約電柱テーブルからデータ取得
      let result = await this.props.doGetOrderTypeList(
          appConditions, appFields, null, 'getKoziCompSub', subquery);
      const errorCode = getErrorCode(result);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }

      // 検索結果が0件の場合、エラーダイアログ表示
      if (!this.props.hasOwnProperty('orderList') ||
           this.props.orderList.length <= 0) {
        console.warn('申込、移設依頼設計書、契約電柱テーブルからデータ取得失敗');
        this.sysErr();
      }

      if (this.props.orderList) {
        // Mapに申込テーブル検索結果を保存
        const appData = this.props.orderList[0];
        const wkDataMap = new Map();

        // 契約電柱
        const contractDnts = appData['ContractDnts__r'];
        if (contractDnts) {
          wkDataMap.set('MaintenanceKoziCompany__c',
          contractDnts.records[0].MaintenanceKoziCompany__r ?
          contractDnts.records[0].MaintenanceKoziCompany__r.Name : null);
        }
        // 移設依頼設計書
        const isetuIrai = appData['IsetuIraiDesignDocument__r'];
        if (isetuIrai) {
          for (const key of Object.keys(isetuIrai)) {
            if (key === 'IsetuIraiCarryOutDate__c') {
              wkDataMap.set(key, changeDateFormat(isetuIrai[key]));
            } else {
              wkDataMap.set(key, isetuIrai[key]);
            }
          }
        }
        // 会社マスタ
        // 事業所名
        const orderKyogaZgsya = appData['OrderKyogaZgsya__r'];
        if (orderKyogaZgsya) {
          wkDataMap.set('OrderKyogaZgsya__r_Name',
          orderKyogaZgsya ? orderKyogaZgsya.Name : null);
          // 共架者名
          const account = orderKyogaZgsya['Account__r'];
          if (account) {
            wkDataMap.set('OrderKyogaZgsya__r_Account__r_Name',
            account ? account.Name : null);
          }
        }

        const dataList =
          {'IsetuIraiCarryOutDate__c': '',
            'DesignCompanySyozoku__c': '',
            'IsetuIraiNo__c': '',
            'OrderKyogaZgsya__r_Account__r_Name': '',
            'OrderKyogaZgsya__r_Name': '',
            'MaintenanceKoziCompany__c': ''};

        // 取得データを画面表示
        for (const data in dataList) {
          if (wkDataMap.has(data)) {
            dataList[data] = wkDataMap.get(data);
          }
        }
        Object.assign(this.props.values, dataList);
        this.setState({
          dataList: dataList,
        });

        const contDntList = [];
        if (contractDnts) {
          for (const item of contractDnts.records) {
            let contDnt = {};
            Object.assign(contDnt, item);
            contDnt['KyogaIsetuKoziCompletionDate__c'] =
             changeDateUtc(item.KyogaIsetuKoziCompletionDate__c);
            contDntList.push(contDnt);
          }
        }

        // 移設依頼設計書に紐づく、契約電柱をリストに表示
        if (contDntList.length > 0) {
          // 一覧画面で入力した値をストアに保存するための初期設定
          this.gridRef.current.setInitItems('1452', contDntList);
        } else {
          // 一覧画面で入力した値をストアに保存するための初期設定
          this.gridRef.current.setInitItems('1452', null);
        }

        // 申込ステータスが完結または契約電柱が０件の場合
        if (appData['OrderStatus__c'] == '90' || contDntList.length == 0) {
          this.setState({isDisabled: true});
        }
      }
    }
  }

  /**
   * 更新内容のチェック
   * 一覧でInputDateやコンボボックスを使用した場合、フォーマットエラーにならないので、
   * フォーマットのチェックは行わない。
   * @param {List} data チェック対象
   * @return {Boolean} 判定結果（true:正常、false:異常）
   */
  checkValidate(data) {
    for (const item of data) {
      if ('KyogaIsetuKoziContents__c' in item) {
        // 必須チェック
        const error = validateRule.required(item.KyogaIsetuKoziContents__c);
        if (error) {
          return false;
        }
      }

      if ('KyogaIsetuKoziCompletionDate__c' in item) {
        // 移設依頼発信年月日から現在日までの間かチェック
        let error = validateRule.correctPastDate(
            changeStringDate(this.props.values.IsetuIraiCarryOutDate__c,
                'YYYY/MM/DD', 'YYYY-MM-DD'))(
            changeDateFormatter(item.KyogaIsetuKoziCompletionDate__c, 'YYYY-MM-DD'));
        if (error) {
          return false;
        }

        if (item.KyogaIsetuKoziContents__c == '01' || item.KyogaIsetuKoziContents__c == '02') {
          // 共架移設工事内容が、移設、撤去の場合、共架移設工事完了日が未入力の場合、必須チェック
          error = validateRule.required4(
              item.KyogaIsetuKoziCompletionDate__c);
          if (error) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * 一覧の更新時
   *
   * @param {object} s
   * @param {object} e イベント
   */
  refreshedFunction = (s, e) => {
    try {
      const isModified = (this.gridRef.current.itemsEdited().length > 0);
      if (this.state.isModified != isModified) {
        this.setState({isModified: isModified});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  renderTable() {
    const props = {
      rowHeaderType: 'none',
      filterOn: true,
      headersVisibility: 'All',
      allowDragging: 'None',
      counterOn: false,
      style: {maxHeight: '400px'},
      initItems: this.items,
      validateEdits: false,
      getError: this.getError,
      refreshedFunction: this.refreshedFunction,
      isReadOnly: this.state.isDisabled,
    };

    return (
    // 一覧表示部の表示設定
      <div className="container-fluid">
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup binding='SerialNumber__c' header='No' dataType='Number' cssClass='uneditableCell' isReadOnly={true} width={60}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='NWZgsyo__r.Name' header='事業所' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='Dsp_SenroName__c' header='線路名' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='Dsp_DntNo__c' header='電柱番号' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DntCategoryName' header='電柱区分' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='DrSideKoziEachDntContentsName' header='電力側電柱別工事内容' dataType='String' width={180} cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='EachDntKoziContentHosoku__c' header='電力側電柱別工事内容／補足事項' dataType='String' width={250} cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='EachDntMessage__c' header='電柱別連絡事項' dataType='String' cssClass='uneditableCell' isReadOnly={true}></FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KyogaIsetuKoziCompletionDate__c' header='共架移設工事完了日' dataType='Date' width={165} isRequired={false}
            editor= {this.dateEditor} format='yyyy/MM/dd'>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding='KyogaIsetuKoziContents__c' header='共架移設工事内容' dataType='String' width={165} isRequired={false}
            dataMap = {this.props.generalPurposeMap && this.isetuDataMap}
          >
          </FlexGridColumnGroup>
        </CustomFlexGrid>
      </div>
    );
  }

  render() {
    const {classes} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >

        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<SaveAltIcon />}
            onClick={() => {
              this.doTempSave();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isDisabled}
          >
            <span>一時保存</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn2" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={() => {
              this.doAccept();
            }}
            variant="contained"
            size="large"
            type="button"
            disabled={this.state.isDisabled}
          >
            <span>登録</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <BackButton
            showWarning={true}
            props={this.props}
            isModified={this.state.isModified}
          />
        </Grid>
      </Grid>
    );

    return (
      <form noValidate autoComplete="off">
        <MainContainer
          props={this.props}
          footerBtn={footerBtn}
        >

          <PaperPart>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >

              <Grid key="key1-1" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiCarryOutDate__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="発信年月日"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-2" item xs={12} sm={6} md={3}>
                <Field
                  name="DesignCompanySyozoku__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="設計会社／所属"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-3" item xs={12} sm={6} md={3}>
                <Field
                  name="IsetuIraiNo__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="一連番号"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-4" item xs={12} sm={6} md={3}>
                <Field
                  name="OrderKyogaZgsya__r_Account__r_Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="共架者名"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-5" item xs={12} sm={6} md={3}>
                <Field
                  name="OrderKyogaZgsya__r_Name"
                  className={classes.fields}
                  component={renderTextField}
                  label="事業所名"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

              <Grid key="key1-6" item xs={12} sm={6} md={3}>
                <Field
                  name="MaintenanceKoziCompany__c"
                  className={classes.fields}
                  component={renderTextField}
                  label="移設依頼送付先"
                  fullWidth
                  type="text"
                  InputProps={{readOnly: true}}
                  validate={[
                  ]}
                />
              </Grid>

            </Grid>
          </PaperPart>

          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid key="key-table" item xs={12}>
              {this.renderTable()}
            </Grid>

          </Grid>

          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography>※移設工事で当社別電柱に新たに共架する場合は、別途申し込みが必要です。<br/>
              　また、移設工事に伴って撤去される場合も、別途申し込みが必要です。</Typography>
            </Grid>
          </Grid>

          <div style={{marginBottom: '20px'}} />

        </MainContainer>
      </form>
    );
  }
}

Container.propTypes = {
  userInfo: PropTypes.object,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object,
  history: PropTypes.object.isRequired,
  orderId: PropTypes.string,
  generalPurposeMap: PropTypes.object,
  orderList: PropTypes.array,
  contractDntList: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  doGetOrderTypeList: PropTypes.func.isRequired,
  doClearOrderList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  orderId: state.attachorder.orderId,
  generalPurposeMap: state.common.generalPurposeMap,
  orderList: state.attachorder.orderList,
  initialValues: {
    // 初回表示時、valuesがundefinedになるため、設定する
  },
});

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderTypeList: attachorderOperations.doGetOrderTypeList,
  doClearOrderList: attachorderOperations.doClearOrderList,
};

const FORM_NAME = 'TransferConstructionCompleteReportInput';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {withRouter} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundcolor: '#000000',
  },
  circleBar: {
    position: 'fixed',
    textAlign: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '2000',

    backgroundColor: '#000',
    MozOpacity: '0.2',
    filter: 'alpha(opacity=20)',
    opacity: '0.2',
  },
  circle: {
    position: 'fixed',
    textAlign: 'center',
    top: '200px',
    left: 'calc(50% - 50px)',
    width: '100px',
    zIndex: '3000',
  },
});

class Loading extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        {this.props.isRequesting &&
          <div>
            <div className = { classes.circleBar }></div>
            <CircularProgress size='10' className = { classes.circle }/>
          </div>
        }
      </div>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isRequesting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isRequesting: state.style.isRequesting,
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
    )(withRouter(Loading)),
);

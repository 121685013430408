const GET_SEN_STB_LIST = 'GET_SEN_STB_LIST';
const CHECK_SEN_STB = 'CHECK_SEN_STB';
const SAVE_SEN_STB = 'SAVE_SEN_STB';

export default {
  /** 線設備一覧 取得 */
  GET_SEN_STB_LIST,

  /** 線設備 業務チェック */
  CHECK_SEN_STB,

  /** 線設備 保存 */
  SAVE_SEN_STB,
};

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import CommonTheme from '../../Theme.js';
import {changeDateFormat} from '../../common/common.js';

/**
 * @typedef ValueData 値部分表示情報
 * @property {string} [value] 値
 * @property {string} [link] リンク先
 * @property {function} [onClick] クリック時のFunction。指定時はリンク先をjavascript:void(0)にする
 * @property {string} [format] フォーマット変換(date:日付型)
 */

/**
 * @typedef LineData 行情報
 * @property {string} key キー
 * @property {string} name 名称
 * @property {ValueData[]} [items] 複数項目表示用
 */

/**
 * @typedef {ValueData & LineData} SimpleTableData データの型
 * 1項目しか表示しない場合は ValueDataの値を設定、
 * 複数項目表示する場合は items[] にValueDataの配列を設定する。
 */

/**
 * @typedef SimpleTableArg 引数の型
 * @property {object} [styles] 表示Style
 * @property {SimpleTableData[]} dataList データ一覧
 */

const defaultStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
  },
  tableHeader: {
    width: '34%',
    color: CommonTheme.palette.primary.contrastText,
    backgroundColor: CommonTheme.palette.primary.dark,
  },
  tableData: {
    whiteSpace: 'pre-wrap',
  },
}));

/**
 * SimpleTable.
 *
 * @param {SimpleTableArg} {styles, dataList}
 * @return {JSX.Element}
 */
export default function SimpleTable({styles, dataList}) {
  const classes = defaultStyles();

  if (styles == null) {
    styles = {};
  }
  const customClasses = makeStyles(styles)();

  // Anchorタグ用属性生成
  for (const data of dataList) {
    if (data.items) {
      // 複数項目表示時はそれらの項目全てに反映
      for (const item of data.items) {
        setAnchorData(item);
      }
    } else {
      // 1項目表示時はその項目のみに反映
      setAnchorData(data);
    }
  }

  return (
    <TableContainer
      component={Paper}
      className={clsx(customClasses.tableContainer, classes.tableContainer)}>
      <Table aria-label="simple table">
        <TableBody>
          {dataList.map((line) => (
            <TableRow key={line.key}>

              <TableCell component="th" scope="row" className={clsx(customClasses.tableHeader, classes.tableHeader)} xs={2}>
                {line.name}
              </TableCell>

              <TableCell component="td" className={clsx(customClasses.tableData, classes.tableData)}>
                {showDataArea(line)}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/**
 * 値部分表示情報にAnchor情報を設定
 * @param {ValueData} data 値部分表示情報
 */
function setAnchorData(data) {
  const anchorAttrs = {};
  if (data.link != null) {
    anchorAttrs['href'] = data.link;
  }

  if (data.download != null) {
    anchorAttrs['download'] = data.download;
  }

  // onClick指定時
  if (data.onClick != null) {
    // クリックイベントを設定
    anchorAttrs['onClick'] = (event) => {
      data.onClick(event);
    };

    // リンクの文字列をjavascript:void(0)にする
    anchorAttrs['href'] = 'javascript:void(0)';
  }

  // format指定時
  if (data.format != null) {
    if (data.format === 'date') {
      // 日付型(画面表示用)にフォーマット変換
      data.value = changeDateFormat(data.value);
    }
  }

  // Anchorタグ用属性があれば情報追加
  if (Object.keys(anchorAttrs).length != 0) {
    data['anchor'] = anchorAttrs;
  }
}

/**
 * 行情報からの値部分表示
 * 複数項目表示時には要素を配列で、そうでない場合には1要素のみ返却
 * @param {LineData} line 行情報
 * @return {JSX.Element|JSX.Element[]}
 */
function showDataArea(line) {
  // 1項目のみ表示時には1要素のみ返却
  if (!line.items) {
    return drawDataArea(line);
  }

  // 複数項目表示時には、各表示内容を配列で返却
  const ret = [];
  const len = line.items.length;
  for (let i = 0; i < len; i++) {
    const item = line.items[i];
    ret.push(drawDataArea(item));

    // 最終行ではない場合は<br/>を追加
    if (i < len - 1) {
      ret.push((<br />));
    }
  }

  return ret;
}

/**
 * 値部分情報1件分の表示
 * Anchor要素がある場合はAnchorタグで囲まれた文字列を、
 * 要素がない場合は単純に文字列を返却する。
 * @param {ValueData} data 値部分表示情報
 * @return {JSX.Element}
 */
function drawDataArea(data) {
  if (data.anchor) {
    return ((<a {...data.anchor}>{data.value}</a>));
  } else {
    return ((data.value));
  }
}

SimpleTable.propTypes = {
  dataList: PropTypes.array,
  styles: PropTypes.object,
};

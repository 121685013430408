import axios from 'axios';

import {
  getOrderList,
  getOrderTypeList,
  getOrderInfo,
  registOrder,
  retrieveOrder,
  setOrderId,
  updateOrder,
  getOrderResultList,
  getOrderResultDetail,
  updateProprietyOrder,
  deleteProprietyOrder,
  deleteFormalOrder,
  getFormalOrderInfo,
  updateFormalOrder,
  checkOrder,
  getOrderSubList,
  getOrderSubAllList,
  setIskkUmu,
  setOrderNo,
  setOrderLastModifiedDate,
  setOrderModifiedCondition,
} from './actions';

import conf from './../../config/config.js';
import common from '../common/operations';
import {setAttachedFileType} from '../../common/fileOperations';
// import {API_CODE} from '../../components/common';

/**
 * 申込 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Object} addFields 追加設定するフィールド({フィールド名：値})
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetOrderList = (conditions, fields, sortParams, addFields) => {
  console.log('申込 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'Order/get', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getOrderList(ret.data.body.data ?
      ret.data.body.data : null);

    if (addFields && retData.orderList) {
      // 検索結果に項目追加
      const size = Object.keys(retData.orderList).length;
      for (let i = 0; i < size; i++) {
        Object.assign(retData.orderList[i], addFields);
      }
    }
    dispatch(retData);
    return ret;
  };
};

/**
 * 申込 取得（関数名指定）
 * @param {object} conditions 検索条件
 * @param {string[]|object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {string} type     実行する関数名
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @param {Object} [addFields] 追加設定するフィールド({フィールド名：値})
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetOrderTypeList = (
    conditions, fields, sortParams, type, subquery, addFields) => {
  console.log('申込 取得（関数名指定）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'Order/' + type, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const retData = getOrderTypeList(
      ret.data.body.data ? ret.data.body.data : null);

    if (addFields && retData.orderList) {
      // 検索結果に項目追加
      const size = Object.keys(retData.orderList).length;
      for (let i = 0; i < size; i++) {
        Object.assign(retData.orderList[i], addFields);
      }
    }
    dispatch(retData);

    return ret;
  };
};

/**
 * 申込情報 取得
 * @param {Object} conditions 検索条件(申込が一意になること)
 * @param {Object} fields 取得するフィールド名
 * @param {bool} isBillingDest 請求先取得フラグ true:取得する、false:取得しない
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doGetOrderInfo = (conditions, fields, isBillingDest = false) => {
  console.log('申込情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    body['sortParams'] = '';
    body['isBillingDest'] = isBillingDest;

    const ret = await axios.post(conf.API_URL + 'Order/getOrderInfo', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    if (data && data.length > 0) {
      dispatch(getOrderInfo(data[0]));
    } else {
      dispatch(getOrderInfo(data));
    }

    return ret;
  };
};

/**
 * 申込 ID指定取得
 * @param {string|string[]} id ID
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doRetrieveOrder = (id) => {
  console.log('申込 ID指定取得...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      Id: id,
    };

    const ret = await axios.post(conf.API_URL + 'Order/retrieve', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    dispatch(retrieveOrder(ret.data.body.data ? ret.data.body.data : null));
    return ret;
  };
};

/**
 * 申込 登録
 * @param {Object} data 登録する内容
 * @return {Promise<AxiosResponse<any>>} 登録結果
 */
const doRegistOrder = (data) => {
  console.log('申込 登録...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const ret = await axios.post(conf.API_URL + 'Order/regist', data, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(registOrder(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 申込 更新
 * @param {Object} conditions 検索条件
 * @param {Object} params 更新する内容
 * @param {string} stage MKS-apiのバリデーション定義参照
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doUpdateOrder = (conditions, params, stage) => {
  console.log('申込 更新...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      conditions: conditions,
      params: params,
      stage: stage,
    };

    const ret = await axios.post(conf.API_URL + 'Order/update', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateOrder(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 申込 ID設定
 *
 * @param {string} id 設定するID
 * @return {void} 設定結果
 */
const doSetOrderId = (id) => {
  console.log('申込ID 設定...');

  return async (dispatch) => {
    dispatch(setOrderId(id));
  };
};


/**
 * 申込情報のクリア
 * @return {void} 処理結果
 */
const doClearOrderList = () => {
  console.log('申込 クリア...');

  return async (dispatch) => {
    const retData = getOrderList(null);
    dispatch(retData);
  };
};

/**
 * 申込情報のクリア
 * @return {void} 処理結果
 */
const doClearOrderInfo = () => {
  console.log('申込 クリア...');

  return async (dispatch) => {
    const retData = getOrderInfo(null);
    dispatch(retData);
  };
};

/**
 * 申込情報のクリア
 * @return {void} 処理結果
 */
const doClearOrder = () => {
  console.log('申込 クリア...');

  return async (dispatch) => {
    const retData = retrieveOrder(null);
    dispatch(retData);
  };
};

/**
 * 可否判定結果 取得
 * @param {Object} conditions 検索条件(申込が一意になること)
 * @param {Object} fields 取得するフィールド名
 * @param {Object} sortParams 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doGetOrderResultList = (conditions, fields, sortParams) => {
  console.log('可否判定結果 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    const ret = await axios.post(conf.API_URL + 'Order/getOrderResultList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(getOrderResultList(data));

    return ret;
  };
};

/**
 * 可否判定結果詳細 取得
 * @param {Object} conditions 検索条件(申込が一意になること)
 * @param {Object} fields 取得するフィールド名
 * @param {Object} subquery サブクエリ
 *                          {fields: [...], table: 'xxx'}
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doGetOrderResultDetail = (conditions, fields, subquery) => {
  console.log('可否判定結果詳細 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'Order/getOrderResultDetail', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    if (data && data.length > 0) {
      dispatch(getOrderResultDetail(data[0]));
    } else {
      dispatch(getOrderResultDetail(data));
    }

    return ret;
  };
};

/**
 * 可否判定結果クリア
 * @return {void} 処理結果
 */
const doClearOrderResultList = () => {
  console.log('可否判定結果 クリア...');

  return async (dispatch) => {
    dispatch(getOrderResultList(null));
  };
};

/**
 * 可否判定結果詳細クリア
 * @return {void} 処理結果
 */
const doClearOrderResultDetail = () => {
  console.log('可否判定結果詳細 クリア...');

  return async (dispatch) => {
    dispatch(getOrderResultDetail(null));
  };
};

/**
 * 可否判定申込 登録/更新
 * @param {string} path 可否判定申込のAPI名(必須)
 * @param {Object} body 検索条件(必須)
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doUpdateProprietyOrder = (path, body) => {
  console.log('可否判定申込 登録/更新...');

  if (!path) {
    return;
  }

  return async (dispatch, getState) => {
    const {auth} = getState();
    const ret = await axios.post(conf.API_URL + 'ApiPropriety/' + path, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateProprietyOrder(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 可否判定申込 削除
 * @param {array} orderConditions 申込削除条件
 * @param {array} contractDntConditions 契約電柱削除条件
 * @param {array} senStbConditions 線設備削除条件
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doDeleteProprietyOrder = (
    orderConditions, contractDntConditions, senStbConditions) => {
  console.log('可否判定申込 削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        orderConditions: orderConditions,
        contractDntConditions: contractDntConditions,
        senStbConditions: senStbConditions,
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/deleteProprietyOrder', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(deleteProprietyOrder(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 可否判定申込 基本情報保存
 * @param {object} conditions 検索条件(更新時のみ)
 * @param {object} orderInfo 登録・更新情報(必須)
 * @param {array} rootFiles ルート図ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} horizonFiles 平面図ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} specFiles 仕様書ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} otherFiles その他ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} pictureFiles 写真ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} deleteFiles 削除ファイル一覧(サーバー未登録のファイル含む)
 * @param {object} mainContractDnt 代表電柱登録情報
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveProprietyOrder = (conditions, orderInfo,
    rootFiles, horizonFiles, specFiles, otherFiles,
    pictureFiles, deleteFiles, mainContractDnt) => {
  console.log('可否判定申込 基本情報保存...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const _fileInfo = {
      uuid: '',
      uploadFiles: [],
      deleteFileKeys: [],
    };

    // S3のバケットに未登録のファイルを一時アップロード
    // attachedFilesの中身はfileOperations.setAttachedFileDataの戻り値と同じ
    const attachedFiles = rootFiles.concat(horizonFiles)
        .concat(specFiles).concat(otherFiles).concat(pictureFiles)
        .filter((data) => !data.ContentDocumentId);
    let uploadResult = {};
    if (attachedFiles.length > 0) {
      uploadResult =
        await common.doUploadToS3Common(attachedFiles, getState, dispatch);
      _fileInfo['uuid'] = uploadResult.uuid;// API実行ごとにuuid発行
      _fileInfo['uploadFiles'] = setAttachedFileType(attachedFiles, uploadResult.uploadFiles);
    }
    // 削除ファイルのId一覧を生成
    _fileInfo['deleteFileKeys'] =
      deleteFiles.filter((file) => !!file.ContentDocumentId)
          .map((file) => file.ContentDocumentId);

    let body = {};
    if (conditions) {
      // 更新
      body = {
        record: {
          conditions: conditions,
          order: orderInfo,
          fileInfo: _fileInfo,
          mainContractDnt: mainContractDnt, // 代表電柱
          stage: 'PROPRIETY_UPDATE',
        },
      };
    } else {
      // 登録
      body = {
        record: {
          order: orderInfo,
          fileInfo: _fileInfo,
          mainContractDnt: mainContractDnt, // 代表電柱
          stage: 'PROPRIETY_INSERT',
        },
      };
    }

    const ret = await axios.post(conf.API_URL + 'ApiPropriety/saveProprietyOrder', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    // 一時アップロードが失敗でファイル登録成功時、エラー情報置き換え
    common.doCheckS3UploadResult(uploadResult, ret);

    dispatch(updateProprietyOrder(
      Object.hasOwnProperty.call(ret.data.body, 'data') ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 本申込 削除
 * @param {array} orderConditions 申込削除条件
 * @param {array} proprietyOrderConditions 可否判定申込更新条件
 * @param {array} contractDntConditions 契約電柱削除条件
 * @param {array} senStbConditions 線設備削除条件
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doDeleteFormalOrder = (
    orderConditions, proprietyOrderConditions,
    contractDntConditions, senStbConditions) => {
  console.log('本申込 削除...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {
      record: {
        orderConditions: orderConditions,
        proprietyOrderConditions: proprietyOrderConditions,
        contractDntConditions: contractDntConditions,
        senStbConditions: senStbConditions,
      },
    };

    const ret = await axios.post(conf.API_URL + 'ApiFormal/deleteFormalOrder', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(deleteFormalOrder(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};


/**
 * 本申込情報 取得
 * 本申込があれば、本申込の申込情報を返す
 * 本申込がなければ、可否判定の申込情報を本申込の形式に直して返す
 * @param {Object} conditions 検索条件(申込が一意になること)
 * @param {Object} fields 取得するフィールド名
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doGetFormalOrderInfo = (conditions, fields) => {
  console.log('本申込情報 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    body['sortParams'] = '';

    const ret = await axios.post(conf.API_URL + 'Order/getFormalOrderInfo', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    if (data && data.length > 0) {
      dispatch(getFormalOrderInfo(data[0]));
    } else {
      dispatch(getFormalOrderInfo(data));
    }

    return ret;
  };
};

/**
 * 本申込 登録/更新
 * @param {string} path 本申込のAPI名(必須)
 * @param {Object} body 検索条件(必須)
 * @param {object[]} [attachFiles] 添付ファイル一覧(省略可)
 * @param {object[]} [deleteFiles] 削除ファイル一覧(省略可)
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doUpdateFormalOrder = (path, body, attachFiles, deleteFiles) => {
  console.log('本申込 登録/更新...');

  if (!path) {
    return;
  }

  return async (dispatch, getState) => {
    // ファイルの登録/削除があった場合、bodyにファイル情報を追加
    const fileInfo =
      await fileUpload(getState, dispatch, attachFiles, deleteFiles);

    if (fileInfo) {
      body.fileInfo = fileInfo;
    }

    const ret = await axios.post(conf.API_URL + 'ApiFormal/' + path, body);
    dispatch(updateFormalOrder(ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 添付ファイルのアップロードとファイル情報の生成
 *
 * @param {Function} getState Reduxストア取得用
 * @param {Function} dispatch Redux Dispatch用
 * @param {object[]} [attachFiles] 添付ファイル一覧
 * @param {object[]} [deleteFiles] 削除ファイル一覧
 * @return {object} ファイル情報
 */
const fileUpload = async (
    getState, dispatch, attachFiles = [], deleteFiles = []) => {
  // 添付ファイルを未アップロードのものだけに絞り込む
  const targetFiles = attachFiles.filter((data) => !data.ContentDocumentId);

  // 登録ファイルの削除ファイルも指定されていない場合は処理終了
  if (targetFiles.length == 0 && deleteFiles.length == 0) {
    return null;
  }

  const fileInfo = {
    uuid: '',
    uploadFiles: [],
    deleteFileKeys: [],
  };

  // 添付ファイルが存在する場合はファイルをアップロードして結果を設定
  if (targetFiles.length > 0) {
    const result =
      await common.doUploadToS3Common(targetFiles, getState, dispatch);
    fileInfo.uuid = result.uuid;//
    fileInfo.uploadFiles =
      setAttachedFileType(targetFiles, result.uploadFiles);
  }

  // 削除ファイルのId一覧を設定
  fileInfo.deleteFileKeys =
    deleteFiles.filter((file) => !!file.ContentDocumentId)
        .map((file) => file.ContentDocumentId);

  return fileInfo;
};

/**
 * 申込情報入力チェック
 * @param {string} path API名
 * @param {Object} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doCheckOrder = (path, conditions) => {
  console.log('申込情報入力チェック...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    if (!path) {
      return;
    }

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    const ret = await axios.post(conf.API_URL + 'Order/' + path, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(checkOrder(data));

    return ret;
  };
};

/**
 * 申込情報入力チェッククリア
 * @param {string} path API名
 * @param {Object} conditions 検索条件
 * @return {Promise<AxiosResponse<any>>} 取得結果
 */
const doClearCheckOrder = () => {
  console.log('申込情報入力チェッククリア...');
  return async (dispatch) => {
    dispatch(checkOrder(null));
  };
};

/**
 * 申込 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} [sortParams] 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetOrderSubList = (conditions, fields, sortParams, subquery) => {
  console.log('申込、サブクエリ 取得...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'Order/getSub', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(getOrderSubList(data));

    return ret;
  };
};

/**
 * 移設依頼申込 取得
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} [sortParams] 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetIsetuIraiOrderSubList =
  (conditions, fields, sortParams, subquery) => {
    console.log('移設依頼申込、サブクエリ 取得...');
    return async (dispatch, getState) => {
      const {auth} = getState();

      const body = {};
      if (conditions) {
        body['conditions'] = conditions;
      }

      if (fields) {
        body['fields'] = fields;
      }

      if (sortParams) {
        body['sortParams'] = sortParams;
      }

      if (subquery) {
        body['subquery'] = subquery;
      }

      const ret = await axios.post(conf.API_URL + 'Order/getIsetuIraiSub', body, {
        headers: {Authorization: auth.loginInfo.idToken},
      });

      const data = ret.data.body.data ? ret.data.body.data : null;
      dispatch(getOrderSubList(data));

      return ret;
    };
  };

/**
 * 申込 取得（アクセス制限なし）
 * @param {Object} conditions 検索条件
 * @param {Object} fields 取得するフィールド名
 * @param {Object} [sortParams] 並び替え対象(1はASC、-1はDESC)
 *                            (未指定時：表示順:ASC)
 * @param {Array} [subquery] サブクエリ設定
 *                           subquery.conditions：検索条件
 *                           subquery.fields：取得フィールド(配列)
 *                           subquery.table：取得テーブル
 * @return {Promise<AxiosResponse<any>>} 取得結果一覧
 */
const doGetOrderSubAllList = (conditions, fields, sortParams, subquery) => {
  console.log('申込、サブクエリ 取得（アクセス制限なし）...');
  return async (dispatch, getState) => {
    const {auth} = getState();

    const body = {};
    if (conditions) {
      body['conditions'] = conditions;
    }

    if (fields) {
      body['fields'] = fields;
    }

    if (sortParams) {
      body['sortParams'] = sortParams;
    }

    if (subquery) {
      body['subquery'] = subquery;
    }

    const ret = await axios.post(conf.API_URL + 'Order/getOrderList', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    const data = ret.data.body.data ? ret.data.body.data : null;
    dispatch(getOrderSubAllList(data));

    return ret;
  };
};

/**
 * 申込一覧情報のクリア(アクセス制限なし)
 * @return {void} 処理結果
 */
const doClearOrderSubAllList = () => {
  console.log('申込一覧 クリア(アクセス制限なし)...');

  return async (dispatch) => {
    const retData = getOrderSubAllList(null);
    dispatch(retData);
  };
};

/**
 * 申込一覧情報のクリア
 * @return {void} 処理結果
 */
const doClearOrderSubList = () => {
  console.log('申込一覧 クリア...');

  return async (dispatch) => {
    const retData = getOrderSubList(null);
    dispatch(retData);
  };
};

/**
 * 本申込情報のクリア
 * @return {void} 処理結果
 */
const doClearFormalOrderInfo = () => {
  console.log('本申込情報 クリア...');

  return async (dispatch) => {
    const retData = getFormalOrderInfo(null);
    dispatch(retData);
  };
};

/**
 * 一束化有無 設定
 * 申込に紐づく契約電柱の中に単独以外の一束化区分があればtrueを設定
 * 上記以外はfalseを設定
 *
 * @param {bool} isIskk
 * @return {void} 設定結果
 */
const doSetIskkUmu = (isIskk) => {
  console.log('一束化有無 設定...');

  return async (dispatch) => {
    dispatch(setIskkUmu(isIskk));
  };
};

/**
 * 申込番号 設定
 *
 * @param {string} orderNo 設定する申込番号
 * @return {void} 設定結果
 */
const doSetOrderNo = (orderNo) => {
  console.log('申込NO 設定...');

  return async (dispatch) => {
    dispatch(setOrderNo(orderNo));
  };
};

/**
 * 訂正申込 基本情報保存
 * @param {object} conditions 検索条件(更新時のみ)
 * @param {object} orderInfo 検索条件(必須)
 * @param {array} rootFiles ルート図ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} horizonFiles ルート図ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} specFiles ルート図ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} otherFiles ルート図ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} pictureFiles 写真ファイル一覧(サーバー登録済のファイル含む)
 * @param {array} deleteFiles 削除ファイル一覧(サーバー未登録のファイル含む)
 * @param {object} mainContractDnt 代表電柱登録情報
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doSaveCorrectOrder = (conditions, orderInfo,
    rootFiles, horizonFiles, specFiles, otherFiles,
    pictureFiles, deleteFiles, mainContractDnt) => {
  console.log('訂正申込 基本情報保存...');

  return async (dispatch, getState) => {
    const {auth} = getState();

    const _fileInfo = {
      uuid: '',
      uploadFiles: [],
      deleteFileKeys: [],
    };

    // S3のバケットに未登録のファイルを一時アップロード
    // attachedFilesの中身はfileOperations.setAttachedFileDataの戻り値と同じ
    const attachedFiles = rootFiles.concat(horizonFiles)
        .concat(specFiles).concat(otherFiles).concat(pictureFiles)
        .filter((data) => !data.ContentDocumentId);
    let uploadResult = {};
    if (attachedFiles.length > 0) {
      uploadResult =
        await common.doUploadToS3Common(attachedFiles, getState, dispatch);
      _fileInfo['uuid'] = uploadResult.uuid;// API実行ごとにuuid発行
      _fileInfo['uploadFiles'] = setAttachedFileType(attachedFiles, uploadResult.uploadFiles);
    }
    // 削除ファイルのId一覧を生成
    _fileInfo['deleteFileKeys'] =
      deleteFiles.filter((file) => !!file.ContentDocumentId)
          .map((file) => file.ContentDocumentId);

    let body = {};
    if (conditions) {
      // 更新
      body = {
        record: {
          conditions: conditions,
          order: orderInfo,
          stage: 'CORRECTORDER_UPDATE',
          fileInfo: _fileInfo,
          mainContractDnt: mainContractDnt,
        },
      };
    } else {
      // 登録
      body = {
        record: {
          order: orderInfo,
          fileInfo: _fileInfo,
          mainContractDnt: mainContractDnt,
        },
      };
    }

    const ret = await axios.post(conf.API_URL + 'ApiCorrectOrder/saveCorrectOrder', body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });

    // 一時アップロードが失敗でファイル登録成功時、エラー情報置き換え
    common.doCheckS3UploadResult(uploadResult, ret);

    dispatch(updateProprietyOrder(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};


/**
 * 訂正申込 登録/更新
 * @param {string} path 訂正申込のAPI名(必須)
 * @param {Object} body 検索条件(必須)
 * @return {Promise<AxiosResponse<any>>} 処理結果
 */
const doUpdateCorrectOrder = (path, body) => {
  console.log('訂正申込 登録/更新...');

  if (!path) {
    return;
  }

  return async (dispatch, getState) => {
    const {auth} = getState();
    const ret = await axios.post(conf.API_URL + 'ApiCorrectOrder/' + path, body, {
      headers: {Authorization: auth.loginInfo.idToken},
    });
    dispatch(updateProprietyOrder(
      ret.data.body.data ? ret.data.body.data : null));

    return ret;
  };
};

/**
 * 申込の最終更新日時を設定
 * 排他チェックに使用する
 *
 * @param {string} orderLastModifiedDate 申込の最終更新日時
 * @return {void} 設定結果
 */
const doSetOrderLastModifiedDate = (orderLastModifiedDate) => {
  return async (dispatch) => {
    dispatch(setOrderLastModifiedDate(orderLastModifiedDate));
  };
};

/**
 * 申込のIdと最終更新日時を設定
 * 排他チェックに使用する
 *
 * @param {string} orderId 申込のId
 * @param {string} orderLastModifiedDate 申込の最終更新日時
 * @return {void} 設定結果
 */
const doSetOrderModifiedCondition = (orderId, orderLastModifiedDate) => {
  return async (dispatch) => {
    dispatch(setOrderModifiedCondition({
      Id: orderId, LastModifiedDate: orderLastModifiedDate,
    }));
  };
};

export default {
  doGetOrderList,
  doGetOrderTypeList,
  doRetrieveOrder,
  doGetOrderInfo,
  doRegistOrder,
  doUpdateOrder,
  doSetOrderId,
  doClearOrderList,
  doClearOrder,
  doClearOrderInfo,
  doGetOrderResultList,
  doGetOrderResultDetail,
  doClearOrderResultList,
  doClearOrderResultDetail,
  doUpdateProprietyOrder,
  doDeleteProprietyOrder,
  doSaveProprietyOrder,
  doDeleteFormalOrder,
  doGetFormalOrderInfo,
  doUpdateFormalOrder,
  doCheckOrder,
  doGetOrderSubList,
  doGetIsetuIraiOrderSubList,
  doClearOrderSubList,
  doGetOrderSubAllList,
  doClearOrderSubAllList,
  doClearFormalOrderInfo,
  doSetIskkUmu,
  doClearCheckOrder,
  doSaveCorrectOrder,
  doUpdateCorrectOrder,
  doSetOrderNo,
  doSetOrderLastModifiedDate,
  doSetOrderModifiedCondition,
};

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';

import * as wijmo from '@grapecity/wijmo';
import * as wjgGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Popup, ComboBox, InputNumber} from '@grapecity/wijmo.react.input';
import {FlexGridColumnGroup, FlexGridCellTemplate} from '@grapecity/wijmo.react.grid';

import {getCodeFromGeneralPurposeMap, getComboList, checkPhotoExists,
  getComboName, getErrorCode, API_CODE, isSameContractDnt,
  checkMainDntNoNWZgsyo, checkMainDntNoInContractDnts,
  getResponseResult,
  createDataMapByComboList,
} from '../../../../../../common/common.js';
import {correlationCorrectCheck} from '../../../../../../common/correlationCheck.js';
import {commonOperations} from '../../../../../../reducks/common';
import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {contractDntOperations} from '../../../../../../reducks/contractDnt';
import MainContainer from '../../../../../organisms/MainContainer.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import CustomFlexGrid, {createCollectionView} from '../../../../../molecules/CustomFlexGrid.js';
import CommonTheme from '../../../../../../Theme.js';
import CustomCard from '../../../../../molecules/CustomCard.js';
import {AttachedPicturesSenCorrect, AttachTypeCorrect, ClosestDrStbDntCorrect,
  TsnHksnCorrect, DrHksnHouseSideRikakKkhCorrect} from '../../../../../molecules/HelpComponent.js';
import SelectPoleNo from '../../../../../organisms/SelectPoleNo.js';
import * as validateRule from '../../../../../validateRule.js';
import {orderDntFields} from '../../../../../../common/SFFields';
import {renderCheckbox, renderSelect} from '../../../../../atoms/CustomPart.js';
import {createSearchList} from '../../../../../../common/ComboParts.js';
import WarningMessageArea from '../../../../../molecules/WarningMessageArea.js';
import withMediaQuery from '../../../../../atoms/withMediaQuery.js';
import HelpIcon from '@material-ui/icons/Help';
import MuiTooltip from '../../../../../atoms/MuiTooltip.js';

const palette = CommonTheme.palette;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupPositive: {
    color: palette.primary.contrastText,
    background: [
      '-webkit-linear-gradient(top, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
      'linear-gradient(to bottom, ' + palette.primary.light + ' 0%, ' + palette.primary.main + ' 100%)',
    ],
    borderColor: palette.primary.dark,
  },
  popupNegative: {
    color: '#000000',
    borderColor: palette.primary.dark,
  },
});

/** この画面で登録/更新を行うフィールド名 */
const addParams = {
  SerialNumber__c: null, // 通し番号
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  KyogaCategory__c: null, // 共架区分
  KyogaType__c: null, // 共架種別
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  AttachType__c: null, // 取付種別
  AttachSpotHeightSyunko__c: null, // 取付点高さ(m)/竣工
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakSyunko__c: null, // 最接近電力設備との離隔/離隔距離(m)/竣工
  TsnHksnUmu__c: null, // 通信引込線有無
  DrHksnKiknCrossRikakSynk__c: null, // 電力引込線との離隔(m)/径間途中,交差部分/竣工
  DrHksnHouseSideRikakKkhSynk__c: null, // 電力引込線との離隔確保/家屋側支持点付近/竣工
  Order__c: null, // 申込のId 更新しない
  KyogaZgsya__c: null, // 共架事業者(AccountのId) 更新しない
  TrsyaUserId__c: null, // 更新しない
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  MaintenanceKoziCompany__c: null, // 保守工事会社
  StbType__c: null, // 設備種別
  IskkCategory__c: null, // 一束化区分
};
const updateParams = {
  Id: null,
  SerialNumber__c: null, // 通し番号
  SzbtItiId__c: null, // 支持物位置ID
  StbKobetuId__c: null, // 設備個別ID
  SenroCode__c: null, // 線路コード
  SenroName__c: null, // 線路名
  SenroNameKana__c: null, // 線路名カナ
  DntCategory__c: null, // 電柱区分
  DntNo__c: null, // 電柱番号
  KyogaCategory__c: null, // 共架区分
  NWZgsyo__c: null, // 中電ＮＷ事業所（汎用マスタの中電NW事業所のID）
  OldNWZgsyo__c: null, // 旧中電ＮＷ事業所
  AttachType__c: null, // 取付種別
  AttachSpotHeightSyunko__c: null, // 取付点高さ(m)/竣工
  ClosestDrStbName__c: null, // 最接近電力設備との離隔/設備名称
  ClosestDrStbRikakSyunko__c: null, // 最接近電力設備との離隔/離隔距離(m)/竣工
  TsnHksnUmu__c: null, // 通信引込線有無
  DrHksnKiknCrossRikakSynk__c: null, // 電力引込線との離隔(m)/径間途中,交差部分/竣工
  DrHksnHouseSideRikakKkhSynk__c: null, // 電力引込線との離隔確保/家屋側支持点付近/竣工
  UpsyaUserId__c: null,
  DntNoManualInput__c: null, // 電柱番号_自由入力
  K6KansnNo__c: null, // 6kV幹線No
  K6Bunk1__c: null, // 6kV分岐1
  K6Bunk2__c: null, // 6kV分岐2
  K6Bunk3__c: null, // 6kV分岐3
  K22SzbtNo__c: null, // 22kV支持物番号
  K22GatiCategory__c: null, // 22kV架地区分
  K6K22HeigaCategory__c: null, // 6kV22kV併架区分
  MaintenanceKoziCompany__c: null, // 保守工事会社
};

/**
 * 訂正取付申込（線設備入力：申込電柱）
 * 画面ID:1603
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isModified: false,
      modalOpen: false,
      ybdsType: 'A', // A or AM
      editItem: null,
      warningMessages: [],
    };
    this.currentCtx = null;
    this.popup = null;
    this.gridRef = React.createRef();
    this.drHksnHouseSideRikakKkhRef = React.createRef();
    this.editPopup = this.editPopup.bind(this);
    this.onFormatItemHandler = this.onFormatItemHandler.bind(this);
    this.filterChangingHandler = this.filterChangingHandler.bind(this);

    // グリッド項目初期化
    this.items = {};
    for (const key of orderDntFields) {
      this.items[key] = '';
    }

    // 編集ダイアログ用
    this.editedAttachType = null; // 取付種別
    this.editedAttachSpotHeight = null; // 取付点高さ（m）
    this.editedClosestDrStbName = null; // 設備名称
    this.editedClosestDrStbRikakOrder = null; // 離隔距離（m）
    this.editedTsnHksnUmu = null; // 通信引込線/通信引込線の有無
    this.editedDrHksnKiknCrossingRikak = null; // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
    this.editedDrHksnHouseSideRikakKkh = false; // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）

    // 一覧のヘッダーにヘルプボタン追加
    // 取付種別
    this.headerAttachTypeTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <AttachTypeCorrect/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 添付写真
    this.headerAttachedPicturesTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <AttachedPicturesSenCorrect/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 最接近電力設備との離隔
    this.headerClosestDrStbDntTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <ClosestDrStbDntCorrect/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // 通信引込線
    this.headerTsnHksnTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <TsnHksnCorrect/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;
    // ②電力引込線との離隔確保（家屋側支接点付近）
    this.headerDrHksnHouseSideRikakKkhTemplate = (cell) =>
      <React.Fragment>
        {cell.col.header}
        <MuiTooltip content={
          <DrHksnHouseSideRikakKkhCorrect/>
        }>
          <HelpIcon fontSize="small"/>
        </MuiTooltip>
      </React.Fragment>;

    // 一覧画面の入力チェック
    this.getError = (item, prop, parsing) => {
      // 電柱番号
      if (prop == 'Dsp_DntNo__c') {
        let str = '';
        if (item[prop] != '未設定') {
          str = item[prop];
        }
        let error = validateRule.required4(str);
        if (error) {
          return error;
        }
      }
      // 取付種別,取付点高さ（m）,
      // 最接近電力設備との離隔/設備名称,最接近電力設備との離隔/離隔距離（m）,
      // 通信引込線/通信引込線の有無
      if (['AttachType__c', 'AttachSpotHeightSyunko__c',
        'ClosestDrStbName__c', 'ClosestDrStbRikakSyunko__c',
        'TsnHksnUmu__c'].includes(prop)) {
        let error = validateRule.required4(
            item[prop]);
        if (error) {
          return error;
        }
      }
      // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分） 通信引込線の有無が有の場合、必須。
      if (['DrHksnKiknCrossRikakSynk__c'].includes(prop)) {
        if (item.TsnHksnUmu__c == '1') {
          let error = validateRule.required4(
              item[prop]);
          if (error) {
            return error;
          }
        }
      }
      // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近） 通信引込線の有無が有の場合、必須。
      if (prop == 'DrHksnHouseSideRikakKkhSynk__c') {
        if (item.TsnHksnUmu__c == '1' &&
          item.DrHksnHouseSideRikakKkhSynk__c !== true) {
          // CE0070
          return '通信引込線の家屋側支持点付近における離隔確保にチェックがありません。';
        }
      }

      // データ範囲チェック
      // 取付点高さ（m）
      if (['AttachSpotHeightSyunko__c'].includes(prop)) {
        // CE0026
        if (item[prop] < 2.5 || item[prop] > 99.9) {
          return '2.5～99.9の範囲内で入力してください';
        }
      }
      // 最接近電力設備との離隔/離隔距離（m）
      if (['ClosestDrStbRikakSyunko__c'].includes(prop)) {
        // CE0026
        if (item[prop] < 0.01 || item[prop] > 9.99) {
          return '0.01～9.99の範囲内で入力してください';
        }
      }
      if (item.TsnHksnUmu__c == '1') {
        // ①電力引込線との離隔（m）（径間途中，交差部分）
        if (['DrHksnKiknCrossRikakSynk__c'].includes(prop)) {
          // CE0026
          if (item[prop] < 0.01 || item[prop] > 9.99) {
            return '0.01～9.99の範囲内で入力してください';
          }
        }
      }
      return null;
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    try {
      // 画面に表示する保守工事会社一覧を取得
      const accountConditions={
        'RecordType.DeveloperName': 'MaintenanceKoziCompany',
        'DeleteFlag__c': false,
      };
      const accountFields=['Id', 'Name', 'KyogaZgsyaCode__c',
        'KyogaZgsyoCode__c', 'MainteKoziCompanyCode__c',
      ];
      let response =
        await this.props.doGetAccountMasterList(
            accountConditions, accountFields);
      let errorCode = getErrorCode(response);
      if (errorCode != API_CODE.SUCCESS) {
        this.sysErr();
        return;
      }
      const _accountList = this.props.accountMasterList ?
        this.props.accountMasterList : [];
      const mainteKoziCompanyList = [];
      for (const _account of _accountList) {
        let _mainteKoziCompany = {};
        Object.assign(_mainteKoziCompany, _account);
        _mainteKoziCompany['DspName'] =
          _mainteKoziCompany.MainteKoziCompanyCode__c + ' ' + _mainteKoziCompany.Name;
        mainteKoziCompanyList.push(_mainteKoziCompany);
      }
      this.props.doSetAccountMasterList(mainteKoziCompanyList);

      // 検索
      await this.searchData();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    try {
      this.props.doClearOrderContractDnt();
      this.props.doClearAccountMasterList();
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 一覧画面に戻る
   */
  gotoListGmn = () => {
    this.props.history.push(
        {pathname: '/CorrectAttachOrderList'},
        null, this.props.history.option.CLEAR,
    );
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 編集ダイアログ初期化
   * @param {object} ctl
   */
  initializeEditPopup(ctl) {
    this.popup = ctl;
  }

  /**
   * 編集ダイアログ 取付種別
   * @param {*} ctl
   */
  initializeEditedAttachType(ctl) {
    this.editedAttachType = ctl;
  }


  /**
   * 編集ダイアログ 取付点高さ
   * @param {*} ctl
   */
  initializeEditedAttachSpotHeight(ctl) {
    this.editedAttachSpotHeight = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/設備名称
   * @param {*} ctl
   */
  initializeEditedClosestDrStbName(ctl) {
    this.editedClosestDrStbName = ctl;
  }

  /**
   * 編集ダイアログ
   * 最接近電力設備との離隔/離隔距離(m)/申請時
   * @param {*} ctl
   */
  initializeEditedClosestDrStbRikakOrder(ctl) {
    this.editedClosestDrStbRikakOrder = ctl;
  }


  /**
   * 編集ダイアログ
   * 通信引込線有無
   * @param {*} ctl
   */
  initializeEditedTsnHksnUmu(ctl) {
    this.editedTsnHksnUmu = ctl;
  }

  /**
   * 編集ダイアログ
   * 通信引込線有無
   * @param {*} sender
   */
  changeTsnHksnUmu(sender) {
    if (sender) {
      // 通信引込線の有無で以下の項目の活性非活性を変える
      // 電力引込線との離隔(m)/径間途中,交差部分, 電力引込線との離隔確保/家屋側支持点付近
      let _disabled = true;
      if (sender.selectedValue == '1') {
        _disabled = false || this.state.isDisabled;
      }
      this.editedDrHksnKiknCrossingRikak.isDisabled = _disabled;
      this.drHksnHouseSideRikakKkhRef.current.disabled = _disabled;
    }
  }

  /**
   * 編集ダイアログ
   * 電力引込線との離隔(m)/径間途中,交差部分
   * @param {*} ctl
   */
  initializeEditedDrHksnKiknCrossingRikak(ctl) {
    this.editedDrHksnKiknCrossingRikak = ctl;
  }

  /**
   * 編集ダイアログ
   * 電力引込線との離隔確保/家屋側支持点付近
   * @param {*} e
   */
  changeDrHksnHouseSideRikakKkh(e) {
    if (e.target) {
      this.editedDrHksnHouseSideRikakKkh = e.target.checked;
    }
  }

  /**
   * 編集ダイアログ処理
   * @param {object} grid
   * @param {object} e
   */
  editPopup(grid, e) {
    try {
      const ht = grid.hitTest(e);
      if (ht.panel === grid.rowHeaders) {
        let _editItem = grid.rows[ht.row].dataItem;
        this.setState({editItem: _editItem});
        this.editedAttachType.selectedValue = _editItem.AttachType__c; // 取付種別
        this.editedAttachSpotHeight.value = _editItem.AttachSpotHeightSyunko__c; // 取付点高さ（m）
        this.editedClosestDrStbName.selectedValue =
          _editItem.ClosestDrStbName__c; // 設備名称
        this.editedClosestDrStbRikakOrder.value =
          _editItem.ClosestDrStbRikakSyunko__c; // 離隔距離（m）
        this.editedTsnHksnUmu.selectedValue = _editItem.TsnHksnUmu__c; // 通信引込線/通信引込線の有無
        this.editedDrHksnKiknCrossingRikak.value =
          _editItem.DrHksnKiknCrossRikakSynk__c; // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
        this.editedDrHksnHouseSideRikakKkh =
          _editItem.DrHksnHouseSideRikakKkhSynk__c; // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
        this.drHksnHouseSideRikakKkhRef.current.checked =
        _editItem.DrHksnHouseSideRikakKkhSynk__c;
        this.popup.show(true, async (s) => {
          if (s.dialogResult === 'wj-hide-ok') {
            (grid.collectionView).editItem(_editItem);
            _editItem.AttachType__c = this.editedAttachType.selectedValue; // 取付種別
            _editItem.AttachSpotHeightSyunko__c =
              this.editedAttachSpotHeight.value; // 取付点高さ（m）
            _editItem.ClosestDrStbName__c =
              this.editedClosestDrStbName.selectedValue; // 設備名称
            _editItem.ClosestDrStbRikakSyunko__c =
              this.editedClosestDrStbRikakOrder.value; // 離隔距離（m）
            _editItem.TsnHksnUmu__c = this.editedTsnHksnUmu.selectedValue; // 通信引込線/通信引込線の有無
            _editItem.DrHksnKiknCrossRikakSynk__c =
              this.editedDrHksnKiknCrossingRikak.value; // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
            _editItem.DrHksnHouseSideRikakKkhSynk__c =
              this.editedDrHksnHouseSideRikakKkh; // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
            (grid.collectionView).commitEdit();
          }
          grid.focus();
        });
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 電柱一括追加ボタン
   */
  doAddDntList = () => {
    this.setState({modalOpen: true, ybdsType: 'AM'});
  }

  /**
   * 径間入力ボタン押下
   */
  doMoveKeikan = async () => {
    try {
      // 申込ステータスが作成中以外は参照なので、入力チェックしない
      if (this.props.order &&
          this.props.order.OrderStatus__c != '00') {
        this.doNext();
        return;
      }

      // 編集ありの場合、ユーザーに確認して次画面へ遷移
      // 編集していても保存しない
      const grid = this.gridRef.current.getGrid();
      if (
        ((grid.collectionView)?.itemsAdded &&
         (grid.collectionView).itemsAdded.length > 0) ||
        ((grid.collectionView)?.itemsEdited &&
         (grid.collectionView).itemsEdited.length > 0) ||
        ((grid.collectionView)?.itemsRemoved &&
         (grid.collectionView).itemsRemoved.length > 0)) {
        this.props.doShowMessage({
          message: {
            id: 'CW0013',
          },
          action: async () => {
            await this.doCheckInput();
          },
        });
        return;
      } else {
        await this.doCheckInput();
        return;
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * 画面遷移時の入力チェック
   */
  doCheckInput = async () => {
    const messages = this.state.warningMessages;

    // 警告チェック
    let hasError = false;
    if (this.props.setGmnWarning) {
      if (Object.hasOwnProperty.call(this.props.setGmnWarning, '1603')) {
        hasError = (this.props.setGmnWarning[1603] != undefined ?
              this.props.setGmnWarning[1603] : false);
      }
    }


    // 入力チェックエラー
    if (hasError) {
      // メッセージが警告だけの場合は操作可能にする
      if (messages.filter((data) => data.id.indexOf('PW') == -1).length == 0) {
        await this.props.doSetGmnWarning('1603', false);
        // 警告表示して次画面へ遷移
        this.props.doShowMessage({
          message: messages,
          action: () => {
            this.doNext();
          },
        });
        return;
      }
      // 警告表示して次画面へ遷移
      this.props.doShowMessage({
        message: {id: 'CW0157', values: ['申込']},
        action: () => {
          this.doNext();
        },
        type: 'warn',
      });
      // }
      return;
    }

    // 入力チェックOK
    this.doNext();
  }

  /**
   * 次画面へ遷移
   */
  doNext = async () => {
    if (this.gridRef && this.gridRef.current) {
      this.gridRef.current.clearChanges();
    }
    this.props.history.push({pathname: '/CorrectAttachSenStb'});
  }

  /**
   * 画面上で非活性になっている項目のデータを保存しないため、
   * 該当データをクリアする
   * @param {object} item 登録/更新データ
   */
  clearDisabledItem = (item) => {
    // 通信引込線の有無が無の場合、通信引込線の1～4は空
    if (item['TsnHksnUmu__c'] != '1') {
      item['DrHksnKiknCrossingRikak__c'] = 0;
      item['DrHksnHouseSideRikakKkh__c'] = false;
    }
  }

  /**
   * 申込電柱の登録/更新処理
   */
  insertOrUpdate = async () => {
    const {order, values} = this.props;
    const contractDntList = [];
    Object.assign(contractDntList, this.gridRef.current.getSourceCollection());
    const saveContractDntList = [];
    const updateConditions = [];
    let serialNumber = 0;
    // Dsp_DntNo__c,Dsp_SenroName__cはSF属性が数式なので保存しなくてよい
    for (const dnt of contractDntList) {
      // 一覧は1から通し番号設定
      serialNumber++;
      if (!dnt.Id) {
        // 登録項目設定
        const insertData = {};
        for (const key in addParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            insertData[key] = dnt[key];
          }
        }
        insertData['MaintenanceKoziCompany__c'] = values['MaintenanceKoziCompany__c'];
        insertData['IskkCategory__c'] = '1';
        insertData['StbType__c'] = '1'; // 線設備
        insertData['SerialNumber__c'] = serialNumber;
        insertData['Order__c'] = order.Id; // 申込と契約電柱紐づけ
        insertData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定
        insertData['KyogaZgsya__c'] = order.OrderKyogaZgsya__c;
        this.clearDisabledItem(insertData);
        saveContractDntList.push(insertData);
      } else {
        // 更新項目設定
        const updateData = {};
        for (const key in updateParams) {
          if (Object.hasOwnProperty.call(dnt, key)) {
            updateData[key] = dnt[key];
          }
        }
        updateData['MaintenanceKoziCompany__c'] = values['MaintenanceKoziCompany__c'];
        updateData['SerialNumber__c'] = serialNumber;
        updateData['KyogaType__c'] = order.KyogaType__c; // 申込と同じ共架種別を設定
        this.clearDisabledItem(updateData);
        saveContractDntList.push(updateData);
        // 通し番号を変更したものを更新対象にする
        updateConditions.push({
          Id: dnt.Id,
          LastModifiedDate: dnt.LastModifiedDate,
        });
      }
    }

    // 削除
    const deleteConditions = [];
    const data = this.gridRef.current.itemsAllChanged();
    if (data.itemsRemoved.length > 0) {
      for (const row of data.itemsRemoved) {
        if (row.Id) {
          deleteConditions.push({
            Id: row.Id,
            LastModifiedDate: row.LastModifiedDate,
          });
        }
      }
    }
    // 確認事項1～3、一時保存日更新用
    // 一時保存日はサーバー側で設定する
    const orderConditions = {
      Id: order.Id,
      LastModifiedDate: this.props.orderLastModifiedDate,
    };
    const updateOrder = {
      Id: order.Id,
      CheckJiko1__c: values['CheckJiko1__c'],
      CheckJiko2__c: values['CheckJiko2__c'],
      CheckJiko3__c: values['CheckJiko3__c'],
    };

    const resSave = await this.props.doSaveContractDnts(
        updateConditions, deleteConditions, saveContractDntList,
        orderConditions, updateOrder);
    const resResult = getResponseResult(resSave, ['申込電柱', '保存']);
    if (resResult.errorCode != API_CODE.SUCCESS) {
      return resResult;
    }

    if (this.gridRef) {
      this.gridRef.current.clearChanges();
    }
    return {success: true, messages: []};
  }

  /**
   * 検索処理
   */
  searchData = async () => {
    const {orderId, generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);

    // 申込IDに紐づく申込電柱を取得する
    const orderConditions = {
      'Id': orderId,
      'RecordType.DeveloperName': 'ContractDifferAttach',
    };
    const orderFields = ['Id', 'LastModifiedDate', 'OrderStatus__c', 'KoziPlaceMainDntNo__c',
      'KyogaType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c',
      'OrderNo__c', 'CheckJiko1__c', 'CheckJiko2__c', 'CheckJiko3__c',
      'MainDntNoNWZgsyo__r.Code__c', 'RecordType.DeveloperName', 'StbType__c'];
    const conditions = {
      'Order__c': orderId,
      'RecordType.DeveloperName': 'ContractDifferAttach',
    };
    const appSortParams = {
      SerialNumber__c: 1,
    };
    let response =
      await this.props.doGetOrderAndContractDntList(
          orderConditions, orderFields,
          conditions, orderDntFields, appSortParams, true);
    let errorCode = getErrorCode(response);
    if (errorCode != API_CODE.SUCCESS) {
      this.props.doShowMessage({
        message: {
          id: 'CE0052',
          values: ['申込電柱', '取得'],
        },
      });
      return false;
    }
    if (!this.props.order) {
      this.sysErr();
      return false;
    }

    for (const key of ['TtTtnbrAroundLengthSyunko__c', 'SerialNumber__c']) {
      this.items[key] = 0;
    }
    for (const key of
      ['AttachSpotHeightSyunko__c', 'AttachSpotHeightSyunko__c']) {
      this.items[key] = 0.0;
    }
    for (const key of
      ['ClosestDrStbRikakSyunko__c', 'DrHksnKiknCrossRikakSynk__c']) {
      this.items[key] = 0.00;
    }
    if (this.props.order) {
      for (const key of ['OrderNo__c', 'KyogaType__c', 'OrderKyogaZgsya__c', 'KyogaZgsyaCode__c', 'KyogaZgsyoCode__c']) {
        this.items[key] = this.props.order[key];
      }
    }
    this.items['DrHksnHouseSideRikakKkhSynk__c'] = false;
    // グリッド初期化
    const cv = createCollectionView(this.props.contractDntList,
        true, this.items, this.getError);
    this.gridRef.current.getGrid().itemsSource = cv;

    // 入力チェック
    let _warningMessages = [];
    this.inputCheck(_warningMessages);
    let isWarn = false;
    // メッセージが警告だけの場合はエラーにしない
    if (_warningMessages.filter((data) => data.id.indexOf('CW0067') == -1).length > 0) {
      isWarn = true;
    }

    this.setState({
      isDisabled: (this.props.order && this.props.order.OrderStatus__c ==
                  orderStatusCreate ? false : true) ||
                  this.props.referenceMode, // 作成中のみ編集可
      isModified: false,
      warningMessages: _warningMessages,
    });
    await this.props.doSetGmnWarning('1603', isWarn);
    return true;
  }

  /**
   * 入力チェック
   * @param {array} warningMessages
   */
  inputCheck = (warningMessages) => {
    const {generalPurposeMap} = this.props;
    const orderStatusCreate =
      getCodeFromGeneralPurposeMap(generalPurposeMap, 'OrderStatus00', 2);
    if (this.props.order &&
        this.props.order.OrderStatus__c != orderStatusCreate) {
      // 作成中のときだけ入力チェックする
      return;
    }

    // 電柱0件チェック
    if (this.props.contractDntList &&
      this.props.contractDntList.length == 0) {
      warningMessages.push({id: 'CE0019', values: ['申込電柱']});
      return;
    }

    // 保守工事会社選択チェック
    const result = this.props.contractDntList.filter((data) =>
      Object.hasOwnProperty.call(data, 'MaintenanceKoziCompany__c') &&
        data.MaintenanceKoziCompany__c == null);
    if (result.length > 0) {
      warningMessages.push({
        id: 'CE0027',
        values: ['保守工事会社'],
      });
    }

    // 必須チェック/桁数チェック/データ範囲チェック
    this.checkItemSen(this.props.contractDntList, warningMessages);
    // 相関チェック
    const correlationMessages =
      correlationCorrectCheck(this.props.order, this.props.contractDntList);
    for (const message of correlationMessages) {
      warningMessages.push(message);
    }

    // 契約電柱と取付種別のチェック
    // エネコムユーザはチェックしない
    if (this.props.attachTypeCheck) {
      // 取付種別が新規で契約電柱マスタに申込電柱が存在する場合、エラー
      if (this.props.attachTypeCheck.sinkiErrDnt) {
        for (const dnt of this.props.attachTypeCheck.sinkiErrDnt) {
          let dspDntNo = Object.hasOwnProperty.call(dnt, 'Dsp_DntNo__c') ?
            dnt.Dsp_DntNo__c : '';
          warningMessages.push({id: 'OE0194', values: [dspDntNo, dnt.SerialNumber__c]});
        }
      }
      // 取付種別が増架、張替で契約電柱マスタに申込電柱が存在しない場合、エラー
      if (this.props.attachTypeCheck.zougaErrDnt) {
        for (const dnt of this.props.attachTypeCheck.zougaErrDnt) {
          let dspDntNo = Object.hasOwnProperty.call(dnt, 'Dsp_DntNo__c') ?
            dnt.Dsp_DntNo__c : '';
          warningMessages.push({id: 'PE0082', values: [dspDntNo, dnt.SerialNumber__c]});
        }
      }
    }

    // 代表電柱同一事業所チェック
    const nWZgsyoMessages =
        checkMainDntNoNWZgsyo(this.props.order, this.props.contractDntList);
    for (const message of nWZgsyoMessages) {
      warningMessages.push(message);
    }
    // 代表電柱番号存在チェック
    const mainDntNoMessages =
        checkMainDntNoInContractDnts(
            this.props.order, this.props.contractDntList);
    for (const message of mainDntNoMessages) {
      warningMessages.push(message);
    }
  }

  /**
  * 必須チェック
  * @param {array} contractDntList チェック対象
  * @param {array} messages メッセージ格納用
  */
  checkItemSen(contractDntList, messages) {
    if (!contractDntList) {
      return;
    }

    for (const contractDnt of contractDntList) {
      // 電柱番号
      if (!contractDnt.DntNo__c &&
        !contractDnt.DntNoManualInput__c) {
        messages.push({
          id: 'CE0017',
          values: ['電柱番号(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 取付種別
      if (!contractDnt.AttachType__c) {
        messages.push({
          id: 'CE0017',
          values: ['取付種別(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 取付点高さ（m）
      if (!contractDnt.AttachSpotHeightSyunko__c) {
        messages.push({
          id: 'CE0017',
          values: ['取付点高さ（m）(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 最接近電力設備との離隔/設備名称
      if (!contractDnt.ClosestDrStbName__c) {
        messages.push({
          id: 'CE0017',
          values: ['最接近電力設備との離隔/設備名称(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 最接近電力設備との離隔/離隔距離（m）
      if (!contractDnt.ClosestDrStbRikakSyunko__c) {
        messages.push({
          id: 'CE0017',
          values: ['最接近電力設備との離隔/離隔距離（m）(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      // 通信引込線/通信引込線の有無
      if (!contractDnt.TsnHksnUmu__c) {
        messages.push({
          id: 'CE0017',
          values: ['通信引込線/通信引込線の有無(' + contractDnt.SerialNumber__c + '行目)'],
        });
      }
      if (contractDnt.TsnHksnUmu__c == '1') {
        // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
        if (!contractDnt.DrHksnKiknCrossRikakSynk__c) {
          messages.push({
            id: 'CE0017',
            values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）(' + contractDnt.SerialNumber__c + '行目)'],
          });
        }
        // 通信引込線/②電力引込線との離隔確保（家屋側支接点付近）
        if (!contractDnt.DrHksnHouseSideRikakKkhSynk__c) {
          messages.push({
            id: 'CE0070',
            values: ['通信引込線/②電力引込線との離隔確保（家屋側支接点付近）', contractDnt.SerialNumber__c],
          });
        }
      }
      // 添付写真
      const photo = this.props.photoExists[contractDnt.Id];
      checkPhotoExists(photo, contractDnt, messages);

      // 取付点高さ（m）
      if (contractDnt.AttachSpotHeightSyunko__c &&
          (contractDnt.AttachSpotHeightSyunko__c < 2.5 ||
           contractDnt.AttachSpotHeightSyunko__c > 99.9)) {
        messages.push({
          id: 'CE0026',
          values: ['取付点高さ（m）(' + contractDnt.SerialNumber__c + '行目)', '2.5～99.9'],
        });
      }
      // 最接近電力設備との離隔/離隔距離（m）
      if (contractDnt.ClosestDrStbRikakSyunko__c &&
          (contractDnt.ClosestDrStbRikakSyunko__c < 0.01 ||
           contractDnt.ClosestDrStbRikakSyunko__c > 9.99)) {
        messages.push({
          id: 'CE0026',
          values: ['最接近電力設備との離隔/離隔距離（m）(' + contractDnt.SerialNumber__c + '行目)', '0.01～9.99'],
        });
      }
      if (contractDnt.TsnHksnUmu__c == '1') {
        // 通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）
        if (contractDnt.DrHksnKiknCrossRikakSynk__c &&
            (contractDnt.DrHksnKiknCrossRikakSynk__c < 0.01 ||
             contractDnt.DrHksnKiknCrossRikakSynk__c > 9.99)) {
          messages.push({
            id: 'CE0026',
            values: ['通信引込線/①電力引込線との離隔（m）（径間途中，交差部分）(' + contractDnt.SerialNumber__c + '行目)', '0.01～9.99'],
          });
        }
      }
    }
  }

  /**
   * 保存ボタン押下時
   */
  doSave = async () => {
    try {
      // 保存処理
      const ret = await this.insertOrUpdate();
      if (!ret.success) {
        this.props.doShowMessage({
          message: ret.messages,
          action: async () => {
            if (ret.errorCode == API_CODE.ERROR_LAST_MODIFIED_DATE) {
              this.gotoListGmn();
            }
          },
        });
        return;
      }

      this.props.doShowMessage({
        message: {
          id: 'CI0010',
          values: ['保存'],
        },
        action: async () => {
          // 再検索
          const searchResult = await this.searchData();
          if (!searchResult) {
            return;
          }
          // 申込の最終更新日を退避
          this.props.doSetOrderLastModifiedDate(
              this.props.order.LastModifiedDate);
        },
      });
    } catch (error) {
      this.sysErr();
    }
  };

  /**
   * 写真登録画面へ遷移する前に変更有無をチェックする
   * @param {object} ctx 選択した契約電柱
   * @param {string} transfer 遷移先
  */
  checkBeforeMoveAttachedPictures = (ctx, transfer) => {
    if ((this.gridRef.current &&
      this.gridRef.current.commitItemsModified()) ||
      this.props.dirty) {
      this.props.doShowMessage({
        message: {
          id: 'CW0013',
        },
        action: () => {
          this.doMoveAttachedPictures(ctx.item, transfer);
        },
      });
      return;
    }
    this.doMoveAttachedPictures(ctx.item, transfer);
  }

  /**
   * 写真登録画面へ遷移
   * @param {string} item 選択した契約電柱
   * @param {string} transfer 遷移先
   */
  doMoveAttachedPictures =(item, transfer) => {
    // 選択した契約電柱.Idが未設定の場合、
    // ファイルと契約電柱を紐づけられないのでエラーにする
    if (!item || !item.Id) {
      this.props.doShowMessage({
        message: {
          id: 'CE0062',
          values: ['申込電柱'],
        },
      });
      return;
    }
    // 1061_竣工写真登録画面へ遷移
    this.props.doSetPhotoRegistrationInfo({
      contractDntId: item.Id, // 写真と紐づく契約電柱のIdを渡す
      transitionSrcId: '1603',
      hasCommunicationLine: item.TsnHksnUmu__c == '1' ? true : false,
      hasNewCopperPipes: false,
      readOnly: this.state.isDisabled,
    });

    // 写真登録画面へ遷移
    this.props.history.push({pathname: '/completionPhotoRegistration'});
  }

  /**
   * 電柱選択画面を閉じる
   */
  closeSelectDnt = () => {
    this.setState({modalOpen: false});
  }

  /**
   * 選択した電柱情報を取得
   * @param {object} dnt
   */
  setSelectedDnt = async (dnt) => {
    try {
      const {generalPurposeMap} = this.props;

      if (dnt == null || dnt.length == 0) {
        return;
      }

      // 選択した電柱情報が一覧に存在する場合、エラー
      let sourceCollection = this.gridRef.current.getSourceCollection();
      if (this.state.ybdsType == 'A') {
        // 1つ選択する場合は自分を変更できるようにチェック対象から自分を除く
        const serialNumber = this.currentCtx.item.SerialNumber__c;
        sourceCollection =
         sourceCollection.filter((data) =>
           data.SerialNumber__c != serialNumber);
      }
      let isSameDnt = false;
      for (const item of dnt) {
        if (sourceCollection.filter((data) =>
          isSameContractDnt(data, item)).length > 0) {
          isSameDnt = true;
          break;
        }
      }
      if (isSameDnt) {
        this.props.doShowMessage({
          message: {
            id: 'CE0034',
            values: ['選択した電柱'],
          },
        });
        return;
      }

      if (this.state.ybdsType == 'A') {
        // 電柱1件選択
        const selectedDnt = dnt[0];
        const editItem = this.currentCtx ? this.currentCtx.item : null;
        this.gridRef.current.editDnt(
            editItem, selectedDnt, generalPurposeMap.NWZgsyo);
      } else {
        // 電柱複数件選択
        this.gridRef.current.addDntList(dnt, generalPurposeMap.NWZgsyo);
      }
    } catch (error) {
      this.sysErr();
    }
  }

  /**
   * onFormatItemハンドラ
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  onFormatItemHandler = (s, e) => {
    if (s.cells === e.panel &&
        e.panel.cellType == wjgGrid.CellType.Cell) {
      const col = e.getColumn();
      // 通信引込線の1～2
      if ((col.name === 'DrHksnKiknCrossingRikak' ||
          col.name === 'DrHksnHouseSideRikakKkh') && s.rows[e.row]) {
        const data = s.rows[e.row].dataItem;
        // 通信引込線の有無が有の場合、編集可。無の場合、編集不可。
        if (data.TsnHksnUmu__c != '1') {
          wijmo.addClass(e.cell, 'wj-state-disabled');
          wijmo.addClass(e.cell, 'uneditableCell');
        } else {
          wijmo.removeClass(e.cell, 'wj-state-disabled');
          wijmo.removeClass(e.cell, 'uneditableCell');
        }
      }
      // チェックボックスにラベルをつける
      if ((col.name === 'DrHksnHouseSideRikakKkh') && s.rows[e.row]) {
        let text = document.createTextNode('良');
        let checkbox = null;
        if (e.cell.firstChild) {
          checkbox = e.cell.firstChild.firstChild;
        }
        if (checkbox != null && checkbox.parentNode != null) {
          checkbox.parentNode.insertBefore(text, checkbox);
        }
      }
    }
  }

  /**
   * 一覧のフィルターを編集する
   * @param {object} s
   * @param {object} e イベント
   */
  filterChangingHandler(s, e) {
    if (e.getColumn().binding === 'DntCategory__c' ||
        e.getColumn().binding === 'AttachType__c' ||
        e.getColumn().binding === 'ClosestDrStbName__c' ||
        e.getColumn().binding === 'TsnHksnUmu__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);

      let categoryName = 'DntCategory'; // 電柱区分
      if (e.getColumn().binding === 'AttachType__c') {
        categoryName = 'AttachType'; // 取付種別
      } else if (e.getColumn().binding === 'ClosestDrStbName__c') {
        categoryName = 'ClosestDrStbStbName'; // 最接近電力設備との離隔/設備名称
      } else if (e.getColumn().binding === 'TsnHksnUmu__c') {
        categoryName = 'UmuCategory'; // 通信引込線/通信引込線の有無
      }

      lb.itemFormatter = (index) => {
        const ret = getComboName(this.props.generalPurposeMap,
            categoryName, lb.collectionView.items[index].value);
        return ret ? ret : '(なし)';
      };
      lb.collectionView.refresh();
    }
    if (e.getColumn().binding === 'DrHksnHouseSideRikakKkhSynk__c') {
      let edt = s.activeEditor;
      let lbHost = edt.hostElement.querySelector('[wj-part=div-values]');
      let lb = wijmo.Control.getControl(lbHost);
      lb.itemFormatter = (index) => {
        let result = '(なし)';
        if (lb.collectionView.items[index].value === true) {
          result = '良';
        }
        return result;
      };
      lb.collectionView.refresh();
    }
  }

  /**
   * refreshedFunction
   * @param {FlexGrid} s
   * @param {FormatItemEventArgs} e
   */
  refreshedFunctionHandler = (s, e) => {
    try {
      let islist = false;
      // 一覧に追加、変更、削除したデータ取得
      const grid = this.gridRef.current.getGrid();

      // 一覧から更新データがあるか判定
      if (
        ((grid.collectionView)?.itemsAdded &&
         (grid.collectionView).itemsAdded.length > 0) ||
        ((grid.collectionView)?.itemsEdited &&
         (grid.collectionView).itemsEdited.length > 0) ||
        ((grid.collectionView)?.itemsRemoved &&
         (grid.collectionView).itemsRemoved.length > 0)) {
        islist = true;
      }
      if (this.state.isModified != islist) {
        this.setState({isModified: islist});
      }
    } catch (error) {
      console.dir(error);
      this.sysErr();
    }
  }

  /**
   * 一覧描画
   * @return {object}
   */
  renderTable() {
    const {classes, generalPurposeMap, isMobile} = this.props;
    const props = {
      rowHeaderType: 'edit',
      filterOn: true,
      exceptFilters: ['edit', 'poleNoSelector', 'AttachedPictures'],
      headersVisibility: 'All',
      allowSorting: 'None',
      allowDragging: 'Both',
      counterOn: false,
      AddDeleteOn: true,
      frozenColumns: 5,
      style: {height: '500px'},
      isReadOnly: this.state.isDisabled,
      useStore: false,
      editPopupItems: this.editPopup,
      doShowMessage: this.props.doShowMessage,
      formatItemFunction: this.onFormatItemHandler,
      validateEdits: false,
      filterChanging: this.filterChangingHandler,
      refreshedFunction: this.refreshedFunctionHandler,
    };

    // コンボボックス: 取付種別
    const attachTypeList = getComboList(generalPurposeMap, 'AttachType', true, {Code__c: ['2', '3', '4']});
    const attachTypeMap = createDataMapByComboList(attachTypeList);

    // コンボボックス: 設備名称
    const stbNameList = getComboList(generalPurposeMap, 'ClosestDrStbStbName');
    const stbNameMap = createDataMapByComboList(stbNameList);

    // コンボボックス: 通信引込線の有無
    const umuCategoryList = getComboList(generalPurposeMap, 'UmuCategory');
    const umuCategoryMap = createDataMapByComboList(umuCategoryList);

    return (
      <>
        <CustomFlexGrid ref={this.gridRef} {...props}>
          <FlexGridColumnGroup header="電柱" align="center">
            <FlexGridColumnGroup binding="poleNoSelector" header="電柱" width={50}
              cellTemplate={CellMaker.makeButton({
                text: '選択',
                cssClass: this.state.isDisabled ? 'wj-state-disabled' : 'btn_navy',
                click: (e, ctx) => {
                  // 1301_電柱選択画面起動
                  this.currentCtx = ctx;
                  this.setState({modalOpen: true, ybdsType: 'A'});
                },
                attributes: {
                  tabindex: -1,
                },
              })}
            />
            <FlexGridColumnGroup binding="Dsp_SenroName__c" header="線路名" dataType="String" cssClass="selectedDntInfo" width={120} isReadOnly={true}/>
            <FlexGridColumnGroup binding="Dsp_DntNo__c" header="電柱番号" dataType="String" cssClass="selectedDntInfo" width={95} isReadOnly={true} isRequired={true}/>
            <FlexGridColumnGroup binding="DntCategory__c" header="電柱区分" dataType="String" cssClass="selectedDntInfo" width={65} isReadOnly={true}>
              <FlexGridCellTemplate cellType="Cell" template={(ctx) => {
                return getComboName(generalPurposeMap, 'DntCategory', ctx.item.DntCategory__c);
              }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="NWZgsyo__r.Name" header="事業所" dataType="String" cssClass="selectedDntInfo" isReadOnly={true} visible={!isMobile}/>
          <FlexGridColumnGroup binding="AttachType__c" header="取付種別" dataMap={attachTypeMap} visible={!isMobile} width={120}>
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerAttachTypeTemplate} />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup binding="AttachSpotHeightSyunko__c" header="取付点高さ\n（m）" dataType="Number" format="n1" visible={!isMobile} width={110}>
            <FlexGridCellTemplate cellType="CellEdit" template={
              (ctx) => {
                return <InputNumber
                  className="flexGrid-cell-editor"
                  min={0.0}
                  max={99.9}
                  step={0.1}
                  format='n1'
                  value={ctx.value}
                  valueChanged={(inpNum) => ctx.value = inpNum.value}
                />;
              }}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup name="AttachedPictures" binding="AttachedPictures" header="添付写真"
            cellTemplate={CellMaker.makeButton({
              text: '添付',
              cssClass: 'btn_navy',
              click: (e, ctx) => {
                this.checkBeforeMoveAttachedPictures(ctx, '添付写真');
              },
              attributes: {
                tabindex: -1,
              },
            })}
          >
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerAttachedPicturesTemplate}/>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="最接近電力設備との離隔" align="center">
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerClosestDrStbDntTemplate}/>
            <FlexGridColumnGroup binding="ClosestDrStbName__c" header="設備名称" dataMap={stbNameMap} visible={!isMobile} width={200}/>
            <FlexGridColumnGroup binding="ClosestDrStbRikakSyunko__c" header="離隔距離\n（m）" dataType="Number" format="n2" visible={!isMobile} width={110}>
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup header="通信引込線" align="center">
            <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerTsnHksnTemplate}/>
            <FlexGridColumnGroup binding="TsnHksnUmu__c" header="通信引込線の有無" dataMap={umuCategoryMap} cssClass="centerPos" width={110} visible={!isMobile}/>
            <FlexGridColumnGroup name="DrHksnKiknCrossingRikak" binding="DrHksnKiknCrossRikakSynk__c" header="①電力引込線との離隔（m）（径間途中，交差部分）" dataType="Number" width={230} format="n2" visible={!isMobile}>
              <FlexGridCellTemplate cellType="CellEdit" template={
                (ctx) => {
                  return <InputNumber
                    className="flexGrid-cell-editor"
                    min={0.00}
                    max={9.99}
                    step={0.01}
                    format='n2'
                    value={ctx.value}
                    valueChanged={(inpNum) => ctx.value = inpNum.value}
                  />;
                }}/>
            </FlexGridColumnGroup>
            <FlexGridColumnGroup name="DrHksnHouseSideRikakKkh" binding="DrHksnHouseSideRikakKkhSynk__c" header="②電力引込線との離隔確保（家屋側支接点付近）" cssClass="centerPos" width={230} visible={!isMobile}>
              <FlexGridCellTemplate cellType="ColumnHeader" autoSizeRows={false} template={this.headerDrHksnHouseSideRikakKkhTemplate}/>
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
        </CustomFlexGrid>

        <Popup isDraggable={true} isResizable={true}
          initialized={this.initializeEditPopup.bind(this)}
          className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
               線路名　：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_SenroName__c') ?
                        this.state.editItem.Dsp_SenroName__c : ''}
              <br/>
               電柱番号：{this.state.editItem &&
                        Object.hasOwnProperty.call(this.state.editItem, 'Dsp_DntNo__c') ?
                        this.state.editItem.Dsp_DntNo__c : ''}
            </h4>
            <button className="btn-close wj-hide"/>
          </div>
          <div className="modal-body">
            <div className="wj-labeled-input">
              <ComboBox
                id="id1603AttachTypePopup"
                initialized={this.initializeEditedAttachType.bind(this)}
                itemsSource={attachTypeList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>取付種別</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id='id1603AttachSpotHeightPopup'
                format='n1'
                min={0.0}
                max={99.9}
                step={0.1}
                initialized={
                  this.initializeEditedAttachSpotHeight.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>取付点高さ（m）</label>
            </div>
            <br></br>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                  最接近電力設備との離隔
            </div>
            <br></br>
            <div className="wj-labeled-input" >
              <ComboBox
                id="id1603ClosestDrStbStbNamePopup"
                initialized={
                  this.initializeEditedClosestDrStbName.bind(this)}
                itemsSource={stbNameList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                isDisabled={this.state.isDisabled}/>
              <label>設備名称</label>
            </div>
            <div className="wj-labeled-input">
              <InputNumber
                id='id1603ClosestDrStbRikakOrderPopup'
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedClosestDrStbRikakOrder.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>離隔距離（m）</label>
            </div>
            <br></br>
            <div className={clsx('wj-labeled-input', 'wj-input-label-header')}>
                   通信引込線
            </div>
            <br></br>
            <div className="wj-labeled-input">
              <ComboBox
                id="id1603TsnHksnUmuPopup"
                initialized={this.initializeEditedTsnHksnUmu.bind(this)}
                itemsSource={umuCategoryList}
                displayMemberPath="Name"
                selectedValuePath="Code__c"
                selectedIndexChanged={this.changeTsnHksnUmu.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>通信引込線の有無</label>
            </div>
            <div className='wj-labeled-input'>
              <InputNumber
                id='id1603DrHksnKiknCrossingRikakPopup'
                // className='wj-label-longText'
                format='n2'
                min={0.00}
                max={9.99}
                step={0.01}
                initialized={
                  this.initializeEditedDrHksnKiknCrossingRikak.bind(this)}
                isDisabled={this.state.isDisabled}/>
              <label>①電力引込線との離隔（m）（径間途中，交差部分）</label>
            </div>
            <div className="wj-labeled-input" style={{paddingTop: '0px'}}>
              <span className='wj-input-label-custom'>
                ②電力引込線との離隔確保（家屋側支接点付近）
              </span>
              <input
                id="id1603DrHksnHouseSideRikakKkhPopup"
                type="checkbox"
                ref={this.drHksnHouseSideRikakKkhRef}
                value={this.editedDrHksnHouseSideRikakKkh}
                onChange={(e) => {
                  this.changeDrHksnHouseSideRikakKkh(e);
                }
                }
                disabled={this.state.isDisabled}/>
              <label htmlFor="id1603DrHksnHouseSideRikakKkhPopup">
              良
              </label>
            </div>
            <div className="modal-footer">
              <button className={clsx('btn', 'wj-hide-ok', classes.popupPositive)}
                disabled={this.state.isDisabled}>OK</button>
              <button className={clsx('btn', 'wj-hide-cancel', classes.popupNegative)}>キャンセル</button>
            </div>
          </div>
        </Popup>

        {/*  tooltipの初期表示位置がおかしくなるので、削除不可！ */}
        <div style={{display: 'none'}}>
          <div id="attachTypeHelpComponent">
            <AttachTypeCorrect/>
          </div>
          <div id="attachedPicturesSenHelpComponent">
            <AttachedPicturesSenCorrect/>
          </div>
          <div id="closestDrStbDntHelpComponent">
            <ClosestDrStbDntCorrect/>
          </div>
          <div id="tsnHksnHelpComponent">
            <TsnHksnCorrect/>
          </div>
          <div id="drHksnHouseSideRikakKkhHelpComponent">
            <DrHksnHouseSideRikakKkhCorrect/>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {classes, handleSubmit} = this.props;

    const footerBtn = (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="key1603AddDntList" item>
          <PositiveButton
            id="id1603AddDntList"
            startIcon={<AddIcon />}
            onClick={handleSubmit(this.doAddDntList)}
            variant="contained"
            size="large"
            disabled={this.state.isDisabled}
          >
            <span>電柱追加</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1603Next" item>
          <PositiveButton
            id="btn1603Next"
            onClick={handleSubmit(this.doMoveKeikan)}
            variant="contained"
            size="large"
            disabled={this.props.referenceMode}
          >
            <span>径間入力</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1603Save" item>
          <PositiveButton
            id="btn1603Save"
            onClick={handleSubmit(this.doSave)}
            variant="contained"
            size="large"
            startIcon={<SaveAltIcon/>}
            disabled={this.state.isDisabled}
          >
            <span>保存</span>
          </PositiveButton>
        </Grid>
        <Grid key="key1603Back" item>
          <BackButton props={this.props}
            isModified={this.state.isModified}
            showWarning={true}/>
        </Grid>
      </Grid>
    );

    return (
      <>
        <form noValidate autoComplete="off" className={classes.root}>
          <MainContainer
            props={this.props}
            footerBtn={footerBtn}>
            {/* 警告メッセージ */}
            <WarningMessageArea messages={this.state.warningMessages}/>
            <Grid container
              direction="column"
              alignItems="flex-start"
            >
              <Grid key="key1603Msg" item xs={12}>
                <span style={{whiteSpace: 'pre-line'}}>
               ケーブルルートの始点，終点になる電柱は入力不要です。
                </span>
                <br/><br/>
              </Grid>
              <Grid key="key1603MaintenanceKoziCompany" item>
                <Field
                  id="id1603MaintenanceKoziCompany"
                  name="MaintenanceKoziCompany__c"
                  component={renderSelect}
                  className={classes.fields}
                  style={{minWidth: '170px', minHeight: '75px'}}
                  label='保守工事会社選択'
                  required={true}
                  InputProps={{
                    readOnly: false,
                  }}
                  validate={[
                  ]}
                  warn={[
                    validateRule.required,
                  ]}
                  disabled={this.state.isDisabled}
                >
                  {this.props.accountMasterList &&
                        createSearchList(
                            this.props.accountMasterList, 'Id', 'DspName')}
                </Field>
              </Grid>
              <Grid key="key1603Table" item xs={12}>
                {this.renderTable()}
              </Grid>
            </Grid>
            <Grid container
              direction="column"
              alignItems="center"
            >
              <Grid item xs={12}>
                <CustomCard
                  title={<strong>確認事項</strong>} isExpanded={false}>
                  <Typography variant="body1">
                  １. 申請者は，通信線と電力設備の離隔距離の緩和を要望する場合，<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;次の事項を確認し，承諾（チェック）してください。
                  </Typography>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <Field
                        id="CheckJiko1__c"
                        name="CheckJiko1__c"
                        component={renderCheckbox}
                        label={<p style={{color: '#000', maxWidth: '32em', margin: '5px auto 0 auto'}}>
                         離隔距離を緩和しても，通信線の作業者の安全は，申請者の責任において確保する。
                        </p>}
                        disabled={this.state.isDisabled}
                      />
                      <Field
                        id="CheckJiko2__c"
                        name="CheckJiko2__c"
                        component={renderCheckbox}
                        label={<p style={{color: '#000', maxWidth: '31em', margin: '5px auto 5px auto'}}>
                         離隔距離を緩和して通信線を架設し，誘導障害が発生した場合は，申請者の責任で改修する。
                        </p>}
                        disabled={this.state.isDisabled}
                      />
                    </FormGroup>
                  </FormControl>
                  <Typography variant="body1">
                  ２．設置する通信線の種別が以下に該当する場合，チェックを入れてください。
                  </Typography>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <Field
                        id="CheckJiko3__c"
                        name="CheckJiko3__c"
                        component={renderCheckbox}
                        label={<p style={{color: '#000', maxWidth: '34em', marginBottom: '0'}}>
                           光ファイバケーブルまたは添架通信用第 1 種ケーブルもしくは添架通信用第 2 種ケーブル
                        </p>}
                        disabled={this.state.isDisabled}
                      />
                    </FormGroup>
                  </FormControl>
                </CustomCard>
              </Grid>
            </Grid>
          </MainContainer>
          <SelectPoleNo
            YbdsType={this.state.ybdsType}
            modalOpen={this.state.modalOpen}
            closeButton={this.closeSelectDnt}
            setSelectedPole={this.setSelectedDnt}/>
        </form>
      </>
    );
  }
}

Container.propTypes = {
  classes: PropTypes.object,
  generalPurposeMap: PropTypes.object,
  userInfo: PropTypes.object,
  history: PropTypes.object,
  values: PropTypes.object,
  orderId: PropTypes.string,
  order: PropTypes.object,
  contractDntList: PropTypes.array,
  contractDntResult: PropTypes.object,
  handleSubmit: PropTypes.func,
  doShowMessage: PropTypes.func,
  doGetOrderAndContractDntList: PropTypes.func,
  referenceMode: PropTypes.bool,
  dirty: PropTypes.bool,
  doSaveContractDnts: PropTypes.func,
  doSetPhotoRegistrationInfo: PropTypes.func,
  doClearOrderContractDnt: PropTypes.func,
  doSetGmnWarning: PropTypes.func,
  setGmnWarning: PropTypes.object,
  doGetAccountMasterList: PropTypes.func.isRequired,
  doClearAccountMasterList: PropTypes.func.isRequired,
  accountMasterList: PropTypes.array,
  doSetAccountMasterList: PropTypes.func.isRequired,
  photoExists: PropTypes.object,
  attachTypeCheck: PropTypes.object,
  isMobile: PropTypes.bool,
  orderLastModifiedDate: PropTypes.string,
  doSetOrderLastModifiedDate: PropTypes.func,
};

const mapStateToProps = (state) => {
  const orderInfo = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'order') ?
    state.contractDnt.orderContractDntList.order : null;
  const orderContractDntList = state.contractDnt.orderContractDntList &&
    Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'contractDntList') ?
    state.contractDnt.orderContractDntList.contractDntList : [];
  const initValues = {
    CheckJiko1__c: orderInfo &&
      Object.hasOwnProperty.call(orderInfo, 'CheckJiko1__c') ?
      orderInfo['CheckJiko1__c'] : false,
    CheckJiko2__c: orderInfo &&
      Object.hasOwnProperty.call(orderInfo, 'CheckJiko2__c') ?
      orderInfo['CheckJiko2__c'] : false,
    CheckJiko3__c: orderInfo &&
      Object.hasOwnProperty.call(orderInfo, 'CheckJiko3__c') ?
      orderInfo['CheckJiko3__c'] : false,
    MaintenanceKoziCompany__c: orderContractDntList.length > 0 ?
      orderContractDntList[0].MaintenanceKoziCompany__c : null,
  };

  return {
    generalPurposeMap: state.common.generalPurposeMap,
    orderId: state.attachorder.orderId,
    order: orderInfo,
    contractDntList: orderContractDntList,
    photoExists: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'photoExistsPerOrder') ?
      state.contractDnt.orderContractDntList.photoExistsPerOrder : {},
    attachTypeCheck: state.contractDnt.orderContractDntList &&
      Object.hasOwnProperty.call(state.contractDnt.orderContractDntList, 'attachTypeCheck') ?
      state.contractDnt.orderContractDntList.attachTypeCheck : null,
    contractDntResult: state.contractDnt.contractDntResult,
    referenceMode: state.common.referenceMode == true ? true : false,
    setGmnWarning: state.common.setGmnWarning,
    accountMasterList: state.common.accountMasterList,
    initialValues: initValues,
    orderLastModifiedDate: state.attachorder.orderLastModifiedDate,
  };
};

const mapDispatchToProps = {
  doShowMessage: commonOperations.doShowMessage,
  doGetOrderAndContractDntList:
    contractDntOperations.doGetOrderAndContractDntList,
  doSaveContractDnts: contractDntOperations.doSaveContractDnts,
  doSetPhotoRegistrationInfo: contractDntOperations.doSetPhotoRegistrationInfo,
  doClearOrderContractDnt: contractDntOperations.doClearOrderContractDnt,
  doSetGmnWarning: commonOperations.doSetGmnWarning,
  doGetAccountMasterList: commonOperations.doGetAccountMasterList,
  doClearAccountMasterList: commonOperations.doClearAccountMasterList,
  doSetAccountMasterList: commonOperations.doSetAccountMasterList,
  doSetOrderLastModifiedDate: attachorderOperations.doSetOrderLastModifiedDate,
};

const FORM_NAME = 'CorrectAttachSenOrderDnt';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(withMediaQuery(
        [
          ['isMobile', (theme) => theme.breakpoints.down('xs')],
        ],
    )(Container))),
);

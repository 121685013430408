import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const withMediaQuery = (queries = []) => (Component) => function Query(props) {
  const mediaProps = {};
  for (const q of queries) {
    mediaProps[q[0]] = useMediaQuery(q[1]);
  }
  return <Component {...mediaProps} {...props} />;
};

export default withMediaQuery;

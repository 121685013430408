import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Container from '../../../../components/templates/Other/UserManagement/KyogaZgsyaBasicInfo';
import {authOperations} from '../../../../reducks/auth';
import {commonOperations} from '../../../../reducks/common';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = {
  getUserInfo: authOperations.getUserInfoOperation,
  doShowMessage: commonOperations.doShowMessage,
};

/**
 * 共架者基本情報 ページ。
 * 画面ID:1533
 *
 */
class App extends React.Component {
  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.getInitData();
    this.doJudge();
  }

  getInitData = async () => {
    await this.props.getUserInfo();
  }

  doJudge = () => {
    const {userInfo, doShowMessage} = this.props;

    // データ取得に失敗した場合
    if (!userInfo) {
      doShowMessage({
        message: 'CS0001',
        action: this.doMoveLogin,
      });
    }
  }

  doMoveLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    return (<Container
      {...this.props}
    />);
  }
}

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

App.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  doShowMessage: PropTypes.func,
};

export default ContainerApp;

const GET_BILLINGDETAIL_ID = 'GET_BILLINGDETAIL_ID';
const GET_BILLINGDETAIL_LIST = 'GET_BILLINGDETAIL_LIST';
const GET_BILLINGDETAIL_LIST_ORFILTER = 'GET_BILLINGDETAIL_LIST_ORFILTER';
const SET_BILLINGDETAIL_ID = 'SET_BILLINGDETAIL_ID';

export default {
  /** 請求明細 取得(ID指定) */
  GET_BILLINGDETAIL_ID,

  /** 請求明細一覧 取得 */
  GET_BILLINGDETAIL_LIST,

  /** 定期共架料内訳一覧 取得（OR条件あり） */
  GET_BILLINGDETAIL_LIST_ORFILTER,

  /** 請求明細ID 設定 */
  SET_BILLINGDETAIL_ID,
};
